import { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useGetCachedUserGeo } from 'cb-wallet-data/stores/OFAC/hooks/useIsPermitted';
import { DAPP_PRIVACY_POLICY_URL, DAPP_TERMS_OF_SERVICE_URL } from 'cb-wallet-data/utils/externalLinks';
import Image from 'next/image';
import { CellSpacing } from '@cbhq/cds-common';
import { IconButton } from '@cbhq/cds-web/buttons/IconButton';
import { ListCell } from '@cbhq/cds-web/cells/ListCell';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { Icon } from '@cbhq/cds-web/icons/Icon';
import { Box, Divider, VStack } from '@cbhq/cds-web/layout';
import { Link, TextBody, TextLabel2, TextTitle2 } from '@cbhq/cds-web/typography';
import { isInEU } from '@cbhq/intl';
import { useAvailableWalletProviders } from ':dapp/connection/hooks/useAvailableWalletProviders';
import { useWalletProviders } from ':dapp/connection/hooks/useWalletProviders';
import { WalletProviderInfo } from ':dapp/connection/types';
import { COINBASE_WALLET } from ':dapp/connection/wallet-providers/coinbase-wallet';
import { CoinbaseWalletOption } from './CoinbaseWalletOption';
import { SmartWalletSignupButton } from './SmartWalletSignupButton';
import { useIsSmartWalletConnected } from './useIsSmartWalletConnected';
const messages = defineMessages({
  getStartedOnchain: {
    defaultMessage: 'Get started onchain',
    description: 'Text telling the user to connect a new wallet'
  },
  connectWallet: {
    defaultMessage: 'Connect wallet',
    description: 'Text telling the user to connect a new wallet'
  },
  recommended: {
    defaultMessage: 'Recommended',
    description: 'Text telling the user a wallet is recommended'
  },
  orConnectExistingWallet: {
    defaultMessage: 'or connect an existing wallet',
    description: 'Text telling the user to connect with another wallet option'
  },
  disclaimer: {
    defaultMessage: 'By connecting a wallet, you agree to our {termsOfService} and {privacyPolicy}.',
    description: 'Text for label where user agrees to the legal terms and privacy policy'
  },
  disclaimerEU: {
    defaultMessage: 'By connecting a wallet, you agree to our {termsOfService} and have read the {privacyPolicy}.',
    description: 'Text for label where EU user agrees to the legal terms and privacy policy'
  },
  termsOfService: {
    defaultMessage: 'Terms of Service',
    description: 'terms of service link'
  },
  privacyPolicy: {
    defaultMessage: 'Privacy Policy',
    description: 'privacy policy link'
  }
});
type ConnectionWalletSelectorProps = {
  onClose: () => void;
  onSelect: (wallet: WalletProviderInfo) => void;
};
const innerSpacing: CellSpacing = {
  spacingTop: 0,
  spacingBottom: 0
};
const listStyle = {
  listStyle: 'none',
  padding: 0
};
export function ConnectionWalletSelector({
  onClose,
  onSelect
}: ConnectionWalletSelectorProps) {
  const {
    formatMessage
  } = useIntl();
  const {
    isPhone
  } = useBreakpoints();
  const handleSelect = useCallback((walletProvider: WalletProviderInfo) => () => {
    onSelect(walletProvider);
  }, [onSelect]);
  const {
    country
  } = useGetCachedUserGeo();
  const walletProviders = useWalletProviders();
  const availableWalletProviders = useAvailableWalletProviders({
    walletProviders
  });
  const renderLink = useCallback((to: string, text: string) => {
    return <Link to={to} underline={false} openInNewWindow>
        {text}
      </Link>;
  }, []);
  const formattedDisclaimerMessage = useMemo(() => {
    return isInEU(country) ? formatMessage(messages.disclaimerEU, {
      termsOfService: renderLink(DAPP_TERMS_OF_SERVICE_URL, formatMessage(messages.termsOfService)),
      privacyPolicy: renderLink(DAPP_PRIVACY_POLICY_URL, formatMessage(messages.privacyPolicy))
    }) : formatMessage(messages.disclaimer, {
      termsOfService: renderLink(DAPP_TERMS_OF_SERVICE_URL, formatMessage(messages.termsOfService)),
      privacyPolicy: renderLink(DAPP_PRIVACY_POLICY_URL, formatMessage(messages.privacyPolicy))
    });
  }, [country, formatMessage, renderLink]);
  const smartWalletConnected = useIsSmartWalletConnected();
  return <VStack gap={1} testID="connection-wallet-selector">
      <ListCell as="div" title={<TextTitle2 as="h1">
            {formatMessage(smartWalletConnected ? messages.connectWallet : messages.getStartedOnchain)}
          </TextTitle2>} innerSpacing={innerSpacing} action={<IconButton transparent name="close" onClick={onClose} testID="connection-wallet-close-icon" />} />
      {!smartWalletConnected && <>
          <SmartWalletSignupButton onSelect={onSelect} />
          <Box position="relative" spacingVertical={3}>
            <Divider direction="horizontal" width="100%" />
            <Box position="absolute" top="50%" left="50%" className={"b1piqmj4"} background spacing={2} width="max-content">
              <TextBody as="p" color="foregroundMuted" align="center">
                {formatMessage(messages.orConnectExistingWallet)}
              </TextBody>
            </Box>
          </Box>
        </>}
      <VStack as="ul" gap={1} style={listStyle}>
        {/**
         * NOTE: passing the wallet provider info here is for presentation purposes only.
         * Inside the CoinbaseWalletSelector we pass the correct wallet provider info
         * for the users connection
         */}
        <CoinbaseWalletOption onSelect={handleSelect(COINBASE_WALLET)} />
        {/* Render additional wallet providers here. Filter out coinbase wallet providers (presentational only) */}
        {availableWalletProviders.filter(walletProvider => !walletProvider.id.includes('com.coinbase')).map(walletProvider => {
        return <ListCell as="li" key={walletProvider.id} priority="start" testID={`connection-wallet-btn-${walletProvider.id}`} title={walletProvider.name} onPress={handleSelect(walletProvider)} media={<Image src={walletProvider.image} height={32} width={32} alt={walletProvider.name} />} action={<Box alignItems="center">
                    {!isPhone && walletProvider.recommended && <TextBody spacingEnd={1} as="span" color="foregroundMuted">
                        {formatMessage(messages.recommended)}
                      </TextBody>}
                    <Icon name="caretRight" size="s" color="foregroundMuted" />
                  </Box>} description={isPhone && walletProvider.recommended && <TextBody spacingEnd={1} as="span" color="foregroundMuted">
                      {formatMessage(messages.recommended)}
                    </TextBody>} />;
      })}
      </VStack>
      <VStack spacing={2} spacingBottom={3} gap={3} justifyContent="center" alignItems="center">
        <VStack justifyContent="center" testID="tos-pp-disclaimer">
          <TextLabel2 as="p" color="foregroundMuted" align="center">
            {formattedDisclaimerMessage}
          </TextLabel2>
        </VStack>
      </VStack>
    </VStack>;
}

require("./ConnectionWalletSelector.linaria.module.css!=!../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./ConnectionWalletSelector.tsx");
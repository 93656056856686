import { getERC20Balance as ethGetERC20Balance } from 'cb-wallet-data/chains/AccountBased/Ethereum/apis/EthereumRPC';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { NetworkError } from 'cb-wallet-data/stores/Networks/NetworkError';
import { fromHex } from 'cb-wallet-data/utils/BigInt+Core';
/**
 * Get ERC20 token balance for given contract/network
 *
 * @param address Wallet address
 * @param contractAddress ERC20 Contract address
 * @param network Wallet network
 *
 * @return Returns balance in atomic unit
 */
export async function getERC20Balance(
  address: string,
  contractAddress: string,
  network: Network,
  onChainBalance?: string,
): Promise<bigint> {
  const ethereumChain = network.asChain?.();

  if (!ethereumChain) {
    throw NetworkError.invalidNetwork(network);
  }

  const bigIntOnChainBalance = onChainBalance ? fromHex(onChainBalance) : undefined;

  if (bigIntOnChainBalance !== undefined) {
    return bigIntOnChainBalance;
  }

  const result = await ethGetERC20Balance(address, contractAddress, ethereumChain.chainId);

  return result;
}

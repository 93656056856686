import { rateFor } from 'cb-wallet-data/stores/ExchangeRates/utils';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { bigIntToDecimal } from 'cb-wallet-data/utils/BigInt+Core';
import { Decimal } from 'decimal.js';

import { LOW_BALANCE_THRESHOLD, SMALL_BALANCE_THRESHOLD } from '../constants';

export function checkIfIsLowBalanceWallet(
  w: Wallet,
  exchangeRatesMap: Record<string, Decimal> = {},
  includeTestnet = false,
  areSmallBalanceWalletsHidden = false,
) {
  if (!includeTestnet && w.network.isTestnet) return false;

  const walletBalance = bigIntToDecimal(w.balance ?? 0n);
  const walletDecimals = bigIntToDecimal(w.decimals);

  const exchangeRate = rateFor({
    exchangeRatesMap,
    currencyCode: w.currencyCode,
    contractAddress: w.contractAddress,
    network: w.network,
    blockchain: w.blockchain,
  });

  if (!exchangeRate) {
    return new Decimal(walletBalance).equals(0);
  }

  const threshold = areSmallBalanceWalletsHidden ? SMALL_BALANCE_THRESHOLD : LOW_BALANCE_THRESHOLD;

  const computedBalance = exchangeRate
    .times(walletBalance)
    .dividedBy(new Decimal(10).pow(walletDecimals));

  return computedBalance.lessThan(threshold);
}

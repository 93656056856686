import { defineMessages, useIntl } from 'react-intl';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import Image from 'next/image';
import { ListCell } from '@cbhq/cds-web/cells/ListCell';
import { Icon } from '@cbhq/cds-web/icons/Icon';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { TextLabel2 } from '@cbhq/cds-web/typography';

import { COINBASE_WALLET } from ':dapp/connection/wallet-providers/coinbase-wallet';

const messages = defineMessages({
  recommended: {
    defaultMessage: 'Recommended',
    description: 'Text telling the user a wallet is recommended',
  },
  cbwUpsell: {
    defaultMessage: 'Earn points with "Spin the Wheel"',
    description: 'Text telling the user they can earn more with Spin the Wheel',
  },
});

type CoinbaseWalletOptionProps = {
  onSelect: () => void;
};

export function CoinbaseWalletOption({ onSelect }: CoinbaseWalletOptionProps) {
  const { formatMessage } = useIntl();
  const isOCSEnabled = useIsFeatureEnabled('ocs_pages');

  return (
    <ListCell
      as="li"
      priority="start"
      testID="coinbase-wallet-option"
      title={COINBASE_WALLET.name}
      onPress={onSelect}
      media={
        <Image src={COINBASE_WALLET.image} height={32} width={32} alt={COINBASE_WALLET.name} />
      }
      action={
        <Box alignItems="center">
          <Icon spacingStart={1} name="caretRight" size="s" color="foregroundMuted" />
        </Box>
      }
      description={
        <VStack>
          {isOCSEnabled && (
            <TextLabel2 as="p" color="foregroundMuted" spacingTop={0.5}>
              {formatMessage(messages.cbwUpsell)}
            </TextLabel2>
          )}
        </VStack>
      }
    />
  );
}

import { useCallback } from 'react';

import { DrawerName } from ':dapp/components/GlobalDrawer/constants';
import { useGlobalDrawerOrNavigate } from ':dapp/components/GlobalDrawer/hooks/useGlobalDrawerOrNavigate';
import { SendDrawer } from ':dapp/components/Send/SendDrawer';
import { SwapSidebarDrawer } from ':dapp/components/Swap/SwapSidebar/SwapSidebarDrawer';
import { RoutesEnum } from ':dapp/utils/RoutesEnum';

type UseHandleGabItemPressProps = {
  closeModal: () => void;
};

export function useHandleGabItemPress({ closeModal }: UseHandleGabItemPressProps) {
  const { openDrawerOrNavigate } = useGlobalDrawerOrNavigate();
  return useCallback(
    (drawer: DrawerName) => () => {
      switch (drawer) {
        case DrawerName.Send:
          openDrawerOrNavigate(<SendDrawer />, RoutesEnum.SEND);
          break;
        case DrawerName.Swap:
          openDrawerOrNavigate(<SwapSidebarDrawer />, RoutesEnum.SWAP);
          break;
        default:
          break;
      }
      closeModal();
    },
    [openDrawerOrNavigate, closeModal],
  );
}

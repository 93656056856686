import { useCallback } from 'react';
import { addAddressesToUTXOHistoryListener } from 'cb-wallet-data/AddressHistory/hooks/useUTXOAddressHistory';
import { PossibleUTXOBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
import {
  BITCOIN_SYMBOL,
  BitcoinSegwitAddressType,
} from 'cb-wallet-data/chains/UTXO/Bitcoin/config';
import { createWallets } from 'cb-wallet-data/chains/UTXO/common/interfaces/createWallets';
import { DOGECOIN_SYMBOL } from 'cb-wallet-data/chains/UTXO/Dogecoin/config';
import {
  LITECOIN_SYMBOL,
  LitecoinSegwitAddressType,
} from 'cb-wallet-data/chains/UTXO/Litecoin/config';
import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { deriveDeterministicAccountIdForXPubKey } from 'cb-wallet-data/stores/Accounts/utils/deriveDeterministicAccountIdForXPubKey';
import { useCreateWalletGroups } from 'cb-wallet-data/stores/WalletGroups/hooks/useCreateWalletGroups';

import { Account } from '../models/Account';
import { AccountType } from '../models/AccountTypes';
import { XPubKeyResponse } from '../models/WalletConnectionResponse';
import {
  ProcessWalletConnectionResponseError,
  ProcessWalletConnectionResponseErrorType,
} from '../types';
import { deriveDefaultAddressForXPubKey } from '../utils/deriveDefaultAddressForXPubKey';

import { useCheckIfAccountExists } from './useCheckIfAccountExists';
import { useSaveAndSetAccount } from './useSaveAndSetAccount';

type CreateAccountForXPubKeyParams = {
  provider: string;
  chain: PossibleUTXOBlockchainSymbol;
  xpubKeys: XPubKeyResponse[];
  providerUserId?: string;
};

export function useCreateAccountAndWalletGroupForXPubKey() {
  const saveAndSetAccount = useSaveAndSetAccount();
  const createWalletGroups = useCreateWalletGroups();
  const checkIfAccountExists = useCheckIfAccountExists();

  return useCallback(
    async function createAccountForXPubKey({
      provider,
      chain,
      xpubKeys,
      providerUserId,
    }: CreateAccountForXPubKeyParams) {
      try {
        if (!xpubKeys?.length) {
          return;
        }

        const anchorXPubKey = (() => {
          switch (chain) {
            case BITCOIN_SYMBOL:
              return (
                xpubKeys.find((xpubKey) => xpubKey.xPubType === BitcoinSegwitAddressType) ||
                xpubKeys[0]
              );
            case LITECOIN_SYMBOL:
              return (
                xpubKeys.find((xpubKey) => xpubKey.xPubType === LitecoinSegwitAddressType) ||
                xpubKeys[0]
              );
            case DOGECOIN_SYMBOL:
              return xpubKeys[0];
            default:
              return xpubKeys[0];
          }
        })();

        const derivedAccountIdFromXPubKey = await deriveDeterministicAccountIdForXPubKey({
          xpubKey: anchorXPubKey,
          chain,
        });

        if (checkIfAccountExists(derivedAccountIdFromXPubKey)) {
          throw new ProcessWalletConnectionResponseError(
            ProcessWalletConnectionResponseErrorType.ADDRESS_ALREADY_IMPORTED,
            'Address already imported',
          );
        }

        const primaryAddress = await deriveDefaultAddressForXPubKey({
          xpubKey: anchorXPubKey,
          chain,
        });

        const account = new Account({
          type: AccountType.DAPP_PROVIDER,
          primaryAddress,
          primaryAddressChain: chain,
          provider,
          dappProviderUserId: providerUserId,
        });

        await saveAndSetAccount(account);
        await createWalletGroups([{ accountId: account.id, walletIndex: 0n }]);

        await createWallets({
          rawValue: chain,
          accountId: account.id,
          xpubKeys,
        });

        addAddressesToUTXOHistoryListener({ accountId: account.id, blockchainSymbol: chain });

        return account;
      } catch (e: ErrorOrAny) {
        cbReportError({
          error: e as Error,
          context: 'dapp-account-creation',
          severity: 'error',
          isHandled: false,
        });

        throw e;
      }
    },
    [checkIfAccountExists, createWalletGroups, saveAndSetAccount],
  );
}

import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';

import { getRemoteCuratedAssetSettings } from '../api';
import { addCuratedAssetSettings } from '../database';

export async function syncCuratedAssetSettings() {
  try {
    const curatedAssetSettings = await getRemoteCuratedAssetSettings();
    if (curatedAssetSettings.length > 0) {
      await addCuratedAssetSettings(curatedAssetSettings);
      return true;
    }
    return false;
  } catch (syncError) {
    const err = coerceError(syncError, 'syncCuratedAssetSettings');
    cbReportError({ error: err, context: 'assets', isHandled: false, severity: 'error' });
    return false;
  }
}

import { useRecoilValue } from 'recoil';

import { accountsAtom } from '../state';

/**
 * Returns raw list of accounts from client db, held in recoil state.
 *
 * @note If you require accounts to be sorted deterministically and/or combined as
 * the user sees them in UI, use useUserFacingAccountsSortedForDisplay instead.
 */
export function useAccounts() {
  const accounts = useRecoilValue(accountsAtom);
  return accounts;
}

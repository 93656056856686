import {
  BaseWalletAdapter as SolanaWalletAdapter,
  WalletConnectionError,
} from '@solana/wallet-adapter-base';
import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import {
  buildWalletConnectionAccountResponse,
  WalletConnectionResponse,
} from 'cb-wallet-data/stores/Accounts/models/WalletConnectionResponse';

import { PossibleNetworkWalletConnector, WalletConnectionErrorType } from './types';

type SolanaConnectionInput = {
  provider: string;
  result: string;
};

function formatSolanaConnectionResponse({
  provider,
  result,
}: SolanaConnectionInput): WalletConnectionResponse {
  const account = buildWalletConnectionAccountResponse({
    solAddresses: [result],
  });

  return { account, provider };
}

export async function connect(provider: string, connector: PossibleNetworkWalletConnector) {
  try {
    if (!(connector instanceof SolanaWalletAdapter)) {
      return {
        error: WalletConnectionErrorType.CONNECTOR_INVALID,
      };
    }
    await connector.connect();
    const result = connector.publicKey?.toBase58();
    if (!result) {
      return {
        error: WalletConnectionErrorType.CONNECTION_FAILED,
      };
    }
    return { result: formatSolanaConnectionResponse({ provider, result }) };
  } catch (error) {
    // Coinbase Wallet
    if (error instanceof WalletConnectionError) {
      if (/Connection error/i.test(error.message)) {
        return {
          error: WalletConnectionErrorType.USER_REJECTED,
        };
      }
    }
    // Phantom
    if (error instanceof Error) {
      if (/User rejected the request/i.test(error.message)) {
        return {
          error: WalletConnectionErrorType.USER_REJECTED,
        };
      }
    }

    cbReportError({
      context: 'wallet-connection-error',
      error: coerceError(error, 'solana connect error'),
      isHandled: false,
      severity: 'error',
    });

    return {
      error: WalletConnectionErrorType.UNKNOWN,
    };
  }
}

import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useCurrencyFormatter } from 'cb-wallet-data/CurrencyFormatter/hooks/useCurrencyFormatter';
import {
  checkAmountSeparator,
  normalizeEnteredAmount,
} from 'cb-wallet-data/CurrencyFormatter/utils/amount';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { useActiveFiatCurrency } from 'cb-wallet-data/stores/ActiveFiatCurrency/hooks/useActiveFiatCurrency';
import Decimal from 'decimal.js';
import { AmountInput } from 'wallet-cds-web/components/AmountInput';
import { PaletteForeground } from '@cbhq/cds-web';

import { usePanoOnrampContext } from ':dapp/providers/PanoOnrampContext';

const MAX_DIGITS_FIAT = 12;

export function BuyAmountInput() {
  const { updateOnrampInfo, sanitizedAmount, selectedAsset } = usePanoOnrampContext();
  const { formatToCrypto } = useCurrencyFormatter();
  const activeFiatCurrency = useActiveFiatCurrency();
  const [fiatAmount, setFiatAmount] = useState('0');
  const { locale } = useIntl();

  const handleInputChange = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement, Event>) => {
      const value = e.currentTarget.value ? e.currentTarget.value : '0';

      const isValidAmount = checkAmountSeparator({ value, locale });

      if (value.length >= MAX_DIGITS_FIAT || !isValidAmount) {
        return;
      }

      const normalizeValue = normalizeEnteredAmount({
        value,
        decimals: BigInt(selectedAsset?.decimals ?? 0n),
        isFiat: true,
        locale,
      });

      setFiatAmount(normalizeValue);
      updateOnrampInfo({
        amount: normalizeValue,
      });
    },
    [locale, selectedAsset?.decimals, updateOnrampInfo],
  );

  const formattedCryptoValue = useMemo(() => {
    if (!selectedAsset) {
      return '';
    }
    return formatToCrypto({
      currencyCode: new CurrencyCode(selectedAsset.code),
      decimals: BigInt(selectedAsset.decimals),
      value: new Decimal(sanitizedAmount),
      convertFromFiat: true,
      network: selectedAsset.network,
      blockchain: selectedAsset.blockchain,
      contractAddress: selectedAsset.contractAddress ?? '',
    });
  }, [formatToCrypto, sanitizedAmount, selectedAsset]);

  const amountInputProps = {
    primaryCurrencyCode: activeFiatCurrency.code.code,
    secondaryText: formattedCryptoValue as string,
    showSwitchIcon: false,
    secondaryTextColorOverride: 'foregroundMuted' as PaletteForeground,
  };

  return (
    <AmountInput
      onInputChanged={handleInputChange}
      primaryFormattedAmount={fiatAmount}
      {...amountInputProps}
    />
  );
}

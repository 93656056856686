import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useToast } from '@cbhq/cds-web/overlays/useToast';

const messages = defineMessages({
  successMessage: {
    defaultMessage: 'Copied to clipboard',
    description: 'Confirm copy toast message',
  },
  failureMessage: {
    defaultMessage: 'Could not be copied',
    description: 'Failure copy toast message',
  },
});

export function useClipboard() {
  const toast = useToast();
  const { formatMessage } = useIntl();
  return useCallback(
    async (
      value: string,
      showToast = true,
      confirmationText = formatMessage(messages.successMessage),
      failureText = formatMessage(messages.failureMessage),
    ) => {
      if (showToast) {
        toast.clearQueue();
      }
      let toastMessage = confirmationText;
      try {
        await navigator.clipboard.writeText(value);
      } catch {
        toastMessage = failureText;
      }

      if (showToast) {
        toast.show(toastMessage);
      }
    },
    [formatMessage, toast],
  );
}

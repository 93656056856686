import {
  triggerMigrateResult,
  triggerMigrateRun,
  triggerMigrationResult,
  triggerMigrationRun,
} from 'cb-wallet-analytics/migrations';
import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { Store } from 'cb-wallet-store/Store';
import { DeepReadonly } from 'ts-essentials';

import { buildDataMigrationParams } from './utils/buildDataMigrationParams';
import { DATA_MIGRATION_FILES, getDataMigrationFiles } from './DataMigrationFiles';
import {
  DataMigrationApp,
  DataMigrationFile,
  DataMigrationFilename,
  DataMigrationParams,
} from './types';

export class DataMigrationFileSystem {
  private files: typeof DATA_MIGRATION_FILES;

  constructor(files: typeof DATA_MIGRATION_FILES) {
    this.files = files;
  }

  getMostRecentDataMigrationFile(): DeepReadonly<DataMigrationFile> | undefined {
    return this.files[this.files.length - 1];
  }

  findNextDataMigrationFile(
    migrationFilename?: DataMigrationFilename,
  ): DeepReadonly<DataMigrationFile> | undefined {
    if (typeof migrationFilename === 'undefined') {
      return this.importDataMigrationAtIndex(0);
    }

    const index = this.files.findIndex((file) => file[0] === migrationFilename);
    if (index === this.files.length - 1) {
      return;
    }

    return this.importDataMigrationAtIndex(index + 1);
  }

  private importDataMigrationAtIndex(index: number): DeepReadonly<DataMigrationFile> | undefined {
    const file = this.files[index];
    return file;
  }
}

type dateISOString = string;
type DataMigrationRunnerMigrateResult =
  | {
      isUpToDate: true;
      error: undefined;
      dataMigrationVersion?: DataMigrationFilename;
      dataMigrationVersionUpdatedAt?: dateISOString;
    }
  | {
      isUpToDate: false;
      error?: Error;
      dataMigrationVersion?: DataMigrationFilename;
      dataMigrationVersionUpdatedAt?: dateISOString;
    };

/**
 * DataMigrationRunner
 *
 * TRY NOT TO:
 * - Modify a migration after it has been released because it may have already
 *   been run for users
 * - Modify Recoil state directly
 *
 * YOU CANNOT:
 * - Migrate database schema
 *
 * When do these migrations run:
 * - After database migrations
 * - Before Recoil state is loaded
 * - Sequentially in order 1-by-1
 *
 * Use to:
 * - Modify database data (not schema)
 * - Modify local storage
 *
 * Instructions:
 * 1. Add a migration in the migrations folder.
 *    - It must have a default export, which will be called when the migration
 *      is set to run.
 *    - Migrations must be idempotent.
 * 2. Append the [key, migration] to DATA_MIGRATION_FILES in ./DataMigrationFiles.
 * 3. Write unit tests for the migration.
 */
export class DataMigrationRunner {
  static StoreKeys_dataMigrationVersion: LocalStorageStoreKey<DataMigrationFilename | undefined> =
    new LocalStorageStoreKey<DataMigrationFilename | undefined>('dataMigrationVersion');

  static StoreKeys_dataMigrationVersionUpdatedAt: LocalStorageStoreKey<dateISOString | undefined> =
    new LocalStorageStoreKey<dateISOString | undefined>('dataMigrationVersionUpdatedAt');

  private dataMigrationVersion?: DataMigrationFilename;
  private dataMigrationVersionUpdatedAt?: string;
  private fileSystem: DataMigrationFileSystem;
  private error?: Error;

  constructor(fileSystem: DataMigrationFileSystem) {
    this.dataMigrationVersion = Store.get(DataMigrationRunner.StoreKeys_dataMigrationVersion);
    this.dataMigrationVersionUpdatedAt = Store.get(
      DataMigrationRunner.StoreKeys_dataMigrationVersionUpdatedAt,
    );
    this.fileSystem = fileSystem;
  }

  /**
   * @param app
   * @returns DataMigrationRunnerMigrateResult is used by RN/Ext to determine
   * whether or not the app can load. Because these migrations will be mandatory,
   * the app will be blocked from loading unless
   * DataMigrationRunnerMigrateResult.isUpToDate is true.
   */
  async migrate(app?: DataMigrationApp): Promise<DataMigrationRunnerMigrateResult> {
    triggerMigrateRun();

    // reset error
    this.error = undefined;

    if (this.isUpToDate()) {
      return this.migrateResult;
    }

    const dataMigrationParams = buildDataMigrationParams(app);
    let dataMigrationFileToRun = this.fileSystem.findNextDataMigrationFile(
      this.dataMigrationVersion,
    );

    while (dataMigrationFileToRun) {
      // eslint-disable-next-line no-await-in-loop
      const executed = await this.execute(dataMigrationFileToRun, dataMigrationParams);

      // halt if there was an error
      if (!executed) {
        break;
      }

      const [filename] = dataMigrationFileToRun;
      // @ts-expect-error TS(2345): Argument of type '"20220803_000000_initHotfixVersi... Remove this comment to see the full error message
      dataMigrationFileToRun = this.fileSystem.findNextDataMigrationFile(filename);
    }

    return this.migrateResult;
  }

  /**
   * Checks to see if the migrations are all run
   *
   * - If there are no migrations, return true
   * - Else, it will get the most recent migration file and check it against
   *   this dataMigrationVersion
   *
   * @returns boolean
   */
  private isUpToDate(): boolean {
    const mostRecentMigrationFile = this.fileSystem.getMostRecentDataMigrationFile();
    if (typeof mostRecentMigrationFile === 'undefined') {
      return true;
    }
    return mostRecentMigrationFile[0] === this.dataMigrationVersion;
  }

  private async execute(
    migrationFile: DeepReadonly<DataMigrationFile>,
    params: DataMigrationParams,
  ): Promise<boolean> {
    const [filename, migration] = migrationFile;
    let isSuccess = true;

    try {
      // @ts-expect-error TS(2322): Type '"20220803_000000_initHotfixVersion" | "20220... Remove this comment to see the full error message
      triggerMigrationRun({ filename });
      // @ts-expect-error TS(2339): Property 'up' does not exist on type '"20220803_00... Remove this comment to see the full error message
      await migration.up(params);

      // Once the migration has run successfully, set the filename as the version.
      // This means that if a migration has not run successfully (aborts in the
      // middle), it will not be considered a success.
      //
      // Success is determined by whether or not errors were thrown. Be careful
      // of throwing errors that should not be blocking.
      // @ts-expect-error TS(2345): Argument of type '"20220803_000000_initHotfixVersi... Remove this comment to see the full error message
      this.setVersion(filename);
    } catch (error: unknown) {
      isSuccess = false;
      this.error = error as Error;
      const err = coerceError(error, 'dataMigrationRunner');
      cbReportError({
        error: err,
        context: 'dataMigrationRunner',
        severity: 'error',
        isHandled: false,
      });
    }

    try {
      // If migration.up is not executed successfully, it will throw and
      // try migration.down
      // @ts-expect-error TS(2339): Property 'down' does not exist on type '"20220803_... Remove this comment to see the full error message
      if (!isSuccess && migration.down) {
        // @ts-expect-error TS(2339): Property 'down' does not exist on type '"20220803_... Remove this comment to see the full error message
        await migration.down(params);
      }
    } catch (error: unknown) {
      const err = coerceError(error, 'dataMigrationRunner');
      cbReportError({
        error: err,
        context: 'dataMigrationRunner',
        severity: 'error',
        isHandled: false,
      });
    }

    // @ts-expect-error TS(2322): Type '"20220803_000000_initHotfixVersion" | "20220... Remove this comment to see the full error message
    triggerMigrationResult({ filename, isSuccess });
    return isSuccess;
  }

  private setVersion(filename: DataMigrationFilename): void {
    const dateString = new Date().toISOString();

    this.dataMigrationVersion = filename;
    this.dataMigrationVersionUpdatedAt = dateString;

    Store.set(DataMigrationRunner.StoreKeys_dataMigrationVersion, filename);
    Store.set(DataMigrationRunner.StoreKeys_dataMigrationVersionUpdatedAt, dateString);
  }

  private get migrateResult(): DataMigrationRunnerMigrateResult {
    // TS requires the clear delineation of true and false for `isUpToDate`
    const migrateResult: DataMigrationRunnerMigrateResult = this.isUpToDate()
      ? {
          error: undefined,
          isUpToDate: this.isUpToDate(),
          dataMigrationVersion: this.dataMigrationVersion,
          dataMigrationVersionUpdatedAt: this.dataMigrationVersionUpdatedAt,
        }
      : {
          error: this.error,
          isUpToDate: false,
          dataMigrationVersion: this.dataMigrationVersion,
          dataMigrationVersionUpdatedAt: this.dataMigrationVersionUpdatedAt,
        };

    // Log the #migrate result as close as possible to when #migrate returns
    // in order to understand the state of ALL migrations just before app loads.
    //
    // The alternative place to put this would be in RN/Ext, right after it returns
    // from #migrate. The decision to put it here is because it's a single,
    // consistent place to track in the DL.
    //
    // NOTE This provides different insight than the individual migraton result
    // tracking, because it's possible in certain scenarios those fail due to
    // another reason (common one being that the extension is unloaded, which is
    // OK because the app does not need to load then).
    //
    // However, if it gets to this event, it should be the case that all migrations
    // are successful.
    triggerMigrateResult(migrateResult);

    if (this.error) {
      const err = coerceError(this.error, 'dataMigrationError');
      cbReportError({
        error: err,
        context: 'dataMigrationRunner.migrateResult',
        severity: 'error',
        isHandled: false,
      });
    }

    return migrateResult;
  }
}

const dataMigrationFilenames = getDataMigrationFiles();
export const dataMigrationFileSystem = new DataMigrationFileSystem(dataMigrationFilenames);
export const dataMigrationRunner = new DataMigrationRunner(dataMigrationFileSystem);

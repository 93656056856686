import { GalleryBySlugResult } from '@cbhq/instant-api-hooks-creator-service/fetch';

export async function getGalleryBySlug(slug: string): Promise<GalleryBySlugResult> {
  const GALLERY_API_URL = `https://api.wallet.coinbase.com/rpc/v3/creators/getGalleryBySlug?slug=${slug}`;

  let galleryData = {} as GalleryBySlugResult;

  try {
    const response = await fetch(GALLERY_API_URL);
    galleryData = await response.json();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching gallery data:', error);
  }

  return galleryData;
}

import { logTxConfirmed } from 'cb-wallet-analytics/data/Transactions';
import {
  TxOrUserOpMetadataKey_analyticsId,
  TxOrUserOpMetadataKey_txSource,
} from 'cb-wallet-data/chains/AccountBased/Ethereum/config';
import { EthereumNetworkMap } from 'cb-wallet-data/chains/AccountBased/Ethereum/EthereumChain';
import { AllPossibleBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
import { toDecimal } from 'cb-wallet-data/stores/ExchangeRates/utils';
import { persistTxOrUserOps } from 'cb-wallet-data/stores/Transactions/database';
import { TxOrUserOp, TxOrUserOpParams } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import { TxOrUserOpMetadata } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOpMetadata';
import { TxOrUserOpMetadataKey_hasRecordedConfirmEvent } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOpMetadataKey';

import { fetchTransactionsFromDbWithMatchingHashes } from './deduplicateAndPersist';

/**
 * This function fires tx_confirmed events for transactions originating from CBW.
 * It does this by:
 * 1. Fetching transactions from DB with hashes which match the transactions received in the sync
 * 2. Iterating over each *hash* of the transactions fetched from DB
 * 3. Checking if we have not yet fired a tx_confirmed event for this hash (hasRecordedEvent === false on any of the transactions that matched)
 * 4. Fire event
 * 5. Add *all* matching transaction objects for this *hash* into an array to update them with hasRecordedEvent == true at the end of loop in Step 2.
 *
 * TODO: we should ensure userOps are filtered out before arriving to this function since we don't control tx throughput of userOps
 * @param txs Transactions received from the history sync
 */
export async function reportTxsConfirmed(
  txs: TxOrUserOp[],
  blockchainSymbol: AllPossibleBlockchainSymbol,
): Promise<void> {
  const transactions = await fetchTransactionsFromDbWithMatchingHashes({ txs, blockchainSymbol });

  // Create a map of txHash to metadata for transactions that were fetched from the backend.
  // With the introduction of HRT, we populate TxOrUserOpMetadata with additional information about the transaction
  // at the time of creation. To ensure this metadata is not lost, we need to merge it with the metadata
  // that was created at submission time.
  const txHashToMetadata: Map<string | undefined, TxOrUserOpMetadata> = new Map();
  txHashToMetadata.set(undefined, new TxOrUserOpMetadata()); // Handle undefined txHash explicitly to simplify logic below
  txs.forEach(function txToMetadata(tx) {
    if (!tx.txHash) return;
    txHashToMetadata.set(tx.txHash, tx.metadata);
  });

  // Creates a map of transaction arrays keyed by hash.
  const existingTxsByHash = transactions.reduce(function reduce(accumulator, transaction) {
    const hash = transaction.txHash;
    if (!hash) return accumulator;

    if (accumulator[hash]) {
      accumulator[hash].push(transaction);
    } else {
      accumulator[hash] = [transaction];
    }

    return accumulator;
  }, {} as Record<string, TxOrUserOp[]>);

  const txToUpdateMetadata: TxOrUserOp[] = [];

  for (const txHash of Object.keys(existingTxsByHash)) {
    if (!txHash) continue;

    const txList = existingTxsByHash[txHash];
    if (!txList) continue;

    // Check the metadata on all txs for the same hash.
    // We need at least one entry that has explicitly been marked as false before submitting for signing
    // (otherwise we'd end up reporting every pre-existing transaction to the analytics engine)
    const needsRecordingConfirmEvent = txList.find(
      (t) => t.metadata.get(TxOrUserOpMetadataKey_hasRecordedConfirmEvent) === false,
    );

    if (!needsRecordingConfirmEvent) {
      continue;
    }

    // get the tx with the longest createdAt timestamp for more accurate metrics
    const firstRecordedTx = txList.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())[0];

    const confirmedAt = firstRecordedTx.confirmedAt;
    const minutesToConfirm = confirmedAt
      ? Math.floor(
          (confirmedAt.getTime() - firstRecordedTx.createdAt.getTime()) / 1000 / 60,
        ).toString()
      : '';
    const secondsToConfirm = confirmedAt
      ? Math.floor((confirmedAt.getTime() - firstRecordedTx.createdAt.getTime()) / 1000).toString()
      : '';

    const feeDecimal = toDecimal(firstRecordedTx.fee, firstRecordedTx.feeCurrencyDecimal);
    const analyticsId = firstRecordedTx.metadata.get(TxOrUserOpMetadataKey_analyticsId) ?? '';
    const txSource = firstRecordedTx.metadata.get(TxOrUserOpMetadataKey_txSource) ?? '';
    const chain = firstRecordedTx.network.asChain();

    const isFirstPartyRPC =
      EthereumNetworkMap.isWhitelisted(chain?.chainId.toString() ?? '-1') &&
      !EthereumNetworkMap.isWhiteListedAndCustomized(chain?.chainId.toString() ?? '-1');

    /// This is an event used to calculate metrics shared in quarterly financial reports. Please
    /// take great care in modifying its usage.
    logTxConfirmed({
      minutesToConfirm,
      secondsToConfirm,
      txSource: txSource.toLocaleString(),
      txHash,
      blockchain: firstRecordedTx.blockchain.rawValue,
      chainId: chain?.chainId,
      chainName: chain?.displayName,
      isContractDeploy: !firstRecordedTx.toAddress,
      analyticsId: analyticsId.toString(),
      networkFeeInCrypto: feeDecimal.toString(),
      networkFee: firstRecordedTx.fee.toString(),
      feeCurrencyCode: firstRecordedTx.feeCurrencyCode.code,
      feeCurrencyDecimal: firstRecordedTx.feeCurrencyDecimal.toString(),
      isFirstPartyRPC: isFirstPartyRPC.toString(),
      nonce: firstRecordedTx.nonce?.toString(),
      isSponsoredTx: firstRecordedTx.isSponsored(),
      isGaslessSwap: firstRecordedTx.isGaslessSwap(),
    });

    // Update all metadata markers
    for (const t of txList) {
      const newMetadata = t.metadata.toMutableMap();
      newMetadata.set(TxOrUserOpMetadataKey_hasRecordedConfirmEvent, true);
      let metadata = new TxOrUserOpMetadata(newMetadata);

      // If the transaction being updated was from HRT, we need to merge the metadata
      // from the HRT transaction with the metadata that was created at submission time, if any.
      const existingMetadata = txHashToMetadata.get(t.txHash);
      if (existingMetadata) {
        metadata = metadata.mergeMetadata(existingMetadata);
      }

      txToUpdateMetadata.push(
        new TxOrUserOp({
          id: t.id,
          createdAt: t.createdAt,
          confirmedAt: t.confirmedAt,
          blockchain: t.blockchain,
          currencyCode: t.currencyCode,
          feeCurrencyCode: t.feeCurrencyCode,
          feeCurrencyDecimal: t.feeCurrencyDecimal,
          toAddress: t.toAddress,
          toDomain: t.toDomain,
          fromAddress: t.fromAddress,
          fromDomain: t.fromDomain,
          amount: t.amount,
          fee: t.fee,
          state: t.state,
          metadata,
          network: t.network,
          accountId: t.accountId,
          walletIndex: t.walletIndex,
          txOrUserOpHash: t.txOrUserOpHash,
          userOpHash: t.userOpHash,
          txHash: t.txHash,
          isSent: t.isSent,
          contractAddress: t.contractAddress,
          tokenName: t.tokenName,
          tokenDecimal: t.tokenDecimal,
          walletId: t.walletId,
          nonce: t.nonce,
          pendingEthTxData: t.pendingEthTxData,
          pendingUTXOTxData: t.pendingUTXOTxData,
          type: t.type,
          transfers: t.transfers,
          deleted: t.deleted,
          isSpam: t.isSpam,
          primaryAction: t.primaryAction,
          toAssetHistoricalPrice: t.toAssetHistoricalPrice,
          fromAssetHistoricalPrice: t.fromAssetHistoricalPrice,
          nativeAssetHistoricalPrice: t.nativeAssetHistoricalPrice,
          fromAssetImage: t.fromAssetImage,
          toAssetImage: t.toAssetImage,
          fromProfileImage: t.fromProfileImage,
          toProfileImage: t.toProfileImage,
          fromAmount: t.fromAmount,
          toAmount: t.toAmount,
          isContractExecution: t.isContractExecution,
          toNetwork: t.toNetwork,
          toCurrencyCode: t.toCurrencyCode,
          toContractAddress: t.toContractAddress,
          toTokenName: t.toTokenName,
          toTokenDecimal: t.toTokenDecimal,
          coinbaseFeeAmount: t.coinbaseFeeAmount,
          coinbaseFeeDecimal: t.coinbaseFeeDecimal,
          coinbaseFeeAssetAddress: t.coinbaseFeeAssetAddress,
          coinbaseFeeCurrencyCode: t.coinbaseFeeCurrencyCode,
          coinbaseFeeName: t.coinbaseFeeName,
          protocolFeeAmount: t.protocolFeeAmount,
          protocolFeeDecimal: t.protocolFeeDecimal,
          protocolFeeCurrencyCode: t.protocolFeeCurrencyCode,
          protocolFeeName: t.protocolFeeName,
          toWalletId: t.toWalletId,
          fromTokenId: t.fromTokenId,
          toTokenId: t.toTokenId,
          source: TxOrUserOp.inferTxSource(t.type, t.source, t.state, t.blockchain, t.confirmedAt),
        } as TxOrUserOpParams),
      );
    }
  }

  await persistTxOrUserOps(txToUpdateMetadata);
}

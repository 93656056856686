/* eslint-disable no-process-env */
import { CSSProperties, useCallback } from 'react';
import { Box, Divider, VStack } from '@cbhq/cds-web/layout';
import { Sidebar } from '@cbhq/cds-web/navigation/Sidebar';
import { Link, TextLegal } from '@cbhq/cds-web/typography';
import { PRODUCTION_RELEASE } from ':dapp/config/env';
import { Logo } from '../AppLogo/Logo';
import { useIsSideNavbarCollapsed } from './hooks/useIsSideNavbarCollapsed';
import { useSideNavbarConfig } from './hooks/useSideNavbarConfig';
import { GlobalActionButtons } from './GlobalActionButtons';
import { SideNavbarItem } from './SideNavbarItem';
import { SideNavbarToggle } from './SideNavbarToggle';
const overflowOverrides: Partial<CSSProperties> = {
  overflowY: 'clip'
};
const linkToCodeFlow = 'https://codeflow.cbhq.net/#/wallet/wallet-mobile/commits';
export function SideNavbar() {
  const sidebarItems = useSideNavbarConfig();
  const {
    isCollapsed,
    toggleCollapsed
  } = useIsSideNavbarCollapsed();
  const appVersion = process.env.APP_VERSION;
  const commitSHA = process.env.APP_COMMIT_SHA;
  const renderEnd = useCallback(() => {
    return <VStack width="100%">
        <SideNavbarToggle isToggled={isCollapsed} onToggle={toggleCollapsed} />
        {!PRODUCTION_RELEASE && <Box spacingHorizontal={2} spacingTop={2}>
            <Link to={`${linkToCodeFlow}/${commitSHA}`} color="foregroundMuted">
              <TextLegal as="p" color="foregroundMuted">{`Version: ${appVersion}`}</TextLegal>
            </Link>
          </Box>}
      </VStack>;
  }, [appVersion, commitSHA, toggleCollapsed, isCollapsed]);
  return <Box height="100vh" position="sticky" top="0" style={overflowOverrides}>
      <Sidebar testID={`side-navbar-${isCollapsed ? 'collapsed' : 'expanded'}`} logo={<Logo />} renderEnd={renderEnd} autoCollapse collapsed={isCollapsed}>
        {sidebarItems.map(props => <SideNavbarItem {...props} key={props.title} />)}
        <Divider spacingVertical={4} className={"dsok1cc"} />
        <GlobalActionButtons isSideNavbarCollapsed={isCollapsed} />
      </Sidebar>
    </Box>;
}

require("./SideNavbar.linaria.module.css!=!../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./SideNavbar.tsx");
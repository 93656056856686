import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { messages } from 'cb-wallet-data/stores/SettingsRedesign/messages/coinbaseConnect';
import {
  SettingCategory,
  SettingCategoryConfig,
} from 'cb-wallet-data/stores/SettingsRedesign/types';
import { useRouter } from 'next/router';

import { SettingRoutesEnum } from ':dapp/utils/RoutesEnum';

import { SETTING_CATEGORY_INNER_SPACING } from '../constants';

export function useCoinbaseConnectConfig(): SettingCategoryConfig {
  const { formatMessage } = useIntl();
  const router = useRouter();

  const onPress = useCallback(() => {
    router.push(SettingRoutesEnum.COINBASE_CONNECT);
  }, [router]);

  return {
    title: formatMessage(messages.title),
    description: formatMessage(messages.description),
    media: SettingCategory.COINBASE_CONNECT,
    accessory: 'arrow',
    testID: `${SettingCategory.COINBASE_CONNECT}-setting`,
    navigationPath: SettingRoutesEnum.COINBASE_CONNECT,
    innerSpacing: SETTING_CATEGORY_INNER_SPACING,
    onPress,
  };
}

import Avatar0 from './Avatar-1.svg';
import Avatar1 from './Avatar-2.svg';
import Avatar2 from './Avatar-3.svg';
import Avatar3 from './Avatar-4.svg';
import Avatar4 from './Avatar-5.svg';
import Avatar5 from './Avatar-6.svg';
import Avatar6 from './Avatar-7.svg';
import Avatar7 from './Avatar-8.svg';
import Avatar8 from './Avatar-9.svg';
import Avatar9 from './Avatar-10.svg';
import Avatar10 from './Avatar-11.svg';
import Avatar11 from './Avatar-12.svg';
import Avatar12 from './Avatar-13.svg';
import Avatar13 from './Avatar-14.svg';
import Avatar14 from './Avatar-15.svg';
import Avatar15 from './Avatar-16.svg';
import Avatar16 from './Avatar-17.svg';
import Avatar17 from './Avatar-18.svg';
import Avatar18 from './Avatar-19.svg';
import Avatar19 from './Avatar-20.svg';
import Avatar20 from './Avatar-21.svg';
import Avatar21 from './Avatar-22.svg';
import Avatar22 from './Avatar-23.svg';
import Avatar23 from './Avatar-24.svg';
import Avatar24 from './Avatar-25.svg';
import Avatar25 from './Avatar-26.svg';
import Avatar26 from './Avatar-27.svg';
import Avatar27 from './Avatar-28.svg';
import Avatar28 from './Avatar-29.svg';
import Avatar29 from './Avatar-30.svg';
import Avatar30 from './Avatar-31.svg';
import Avatar31 from './Avatar-32.svg';
import Avatar32 from './Avatar-33.svg';
import Avatar33 from './Avatar-34.svg';
import Avatar34 from './Avatar-35.svg';
import Avatar35 from './Avatar-36.svg';
import Avatar36 from './Avatar-37.svg';
import Avatar37 from './Avatar-38.svg';
import Avatar38 from './Avatar-39.svg';
import Avatar39 from './Avatar-40.svg';
import Avatar40 from './Avatar-41.svg';
import Avatar41 from './Avatar-42.svg';
import Avatar42 from './Avatar-43.svg';
import Avatar43 from './Avatar-44.svg';
import Avatar44 from './Avatar-45.svg';
import Avatar45 from './Avatar-46.svg';
import Avatar46 from './Avatar-47.svg';
import Avatar47 from './Avatar-48.svg';
import Avatar48 from './Avatar-49.svg';
import Avatar49 from './Avatar-50.svg';
import Avatar50 from './Avatar-51.svg';
import Avatar51 from './Avatar-52.svg';
import Avatar52 from './Avatar-53.svg';
import Avatar53 from './Avatar-54.svg';
import Avatar54 from './Avatar-55.svg';
import Avatar55 from './Avatar-56.svg';

type AvatarIconProps = {
  size: number;
  src: string;
  alt: string;
};

function AvatarIcon({ size, src, alt }: AvatarIconProps) {
  return <img alt={alt} height={size} width={size} src={src} />;
}

type AvatarProps = {
  size: number;
};
export const AvatarMap = {
  0: ({ size }: AvatarProps) => <AvatarIcon src={Avatar0} alt="Avatar0" size={size} />,
  1: ({ size }: AvatarProps) => <AvatarIcon src={Avatar1} alt="Avatar1" size={size} />,
  2: ({ size }: AvatarProps) => <AvatarIcon src={Avatar2} alt="Avatar2" size={size} />,
  3: ({ size }: AvatarProps) => <AvatarIcon src={Avatar3} alt="Avatar3" size={size} />,
  4: ({ size }: AvatarProps) => <AvatarIcon src={Avatar4} alt="Avatar4" size={size} />,
  5: ({ size }: AvatarProps) => <AvatarIcon src={Avatar5} alt="Avatar5" size={size} />,
  6: ({ size }: AvatarProps) => <AvatarIcon src={Avatar6} alt="Avatar6" size={size} />,
  7: ({ size }: AvatarProps) => <AvatarIcon src={Avatar7} alt="Avatar7" size={size} />,
  8: ({ size }: AvatarProps) => <AvatarIcon src={Avatar8} alt="Avatar8" size={size} />,
  9: ({ size }: AvatarProps) => <AvatarIcon src={Avatar9} alt="Avatar9" size={size} />,
  10: ({ size }: AvatarProps) => <AvatarIcon src={Avatar10} alt="Avatar10" size={size} />,
  11: ({ size }: AvatarProps) => <AvatarIcon src={Avatar11} alt="Avatar11" size={size} />,
  12: ({ size }: AvatarProps) => <AvatarIcon src={Avatar12} alt="Avatar12" size={size} />,
  13: ({ size }: AvatarProps) => <AvatarIcon src={Avatar13} alt="Avatar13" size={size} />,
  14: ({ size }: AvatarProps) => <AvatarIcon src={Avatar14} alt="Avatar14" size={size} />,
  15: ({ size }: AvatarProps) => <AvatarIcon src={Avatar15} alt="Avatar15" size={size} />,
  16: ({ size }: AvatarProps) => <AvatarIcon src={Avatar16} alt="Avatar16" size={size} />,
  17: ({ size }: AvatarProps) => <AvatarIcon src={Avatar17} alt="Avatar17" size={size} />,
  18: ({ size }: AvatarProps) => <AvatarIcon src={Avatar18} alt="Avatar18" size={size} />,
  19: ({ size }: AvatarProps) => <AvatarIcon src={Avatar19} alt="Avatar19" size={size} />,
  20: ({ size }: AvatarProps) => <AvatarIcon src={Avatar20} alt="Avatar20" size={size} />,
  21: ({ size }: AvatarProps) => <AvatarIcon src={Avatar21} alt="Avatar21" size={size} />,
  22: ({ size }: AvatarProps) => <AvatarIcon src={Avatar22} alt="Avatar22" size={size} />,
  23: ({ size }: AvatarProps) => <AvatarIcon src={Avatar23} alt="Avatar23" size={size} />,
  24: ({ size }: AvatarProps) => <AvatarIcon src={Avatar24} alt="Avatar24" size={size} />,
  25: ({ size }: AvatarProps) => <AvatarIcon src={Avatar25} alt="Avatar25" size={size} />,
  26: ({ size }: AvatarProps) => <AvatarIcon src={Avatar26} alt="Avatar26" size={size} />,
  27: ({ size }: AvatarProps) => <AvatarIcon src={Avatar27} alt="Avatar27" size={size} />,
  28: ({ size }: AvatarProps) => <AvatarIcon src={Avatar28} alt="Avatar28" size={size} />,
  29: ({ size }: AvatarProps) => <AvatarIcon src={Avatar29} alt="Avatar29" size={size} />,
  30: ({ size }: AvatarProps) => <AvatarIcon src={Avatar30} alt="Avatar30" size={size} />,
  31: ({ size }: AvatarProps) => <AvatarIcon src={Avatar31} alt="Avatar31" size={size} />,
  32: ({ size }: AvatarProps) => <AvatarIcon src={Avatar32} alt="Avatar32" size={size} />,
  33: ({ size }: AvatarProps) => <AvatarIcon src={Avatar33} alt="Avatar33" size={size} />,
  34: ({ size }: AvatarProps) => <AvatarIcon src={Avatar34} alt="Avatar34" size={size} />,
  35: ({ size }: AvatarProps) => <AvatarIcon src={Avatar35} alt="Avatar35" size={size} />,
  36: ({ size }: AvatarProps) => <AvatarIcon src={Avatar36} alt="Avatar36" size={size} />,
  37: ({ size }: AvatarProps) => <AvatarIcon src={Avatar37} alt="Avatar37" size={size} />,
  38: ({ size }: AvatarProps) => <AvatarIcon src={Avatar38} alt="Avatar38" size={size} />,
  39: ({ size }: AvatarProps) => <AvatarIcon src={Avatar39} alt="Avatar39" size={size} />,
  40: ({ size }: AvatarProps) => <AvatarIcon src={Avatar40} alt="Avatar40" size={size} />,
  41: ({ size }: AvatarProps) => <AvatarIcon src={Avatar41} alt="Avatar41" size={size} />,
  42: ({ size }: AvatarProps) => <AvatarIcon src={Avatar42} alt="Avatar42" size={size} />,
  43: ({ size }: AvatarProps) => <AvatarIcon src={Avatar43} alt="Avatar43" size={size} />,
  44: ({ size }: AvatarProps) => <AvatarIcon src={Avatar44} alt="Avatar44" size={size} />,
  45: ({ size }: AvatarProps) => <AvatarIcon src={Avatar45} alt="Avatar45" size={size} />,
  46: ({ size }: AvatarProps) => <AvatarIcon src={Avatar46} alt="Avatar46" size={size} />,
  47: ({ size }: AvatarProps) => <AvatarIcon src={Avatar47} alt="Avatar47" size={size} />,
  48: ({ size }: AvatarProps) => <AvatarIcon src={Avatar48} alt="Avatar48" size={size} />,
  49: ({ size }: AvatarProps) => <AvatarIcon src={Avatar49} alt="Avatar49" size={size} />,
  50: ({ size }: AvatarProps) => <AvatarIcon src={Avatar50} alt="Avatar50" size={size} />,
  51: ({ size }: AvatarProps) => <AvatarIcon src={Avatar51} alt="Avatar51" size={size} />,
  52: ({ size }: AvatarProps) => <AvatarIcon src={Avatar52} alt="Avatar52" size={size} />,
  53: ({ size }: AvatarProps) => <AvatarIcon src={Avatar53} alt="Avatar53" size={size} />,
  54: ({ size }: AvatarProps) => <AvatarIcon src={Avatar54} alt="Avatar54" size={size} />,
  55: ({ size }: AvatarProps) => <AvatarIcon src={Avatar55} alt="Avatar55" size={size} />,
};

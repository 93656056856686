import dynamic from 'next/dynamic';

import { useGlobalDrawer } from '../GlobalDrawer/GlobalDrawerProvider';

import { SendStepFallback } from './SendStepFallback';

const SendFlow = dynamic(async () => import('./SendFlow').then((mod) => mod.SendFlow), {
  loading: SendStepFallback,
  ssr: false,
});

export function SendDrawer() {
  // TODO: pass query params to send flow to initialize the flow
  const { closeDrawer } = useGlobalDrawer();
  return <SendFlow onClose={closeDrawer} />;
}

import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import { Button } from 'wallet-cds-web/components/Button';
import { GradientButton } from 'wallet-cds-web/components/GradientButton';
import { CryptoWalletMiami } from 'wallet-cds-web/components/MiamiIllustrationWrapper/MiamiViceIllustrations/CryptoWalletMiami';
import { CellSpacing } from '@cbhq/cds-common';
import { ListCell } from '@cbhq/cds-web/cells';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextDisplay3, TextTitle2 } from '@cbhq/cds-web/typography';
import { useConnectScwCoinbase } from ':dapp/connection/hooks/useConnectScwCoinbase';
import { useIsMobile } from ':dapp/hooks/useIsMobile';
const messages = defineMessages({
  scwTitle: {
    defaultMessage: 'Get onchain faster and easier with a smart wallet',
    description: 'smart wallet promo card title'
  },
  scwCopy1: {
    defaultMessage: 'No app, extension, or download required',
    description: 'smart wallet value proposition copy 1'
  },
  scwCopy2: {
    defaultMessage: 'Instant wallet creation with passkeys',
    description: 'smart wallet value proposition copy 2'
  },
  scwConnectButton: {
    defaultMessage: 'Create smart wallet',
    description: 'smart wallet create button'
  },
  scwLearnMore: {
    defaultMessage: 'Learn more',
    description: 'smart wallet value button to smart wallet landing pge'
  }
});

/**
 * Smart Wallet promotional card on landing page
 */
export function SmartWalletPromo() {
  const router = useRouter();
  const {
    formatMessage
  } = useIntl();
  const {
    loading,
    handleConnect
  } = useConnectScwCoinbase();
  const isMobile = useIsMobile();
  const handleNavigateSmartWallet = useCallback(async () => router.push('/smart-wallet'), [router]);
  const HeaderText = isMobile ? TextTitle2 : TextDisplay3;
  return <HStack width="100%" justifyContent="center" spacing={3} className={promoContainer}>
      <HStack spacing={6} borderRadius="roundedLarge" className="promo-scw-card">
        <HStack className="promo-scw" alignItems="center" justifyContent="flex-start" gap={5} flexWrap="wrap">
          <CryptoWalletMiami />
          <VStack gap={4} maxWidth={600} className="promo-scw-container">
            <HeaderText as="h3" className="promo-scw-content">
              {formatMessage(messages.scwTitle)}
            </HeaderText>
            <VStack>
              <ListCell compact innerSpacing={0 as CellSpacing} outerSpacing={0 as CellSpacing} media={<Box dangerouslySetBackground="#fff" borderRadius="roundedFull" spacing={1}>
                    <Icon color="secondary" name="checkmark" size="xs" />
                  </Box>} title={formatMessage(messages.scwCopy1)} />
              <ListCell compact innerSpacing={0 as CellSpacing} outerSpacing={0 as CellSpacing} media={<Box dangerouslySetBackground="#fff" borderRadius="roundedFull" spacing={1}>
                    <Icon color="secondary" name="checkmark" size="xs" />
                  </Box>} title={formatMessage(messages.scwCopy2)} />
            </VStack>
            <HStack gap={2} className={btnGroup}>
              <HStack className="scw-cta">
                <GradientButton loading={loading} variant="secondary" block onPress={handleConnect}>
                  {formatMessage(messages.scwConnectButton)}
                </GradientButton>
              </HStack>
              <Button transparent variant="secondary" onPress={handleNavigateSmartWallet}>
                <span className="learn-more-cta-text">{formatMessage(messages.scwLearnMore)}</span>
              </Button>
            </HStack>
          </VStack>
        </HStack>
      </HStack>
    </HStack>;
}
const promoContainer = "p121g545";
const btnGroup = "bak0tl6";

require("./SmartWalletPromo.linaria.module.css!=!../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./SmartWalletPromo.tsx");
import { EthereumBlockchain } from 'cb-wallet-data/chains/AccountBased/Ethereum/config';
import { ETHEREUM_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { EthereumChain } from 'cb-wallet-data/chains/AccountBased/Ethereum/EthereumChain';
import {
  SolanaBlockchain,
  SolanaNetworkSetting,
} from 'cb-wallet-data/chains/AccountBased/Solana/config';
import { SOLANA_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Solana/constants';
import { PossibleAccountBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { parseEtherscanTransaction } from 'cb-wallet-data/stores/Transactions/interfaces/EtherscanParser';
import { parseHRTTransaction } from 'cb-wallet-data/stores/Transactions/methods/parseHRTTransaction';
import { parseTransaction } from 'cb-wallet-data/stores/Transactions/methods/parseTransaction';
import { TxOrUserOp } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import {
  EthereumAddressHistory,
  EthereumTransaction,
  EtherscanResult,
  SolanaAddressHistory,
  SolanaTransaction,
  V1AddressMeta,
  V1SpamScoreThresholds,
  V2SpamScoreThresholds,
  V2Transaction,
} from 'wallet-engine-signing/history';
import { V1Transaction } from '@cbhq/instant-api-hooks-wallet-tx-history/types';

export function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

type ParseTransactionsMetadata<TxHistoryVersion> = {
  blockchainSymbol: PossibleAccountBlockchainSymbol;
  address: string;
  network: Network;
  accountId: string;
  spamScoreThresholds: V2SpamScoreThresholds | V1SpamScoreThresholds;
  walletIndex: bigint;
  txHistoryVersion: TxHistoryVersion;
  addressMetadata: Record<string, V1AddressMeta>;
};

// Each EthereumTransaction is converted into 1 or many `TxOrUserOp` items that can then be inserted into the
// `tx_or_userop_history` table
export async function parseTransactions(
  unsyncedTransactions: (EthereumTransaction | SolanaTransaction)[],
  metadata: ParseTransactionsMetadata<
    EthereumAddressHistory['txHistoryVersion'] | SolanaAddressHistory['txHistoryVersion']
  >,
): Promise<TxOrUserOp[]> {
  const parsedTxOrUserOps = await Promise.all(
    unsyncedTransactions.map(async function parseTx(rawTx) {
      if (metadata.blockchainSymbol === ETHEREUM_SYMBOL) {
        return parseEthereumTransaction(rawTx, metadata);
      }

      if (metadata.blockchainSymbol === SOLANA_SYMBOL) {
        return parseSolanaTransaction(
          rawTx as SolanaTransaction,
          metadata as ParseTransactionsMetadata<SolanaAddressHistory['txHistoryVersion']>,
        );
      }
    }),
  );

  return parsedTxOrUserOps.flat().filter(notEmpty<TxOrUserOp>);
}

export async function parseEthereumTransaction(
  rawTx: EthereumTransaction,
  metadata: ParseTransactionsMetadata<EthereumAddressHistory['txHistoryVersion']>,
): Promise<(TxOrUserOp | undefined)[]> {
  const {
    address,
    network,
    accountId,
    spamScoreThresholds,
    walletIndex,
    txHistoryVersion,
    addressMetadata,
  } = metadata;

  switch (txHistoryVersion) {
    case 'etherscan':
      return parseEtherscanTransaction({
        rawTx: rawTx as EtherscanResult,
        address,
        chain: network.asChain() as EthereumChain,
        accountId,
        walletIndex,
      }).then((txOrUserOp) => [txOrUserOp]);
    case 'v3':
      return parseHRTTransaction({
        tx: rawTx as V1Transaction,
        userAddress: address,
        blockchain: EthereumBlockchain,
        network,
        accountId,
        walletIndex,
        spamScoreThresholds: spamScoreThresholds as V1SpamScoreThresholds,
        addressMetadata,
      });
    case 'v2':
      return parseTransaction({
        tx: rawTx as V2Transaction,
        blockchain: EthereumBlockchain,
        network,
        accountId,
        walletIndex,
        spamScoreThresholds: spamScoreThresholds as V2SpamScoreThresholds,
      }).then((txOrUserOp) => [txOrUserOp]);
    default: {
      const _exhaustiveCheck: never = txHistoryVersion;
      return _exhaustiveCheck;
    }
  }
}

export async function parseSolanaTransaction(
  rawTx: SolanaTransaction,
  metadata: ParseTransactionsMetadata<SolanaAddressHistory['txHistoryVersion']>,
): Promise<(TxOrUserOp | undefined)[]> {
  const {
    accountId,
    address,
    addressMetadata,
    spamScoreThresholds,
    walletIndex,
    txHistoryVersion,
  } = metadata;

  switch (txHistoryVersion) {
    case 'v2':
      return parseTransaction({
        blockchain: SolanaBlockchain,
        network: SolanaNetworkSetting.defaultMainnet.network,
        accountId,
        walletIndex,
        tx: rawTx as V2Transaction,
      }).then((txOrUserOp) => [txOrUserOp]);
    case 'v3':
      return parseHRTTransaction({
        tx: rawTx as V1Transaction,
        userAddress: address,
        blockchain: SolanaBlockchain,
        network: SolanaNetworkSetting.defaultMainnet.network,
        accountId,
        walletIndex,
        spamScoreThresholds,
        addressMetadata,
      });
    default: {
      const _exhaustiveCheck: never = txHistoryVersion;
      return _exhaustiveCheck;
    }
  }
}

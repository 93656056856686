import { useEffect, useMemo } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { useBalanceRefreshIndicator } from 'cb-wallet-data/AddressHistory/hooks/useBalanceRefreshIndicator';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { useRefetcher } from 'cb-wallet-data/hooks/useRefetcher';
import { useStableQueries } from 'cb-wallet-data/hooks/useStableQueries';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { useActiveFiatCurrency } from 'cb-wallet-data/stores/ActiveFiatCurrency/hooks/useActiveFiatCurrency';
import { FiatCurrency } from 'cb-wallet-data/stores/ActiveFiatCurrency/models/FiatCurrency';
import { fiatCodesWithAlts } from 'cb-wallet-data/stores/Currencies/LocaleCurrencies';
import { useCustomTrackedExchangeRates } from 'cb-wallet-data/stores/ExchangeRates/hooks/useAddTrackedExchangeRate';
import { useWalletsForWalletGroupIds } from 'cb-wallet-data/stores/Wallets/hooks/useWalletsForWalletGroupIds';
import { hash } from 'cb-wallet-data/utils/hash';
import { emptyArray } from 'cb-wallet-data/utils/stableObjects';
import { Decimal } from 'decimal.js';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  CryptoExchangeRate,
  fetchCryptoExchangeRates,
  fetchFiatExchangeRates,
  FiatExchangeRate,
  paramsForWallets,
} from '../api';
import { exchangeRatesAtom, exchangeRatesMapSelector } from '../state';
import { ExchangeRate } from '../types';
import { encodeCurrencyCodes } from '../utils';

import { useCanFetchExchangeRates } from './useCanFetchExchangeRates';
import { CryptoAssetQuotesQuery, useMemoizedExchangeRateData } from './useMemoizedExchangeRateData';
import { useOverriddenExchangeRates } from './useOverriddenExchangeRates';

// Whenever we modify the types we are returning from the API calls, we need to update the query keys as well.
// Not doing so will result in react query grabbing outdated cached data, which will likely cause processing errors.
export const FIAT_QUOTE_QUERY_KEY = 'getExchangeRates4';
export const CRYPTO_QUOTE_QUERY_KEY = 'getAssetQuotes4';

const options = {
  suspense: false, // This one does NOT suspend because we add Wallets a bunch at runtime
  useErrorBoundary: false, // Do not re-throw errors from background fetches
  staleTime: Infinity, // We use useRefetcher hook to fetch al queries at the same time
  keepPreviousData: true,
  notifyOnChangeProps: ['data', 'isFetching', 'isInitialLoading'] as (
    | 'data'
    | 'isFetching'
    | 'isInitialLoading'
  )[],
};

export function useRefreshExchangeRates(walletGroupIds: (string | undefined)[]) {
  // We wait to fetch exchange rates to improve cold start perf, unless there are no cached rates
  const canFetchExchangeRates = useCanFetchExchangeRates();

  // Queries for crypto assets
  const wallets = useWalletsForWalletGroupIds(walletGroupIds);
  const customTrackedExchangeRates = useCustomTrackedExchangeRates();
  const setExchangeRates = useSetRecoilState(exchangeRatesAtom);
  const { setExchangeRatesUpdating } = useBalanceRefreshIndicator();

  const cryptoAssetQuotesQueries = useMemo(() => {
    // We already have exchangeRates from cache let's wait until the app is started
    if (!canFetchExchangeRates) {
      return emptyArray as CryptoAssetQuotesQuery[];
    }
    return paramsForWallets(wallets, customTrackedExchangeRates).map((param) => {
      return {
        queryKey: [CRYPTO_QUOTE_QUERY_KEY, hash(JSON.stringify(param))],
        queryFn: async () => fetchCryptoExchangeRates(param),
        enabled: true,
        ...options,
      };
    });
  }, [customTrackedExchangeRates, wallets, canFetchExchangeRates]);

  // The query for fiat exchange rates
  const activeFiatCurrency = useActiveFiatCurrency();
  const fiatCodes = useMemo(() => {
    /**
     * Fetch USD exchange rates for all currencies in order to convert to USD
     * @see useActiveAccountBalanceInUSD
     */
    if (fiatCodesWithAlts[activeFiatCurrency.code.code]) {
      const altFiatCurrency = new CurrencyCode(fiatCodesWithAlts[activeFiatCurrency.code.code]);
      return encodeCurrencyCodes([activeFiatCurrency.code, altFiatCurrency, FiatCurrency.USD.code]);
    }

    return encodeCurrencyCodes([activeFiatCurrency.code, FiatCurrency.USD.code]);
  }, [activeFiatCurrency.code]);

  const fiatRateQuery = useMemo(() => {
    return {
      queryKey: [FIAT_QUOTE_QUERY_KEY, fiatCodes],
      queryFn: async () => fetchFiatExchangeRates(fiatCodes),
      enabled: canFetchExchangeRates,
      ...options,
    };
  }, [fiatCodes, canFetchExchangeRates]);

  // Run all of these queries at once, caching each individually
  // NOTE: These MUST return data that can serialize with JSON.stringify and parse!
  // Otherwise the cache WILL NOT WORK.

  const cryptoQuotesQueryResults = useStableQueries(cryptoAssetQuotesQueries);
  const {
    data: fiatRateData,
    isFetching: isFiatRateFetching,
    isInitialLoading: isFiatRateInitialLoading,
    refetch: refetchFiatRate,
  } = useQuery(fiatRateQuery);

  const cryptoRatesFetched = useMemo(
    () => cryptoQuotesQueryResults.some((result) => result.isFetched),
    [cryptoQuotesQueryResults],
  );

  const cryptoQuotesIsFetching = useMemo(
    () => cryptoQuotesQueryResults.some((result) => result.isFetching),
    [cryptoQuotesQueryResults],
  );

  const isFetching =
    cryptoQuotesIsFetching ||
    cryptoQuotesQueryResults.some((query) => query.isInitialLoading) ||
    isFiatRateFetching ||
    isFiatRateInitialLoading;

  useEffect(
    function setCryptoRatesFetchingComplete() {
      if (cryptoRatesFetched && !cryptoQuotesIsFetching) {
        setExchangeRatesUpdating(false);
      }
    },
    [cryptoRatesFetched, cryptoQuotesIsFetching, setExchangeRatesUpdating],
  );

  useRefetcher(
    [...cryptoQuotesQueryResults.map((result) => result.refetch), refetchFiatRate],
    canFetchExchangeRates,
    60 * 1000,
  );

  const memoizedResults = useMemoizedExchangeRateData({
    cryptoAssetQuotesQueries,
    fiatRateQuery,
    isFetching,
    fiatRateData,
    cryptoQuotesQueryResults,
    canFetchExchangeRates,
  });

  const fiatRatesWithOverrides = useOverriddenExchangeRates(memoizedResults.fiatRateData);

  const computedRates = useMemo(() => {
    return transformExchangeRates(
      memoizedResults.cryptoQuotesQueryResults,
      fiatRatesWithOverrides,
      activeFiatCurrency.code.code,
    );
  }, [
    activeFiatCurrency.code.code,
    fiatRatesWithOverrides,
    memoizedResults.cryptoQuotesQueryResults,
  ]);

  useEffect(
    function syncExchangeRates() {
      const cryptoRatesLoading = memoizedResults.cryptoQuotesQueryResults.some(
        (result) => result.isLoading,
      );

      // The fiat request resolves first since it doesn't wait for wallets
      // We don't want to set exchanges rates until we have crypto rates too
      if (computedRates && computedRates.length > 1 && !cryptoRatesLoading) {
        setExchangeRates(computedRates);
      }
    },
    [computedRates, setExchangeRates, memoizedResults],
  );
}

export function transformExchangeRates(
  cryptoRateQueryResults: Pick<UseQueryResult<CryptoExchangeRate[], unknown>, 'data'>[],
  fiatRateQueryResult: FiatExchangeRate[] | undefined,
  activeFiatCurrencySymbol: string,
) {
  if (!cryptoRateQueryResults?.length || !fiatRateQueryResult) return;

  const cryptoRateResults = cryptoRateQueryResults.flatMap((result) => result.data || []);

  const relativeRates = calculateRelativeRates({
    cryptoRateResults,
    fiatRateResults: fiatRateQueryResult,
    activeFiatCurrencySymbol,
  });

  if (!relativeRates) return;

  return relativeRates.cryptoExchangeRates.concat(relativeRates.fiatExchangeRates);
}

export function calculateRelativeRates({
  cryptoRateResults,
  fiatRateResults,
  activeFiatCurrencySymbol,
}: {
  cryptoRateResults: CryptoExchangeRate[] | undefined;
  fiatRateResults: FiatExchangeRate[] | undefined;
  activeFiatCurrencySymbol: string;
}): { cryptoExchangeRates: ExchangeRate[]; fiatExchangeRates: ExchangeRate[] } | undefined {
  if (!cryptoRateResults?.length || !fiatRateResults) return;

  // Get the active fiat currency's exchange rate relative to USD
  const activeFiatExchangeRate = fiatRateResults.find(
    (rate) => rate.currencyCode.toUpperCase() === activeFiatCurrencySymbol.toUpperCase(),
  );

  // If we don't have an activeFiatExchangeRate we cannot calculate the cryto exchange rates
  if (!activeFiatExchangeRate || Number(activeFiatExchangeRate.rateRelativeToUSD) === 0) {
    return;
  }

  const cryptoExchangeRates = cryptoRateResults.reduce(function processCryptoRates(
    acc,
    { currencyCode, rateRelativeToUSD, contractAddress, chainId, networkId },
  ) {
    if (currencyCode && rateRelativeToUSD) {
      acc.push({
        currencyCode: new CurrencyCode(currencyCode),
        rate: new Decimal(rateRelativeToUSD).dividedBy(
          new Decimal(activeFiatExchangeRate.rateRelativeToUSD),
        ),
        contractAddress,
        chainId: chainId ? BigInt(chainId) : undefined,
        networkId: networkId ?? undefined,
      } as ExchangeRate);
    }
    return acc;
  },
  [] as ExchangeRate[]);

  const fiatExchangeRates = fiatRateResults.map(({ currencyCode, rateRelativeToUSD }) => {
    return {
      currencyCode: new CurrencyCode(currencyCode),
      rate: new Decimal(rateRelativeToUSD).dividedBy(
        new Decimal(activeFiatExchangeRate.rateRelativeToUSD),
      ),
      contractAddress: undefined,
      chainId: undefined,
      networkId: undefined,
    } as ExchangeRate;
  });

  return { cryptoExchangeRates, fiatExchangeRates };
}

export function useExchangeRates(): ExchangeRate[] {
  return useRecoilValue(exchangeRatesAtom);
}

export function useExchangeRatesMap(): Record<string, Decimal> {
  return useRecoilValue(exchangeRatesMapSelector);
}

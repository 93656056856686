import { useCallback } from 'react';
import {
  DefiPosition,
  DefiPositionResponse,
  DefiPositionType,
} from 'cb-wallet-data/stores/DefiPortfolio/models/DefiPosition';

import { useParseAssetInfoResponse } from '../useParseAssetInfoResponse';

export function useParseProtocolPositionResponse() {
  const parseAssetInfoResponse = useParseAssetInfoResponse();

  return useCallback(
    function parseProtocolPositionResponse(
      defiPostionResponse: DefiPositionResponse,
      type: DefiPositionType,
    ): DefiPosition {
      return {
        assetInfo: parseAssetInfoResponse(defiPostionResponse.assetInfo),
        protocolName: defiPostionResponse.protocolName,
        protocolImage: defiPostionResponse.protocolImage,
        protocolId: defiPostionResponse.protocolId,
        walletAddress: defiPostionResponse.walletAddress,
        externalLink: defiPostionResponse.externalLink,
        type,
      };
    },
    [parseAssetInfoResponse],
  );
}

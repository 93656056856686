import { AssetInfo, AssetInfoResponse } from './AssetInfo';

export type BaseDefiPositionResponse = {
  protocolName: string;
  protocolImage: string;
  protocolId: string;
  walletAddress: string;
  externalLink: string;
};

export type DefiPositionResponse = {
  assetInfo: AssetInfoResponse;
} & BaseDefiPositionResponse;

export type LiquidityPoolPositionResponse = {
  assetInfos: AssetInfoResponse[];
} & BaseDefiPositionResponse;

export enum DefiPositionType {
  Lending = 'lending',
  LiquidityPool = 'liquidityPool',
  Staked = 'staked',
  Locked = 'locked',
  Claimable = 'claimable',
  Vesting = 'vesting',
  Farming = 'farming',
  LeveragedFarming = 'leveragedFarming',
  Yield = 'yield',
  YieldFarming = 'yieldFarming',
  Deposits = 'deposits',
  Borrow = 'borrow',
  Rewards = 'rewards',
}

export type BaseDefiPosition = {
  protocolName: string;
  protocolImage: string;
  protocolId: string;
  walletAddress: string;
  type: DefiPositionType;
  externalLink: string;
};

export type DefiPosition = {
  assetInfo: AssetInfo;
} & BaseDefiPosition;

export type LiquidityPoolPosition = {
  assetInfos: AssetInfo[];
} & BaseDefiPosition;

export type DefiPositionRow = DefiPosition | LiquidityPoolPosition;

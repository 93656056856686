import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  AllPossibleBlockchainSymbol,
  blockchainConfigurations,
} from 'cb-wallet-data/chains/blockchains';
import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import {
  AccountWithBalance,
  useAccountsWithBalances,
} from 'cb-wallet-data/stores/Accounts/hooks/useAccountsWithBalances';
import { useActiveFiatCurrency } from 'cb-wallet-data/stores/ActiveFiatCurrency/hooks/useActiveFiatCurrency';
import { FiatCurrency } from 'cb-wallet-data/stores/ActiveFiatCurrency/models/FiatCurrency';
import { useOnrampAssets } from 'cb-wallet-data/stores/Buy/hooks/useOnrampAssets';
import { useOnrampCheckoutSession } from 'cb-wallet-data/stores/Buy/hooks/useOnrampCheckoutSession';
import { useOnrampDefaultOptions } from 'cb-wallet-data/stores/Buy/hooks/useOnrampDefaultOptions';
import { useOnrampNetworks } from 'cb-wallet-data/stores/Buy/hooks/useOnrampNetworks';
import {
  OnrampPaymentMethods,
  useOnrampPaymentMethods,
} from 'cb-wallet-data/stores/Buy/hooks/useOnrampPaymentMethods';
import { OnrampAsset } from 'cb-wallet-data/stores/Buy/models/OnrampAsset';
import { OnrampCheckoutSession } from 'cb-wallet-data/stores/Buy/models/OnrampCheckoutSession';
import {
  useManuallyChangedActiveFiatCurrency,
  useOnrampFiatCurrency,
  useSetOnrampFiatCurrency,
} from 'cb-wallet-data/stores/Buy/state';
import { OnrampFiat } from 'cb-wallet-data/stores/Buy/types/OnrampFiat';
import { OnrampNetwork } from 'cb-wallet-data/stores/Buy/types/OnrampNetwork';
import { OnrampPaymentMethod } from 'cb-wallet-data/stores/Buy/types/OnrampPaymentMethod';
import { OnrampProvider } from 'cb-wallet-data/stores/Buy/types/OnrampProvider';
import { useLocalFiatCode } from 'cb-wallet-data/stores/Currencies/hooks/useLocalFiatCode';
import { locationDebugOverridesAtom } from 'cb-wallet-data/stores/User/state';
import { useActiveWalletGroupId } from 'cb-wallet-data/stores/WalletGroups/hooks/useActiveWalletGroupId';
import { usePrimaryReceiveAddresses } from 'cb-wallet-data/stores/Wallets/hooks/usePrimaryReceiveAddresses';
import { useRecoilValue } from 'recoil';
import { SanitizedBigDecimal } from 'wallet-engine-signing/util/SanitizedBigDecimal';
import { useToggler } from '@cbhq/cds-common';

export type PanoOnrampContextProps = {
  amount: string;
  sanitizedAmount: string;
  fiatCurrency: FiatCurrency;
  assets: OnrampAsset[] | undefined;
  networks: OnrampNetwork[] | undefined;
  paymentMethods: OnrampPaymentMethods | undefined;
  isLoadingPaymentMethods?: boolean;
  isLoadingPaymentProviderQuery?: boolean;
  checkoutSession: OnrampCheckoutSession | undefined;
  sourceWallet: AccountWithBalance | undefined;
  availableFiats: OnrampFiat[];
  countryCode?: string;
  subdivisionCode?: string;
  updateOnrampInfo: (newValue: Partial<PanoOnrampContextValue>) => void;
  selectedAsset: OnrampAsset | undefined;
  setSelectedAsset: (newAsset: OnrampAsset | undefined) => void;
  selectedPaymentMethod: OnrampPaymentMethod | undefined;
  setSelectedPaymentMethod: (newPaymentMethod: OnrampPaymentMethod | undefined) => void;
  getCheckoutSessionAsync: (
    quoteId: string | undefined,
  ) => Promise<OnrampCheckoutSession | undefined>;
  selectedProvider: OnrampProvider | undefined;
  setSelectedProvider: (newProvider: OnrampProvider | undefined) => void;
  setSelectedWallet: (newWallet: AccountWithBalance | undefined) => void;
  setOnrampFiatCurrency: (newCurrency: OnrampFiat | undefined) => void;
  isExternalProviderWebviewVisible: boolean;
  toggleIsExternalProviderWebviewVisible: () => void;
  is3rdPartyLegalNoticeModalVisible: boolean;
  toggleIs3rdPartyLegalNoticeModalVisible: () => void;
  onrampErrorMessage?: string;
  setOnrampErrorMessage: (onrampErrorMessage?: string) => void;
};

type PanoOnrampContextValue = Omit<
  PanoOnrampContextProps,
  | 'onError'
  | 'onSuccess'
  | 'setOnrampErrorMessage'
  | 'updateOnrampInfo'
  | 'setSelectedAsset'
  | 'getCheckoutSessionAsync'
  | 'setSelectedProvider'
  | 'setSelectedWallet'
  | 'setOnrampFiatCurrency'
  | 'setSelectedPaymentMethod'
  | 'toggleIsExternalProviderWebviewVisible'
  | 'toggleIs3rdPartyLegalNoticeModalVisible'
>;

export const panoOnrampContextInitialValue: PanoOnrampContextValue = {
  amount: '0',
  sanitizedAmount: '0',
  availableFiats: [],
  fiatCurrency: new FiatCurrency(CurrencyCode.USD, 'Dollar', 2n),
  assets: undefined,
  networks: undefined,
  onrampErrorMessage: undefined,
  sourceWallet: undefined,
  selectedAsset: undefined,
  paymentMethods: undefined,
  checkoutSession: undefined,
  selectedProvider: undefined,
  selectedPaymentMethod: undefined,
  isLoadingPaymentProviderQuery: false,
  isExternalProviderWebviewVisible: false,
  is3rdPartyLegalNoticeModalVisible: false,
};

const defaultValues: PanoOnrampContextProps = {
  ...panoOnrampContextInitialValue,
  setSelectedAsset: (_: OnrampAsset | undefined) => {
    throw new Error('setSelectedAsset cannot be called before PanoOnrampContext initialization.');
  },
  setSelectedPaymentMethod: (_: OnrampPaymentMethod | undefined) => {
    throw new Error(
      'setSelectedPaymentMethod cannot be called before PanoOnrampContext initialization.',
    );
  },
  setSelectedProvider: (_: OnrampProvider | undefined) => {
    throw new Error(
      'setSelectedProvider cannot be called before PanoOnrampContext initialization.',
    );
  },
  setSelectedWallet: (_: AccountWithBalance | undefined) => {
    throw new Error('setSelectedWallet cannot be called before PanoOnrampContext initialization.');
  },
  setOnrampFiatCurrency: (_: OnrampFiat | undefined) => {
    throw new Error(
      'setOnrampFiatCurrency cannot be called before PanoOnrampContext initialization.',
    );
  },
  getCheckoutSessionAsync: (_: string | undefined) => {
    throw new Error(
      'getCheckoutSessionAsync cannot be called before PanoOnrampContext initialization.',
    );
  },
  updateOnrampInfo: (_: Partial<PanoOnrampContextValue>) => {
    throw new Error('updateOnrampInfo cannot be called before PanoOnrampContext initialization.');
  },
  toggleIsExternalProviderWebviewVisible: () => {
    throw new Error(
      'toggleIsExternalProviderWebviewVisible cannot be called before PanoOnrampContext initialization.',
    );
  },
  toggleIs3rdPartyLegalNoticeModalVisible: () => {
    throw new Error(
      'toggleIs3rdPartyLegalNoticeModalVisible cannot be called before PanoOnrampContext initialization.',
    );
  },
  setOnrampErrorMessage: () => {
    throw new Error(
      'setOnrampErrorMessage cannot be called before PanoOnrampContext initialization.',
    );
  },
};

export const PanoOnrampContext = createContext(defaultValues);

type Props = {
  children: ReactNode;
  initialValues?: Partial<PanoOnrampContextValue>;
};

export function PanoOnrampContextProvider({ children, initialValues }: Props) {
  const activeFiatCurrency = useActiveFiatCurrency();
  const hasManuallyChangedActiveFiatCurrency = useManuallyChangedActiveFiatCurrency();

  const amount = '0';
  const chainId = 1;
  const currencyCode = 'BRL';
  const countryCode = 'BR';
  const subdivisionCode = '';

  const [isExternalProviderWebviewVisible, { toggle: toggleIsExternalProviderWebviewVisible }] =
    useToggler(false);

  const [is3rdPartyLegalNoticeModalVisible, { toggle: toggleIs3rdPartyLegalNoticeModalVisible }] =
    useToggler(false);

  const [onrampInfo, setOnrampInfo] = useState<PanoOnrampContextValue>(
    panoOnrampContextInitialValue,
  );

  const [onrampErrorMessage, setOnrampErrorMessage] =
    useState<PanoOnrampContextProps['onrampErrorMessage']>(undefined);

  const isConnected = true;

  const onrampFiatCurrency = useOnrampFiatCurrency();
  const setOnrampFiatCurrency = useSetOnrampFiatCurrency();

  const { isLocationOverrideEnabled, countryCode: countryCodeOverride } = useRecoilValue(
    locationDebugOverridesAtom,
  );

  const activeWalletGroupId = useActiveWalletGroupId();

  // local fiat code based on the geo location
  const localFiatCode = useLocalFiatCode({
    countryCodeOverride: isLocationOverrideEnabled ? countryCodeOverride : undefined,
  });

  const isOnrampCurrencySelectorEnabled = false; // useIsFeatureEnabled('onramp_currency_selector');

  const fiatCurrencyForDefaultOptionsParam = useMemo(() => {
    if (!onrampFiatCurrency || !isOnrampCurrencySelectorEnabled) {
      if (!localFiatCode || hasManuallyChangedActiveFiatCurrency) {
        return activeFiatCurrency;
      }

      return new FiatCurrency(
        new CurrencyCode(localFiatCode.fiatCode),
        localFiatCode.name,
        BigInt(localFiatCode.decimals),
      );
    }

    const selectedCurrencyCode = new CurrencyCode(onrampFiatCurrency.code);

    return new FiatCurrency(
      selectedCurrencyCode,
      onrampFiatCurrency.name,
      BigInt(onrampFiatCurrency.decimals),
    );
  }, [
    activeFiatCurrency,
    hasManuallyChangedActiveFiatCurrency,
    isOnrampCurrencySelectorEnabled,
    localFiatCode,
    onrampFiatCurrency,
  ]);

  const defaultOptions = useOnrampDefaultOptions({
    isConnected,
    fiatCode: fiatCurrencyForDefaultOptionsParam.code.code,
    suspense: onrampFiatCurrency === undefined, // only suspend if we don't have a selected onramp fiat currency
    countryCode,
    subdivisionCode,
  });

  const fiatCurrency = useMemo(() => {
    if (!defaultOptions?.fiat) {
      return fiatCurrencyForDefaultOptionsParam;
    }

    return new FiatCurrency(
      new CurrencyCode(defaultOptions?.fiat.code),
      defaultOptions?.fiat.name,
      BigInt(defaultOptions?.fiat.decimals),
    );
  }, [defaultOptions?.fiat, fiatCurrencyForDefaultOptionsParam]);

  const { data: assets, isLoading: isLoadingAssets } = useOnrampAssets({
    fiatCode: fiatCurrency.code.code,
    countryCode,
    subdivisionCode,
    walletGroupId: undefined,
  });

  const { data: networks, isLoading: isLoadingNetworks } = useOnrampNetworks({
    fiatCode: fiatCurrency.code.code,
    countryCode,
    subdivisionCode,
    walletGroupId: undefined,
  });

  // We need to sanitize it based on the app locale to be able to parse the number correctly
  // Otherwise, it'd fail for fiat currencies like BRL
  // In other flows (like Swap) it works fine because they handle the amount differently using
  // crypto amount; which is not supported by the aggregator API.
  const sanitizedAmount = useMemo(() => {
    return SanitizedBigDecimal.create(onrampInfo.amount, 'en').toString();
  }, [onrampInfo.amount]);

  const primaryReceiveAddressess = usePrimaryReceiveAddresses(activeWalletGroupId);
  const receiveAddress = useMemo(() => {
    const addressFromBlockchainSymbol = primaryReceiveAddressess.get(
      onrampInfo.selectedAsset?.networkLegacyCode as AllPossibleBlockchainSymbol,
    )?.address;

    const isEVMAsset = blockchainConfigurations.ETH.networkSetting.allNetworks.some(
      (evmNetwork) => evmNetwork.network.asChain()?.chainId === onrampInfo.selectedAsset?.chainId,
    );

    if (isEVMAsset) {
      return primaryReceiveAddressess.get('ETH')?.address;
    }

    if (onrampInfo.selectedAsset && !addressFromBlockchainSymbol && !isEVMAsset) {
      cbReportError({
        error: new Error('receiveAddress is required'),
        context: 'buy',
        severity: 'warning',
        isHandled: true,
      });
    }

    return addressFromBlockchainSymbol;
  }, [onrampInfo.selectedAsset, primaryReceiveAddressess]);

  const {
    checkoutSession,
    getCheckoutSessionAsync,
    reset: resetCheckoutSession,
  } = useOnrampCheckoutSession({
    receiveAddress,
    fiatAmount: sanitizedAmount,
    assetCode: onrampInfo.selectedAsset?.code ?? '',
    networkId: onrampInfo.selectedAsset?.networkId ?? '',
    providerId: onrampInfo.selectedProvider?.id ?? '',
    contractAddress: onrampInfo.selectedAsset?.contractAddress ?? '',
    aggregatorId: onrampInfo.selectedProvider?.aggregatorId ?? '',
    paymentMethodId: onrampInfo.selectedPaymentMethod?.id ?? '',
    paymentMethodUuid: onrampInfo.selectedPaymentMethod?.uuid ?? '',
    fiatCode: fiatCurrency.code.code,
    countryCode,
    subdivisionCode,
  });

  const availableFiats = useMemo(() => defaultOptions?.availableFiats ?? [], [defaultOptions]);

  const handleUpdateOnrampInfo = useCallback(
    (newValues: Partial<PanoOnrampContextValue>) => {
      // We need to reset the checkout session here otherwise it'll persist the provider url
      // and use it even after changing one of the inputs (asset, network, provider, etc.)
      resetCheckoutSession();

      setOnrampInfo((prevOnrampInfo) => ({ ...prevOnrampInfo, ...newValues }));
    },
    [resetCheckoutSession],
  );

  const handleSetSelectedProvider = useCallback(
    (provider: OnrampProvider | undefined) => {
      // logOnrampProviderSelected(provider?.id);

      handleUpdateOnrampInfo({ selectedProvider: provider });
    },
    [handleUpdateOnrampInfo],
  );

  const handleSetSelectedWallet = useCallback(
    (wallet: AccountWithBalance | undefined) => {
      handleUpdateOnrampInfo({ sourceWallet: wallet });
    },
    [handleUpdateOnrampInfo],
  );

  const { paymentMethods, isLoading: isLoadingPaymentMethods } = useOnrampPaymentMethods({
    asset: onrampInfo.selectedAsset,
    fiatCode: fiatCurrency.code.code,
    countryCode,
    subdivisionCode,
    includeCoinbaseMgx: false,
    includeCoinbaseNative: false,
  });

  useEffect(
    function updateProviderAndPaymentMethod() {
      if (Object.keys(paymentMethods).length === 0 || !onrampInfo.selectedProvider) {
        return;
      }

      // fallback to the first provider that has at least one payment method enabled
      const fallbackProvider = Object.values(paymentMethods).find((p) =>
        p.paymentMethods.some((pm) => !pm.disabled),
      );

      const nextProviderWithFallback =
        paymentMethods[onrampInfo.selectedProvider.id] ?? fallbackProvider;

      // next payment method is the one that was selected before
      const nextPaymentMethod = nextProviderWithFallback.paymentMethods.find(
        (paymentMethod) => paymentMethod.id === onrampInfo.selectedPaymentMethod?.id,
      );

      // fallback to the first payment method that is not disabled
      const fallBackPaymentMethod = nextProviderWithFallback.paymentMethods.find(
        (pm) => !pm.disabled,
      );

      const nextPaymentMethodWithFallback = nextPaymentMethod ?? fallBackPaymentMethod;

      handleUpdateOnrampInfo({
        selectedProvider: nextProviderWithFallback.providerInfo,
        selectedPaymentMethod: nextPaymentMethodWithFallback,
      });
    },
    [
      handleUpdateOnrampInfo,
      onrampInfo.selectedAsset,
      onrampInfo.selectedPaymentMethod?.id,
      onrampInfo.selectedProvider,
      paymentMethods,
    ],
  );

  const accountsWithBalance = useAccountsWithBalances();
  const sourceWallet =
    onrampInfo.sourceWallet ||
    accountsWithBalance.filter((account) => account.primaryAddressChain === 'ETH')[0];

  const assetParam = useMemo(
    () =>
      currencyCode && chainId !== undefined
        ? assets?.find((asset) => asset.code === currencyCode && asset.chainId === chainId)
        : undefined,
    [assets, chainId, currencyCode],
  );

  const newValues = useMemo<Partial<PanoOnrampContextValue>>(() => {
    return {
      // If there's an asset that matches the route params, use it; otherwise, use default option.
      selectedAsset: assetParam ?? defaultOptions?.asset,
      selectedPaymentMethod: defaultOptions?.paymentMethod,
      selectedProvider: defaultOptions?.provider,
      ...(amount || defaultOptions?.fiat.amount
        ? { amount: String(amount || defaultOptions?.fiat.amount) }
        : undefined),
    };
  }, [
    amount,
    assetParam,
    defaultOptions?.asset,
    defaultOptions?.fiat.amount,
    defaultOptions?.paymentMethod,
    defaultOptions?.provider,
  ]);

  useEffect(
    function updateOnrampInfo() {
      /**
       * we wanna make sure we're not updating the onramp info
       * if the assets request is still loading
       */
      if (!isLoadingAssets) {
        handleUpdateOnrampInfo(newValues);
      }
    },
    [isLoadingAssets, newValues, handleUpdateOnrampInfo],
  );

  const handleSetSelectedAsset = useCallback(
    (asset: OnrampAsset | undefined) => {
      handleUpdateOnrampInfo({ selectedAsset: asset });
    },
    [handleUpdateOnrampInfo],
  );

  const handleSetSelectedPaymentMethod = useCallback(
    (paymentMethod: OnrampPaymentMethod | undefined) => {
      setOnrampErrorMessage(undefined);
      handleUpdateOnrampInfo({
        selectedPaymentMethod: paymentMethod,
      });
    },
    [handleUpdateOnrampInfo],
  );

  const value: PanoOnrampContextProps = useMemo(() => {
    return {
      ...initialValues,
      ...onrampInfo,
      assets,
      fiatCurrency,
      networks,
      sourceWallet,
      paymentMethods,
      checkoutSession,
      sanitizedAmount,
      availableFiats,
      countryCode,
      subdivisionCode,
      onrampErrorMessage,
      setOnrampErrorMessage,
      getCheckoutSessionAsync,
      isLoadingPaymentMethods,
      isExternalProviderWebviewVisible,
      toggleIsExternalProviderWebviewVisible,
      is3rdPartyLegalNoticeModalVisible,
      toggleIs3rdPartyLegalNoticeModalVisible,
      updateOnrampInfo: handleUpdateOnrampInfo,
      setSelectedAsset: handleSetSelectedAsset,
      setSelectedProvider: handleSetSelectedProvider,
      setSelectedWallet: handleSetSelectedWallet,
      setOnrampFiatCurrency,
      setSelectedPaymentMethod: handleSetSelectedPaymentMethod,
      isLoadingPaymentProviderQuery:
        isLoadingAssets || isLoadingPaymentMethods || isLoadingNetworks,
    };
  }, [
    initialValues,
    onrampInfo,
    assets,
    fiatCurrency,
    networks,
    sourceWallet,
    paymentMethods,
    checkoutSession,
    sanitizedAmount,
    availableFiats,
    countryCode,
    subdivisionCode,
    onrampErrorMessage,
    getCheckoutSessionAsync,
    isLoadingPaymentMethods,
    isExternalProviderWebviewVisible,
    toggleIsExternalProviderWebviewVisible,
    is3rdPartyLegalNoticeModalVisible,
    toggleIs3rdPartyLegalNoticeModalVisible,
    handleUpdateOnrampInfo,
    handleSetSelectedAsset,
    handleSetSelectedProvider,
    handleSetSelectedWallet,
    setOnrampFiatCurrency,
    handleSetSelectedPaymentMethod,
    isLoadingAssets,
    isLoadingNetworks,
  ]);

  return <PanoOnrampContext.Provider value={value}>{children}</PanoOnrampContext.Provider>;
}

export function usePanoOnrampContext() {
  const context = useContext(PanoOnrampContext);

  if (context === undefined) {
    throw new Error('useOnrampContext must be used within a PanoOnrampContextProvider');
  }

  return context;
}

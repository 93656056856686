import { applicationModeAtom } from 'cb-wallet-data/hooks/DebugMenu/state';
import { useRecoilValue } from 'recoil';

import { FeatureConfig } from '../types';

/**
 * Checks if the feature is enabled for the current application mode.
 * Assumes that if no modes are defined for the feature config, it's enabled for superapp mode.
 */
export function useIsFeatureEnabledForModes(modes: FeatureConfig['modes']) {
  const currentApplicationMode = useRecoilValue(applicationModeAtom);

  // if nullable, app is not setting an explicit mode
  // disregard mode-based feature check and return true
  if (!currentApplicationMode) {
    return true;
  }

  if (modes.length === 0) {
    if (currentApplicationMode !== 'superapp') {
      return false;
    }

    return true;
  }

  return modes.includes(currentApplicationMode);
}

import {
  triggerInvalidRefreshTokenDetected,
  triggerInvalidRefreshTokenHandled,
} from 'cb-wallet-analytics/auth';
import { handleAuthTokenRefreshFailure } from 'cb-wallet-data/HTTP/config';

let executor: Promise<unknown> | false = false;
let executorIteration = 0;

function lock<T>(runner: () => Promise<T>) {
  return async () => {
    if (!executor) {
      const verifyAndRun = async () => {
        try {
          const runnerResult = await runner();
          executor = false;
          executorIteration++;
          return runnerResult;
        } catch {
          executor = false;
          executorIteration++;
        }
      };
      executor = verifyAndRun();
    }
    return executor;
  };
}

const refreshTokenWithLock = lock(handleAuthTokenRefreshFailure);

export async function withTokenRefreshFailureHandling<T>(f: () => Promise<T>) {
  if (executor) {
    await executor;
  }
  const executorIterationLocal = executorIteration;
  try {
    const response = await f();
    return response;
  } catch (error: ErrorOrAny) {
    if (error.message === 'refreshToken is invalid') {
      triggerInvalidRefreshTokenDetected();
      if (executor) {
        await executor;
      } else if (executorIteration === executorIterationLocal) {
        await refreshTokenWithLock();
      }
      triggerInvalidRefreshTokenHandled();
      const response = await f();
      return response;
    }
    throw error;
  }
}

import { EthereumNetworkMap } from 'cb-wallet-data/chains/AccountBased/Ethereum/EthereumChain';
import { Chain, defineChain } from 'viem';
import * as viemChains from 'viem/chains';

export const VIEM_CHAIN_MAP = Object.values(viemChains).reduce(function mapChainIdToChain(
  acc: Record<number, Chain>,
  chain,
) {
  acc[chain.id] = chain;
  return acc;
},
{});

export const WHITELISTED_CHAINS_MAP = Object.values(EthereumNetworkMap.whitelisted).reduce(
  function mapChainToEthereumChain(acc: Record<number, Chain>, chain) {
    acc[chain.chainId] = defineChain({
      id: chain.chainId,
      name: chain.baseAssetDisplayName,
      network: chain.displayName,
      nativeCurrency: {
        name: chain.baseAssetDisplayName,
        symbol: chain.baseAssetCurrencyCode ?? 'ETH',
        decimals: chain.baseAssetDecimal,
      },
      rpcUrls: {
        public: {
          http: [chain.rpcUrl],
        },
        default: {
          http: [chain.rpcUrl],
        },
      },
      blockExplorers: {
        default: {
          url: chain.blockExplorerUrl,
          name: `${chain.displayName}Scan`,
        },
      },
    });
    return acc;
  },
  {},
);

export const WHITELISTED_CHAINS = Object.values(WHITELISTED_CHAINS_MAP);

export const GALLERY_PAGE_SLUGS = [
  'get-based',
  'mistermiggles',
  'ethereum-etf',
  'globalsummer',
  'onchainsummit',
] as const;

export type GalleryPageSlug = (typeof GALLERY_PAGE_SLUGS)[number];

export type GalleryPageConfigType = {
  slug: string;
  starterPackLink?: string;
  metadata?: {
    imageUrl: string;
    imageType: string;
  };
};

type GalleryPageConfig = Record<GalleryPageSlug, GalleryPageConfigType>;

export const galleryPageConfig: GalleryPageConfig = {
  mistermiggles: {
    slug: 'mistermiggles',
    starterPackLink: 'https://www.coinbase.com/onchain#remix-challenge',
    metadata: {
      imageUrl:
        'https://res.cloudinary.com/coin-nft/image/upload/v1721148412/marketing/BSE24_MisterMiggles_gif_farcaster_16x9.gif',
      imageType: 'image/gif',
    },
  },
  'get-based': {
    slug: 'get-based',
    starterPackLink: 'https://www.coinbase.com/onchain#remix-challenge',
    metadata: {
      imageUrl:
        'https://res.cloudinary.com/coin-nft/image/upload/v1721148412/marketing/BSE24_MisterMiggles_gif_farcaster_16x9.gif',
      imageType: 'image/gif',
    },
  },
  'ethereum-etf': {
    slug: 'ethereum-etf',
    metadata: {
      imageUrl:
        'https://res.cloudinary.com/coin-nft/image/upload/v1721749875/marketing/ETH_ETF_2.gif',
      imageType: 'image/gif',
    },
  },
  globalsummer: {
    slug: 'globalsummer',
    metadata: {
      imageUrl:
        'https://res.cloudinary.com/coin-nft/image/upload/v1721838951/marketing/galleries/globalsummer.gif',
      imageType: 'image/gif',
    },
  },
  onchainsummit: {
    slug: 'onchainsummit',
    starterPackLink: 'https://boxedfoodscompany.box.com/s/3pau3l9oi01ih546zkzolwmsney06wzu',
    metadata: {
      imageUrl:
        'https://res.cloudinary.com/coin-nft/image/upload/v1722270641/marketing/galleries/onchain-remix.png',
      imageType: 'image/png',
    },
  },
};

import { Persistable } from 'cb-wallet-data/persistence/Database.interface';
import { AddressType } from 'cb-wallet-data/stores/Addresses/models/AddressType';

export type WalletAddressDMO = {
  type: string;
  address: string;
  index: string;
};

export class WalletAddress implements Persistable<WalletAddressDMO> {
  constructor(readonly type: AddressType, readonly address: string, readonly index: bigint) {}

  get asDMO(): WalletAddressDMO {
    return {
      type: this.type.rawValue,
      address: this.address,
      index: this.index.toString(),
    };
  }

  static fromDMO(dmo: WalletAddressDMO): WalletAddress {
    return new WalletAddress(new AddressType(dmo.type), dmo.address, BigInt(dmo.index));
  }
}

import {
  logUTXOAddressCreationFailure,
  logUTXOAddressCreationSuccess,
  logUTXOAddressScanningFailure,
  logUTXOAddressScanningSuccess,
} from 'cb-wallet-analytics/balances/Balances';
import { PossibleUTXOBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
import { getAddressesTypesToRefresh } from 'cb-wallet-data/chains/UTXO/common/getAddressTypesToRefresh';
import { getXpubKeyFromLocalStorage } from 'cb-wallet-data/chains/UTXO/common/getXpubKeyFromLocalStorage';
import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { Address } from 'cb-wallet-data/stores/Addresses/models/Address';
import groupBy from 'lodash/groupBy';
import { HistoryEventUpdate } from 'wallet-engine-signing/history';

let isFirstSessionAfterWalletCreation = false;
let accountIdForSession: Account['id'] | null = null;

type LogUTXOAddressCreationArgs = {
  existingAddresses: Address[];
  newAddresses: Address[];
  blockchainSymbol: PossibleUTXOBlockchainSymbol;
  accountId: Account['id'];
};

// Checks that the minimum set of addresses is derived for each UTXO blockchain
export function logUTXOAddressCreation({
  existingAddresses,
  newAddresses,
  blockchainSymbol,
  accountId,
}: LogUTXOAddressCreationArgs) {
  // When the wallet is first created, the only addresses that will exist are 0 index addresses.
  isFirstSessionAfterWalletCreation = existingAddresses.every(({ index }) => index === 0n);

  if (isFirstSessionAfterWalletCreation) {
    accountIdForSession = accountId;

    const addresses = [...existingAddresses, ...newAddresses];
    // On first session, ensure the minimum set of addresses exist for each chain
    const missingAddressTypes = findIncompleteAddressTypes({
      addresses,
      blockchainSymbol,
    });

    if (!missingAddressTypes.length) {
      logUTXOAddressCreationSuccess(blockchainSymbol);
    } else {
      const addressTypesWithMissingXpubKeys = getAddressTypesForMissingXpubKeys(
        blockchainSymbol,
        accountId,
      );
      logUTXOAddressCreationFailure({
        chainName: blockchainSymbol,
        addressTypesWithMissingXpubKeys,
        numDerivedAddresses: addresses.length,
        missingAddressTypes,
      });
    }
  }
}

type LogUTXOAddressScanningArgs = {
  blockchainSymbol: PossibleUTXOBlockchainSymbol;
  updates: HistoryEventUpdate['updates'];
};

// Checks that each derived address has been indexed successfully
export function logUTXOAddressScanning({ blockchainSymbol, updates }: LogUTXOAddressScanningArgs) {
  if (isFirstSessionAfterWalletCreation) {
    const updatesToAddressLike = updates.map(({ context }) => ({
      index: context?.addressIndex,
      type: { rawValue: context?.addressType },
      isChangeAddress: context?.isChangeAddress,
    }));

    const missingAddressTypes = findIncompleteAddressTypes({
      addresses: updatesToAddressLike,
      blockchainSymbol,
    });

    if (!missingAddressTypes.length) {
      logUTXOAddressScanningSuccess(blockchainSymbol);
    } else if (accountIdForSession) {
      const addressTypesWithMissingXpubKeys = getAddressTypesForMissingXpubKeys(
        blockchainSymbol,
        accountIdForSession,
      );
      logUTXOAddressScanningFailure({
        chainName: blockchainSymbol,
        addressTypesWithMissingXpubKeys,
        numDerivedAddresses: updatesToAddressLike.length,
        missingAddressTypes,
      });
    }
  }
}

type EnsureZeroIndexAddressesExistParams = {
  addresses: AddressLike[];
  blockchainSymbol: PossibleUTXOBlockchainSymbol;
};

export type AddressLike = Pick<Address, 'index' | 'type' | 'isChangeAddress'>;

export function findIncompleteAddressTypes({
  addresses,
  blockchainSymbol,
}: EnsureZeroIndexAddressesExistParams) {
  const addressesByAddressType = groupBy(addresses, ({ type }) => type.rawValue);
  const addressTypes = getAddressesTypesToRefresh(blockchainSymbol);

  const incompleteAddressTypes = addressTypes.filter(function ensureAddressesExist(addressType) {
    const receiveAddressesExist = ensureMinimumAddressSet({
      addresses: addressesByAddressType[addressType.rawValue],
      startIndex: 0n,
      endIndex: 19n,
      isChangeAddress: false,
    });

    const changeAddressesExist = ensureMinimumAddressSet({
      addresses: addressesByAddressType[addressType.rawValue],
      startIndex: 0n,
      endIndex: 19n,
      isChangeAddress: true,
    });

    return !receiveAddressesExist || !changeAddressesExist;
  });

  return incompleteAddressTypes.map((type) => type.rawValue);
}

type EnsureMinimumAddressSetParams = {
  addresses: AddressLike[];
  startIndex: bigint;
  endIndex: bigint;
  isChangeAddress: boolean;
};

function ensureMinimumAddressSet({
  addresses,
  startIndex,
  endIndex,
  isChangeAddress,
}: EnsureMinimumAddressSetParams): boolean {
  const indexSet = new Set<bigint>();

  (addresses || []).forEach((address) => {
    if (
      address.index >= startIndex &&
      address.index <= endIndex &&
      !indexSet.has(address.index) &&
      address.isChangeAddress === isChangeAddress
    ) {
      indexSet.add(address.index);
    }
  });

  for (let i = startIndex; i <= endIndex; i++) {
    if (!indexSet.has(i)) {
      return false;
    }
  }

  return true;
}

function getAddressTypesForMissingXpubKeys(
  blockchainSymbol: PossibleUTXOBlockchainSymbol,
  accountId: Account['id'],
) {
  const addressTypes = getAddressesTypesToRefresh(blockchainSymbol);
  return addressTypes
    .filter(function isXpubKeyMissing(addressType) {
      const xpubKey = getXpubKeyFromLocalStorage({
        blockchain: new Blockchain(blockchainSymbol),
        currencyCode: new CurrencyCode(blockchainSymbol),
        accountId,
        addressType,
        walletIndex: 0n,
        isTestnet: false,
      });
      return !xpubKey;
    })
    .map((type) => type.rawValue);
}

import { StoreKind } from './StoreKind';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class StoreKey<T> {
  readonly name: string;

  /**
   * NOTE: Typescript enums get interpreted as integers unless they are specified as strings
   * This logic filters out falsy values, which filters out the StoreKind.LocalStorage from
   * the store key name. In order to not break the existing keys we maintain the same behavior,
   * even though it is not ideal.
   *
   * new StoreKey('id', 'uuid', StoreKind.LocalStorage) => 'id_uuid'
   * new StoreKey('id', 'uuid', StoreKind.Memory) => 'id_uuid_1'
   */
  constructor(readonly id: string, readonly uuid: string | undefined, readonly kind: StoreKind) {
    this.name = [this.id, uuid, kind].filter((x) => x).join('_');
  }
}

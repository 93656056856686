import { useCallback } from 'react';
import { Asset } from 'cb-wallet-data/stores/Assets/Asset';
import { AssetInfo, AssetInfoResponse } from 'cb-wallet-data/stores/DefiPortfolio/models/AssetInfo';

export function useParseAssetInfoResponse() {
  return useCallback(function parseAssetInfoResponse(
    assetInfoResponse: AssetInfoResponse,
  ): AssetInfo {
    const assetResponse = assetInfoResponse.asset;

    const result = {
      asset: new Asset(
        assetResponse.name,
        assetResponse.currencyCode,
        assetResponse.address,
        assetResponse.decimals,
        assetResponse.imageURL,
        assetResponse.chainId,
        assetResponse.blockchain,
        assetResponse.uuid,
      ),
      balance: BigInt(assetInfoResponse.balance),
    };

    return result;
  },
  []);
}

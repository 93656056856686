import { logRateResults } from 'cb-wallet-analytics/data/Portfolio';
import { logPotentialExchangeRateIssue } from 'cb-wallet-analytics/data/Transactions';
import {
  ETHEREUM_CHAIN_ID,
  ETHEREUM_NETWORK_ID,
} from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import {
  EthereumChain,
  EthereumNetworkMap,
} from 'cb-wallet-data/chains/AccountBased/Ethereum/EthereumChain';
import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { getNetworkId } from 'cb-wallet-data/stores/Networks/utils/getNetworkId';
import { bigIntToDecimal } from 'cb-wallet-data/utils/BigInt+Core';
import { Decimal } from 'decimal.js';

import { getExchangeRateKey } from './state';

export function toDecimal(value: bigint | Decimal, decimals: bigint): Decimal {
  const decimalValue = value instanceof Decimal ? value : bigIntToDecimal(value);
  const denom = new Decimal(10).pow(Number(decimals));
  return decimalValue.dividedBy(denom);
}

export function formatDecimal({
  integerFormatter,
  decimalFormatter,
  value,
}: {
  integerFormatter: Intl.NumberFormat;
  decimalFormatter: Intl.NumberFormat;
  value: Decimal;
}): string {
  if (value.isInteger()) {
    // Questsionable
    return integerFormatter.format(Number(value.toFixed()));
  }

  const formattedZero = integerFormatter.format(0);
  const decimalSeparator = formattedZero.charAt(formattedZero.indexOf('0') + 1);
  let integer = value.truncated();
  const fraction = value.sub(integer);
  let formattedFraction = decimalFormatter.format(fraction.toNumber());

  // Hardcoded $??
  if (formattedFraction === '1.00') integer = integer.add(new Decimal(1));

  formattedFraction = formattedFraction.substring(formattedFraction.indexOf(decimalSeparator) + 1);

  // Questionable
  let formattedInteger = integerFormatter.format(Number(integer.toFixed()));
  const decimalIndex = formattedInteger.indexOf(decimalSeparator);
  if (decimalIndex > 0) {
    formattedInteger = formattedInteger.substring(0, decimalIndex);
  }

  return formattedInteger + decimalSeparator + formattedFraction;
}

type RateForOptions = {
  exchangeRatesMap: Record<string, Decimal>;
  currencyCode: CurrencyCode;
  contractAddress?: string;
  network?: Network;
  blockchain?: Blockchain;
};

type LogPotentialExchangeRateIssueIfNecessaryOptions = {
  fiatBalance: Decimal;
  blockchain: string;
  chainId?: number | string;
  chainName?: string;
  currencyCode?: string;
  contractAddress?: string;
  networkName?: string;
};

let initializedRateFor = false;
let successfulQuoteRetrievals = 0;
let unsuccessfulQuoteRetrievals = 0;
let whitelistedCurrencyCodesUnsuccessfulRetrievals = new Map<string, number>();
const rateLogInterval = 20 * 1000; // log rate results every twenty seconds
// These are currency codes that we should always have a quote for. If we don't have a
// quote for them, emit a metric we can alert on.
const whitelistedCurrencyCodes = new Set<string>([CurrencyCode.BTC.value, CurrencyCode.SOL.value]);

/**
 * Method to get BigDecimal exchange rate (or null if not found) value for provided token params. This
 * method is called extremely often and we should be very careful when adding logic here - anything
 * making this call more expensive could substantially slow down the app!
 *
 * @param currencyCode token currency code, like 'BTC'
 * @param contractAddress optional contract address for ERC20 tokens
 * @param name optional human readable name value, like 'Bitcoin'
 *
 * @return Optional BigDecimal value for exchange rate
 */
export function rateFor({
  exchangeRatesMap,
  currencyCode,
  contractAddress,
  network,
  blockchain,
}: RateForOptions): Decimal | undefined {
  if (network?.asChain()?.isCustomNetwork) {
    return undefined;
  }

  if (!initializedRateFor) {
    EthereumNetworkMap.allNetworks.forEach((value: EthereumChain, _: string) => {
      if (!value.isTestnet && !value.isCustomNetwork && value.baseAssetCurrencyCode) {
        whitelistedCurrencyCodes.add(value.baseAssetCurrencyCode);
      }
    });

    setInterval(
      // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
      // eslint-disable-next-line wallet/no-anonymous-params
      () => {
        logRateResults({
          successes: successfulQuoteRetrievals,
          fails: unsuccessfulQuoteRetrievals,
          criticalFails: whitelistedCurrencyCodesUnsuccessfulRetrievals,
        });
        successfulQuoteRetrievals = 0;
        unsuccessfulQuoteRetrievals = 0;
        whitelistedCurrencyCodesUnsuccessfulRetrievals = new Map();
      },
      rateLogInterval,
    );

    initializedRateFor = true;
  }

  const chain = network?.asChain();
  const networkId = getNetworkId({ network, blockchain });
  const chainId = chain?.chainId ? BigInt(chain?.chainId) : undefined;

  // Special handling for ETH, because several chains including testnets
  // use it as base asset. ETH is the same across everything, use mainnet rate.
  const isETH = !contractAddress && CurrencyCode.isEqual(currencyCode, CurrencyCode.ETH);
  const fixedChainId = isETH ? ETHEREUM_CHAIN_ID : chainId;
  const fixedNetworkId = isETH ? ETHEREUM_NETWORK_ID : networkId;

  const key = getExchangeRateKey({
    chainId: fixedChainId,
    networkId: fixedNetworkId,
    contractAddress,
    currencyCode,
  });

  let rate: Decimal | undefined;

  if (key) {
    rate = exchangeRatesMap[key];
  }

  if (rate) {
    successfulQuoteRetrievals++;
  } else if (!chain?.isTestnet) {
    // don't count failures for testnets
    unsuccessfulQuoteRetrievals++;
    if (
      currencyCode &&
      (!contractAddress || !chain) &&
      whitelistedCurrencyCodes.has(currencyCode.value)
    ) {
      // we fetched quote by currencyCode
      // this currency code is whitelisted
      // we didn't get a quote...this is a problem. emit a special metric
      // so we can alert on this
      const oldVal = whitelistedCurrencyCodesUnsuccessfulRetrievals.get(currencyCode.value) || 0;
      whitelistedCurrencyCodesUnsuccessfulRetrievals.set(currencyCode.value, oldVal + 1);
    }
  }

  return rate;
}

export function logPotentialExchangeRateIssueIfNecessary({
  fiatBalance,
  blockchain,
  chainId,
  chainName,
  currencyCode,
  contractAddress,
  networkName,
}: LogPotentialExchangeRateIssueIfNecessaryOptions) {
  if (fiatBalance.greaterThanOrEqualTo(1e9)) {
    logPotentialExchangeRateIssue({
      blockchain,
      currencyCode,
      contractAddress: contractAddress ?? '',
      networkName,
      chainName: chainName ?? '',
      chainId: chainId ?? '',
    });
  }
}

export function encodeCurrencyCodes(input: CurrencyCode[]): string {
  return encodeURI(input.map((it) => it.rawValue).join(','));
}

import { experiments } from 'cb-wallet-data/experiments';
import { useOverridableKillSwitch } from 'cb-wallet-data/hooks/KillSwitches/useOverridableKillSwitch';
import { getKillSwitchForPlatform } from 'cb-wallet-data/hooks/KillSwitches/utils/getKillSwitchForPlatform';
import { useIsExperimentInTreatment } from 'cb-wallet-data/stores/Experiments/hooks/useIsExperimentInTreatment';

export function useIsBackendNFTChainConfigEnabled() {
  const isTreatment = useIsExperimentInTreatment(
    experiments.may_2023_wallet_backend_nft_chain_config,
  );
  const isKilled = useOverridableKillSwitch(
    getKillSwitchForPlatform('kill_backend_based_nft_chain_config'),
  );

  return isTreatment && !isKilled;
}

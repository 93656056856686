import { logMetric, MetricType } from '@cbhq/client-analytics';

export function logRateResults({
  successes,
  fails,
  criticalFails,
}: {
  successes: number;
  fails: number;
  criticalFails: Map<string, number>;
}) {
  if (successes > 0) {
    logMetric({
      metricName: 'exchange_rate_fetch_success',
      metricType: MetricType.count,
      value: successes,
    });
  }
  if (fails > 0) {
    logMetric({
      metricName: 'exchange_rate_fetch_fail',
      metricType: MetricType.count,
      value: fails,
    });
  }
  criticalFails.forEach((count: number, symbol: string) => {
    logMetric({
      metricName: 'critical_exchange_rate_fail',
      metricType: MetricType.count,
      value: count,
      tags: { asset: symbol },
    });
  });
}

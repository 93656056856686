import {
  asNetwork,
  EthereumBlockchain,
  EthereumWalletConfiguration,
} from 'cb-wallet-data/chains/AccountBased/Ethereum/config';
import { EthereumChain } from 'cb-wallet-data/chains/AccountBased/Ethereum/EthereumChain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { fetchDomains } from 'cb-wallet-data/stores/DecentralizedID/hooks/usePublicProfileByAddress';
import { TxOrUserOp } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import { TxOrUserOpMetadata } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOpMetadata';
import { TxState } from 'cb-wallet-data/stores/Transactions/models/TxState';
import { unixTimestampToDate } from 'cb-wallet-data/stores/Transactions/utils/unixTimestampToDate';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { v4 as uuidv4 } from 'uuid';
import { EtherscanResult, EtherscanTokenTransactionResponse } from 'wallet-engine-signing/history';

export type ParseEtherscanTransaction = {
  rawTx: EtherscanResult;
  address: string;
  chain: EthereumChain;
  accountId: Account['id'];
  walletIndex: bigint;
};

export async function parseEtherscanTransaction({
  rawTx,
  address,
  chain,
  accountId,
  walletIndex,
}: ParseEtherscanTransaction) {
  const date = unixTimestampToDate(rawTx.timeStamp);
  const amount = BigInt(rawTx.value);
  const gasUsed = BigInt(rawTx.gasUsed);
  const gasPrice = BigInt(rawTx.gasPrice);
  const tokenName = chain.baseAssetDisplayName;
  const tokenDecimal = BigInt(chain.baseAssetDecimal);
  const nonce = BigInt(rawTx.nonce);
  const isTokenTx =
    rawTx.contractAddress !== null &&
    (rawTx as EtherscanTokenTransactionResponse).tokenSymbol &&
    chain.baseAssetDecimal;
  const currencyCode = isTokenTx
    ? new CurrencyCode((rawTx as EtherscanTokenTransactionResponse).tokenSymbol)
    : EthereumWalletConfiguration.currencyCodeForNetwork(asNetwork(chain));
  const network = asNetwork(chain);
  const walletId = Wallet.generateId({
    blockchain: EthereumBlockchain,
    currencyCode,
    network,
    contractAddress: rawTx.contractAddress,
    selectedIndex: walletIndex,
    accountId,
  });

  const [toDomain, fromDomain] = await fetchDomains([rawTx.to, rawTx.from]);

  return new TxOrUserOp({
    id: uuidv4(),
    createdAt: date,
    confirmedAt: date,
    blockchain: EthereumBlockchain,
    currencyCode,
    feeCurrencyCode: EthereumWalletConfiguration.currencyCodeForNetwork(asNetwork(chain)),
    feeCurrencyDecimal: tokenDecimal,
    toAddress: rawTx.to,
    toDomain,
    fromAddress: rawTx.from,
    fromDomain,
    amount,
    fee: gasUsed * gasPrice,
    state: rawTx.isError === '1' ? TxState.FAILED : TxState.CONFIRMED,
    metadata: new TxOrUserOpMetadata(),
    network: asNetwork(chain),
    accountId,
    walletIndex,
    txOrUserOpHash: rawTx.hash,
    userOpHash: undefined,
    txHash: rawTx.hash,
    isSent: rawTx.to.toLowerCase() !== address.toLowerCase(),
    contractAddress: rawTx.contractAddress,
    tokenName,
    tokenDecimal,
    walletId,
    nonce,
  });
}

import { CB_WALLET_API_URL } from 'cb-wallet-env/env';
import { V1GetMintSlugResponse } from '@cbhq/instant-api-hooks-creator-service/types';

export async function getMintDataBySlug(slug: string): Promise<V1GetMintSlugResponse> {
  const URL = `${CB_WALLET_API_URL}/rpc/v3/creators/getMintSlug?slug=${slug}`;

  const response = await fetch(URL);
  const data = await response.json();

  return data;
}

import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { Store } from 'cb-wallet-store/Store';

// Wallet indexes to scan include 1-9.
// 0 is not needed, since 0th index wallet group is created by default for every account.
// User can create wallet groups up to 14th index, but we've capped scanning up to 9th for perf concerns.
type WalletIndexToScan = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
type ScannedWalletIndexes = Record<WalletIndexToScan, boolean>;
type BalancesScannedByAccount = Record<Account['id'], ScannedWalletIndexes>;

const WALLET_INDEXES_TO_SCAN: WalletIndexToScan[] = [1, 2, 3, 4, 5, 6, 7, 8, 9];
const DEFAULT_BALANCES_SCANNED_BY_ACCOUNT: BalancesScannedByAccount = {};
export const DEFAULT_SCANNED_INDEXES: ScannedWalletIndexes = {
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
  6: false,
  7: false,
  8: false,
  9: false,
};

export const StoreKeys_balancesScannedByAccount =
  new LocalStorageStoreKey<BalancesScannedByAccount>('balancesScannedByAccount');

/**
 * Persist that all indexes of an account have been scanned to local storage.
 */
export function setAllWalletIndexesOfAccountScanned(accountId: Account['id']) {
  const prevBalancesScanned =
    Store.get<BalancesScannedByAccount>(StoreKeys_balancesScannedByAccount) ??
    DEFAULT_BALANCES_SCANNED_BY_ACCOUNT;

  const scannedIndexesOfAccount = WALLET_INDEXES_TO_SCAN.reduce<ScannedWalletIndexes>(
    function reduceScannedIndexesOfAccount(acc, walletIndex) {
      acc[walletIndex] = true;
      return acc;
    },
    { ...DEFAULT_SCANNED_INDEXES },
  );

  const nextBalancesScanned = {
    ...prevBalancesScanned,
    [accountId]: scannedIndexesOfAccount,
  };

  Store.set(StoreKeys_balancesScannedByAccount, nextBalancesScanned);
}

/**
 * Persist a wallet's index as scanned to local storage.
 */
export function setWalletIndexScanned(accountId: Account['id'], walletIndex: WalletIndexToScan) {
  const prevBalancesScanned =
    Store.get<BalancesScannedByAccount>(StoreKeys_balancesScannedByAccount) ??
    DEFAULT_BALANCES_SCANNED_BY_ACCOUNT;

  // Rebuild a new instance of the local storage state, but with the specified account + index marked as scanned.
  const nextBalancesScanned = { ...prevBalancesScanned };

  // If this account does not have an entry yet in the local storage state, create the initial obj.
  if (!nextBalancesScanned[accountId]) {
    nextBalancesScanned[accountId] = { ...DEFAULT_SCANNED_INDEXES };
  }

  nextBalancesScanned[accountId][walletIndex] = true;

  Store.set(StoreKeys_balancesScannedByAccount, nextBalancesScanned);
}

/**
 * Determine which indexes should be scanned based on given wallet groups and
 * persisted  local storage value which keeps track of which indexes have been scanned.
 */
export function getWalletIndexesToScan(accountId: Account['id']) {
  const balancesScannedByAccount =
    Store.get<BalancesScannedByAccount>(StoreKeys_balancesScannedByAccount) ??
    DEFAULT_BALANCES_SCANNED_BY_ACCOUNT;

  return WALLET_INDEXES_TO_SCAN.reduce<WalletIndexToScan[]>(function reduceWalletIndexesToScan(
    acc,
    walletIndexAsNum,
  ) {
    if (!balancesScannedByAccount[accountId]?.[walletIndexAsNum]) {
      acc.push(walletIndexAsNum);
    }

    return acc;
  },
  []);
}

import { useEffect } from 'react';
import {
  logAssetDetailViewed,
  logAssetsSidebarViewed,
  logAssetsViewed,
  logTestnetsViewed,
} from 'cb-wallet-analytics/assets';
import {
  logNftCollectionViewed,
  logNftDetailViewed,
  logNftPreviewViewed,
  logNftViewed,
} from 'cb-wallet-analytics/collectible';
import { logDatadogSyntheticsPageViewed } from 'cb-wallet-analytics/dapp';
import { logDefiTabViewed } from 'cb-wallet-analytics/defi';
import {
  logSettingsDisplayViewed,
  logSettingsManageWalletsViewed,
  logSettingsViewed,
} from 'cb-wallet-analytics/settings';
import {
  logTransactionDetailViewed,
  logTransactionSidebarViewed,
  logTransactionViewed,
} from 'cb-wallet-analytics/transactions';
import { useRouter } from 'next/router';

import { RouterPathname } from ':dapp/utils/RoutesEnum';

/**
 * Logs the page viewed event based on the current route
 */
export function useLogPageViewed() {
  const router = useRouter();

  const handleRouteChange = () => {
    switch (router.pathname) {
      // Crypto
      case RouterPathname.ASSETS:
        logAssetsViewed();
        break;

      case RouterPathname.CRYPTO_DETAIL:
        logAssetDetailViewed();
        break;

      case RouterPathname.CRYPTO_PREVIEW:
        logAssetsSidebarViewed();
        break;

      case RouterPathname.TESTNETS:
        logTestnetsViewed();
        break;

      // DEFI
      case RouterPathname.DEFI:
        logDefiTabViewed();
        break;

      // NFT
      case RouterPathname.NFT:
        logNftViewed();
        break;

      case RouterPathname.NFT_DETAIL:
        logNftDetailViewed();
        break;

      case RouterPathname.NFT_PREVIEW:
        logNftPreviewViewed();
        break;

      case RouterPathname.NFT_COLLECTION:
        logNftCollectionViewed();
        break;

      // Transactions
      case RouterPathname.TRANSACTIONS:
        logTransactionViewed();
        break;

      case RouterPathname.TRANSACTIONS_DETAIL:
        logTransactionDetailViewed();
        break;

      case RouterPathname.TRANSACTIONS_PREVIEW:
        logTransactionSidebarViewed();
        break;

      // Settings
      case RouterPathname.SETTINGS:
        logSettingsViewed();
        break;

      case RouterPathname.SETTINGS_MANAGE_WALLETS:
        logSettingsManageWalletsViewed();
        break;

      case RouterPathname.SETTINGS_DISPLAY:
      case RouterPathname.SETTINGS_LOCAL_CURRENCY:
        logSettingsDisplayViewed();
        break;

      case RouterPathname.DO_NOT_USE_DATA_DOG_SYNTHETICS:
        logDatadogSyntheticsPageViewed();
        break;

      // TODO: Add logging for other pages
      /* istanbul ignore next */
      default:
        break;
    }
  };

  useEffect(handleRouteChange, [router.pathname, router.asPath]);
}

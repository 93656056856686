/**
 * Represents an exchangeable asset (ERC20 or ETH)
 *
 * @property name Asset display name
 * @property currencyCode Asset symbol i.e. ETH, BAT, USDC
 * @property contractAddress ERC20 contract address or null for Ethereum
 * @property decimals Number of decimals to represent asset's value in base unit
 * @property imageURL Assets logo URL
 * @property swappable Whether the asset is supported for native swap
 * @property aggregatorIDs The list of supported aggregators
 */ import { asNetwork as asEVMNetwork } from 'cb-wallet-data/chains/AccountBased/Ethereum/config';
import { ETHEREUM_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { EthereumNetworkMap } from 'cb-wallet-data/chains/AccountBased/Ethereum/EthereumChain';
import { ContractAddress } from 'cb-wallet-data/chains/AccountBased/Ethereum/models/ContractAddress';
import { SOLANA_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Solana/constants';
import { SolanaNetworkMap } from 'cb-wallet-data/chains/AccountBased/Solana/models/SolanaChain';
import { asNetwork as asSolanaNetwork } from 'cb-wallet-data/chains/AccountBased/Solana/utils/chain';
import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { Network, TESTNET_PREFIX } from 'cb-wallet-data/stores/Networks/models/Network';
import { getPrefix } from 'cb-wallet-data/stores/Swap/api/useSupportedSwapChains';

function getNetworkByChain(blockchain: Blockchain, chainId: bigint) {
  switch (blockchain.rawValue.toUpperCase()) {
    case SOLANA_SYMBOL:
      return asSolanaNetwork(
        SolanaNetworkMap.fromChainId(chainId) ?? SolanaNetworkMap.whitelisted.SOLANA_MAINNET,
      );
    case ETHEREUM_SYMBOL:
      return asEVMNetwork(
        EthereumNetworkMap.fromChainId(chainId) ?? EthereumNetworkMap.whitelisted.ETHEREUM_MAINNET,
      );
    default:
      // Blockchains that aren't ETH / ETC / SOL have TESTNET as network
      return Network.createTestnetNetwork(false);
  }
}

const BLOCKCHAINS_WITH_CHAIN_ID_PREFIX = [SOLANA_SYMBOL, ETHEREUM_SYMBOL];

export class Asset {
  name: string;
  currencyCode: CurrencyCode;
  address: ContractAddress | undefined;
  decimals: bigint;
  imageURL: string;
  chainId: bigint;
  blockchain: Blockchain;
  uuid?: string;

  constructor(
    name: string,
    currencyCode: string,
    address: string | undefined,
    decimals: number,
    imageURL: string,
    chainId: number,
    blockchain: string,
    uuid?: string | undefined,
  ) {
    this.name = name;
    this.currencyCode = new CurrencyCode(currencyCode);
    this.address = address ? new ContractAddress(address) : undefined;
    this.decimals = BigInt(decimals);
    this.imageURL = imageURL;
    this.chainId = BigInt(chainId);
    this.blockchain = new Blockchain(blockchain);
    this.uuid = uuid;
  }

  // We needed to perform some normalization here to match the pattern returned by the API
  // If "contractAddress" doesn't exists, we should use the string "undefined" instead
  // - API pattern: "ETH/WETH/ETHEREUM_CHAIN:1/false/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"
  get id(): string {
    // Native blockchains that aren't ETH / SOL have TESTNET as prefix
    // Only add chain id for eth / sol
    const blockChainUpperCase = this.blockchain.rawValue.toUpperCase();
    const prefix = BLOCKCHAINS_WITH_CHAIN_ID_PREFIX.includes(blockChainUpperCase)
      ? `${getPrefix(this.chainId)}:${this.chainId}`
      : TESTNET_PREFIX;
    return `${this.blockchain.rawValue.toUpperCase()}/${this.currencyCode.rawValue.toUpperCase()}/${prefix}/false/${
      this.address?.rawValue ?? 'undefined'
    }`;
  }

  get network(): Network {
    return getNetworkByChain(this.blockchain, this.chainId);
  }

  /**
   * compare 2 assets
   * @param other
   * @returns true if they have the same chainId, contractAddress(even if undefined),
   * currency code, and decimals
   */
  equals(other: Asset): boolean {
    return (
      this.address?.rawValue.toLowerCase() === other.address?.rawValue.toLowerCase() &&
      this.blockchain.rawValue === other.blockchain.rawValue &&
      this.chainId === other.chainId &&
      this.currencyCode.rawValue === other.currencyCode.rawValue &&
      this.decimals === other.decimals
    );
  }
}

import { PlatformName } from '@cbhq/client-analytics';

// https://github.cbhq.net/infra/config-service/blob/2b99c4f536d0110c3507ba322bca3e95ff19801b/protos/coinbase/config/parameter_types.proto#L241
export const WALLET_ANDROID_CLIENT_TYPE = 7;
export const WALLET_IOS_CLIENT_TYPE = 6;
export const WALLET_EXTENSION_CLIENT_TYPE = 8;
export const WALLET_WEB_CLIENT_TYPE = 9;

export type WalletClientType = 6 | 7 | 8 | 9;

const platformToTypeMap = {
  [PlatformName.ios]: WALLET_IOS_CLIENT_TYPE,
  [PlatformName.android]: WALLET_ANDROID_CLIENT_TYPE,
  [PlatformName.extension]: WALLET_EXTENSION_CLIENT_TYPE,
  [PlatformName.web]: WALLET_WEB_CLIENT_TYPE,
};

export function getClientType(): WalletClientType {
  return platformToTypeMap[getPlatformName()!] as WalletClientType;
}
type ClientPlatform =
  | PlatformName.ios
  | PlatformName.android
  | PlatformName.web
  | PlatformName.extension;

type ConfigOptions = {
  version?: string;
  platform?: ClientPlatform;
  // mobile only analytics
  appsFlyerAppID?: string | undefined;
  logAppsFlyerEvent?: (eventName: string) => void;
};

let metadata: ConfigOptions | undefined;

export function setApplicationMetadata(options: ConfigOptions) {
  if (metadata) {
    metadata = {
      ...metadata,
      ...options,
    };
  } else {
    metadata = options;
  }
}

export function getApplicationMetadata(): ConfigOptions {
  if (!metadata) {
    throw new Error(
      'cb-wallet-data initialization error: setApplicationMetadata must be called prior to getApplicationMetadata.',
    );
  }

  return metadata;
}

export function getPlatformName() {
  return getApplicationMetadata().platform;
}

import { useMemo } from 'react';
import { useKilnEarnBalanceTotalAmount } from 'cb-wallet-data/stores/Stake/api/useKilnEarnBalanceTotalAmount';
import Decimal from 'decimal.js';

import { useFetchEarnProtocolPositionsAggregateBalance } from './useFetchEarnProtocolPositionsAggregateBalance';
import { useFetchProtocolPositionsAggregateBalance } from './useFetchProtocolPositionsAggregateBalance';

export type DisableDeFiBalancesOptions = {
  enableKilnEarn?: boolean;
  enableLiquidityPool?: boolean;
  enableEarnPositions?: boolean;
  enableProtocolPositions?: boolean;
};

export type Params = {
  // If enabled is false, the hook will return 0 for all balances because we don't want to add
  // DeFi balances to the total.
  enabled?: boolean;
} & DisableDeFiBalancesOptions;

export function useDeFiAggregateBalance({
  enabled = true,
  enableKilnEarn = false,
  enableEarnPositions = false,
  enableLiquidityPool = false,
  enableProtocolPositions = false,
}: Params = {}) {
  const protocolPositionsBalance = useFetchProtocolPositionsAggregateBalance({
    enabled: enabled && enableProtocolPositions,
  });

  const kilnEarnBalance = useKilnEarnBalanceTotalAmount({ enabled: enabled && enableKilnEarn });

  const earnPositionsBalance = useFetchEarnProtocolPositionsAggregateBalance({
    enabled: enabled && enableEarnPositions,
  });

  // TODO (WALL-30562): Add earn section balance here once it is ready
  const liquidityPoolBalance = useMemo(() => {
    return enableLiquidityPool ? new Decimal(0) : new Decimal(0);
  }, [enableLiquidityPool]);

  const total = useMemo(() => {
    return protocolPositionsBalance
      .plus(kilnEarnBalance)
      .plus(liquidityPoolBalance)
      .plus(earnPositionsBalance);
  }, [earnPositionsBalance, kilnEarnBalance, liquidityPoolBalance, protocolPositionsBalance]);

  return useMemo(
    () => ({
      total,
      kilnEarn: kilnEarnBalance,
      liquidityPool: liquidityPoolBalance,
      earnPositions: earnPositionsBalance,
      protocolPositions: protocolPositionsBalance,
    }),
    [earnPositionsBalance, kilnEarnBalance, liquidityPoolBalance, protocolPositionsBalance, total],
  );
}

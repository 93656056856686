import { SearchEntity } from 'cb-wallet-data/stores/Search/types';

export type GlobalSearchFilterItem = {
  id: SearchEntity;
  label: string;
};

export const baseGlobalSearchFilters: GlobalSearchFilterItem[] = [
  {
    id: SearchEntity.SEARCH_ENTITY_UNSPECIFIED,
    label: 'All',
  },
  {
    id: SearchEntity.SEARCH_ENTITY_ASSET,
    label: 'Coins',
  },
  {
    id: SearchEntity.SEARCH_ENTITY_NFT_COLLECTIONS,
    label: 'NFTs',
  },
  {
    id: SearchEntity.SEARCH_ENTITY_DAPP,
    label: 'Apps',
  },
];

export const globalSearchFiltersWithSettings: GlobalSearchFilterItem[] = [
  ...baseGlobalSearchFilters,
  {
    id: SearchEntity.SEARCH_ENTITY_SETTING,
    label: 'Settings',
  },
];

import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { Persistable } from 'cb-wallet-data/persistence/Database.interface';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { Column, Entity, Index, PrimaryColumn } from '@cbhq/typeorm';

import { AssetManagementStatus } from './AssetManagementStatus';
import { AssetManagementType } from './AssetManagementType';

/**
 * Used to cache a list of curated assets loaded from the backend in phase 2.
 * Adding it in phase 1 to avoid migrations down the line.
 */
@Entity('curated_asset_setting')
@Index('IDX_CURATED_ASSET_SETTINGS', [
  'currencyCodeStr',
  'contractAddress',
  'blockchainStr',
  'networkStr',
])
export class CuratedAssetSettingDMO {
  @PrimaryColumn()
  id!: string;

  @Column()
  blockchainStr!: string;

  @Column()
  currencyCodeStr!: string;

  @Column()
  networkStr!: string;

  @Column({ type: 'int' })
  status!: AssetManagementStatus;

  @Column({ type: 'int' })
  type!: AssetManagementType;

  @Column({ nullable: true })
  contractAddress?: string;
}

export class CuratedAssetSetting implements Persistable<CuratedAssetSettingDMO> {
  readonly id: string;
  constructor(
    readonly blockchain: Blockchain,
    readonly currencyCode: CurrencyCode,
    readonly network: Network,
    readonly status: AssetManagementStatus,
    readonly type: AssetManagementType,
    readonly contractAddress: string | undefined,
  ) {
    this.id = CuratedAssetSetting.generateID(currencyCode, contractAddress, blockchain);
  }

  static fromDMO(dmo: CuratedAssetSettingDMO): CuratedAssetSetting {
    return new CuratedAssetSetting(
      new Blockchain(dmo.blockchainStr),
      new CurrencyCode(dmo.currencyCodeStr),
      Network.create(dmo.networkStr)!,
      dmo.status,
      dmo.type,
      dmo.contractAddress,
    );
  }

  static generateID(
    currencyCode: CurrencyCode,
    contractAddress: string | undefined,
    blockchain: Blockchain,
  ): string {
    return [currencyCode.rawValue, contractAddress, blockchain.rawValue].join('/');
  }

  get asDMO(): CuratedAssetSettingDMO {
    return {
      id: this.id,
      blockchainStr: this.blockchain.rawValue,
      currencyCodeStr: this.currencyCode.rawValue,
      networkStr: this.network.rawValue,
      status: this.status,
      type: this.type,
      contractAddress: this.contractAddress,
    };
  }
}

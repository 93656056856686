import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { Store } from 'cb-wallet-store/Store';
import { parse, stringify } from 'cb-wallet-store/utils/serialization';
import { AtomEffect } from 'recoil';

/**
 * Provides atom effect for storing a Recoil atom's object value in localStorage through the Store class.
 *
 * @param key The Store key to use for storage
 * @returns Effects for use with a Recoil atom
 */
export function objectAtomLocalStorageEffect<T>(key: LocalStorageStoreKey<string>) {
  return (({ setSelf, onSet }) => {
    const savedValue = Store.get(key) as string;

    if (savedValue) {
      try {
        const deserialized = parse(savedValue);
        setSelf(deserialized);
      } catch (e) {
        setSelf(savedValue as unknown as T);
      }
    }

    onSet(function ObjectAtomLocalStorageEffectOnSet(newValue: T) {
      const serialized = stringify(newValue);
      Store.set(key, serialized);
    });
  }) as AtomEffect<T>;
}

import { useMemo } from 'react';
import { useAccounts } from 'cb-wallet-data/stores/Accounts/hooks/useAccounts';
import { useDestinationWallets } from 'cb-wallet-data/stores/Buy/cbpay/hooks/useDestinationWallets';
import { useWalletGroups } from 'cb-wallet-data/stores/WalletGroups/hooks/useWalletGroups';

import { useScwWalletSupportedBlockchains } from ':dapp/hooks/Buy/useScwWalletSupportedBlockchains';
import { useIsScwAccountConnected } from ':dapp/hooks/Scw/useIsScwAccountConnected';

export function useScwDestinationWallets() {
  const accounts = useAccounts();
  const walletGroups = useWalletGroups({ visible: true });

  const { singleAccountId, walletGroupId } = useMemo(() => {
    if (walletGroups.length > 1 || accounts.length > 1 || !accounts[0] || !walletGroups[0]) {
      return {};
    }

    const walletGroupIdInner = walletGroups[0].id;
    const singleAccountIdInner = accounts[0].id;
    return {
      singleAccountId: singleAccountIdInner,
      walletGroupId: walletGroupIdInner,
    };
  }, [walletGroups, accounts]);

  const supportedBlockchains = useScwWalletSupportedBlockchains(singleAccountId);

  const isSingleScwWallet = useIsScwAccountConnected(singleAccountId);
  const destinationWallets = useDestinationWallets({
    walletGroupId,
    networks: supportedBlockchains,
  });

  return useMemo(() => {
    if (isSingleScwWallet) {
      return { scwDestinationWallets: destinationWallets };
    }
    return {};
  }, [isSingleScwWallet, destinationWallets]);
}

import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { parseBridge } from 'cb-wallet-data/stores/Transactions/methods/parsers/HRT/parseBridge';
import { parseContractExecution } from 'cb-wallet-data/stores/Transactions/methods/parsers/HRT/parseContractExecution';
import { parseSimple } from 'cb-wallet-data/stores/Transactions/methods/parsers/HRT/parseSimple';
import { parseStake } from 'cb-wallet-data/stores/Transactions/methods/parsers/HRT/parseStake';
import { parseSwap } from 'cb-wallet-data/stores/Transactions/methods/parsers/HRT/parseSwap';
import { hrtTransfersToTransfer } from 'cb-wallet-data/stores/Transactions/methods/parsers/utils/utils';
import { TxOrUserOp } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import {
  Apiv1Label,
  V1AddressMeta,
  V1PrimaryAction,
  V1SpamScoreThresholds,
  V1TransactionStatus,
  V1Transfer,
} from '@cbhq/instant-api-hooks-wallet-tx-history/types';

export function parseTransfers({
  primaryAction,
  historicalAssetPricesUsd,
  feeAmount,
  txNonce,
  txTimestamp,
  txStatus,
  txHash,
  userAddress,
  blockchain,
  network,
  accountId,
  walletIndex,
  spamScoreThresholds,
  addressMetadata,
  inputTransfers,
  userOpHash,
}: {
  primaryAction: V1PrimaryAction | undefined;
  historicalAssetPricesUsd?: Record<string, string>;
  feeAmount?: string;
  txNonce: number;
  txTimestamp: string;
  txStatus: V1TransactionStatus;
  txHash: string;
  userAddress: string;
  blockchain: Blockchain;
  network: Network;
  accountId: Account['id'];
  walletIndex: bigint;
  spamScoreThresholds?: V1SpamScoreThresholds;
  addressMetadata: Record<string, V1AddressMeta>;
  inputTransfers: V1Transfer[] | undefined;
  userOpHash: string | undefined;
}): TxOrUserOp | undefined {
  const transfers = hrtTransfersToTransfer(inputTransfers || [], addressMetadata);

  switch (primaryAction?.type) {
    case Apiv1Label.LABEL_SWAP:
      return parseSwap({
        primaryAction,
        feeAmount,
        historicalAssetPricesUsd,
        txHash,
        txNonce,
        txStatus,
        txTimestamp,
        userOpHash,
        userAddress,
        blockchain,
        network,
        accountId,
        walletIndex,
        spamScoreThresholds,
        addressMetadata,
        transfers: transfers || [],
      });

    case Apiv1Label.LABEL_BRIDGE_IN:
    case Apiv1Label.LABEL_BRIDGE_OUT:
      return parseBridge({
        primaryAction,
        feeAmount,
        historicalAssetPricesUsd,
        txHash,
        txNonce,
        txStatus,
        txTimestamp,
        userOpHash,
        userAddress,
        blockchain,
        network,
        accountId,
        walletIndex,
        spamScoreThresholds,
        addressMetadata,
        transfers: transfers || [],
      });

    case Apiv1Label.LABEL_SEND:
    case Apiv1Label.LABEL_RECEIVE:
    case Apiv1Label.LABEL_MINT:
      return parseSimple({
        primaryAction,
        historicalAssetPricesUsd,
        feeAmount,
        txNonce,
        txTimestamp,
        txStatus,
        txHash,
        userOpHash,
        userAddress,
        blockchain,
        network,
        accountId,
        walletIndex,
        spamScoreThresholds,
        addressMetadata,
        transfers: transfers || [],
      });

    case Apiv1Label.LABEL_STAKE:
    case Apiv1Label.LABEL_UNSTAKE:
    case 'LABEL_CLAIM' as Apiv1Label: {
      if (primaryAction?.swapDetails) {
        return parseSwap({
          primaryAction,
          historicalAssetPricesUsd,
          feeAmount,
          txNonce,
          txTimestamp,
          txStatus,
          txHash,
          userOpHash,
          userAddress,
          blockchain,
          network,
          accountId,
          walletIndex,
          spamScoreThresholds,
          addressMetadata,
          transfers: transfers || [],
        });
      }

      return parseStake({
        primaryAction,
        feeAmount,
        txNonce,
        txTimestamp,
        txStatus,
        userAddress,
        blockchain,
        network,
        accountId,
        walletIndex,
        userOpHash,
        addressMetadata,
        transfers: transfers || [],
      });
    }

    case Apiv1Label.LABEL_UNKNOWN:
    case Apiv1Label.LABEL_APPROVE:
    case Apiv1Label.LABEL_UNWRAP:
    case Apiv1Label.LABEL_WRAP:
      // TODO: Not in MVP
      break;
    case Apiv1Label.LABEL_CONTRACT_EXECUTION:
    // Fall through to default

    default:
    // Fall through to default
  }

  return parseContractExecution({
    primaryAction,
    historicalAssetPricesUsd,
    feeAmount,
    txHash,
    txNonce,
    txTimestamp,
    txStatus,
    userOpHash,
    userAddress,
    blockchain,
    network,
    accountId,
    walletIndex,
    addressMetadata,
    transfers: transfers || [],
  });
}

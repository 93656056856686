import { useCallback } from 'react';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { TrackedAsset } from 'cb-wallet-data/stores/ExchangeRates/types';
import { atomLocalStorageEffect } from 'cb-wallet-data/utils/atomLocalStorageEffect';
import { atomWithStorage } from 'cb-wallet-data/utils/objectAtomLocalStorageEffectJotai';
import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { useAtomValue, useSetAtom } from 'jotai';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

import { OnrampFiat } from './types/OnrampFiat';

type OnrampDebugOverrides = {
  onrampSimulatorHeaderEnabled?: boolean;
};

const StoreKeys_onrampDebugOverrides = new LocalStorageStoreKey<OnrampDebugOverrides>(
  'onrampDebugOverrides',
);

export const onrampDebugOverridesAtom = atom<OnrampDebugOverrides>({
  key: 'onrampDebugOverridesAtom',
  default: {
    onrampSimulatorHeaderEnabled: false,
  },
  effects: [atomLocalStorageEffect(StoreKeys_onrampDebugOverrides)],
});

const StoreKeys_openedOnrampLastUsedProviderTrayAtom = new LocalStorageStoreKey<boolean>(
  'openedOnrampLastUsedProviderTrayAtom',
);

export const openedOnrampLastUsedProviderTrayAtom = atom<boolean>({
  key: 'openedOnrampLastUsedProviderTrayAtom',
  default: false,
  effects: [atomLocalStorageEffect(StoreKeys_openedOnrampLastUsedProviderTrayAtom)],
});

export const StoreKeys_onrampFiatCurrency = new LocalStorageStoreKey<OnrampFiat | undefined>(
  'onrampFiatCurrency',
);

export const onrampFiatCurrencyAtom = atomWithStorage<OnrampFiat | undefined>({
  key: StoreKeys_onrampFiatCurrency,
  initialValue: undefined,
});

export function useOnrampFiatCurrency() {
  return useAtomValue(onrampFiatCurrencyAtom);
}

export function useSetOnrampFiatCurrency() {
  return useSetAtom(onrampFiatCurrencyAtom);
}

const StoreKeys_manuallyChangedActiveFiatCurrency = new LocalStorageStoreKey<boolean>(
  'manuallyChangedActiveFiatCurrency',
);

export const manuallyChangedActiveFiatCurrencyAtom = atomWithStorage<boolean>({
  key: StoreKeys_manuallyChangedActiveFiatCurrency,
  initialValue: false,
});

export function useManuallyChangedActiveFiatCurrency() {
  return useAtomValue(manuallyChangedActiveFiatCurrencyAtom);
}

export function useSetManuallyChangedActiveFiatCurrency() {
  return useSetAtom(manuallyChangedActiveFiatCurrencyAtom);
}

export const customOnrampTrackedExchangeRates = atom<TrackedAsset[]>({
  key: 'customOnrampTrackedExchangeRates',
  default: [],
});

/**
 * Manually start monitoring the exchange rate for some onramp crypto asset.
 *
 * If we've never tracked that asset before, it will be fetched immediately.
 *
 * If we've already tracked that asset or already have an exchange rate for it,
 * this function has no effect.
 *
 * @returns A function that can be called to start tracking the exchange rate for a crypto asset.
 */
type AddOnrampTrackedExchangeRate = {
  code: CurrencyCode;
  chainId: bigint;
  networkId: string;
  contractAddress?: string;
};
export function useOnrampAddTrackedExchangeRate(): ({
  code,
  chainId,
  networkId,
  contractAddress,
}: AddOnrampTrackedExchangeRate) => Promise<void> {
  const setOnrampTrackedExchangeRate = useSetRecoilState(customOnrampTrackedExchangeRates);

  return useCallback(
    async function refresh({
      code,
      chainId,
      networkId,
      contractAddress,
    }: AddOnrampTrackedExchangeRate) {
      setOnrampTrackedExchangeRate(function updateTrackedExchangeRate(old) {
        if (
          !old.some(
            (n) =>
              n.code === code &&
              n.chainId === chainId.toString() &&
              n.networkId === networkId &&
              n.contractAddress === contractAddress,
          )
        ) {
          return [...old, { code, chainId: chainId.toString(), networkId, contractAddress }];
        }
        return old;
      });
    },
    [setOnrampTrackedExchangeRate],
  );
}

export function useCustomOnrampTrackedExchangeRates(): TrackedAsset[] {
  return useRecoilValue(customOnrampTrackedExchangeRates);
}

import { NetworkCustomization } from 'cb-wallet-data/chains/AccountBased/Ethereum/models/CustomNetwork';
import { PossibleUTXOBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { BuyAndSwapData } from 'cb-wallet-data/stores/Web3Activation/hooks/usePersistBuyAndSwapContext';
import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { ExperimentType } from '@cbhq/experiments';

export const StoreKeys_debugMenuExperimentOverrides = new LocalStorageStoreKey<ExperimentType>(
  'debugMenuExperimentOverrides',
);

export const StoreKeys_debugMenuKillSwitchOverrides = new LocalStorageStoreKey<string[]>(
  'debugMenuKillSwitchOverrides',
);

export const StoreKeys_customNetworks = new LocalStorageStoreKey<NetworkCustomization[]>(
  'customNetworks',
);

export const StoreKeys_customizedWhitelistedNetworks = new LocalStorageStoreKey<
  NetworkCustomization[]
>('whitelistedNetworks');

/*
 * StoreKey for keeping track of active wallet index
 */
export function StoreKeys_activeIndex(blockchain: Blockchain): LocalStorageStoreKey<bigint> {
  return new LocalStorageStoreKey('activeAddressIndex', blockchain.rawValue);
}

export const StoreKeys_isBRDScanningEnabled = new LocalStorageStoreKey<boolean>(
  'isBRDScanningEnabled',
);

export const StoreKeys_unusedUTXOAddressTypes = new LocalStorageStoreKey<string[]>(
  'unusedUTXOAddressTypes',
);

export const StoreKeys_disableUTXOScanningHeuristic = new LocalStorageStoreKey<boolean>(
  'disableUTXOScanningHeuristic',
);

export function StoreKeys_BuyAndSwapContext(
  userId?: number,
): LocalStorageStoreKey<BuyAndSwapData | null> | undefined {
  if (!userId) return undefined;
  return new LocalStorageStoreKey<BuyAndSwapData | null>(`buyAndSwapContext:${userId}`);
}

export const StoreKeys_lastSyncedBlockheightBalances = new LocalStorageStoreKey<Record<
  PossibleUTXOBlockchainSymbol,
  number
> | null>('lastSyncedBlockheight');

export const StoreKeys_lastSyncedBlockheightTransactions = new LocalStorageStoreKey<Record<
  PossibleUTXOBlockchainSymbol,
  number
> | null>('lastSyncedBlockheightTransactions');

export const StoreKeys_throttleRequestLastUpdated = new LocalStorageStoreKey<Record<
  PossibleUTXOBlockchainSymbol,
  number
> | null>('throttleRequestLastUpdated');

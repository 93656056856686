import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import {
  BaseError,
  Chain,
  ChainDisconnectedError,
  createPublicClient,
  createWalletClient,
  custom,
  EIP1193Provider,
  SwitchChainError,
  WalletClient,
} from 'viem';

import { SigningMethodError, SigningMethodErrorType } from '../SigningMethodError';

/**
 * This validates that the wallet provider is on the correct chain for the current transaction
 * If the wallet provider is not on the correct chain, it will attempt to switch to the correct chain
 * If the wallet provider does not support the current chain it will attempt to add the chain
 * If all else fails it will throw a SigningMethodError
 *
 * @param {EIP1193Provider} provider web3 provider
 * @param {Chain} chain chain object for the current transaction
 * @throws {SigningMethodError} SigningMethodErrorType.UnsupportedChain - the chain you're trying to sign with is unsupported
 */
export async function validateCurrentEthereumChain(provider: EIP1193Provider, chain: Chain) {
  const publicClient = createPublicClient({
    transport: custom(provider),
    chain,
  });
  // Do nothing if the chain is already active
  const currentChainId = await publicClient.getChainId();
  if (chain.id === currentChainId) {
    return;
  }

  const walletClient = createWalletClient({
    transport: custom(provider),
    chain,
  });

  try {
    await walletClient.switchChain({ id: chain.id });
  } catch (error) {
    // try to add the chain if it's not supported
    if (
      error instanceof SwitchChainError ||
      (error instanceof BaseError && error.message.includes('Unrecognized chain ID'))
    ) {
      await addEthereumChain(walletClient, chain);
      return;
    }
    // this happens on wallets like phantom (not evm compatible).
    // phantom currently supports ETH and Polygon
    if (error instanceof ChainDisconnectedError) {
      cbReportError({
        error: coerceError(error, 'switching chains - chain disconnected error'),
        severity: 'error',
        context: 'ensure-providers-current-chain',
        isHandled: true,
      });
      throw new SigningMethodError(
        SigningMethodErrorType.UnsupportedChain,
        'wallet provider does not support this chain',
      );
    }
    const coercedError = coerceError(error, 'failed to switch chain');
    cbReportError({
      error: coercedError,
      severity: 'error',
      context: 'ensure-providers-current-chain',
      isHandled: true,
    });
    throw new SigningMethodError(SigningMethodErrorType.Generic, coercedError.message);
  }
}

async function addEthereumChain(walletClient: WalletClient, chain: Chain) {
  try {
    await walletClient.addChain({ chain });
  } catch (error) {
    const coercedError = coerceError(error, 'Failed to add chain');
    cbReportError({
      error: coerceError(coercedError, 'Failed to add chain'),
      severity: 'error',
      context: 'ensure-providers-current-chain',
      isHandled: true,
    });
    throw new SigningMethodError(SigningMethodErrorType.Generic, coercedError.message);
  }
}

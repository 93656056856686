import { useOverridableKillSwitch } from 'cb-wallet-data/hooks/KillSwitches/useOverridableKillSwitch';
import { getKillSwitchForPlatform } from 'cb-wallet-data/hooks/KillSwitches/utils/getKillSwitchForPlatform';

/**
 * Returns whether a user has a kill switch enabled for their platform, taking into account local overrides.
 *
 * @param killSwitchName - the killswitch name without the platform suffix
 * @returns A boolean indicating if the kill switch is enabled for the user's platform
 */
export function useOverridableKillSwitchForPlatform(killSwitchName: string | null): boolean {
  const platformSpecificKillSwitch = getKillSwitchForPlatform(killSwitchName);
  return useOverridableKillSwitch(platformSpecificKillSwitch);
}

import { SigningAndDerivationMethods } from '../types/SigningAndDerivationMethods';

/**
 * Throw an error when an unimplemented method is called.
 *
 * Can be used to register methods to the signingAndDerivationMethods service locator,
 * when not required for an app.
 */
export function throwUnimplementedError(
  methodName: keyof SigningAndDerivationMethods,
): (...args: any[]) => Promise<never> {
  return async function unimplementedServiceLocatorImplementation(_params) {
    throw new Error(`Unimplemented method ${methodName}`);
  };
}

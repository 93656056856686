const cache = new WeakMap<object, unknown>();

/**
 * Memoize an expensive computation globally. The cache is based
 * on the value passed. It is stored in a WeakMap so the cached
 * value will be released when the value it is based on is.
 *
 * The main use case for this currently is to memoize expensive
 * transforms from values returned by react-query. Note that this
 * might be replaced with a better more generic api in the future.
 */
export function memoizeDerivedValue<ValueT extends object, ResT>(
  value: ValueT,
  transform: () => ResT,
): ResT {
  const cached = cache.get(value);
  if (cached !== undefined) {
    return cached as ResT;
  }
  const result = transform();
  cache.set(value, result);
  return result;
}

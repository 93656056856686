import { useCallback, useMemo, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useAccountsWithBalances } from 'cb-wallet-data/stores/Accounts/hooks/useAccountsWithBalances';
import { OnrampNetwork } from 'cb-wallet-data/stores/Buy/types/OnrampNetwork';
import { CellSpacing } from '@cbhq/cds-common';
import { ListCell } from '@cbhq/cds-web/cells';
import { Dropdown, DropdownRefProps } from '@cbhq/cds-web/dropdown';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { RemoteImage } from '@cbhq/cds-web/media';
import { PressableOpacity } from '@cbhq/cds-web/system';
import { TextCaption, TextHeadline, TextLabel1, TextLabel2 } from '@cbhq/cds-web/typography';

import { usePanoOnrampContext } from ':dapp/providers/PanoOnrampContext';

const messages = defineMessages({
  network: {
    defaultMessage: 'On',
    description: 'Onramp flow label to indicate what is the network currently selected',
  },
  title: {
    defaultMessage: 'Select Network',
    description: 'Title for select network screen',
  },
});

export const onRampDefaultMainnets = {
  'bitcoin-mainnet': 'BTC',
  'dogecoin-mainnet': 'DOGE',
  'litecoin-mainnet': 'LTC',
  'solana-mainnet': 'SOL',
  'ethereum-mainnet': 'ETH',
};

export type OnRampDefaultMainnets = keyof typeof onRampDefaultMainnets;
export type WalletChain = 'ETH' | 'SOL' | 'BTC' | 'DOGE' | 'LTC';

export function NetworkSwitcher() {
  const { formatMessage } = useIntl();
  const { selectedAsset } = usePanoOnrampContext();

  const networkSwitcher = useMemo(() => {
    if (!selectedAsset) return null;

    const networkChain = selectedAsset.network?.asChain();
    return (
      <HStack>
        <PressableOpacity testID="onramp-entry-network-switcher">
          <HStack
            gap={1}
            spacing={1}
            borderRadius="roundedLarge"
            background="backgroundAlternate"
            alignItems="center"
          >
            <RemoteImage
              source={networkChain?.chainImageUrl ?? selectedAsset?.imageUrl}
              resizeMode="cover"
              shape="circle"
              height={20}
              width={20}
            />
            <TextLabel2 as="p">
              {formatMessage(messages.network)}{' '}
              <TextLabel1 as="span">
                {networkChain?.displayName ?? selectedAsset?.networkName}
              </TextLabel1>
            </TextLabel2>
            <Icon name="caretDown" size="xs" color="foregroundMuted" spacingStart={0.5} />
          </HStack>
        </PressableOpacity>
      </HStack>
    );
  }, [selectedAsset, formatMessage]);

  return networkSwitcher;
}

type SelectNetworkDropdownContentProps = {
  dRef: React.MutableRefObject<DropdownRefProps | null>;
};

function NetworkSwitcherContent({ dRef }: SelectNetworkDropdownContentProps) {
  const { formatMessage } = useIntl();

  const cellSpacing = useMemo<CellSpacing>(() => {
    return {
      spacingHorizontal: 2,
      spacingVertical: 0,
    };
  }, []);

  const { networks = [], assets = [], setSelectedAsset, selectedAsset } = usePanoOnrampContext();

  const accountsWithBalance = useAccountsWithBalances();

  const filteredNetworks = useMemo(() => {
    const networkList: OnrampNetwork[] = [];
    const availableChains = [
      ...new Set(accountsWithBalance.map((account) => account.primaryAddressChain)),
    ];

    networks.forEach((network) => {
      const mainnet = onRampDefaultMainnets[network.id as OnRampDefaultMainnets];
      if (mainnet && availableChains.includes(mainnet as WalletChain)) {
        networkList.push(network);
      }
      if (
        !mainnet &&
        availableChains.includes('ETH') &&
        !Object.keys(onRampDefaultMainnets).includes(network.id)
      ) {
        networkList.push(network);
      }
    });
    return networkList;
  }, [accountsWithBalance, networks]);

  const handleNetworkChange = useCallback(
    (e: OnrampNetwork) => () => {
      const networkAsset = assets?.filter((asset) => asset.networkId === e.id)[0];
      setSelectedAsset(networkAsset);
      dRef?.current?.closeMenu();
    },
    [assets, dRef, setSelectedAsset],
  );

  return (
    <VStack height="100%" width="100%" overflow="hidden" testID="dropdown-content">
      <Box spacingHorizontal={2} spacingTop={2}>
        <TextCaption testID="dropdown-content-title" as="h2" numberOfLines={1}>
          {formatMessage(messages.title)}
        </TextCaption>
      </Box>
      <VStack width="100%" overflow="auto">
        {filteredNetworks.map((network) => (
          <ListCell
            key={network.id}
            testID={`network-list-item-${network.chainId}`}
            variant="positive"
            selected={network.id === selectedAsset?.networkId}
            onPress={handleNetworkChange(network)}
            title={
              <Box maxWidth={150}>
                <TextHeadline as="p" overflow="truncate">
                  {network.name}
                </TextHeadline>
              </Box>
            }
            outerSpacing={cellSpacing}
            media={
              <RemoteImage
                testID="network-selector-image"
                source={network.imageUrl ?? ''}
                height={32}
                width={32}
              />
            }
          />
        ))}
      </VStack>
    </VStack>
  );
}

export function NetworkSwitcherDropdown() {
  const dropdownRef = useRef<DropdownRefProps | null>(null);
  const content = useMemo(() => {
    return <NetworkSwitcherContent dRef={dropdownRef} />;
  }, []);

  return (
    <Dropdown
      content={content}
      enableMobileModal
      maxHeight={400}
      maxWidth={325}
      minWidth={325}
      ref={dropdownRef}
    >
      <NetworkSwitcher />
    </Dropdown>
  );
}

import { Framework, Region, TrackerType, TrackingCategory } from '@coinbase/cookie-manager';

export const cookieManagerConfig = {
  categories: [
    {
      id: TrackingCategory.NECESSARY,
      expiry: 365,
      required: true,
      trackers: [
        {
          id: 'cookies-banner',
          type: TrackerType.COOKIE,
        },
      ],
    },
    {
      id: TrackingCategory.NECESSARY,
      expiry: 365,
      required: true,
      trackers: [
        {
          id: 'hasL1WarningSeen',
          type: TrackerType.COOKIE,
        },
      ],
    },
    {
      id: TrackingCategory.FUNCTIONAL,
      expiry: 365,
      required: true,
      trackers: [
        {
          id: 'spectrum',
          type: TrackerType.COOKIE,
        },
      ],
    },
  ],
  geolocationRules: [
    {
      region: Region.DEFAULT,
      framework: Framework.OPT_OUT,
    },
    {
      region: Region.EU,
      framework: Framework.OPT_IN,
    },
  ],
};

/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { AddressType } from 'cb-wallet-data/stores/Addresses/models/AddressType';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { NetworkSetting } from 'cb-wallet-data/stores/Networks/models/NetworkSetting';
import { WalletConfiguration } from 'cb-wallet-data/stores/Wallets/models/WalletConfiguration';

interface IUTXOConfiguration {
  readonly supportedAddressTypes: AddressType[];
  readonly blockchain: Blockchain;
  readonly currencyName: string;
  readonly currencyCode: CurrencyCode;
  readonly decimals: bigint;
  readonly imageURL: string | undefined;
  readonly defaultReceiveType: AddressType;
  readonly txResubmitterInterval: bigint;
  readonly maxSubmissionAttempts: bigint;
  readonly qrCodeUriMainnetScheme: string;
  readonly qrCodeUriTestnetScheme: string;
  readonly networkSetting: NetworkSetting;
  readonly refreshInterval: bigint;
  isDerivationPathSupported?: (addressType: AddressType, isTestnet: boolean) => boolean;
}

export interface UTXOConfiguration extends IUTXOConfiguration {}

/**
 * Represents base configuration for integrating a bitcoin-like blockchain
 *
 * @property supportedAddressTypes List of supported address formats
 * @property service WalletEngine service implementation
 * @property blockchain HDWallet blockchain
 * @property currencyName HDWallet currency name
 * @property currencyCodeForNetwork HDWallet currency code
 * @property decimals HDWallet decimals
 * @property imageURL HDWallet image url
 * @property defaultReceiveType Default supported address format
 * @property txResubmitterInterval Interval for how often to run transaction resubmitter
 * @property maxSubmissionAttempts Max number of transaction resubmissions with status="notFound" before marking a
 *                                 transaction failed
 * @property qrCodeUriMainnetScheme QR code parser mainnet URI scheme
 * @property qrCodeUriTestnetScheme QR code parser testnet URI scheme
 * @property networkSetting Contains list of supported networks and default network setting
 * @property refreshInterval Balance refresh interval in seconds
 * open val isSyncingRequired: Boolean
 */
export class UTXOConfiguration extends WalletConfiguration {
  constructor(props: IUTXOConfiguration) {
    super({
      blockchain: props.blockchain,
      networkSetting: props.networkSetting,
      refreshInterval: props.refreshInterval,
      currencyCode: props.currencyCode,
      decimals: props.decimals,
      imageURL: props.imageURL,
      defaultReceiveType: props.defaultReceiveType,
      supportsMultiWallet: false,
      isSyncingRequired: true,
    });

    Object.assign(this, props);
  }

  override displayName(network: Network) {
    return network.isTestnet ? `Testnet ${this.currencyName}` : this.currencyName;
  }
}

import { useEffect } from 'react';
import { useAccounts } from 'cb-wallet-data/stores/Accounts/hooks/useAccounts';
import { atom, useAtomValue, useSetAtom } from 'jotai';

import { providerLocator } from '../ProviderLocator';

const providersReady = atom<boolean>(false);

/**
 * Registers all providers from the accounts store with the provider locator.
 * This is an effect that is used to keep the accounts and the provider locator in sync.
 *
 * It should be called only once at the top of the application
 */
export function useSyncProviderLocator() {
  const accounts = useAccounts();
  const setProvidersReady = useSetAtom(providersReady);

  useEffect(
    function initializeProviderLocatorEffect() {
      accounts.forEach(function initializeProviderLocator(account) {
        if (!account.provider) return;
        providerLocator.register(account.id, account.provider);
      });

      // Remove provider locator entries that dont have a corresponding account
      providerLocator.ids().forEach((id) => {
        if (!accounts.find((account) => account.id === id)) {
          providerLocator.unregister(id);
        }
      });
      setProvidersReady(true);
    },
    [accounts, setProvidersReady],
  );
}

export function useProvidersReady() {
  return useAtomValue(providersReady);
}

import { memo, useCallback } from 'react';
import { useHasAccountsAndWalletGroups } from 'cb-wallet-data/hooks/initialization/useHasAccountsAndWalletGroups';
import { SearchEntity } from 'cb-wallet-data/stores/Search/types';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { Box } from '@cbhq/cds-web/layout';
import { TextLabel1 } from '@cbhq/cds-web/typography/TextLabel1';
import { baseGlobalSearchFilters, GlobalSearchFilterItem, globalSearchFiltersWithSettings } from ':dapp/components/Search/constants';
type SearchFilterProps = {
  selectedFilter: SearchEntity;
  setSelectedFilter: (filter: SearchEntity) => void;
};
const searchFilterItemStyles = {
  cursor: 'pointer'
} as const;
const hideScrollBar = "h171qgq4";
export const SearchFilter = memo(function SearchFilter({
  selectedFilter,
  setSelectedFilter
}: SearchFilterProps) {
  const {
    isPhone
  } = useBreakpoints();
  const hasAccountsAndWalletGroups = useHasAccountsAndWalletGroups();
  const handleFilterItemPress = useCallback((item: GlobalSearchFilterItem) => {
    return () => {
      setSelectedFilter(item.id);
    };
  }, [setSelectedFilter]);
  const searchFilters = hasAccountsAndWalletGroups ? globalSearchFiltersWithSettings : baseGlobalSearchFilters;
  return <Box overflow={isPhone ? 'scroll' : undefined} testID="global-search-filter" spacingTop={2} spacingBottom={1} spacingStart={2} className={isPhone ? hideScrollBar : undefined}>
      {searchFilters.map((item: GlobalSearchFilterItem) => {
      return <Box spacingEnd={1} key={item.id}>
            <Box minWidth="40px" maxWidth="84px" borderRadius="roundedFull" background={selectedFilter === item.id ? 'primary' : 'secondary'} justifyContent="center" alignItems="center" spacingHorizontal={2} spacingVertical={1} onClick={handleFilterItemPress(item)} testID={`global-search-filter-${item.label}`} style={searchFilterItemStyles}>
              <TextLabel1 as="p" color={selectedFilter === item.id ? 'primaryForeground' : 'foreground'}>
                {item.label}
              </TextLabel1>
            </Box>
          </Box>;
    })}
    </Box>;
});

require("./SearchFilter.linaria.module.css!=!../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./SearchFilter.tsx");
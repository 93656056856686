import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { Decimal } from 'decimal.js';

export function getTotalBalanceFromWallets(
  wallets: Wallet[],
  exchangeRatesMap: Record<string, Decimal> = {},
  includeTestnet = false,
): Decimal {
  const computedBalances: Decimal[] = [];
  wallets
    .filter((w: Wallet) => {
      return includeTestnet || !w.network.isTestnet;
    })
    .forEach(
      // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
      // eslint-disable-next-line wallet/no-anonymous-params
      (w: Wallet) => {
        const fiatBalance = w.fiatBalance({ exchangeRatesMap });
        computedBalances.push(fiatBalance);
      },
    );

  return computedBalances.reduce((acc, value) => {
    return acc.plus(value);
  }, new Decimal(0));
}

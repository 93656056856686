import { DataMigration, DataMigrationParams } from 'cb-wallet-data/DataMigrationRunner/types';
import { getWalletGroups, saveWalletGroups } from 'cb-wallet-data/stores/WalletGroups/database';
import { WalletGroup } from 'cb-wallet-data/stores/WalletGroups/models/WalletGroup';

const migrateThemeColor: DataMigration = {
  // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
  // eslint-disable-next-line wallet/no-anonymous-params
  up: async (params: DataMigrationParams) => {
    // eslint-disable-next-line no-console
    console.log('Migration: migrateThemeColor');

    const walletGroups = await getWalletGroups();
    if (!walletGroups.length) {
      return;
    }

    const { themeColor } = params;

    const updatedWalletGroups = walletGroups.map((wg) => new WalletGroup({ ...wg, themeColor }));

    await saveWalletGroups(updatedWalletGroups);
  },
};

export default migrateThemeColor;

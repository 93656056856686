import { SignAndSubmitETH2930TransactionWithWeb3 } from 'cb-wallet-data/ServiceLocator/signingAndDerivation/types/SignAndSubmitETH2930Transaction';

import { baseSignAndSubmitETHTransaction } from ':dapp/datalayer/web3/utils/baseSignAndSubmitETHTransaction';

/**
 * Signs an eip 2930 transaction using the web3 providers signTransaction method
 * signETH2930Transaction emits SigningMethodErrors errors, which should be handled by the caller
 *
 * see baseSignAndSubmitETHTransaction (:dapp/datalayer/web3/utils/baseSignAndSubmitETHTransaction) for more details
 */
export async function signAndSubmitETH2930Transaction(
  params: Parameters<SignAndSubmitETH2930TransactionWithWeb3>[0],
): ReturnType<SignAndSubmitETH2930TransactionWithWeb3> {
  return baseSignAndSubmitETHTransaction(params);
}

import { useMemo } from 'react';
import { fiatCodesWithAlts } from 'cb-wallet-data/stores/Currencies/LocaleCurrencies';

import { FiatExchangeRate } from '../api';

/**
 * Returns the fiat exchange rates with any overridden rates
 * applied. Overrides come from useQueryOverriddenRates.
 *
 * Overrides are usually done for one-off situations where a certain geo has
 * an unofficial rate that's not yet supported by the asset price service
 *
 * @param fiatRates - The original fiat exchange rates
 * @returns The fiat exchange rates with any overridden rates applied
 */
export function useOverriddenExchangeRates(fiatRates: FiatExchangeRate[] | undefined) {
  return useMemo(() => {
    // Manually-defined overrides for known currencies
    for (const [fiatCode, altCode] of Object.entries(fiatCodesWithAlts)) {
      const fiatRate = fiatRates?.find((f) => f.currencyCode === fiatCode);
      const altFiatRate = fiatRates?.find((f) => f.currencyCode === altCode);
      if (fiatRate && altFiatRate) {
        fiatRate.rateRelativeToUSD = altFiatRate.rateRelativeToUSD;
      }
    }

    return fiatRates;
  }, [fiatRates]);
}

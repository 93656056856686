import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useCoinbaseOAuthAccessToken } from 'cb-wallet-data/hooks/useCoinbaseOAuthAccessToken';
import type { FetchOptionsType } from 'cb-wallet-http/fetchJSON';

import { onrampServiceAuthedConfig, onrampServiceConfig } from '../config';

type OnrampRequestConfig = Pick<
  FetchOptionsType,
  'walletApi' | 'additionalHeaders' | 'apiVersion' | 'service' | 'withRetailToken'
>;

export function useOnrampRequestConfig(): OnrampRequestConfig {
  const { locale } = useIntl();
  const { accessToken: retailAccessToken, isLoading } = useCoinbaseOAuthAccessToken();
  const isConnected = useMemo(
    () => Boolean(retailAccessToken) && !isLoading,
    [isLoading, retailAccessToken],
  );

  const serviceConfig = useMemo(
    () => (isConnected ? onrampServiceAuthedConfig : onrampServiceConfig),
    [isConnected],
  );

  const config = useMemo<OnrampRequestConfig>(
    () => ({
      walletApi: 'ONRAMP_API',
      withRetailToken: isConnected,
      additionalHeaders: {
        'accept-language': locale,
      },
      ...serviceConfig,
    }),
    [isConnected, locale, serviceConfig],
  );

  return config;
}

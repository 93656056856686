import { useEffect, useMemo, useRef } from 'react';
import { useHasChanged } from 'cb-wallet-data/hooks/useHasChanged';
import { useGetAccountById } from 'cb-wallet-data/stores/Accounts/hooks/useGetAccountById';
import { useExchangeRatesMap } from 'cb-wallet-data/stores/ExchangeRates/hooks/useExchangeRates';
import { useWalletGroups } from 'cb-wallet-data/stores/WalletGroups/hooks/useWalletGroups';
import { unstable_batchedUpdates } from 'cb-wallet-data/utils/batchUpdates';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  fiatBalanceByWalletGroupAtom,
  walletIdsToWalletGroupIdsMapAtom,
  walletsArraySelector,
  walletsByWalletGroupAtom,
} from '../state';
import { computeFiatBalancesByWalletGroup } from '../utils/computeFiatBalancesByWalletGroup';
import { prepareWalletsByWalletGroup } from '../utils/prepareWalletsByWalletGroup';

import { useGetIsVisibleWallet } from './useGetIsVisibleWallet';
import { useIsValidWallet } from './useIsValidWallet';

/**
 * Manages cached state of wallets mapped by wallet group.
 *
 * NOTE: This should only be called once at the top of the app.
 */
export function useCacheWalletsByWalletGroup() {
  const getAccountById = useGetAccountById();
  const isValidWallet = useIsValidWallet();
  const isVisibleWallet = useGetIsVisibleWallet();
  const walletGroups = useWalletGroups();
  const setWalletsByWalletGroup = useSetRecoilState(walletsByWalletGroupAtom);

  const allWallets = useRecoilValue(walletsArraySelector);
  const currWalletIdsToWalletGroupIds = useRecoilValue(walletIdsToWalletGroupIdsMapAtom);
  const setWalletIdsToWalletGroupIds = useSetRecoilState(walletIdsToWalletGroupIdsMapAtom);
  const setFiatBalancesToWalletGroupIds = useSetRecoilState(fiatBalanceByWalletGroupAtom);
  const prevWalletIdsToWalletGroupIdsRef = useRef(useRecoilValue(walletIdsToWalletGroupIdsMapAtom)); // Boxed reference of state value, that can safely be passed a dependency to computeAndCacheWalletsByWalletGroup.
  const exchangeRatesMap = useExchangeRatesMap();

  /**
   * Maintains a boxed reference to the computed walletIdsToWalletGroupIds
   * state, which can safely be passed to computeAndCacheWalletsByWalletGroup.
   *
   * This provides a performance enhancement to speed up matching wallets to
   * wallet groups, by utilizing any previous found relationships.
   *
   * This works because once a wallet has been matched to a wallet group, that
   * relationship should never change during app lifecycle.
   */
  useEffect(
    function cachePrevWalletIdsToWalletGroupIds() {
      prevWalletIdsToWalletGroupIdsRef.current = currWalletIdsToWalletGroupIds;
    },
    [currWalletIdsToWalletGroupIds, prevWalletIdsToWalletGroupIdsRef],
  );

  /**
   * Continuously recompute and cache walletsByWalletGroup.
   * Most often triggered by balance refreshing updating walletsAtom.
   *
   * WARNING!!! BE VERY CAREFUL WHEN ADDING DEPENDENCIES HERE!!!
   *
   * If a dependency recomputes often it will lead to way more renders. This
   * should all be moved into a recoil selector. Reach out to @zac.marion and
   * @jason.bobich before changing
   */
  const { walletsByWalletGroup, walletIdsToWalletGroupIds } = useMemo(() => {
    return prepareWalletsByWalletGroup({
      wallets: allWallets,
      walletGroups,
      getAccountById,
      isValidWallet,
      prevWalletIdsToWalletGroupIds: prevWalletIdsToWalletGroupIdsRef.current,
    });
  }, [allWallets, walletGroups, getAccountById, isValidWallet, prevWalletIdsToWalletGroupIdsRef]);

  /**
   * Because exchange rates changes more often than any other dependency
   * we separately compute fiatBalancesByWalletGroup instead of doing it all
   * in the same prepareWalletsByWalletGroup function
   */
  const fiatBalancesByWalletGroup = useMemo(() => {
    return computeFiatBalancesByWalletGroup({
      wallets: allWallets,
      exchangeRatesMap,
      isVisibleWallet,
      getAccountById,
      walletGroups,
      walletIdsToWalletGroupIds,
    });
  }, [
    allWallets,
    exchangeRatesMap,
    isVisibleWallet,
    getAccountById,
    walletGroups,
    walletIdsToWalletGroupIds,
  ]);

  const hasWalletsByWalletGroupChanged = useHasChanged(walletsByWalletGroup);
  const hasWalletIdsToWalletGroupIdsChanged = useHasChanged(walletIdsToWalletGroupIds);
  const hasFiatBalancesByWalletGroupChanged = useHasChanged(fiatBalancesByWalletGroup);

  useEffect(() => {
    /**
     * Batch updates here as this is quite expensive and we don't want
     * it to result in 3 renders
     */
    unstable_batchedUpdates(function batchWalletUpdates() {
      if (hasWalletsByWalletGroupChanged) {
        setWalletsByWalletGroup(walletsByWalletGroup);
      }
      if (hasWalletIdsToWalletGroupIdsChanged) {
        setWalletIdsToWalletGroupIds(walletIdsToWalletGroupIds);
      }
      if (hasFiatBalancesByWalletGroupChanged) {
        setFiatBalancesToWalletGroupIds(fiatBalancesByWalletGroup);
      }
    });
  }, [
    hasWalletsByWalletGroupChanged,
    hasWalletIdsToWalletGroupIdsChanged,
    hasFiatBalancesByWalletGroupChanged,
    walletsByWalletGroup,
    walletIdsToWalletGroupIds,
    setWalletsByWalletGroup,
    setFiatBalancesToWalletGroupIds,
    fiatBalancesByWalletGroup,
    setWalletIdsToWalletGroupIds,
  ]);
}

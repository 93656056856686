import BN from 'bn.js'; // those functions are used on the communication between the data layer end the wallet-engine
import createKeccakHash from 'keccak';
import padStart from 'lodash/padStart';

const HEXADECIMAL_STRING_REGEX = /^[a-f0-9]*$/;

export function isHexString(hex: string): boolean {
  const s = strip0x(hex).toLowerCase();
  return !!s.match(HEXADECIMAL_STRING_REGEX);
}

export function ensureHexString(hex: string): string {
  const s = strip0x(hex).toLowerCase();
  if (!s.match(HEXADECIMAL_STRING_REGEX)) {
    throw new Error(`"${hex}" is not a hexadecimal string`);
  }
  return s;
}

export function addressArgument(address: string): string {
  return padStart(strip0x(address).toLowerCase(), 64, '0');
}

export function bnFromNumber(num: number): BN {
  const intNum = Math.floor(num);
  if (intNum <= Number.MAX_SAFE_INTEGER) {
    return new BN(intNum);
  }
  return new BN(intNum.toString(10));
}

export function bnFromBigInt(bi: bigint): BN {
  return new BN(bi.toString(10));
}

export function bigIntFromNumber(num: number): bigint {
  const intNum = Math.floor(num);
  if (intNum <= Number.MAX_SAFE_INTEGER) {
    return BigInt(intNum);
  }
  return BigInt(intNum.toString(10));
}

export function bigIntFromHex(hex: string): bigint {
  if (hex.length === 0) {
    return BigInt(0);
  }
  return BigInt(`0x${ensureHexString(hex)}`);
}

export function bnFromHex(hex: string): BN {
  if (hex.length === 0) {
    return new BN(0);
  }
  return new BN(ensureHexString(hex), 16);
}

export function hexFromNumber(num: number, includePrefix = true): string {
  const hex = bnFromNumber(num).toString(16);
  return includePrefix ? `0x${hex}` : hex;
}

export function hexFromBN(bn: BN, includePrefix = true): string {
  const hex = bn.toString(16);
  return includePrefix ? `0x${hex}` : hex;
}

export function hexFromBigInt(bi: bigint, includePrefix = true): string {
  const hex = bi.toString(16);
  return includePrefix ? `0x${hex}` : hex;
}

export function hexFromBuffer(buf: Buffer, includePrefix = true): string {
  const hex = buf.toString('hex');
  return includePrefix ? `0x${hex}` : hex;
}

export function evenLengthHex(hex: string, includePrefix = true): string {
  let h = ensureHexString(hex);
  if (h.length % 2 === 1) {
    h = `0${h}`;
  }
  return includePrefix ? `0x${h}` : h;
}

export function numberFromHex(hex: string): number {
  const bn = bnFromHex(hex);
  if (bn.lte(new BN(Number.MAX_SAFE_INTEGER))) {
    return bn.toNumber();
  }
  return Number(bn.toString(10));
}

export function bufferFromHex(hex: string): Buffer {
  if (hex.length === 0) {
    return Buffer.alloc(0);
  }
  return Buffer.from(evenLengthHex(hex, false), 'hex');
}

export function bufferFromNumber(num: number, blankZero = true): Buffer {
  if (num === 0 && blankZero) {
    return Buffer.alloc(0);
  }
  return bufferFromHex(hexFromNumber(num, false));
}

export function bufferFromBigInt(value: bigint, blankZero = true): Buffer {
  if (value === BigInt(0) && blankZero) {
    return Buffer.alloc(0);
  }
  return bufferFromNumber(Number(value), false);
}

export function bufferFromBN(bn: BN, blankZero = true): Buffer {
  if (bn.isZero() && blankZero) {
    return Buffer.alloc(0);
  }
  return bn.toArrayLike(Buffer);
}

export function keccak256(data: Buffer | string): Buffer {
  const buf = data instanceof Buffer ? data : Buffer.from(data, 'utf8');
  return createKeccakHash('keccak256').update(buf).digest();
}

export function strip0x(hex: string): string {
  if (hex.startsWith('0x') || hex.startsWith('0X')) {
    return hex.slice(2);
  }
  return hex;
}

export function prepend0x(hex: string): string {
  if (hex.startsWith('0x') || hex.startsWith('0X')) {
    return `0x${hex.slice(2)}`;
  }
  return `0x${hex}`;
}

export function parseABIString(hex: string): string {
  const h = strip0x(hex);
  const len = numberFromHex(h.slice(64, 128));
  if (h.replace(/0/g, '') === '') {
    return '';
  }
  if (h.length <= 64) {
    return bufferFromHex(h).toString('utf8').replace(/\0+$/g, '');
  }
  return bufferFromHex(h.slice(128)).toString('utf8').slice(0, len);
}

export function testEcho(message: string): string {
  return message;
}

export function testThrow(errorMessage: string): string {
  throw new Error(errorMessage);
}

import { useCallback, useEffect, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useAccountsWithBalances } from 'cb-wallet-data/stores/Accounts/hooks/useAccountsWithBalances';
import { AssetAvatar } from 'wallet-cds-web/components/AssetAvatar';
import { FormattedAddressWithDomain } from 'wallet-cds-web/components/FormattedAddressWithDomain';
import { ProfileAvatar } from 'wallet-cds-web/components/ProfileAvatar';
import { useStepper } from 'wallet-cds-web/external-libs/stepper';

import { stepper } from ':dapp/pages/buy/components/BuyFlowStepper/flow';
import { BuyFlowStepsEnum } from ':dapp/pages/buy/components/BuyFlowStepper/steps';
import { useGlobalEventMetadataContext } from ':dapp/providers/GlobalEventsProvider';
import { usePanoOnrampContext } from ':dapp/providers/PanoOnrampContext';

const messages = defineMessages({
  buyLabel: {
    defaultMessage: 'Buy',
    description: 'Label for onramp flow that indicates what asset to buy',
  },
  destinationWallet: {
    defaultMessage: 'Destination wallet address',
    description: 'Onramp flow label to indicate destination wallet address',
  },
});

export function useBuyAssetSelectorGroupValues() {
  const { formatMessage } = useIntl();
  const { goToStep } = useStepper(stepper);
  const { selectedAsset, sourceWallet } = usePanoOnrampContext();
  const { setDappProviderUserId } = useGlobalEventMetadataContext();

  const accountsWithBalance = useAccountsWithBalances();
  const initWallet = accountsWithBalance.filter(
    (account) => account.primaryAddressChain === 'ETH',
  )[0];

  const selectedWallet = sourceWallet || initWallet;
  const address = selectedWallet?.primaryAddressForDapp;

  useEffect(
    () => setDappProviderUserId(selectedWallet.dappProviderUserId),
    [selectedWallet.dappProviderUserId, setDappProviderUserId],
  );

  const handleAssetSelected = useCallback(() => {
    goToStep(BuyFlowStepsEnum.ASSET_SELECTOR);
  }, [goToStep]);

  const handlePressSelectAsset = useCallback(() => {
    goToStep(BuyFlowStepsEnum.WALLET_SELECTOR);
  }, [goToStep]);

  const primarySelector = useMemo(() => {
    return {
      title: formatMessage(messages.buyLabel),
      subtitle: selectedAsset?.code,
      onClick: handleAssetSelected,
      media: (
        <AssetAvatar
          assetSrc={selectedAsset?.imageUrl}
          assetCurrencyCode={selectedAsset?.code}
          networkBadgeSrc={
            selectedAsset?.network ? selectedAsset.network.asChain()?.chainImageUrl : undefined
          }
        />
      ),
      testID: 'onramp-asset-selector',
    };
  }, [
    formatMessage,
    handleAssetSelected,
    selectedAsset?.code,
    selectedAsset?.imageUrl,
    selectedAsset?.network,
  ]);

  const secondarySelector = useMemo(() => {
    return {
      title: formatMessage(messages.destinationWallet),
      subtitle: <FormattedAddressWithDomain compact variant="foregroundMuted" address={address} />,
      onClick: handlePressSelectAsset,
      media: <ProfileAvatar alt={address} address={address} />,
      testID: 'pano-onramp-entry-wallet-selector',
    };
  }, [address, formatMessage, handlePressSelectAsset]);

  return useMemo(
    () => ({
      primarySelector,
      secondarySelector,
    }),
    [primarySelector, secondarySelector],
  );
}

import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { AllPossibleBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import {
  AccountWithBalance,
  useAccountsWithBalances,
} from 'cb-wallet-data/stores/Accounts/hooks/useAccountsWithBalances';

import { useGlobalEventMetadataContext } from ':dapp/providers/GlobalEventsProvider';

export enum WalletPreferenceFeature {
  Mint,
  Swap,
  Quests,
  OCS,
}

function isWalletPreferenceFeature(value: unknown): value is WalletPreferenceFeature {
  if (typeof value !== 'number') return false;
  return Object.values(WalletPreferenceFeature).includes(value);
}

export type PreferredWallets = {
  account: AccountWithBalance | undefined;
  accountsWithBalances: AccountWithBalance[];
  setPreferredAccount: (wallet: AccountWithBalance) => void;
  hasPreferredWallet: (feature: WalletPreferenceFeature) => boolean;
  deletePreferredWallet: (address: string) => void;
};

type UsePreferredWalletProps = {
  feature: WalletPreferenceFeature;
  blockchainSymbol: AllPossibleBlockchainSymbol;
};

function getQueryKey({
  feature,
  blockchainSymbol,
  storageKey,
  accountsLength,
}: {
  feature: WalletPreferenceFeature;
  blockchainSymbol: AllPossibleBlockchainSymbol;
  storageKey: string;
  accountsLength: number;
}) {
  return ['preferred-account', storageKey, feature, blockchainSymbol, accountsLength];
}

export function usePreferredAccount(opts: UsePreferredWalletProps): PreferredWallets {
  const { feature, blockchainSymbol } = opts;
  const accountsWithBalances = useAccountsWithBalances();
  const queryClient = useQueryClient();
  const storageKey = `wallet_pref_${feature.toString()}`;
  const accountsLength = accountsWithBalances.length;
  const { setDappProviderUserId } = useGlobalEventMetadataContext();

  const { data } = useQuery({
    queryKey: getQueryKey({
      feature,
      blockchainSymbol,
      storageKey,
      accountsLength,
    }),
    queryFn: () => {
      const walletsForChain = accountsWithBalances.filter(
        (b) => b.primaryAddressChain === opts.blockchainSymbol,
      );

      const storedAccountAddress = localStorage.getItem(storageKey);
      const storedAccount = storedAccountAddress
        ? accountsWithBalances.find(
            (b) => b.primaryAddress.toLowerCase() === storedAccountAddress.toLowerCase(),
          )
        : undefined;

      if (storedAccount) {
        return storedAccount;
      }
      if (walletsForChain.length > 0) {
        return walletsForChain[0];
      }
      return null;
    },
  });

  const setPreferredWallet = (selectedWallet: AccountWithBalance) => {
    setDappProviderUserId(selectedWallet?.dappProviderUserId);
    localStorage.setItem(storageKey, selectedWallet.primaryAddressForDapp);
    queryClient.setQueryData(
      getQueryKey({
        feature,
        blockchainSymbol,
        storageKey,
        accountsLength,
      }),
      selectedWallet,
    );
  };

  const hasPreferredWallet = (preferenceFeature: WalletPreferenceFeature) => {
    return !!localStorage.getItem(`wallet_pref_${preferenceFeature.toString()}`);
  };

  const deletePreferredWallet = (address: string) => {
    Object.values(WalletPreferenceFeature).forEach((preferenceFeature) => {
      if (!isWalletPreferenceFeature(preferenceFeature)) return;
      const walletPrefKey = `wallet_pref_${preferenceFeature.toString()}`;
      const storedWallet = localStorage.getItem(walletPrefKey);
      if (storedWallet && storedWallet.toLowerCase() === address.toLowerCase()) {
        localStorage.removeItem(walletPrefKey);
        queryClient.setQueryData(
          getQueryKey({
            feature: preferenceFeature,
            blockchainSymbol,
            storageKey: walletPrefKey,
            accountsLength,
          }),
          null,
        );
      }
    });
  };

  const account = data ?? undefined;

  useEffect(
    function setMetadata() {
      setDappProviderUserId(account?.dappProviderUserId);
    },
    [account?.dappProviderUserId, setDappProviderUserId],
  );

  return {
    account,
    accountsWithBalances,
    setPreferredAccount: setPreferredWallet,
    hasPreferredWallet,
    deletePreferredWallet,
  };
}

// Sanitize value to be concatenated as CSS to ensure no HTML and CSS
// XSS attack can accidentally make it through those variables.
function cssValue(value: any): string {
  if (value || value === 0) {
    return value.toString().replace(/[^#a-z0-9%()., _-]/gi, '');
  }

  return '';
}

// eslint-disable-next-line import/no-default-export
export default cssValue;

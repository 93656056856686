import { useMemo } from 'react';
import { KeyActionCategoryConfig } from 'cb-wallet-data/stores/SettingsRedesign/types';

import { useSwapPageConfig } from ':dapp/hooks/keyActions/useSwapPageConfig';

export function useKeyActionsConfigs(): KeyActionCategoryConfig[] {
  const swapConfig = useSwapPageConfig();

  return useMemo(() => {
    return [swapConfig];
  }, [swapConfig]);
}

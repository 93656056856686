import { PossibleUTXOBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
import { BITCOIN_SYMBOL } from 'cb-wallet-data/chains/UTXO/Bitcoin/config';
import { deriveAddressFromXpubKey as deriveAddressFromXpubKeyBTC } from 'cb-wallet-data/chains/UTXO/Bitcoin/Wallet/deriveAddressFromXpubKey';
import { DOGECOIN_SYMBOL } from 'cb-wallet-data/chains/UTXO/Dogecoin/config';
import { deriveAddressFromXpubKey as deriveAddressFromXpubKeyDOGE } from 'cb-wallet-data/chains/UTXO/Dogecoin/Wallet/deriveAddressFromXpubKey';
import { LITECOIN_SYMBOL } from 'cb-wallet-data/chains/UTXO/Litecoin/config';
import { deriveAddressFromXpubKey as deriveAddressFromXpubKeyLTC } from 'cb-wallet-data/chains/UTXO/Litecoin/Wallet/deriveAddressFromXpubKey';
import { AddressType } from 'cb-wallet-data/stores/Addresses/models/AddressType';

type DeriveAddressFromXpubKey = (
  xpubKey: string,
  index: bigint,
  addressType: AddressType,
  isChange: boolean,
  isTestnet: boolean,
) => Promise<string>;

export const deriveAddressFromXpubKeyForBlockchain: Record<
  PossibleUTXOBlockchainSymbol,
  DeriveAddressFromXpubKey
> = {
  [BITCOIN_SYMBOL]: deriveAddressFromXpubKeyBTC,
  [DOGECOIN_SYMBOL]: deriveAddressFromXpubKeyDOGE,
  [LITECOIN_SYMBOL]: deriveAddressFromXpubKeyLTC,
};

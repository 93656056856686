import { useCallback, useMemo, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import {
  triggerConnectedWalletsMenuManageClick,
  useConnectedWalletsMenuView,
} from 'cb-wallet-analytics/wallet-connection';
import {
  PossibleAccountBlockchainSymbol,
  possibleAccountBlockchainSymbols,
} from 'cb-wallet-data/chains/blockchains';
import { useAccountsWithBalances } from 'cb-wallet-data/stores/Accounts/hooks/useAccountsWithBalances';
import { useBasicPublicProfileForAddresses } from 'cb-wallet-data/stores/DecentralizedID/hooks/useBasicPublicProfileForAddresses';
import { useVisiblePortfolioBalance } from 'cb-wallet-data/stores/Wallets/hooks/usePortfolioBalance';
import { useRouter } from 'next/router';
import { Button } from 'wallet-cds-web/components/Button';
import { ResponsivePropsDevices, ResponsiveStyles } from '@cbhq/cds-common';
import { useModal } from '@cbhq/cds-common/overlays/useModal';
import { NavigationIconButton } from '@cbhq/cds-web/buttons';
import { ListCell } from '@cbhq/cds-web/cells';
import { DropdownRefProps } from '@cbhq/cds-web/dropdown';
import { Dropdown } from '@cbhq/cds-web/dropdown/Dropdown';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextTitle3 } from '@cbhq/cds-web/typography';

import { useIsCoinbaseDappBrowser } from ':dapp/hooks/useIsCoinbaseDappBrowser';
import { SettingRoutesEnum } from ':dapp/utils/RoutesEnum';

import { ConnectButton } from '../ConnectButton/ConnectButton';
import { L1WarningModal } from '../L1WarningModal/L1WarningModal';

import { ConnectedWalletsMenuRow } from './ConnectedWalletsMenuRow';

const messages = defineMessages({
  cryptoBalance: {
    defaultMessage: 'Crypto Balance',
    description: 'Text showing the user their crypto balance',
  },
  manage: {
    defaultMessage: 'Manage',
    description: 'Text telling the user to manage their wallets',
  },
  noConnectedWallets: {
    defaultMessage: 'No connected wallets',
    description: 'Text telling the user to connect a wallet',
  },
});

type ConnectedWalletsMenuContentProps = {
  dRef: React.MutableRefObject<DropdownRefProps | null>;
};

// Show 5 rows at a time. 88 * 5 = 440
const MAX_HEIGHT = 5 * 88;

const responsiveConfig: Partial<Record<ResponsivePropsDevices, ResponsiveStyles>> = {
  phone: {
    flexDirection: 'column',
  },
  tablet: {
    flexDirection: 'row',
  },
};

function ConnectedWalletsMenuContent({ dRef }: ConnectedWalletsMenuContentProps) {
  const router = useRouter();
  const { formatMessage } = useIntl();
  const accountsWithBalance = useAccountsWithBalances();
  const addressesForAccounts = useMemo(
    () => accountsWithBalance.map((account) => account.primaryAddress),
    [accountsWithBalance],
  );

  const basicPublicProfiles = useBasicPublicProfileForAddresses(addressesForAccounts);
  const isCoinbaseDappBrowser = useIsCoinbaseDappBrowser();

  const accountsWithDomainInfo = useMemo(() => {
    return accountsWithBalance.map((account) => {
      const basicPublicProfile = basicPublicProfiles?.[account.primaryAddress];
      const avatar = basicPublicProfile?.avatar;
      const domain = basicPublicProfile?.name;
      return {
        ...account,
        domain,
        avatar,
      };
    });
  }, [basicPublicProfiles, accountsWithBalance]);

  const portfolioBalance = useVisiblePortfolioBalance();
  const { openModal, closeModal } = useModal();

  useConnectedWalletsMenuView();

  const handleOpenModal = useCallback(
    (address: string) => {
      dRef?.current?.closeMenu();
      openModal(<L1WarningModal address={address} visible onClose={closeModal} />);
    },
    [closeModal, dRef, openModal],
  );

  const handleNavigate = useCallback(() => {
    triggerConnectedWalletsMenuManageClick();
    dRef?.current?.closeMenu();
    router.push(SettingRoutesEnum.MANAGE_WALLETS);
  }, [dRef, router]);

  if (accountsWithBalance.length === 0) {
    return (
      <ListCell
        testID="no-connected-wallets"
        compact
        title={<TextTitle3 as="h2">{formatMessage(messages.noConnectedWallets)}</TextTitle3>}
      />
    );
  }

  return (
    <VStack
      spacingVertical={1}
      overflow="hidden"
      testID="connected-wallets"
      width="100%"
      maxWidth={400}
    >
      <ListCell
        compact
        title={<TextTitle3 as="h2">{formatMessage(messages.cryptoBalance)}</TextTitle3>}
        detail={
          <TextBody as="span" color="foregroundMuted" align="end">
            {portfolioBalance}
          </TextBody>
        }
      />
      <VStack gap={1} maxHeight={MAX_HEIGHT} overflow="auto">
        {accountsWithDomainInfo.map(
          ({
            primaryAddressForDapp: primaryAddress,
            primaryAddressChain,
            provider,
            balance,
            avatar,
          }) => {
            if (!provider) {
              return null;
            }
            return (
              <ConnectedWalletsMenuRow
                key={primaryAddress}
                address={primaryAddress}
                balance={balance}
                provider={provider}
                onShowL1WarningModal={handleOpenModal}
                addressChain={primaryAddressChain}
                avatar={avatar}
                copyEnabled={possibleAccountBlockchainSymbols.includes(
                  primaryAddressChain as PossibleAccountBlockchainSymbol,
                )}
              />
            );
          },
        )}
      </VStack>
      <HStack
        gap={1}
        spacing={2}
        spacingHorizontal={3}
        width="100%"
        responsiveConfig={responsiveConfig}
      >
        {!isCoinbaseDappBrowser && (
          <ConnectButton block type="add" onOpen={dRef?.current?.closeMenu} endIcon="add" />
        )}
        <Button
          block
          variant="secondary"
          compact
          endIcon="gear"
          testID="manage-wallets-button"
          onPress={handleNavigate}
        >
          {formatMessage(messages.manage)}
        </Button>
      </HStack>
    </VStack>
  );
}

export function ConnectedWalletsMenu() {
  const dropdownRef = useRef<DropdownRefProps | null>(null);
  const { isPhone } = useBreakpoints();

  const content = useMemo(() => {
    return <ConnectedWalletsMenuContent dRef={dropdownRef} />;
  }, []);

  return (
    <Dropdown
      content={content}
      enableMobileModal
      maxHeight={554}
      ref={dropdownRef}
      width={isPhone ? '100%' : 400}
      maxWidth={400}
    >
      <NavigationIconButton testID="dropdown-button" name="wallet" />
    </Dropdown>
  );
}

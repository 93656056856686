import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { getJSON } from 'cb-wallet-http/fetchJSON';

export const CHECK_GEO_QUERY_KEY = 'checkGeoAvailability';

export type OFACAvailabilityResponse = {
  country: string;
  permitted: boolean;
};

export async function fetchGeoAvailability() {
  const { result } = await getJSON<{ result: OFACAvailabilityResponse }>(CHECK_GEO_QUERY_KEY, {});
  return result;
}

const defaultAvailability: OFACAvailabilityResponse = { permitted: true, country: '' };

export function useGeoAvailability(): OFACAvailabilityResponse {
  const { data } = useQuery([CHECK_GEO_QUERY_KEY], fetchGeoAvailability, {
    suspense: false, // No need to suspend, we have a placeholder value
    cacheTime: 0, // Always get latest
    notifyOnChangeProps: ['data'],
    placeholderData: defaultAvailability,
  });

  return data ?? defaultAvailability;
}

export function useIsPermitted() {
  return useGeoAvailability().permitted;
}

export function usePrefetchGeoAvailability() {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.prefetchQuery([CHECK_GEO_QUERY_KEY], fetchGeoAvailability);
  }, [queryClient]);
}

export function useGetCachedUserGeo(): OFACAvailabilityResponse {
  const { data } = useQuery([CHECK_GEO_QUERY_KEY], fetchGeoAvailability, {
    suspense: false,
    cacheTime: 1000 * 60 * 60, // For example, cache for 1 hour
    staleTime: Infinity, // The cached data is always fresh
    notifyOnChangeProps: ['data'],
    placeholderData: defaultAvailability,
  });

  return data ?? defaultAvailability;
}

import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { Store } from 'cb-wallet-store/Store';

import { StoreKeys_isZeroBalanceWallet, StoreKeys_lastZeroBalanceFireTimeStamp } from '../state';

export function getIsZeroBalanceWallet(accountId: Account['id']) {
  return Store.get<boolean | undefined>(StoreKeys_isZeroBalanceWallet(accountId));
}

export function setIsZeroBalanceWallet({
  accountId,
  value,
}: {
  accountId: Account['id'];
  value: boolean;
}) {
  return Store.set<boolean | undefined>(StoreKeys_isZeroBalanceWallet(accountId), value);
}

export function getLastZeroBalanceFireTimeStamp(accountId: Account['id']) {
  return Store.get<number>(StoreKeys_lastZeroBalanceFireTimeStamp(accountId));
}

export function setLastZeroBalanceFireTimeStamp({
  accountId,
  value,
}: {
  accountId: Account['id'];
  value: number;
}) {
  return Store.set<number>(StoreKeys_lastZeroBalanceFireTimeStamp(accountId), value);
}

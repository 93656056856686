import { TxSubmissionType } from './TxSubmissionType';
/**
 * Represents a pending transaction submission's data
 */
export class PendingEthTxState {
  constructor(
    readonly baseFeePerGas: bigint,
    readonly data: Buffer,
    readonly gasLimit: bigint,
    readonly maxFeePerGas: bigint,
    readonly maxPriorityFeePerGas: bigint,
    readonly txSubmissionType: TxSubmissionType,
    readonly l1GasFee: bigint | undefined,
  ) {}

  static isEqual(txState1: PendingEthTxState | undefined, txState2: PendingEthTxState | undefined) {
    if (!txState1) return !txState2;
    if (!txState2) return !txState1;
    return (
      txState1.baseFeePerGas === txState2.baseFeePerGas &&
      txState1.data.equals(txState2.data) &&
      txState1.gasLimit === txState2.gasLimit &&
      txState1.maxFeePerGas === txState2.maxFeePerGas &&
      txState1.maxPriorityFeePerGas === txState2.maxPriorityFeePerGas &&
      txState1.txSubmissionType === txState2.txSubmissionType &&
      txState1.l1GasFee === txState2.l1GasFee
    );
  }

  get lowerBoundValue(): bigint {
    return (this.baseFeePerGas + this.maxPriorityFeePerGas) * this.gasLimit + (this.l1GasFee ?? 0n);
  }
}

export class PendingUTXOTxState {
  constructor(
    readonly txSubmissionType: TxSubmissionType,
    readonly feeRate: bigint,
    readonly utxos: { hash: string; index: bigint }[] | undefined = undefined,
  ) {}

  static isEqual(
    txState1: PendingUTXOTxState | undefined,
    txState2: PendingUTXOTxState | undefined,
  ) {
    if (!txState1) return !txState2;
    if (!txState2) return !txState1;
    return (
      txState1.txSubmissionType === txState2.txSubmissionType &&
      txState1.utxos === txState2.utxos &&
      txState1.feeRate === txState2.feeRate
    );
  }
}

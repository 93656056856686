import { SolanaAddressHistory } from 'wallet-engine-signing/history';
import { TransactionError, TransactionMaxRetriesError } from 'wallet-engine-signing/history/errors';
import { NudgeDetails } from 'wallet-engine-signing/history/types';
import { isFulfilled, retryWithBackoff } from 'wallet-engine-signing/history/utils';
import {
  V1AddressMeta,
  V1GetTransactionDetailsRequest,
  V1SpamScoreThresholds,
  V1Transaction,
} from '@cbhq/instant-api-hooks-wallet-tx-history/types';

import {
  EtherscanResult,
  getEtherscanEthereumTransactionsPage,
  getEtherscanTokenTransactionsPage,
} from './etherscan';
import { V2SpamScoreThresholds } from './RPC';
import {
  getTxHistoryV2TransactionForTxHash,
  getTxHistoryV2TransactionsPage,
  getTxHistoryV3TransactionForTxHash,
  getTxHistoryV3TransactionsPage,
} from './txhistory';
import { EthereumAddressHistory, UnsupportedTronTransactionNetwork, V2Transaction } from './types';

export type RefreshEthereumTransactions = {
  addressConfigs: EthereumAddressHistory[];
  onRefreshComplete: (updates: EthereumAddressHistory[]) => void;
};

// This function will emit a 'transactions' event when the first page of transactions
// comes in for all subscribed EVM networks. This allows clients to render the most recent
// transactions as soon as possible. Then a second, final transactions event is emitted
// when all remaining transactions come in.
export async function refreshEthereumTransactions({
  addressConfigs,
  onRefreshComplete,
}: RefreshEthereumTransactions) {
  // Gets the first page of updates for all registered addresses
  const initialTransactionsPagePromises = await Promise.allSettled(
    addressConfigs.map(async (addressConfig) =>
      refreshEthereumTransactionsForAddress({ addressConfig, fetchFirstPageOnly: true }),
    ),
  );

  const initialTransactionsUpdates = initialTransactionsPagePromises
    .filter(isFulfilled)
    .map((result) => result.value);

  if (initialTransactionsUpdates.length) {
    onRefreshComplete(initialTransactionsUpdates);
  }

  // Gets any remaining transactions that come in after the initial page
  const remainingTransactionsPagesPromises = await Promise.allSettled(
    initialTransactionsUpdates
      .filter((addressUpdate) => addressUpdate.nextPaginationToken)
      .map(async (addressConfig) =>
        refreshEthereumTransactionsForAddress({
          addressConfig,
          paginationToken: addressConfig.nextPaginationToken,
          fetchFirstPageOnly: false,
        }),
      ),
  );

  const remainingTransactionsUpdates = remainingTransactionsPagesPromises
    .filter(isFulfilled)
    .map((result) => result.value);

  if (remainingTransactionsUpdates.length) {
    onRefreshComplete(remainingTransactionsUpdates);
  }
}

export async function handleEthereumNudgeTransactions(
  addresses: EthereumAddressHistory[],
  nudgeDetails: NudgeDetails,
) {
  const { chainId, txHash, providerName, providerNetworkID } = nudgeDetails;

  // We want to skip the regular nudge logic if we have a providerName (i.e. it's a tron bridge.xyz tx)
  // and "providerNetworkID" is "networks/tron-mainnet", because it'd mean that we don't have a supported (base)
  // tx hash to fetch the transaction from the txhistory service (v2/v3).
  if (providerName && providerNetworkID === UnsupportedTronTransactionNetwork.TRON) {
    return {
      // We need to pass "historyItem" here so we can check the "blockchainSymbol"
      // on "useUpdateTransactions"
      updates: addresses,
      blockheight: 0,
      // We want to return "nudgeProvider" and "nudgeProviderNetworkID" so we can refetch the PTS pending
      // transactions on "useUpdateTransactions"
      nudgeProvider: providerName,
      nudgeProviderNetworkID: providerNetworkID,
    };
  }

  try {
    const txHistoryUpdates = await Promise.allSettled(
      addresses.map(async (address) => {
        const isV3Enabled = address.txHistoryVersion === 'v3';
        const params: V1GetTransactionDetailsRequest = {
          hash: txHash,
          providerName,
          address: address.address,
          network: address.wacNetworkId,
        };

        if (isV3Enabled) {
          const result = await retryWithBackoff(async () =>
            getTxHistoryV3TransactionForTxHash(params),
          );

          return {
            ...address,
            unsyncedTransactions: [result.transaction as V1Transaction],
            spamScoreThresholds: result.spamScoreThresholds!,
            addressMetadata: result.addressMeta!,
          };
        }

        // Else we're using v2
        const transaction = await retryWithBackoff(async () =>
          getTxHistoryV2TransactionForTxHash(params),
        );

        return {
          ...address,
          unsyncedTransactions: [transaction],
        };
      }),
    );

    const updates = txHistoryUpdates.filter(isFulfilled).map((result) => result.value);

    return {
      updates,
      blockheight: 0,
      nudgeProvider: providerName,
      nudgeProviderNetworkID: providerNetworkID,
    };
  } catch (err) {
    return {
      updates: [],
      blockheight: 0,
      errors: [new TransactionMaxRetriesError((err as Error).message, 'ETH', chainId)],
    };
  }
}

type RefreshEthereumTransactionForAddressParams = {
  addressConfig: EthereumAddressHistory;
  fetchFirstPageOnly?: boolean;
  paginationToken?: string;
};

export async function refreshEthereumTransactionsForAddress({
  addressConfig,
  paginationToken,
  fetchFirstPageOnly = false,
}: RefreshEthereumTransactionForAddressParams) {
  const { txHistoryVersion } = addressConfig;
  const isTxHistoryCompatible = txHistoryVersion === 'v2' || txHistoryVersion === 'v3';

  // Ethereum transactions on whitelisted networks are fetched from the txhistory service
  // and etherscan for custom networks and certain testnets.
  const txUpdate = isTxHistoryCompatible
    ? await getTxHistoryTransactions({ addressConfig, fetchFirstPageOnly, paginationToken })
    : await getEtherscanTransactions({ addressConfig, fetchFirstPageOnly });

  return {
    ...addressConfig,
    ...txUpdate,
    nextPaginationToken: txUpdate.nextPaginationToken ?? '',
  };
}

type GetTxHistoryTransactionsPage = {
  addressMetadata: Record<string, V1AddressMeta>;
  unsyncedTransactions: (V1Transaction | V2Transaction)[];
  spamScoreThresholds?: V1SpamScoreThresholds | V2SpamScoreThresholds;
  nextPaginationToken?: string;
};

// TODO this is being used for Ethereum and Solana now, might make sense to put in a shared directory
export async function getTxHistoryTransactions({
  addressConfig,
  paginationToken,
  fetchFirstPageOnly = false,
  addressMetadataAggregator = {},
  transactionsAggregator = [],
}: {
  addressConfig: EthereumAddressHistory | SolanaAddressHistory;
  fetchFirstPageOnly?: boolean;
  addressMetadataAggregator?: Record<string, V1AddressMeta>;
  transactionsAggregator?: (V1Transaction | V2Transaction)[];
  paginationToken?: string;
}): Promise<GetTxHistoryTransactionsPage> {
  const { address, lastSyncedTxHash, wacNetworkId } = addressConfig;

  const getTxHistory =
    addressConfig.txHistoryVersion === 'v2'
      ? getTxHistoryV2TransactionsPage
      : getTxHistoryV3TransactionsPage;

  const {
    addressMetadata,
    nextPaginationToken,
    spamScoreThresholds,
    transactions = [],
  } = await getTxHistory({
    address,
    network: wacNetworkId,
    paginationToken,
  });

  const isSyncComplete = isTxHistoryServiceSyncComplete(
    transactions,
    nextPaginationToken,
    lastSyncedTxHash,
  );

  const allTransactions = [...transactionsAggregator, ...transactions];
  const allAddressMetadata = {
    ...addressMetadataAggregator,
    ...filterEmptyAddressMetadata(addressMetadata),
  };

  if (isSyncComplete || fetchFirstPageOnly) {
    return {
      unsyncedTransactions: allTransactions.map((tx) => ({
        ...tx,
        txHistoryVersion: addressConfig.txHistoryVersion,
      })),
      addressMetadata: allAddressMetadata,
      spamScoreThresholds,
      nextPaginationToken,
    };
  }

  return getTxHistoryTransactions({
    addressConfig,
    addressMetadataAggregator: allAddressMetadata,
    transactionsAggregator: allTransactions,
    paginationToken: nextPaginationToken,
  });
}

type GetEtherscanTransactions = {
  unsyncedTransactions: EtherscanResult[];
  errors: TransactionError[];
  nextPaginationToken?: string;
};

export const ETHERSCAN_PAGE_SIZE_FULL_SYNC = 50;
export const ETHERSCAN_PAGE_SIZE_PARTIAL_SYNC = 10;

export async function getEtherscanTransactions({
  addressConfig,
  fetchFirstPageOnly = false,
  transactionsAggregator = [],
}: {
  addressConfig: EthereumAddressHistory;
  fetchFirstPageOnly?: boolean;
  transactionsAggregator?: EtherscanResult[];
}): Promise<GetEtherscanTransactions> {
  // Can't do anything for custom networks where these aren't defined
  if (!addressConfig.etherscanCompatibleTxHistoryApi || !addressConfig.etherscanLikeApiKey) {
    return { unsyncedTransactions: [], errors: [] };
  }

  const perPage = addressConfig.lastSyncedTxHash
    ? ETHERSCAN_PAGE_SIZE_PARTIAL_SYNC
    : ETHERSCAN_PAGE_SIZE_FULL_SYNC;
  const transactions = [];

  try {
    const ethereumTransactions = await getEtherscanEthereumTransactionsPage({
      addressConfig,
      perPage,
    });
    const tokenTransactions = await getEtherscanTokenTransactionsPage({
      addressConfig,
      perPage,
    });

    transactions.push(...ethereumTransactions, ...tokenTransactions);
  } catch (err) {
    return {
      unsyncedTransactions: [],
      errors: [err as TransactionError],
    };
  }

  const isSyncComplete = isEtherscanTxSyncComplete(transactions, addressConfig.lastSyncedTxHash);

  const allTransactions = [...transactionsAggregator, ...transactions];

  if (isSyncComplete || fetchFirstPageOnly) {
    return {
      unsyncedTransactions: allTransactions.map((tx) => ({
        ...tx,
        source: 'etherscan',
      })),
      nextPaginationToken: '',
      errors: [],
    };
  }

  return getEtherscanTransactions({
    addressConfig,
    transactionsAggregator: allTransactions,
  });
}

export function isEtherscanTxSyncComplete(
  fetchedTransactions: EtherscanResult[],
  lastSyncedHash?: string,
) {
  if (!fetchedTransactions.length) {
    return true;
  }

  // initial sync
  if (!lastSyncedHash) {
    return fetchedTransactions.length < ETHERSCAN_PAGE_SIZE_FULL_SYNC;
  }

  return fetchedTransactions.some((tx) => tx.hash === lastSyncedHash);
}

export function isTxHistoryServiceSyncComplete(
  fetchedTransactions: (V1Transaction | V2Transaction)[],
  paginationToken?: string,
  lastSyncedHash?: string,
) {
  const initialSyncComplete = !lastSyncedHash && !paginationToken;

  if (initialSyncComplete) {
    return true;
  }

  // The transition to userOps will not change how we handle pagination. we will still key off txHash for syncing [TxOrUserOp]s into our db
  return !paginationToken || fetchedTransactions.some((tx) => tx.hash === lastSyncedHash);
}

function isEmptyMetadata(item: string | Record<string, unknown>) {
  return !item || (typeof item === 'object' && !Object.keys(item).length);
}

// Occasionally the txhistory service returns empty metadata for certain networks which results
// in valid metadata being overwritten with empty metadata. This function filters out empty metadata
// to prevent this from happening.
export function filterEmptyAddressMetadata(addressMetadata: Record<string, V1AddressMeta> = {}) {
  return Object.entries(addressMetadata).reduce((acc, [address, metadata]) => {
    if (!Object.values(metadata).every(isEmptyMetadata)) {
      acc[address] = metadata;
    }
    return acc;
  }, {} as Record<string, V1AddressMeta>);
}

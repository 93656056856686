import { useCallback, useMemo } from 'react';
import {
  AllPossibleBlockchainSymbol,
  allPossibleBlockchainSymbols,
} from 'cb-wallet-data/chains/blockchains';
import { StoreKeys_cryptosLoadingState } from 'cb-wallet-data/hooks/useBalanceUpdateEvents/storeKeys';
import { useSyncIsZeroBalanceWallet } from 'cb-wallet-data/hooks/useSyncIsZeroBalanceWallet';
// eslint-disable-next-line no-restricted-imports
import { useActiveWalletGroupId } from 'cb-wallet-data/stores/WalletGroups/hooks/useActiveWalletGroupId';
import { Store } from 'cb-wallet-store/Store';

type BlockchainBalanceSetting = {
  hasBalance: boolean;
  indexesToSync: Set<bigint>;
  indexesSynced: Set<bigint>;
};

export const HasBalanceByChain = new Map<AllPossibleBlockchainSymbol, BlockchainBalanceSetting>();

export function initializeHasBalanceByChain() {
  allPossibleBlockchainSymbols.forEach((blockchainSymbol) => {
    HasBalanceByChain.set(blockchainSymbol, {
      hasBalance: false,
      indexesToSync: new Set(),
      indexesSynced: new Set(),
    });
  });
}

initializeHasBalanceByChain();

export function setIndexesToSync(
  blockchainSymbol: AllPossibleBlockchainSymbol,
  indexesToSync: bigint[],
) {
  const blockchainBalanceSetting = HasBalanceByChain.get(blockchainSymbol);
  HasBalanceByChain.set(blockchainSymbol, {
    ...blockchainBalanceSetting!,
    indexesSynced: new Set(),
    indexesToSync: new Set(indexesToSync),
  });
}

export function setBlockchainHasBalance(
  blockchainSymbol: AllPossibleBlockchainSymbol,
  hasBalance: boolean,
) {
  const blockchainBalanceSetting = HasBalanceByChain.get(blockchainSymbol);
  HasBalanceByChain.set(blockchainSymbol, {
    ...blockchainBalanceSetting!,
    hasBalance,
  });
}

export function allIndexesSynced(balanceSetting: BlockchainBalanceSetting) {
  let synced = true;

  balanceSetting.indexesToSync.forEach((indexToSync) => {
    if (!balanceSetting.indexesSynced.has(indexToSync)) {
      synced = false;
    }
  });

  return synced;
}

type SprigEventTrack = (
  eventName: string,
  newAttribute?: { name: string; value: string } | undefined,
) => void;

export function useSetBlockchainIndexesSynced() {
  const syncIsZeroBalanceWallet = useSyncIsZeroBalanceWallet();
  const walletGroupId = useActiveWalletGroupId();
  const cryptosLoadingStateStoreKey = useMemo(
    () => StoreKeys_cryptosLoadingState(walletGroupId),
    [walletGroupId],
  );

  function setBlockchainIndexesSynced(
    blockchainSymbol: AllPossibleBlockchainSymbol,
    indexes: Set<bigint>,
    // TODO sprig is only on RN, will pass this once we're using useAddressHistory in RN
    sprigEventTrack?: SprigEventTrack,
  ) {
    const blockchainBalanceSetting = HasBalanceByChain.get(blockchainSymbol);

    indexes.forEach((index) => {
      blockchainBalanceSetting?.indexesSynced.add(index);
    });

    const allBalancesSynced = [...HasBalanceByChain.values()].every(allIndexesSynced);

    const isZeroBalance = [...HasBalanceByChain.values()].every(
      ({ hasBalance }: { hasBalance: boolean }) => !hasBalance,
    );

    if (allBalancesSynced) {
      Store.set(cryptosLoadingStateStoreKey, 'loaded');
      syncIsZeroBalanceWallet({
        sprigEventTrack,
        isZeroBalanceOverride: isZeroBalance,
      });
    }
  }

  return useCallback(setBlockchainIndexesSynced, [
    cryptosLoadingStateStoreKey,
    syncIsZeroBalanceWallet,
  ]);
}

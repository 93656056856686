import { useCallback } from 'react';
import { logZeroBalance, ZeroBalanceEventContext } from 'cb-wallet-analytics/assets';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { useSetRecoilState } from 'recoil';

import { isNewlyCreatedWalletAtom } from '../state';
import {
  getLastZeroBalanceFireTimeStamp,
  setIsZeroBalanceWallet,
  setLastZeroBalanceFireTimeStamp,
} from '../utils/isZeroBalanceWallet';

export type SetIsZeroBalanceWalletParams = {
  isZeroBalanceWallet: boolean;
  zeroBalanceEventContext?: ZeroBalanceEventContext;
  isNewlyCreatedWallet?: boolean;
  accountId: Account['id'];
  debounced?: boolean; // debounce using lastZeroBalanceFireTimeStamp
};

export function useSetAndTriggerZeroBalanceEvent() {
  const setIsNewlyCreatedWallet = useSetRecoilState(isNewlyCreatedWalletAtom);

  return useCallback(
    function setAndTriggerZeroBalanceEvent({
      isZeroBalanceWallet,
      zeroBalanceEventContext,
      isNewlyCreatedWallet,
      debounced = false,
      accountId,
    }: SetIsZeroBalanceWalletParams) {
      const currentTimeStamp = Date.now();
      const lastZeroBalanceFireTimeStamp = getLastZeroBalanceFireTimeStamp(accountId);
      const lastToCurrentFireDeltaInSecond =
        lastZeroBalanceFireTimeStamp && (currentTimeStamp - lastZeroBalanceFireTimeStamp) / 1000;

      if (debounced && lastToCurrentFireDeltaInSecond && lastToCurrentFireDeltaInSecond < 1) {
        return;
      }

      setIsZeroBalanceWallet({ accountId, value: isZeroBalanceWallet });

      logZeroBalance({
        isZeroBalanceWallet,
        context: zeroBalanceEventContext,
        lastToCurrentFireDeltaInSecond,
      });

      setLastZeroBalanceFireTimeStamp({ accountId, value: currentTimeStamp });

      if (isNewlyCreatedWallet) {
        setIsNewlyCreatedWallet(true);
      }
    },
    [setIsNewlyCreatedWallet],
  );
}

import { ReactNode, useMemo } from 'react';
import { SpacingScale, useSpectrum } from '@cbhq/cds-common';
import { IconButton } from '@cbhq/cds-web/buttons';
import { Box, HStack } from '@cbhq/cds-web/layout';
import { Modal, ModalProps as CDSModalProps } from '@cbhq/cds-web/overlays';
import { TextTitle1 } from '@cbhq/cds-web/typography';
export type HomebaseModalProps = {
  title?: string;
  children: ReactNode;
  visible: boolean;
  header?: ReactNode;
  footer?: ReactNode;
  size?: 'small' | 'large' | 'auto';
  spacingHorizontal?: SpacingScale;
  onClose: () => void;
  contentSpacingTop?: SpacingScale;
  contentSpacingBottom?: SpacingScale;
  contentOverflow?: 'hidden' | 'auto' | 'visible' | 'scroll';
  classOverride?: string;
  footerSpacingBottom?: SpacingScale;
} & Omit<CDSModalProps, 'onRequestClose'>;

/**
 *
 * USAGE:
 *
 * // In parent component/page:
 *
 * import { useToggler } from '@cbhq/cds-common';
 * import { HomebaseModal } from ':dapp/components/HomebaseModal/HomebaseModal';
 *
 *
 * function Component() {
 *  const [visible, { toggleOn, toggleOff }] = useToggler();
 *
 *  return (
 *    <>
 *      // Can open modal with toggleOn, or by initializing useToggler(true)
 *      <Button onPress={toggleOn}>Open</Button>
 *
 *      // Pass visible to show/hide and onClose handler
 *      <HomebaseModal title="Modal Title" visible={visible} onClose={toggleOff}>
 *        ....
 *        Modal content (any ReactNode can go here)
 *        ....
 *      </HomebaseModal>
 *    </>
 *  )
 * }
 *
 *
 */

export function HomebaseModal({
  title,
  size = 'small',
  footer,
  header,
  children,
  visible,
  onClose,
  spacingHorizontal = 4,
  contentSpacingTop,
  contentSpacingBottom,
  contentOverflow = 'hidden',
  classOverride,
  footerSpacingBottom,
  ...rest
}: HomebaseModalProps) {
  const spectrum = useSpectrum();
  const modalClass = spectrum === 'dark' ? "mwkdz5g" : "m16psch2";
  const modalWidthClass = useMemo(() => {
    if (size === 'small') return "m1k099m9";
    if (size === 'large') return "m2cyvdv";
    return "m1h92ovk";
  }, [size]);
  const contentBottomSpacing = useMemo(() => {
    if (contentSpacingBottom === undefined) return footer ? 2 : 4;
    return contentSpacingBottom;
  }, [contentSpacingBottom, footer]);
  return <Modal {...rest} visible={visible} onRequestClose={onClose} className={`${modalClass} ${modalWidthClass} ${classOverride}`}>
      {header ? <Box testID="homebase-modal-header">{header}</Box> :
    //   default header
    <HStack spacingVertical={2} spacingStart={4} spacingEnd={2} justifyContent="space-between" alignItems="center" width="100%">
          <TextTitle1 as="h3">{title}</TextTitle1>
          <IconButton testID="homebase-modal-close" name="close" compact transparent onPress={onClose} />
        </HStack>}
      <Box width="100%" spacingTop={contentSpacingTop ?? 3} spacingHorizontal={spacingHorizontal} spacingBottom={contentBottomSpacing} testID="homebase-modal-content" overflow={contentOverflow}>
        {children}
      </Box>
      {footer ? <Box testID="homebase-modal-footer" width="100%" spacingBottom={footerSpacingBottom || 4}>
          {footer}
        </Box> : null}
    </Modal>;
}

require("./HomebaseModal.linaria.module.css!=!../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./HomebaseModal.tsx");
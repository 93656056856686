import { useCallback, useMemo } from 'react';
import { FormatNumberOptions, useIntl } from 'react-intl';
import { useAreFiatUICopiesEnabled } from 'cb-wallet-data/hooks/KillSwitches/useIsFiatUICopyEnabled';
import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { useActiveFiatCurrency } from 'cb-wallet-data/stores/ActiveFiatCurrency/hooks/useActiveFiatCurrency';
import { FiatCurrency } from 'cb-wallet-data/stores/ActiveFiatCurrency/models/FiatCurrency';
import { useExchangeRatesMap } from 'cb-wallet-data/stores/ExchangeRates/hooks/useExchangeRates';
import { formatDecimal, toDecimal } from 'cb-wallet-data/stores/ExchangeRates/utils';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { usePrivacyMode } from 'cb-wallet-data/stores/User/hooks/usePrivacyMode';
import { Decimal } from 'decimal.js';

import { CurrencyComponents } from '../types/CurrencyComponents';
import { abbreviateFiatRawValue } from '../utils/abbreviateFiatRawValue';
import { cryptoValue as cryptoValueUtil, CryptoValueParams } from '../utils/cryptoValue';
import { fiatValue as fiatValueUtil, FiatValueProps } from '../utils/fiatValue';
import { formatDecimalToCrypto } from '../utils/formatDecimalToCrypto';
import { formatToFiatCurrency as formatToFiatCurrencyUtil } from '../utils/formatToFiatCurrency';

Decimal.set({ precision: 100 });

const DEFAULT_MAX_DECIMAL_DISPLAY = 8n;
const DEFAULT_MAX_DECIMAL_DISPLAY_FOR_RANGES = 4n;
export const DEFAULT_PRIVACY_MODE_ELEMENT = '••••••••';

export type CurrencyFormatter = {
  fiatValue: (
    data: Omit<FiatValueProps, 'exchangeRatesMap' | 'shouldOverrideWithDollar'>,
  ) => Decimal | undefined;

  cryptoValue: (
    data: Omit<CryptoValueParams, 'exchangeRatesMap'> & {
      customExchangeRatesMap?: Record<string, Decimal> | undefined;
    },
  ) => Decimal | undefined;

  fiatValueString: (
    value: Decimal,
    showCurrencySymbol?: boolean,
    overridePrivacy?: boolean,
    maximumFractionDigits?: number,
    minimumFractionDigits?: number,
  ) => string;

  formatToCrypto: (data: {
    currencyCode: CurrencyCode;
    decimals: bigint;
    value: bigint | Decimal | undefined;
    includeCode?: boolean;
    maxDisplayDecimals?: bigint;
    minDisplayDecimals?: bigint;
    convertFromFiat?: boolean;
    network?: Network;
    blockchain?: Blockchain;
    shouldDisplayDustAmount?: boolean;
    overridePrivacy?: boolean;
    contractAddress?: string | undefined;
    customExchangeRatesMap?: Record<string, Decimal> | undefined;
  }) => string | undefined;

  formatToFiatCurrency: (data: {
    fiatCurrency: FiatCurrency;
    fromCurrencyCode: CurrencyCode;
    decimals: bigint;
    value: bigint | undefined;
    isAbbreviated?: boolean;
    contractAddress?: string;
    network?: Network;
    blockchain?: Blockchain;
    includeCode?: boolean;
    formatZeroBalance?: boolean;
    overridePrivacy?: boolean;
    preserveEndingZeros?: boolean;
    numberFormatNotation?: FormatNumberOptions['notation'];
    shouldOverrideWithDollar?: boolean;
  }) => string | undefined;

  formatToFiat: (data: {
    fromCurrencyCode: CurrencyCode;
    decimals: bigint;
    value?: bigint;
    isAbbreviated?: boolean;
    contractAddress?: string | undefined;
    network: Network | undefined;
    blockchain: Blockchain | undefined;
    includeCode?: boolean;
    formatZeroBalance?: boolean;
    overridePrivacy?: boolean;
    preserveEndingZeros?: boolean;
    numberFormatNotation?: FormatNumberOptions['notation'];
    overrideFiatDecimals?: bigint;
  }) => string | undefined;

  /**
   * @deprecated Don’t use this method, use **formatToFiat** instead. We should represent all blockchain amounts using big integers. This method only exists right now until technical debt on {@link https://jira.coinbase-corp.com/browse/WALL-17018 WALL-[17018]} is fixed.
   */
  formatDecimalToFiat: (
    fromCurrencyCode: CurrencyCode,
    decimals: bigint,
    value?: Decimal,
    isAbbreviated?: boolean,
    contractAddress?: string | undefined,
    network?: Network | undefined,
    blockchain?: Blockchain | undefined,
    includeCode?: boolean,
    formatZeroBalance?: boolean,
    overridePrivacy?: boolean,
  ) => string | undefined;

  formatFiatTotal: (
    amounts: CurrencyComponents[],
    showAmountPrefix: boolean,
    overridePrivacy?: boolean,
  ) => [string | undefined, boolean];

  formatToFiatFeeRange: (data: {
    fromCurrencyCode: CurrencyCode;
    decimals: bigint;
    upperBoundValue: bigint;
    isAbbreviated?: boolean;
    lowerBoundValue?: bigint | undefined;
    network?: Network | undefined;
    blockchain?: Blockchain | undefined;
    overridePrivacy?: boolean;
    overrideUpperBoundFiatDecimals?: bigint;
    overrideLowerBoundFiatDecimals?: bigint;
  }) => string | undefined;

  formatToFiatFeeMinMax: (data: {
    fromCurrencyCode: CurrencyCode;
    decimals: bigint;
    upperBoundValue: bigint;
    isAbbreviated?: boolean;
    lowerBoundValue?: bigint | undefined;
    network?: Network | undefined;
    blockchain?: Blockchain | undefined;
  }) => { minFeeInUSD: string | undefined; maxFeeInUSD: string | undefined };

  formatToCryptoRange: (
    currencyCode: CurrencyCode,
    decimals: bigint,
    lowerBoundValue: bigint,
    upperBoundValue: bigint,
    includeCode?: boolean,
    maxDisplayDecimals?: bigint,
    minDisplayDecimals?: bigint,
  ) => string | undefined;

  formatToCryptoMinMax: (data: {
    decimals: bigint;
    lowerBoundValue: bigint;
    upperBoundValue: bigint;
    includeCode?: boolean;
    maxDisplayDecimals?: bigint;
    minDisplayDecimals?: bigint;
  }) => { minFeeInCrypto: string | undefined; maxFeeInCrypto: string | undefined };

  formatToFiatTotalRange: (data: {
    includeFee: boolean;
    feeCurrencyCode: CurrencyCode;
    currencyCode: CurrencyCode;
    contractAddress?: string;
    network?: Network;
    blockchain?: Blockchain | undefined;
    fee: bigint;
    lowerBoundFee?: bigint;
    amount: bigint;
    isConfirmed: boolean;
    decimals: bigint;
    feeDecimals: bigint;
    overridePrivacy?: boolean;
  }) => string | undefined;
};

/**
 * A set of functions which are used to convert values between currencies and format them for display.
 *
 * @returns A CurrencyFormatter object, whose properties are functions related to the conversion and formatting of currencies.
 */
export function useCurrencyFormatter(): CurrencyFormatter {
  const { formatters } = useIntl();
  const exchangeRatesMap = useExchangeRatesMap();
  const activeFiatCurrency = useActiveFiatCurrency();
  const activeFiatCurrencyCode = activeFiatCurrency.code.code;
  const maximumFractionDigits = Number(activeFiatCurrency.decimals);
  const privacyModeIsEnabled = usePrivacyMode();
  const areFiatUICopiesEnabled = useAreFiatUICopiesEnabled();

  /**
   * The formatted fiat string derived from the given BigDecimal input
   *
   * @param value [BigDecimal] value
   * @param showCurrencySymbol [Boolean] whether to include the currency symbol
   * @param overridePrivacy [Boolean] whether to override the app set privacy mode
   * @param numOfDecimalsToDisplay [Number] the number of decimals to display
   *
   * @return a string with the value formatted to the right number of decimals, optionally including the currency
   */
  const fiatValueString = useCallback<CurrencyFormatter['fiatValueString']>(
    function fiatValueString(
      value,
      showCurrencySymbol = true,
      overridePrivacy = false,
      maximumFractionDigitsParam = maximumFractionDigits,
      minimumFractionDigits?,
    ) {
      if (privacyModeIsEnabled && !overridePrivacy) {
        return DEFAULT_PRIVACY_MODE_ELEMENT;
      }

      const decimalFormatter = formatters.getNumberFormat(undefined, {
        style: 'decimal',
        maximumFractionDigits: maximumFractionDigitsParam,
        minimumFractionDigits,
      });

      if (showCurrencySymbol) {
        const currencyFormatter = formatters.getNumberFormat(undefined, {
          style: 'currency',
          currency: activeFiatCurrencyCode,
          maximumFractionDigits: maximumFractionDigitsParam,
          minimumFractionDigits,
        });

        const valueFixedDecimals = new Decimal(value.toFixed(minimumFractionDigits));

        return currencyFormatter.format(valueFixedDecimals.toNumber());
      }

      return decimalFormatter.format(Number(value));
    },
    [maximumFractionDigits, privacyModeIsEnabled, formatters, activeFiatCurrencyCode],
  );

  const fiatValue = useCallback<CurrencyFormatter['fiatValue']>(
    function fiatValue({
      currencyCode,
      decimals,
      value,
      contractAddress,
      network,
      blockchain,
      digitsToFixed,
    }) {
      return fiatValueUtil({
        currencyCode,
        decimals,
        value,
        contractAddress,
        network,
        blockchain,
        digitsToFixed,
        exchangeRatesMap,
      });
    },
    [exchangeRatesMap],
  );

  /**
   * Convert fiat value to crypto value
   */
  const cryptoValue = useCallback<CurrencyFormatter['cryptoValue']>(
    function cryptoValue({
      currencyCode,
      value,
      contractAddress,
      network,
      blockchain,
      customExchangeRatesMap = exchangeRatesMap,
    }) {
      return cryptoValueUtil({
        exchangeRatesMap: customExchangeRatesMap,
        currencyCode,
        value,
        contractAddress,
        network,
        blockchain,
      });
    },
    [exchangeRatesMap],
  );

  /** Format a [BigInt] representation of a cryptocurrency amount to a given fiat currency using the given [fromCurrencyCode] */
  const formatToFiatCurrency = useCallback<CurrencyFormatter['formatToFiatCurrency']>(
    function formatToFiatCurrency({
      fromCurrencyCode,
      fiatCurrency,
      decimals,
      value,
      isAbbreviated = false,
      contractAddress,
      network,
      blockchain,
      includeCode = true,
      formatZeroBalance = false,
      overridePrivacy = false,
      preserveEndingZeros = false,
      numberFormatNotation = 'compact',
      shouldOverrideWithDollar = false,
    }): string | undefined {
      return formatToFiatCurrencyUtil({
        fromCurrencyCode,
        fiatCurrency,
        decimals,
        value,
        isAbbreviated,
        contractAddress,
        network,
        blockchain,
        includeCode,
        formatZeroBalance,
        overridePrivacy,
        preserveEndingZeros,
        numberFormatNotation,
        privacyModeIsEnabled,
        formatters,
        maximumFractionDigits: Number(fiatCurrency.decimals),
        exchangeRatesMap,
        shouldOverrideWithDollar,
      });
    },
    [privacyModeIsEnabled, formatters, exchangeRatesMap],
  );

  const formatToFiatBase = useCallback<CurrencyFormatter['formatToFiat']>(
    function formatToFiatBase({
      fromCurrencyCode,
      decimals,
      value,
      isAbbreviated = false,
      contractAddress,
      network,
      blockchain,
      includeCode = true,
      formatZeroBalance = false,
      overridePrivacy = false,
      preserveEndingZeros = false,
      numberFormatNotation,
      overrideFiatDecimals,
    }) {
      return network?.asChain()?.isCustomNetwork
        ? undefined
        : formatToFiatCurrency({
            fromCurrencyCode,
            fiatCurrency: overrideFiatDecimals
              ? new FiatCurrency(
                  activeFiatCurrency.code,
                  activeFiatCurrency.name,
                  overrideFiatDecimals,
                )
              : activeFiatCurrency,
            decimals,
            value,
            isAbbreviated,
            contractAddress,
            network,
            blockchain,
            includeCode,
            formatZeroBalance,
            overridePrivacy,
            preserveEndingZeros,
            numberFormatNotation,
          });
    },
    [activeFiatCurrency, formatToFiatCurrency],
  );

  const formatToFiatForUI = useCallback(
    function formatToFiatForUI(
      fromCurrencyCode: CurrencyCode,
      decimals: bigint,
      value?: bigint,
      overridePrivacy = false,
      contractAddress?: string | undefined,
      network?: Network | undefined,
      blockchain?: Blockchain | undefined,
    ): string | undefined {
      if (!value) return undefined;

      if (privacyModeIsEnabled && !overridePrivacy) {
        return DEFAULT_PRIVACY_MODE_ELEMENT;
      }

      const amountFiatValueString = fiatValue({
        currencyCode: fromCurrencyCode,
        decimals,
        value,
        contractAddress,
        network,
        blockchain,
        digitsToFixed: 2,
      })?.toFixed(2);

      if (!amountFiatValueString) return undefined;

      const amountFiatValue = new Decimal(amountFiatValueString);

      const preciseAmountFiatValue = fiatValue({
        currencyCode: fromCurrencyCode,
        decimals,
        value,
        contractAddress,
        network,
        blockchain,
        digitsToFixed: 8,
      });

      const tooLowValueCopy = `< ${fiatValueString(
        new Decimal(0.00000001),
        true,
        overridePrivacy,
        8,
      )}`;

      const shouldIncreasePrecision = amountFiatValue?.lessThan(0.01);

      const abbreviatedPreciseNumber = abbreviateFiatRawValue(preciseAmountFiatValue?.toString());

      if (!abbreviatedPreciseNumber) return undefined;

      const amountInFiatCopy = fiatValueString(
        new Decimal(shouldIncreasePrecision ? abbreviatedPreciseNumber : amountFiatValueString),
        true,
        overridePrivacy,
        shouldIncreasePrecision ? 8 : 2,
      );

      let finalFiatAmountCopy: string | undefined;

      if (!!value && preciseAmountFiatValue?.eq(0)) {
        finalFiatAmountCopy = tooLowValueCopy;
      } else {
        finalFiatAmountCopy = amountInFiatCopy;
      }

      return finalFiatAmountCopy;
    },
    [fiatValue, fiatValueString, privacyModeIsEnabled],
  );

  /**
   * Format a [BigInt] representation of a cryptocurrency amount to a fiat format using the
   * given [fromCurrencyCode]
   *
   * @param fromCurrencyCode Crypto currency code
   * @param contractAddress ERC20 token contract address
   * @param decimals Crypto currency decimals count
   * @param value Value to format
   * @param isAbbreviated If true, returns a shorthand representation of the fiat amount i.e. "21.5M" or "1.2B"
   * @param name Human readable name, like `Bitcoin`
   * @return
   */
  const formatToFiat = useCallback<CurrencyFormatter['formatToFiat']>(
    function formatToFiat({
      fromCurrencyCode,
      decimals,
      value,
      isAbbreviated = false,
      contractAddress,
      network,
      blockchain,
      includeCode = true,
      formatZeroBalance = false,
      overridePrivacy = false,
      preserveEndingZeros = false,
      numberFormatNotation,
      overrideFiatDecimals,
    }) {
      const isUICopy =
        areFiatUICopiesEnabled &&
        !overrideFiatDecimals &&
        !isAbbreviated &&
        includeCode &&
        !numberFormatNotation &&
        !preserveEndingZeros &&
        !formatZeroBalance;

      return isUICopy
        ? formatToFiatForUI(
            fromCurrencyCode,
            decimals,
            value,
            overridePrivacy,
            contractAddress,
            network,
            blockchain,
          )
        : formatToFiatBase({
            fromCurrencyCode,
            decimals,
            value,
            isAbbreviated,
            contractAddress,
            network,
            blockchain,
            includeCode,
            formatZeroBalance,
            overridePrivacy,
            preserveEndingZeros,
            numberFormatNotation,
            overrideFiatDecimals,
          });
    },
    [areFiatUICopiesEnabled, formatToFiatForUI, formatToFiatBase],
  );

  /**
   * Format a [Decimal] representation of a cryptocurrency amount to a fiat format using the
   * given [fromCurrencyCode]
   *
   * @param fromCurrencyCode Crypto currency code
   * @param contractAddress ERC20 token contract address
   * @param decimals Crypto currency decimals count
   * @param value Value to format
   * @param isAbbreviated If true, returns a shorthand representation of the fiat amount i.e. "21.5M" or "1.2B"
   * @param name Human readable name, like `Bitcoin`
   * @return
   */
  const formatDecimalToFiat = useCallback<CurrencyFormatter['formatDecimalToFiat']>(
    // eslint-disable-next-line max-params
    function formatDecimalToFiat(
      fromCurrencyCode,
      decimals,
      value,
      isAbbreviated = false,
      contractAddress?,
      network?,
      blockchain?,
      includeCode = true,
      formatZeroBalance = false,
      overridePrivacy = false,
      preserveEndingZeros = false,
    ) {
      if (value === undefined) return;
      // proceed when formatZeroBalance is true and value is 0n
      // i.e we want to display a $0 balance
      if (value instanceof Decimal && value.eq(0) && !formatZeroBalance) return;

      const fiatDecimalValue = fiatValue({
        currencyCode: fromCurrencyCode,
        decimals,
        value,
        contractAddress,
        network,
        blockchain,
      });

      if (!fiatDecimalValue) return undefined;

      if (privacyModeIsEnabled && !overridePrivacy) {
        return DEFAULT_PRIVACY_MODE_ELEMENT;
      }

      if (isAbbreviated) {
        const formatter = formatters.getNumberFormat(undefined, {
          style: 'decimal',
          notation: 'compact',
          compactDisplay: 'short',
          maximumFractionDigits: 1,
        });
        return formatter.format(fiatDecimalValue.toNumber());
      }
      if (!includeCode) {
        const formatter = formatters.getNumberFormat(undefined, {
          style: 'decimal',
          maximumFractionDigits: 2,
        });

        return preserveEndingZeros
          ? fiatDecimalValue.toFixed(Number(activeFiatCurrency.decimals))
          : formatter.format(fiatDecimalValue.toNumber());
      }

      const currencyFormatter = formatters.getNumberFormat(undefined, {
        style: 'currency',
        currency: activeFiatCurrencyCode,
      });

      const decimalFormatter = formatters.getNumberFormat(undefined, {
        style: 'currency',
        currency: activeFiatCurrencyCode,
      });

      const fiatValueToDecimals = new Decimal(
        fiatDecimalValue.toFixed(Number(activeFiatCurrency.decimals)),
      );

      const result = formatDecimal({
        integerFormatter: currencyFormatter,
        decimalFormatter,
        value: fiatValueToDecimals,
      });

      return result;
    },
    [
      fiatValue,
      privacyModeIsEnabled,
      formatters,
      activeFiatCurrencyCode,
      activeFiatCurrency.decimals,
    ],
  );

  const formatFiatTotal = useCallback<CurrencyFormatter['formatFiatTotal']>(
    function formatFiatTotal(amounts, showAmountPrefix, overridePrivacy = false) {
      let value = new Decimal(0);
      amounts.forEach((amount) => {
        value = value.add(
          fiatValue({
            currencyCode: amount.currencyCode,
            decimals: amount.decimals,
            value: amount.value,
            contractAddress: amount.contractAddress,
            network: amount.network,
            blockchain: amount.blockchain,
          }) ?? new Decimal(0),
        );
      });

      if (value.isZero()) {
        return [undefined, false];
      }

      const absValue = value.absoluteValue();
      const isPositive = value.isPositive();

      const fiatString = fiatValueString(absValue, true, overridePrivacy);

      if (showAmountPrefix) {
        const amountPrefix = isPositive ? '+ ' : '- ';
        return [amountPrefix + fiatString, isPositive];
      }
      return [fiatString, isPositive];
    },
    [fiatValue, fiatValueString],
  );

  /**
   * Format a [BigInt] representation of a cryptocurrency amount to a fiat format using the
   * given [upperBoundValue and lowBoundValue].
   *
   * NOTE: we strongly recommend to use BigInt values for upperBound and lowerBound.
   * We should only use Decimal values for cases where BigInt constructor is not supported
   * (e.g format float values)
   *
   * @return "U$0.01 - U$0.02" (example)
   */
  const formatToFiatFeeRange = useCallback<CurrencyFormatter['formatToFiatFeeRange']>(
    function formatToFiatFeeRange({
      fromCurrencyCode,
      decimals,
      upperBoundValue,
      isAbbreviated = false,
      lowerBoundValue,
      network,
      blockchain,
      overridePrivacy = false,
    }) {
      const lowerBoundValueLocal = lowerBoundValue ?? upperBoundValue;
      const lowerBoundString = formatToFiatBase({
        fromCurrencyCode,
        decimals,
        value: lowerBoundValueLocal,
        isAbbreviated,
        contractAddress: undefined,
        network,
        blockchain,
        includeCode: true,
        formatZeroBalance: false,
        overridePrivacy,
      });

      const upperBoundString = formatToFiat({
        fromCurrencyCode,
        decimals,
        value: upperBoundValue,
        isAbbreviated,
        contractAddress: undefined,
        network,
        blockchain,
        includeCode: true,
        formatZeroBalance: false,
        overridePrivacy,
      });

      if (upperBoundString === undefined || lowerBoundString === undefined) return undefined;

      if (upperBoundString.startsWith('<')) return upperBoundString;

      if (upperBoundValue > lowerBoundValueLocal && upperBoundString !== lowerBoundString) {
        return `${lowerBoundString} – ${upperBoundString}`;
      }
      return upperBoundString;
    },
    [formatToFiat, formatToFiatBase],
  );

  const formatToFiatFeeMinMax = useCallback<CurrencyFormatter['formatToFiatFeeMinMax']>(
    function formatToFiatFeeMinMax({
      fromCurrencyCode,
      decimals,
      upperBoundValue,
      isAbbreviated = false,
      lowerBoundValue,
      network,
      blockchain,
    }) {
      const lowerBoundValueLocal = lowerBoundValue ?? upperBoundValue;

      const lowerBoundString = formatToFiat({
        fromCurrencyCode,
        decimals,
        value: lowerBoundValueLocal,
        isAbbreviated,
        contractAddress: undefined,
        network,
        blockchain,
        includeCode: false,
      });

      const upperBoundString = formatToFiat({
        fromCurrencyCode,
        decimals,
        value: upperBoundValue,
        isAbbreviated,
        contractAddress: undefined,
        network,
        blockchain,
        includeCode: false,
      });

      if (upperBoundString === undefined || lowerBoundString === undefined) {
        return { minFeeInUSD: undefined, maxFeeInUSD: undefined };
      }
      if (upperBoundValue > lowerBoundValueLocal && upperBoundString !== lowerBoundString) {
        return { minFeeInUSD: lowerBoundString, maxFeeInUSD: upperBoundString };
      }
      return { minFeeInUSD: undefined, maxFeeInUSD: upperBoundString };
    },
    [formatToFiat],
  );

  const formatToFiatTotalRange = useCallback<CurrencyFormatter['formatToFiatTotalRange']>(
    function formatToFiatTotalRange({
      feeCurrencyCode,
      currencyCode,
      contractAddress,
      blockchain,
      network,
      fee,
      lowerBoundFee,
      amount,
      isConfirmed,
      decimals,
      feeDecimals,
      includeFee,
      overridePrivacy = false,
    }) {
      const lowerBound = lowerBoundFee ?? fee;

      const regularFeeUpperInDecimal = fiatValue({
        currencyCode: feeCurrencyCode,
        decimals: feeDecimals,
        value: fee,
        network,
        blockchain,
        digitsToFixed: 2,
      });

      const regularAmountInDecimal = fiatValue({
        currencyCode,
        decimals,
        value: amount,
        contractAddress,
        network,
        blockchain,
        digitsToFixed: 2,
      });

      // base assets for fees have no contract address, keep as undefined
      const preciseFeeUpperInDecimal = fiatValue({
        currencyCode: feeCurrencyCode,
        decimals: feeDecimals,
        value: fee,
        network,
        blockchain,
        digitsToFixed: 8,
      });

      const feeLowerInDecimal = fiatValue({
        currencyCode: feeCurrencyCode,
        decimals: feeDecimals,
        value: lowerBound,
        network,
        blockchain,
        digitsToFixed: 2,
      });

      const preciseAmountInDecimal = fiatValue({
        currencyCode,
        decimals,
        value: amount,
        contractAddress,
        network,
        blockchain,
        digitsToFixed: 8,
      });

      // bound check
      if (regularAmountInDecimal === undefined || regularFeeUpperInDecimal === undefined)
        return undefined;

      // bound check
      if (preciseAmountInDecimal === undefined) return undefined;

      // bound check
      if (preciseFeeUpperInDecimal === undefined || feeLowerInDecimal === undefined)
        return undefined;

      const abbreviatedFeeRawValueString = abbreviateFiatRawValue(
        preciseFeeUpperInDecimal.toString(),
      );
      const abbreviatedAmountRawValueString = abbreviateFiatRawValue(
        preciseAmountInDecimal.toString(),
      );

      // bound check
      if (!abbreviatedFeeRawValueString || !abbreviatedAmountRawValueString) return undefined;

      const shouldIncreaseFeePrecision =
        areFiatUICopiesEnabled && regularFeeUpperInDecimal.lessThan(0.01);
      const shouldIncreaseAmountPrecision =
        areFiatUICopiesEnabled && regularAmountInDecimal.lessThan(0.01);

      const feeUpperInDecimal = shouldIncreaseFeePrecision
        ? new Decimal(abbreviatedFeeRawValueString)
        : regularFeeUpperInDecimal;

      const amountInDecimal = shouldIncreaseAmountPrecision
        ? new Decimal(abbreviatedAmountRawValueString)
        : regularAmountInDecimal;

      const totalUpper = amountInDecimal.add(feeUpperInDecimal);
      const totalLower = amountInDecimal.add(feeLowerInDecimal);

      if (!includeFee) {
        return fiatValueString(amountInDecimal, true, overridePrivacy);
      }

      const shouldIncreaseTotalPrecision =
        (shouldIncreaseFeePrecision || shouldIncreaseAmountPrecision) && totalUpper.lessThan(0.01);

      const tooLowValueCopy = `< ${fiatValueString(
        new Decimal(0.00000001),
        true,
        overridePrivacy,
        8,
      )}`;

      const totalUpperCopy = fiatValueString(
        totalUpper,
        true,
        overridePrivacy,
        shouldIncreaseTotalPrecision ? 8 : 2,
      );

      const totalUpperString =
        shouldIncreaseTotalPrecision && totalUpper.eq(0) ? tooLowValueCopy : totalUpperCopy;

      const totalLowerString = fiatValueString(totalLower, true, overridePrivacy, 2);

      if (totalUpperString === tooLowValueCopy) return totalUpperString;

      // confirmed transaction displays fixed total amount in fiat
      if (
        isConfirmed ||
        totalUpperString === totalLowerString ||
        totalUpper.lessThanOrEqualTo(totalLower)
      ) {
        return totalUpperString;
      }
      // pending transaction displays total amount in a range
      return `${totalLowerString} – ${totalUpperString}`;
    },
    [areFiatUICopiesEnabled, fiatValue, fiatValueString],
  );

  /**
   * Format [BN] to a crypto format
   *
   * @param currencyCode Currency code
   * @param decimals Currency decimals count
   * @param value Value to format
   * @param includeCode Whether we should show the currency suffix i.e. "1,000 ETH" vs `1,000`
   * @param maxDisplayDecimals Max number of decimals displayed in the formatted string.
   * @param convertFromFiat Whether we should convert the value from fiat to crypto. We need network and blockchain to be provided in this case.
   * @param network Network
   * @param blockchain Blockchain
   * @param shouldDisplayDustAmount Whether we should display dust amount
   * @param overridePrivacy Whether we should override the privacy mode i.e. ********
   * @param contractAddress Contract address
   *
   * @return The formatted crypto
   */
  const formatToCrypto = useCallback<CurrencyFormatter['formatToCrypto']>(
    function formatToCrypto({
      currencyCode,
      decimals,
      value,
      includeCode = true,
      maxDisplayDecimals = DEFAULT_MAX_DECIMAL_DISPLAY,
      minDisplayDecimals = 0n,
      convertFromFiat = false,
      network,
      blockchain,
      shouldDisplayDustAmount = true,
      overridePrivacy = false,
      contractAddress = undefined,
      customExchangeRatesMap = undefined,
    }) {
      if (value === undefined) return undefined;

      if (privacyModeIsEnabled && !overridePrivacy) {
        return DEFAULT_PRIVACY_MODE_ELEMENT;
      }

      const decimalValue = convertFromFiat
        ? cryptoValue({
            currencyCode,
            value,
            contractAddress,
            network,
            blockchain,
            customExchangeRatesMap,
          })
        : toDecimal(value, decimals);

      if (!decimalValue) {
        return undefined;
      }

      return formatDecimalToCrypto({
        formatters,
        includeCode,
        currencyCode,
        decimalValue,
        minDisplayDecimals,
        maxDisplayDecimals,
        shouldDisplayDustAmount,
      });
    },
    [privacyModeIsEnabled, cryptoValue, formatters],
  );

  const formatToCryptoRange = useCallback<CurrencyFormatter['formatToCryptoRange']>(
    function formatToCryptoRange(
      currencyCode,
      decimals,
      lowerBoundValue,
      upperBoundValue,
      includeCode = true,
      maxDisplayDecimals = DEFAULT_MAX_DECIMAL_DISPLAY_FOR_RANGES,
      minDisplayDecimals = 0n,
    ) {
      const lowerBoundValueLocal = lowerBoundValue ?? upperBoundValue;

      const lowerBoundString = formatToCrypto({
        currencyCode,
        decimals,
        value: lowerBoundValueLocal,
        includeCode: false,
        maxDisplayDecimals,
        minDisplayDecimals,
      });

      const upperBoundString = formatToCrypto({
        currencyCode,
        decimals,
        value: upperBoundValue,
        includeCode,
        maxDisplayDecimals,
        minDisplayDecimals,
      });

      if (upperBoundString === undefined || lowerBoundString === undefined) return undefined;
      if (
        upperBoundValue > lowerBoundValue &&
        upperBoundString.split(' ')[0] !== lowerBoundString
      ) {
        return `${lowerBoundString} – ${upperBoundString}`;
      }
      return upperBoundString;
    },
    [formatToCrypto],
  );

  const formatToCryptoMinMax = useCallback<CurrencyFormatter['formatToCryptoMinMax']>(
    function formatToCryptoMinMax({ decimals, lowerBoundValue, upperBoundValue }) {
      const lowerBoundValueLocal = lowerBoundValue ?? upperBoundValue;

      const lowerBoundString = toDecimal(lowerBoundValueLocal, decimals).toString();
      const upperBoundString = toDecimal(upperBoundValue, decimals).toString();

      if (upperBoundString === undefined || lowerBoundString === undefined)
        return { minFeeInCrypto: undefined, maxFeeInCrypto: undefined };
      if (
        upperBoundValue > lowerBoundValue &&
        upperBoundString.split(' ')[0] !== lowerBoundString
      ) {
        return { minFeeInCrypto: lowerBoundString, maxFeeInCrypto: upperBoundString };
      }
      return { minFeeInCrypto: undefined, maxFeeInCrypto: upperBoundString };
    },
    [],
  );

  return useMemo(
    () => ({
      fiatValue,
      cryptoValue,
      fiatValueString,
      formatToCrypto,
      formatToFiatCurrency,
      formatToFiat,
      formatDecimalToFiat,
      formatFiatTotal,
      formatToFiatFeeRange,
      formatToFiatFeeMinMax,
      formatToCryptoRange,
      formatToCryptoMinMax,
      formatToFiatTotalRange,
    }),
    [
      fiatValue,
      cryptoValue,
      fiatValueString,
      formatToCrypto,
      formatToFiatCurrency,
      formatToFiat,
      formatDecimalToFiat,
      formatFiatTotal,
      formatToFiatFeeRange,
      formatToFiatFeeMinMax,
      formatToCryptoRange,
      formatToCryptoMinMax,
      formatToFiatTotalRange,
    ],
  );
}

import { useCallback } from 'react';
import {
  DefiPositionType,
  LiquidityPoolPosition,
  LiquidityPoolPositionResponse,
} from 'cb-wallet-data/stores/DefiPortfolio/models/DefiPosition';

import { useParseAssetInfoResponse } from '../useParseAssetInfoResponse';

export function useParseLiquidityPoolPositionResponse() {
  const parseAssetInfoResponse = useParseAssetInfoResponse();

  return useCallback(
    function parseLiquidityPoolPositionResponse(
      defiPostionResponse: LiquidityPoolPositionResponse,
    ): LiquidityPoolPosition {
      return {
        assetInfos: defiPostionResponse.assetInfos.map(parseAssetInfoResponse),
        protocolName: defiPostionResponse.protocolName,
        protocolImage: defiPostionResponse.protocolImage,
        protocolId: defiPostionResponse.protocolId,
        walletAddress: defiPostionResponse.walletAddress,
        externalLink: defiPostionResponse.externalLink,
        type: DefiPositionType.LiquidityPool,
      };
    },
    [parseAssetInfoResponse],
  );
}

import { useCallback } from 'react';
import { ETHEREUM_PREFIX } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { useKillSwitchByEthNetwork } from 'cb-wallet-data/hooks/KillSwitches/useKillSwitchByEthNetwork';
import {
  chainComponentsFromNetworkRawValue,
  Network,
} from 'cb-wallet-data/stores/Networks/models/Network';

/**
 *
 * @returns A function that can be called with a Network, which returns a boolean
 * indicating whether the network can be used.
 */
export function useIsNetworkAllowed(): (network: Network) => boolean {
  const isNetworkKilled = useKillSwitchByEthNetwork('kill_chain');

  return useCallback(
    function isNetworkAllowed(network: Network) {
      const chain = network.asChain();
      const { chainPrefix } = chainComponentsFromNetworkRawValue(network.rawValue);

      // If it's evm compatible but chain is undefined, it's deprecated from our EthereumChain network map
      if (chainPrefix === ETHEREUM_PREFIX && !chain) {
        return false;
      }

      // If chain is undefined, but it's not an evm network, allow. ex: btc, doge, etc
      if (!chain) {
        return true;
      }

      // If none of the above conditions are met, check isNetworkKilled
      return !isNetworkKilled(BigInt(chain.chainId));
    },
    [isNetworkKilled],
  );
}

import { RouterPathnameKeys } from ':dapp/utils/RoutesEnum';

export const pageKeyRegex: Record<RouterPathnameKeys, RegExp> = {
  assets: /(^\/assets$|^\/$)/,
  crypto: /^\/assets\/crypto(.*)/,
  defi: /(^\/assets\/defi|^\/swap$)(.*)/,
  nft: /^\/assets\/nft(.*)/,
  transactions: /^\/assets\/transactions(.*)/,
  creations: /^\/assets\/creations(.*)/,
  testnets: /^\/assets\/testnets(.*)/,
  settings: /^\/settings(.*)/,
  dapps: /^\/apps(.*)/,
  quests: /^\/quests(.*)/,
  ocs: /^\/ocs(.*)/,
};

import { useCallback } from 'react';

import { Account } from '../models/Account';

import { useGetAccountById } from './useGetAccountById';

export function useCheckIfAccountExists() {
  const getAccountById = useGetAccountById();

  return useCallback(
    function checkIfAccountExists(accountId: Account['id']) {
      return !!getAccountById(accountId);
    },
    [getAccountById],
  );
}

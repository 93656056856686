import { logMetric, MetricType } from '@cbhq/client-analytics';

import { logDataEvent } from '../utils/log';

type TransactionHistoryEventOptions = {
  transactionHistoryVersion: string;
  chainName: string;
  isFullSync: string;
  errorMessage?: string;
};

export const txGenerationStart = 'task.tx_generation.start';
export const txGenerationSuccess = 'task.tx_generation.success';
export const txGenerationFail = 'task.tx_generation.fail';
export const txSubmittedStart = 'task.confirm_transaction.tx_submitted.start';
export const txSubmittedSuccess = 'task.confirm_transaction.tx_submitted.success';
export const txSubmittedFail = 'task.confirm_transaction.tx_submitted.fail';
export const txReceived = 'task.receive_transaction.tx_received';

export function logConfirmTransactionGasPriceFailure(params: {
  blockchain: string;
  chainName: string;
  chainId: string;
  errorType: string;
  errorMessage: string;
  txSource: string;
  gasPriceInGwei?: string;
  gasLimit?: string;
  solanaGasPrice?: string;
  balanceUndefined?: boolean;
}) {
  logDataEvent('confirm_transaction.gas_price_failure', {
    loggingId: '26ee3024-b786-48e0-9554-f8458664821a',
    ...params,
  });
}

export function logConfirmTransactionFeeWalletBalanceMismatch(params: {
  blockchain: string;
  chainName?: string;
  chainId?: string;
}) {
  logDataEvent('confirm_transaction.fee_wallet_balance_mismatch', {
    ...params,
  });
}

export function logConfirmTransactionGasPriceSuccess(params: {
  blockchain: string;
  chainName?: string;
  chainId?: string;
}) {
  logDataEvent('confirm_transaction.gas_price_success', {
    loggingId: '5735417b-822e-43ee-8747-5bebb2c4d5ef',
    chainId: params.chainId ?? '',
    blockchain: params.blockchain,
    chainName: params.chainName ?? '',
  });
}

export function logEthTransactionAcceptedButReverted() {
  logDataEvent('eth_tx_accepted_but_reverted', {
    loggingId: '740f6ed8-0a25-4ce5-ab5f-c14815a3faa6',
  });
}

export function logSolanaTxAcceptedButReverted() {
  logDataEvent('solana_tx_accepted_but_reverted', {
    loggingId: 'b15cba39-3793-42cf-b8d6-93f30842234e',
  });
}

export function logXrpTransactionAcceptedButReverted() {
  logDataEvent('xrp_tx_accepted_but_reverted', {
    loggingId: 'fb487e99-20cb-4b63-87f4-7cfb9e6c4de3',
  });
}

export function logXlmTransactionAcceptedButReverted() {
  logDataEvent('xlm_tx_accepted_but_reverted', {
    loggingId: 'f05b98be-6b99-4967-9644-e1804730ffb0',
  });
}

export function logResubmitTransactionFail(networkRawValue: string) {
  logDataEvent('task.resubmit_transaction.fail', {
    loggingId: '3450e9d1-0cc4-445d-a3b0-9afb2063aa4b',
    networkRawValue,
  });
}

export function logNudgeInvalidData({ data, errorMessage }: { data: any; errorMessage: string }) {
  logDataEvent('nudge.invalid_data', {
    loggingId: '542ab748-a8a2-4b05-b9f0-7d9b64e60eea',
    data,
    errorMessage,
  });
}

export function logNudgeConnectionError(event: any) {
  logDataEvent('nudge.connection_error', {
    loggingId: '53e4f5df-968b-4284-bdcf-dc679c58119a',
    event: JSON.stringify(event),
  });
}

export function logNudgeConnectionOpened() {
  logDataEvent('nudge.connection_opened', {
    loggingId: 'ce58aee7-cd4b-4c30-a004-d4e342d9ac4a',
  });
}

export function logTxDropped({
  txSource,
  blockchain,
  chainId,
  chainName,
  txHash,
  isContractDeploy,
  isFirstPartyRPC,
  isSponsoredTx,
  isGaslessSwap,
}: {
  txHash: string;
  txSource: string;
  blockchain: string;
  chainId: number;
  chainName: string;
  isContractDeploy: boolean;
  isFirstPartyRPC: string;
  isSponsoredTx: boolean;
  isGaslessSwap: boolean;
}) {
  logDataEvent('tx_dropped', {
    loggingId: 'eab43568-c7da-4527-9ccd-d6695088d93c',
    txSource,
    blockchain,
    chainId,
    chainName,
    txHash,
    isContractDeploy,
    isFirstPartyRPC,
    isSponsoredTx,
    isGaslessSwap,
  });
}

export function logTxFailed({
  txSource,
  blockchain,
  chainId,
  chainName,
  txHash,
  isContractDeploy,
  isFirstPartyRPC,
  isSponsoredTx,
  isGaslessSwap,
}: {
  txSource: string;
  blockchain: string;
  chainId: number;
  chainName: string;
  txHash: string;
  isContractDeploy: boolean;
  isFirstPartyRPC: string;
  isSponsoredTx: boolean;
  isGaslessSwap: boolean;
}) {
  logDataEvent('tx_failed', {
    loggingId: 'a75f8247-37fa-4414-92b7-7f4bc0f84cbd',
    txSource,
    blockchain,
    chainId,
    chainName,
    txHash,
    isContractDeploy,
    isFirstPartyRPC,
    isSponsoredTx,
    isGaslessSwap,
  });
}

/// This is an event used to calculate metrics shared in quarterly financial reports. Please
/// take great care in modifying its usage.
export function logTxConfirmed(params: {
  txSource: string;
  txHash: string;
  blockchain: string;
  chainId?: number;
  chainName?: string;
  isContractDeploy: boolean;
  isFirstPartyRPC: string;
  minutesToConfirm?: string;
  secondsToConfirm?: string;
  /* analyticsId exists so we can write sql queries and join them by this id across events for solana transactions */
  analyticsId?: string;
  networkFeeInCrypto?: string;
  networkFee?: string;
  feeCurrencyCode?: string;
  feeCurrencyDecimal?: string;
  nonce?: string;
  isSponsoredTx: boolean;
  isGaslessSwap: boolean;
}) {
  logDataEvent('tx_confirmed', {
    ...params,
    chainId: params.chainId ?? -1,
    chainName: params.chainName ?? '',
  });
}

export function logTxRecieved(params: {
  txHash?: string;
  blockchain: string;
  chainId?: number;
  chainName?: string;
  currencyCode: string;
  feeCurrencyCode: string;
  feeCurrencyDecimal: string;
  fromAddress?: string;
  fromDomain?: string;
  toAddress?: string;
  toDomain?: string;
  amount: string;
  fee: string;
  network: string;
  contractAddress?: string;
  nonce?: string;
  isSpam?: boolean;
  isGaslessSwap: boolean;
  isSponsoredTx: boolean;
  isNFTTransfer: boolean;
  isNFTTransaction: boolean;
  isCashout: boolean;
  isConsumerTransfer?: boolean;
  isRelayed: boolean;
}) {
  logDataEvent(txReceived, {
    ...params,
    chainId: params.chainId ?? -1,
    chainName: params.chainName ?? '',
  });
}

export function logTxDroppedByRelayer(params: {
  txSource: string;
  txHash: string;
  blockchain: string;
  chainId: number;
  chainName: string;
  isSponsoredTx: boolean;
  isGaslessSwap: boolean;
}) {
  logDataEvent('tx_dropped_by_relayer', {
    ...params,
  });
}

export function logPotentialExchangeRateIssue({
  blockchain,
  chainId,
  chainName,
  currencyCode,
  contractAddress,
  networkName,
}: {
  blockchain: string;
  chainId?: number | string;
  chainName?: string;
  currencyCode?: string;
  contractAddress?: string;
  networkName?: string;
}) {
  logDataEvent('potential_exchange_rate_issue', {
    loggingId: '7c067630-bb8c-11ec-8422-0242ac120002',
    blockchain,
    currencyCode,
    contractAddress: contractAddress ?? '',
    networkName,
    chainName: chainName ?? '',
    chainId: chainId ?? '',
  });
}

export function triggerSyncTransactionsStart(options: TransactionHistoryEventOptions) {
  return logMetric({
    metricName: 'transactions.sync.start',
    metricType: MetricType.count,
    value: 1,
    tags: { ...options },
  });
}

export function triggerSyncTransactionsSuccess(options: TransactionHistoryEventOptions) {
  return logMetric({
    metricName: 'transactions.sync.success',
    metricType: MetricType.count,
    value: 1,
    tags: { ...options },
  });
}

export function triggerSyncTransactionsFail(options: TransactionHistoryEventOptions) {
  return logMetric({
    metricName: 'transactions.sync.fail',
    metricType: MetricType.count,
    value: 1,
    tags: { ...options },
  });
}

export function triggerHadMaliciousZeroValueTransfer() {
  return logMetric({
    metricName: 'transactions.had_malicious_zero_value_transfer',
    metricType: MetricType.count,
    value: 1,
  });
}

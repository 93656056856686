import { useCallback } from 'react';
import { logTxDropped, logTxFailed } from 'cb-wallet-analytics/data/Transactions';
import { TxOrUserOpMetadataKey_txSource } from 'cb-wallet-data/chains/AccountBased/Ethereum/config';
import { EthereumNetworkMap } from 'cb-wallet-data/chains/AccountBased/Ethereum/EthereumChain';
import { AllPossibleBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { TxOrUserOp, TxOrUserOpParams } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';

import { persistTxOrUserOps, retrieveTransactionsMatchingHash } from '../database';
import { TxState } from '../models/TxState';

import { usePaginatedTransactions } from './usePaginatedTransactions';

const droppedTransactionHashes: string[] = [];

function handleDroppedOrFailedTransaction(
  transaction: TxOrUserOp,
  blockchain: Blockchain,
  txState: TxState,
): void {
  const hash = transaction.txHash!;

  let txSource = transaction.metadata.get(TxOrUserOpMetadataKey_txSource);

  if (typeof txSource !== 'string') {
    txSource = '';
  }

  const chain = transaction.network.asChain();
  const chainId = chain?.chainId ?? -1;
  const chainName = chain?.displayName ?? '';
  const isContractDeploy = !transaction.toAddress;
  const isSponsoredTx = transaction.isSponsored();
  const isGaslessSwap = transaction.isGaslessSwap();

  if (!droppedTransactionHashes.includes(hash)) {
    const isFirstPartyRPC =
      EthereumNetworkMap.isWhitelisted(chain?.chainId.toString() ?? '-1') &&
      !EthereumNetworkMap.isWhiteListedAndCustomized(chain?.chainId.toString() ?? '-1');

    if (txState === TxState.DROPPED) {
      logTxDropped({
        txHash: hash,
        txSource: txSource.toLocaleString(),
        blockchain: blockchain.rawValue,
        chainId,
        chainName,
        isContractDeploy,
        isFirstPartyRPC: isFirstPartyRPC.toString(),
        isSponsoredTx,
        isGaslessSwap,
      });
    }

    if (txState === TxState.FAILED) {
      logTxFailed({
        txHash: hash,
        txSource: txSource.toLocaleString(),
        blockchain: blockchain.rawValue,
        chainId,
        chainName,
        isContractDeploy,
        isFirstPartyRPC: isFirstPartyRPC.toString(),
        isSponsoredTx,
        isGaslessSwap,
      });
    }

    droppedTransactionHashes.push(hash);
  }
}

type UpdateTransactionState = (
  transactionHash: string,
  blockchain: Blockchain,
  txState: TxState,
) => Promise<void>;

export function useUpdateTransactionState(): UpdateTransactionState {
  const { updatePaginatedTransactions } = usePaginatedTransactions();
  const updateTransactionState = async (
    transactionHash: string,
    blockchain: Blockchain,
    txState: TxState,
  ) => {
    const transactions = await retrieveTransactionsMatchingHash({
      hash: transactionHash,
      blockchainSymbol: blockchain.rawValue as AllPossibleBlockchainSymbol,
    });

    const matchingTransactions = transactions.filter((tx) => tx.txHash === transactionHash);

    const firstMatchingTransaction = matchingTransactions[0];

    const isTransitioningToFailedOrDropped =
      txState === TxState.FAILED || txState === TxState.DROPPED;
    if (isTransitioningToFailedOrDropped) {
      handleDroppedOrFailedTransaction(firstMatchingTransaction, blockchain, txState);
    }

    const filteredTxs = matchingTransactions
      .filter(({ state }) => txState !== state)
      .map(
        (fetchedTx) =>
          new TxOrUserOp({
            id: fetchedTx.id,
            createdAt: fetchedTx.createdAt,
            confirmedAt: fetchedTx.confirmedAt,
            blockchain: fetchedTx.blockchain,
            currencyCode: fetchedTx.currencyCode,
            feeCurrencyCode: fetchedTx.feeCurrencyCode,
            feeCurrencyDecimal: fetchedTx.feeCurrencyDecimal,
            toAddress: fetchedTx.toAddress,
            toDomain: fetchedTx.toDomain,
            fromAddress: fetchedTx.fromAddress,
            fromDomain: fetchedTx.fromDomain,
            amount: fetchedTx.amount,
            fee: fetchedTx.fee,
            state: txState,
            metadata: fetchedTx.metadata,
            network: fetchedTx.network,
            walletIndex: fetchedTx.walletIndex,
            accountId: fetchedTx.accountId,
            txOrUserOpHash: fetchedTx.txOrUserOpHash,
            userOpHash: fetchedTx.userOpHash,
            txHash: fetchedTx.txHash,
            isSent: fetchedTx.isSent,
            contractAddress: fetchedTx.contractAddress,
            tokenName: fetchedTx.tokenName,
            tokenDecimal: fetchedTx.tokenDecimal,
            walletId: fetchedTx.walletId,
            nonce: fetchedTx.nonce,
            pendingEthTxData: fetchedTx.pendingEthTxData,
            pendingUTXOTxData: fetchedTx.pendingUTXOTxData,
            type: fetchedTx.type,
            transfers: fetchedTx.transfers,
            deleted: fetchedTx.deleted,
            isSpam: fetchedTx.isSpam,
            primaryAction: fetchedTx.primaryAction,
            toAssetHistoricalPrice: fetchedTx.toAssetHistoricalPrice,
            fromAssetHistoricalPrice: fetchedTx.fromAssetHistoricalPrice,
            nativeAssetHistoricalPrice: fetchedTx.nativeAssetHistoricalPrice,
            fromAssetImage: fetchedTx.fromAssetImage,
            toAssetImage: fetchedTx.toAssetImage,
            fromProfileImage: fetchedTx.fromProfileImage,
            toProfileImage: fetchedTx.toProfileImage,
            fromAmount: fetchedTx.fromAmount,
            toAmount: fetchedTx.toAmount,
            isContractExecution: fetchedTx.isContractExecution,
            toNetwork: fetchedTx.toNetwork,
            toCurrencyCode: fetchedTx.toCurrencyCode,
            toContractAddress: fetchedTx.toContractAddress,
            toTokenName: fetchedTx.toTokenName,
            toTokenDecimal: fetchedTx.toTokenDecimal,
            coinbaseFeeAmount: fetchedTx.coinbaseFeeAmount,
            coinbaseFeeDecimal: fetchedTx.coinbaseFeeDecimal,
            coinbaseFeeAssetAddress: fetchedTx.coinbaseFeeAssetAddress,
            coinbaseFeeCurrencyCode: fetchedTx.coinbaseFeeCurrencyCode,
            coinbaseFeeName: fetchedTx.coinbaseFeeName,
            protocolFeeAmount: fetchedTx.protocolFeeAmount,
            protocolFeeDecimal: fetchedTx.protocolFeeDecimal,
            protocolFeeCurrencyCode: fetchedTx.protocolFeeCurrencyCode,
            protocolFeeName: fetchedTx.protocolFeeName,
            toWalletId: fetchedTx.toWalletId,
            fromTokenId: fetchedTx.fromTokenId,
            toTokenId: fetchedTx.toTokenId,
            source: TxOrUserOp.inferTxSource(
              fetchedTx.type,
              fetchedTx.source,
              fetchedTx.state,
              fetchedTx.blockchain,
              fetchedTx.confirmedAt,
            ),
          } as TxOrUserOpParams),
      );

    if (filteredTxs.length === 0) return;

    await persistTxOrUserOps(filteredTxs);
    updatePaginatedTransactions(filteredTxs);
  };

  return useCallback(updateTransactionState, [updatePaginatedTransactions]);
}

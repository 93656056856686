import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { BASE_ASSET_ADDRESS } from 'cb-wallet-data/stores/Transactions/methods/parsers/HRT/constants';
import { ParseParams } from 'cb-wallet-data/stores/Transactions/methods/parsers/HRT/parseParams';
import {
  buildHRTMetadata,
  findRelevantTransfer,
  primaryActionToType,
} from 'cb-wallet-data/stores/Transactions/methods/parsers/utils/utils';
import { TxOrUserOp, TxOrUserOpParams } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import { TxState } from 'cb-wallet-data/stores/Transactions/models/TxState';
import { unixTimestampToDate } from 'cb-wallet-data/stores/Transactions/utils/unixTimestampToDate';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { v4 as uuidv4 } from 'uuid';
import { Apiv1Label, V1TransactionStatus } from '@cbhq/instant-api-hooks-wallet-tx-history/types';

/**
 * parseContractExecution is used as our default transaction type parser for HRT transactions. It is used in cases
 * where we are unable to parse the transaction type, or if there is an issue parsing a transaction type.
 */
export function parseContractExecution({
  primaryAction,
  historicalAssetPricesUsd,
  feeAmount,
  txHash,
  txNonce,
  txTimestamp,
  txStatus,
  userOpHash,
  userAddress,
  blockchain,
  network,
  accountId,
  walletIndex,
  addressMetadata,
  transfers,
}: ParseParams): TxOrUserOp | undefined {
  const relevantTransfer = findRelevantTransfer(
    primaryAction?.type || Apiv1Label.LABEL_UNKNOWN,
    transfers,
    userAddress,
  );

  const nativeAssetMetadata = addressMetadata[BASE_ASSET_ADDRESS]?.token;

  const date = unixTimestampToDate(txTimestamp || new Date().getTime().toString());
  const nonce = BigInt(txNonce || 0);

  const nativeAssetHistoricalPrice = historicalAssetPricesUsd?.[BASE_ASSET_ADDRESS];

  const nativeAssetCurrencyCode = new CurrencyCode(
    nativeAssetMetadata?.symbol || blockchain.rawValue,
  );
  const emptyCurrencycode = new CurrencyCode('');

  const feeCurrencyDecimal = BigInt(nativeAssetMetadata?.decimals || 0);
  const fee = BigInt(feeAmount || 0);
  const feeCurrencyCode = nativeAssetCurrencyCode;

  const txMetadata = buildHRTMetadata([], primaryAction?.metadata);

  const walletId = Wallet.generateId({
    blockchain,
    currencyCode: relevantTransfer?.currencyCode || emptyCurrencycode,
    network,
    contractAddress:
      relevantTransfer?.contractAddress === BASE_ASSET_ADDRESS
        ? undefined
        : relevantTransfer?.contractAddress,
    selectedIndex: walletIndex,
    accountId,
  });

  return new TxOrUserOp({
    id: uuidv4(),
    createdAt: date,
    confirmedAt: date,
    blockchain,
    currencyCode: relevantTransfer?.currencyCode || emptyCurrencycode,
    feeCurrencyCode,
    feeCurrencyDecimal,
    toAddress: relevantTransfer?.toAddress || undefined,
    toDomain: relevantTransfer?.toDomain || undefined,
    fromAddress: relevantTransfer?.fromAddress || undefined,
    fromDomain: relevantTransfer?.fromDomain || undefined,
    amount: relevantTransfer?.amount || 0n,
    fee,
    state:
      txStatus === V1TransactionStatus.TRANSACTION_STATUS_SUCCESS
        ? TxState.CONFIRMED
        : TxState.FAILED,
    metadata: txMetadata,
    network,
    txOrUserOpHash: userOpHash ?? txHash!,
    userOpHash,
    walletIndex,
    accountId,
    txHash,
    isSent: true,
    contractAddress:
      relevantTransfer?.contractAddress === BASE_ASSET_ADDRESS
        ? undefined
        : relevantTransfer?.contractAddress,
    tokenName: relevantTransfer?.tokenName || '',
    tokenDecimal: relevantTransfer?.decimal || 0n,
    walletId,
    nonce,
    type: primaryActionToType({ primaryActionLabel: primaryAction?.type, txMetadata }),
    transfers,
    primaryAction: 'CONTRACT_EXECUTION',
    nativeAssetHistoricalPrice,
    isContractExecution: true,
    source: 'V3',
  } as TxOrUserOpParams);
}

import { createConfig } from '@wagmi/core';
import { getEthereumRPCURL } from 'cb-wallet-data/chains/AccountBased/Ethereum/apis/EthereumRPC';
import { bundlerActions } from 'permissionless';
import { Chain, createClient, http } from 'viem';

import { WHITELISTED_CHAINS } from './chains';

let config: ReturnType<typeof createConfig>;

export function getConfig() {
  return config;
}

export function initConfig({
  multiInjectedProviderDiscovery = true,
  chains = WHITELISTED_CHAINS,
}: {
  multiInjectedProviderDiscovery?: boolean;
  chains?: Chain[];
}) {
  config = createConfig({
    chains: chains as unknown as readonly [Chain, ...Chain[]],
    multiInjectedProviderDiscovery,
    client({ chain }) {
      return createClient({
        chain,
        transport: http(getEthereumRPCURL(chain.id)),
      }).extend(bundlerActions);
    },
  });
  return config;
}

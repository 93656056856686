// eslint-disable-next-line @cbhq/func-declaration-in-module
let authTokenRefreshFailureHandler = async () => {
  // The Extension has its own mechanism for re-fetching auth tokens
  // https://github.cbhq.net/wallet/wallet-mobile/pull/3222
};

// Client apps have app-specific behavior for handling auth token refresh failures
export function setAuthTokenRefreshFailureHandler(handler: () => Promise<void>) {
  authTokenRefreshFailureHandler = handler;
}

export async function handleAuthTokenRefreshFailure(): Promise<void> {
  return authTokenRefreshFailureHandler();
}

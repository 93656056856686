import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { Persistable } from 'cb-wallet-data/persistence/Database.interface';
import { stringToURL } from 'cb-wallet-data/utils/String+Core';
import { Column, Entity, Index, PrimaryColumn } from '@cbhq/typeorm';

@Entity('erc20')
@Index('IDX_ERC20_ADDR_CHAIN', ['contractAddress', 'chainIdStr'], { unique: true })
export class ERC20DMO {
  @PrimaryColumn('varchar')
  id!: string;

  @Column('varchar')
  name!: string;

  @Column({ name: 'decimalsStr', type: 'varchar' })
  decimalsStr!: string;

  @Column('varchar')
  contractAddress!: string;

  @Column({ name: 'chainIdStr', type: 'varchar' })
  chainIdStr!: string;

  @Column({ name: 'currencyCodeStr', type: 'varchar' })
  currencyCodeStr!: string;

  @Column({ name: 'blockchainStr', type: 'varchar' })
  blockchainStr!: string;

  @Column({ name: 'imageURLStr', type: 'varchar', nullable: true })
  imageURLStr?: string;

  // 7/31/23 - After reverting to the previous implementation with the Date.now()
  // as the default, it is running into a temporary table error:
  // [react-native-quick-sqlite] SQL execution error: NOT NULL constraint failed: temporary_erc20.lastUpdated
  //
  // This column is updated to nullable with no default value.
  //
  // 7/26/23 - As a result of
  // https://github.cbhq.net/wallet/wallet-mobile/pull/13394/
  // there were typeorm database errors:
  // [react-native-quick-sqlite] SQL execution error: NOT NULL constraint failed: temporary_erc20.lastUpdated
  //
  // This is reverted back to the previous implementation of:
  // @Column({ name: 'lastUpdated', nullable: false, default: Date.now() })
  @Column({ name: 'lastUpdated', nullable: true })
  lastUpdated!: number;
}

export function getERC20Id(contractAddress: string, chainId: string) {
  return [contractAddress, chainId].join('/');
}

export class ERC20 implements Persistable<ERC20DMO> {
  readonly id: string;

  constructor(
    readonly name: string,
    readonly decimals: bigint,
    readonly contractAddress: string,
    readonly chainId: bigint,
    readonly currencyCode: CurrencyCode,
    readonly blockchain: Blockchain,
    readonly lastUpdated: number,
    readonly imageURL?: URL,
  ) {
    this.id = getERC20Id(contractAddress, chainId.toString());
  }

  get asDMO(): ERC20DMO {
    return {
      id: this.id,
      name: this.name,
      decimalsStr: this.decimals.toString(),
      contractAddress: this.contractAddress,
      chainIdStr: this.chainId.toString(),
      currencyCodeStr: this.currencyCode.rawValue,
      blockchainStr: this.blockchain.rawValue,
      imageURLStr: this.imageURL?.toString(),
      lastUpdated: this.lastUpdated ?? Date.now(),
    };
  }

  static fromDMO(dmo: ERC20DMO): ERC20 {
    return new ERC20(
      dmo.name,
      BigInt(dmo.decimalsStr),
      dmo.contractAddress,
      BigInt(dmo.chainIdStr),
      new CurrencyCode(dmo.currencyCodeStr),
      new Blockchain(dmo.blockchainStr),
      dmo.lastUpdated ?? Date.now(),
      stringToURL(dmo.imageURLStr),
    );
  }
}

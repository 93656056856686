import {
  QueriesOptions,
  QueriesResults,
  // eslint-disable-next-line no-restricted-imports
  useQueries as useQueriesBase,
} from '@tanstack/react-query';
import { useDeepCompareMemoize } from 'use-deep-compare-effect';

/**
 * The array returned from useQueries is not stable, which can lead to
 * issues the hook or component calling useQueries renders often. This hook
 * wraps the result of useQueries in a deep equality check and only returns
 * a new object if the value has changed at all
 */
export function useStableQueries<T extends unknown[]>(
  queries: readonly [...QueriesOptions<T>],
): QueriesResults<T> {
  const result = useQueriesBase({ queries });

  return useDeepCompareMemoize(result);
}

export type SubdomainProfile = {
  visible: boolean;
  controller?: string;
  registrant?: string;
  avatar?: string;
  email?: string;
  url?: string;
  description?: string;
  notice?: string;
  keywords?: string;
  github?: string;
  reddit?: string;
  twitter?: string;
  content?: string;
};

export type NFTAvatarInfo = {
  token_type: number;
  token_id: string;
  contract_address: string;
  chain_id: string;
};

export const tokenTypeMap = new Map([
  [undefined, 0],
  ['ERC20', 1],
  ['ERC721', 2],
  ['ERC1155', 3],
  ['ERC165', 4],
]);

export type PartialSubdomainProfile = Partial<SubdomainProfile>;

const linkFields = ['github', 'reddit', 'twitter'] as const;
export type LinkField = (typeof linkFields)[number];
export type ComField = `com.${LinkField}`;

export type SubdomainProfileBody = Omit<
  PartialSubdomainProfile,
  LinkField | 'controller' | 'registrant'
>;

export type ProfileField = keyof SubdomainProfileBody;
export type MaskField = ProfileField | ComField;

const profileFields: ProfileField[] = [
  'avatar',
  'content',
  'description',
  'email',
  'keywords',
  'notice',
  'url',
  'visible',
];

export type SubdomainFieldMask = {
  paths: MaskField[];
};

export type UpdateSubdomainProfileOptions = {
  subdomain: string;
  properties: SubdomainProfileBody;
  address?: string;
};

export type UpdateSubdomainProfileBody = {
  subdomain: UpdateSubdomainProfileOptions['subdomain'];
  properties: Omit<SubdomainProfileBody, LinkField> & { [K in ComField]?: string };
  field_mask: SubdomainFieldMask;
  ethereumAddresses?: string[];
};

type Addresses = Record<string, string>;

type CoinAddresses = Record<'coin_addresses', Addresses>;

export type SubdomainProfileResponse = {
  profile: CoinAddresses & PartialSubdomainProfile;
};

export type PublicSubdomainProfileResponse = CoinAddresses & {
  profile: PartialSubdomainProfile;
  subdomain: string;
};

export type SubdomainProfileResult = Partial<{
  subdomain: string;
  address: string;
  addresses: Addresses;
  profile: PartialSubdomainProfile;
}>;

export type SubdomainState = 'Invalid' | 'Reserved' | 'Claimed' | 'NotClaimed';

export function isLinkField(key: string): key is LinkField {
  return linkFields.includes(key as LinkField);
}

export function isProfileField(key: string): key is ProfileField {
  return profileFields.includes(key as ProfileField);
}

export type UpdateWalletAddressOptions = {
  subdomain: string;
  symbol: string;
  address: string;
};

export type UpdateWalletAddressBody = {
  subdomain: string;
  coin_symbol: string;
  coin_address: string;
};

export type UserDomainsBody = {
  userAddress: string;
};

export type UserDomainName = {
  type: 'l1' | 'offchain';
  name: string;
  isPrimaryDomain: boolean;
  avatarUrl?: string;
  address?: string;
};

export type UserDomainNamesResponse = {
  domains?: UserDomainName[];
};

export type SetUserPrimaryDomainBody = {
  primaryDomainName: string;
  userAddress: string;
};

export type Profile = {
  id: number;
  address: string;
  subdomain: string;
  subdomainState: SubdomainState;
  subdomainProfile: PublicDomainProfile;
  primaryDomainName: string;
  primaryDomainAvatar: string;
};

export type PublicProfile = {
  address: string;
  primaryDomainType: PublicProfilePrimaryDomainType;
  subdomainProfile?: PublicDomainProfile;
  ensDomainProfile?: PublicDomainProfile;
};

export type PublicProfilePrimaryDomainType = 'cbid' | 'eth';

export type PublicDomainProfile = {
  name: string;
  visible: boolean;
  profileTextRecords: PublicProfileTextRecords;
  coinAddresses: Record<string, string>;
};

export type PublicProfileTextRecords = {
  email?: string;
  url?: string;
  description?: string;
  notice?: string;
  keywords?: string;
  github?: string;
  discord?: string;
  reddit?: string;
  twitter?: string;
  telegram?: string;
  content?: string;
  avatar?: string;
};

export type ProfileByAddressesProfile = {
  address: string;
  subdomainProfile: PublicDomainProfile;
  ensDomainProfile: PublicDomainProfile;
  primaryDomainName: string;
  primaryDomainAvatar?: string;
  subdomainState: SubdomainState;
  subdomain: string;
};

export type SubdomainClaimEntrypoint =
  | 'instant-onboarding'
  | 'onboarding'
  | 'existing'
  | 'messaging';

export function isNFTAvatar(avatar: string | undefined) {
  if (!avatar) return false;
  return avatar.startsWith('eip155');
}

import { AmountInputLoadingFallback } from 'wallet-cds-web/components/AmountInput/AmountInputLoadingFallback';
import { AssetSelectorGroupLoadingFallback } from 'wallet-cds-web/components/AssetSelectorGroup/AssetSelectorGroupLoadingFallback';
import { VStack } from '@cbhq/cds-web/layout';

import { SwapSidebarContentWrapper } from '../Swap/SwapSidebarContentWrapper';

export function SendStepFallback() {
  return (
    <SwapSidebarContentWrapper>
      <VStack
        testID="send-step-fallback"
        height={525}
        width="100%"
        gap={2}
        spacing={3}
        justifyContent="space-between"
      >
        <VStack gap={2}>
          <AmountInputLoadingFallback />
          <AssetSelectorGroupLoadingFallback />
        </VStack>
      </VStack>
    </SwapSidebarContentWrapper>
  );
}

import { fromEthereumChain } from 'cb-wallet-data/chains/AccountBased/Ethereum/config';
import { EthereumNetworkMap } from 'cb-wallet-data/chains/AccountBased/Ethereum/EthereumChain';

export function selectEthereumNetworkFromChainId(chainId: number | undefined) {
  if (!chainId) {
    throw new Error('No chainId provided');
  }

  const chain = EthereumNetworkMap.fromChainId(BigInt(chainId));

  if (!chain) return;

  const network = fromEthereumChain(chain).network;

  return network;
}

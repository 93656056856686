import { Persistable } from 'cb-wallet-data/persistence/Database.interface';
import { Column, Entity, Index, PrimaryColumn } from '@cbhq/typeorm';

import { PAYMENT_ACTIVITY_CONTENT_TYPE } from '../constants';
import { ConversationMetadata, FormattedConversationPreview } from '../types';

@Entity('conversation_preview')
@Index('IDX_CONVERSATION_PREVIEW', ['conversationKey', 'walletAddress'])
export class ConversationPreviewDMO {
  // It seems that some of the tests assume there is an `id` field, so I have included one here
  @PrimaryColumn()
  id!: string;

  @Column()
  conversationKey!: string;

  @Column()
  topic!: string;

  @Column()
  walletAddress!: string;

  @Column({ nullable: true })
  messageId?: string;

  @Column({ nullable: true })
  contentText?: string;

  @Column({ nullable: true })
  senderAddress?: string;

  @Column()
  peerAddress!: string;

  @Column({ nullable: true })
  contentType?: string;

  @Column('datetime', { nullable: true })
  sent?: Date;

  @Column('datetime')
  createdAt!: Date;

  @Column({ nullable: true })
  appDomain?: string;

  @Column({ default: 0 })
  unreadMessageCount?: number;

  @Column({ nullable: true })
  displayName?: string;

  @Column({ nullable: true })
  displayImage?: string;
}

export class ConversationPreview implements Persistable<ConversationPreviewDMO> {
  readonly conversationKey!: string;
  readonly topic!: string;
  readonly walletAddress!: string;
  readonly contentText?: string;
  readonly contentType?: string;
  readonly messageId?: string;
  readonly senderAddress?: string;
  readonly peerAddress!: string;
  readonly sent?: Date;
  readonly createdAt!: Date;
  readonly appDomain?: string;
  readonly unreadMessageCount?: number;
  readonly displayName?: string;
  readonly displayImage?: string;

  get asDMO(): ConversationPreviewDMO {
    const DMO: ConversationPreviewDMO = {
      id: this.id,
      conversationKey: this.conversationKey,
      topic: this.topic,
      walletAddress: this.walletAddress,
      contentText: this.contentText,
      senderAddress: this.senderAddress,
      peerAddress: this.peerAddress,
      contentType: this.contentType,
      messageId: this.messageId,
      sent: this.sent,
      createdAt: this.createdAt,
      appDomain: this.appDomain,
      displayName: this.displayName,
      displayImage: this.displayImage,
    };

    if (this.unreadMessageCount !== undefined) {
      DMO.unreadMessageCount = this.unreadMessageCount;
    }

    return DMO;
  }

  get asFormattedConversationPreview(): FormattedConversationPreview {
    // only add message if all fields are present
    if (!this.messageId || !this.senderAddress || !this.sent || !this.contentType) {
      return {
        key: this.conversationKey,
        appDomain: this.appDomain,
        metadata: this.conversationMetadata,
        unreadMessageCount: this.unreadMessageCount,
        walletAddress: this.walletAddress,
      };
    }

    return {
      key: this.conversationKey,
      appDomain: this.appDomain,
      message: {
        content: this.getContent(),
        contentType: this.contentType,
        sent: this.sent,
        senderAddress: this.senderAddress,
        id: this.messageId,
      },
      metadata: this.conversationMetadata,
      unreadMessageCount: this.unreadMessageCount,
      walletAddress: this.walletAddress,
    };
  }

  get conversationMetadata(): ConversationMetadata {
    return {
      conversationKey: this.conversationKey,
      createdAt: this.createdAt,
      topic: this.topic,
      peerAddress: this.peerAddress,
      displayName: this.displayName,
      displayImage: this.displayImage,
    };
  }

  getContent() {
    return this.contentType?.startsWith(PAYMENT_ACTIVITY_CONTENT_TYPE) && this.contentText
      ? JSON.parse(this.contentText)
      : this.contentText;
  }

  get id() {
    return [this.walletAddress.toLowerCase(), this.conversationKey].join('/');
  }

  static fromDMO(previewMessage: ConversationPreviewDMO) {
    return new ConversationPreview(previewMessage);
  }

  static fromFormattedConversationPreview(
    { key, message, appDomain, metadata, unreadMessageCount }: FormattedConversationPreview,
    walletAddress: string,
  ) {
    if (!metadata) {
      throw new Error('No metadata field found');
    }
    const contentType = message?.contentType;

    function getContextText() {
      if (contentType?.startsWith('xmtp.org/text')) {
        return message?.content as string;
      }
      if (message?.contentType?.startsWith('xmtp.org/remoteStaticAttachment')) {
        return 'Attachment';
      }
      return JSON.stringify(message?.content);
    }

    const contentText = getContextText();

    return new ConversationPreview({
      walletAddress,
      contentText,
      contentType,
      conversationKey: key,
      topic: metadata.topic,
      messageId: message?.id,
      senderAddress: message?.senderAddress,
      peerAddress: metadata.peerAddress,
      sent: message?.sent,
      createdAt: metadata.createdAt,
      appDomain,
      unreadMessageCount,
      displayName: metadata.displayName,
      displayImage: metadata.displayImage,
    });
  }

  constructor({
    conversationKey,
    topic,
    walletAddress,
    contentText,
    contentType,
    senderAddress,
    peerAddress,
    messageId,
    sent,
    createdAt,
    appDomain,
    unreadMessageCount,
    displayName,
    displayImage,
  }: Omit<
    ConversationPreview,
    'asDMO' | 'asFormattedConversationPreview' | 'id' | 'getContent' | 'conversationMetadata'
  >) {
    this.conversationKey = conversationKey;
    this.topic = topic;
    this.walletAddress = walletAddress;
    this.contentText = contentText;
    this.contentType = contentType;
    this.senderAddress = senderAddress;
    this.peerAddress = peerAddress;
    this.sent = sent;
    this.createdAt = createdAt;
    this.appDomain = appDomain;
    this.messageId = messageId;
    this.unreadMessageCount = unreadMessageCount;
    this.displayName = displayName;
    this.displayImage = displayImage;
  }
}

import Url from 'url-parse';

export type ParsedUrl = ReturnType<typeof parseUrl>;

export const BUILD_VALID_URL_ERROR_PREFIX = 'invalid_url';
export function buildValidURL(url: string): URL | string {
  try {
    return new URL(prefixedURL(url));
  } catch (e) {
    return `${BUILD_VALID_URL_ERROR_PREFIX}:${String(e)}`;
  }
}

/**
 * Returns a URL object if the string passed in is a valid URL, otherwise it returns undefined
 */
export function tryCreateUrl(url: string | undefined): URL | undefined {
  if (!url) return undefined;

  try {
    return new URL(url);
  } catch (error) {
    return undefined;
  }
}

/**
 * Returns the url if the string passed in is a valid url, otherwise it returns undefined
 */
export function sanitizeUrlString(url: string | undefined): string | undefined {
  return tryCreateUrl(url)?.toString();
}

export function safeParseUrl(url: string): ParsedUrl {
  try {
    return parseUrl(url);
  } catch (e) {
    // Return a ParsedUrl type with empty values
    return {
      protocol: '',
      hostname: '',
      params: {},
      pathname: '',
      host: '',
      origin: '',
    };
  }
}

export function isURL(query: string): boolean {
  const prefixed = prefixedURL(query);

  // Regex taken from this StackOverflow answer: https://stackoverflow.com/a/49185442
  // A similar method is used in segmentio's is-url library
  // This isn't foolproof, but works reliably enough for DApp Browser suggestions
  return /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/.test(prefixed);
}

export function googleSearchURL(query: string): URL | string {
  const encodedQuery = encodeURIComponent(query);

  try {
    return new URL(`https://www.google.com/search?q=${encodedQuery}`);
  } catch (e) {
    return encodedQuery;
  }
}

function prefixedURL(url: string): string {
  let prefixed: string;
  if (!(url.toLowerCase().startsWith('https://') || url.toLowerCase().startsWith('http://'))) {
    prefixed = `https://${url}`;
  } else {
    prefixed = url;
  }

  return prefixed;
}

function extractProtocol(parsedProtocol: string) {
  return parsedProtocol.endsWith(':')
    ? parsedProtocol.substr(0, parsedProtocol.length - 1)
    : parsedProtocol;
}

export function parseUrl(url: string) {
  const parsedUrl = new Url(url, true);
  return {
    protocol: extractProtocol(parsedUrl.protocol),
    hostname: parsedUrl.hostname,
    params: parsedUrl.query,
    pathname: parsedUrl.pathname,
    host: parsedUrl.host, // includes host+port number for eg localhost:3000
    origin: `${parsedUrl.protocol}${parsedUrl.slashes ? '//' : ''}${parsedUrl.host}`,
  };
}

export type ParamType = Record<string, string | number | boolean | undefined>;

export function buildUrl(url: string, params: ParamType = {}) {
  if (Object.keys(params).length === 0) {
    return url;
  }

  const searchParams = new URLSearchParams(params as Record<string, string>);
  searchParams.sort();
  return `${url}?${searchParams.toString()}`;
}

export function appendQuery(url: string, params: ParamType = {}) {
  const { params: existingParams, pathname, origin } = parseUrl(url);

  return buildUrl(`${origin}${pathname}`, {
    ...existingParams,
    ...params,
  });
}

export function isExternalProtocol(url: string) {
  if (url === 'about:blank') return false;
  const parsedUrl = new Url(url, true);
  const protocol = extractProtocol(parsedUrl.protocol).toLowerCase();

  if (!protocol?.length) return false;

  return !['http', 'https', 'ws', 'wss', 'cbwallet', 'walletswap'].includes(protocol);
}

export function sanitizeUrl(rawUrl?: string) {
  return rawUrl?.replace(/\u200B/g, '').trim() ?? '';
}

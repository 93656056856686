/* istanbul ignore file */
// This is a temp check if the wallet is a smart wallet and update the provider id
// This will be removed in a future PR in favor of using separate connectors
import isEmpty from 'lodash/isEmpty';
import { EIP1193Provider } from 'viem';

import { WalletProviderInfo, WalletProviderNetworkInfo } from '../types';
import { COINBASE_SMART_WALLET_ID, COINBASE_WALLET_ID } from '../wallet-providers/coinbase-wallet';

export async function isCoinbaseSmartWallet(
  walletProvider: WalletProviderInfo,
  network: WalletProviderNetworkInfo,
  providerId?: string,
) {
  if (!providerId) {
    return false;
  }
  if ([COINBASE_SMART_WALLET_ID, COINBASE_WALLET_ID].includes(providerId)) {
    try {
      const provider = (await walletProvider.connectorManager.getProvider(
        network.id,
      )) as EIP1193Provider;
      // TODO: update with getCapabilities method
      // @ts-expect-error - getCapabilities is not part of the EIP1193Provider interface
      const res = await provider.request({ method: 'wallet_getCapabilities' });
      // Should we do a more thorough check here? Tag Lukas on PR
      return !isEmpty(res);
    } catch {
      // If the wallet does not support getCapabilities, then
      // we don't need to do anything
      // TODO: add logging here
    }
  }
}

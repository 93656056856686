import { logIsFeatureEnabledGet } from 'cb-wallet-analytics/feature-management';

type LogIsFeatureEnabledParams = { featureName: string; isEnabled: boolean };

/**
 * We want to log the first time that a feature is checked for isEnabled,
 * or any time the feature's enabled state changes
 */
export class FeatureLogger {
  // featureName: isEnabled
  private features: Record<string, boolean> = {};

  logIsFeatureEnabled({ featureName, isEnabled }: LogIsFeatureEnabledParams) {
    if (!(featureName in this.features) || this.features[featureName] !== isEnabled) {
      this.features[featureName] = isEnabled;
      logIsFeatureEnabledGet({ featureName, isEnabled });
    }
  }
}

export const featureLogger = new FeatureLogger();

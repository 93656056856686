import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { SetAuthTokens } from 'cb-wallet-data/stores/Authentication/tokens/useSetAuthTokens';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { getFetchResponse, getJSON, postJSON } from 'cb-wallet-http/fetchJSON';
import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { Store } from 'cb-wallet-store/Store';

import { AssetManagementStatus } from './models/AssetManagementStatus';
import { CuratedAssetSetting } from './models/CuratedAssetSetting';
import { UserCollectibleSetting } from './models/UserCollectibleSetting';
import { UserWalletSetting } from './models/UserWalletSetting';

export type UserAssetSettingsFetchResult = {
  result: {
    collectibles: RemoteUserCollectibleSetting[];
    wallets: RemoteUserWalletSetting[];
    shouldShowLowBalance: boolean;
  };
};

type UpdateAssetSettingsParams = {
  address?: string;
  collectibles?: UserCollectibleSetting[];
  wallets?: UserWalletSetting[];
  shouldShowLowBalance?: boolean;
};

type RemoteUserWalletSetting = {
  blockchain: string;
  network: string;
  currencyCode: string;
  contractAddress: string;
  primaryAddress: string;
  status: AssetManagementStatus;
};

type RemoteUserCollectibleSetting = {
  blockchain: string;
  network: string;
  tokenId: string;
  contractAddress: string;
  primaryAddress: string;
  status: AssetManagementStatus;
};

export type RemoteCuratedAssetSetting = {
  blockchain: string;
  contractAddress: string;
  currencyCode: string;
  network: string;
  status: number;
  type: number;
};

type CuratedAssetSettingsResult = {
  result: {
    curatedAssetSettings: RemoteCuratedAssetSetting[];
    etag: string;
  };
};

const emptyCuratedAssetSettingsResponse = {
  result: {
    curatedAssetSettings: [],
    etag: '',
  },
};

type ReportSpamCollectibleProps = {
  address: string;
  chainId: string;
  contractAddress: string;
  tokenId: string;
  feedback: string;
};

export const StoreKeys_eTagCuratedAssets = new LocalStorageStoreKey<string>('eTagCuratedAssets');

const USER_SETTINGS_ENDPOINT = 'userAssetSettings';
const CURATED_ASSET_SETTINGS_ENDPOINT = 'curatedAssets';
export const REPORT_COLLECTIBLE_SPAM_ENDPOINT = 'collectibles/reportSpam';
export const REPORT_COLLECTIBLE_NOT_SPAM_ENDPOINT = 'collectibles/reportNotSpam';
const INITIAL_PAGE_NUMBER = 1;
const MAX_PAGE_NUMBER = 10;
export const RESULTS_PER_PAGE = 1000;

function parseResponseToUserWalletSetting(
  setting: RemoteUserWalletSetting,
  currentPrimaryAddress?: string,
) {
  return new UserWalletSetting(
    new Blockchain(setting.blockchain),
    new CurrencyCode(setting.currencyCode),
    Network.create(setting.network) ??
      new Network(setting.network.split('/')[0], setting.network.includes('true')),
    setting.status,
    setting.contractAddress,
    setting.primaryAddress ?? currentPrimaryAddress,
  );
}

function parseResponseToUserCollectibleSetting(
  setting: RemoteUserCollectibleSetting,
  currentPrimaryAddress?: string,
) {
  return new UserCollectibleSetting(
    new Blockchain(setting.blockchain),
    Network.create(setting.network) ??
      new Network(setting.network.split('/')[0], setting.network.includes('true')),
    setting.status,
    setting.contractAddress,
    setting.primaryAddress ?? currentPrimaryAddress,
    setting.tokenId,
  );
}

function parseUserWalletSettingToRemoteSetting(
  setting: UserWalletSetting,
  currentPrimaryAddress?: string,
) {
  return {
    blockchain: setting.blockchain.rawValue,
    currencyCode: setting.currencyCode.rawValue,
    network: setting.network.rawValue,
    contractAddress: setting.contractAddress,
    primaryAddress: setting.primaryAddress ?? currentPrimaryAddress,
    status: setting.status,
  };
}

function parseUserCollectibleSettingToRemoteSetting(
  setting: UserCollectibleSetting,
  currentPrimaryAddress?: string,
) {
  return {
    blockchain: setting.blockchain.rawValue,
    tokenId: setting.tokenId,
    network: setting.network.rawValue,
    contractAddress: setting.contractAddress,
    primaryAddress: setting.primaryAddress ?? currentPrimaryAddress,
    status: setting.status,
  };
}

function parseResponseToCuratedAssetSetting(setting: RemoteCuratedAssetSetting) {
  return new CuratedAssetSetting(
    new Blockchain(setting.blockchain),
    new CurrencyCode(setting.currencyCode),
    Network.create(setting.network)!,
    setting.status,
    setting.type,
    setting.contractAddress,
  );
}

async function fetchCuratedAssetSettings(page: number) {
  const { status, body } = await getFetchResponse<CuratedAssetSettingsResult>(
    CURATED_ASSET_SETTINGS_ENDPOINT,
    {
      page: page.toString(),
      perPage: RESULTS_PER_PAGE.toString(),
    },
    {
      additionalHeaders: {
        'If-None-Match': Store.get<string>(StoreKeys_eTagCuratedAssets),
        'Cache-Control': 'no-cache',
      },
    },
  );

  return status === 304 ? emptyCuratedAssetSettingsResponse.result : body.result;
}

/**
 * Get user asset settings for both wallets and collectible from API
 * @param address Wallet address
 * @param setAuthTokens Function to refresh auth tokens
 * @return A function to fetch wallets and collectible settings from API given an address.
 */
export async function getRemoteUserAssetSettings(address: string, setAuthTokens: SetAuthTokens) {
  const { result } = await getJSON<UserAssetSettingsFetchResult>(
    `${USER_SETTINGS_ENDPOINT}?address=${address}`,
    {},
    { authenticated: true, setAuthTokens },
  );

  return {
    wallets: result.wallets?.map((w) => parseResponseToUserWalletSetting(w, address)) ?? [],
    collectibles:
      result.collectibles?.map((c) => parseResponseToUserCollectibleSetting(c, address)) ?? [],
    shouldShowLowBalance: result.shouldShowLowBalance,
  };
}

export async function updateRemoteUserAssetSettings(
  address: string,
  { collectibles = [], wallets = [], shouldShowLowBalance }: UpdateAssetSettingsParams,
  setAuthTokens: SetAuthTokens,
) {
  return postJSON(
    USER_SETTINGS_ENDPOINT,
    {
      address,
      collectibles: collectibles.map((c) => parseUserCollectibleSettingToRemoteSetting(c, address)),
      wallets: wallets.map((w) => parseUserWalletSettingToRemoteSetting(w, address)),
      shouldShowLowBalance,
    },
    {
      authenticated: true,
      setAuthTokens,
    },
  );
}

export async function reportSpamCollectible({
  address,
  chainId,
  contractAddress,
  tokenId,
  feedback,
}: ReportSpamCollectibleProps) {
  return postJSON(REPORT_COLLECTIBLE_SPAM_ENDPOINT, {
    userAddress: address,
    chainId,
    contractAddress,
    tokenId,
    feedback,
  });
}

export async function reportNotSpamCollectible({
  address,
  chainId,
  contractAddress,
  tokenId,
  feedback,
}: ReportSpamCollectibleProps) {
  return postJSON(REPORT_COLLECTIBLE_NOT_SPAM_ENDPOINT, {
    userAddress: address,
    chainId,
    contractAddress,
    tokenId,
    feedback,
  });
}

export async function getRemoteCuratedAssetSettings() {
  const parsedCuratedAssetSettings: CuratedAssetSetting[] = [];
  let etagToSave = '';

  for (let pageNumber = INITIAL_PAGE_NUMBER; ; pageNumber++) {
    // eslint-disable-next-line no-await-in-loop
    const { curatedAssetSettings, etag } = await fetchCuratedAssetSettings(pageNumber);
    const parsed = curatedAssetSettings.map(parseResponseToCuratedAssetSetting);
    parsedCuratedAssetSettings.push(...parsed);
    etagToSave = etagToSave || etag;
    if (curatedAssetSettings.length < RESULTS_PER_PAGE || pageNumber === MAX_PAGE_NUMBER) break;
  }

  if (etagToSave) {
    Store.set<string>(StoreKeys_eTagCuratedAssets, etagToSave);
  }

  return parsedCuratedAssetSettings;
}

import { useCallback } from 'react';
import { useCollections } from 'cb-wallet-data/stores/Collection/hooks/useCollections';
// eslint-disable-next-line no-restricted-imports
import { useActiveWalletGroupId } from 'cb-wallet-data/stores/WalletGroups/hooks/useActiveWalletGroupId';
import { usePortfolioWallets } from 'cb-wallet-data/stores/Wallets/hooks/usePortfolioWallets';
import { usePrimaryReceiveAddresses } from 'cb-wallet-data/stores/Wallets/hooks/usePrimaryReceiveAddresses';
import { useRecoilValue } from 'recoil';

import { addUserCollectibleSettings, addUserWalletSettings } from '../database';
import {
  curatedAssetsSettingsAtom,
  userCollectibleSettingsAtom,
  userWalletSettingsAtom,
} from '../state';
import { getCollectiblesToBeMarkedSpamOrWhitelist } from '../utils/getCollectiblesToBeMarkedSpamOrWhitelist';
import { getWalletsToBeMarkedSpamOrWhitelist } from '../utils/getWalletsToBeMarkedSpamOrWhitelist';

import { useHydrateUserCollectibleSettingsFromDatabase } from './useHydrateUserCollectibleSettings';
import { useHydrateUserWalletSettingsFromDatabase } from './useHydrateUserWalletSettings';

/**
 * @returns A function that can be invoked to mark wallets and
 * collectibles as spam or whitelist using curated assets.
 */

export function useMarkAssetAsSpamOrWhitelist(): () => Promise<void> {
  const wallets = usePortfolioWallets();

  const { collections } = useCollections({ suspense: false });

  const curatedAssetsSettingsMap = useRecoilValue(curatedAssetsSettingsAtom);
  const userWalletSettingsMap = useRecoilValue(userWalletSettingsAtom);
  const userCollectibleSettingsMap = useRecoilValue(userCollectibleSettingsAtom);
  const activeWalletGroupId = useActiveWalletGroupId();
  const primaryAddress = usePrimaryReceiveAddresses(activeWalletGroupId).get('ETH')?.address;
  const hydrateUserWalletSettings = useHydrateUserWalletSettingsFromDatabase();
  const hydrateUserCollectibleSettings = useHydrateUserCollectibleSettingsFromDatabase();

  return useCallback(
    async function markAssetAsSpamOrWhitelist() {
      if (wallets) {
        const spamOrWhitelistWallets = getWalletsToBeMarkedSpamOrWhitelist(
          wallets,
          curatedAssetsSettingsMap,
          userWalletSettingsMap,
        );
        await addUserWalletSettings(spamOrWhitelistWallets);
        hydrateUserWalletSettings();
      }

      if (collections?.length && primaryAddress) {
        const spamOrWhitelistCollectibles = getCollectiblesToBeMarkedSpamOrWhitelist(
          collections,
          primaryAddress,
          curatedAssetsSettingsMap,
          userCollectibleSettingsMap,
        );
        await addUserCollectibleSettings(spamOrWhitelistCollectibles);
        hydrateUserCollectibleSettings();
      }
    },
    [
      collections,
      curatedAssetsSettingsMap,
      hydrateUserCollectibleSettings,
      hydrateUserWalletSettings,
      primaryAddress,
      userCollectibleSettingsMap,
      userWalletSettingsMap,
      wallets,
    ],
  );
}

import { useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useActiveWalletGroupId } from 'cb-wallet-data/stores/WalletGroups/hooks/useActiveWalletGroupId';
import { useRecoilState } from 'recoil';

import { areLowBalanceWalletsHiddenByWalletGroupIdSelectorFamily } from '../state';
/**
 *
 * This hook should be used for sync purposes only, it updates the hidden wallets atom without
 * calling to persist it on our backend
 *
 * THIS HOOK SHOULD NEVER BE USED FOR OTHER PURPOSES THAN THAT. In case it is needed to update
 * low balance wallets visibility, please use `useAreLowBalanceWalletsHidden`, which will persist
 * it on our server and sync it with all platforms.
 */
export function useUnsafeSetAreLowBalanceWalletsHiddenAtom() {
  const walletGroupId = useActiveWalletGroupId();
  const [, setAreLowBalanceWalletsHiddenForWalletGroupId] = useRecoilState(
    areLowBalanceWalletsHiddenByWalletGroupIdSelectorFamily(walletGroupId || ''),
  );
  return setAreLowBalanceWalletsHiddenForWalletGroupId;
}

export function useAreLowBalanceWalletsHidden() {
  const walletGroupId = useActiveWalletGroupId();
  const [areLowBalanceWalletsHidden, setAreLowBalanceWalletsHiddenForWalletGroupId] =
    useRecoilState(areLowBalanceWalletsHiddenByWalletGroupIdSelectorFamily(walletGroupId || ''));

  return useMemo(
    () => [areLowBalanceWalletsHidden, setAreLowBalanceWalletsHiddenForWalletGroupId] as const,
    [areLowBalanceWalletsHidden, setAreLowBalanceWalletsHiddenForWalletGroupId],
  );
}

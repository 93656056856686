import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, HStack } from '@cbhq/cds-web/layout';
import { TextBody } from '@cbhq/cds-web/typography';

type PercentChangeTextProps = {
  percentChange: number;
};

const transformStyles = { transform: 'scaleX(-1)' } as const;

export function PercentChangeText({ percentChange }: PercentChangeTextProps) {
  const { formatNumber } = useIntl();

  const color = useMemo(() => {
    if (percentChange > 0) return 'positive';
    if (percentChange < 0) return 'negative';
  }, [percentChange]);

  const iconName = useMemo(() => {
    if (percentChange > 0) return 'diagonalUpArrow';
    if (percentChange < 0) return 'diagonalDownArrow';
    return null;
  }, [percentChange]);

  const percentFormatted = formatNumber(Math.abs(percentChange), {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const isPercentNegative = percentChange < 0;

  return (
    <HStack justifyContent="flex-end" alignItems="center" testID="global-search-percent-change">
      {iconName && (
        <Box
          style={isPercentNegative ? transformStyles : undefined}
          testID="global-search-percent-change-icon-container"
        >
          <Icon
            testID={`global-search-percent-change-icon-${color}`}
            name={iconName}
            size="xs"
            color={color}
            spacingStart={isPercentNegative ? 0.5 : 0}
            spacingEnd={isPercentNegative ? 0 : 0.5}
          />
        </Box>
      )}
      <TextBody as="p" color={color}>
        {percentFormatted}
      </TextBody>
    </HStack>
  );
}

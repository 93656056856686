import { EthereumBlockchain } from 'cb-wallet-data/chains/AccountBased/Ethereum/config';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { Collection } from 'cb-wallet-data/stores/Collection/types';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';

import { generateUserCollectibleSettingId } from '../hooks/useGenerateUserCollectibleSettingId';
import { CuratedAssetSetting } from '../models/CuratedAssetSetting';
import { UserCollectibleSetting } from '../models/UserCollectibleSetting';

import { isAssetStatusChangeRequired } from './isAssetStatusChangeRequired';

export function getCollectiblesToBeMarkedSpamOrWhitelist(
  collections: Collection[],
  primaryAddress: string,
  curatedAssetsSettingsMap: Record<string, CuratedAssetSetting>,
  userCollectibleSettingsMap: Record<string, UserCollectibleSetting>,
): UserCollectibleSetting[] {
  return collections.reduce(
    // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
    // eslint-disable-next-line wallet/no-anonymous-params
    (userCollectibleSettings, { collectionInfo: { contractAddress, chainId }, tokens }) => {
      const curatedAssetSetting =
        curatedAssetsSettingsMap[
          CuratedAssetSetting.generateID(new CurrencyCode(''), contractAddress, EthereumBlockchain)
        ];

      if (curatedAssetSetting) {
        tokens.tokenList.forEach(
          // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
          // eslint-disable-next-line wallet/no-anonymous-params
          (token) => {
            const userCollectibleSetting =
              userCollectibleSettingsMap[
                generateUserCollectibleSettingId({
                  contractAddress: token.contractAddress,
                  tokenId: token.tokenId,
                  ownerAddress: primaryAddress,
                  chainId: BigInt(chainId),
                })
              ];
            if (isAssetStatusChangeRequired(userCollectibleSetting, curatedAssetSetting)) {
              userCollectibleSettings.push(
                new UserCollectibleSetting(
                  EthereumBlockchain,
                  Network.fromChainId({ chainId: BigInt(chainId) }),
                  curatedAssetSetting.status,
                  contractAddress,
                  primaryAddress,
                  token.tokenId,
                ),
              );
            }
          },
        );
      }

      return userCollectibleSettings;
    },
    [] as UserCollectibleSetting[],
  );
}

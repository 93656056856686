import { useOverridableKillSwitch } from 'cb-wallet-data/hooks/KillSwitches/useOverridableKillSwitch';
import { getKillSwitchByPlatform } from 'cb-wallet-data/hooks/KillSwitches/utils/getKillSwitchByPlatform';

export function useIsTokenApprovalsEnabled(): boolean {
  const isRevokeApprovalKilled = useOverridableKillSwitch(
    getKillSwitchByPlatform('kill_revoke_approvals'),
  );

  return !isRevokeApprovalKilled;
}

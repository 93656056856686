export function getLocalStorageSize() {
  let totalSize = 0;
  // Check if window object is defined, indicating the code is running in the browser
  if (typeof window === 'undefined') {
    return -1;
  }

  for (const key in localStorage) {
    if (Object.prototype.hasOwnProperty.call(localStorage, key)) {
      const value = localStorage[key];
      const itemSize = (value.length + key.length) * 2; // Each character is 2 bytes in UTF-16 encoding
      totalSize += itemSize;
    }
  }
  const totalSizeMB = totalSize / (1024 * 1024); // Convert to MB
  return +totalSizeMB.toFixed(2); // Return totalSizeMB as a number with only two decimal places
}

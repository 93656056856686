import { ReactNode } from 'react';
import { VStack } from '@cbhq/cds-web/layout';

export const DEFAULT_VIEWPORT_FULL_HEIGHT = 600;

type StepperStepProps = {
  component: ReactNode;
};

export function StepperStep({ component }: StepperStepProps) {
  return <VStack height={DEFAULT_VIEWPORT_FULL_HEIGHT}>{component}</VStack>;
}

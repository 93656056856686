export class HTTPError extends Error {
  constructor(
    readonly status: number,
    readonly code: string,
    readonly message: string,
    readonly description?: string,
    readonly errorDetail?: Record<string, string | number | boolean>,
    readonly endpoint?: string,
  ) {
    super(message);
  }
}

import { useCallback } from 'react';
import { CombinedThemeColorPreference } from 'cb-wallet-data/stores/ThemeColors/themeColorConfigs';
import { themeColorAtom } from 'cb-wallet-data/stores/User/state';
import { useRecoilState } from 'recoil';

export function useThemeColorPreference(): [
  CombinedThemeColorPreference,
  (name: CombinedThemeColorPreference) => void,
] {
  const [themeColorPreference, setThemeColorPreference] = useRecoilState(themeColorAtom);

  const setPreference = useCallback(
    (name: CombinedThemeColorPreference) => {
      setThemeColorPreference(name);
    },
    [setThemeColorPreference],
  );

  return [themeColorPreference, setPreference];
}

import { useCallback } from 'react';
import { useOverridableKillSwitch as useKillSwitch } from 'cb-wallet-data/hooks/KillSwitches/useOverridableKillSwitch';
import { getKillSwitchByPlatform } from 'cb-wallet-data/hooks/KillSwitches/utils/getKillSwitchByPlatform';
import { EVMChain } from 'cb-wallet-data/stores/Networks/models/EVMChains';
import { NonEVMChain } from 'cb-wallet-data/stores/Networks/models/NonEVMChains';

export function useSwapByChainKillSwitch() {
  const isEthereumKillSwitched = useKillSwitch(getKillSwitchByPlatform('kill_swap_ethereum'));
  const isPolygonKillSwitched = useKillSwitch(getKillSwitchByPlatform('kill_swap_polygon'));
  const isAvaxKillSwitched = useKillSwitch(getKillSwitchByPlatform('kill_swap_avax'));
  const isSolanaKillSwitched = useKillSwitch(getKillSwitchByPlatform('kill_swap_solana'));
  const isBSCKillSwitched = useKillSwitch(getKillSwitchByPlatform('kill_swap_bsc'));
  const isOptimismKillSwitched = useKillSwitch(getKillSwitchByPlatform('kill_swap_optimism'));
  const isBaseKillSwitched = useKillSwitch(getKillSwitchByPlatform('kill_swap_base'));

  return useCallback(
    function swapByChainKillSwitch(chainId?: bigint) {
      if (chainId === undefined) return false;

      switch (Number(chainId)) {
        case EVMChain.ETHEREUM_MAINNET:
          return isEthereumKillSwitched;
        case EVMChain.POLYGON:
          return isPolygonKillSwitched;
        case EVMChain.BSC:
          return isBSCKillSwitched;
        case EVMChain.AVAX:
          return isAvaxKillSwitched;
        case EVMChain.OPTIMISM:
          return isOptimismKillSwitched;
        case EVMChain.BASE:
          return isBaseKillSwitched;
        case NonEVMChain.SOL:
          return isSolanaKillSwitched;
        default:
          return false;
      }
    },
    [
      isAvaxKillSwitched,
      isBSCKillSwitched,
      isBaseKillSwitched,
      isEthereumKillSwitched,
      isOptimismKillSwitched,
      isPolygonKillSwitched,
      isSolanaKillSwitched,
    ],
  );
}

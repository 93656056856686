import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import qs from 'qs';

import { useIsMobile } from ':dapp/hooks/useIsMobile';

import { OpenDrawerOptions, useGlobalDrawer } from '../GlobalDrawerProvider';

/**
 * @params drawerContent{React.ReactNode} - the content to display in the global drawer
 * @params mobilePathname {string} - should be the full path including any query params needed for the view
 *
 * @returns a function that opens the global drawer or navigates to a page.
 *
 * * when using the app on desktop opens GlobalDrawer with the passed content
 * * when using the app on mobile navigates to the passed path
 *
 * This is useful for views like global swap or the swap page or crypto preview to the asset detail page, etc..
 */
export function useGlobalDrawerOrNavigate() {
  const { openDrawer } = useGlobalDrawer();
  const isMobile = useIsMobile();
  const router = useRouter();

  const openDrawerOrNavigate = useCallback(
    async (drawerContent: React.ReactNode, mobilePathname: string, options?: OpenDrawerOptions) => {
      if (isMobile) {
        let params = '';
        if (options?.contentParams) {
          params = qs.stringify({ contentParams: options?.contentParams });
        }
        const querystring = params.length > 0 ? `?${params}` : '';
        await router.push(`${mobilePathname}${querystring}`);
      } else {
        openDrawer(drawerContent, options);
      }
    },
    [isMobile, openDrawer, router],
  );

  return useMemo(
    () => ({
      openDrawerOrNavigate,
    }),
    [openDrawerOrNavigate],
  );
}

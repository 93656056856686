import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { Store } from 'cb-wallet-store/Store';

const StoreKeys_fetchedDomains = new LocalStorageStoreKey<string>('fetchedDomains');
type SavedDomainValue = {
  domain: string | undefined;
  updatedAt: number;
};
type SavedDomain = {
  domain: string | undefined;
  isExpired: boolean;
};

export class DomainStorage {
  private domainMap: Map<string, SavedDomainValue>;

  constructor() {
    const savedDomains = Store.get<string>(StoreKeys_fetchedDomains);
    this.domainMap = new Map<string, SavedDomainValue>(savedDomains && JSON.parse(savedDomains));
  }

  private isOneDayOld(date: number): boolean {
    const day = 1000 * 60 * 60 * 24; // in MS
    const dayAgo = Date.now() - day;

    return date < dayAgo;
  }

  get(address: string): SavedDomain | undefined {
    const value = this.domainMap.get(address);
    return (
      value && {
        domain: value.domain,
        isExpired: value.updatedAt ? this.isOneDayOld(value?.updatedAt) : true,
      }
    );
  }

  add(address: string, domain: string | undefined): void {
    this.domainMap.set(address, {
      domain,
      updatedAt: Date.now(),
    });
    Store.set<string>(
      StoreKeys_fetchedDomains,
      JSON.stringify(Array.from(this.domainMap.entries())),
    );
  }
}

import isMatch from 'lodash/isMatch';

export function createHistoryCache<T extends { context?: Record<string, unknown> }>(
  getCacheId: (historyItem: T) => string,
) {
  let historyCache: Record<ReturnType<typeof getCacheId>, T> = {};

  function clear() {
    historyCache = {};
  }

  function getCacheItem(addressItem: Partial<T>): T {
    const cacheId = getCacheId(addressItem as T);
    return historyCache[cacheId];
  }

  function setCacheItem(cacheItem: T): void {
    const cacheId = getCacheId(cacheItem);
    const existingItem = historyCache[cacheId] || {};
    historyCache[cacheId] = {
      ...existingItem,
      ...cacheItem,
    };
  }

  function deleteCacheItem(cacheItem: T): void {
    const cacheId = getCacheId(cacheItem);
    delete historyCache[cacheId];
  }

  function all(search?: Record<string, unknown>): T[] {
    const values = Object.values(historyCache);
    if (!search) {
      return values;
    }

    return values.filter((value) => isMatch(value, search));
  }

  return {
    all,
    clear,
    getCacheItem,
    setCacheItem,
    deleteCacheItem,
  };
}

import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  allBlockchainsAreRefreshedSelector,
  lastBalanceUpdateMetadataAtom,
  RefreshSource,
} from '../state';

export function useLastBalanceUpdateMetadata() {
  return useRecoilValue(lastBalanceUpdateMetadataAtom);
}

export function useSetLastBalanceUpdateMetadata() {
  const setLastBalanceUpdateMetadata = useSetRecoilState(lastBalanceUpdateMetadataAtom);
  const allBlockchainsAreRefreshed = useRecoilValue(allBlockchainsAreRefreshedSelector);

  return useCallback(
    function setLastBalanceUpdateMetadataCallback({
      source,
      updatedAt,
    }: {
      source: RefreshSource;
      updatedAt: number;
    }) {
      setLastBalanceUpdateMetadata(
        // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
        // eslint-disable-next-line wallet/no-anonymous-params
        (prev) => {
          const balanceUpdateHasHappened = prev.updatedAt !== 0;
          if (!balanceUpdateHasHappened) {
            return { source, updatedAt };
          }
          if (balanceUpdateHasHappened && allBlockchainsAreRefreshed) {
            return { source, updatedAt };
          }
          return prev;
        },
      );
    },
    [allBlockchainsAreRefreshed, setLastBalanceUpdateMetadata],
  );
}

import { BaseWalletAdapter as SolanaWalletConnector } from '@solana/wallet-adapter-base';

import { isSolanaConnector } from './utils/isSolanaConnector';
import * as ethereum from './ethereum';
import * as solana from './solana';
import {
  ConnectWalletOptions,
  PossibleNetworkWalletConnector,
  WalletConnectorConnectResponse,
  WalletProviderNetwork,
  WalletProviderNetworkInfo,
} from './types';

export class WalletConnectorManager {
  readonly networks: WalletProviderNetworkInfo[];
  readonly connectors: Map<string, PossibleNetworkWalletConnector>;
  readonly id: string;

  constructor(id: string, networks: WalletProviderNetworkInfo[]) {
    this.networks = networks;
    this.connectors = new Map();
    this.id = id;
    this.networks.forEach((network) => {
      if (network.connector) {
        this.connectors.set(network.id, network.connector);
      }
    });
  }

  async connect(
    network: WalletProviderNetwork,
    options?: ConnectWalletOptions,
  ): Promise<WalletConnectorConnectResponse> {
    const connector = this.getConnector(network);
    if (!connector) {
      throw new Error(`no connector for network ${network}`);
    }
    if (!(await this.isReady(network))) {
      throw new Error('connector not installed or ready');
    }
    switch (network) {
      case WalletProviderNetwork.Ethereum: {
        return ethereum.connect(this.id, connector, options);
      }
      case WalletProviderNetwork.Solana: {
        return solana.connect(this.id, connector);
      }
      default:
        throw new Error(`unsupported network ${network}`);
    }
  }

  async disconnect(network: WalletProviderNetwork) {
    const connector = this.getConnector(network);
    if (!connector) {
      throw new Error(`no connector for network ${network}`);
    }
    switch (network) {
      case WalletProviderNetwork.Ethereum: {
        if (ethereum.isValidConnector(connector)) {
          await ethereum.disconnect(connector);
          return true;
        }
        return false;
      }
      case WalletProviderNetwork.Solana: {
        if (isSolanaConnector(connector)) {
          await connector.disconnect();
          return true;
        }
        return false;
      }
      default:
        return false;
    }
  }

  getConnector(network: WalletProviderNetwork) {
    const connector = this.connectors.get(network);
    if (!connector) {
      return null;
    }
    return connector;
  }

  async getProvider(network: WalletProviderNetwork) {
    const connector = this.getConnector(network);
    if (!connector) {
      throw new Error(`no connector for network ${network}`);
    }
    switch (network) {
      case WalletProviderNetwork.Ethereum: {
        if (!ethereum.isValidConnector(connector)) {
          break;
        }
        return connector.getProvider();
      }
      case WalletProviderNetwork.Solana:
        if (!(connector instanceof SolanaWalletConnector)) {
          break;
        }
        return connector;
      default:
        throw new Error(`unsupported network ${network}`);
    }
  }

  async isConnected(network: WalletProviderNetwork) {
    const connector = this.getConnector(network);
    if (!connector) return false;
    switch (network) {
      case WalletProviderNetwork.Ethereum: {
        if (!ethereum.isValidConnector(connector)) {
          return false;
        }
        return connector.isAuthorized();
      }
      case WalletProviderNetwork.Solana:
        if (!(connector instanceof SolanaWalletConnector)) {
          return false;
        }
        return connector.connected;
      default:
        return false;
    }
  }

  async isReady(network: WalletProviderNetwork) {
    const connector = this.getConnector(network);
    if (!connector) return false;
    switch (network) {
      case WalletProviderNetwork.Ethereum: {
        if (!ethereum.isValidConnector(connector)) {
          return false;
        }
        const provider = await connector.getProvider();
        return !!provider;
      }
      case WalletProviderNetwork.Solana:
        if (!(connector instanceof SolanaWalletConnector)) {
          return false;
        }
        return connector.readyState === 'Installed';
      default:
        return false;
    }
  }
}

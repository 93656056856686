import { isAddressValid } from '../../blockchains/UTXO/addressValidation';
import { postJSON, UTXOChains } from '../../blockchains/UTXO/indexer';
import { isHexString } from '../../utils';
import {
  BTCLikeServiceInterface,
  CoinSelection,
  SignedTransaction,
} from '../generated/BTCLikeService.generated';

export interface BTCLikeServiceConfig {
  blockchainSymbol: UTXOChains;
}

export abstract class BTCLikeService implements BTCLikeServiceInterface {
  blockchainSymbol: UTXOChains;

  constructor(config: BTCLikeServiceConfig) {
    this.blockchainSymbol = config.blockchainSymbol;
  }

  protected abstract sign(
    privatekeys: { [address: string]: Buffer },
    coinSelection: CoinSelection,
    testnet: boolean,
  ): SignedTransaction;

  public async isAddressValid(params: { address: string; testnet: boolean }): Promise<boolean> {
    return isAddressValid({
      blockchainSymbol: this.blockchainSymbol,
      address: params.address,
      testnet: params.testnet,
    });
  }

  public async signTransaction(params: {
    privateKeys: { [address: string]: Buffer };
    coinSelection: CoinSelection;
    testnet: boolean;
  }): Promise<SignedTransaction> {
    return this.sign(params.privateKeys, params.coinSelection, params.testnet);
  }

  public async submitSignedTransaction(params: {
    signedTxData: Buffer;
    testnet: boolean;
  }): Promise<void> {
    const res = await postJSON<string>({
      endpoint: '/v1/submitSignedTransaction',
      blockchainSymbol: this.blockchainSymbol,
      body: {
        data: params.signedTxData.toString('hex'),
        testnet: params.testnet,
      },
      options: { isTextResponse: true },
    });

    if (!isHexString(res.body) || res.body.length === 0) {
      throw new Error('unexpected response');
    }
  }
}

import { SOLANA_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Solana/constants';
import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { fetchDomains } from 'cb-wallet-data/stores/DecentralizedID/hooks/usePublicProfileByAddress';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { TxFlowOrType, TxOrUserOp } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { v4 as uuidv4 } from 'uuid';
import { V2Transaction } from 'wallet-engine-signing/history';

import { SpamScoreThresholds } from '../api';
import { Transfer } from '../models/Transfer';
import { TxOrUserOpMetadata } from '../models/TxOrUserOpMetadata';
import { TxState } from '../models/TxState';
import { unixTimestampToDate } from '../utils/unixTimestampToDate';

function findRelevantTransfer(transfers: Transfer[], userAddress: string): Transfer | undefined {
  let transferToReturn: Transfer | undefined;

  for (const transfer of transfers) {
    if (!transferToReturn) {
      transferToReturn = transfer;
    } else {
      // we are choosing the send tx with the highest amount
      transferToReturn =
        transferToReturn.amount > transfer.amount && transferToReturn.fromAddress === userAddress
          ? transferToReturn
          : transfer;
    }
  }

  return transferToReturn;
}

function isSend(type: TxFlowOrType): boolean {
  // we want sends, swaps, and mints to be labelled with isSend === true so
  // that we display them uniformly as having sent crypto
  return type === 'SEND' || type === 'SWAP' || type === 'MINT' || type === 'SPONSORED';
}

function getTransactionType(tx: V2Transaction, relevantTransfer: Transfer): TxFlowOrType {
  if (tx.types?.length && tx.types.includes('SPONSORED') && tx.types.includes('SEND')) {
    return 'SPONSORED';
  }

  if (tx.types?.length && tx.types.includes('GASLESS') && tx.types.includes('SWAP')) {
    return 'GASLESS';
  }

  // Respect SWAP if that's what was received from tx history
  if (tx.types?.length && tx.types.includes('SWAP')) {
    return 'SWAP';
  }

  // Respect SEND if that's what was received from tx history
  if (
    relevantTransfer.fromAddress === tx.user.address ||
    (tx.types?.length && tx.types.includes('SEND'))
  ) {
    return 'SEND';
  }

  return 'RECEIVE';
}

function isSpam(
  spamScoreThresholds?: SpamScoreThresholds,
  spamScore?: number,
): boolean | undefined {
  return (
    spamScore !== undefined && spamScoreThresholds && spamScore >= spamScoreThresholds?.likely_spam
  );
}

export async function parseTransaction({
  tx,
  blockchain,
  network,
  accountId,
  walletIndex,
  spamScoreThresholds,
}: {
  tx: V2Transaction;
  blockchain: Blockchain;
  network: Network;
  accountId: Account['id'];
  walletIndex: bigint;
  spamScoreThresholds?: SpamScoreThresholds;
}): Promise<TxOrUserOp | undefined> {
  const chain = network.asChain();

  if (!chain) {
    return undefined;
  }

  const transfers = tx.transfers.map(
    (transfer) =>
      new Transfer(
        transfer.from.address,
        transfer.from.domain || '',
        transfer.to.address,
        transfer.to.domain || '',
        BigInt(transfer.exchange.amount),
        transfer.exchange.currency.symbol,
        transfer.exchange.currency.address,
        transfer.exchange.currency.name,
        BigInt(transfer.exchange.currency.decimal),
        transfer.exchange.currency.type,
        transfer.exchange.currency?.spam_score,
      ),
  );

  const relevantTransfer = findRelevantTransfer(transfers, tx.user.address);

  if (!relevantTransfer) {
    return undefined;
  }

  const date = unixTimestampToDate(tx.timestamp.toString());
  const feeCurrencyDecimal = BigInt(tx.gasfee.currency.decimal);
  const fee = BigInt(tx.gasfee.amount);
  const feeCurrencyCode = new CurrencyCode(tx.gasfee.currency.symbol);
  const nonce = BigInt(tx.nonce);
  const transactionType = getTransactionType(tx, relevantTransfer);

  // TODO: [Shrey] Remove this once BE starts resolving the SOL domains and starts sending in TX response
  const [toDomain, fromDomain] =
    // BE currently does not return resolved SOL address to domain, so we need to do it on client.
    blockchain.rawValue === SOLANA_SYMBOL
      ? await fetchDomains([relevantTransfer.toAddress, relevantTransfer.fromAddress])
      : [relevantTransfer.toDomain, relevantTransfer.fromDomain];

  const walletId = Wallet.generateId({
    blockchain,
    currencyCode: relevantTransfer.currencyCode,
    network,
    contractAddress: relevantTransfer.contractAddress,
    selectedIndex: walletIndex,
    accountId,
  });

  return new TxOrUserOp({
    id: uuidv4(),
    createdAt: date,
    confirmedAt: date,
    blockchain,
    currencyCode: relevantTransfer.currencyCode,
    feeCurrencyCode,
    feeCurrencyDecimal,
    toAddress: relevantTransfer.toAddress,
    toDomain,
    fromAddress: relevantTransfer.fromAddress,
    fromDomain,
    amount: relevantTransfer.amount,
    fee,
    state: tx.status === 'SUCCESS' ? TxState.CONFIRMED : TxState.FAILED,
    metadata: new TxOrUserOpMetadata(),
    network,
    walletIndex,
    accountId,
    txOrUserOpHash: tx.hash,
    userOpHash: undefined,
    txHash: tx.hash,
    isSent: isSend(transactionType),
    contractAddress: relevantTransfer.contractAddress,
    tokenName: relevantTransfer.tokenName,
    tokenDecimal: relevantTransfer.decimal,
    walletId,
    nonce,
    type: transactionType,
    transfers,
    isSpam: isSpam(spamScoreThresholds, relevantTransfer?.spamScore),
  });
}

import bitcoin from 'bitcoinjs-lib';

import { derivePublicKey } from './derivePublicKey';
import { getNetwork } from './getNetwork';
import { UTXOChains } from './indexer';

type DeriveSegwitAddressFromXpubKeyParams = {
  blockchainSymbol: UTXOChains;
  xpubKey: string;
  index: number;
  change: boolean;
  testnet: boolean;
};

export async function deriveSegwitAddressFromXpubKey({
  blockchainSymbol,
  xpubKey,
  index,
  change,
  testnet,
}: DeriveSegwitAddressFromXpubKeyParams): Promise<string> {
  const network = getNetwork(blockchainSymbol, testnet);
  const publicKey = await derivePublicKey({ xpubKey, index, change });
  const p2wpkh = bitcoin.payments.p2wpkh({ pubkey: publicKey, network });
  return p2wpkh.address;
}

import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { rateFor } from 'cb-wallet-data/stores/ExchangeRates/utils';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { bigIntToDecimal } from 'cb-wallet-data/utils/BigInt+Core';
import Decimal from 'decimal.js';

/**
 * Convert fiat value to crypto value
 */
export type CryptoValueParams = {
  exchangeRatesMap: Record<string, Decimal>;
  currencyCode: CurrencyCode;
  value: bigint | Decimal;
  contractAddress?: string;
  network?: Network;
  blockchain?: Blockchain;
};

export function cryptoValue({
  exchangeRatesMap,
  currencyCode,
  value,
  contractAddress,
  network,
  blockchain,
}: CryptoValueParams): Decimal | undefined {
  const exchangeRate = rateFor({
    exchangeRatesMap,
    currencyCode,
    contractAddress,
    network,
    blockchain,
  });

  if (!exchangeRate) return undefined;

  const decimalValue = typeof value === 'bigint' ? bigIntToDecimal(value) : value;
  return decimalValue.dividedBy(exchangeRate);
}

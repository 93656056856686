import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';

export class Transfer {
  // eslint-disable-next-line max-params
  constructor(
    readonly fromAddress: string,
    readonly fromDomain: string,
    readonly toAddress: string,
    readonly toDomain: string,
    readonly amount: bigint,
    readonly currencySymbol: string,
    readonly contractAddress: string,
    readonly name: string,
    readonly decimal: bigint,
    readonly currencyType: string,
    readonly spamScore?: number,
    readonly tokenId?: string,
  ) {}

  get currencyCode(): CurrencyCode {
    const falbackSymbol = this.currencyType === 'NFT' ? 'NFT' : '';

    const symbol = this.currencySymbol !== '' ? this.currencySymbol : falbackSymbol;

    return new CurrencyCode(symbol);
  }

  get tokenName(): string {
    const fallbackName = this.currencyType === 'NFT' ? 'NFT' : '';

    return this.name !== '' ? this.name : fallbackName;
  }
}

import { useCallback } from 'react';
import { logTransactionImportDuration } from 'cb-wallet-analytics/transactions';
import { parseTransactions } from 'cb-wallet-data/AddressHistory/utils/parseTransactions';
import { ETHEREUM_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { SOLANA_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Solana/constants';
import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { saveLastSyncedTxHashOnTxUpdate } from 'cb-wallet-data/stores/LastSyncedTxHash/utils';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { useRefreshTokenApprovals } from 'cb-wallet-data/stores/TokenApprovals/hooks/useRefreshTokenApprovals';
import { usePaginatedTransactions } from 'cb-wallet-data/stores/Transactions/hooks/usePaginatedTransactions';
import { deduplicateAndPersist } from 'cb-wallet-data/stores/Transactions/methods/deduplicateAndPersist';
import { reportTxsConfirmed } from 'cb-wallet-data/stores/Transactions/methods/reportTransactionsConfirmed';
import { useFetchPendingTronTransactions } from 'cb-wallet-data/stores/Transactions/methods/useFetchPendingTronTransactions';
import { UnsupportedTronTransactionProvider } from 'cb-wallet-data/stores/Transactions/models/UnsupportedTronTransaction';
import { processPendingRelayedTransactions } from 'cb-wallet-data/stores/Transactions/sponsored/processPendingRelayedTransactions';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { getTimer } from 'cb-wallet-data/utils/globalTimer';
import { PERF_MARKS, TX_HISTORY_MARKS } from 'cb-wallet-data/utils/perf-constants';
import { endPerfMark } from 'cb-wallet-data/utils/perfMark';
import {
  EthereumAddressHistory,
  HistoryEventUpdate,
  SolanaAddressHistory,
} from 'wallet-engine-signing/history';
import { TransactionSyncingRateLimitError } from 'wallet-engine-signing/history/errors';
import { UnsupportedTronTransactionNetwork } from 'wallet-engine-signing/history/Ethereum/types';
import { isFulfilled } from 'wallet-engine-signing/history/utils';

const hasLoggedImportDurationByChain = new Set();

/**
 * useUpdateTransactions is the callback passed to the address history listener transactions
 * update event. It parses the transactions coming back from tx history service then upserts
 * their corresponding tx in the paginated transactions atom and the database.
 */
export function useUpdateTransactions() {
  const isPoisonousTxFilterEnabled = useIsFeatureEnabled('filter_poisonous_transactions');
  const isAddressHistoryTransactionsEnabled = useIsFeatureEnabled('address_history_transactions');
  const isTronReceiveEnabled = useIsFeatureEnabled('tron_usdt_receive');
  const { refetchPendingTronTransactions } = useFetchPendingTronTransactions();
  const refreshTokenApprovals = useRefreshTokenApprovals();
  const { updatePaginatedTransactions } = usePaginatedTransactions();

  async function updateTransactions(ev: HistoryEventUpdate) {
    if (!ev.updates.length) {
      return;
    }

    const blockchainSymbol = ev.updates[0]?.blockchainSymbol;

    if (
      ev.interval === 'nudge' &&
      blockchainSymbol === ETHEREUM_SYMBOL &&
      ev.nudgeProvider === UnsupportedTronTransactionProvider.BRIDGE_XYZ
    ) {
      // If Receive Tron USDT is disabled, we should not perform the refetch and skip all the nudge logic
      // because we don't want to process it if the feature is disabled for the given platform.
      if (!isTronReceiveEnabled) return;

      refetchPendingTronTransactions();

      // If it's a tron network nudge, we should ignore it and return early. because it'd mean that we
      // don't have a supported (base) tx hash to fetch the transaction from the txhistory service (v2/v3) yet.
      if (ev.nudgeProviderNetworkID === UnsupportedTronTransactionNetwork.TRON) {
        return;
      }
    }

    // isAddressHistoryTransactionsEnabled is only being used for Solana. So if its killed and the blockchain
    // is Solana we return here and ignore if Ethereum.
    if (!isAddressHistoryTransactionsEnabled && blockchainSymbol === SOLANA_SYMBOL) {
      return;
    }

    // Log the time it takes to import transaction history
    const importStart = getTimer('addressHistory.ImportStart');
    if (
      ev.interval === 'init' &&
      importStart &&
      !hasLoggedImportDurationByChain.has(blockchainSymbol)
    ) {
      const duration = performance.now() - importStart;
      logTransactionImportDuration({
        duration,
        chainName: blockchainSymbol,
      });
      hasLoggedImportDurationByChain.add(blockchainSymbol);
    }

    const { updates } = ev;

    const parsedTransactionsResults = await Promise.allSettled(
      updates.map(async function handleUnsyncedTransactions(update) {
        const {
          address,
          unsyncedTransactions,
          addressMetadata,
          spamScoreThresholds,
          context,
          txHistoryVersion,
          errors,
        } = update as EthereumAddressHistory | SolanaAddressHistory;

        const { walletId } = context as { walletId: string };
        const { accountId, network, selectedIndex } = Wallet.propsFromId(walletId);

        errors?.forEach((error) => {
          // We're already aware that our etherscan networks often hit rate limits
          if (
            !(error instanceof TransactionSyncingRateLimitError) ||
            txHistoryVersion !== 'etherscan'
          ) {
            cbReportError({
              context: 'transaction_history',
              metadata: {
                chainId: network.asChain()?.chainId,
              },
              error,
              isHandled: true,
              severity: 'error',
            });
          }
        });

        const parsedTransactions = await parseTransactions(unsyncedTransactions, {
          blockchainSymbol: blockchainSymbol as 'ETH' | 'SOL',
          address,
          network,
          accountId,
          spamScoreThresholds,
          walletIndex: selectedIndex!,
          txHistoryVersion,
          addressMetadata,
        });

        return parsedTransactions;
      }),
    );

    const parsedTransactions = parsedTransactionsResults
      .filter(isFulfilled)
      .flatMap((result) => result.value);

    // Persist and report analytics on confirmed transactions
    if (parsedTransactions?.length) {
      // update sponsored txs in db to be deleted = true
      const updatedSponsoredTxs = [];
      if (blockchainSymbol === ETHEREUM_SYMBOL) {
        const updatedTxs = await processPendingRelayedTransactions();
        updatedSponsoredTxs.push(...updatedTxs);
      }

      const updatedTransactions = await deduplicateAndPersist(
        parsedTransactions,
        blockchainSymbol,
        isPoisonousTxFilterEnabled,
      );

      updatePaginatedTransactions([...updatedTransactions, ...updatedSponsoredTxs]);

      await reportTxsConfirmed(parsedTransactions, blockchainSymbol);

      saveLastSyncedTxHashOnTxUpdate(updatedTransactions);

      // Do a manual refresh of the token approvals list when a new transactions is detected
      if (ev.interval === 'nudge') {
        refreshTokenApprovals(updatedTransactions);
      }
    }

    // Update perf marks for each update/network once all transactions have been processed
    await Promise.allSettled(
      updates.map(async function settleStuff(update) {
        const { chainId, txHistoryVersion, lastSyncedTxHash } = update as
          | EthereumAddressHistory
          | SolanaAddressHistory;

        const chain = Network.fromChainId({ chainId }).asChain();
        const chainName = chain?.displayName ?? '';
        const perfMarkOptions = {
          label: chainName.replaceAll(' ', '_').toLowerCase(),
        };

        const perfMarkName = `${PERF_MARKS.tx_history}_${txHistoryVersion}_${
          lastSyncedTxHash ? TX_HISTORY_MARKS.update_sync : TX_HISTORY_MARKS.full_sync
        }`;

        const isCustomNetworkOrTestnet =
          chain?.isCustomNetwork || chain?.isTestnet || txHistoryVersion === 'etherscan';

        if (!isCustomNetworkOrTestnet) {
          endPerfMark(perfMarkName, perfMarkOptions);
        }
      }),
    );
  }

  return useCallback(updateTransactions, [
    updatePaginatedTransactions,
    refreshTokenApprovals,
    isAddressHistoryTransactionsEnabled,
    isTronReceiveEnabled,
    refetchPendingTronTransactions,
    isPoisonousTxFilterEnabled,
  ]);
}

import { PossibleUTXOBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
import { createAddressHistoryListener } from 'wallet-engine-signing/history';

const UTXO_BALANCE_POLLING_INTERVAL_SECONDS = 30;

export const UTXOAddressHistoryListeners: Record<
  PossibleUTXOBlockchainSymbol,
  ReturnType<typeof createAddressHistoryListener>
> = {
  BTC: createAddressHistoryListener('BTC', {
    pollingIntervalSeconds: UTXO_BALANCE_POLLING_INTERVAL_SECONDS,
  }),
  DOGE: createAddressHistoryListener('DOGE', {
    pollingIntervalSeconds: UTXO_BALANCE_POLLING_INTERVAL_SECONDS,
  }),
  LTC: createAddressHistoryListener('LTC', {
    pollingIntervalSeconds: UTXO_BALANCE_POLLING_INTERVAL_SECONDS,
  }),
};

export const ethereumAddressHistoryListener = createAddressHistoryListener('ETH');

export const solanaAddressHistoryListener = createAddressHistoryListener('SOL');

import { memo } from 'react';
import { useOnMount } from 'cb-wallet-data/hooks/useOnMount';
import {
  BorderRadius,
  DimensionValue,
  IconSize,
  PaletteBackground,
  PaletteForeground,
} from '@cbhq/cds-web';
import { Icon } from '@cbhq/cds-web/icons/Icon';
import { Box, VStack } from '@cbhq/cds-web/layout';

type EmptyCollectibleListItemProps = {
  adjustedHeight?: DimensionValue;
  adjustedWidth?: DimensionValue;
  radius?: BorderRadius;
  iconSize?: IconSize;
  iconColor?: PaletteForeground;
  background?: PaletteBackground;
  onLoad?: () => void;
};

const ICON_COLOR = 'var(--background-overlay)';

// Empty icon for collections list item avatar
const EmptyCollectionListItem = memo(function EmptyCollectionListItem({
  adjustedHeight,
  adjustedWidth,
  radius = 'roundedFull',
  onLoad,
}: EmptyCollectibleListItemProps) {
  useOnMount(() => {
    onLoad?.();
  });

  return (
    <Box
      testID="empty-collection-list-item"
      width={adjustedWidth}
      height={adjustedHeight}
      borderRadius={radius}
      background="transparent"
      overflow="hidden"
    >
      <VStack height="100%" width="100%" justifyContent="center" alignItems="center">
        <Icon size="s" name="sparkle" dangerouslySetColor={ICON_COLOR} />
      </VStack>
    </Box>
  );
});

// Empty icon for NFT grid
const EmptyCollectibleListItem = memo(function EmptyCollectibleListItem({
  adjustedHeight,
  adjustedWidth,
  radius,
  iconSize = 'l',
  iconColor,
  background = 'transparent',
  onLoad,
}: EmptyCollectibleListItemProps) {
  useOnMount(() => {
    onLoad?.();
  });

  return (
    <Box
      testID="empty-list-item"
      width={adjustedWidth}
      height={adjustedHeight}
      borderRadius={radius}
      background={background}
      overflow="hidden"
    >
      <VStack height="100%" width="100%" justifyContent="center" alignItems="center">
        <Icon
          size={iconSize}
          name="nft"
          color={iconColor}
          dangerouslySetColor={iconColor ? undefined : ICON_COLOR}
        />
      </VStack>
    </Box>
  );
});

export { EmptyCollectibleListItem, EmptyCollectionListItem };

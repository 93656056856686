import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { SignEthereumMessageWithWeb3 } from 'cb-wallet-data/ServiceLocator/signingAndDerivation/types/SignEthereumMessage';
import { strip0x } from 'cb-wallet-data/utils/String+Core';
import { BaseError, createWalletClient, custom, EIP1193Provider, isAddress } from 'viem';

import { WalletProviderNetwork } from ':dapp/connection/types';

import { getConnectorManagerForAccountId } from '../utils/getConnectorManagerForAccountId';
import { getEthereumAddressFromAccountId } from '../utils/getEthereumAddressFromAccountId';
import { validateKnownEthereumAddress } from '../utils/validateKnownEthereumAddress';
import { validateWalletProvidersConnected } from '../utils/validateWalletProvidersConnected';
/**
 * Signs an ethereum message using the web3 providers personal_sign method
 * signEthereumMessage emits SigningMethodErrors errors, which should be handled by the caller
 *
 * @param accountId {string}
 * @param message {Buffer}
 * @throws {SigningMethodError} SigningMethodErrorType.WrongSelectedAddress - the address you're trying to sign with is not connected to your wallet
 * @throws {SigningMethodError} SigningMethodErrorType.UserRejectedRequest - the user rejected the new connect request
 * @throws {SigningMethodError} SigningMethodErrorType.Generic - catch-all for unknown or unlikely errors
 * @returns {Buffer}
 */
export async function signEthereumMessage({
  accountId,
  message,
}: Parameters<SignEthereumMessageWithWeb3>[0]): ReturnType<SignEthereumMessageWithWeb3> {
  const address = getEthereumAddressFromAccountId(accountId);
  const connectorManager = getConnectorManagerForAccountId(accountId);

  const provider = (await connectorManager.getProvider(
    WalletProviderNetwork.Ethereum,
  )) as EIP1193Provider;

  const walletClient = createWalletClient({
    account: address,
    transport: custom(provider),
  });

  // validate the provider is connected
  await validateWalletProvidersConnected(connectorManager, WalletProviderNetwork.Ethereum);
  // validate the provider is connected to the correct address
  await validateKnownEthereumAddress(provider, address);

  const account = address;
  if (!isAddress(account)) {
    throw new Error('invalid address');
  }

  try {
    const signature = await walletClient.signMessage({
      account,
      message: message.toString(),
    });

    return Buffer.from(strip0x(signature).toLowerCase(), 'hex');
  } catch (error) {
    cbReportError({
      error: coerceError(error, 'sign etherum message'),
      context: 'sign-message',
      severity: 'error',
      isHandled: false,
    });
    const err = error instanceof BaseError ? new Error(error.shortMessage) : error;
    throw err;
  }
}

import { CoinbaseSmartWalletProvider } from '../providers/CoinbaseSmartWalletProvider';

import {
  CoinbaseWalletParameters,
  createCoinbaseWalletConnectorFn,
} from './createCoinbaseWalletConnectorFn';

export const type = 'coinbaseSmartWallet' as const;

export function coinbaseSmartWallet(parameters: CoinbaseWalletParameters = {}) {
  return createCoinbaseWalletConnectorFn({
    type,
    id: 'coinbaseSmartWalletSDK',
    provider: CoinbaseSmartWalletProvider,
  })(parameters);
}

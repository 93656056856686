import { getStores } from '@slicekit/core';
import { CAIP19Parser } from 'cb-wallet-data/utils/caip19AssetParser';

import { CB_WALLET_API_URL, CB_WALLET_DAPP_URL } from ':dapp/config/env';
import { getFrameMetadataFromUrl } from ':dapp/pages/ocs/utils/getFrameMetadataFromUrl';
import { RouterPathname } from ':dapp/utils/RoutesEnum';

import { getGalleryMetaData } from './getGalleryMetadata';
import { getMintDataBySlug } from './getMintDataBySlug';
import { getNftMetadata } from './getNftMetadata';
import { MINT_PAGE_SLUGS, mintPageConfig, MintPageSlug } from './mintPageConstants';

export type Metadata = {
  property: string;
  content: string;
};

async function getNftMetaData(_routeName: string, _query: NodeJS.Dict<string | string[]>) {
  const slugOrAddress = (_query.contractAddress || _query.address) as MintPageSlug;
  let address = '';
  let tokenId = (_query.tokenId as string) || undefined;

  if (!MINT_PAGE_SLUGS.includes(slugOrAddress)) {
    if (slugOrAddress?.startsWith('eip')) {
      const caip19Asset = CAIP19Parser.TryParse(slugOrAddress);
      address = String(caip19Asset?.assetReference);
      tokenId = String(caip19Asset?.tokenId);
    } else {
      // if slug can't be parsed to get the address, get the address from the API
      const mintDetailsBySlug = await getMintDataBySlug(slugOrAddress);
      address = mintDetailsBySlug?.slug?.contractAddress ?? slugOrAddress;
    }
  } else {
    ({ contractAddress: address, tokenId } = mintPageConfig[slugOrAddress]);
  }

  const nftData = await getNftMetadata(address, tokenId);

  const mintDetailTags = [];

  if (_routeName === RouterPathname.MINT_DETAIL) {
    mintDetailTags.push(
      {
        property: 'fc:frame:post_url',
        content: `${CB_WALLET_DAPP_URL}/api/after-mint/${address}?tokenId=${tokenId}`,
      },
      {
        property: 'fc:frame:button:1',
        content: 'Mint',
      },
      {
        property: 'fc:frame:button:1:action',
        content: 'tx',
      },
      {
        property: 'fc:frame:button:1:target',
        content: `${CB_WALLET_DAPP_URL}/api/mint/${address}?tokenId=${tokenId}`,
      },
      {
        property: 'fc:frame:button1:post_url',
        content: `${CB_WALLET_DAPP_URL}/api/after-mint/${address}?tokenId=${tokenId}`,
      },
    );
  }

  return [
    ...mintDetailTags,
    {
      property: 'og:title',
      content: nftData.name,
    },
    {
      property: 'og:description',
      content: nftData.description,
    },
    {
      property: 'title',
      content: nftData.name,
    },
    {
      property: 'fc:frame',
      content: 'vNext',
    },
    {
      property: 'fc:frame:image',
      content: nftData.imagePreview,
    },
    {
      property: 'og:image',
      content: nftData.image,
    },
    // Twitter
    { property: 'twitter:title', content: nftData.name },
    {
      property: 'twitter:description',
      content: nftData.description ?? '',
    },
    { property: 'twitter:image', content: nftData.imagePreview },
  ];
}

function buildOGTags({
  title,
  description,
  image,
}: {
  title: string;
  description: string;
  image: string;
}) {
  return [
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: description,
    },
    {
      property: 'og:image',
      content: image,
    },
    { property: 'twitter:title', content: title },
    {
      property: 'twitter:description',
      content: description,
    },
    { property: 'twitter:image', content: image },
    {
      property: 'fc:frame',
      content: 'vNext',
    },
    {
      property: 'fc:frame:image',
      content: image,
    },
  ];
}

async function buildSummerPassTags() {
  return buildOGTags({
    title: `Onchain Summer`,
    description: `Onchain Summer is a celebration of onchain art, music, culture, gaming, and more. Earn points from minting your favorite drops, redeem prizes, and buy merch onchain. Join me in creating a better and more creative internet.`,
    image: 'https://go.wallet.coinbase.com/static/ocsogtag.png',
  });
}

function getDefaultOCSMetaData() {
  return buildOGTags({
    title: `Onchain Summer`,
    description: `Onchain Summer is a celebration of onchain art, music, culture, gaming, and more. Earn points from minting your favorite drops, redeem prizes, and buy merch onchain. Join me in creating a better and more creative internet.`,
    image: 'https://go.wallet.coinbase.com/static/ocsogtag.png',
  });
}

async function buildChallengeCardTags(challengeId: string) {
  if (!challengeId?.length) {
    return getDefaultOCSMetaData();
  }

  const apiRes = await fetch(
    `${CB_WALLET_API_URL}/rpc/v2/explore/getContentByIdUnauth?id=${challengeId}`,
    {
      method: 'GET',
    },
  );

  if (!apiRes.ok) {
    return getDefaultOCSMetaData();
  }

  const data = await apiRes.json();
  const cardData = data?.content;

  if (!cardData?.ocsChallengeCard) {
    return getDefaultOCSMetaData();
  }

  return buildOGTags({
    title: cardData.ocsChallengeCard.title,
    description: cardData.ocsChallengeCard.shortDescription,
    image: (cardData.ocsChallengeCard.imageUrl || '').trim(),
  });
}

async function getOCSMetaData(_routeName: string, _query: NodeJS.Dict<string | string[]>) {
  const challengeId = _query.challenge_id as string;
  const summerpassId = _query.summerpass_id as string;

  if (!challengeId && !summerpassId) {
    return getDefaultOCSMetaData();
  }

  if (summerpassId && summerpassId.length > 0) {
    const ogTags = await buildSummerPassTags();
    return ogTags;
  }

  if (challengeId && challengeId.length > 0) {
    const ogTags = await buildChallengeCardTags(challengeId);
    return ogTags;
  }

  return getDefaultOCSMetaData();
}

async function getSliceProductMetadata(_routeName: string, query: NodeJS.Dict<string | string[]>) {
  const metadata = await getFrameMetadataFromUrl(
    `https://slice.so/slicer/${query?.shopId}/products/${query?.productId}`,
  );

  return metadata;
}

async function getShopMetaData(_routeName: string, query: NodeJS.Dict<string | string[]>) {
  const shopData = await getStores({ slicerIds: query?.shopId ? [Number(query?.shopId)] : [] });

  const shop = shopData?.[0];

  if (!shop) {
    return getDefaultOCSMetaData();
  }

  return buildOGTags({
    title: `Onchain Summer Shop - ${shop.name}`,
    description: shop.description || '',
    image: shop.image || '',
  });
}

async function getOCSShopPageMetaData(_routeName: string, _query: NodeJS.Dict<string | string[]>) {
  return buildOGTags({
    title: `Onchain Summer Shop`,
    description:
      'Onchain Summer shop is here. Introducing a new onchain marketplace with unique items from talented creators worldwide. Get merch, onchain. Join me in creating a better and more creative internet.',
    image: 'https://go.wallet.coinbase.com/static/ocsogtag.png',
  });
}

async function getOCSCoffeeDaysMetaData(
  _routeName: string,
  _query: NodeJS.Dict<string | string[]>,
) {
  return buildOGTags({
    title: `Coffee Days`,
    description: 'Help us bring a billion users onchain, one cup of coffee at a time.',
    image: 'https://go.wallet.coinbase.com/static/Hero%20%282%29.png',
  });
}

async function getCreateNftLanding() {
  return buildOGTags({
    title: `Create, share, earn`,
    description:
      'Your creativity is the limit. Share art, music, videos, projects and podcasts - onchain.',
    image: 'https://go.wallet.coinbase.com/static/create-nft-landing-desktop.png',
  });
}

async function getOnchainVisionLanding() {
  return buildOGTags({
    title: `Share your creativity onchain`,
    description: 'Bring your art, music, videos, podcasts, and other projects to the world',
    image: 'https://go.wallet.coinbase.com/static/onchain-vision-og.png',
  });
}

export type MetadataConfigType = Record<
  string,
  Metadata[] | ((routeName: string, query: NodeJS.Dict<string | string[]>) => Promise<Metadata[]>)
>;

export const config: MetadataConfigType = {
  [RouterPathname.MINT_DETAIL]: getNftMetaData,
  [RouterPathname.NFT_COLLECTION]: getNftMetaData,
  [RouterPathname.GALLERY_DETAIL]: getGalleryMetaData,
  // OCS
  [RouterPathname.HOME]: getOCSMetaData, // TODO: remove this when OCS is over
  [RouterPathname.OCS_SUMMER]: getOCSMetaData,
  [RouterPathname.OCS_ROOT]: getOCSMetaData,
  [RouterPathname.OCS_PLAY]: getOCSMetaData,
  [RouterPathname.OCS_PROGRESS]: getOCSMetaData,
  [RouterPathname.OCS_SHOP]: getOCSShopPageMetaData,
  [RouterPathname.OCS_SWEEPSTAKES]: getOCSMetaData,
  [RouterPathname.OCS_MERCHANT]: getShopMetaData,
  [RouterPathname.OCS_TODAY]: getOCSMetaData,
  [RouterPathname.OCS_PRODUCT]: getSliceProductMetadata,
  [RouterPathname.OCS_COFFEE_DAYS]: getOCSCoffeeDaysMetaData,

  [RouterPathname.SETTINGS]: [
    {
      property: 'og:title',
      content: 'Settings',
    },
  ],

  [RouterPathname.CREATE_NFT_LANDING]: getCreateNftLanding,
  [RouterPathname.ONCHAIN_VISION]: getOnchainVisionLanding,
};

export const defaultMetadata: Metadata[] = [
  // Primary Meta Tags
  {
    property: 'title',
    content: '',
  },
  // Open Graph / Facebook
  {
    property: 'og:title',
    content: 'Coinbase Wallet: All your crypto, all in one place',
  },
  {
    property: 'og:description',
    content:
      'Connect your crypto wallets to view all your coins, NFTs, and DeFi positions on one screen.',
  },
  {
    property: 'og:image',
    content: 'https://go.wallet.coinbase.com/static/pano_og_generic.png',
  },
  { property: 'og:image:type', content: 'image/png' },
  { property: 'og:image:width', content: '1200' },
  { property: 'og:image:height', content: '630' },
  // Twitter
  { property: 'twitter:card', content: 'summary_large_image' },
  { property: 'twitter:title', content: 'Coinbase Wallet: All your crypto, all in one place' },
  {
    property: 'twitter:description',
    content:
      'Connect your crypto wallets to view all your coins, NFTs, and DeFi positions on one screen.',
  },
  {
    property: 'twitter:image',
    content: 'https://go.wallet.coinbase.com/static/pano_og_twitter.png',
  },
  { property: 'twitter:creator', content: '@CoinbaseWallet' },
];

export async function getRouteMetadata(
  routeName: string,
  query: NodeJS.Dict<string | string[]>,
): Promise<Metadata[]> {
  const metadataOrFunc = config[routeName] ?? [];
  const routeSpecificMetadata =
    typeof metadataOrFunc === 'function' ? await metadataOrFunc(routeName, query) : metadataOrFunc;

  return [...routeSpecificMetadata, ...defaultMetadata].reduce((acc, current) => {
    const existing = acc.find((item) => item.property === current.property);
    if (!existing) {
      acc.push(current);
    }
    return acc;
  }, [] as Metadata[]);
}

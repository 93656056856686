import { SignAndSubmitETH1559TransactionWithWeb3 } from 'cb-wallet-data/ServiceLocator/signingAndDerivation/types/SignAndSubmitETH1559Transaction';

import { baseSignAndSubmitETHTransaction } from '../utils/baseSignAndSubmitETHTransaction';

/**
 * Signs an eip 1559 transaction using the web3 providers signTransaction method
 * signETH1559Transaction emits SigningMethodErrors errors, which should be handled by the caller
 *
 * see baseSignAndSubmitETHTransaction (:dapp/datalayer/web3/utils/baseSignAndSubmitETHTransaction) for more details
 */
export async function signAndSubmitETH1559Transaction(
  params: Parameters<SignAndSubmitETH1559TransactionWithWeb3>[0],
): ReturnType<SignAndSubmitETH1559TransactionWithWeb3> {
  return baseSignAndSubmitETHTransaction(params);
}

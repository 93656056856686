import { useCallback } from 'react';
import {
  logSaveAndSetAccountFailed,
  logSaveAndSetAccountStarted,
  logSaveAndSetAccountSuccess,
} from 'cb-wallet-analytics/accounts';
import uniqBy from 'lodash/uniqBy';
import { useSetRecoilState } from 'recoil';

import { saveAccount } from '../database';
import { Account } from '../models/Account';
import { accountsAtom } from '../state';

export function useSaveAndSetAccount() {
  const setAccounts = useSetRecoilState(accountsAtom);
  return useCallback(
    async function saveAndSetAccount(account: Account): Promise<void> {
      try {
        logSaveAndSetAccountStarted();
        await saveAccount(account);
        setAccounts((accounts) => uniqBy([account, ...accounts], 'id'));
        logSaveAndSetAccountSuccess();
      } catch (e) {
        logSaveAndSetAccountFailed();
        throw e;
      }
    },
    [setAccounts],
  );
}

import { BLOCK_EXPLORER_URL as BTCExplorer } from 'cb-wallet-data/chains/UTXO/Bitcoin/constants';
import { BLOCK_EXPLORER_URL as DOGEExplorer } from 'cb-wallet-data/chains/UTXO/Dogecoin/constants';
import { BLOCK_EXPLORER_URL as LTCExplorer } from 'cb-wallet-data/chains/UTXO/Litecoin/constants';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';

export class Blockchain {
  readonly rawValue: string;

  constructor(rawValue: string) {
    this.rawValue = rawValue.toUpperCase();
  }

  toString(): string {
    return this.rawValue;
  }

  static isEqual(b1: Blockchain, b2: Blockchain): boolean {
    return b1.rawValue === b2.rawValue;
  }

  get addressCharLength(): number {
    switch (this.rawValue) {
      case 'SOL':
        return 44;
      case 'ETH':
      case 'BTC':
        return 42;
      case 'LTC':
        return 43;
      case 'DOGE':
        return 34;
      default:
        throw new Error('Blockchain not supported');
    }
  }

  /**
   * Computes a static URL associated with a [Blockchain] instance. When the resulting URL is loaded in a
   * browser it displays the details of a particular transaction.
   *
   * @param txHash the transaction hash to build a URL for.
   * @param network the [Network] to build a URL for
   */
  blockExplorerUrl(txHash: string, network: Network): string | undefined {
    switch (this.rawValue) {
      case 'ETH': {
        const ethereumChain = network.asChain();
        if (ethereumChain?.blockExplorerUrl) {
          return `${ethereumChain.blockExplorerUrl}/tx/${txHash}`;
        }
        return undefined;
      }

      case 'SOL': {
        const solanaChain = network.asChain();
        if (solanaChain) {
          return `${solanaChain.blockExplorerUrl}/tx/${txHash}`;
        }
        return undefined;
      }

      case 'BTC': {
        return `${BTCExplorer}/tx/${txHash}`;
      }

      case 'DOGE': {
        return `${DOGEExplorer}/tx/${txHash}`;
      }

      case 'LTC': {
        return `${LTCExplorer}/tx/${txHash}`;
      }

      default:
        return undefined;
    }
  }

  fullName(): string | undefined {
    switch (this.rawValue) {
      case 'ETH':
        return 'Ethereum';
      case 'SOL':
        return 'Solana';
      case 'BTC':
        return 'Bitcoin';
      case 'DOGE':
        return 'Dogecoin';
      case 'LTC':
        return 'Litecoin';
      default:
        return undefined;
    }
  }

  // used for informational displays to users. Roughly blocktime * 3 + cushion
  avgConfirmationTime(): { confirmationTime: string; timeUnit: string } | undefined {
    switch (this.rawValue) {
      case 'ETH':
        return { confirmationTime: '30', timeUnit: 'seconds' };
      case 'SOL':
        return { confirmationTime: '10', timeUnit: 'seconds' };
      case 'BTC':
        return { confirmationTime: '30', timeUnit: 'minutes' };
      case 'DOGE':
        return { confirmationTime: '3', timeUnit: 'minutes' };
      case 'LTC':
        return { confirmationTime: '8', timeUnit: 'minutes' };
      default:
        return undefined;
    }
  }
}

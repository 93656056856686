import { CSSProperties } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Image from 'next/image';
import { IconButton } from '@cbhq/cds-web/buttons/IconButton';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { Spinner } from '@cbhq/cds-web/loaders/Spinner';
import { TextBody, TextTitle1 } from '@cbhq/cds-web/typography';

import { WalletProviderInfo } from ':dapp/connection/types';

type ConnectionLoadingProps = {
  onClose: () => void;
  walletProvider: WalletProviderInfo;
};

const messages = defineMessages({
  confirmConnection: {
    defaultMessage: 'Confirm connection',
    description: 'Text telling the user to confirm connection',
  },
  confirmCopy: {
    defaultMessage: 'Confirm the transaction with your wallet to connect to Coinbase Wallet',
    description: 'Text telling the user to confirm the transaction with their wallet',
  },
});

const imageStyle: CSSProperties = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

export function ConnectionLoading({ onClose, walletProvider }: ConnectionLoadingProps) {
  const { formatMessage } = useIntl();
  return (
    <VStack gap={2} testID="connection-loading" spacing={1} spacingBottom={6}>
      <Box alignItems="center" justifyContent="flex-end">
        <IconButton
          transparent
          name="close"
          onClick={onClose}
          testID="connection-loading-close-icon"
        />
      </Box>
      <Box justifyContent="center" position="relative">
        <Spinner size={6} color="primary" />
        <Image
          src={walletProvider.image}
          height={24}
          width={24}
          alt={walletProvider.name}
          style={imageStyle}
        />
      </Box>
      <Box justifyContent="center">
        <TextTitle1 spacingStart={2} as="h1" align="center">
          {formatMessage(messages.confirmConnection)}
        </TextTitle1>
      </Box>
      <Box justifyContent="center">
        <Box maxWidth="18.75rem">
          <TextBody align="center" as="p" color="foregroundMuted">
            {formatMessage(messages.confirmCopy)}
          </TextBody>
        </Box>
      </Box>
    </VStack>
  );
}

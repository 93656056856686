import { TxOrUserOp } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';

import { TxOrUserOpMetadataKey_cashoutWithdrawalType } from '../models/TxOrUserOpMetadataKey';

export type CashoutWithdrawalType =
  | 'ACH_BANK_ACCOUNT'
  | 'FIAT_ACCOUNT'
  | 'PAYMENT_METHOD_TYPE_ACH'
  | 'PAYMENT_METHOD_TYPE_RTP';

/**
 * The function with return the cashout transaction entity name.
 * The Entity Name could be Bank Account, Coinbase Account, Debit Card etc
 *
 * @param transaction the transaction metadata
 *
 * @return the entity name
 */
export function getCashoutEntityName(metadata: TxOrUserOp['metadata']) {
  if (!metadata.has(TxOrUserOpMetadataKey_cashoutWithdrawalType)) {
    return '';
  }

  const cashoutType = metadata.get(
    TxOrUserOpMetadataKey_cashoutWithdrawalType,
  ) as CashoutWithdrawalType;
  if (
    ['ACH_BANK_ACCOUNT', 'PAYMENT_METHOD_TYPE_ACH', 'PAYMENT_METHOD_TYPE_RTP'].includes(cashoutType)
  ) {
    return 'Bank Account';
  }

  return 'Coinbase Account';
}

import BN from 'bn.js';
import { postJSON } from 'cb-wallet-http/fetchJSON';

import { bnFromHex, parseABIString } from './formatNumbers';

export function handleErrorResponse(data: any) {
  const errorMessage = getErrorMessageFromResponseData(data);
  if (errorMessage) {
    throw new Error(errorMessage);
  }
}

export function getBNResult(data: any): BN {
  handleErrorResponse(data);

  if (!data || typeof data.result !== 'string') {
    throw new Error('unexpected response');
  }

  if (data.result.length === 0) {
    return new BN(0);
  }

  return bnFromHex(data.result);
}

export function getIntegerResult(data: any): number {
  const result = getBNResult(data).toNumber();
  if (!Number.isSafeInteger(result)) {
    throw new Error('result is not a safe integer');
  }
  return result;
}

export function getStringResult(data: any): string {
  handleErrorResponse(data);

  if (!data || typeof data.result !== 'string') {
    throw new Error('unexpected response');
  }

  return data.result;
}

export function getABIStringResult(data: any): string {
  const result = getStringResult(data);
  return parseABIString(result);
}

export function getErrorMessageFromResponseData(data: any): string | null {
  if (data?.error && typeof data.error.message === 'string') {
    return data.error.message;
  }
  return null;
}
export type JSONRPCParams = (string | boolean | number | Record<string, unknown>)[];

export async function callEthereumJSONRPC<T = any>(
  method: string,
  params: JSONRPCParams,
  rpcURL: string,
  headers: Record<string, string> = {},
): Promise<T> {
  const body = {
    jsonrpc: '2.0',
    id: 1,
    method,
    params,
  };

  return postJSON(rpcURL, body, {
    isThirdParty: true,
    authenticated: false,
    additionalHeaders: headers,
  });
}

import { InsertResult } from '@cbhq/typeorm';

export function filterFulfilled<T>(promises: PromiseSettledResult<InsertResult>[]) {
  return promises.reduce(function filterFulfilledReducer(acc, curr) {
    if (curr.status === 'fulfilled') {
      acc.push(...curr.value.identifiers.map((identifier) => identifier.id));
    }
    return acc;
  }, [] as T[]);
}

import { useCallback } from 'react';
import { withTokenRefreshFailureHandling } from 'cb-wallet-data/HTTP/utils/withTokenRefreshFailureHandling';
import { useSetAuthTokens } from 'cb-wallet-data/stores/Authentication/tokens/useSetAuthTokens';
import { FetchOptionsType, getJSON } from 'cb-wallet-http/fetchJSON';

import { AuthedFetchFn, AuthedFetchParams, AuthedGetFn } from './types';

export type RequestOptions = {
  rawData?: boolean;
  forceDevEnvironment?: boolean;
  additionalHeaders?: FetchOptionsType['additionalHeaders'];
};

/**
 * @deprecated this function does not properly type params and can lead to unspecified behaviour. Use useSafeAuthedGet instead.
 * Returns a function which performs an authenticated HTTP GET request.
 *
 * @param endpoint
 * @param requestOptions
 * @returns Promise of T
 */
export function useAuthedGet<T>(
  endpoint: string,
  requestOptions: RequestOptions = { forceDevEnvironment: false, rawData: false },
): AuthedFetchFn<T> {
  const setAuthTokens = useSetAuthTokens();

  return useCallback(
    async function authedGet(params: AuthedFetchParams = {}, options = {}): Promise<T> {
      return withTokenRefreshFailureHandling<T>(
        // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
        // eslint-disable-next-line wallet/no-anonymous-params
        async () => {
          const response = await getJSON<Record<string, T>>(
            endpoint,
            params as Record<string, string>,
            {
              setAuthTokens,
              authenticated: true,
              forceDevEnvironment: requestOptions.forceDevEnvironment,
              additionalHeaders: requestOptions.additionalHeaders,
              ...options,
            },
          );

          if (requestOptions.rawData) {
            return response as unknown as T;
          }

          return response.result;
        },
      );
    },
    [
      endpoint,
      requestOptions.additionalHeaders,
      requestOptions.forceDevEnvironment,
      requestOptions.rawData,
      setAuthTokens,
    ],
  );
}

/**
 * Returns a function which performs an authenticated HTTP GET request.
 *
 * @param endpoint
 * @param requestOptions
 * @returns Promise of T
 */
export function useSafeAuthedGet<T>(
  endpoint: string,
  requestOptions: RequestOptions = { forceDevEnvironment: false, rawData: false },
): AuthedGetFn<T> {
  return useAuthedGet(endpoint, requestOptions);
}

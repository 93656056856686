import { providerLocator } from 'cb-wallet-data/ProviderLocator/ProviderLocator';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';

import { getWalletProvidersConnectionManagerMap } from ':dapp/connection/hooks/useWalletProviders';

export function getProviderForAccountId(accountId: Account['id']) {
  return providerLocator.get(accountId);
}

export function getConnectorManagerForAccountId(accountId: Account['id']) {
  const walletProvider = providerLocator.get(accountId);
  if (!walletProvider) {
    throw new Error('no provider registered for account');
  }
  const walletProviderConnectorMap = getWalletProvidersConnectionManagerMap();
  const connectorManager = walletProviderConnectorMap[walletProvider];
  if (!connectorManager) {
    throw new Error('connectorManager not found for wallet provider');
  }
  return connectorManager;
}

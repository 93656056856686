import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Display',
    description: 'The display setting category title',
  },
  description: {
    defaultMessage: 'Dark mode, currency, balances',
    description: 'The display setting category description',
  },
  descriptionWithLanguageSetting: {
    defaultMessage: 'Dark mode, currency, balances, language',
    description: 'The display setting category description with language setting',
  },
  dappDescription: {
    defaultMessage: 'Theme color, appearance, currencies, private mode, hide balances',
    description: 'The display setting category description on panorama',
  },
  appearanceSectionTitle: {
    defaultMessage: 'Appearance',
    description: 'Title for appearance setting',
  },
  selectedMode: {
    defaultMessage: 'Selected',
    description: 'Label for the selected color scheme',
  },
  unselectedMode: {
    defaultMessage: 'Unselected',
    description: 'Label for the unselected color scheme',
  },
  darkTitle: {
    defaultMessage: 'Dark',
    description: 'Title for setting to change app color scheme to dark mode',
  },
  darkDescription: {
    defaultMessage: 'Always use dark mode',
    description: 'Description of setting to change app color scheme to always be dark mode',
  },
  lightTitle: {
    defaultMessage: 'Light',
    description: 'Title for setting to change app color scheme to light mode',
  },
  lightDescription: {
    defaultMessage: 'Always use light mode',
    description: 'Description of setting to change app color scheme to always be light mode',
  },
  paymentsModeTitle: {
    defaultMessage: 'Simple mode',
    description: 'Title for setting to change app into simple mode',
  },
  paymentsModeDescription: {
    defaultMessage: 'Perfect for beginners',
    description: 'Description of setting that enables all advanced features in the app',
  },
  systemTitle: {
    defaultMessage: 'Auto',
    description: 'Title for setting to change app color scheme to match that of the system',
  },
  systemDescription: {
    defaultMessage: "Use your device's default mode",
    description: 'Description of setting to change app color scheme to match that of the system',
  },
  balanceSectionTitle: {
    defaultMessage: 'Balances',
    description: 'Title for balances setting',
  },
  localCurrencyTitle: {
    defaultMessage: 'Local currency',
    description: 'Title for setting to change local currency throughout the app, e.g. USD to EUR',
  },
  privacyModeSettingTitle: {
    defaultMessage: 'Privacy mode',
    description: 'Label for setting to hide wallet balances',
  },
  privacyModeSettingDescription: {
    defaultMessage: 'Hide all balances',
    description: 'Description for setting to hide wallet balances',
  },
  privacyModeSwitchLabel: {
    defaultMessage: 'Privacy mode toggle',
    description: 'Accessibility label for the privacy mode toggle',
  },
  privacyModeTooltipTitle: {
    defaultMessage: 'Balances are now hidden',
    description: 'Title of tray after toggling privacy mode',
  },
  privacyModeTooltipDescription: {
    defaultMessage:
      'You can always touch and hold your portfolio balance anywhere in the app to switch between hide and show.',
    description: 'Describes how to turn on/off privacy mode by tapping portfolio balances',
  },
  privacyModeTooltipBtnText: {
    defaultMessage: 'Dismiss',
    description: 'Text on tooltip button',
  },
  hideSmallBalancesTitle: {
    defaultMessage: 'Hide small balances',
    description: 'Title for setting to hide small balances',
  },
  hideSmallBalancesDescription: {
    defaultMessage: 'Balances below $1.00',
    description: 'Description for setting to hide small balances below $1.00',
  },
  hideSmallBalancesAccessibilityLabel: {
    defaultMessage: 'Hide small balances toggle',
    description: 'Accessibility label for the hide small balances toggle',
  },
  testnetModeSwitchLabel: {
    defaultMessage: 'Testnet mode toggle',
    description: 'Accessibility label for the testnet mode toggle',
  },
  testnetModeSettingTitle: {
    defaultMessage: 'Testnets',
    description: 'Label for setting to toggle testnet mode',
  },
  testnetModeSettingDescription: {
    defaultMessage: 'Show testnet balances',
    description: 'Description for setting to toggle testnet mode',
  },
  languageTitle: {
    defaultMessage: 'Language',
    description: 'Language setting category title',
  },
  stickyTabsSectionTitle: {
    defaultMessage: 'Tabs',
    description: 'Section title for setting to toggle sticky tabs',
  },
  stickyTabsTitle: {
    defaultMessage: 'Sticky tabs',
    description: 'Title for setting to toggle sticky tabs',
  },
  stickyTabsDescription: {
    defaultMessage: 'The last tab selected always stays selected, even after app close',
    description: 'Description for setting to toggle sticky tabs',
  },
});

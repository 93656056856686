import { AppMode } from 'cb-wallet-data/FeatureManager/types';
import { atomLocalStorageEffect } from 'cb-wallet-data/utils/atomLocalStorageEffect';
import { atomWithStorage } from 'cb-wallet-data/utils/objectAtomLocalStorageEffectJotai';
import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { Store } from 'cb-wallet-store/Store';
import { atom } from 'recoil';

export const StoreKeys_isPerformanceMonitorEnabled = new LocalStorageStoreKey<boolean>(
  'isPerformanceMonitorEnabled',
);

export const isPerformanceMonitorEnabledAtom = atom({
  key: 'isPerformanceMonitorEnabled',
  default: false,
  effects: [atomLocalStorageEffect(StoreKeys_isPerformanceMonitorEnabled)],
});

/**
 * @deprecated. Legacy atom that was used to determine if the user was in advanced mode (super app) or not.
 */
export const StoreKeys_isAdvancedModeEnabled = new LocalStorageStoreKey<boolean>(
  'isAdvancedModeEnabled',
);

/**
 * @deprecated Legacy atom that was used to determine if the user was in advanced mode (super app) or not.
 * Please use `applicationModeAtom` instead.
 */
// eslint-disable-next-line camelcase
export const __deprecated_isAdvancedModeEnabledAtom = atom({
  key: 'isAdvancedModeEnabled',
  default: true,
  effects: [atomLocalStorageEffect(StoreKeys_isAdvancedModeEnabled)],
});

export const StoreKeys_applicationMode = new LocalStorageStoreKey<string | null>('applicationMode');

export const applicationModeAtom = atom<AppMode | null>({
  key: 'applicationMode',
  default: null,
  effects: [atomLocalStorageEffect(StoreKeys_applicationMode)],
});

export const StoreKeys_isDebugToastEnabled = new LocalStorageStoreKey<boolean>(
  'isDebugToastEnabled',
);

export const StoreKeys_isSocialFeedEnabled = new LocalStorageStoreKey<boolean>(
  'isSocialFeedEnabled',
);

export const isDebugToastEnabledAtom = atom({
  key: 'isDebugToastEnabled',
  default: false,
  effects: [atomLocalStorageEffect(StoreKeys_isDebugToastEnabled)],
});

export const isSocialFeedEnabledAtom = atom({
  key: 'isSocialFeedEnabled',
  default: false,
  effects: [atomLocalStorageEffect(StoreKeys_isSocialFeedEnabled)],
});

export const toastMessageAtom = atom({
  key: 'toastMessage',
  default: '',
});

export function isDebugToastEnabled() {
  return Store.get<boolean>(StoreKeys_isDebugToastEnabled) ?? false;
}

const StoreKeys_isSimulatingEmptyWallet = new LocalStorageStoreKey<boolean>(
  'isSimulatingEmptyWallet',
);

export const isSimulatingEmptyWalletAtom = atom({
  key: 'isSimulatingEmptyWallet',
  default: false,
  effects: [atomLocalStorageEffect(StoreKeys_isSimulatingEmptyWallet)],
});

export const StoreKeys_baseUrl = new LocalStorageStoreKey<string>('baseUrl');

export const StoreKeys_isWalletApiDevEnvEnabled = new LocalStorageStoreKey<boolean>(
  'isWalletApiDevEnvEnabled',
);

export const isWalletApiDevEnvEnabledAtom = atomWithStorage({
  key: StoreKeys_isWalletApiDevEnvEnabled,
  initialValue: false,
});

import { logIsFeatureEnabledStoreSet } from 'cb-wallet-analytics/feature-management';

import { FeatureName } from '../featureConfigs';

/**
 * IsFeatureEnabledStore
 *
 * If this is being accessed before the store is synced, it could result in a
 * different value.
 *
 * To use this, navigate to useSyncIsFeatureEnabledStore and make sure the
 * featureName and its value is being set there.
 *
 * This is a prototype mode.
 *
 * NOTE Please reach out to ericshenghsiung.liu@coinbase.com before using.
 */
class IsFeatureEnabledStore {
  #isEnabledByFeatureName: Partial<Record<FeatureName, boolean>>;

  constructor() {
    this.#isEnabledByFeatureName = {};
  }

  /**
   * getIsFeatureEnabled
   *
   * Returns the stored value of the feature name if present. Else, returns false.
   *
   * NOTE This returns false by default.
   */
  getIsFeatureEnabled(featureName: FeatureName): boolean {
    return Boolean(this.#isEnabledByFeatureName[featureName] ?? false);
  }

  /**
   * setIsFeatureEnabled
   */
  setIsFeatureEnabled(value: Partial<Record<FeatureName, boolean>>) {
    this.#isEnabledByFeatureName = {
      ...this.#isEnabledByFeatureName,
      ...value,
    };

    logIsFeatureEnabledStoreSet();
  }
}

const isFeatureEnabledStore = new IsFeatureEnabledStore();

export { isFeatureEnabledStore };

import { AllPossibleBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';

import { DestinationWallet, SupportedBlockchain } from './generateOnrampUrl';

export type LegacySupportedBlockchain =
  | 'ETH'
  | 'AVAX'
  | 'SOL'
  | 'MATIC'
  | 'OP'
  | 'BTC'
  | 'LTC'
  | 'DOGE'
  | 'BASE'
  | 'ARB';

export type LegacyDestinationWallets = {
  address: string;
  blockchains: LegacySupportedBlockchain[];
};

const defaultLegacySupportedBlockchainId = ['ETH', 'AVAX', 'SOL', 'MATIC', 'OP', 'BASE', 'ARB'];
export const LegacyIdToBlockchainId: Record<LegacySupportedBlockchain, SupportedBlockchain> = {
  AVAX: 'avalanche-c-chain',
  ETH: 'ethereum',
  SOL: 'solana',
  MATIC: 'polygon',
  OP: 'optimism',
  BTC: 'bitcoin',
  LTC: 'litecoin',
  DOGE: 'dogecoin',
  BASE: 'base',
  ARB: 'arbitrum',
};

type ParseDetinationWalletsArgs = {
  destinationWallets: LegacyDestinationWallets[];
  tokenId: string;
  additionalSupportedBlockchainIds?: AllPossibleBlockchainSymbol[];
};
export function parseDestinationWallets({
  destinationWallets,
  tokenId,
  additionalSupportedBlockchainIds,
}: ParseDetinationWalletsArgs): DestinationWallet[] | undefined {
  const supportedBlockchainIds = [...defaultLegacySupportedBlockchainId];
  if (tokenId === 'ETH') {
    const ethWallet = destinationWallets.find((wallet) => wallet.blockchains.includes('ETH'));
    if (!ethWallet) {
      return;
    }

    return [
      {
        address: ethWallet.address,
        assets: ['ETH'],
      },
    ];
  }

  if (additionalSupportedBlockchainIds) {
    supportedBlockchainIds.push(...additionalSupportedBlockchainIds);
  }

  return (
    destinationWallets
      .map((wallet) => ({
        address: wallet.address,
        blockchains: wallet.blockchains.filter((blockchain) =>
          supportedBlockchainIds.includes(blockchain),
        ),
      }))
      .filter((filteredChains) => filteredChains.blockchains.length !== 0)
      // convert supported legacy blockchain names
      .map((wallet) => ({
        address: wallet.address,
        blockchains: wallet.blockchains.map(
          (filteredBlockchain) =>
            (LegacyIdToBlockchainId as Record<string, SupportedBlockchain>)[filteredBlockchain],
        ),
      }))
  );
}

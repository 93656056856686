export function truncateMiddle(
  str: string,
  charactersAtFront: number,
  charactersAtEnd: number,
): string {
  const truncationString = '…';
  const trimmed = str.trim();

  if (trimmed.length <= charactersAtFront + charactersAtEnd + truncationString.length) {
    return str;
  }

  const startOfEnd = trimmed.length - charactersAtEnd;
  const firstChars = trimmed.substring(0, charactersAtFront);
  const lastChars = trimmed.substring(startOfEnd);

  return firstChars + truncationString + lastChars;
}

export function abridgeName(n: string, maxLength?: number) {
  const name = n.trim();
  const nameLength = name.length;
  const MAX_LENGTH = maxLength ?? 18;
  const TRUNCATE = Math.max(1, MAX_LENGTH - 3);

  if (nameLength < TRUNCATE || (nameLength >= TRUNCATE && nameLength < MAX_LENGTH)) {
    return name;
  }

  return `${name.substring(0, TRUNCATE)}...`;
}

export function stringToURL(str?: string, base?: string): URL | undefined {
  if (!str) return undefined;

  try {
    return new URL(str, base);
  } catch {
    return undefined;
  }
}

const hexadecimalCharacters = '0123456789abcdef';

export function strip0x(str: string): string {
  return str.startsWith('0x') ? str.substring(2) : str;
}

export function asHexEncodedData(s: string): Buffer | undefined {
  let str = strip0x(s).toLowerCase();
  if (str.length % 2 !== 0) {
    str = `0${it}`;
  }

  const size = str.length / 2;
  const bytes = new Uint8Array(size);

  for (let i = 0; i < size; i++) {
    const valLeft = hexadecimalCharacters.indexOf(str[i * 2]);
    if (valLeft === -1) {
      return undefined;
    }
    const valRight = hexadecimalCharacters.indexOf(str[i * 2 + 1]);
    if (valRight === -1) {
      return undefined;
    }
    bytes[i] = valLeft * 16 + valRight;
  }

  return Buffer.from(bytes);
}

export function hexToUtf8(message: string): string | null {
  const strippedMsg = strip0x(message);
  return Buffer.from(strippedMsg.toLowerCase(), 'hex').toString('utf8') || null;
}

import { useCallback, useEffect, useMemo, useRef } from 'react';
import { logBalanceUpdated, logCryptoBalancesCached } from 'cb-wallet-analytics/assets';
import { useDebouncedCallback } from 'cb-wallet-data/hooks/useDebouncedCallback';
// eslint-disable-next-line no-restricted-imports
import { useActiveWalletGroupId } from 'cb-wallet-data/stores/WalletGroups/hooks/useActiveWalletGroupId';
import { usePortfolioWallets } from 'cb-wallet-data/stores/Wallets/hooks/usePortfolioWallets';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { areAllNetworksBalanceSyncedSelector } from 'cb-wallet-data/stores/Wallets/state';
import { hashString } from 'cb-wallet-data/utils/hashString';
import { Store } from 'cb-wallet-store/Store';
import noop from 'lodash/noop';
import { useRecoilValue } from 'recoil';

import { StoreKeys_cryptosLoadingState, StoreKeys_WalletBalance } from './storeKeys';

export const BALANCE_CACHE_DEBOUNCING_TIME = 2000;

export type CryptoBalanceUpdateOptions = {
  onBalanceDidUpdate?: (wallets: Wallet[]) => void;
  onBalanceNoUpdate?: (wallets: Wallet[]) => void;
  includeTestnet?: boolean;
};

export function useCryptosUpdate({
  onBalanceDidUpdate = noop,
  onBalanceNoUpdate = noop,
  includeTestnet = false,
}: CryptoBalanceUpdateOptions) {
  const wallets = usePortfolioWallets();
  const walletGroupId = useActiveWalletGroupId();
  const cryptosLoadingStateStoreKey = useMemo(
    () => StoreKeys_cryptosLoadingState(walletGroupId),
    [walletGroupId],
  );
  const cryptosLoadingState = Store.get(cryptosLoadingStateStoreKey);
  const areAllNetworksBalanceSynced = useRecoilValue(areAllNetworksBalanceSyncedSelector);

  // A balance update generally results in a couple re-renders, so we use a ref to track if the callback has run
  // to avoid calling the no update callback right after the did update callback is called
  // and calling no update callback multiple times
  const didCallbackRun = useRef(false);

  // Debounce balance cache with longer time due to the higher latency on balance data syncing with portfolioWalletsAtom
  const handleBalanceCached = useCallback(
    function handleBalanceCached() {
      if (!wallets) return;

      // Set cryptosLoadingState = loaded
      if (!cryptosLoadingState && areAllNetworksBalanceSynced) {
        return Store.set(cryptosLoadingStateStoreKey, 'loaded');
      }

      // Cache balance when cryptosLoadingState = 'loaded'
      if (cryptosLoadingState !== 'loaded') return;

      wallets.forEach(function cacheBalancesToLocalStorage(wallet) {
        if (wallet.network.isTestnet && !includeTestnet) return;

        const key = StoreKeys_WalletBalance(wallet.id);
        const storedBalance = Store.get<bigint>(key);
        const walletBalance = wallet.balance ?? 0n;

        if (storedBalance !== undefined) return;

        Store.set(key, walletBalance);
      });

      Store.set(cryptosLoadingStateStoreKey, 'cached');

      logCryptoBalancesCached({ walletsLength: wallets.length });
    },
    [
      areAllNetworksBalanceSynced,
      cryptosLoadingState,
      cryptosLoadingStateStoreKey,
      includeTestnet,
      wallets,
    ],
  );

  const debouncedBalanceCached = useDebouncedCallback(
    handleBalanceCached,
    BALANCE_CACHE_DEBOUNCING_TIME,
  );

  const handleBalanceUpdated = useCallback(
    async function handleBalanceUpdated() {
      if (!wallets || cryptosLoadingState !== 'cached') return;

      // Check balance
      Promise.all(
        wallets.map(async function compareRefreshedToCachedBalances(
          wallet,
        ): Promise<Wallet | undefined> {
          // exclude test net wallet
          if (wallet.network.isTestnet && !includeTestnet) return;

          const key = StoreKeys_WalletBalance(wallet.id);
          const storedBalance = Store.get<bigint>(key);
          const walletBalance = wallet.balance ?? 0n;

          if (storedBalance !== undefined && storedBalance === walletBalance) return;

          Store.set(key, walletBalance);

          const baseChain = wallet?.network?.asChain();

          logBalanceUpdated({
            walletGroupId,
            walletId: wallet.id,
            walletsLength: wallets.length,
            blockchain: wallet?.blockchain?.rawValue,
            currencyCode: wallet?.currencyCode?.rawValue,
            networkName: wallet?.network?.rawValue,
            chainName: baseChain?.displayName,
            chainId: baseChain?.chainId?.toString(),
            isStartBalanceZero: storedBalance === undefined || storedBalance === 0n,
            contractAddress: wallet?.contractAddress,
            isCollectionUpdate: false,
            cachedBalance: storedBalance ? await hashString(storedBalance?.toString()) : undefined,
            refreshedBalance: storedBalance
              ? await hashString(walletBalance?.toString())
              : undefined,
            lastBalanceUpdateTxHash: wallet.lastBalanceUpdateTxHash,
          });
          return wallet;
        }),
      ).then(function handleBalanceUpdateComplete(balanceCheckResults) {
        const balanceUpdatedWallets = balanceCheckResults.filter(
          (result) => result !== undefined,
        ) as Wallet[];

        if (balanceUpdatedWallets.length > 0) {
          onBalanceDidUpdate?.(balanceUpdatedWallets);
        } else if (!didCallbackRun.current) {
          onBalanceNoUpdate?.(wallets);
        }

        didCallbackRun.current = true;
      });
    },
    [
      wallets,
      cryptosLoadingState,
      includeTestnet,
      walletGroupId,
      onBalanceDidUpdate,
      onBalanceNoUpdate,
    ],
  );

  useEffect(debouncedBalanceCached, [debouncedBalanceCached]);
  useEffect(
    function balanceUpdate() {
      handleBalanceUpdated();

      // Reset didCallbackRun to false after BALANCE_CACHE_DEBOUNCING_TIME
      const timer = setTimeout(() => {
        didCallbackRun.current = false;
      }, BALANCE_CACHE_DEBOUNCING_TIME);
      return () => clearTimeout(timer);
    },
    [handleBalanceUpdated],
  );
}

/* eslint-disable no-process-env */
import { CB_WALLET_API_DEV_URL } from 'cb-wallet-env/env';
import { AccessTokenResult } from 'cb-wallet-http/Authentication/types/AccessTokenResult';
import { getUserIdFromStorage } from 'cb-wallet-http/User/state';
import { getApplicationMetadata } from 'cb-wallet-metadata/metadata';
import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { Store } from 'cb-wallet-store/Store';
import { getAnalyticsHeaders, PlatformName } from '@cbhq/client-analytics';

import { authTokenManager } from './Authentication/models/AuthTokenManager';
import { getAbortSignal } from './abort';
import { WALLET_API_URL_MAP } from './constants';
import { handleHTTPError } from './handleHTTPError';
import { HTTPError } from './HTTPError';
import { NetworkRequestFailedError } from './NetworkRequestFailedError';
import {
  getNetworkTracingHeaders,
  startNetworkSpan,
  stopNetworkTrace,
} from './networkTraceProvider';
import { isEmptyObject } from './utils';

type FetchMethod = 'GET' | 'POST' | 'DELETE';
type FetchWithBodyMethod = 'POST' | 'DELETE';
type ApiVersion = 1 | 2 | 3;
type Headers = Record<string, string | undefined>;
type FetchResponse<T> = { status: number; body: T; headers: Headers };
type SearchParams = ConstructorParameters<typeof URLSearchParams>[0];
type ResponseHeadersType =
  | (globalThis.Headers & { entries: () => IterableIterator<[string, string]> })
  | null
  | undefined;
type ApiService =
  | 'rpc'
  | 'activation'
  | 'referral-service'
  | 'payment-providers'
  | 'payment-providers-authed'
  | 'provider-transaction-service/transactions'
  | 'offramps'
  | 'cards'
  | 'payment-provider-proxy/mesh/api'
  | 'rewards';

export type WalletAccountType =
  | '0' // mnemonic
  | '1' // private key
  | '2' // wallet link
  | '3' // ledger
  | '4' // trezor
  | '5' // dapp provider
  | '6'; // smart contract wallet

export type AnyJSON = Record<string, any>;

export type SharedFetchOptions = {
  method: FetchMethod;
  retried?: boolean;
  apiVersion?: ApiVersion;
  additionalHeaders?: Headers;
  returnHeaders?: boolean;
  isTextResponse?: boolean;
  service?: ApiService;
  withRetailToken?: boolean;
  walletApi?: keyof typeof WALLET_API_URL_MAP;
  /**
   * force dev environment for wallet/api for this call alone. can be useful w/ following workflow: sign in w/ dev
   * environment globally enabled, switch to global prod use, and then forceDevEnvironment=true for endpoint under test
   */
  forceDevEnvironment?: boolean;
  walletAccountType?: WalletAccountType;
};

type AuthedFetchOptions = {
  authenticated: true;
  isThirdParty?: never; // We must never pass credentials to 3rd party APIs
  setAuthTokens: (tokens: AccessTokenResult) => void;
  /*
   * @Todo: Remove Temporary fix to avoid CORS issues
   * https://jira.coinbase-corp.com/browse/WALL-28476
   */
  skipBlockingHeaders?: boolean;
  walletApi?: keyof typeof WALLET_API_URL_MAP;
  withRetailToken?: boolean; // whether to get retail access token into request headers, and perform the refresh on 401
} & SharedFetchOptions;

type UnauthedFetchOptions = {
  authenticated?: false;
  isThirdParty?: boolean; // Allows passing full URLs
  skipBlockingHeaders?: boolean; // Temporary fix to avoid CORS issues
  walletApi?: 'CB_WALLET_API'; // CB_WALLET_API is the default wallet api url and can be un-authenticated
  withRetailToken?: false; // Retail token is only used for authenticated requests
} & SharedFetchOptions;

export type FetchOptionsType = AuthedFetchOptions | UnauthedFetchOptions;

// Omit a type from each branch of union
type DistributiveOmit<T, K extends keyof any> = T extends any ? Omit<T, K> : never;

type OmitFetchOptions<T extends string | number | symbol> = DistributiveOmit<FetchOptionsType, T>;

type FetchOptionsNoMethod = OmitFetchOptions<'method'>;

const StoreKeys_baseUrl = new LocalStorageStoreKey<string>('baseUrl');

export const StoreKeys_chaosTestingServiceGroups = new LocalStorageStoreKey<string>(
  'chaosTestingServiceGroups',
);

const CB_AUTH_APIS: (keyof typeof WALLET_API_URL_MAP)[] = ['ONRAMP_API', 'OFFRAMP_API'];

export const StoreKeys_chaosTestingProviderGroups = new LocalStorageStoreKey<string>(
  'chaosTestingProviderGroups',
);

/**
 * Throws HTTPError in case of non-200 response code.
 */
export async function request<T>(
  endpoint: string,
  body: any,
  options: FetchOptionsType,
): Promise<FetchResponse<T>> {
  const {
    method,
    authenticated,
    retried,
    apiVersion = 2,
    isThirdParty = false,
    service = 'rpc',
    forceDevEnvironment,
    walletApi = 'CB_WALLET_API',
    withRetailToken = false,
  } = options;

  const { version, platform, appsFlyerAppID } = getApplicationMetadata();

  const chaosTestingServiceGroups =
    (Store.get(StoreKeys_chaosTestingServiceGroups) as string) || '';
  const chaosTestingProviderGroups =
    (Store.get(StoreKeys_chaosTestingProviderGroups) as string) || '';

  /* Use dev environment if 
    - forceDevEnvironment is true
    - or if the wallet service is CB_WALLET_API and the base URL is the dev URL 
  */
  const isApiDevEnv =
    forceDevEnvironment ||
    (walletApi === 'CB_WALLET_API' && Store.get(StoreKeys_baseUrl) === CB_WALLET_API_DEV_URL);

  const baseUrl = WALLET_API_URL_MAP[walletApi][isApiDevEnv ? 'development' : 'production'];

  const url = isThirdParty
    ? new URL(endpoint)
    : new URL(`${baseUrl}/${service}/v${apiVersion}/${endpoint}`);

  if (walletApi === 'OFFRAMP_API') {
    url.pathname = `/${service}/${endpoint}`;
  }

  if (method === 'GET' && body) {
    url.search = body;
  }

  // Trim trailing slash since it causes the Wallet API to respond with 301
  const fullURL = url.toString().replace(/\/$/, '');

  const authorizationHeaders = authenticated
    ? await authTokenManager.getAuthedHeaders({
        withRetailToken,
        withXCbwAuthorizationHeader: CB_AUTH_APIS.includes(walletApi), // always include wallet access token in X-Cbw-Authorization header for ONRAMP_API
      })
    : null;

  const tracingSpan = startNetworkSpan(endpoint);
  const headers = {
    Accept: 'application/json',
    // Temporary fix to avoid CORS issues
    ...(options.skipBlockingHeaders
      ? {}
      : {
          'X-App-Version': version ?? '',
          'X-Platform-Name': platform ?? '',
          'X-Appsflyer-Id': appsFlyerAppID ?? '',
        }),
    ...authorizationHeaders,

    // Order matters here. We want calles to be able to override the Authorization header
    // if they want to.
    ...options.additionalHeaders,
    ...(chaosTestingServiceGroups
      ? { 'X-Cb-Egw-Simulate-Killswitch': chaosTestingServiceGroups.replace(/, *$/, '') }
      : null),
    ...(chaosTestingProviderGroups
      ? {
          'X-Cb-Egw-Simulate-Killswitch-Downstream': chaosTestingProviderGroups.replace(/, *$/, ''),
        }
      : null),
    ...getAnalyticsHeaders(),
    // if current request is traced we add headers otherwise we don't
    ...getNetworkTracingHeaders(tracingSpan),
  };
  const initOptions = ['POST', 'DELETE'].includes(method) ? { method, body } : null;

  const init: RequestInit = {
    headers,
    signal: getAbortSignal(),
    ...initOptions,
  };

  let response;
  try {
    response = await fetch(fullURL, init);
  } catch (e) {
    if (e instanceof Error) {
      throw new NetworkRequestFailedError(e.message, endpoint);
    }
    throw e;
  }

  if (response.status >= 400) {
    // If we got a 401, attempt to refresh and let the next retry succeed
    if (authenticated && response.status === 401 && !retried) {
      // Onramp api has the www-authenticate header with 'Bear realm="coinbase-wallet"' value when the responses are 401s.
      // For other wallet apis, the www-authenticate header is not present.
      const wwwAuthenticate = response.headers.get('www-authenticate');

      /*
       * If the walletApi is not ONRAMP_API, we should always refresh the wallet access token.
       *
       * If the walletApi is ONRAMP_API and the www-authenticate header includes 'coinbase-wallet',
       * we should refresh the wallet access token. Otherwise, we should refresh the retail access token.
       */
      const shouldRefreshWalletAccessToken =
        !CB_AUTH_APIS.includes(walletApi) || !!wwwAuthenticate?.includes('coinbase-wallet');

      await authTokenManager.refreshAccessTokens({
        onRefreshWalletAccessToken: options.setAuthTokens,
        shouldRefreshWalletAccessToken,
      });
      // Retry once after refreshing the access token
      return request<T>(endpoint, body, { ...options, retried: true });
    }

    await handleHTTPError(response);
  }

  let parsedResponse = null;

  if (options.isTextResponse && response.ok) {
    parsedResponse = await response.text();
  } else if (response.ok) {
    parsedResponse = await response.json();
  }

  stopNetworkTrace(tracingSpan, {
    error: Number(Boolean(response.status >= 400)),
  });

  return {
    status: response.status,
    body: parsedResponse,
    headers: Object.fromEntries((response.headers as ResponseHeadersType)?.entries() ?? []),
  };
}

export function getReleaseStage() {
  const { platform } = getApplicationMetadata();

  if (platform === PlatformName.extension) {
    return process.env.RELEASE_ENVIRONMENT ?? 'local';
  }
  if (platform === PlatformName.web) {
    return process.env.NEXT_PUBLIC_RELEASE_STAGE ?? 'local';
  }

  return process.env.RELEASE_STAGE ?? 'development';
}

export async function fetchJSON<T>(
  endpoint: string,
  body: any,
  options: FetchOptionsType,
): Promise<FetchResponse<T>> {
  const userId = getUserIdFromStorage();
  return request<T>(endpoint, body, {
    ...options,
    additionalHeaders: {
      ...{ 'Content-Type': 'application/json' },
      // Temporary fix to avoid CORS issues
      ...(options.skipBlockingHeaders ? {} : { 'X-Wallet-User-Id': userId }),
      ...(options.skipBlockingHeaders ? {} : { 'X-Release-Stage': getReleaseStage() }),
      ...(options.additionalHeaders ?? {}),
      ...(options.walletAccountType ? { 'X-Wallet-Account-Type': options.walletAccountType } : {}),
    },
  });
}

export async function getFetchResponse<T>(
  endpoint: string,
  params: SearchParams,
  options?: FetchOptionsNoMethod,
): Promise<FetchResponse<T>> {
  const searchParams = new URLSearchParams(params).toString();

  return fetchJSON<T>(endpoint, searchParams.toString(), {
    method: 'GET',
    ...options,
  });
}

export async function getJSON<T>(
  endpoint: string,
  params: Record<string, string>,
  options?: FetchOptionsNoMethod,
): Promise<T> {
  return (await getFetchResponse<T>(endpoint, params, options)).body;
}

export async function fetchWithBodyJSON<T>(
  endpoint: string,
  params: Record<string, any>,
  { returnHeaders, ...options }: FetchOptionsType & { method: FetchWithBodyMethod },
): Promise<FetchResponse<T> | T> {
  const body = isEmptyObject(params) ? undefined : JSON.stringify(params, replacer);
  const result = await fetchJSON<T>(endpoint, body, options);

  return returnHeaders ? result : result.body;
}

export async function postJSON<T>(
  endpoint: string,
  params: Record<string, any>,
  options?: FetchOptionsNoMethod & { returnHeaders?: false },
): Promise<T>;
export async function postJSON<T>(
  endpoint: string,
  params: Record<string, any>,
  options?: FetchOptionsNoMethod & { returnHeaders?: true },
): Promise<FetchResponse<T>>;
export async function postJSON<T>(
  endpoint: string,
  params: Record<string, any>,
  options?: FetchOptionsNoMethod & { returnHeaders?: boolean },
): Promise<FetchResponse<T> | T> {
  return fetchWithBodyJSON<T>(endpoint, params, { method: 'POST', ...options });
}

export async function deleteJSON<T>(
  endpoint: string,
  params: Record<string, any>,
  options?: FetchOptionsNoMethod & { returnHeaders?: false },
): Promise<T>;
export async function deleteJSON<T>(
  endpoint: string,
  params: Record<string, any>,
  options?: FetchOptionsNoMethod & { returnHeaders?: true },
): Promise<FetchResponse<T>>;
export async function deleteJSON<T>(
  endpoint: string,
  params: Record<string, any>,
  options?: FetchOptionsNoMethod & { returnHeaders?: boolean },
): Promise<FetchResponse<T> | T> {
  return fetchWithBodyJSON<T>(endpoint, params, { method: 'DELETE', ...options });
}

export async function postFile<T>(
  endpoint: string,
  formData: FormData,
  options?: FetchOptionsNoMethod & { returnHeaders?: false },
): Promise<T>;
export async function postFile<T>(
  endpoint: string,
  formData: FormData,
  options?: FetchOptionsNoMethod & { returnHeaders?: true },
): Promise<FetchResponse<T>>;
export async function postFile<T>(
  endpoint: string,
  formData: FormData,
  options?: FetchOptionsNoMethod & { returnHeaders?: boolean },
): Promise<FetchResponse<T> | T> {
  const response = await request<T>(endpoint, formData, { method: 'POST', ...options });

  return options?.returnHeaders ? response : response.body;
}

export function isAuthError(error: HTTPError) {
  return error.message === 'refreshToken is invalid';
}

function replacer(_: any, value: any) {
  return typeof value === 'bigint' ? value.toString() : value;
}

/**
 * This file is not covered by unit tests because it is copied/pasted from @coinbase/wallet-sdk.
 * The only change in this file is passing the scwOnboardMode flag as part of the request if it exists.
 */
/* istanbul ignore file */
/* eslint-disable no-restricted-imports */
import { Communicator } from '@coinbase/wallet-sdk/dist/core/communicator/Communicator';
import { SignerType } from '@coinbase/wallet-sdk/dist/core/message';
import { AppMetadata, Preference } from '@coinbase/wallet-sdk/dist/core/provider/interface';

type FetchSignerRequest = {
  id: string;
  event: 'selectSignerType';
  data: Preference & Record<string, unknown>;
};

export async function fetchSignerType(params: {
  communicator: Communicator;
  preference: Preference;
  metadata: AppMetadata; // for WalletLink
  options?: Record<string, unknown>;
}): Promise<SignerType> {
  const { communicator } = params;
  const request: FetchSignerRequest = {
    id: crypto.randomUUID(),
    event: 'selectSignerType',
    data: { ...params.preference },
  };
  // This deviates from the SDK's implementation to support dapp controlled onboarding for smart wallet
  // This allows the dapp to start the onboarding flow inside of SCW web.
  // By passing the scwOnboardMode flag, pano is telling SCW to start the onboarding flow on mount
  // instead of waiting for the user to click the "Create Smart Wallet" button.
  if (params.options?.scwOnboardMode) {
    request.data.scwOnboardMode = params.options.scwOnboardMode;
  }
  const { data } = await communicator.postRequestAndWaitForResponse(request);
  return data as SignerType;
}

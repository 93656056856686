import { ActionType, ComponentType } from '@cbhq/client-analytics';

import { logDataEvent } from '../utils/log';

export function triggerExposureTracked({
  experimentGroup,
  experimentName,
  experimentSubjectId,
  experimentSubjectType,
  isZeroBalanceWallet,
}: {
  experimentGroup?: string;
  experimentName?: string;
  experimentSubjectId?: string;
  experimentSubjectType?: string;
  isZeroBalanceWallet?: boolean;
}) {
  logDataEvent('experiment_exposure.tracked', {
    loggingId: '3b5b457d-c4f7-46ae-b722-e9f28d203217',
    componentType: ComponentType.unknown,
    action: ActionType.unknown,
    experimentGroup,
    experimentName,
    experimentSubjectId,
    experimentSubjectType,
    isZeroBalanceWallet,
  });
}

import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { Store } from 'cb-wallet-store/Store';
import { AtomEffect } from 'recoil';

/**
 * Provides atom effects for storing a Recoil atom's string value in localStorage through the Store class.
 *
 * @param key The Store key to use for storage
 * @returns Effects for use with a Recoil atom
 */
export function atomLocalStorageEffect<T>(key: LocalStorageStoreKey<string>) {
  return (({ setSelf, onSet }) => {
    const savedValue = Store.get<T>(key);

    if (savedValue !== undefined) {
      setSelf(savedValue);
    }

    onSet((newValue: T) => {
      Store.set(key, newValue);
    });
  }) as AtomEffect<T>;
}

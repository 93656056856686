/**
 * Represents a signed transaction. Updates to the EthereumSignedTx table feed into TxHistoryRepository and TxHistoryRepo
 * channels those updates to the Transaction table that is the source of truth for transaction history.
 *
 * EthereumSignedTx is solely for keeping track of pending transactions and resubmissions.
 */
import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { Persistable } from 'cb-wallet-data/persistence/Database.interface';
import { SignedTx } from 'cb-wallet-data/stores/Transactions/interfaces/SignedTx';
import { TxState } from 'cb-wallet-data/stores/Transactions/models/TxState';
import { uint8ArrayTransformer } from 'cb-wallet-data/utils/dbTransformers';
import { Column, Entity, Index, PrimaryColumn } from '@cbhq/typeorm';

@Entity('ethereum_signed_tx')
@Index('IDX_ETH_STATE_CHAIN', ['state', 'chainId'])
@Index('IDX_ETH_STATE_CHAIN_FROM', ['state', 'chainId', 'fromAddress'])
@Index('IDX_ETH_CHAIN_FROM_NONCE', ['chainId', 'fromAddress', 'nonce'])
@Index('IDX_ETH_CHAIN_NONCE_FROM_CURR_STATE', [
  'chainId',
  'nonce',
  'fromAddress',
  'currencyCodeStr',
  'state',
])
@Index('IDX_ETH_CHAIN_NONCE_FROM_TO_STATE', [
  'chainId',
  'nonce',
  'fromAddress',
  'toAddress',
  'state',
])
@Index('IDX_TXHASH', ['txHash'])
export class EthereumSignedTxDMO {
  @PrimaryColumn()
  id!: string;

  @Column()
  fromAddress!: string;

  @Column({ nullable: true })
  toAddress?: string;

  @Column()
  nonce!: string;

  @Column({ type: 'int' })
  chainId!: number;

  @Column({ type: 'varchar', transformer: uint8ArrayTransformer })
  signedTxData!: Uint8Array;

  @Column()
  txHash!: string;

  @Column()
  weiValue!: string;

  @Column({ nullable: true })
  erc20Value?: string;

  @Column()
  blockchainStr!: string;

  @Column()
  currencyCodeStr!: string;

  @Column({ type: 'int' })
  state!: TxState;

  @Column()
  notFoundCount!: string;
}

export class EthereumSignedTx implements SignedTx, Persistable<EthereumSignedTxDMO> {
  // eslint-disable-next-line max-params
  constructor(
    readonly id: string,
    readonly fromAddress: string,
    readonly toAddress: string | undefined,
    readonly nonce: bigint,
    readonly chainId: bigint,
    readonly signedTxData: Buffer,
    readonly txHash: string,
    readonly weiValue: bigint,
    readonly erc20Value: bigint | undefined,
    readonly blockchain: Blockchain,
    readonly currencyCode: CurrencyCode,
    readonly state: TxState,
    readonly notFoundCount: bigint,
  ) {}

  get asDMO(): EthereumSignedTxDMO {
    return {
      id: this.id,
      fromAddress: this.fromAddress,
      toAddress: this.toAddress,
      nonce: this.nonce.toString(),
      chainId: Number(this.chainId),
      signedTxData: new Uint8Array(this.signedTxData),
      txHash: this.txHash,
      weiValue: this.weiValue.toString(),
      erc20Value: this.erc20Value?.toString(),
      blockchainStr: this.blockchain.rawValue,
      currencyCodeStr: this.currencyCode.rawValue,
      state: this.state,
      notFoundCount: this.notFoundCount.toString(),
    };
  }

  static fromDMO(dmo: EthereumSignedTxDMO): EthereumSignedTx {
    return new EthereumSignedTx(
      dmo.id,
      dmo.fromAddress,
      dmo.toAddress,
      BigInt(dmo.nonce),
      BigInt(dmo.chainId),
      Buffer.from(dmo.signedTxData),
      dmo.txHash,
      BigInt(dmo.weiValue),
      dmo.erc20Value ? BigInt(dmo.erc20Value) : undefined,
      new Blockchain(dmo.blockchainStr),
      new CurrencyCode(dmo.currencyCodeStr),
      dmo.state,
      BigInt(dmo.notFoundCount),
    );
  }
}

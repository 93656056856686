import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import {
  triggerCookieBannerDismissPressed,
  triggerCookiePolicyButtonPressed,
  useCookieBannerViewed,
} from 'cb-wallet-analytics/cookies';
import { Button } from 'wallet-cds-web/components/Button';
import { durations } from '@cbhq/cds-common/motion/tokens';
import { Box } from '@cbhq/cds-web/layout';
import { Link, TextLegal } from '@cbhq/cds-web/typography';

import { useCookieContext } from ':dapp/providers/CookieManagerProvider';

const BANNER_TRANSITION_TIME = durations.slow3;

export const messages = defineMessages({
  bannerLegalDescription: {
    defaultMessage:
      'We use strictly necessary cookies to enable essential functions, such as security. For more information, see our {link}.',
    description: 'Banner text informing the user that we use cookies on the website',
  },
  dismissButton: {
    defaultMessage: `Dismiss`,
    description: 'Button to dismiss the cookie banner',
  },
  cookiePolicy: {
    defaultMessage: 'Cookie Policy',
    description: 'Label for cookie policy link',
  },
});

export const CookiesBanner = memo(() => {
  const [isVisible, setIsVisible] = useState(false);
  const { handleHasBannerRendered, handleSetDismissCookie, isCookieBannerDismissed } =
    useCookieContext();
  const { formatMessage } = useIntl();

  useCookieBannerViewed();

  useEffect(
    function showBannerAfterTimeout() {
      if (!isCookieBannerDismissed) {
        setTimeout(() => setIsVisible(true), BANNER_TRANSITION_TIME);
      }
      handleHasBannerRendered();
    },
    [handleHasBannerRendered, isCookieBannerDismissed],
  );

  const handlePolicyLinkClick = useCallback(() => {
    triggerCookiePolicyButtonPressed();
  }, []);

  const handleAcceptClick = useCallback(() => {
    triggerCookieBannerDismissPressed();
    handleSetDismissCookie('dismissed', { name: 'cookies-banner' });
    setIsVisible(!isVisible);
  }, [handleSetDismissCookie, isVisible]);

  const shouldDisplay = useMemo(() => {
    return isVisible && navigator.cookieEnabled;
  }, [isVisible]);

  const boxStyle = useMemo(
    () => ({
      transition: `bottom ${BANNER_TRANSITION_TIME}ms`,
      willChange: 'bottom',
    }),
    [],
  );

  return (
    <Box
      position="fixed"
      width="100%"
      bottom={shouldDisplay ? 0 : -200}
      testID="banner-container"
      zIndex={3}
      data-testname={isVisible ? 'open-banner' : 'hidden-banner'}
      style={boxStyle}
    >
      <Box background="backgroundAlternate" justifyContent="center" width="100%" id="cookie-banner">
        <Box
          spacingVertical={3}
          spacingHorizontal={6}
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          maxWidth="800px"
        >
          <TextLegal as="p" spacingBottom={0} spacingEnd={2}>
            {formatMessage(messages.bannerLegalDescription, {
              link: (
                <Link
                  to="https://www.coinbase.com/legal/cookie"
                  onClick={handlePolicyLinkClick}
                  openInNewWindow
                  testID="policy-link"
                >
                  {formatMessage(messages.cookiePolicy)}
                </Link>
              ),
            })}
          </TextLegal>
          <Button onPress={handleAcceptClick} compact testID="dismiss-button">
            {formatMessage(messages.dismissButton)}
          </Button>
        </Box>
      </Box>
    </Box>
  );
});

CookiesBanner.displayName = 'CookiesBanner';

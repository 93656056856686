import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Image from 'next/image';
import { IconButton } from '@cbhq/cds-web/buttons/IconButton';
import { ListCell } from '@cbhq/cds-web/cells/ListCell';
import { Icon } from '@cbhq/cds-web/icons/Icon';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextHeadline } from '@cbhq/cds-web/typography';

import { useAvailableNetworks } from ':dapp/connection/hooks/useAvailableNetworks';
import { WalletProviderInfo, WalletProviderNetworkInfo } from ':dapp/connection/types';

const messages = defineMessages({
  selectNetwork: {
    defaultMessage: 'Select a network',
    description: 'Text telling the user to select a network',
  },
  multipleNetworks: {
    defaultMessage: 'This wallet supports multiple networks. Which one do you want to connect to?',
    description: 'Text telling the user that multiple networks are available',
  },
});

type ConnectionNetworkSelectorProps = {
  onBack: () => void;
  onClose: () => void;
  onConnect: (
    walletProvider: WalletProviderInfo,
    network: WalletProviderNetworkInfo,
  ) => Promise<void>;
  walletProvider: WalletProviderInfo;
};

const listStyle = {
  listStyle: 'none',
  padding: 0,
};

export function ConnectionNetworkSelector({
  walletProvider,
  onClose,
  onConnect,
  onBack,
}: ConnectionNetworkSelectorProps) {
  const { formatMessage } = useIntl();
  const handleConnect = useCallback(
    (network: WalletProviderNetworkInfo) => async () => {
      await onConnect(walletProvider, network);
    },
    [onConnect, walletProvider],
  );

  const availableNetworks = useAvailableNetworks(walletProvider);

  return (
    <VStack testID="connection-network-selector" spacingBottom={2}>
      <Box spacing={2} alignItems="center" justifyContent="space-between">
        <Box alignItems="center">
          <IconButton
            name="backArrow"
            onClick={onBack}
            testID="connection-network-back-arrow-icon"
            transparent
          />
          <TextHeadline spacingStart={2} as="h2">
            {formatMessage(messages.selectNetwork)}
          </TextHeadline>
        </Box>
        <IconButton
          transparent
          name="close"
          onClick={onClose}
          testID="connection-network-close-icon"
        />
      </Box>
      <Box spacing={1}>
        <VStack gap={1} spacing={3} borderRadius="roundedLarge" dangerouslySetBackground="#27282B">
          <Box alignItems="center">
            <Image src={walletProvider.image} height={32} width={32} alt={walletProvider.name} />
            <TextHeadline transform="capitalize" spacingStart={2} as="span">
              {walletProvider.name}
            </TextHeadline>
          </Box>
          <Box>
            <TextBody as="p" color="foregroundMuted">
              {formatMessage(messages.multipleNetworks)}
            </TextBody>
          </Box>
        </VStack>
      </Box>
      <VStack as="ul" style={listStyle}>
        {availableNetworks.map((network) => {
          return (
            <ListCell
              as="li"
              key={network.name}
              testID={`connection-network-btn-${network.name}`}
              title={
                <TextBody transform="capitalize" as="span">
                  {network.name}
                </TextBody>
              }
              onPress={handleConnect(network)}
              media={
                <Image unoptimized src={network.image} height={24} width={24} alt={network.name} />
              }
              action={
                <Box alignItems="center">
                  <Icon name="caretRight" size="s" color="foregroundMuted" />
                </Box>
              }
            />
          );
        })}
      </VStack>
    </VStack>
  );
}

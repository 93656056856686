import { parse, stringify } from 'cb-wallet-store/utils/serialization';

import { IndexedDBKeyValueStore } from '../persistence/IndexedDBKeyValueStore';

import {
  DehydratedQuery,
  PersistedClient,
  PersistedClientMetadata,
  PersistorAsync,
} from './persistQueryClient';

export const INDEXED_DB_RQ_CACHE_METADATA_KEY = 'rq_metadata';
export const INDEXED_DB_RQ_CACHE_DATA_KEY_PREFIX = 'rq_data';

function buildDataCacheKey(key: string) {
  return `${INDEXED_DB_RQ_CACHE_DATA_KEY_PREFIX}:${key}`;
}

/**
 * An implementation of the `PersistorAsync` interface that uses IndexedDB as the underlying storage mechanism.
 *
 * __NOTE:__ We explicitly define the shape of implemented methods due to an open issue in TypeScript with classes that
 * utilize the `implements` keyword (see https://github.com/microsoft/TypeScript/issues/32082).
 */
export class IndexedDBPersistor implements PersistorAsync {
  private store: IndexedDBKeyValueStore;

  constructor() {
    this.store = new IndexedDBKeyValueStore({
      dbVersion: 1,
      dbName: 'wallet_rq_cache_db',
      storeName: 'wallet_rq_cache_store',
    });
  }

  persistQuery: PersistorAsync['persistQuery'] = async (query) => {
    await this.store.upsert(buildDataCacheKey(query.queryHash), stringify(query));
  };

  removeQuery: PersistorAsync['removeQuery'] = async (queryHash) => {
    await this.store.remove(buildDataCacheKey(queryHash));
  };

  restoreClient: PersistorAsync['restoreClient'] = async () => {
    const allCacheItems = await this.store.getAll<string>();

    if (!Array.isArray(allCacheItems) || allCacheItems.length === 0) {
      return undefined;
    }

    const client: PersistedClient = {
      metadata: undefined,
      clientState: {
        mutations: [],
        queries: [],
      },
    };

    allCacheItems.forEach(function buildPersistedClient(cache) {
      const { id: cacheKey } = cache;

      if (cacheKey === INDEXED_DB_RQ_CACHE_METADATA_KEY) {
        client.metadata = parse<PersistedClientMetadata>(cache.value);
      } else {
        client.clientState.queries.push(parse<DehydratedQuery>(cache.value));
      }
    });

    return client;
  };

  removeClient: PersistorAsync['removeClient'] = async () => {
    await this.store.clearAll();
  };

  persistMetadata: PersistorAsync['persistMetadata'] = async (metadata) => {
    await this.store.upsert(INDEXED_DB_RQ_CACHE_METADATA_KEY, stringify(metadata));
  };
}

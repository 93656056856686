import { StorageArea } from 'cb-wallet-store/models/StorageArea';
import { StoreKey } from 'cb-wallet-store/models/StoreKey';

export class MemoryStorage implements StorageArea {
  private storage = new Map<string, any | undefined>();

  set<T>(key: StoreKey<T>, value: T | undefined): void {
    this.storage.set(key.name, value);
  }

  get<T>(key: StoreKey<T>): T | undefined {
    return this.storage.get(key.name);
  }

  clear(): void {
    this.storage.clear();
  }
}

import { useEffect, useMemo, useRef } from 'react';
import { Connector, reconnect } from '@wagmi/core';
import { providerLocator } from 'cb-wallet-data/ProviderLocator/ProviderLocator';
import { getConfig } from 'cb-wallet-data/scw/libs/wagmi/config';

import * as ethereum from '../ethereum';
import { WalletProviderNetwork } from '../types';

import { useEthereumAccountChangeListener } from './useEthereumAccountChangeListener';
import { getWalletProvidersConnectionManagerMap } from './useWalletProviders';

export function useSyncEthereumEventListeners() {
  const hasReconnected = useRef(false);

  const ethereumEventListener = useEthereumAccountChangeListener();

  const providers = providerLocator.providers();
  const uniqueProviders = useMemo(() => {
    return Array.from(new Set(providers));
  }, [providers]);

  const connectorManagerMap = useMemo(() => getWalletProvidersConnectionManagerMap(), []);

  // We need to stringify these to avoid the effect being called too often
  const stringifiedProviders = JSON.stringify(uniqueProviders);
  const stringifiedConnectorManagerKeys = JSON.stringify(Object.keys(connectorManagerMap));

  useEffect(() => {
    (async () => {
      const connectors = uniqueProviders
        .map((provider) => {
          const connectorManager = connectorManagerMap[provider];
          if (!connectorManager) {
            return;
          }
          const connector = connectorManager.getConnector(WalletProviderNetwork.Ethereum);
          if (!connector) {
            return;
          }
          if (!ethereum.isValidConnector(connector)) {
            return;
          }
          return { connector, provider };
        })
        .filter(Boolean) as { connector: Connector; provider: string }[];

      if (connectors.length === 0) return;
      // Only try to reconnect if we haven't already
      if (!hasReconnected.current) {
        await reconnect(getConfig(), {
          connectors: connectors.map(({ connector }) => connector),
        });
        hasReconnected.current = true;
      }

      connectors.forEach(({ connector, provider }) => {
        connector.emitter.on('change', ethereumEventListener(provider));
      });
    })();
    // Were using the stringified versions here to avoid the effect being called too often
    // eslint-disable-next-line wallet/exhaustive-deps
  }, [ethereumEventListener, stringifiedConnectorManagerKeys, stringifiedProviders]);
}

import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { USER_PROFILE_QUERY_KEY } from 'cb-wallet-data/stores/User/hooks/useUser';
import { useSetRecoilState } from 'recoil';

import { accessTokenAtom, refreshTokenAtom } from './state';

type ClearAuthTokens = () => void;

export function useClearAuthTokens(): ClearAuthTokens {
  const setAccessToken = useSetRecoilState(accessTokenAtom);
  const setRefreshToken = useSetRecoilState(refreshTokenAtom);

  const queryClient = useQueryClient();

  const clearAuthTokens = (): void => {
    setAccessToken(undefined);
    setRefreshToken(undefined);

    queryClient.invalidateQueries([USER_PROFILE_QUERY_KEY]);
  };

  return useCallback(clearAuthTokens, [queryClient, setAccessToken, setRefreshToken]);
}

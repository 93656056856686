import { ReactNode, useCallback } from 'react';
import { IntlProvider } from 'react-intl';
import { IntlErrorHandler, onIntlProviderError } from 'cb-wallet-data/errors/onIntlProviderError';
import { useRouter } from 'next/router';
import { DEFAULT_LOCALE, MessagesMap } from '@cbhq/intl';

import { RELEASE_ENVIRONMENT, ReleaseEnvironment } from ':dapp/config/env';

type AppIntlProviderProps = {
  children: ReactNode;
  messages: MessagesMap;
};

export function AppIntlProvider({ children, messages }: AppIntlProviderProps) {
  const { locale = DEFAULT_LOCALE } = useRouter();

  const onError = useCallback<IntlErrorHandler>((error) => {
    onIntlProviderError(error, RELEASE_ENVIRONMENT === ReleaseEnvironment.production);
  }, []);

  return (
    <IntlProvider
      locale={locale}
      defaultLocale={DEFAULT_LOCALE}
      onError={onError}
      messages={messages}
    >
      {children}
    </IntlProvider>
  );
}

import { defaultContext } from '@tanstack/react-query';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { relayInitializedPromise, WalletLinkRelayType } from 'cb-wallet-data/WalletLink/relay';

const QUERY_KEY = 'walletlinkrelay';

export function useWalletLinkRelay(): WalletLinkRelayType | undefined {
  const relay = useQuery({
    queryFn: async () => relayInitializedPromise,
    queryKey: [QUERY_KEY],
    context: defaultContext,
  });

  return relay.data;
}

function throwNotImplementedError() {
  throw new Error('Not implemented');
}

// @ts-expect-error We want to throw if unimplemented methods are called.
export const defaultWalletLinkRelay: WalletLinkRelayType = Object.freeze({
  walletUsername: null,
  sessionConfig: null,
  setOnSessionConfigChangedCallback: () => {},
  isLinked: undefined,
  setChainCallback: throwNotImplementedError,
  signEthereumMessage: throwNotImplementedError,
  appVersion: null,
  sendCbRequest: throwNotImplementedError,
  signAndSubmitEthereumTransaction: throwNotImplementedError,
  addOnSessionConfigChangedCallback: throwNotImplementedError,
});

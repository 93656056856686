import { AllPossibleBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
// eslint-disable-next-line no-restricted-imports
import { useActiveWalletGroupId } from 'cb-wallet-data/stores/WalletGroups/hooks/useActiveWalletGroupId';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { activePrimaryReceiveWalletsByBlockchainAndWalletGroupSelector } from 'cb-wallet-data/stores/Wallets/state';
import { useRecoilValue } from 'recoil';

/**
 * Get the active wallet for each blockchain
 *
 * @return a map of current blockchain's default mainnet to a list of wallets
 */
export function usePrimaryReceiveWallets(): Map<AllPossibleBlockchainSymbol, Wallet> {
  const activeWalletGroup = useActiveWalletGroupId();
  return useRecoilValue(
    activePrimaryReceiveWalletsByBlockchainAndWalletGroupSelector(activeWalletGroup),
  );
}

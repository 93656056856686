import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  nftApprovalsAPICallFailureEvent,
  nftApprovalsAPICallSuccessEvent,
  nftApprovalsAPICallTriggered,
  threeSecondThresholdTriggered,
  tokenApprovalsAPICallFailureEvent,
  tokenApprovalsAPICallSuccessEvent,
  tokenApprovalsAPICallTriggered,
} from 'cb-wallet-analytics/token-approvals';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import {
  CollectionApprovalItem,
  NFTApprovalItem,
  TokenApprovalItem,
} from 'cb-wallet-data/stores/TokenApprovals/types';
// eslint-disable-next-line no-restricted-imports
import { useActiveWalletGroupId } from 'cb-wallet-data/stores/WalletGroups/hooks/useActiveWalletGroupId';
import { usePrimaryReceiveAddresses } from 'cb-wallet-data/stores/Wallets/hooks/usePrimaryReceiveAddresses';
import { useWalletsByWalletGroup } from 'cb-wallet-data/stores/Wallets/hooks/useWalletsByWalletGroup';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';

import { getNFTApprovals, getTokenApprovals } from '../api/getTokenApprovals';

import { useIsTokenApprovalsEnabled } from './useIsTokenApprovalsEnabled';

const GET_TOKEN_APPROVALS_KEY = 'GET_TOKEN_APPROVALS_KEY';
const GET_NFT_APPROVALS_KEY = 'GET_NFT_APPROVALS_KEY';

export function useTokenApprovalsData() {
  const queryClient = useQueryClient();
  const isApprovalsEnabled = useIsTokenApprovalsEnabled();
  const activeWalletGroupId = useActiveWalletGroupId();
  const primaryReceiveAddress = usePrimaryReceiveAddresses(activeWalletGroupId).get('ETH')?.address;
  const primaryReceiveAddressArr = useMemo(
    () => (primaryReceiveAddress ? [primaryReceiveAddress] : []),
    [primaryReceiveAddress],
  );
  const walletsByWalletGroup = useWalletsByWalletGroup();

  // Ref to keep a track of a timeout for 3 seconds in the settings page
  const timeoutRef = useRef<NodeJS.Timeout | null>();

  const tokenApprovalsKey = [GET_TOKEN_APPROVALS_KEY, primaryReceiveAddressArr];
  const NFTApprovalsKey = [GET_NFT_APPROVALS_KEY, primaryReceiveAddressArr];
  const queryCacheTokenApproval = queryClient
    .getQueryCache()
    .find<{ approvals: TokenApprovalItem[] } | undefined>(tokenApprovalsKey);

  const queryCacheNFTApproval = queryClient.getQueryCache().find<
    | {
        itemApprovals?: NFTApprovalItem[] | undefined;
        collectionApprovals?: CollectionApprovalItem[] | undefined;
      }
    | undefined
  >(NFTApprovalsKey);

  const cachedTokenApprovalData = queryCacheTokenApproval?.state.data;
  const cachedNFTApprovalData = queryCacheNFTApproval?.state.data;

  // State for when a timeout of 3 seconds is triggered.
  const [timeoutTriggered, setTimeoutTriggered] = useState(false);
  const {
    isFetching: isTokenApprovalsLoading,
    isError: isTokenApprovalsError,
    data: freshTokenApprovalsData,
    refetch: refetchTokenApprovals,
  } = useQuery(
    [GET_TOKEN_APPROVALS_KEY, primaryReceiveAddressArr],

    async function fetchTokenApprovals() {
      tokenApprovalsAPICallTriggered();
      return getTokenApprovals(primaryReceiveAddressArr);
    },
    {
      enabled: isApprovalsEnabled && primaryReceiveAddressArr.length > 0,
      retry: false,
      cacheTime: 1000 * 60 * 60, // one hour cache time
      staleTime: 1000 * 60 * 3,
      onSuccess: (data) => tokenApprovalsAPICallSuccessEvent(data?.approvals?.length ?? 0),
      onError: tokenApprovalsAPICallFailureEvent,
    },
  );

  const {
    isFetching: isNFTApprovalsLoading,
    isError: isNFTApprovalsError,
    data: freshNFTApprovalsData,
    refetch: refetchNFTApprovals,
  } = useQuery(
    [GET_NFT_APPROVALS_KEY, primaryReceiveAddressArr],

    async function fetchNFTApprovals() {
      nftApprovalsAPICallTriggered();
      return getNFTApprovals(primaryReceiveAddressArr);
    },
    {
      enabled: isApprovalsEnabled && primaryReceiveAddressArr.length > 0,
      retry: false,
      cacheTime: 1000 * 60 * 60,
      staleTime: 1000 * 60 * 3,
      onSuccess: (data) =>
        nftApprovalsAPICallSuccessEvent(
          (data.collectionApprovals?.length || 0) + (data.itemApprovals?.length || 0),
        ),
      onError: nftApprovalsAPICallFailureEvent,
    },
  );

  const tokenApprovalsData = freshTokenApprovalsData || cachedTokenApprovalData;
  const NFTApprovalsData = freshNFTApprovalsData || cachedNFTApprovalData;

  const isOpenApprovalExperienceError = useMemo(
    () => !tokenApprovalsData && !NFTApprovalsData && isTokenApprovalsError && isNFTApprovalsError,
    [tokenApprovalsData, NFTApprovalsData, isTokenApprovalsError, isNFTApprovalsError],
  );

  const isTokenRevokeExperienceError = useMemo(
    () => !tokenApprovalsData && isTokenApprovalsError,
    [isTokenApprovalsError, tokenApprovalsData],
  );
  const isNFTRevokeExperienceError = useMemo(
    () => !NFTApprovalsData && isNFTApprovalsError,
    [NFTApprovalsData, isNFTApprovalsError],
  );

  const isLoading = useMemo(
    () => isTokenApprovalsLoading || isNFTApprovalsLoading,
    [isTokenApprovalsLoading, isNFTApprovalsLoading],
  );

  const walletIdByContractAddress = useMemo(() => {
    const walletsById = new Map<string, Wallet>();

    if (!activeWalletGroupId) {
      return walletsById;
    }

    const selectedWallets = walletsByWalletGroup[activeWalletGroupId];

    selectedWallets?.forEach((wallet: Wallet) => {
      if (wallet.contractAddress) {
        walletsById.set(wallet.contractAddress.toLowerCase(), wallet);
      }
    });

    return walletsById;
  }, [walletsByWalletGroup, activeWalletGroupId]);

  const tokenApprovalsWithTokenMetadata = useMemo(() => {
    const updatedApprovals = (tokenApprovalsData?.approvals || []).map(function decorateApproval(
      approval,
    ) {
      const wallet = walletIdByContractAddress.get(approval.contractAddress.address.toLowerCase());

      // if it has a name we'll assume it has enough metadata
      if (approval.amount.currency.name || !wallet) {
        return approval;
      }

      return {
        ...approval,
        amount: {
          ...approval.amount,
          currency: {
            ...approval.amount.currency,
            name: wallet.displayName,
          },
        },
      };
    });
    return {
      ...tokenApprovalsData,
      approvals: updatedApprovals,
    };
  }, [tokenApprovalsData, walletIdByContractAddress]);

  // state to show a warning and a number in the settings page
  const [showWarning, numberOfItems] = useMemo(() => {
    let showWarningFlag = false;
    let numberOfItemsCount = 0;
    let NFTCollectionApprovalsLength = 0;
    if (tokenApprovalsWithTokenMetadata?.approvals) {
      numberOfItemsCount = tokenApprovalsWithTokenMetadata.approvals.length;
    }

    if (NFTApprovalsData?.collectionApprovals || NFTApprovalsData?.itemApprovals) {
      const { collectionApprovals = [], itemApprovals = [] } = NFTApprovalsData;
      NFTCollectionApprovalsLength = collectionApprovals.length;
      numberOfItemsCount += itemApprovals.length + collectionApprovals.length;
    }

    showWarningFlag =
      tokenApprovalsWithTokenMetadata?.approvals?.some((item) => item.isUnlimited) ||
      NFTCollectionApprovalsLength > 0;

    return [showWarningFlag, numberOfItemsCount];
  }, [tokenApprovalsWithTokenMetadata, NFTApprovalsData]);

  const refetchData = useCallback(
    async function refetchData() {
      return Promise.all([refetchTokenApprovals(), refetchNFTApprovals()]);
    },
    [refetchTokenApprovals, refetchNFTApprovals],
  );

  // Use effect to check if the API calls take more than 3 seconds to load. If so, toggle a boolean flag.
  useEffect(
    function timerFunctionality() {
      if (isLoading) {
        timeoutRef.current = global.setTimeout(function triggerTimeout() {
          setTimeoutTriggered(true);
          threeSecondThresholdTriggered();
        }, 3000);
      } else {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
        }
        setTimeoutTriggered(false);
      }
    },
    [isLoading],
  );

  return {
    isLoading,
    isOpenApprovalExperienceError,
    isTokenRevokeExperienceError,
    isNFTRevokeExperienceError,
    timeoutTriggered,
    showWarning,
    numberOfItems,
    refetchData,
    tokenApprovalsData: tokenApprovalsWithTokenMetadata,
    NFTApprovalsData,
  };
}

import { StorageArea } from './models/StorageArea';
import { StoreKey } from './models/StoreKey';
import { StoreKind } from './models/StoreKind';
import { LocalStorage } from './storages/LocalStorage';
import { MemoryStorage } from './storages/MemoryStorage';

export class StoreClass {
  private localStorage = new LocalStorage();
  private memoryStorage = new MemoryStorage();
  private isOpen = true;

  open(): void {
    this.isOpen = true;
  }

  set<T>(key: StoreKey<T>, value: T | undefined): void {
    if (!this.isOpen) {
      return;
    }

    this.storageForKey(key).set(key, value);
  }

  get<T>(key: StoreKey<T>): T | undefined {
    return this.storageForKey(key).get(key);
  }

  has<T>(key: StoreKey<T>): boolean {
    const value = this.get(key);
    return value !== null && value !== undefined;
  }

  clear(options?: { exceptions?: StoreKey<any>[] }): void {
    this.isOpen = false;
    this.deleteAllEntries([StoreKind.LocalStorage, StoreKind.Memory], options);
  }

  private deleteAllEntries(
    kinds: StoreKind[],
    clearOptions?: { exceptions?: StoreKey<any>[] },
  ): void {
    kinds.forEach((kind) => {
      if (kind === StoreKind.LocalStorage) {
        this.localStorage.clear(clearOptions);
      }
      if (kind === StoreKind.Memory) {
        this.memoryStorage.clear();
      }
    });
  }

  private storageForKey<T>(key: StoreKey<T>): StorageArea {
    switch (key.kind) {
      case StoreKind.LocalStorage:
        return this.localStorage;
      case StoreKind.Memory:
        return this.memoryStorage;
      default:
        /* istanbul ignore next */
        throw new Error('Switch statements must be exhaustive');
    }
  }
}

export const Store = new StoreClass();

import { useCallback } from 'react';
import { AccessTokenResult } from 'cb-wallet-http/Authentication/types/AccessTokenResult';
import { useSetRecoilState } from 'recoil';

import { accessTokenAtom, refreshTokenAtom } from './state';

export type SetAuthTokens = ({ token, refreshToken }: AccessTokenResult) => void;

export function useSetAuthTokens(): SetAuthTokens {
  const setAccessToken = useSetRecoilState(accessTokenAtom);
  const setRefreshToken = useSetRecoilState(refreshTokenAtom);

  const setAuthTokens = ({ token, refreshToken }: AccessTokenResult): void => {
    setAccessToken(token);
    setRefreshToken(refreshToken);
  };

  return useCallback(setAuthTokens, [setAccessToken, setRefreshToken]);
}

import { useCallback } from 'react';
import { SolanaChain } from 'cb-wallet-data/chains/AccountBased/Solana/models/SolanaChain';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { useOverridableKillSwitch } from 'cb-wallet-data/hooks/KillSwitches/useOverridableKillSwitch';
import { getKillSwitchByPlatform } from 'cb-wallet-data/hooks/KillSwitches/utils/getKillSwitchByPlatform';
import { TxHistoryVersion } from 'cb-wallet-data/stores/Transactions/TxHistoryVersion';

/**
 * Returns the active tx history version for SOL based on the killswitches and experiements
 * that are active. The hook returns a function which accepts a chain, this was done since we need to
 * be able to determine the active tx history version for a chain inside a loop
 */
export function useGetSolTxHistoryVersion() {
  const isTxHistoryServiceKilledForSolana = useOverridableKillSwitch(
    getKillSwitchByPlatform('kill_txhistory_service_sol'),
  );
  const isTxHistoryServiceV3KilledForSolana = useOverridableKillSwitch(
    getKillSwitchByPlatform('kill_txhistory_service_sol_v3'),
  );
  const isHRTEnabled = useIsFeatureEnabled('human_readable_transactions');

  return useCallback(
    (chain?: SolanaChain): { isEnabled: boolean; apiVersion: TxHistoryVersion | undefined } => {
      let isEnabled = false;
      let apiVersion: TxHistoryVersion | undefined;

      switch (true) {
        case !chain || chain.isTestnet:
          // Fall through to return default values for isEnabled and apiVersion
          break;
        case isHRTEnabled && !isTxHistoryServiceV3KilledForSolana:
          isEnabled = true;
          apiVersion = 'v3';
          break;
        case !isTxHistoryServiceKilledForSolana:
          isEnabled = true;
          apiVersion = 'v2';
          break;
        default:
        // Fall through to return default values for isEnabled and apiVersion
      }

      return {
        isEnabled,
        apiVersion,
      };
    },
    [isHRTEnabled, isTxHistoryServiceV3KilledForSolana, isTxHistoryServiceKilledForSolana],
  );
}

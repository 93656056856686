import { getLastSyncedBlockheight } from 'cb-wallet-data/AddressHistory/utils/blockheightSyncing';
import {
  blockchainConfigurations,
  PossibleUTXOBlockchainSymbol,
} from 'cb-wallet-data/chains/blockchains';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { saveAddresses } from 'cb-wallet-data/stores/Addresses/database';
import { Address } from 'cb-wallet-data/stores/Addresses/models/Address';
import { getNetworkId } from 'cb-wallet-data/stores/Networks/utils/getNetworkId';
import { UTXOAddressConfig } from 'wallet-engine-signing/history/UTXO/types';

import { UTXOAddressHistoryListeners } from '../listeners';

import { getThrottleRequestLastUpdated } from './getThrottleRequestLastUpdated';
import { getUTXOAddressesForAccount } from './getUTXOAddressesForAccount';

type AddAddressToHistoryListenerParams = {
  addresses: Address[];
  blockchainSymbol: PossibleUTXOBlockchainSymbol;
  accountId: Account['id'];
  resetBlockheight?: boolean;
  isBalanceFetchingThrottleEnabled?: boolean;
  skipBalanceRefresh?: boolean;
  skipTransactionRefresh?: boolean;
};

export async function removeAddressesFromUTXOHistoryListener({
  accountId,
  blockchainSymbol,
}: {
  accountId: Account['id'];
  blockchainSymbol: PossibleUTXOBlockchainSymbol;
}) {
  const { existingAddresses, newAddresses } = await getUTXOAddressesForAccount({
    blockchainSymbol,
    accountId,
  });

  const addresses = [...existingAddresses, ...newAddresses];

  if (addresses.length) {
    const addressConfigs = createAddressConfigs(accountId, blockchainSymbol, addresses);
    const addressHistoryListener = UTXOAddressHistoryListeners[blockchainSymbol];

    addressHistoryListener.removeAddresses(addressConfigs);
  }
}

export async function addAddressesToHistoryListener({
  addresses,
  blockchainSymbol,
  accountId,
  resetBlockheight = false,
  isBalanceFetchingThrottleEnabled,
  skipBalanceRefresh,
  skipTransactionRefresh,
}: AddAddressToHistoryListenerParams) {
  if (!addresses.length) {
    return;
  }

  await saveAddresses(addresses);

  const addressConfigs = createAddressConfigs(accountId, blockchainSymbol, addresses);

  const lastSyncedBlockheight = getLastSyncedBlockheight({
    blockchainSymbol,
    accountId,
    getTransactionsBlockheight: false,
  });

  const throttleRequestLastUpdated = getThrottleRequestLastUpdated(blockchainSymbol, accountId);
  // 8 minutes throttle in milliseconds
  const defaultThrottleTime = 8 * 60 * 1000;
  const addressHistoryListener = UTXOAddressHistoryListeners[blockchainSymbol];

  addressHistoryListener.addAddresses(
    addressConfigs,
    {
      lastSyncedBlockheight: resetBlockheight ? 0 : lastSyncedBlockheight,
      skipBalanceRefresh,
      skipTransactionRefresh,
      throttleRequestsLastUpdate: isBalanceFetchingThrottleEnabled
        ? throttleRequestLastUpdated
        : undefined,
      throttleRequestsMilliseconds: isBalanceFetchingThrottleEnabled
        ? defaultThrottleTime
        : undefined,
    },
    isBalanceFetchingThrottleEnabled,
  );
}

export function createAddressConfigs(
  accountId: Account['id'],
  blockchainSymbol: PossibleUTXOBlockchainSymbol,
  addresses: Address[],
): UTXOAddressConfig[] {
  const { blockchain } = blockchainConfigurations[blockchainSymbol];
  return addresses.map((address) => {
    return {
      address: address.address,
      blockchainSymbol,
      testnet: false,
      lastSyncedHeight: 0,
      isNudgeEnabled: false,
      wacNetworkId: getNetworkId({ blockchain, network: undefined })!,
      context: {
        addressType: address.type.rawValue,
        isChangeAddress: address.isChangeAddress,
        accountId,
        addressIndex: address.index,
      },
    };
  });
}

import { tryGetRepository } from 'cb-wallet-data/persistence/Database';

import { Account, AccountDMO } from './models/Account';

export function repository() {
  return tryGetRepository<AccountDMO>('account');
}

/**
 * @param includeDeleted - If true, returns all accounts in the database, including those that have been soft deleted
 * @returns {Promise<Account[]>}
 */

export async function getAccounts(
  {
    includeDeleted,
  }: {
    includeDeleted: boolean;
  } = { includeDeleted: false },
): Promise<Account[]> {
  const rows = await repository().find();

  if (includeDeleted) {
    return rows.map(Account.fromDMO);
  }

  // We have to filter these in a javascript filter because adding
  // find({ isDeleted: false }) will filter out accounts that have
  // isDeleted as null/undefined specifically for IndexDB
  // (which is going to be the case for all users who upgraded from a previous version of the app)
  return rows.filter((row) => !row.isDeleted).map(Account.fromDMO);
}

export async function saveAccount(account: Account): Promise<void> {
  await repository().upsert(account.asDMO, ['id']);
}

/**
 * Delete accounts in bulk
 *
 * Takes account ids as an input parameter and deletes them from the database.
 * repository method is only invoked if the accountIds array is not empty.
 */
export async function deleteAccounts(accountIds: string[]): Promise<void> {
  if (accountIds.length > 0) {
    await repository().delete(accountIds);
  }
}

export async function updateSoftDeleteAccountStatus({
  accountId,
  isDeleted = true,
}: {
  accountId: string;
  isDeleted?: boolean;
}): Promise<void> {
  await repository().update({ id: accountId }, { isDeleted });
}

export async function getSoftDeletedAccounts(): Promise<Account[]> {
  const rows = await repository().find({
    where: {
      isDeleted: true,
    },
  });

  return rows.map(Account.fromDMO);
}

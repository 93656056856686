import { triggerOpenDatabaseStart, triggerOpenDatabaseSuccess } from 'cb-wallet-analytics/app-load';
import { defaultConnectionOptions, openDatabase } from 'cb-wallet-data/persistence/Database';

/**
 * Opens database and logs before and after
 */
export async function openDb() {
  triggerOpenDatabaseStart(defaultConnectionOptions.version);
  await openDatabase();
  triggerOpenDatabaseSuccess(defaultConnectionOptions.version);
}

import { useMemo } from 'react';
import { useWalletLinkSessionConfig } from 'cb-wallet-data/stores/WalletLink/useWalletLinkSessionConfig';

import { useUser } from './useUser';

export function useUserId() {
  const sessionConfig = useWalletLinkSessionConfig();
  const user = useUser();

  const userId = useMemo(() => {
    return user?.id?.toString() ?? sessionConfig?.webhookId ?? '';
  }, [user, sessionConfig]);

  return userId;
}

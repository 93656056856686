import {
  cloneElement,
  Component,
  ErrorInfo,
  isValidElement,
  memo,
  ReactElement,
  ReactNode,
} from 'react';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import {
  cbReportError,
  ErrorContext,
  ErrorData,
  shouldReport,
} from 'cb-wallet-data/errors/reportError';

export type FallbackProps = {
  error?: Error;
  reset?: () => void;
};

export type OnErrorInfo = {
  error: Error;
  errorInfo: ErrorInfo;
  errorHash: string | undefined;
};

export type ErrorBoundaryFallback = React.ReactElement<FallbackProps> | null;

export type ErrorBoundaryProps = {
  // You are required to provide a context so that we can group errors in bugsnag
  context?: ErrorContext;
  children: ReactNode;
  onError?: (errorData: OnErrorInfo) => void;
  onReset?: () => void;
  metadata?: ErrorData['metadata'];
  fallback?: ErrorBoundaryFallback | React.ReactNode;
  renderFallback?: (
    props: FallbackProps,
  ) => ReactElement<unknown, string | typeof Component> | null;
};

type State = {
  error: Error | null;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, State> {
  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  state: State = {
    error: null,
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { onError, context, metadata } = this.props;

    try {
      let errorHash: string | undefined;
      if (shouldReport(error)) {
        const result = cbReportError({
          error,
          errorInfo,
          metadata,
          context: context ?? 'component_error',
          severity: 'error',
          isHandled: false,
        });
        errorHash = result.errorHash;
      }
      if (onError) onError({ error, errorInfo, errorHash });
    } catch (reportingError) {
      // eslint-disable-next-line no-console
      console.error('Could not report error:', reportingError);
    }
  }

  onReset = () => {
    this.props.onReset?.();
    this.setState({
      error: null,
    });
  };

  render() {
    const { error } = this.state;
    const { children, fallback, renderFallback } = this.props;
    if (!error) return children;

    if (isValidElement(fallback)) {
      return cloneElement<any>(fallback, {
        error,
      });
    }
    if (typeof renderFallback === 'function') {
      return renderFallback({ error, reset: this.onReset });
    }

    return null;
  }
}

/**
 * `ErrorBoundary` component catches errors in its children, reports them and renders a fallback UI.
 */
const QueryResetWrappedBoundary = memo(function WrappedErrorBoundary(props: ErrorBoundaryProps) {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => {
        return <ErrorBoundary {...props} onReset={reset} />;
      }}
    </QueryErrorResetBoundary>
  );
});

export { QueryResetWrappedBoundary as ErrorBoundary };

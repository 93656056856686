import { useMemo } from 'react';
import { useHasAccountsAndWalletGroups } from 'cb-wallet-data/hooks/initialization/useHasAccountsAndWalletGroups';
import { useIsGlobalSwapEligible } from 'cb-wallet-data/hooks/Swap/useIsGlobalSwapEligible/useIsGlobalSwapEligible';

import { useIsFeatureEnabledForScw } from '../Scw/useIsFeatureEnabledForScw';

export function useIsGlobalSwapEnabled() {
  const hasAccountsAndWalletGroups = useHasAccountsAndWalletGroups();
  const isGlobalSwapEligible = useIsGlobalSwapEligible();
  const isSCWEnabled = useIsFeatureEnabledForScw('swap');

  return useMemo(() => {
    return isGlobalSwapEligible && hasAccountsAndWalletGroups && isSCWEnabled;
  }, [hasAccountsAndWalletGroups, isGlobalSwapEligible, isSCWEnabled]);
}

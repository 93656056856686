import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';

type GetNetworkIdProps = {
  network: Network | undefined;
  blockchain: Blockchain | undefined;
};

export function getNetworkId({ network, blockchain }: GetNetworkIdProps) {
  if (!network) {
    return undefined;
  }
  // evms will have associated wacNetworkId
  if (network.asChain()) {
    return network.asChain()?.wacNetworkId;
  }

  if (!blockchain) {
    return undefined;
  }
  // non evm's can be hardcoded based on their chain symbol
  switch (blockchain.rawValue) {
    case 'BTC':
      return 'networks/bitcoin-mainnet';
    case 'DOGE':
      return 'networks/dogecoin-mainnet';
    case 'LTC':
      return 'networks/litecoin-mainnet';
    case 'SOL':
      return 'networks/solana-mainnet';
    default:
      return undefined;
  }
}

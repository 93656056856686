import { defineMessages, useIntl } from 'react-intl';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import compact from 'lodash/compact';
import { NavigationIconName } from '@cbhq/cds-web';

import { AppRoutesEnum, RoutesEnum } from ':dapp/utils/RoutesEnum';

const messages = defineMessages({
  ocsLabel: {
    defaultMessage: 'Onchain Summer',
    description: 'Tab button label to navigate to the Onchain Summer page',
  },
  assetsLabel: {
    defaultMessage: 'Assets',
    description: 'Tab button label to navigate to the Assets tab',
  },
  appsLabel: {
    defaultMessage: 'Apps',
    description: 'Tab button label to navigate to the Apps tab',
  },
  settingsLabel: {
    defaultMessage: 'Settings',
    description: 'Tab button label to navigate to the Settings tab',
  },
});

type BottomNavbarItemType = {
  title: string;
  route: AppRoutesEnum;
  icon: NavigationIconName;
  triggerAnalyticsEventCallback?: () => void;
};

export function useBottomNavbarConfig() {
  const { formatMessage } = useIntl();
  const isDappPageEnabled = useIsFeatureEnabled('dapps_page');
  const isOCSEnabled = useIsFeatureEnabled('ocs_pages');

  const bottomNavbarItems: BottomNavbarItemType[] = compact([
    isOCSEnabled
      ? {
          title: formatMessage(messages.ocsLabel),
          icon: 'light',
          route: RoutesEnum.OCS,
          base: RoutesEnum.OCS,
        }
      : null,
    { title: formatMessage(messages.assetsLabel), icon: 'chartPie', route: RoutesEnum.ASSETS },
    isDappPageEnabled
      ? {
          title: formatMessage(messages.appsLabel),
          icon: 'planet',
          route: RoutesEnum.DAPPS,
        }
      : null,
  ]);
  return bottomNavbarItems;
}

import { useEffect, useMemo } from 'react';

import { featureLogger } from '../classes/FeatureLogger';
import { featureConfigs, FeatureName } from '../featureConfigs';
import { IsEligibleSuspenseQuery } from '../types';

import { useIsFeatureEnabledForAccountTypes } from './useIsFeatureEnabledForAccountTypes';
import { useIsFeatureEnabledForExperiments } from './useIsFeatureEnabledForExperiments';
import { useIsFeatureEnabledForKillSwitchName } from './useIsFeatureEnabledForKillSwitchName';
import { useIsFeatureEnabledForModes } from './useIsFeatureEnabledForModes';
import { useIsFeatureEnabledForSuspenseQuery } from './useIsFeatureEnabledForSuspenseQuery';

export type IsFeatureEnabledOptions = {
  /**
   * forceDisable
   *
   * Use this option to disable a feature when it is disabled due to external
   * factors outside of the Feature Config.
   *
   * If forceDisable is true, it will:
   * - skipTracking for any experiments
   * - return false from useIsFeatureEnabled
   *
   * Possible use cases:
   * - When a feature is disabled due to another condition, pass forceDisable: true.
   * - When a feature is only enabled on the 2nd wallet group, and it's the first
   *  wallet group, pass forceDisable: true.
   */
  forceDisable?: boolean;

  /**
   * forceSkipTrackingForExperiment
   *
   * NOTE This should be rarely used.
   *
   * Use this option when you only want to use the experiment assignment and
   * skip tracking.
   */
  forceSkipTrackingForExperiment?: boolean;

  /**
   * experimentTreatmentGroups
   *
   * Defaults to ['treatment']
   *
   * Use this option to override the default treatment group for the feature's experiment.
   * This is used to check if the feature is enabled for a given treatment group and is primarily
   * used for multi variant experiments.
   */
  experimentTreatmentGroups?: string[];

  /**
   * isEligibleFn
   *
   * @property {Function} queryFn - An async function which returns boolean determining if the feature should be enabled
   * @property {QueryKey} queryKey - The key used to identify and cache the query.
   * NOTE
   * - Please handle any runtime errors within queryFn
   * - Make sure to include dependencies of your queryFn as queryKey
   * - Errors inside this async function are caught automatically and sent to Bugsnag. If you want custom error handling / reporting, please handle the errors yourself inside of queryFn
   *
   * Use Cases:
   * - When there is a BE eligibility endpoint
   */
  isEligibleSuspenseQuery?: IsEligibleSuspenseQuery;
};

type IsFeatureEnabledOptionsWithRequiredFields = Required<
  Pick<
    IsFeatureEnabledOptions,
    'forceDisable' | 'forceSkipTrackingForExperiment' | 'experimentTreatmentGroups'
  >
> &
  Partial<Pick<IsFeatureEnabledOptions, 'isEligibleSuspenseQuery'>>;

function determineOptionsToUse(
  options?: IsFeatureEnabledOptions,
): IsFeatureEnabledOptionsWithRequiredFields {
  return {
    forceDisable: Boolean(options?.forceDisable),
    forceSkipTrackingForExperiment: Boolean(options?.forceSkipTrackingForExperiment),
    experimentTreatmentGroups: options?.experimentTreatmentGroups ?? ['treatment'],
    isEligibleSuspenseQuery: options?.isEligibleSuspenseQuery,
  };
}

/**
 * useIsFeatureEnabled
 *
 * Use this hook in conjunction with the Feature Config.
 *
 * Instructions
 * 1. Create a featureName.
 * 2. Create a featureConfig keyed by that featureName.
 * 3. Use this hook to reference that feature config.
 */
export function useIsFeatureEnabled(
  featureName: FeatureName,
  options?: IsFeatureEnabledOptions,
): boolean {
  const {
    forceDisable,
    forceSkipTrackingForExperiment,
    experimentTreatmentGroups,
    isEligibleSuspenseQuery: isEligibleFn,
  } = determineOptionsToUse(options);
  const featureConfig = featureConfigs[featureName];

  const isEnabledForAccountTypes = useIsFeatureEnabledForAccountTypes(featureConfig.accountTypes);
  const isEnabledForModes = useIsFeatureEnabledForModes(featureConfig.modes);

  const isEnabledForKillSwitchName = useIsFeatureEnabledForKillSwitchName(
    featureConfig.killSwitchName,
  );

  const isEnabledForAccountTypesAndKillSwitchName =
    isEnabledForAccountTypes && isEnabledForKillSwitchName;

  const isEnabledForIsEligibleQuery = useIsFeatureEnabledForSuspenseQuery({
    featureName,
    isEligibleSuspenseQuery: isEligibleFn,
    skipIsEligibleSuspenseQuery: !isEnabledForAccountTypesAndKillSwitchName,
  });

  const hasAllConditionsBesidesExperimentsBeenMet =
    isEnabledForAccountTypes &&
    isEnabledForKillSwitchName &&
    isEnabledForIsEligibleQuery &&
    isEnabledForModes;

  const isEnabledForExperiments = useIsFeatureEnabledForExperiments({
    featureName,
    // If forceDisable or forceSkipTrackingForExperiment is true, it pass it through.
    //
    // Else, if not all conditions besides experiments have been met, it will
    // will also skip tracking so that it doesn't get tracked as exposed.
    mustSkipTracking:
      forceDisable || !hasAllConditionsBesidesExperimentsBeenMet || forceSkipTrackingForExperiment,
    experimentTreatmentGroups,
  });

  const isEnabled = useMemo(() => {
    if (forceDisable) {
      return false;
    }

    return hasAllConditionsBesidesExperimentsBeenMet && isEnabledForExperiments;
  }, [forceDisable, hasAllConditionsBesidesExperimentsBeenMet, isEnabledForExperiments]);

  // whenever the value changes, emit a log for observability
  useEffect(() => {
    featureLogger.logIsFeatureEnabled({ featureName, isEnabled });
  }, [featureName, isEnabled]);

  return isEnabled;
}

import { CbKillSwitch } from 'cb-wallet-data/killswitches/killswitches';
import { getPlatformName } from 'cb-wallet-metadata/metadata';
import { PlatformName } from '@cbhq/client-analytics';

/**
 * In order to use this function, you must configure your killswitch names to be like so:
 * - kill_thing_ios
 * - kill_thing_android
 * - kill_thing_extension
 * - kill_thing_web
 *
 * @param killSwitchName - the killswitch name without the platform suffix
 * @returns a killswitch name with the platform suffix based on the user's platform
 */
export function getKillSwitchForPlatform(killSwitchName: string | null): CbKillSwitch | null {
  if (killSwitchName === null) {
    return null;
  }

  const platform = getPlatformName();
  if (platform === PlatformName.extension) {
    return `${killSwitchName}_extension` as CbKillSwitch;
  }

  if (platform === PlatformName.ios) {
    return `${killSwitchName}_ios` as CbKillSwitch;
  }

  if (platform === PlatformName.android) {
    return `${killSwitchName}_android` as CbKillSwitch;
  }

  if (platform === PlatformName.web) {
    return `${killSwitchName}_web` as CbKillSwitch;
  }

  return killSwitchName as CbKillSwitch;
}

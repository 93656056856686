import memoize from 'lodash/memoize';

import {
  TxOrUserOpMetadataKey,
  TxOrUserOpMetadataKind,
  TxOrUserOpMetadataValue,
} from './TxOrUserOpMetadataKey';

function cacheKeyResolver(rawValues: string[]) {
  return rawValues.sort().join(',');
}

/**
 * We memoize this as it is called quite often for wallets with lots of transactions,
 * but often with only a small number of rawValues.
 */
const createTxOrUserOpMetadataMemoized = memoize(function createTxOrUserOpMetadata(
  rawValues: string[],
) {
  return new TxOrUserOpMetadata(
    new Map(
      rawValues.map(function mapValuesToTxOrUserOpMetadataKind(
        entry,
      ): [TxOrUserOpMetadataKey, TxOrUserOpMetadataValue] {
        const pieces = entry.split('//', 2);

        const key = TxOrUserOpMetadataKey.create(pieces[0]);
        const valueString = pieces[1];
        let value: TxOrUserOpMetadataValue;
        switch (key.kind) {
          case TxOrUserOpMetadataKind.string:
            value = valueString;
            break;
          case TxOrUserOpMetadataKind.bigint:
            value = BigInt(valueString);
            break;
          case TxOrUserOpMetadataKind.boolean:
            value = JSON.parse(valueString);
            break;
          case TxOrUserOpMetadataKind.number:
            value = parseInt(valueString, 10);
            break;
          default:
            throw new Error(`Invalid TxOrUserOpMetadataKey.kind: ${key.kind}`);
        }

        return [key, value];
      }),
    ),
  );
},
cacheKeyResolver);

export class TxOrUserOpMetadata {
  private readonly metadataMap: Map<string, TxOrUserOpMetadataValue>;

  constructor(map: Map<TxOrUserOpMetadataKey, TxOrUserOpMetadataValue> = new Map()) {
    this.metadataMap = new Map<string, TxOrUserOpMetadataValue>(
      Array.from(map.entries()).map(([key, value]) => {
        return [key.rawValue, value];
      }),
    );
  }

  get(key: TxOrUserOpMetadataKey): TxOrUserOpMetadataValue | undefined {
    return this.metadataMap.get(key.rawValue);
  }

  has(key: TxOrUserOpMetadataKey): boolean {
    return this.metadataMap.has(key.rawValue);
  }

  get rawValue(): string[] {
    return Array.from(this.metadataMap.entries()).map(([key, value]) =>
      [key, value.toString()].join('//'),
    );
  }

  get rawMap(): Map<string, TxOrUserOpMetadataValue> {
    return this.metadataMap;
  }

  static isEqual(firstMetadata: TxOrUserOpMetadata, secondMetadata: TxOrUserOpMetadata): boolean {
    const firstMap = firstMetadata.rawMap;
    const secondMap = secondMetadata.rawMap;

    return (
      firstMap.size === secondMap.size &&
      Array.from(firstMap.entries()).every(
        // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
        // eslint-disable-next-line wallet/no-anonymous-params
        ([key, value]) => {
          if (typeof value === 'bigint') {
            return value === (secondMap.get(key) as bigint);
          }
          return secondMap.get(key) === value;
        },
      )
    );
  }

  static create(rawValues: string[]): TxOrUserOpMetadata {
    return createTxOrUserOpMetadataMemoized(rawValues);
  }

  toMutableMap(): Map<TxOrUserOpMetadataKey, TxOrUserOpMetadataValue> {
    return new Map<TxOrUserOpMetadataKey, TxOrUserOpMetadataValue>(
      Array.from(this.metadataMap.entries()).map(([key, value]) => {
        return [TxOrUserOpMetadataKey.create(key), value];
      }),
    );
  }

  mergeMetadata(txMetadata: TxOrUserOpMetadata) {
    const mergedMap = new Map(this.toMutableMap());
    for (const [key, value] of txMetadata.toMutableMap().entries()) {
      mergedMap.set(key, value);
    }
    return new TxOrUserOpMetadata(mergedMap);
  }
}

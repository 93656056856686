import { CB_WALLET_API_URL, isNonExtensionUiTest } from 'cb-wallet-env/env';
import { ethProductionUrls } from 'cb-wallet-env/productionUrls';

export type ChainUrlGroup = {
  blockExplorerUrl: string;
  rpcUrl: string;
  etherscanCompatibleTxHistoryApi: string;
};

let ethInternalUrls:
  | Record<'COINNET_MAINNET' | 'BASENET_MAINNET', ChainUrlGroup>
  | Record<string, never> = {};

// have to use process.env directly for webpack to remove from prod build
// eslint-disable-next-line no-process-env
if (process.env.NODE_ENV === 'development') {
  // keep internal URLs private
  ethInternalUrls = {
    COINNET_MAINNET: {
      blockExplorerUrl: 'https://coinscan.cbhq.net',
      rpcUrl: 'https://coinnet.cbhq.net',
      etherscanCompatibleTxHistoryApi: 'https://coinscan.cbhq.net',
    },
    BASENET_MAINNET: {
      blockExplorerUrl: 'https://basescan.cbhq.net',
      rpcUrl: 'https://basenet.cbhq.net',
      etherscanCompatibleTxHistoryApi: 'https://basescan.cbhq.net',
    },
  };
}

const allEthUrls = {
  ...ethProductionUrls,
  ...ethInternalUrls,
};

const e2eUrls = Object.keys(allEthUrls).reduce(
  (map, key) => ({
    ...map,
    [key]: {
      blockExplorerUrl: `${CB_WALLET_API_URL}/mock-block-explorer`,
      rpcUrl: `${CB_WALLET_API_URL}/mock-chain-rpc`,
      etherscanCompatibleTxHistoryApi: `${CB_WALLET_API_URL}/mock-etherscan-${key}`,
    },
  }),
  {},
) as any;

export const ethereumUrls = isNonExtensionUiTest ? e2eUrls : allEthUrls;

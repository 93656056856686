import {
  allDeprecatedBlockchains,
  AllPossibleBlockchainSymbol,
  blockchainConfigurations,
  getAllCurrentSupportedBlockchainSymbols,
} from 'cb-wallet-data/chains/blockchains';
import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { NetworkSettingItem } from 'cb-wallet-data/stores/Networks/models/NetworkSetting';

import { generateNetworkHasBalanceMapKey } from './generateNetworkHasBalanceMapKey';

/**
 * Get all blockchains that have balance refreshes
 *  and returns an array of associated network raw values
 *  to track whether there is balance on the network or not.
 *
 * It's used as balance loading state indicator that
 *  only triggers isZeroBalanceWallet logic when all initial network has balance keys are set
 *  and the values are not used in determine isZeroBalanceWallet = true/false
 */
export function getNetworkHasBalanceMapLoadingIndicatorKeys(
  isNetworkAllowed: (network: Network) => boolean,
): string[] {
  const allCurrentSupportedBlockchainSymbols = getAllCurrentSupportedBlockchainSymbols();
  const supportedBlockchainSymbols = allCurrentSupportedBlockchainSymbols.filter(
    function filterAppDeprecatedBlockChains(symbol) {
      return !allDeprecatedBlockchains.includes(symbol);
    },
  );

  const allSupportedNetworkValues = supportedBlockchainSymbols
    .map((blockchainSymbol) => blockchainConfigurations[blockchainSymbol])
    .reduce(function getBlockChainsFromConfigs(
      blockchains: { blockchain: Blockchain; mainnet: NetworkSettingItem }[],
      config,
    ) {
      const mainnetAndBlockchain = config.networkSetting.mainnets
        .filter((mainnet) => isNetworkAllowed(mainnet.network))
        .map((mainnet) => ({
          mainnet,
          blockchain: config.blockchain,
        }));

      for (const mainnetAndBlockchainItem of mainnetAndBlockchain) {
        blockchains.push(mainnetAndBlockchainItem);
      }

      return blockchains;
    },
    [])
    .map(function getNetworksFromBlockchains(mainnetAndBlockchain) {
      return generateNetworkHasBalanceMapKey({
        blockchainSymbol: mainnetAndBlockchain.blockchain.rawValue as AllPossibleBlockchainSymbol,
        network: mainnetAndBlockchain.mainnet.network,
      });
    });

  return allSupportedNetworkValues;
}

import { ActionType, ComponentType } from '@cbhq/client-analytics';

import { logDataEvent } from '../utils/log';

export function triggerRepairNetworksStart(
  numNetworksToDelete: number,
  numNetworksToImport: boolean,
) {
  logDataEvent('dapp.repairing_networks.start', {
    data: {
      numNetworksToDelete,
      numNetworksToImport,
    },
    action: ActionType.unknown,
    componentType: ComponentType.unknown,
  });
}

export function triggerRepairNetworksSuccess(
  hadNetworksToDelete: number,
  hadNetworksToImport: number,
) {
  logDataEvent('dapp.repairing_networks.success', {
    data: {
      hadNetworksToDelete,
      hadNetworksToImport,
    },
    action: ActionType.unknown,
    componentType: ComponentType.unknown,
  });
}

export function triggerRepairNetworksFail() {
  logDataEvent('dapp.repairing_networks.fail', {
    action: ActionType.unknown,
    componentType: ComponentType.unknown,
  });
}

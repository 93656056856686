import { Persistable } from 'cb-wallet-data/persistence/Database.interface';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { CombinedThemeColorPreference } from 'cb-wallet-data/stores/ThemeColors/themeColorConfigs';
import isBoolean from 'lodash/isBoolean';
import { Column, Entity, Index, PrimaryColumn } from '@cbhq/typeorm';

import { assertNumber } from '../utils/assertNumber';
import { assertThemeColor } from '../utils/assertThemeColor';

function transformValueToThemeColor(value: unknown): CombinedThemeColorPreference | undefined {
  try {
    assertThemeColor(value);
    return value;
  } catch (e) {
    // will return undefined
  }
}

function transformValueToNumber(value: unknown): number | undefined {
  try {
    assertNumber(value);
    return value;
  } catch (e) {
    return undefined;
  }
}

@Entity('wallet_group')
@Index('IDX_WALLET_GROUP', ['accountId', 'walletIndex', 'hardwareDerivationPath', 'isHidden'])
@Index('IDX_WALLET_GROUP_CreatedAt', ['createdAt'])
@Index('IDX_WALLET_GROUP_Theme', ['themeColor'])
export class WalletGroupDMO {
  @PrimaryColumn()
  id!: string;

  @Column('datetime')
  createdAt!: Date;

  @Column()
  accountId!: string;

  @Column()
  walletIndex!: string;

  @Column({ nullable: true })
  hardwareDerivationPath?: string;

  @Column()
  nickname!: string;

  @Column()
  isHidden!: boolean;

  @Column({ nullable: true })
  themeColor?: string; // ThemeColorPreference

  @Column({ nullable: true })
  avatarIndex?: number; // Default avatar index
}

export type WalletGroupConstructorOptions = {
  id?: WalletGroup['id'];
  createdAt?: Date;
  accountId: Account['id'];
  walletIndex: bigint;
  hardwareDerivationPath?: string;
  nickname?: string;
  isHidden?: boolean;
  themeColor?: CombinedThemeColorPreference;
  avatarIndex?: number;
};

type WalletGroupIdOptions = {
  accountId: Account['id'];
  walletIndex?: bigint;
  hardwareDerivationPath?: string;
};

export class WalletGroup implements Persistable<WalletGroupDMO> {
  /** Wallet Group ID. `accountId/walletIndex` or `accountId/hardwareDerivationPath` */
  id: string;
  createdAt: Date;
  accountId: Account['id'];
  walletIndex: bigint;
  nickname: string;
  hardwareDerivationPath?: string;
  isHidden: boolean;
  themeColor?: CombinedThemeColorPreference;
  avatarIndex?: number;

  get asDMO(): WalletGroupDMO {
    return {
      id: this.id,
      createdAt: this.createdAt,
      accountId: this.accountId,
      walletIndex: this.walletIndex.toString(),
      nickname: this.nickname,
      hardwareDerivationPath: this.hardwareDerivationPath,
      isHidden: this.isHidden,
      themeColor: this.themeColor,
      avatarIndex: this.avatarIndex,
    };
  }

  static fromDMO(options: WalletGroupDMO): WalletGroup {
    const { themeColor, walletIndex, avatarIndex } = options;

    return new WalletGroup({
      ...options,
      walletIndex: BigInt(walletIndex),
      themeColor: transformValueToThemeColor(themeColor),
      avatarIndex: transformValueToNumber(avatarIndex),
    });
  }

  static generateId({ accountId, walletIndex, hardwareDerivationPath }: WalletGroupIdOptions) {
    if (hardwareDerivationPath) {
      return [accountId, hardwareDerivationPath].join('/');
    }
    return [accountId, walletIndex].join('/');
  }

  static propsFromId(walletGroupId: string) {
    const lastSlashIndex = walletGroupId.lastIndexOf('/');
    const accountId = walletGroupId.slice(0, lastSlashIndex);
    const walletIndexOrHardwareDerivationPath = walletGroupId.slice(lastSlashIndex + 1);
    return {
      accountId,
      walletIndexOrHardwareDerivationPath,
    };
  }

  constructor({
    id,
    createdAt,
    accountId,
    walletIndex,
    hardwareDerivationPath,
    nickname,
    isHidden,
    themeColor,
    avatarIndex,
  }: WalletGroupConstructorOptions) {
    this.walletIndex = BigInt(walletIndex);
    this.id =
      id ||
      WalletGroup.generateId({ accountId, walletIndex: this.walletIndex, hardwareDerivationPath });
    this.createdAt = createdAt || new Date();
    this.accountId = accountId;
    this.hardwareDerivationPath = hardwareDerivationPath;
    this.nickname = nickname || '';
    this.isHidden = isBoolean(isHidden) ? isHidden : false;
    this.themeColor = themeColor;
    this.avatarIndex = avatarIndex;
  }
}

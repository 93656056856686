import { memo, useMemo } from 'react';
import { black, blue, white } from '@cbhq/cds-common/hooks/useLogo';
import { useSpectrumConditional } from '@cbhq/cds-common/hooks/useSpectrumConditional';
import { usePalette } from '@cbhq/cds-web/hooks/usePalette';
export const iconStyles = "isxjajm";
export type WalletLogoProps = {
  /**
   * logo size
   *
   * @default 32
   */
  size?: 16 | 24 | 32 | 100 | 134;
  foreground?: boolean;
  /**
   * Sets the gradient border around the logo, only at size 134px
   */
  isGradientBorder?: boolean;
  useThemeColor?: boolean;
};
const logoData = {
  16: {
    viewBox: '0 0 16 16',
    path: 'M0 8C0 12.4178 3.58222 16 8 16C12.4178 16 16 12.4178 16 8C16 3.58222 12.4178 0 8 0C3.58222 0 0 3.58222 0 8ZM5.95556 5.42222C5.66 5.42222 5.42222 5.66 5.42222 5.95556V10.0444C5.42222 10.34 5.66 10.5778 5.95556 10.5778H10.0444C10.34 10.5778 10.5778 10.34 10.5778 10.0444V5.95556C10.5778 5.66 10.34 5.42222 10.0444 5.42222H5.95556Z',
    gradientPath: null
  },
  24: {
    viewBox: '0 0 24 24',
    path: 'M0 12C0 18.6267 5.37333 24 12 24C18.6267 24 24 18.6267 24 12C24 5.37333 18.6267 0 12 0C5.37333 0 0 5.37333 0 12ZM8.93333 8.13333C8.49 8.13333 8.13333 8.49 8.13333 8.93333V15.0667C8.13333 15.51 8.49 15.8667 8.93333 15.8667H15.0667C15.51 15.8667 15.8667 15.51 15.8667 15.0667V8.93333C15.8667 8.49 15.51 8.13333 15.0667 8.13333H8.93333Z',
    gradientPath: null
  },
  32: {
    viewBox: '0 0 32 32',
    path: 'M0 16C0 24.8356 7.16444 32 16 32C24.8356 32 32 24.8356 32 16C32 7.16444 24.8356 0 16 0C7.16444 0 0 7.16444 0 16ZM11.9111 10.8444C11.32 10.8444 10.8444 11.32 10.8444 11.9111V20.0889C10.8444 20.68 11.32 21.1556 11.9111 21.1556H20.0889C20.68 21.1556 21.1556 20.68 21.1556 20.0889V11.9111C21.1556 11.32 20.68 10.8444 20.0889 10.8444H11.9111Z',
    gradientPath: null
  },
  100: {
    viewBox: '0 0 100 100',
    path: 'M0 50C0 77.6111 22.3889 100 50 100C77.6111 100 100 77.6111 100 50C100 22.3889 77.6111 0 50 0C22.3889 0 0 22.3889 0 50ZM37.2222 33.8889C35.375 33.8889 33.8889 35.375 33.8889 37.2222V62.7778C33.8889 64.625 35.375 66.1111 37.2222 66.1111H62.7778C64.625 66.1111 66.1111 64.625 66.1111 62.7778V37.2222C66.1111 35.375 64.625 33.8889 62.7778 33.8889H37.2222Z',
    gradientPath: null
  },
  134: {
    viewBox: '0 0 134 134',
    path: 'M19 67C19 93.5067 40.4933 115 67 115C93.5067 115 115 93.5067 115 67C115 40.4933 93.5067 19 67 19C40.4933 19 19 40.4933 19 67ZM54.7333 51.5333C52.96 51.5333 51.5333 52.96 51.5333 54.7333V79.2667C51.5333 81.04 52.96 82.4667 54.7333 82.4667H79.2667C81.04 82.4667 82.4667 81.04 82.4667 79.2667V54.7333C82.4667 52.96 81.04 51.5333 79.2667 51.5333H54.7333Z',
    gradientPath: null
  }
};
const title = 'Coinbase Wallet Logo';

/**
 * Adapted from https://github.cbhq.net/frontend/cds/blob/master/packages/web/icons/iconStyles.ts
 */
export const WalletLogo = memo(({
  size = 32,
  foreground,
  useThemeColor
}: WalletLogoProps) => {
  const spectrum = useSpectrumConditional({
    light: foreground ? black : blue,
    dark: white
  });
  const {
    primary
  } = usePalette();
  const fillColor = useMemo(() => useThemeColor ? primary : spectrum, [useThemeColor, primary, spectrum]);
  const {
    viewBox,
    path
  } = logoData[size];
  return <svg xmlns="http://www.w3.org/2000/svg" role="img" aria-label={title} viewBox={viewBox} width={size} height={size} className={iconStyles} data-testid="wallet-logo" fill="none">
      <title>{title}</title>
      <path d={path} fill={fillColor} fillRule="evenodd" clipRule="evenodd" />
    </svg>;
});
WalletLogo.displayName = 'WalletLogo';

require("./WalletLogo.linaria.module.css!=!../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./WalletLogo.tsx");
import { DataMigration } from 'cb-wallet-data/DataMigrationRunner/types';

const initHotfixVersion: DataMigration = {
  up: async () => {
    // eslint-disable-next-line no-console
    console.log('Migration: initHotfixVersion');
  },
};

export default initHotfixVersion;

// eslint-disable-next-line no-restricted-imports
import BN from 'bn.js'; // those functions are used in low level chain operations
import { Decimal } from 'decimal.js';

export function fromHex(hex: string): bigint | undefined {
  try {
    // BigInt constructor throws error on invalid values, instead
    // we want to return undefined
    return BigInt(hex.startsWith('0x') ? hex : `0x${hex}`);
  } catch (err) {
    return undefined;
  }
}

export function bigIntToDecimal(value: bigint): Decimal {
  return new Decimal(value.toString(10));
}

export function safebigIntToDecimal(bi?: bigint): Decimal | undefined {
  if (bi === undefined) return undefined;

  return bigIntToDecimal(bi);
}

export function bigIntToBN(value: bigint): BN {
  return new BN(value.toString());
}

export function BNToBigint(value: BN): bigint {
  return BigInt(value.toString(10));
}

export function BNToDecimal(value: BN): Decimal {
  return new Decimal(value.toString(10));
}

/**
 * Returns absolute value for BigInt numbers
 *
 * BigInt(-1n) -> 1n;
 */
export function BigIntAbs(a: bigint) {
  return a >= 0n ? a : a * -1n;
}

/**
 * Returns the greatest value of the arguments passed in
 */
export function bigIntMax(...args: bigint[]) {
  return args.reduce((m, e) => (e > m ? e : m));
}

import { useCallback, useEffect, useMemo } from 'react';
import { Store } from 'cb-wallet-store/Store';
import { useModal } from '@cbhq/cds-common/overlays/useModal';

import { ExternalLinkModal, StoreKeys_externalLinkWarningDisabled } from './ExternalLinkModal';

/* ALLOWED_HOSTNAMES is a list of trusted host names to skip showing the modal for.
Sometimes we hard-code links to prod (for things like terms of service adding
that link here to make testing in other environments less confusing */
const ALLOWED_HOSTNAMES = ['coinbase.com', 'wallet.coinbase.com'];

type handleExternalLinkClickOptions = {
  target?: string;
};

export function useExternalLinkModal() {
  const { openModal, closeModal } = useModal();

  const allowedHostnames = useMemo(() => [...ALLOWED_HOSTNAMES, window.location.hostname], []);

  const handleExternalLinkClick = useCallback(
    (externalLink: string, { target }: handleExternalLinkClickOptions) =>
      () => {
        if (target) {
          window.open(externalLink, target);
        } else {
          window.location.href = externalLink;
        }
        closeModal();
      },
    [closeModal],
  );

  const handleClick = useCallback(
    (event: MouseEvent) => {
      const isWarningDisabled = JSON.parse(
        Store.get(StoreKeys_externalLinkWarningDisabled) || 'false',
      );
      if (isWarningDisabled) {
        return;
      }

      const anchor = (event.target as HTMLElement).closest('a');
      if (anchor?.href) {
        const linkHostname = new URL(anchor.href).hostname;
        if (
          !allowedHostnames.includes(
            linkHostname.startsWith('www.') ? linkHostname.replace(/^www\./, '') : linkHostname,
          )
        ) {
          event.preventDefault();
          openModal(
            <ExternalLinkModal
              onClose={closeModal}
              onContinue={handleExternalLinkClick(anchor.href, { target: anchor.target })}
              hostname={linkHostname}
            />,
          );
        }
      }
    },
    [allowedHostnames, openModal, closeModal, handleExternalLinkClick],
  );

  useEffect(
    function handleDocumentClick() {
      document.addEventListener('click', handleClick);

      return () => {
        document.removeEventListener('click', handleClick);
      };
    },
    [handleClick],
  );
  return {
    handleExternalLinkClick,
  };
}

import { useRecoilValue } from 'recoil';

import { activeWalletGroupIdAtom } from '../state';

import { useWarnDevOfActiveWalletGroupIdUsage } from './useWarnDevOfActiveWalletGroupIdUsage';

// TODO:
// - Combine with existing useActiveWalletIndex somehow
// - remove nullish coallesce
// - Remove usage from datalayer and move entirely to extension & rn repos
export function useActiveWalletGroupId() {
  useWarnDevOfActiveWalletGroupIdUsage();
  return useRecoilValue(activeWalletGroupIdAtom);
}

/**
 * Unified config of interval constants. We want to avoid certain expensive operations
 * happening at the same time, so we try to space our intervals out as evenly as
 * possible. We try to avoid overlapping with transaction syncing as it is the most
 * expensive operation currently
 *
 *                                                        txn_sync
 * bal                         bal                 bal                 bal
 *                                                                     def
 *             freq      freq      freq     freq      freq       freq
 * |--------5--------10--------15--------20--------25--------30--------35
 */
export const BALANCE_POLLING_INTERVAL = 10 * 1000;
export const BALANCE_POLLING_DELAY = 5 * 1000;

export const TXN_SYNCING_INTERVAL = 30 * 1000;

export const DEFAULT_PENDING_TXN_RESUBMIT_INTERVAL = 30 * 1000;
export const DEFAULT_PENDING_TXN_RESUBMIT_DELAY = 5 * 1000;

export const FREQUENT_PENDING_TXN_RESUBMIT_INTERVAL = 5 * 1000;
export const FREQUENT_PENDING_TXN_RESUBMIT_DELAY = 2.5 * 1000;

export const CURATED_ASSET_SYNCING_INTERVAL = 30 * 60 * 1000;

import { Store } from 'cb-wallet-store/Store';
import { v4 as uuidv4 } from 'uuid';

import { StoreKeys_amplitudeDeviceId } from ':dapp/utils/StoreKeys';

export function getDeviceId() {
  const deviceId = Store.get<string>(StoreKeys_amplitudeDeviceId) ?? uuidv4();
  Store.set(StoreKeys_amplitudeDeviceId, deviceId);
  return deviceId;
}

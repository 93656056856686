export const ETHERSCAN_DOMAIN = 'etherscan.io';
export const POLYGONSCAN_DOMAIN = 'polygonscan.com';
export const SOLSCAN_DOMAIN = 'solscan.io';
export const MAGIC_EDEN_DOMAIN = 'magiceden.io';
export const OPTIMISTIC_ETHERSCAN_DOMAIN = 'optimistic.etherscan.io';
export const BASESCAN_DOMAIN = 'explorer.base.org';

export const POLYGON_CHAIN_ID = '137';
export const SOLANA_CHAIN_ID = '101';
export const GNOSISSCAN_DOMAIN = 'gnosisscan.io';

export const ETH_TOKEN_ADDRESS = '0x0000000000000000000000000000000000000000';

import { useMemo } from 'react';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
/**
 * Returns the width of the side sheet based on the current breakpoint
 */
export function useGlobalDrawerWidth() {
  const { isPhone, isPhoneLandscape, isTabletLandscape, isTablet } = useBreakpoints();
  // Return the drawer width based on the breakpoint as a percetnage
  return useMemo(() => {
    switch (true) {
      case isPhone:
      case isPhoneLandscape:
        return '100%';
      case isTablet:
      case isTabletLandscape:
      default:
        return '450px';
    }
  }, [isPhone, isPhoneLandscape, isTablet, isTabletLandscape]);
}

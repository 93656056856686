export type PromiseWithTerminationFns<T> = {
  promise: Promise<T>;
  resolve: (value: T) => void;
  reject: (error: ErrorOrAny) => void;
};

export function promiseWithTermination<T>(): PromiseWithTerminationFns<T> {
  let resolve: ((value: T) => void) | undefined;
  let reject: ((error: ErrorOrAny) => void) | undefined;

  const promise = new Promise<T>((a, b) => {
    resolve = a;
    reject = b;
  });

  return {
    promise,
    resolve: resolve!,
    reject: reject!,
  };
}

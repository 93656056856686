export function filterNullish<T>(array: (T | null | undefined)[]): T[] {
  return array.filter((item): item is T => !!item);
}

export function reduceIntoMap<T, K, V>(
  array: T[],
  closure: (map: Map<K, V>, item: T) => void,
): Map<K, V> {
  const map = new Map<K, V>();
  array.forEach((it) => closure(map, it));

  return map;
}

export function isNullOrUndefined(data: unknown): data is null | undefined {
  return data === undefined || data === null;
}

export function mapNotNull<T, R>(array: T[], closure: (item: T) => R): NonNullable<R>[] {
  return array
    .map(closure)
    .filter((item: R | undefined | null) => !isNullOrUndefined(item)) as NonNullable<R>[];
}

/**
 *
 * @param array
 * @returns the first item in the array. If the array is empty, it returns undefined
 */
export function firstOrUndefined<T>(array: T[]): T | undefined {
  return array[0];
}

/**
 * Replaces entry at specified index.
 * Immutable operation. Always returns a new array instance.
 * If index is non-existent, function returns a fresh copy of the array with no changes
 */
export function replaceAt<T>(array: T[], index: number, value: T): T[] {
  const copy = array.slice(0);
  if (index < 0 || index >= array.length) return copy;

  copy[index] = value;
  return copy;
}

/**
 * Move the target item to a new position in the array.
 * Immutable operation. Always returns a new array instance.
 */
export function moveToIndex<T>(array: T[], fromIndex: number, toIndex: number): T[] {
  const newArray = [...array];
  const arrayLen = array.length;

  if (
    fromIndex === toIndex ||
    fromIndex < 0 ||
    fromIndex >= arrayLen ||
    toIndex < 0 ||
    toIndex >= arrayLen
  ) {
    return newArray;
  }

  const target = newArray[fromIndex];
  const inc = toIndex < fromIndex ? -1 : 1;

  for (let i = fromIndex; i !== toIndex; i += inc) {
    newArray[i] = newArray[i + inc];
  }

  newArray[toIndex] = target;

  return newArray;
}

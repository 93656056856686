import { useMemo } from 'react';
import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { useRecoilValue } from 'recoil';

import { Account } from '../models/Account';
import { activeAccountSelector } from '../state';

/**
 * Finds the current active account or throws an error if not found. In
 * cases where there could be no active account, such as onboarding,
 * use the activeAccountSelector Recoil value directly.
 */
export function useActiveAccount(): Account {
  const account = useRecoilValue(activeAccountSelector);

  return useMemo(() => {
    if (!account) {
      const error = new Error('No active account');
      cbReportError({ error, context: 'multi-account', isHandled: false, severity: 'error' });
      throw error;
    }

    return account;
  }, [account]);
}

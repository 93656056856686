import { useRecoilValue } from 'recoil';

import { userFacingAccountsSortedByCreatedAtSelector } from '../state';

/**
 * Returns user-facing accounts sorted ascending by createdAt date (oldest first).
 *
 * This hook is used for analytics reporting; if using for UI display, use
 *  `useUserFacingAccountsSortedForDisplay` instead.
 */
export function useUserFacingAccountsSortedByCreatedAt() {
  return useRecoilValue(userFacingAccountsSortedByCreatedAtSelector);
}

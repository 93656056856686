import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';

import { useSearchContext } from ':dapp/providers/SearchProvider';

import { SearchDesktop } from './SearchDesktop';
import { SearchMobile } from './SearchMobile';

export function Search() {
  const { isPhone } = useBreakpoints();
  const { isSearching } = useSearchContext();

  if (!isSearching) {
    return null;
  }

  return isPhone ? <SearchMobile /> : <SearchDesktop />;
}

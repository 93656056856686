import { AllPossibleBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
import { tryGetRepository } from 'cb-wallet-data/persistence/Database';

import { Network } from '../Networks/models/Network';

import { setWallets } from './hooks/useSetWallets';
import { Wallet, WalletDMO } from './models/Wallet';

export function repository() {
  return tryGetRepository<WalletDMO>('wallet');
}

/**
 * Hydrates Wallets from the DB into recoil state
 *
 * THIS SHOULD ONLY BE CALLED ONCE WHEN THE APP INITS
 */
export async function hydrateWallets(): Promise<boolean> {
  const rows = await repository().find();

  setWallets(rows.map(Wallet.fromDMO).filter((w) => !w.isDeleted));
  return rows.length > 0;
}

export async function saveWallets(wallets: Wallet[]): Promise<void> {
  setWallets(wallets);
  await Promise.all(
    repository().batchUpsert(
      wallets.map((w) => w.asDMO),
      ['id'],
    ),
  );
}

export async function deleteWallet(wallet: Wallet): Promise<void> {
  // Make sure we don't resave a wallet after it has been deleted
  await repository().delete(wallet.id);
}

/**
 * Delete wallets in bulk
 *
 * Takes wallets ids as an input parameter and deletes them from the database.
 * repository method is only invoked if the walletIds array is not empty.
 */
export async function deleteWallets(walletIds: string[]): Promise<void> {
  if (walletIds.length) {
    await repository().delete(walletIds);
  }
}

export async function softDeleteWallets(wallets: Wallet[]): Promise<void> {
  await Promise.all(
    repository().batchUpsert(
      wallets.map(function markWalletAsSoftDeleted(w) {
        const dmo = w.asDMO;
        dmo.isDeleted = true;
        return dmo;
      }),
      ['id'],
    ),
  );
}

export async function getWallets(): Promise<Wallet[]> {
  const rows = await repository().find();
  return rows.map(Wallet.fromDMO).filter((w) => !w.isDeleted);
}

export async function getWalletsOfBlockchainRecord(
  symbol: AllPossibleBlockchainSymbol,
): Promise<Record<Wallet['id'], Wallet>> {
  const rows = (
    await repository().find({
      where: {
        blockchainStr: symbol,
      },
    })
  ).filter((w) => !w.isDeleted);

  return rows.reduce<Record<Wallet['id'], Wallet>>(function reduceWallets(
    acc: Record<Wallet['id'], Wallet>,
    wallet: WalletDMO,
  ) {
    acc[wallet.id] = Wallet.fromDMO(wallet);
    return acc;
  },
  {});
}

export async function getWalletsForAccount(accountId: string): Promise<Wallet[]> {
  const rows = await repository().find({
    where: {
      accountId,
    },
  });

  return rows.map(Wallet.fromDMO).filter((w) => !w.isDeleted);
}

export async function getWalletIdsForBlockchainAndNetwork(
  symbol: AllPossibleBlockchainSymbol,
  network: Network,
): Promise<string[]> {
  const rows = await repository().find({
    where: {
      blockchainStr: symbol,
      networkStr: network.rawValue,
    },
  });

  return rows.map((row) => row.id);
}

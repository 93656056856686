import {
  blockchainConfigurations,
  PossibleUTXOBlockchainSymbol,
} from 'cb-wallet-data/chains/blockchains';
import {
  MAX_UNUSED,
  MIN_UNUSED,
  xpubKeyDerivationPathForBlockchain,
} from 'cb-wallet-data/chains/UTXO/common/config';
import { deriveAddressFromXpubKeyForBlockchain } from 'cb-wallet-data/chains/UTXO/common/deriveAddressFromXpubKey';
import { getAddressesTypesToRefresh } from 'cb-wallet-data/chains/UTXO/common/getAddressTypesToRefresh';
import { getXpubKeyFromLocalStorage } from 'cb-wallet-data/chains/UTXO/common/getXpubKeyFromLocalStorage';
import { UTXOError } from 'cb-wallet-data/chains/UTXO/exceptions/UTXOError';
import { UTXOConfiguration } from 'cb-wallet-data/chains/UTXO/models/UTXOConfiguration';
import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { getAddressesForBlockchainOfAccount } from 'cb-wallet-data/stores/Addresses/database';
import { Address } from 'cb-wallet-data/stores/Addresses/models/Address';
import { AddressType } from 'cb-wallet-data/stores/Addresses/models/AddressType';
import {
  StoreKeys_disableUTXOScanningHeuristic,
  StoreKeys_unusedUTXOAddressTypes,
} from 'cb-wallet-data/stores/Wallets/storeKeys';
import { Store } from 'cb-wallet-store/Store';
import range from 'lodash/range';

type GetUTXOAddressesForAccountParams = {
  blockchainSymbol: PossibleUTXOBlockchainSymbol;
  accountId: Account['id'];
};

export async function getUTXOAddressesForAccount({
  blockchainSymbol,
  accountId,
}: GetUTXOAddressesForAccountParams) {
  const configuration = blockchainConfigurations[blockchainSymbol];
  const existingAddresses = await getAddressesForBlockchainOfAccount({
    blockchain: configuration.blockchain,
    currencyCode: configuration.currencyCode,
    network: configuration.networkSetting.defaultMainnet.network,
    accountId,
  });

  try {
    const addressTypes = getAddressesTypesToRefresh(blockchainSymbol);
    const unusedAddressTypes = Store.get<string[]>(StoreKeys_unusedUTXOAddressTypes) || [];
    const utxoScanningHeuristicDisabled = Store.get<boolean>(
      StoreKeys_disableUTXOScanningHeuristic,
    );

    const unusedAddresses = existingAddresses.filter(({ isUsed }) => !isUsed);

    const newAddresses = await Promise.all(
      addressTypes.map(async function createNewAddresses(addressType: AddressType) {
        const addressTypeUnused = unusedAddressTypes.includes(addressType.rawValue);
        const bufferSize =
          addressTypeUnused && !utxoScanningHeuristicDisabled ? MIN_UNUSED : MAX_UNUSED;
        const addressesForAddressType = unusedAddresses.filter(
          ({ type }: Address) => type.rawValue === addressType.rawValue,
        );

        const unusedChangeAddresses = addressesForAddressType.filter(
          (address) => address.isChangeAddress,
        );
        const unusedReceiveAddresses = addressesForAddressType.filter(
          (address) => !address.isChangeAddress,
        );

        const addressesToSave = [];

        // We only need an address buffer of 1 for change addresses
        if (unusedChangeAddresses.length < bufferSize) {
          const numAddresses = bufferSize - unusedChangeAddresses.length;
          const startIndex = getHighestIndexForAddresses(addressType, true, existingAddresses);

          const newChangeAddresses = await createNextAddresses({
            blockchainSymbol,
            addressType,
            accountId,
            numAddresses,
            startIndex: startIndex + 1,
            isChangeAddress: true,
          });

          addressesToSave.push(...newChangeAddresses);
        }

        if (unusedReceiveAddresses.length < bufferSize) {
          const numAddresses = bufferSize - unusedReceiveAddresses.length;
          const startIndex = getHighestIndexForAddresses(addressType, false, existingAddresses);

          const newReceiveAddresses = await createNextAddresses({
            blockchainSymbol,
            addressType,
            accountId,
            numAddresses,
            startIndex: startIndex + 1,
            isChangeAddress: false,
          });

          addressesToSave.push(...newReceiveAddresses);
        }

        return addressesToSave;
      }),
    );

    return {
      existingAddresses,
      newAddresses: newAddresses.flat(),
    };
  } catch (err: unknown) {
    cbReportError({
      context: 'utxo_balance_scanning',
      metadata: {
        blockchainSymbol,
        numAddresses: existingAddresses.length,
        step: 'derive_new_addresses',
      },
      error: err as Error,
      isHandled: false,
      severity: 'error',
    });

    return {
      existingAddresses,
      newAddresses: [],
    };
  }
}

export function getHighestIndexForAddresses(
  addressType: AddressType,
  isChangeAddress: boolean,
  addresses: Address[] = [],
) {
  const [lastIndexedAddress] = addresses
    .filter(
      (address) =>
        address.type.rawValue === addressType.rawValue &&
        address.isChangeAddress === isChangeAddress,
    )
    .sort((a, b) => Number(a.index - b.index))
    .slice(-1);

  return Number(lastIndexedAddress?.index ?? 0n);
}

type CreateNextAddressesArgs = {
  blockchainSymbol: PossibleUTXOBlockchainSymbol;
  addressType: AddressType;
  accountId: Account['id'];
  numAddresses: number;
  startIndex: number;
  isChangeAddress: boolean;
};

async function createNextAddresses({
  blockchainSymbol,
  addressType,
  accountId,
  numAddresses,
  startIndex,
  isChangeAddress,
}: CreateNextAddressesArgs) {
  const configuration = blockchainConfigurations[blockchainSymbol] as UTXOConfiguration;
  const indexes = range(startIndex, startIndex + numAddresses);
  const deriveAddressFromXpubKey = deriveAddressFromXpubKeyForBlockchain[blockchainSymbol];
  const xpubKeyDerivationPath = xpubKeyDerivationPathForBlockchain[blockchainSymbol];
  const { network } = configuration.networkSetting.defaultMainnet;
  const xpubKey = getXpubKeyFromLocalStorage({
    blockchain: configuration.blockchain,
    currencyCode: configuration.currencyCode,
    addressType,
    accountId,
    walletIndex: 0n, // No multiwallet for UTXOs
    isTestnet: false,
  });

  if (!xpubKey) {
    cbReportError({
      context: 'utxo_balance_scanning',
      error: new Error('Missing xpubkey'),
      isHandled: false,
      severity: 'error',
      metadata: {
        addressType: addressType.rawValue,
        accountId,
        blockchain: blockchainSymbol,
        currencyCode: configuration.currencyCode.rawValue,
        derivationPath: xpubKeyDerivationPath(addressType, network.isTestnet),
      },
    });
    return [];
  }

  const addresses = await Promise.all(
    indexes.map(async function createAddress(index) {
      const address = await deriveAddressFromXpubKey(
        xpubKey,
        BigInt(index),
        addressType,
        isChangeAddress,
        false,
      );

      const prefix = xpubKeyDerivationPath(addressType, network.isTestnet);

      if (!prefix) {
        throw UTXOError.unableToGenerateAddressDerivationPath;
      }

      const derivationPath = `${prefix}/${isChangeAddress ? 1 : 0}/${index}`;

      return new Address({
        index: BigInt(index),
        address,
        balance: 0n,
        currencyCode: configuration.currencyCode,
        isChangeAddress,
        network,
        derivationPath,
        isUsed: false,
        blockchain: configuration.blockchain,
        type: addressType,
        accountId,
      });
    }),
  );

  return addresses;
}

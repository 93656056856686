import {
  AllPossibleBlockchainSymbol,
  isMultiWalletBlockchain,
} from 'cb-wallet-data/chains/blockchains';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { WalletGroup } from 'cb-wallet-data/stores/WalletGroups/models/WalletGroup';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';

type PrepareWalletsByWalletGroupOptions = {
  /** All wallets. */
  wallets: Wallet[];

  /** All wallet groups. */
  walletGroups: WalletGroup[];

  /** Allow lookup of an account from recoil, from a wallet's given accountId. */
  getAccountById: (accountId: Account['id']) => Account | undefined;

  /** Checks if a wallet is valid usable. @see useIsValidWallet */
  isValidWallet: (wallet: Wallet) => boolean;

  /** Any previously found matches, which can be re-used. */
  prevWalletIdsToWalletGroupIds?: WalletIdsToWalletGroupIds;
};

export type WalletsByWalletGroup = Record<WalletGroup['id'], Wallet[]>;
export type WalletIdsToWalletGroupIds = Record<Wallet['id'], WalletGroup['id']>;
export type WalletGroupsByAccount = Record<Account['id'], WalletGroup[]>;

type PrepareWalletsByWalletGroupReturn = {
  /** All wallets matched to their wallet groups, with balances cached. */
  walletsByWalletGroup: WalletsByWalletGroup;

  /** Map of all wallet to wallet group matches found, for quick reverse lookup. */
  walletIdsToWalletGroupIds: WalletIdsToWalletGroupIds;
};

export const DEFAULT_WALLETS_BY_WALLET_GROUP = {
  walletsByWalletGroup: {},
  walletIdsToWalletGroupIds: {},
};

/**
 * Maps wallets to corresponding wallet groups, and caches the total
 * balance for each wallet group.
 */
export function prepareWalletsByWalletGroup({
  wallets,
  walletGroups,
  getAccountById,
  isValidWallet,
  prevWalletIdsToWalletGroupIds = {},
}: PrepareWalletsByWalletGroupOptions): PrepareWalletsByWalletGroupReturn {
  // [Perf] If no wallet groups have been hydrated yet, skip iterating over wallets,
  // which would result in no matches.
  if (!walletGroups.length) {
    return DEFAULT_WALLETS_BY_WALLET_GROUP;
  }

  // Separate wallet groups by accountId first, so for each wallet we can
  // search for a match among a list already filtered to the wallet's account.
  const walletGroupsByAccount = walletGroups.reduce<WalletGroupsByAccount>(
    function reduceWalletGroupsByAccount(acc, walletGroup) {
      if (!acc[walletGroup.accountId]) acc[walletGroup.accountId] = [];
      acc[walletGroup.accountId].push(walletGroup);
      return acc;
    },
    {},
  );

  // walletIdsToWalletGroupIds is built as wallets are matched to wallet groups, and
  // can be passed back to this function on next execution, to avoid re-matching.
  // Note: Once a wallet is matched to a wallet group, that relationship won't change,
  // while both entities exist.
  const nextWalletIdsToWalletGroupIds: WalletIdsToWalletGroupIds = {};

  // Initial state is created by mapping an empty array of wallets to each wallet group,
  // and any wallet group associated with non-existing accounts are left off.
  const initWalletsByWalletGroup = walletGroups.reduce<WalletsByWalletGroup>(
    function reduceInitWalletsByWalletGroup(acc, walletGroup) {
      if (!getAccountById(walletGroup.accountId)) return acc;
      acc[walletGroup.id] = [];
      return acc;
    },
    {},
  );

  // For every wallet, search for a matching wallet group among a list of wallet
  // groups already filtered to the wallet's account.
  const walletsByWalletGroup = wallets.reduce<WalletsByWalletGroup>(
    function reduceWalletsByWalletGroup(acc, wallet) {
      if (!isValidWallet(wallet)) return acc;

      // Skip adding this wallet, if its associated account no longer exists.
      // This may happen if an account was removed from state before its wallets.
      const walletAccount = getAccountById(wallet.accountId);
      if (!walletAccount) return acc;

      // If a wallet has been matched to wallet group, that relationship won't change;
      // so we can speed up this overall computation by utilizing any previous match
      // found for the current wallet.
      const walletGroupId =
        prevWalletIdsToWalletGroupIds[wallet.id] ||
        findWalletGroupIdForWallet({
          wallet,
          walletAccount,
          walletGroupsOfWalletAccount: walletGroupsByAccount[wallet.accountId],
        });

      // Skip adding this this wallet if:
      // - No matching wallet group was found.
      // - Previously matched wallet group from cache no longer exists.
      if (!walletGroupId || !acc[walletGroupId]) return acc;

      acc[walletGroupId].push(wallet);
      nextWalletIdsToWalletGroupIds[wallet.id] = walletGroupId;

      return acc;
    },
    initWalletsByWalletGroup,
  );

  return {
    walletsByWalletGroup,
    walletIdsToWalletGroupIds: nextWalletIdsToWalletGroupIds,
  };
}

type FindWalletGroupIdForWalletOptions = {
  /** Wallet, which a matching wallet group is being searched for. */
  wallet: Wallet;

  /** Wallet groups, already filtered down to those of the wallet's account. */
  walletGroupsOfWalletAccount?: WalletGroup[];

  /** Wallet's corresponding account. */
  walletAccount: Account;
};

/**
 * Given a wallet, find its matching wallet group ID among
 * provided wallet groups.
 */
export function findWalletGroupIdForWallet({
  wallet,
  walletAccount,
  walletGroupsOfWalletAccount,
}: FindWalletGroupIdForWalletOptions): WalletGroup['id'] | undefined {
  const walletBlockchainSymbol = wallet.blockchain.rawValue as AllPossibleBlockchainSymbol;
  const walletIndex = wallet.selectedIndex ?? BigInt(0); // UTXO wallets will have selectedIndex=undefined
  const walletIndexNum = Number(walletIndex);
  const { isMultiAccountRollup } = walletAccount;

  const walletGroupForWallet = walletGroupsOfWalletAccount?.find(
    function _findWalletGroupIdForWallet(walletGroup) {
      // For "rollup" type accounts (eg. ledger, private key), all wallets of a given account correspond
      // to that account's single wallet group; so always match to the account's first wallet group.
      if (isMultiAccountRollup) {
        return true;
      }

      const walletGroupIndexNum = Number(walletGroup.walletIndex);

      // No valid UTXO wallet should have a positive index, but if it does, never match it to a wallet group.
      if (!isMultiWalletBlockchain(walletBlockchainSymbol)) {
        return walletIndexNum === 0 ? walletGroupIndexNum === 0 : false;
      }

      return walletIndexNum === walletGroupIndexNum;
    },
  );

  return walletGroupForWallet?.id;
}

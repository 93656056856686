import { atomLocalStorageEffect } from 'cb-wallet-data/utils/atomLocalStorageEffect';
import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { atom } from 'recoil';

const StoreKeys_isTestnetsEnabled = new LocalStorageStoreKey<boolean>('isTestnetsEnabled');

export const isTestnetsEnabledAtom = atom<boolean | undefined>({
  key: 'isTestnetsEnabled',
  default: undefined,
  effects: [atomLocalStorageEffect(StoreKeys_isTestnetsEnabled)],
});

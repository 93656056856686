import { Blockchain } from 'cb-wallet-data/models/Blockchain';

import { Network } from './Network';

/**
 * Represents a network setting item
 */
export class NetworkSettingItem {
  constructor(readonly name: string, readonly network: Network) {}
}

/**
 * Represents all available network settings for a given blockchain
 */
export class NetworkSetting {
  constructor(
    readonly blockchain: Blockchain,
    readonly defaultMainnet: NetworkSettingItem,
    readonly mainnets: NetworkSettingItem[],
    readonly testnets: NetworkSettingItem[],
    readonly customNetworks: NetworkSettingItem[] = [],
  ) {}

  get defaultNetworks(): NetworkSettingItem[] {
    return [...this.mainnets, ...this.testnets];
  }

  get allNetworks(): NetworkSettingItem[] {
    return [...this.mainnets, ...this.testnets, ...this.customNetworks];
  }

  get mainnetsAndCustomNetworks(): NetworkSettingItem[] {
    return [...this.mainnets, ...this.customNetworks];
  }

  get testnetNetworks(): NetworkSettingItem[] {
    return [...this.testnets];
  }

  static forMainnetOnlyBlockchain(blockchain: Blockchain): NetworkSetting {
    const mainnetNetwork = new NetworkSettingItem('Mainnet', Network.createTestnetNetwork(false));

    return new NetworkSetting(blockchain, mainnetNetwork, [mainnetNetwork], []);
  }
}

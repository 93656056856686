import { WalletAccountType } from 'cb-wallet-http/fetchJSON';

export enum AccountType {
  MNEMONIC = 'mnemonic',
  PRIVATE_KEY = 'private_key',
  WALLET_LINK = 'wallet_link',
  LEDGER = 'ledger',
  TREZOR = 'trezor',
  DAPP_PROVIDER = 'dapp_provider',
  SCW = 'smart_contract_wallet',
}

// Account Types for  X-Wallet-Account-Type Header
// This map can only be extended. Values should not be removed or replaced
export const AccountTypeHeaderMap: Record<AccountType, WalletAccountType> = {
  [AccountType.MNEMONIC]: '0',
  [AccountType.PRIVATE_KEY]: '1',
  [AccountType.WALLET_LINK]: '2',
  [AccountType.LEDGER]: '3',
  [AccountType.TREZOR]: '4',
  [AccountType.DAPP_PROVIDER]: '5',
  [AccountType.SCW]: '6',
};

// //////////////
// HARDWARE TYPES
// //////////////
/**
 * @see {@link Account.isHardware}
 */
export const HARDWARE_TYPES = [AccountType.LEDGER, AccountType.TREZOR] as const;

/**
 * @see {@link Account.isHardware}
 */
export type HardwareAccountType = (typeof HARDWARE_TYPES)[number];

// //////////////////////////
// MULTI ACCOUNT ROLLUP TYPES
// //////////////////////////
/**
 * @see {@link Account.isMultiAccountRollup}
 */
export const MULTI_ACCOUNT_ROLLUP_TYPES = [
  ...HARDWARE_TYPES,
  AccountType.PRIVATE_KEY,
  AccountType.SCW, // TODO [scw]: Temporary until final UI work
] as const;

/**
 * @see {@link Account.isMultiAccountRollup}
 */
export type MultiAccountRollupType = (typeof MULTI_ACCOUNT_ROLLUP_TYPES)[number];

// /////////////////////////
// SIGNS VIA EXTENSION TYPES
// /////////////////////////
/**
 * @see {@link Account.signsViaExtension}
 */
export const SIGNS_VIA_EXTENSION_TYPES = [
  AccountType.MNEMONIC,
  AccountType.PRIVATE_KEY,
  AccountType.LEDGER,
  AccountType.TREZOR,
  AccountType.DAPP_PROVIDER,
] as const;

/**
 * @see {@link Account.signsViaExtension}
 */
export type SignsViaExtensionType = (typeof SIGNS_VIA_EXTENSION_TYPES)[number];

// ////////////////////////
// LOCALLY STORED KEY TYPES
// ////////////////////////
/**
 * @see {@link Account.hasLocallyStoredKey}
 */
export const LOCALLY_STORED_KEY_TYPES = [AccountType.MNEMONIC, AccountType.PRIVATE_KEY] as const;

/**
 * @see {@link Account.hasLocallyStoredKey}
 */
export type LocallyStoredKeyType = (typeof LOCALLY_STORED_KEY_TYPES)[number];

// ///////////////////////////
// SUPPORTS MULTI WALLET TYPES
// ///////////////////////////

/**
 * @see {@link Account.supportsMultiWallet}
 */
export const SUPPORTS_MULTI_WALLET_TYPES = [AccountType.MNEMONIC] as const;
/**
 * @see {@link Account.supportsMultiWallet}
 */
export type SupportsMultiWalletType = (typeof SUPPORTS_MULTI_WALLET_TYPES)[number];

// /////////////////////////
// SUPPORTS UTXO TYPES
// /////////////////////////
/**
 * @see {@link Account.supportsUTXO}
 */
export const SUPPORTS_UTXO_TYPES = [AccountType.MNEMONIC] as const;

import { ActionType, ComponentType, logMetric, MetricType } from '@cbhq/client-analytics';

import { logDataEvent, logEvent, useLogEventOnMount } from '../utils/log';

export function logTransactionsEmptyAddCryptoClicked() {
  return logEvent('transactions_empty.add_crypto.clicked', {
    loggingId: '0ce605fe-b38d-4771-9e81-6f781fb028c2',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}
export function useTransactionViewed() {
  return useLogEventOnMount('transactions.viewed', {
    loggingId: 'bebfaf2f-dd0c-48c5-a48b-579897c43134',
    componentType: ComponentType.page,
  });
}

// used in dapp
export function logTransactionViewed() {
  return logEvent('transactions.viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function logTransactionDetailViewed() {
  return logEvent('transaction_detail.viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function logTransactionRowClicked(data?: {
  type?: string;
  network?: string;
  asset?: string;
}) {
  return logEvent('transactions_row.clicked', {
    action: ActionType.click,
    componentType: ComponentType.table,
    ...data,
  });
}

export function logTransactionsViewClicked(listLength: number) {
  return logEvent('transactions_list.view_all.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
    queryLength: listLength,
  });
}

export function logTransactionSidebarViewed() {
  return logEvent('transaction_sidebar.viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

type TransactionUpdateLatencyProps = {
  txHash: string;
  absoluteNudgedAt: number | undefined;
  nudgedAt: number | undefined;
  submittedAt: number | undefined;
  txSource?: string;
  isDelayedRefresh: boolean | undefined;
  network: string;
  appBackgroundStart: number | undefined;
  appBackgroundEnd: number | undefined;
};

// Keeps track of which tx hashes we've already logged update latency for to ensure we don't log
// multiple times for the same tx
const didLogUpdateLatency: Record<string, boolean> = {};

// 2 minutes curoff to avoid logging inflated durations due to bugs in pending transaction handling
const UPDATE_LATENCY_CUTOFF = 2 * 1000 * 60;

export function logTransactionUpdateLatency({
  txHash,
  absoluteNudgedAt,
  nudgedAt,
  submittedAt,
  txSource,
  isDelayedRefresh,
  network,
  appBackgroundStart,
  appBackgroundEnd,
}: TransactionUpdateLatencyProps) {
  if (
    !submittedAt ||
    didLogUpdateLatency[txHash] ||
    appBackgroundEnd !== undefined ||
    appBackgroundStart !== undefined
  ) {
    return;
  }

  let updateLatency = performance.now() - submittedAt;
  if (isDelayedRefresh === false && nudgedAt && absoluteNudgedAt) {
    const timeOnConfirmScreen = nudgedAt - absoluteNudgedAt;
    updateLatency -= timeOnConfirmScreen;
  }

  if (updateLatency > UPDATE_LATENCY_CUTOFF) {
    didLogUpdateLatency[txHash] = true;
    return;
  }

  logMetric({
    metricName: 'transaction.update_latency_v2',
    metricType: MetricType.distribution,
    value: updateLatency,
    tags: {
      networkName: network,
      txSource: txSource ?? 'unknown',
    },
  });
  didLogUpdateLatency[txHash] = true;
}

export function logTransactionImportDuration({
  chainName,
  duration,
}: {
  chainName: string;
  duration: number;
}) {
  logMetric({
    metricName: 'transaction.import_duration',
    metricType: MetricType.distribution,
    value: duration,
    tags: {
      chainName,
    },
  });
}

export function logCancelClicked() {
  logEvent('transaction_detail.cancel.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function logSpeedUpClicked() {
  logEvent('transaction_detail.speed_up.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function logBlockExplorerClicked(blockchain: string, network: string) {
  logEvent('transactions.block_explorer.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
    loggingId: '9c632496-c3be-48ab-8879-19846af620c8',
    blockchain,
    network,
  });
}

export function logViewAssetClicked(walletId: string, context?: string) {
  logEvent('transaction_detail.view_asset.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
    walletId,
    context,
  });
}

type ViewNFTClickedProps = {
  contractAddress: string | undefined;
  tokenId: string | undefined;
  chainId: number | undefined;
};
export function logViewNFTClicked({ contractAddress, tokenId, chainId }: ViewNFTClickedProps) {
  logEvent('transaction_detail.view_nft.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
    contractAddress,
    tokenId,
    chainId,
  });
}

export function logSendAgainClicked(walletId: string) {
  logEvent('transaction_detail.send_again.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
    walletId,
  });
}

type SwapAgainClickedProps = {
  sourceSwapId: string | undefined;
  chainId: number | undefined;
  contractAddress: string | undefined;
};
export function logSwapAgainClicked({
  sourceSwapId,
  chainId,
  contractAddress,
}: SwapAgainClickedProps) {
  logEvent('transaction_detail.swap_again.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
    sourceSwapId,
    chainId,
    contractAddress,
  });
}

type BridgeAgainClickedProps = {
  fromChainId: number | undefined;
  targetChainId: number | undefined;
};
export function logBridgeAgainClicked({ fromChainId, targetChainId }: BridgeAgainClickedProps) {
  logEvent('transaction_detail.bridge_again.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
    fromChainId,
    targetChainId,
  });
}

export function logStakeAgainClicked(asset: string | undefined) {
  logEvent('transaction_detail.stake_again.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
    asset,
  });
}

const ONE_MINUTE = 1000 * 60;
const ONE_HOUR = ONE_MINUTE * 60;
const ONE_DAY = ONE_HOUR * 24;

type StuckPendingTransactionProps = {
  createdAt: Date;
  blockchain: string;
  network: string;
};

export function logStuckPendingTransaction({
  createdAt,
  blockchain,
  network,
}: StuckPendingTransactionProps) {
  const now = new Date();
  const timeSinceCreation = now.getTime() - createdAt.getTime();

  let timeBucket: string;
  if (timeSinceCreation >= 7 * ONE_DAY) {
    timeBucket = '7_days';
  } else if (timeSinceCreation >= ONE_DAY) {
    timeBucket = '1_day';
  } else if (timeSinceCreation >= 6 * ONE_HOUR) {
    timeBucket = '6_hours';
  } else if (timeSinceCreation >= ONE_HOUR) {
    timeBucket = '1_hour';
  } else if (timeSinceCreation >= 30 * ONE_MINUTE) {
    timeBucket = '30_minutes';
  } else {
    return;
  }

  logDataEvent('transactions.pending.stuck', {
    timeBucket,
    blockchain,
    network,
  });
}

type TransactionDetailsClickedProps = {
  source: string;
  blockchain: string;
  network: string;
};

export function logTransactionDetailsClicked({
  source,
  blockchain,
  network,
}: TransactionDetailsClickedProps) {
  logEvent('transactions.details.clicked', {
    action: ActionType.click,
    componentType: ComponentType.page,
    source,
    blockchain,
    network,
  });
}

type GiftLinkParams = {
  chainId: number | undefined;
  chainName: string | undefined;
  contractAddress: string | undefined;
  currencyCode: string;
};

export function logCreateGiftLinkTapped(props: GiftLinkParams) {
  logEvent('giftlink.create.tapped', {
    action: ActionType.click,
    componentType: ComponentType.page,
    ...props,
  });
}

export function logGiftCryptoModalOpened(props: GiftLinkParams) {
  logEvent('giftlink.modal.opened', {
    action: ActionType.click,
    componentType: ComponentType.modal,
    ...props,
  });
}

export function logNavigateToRegenerateGiftLink(props: GiftLinkParams) {
  logEvent('giftlink.regenerate.tapped', {
    action: ActionType.click,
    componentType: ComponentType.page,
    ...props,
  });
}

type hrtParams = {
  primaryAction: string;
  blockchain: string;
  network: string;
};

export function logHRTTxnMissingDecimals({ blockchain, network, primaryAction }: hrtParams) {
  logMetric({
    metricName: 'transaction.hrt.missing_decimals',
    metricType: MetricType.count,
    value: 1,
    tags: {
      blockchain,
      networkName: network,
      primaryAction,
    },
  });
}

export function logHRTMissingTransfers({ primaryAction, blockchain, network }: hrtParams) {
  logMetric({
    metricName: 'transaction.hrt.missing_transfers',
    metricType: MetricType.count,
    value: 1,
    tags: {
      blockchain,
      networkName: network,
      primaryAction,
    },
  });
}

type TxDetailDeeplinkResultParams = {
  walletAddress: string;
  txHash: string;
  txDetailDeeplinkResult: 'success' | 'tx_not_found';
  network: string;
  chainId: string;
};

export function logTxDetailDeeplinkProcessed(data: TxDetailDeeplinkResultParams) {
  return logEvent('transaction.tx_deeplink.processed', {
    action: ActionType.view,
    componentType: ComponentType.page,
    walletAddress: data.walletAddress,
    txHash: data.txHash,
    txDetailDeeplinkResult: data.txDetailDeeplinkResult,
    network: data.network,
    chainId: data.chainId,
  });
}

type logAddressCopiedParams = {
  network: string;
  blockchain: string;
  addressType: 'from' | 'to';
  source: string | undefined;
};

export function logAddressCopied({
  network,
  blockchain,
  addressType,
  source,
}: logAddressCopiedParams) {
  logEvent('transaction.address.copied', {
    action: ActionType.click,
    componentType: ComponentType.text,
    network,
    blockchain,
    addressType,
    source,
  });
}

type LogMissingTransactionForAddressParams = {
  blockchain: string;
  addressType: { rawValue: string };
};

export function logMissingTransactionsForAddressType({
  blockchain,
  addressType,
}: LogMissingTransactionForAddressParams) {
  logEvent('transactions.transactions_missing_for_address_type', {
    action: ActionType.unknown,
    componentType: ComponentType.unknown,
    blockchain,
    addressType: addressType.rawValue,
  });
}

type LogFilteredPoisonousTransaction = {
  txHash?: string;
  network: string;
};

export function logFilteredPoisonousTransaction({
  txHash,
  network,
}: LogFilteredPoisonousTransaction) {
  logEvent('transactions.poisonous_transaction_filtered', {
    action: ActionType.unknown,
    componentType: ComponentType.unknown,
    txHash,
    network,
  });
}

import { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';

import { FETCH_TOKEN_DETAILS_KEY, getTokenDetails } from '../api';
import { Collectible } from '../types';

/**
 * Fetches, caches, and polls collectible detail from the Coinbase API.
 *
 * @returns The UseQueryResult containing a Collectible object.
 */

type Props = {
  contractAddress?: string;
  tokenId?: string;
  chainId?: string;
  includeFloorPrice?: boolean;
  suspense?: boolean;
  userAddress?: string;
};

export function useTokenDetails({
  contractAddress,
  tokenId,
  chainId,
  includeFloorPrice,
  suspense = true,
  userAddress,
}: Props): UseQueryResult<Collectible> {
  return useQuery({
    queryKey: [
      FETCH_TOKEN_DETAILS_KEY,
      contractAddress,
      tokenId,
      includeFloorPrice,
      chainId,
      userAddress,
    ],
    queryFn: async () => {
      return getTokenDetails(contractAddress, tokenId, includeFloorPrice, chainId, userAddress);
    },
    suspense,
    useErrorBoundary: false,
    staleTime: 1000 * 30,
    notifyOnChangeProps: ['data'],
    retry: 1,
  });
}

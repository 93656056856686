import { useMemo } from 'react';

import {
  EarnPortfolioAsset,
  EarnPortfolioAssetResponse,
  EarnPortfolioResponse,
} from '../models/EarnPortfolioResponse';

import { useParseAssetInfoResponse } from './useParseAssetInfoResponse';

type useParseEarnPortfolioResponseProps = {
  earnPortfolioResponse?: EarnPortfolioResponse;
};

export function useParseEarnPortfolioResponse({
  earnPortfolioResponse,
}: useParseEarnPortfolioResponseProps): EarnPortfolioAsset[] {
  const parseAssetInfoResponse = useParseAssetInfoResponse();

  return useMemo(() => {
    if (!earnPortfolioResponse?.earnPortfolioAssets.length) return [] as EarnPortfolioAsset[];

    return earnPortfolioResponse.earnPortfolioAssets.map(function parseEarnPortfolioResponse(
      assetResponse: EarnPortfolioAssetResponse,
    ): EarnPortfolioAsset {
      return {
        assetInfo: parseAssetInfoResponse(assetResponse.assetInfo),
        earnRate: assetResponse.earnRate,
        walletAddress: assetResponse.walletAddress,
      };
    });
  }, [earnPortfolioResponse, parseAssetInfoResponse]);
}

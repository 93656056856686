import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { Drawer } from 'wallet-cds-web/components/Drawer';
import { CellSpacing, useToggler } from '@cbhq/cds-common';
import { IconButton } from '@cbhq/cds-web/buttons';
import { ListCell } from '@cbhq/cds-web/cells';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { Sidebar } from '@cbhq/cds-web/navigation/Sidebar';
import { TextHeadline } from '@cbhq/cds-web/typography';

import { useLaunchBuyFlow } from ':dapp/hooks/Buy/useLaunchBuyFlow';
import { useIsFeatureEnabledForScw } from ':dapp/hooks/Scw/useIsFeatureEnabledForScw';
import { useIsGlobalSwapEnabled } from ':dapp/hooks/Swap/useIsGlobalSwapEnabled';
import { useHandleGabItemPress } from ':dapp/hooks/useHandleGabItemPress';

import { DrawerName } from '../GlobalDrawer/constants';
import { useSideNavbarConfig } from '../SideNavbar/hooks/useSideNavbarConfig';
import { SideNavbarItem } from '../SideNavbar/SideNavbarItem';

const messages = defineMessages({
  menu: {
    defaultMessage: 'Menu',
    description: 'Sidenav bar heading',
  },
  buy: {
    defaultMessage: 'Buy',
    description: 'Global buy entry point button label',
  },
  swap: {
    defaultMessage: 'Swap',
    description: 'Global swap entry point button label',
  },
  send: {
    defaultMessage: 'Send',
    description: 'Global send entry point button label',
  },
});

const innerSpacing: CellSpacing = {
  spacingHorizontal: 1,
};

type GlobalActionsDrawerProps = {
  isDrawerOpen: boolean;
  toggleDrawer: () => void;
};

export function GlobalActionsDrawer({ isDrawerOpen, toggleDrawer }: GlobalActionsDrawerProps) {
  const { formatMessage } = useIntl();
  const sidebarItems = useSideNavbarConfig();
  const [, { toggleOff }] = useToggler();
  const handleOpenDrawer = useHandleGabItemPress({ closeModal: toggleOff });
  const isSCWSendEnabled = useIsFeatureEnabledForScw('send');
  const isGlobalSwapEnabled = useIsGlobalSwapEnabled();
  const isGlobalSendEnabled = useIsFeatureEnabled('send_blockchain_all');
  const { launchBuyFlow, isBuyFlowEnabled } = useLaunchBuyFlow();
  const isSendFlowEnabled = isGlobalSendEnabled && isSCWSendEnabled;

  const handleBuyFlow = useCallback(async () => {
    await launchBuyFlow();
    toggleOff();
    toggleDrawer();
  }, [launchBuyFlow, toggleOff, toggleDrawer]);

  const handlePressSend = useCallback(() => {
    toggleDrawer();
    handleOpenDrawer(DrawerName.Send)();
  }, [handleOpenDrawer, toggleDrawer]);

  const handlePressSwap = useCallback(() => {
    toggleDrawer();
    handleOpenDrawer(DrawerName.Swap)();
  }, [handleOpenDrawer, toggleDrawer]);

  return (
    <Drawer open={isDrawerOpen} pin="left" size="75%" bordered={false}>
      <Sidebar testID="side-navbar" width="100%">
        <HStack spacing={1} justifyContent="space-between" alignItems="center" width="75%">
          <IconButton
            name="backArrow"
            accessibilityLabel="Sidenav menu close button"
            variant="secondary"
            transparent
            onPress={toggleDrawer}
          />
          <TextHeadline as="p" testID="wallet-sidenav-header">
            {formatMessage(messages.menu)}
          </TextHeadline>
        </HStack>
        <VStack borderedBottom spacingBottom={2}>
          {sidebarItems.map((props) => (
            <SideNavbarItem {...props} key={props.title} onPress={toggleDrawer} />
          ))}
        </VStack>
        <VStack borderRadius="roundedLarge">
          {isBuyFlowEnabled && (
            <ListCell
              compact
              innerSpacing={innerSpacing}
              title={formatMessage(messages.buy)}
              media={<IconButton name="add" variant="secondary" />}
              onPress={handleBuyFlow}
            />
          )}
          {isSendFlowEnabled && (
            <ListCell
              compact
              innerSpacing={innerSpacing}
              title={formatMessage(messages.send)}
              media={<IconButton name="arrowUp" variant="secondary" />}
              onPress={handlePressSend}
            />
          )}
          {isGlobalSwapEnabled && (
            <ListCell
              compact
              innerSpacing={innerSpacing}
              title={formatMessage(messages.swap)}
              media={<IconButton name="wireTransfer" variant="secondary" />}
              onPress={handlePressSwap}
            />
          )}
        </VStack>
      </Sidebar>
    </Drawer>
  );
}

import { useRecoilValue } from 'recoil';

import { accessTokenAtom, refreshTokenAtom } from './state';

/**
 * Some API methods require an access token. If this app has an access token stored,
 * or a refresh token which can be exchanged for an access token, it is able to call these methods.
 *
 * @returns A boolean indicating that the app is able to authenticate with the Wallet API.
 */
export function useCanAuthenticateWithAPI(): boolean {
  const accessToken = useRecoilValue(accessTokenAtom);
  const refreshToken = useRecoilValue(refreshTokenAtom);
  return !!(accessToken ?? refreshToken);
}

import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import {
  ChallengeCompleteTypeError,
  useMutateChallengeComplete,
} from 'cb-wallet-data/hooks/Gamification/useMutateChallengeComplete';

type Challenge = {
  challengeId: string;
  addedAt: string;
  walletAddress: string;
};

const storageExpiry = 2 * 24 * 60 * 60 * 1000; // 2 days
const challengesToClaimKey = 'challengesToClaim';

// Helper functions to work with object and localStorage
function loadChallengesFromStorage(): Record<string, Challenge> {
  const storedData = localStorage.getItem(challengesToClaimKey);
  return storedData ? JSON.parse(storedData) : {};
}

function saveChallengesToStorage(challengesObj: Record<string, Challenge>) {
  localStorage.setItem(challengesToClaimKey, JSON.stringify(challengesObj));
}

export function setChallengeToClaim(challengeId: string, walletAddress: string) {
  const challengesObj = loadChallengesFromStorage();
  const key = `${challengeId}-${walletAddress.toLowerCase()}`;

  if (challengesObj[key]) {
    return;
  }

  const challengesArray = Object.values(challengesObj);
  if (challengesArray.length >= 10) {
    const oldestKey = challengesArray.sort(
      (a, b) => new Date(a.addedAt).getTime() - new Date(b.addedAt).getTime(),
    )[0];
    delete challengesObj[`${oldestKey.challengeId}-${oldestKey.walletAddress.toLowerCase()}`];
  }

  challengesObj[key] = { challengeId, walletAddress, addedAt: new Date().toISOString() };

  saveChallengesToStorage(challengesObj);
}

export function useAutoClaimChallenges() {
  const queryClient = useQueryClient();
  const { completeChallenge } = useMutateChallengeComplete();
  const autoClaimChallengeEnabled = useIsFeatureEnabled('ocs_auto_claim_challenges');

  useEffect(
    function executeAutoClaimChallenges() {
      async function autoClaimChallenges() {
        if (!autoClaimChallengeEnabled) {
          return;
        }
        const challengesObj = loadChallengesFromStorage();
        const twoDaysAgo = new Date().getTime() - storageExpiry;

        const challengesToKeepObj: Record<string, Challenge> = {};

        for (const key in challengesObj) {
          if (Object.prototype.hasOwnProperty.call(challengesObj, key)) {
            const challenge = challengesObj[key];
            const challengeDate = new Date(challenge.addedAt).getTime();
            if (challengeDate > twoDaysAgo) {
              try {
                // eslint-disable-next-line no-await-in-loop
                const response = await new Promise((resolve, reject) => {
                  completeChallenge(
                    { challengeId: challenge.challengeId, walletAddress: challenge.walletAddress },
                    {
                      onSuccess: (completeResponse: unknown) => {
                        resolve(completeResponse);
                      },
                      onError: (error) => {
                        reject(error);
                      },
                    },
                  );
                });
                if ((response as ChallengeCompleteTypeError)?.message === 'failed-challenge') {
                  challengesToKeepObj[key] = challenge;
                }
              } catch (error) {
                challengesToKeepObj[key] = challenge;
              }
            }
          }
        }

        if (Object.keys(challengesToKeepObj).length !== Object.keys(challengesObj).length) {
          queryClient.invalidateQueries({ queryKey: ['getContentByTrendingUnauth'] });
          queryClient.invalidateQueries({ queryKey: ['game_userProfileState'] });
        }

        saveChallengesToStorage(challengesToKeepObj);
      }

      autoClaimChallenges();
    },
    [queryClient, completeChallenge, autoClaimChallengeEnabled],
  );
}

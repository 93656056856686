import { useEffect } from 'react';

import { isFeatureEnabledStore } from '../classes/IsFeatureEnabledStore';

import { useIsFeatureEnabled } from './useIsFeatureEnabled';

/**
 * useSyncIsFeatureEnabledStore
 *
 * Syncs Recoil state of isFeatureEnabled for a featureName to a local store which
 * can be accessed by consumers outside the React lifecycle
 */
export function useSyncIsFeatureEnabledStore() {
  const isFeTracingEnabled = useIsFeatureEnabled('fe_tracing');
  const isWalletProviderMipdEnabled = useIsFeatureEnabled('wallet_provider_mipd');
  const isCoinbaseWalletEnabled = useIsFeatureEnabled('wallet_provider_coinbase_wallet');
  const isPhantomEnabled = useIsFeatureEnabled('wallet_provider_phantom');
  const isWalletconnectEnabled = useIsFeatureEnabled('wallet_provider_walletconnect');

  // So getShouldMakePaymasterCall can access the values
  const isScwAppPaymastersEnabled = useIsFeatureEnabled('scw_app_paymasters');
  const isScwSepoliaPaymasterEnabled = useIsFeatureEnabled('scw_sepolia_paymaster');
  const isScwCloudPaymastersEnabled = useIsFeatureEnabled('scw_cloud_paymasters');

  useEffect(
    function setKillSwitchesInStore() {
      /* eslint-disable camelcase */
      isFeatureEnabledStore.setIsFeatureEnabled({
        fe_tracing: isFeTracingEnabled,
        wallet_provider_mipd: isWalletProviderMipdEnabled,
        wallet_provider_coinbase_wallet: isCoinbaseWalletEnabled,
        wallet_provider_phantom: isPhantomEnabled,
        wallet_provider_walletconnect: isWalletconnectEnabled,
        scw_app_paymasters: isScwAppPaymastersEnabled,
        scw_sepolia_paymaster: isScwSepoliaPaymasterEnabled,
        scw_cloud_paymasters: isScwCloudPaymastersEnabled,
      });
      /* eslint-enable camelcase */
    },
    [
      isFeTracingEnabled,
      isWalletProviderMipdEnabled,
      isCoinbaseWalletEnabled,
      isPhantomEnabled,
      isWalletconnectEnabled,
      isScwAppPaymastersEnabled,
      isScwSepoliaPaymasterEnabled,
      isScwCloudPaymastersEnabled,
    ],
  );
}

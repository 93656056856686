import { WalletGroupToDisplay } from 'cb-wallet-data/stores/WalletGroups/hooks/useWalletGroupToDisplay';
import { CellBaseProps, CellCommonProps } from '@cbhq/cds-common';
import { UiIconName } from '@cbhq/cds-icons';

export enum SettingCategory {
  WALLETS = 'wallets',
  MANAGE_ALL_WALLETS = 'manageAllWallets',
  NETWORK = 'network',
  SECURITY = 'security',
  DISPLAY = 'display',
  COINBASE_CONNECT = 'coinbaseConnect',
  NOTIFICATIONS = 'notifications',
  INTERNAL = 'internal',
  DEVELOPER = 'developer',
  HELP = 'help',
  LEGAL = 'legal',
  TOS = 'tos',
  PRIVACY_POLICY = 'privacyPolicy',
  REFERRAL = 'referral',
  VERSION = 'version',
  SUBCATEGORY = 'subCategory',
  SESSION = 'session',
  SMART_WALLET = 'smartWallet',
  REFERRAL_PROGRAM = 'referralProgram',
  REFERRAL_PROGRAM_CARD = 'referralProgramCard',
  PAYMENTS_MODE = 'paymentsMode',
}

export type SettingSection = {
  title: string;
};

export type SettingCellAccessoryType =
  | 'arrow'
  | 'more'
  | 'selected'
  | 'externalLink'
  | 'dotWithArrow';

export type SettingCategoryConfig = CellCommonProps & {
  title: React.ReactNode;
  titleText?: string; // used to generate search title incase title is a component
  description?: React.ReactNode;
  detail?: React.ReactNode;
  media?: SettingCategory | CellBaseProps['media'];
  accessory?: SettingCellAccessoryType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onPress?: (options?: Record<string, any>) => void;
  navigationPath?: string;
  isTouchableWithoutFeedback?: boolean;
  showDivider?: boolean;
  subCategories?: SettingCategoryConfig[];
  isHidden?: boolean;
  isHiddenOnSearch?: boolean;
  searchableTitle?: string;
  searchableDescription?: string;
  iconName?: UiIconName;
};

export type SubCategoryParam = Pick<
  SettingCategoryConfig,
  'title' | 'titleText' | 'onPress' | 'testID' | 'navigationPath'
> & {
  options?: Record<string, unknown>;
};

export enum NetworksSettingTabKey {
  MAINNETS = 'mainnets',
  TESTNETS = 'testnets',
  CUSTOM = 'custom',
}

export enum InternalSettingTabKey {
  DEBUG = 'debug',
  KILLSWITCHES = 'killSwitches',
  EXPERIMENTS = 'experiments',
  DELETE_CLOUD_BACKUP = 'delete_cloud_backup',
  CHAOS_TESTING = 'chaos_testing',
}

export type WalletsSubCategoryConfigsProps = {
  walletGroup: WalletGroupToDisplay;
  isHidden?: boolean;
};

export type KeyActionCategoryConfig = CellCommonProps & {
  title: React.ReactNode;
  description?: React.ReactNode;
  detail?: React.ReactNode;
  media?: SettingCategory | CellBaseProps['media'];
  accessory?: SettingCellAccessoryType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onPress?: (options?: Record<string, any>) => void;
  navigationPath?: string;
  subCategories?: KeyActionCategoryConfig[];
  iconName?: UiIconName;
};

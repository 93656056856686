import { CB_WALLET_API_URL, isNonExtensionUiTest, SOLANA_RPC_URL } from 'cb-wallet-env/env';
import { ChainUrlGroup } from 'wallet-engine-signing/signing/ethereum/urls';

const productionUrls = {
  SOLANA_MAINNET: {
    blockExplorerUrl: 'https://solana.fm',
    rpcUrl: SOLANA_RPC_URL,
  },
};

type E2eUrls = Record<
  keyof typeof productionUrls,
  Pick<ChainUrlGroup, 'blockExplorerUrl' | 'rpcUrl'>
>;

const e2eUrls: E2eUrls = Object.keys(productionUrls).reduce(
  function reduceE2eUrls(accumulator: Partial<E2eUrls>, key: string) {
    accumulator[key as keyof typeof productionUrls] = {
      blockExplorerUrl: `${CB_WALLET_API_URL}/mock-block-explorer`,
      rpcUrl: `${CB_WALLET_API_URL}/mock-chain-rpc`,
    };

    return accumulator;
  },
  {},
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) as any;

export const solanaUrls = isNonExtensionUiTest ? e2eUrls : productionUrls;

// Generic handler for websockets

export type WebsocketHandler = {
  connectWebsocket: () => WebSocket;
  disconnectWebsocket: () => void;
  sendMessage: (data: string) => void;
};

type RegisterWebsocketConnectionParams = {
  rpcURL: string;
  onMessage: (ev: MessageEvent<any>) => void;
  onOpen?: (ev: Event) => Promise<void>;
  onError?: (err: Event) => void;
};

export function registerWebsocketConnection({
  rpcURL,
  onMessage,
  onOpen,
  onError,
}: RegisterWebsocketConnectionParams): WebsocketHandler {
  let ws: WebSocket;
  const messageQueue: string[] = [];

  const flushMessageQueue = () => {
    while (ws && ws.readyState === ws.OPEN && messageQueue.length > 0) {
      const message = messageQueue.shift();
      if (message) {
        ws.send(message);
      }
    }
  };

  function connectWebsocket() {
    if (!ws || (ws && ws.readyState === ws.CLOSED)) {
      ws = new WebSocket(rpcURL);

      ws.onopen = (ev: Event) => {
        if (onOpen) onOpen(ev);
        flushMessageQueue();
      };

      ws.onerror = (err) => {
        if (onError) onError(err);
      };

      ws.onclose = () => {
        messageQueue.length = 0;
      };

      ws.onmessage = (event) => {
        onMessage(event);
      };
    }
    return ws;
  }

  function disconnectWebsocket() {
    if (ws) {
      ws.close();
    }
  }

  function sendMessage(message: string): void {
    if (ws && ws.readyState === ws.OPEN) {
      ws.send(message);
    } else if (ws && ws.readyState === ws.CONNECTING) {
      messageQueue.push(message);
    }
    // We don't care otherwise, we drop the message.
  }

  return {
    connectWebsocket,
    disconnectWebsocket,
    sendMessage,
  };
}

import memoize from 'lodash/memoize';

export type KeyPair = {
  publicKey: Buffer;
  privateKey: Buffer;
};

export async function xpubKeyFromSeed(seed: Buffer, derivationPath: string): Promise<string> {
  const { xpubKeyFromSeed: xpubKeyFromSeedCipherCore } = await import('cipher-core');
  return xpubKeyFromSeedCipherCore(seed, derivationPath);
}

// Only needed for signing
export async function secp256k1KeyPair(seed: Buffer, derivationPath: string): Promise<KeyPair> {
  const { deriveKeyPair: deriveKeyPairCipherCore } = await import('cipher-core');
  return deriveKeyPairCipherCore(seed, derivationPath);
}

export async function secp256k1PublicKey(seed: Buffer, derivationPath: string): Promise<Buffer> {
  const { deriveKeyPair: deriveKeyPairCipherCore } = await import('cipher-core');
  return deriveKeyPairCipherCore(seed, derivationPath).publicKey;
}

// Only needed for signing
export async function ed25519KeyPair(seed: Buffer, derivationPath: string): Promise<KeyPair> {
  const { deriveEd25519KeyPair: deriveEd25519KeyPairCipherCore } = await import('cipher-core');
  return deriveEd25519KeyPairCipherCore(seed, derivationPath);
}

export async function ed25519PublicKey(seed: Buffer, derivationPath: string): Promise<Buffer> {
  const { deriveEd25519KeyPair: deriveEd25519KeyPairCipherCore } = await import('cipher-core');
  return deriveEd25519KeyPairCipherCore(seed, derivationPath).publicKey;
}

/**
 * By default lodash uses only the first parameter as the cache key
 * Since the wordList is stable in order, and we only use different word lists
 * for different languages, we can just use the first word as the key
 */
function cacheKeyResolver(recoveryPhrase: string, wordList?: string[]) {
  if (!wordList) return recoveryPhrase;
  return `${recoveryPhrase}-${wordList[0]}`;
}

export const seedFromRecoveryPhrase = memoize(async function seedFromRecoveryPhrase(
  recoveryPhrase: string,
  wordList?: string[],
) {
  const { seedFromRecoveryPhrase: seedFromRecoveryPhraseCipherCore, findMatchingWordList } =
    await import('cipher-core');
  const inferredWordList = wordList || findMatchingWordList(recoveryPhrase);
  return seedFromRecoveryPhraseCipherCore(recoveryPhrase, inferredWordList);
},
cacheKeyResolver);

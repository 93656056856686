import { StoreKeys_debugMenuExperimentOverrides } from 'cb-wallet-data/stores/Wallets/storeKeys';
import { atomWithStorage } from 'cb-wallet-data/utils/objectAtomLocalStorageEffectJotai';
import { DataFromExperiment, ExperimentType } from '@cbhq/experiments';

export const debugMenuExperimentsOverridesAtom = atomWithStorage<
  DataFromExperiment<ExperimentType>[]
>({
  key: StoreKeys_debugMenuExperimentOverrides,
  initialValue: [],
});

import { useMemo } from 'react';
import { ETHEREUM_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { useAccounts } from 'cb-wallet-data/stores/Accounts/hooks/useAccounts';
import { useFetchDefiEarnPortfolio } from 'cb-wallet-data/stores/DefiPortfolio/api/useFetchDefiEarnPortfolio';
import { useParseEarnPortfolioResponse } from 'cb-wallet-data/stores/DefiPortfolio/hooks/useParseEarnPortfolioResponse';
import { EarnPortfolioAsset } from 'cb-wallet-data/stores/DefiPortfolio/models/EarnPortfolioResponse';

import { useFilterEarnPortfolioAssets } from './useFilterEarnPortfolioAssets';

export type UseDefiEarnPortfolioAssetsProps = {
  enabled?: boolean;
  selectedNetworkFilters?: string[];
  selectedWalletFilters?: string[];
};

export function useDefiEarnPortfolioAssets({
  selectedNetworkFilters,
  selectedWalletFilters,
  enabled = true,
}: UseDefiEarnPortfolioAssetsProps) {
  const accounts = useAccounts();
  const evmAddresses = useMemo(() => {
    return accounts
      .filter((account) => account.primaryAddressChain === ETHEREUM_SYMBOL)
      .map((account) => account.primaryAddress);
  }, [accounts]);

  const { response, error, isLoading } = useFetchDefiEarnPortfolio({ evmAddresses, enabled });
  const earnPortfolioAssets = useParseEarnPortfolioResponse({ earnPortfolioResponse: response });

  const filteredEarnPortfolioAssets = useFilterEarnPortfolioAssets({
    selectedNetworkFilters,
    selectedWalletFilters,
    portfolioAssets: earnPortfolioAssets,
  });

  return useMemo(() => {
    return {
      earnPortfolioAssets: filteredEarnPortfolioAssets as EarnPortfolioAsset[],
      error,
      isLoading,
    };
  }, [error, filteredEarnPortfolioAssets, isLoading]);
}

import { defineMessage, defineMessages, useIntl } from 'react-intl';
import { Button } from '@cbhq/cds-web/buttons/Button';
import { HeroSquare } from '@cbhq/cds-web/illustrations';
import { VStack } from '@cbhq/cds-web/layout';
import { TextTitle2 } from '@cbhq/cds-web/typography';

const messages = defineMessages({
  buttonCopy: {
    description: 'Go home',
    defaultMessage: 'Go home',
  },
  somethingWentWrong: {
    description: 'Error page copy',
    defaultMessage: 'Something went wrong',
  },
});

type GlobalErrorPageProps = {
  onDismiss?: () => void;
  dismissText?: ReturnType<typeof defineMessage>;
};

/**
 * Full screen error page with an action button
 */
export function GlobalErrorPage({ onDismiss, dismissText }: GlobalErrorPageProps) {
  const { formatMessage } = useIntl();

  return (
    <VStack height="100vh" justifyContent="center" alignItems="center" gap={3}>
      <HeroSquare name="errorRefresh" />
      <TextTitle2 as="h2" spacingTop={5} spacingBottom={2}>
        {formatMessage(messages.somethingWentWrong)}
      </TextTitle2>
      {onDismiss && (
        <Button testID="global-error-page-btn" onPress={onDismiss}>
          {formatMessage(dismissText ?? messages.buttonCopy)}
        </Button>
      )}
    </VStack>
  );
}

import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { getAllUserCollectibleSettings } from '../database';
import { getMapFromArrayById, userCollectibleSettingsAtom } from '../state';

/**
 * @returns A function that can be invoked in order to load User Collectible Settings from
 * IndexedDB/Sqlite into application state.
 */

export function useHydrateUserCollectibleSettingsFromDatabase(): () => Promise<void> {
  const setUserCollectibleSettings = useSetRecoilState(userCollectibleSettingsAtom);
  return useCallback(
    async function hydrateUserCollectibleSettingsFromDatabase() {
      const settings = await getAllUserCollectibleSettings();
      const collectibleSettingsMap = getMapFromArrayById(settings);
      setUserCollectibleSettings(collectibleSettingsMap);
    },
    [setUserCollectibleSettings],
  );
}

import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useToast } from '@cbhq/cds-web/overlays/useToast';

const messages = defineMessages({
  copied: {
    defaultMessage: 'URL copied to clipboard',
    description: 'Toast message when URL is copied to clipboard',
  },
});

type CopyToClipboardProps =
  | {
      successMessage?: string;
      hideCloseButton?: boolean;
    }
  | undefined;

export function useCopyToClipboard(props: CopyToClipboardProps = {}) {
  const toast = useToast();
  const { formatMessage } = useIntl();

  const handleCopy = useCallback(
    async (text: string) => {
      if (navigator?.clipboard) {
        await navigator.clipboard.writeText(text);
        toast.show(props.successMessage || formatMessage(messages.copied), {
          hideCloseButton: props.hideCloseButton || false,
        });
      } else {
        throw new Error('Clipboard API not available');
      }
    },
    [formatMessage, toast, props.successMessage, props.hideCloseButton],
  );

  return { handleCopy };
}

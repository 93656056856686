import { HardhatMetadata } from 'cb-wallet-data/chains/AccountBased/Ethereum/apis/EthereumRPC';
import {
  EthereumChain,
  EthereumNetworkMap,
} from 'cb-wallet-data/chains/AccountBased/Ethereum/EthereumChain';
import { NetworkCustomization } from 'cb-wallet-data/chains/AccountBased/Ethereum/models/CustomNetwork';
import { WalletConfigurationForEthereum } from 'cb-wallet-data/chains/AccountBased/Ethereum/models/EthereumBasedConfiguration';
import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { AddressType } from 'cb-wallet-data/stores/Addresses/models/AddressType';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import {
  NetworkSetting,
  NetworkSettingItem,
} from 'cb-wallet-data/stores/Networks/models/NetworkSetting';
import {
  TxOrUserOpMetadataKey,
  TxOrUserOpMetadataKind,
} from 'cb-wallet-data/stores/Transactions/models/TxOrUserOpMetadataKey';
import { CB_WALLET_API_URL } from 'cb-wallet-env/env';
import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';

import { ETHEREUM_CURRENCY_DECIMAL, ETHEREUM_PREFIX, ETHEREUM_SYMBOL } from './constants';

export const EthereumBlockchain = new Blockchain(ETHEREUM_SYMBOL);

export const EthereumAddressType = new AddressType('Ethereum');

export const EthereumNetworkSetting = makeETHNetworkSetting();

export const EthereumWalletConfiguration = new WalletConfigurationForEthereum({
  blockchain: EthereumBlockchain,
  networkSetting: EthereumNetworkSetting,
  refreshInterval: 30n,
  currencyCode: CurrencyCode.ETH,
  decimals: ETHEREUM_CURRENCY_DECIMAL,
  imageURL: 'https://wallet-api-production.s3.amazonaws.com/uploads/tokens/eth_288.png',
  defaultReceiveType: EthereumAddressType,
  supportsMultiWallet: true,
  isSyncingRequired: false,
  isERC20Supported: true,
});

export function isNetwork1559Enabled(network: Network): boolean {
  const chain = network.asChain();
  if (chain?.blockchainSymbol === 'ETH') {
    return chain.is1559Enabled;
  }

  return false;
}
/**
 *
 * @param network
 * @returns true if the passed in network is based on the OP stack (Optimism, Base, etc.)
 */
export function isNetworkOVMBased(network: Network | undefined): boolean {
  const chain = network?.asChain();
  if (chain?.blockchainSymbol === 'ETH') {
    return Boolean(chain.isOvmNetwork);
  }

  return false;
}

export function fromEthereumChain(chain: EthereumChain): NetworkSettingItem {
  return new NetworkSettingItem(chain.displayName, asNetwork(chain));
}

export function fromNetworkRawValue(rawValue: string): EthereumChain | undefined {
  const chainId = chainIdFromNetworkRawValue(rawValue);

  if (!chainId) {
    return undefined;
  }

  return EthereumNetworkMap.fromChainId(chainId);
}

export function chainIdFromNetworkRawValue(rawValue: string): bigint | undefined {
  const pieces = rawValue.split('/');

  if (pieces.length !== 2) {
    return undefined;
  }

  const prefix = pieces[0];
  const prefixPieces = prefix?.split(':') ?? [];

  if (prefixPieces.length !== 2 || ETHEREUM_PREFIX !== prefixPieces[0]) {
    return undefined;
  }

  const chainIdStr = prefixPieces[1];

  if (!chainIdStr) {
    return undefined;
  }

  return BigInt(chainIdStr);
}

export function asNetwork(chain: EthereumChain): Network {
  return new Network(`${ETHEREUM_PREFIX}:${chain.chainId}`, chain.isTestnet);
}

export const SelectedChain = EthereumNetworkMap.whitelisted.ETHEREUM_MAINNET;

/**
 * Current block height for the given chainId
 */
export function StoreKeys_ethereumBlockHeight(chainId: bigint): LocalStorageStoreKey<bigint> {
  return new LocalStorageStoreKey('ethereum_block_height', chainId.toString());
}

/**
 * Hardhat Metadata when connected to localhost with chain id 31337
 */
export const StoreKeys_hardhatNetwork = new LocalStorageStoreKey<HardhatMetadata | undefined>(
  'hardhatNetwork',
);

export const TxOrUserOpMetadataKey_txSource = new TxOrUserOpMetadataKey(
  'txSource',
  TxOrUserOpMetadataKind.string,
);

export const TxOrUserOpMetadataKey_approvalKey = new TxOrUserOpMetadataKey(
  'approvalKey',
  TxOrUserOpMetadataKind.string,
);

export const TxOrUserOpMetadataKey_sendNFTId = new TxOrUserOpMetadataKey(
  'sendNFTId',
  TxOrUserOpMetadataKind.string,
);

/** unique identifier needed for analyzing solana transactions which lack an identifier we can use for analytics sql joins */
export const TxOrUserOpMetadataKey_analyticsId = new TxOrUserOpMetadataKey(
  'analyticsId',
  TxOrUserOpMetadataKind.string,
);

export const TxOrUserOpMetadataKey_contactRecordID = new TxOrUserOpMetadataKey(
  'contactRecordID',
  TxOrUserOpMetadataKind.string,
);

export function makeETHNetworkSetting(): NetworkSetting {
  const defaultMainnet = new NetworkSettingItem(
    'Ethereum',
    asNetwork(EthereumNetworkMap.whitelisted.ETHEREUM_MAINNET),
  );

  const allValues: EthereumChain[] = [];
  for (const ethChain of Object.values(EthereumNetworkMap.whitelisted)) {
    if (!isNaN(ethChain?.chainId)) {
      allValues.push(EthereumNetworkMap.fromChainId(BigInt(ethChain.chainId))!);
    }
  }

  const mainnets: NetworkSettingItem[] = [defaultMainnet];
  const testnets: NetworkSettingItem[] = [];

  allValues
    .filter((chain) => {
      return chain.chainId !== EthereumNetworkMap.whitelisted.ETHEREUM_MAINNET.chainId;
    })
    .map((chain) => fromEthereumChain(chain))
    .sort(function sortChainsByName(chain1, chain2) {
      const chain1Name = chain1.name.toLowerCase();
      const chain2Name = chain2.name.toLowerCase();

      if (chain1Name < chain2Name) return -1;
      if (chain1Name > chain2Name) return 1;

      return 0;
    })
    .forEach(function filterChainsByType(chain) {
      const item = new NetworkSettingItem(chain.name, chain.network);
      if (chain.network.isTestnet) {
        testnets.push(item);
      } else {
        mainnets.push(item);
      }
    });

  return new NetworkSetting(EthereumBlockchain, defaultMainnet, mainnets, testnets);
}

/**
 * Returns the derivation path for the ethereum address at the given index
 *
 * @param index Index of the wallet
 *
 * @return The derivation path
 */
export function ethereumAddressDerivationPath(index: bigint): string {
  return `m/44'/60'/0'/0/${index}`;
}

type EthereumNetwork = {
  name: string;
  rpcUrl: string;
  gasStationUrl: string;
};

export const EthereumNetworkConfigs = new Map<string, EthereumNetwork>(
  EthereumNetworkSetting.allNetworks.map(
    // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
    // eslint-disable-next-line wallet/no-anonymous-params
    (networkSetting) => {
      const ethereumChain = networkSetting.network.asChain() as EthereumChain;
      const chainId: string = ethereumChain.chainId.toString();
      let gasStationUrl = `${CB_WALLET_API_URL}/rpc/v2/getMainnetGasPrices`;
      if (parseInt(chainId, 10) !== EthereumNetworkMap.whitelisted.ETHEREUM_MAINNET.chainId) {
        gasStationUrl = '';
      }
      const ethereumConfig = {
        name: ethereumChain.displayName,
        rpcUrl: ethereumChain.rpcUrl,
        gasStationUrl,
      };
      return [chainId, ethereumConfig];
    },
  ),
);

export function appendCustomNetworks(customNetworks: NetworkCustomization[]): void {
  const networkList: Record<string, EthereumNetwork> = {};
  for (const network of customNetworks) {
    networkList[network.chainId] = {
      name: network.chainName,
      rpcUrl: network.rpcUrls[0],
      gasStationUrl: '',
    };

    // This is a temporary measure, we will eventually check whether
    // a network is valid or not by reading from the database instead of EthereumNetworkMap.fromChainId
    const networkIconUrl =
      network.iconUrls && network.iconUrls.length > 0 ? network.iconUrls[0] : '';
    EthereumNetworkMap.addNetwork(network.chainId, {
      blockchainSymbol: 'ETH',
      isNonEthChain: false,
      chainId: Number(network.chainId),
      chainImageUrl: networkIconUrl,
      baseAssetCurrencyCode: network.nativeCurrency.symbol ?? 'ETH',
      baseAssetImageUrl: networkIconUrl,
      baseAssetDisplayName: network.nativeCurrency.name,
      baseAssetDecimal: network.nativeCurrency.decimals,
      displayName: network.chainName,
      isTestnet: network.isTestnet || false,
      isLayer2: true,
      is1559Enabled: false,
      isCustomNetwork: true,
      blockExplorerUrl: network.blockExplorerUrls[0],
      rpcUrl: network.rpcUrls[0],
      etherscanCompatibleTxHistoryApi: '',
      etherscanLikeApiKey: '',
      wacNetworkId: '',
    });
  }

  appendEthereumNetworkConfigs(networkList);
}

export function removeCustomNetwork(chainId: string) {
  EthereumNetworkMap.removeNetwork(chainId);
  EthereumNetworkConfigs.delete(chainId.toString());
}

export function updateWhitelistedNetwork(customNetworks: NetworkCustomization[]): void {
  const networkList: Record<string, EthereumNetwork> = {};
  for (const network of customNetworks) {
    if (EthereumNetworkMap.fromChainId(BigInt(network.chainId))) {
      networkList[network.chainId] = {
        name: network.chainName,
        rpcUrl: network.rpcUrls[0],
        gasStationUrl: '',
      };

      // This is a temporary meassure, we will eventually check whether
      // a network is valid or not by reading from the database instead of EthereumNetworkMap.fromChainId
      const chainOnFile = EthereumNetworkMap.fromChainId(BigInt(network.chainId))!;

      EthereumNetworkMap.addNetwork(network.chainId, {
        ...chainOnFile,
        blockchainSymbol: 'ETH',
        isNonEthChain: false,
        chainImageUrl: network.chainImageUrl ?? chainOnFile.baseAssetImageUrl,
        baseAssetDisplayName: network.baseAssetDisplayName || chainOnFile.baseAssetDisplayName,
        displayName: network.chainName,
        blockExplorerUrl: network.blockExplorerUrls[0],
        rpcUrl: network.rpcUrls[0],
      });
    }
  }

  appendEthereumNetworkConfigs(networkList);
}

export function revertWhitelistedNetwork(chainId: string): void {
  if (EthereumNetworkMap.isWhiteListedAndCustomized(chainId)) {
    EthereumNetworkMap.removeCustomizationForWhitelisted(chainId);
    const revertedChain = EthereumNetworkMap.fromChainId(BigInt(chainId));
    if (revertedChain) {
      let gasStationUrl = `${CB_WALLET_API_URL}/rpc/v2/getMainnetGasPrices`;
      if (parseInt(chainId, 10) !== EthereumNetworkMap.whitelisted.ETHEREUM_MAINNET.chainId) {
        gasStationUrl = '';
      }
      const ethereumConfig: EthereumNetwork = {
        name: revertedChain.displayName,
        rpcUrl: revertedChain.rpcUrl,
        gasStationUrl,
      };

      const networkList: Record<string, EthereumNetwork> = {};
      networkList[chainId] = ethereumConfig;
      appendEthereumNetworkConfigs(networkList);
    }
  }
}

function appendEthereumNetworkConfigs(ethereumConfigs: Record<string, EthereumNetwork>) {
  for (const [key, value] of Object.entries(ethereumConfigs)) {
    if (value) {
      EthereumNetworkConfigs.set(key, value);
    }
  }
}

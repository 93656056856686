import { useEffect, useMemo } from 'react';
import { isSimulatingEmptyWalletAtom } from 'cb-wallet-data/hooks/DebugMenu/state';
import { useDebounce } from 'cb-wallet-data/hooks/useDebounce';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { getPlatformName } from 'cb-wallet-metadata/metadata';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { PlatformName } from '@cbhq/client-analytics';

import {
  portfolioWalletGroupIdsAtom,
  portfolioWalletsByWalletGroupAtom,
  walletsByWalletGroupAtom,
} from '../state';

import { useBasePortfolioWalletsByWalletGroup } from './usePortfolioWallets';

export function useCachePortfolioWallets(walletGroupIds: (string | undefined)[]) {
  const isSimulatingEmptyWallet = useRecoilValue(isSimulatingEmptyWalletAtom);
  const setPortfolioWallets = useSetRecoilState(portfolioWalletsByWalletGroupAtom);
  const allWalletsByWalletGroup = useRecoilValue(walletsByWalletGroupAtom);
  const selectedWalletsByWalletGroup = useMemo(() => {
    return Object.entries(allWalletsByWalletGroup).reduce(function selectWalletsByWalletGroup(
      acc,
      [walletGroupId, wallets],
    ) {
      if (!walletGroupIds.includes(walletGroupId)) {
        return acc;
      }
      acc[walletGroupId] = wallets;
      return acc;
    },
    {} as Record<string, Wallet[]>);
  }, [allWalletsByWalletGroup, walletGroupIds]);

  const setPortfolioWalletGroupIds = useSetRecoilState(portfolioWalletGroupIdsAtom);

  /**
   * We debounce access to wallets since balances are fetched in multiple
   * passes, leading to several updates for one fetch cycle. We don't want to trigger
   * rerenders for the partial updates, just when all of them are finished, so we
   * debounce the value for 200ms
   *
   * We do not debounce on RN because of changes made to wallets loading made it
   * unnecessary. Removing the debounce for Extension led to instability in
   * and caused functional test failures. We should investigate why this happens
   * and make necessary changes to remove the debounce on both platforms.
   * JIRA ticket: https://jira.coinbase-corp.com/browse/WALL-20429
   */
  // TODO: Remove this and make it a selector
  const platform = useMemo(() => getPlatformName(), []);

  const debouncedWallets = useDebounce(
    selectedWalletsByWalletGroup,
    platform === PlatformName.extension ? 200 : null,
  );
  const portfolioWalletsByWalletGroup = useBasePortfolioWalletsByWalletGroup({
    walletsByWalletGroup: debouncedWallets,
    includeCustomNetworks: true,
  });

  useEffect(() => {
    setPortfolioWalletGroupIds(walletGroupIds);
  }, [setPortfolioWalletGroupIds, walletGroupIds]);

  useEffect(
    function computeAndCachePortfolioWallets() {
      if (isSimulatingEmptyWallet) {
        let emptyWallet;
        if (portfolioWalletsByWalletGroup !== undefined) {
          emptyWallet = Object.keys(portfolioWalletsByWalletGroup).reduce(
            function createEmptyWalletsByWalletGroup(acc, walletGroupId) {
              acc.set(walletGroupId, []);
              return acc;
            },
            new Map(),
          );
        }
        setPortfolioWallets(emptyWallet);
      } else {
        setPortfolioWallets(portfolioWalletsByWalletGroup);
      }
    },
    [isSimulatingEmptyWallet, portfolioWalletsByWalletGroup, setPortfolioWallets],
  );
}

import toLower from 'lodash/toLower';
import { EIP1193Provider } from 'viem';

import { SigningMethodError, SigningMethodErrorType } from '../SigningMethodError';

/**
 * Validate that the address you're trying to sign with is connected to your wallet
 *
 * @param {EIP1193Provider} provider web3 provider
 * @param {string} address `0x` prefixed ethereum address
 * @throws {SigningMethodError} SigningMethodErrorType.WrongSelectedAddress - the address you're trying to sign with is not connected to your wallet
 */
export async function validateKnownEthereumAddress(provider: EIP1193Provider, address: string) {
  const addresses = await provider.request({
    method: 'eth_accounts',
  });
  const hasValidAddress = addresses
    .map((_address) => toLower(_address.split(':')[0]))
    .includes(toLower(address));
  if (!hasValidAddress) {
    throw new SigningMethodError(
      SigningMethodErrorType.WrongSelectedAddress,
      "the address you're trying to sign with is not connected to your wallet",
    );
  }
  return true;
}

import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { AccountType } from 'cb-wallet-data/stores/Accounts/models/AccountTypes';
import {
  activeWalletGroupIdAtom,
  walletGroupsAtom,
} from 'cb-wallet-data/stores/WalletGroups/state';
import { atomLocalStorageEffect } from 'cb-wallet-data/utils/atomLocalStorageEffect';
import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import Decimal from 'decimal.js';
import { atom, selector, selectorFamily } from 'recoil';

import { fiatBalanceByWalletGroupAtom } from '../Wallets/state';

const DEFAULT_ACCOUNTS: Account[] = [];

export const accountsAtom = atom<Account[]>({
  key: 'accounts',
  default: DEFAULT_ACCOUNTS,
});

export const activeAccountIdSelector = selector<Account['id'] | undefined>({
  key: 'activeAccountId',
  get: function getActiveAccountId({ get }) {
    const walletGroups = get(walletGroupsAtom);

    const activeWalletGroupId = get(activeWalletGroupIdAtom);
    const activeWalletGroup = walletGroups?.find((wg) => wg.id === activeWalletGroupId);
    return activeWalletGroup?.accountId;
  },
});

/**
 * Uses the existing active account ID selector and returns its associated `Account` object.
 */
export const activeAccountSelector = selector<Account | undefined>({
  key: 'activeAccount',
  get: function getActiveAccount({ get }) {
    const activeAccountId = get(activeAccountIdSelector);
    const accounts = get(accountsAtom);

    return accounts.find((account) => account.id === activeAccountId);
  },
});

export const firstCreatedAccountSelector = selector<Account | undefined>({
  key: 'firstCreatedAccount',
  get: function getFirstCreatedAccount({ get }) {
    const accounts = get(accountsAtom);
    const [firstAccount] = [...accounts].sort(
      (a, b) => a.createdAt.getTime() - b.createdAt.getTime(),
    );

    return firstAccount ?? undefined;
  },
});

export const firstCreatedMnemonicAccountSelector = selector<Account | undefined>({
  key: 'firstCreatedMnemonicAccount',
  get: function getFirstCreatedMnemonicAccount({ get }) {
    const accounts = get(accountsAtom);
    const [firstAccount] = [...accounts]
      .filter(({ isMnemonicAccount }) => isMnemonicAccount)
      .sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());

    return firstAccount ?? undefined;
  },
});

export type AccountTotals = Record<AccountType, number>;

/**
 * This selector will return a mapping of `AccountType` to number of `Account` objects in Recoil state.
 */
export const accountTotalsSelector = selector<AccountTotals>({
  key: 'accountTotals',
  get: function getAccountTotals({ get }) {
    const defaultAccountTotals = {
      [AccountType.MNEMONIC]: 0,
      [AccountType.SCW]: 0,
      [AccountType.WALLET_LINK]: 0,
      [AccountType.LEDGER]: 0,
      [AccountType.TREZOR]: 0,
      [AccountType.PRIVATE_KEY]: 0,
      [AccountType.DAPP_PROVIDER]: 0,
    };

    return get(accountsAtom).reduce<AccountTotals>(function reduceAccountsToTotal(
      accumulator,
      account,
    ) {
      const { type: accountType } = account;

      accumulator[accountType] += 1;

      return accumulator;
    },
    defaultAccountTotals);
  },
});

export type AccountsByAccountType = Record<AccountType, Account[]>;

export const accountsByAccountTypeSelector = selector<AccountsByAccountType>({
  key: 'accountsByAccountType',
  get: function getAccountsByAccountType({ get }) {
    const allAccounts = get(accountsAtom);
    const emptyAccountsByAccountType = {
      [AccountType.MNEMONIC]: [],
      [AccountType.SCW]: [],
      [AccountType.WALLET_LINK]: [],
      [AccountType.LEDGER]: [],
      [AccountType.TREZOR]: [],
      [AccountType.PRIVATE_KEY]: [],
      [AccountType.DAPP_PROVIDER]: [],
    };

    return allAccounts.reduce<AccountsByAccountType>(function reduceAccountsByType(
      reduceResults,
      account,
    ) {
      reduceResults[account.type].push(account);

      return reduceResults;
    },
    emptyAccountsByAccountType);
  },
});

/**
 * SelectorFamily to return accounts filtered by account type
 * @param accountType - the AccountType to filter with @see {@link AccountType}
 */
export const accountsByAccountTypeSelectorFamily = selectorFamily<Account[], AccountType>({
  key: 'accountsByAccountTypeSelectorFamily',
  get:
    (accountType: AccountType) =>
    ({ get }) => {
      const accounts = get(accountsAtom);
      return accounts
        .filter(({ type }) => type === accountType)
        .sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());
    },
});

/**
 * SelectorFamily to return the total fiat balance for a given account
 *
 */

export const accountBalanceByAccountIdSelectorFamily = selectorFamily<Decimal, Account['id']>({
  key: 'accountBalanceByAccountIdSelectorFamily',
  get:
    (accountId: Account['id']) =>
    ({ get }) => {
      const fiatBalancesByWalletGroup = get(fiatBalanceByWalletGroupAtom);
      const walletGroups = get(walletGroupsAtom);
      const walletGroupsForAccount = walletGroups.filter(
        (walletGroup) => walletGroup.accountId === accountId,
      );
      const totalAccountBalance = walletGroupsForAccount.reduce(function totalBalanceOfAccount(
        accumulatorAccountBalance,
        walletGroup,
      ) {
        const walletGroupBalance = fiatBalancesByWalletGroup[walletGroup.id];
        return accumulatorAccountBalance.add(walletGroupBalance || new Decimal(0));
      },
      new Decimal(0));
      return totalAccountBalance;
    },
});

/**
 * All user-facing accounts.
 *
 * User-facing accounts are determined by gathering all accounts, and combining any "rollup" type accounts
 * (e.g. Ledger, Private Key) into a single account for their respective types.
 *
 * @see useUserFacingAccountsData
 */
export const userFacingAccountsSelector = selector<Account[]>({
  key: 'userFacingAccountsSelector',
  get: function getUserFacingAccounts({ get }) {
    const accountsByAccountType = get(accountsByAccountTypeSelector);

    // Use first-created ledger account, which the UI can nest all ledger accounts under for display.
    const ledgerAccounts = [...accountsByAccountType[AccountType.LEDGER]];
    ledgerAccounts.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());
    const includedLedgerAccounts = ledgerAccounts.length > 0 ? [ledgerAccounts[0]] : [];

    // Use first-created scw account, which the UI can nest all scw accounts under for display.
    const scwAccounts = [...accountsByAccountType[AccountType.SCW]];
    scwAccounts.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());
    const includedSCWAccounts = scwAccounts.length > 0 ? [scwAccounts[0]] : [];

    // Use first-created private key account, which the UI can nest all private key accounts under for display.
    const privateKeyAccounts = [...accountsByAccountType[AccountType.PRIVATE_KEY]];
    privateKeyAccounts.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());
    const includedPrivateKeyAccounts = privateKeyAccounts.length > 0 ? [privateKeyAccounts[0]] : [];

    return [
      ...accountsByAccountType[AccountType.MNEMONIC],
      ...accountsByAccountType[AccountType.WALLET_LINK],
      ...accountsByAccountType[AccountType.DAPP_PROVIDER],
      ...includedPrivateKeyAccounts,
      ...includedLedgerAccounts,
      ...includedSCWAccounts,
    ];
  },
});

/**
 * All user-facing accounts, sorted ascending by createdAt date (oldest first).
 */
export const userFacingAccountsSortedByCreatedAtSelector = selector<Account[]>({
  key: 'userFacingAccountsSortedByCreatedAt',
  get: function getUserFacingAccountsSortedByCreatedAt({ get }) {
    const userFacingAccounts = get(userFacingAccountsSelector);
    return [...userFacingAccounts].sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());
  },
});

export const nonMultiAccountRollupAccountsSelector = selector<Account[]>({
  key: 'nonMultiAccountRollupAccountsSelector',
  get: ({ get }) => get(accountsAtom).filter(({ isMultiAccountRollup }) => !isMultiAccountRollup),
});

/**
 * A *read-only* mapping of `Account` ID strings and a boolean to indicate if the `Account` is backed up to the cloud or not.
 *
 * @see https://github.com/facebookexperimental/Recoil/issues/855#issuecomment-772308194
 *
 * __NOTE:__ We intentionally kept this a simple string->boolean instead of string->object to avoid having this become a dumping ground
 * of multi-purpose and mixed values.
 */
export type AccountCloudBackupStatusMap = ReadonlyMap<Account['id'], boolean>;

export const accountCloudBackupStatusAtom = atom<AccountCloudBackupStatusMap>({
  key: 'accountCloudBackupStatus',
  default: new Map<Account['id'], boolean>(),
});

/**
 * It is critical that these match up to Account# methods that return a boolean.
 *
 * TODO Create an interface for Account that ensures these methods
 */
const accountConditionals = [
  'signsViaExtension',
  'supportsMultiWallet',
  'hasLocallyStoredKey',
  'isHardwareAccount',
  'isMultiAccountRollup',
] as const;

export type AccountConditionalType = (typeof accountConditionals)[number];

export type AccountConditionalParams = {
  conditional: AccountConditionalType;
  inverse?: boolean;
};

/**
 * SelectorFamily to return accounts based on conditionals that exist on the account instance
 * @param conditional - the string name of the condition to check on the account instance @see {@link AccountConditionalType}
 * @param inverse - a boolean to invert the condition check
 */
export const accountsByConditionalSelectorFamily = selectorFamily<
  Account[],
  AccountConditionalParams
>({
  key: 'accountsByConditionalSelectorFamily',
  get:
    ({ conditional, inverse = false }: AccountConditionalParams) =>
    ({ get }) => {
      const accounts: Account[] = get(accountsAtom);
      return accounts.filter((account) => {
        return inverse ? !account[conditional] : account[conditional];
      });
    },
});

const StoreKeys_recoveryPhraseTrayViewed = new LocalStorageStoreKey<boolean>(
  'StoreKeys_recoveryPhraseTrayViewed',
);
export const recoveryPhraseTrayViewedAtom = atom<boolean>({
  key: 'recoveryPhraseTrayViewedAtom',
  default: undefined,
  effects: [atomLocalStorageEffect(StoreKeys_recoveryPhraseTrayViewed)],
});

/**
 * Used to block any other related integrity checks or repair scripts
 * while this repair is in progress.
 *
 * RN:
 * - SignedInNavigator is blocked from rendering in RootStack until this flag is set back to false.
 * - This prevents SignedInNavigator useOnMount from backfilling wallets before repair is complete.
 *
 * Ext:
 * - TODO: https://jira.coinbase-corp.com/browse/WALL-29718
 */
export const isRepairingSecretAccountsAtom = atom<boolean | undefined>({
  key: 'isRepairingSecretAccounts',
  default: undefined,
});

import { useEffect, useMemo, useState } from 'react';

import { fetchDomains } from './usePublicProfileByAddress';

export function useFetchDomains(
  addresses: string[],
): Record<string, string | undefined> | undefined {
  const [domains, setDomains] = useState<Record<string, string | undefined> | undefined>(undefined);

  useEffect(
    function resolveSubdomainForAddress() {
      (async () => {
        const fetchedDomains = await fetchDomains(addresses);
        if (fetchedDomains?.length) {
          const domainsRecord = addresses.reduce(function mapAddressToDomain(acc, address, index) {
            acc[address] = fetchedDomains[index];
            return acc;
          }, {} as Record<string, string | undefined>);
          setDomains(domainsRecord);
        }
      })();
    },
    [addresses],
  );

  return domains;
}

export function useFetchDomain(address: string): string | undefined {
  const addresses = useMemo(() => [address], [address]);

  const domainRecord = useFetchDomains(addresses);

  return useMemo(() => domainRecord?.[address], [address, domainRecord]);
}

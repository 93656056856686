import { useMemo } from 'react';
import { ETHEREUM_PREFIX } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { SOLANA_PREFIX } from 'cb-wallet-data/chains/AccountBased/Solana/utils/chain';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import {
  AccountType,
  AccountTypeHeaderMap,
} from 'cb-wallet-data/stores/Accounts/models/AccountTypes';
import { activeAccountSelector } from 'cb-wallet-data/stores/Accounts/state';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { SupportedChain } from 'cb-wallet-data/stores/Networks/models/SupportedChain';
import { SupportedSwapChainsResponse } from 'cb-wallet-data/stores/Swap/types/SupportedSwapChainsResponse';
import { getJSON } from 'cb-wallet-http/fetchJSON';
import { useRecoilValue } from 'recoil';

import { useSwapByChainKillSwitch } from './useSwapByChainKillSwitch';

export const SUPPORTED_CHAINS_QUERY_KEY = 'swap/supportedChains';

export function getPrefix(chainId: bigint) {
  switch (Number(chainId)) {
    case 101:
      return SOLANA_PREFIX;
    default:
      return ETHEREUM_PREFIX;
  }
}

async function fetchSupportedChains(accountType?: AccountType): Promise<SupportedChain[]> {
  const { result } = await getJSON<{ result: SupportedSwapChainsResponse }>(
    SUPPORTED_CHAINS_QUERY_KEY,
    {},
    {
      ...(accountType ? { walletAccountType: AccountTypeHeaderMap[accountType] } : {}),
    },
  );
  return result.chains;
}

export function useSupportedSwapChains(): SupportedChain[] {
  const isKillSwitched = useSwapByChainKillSwitch();
  const activeAccount = useRecoilValue(activeAccountSelector);
  const { data } = useQuery(
    [SUPPORTED_CHAINS_QUERY_KEY],
    async function getSupportedChains() {
      return fetchSupportedChains(activeAccount?.type);
    },
    {
      suspense: true,
      staleTime: 1000 * 30,
      notifyOnChangeProps: ['data'],
    },
  );

  return useMemo(() => {
    return (
      data?.reduce(function reduceSupportedChains(chains: SupportedChain[], item: SupportedChain) {
        const isDisabled = isKillSwitched(item.chainId);

        const chainId = BigInt(item.chainId);

        chains.push({ name: item.name, chainId, prefix: getPrefix(chainId), isDisabled });

        return chains;
      }, []) || []
    );
  }, [data, isKillSwitched]);
}

export function useSupportedSwapChainsMap() {
  const supportedNetworks = useSupportedSwapChains();
  return useMemo(
    () =>
      new Map(
        supportedNetworks.map(function getChainTuple(networks) {
          const networkRawValue = Network.fromSupportedChain(networks).rawValue;
          return [networkRawValue, networks];
        }),
      ),
    [supportedNetworks],
  );
}

import { useCallback, useEffect, useState } from 'react';
import {
  triggerAccountsWalletGroupsHydrationStart,
  triggerAccountsWalletGroupsHydrationSuccess,
  triggerAccountsWalletGroupsPresencePostHydration,
} from 'cb-wallet-analytics/app-load';
import { useAccounts } from 'cb-wallet-data/stores/Accounts/hooks/useAccounts';
import { useHydrateAccounts } from 'cb-wallet-data/stores/Accounts/hooks/useHydrateAccounts';
import { useHydrateUserWalletSettingsFromDatabase } from 'cb-wallet-data/stores/AssetManagement/hooks/useHydrateUserWalletSettings';
import { useHydrateWalletGroups } from 'cb-wallet-data/stores/WalletGroups/hooks/useHydrateWalletGroups';
import { useWalletGroups } from 'cb-wallet-data/stores/WalletGroups/hooks/useWalletGroups';
import { hydrateWallets } from 'cb-wallet-data/stores/Wallets/database';

/**
 * Performs all required hydration from the DB to Recoil state.
 * Also performs state checks that repair the database in case of incorrectly formatted data
 */
export function useHydrateDb() {
  // internal state
  const [areAccountsWalletGroupsHydrated, setAreAccountsWalletGroupsHydrated] = useState(false);

  const accounts = useAccounts();
  const walletGroups = useWalletGroups();

  const hydrateAccounts = useHydrateAccounts();
  const hydrateWalletGroups = useHydrateWalletGroups();
  const hydrateUserWalletSettings = useHydrateUserWalletSettingsFromDatabase();

  useEffect(
    // For observability, logs out accounts, wallet groups lengths after
    // hydration. This should only log once.
    function logAccountsWalletGroupsAfterHydration() {
      if (areAccountsWalletGroupsHydrated) {
        triggerAccountsWalletGroupsPresencePostHydration({
          accountsLength: accounts.length,
          walletGroupsLength: walletGroups.length,
        });
      }
    },
    [accounts.length, areAccountsWalletGroupsHydrated, walletGroups.length],
  );

  return useCallback(
    async function hydrateDb() {
      await hydrateWallets();

      // NOTE Hydration can occur after this line
      // hydrate accounts and walletGroups from db
      triggerAccountsWalletGroupsHydrationStart();
      // NOTE There is tracking internally within each hydration
      await Promise.all([hydrateAccounts(), hydrateWalletGroups()]);
      setAreAccountsWalletGroupsHydrated(true);
      triggerAccountsWalletGroupsHydrationSuccess();

      // User wallet settings are used to render the Assets screen, so
      // hydrating them as soon as possible reduces load times and jank
      await hydrateUserWalletSettings();
    },
    [hydrateAccounts, hydrateWalletGroups, hydrateUserWalletSettings],
  );
}

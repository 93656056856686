import { useCallback, useEffect, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { useAccountsWithBalances } from 'cb-wallet-data/stores/Accounts/hooks/useAccountsWithBalances';
import { OnrampAsset } from 'cb-wallet-data/stores/Buy/models/OnrampAsset';
import { useAddTrackedExchangeRate } from 'cb-wallet-data/stores/ExchangeRates/hooks/useAddTrackedExchangeRate';
import { computeOnrampAssetSearchResults } from 'cb-wallet-data/utils/search/computeOnrampAssetSearchResults';
import { AssetAvatar } from 'wallet-cds-web/components/AssetAvatar';
import { StepperNavbar } from 'wallet-cds-web/components/StepperNavbar';
import useStepper from 'wallet-cds-web/external-libs/stepper/hooks/useStepper';
import { CellSpacing } from '@cbhq/cds-common';
import { ListCell } from '@cbhq/cds-web/cells';
import { SearchInput } from '@cbhq/cds-web/controls/SearchInput';
import { VStack } from '@cbhq/cds-web/layout';
import { TextLabel2 } from '@cbhq/cds-web/typography';

import { usePanoOnrampContext } from ':dapp/providers/PanoOnrampContext';

import { stepper } from '../BuyFlowStepper/flow';
import { NetworkSwitcherDropdown } from '../NetworkSwitcher/NetworkSwitcher';

const ONRAMP_ENTRY_ASSET_DETAIL_WIDTH = 185;
const SELECT_ASSET_ROW_HEIGHT = 80;
const SELECT_ASSET_ROWS_HEIGHT = 400;

const messages = defineMessages({
  selectAsset: {
    defaultMessage: 'Select asset',
    description: 'Onramp flow to select asset',
  },
});

export function AssetSelectorContent() {
  const { formatMessage } = useIntl();
  const { goBack } = useStepper(stepper);
  const [query, setQuery] = useState('');
  const cellSpacing = useMemo<CellSpacing>(() => {
    return {
      spacingHorizontal: 2,
      spacingVertical: 0,
    };
  }, []);
  const {
    assets = [],
    setSelectedAsset,
    selectedAsset,
    setSelectedWallet,
    sourceWallet,
  } = usePanoOnrampContext();

  const assetsByNetwork = useMemo(() => {
    if (selectedAsset?.networkId === 'all-networks') return assets;

    return assets?.filter((asset) => asset.networkId === selectedAsset?.networkId);
  }, [assets, selectedAsset?.networkId]);

  const filteredAssets = useMemo(() => {
    return computeOnrampAssetSearchResults({
      assets: assetsByNetwork,
      query,
    });
  }, [assetsByNetwork, query]);

  const addTrackedRate = useAddTrackedExchangeRate();
  const accountsWithBalance = useAccountsWithBalances();
  const handleAssetChange = useCallback(
    (asset: OnrampAsset) => () => {
      addTrackedRate({
        code: new CurrencyCode(asset.code),
        chainId: BigInt(asset.chainId),
        // TODO: networkId in OnrampAsset is missing the 'networks/' prefix included in our standardized networkId format.
        networkId: `networks/${asset.networkId}`,
        contractAddress: asset?.contractAddress ?? '',
      });
      setSelectedAsset(asset);

      if (
        asset.code !== sourceWallet?.primaryAddressChain &&
        asset.networkLegacyCode !== sourceWallet?.primaryAddressChain
      ) {
        const searchWallet = accountsWithBalance.filter(
          (wallet) =>
            wallet.primaryAddressChain === asset?.code ||
            wallet.primaryAddressChain === asset?.networkLegacyCode,
        );
        if (searchWallet.length >= 1) {
          setSelectedWallet(searchWallet[0]);
        } else {
          setSelectedWallet(
            accountsWithBalance.filter((wallet) => wallet.primaryAddressChain === 'ETH')[0],
          );
        }
      }
      goBack();
    },
    [
      addTrackedRate,
      setSelectedAsset,
      sourceWallet?.primaryAddressChain,
      goBack,
      accountsWithBalance,
      setSelectedWallet,
    ],
  );

  const handleBackPressed = useCallback(() => {
    goBack();
  }, [goBack]);

  useEffect(
    function addTrackedRateAsset() {
      if (selectedAsset) {
        addTrackedRate({
          code: new CurrencyCode(selectedAsset.code),
          chainId: BigInt(selectedAsset.chainId),
          // TODO: networkId in OnrampAsset is missing the 'networks/' prefix included in our standardized networkId format.
          networkId: `networks/${selectedAsset.networkId}`,
          contractAddress: selectedAsset.contractAddress ?? '',
        });
      }
    },
    [addTrackedRate, selectedAsset],
  );

  const rowRenderer = useCallback(
    ({ index, key, style }: ListRowProps) => {
      const asset = filteredAssets[index];

      return (
        <div key={key} style={style}>
          <ListCell
            innerSpacing={cellSpacing}
            variant="positive"
            testID={`onramp-buy-asset-item-${asset.code}`}
            onPress={handleAssetChange(asset)}
            detailWidth={ONRAMP_ENTRY_ASSET_DETAIL_WIDTH}
            title={asset.name}
            description={
              <TextLabel2 as="span" color="foregroundMuted">
                {asset.code}
              </TextLabel2>
            }
            media={
              <AssetAvatar
                assetSrc={asset.imageUrl}
                assetCurrencyCode={asset.code}
                networkBadgeSrc={asset.network ? asset.network.asChain()?.chainImageUrl : undefined}
              />
            }
            outerSpacing={cellSpacing}
          />
        </div>
      );
    },
    [cellSpacing, filteredAssets, handleAssetChange],
  );

  const handleOnSearch = useCallback(function handleOnSearch(queryText: string) {
    setQuery(queryText);
  }, []);

  return filteredAssets ? (
    <VStack width="100%" overflow="hidden">
      <VStack gap={2} spacing={3}>
        <StepperNavbar
          leftIcon="backArrow"
          title={formatMessage(messages.selectAsset)}
          onLeftIconPress={handleBackPressed}
          rightComponent={<NetworkSwitcherDropdown />}
        />
        <SearchInput
          testID="asset-selector-search-input"
          value={query}
          onChangeText={handleOnSearch}
          placeholder="Search"
        />
      </VStack>
      <VStack minHeight={400} width="100%" overflow="auto" spacingHorizontal={1} borderedBottom>
        <AutoSizer disableHeight>
          {({ width }) => (
            <List
              width={width}
              height={SELECT_ASSET_ROWS_HEIGHT}
              rowHeight={SELECT_ASSET_ROW_HEIGHT}
              rowCount={filteredAssets.length}
              overscanRowCount={10}
              rowRenderer={rowRenderer}
            />
          )}
        </AutoSizer>
      </VStack>
    </VStack>
  ) : null;
}

/* istanbul ignore file */
export enum SigningMethodErrorType {
  WrongSelectedAddress = 'wrong-selected-address',
  UserRejectedRequest = 'user-rejected-request',
  UnsupportedChain = 'unsupported-chain',
  InvalidConnector = 'invalid-connector',
  Generic = 'generic',
}

export class SigningMethodError extends Error {
  constructor(public type: SigningMethodErrorType, message: string) {
    super(message);
  }
}

import { EthereumBlockchain } from 'cb-wallet-data/chains/AccountBased/Ethereum/config';
import { AccountType } from 'cb-wallet-data/stores/Accounts/models/AccountTypes';
import { accountsAtom, firstCreatedAccountSelector } from 'cb-wallet-data/stores/Accounts/state';
import { walletsByWalletGroupAtom } from 'cb-wallet-data/stores/Wallets/state';
import { objectAtomLocalStorageEffect } from 'cb-wallet-data/utils/objectAtomLocalStorageEffect';
import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { atom, selector, selectorFamily } from 'recoil';

import { Account } from '../Accounts/models/Account';

import { WalletGroup } from './models/WalletGroup';

export const DEFAULT_WALLET_GROUPS: WalletGroup[] = [];

/**
 * All wallet groups.
 *
 * Each wallet group includes attributes, which can be used to derive
 * the correct subset of wallets from walletsAtom at runtime.
 *
 * @see prepareWalletsByWalletGroup
 */
export const walletGroupsAtom = atom<WalletGroup[]>({
  key: 'walletGroups',
  default: DEFAULT_WALLET_GROUPS,
});

export const StoreKeys_activeWalletGroupId = new LocalStorageStoreKey<string>(
  'activeWalletGroupId',
);

/**
 * Active wallet group ID, selected by the user.
 *
 * Note: Each wallet group includes an accountId; so by knowing the active
 * wallet group, we know the active account.
 *
 * @see activeAccountIdSelector
 */
export const activeWalletGroupIdAtom = atom<string | undefined>({
  key: 'activeWalletGroupId',
  default: selector({
    key: 'activeWalletGroupIdDefault',
    get: function getActiveWalletGroupId({ get }) {
      // Defaults to first created wallet group
      // TODO: Re-evaluate this logic after combining active wallet
      // group and active index states.
      const sortedWalletGroups = [...get(walletGroupsAtom)].sort(
        (a, b) => a.createdAt.getTime() - b.createdAt.getTime(),
      );
      return sortedWalletGroups[0]?.id;
    },
  }),
  effects: [objectAtomLocalStorageEffect(StoreKeys_activeWalletGroupId)],
});

/**
 * Given activeWalletGroupIdAtom, this is the active wallet group object
 * found in walletGroupsAtom.
 */
export const activeWalletGroupSelector = selector<WalletGroup | undefined>({
  key: 'activeWalletGroup',
  get: function getActiveWalletGroup({ get }) {
    const walletGroups = get(walletGroupsAtom);
    const activeWalletGroupId = get(activeWalletGroupIdAtom);
    return walletGroups.find((walletGroup) => walletGroup.id === activeWalletGroupId);
  },
});

/**
 * @param accountType {@link AccountType}
 * @returns all wallet groups across all accounts for a given account type
 */
export const getWalletGroupsByAccountType = selectorFamily<WalletGroup[], AccountType>({
  key: 'getWalletGroupsByAccountType',
  get:
    (accountType) =>
    ({ get }) => {
      const allWalletGroups = get(walletGroupsAtom);
      const allAccounts = get(accountsAtom);

      return allWalletGroups.filter(function filterWalletGroupsByAccountType({ accountId }) {
        const accountForWalletGroup = allAccounts.find(({ id }) => id === accountId);

        return accountForWalletGroup?.type === accountType;
      });
    },
});

/**
 * @param walletGroupId {@link walletGroupId}
 * @returns account object for a given wallet group
 */
export const getAccountByWalletGroupId = selectorFamily<
  Account | undefined,
  WalletGroup['id'] | undefined
>({
  key: 'getAccountByWalletGroupId',
  get:
    (walletGroupId) =>
    ({ get }) => {
      const allAccounts = get(accountsAtom);

      const allWalletGroups = get(walletGroupsAtom);

      const walletGroup = allWalletGroups.find((wg) => wg.id === walletGroupId);

      return allAccounts.find((account) => account.id === walletGroup?.accountId);
    },
});

/**
 * @returns all wallet groups keyed by Eth Address
 */
export const walletGroupsByEthAddressSelector = selector<Record<string, WalletGroup>>({
  key: 'walletGroupsByEthAddressSelector',
  get: function getActiveWalletGroup({ get }) {
    const walletGroups = get(walletGroupsAtom);
    const walletsByWalletGroup = get(walletsByWalletGroupAtom);

    const walletGroupsByEthAddress = walletGroups.reduce(function reduceWalletGroupsByEthAddress(
      accumulator,
      walletGroup,
    ) {
      const wallets = walletsByWalletGroup[walletGroup.id] ?? [];
      const ethAddress = wallets.find(
        (wallet) => wallet.blockchain.rawValue === EthereumBlockchain.rawValue,
      )?.primaryAddress;

      if (ethAddress) {
        accumulator[ethAddress] = walletGroup;
      }

      return accumulator;
    },
    {} as Record<string, WalletGroup>);

    return walletGroupsByEthAddress;
  },
});

/** Currently, the first wallet group is the first wallet group that is shown in the UI such as 
the Wallet Switcher + Manage Wallets views.

This logic gets the first account by `createdAt` date which works for all account types.
@see userFacingAccountsSortedByCreatedAtSelector

For Mnemonic account type, it sorts the wallet groups by `walletIndex`. For other 
account types, there is a 1:1 account:walletGroup mapping, so there should only be 1 wallet 
group where its `walletIndex` is 0n. */
export const firstWalletGroupSelector = selector<WalletGroup | undefined>({
  key: 'firstWalletGroup',
  get: function getFirstWalletGroup({ get }) {
    const walletGroups = get(walletGroupsAtom);
    const firstAccount = get(firstCreatedAccountSelector);
    const firstWalletGroup = walletGroups.find(
      (wg) => wg.accountId === firstAccount?.id && wg.walletIndex === 0n,
    );
    return firstWalletGroup!;
  },
});

/** return is the active wallet group the first wallet group */
export const isActiveWalletGroupTheFirstWalletGroupSelector = selector<boolean>({
  key: 'isActiveWalletGroupTheFirstWalletGroup',
  get: function getIsActiveWalletGroupTheFirstWalletGroup({ get }) {
    const activeWalletGroupId = get(activeWalletGroupIdAtom);
    const firstWalletGroup = get(firstWalletGroupSelector);

    // firstWalletGroup will be undefined before walletGroupsAtom has
    // been hydrated on app load or coming out of onboarding.
    return firstWalletGroup?.id === activeWalletGroupId;
  },
});

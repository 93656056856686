import { DataMigration, DataMigrationParams } from 'cb-wallet-data/DataMigrationRunner/types';
import { getAccounts } from 'cb-wallet-data/stores/Accounts/database';
import { getAllAddresses, saveAddresses } from 'cb-wallet-data/stores/Addresses/database';
import { Address } from 'cb-wallet-data/stores/Addresses/models/Address';
import { getWallets, saveWallets } from 'cb-wallet-data/stores/Wallets/database';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';

/**
 * Migration script to ensure all wallets and addresses have an
 * accountId field.
 *
 * With the addition of multi-account features, Wallet and Address
 * models were given a new accountId field, to distinguish these
 * records between multiple accounts.
 *
 * Moving forward, lookups of wallets and addresses require factoring
 * in the accountId; so for any users who have onboarded before
 * multi-account features existed, we must add the accountId field to
 * all of their pre-existing wallets and addresses in client database.
 */
const addAccountIdFieldToWalletsAndAddresses: DataMigration = {
  // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
  // eslint-disable-next-line wallet/no-anonymous-params
  up: async (_params: DataMigrationParams) => {
    // eslint-disable-next-line no-console
    console.log('Migration: addAccountIdFieldToWalletsAndAddresses');

    const accounts = await getAccounts();

    // We can't add accountId to wallets and addresses without an account.
    // If there are no accounts, we're assuming either:
    // 1. User has not onboarded yet.
    // 2. We're in a bad state, as migrateForMultiAccount should have added the first account.
    if (!accounts.length) return;

    // If user has multiple accounts, they would have already been using the app with
    // multi-account features in place, and this migration should have executed already.
    //
    // It's problematic to allow this user to continue because we can't be sure what
    // accountId should be attached to every wallet and address.
    if (accounts.length > 1) {
      throw new Error('user already has multiple accounts');
    }

    const accountId = accounts[0].id; // accounts items should always contain exactly one item
    const [prevWallets, prevAddresses] = await Promise.all([getWallets(), getAllAddresses()]);

    // 1) Find any wallets without an accountId, add the accountId, and
    // queue them to be updated in db.
    // We are not spreading the reduced object here so it is fine
    // eslint-disable-next-line wallet/no-spread-in-reduce
    const nextWallets = prevWallets.reduce<Wallet[]>(function reduceWalletsWithoutAccountId(
      acc,
      prevWallet,
    ) {
      const hasAccountId = prevWallet.accountId === accountId;

      if (!hasAccountId) {
        const nextWallet = Wallet.fromDMO({ ...prevWallet.asDMO, accountId });
        acc.push(nextWallet);
      }

      return acc;
    },
    []);

    // 2) Find any addresses without an accountId, add the accountId, and
    // queue them to be updated in db.
    // We are not spreading the reduced object here so it is fine
    // eslint-disable-next-line wallet/no-spread-in-reduce
    const nextAddresses = prevAddresses.reduce<Address[]>(function reduceAddressesWithoutAccountId(
      acc,
      prevAddress,
    ) {
      const hasAccountId = prevAddress.accountId === accountId;

      if (!hasAccountId) {
        const nextAddress = Address.fromDMO({ ...prevAddress.asDMO, accountId });
        acc.push(nextAddress);
      }

      return acc;
    },
    []);

    // 3) For any updated wallets or addresses, save them to the db.
    const saveUpdatedRecordsToDbPromises = [];

    if (nextWallets.length > 0) {
      saveUpdatedRecordsToDbPromises.push(saveWallets(nextWallets));
    }

    if (nextAddresses.length > 0) {
      saveUpdatedRecordsToDbPromises.push(saveAddresses(nextAddresses));
    }

    await Promise.all(saveUpdatedRecordsToDbPromises);
  },
};

export default addAccountIdFieldToWalletsAndAddresses;

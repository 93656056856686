import { ETHEREUM_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { SOLANA_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Solana/constants';

import {
  PossibleNetworkWalletConnector,
  WalletProviderNetwork,
  WalletProviderNetworkInfo,
} from './types';

export function makeEthereumNetwork(
  connector: PossibleNetworkWalletConnector | null,
): WalletProviderNetworkInfo {
  return {
    connector,
    image: 'https://go.wallet.coinbase.com/static/chains/ethereum-logo.png',
    enabled: true,
    id: WalletProviderNetwork.Ethereum,
    name: 'Ethereum',
    symbol: ETHEREUM_SYMBOL,
  };
}

export function makeSolanaNetwork(
  connector: PossibleNetworkWalletConnector | null,
): WalletProviderNetworkInfo {
  return {
    connector,
    image: 'https://go.wallet.coinbase.com/static/solana-logo.svg',
    enabled: true,
    id: WalletProviderNetwork.Solana,
    name: 'Solana',
    symbol: SOLANA_SYMBOL,
  };
}

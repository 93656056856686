import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { VIEM_CHAIN_MAP, WHITELISTED_CHAINS_MAP } from 'cb-wallet-data/scw/libs/wagmi/chains';
import { SignAndSubmitETH1559TransactionWithWeb3 } from 'cb-wallet-data/ServiceLocator/signingAndDerivation/types/SignAndSubmitETH1559Transaction';
import { SignAndSubmitETH2930TransactionWithWeb3 } from 'cb-wallet-data/ServiceLocator/signingAndDerivation/types/SignAndSubmitETH2930Transaction';
import { SignAndSubmitETHLegacyTransactionWithWeb3 } from 'cb-wallet-data/ServiceLocator/signingAndDerivation/types/SignAndSubmitETHLegacyTransaction';
import { strip0x } from 'cb-wallet-data/utils/String+Core';
import { BaseError, createWalletClient, custom, EIP1193Provider, toHex } from 'viem';
import { Sign1559TransactionBaseParams } from 'wallet-engine-signing/signing/ethereum/sign1559Transaction';
import { Sign2930TransactionBaseParams } from 'wallet-engine-signing/signing/ethereum/sign2930Transaction';
import { SignETHLegacyTransactionBaseParams } from 'wallet-engine-signing/signing/ethereum/signLegacyTransaction';

import { WalletProviderNetwork } from ':dapp/connection/types';

import { SigningMethodError, SigningMethodErrorType } from '../SigningMethodError';

import { getConnectorManagerForAccountId } from './getConnectorManagerForAccountId';
import { getEthereumAddressFromAccountId } from './getEthereumAddressFromAccountId';
import { validateCurrentEthereumChain } from './validateCurrentEthereumChain';
import { validateKnownEthereumAddress } from './validateKnownEthereumAddress';
import { validateWalletProvidersConnected } from './validateWalletProvidersConnected';

type Params =
  | Parameters<SignAndSubmitETHLegacyTransactionWithWeb3>[0]
  | Parameters<SignAndSubmitETH1559TransactionWithWeb3>[0]
  | Parameters<SignAndSubmitETH2930TransactionWithWeb3>[0];

type BaseSignETHTransactionReturnType =
  | ReturnType<SignAndSubmitETHLegacyTransactionWithWeb3>
  | ReturnType<SignAndSubmitETH1559TransactionWithWeb3>
  | ReturnType<SignAndSubmitETH2930TransactionWithWeb3>;

function hasLegacyOr2930TxProperties(
  params: Record<string, unknown>,
): params is SignETHLegacyTransactionBaseParams | Sign2930TransactionBaseParams {
  return params.gasLimit !== undefined && params.gasPriceInWei !== undefined;
}

function has1559TxProperties(
  params: Record<string, unknown>,
): params is Sign1559TransactionBaseParams {
  return params.maxFeePerGas !== undefined && params.maxPriorityFeePerGas !== undefined;
}

/**
 * Handles signing base ethereum transactions using the web3 provider
 * baseSignETHTransaction emits SigningMethodErrors errors, which should be handled by the caller
 *
 * @param accountId {string}
 * @param unsignedTx {SignETHLegacyTransactionBaseParams}
 * @param skipPrepare indicates that there is no need to prepare the call, ie re-calculate gas price, gas limit, and nonce
 * @throws {SigningMethodError} SigningMethodErrorType.WrongSelectedAddress - the address you're trying to sign with is not connected to your wallet
 * @throws {SigningMethodError} SigningMethodErrorType.UserRejectedRequest - the user rejected the new connect request
 * @throws {SigningMethodError} SigningMethodErrorType.UnsupportChain - either dapp or wallet doesn't support the selected chain
 * @throws {SigningMethodError} SigningMethodErrorType.Generic - catch-all for unknown or unlikely errors
 * @returns {SignedEthereumTransaction}
 */
export async function baseSignAndSubmitETHTransaction({
  accountId,
  unsignedTx,
  skipSubmit,
  skipPrepare,
}: Params): BaseSignETHTransactionReturnType {
  try {
    if (skipSubmit) {
      throw new SigningMethodError(
        SigningMethodErrorType.Generic,
        'skipSubmit is not supported for web3 signing',
      );
    }
    const chain = WHITELISTED_CHAINS_MAP[unsignedTx.chainId] || VIEM_CHAIN_MAP[unsignedTx.chainId];
    if (!chain) {
      throw new SigningMethodError(
        SigningMethodErrorType.UnsupportedChain,
        `chain id: ${unsignedTx.chainId} is unsupported`,
      );
    }

    const address = getEthereumAddressFromAccountId(accountId);
    const connectorManager = getConnectorManagerForAccountId(accountId);
    const provider = (await connectorManager.getProvider(
      WalletProviderNetwork.Ethereum,
    )) as EIP1193Provider;

    const walletClient = createWalletClient({
      account: address,
      transport: custom(provider),
      chain,
    });

    // validate the provider is connected
    await validateWalletProvidersConnected(connectorManager, WalletProviderNetwork.Ethereum);
    // validate the provider is connected to the correct address
    await validateKnownEthereumAddress(provider, address);
    // validate the provider is connected to the correct chain
    await validateCurrentEthereumChain(provider, chain);

    const params: Record<string, bigint | string | `0x${string}`> = {
      to: unsignedTx.toAddress as `0x${string}`,
      value: BigInt(unsignedTx.weiValue.toString()),
    };
    if (has1559TxProperties(unsignedTx)) {
      params.maxFeePerGas = BigInt(unsignedTx.maxFeePerGas.toString());
      params.maxPriorityFeePerGas = BigInt(unsignedTx.maxPriorityFeePerGas.toString());
    } else if (hasLegacyOr2930TxProperties(unsignedTx)) {
      params.gasPrice = BigInt(unsignedTx.gasPriceInWei.toString());
      params.gasLimit = BigInt(unsignedTx.gasLimit.toString());
    } else {
      throw new SigningMethodError(SigningMethodErrorType.Generic, 'unknown transaction type');
    }

    if (unsignedTx.data.length) {
      params.data = toHex(unsignedTx.data);
    }

    let request;
    if (skipPrepare) {
      request = {
        chain,
        ...params,
      };
    } else {
      // prepare populates gas price, nonce, and gasLimit
      // none of this is relevant for an SCW userOp.
      // we also have already calculated all of these values already, so we should consider just never using
      // prepareTransactionRequest even for non-scw calls
      request = await walletClient.prepareTransactionRequest({
        chain,
        ...params,
      });
    }

    // Few dapps populate the nonce. We shouldn't populate the nonce either as an indication that we want the wallet
    // to populate the nonce. Why is it better for the Wallet to populate the nonce? The Wallet knows about all pending
    // transactions. The Wallet knows about speed-up/cancel transactions. The dapp does not.
    const finalRequest = {
      ...request,
      nonce: undefined,
    };

    const hash = await walletClient.sendTransaction(finalRequest);
    return {
      // The web3 interface doesn't return the signed tx data for resubmit.
      // We return an empty buffer here to indicate that the signature data is not available.
      data: Buffer.from(new Uint8Array(0)),
      hash: Buffer.from(strip0x(hash), 'hex'),
    };
  } catch (error) {
    cbReportError({
      error: coerceError(error, 'base sign eth transaction'),
      context: 'transactions',
      severity: 'error',
      isHandled: false,
    });

    const err = error instanceof BaseError ? new Error(error.shortMessage) : error;
    throw err;
  }
}

import { useCallback, useEffect, useRef } from 'react';
import {
  logExchangeRateFetchDuration,
  logTotalBalancePendingDuration,
} from 'cb-wallet-analytics/balances/Balances';
import { allBlockchainsAreRefreshedSelector } from 'cb-wallet-data/stores/Wallets/state';
import { atom, useRecoilState, useRecoilValue } from 'recoil';

export const balancesUpdatingAtom = atom({
  key: 'balancesUpdating',
  default: true,
});

export const exchangeRatesUpdatingAtom = atom({
  key: 'exchangeRatesUpdating',
  default: true,
});

let hasLoggedTotalBalanceDuration = false;
let hasLoggedExchangeRateDuration = false;

export function useBalanceRefreshIndicator() {
  const [balanceUpdating, setBalanceUpdating] = useRecoilState(balancesUpdatingAtom);
  const [exchangeRatesUpdating, setExchangeRatesUpdating] =
    useRecoilState(exchangeRatesUpdatingAtom);
  const allBlockchainsAreRefreshed = useRecoilValue(allBlockchainsAreRefreshedSelector);
  const startTime = useRef(performance.now());

  useEffect(
    function setBalanceUpdatingOnRefreshComplete() {
      if (allBlockchainsAreRefreshed && !exchangeRatesUpdating) {
        setBalanceUpdating(false);

        if (!hasLoggedTotalBalanceDuration) {
          hasLoggedTotalBalanceDuration = true;
          logTotalBalancePendingDuration({ startTime: startTime.current });
        }
      }
    },
    [allBlockchainsAreRefreshed, setBalanceUpdating, exchangeRatesUpdating],
  );

  // If the balance is still updating after 15 seconds, we'll just assume it's stuck and clear it
  useEffect(
    function clearBalanceUpdatingAfterTimeout() {
      const timeout = setTimeout(() => {
        if (balanceUpdating) {
          setBalanceUpdating(false);

          if (!hasLoggedTotalBalanceDuration) {
            hasLoggedTotalBalanceDuration = true;
            logTotalBalancePendingDuration({ startTime: startTime.current });
          }
        }
      }, 15_000);
      return () => clearTimeout(timeout);
    },
    [balanceUpdating, setBalanceUpdating],
  );

  const setExchangeRatesUpdatingWithLogs = useCallback(
    (updating: boolean) => {
      if (!updating) {
        setExchangeRatesUpdating(false);

        if (!hasLoggedExchangeRateDuration) {
          hasLoggedExchangeRateDuration = true;
          logExchangeRateFetchDuration({ startTime: startTime.current });
        }
      }
    },
    [setExchangeRatesUpdating],
  );

  return {
    balanceUpdating,
    setBalanceUpdating,
    setExchangeRatesUpdating: setExchangeRatesUpdatingWithLogs,
  };
}

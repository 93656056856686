import { useEffect, useRef } from 'react';

import { TimerHandler } from './types';

type Delay = number | null;

/**
 * A declarative useInterval
 *
 * @param callback - Function that will be called every `delay` ms.
 * @param delay - Number representing the delay in ms. Set to `null` to "pause" the interval.
 */
export function useIntervalBase(callback: TimerHandler, delay: Delay): void {
  const savedCallbackRef = useRef<TimerHandler>();

  useEffect(
    function saveCallbackRef() {
      savedCallbackRef.current = callback;
    },
    [callback],
  );

  useEffect(
    function invokeCallback() {
      const handler = (...args: any[]): void => {
        savedCallbackRef.current!(...args);
      };

      if (delay !== null) {
        const intervalId = setInterval(handler, delay);
        return () => {
          clearInterval(intervalId);
        };
      }
    },
    [delay],
  );
}

import { JSXElementConstructor, ReactElement, ReactNode } from 'react';
import { CellSpacing } from '@cbhq/cds-common';
import { ListCell } from '@cbhq/cds-web/cells/ListCell';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, VStack } from '@cbhq/cds-web/layout';

export type Selector = {
  testID?: string;
  title: string | ReactNode;
  subtitle: string | ReactNode;
  detail?: string | ReactNode;
  subdetail?: string | ReactNode;
  media?: ReactElement<any, string | JSXElementConstructor<any>>;
  onClick?: () => void;
};

export type AssetSelectorGroupProps = {
  primarySelector: Selector;
  secondarySelector?: Selector;
};

const cellSpacing: CellSpacing = {
  spacingHorizontal: 0,
};

export function AssetSelector({
  title,
  subtitle,
  detail,
  subdetail,
  media,
  testID,
  onClick,
}: Selector) {
  return (
    <ListCell
      compact
      testID={testID}
      accessory={onClick ? 'arrow' : undefined}
      title={title}
      description={subtitle}
      detail={detail}
      subdetail={subdetail}
      onPress={onClick}
      media={media}
      outerSpacing={cellSpacing}
    />
  );
}

export function AssetSelectorGroup({
  primarySelector,
  secondarySelector,
}: AssetSelectorGroupProps) {
  return (
    <VStack justifyContent="flex-end" gap={0.5}>
      <AssetSelector {...primarySelector} />
      {secondarySelector ? (
        <>
          <Box alignItems="center">
            <Icon
              name="verticalLine"
              size="s"
              spacingStart={1}
              color="foregroundMuted"
              testID="vertical-line"
            />
          </Box>
          <AssetSelector {...secondarySelector} />
        </>
      ) : null}
    </VStack>
  );
}

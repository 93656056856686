import { Persistor } from './persistQueryClient';

let overriddenPersistor: Persistor | undefined;

/**
 * This will set the overridden persistor to be used in `createPersistor`.
 */
export function setOverriddenPersistor(persistor: Persistor | undefined) {
  overriddenPersistor = persistor;
}

/**
 * This will return the overridden persistor if it exists.
 */
export function getOverriddenPersistor(): Persistor | undefined {
  return overriddenPersistor;
}

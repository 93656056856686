import { useCallback } from 'react';

import { WalletConnectionResponse } from '../models/WalletConnectionResponse';

import { deleteDappProviderAccountMutex } from './useDeleteDappProviderAccount';
import { useProcessAccountBasedBlockchainAddresses } from './useProcessAccountBasedBlockchainAddresses';
import { useProcessUTXOBasedBlockchainXPubKeys } from './useProcessUTXOBasedBlockchainXPubKeys';

export function useProcessWalletConnectionResponse() {
  const processAccountBasedBlockchainAddresses = useProcessAccountBasedBlockchainAddresses();
  const processUTXOBasedBlockchainXPubKeys = useProcessUTXOBasedBlockchainXPubKeys();
  return useCallback(
    async function processWalletConnectionResponse(
      response: WalletConnectionResponse,
      skipTransactionRefresh?: boolean,
    ) {
      const { provider, account, utxo, providerUserId } = response;

      try {
        await deleteDappProviderAccountMutex.acquire();

        if (account) {
          await processAccountBasedBlockchainAddresses({
            provider,
            account,
            skipTransactionRefresh,
            providerUserId,
          });
        }

        if (utxo) {
          await processUTXOBasedBlockchainXPubKeys({ provider, utxo, providerUserId });
        }
        // This method is not really deprecated, you can see in later version of async-mutex the author
        // has removed the @deprecated warning
        deleteDappProviderAccountMutex.release();
      } catch (error: ErrorOrAny) {
        // This method is not really deprecated, you can see in later version of async-mutex the author
        // has removed the @deprecated warning
        deleteDappProviderAccountMutex.release();
        // No need to report here because the callers are already reporting the error.
      }
    },
    [processAccountBasedBlockchainAddresses, processUTXOBasedBlockchainXPubKeys],
  );
}

import { ActionType, ComponentType } from '@cbhq/client-analytics';

import { logDataEvent, logEvent, useLogEventOnMount } from '../utils/log';

export type WalletConnectionProps = {
  walletProviderID?: string;
  walletProviderName?: string;
  networkName?: string;
  networkID?: string;
  errorName?: string;
  errorMessage?: string;
  numberOfWallets?: number;
};

export function triggerWalletConnectionConnectClick() {
  logEvent('wallet_connection.connect.click', {
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function logSmartWalletSignUp() {
  logDataEvent('wallet_connection.smart_wallet_signup.click', {
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function useViewSmartWalletSignUp() {
  useLogEventOnMount('wallet_connection.smart_wallet_signup.viewed', {
    action: ActionType.view,
    componentType: ComponentType.button,
  });
}

export function triggerWalletProviderConnectStart(props: WalletConnectionProps) {
  logEvent('wallet_connection.wallet_provider_connect.start', {
    action: ActionType.click,
    componentType: ComponentType.button,
    ...props,
  });
}

export function triggerWalletProviderConnectSuccess(props: WalletConnectionProps) {
  logEvent('wallet_connection.wallet_provider_connect.success', {
    componentType: ComponentType.unknown,
    action: ActionType.measurement,
    ...props,
  });
}

export function triggerWalletProviderConnectError(props: WalletConnectionProps) {
  logEvent('wallet_connection.wallet_provider_connect.error', {
    componentType: ComponentType.unknown,
    action: ActionType.measurement,
    ...props,
  });
}

export function useConnectedWalletsMenuView() {
  useLogEventOnMount('wallet_connection.connected_wallets_menu.viewed', {
    action: ActionType.view,
    componentType: ComponentType.dropdown,
  });
}

export function triggerConnectedWalletsMenuCopyClick(props: WalletConnectionProps) {
  logEvent('wallet_connection.connected_wallets_menu.copy.click', {
    action: ActionType.click,
    componentType: ComponentType.button,
    ...props,
  });
}

export function triggerConnectedWalletsMenuManageClick() {
  logEvent('wallet_connection.connected_wallets_menu.manage_wallet.click', {
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function triggerAddAdditionalWalletClick() {
  logEvent('wallet_connection.add_additional_wallet.click', {
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function useConnectedAddressActionsViewed() {
  useLogEventOnMount('wallet_connection.manage_wallets.connected_address_actions.viewed', {
    action: ActionType.view,
    componentType: ComponentType.dropdown,
  });
}

export function triggerCopyAddressClick() {
  logEvent('wallet_connection.manage_wallets.copy_address.click', {
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function triggerDisconnectWalletClick(props: WalletConnectionProps) {
  logEvent('wallet_connection.manage_wallets.disconnect_wallet.click', {
    action: ActionType.click,
    componentType: ComponentType.button,
    ...props,
  });
}

export function triggerDisconnectMultipleWalletsClick(props: WalletConnectionProps) {
  logEvent('wallet_connection.manage_wallets.disconnect_multiple_wallets.click', {
    action: ActionType.click,
    componentType: ComponentType.button,
    ...props,
  });
}

export function triggerDisconnectStart(props: WalletConnectionProps) {
  logEvent('wallet_connection.disconnect_wallet.start', {
    componentType: ComponentType.unknown,
    action: ActionType.measurement,
    ...props,
  });
}

export function triggerDisconnectSuccess(props?: WalletConnectionProps) {
  logEvent('wallet_connection.disconnect_wallet.success', {
    componentType: ComponentType.unknown,
    action: ActionType.measurement,
    ...props,
  });
}

export function triggerDisconnectFailure(props?: WalletConnectionProps) {
  logEvent('wallet_connection.disconnect_wallet.failure', {
    componentType: ComponentType.unknown,
    action: ActionType.measurement,
    ...props,
  });
}

export function triggerResetRequired() {
  logDataEvent('wallet_connection.reset.required', {});
}

import {
  Connector as EthereumConnector,
  ConnectorAlreadyConnectedError,
  disconnect as disconnectEthereum,
} from '@wagmi/core';
import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { getConfig } from 'cb-wallet-data/scw/libs/wagmi/config';
import { buildWalletConnectionAccountResponse } from 'cb-wallet-data/stores/Accounts/models/WalletConnectionResponse';
import { getAddress, InvalidAddressError, UserRejectedRequestError } from 'viem';

import { connect as connectEthereum } from './wagmi.overrides/connect';
import {
  ConnectWalletOptions,
  PossibleNetworkWalletConnector,
  WalletConnectionErrorType,
  WalletConnectorConnectResponse,
} from './types';

/**
 * Type predicate function that checks if the given object is an EthereumConnector
 * Based on the return type of the createConnectorFn
 * https://github.com/wevm/wagmi/blob/7a95b5418707ab38415d2eabab5bb12faabf2ad1/packages/core/src/createConfig.ts#L445
 * https://github.com/wevm/wagmi/blob/main/packages/core/src/connectors/createConnector.ts#L34-L64
 * @returns boolean
 */
export function isValidConnector(connector: unknown): connector is EthereumConnector {
  if (typeof connector !== 'object' || connector === null) {
    return false;
  }
  return [
    'id',
    'name',
    'type',
    'connect',
    'disconnect',
    'getAccounts',
    'getChainId',
    'getProvider',
    'isAuthorized',
    'onAccountsChanged',
    'onChainChanged',
    'onDisconnect',
  ].every((key) => {
    return Object.keys(connector).includes(key);
  });
}

export async function connect(
  walletProvider: string,
  connector: PossibleNetworkWalletConnector,
  options?: ConnectWalletOptions,
): Promise<WalletConnectorConnectResponse> {
  try {
    if (!isValidConnector(connector)) {
      return {
        error: WalletConnectionErrorType.CONNECTOR_INVALID,
      };
    }
    const provider = await connector.getProvider();
    if (!provider) {
      return {
        error: WalletConnectionErrorType.CONNECTOR_NOT_READY,
      };
    }

    const result = await connectEthereum(getConfig(), {
      connector,
      options,
    });

    // When the provider is Coinbase Smart Wallet, the first element in the eth_requestAccounts
    // response will contain the address along with the user ID, separated by a colon. This needs
    // to be extracted and returned. For any other provider, the user ID will be undefined
    // and the address will be the only value in the string.
    const [, providerUserId] = result.accounts[0].split(':');
    const addresses = result.accounts.map((account) => getAddress(account.split(':')[0]));

    return {
      result: {
        provider: walletProvider,
        account: buildWalletConnectionAccountResponse({
          ethAddresses: addresses,
        }),
        providerUserId,
      },
    };
  } catch (error) {
    if (error instanceof InvalidAddressError) {
      return {
        error: WalletConnectionErrorType.MALFORMED_RESPONSE,
      };
    }
    // User rejected request
    if (error instanceof UserRejectedRequestError) {
      return {
        error: WalletConnectionErrorType.USER_REJECTED,
      };
    }
    if (typeof error === 'object' && error !== null && 'code' in error) {
      if (error.code === 4001) {
        return {
          error: WalletConnectionErrorType.USER_REJECTED,
        };
      }
    }
    if (error instanceof ConnectorAlreadyConnectedError) {
      return {
        error: WalletConnectionErrorType.CONNECTOR_ALREADY_CONNECTED,
      };
    }
    if (
      /Connection request reset. Please try again/i.test(
        coerceError(error, 'ethereum connect error').message,
      )
    ) {
      // Do nothing. this happens when the walletconnect modal is closed
      return {
        error: WalletConnectionErrorType.WALLETCONNECT_CONNECTION_RESET,
      };
    }

    cbReportError({
      context: 'wallet-connection-error',
      error: coerceError(error, 'ethereum connect error'),
      isHandled: false,
      severity: 'error',
    });

    return {
      error: WalletConnectionErrorType.UNKNOWN,
    };
  }
}

export async function disconnect(connector: PossibleNetworkWalletConnector): Promise<void> {
  if (!isValidConnector(connector)) {
    return;
  }
  return disconnectEthereum(getConfig(), {
    connector,
  });
}

import { useMemo } from 'react';
import { AppLoadVersion } from 'cb-wallet-analytics/app-load';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { getPlatformName } from 'cb-wallet-metadata/metadata';
import { PlatformName } from '@cbhq/client-analytics';

/**
 * useAppLoadVersion is used for denoting the versions of app load, for observability
 * purposes. This is only used for RN. For any other platform, it currently always
 * returns 1.
 *
 * Versions
 * 1 - Prior to AuthGateRootStack feature enabled
 * 2 - AuthGateRootStack feature is enabled
 */
export function useAppLoadVersion(): { version: AppLoadVersion } {
  const isAuthGateRootStackEnabled = useIsFeatureEnabled('auth_gate_root_stack');
  const platformName = getPlatformName();
  const isRN = platformName === PlatformName.ios || platformName === PlatformName.android;

  return useMemo(() => {
    if (!isRN) {
      return { version: 1 };
    }

    if (isAuthGateRootStackEnabled) {
      return { version: 2 };
    }

    return { version: 1 };
  }, [isAuthGateRootStackEnabled, isRN]);
}

import isMatch from 'lodash/isMatch';
import {
  AddressConfig,
  AddressConfigWithHistory,
  getHistoryFunctionsByChain,
} from 'wallet-engine-signing/history/historyByChain';
import { createHistoryCache } from 'wallet-engine-signing/history/historyCache';
import {
  NudgeEvent,
  NudgeEventInit,
  NudgeEventOnChain,
  registerNudgeConnection,
} from 'wallet-engine-signing/history/nudge';
import { NudgeDetails, SupportedChains } from 'wallet-engine-signing/history/types';
import {
  registerWebsocketConnection,
  WebsocketHandler,
} from 'wallet-engine-signing/history/websockets';

import { WebsocketConnectionError } from './errors';

export type HistoryEventError = {
  type: 'error';
  error: Error;
};

export type HistoryEventUpdate = {
  type: 'balance' | 'transactions';
  interval: 'init' | 'poll' | 'nudge';
  updates: AddressConfigWithHistory[];
  nudgeProvider?: string;
  nudgeProviderNetworkID?: string;
  blockheight?: number;
  txHash?: string;
  chainId?: bigint;
  didRetry?: boolean;
  errors?: Error[];
};

export type HistoryEventCallback<T> = (ev: T) => void;

type HistoryEventSubscription<T> = {
  type: HistoryEvent['type'];
  cb: HistoryEventCallback<T>;
  context: Partial<AddressConfig>;
};

export type HistoryEvent = HistoryEventError | HistoryEventUpdate;

type HistoryListenerOptions = {
  pollingIntervalSeconds?: number;
};

type RefreshParams = {
  addressConfigs: any[];
  isRetry?: boolean;
  fullRefresh?: boolean;
  isBalanceFetchingThrottleEnabled?: boolean;
};

type ThrottleRequestParams = {
  throttleRequestsMilliseconds?: number;
  throttleRequestsLastUpdate?: number;
};

const DEFAULT_POLLING_INTERVAL_SECONDS = 30;
const SOCKET_NUDGE_FOLLOW_REQUEST_TIMEOUT = 5000;

/**
 * An AddressHistoryListener coordinates making sure balances and txOrUserOp history is up-to-date for each blockchain.
 *
 * There is one AddressHistoryListener instance per eth, btc, ltc, doge, and sol.
 *
 * For a given AddressHistoryListener, paginated fetching of all transactions is handled per unique network. We keep
 * track of the last saved txHash per network.
 *
 * Balance+history updates are handled by either polling, sse nudge, or websocket nudge infrastructure. There is one
 * SSE connection for all EVM blockchains.
 *
 * @param blockchainSymbol
 * @param options
 */
export function createAddressHistoryListener(
  blockchainSymbol: SupportedChains,
  options?: HistoryListenerOptions,
) {
  const { pollingIntervalSeconds = DEFAULT_POLLING_INTERVAL_SECONDS } = options || {};
  const {
    getCacheId,
    getBlockheight,
    initializeCacheItem,
    handleNudgeBalances,
    handleNudgeTransactions,
    refreshBalances: refreshBalancesForChain,
    refreshTransactions: refreshTransactionsForChain,
    registerWebsocketHandlers: websocketHandlersByChain,
  } = getHistoryFunctionsByChain(blockchainSymbol);

  // cache is made up of either AddressConfig or AddressHistory objects
  const cache = createHistoryCache<any>(getCacheId);

  let updateSubscriptions: HistoryEventSubscription<HistoryEventUpdate>[] = [];
  let errorSubscriptions: HistoryEventSubscription<HistoryEventError>[] = [];

  let nudgeConnection: ReturnType<typeof registerNudgeConnection>;
  let pollingInterval: ReturnType<typeof setInterval>;
  let syncedBlockheight = 0;

  let throttleRequestsMilliseconds: number | undefined;
  let throttleRequestsLastUpdate: number | undefined;

  let websocketHandler: WebsocketHandler;

  async function onNudge(addresses: AddressConfigWithHistory[], nudgeDetails: NudgeDetails) {
    const balanceUpdate = await handleNudgeBalances(addresses as any, nudgeDetails);

    if (balanceUpdate?.updates?.length) {
      notifySubscribers('balance', updateSubscriptions, {
        ...balanceUpdate,
        interval: 'nudge',
        txHash: nudgeDetails.txHash,
      });

      balanceUpdate.updates.forEach((update: AddressConfigWithHistory) => {
        cache.setCacheItem(update);
      });
    }

    const transactionsUpdate = await handleNudgeTransactions(addresses as any, nudgeDetails);

    if (transactionsUpdate?.updates?.length) {
      notifySubscribers('transactions', updateSubscriptions, {
        ...transactionsUpdate,
        interval: 'nudge',
        txHash: nudgeDetails.txHash,
      });
    }
  }

  async function onSSENudge(nudgeEvent: NudgeEvent<NudgeEventOnChain | NudgeEventInit>) {
    if (nudgeEvent.data.source === 'ON_CHAIN') {
      const nudgeEnabledAddresses = cache.all().filter((address) => address.isNudgeEnabled);
      const { txHash, chainId, providerName, providerNetworkID } = nudgeEvent.data;
      const nudgedAddresses = nudgeEnabledAddresses.filter((address) => {
        const addressMatches =
          address.address.toLowerCase() === nudgeEvent.data.address.toLowerCase();
        const chainIdMatches = address.chainId === BigInt(chainId);
        return addressMatches && chainIdMatches;
      });

      onNudge(nudgedAddresses, {
        txHash,
        chainId: BigInt(chainId),
        providerName,
        providerNetworkID,
      });
    }
  }

  async function onSocketNudge(event: MessageEvent) {
    const websocketEnabledAddresses = cache
      .all()
      .filter((address) => address.isWebsocketNudgeEnabled);
    const txHash = websocketHandlersByChain.getTxHashFromSocketMessage(event);
    try {
      if (txHash) {
        onNudge(websocketEnabledAddresses, { txHash });

        // Oftentimes the RPC node requested can be out of sync with the txhash in context
        // so we do a follow up request after 5s to ensure the balance is updated
        setTimeout(() => {
          onNudge(websocketEnabledAddresses, { txHash });
        }, SOCKET_NUDGE_FOLLOW_REQUEST_TIMEOUT);
      }
    } catch (err: Error | unknown) {
      onError(err as Error);
    }
  }

  function onError(error: Error) {
    errorSubscriptions
      .filter(({ type }) => type === 'error')
      .forEach(({ cb }) =>
        cb({
          type: 'error',
          error,
        }),
      );
  }

  // If the nudge connection was dropped we need to do a full refresh of all
  // nudge enabled addresses.
  async function onNudgeReopen() {
    const addresses = cache.all().filter(({ isNudgeEnabled }) => isNudgeEnabled);
    const balanceUpdate = await refreshBalancesForAddresses({ addressConfigs: addresses });

    balanceUpdate.updates.forEach((update: AddressConfigWithHistory) => {
      cache.setCacheItem(update);
    });

    notifySubscribers('balance', updateSubscriptions, {
      ...balanceUpdate,
      interval: 'nudge',
    });
  }

  function addEventListener(
    type: HistoryEvent['type'],
    cb: HistoryEventCallback<HistoryEventUpdate> | HistoryEventCallback<HistoryEventError>,
    context: Partial<AddressConfig> = {},
  ) {
    if (['balance', 'transactions'].includes(type)) {
      updateSubscriptions.push({
        cb: cb as HistoryEventCallback<HistoryEventUpdate>,
        context,
        type,
      });
    }

    if (type === 'error') {
      errorSubscriptions.push({
        cb: cb as HistoryEventCallback<HistoryEventError>,
        context,
        type,
      });
    }
  }

  function createPollingInterval(
    pollEnabledAddresses: AddressConfigWithHistory[],
    isBalanceFetchingThrottleEnabled?: boolean,
  ) {
    if (pollingInterval) clearInterval(pollingInterval);

    pollingInterval = setInterval(async function addHistoryPoll() {
      const currentBlockheight = await getBlockheight();
      const shouldThrottle =
        isBalanceFetchingThrottleEnabled &&
        shouldThrottleRequests({
          throttleRequestsMilliseconds,
          throttleRequestsLastUpdate,
        });

      if (isBlockheightBehind(syncedBlockheight, currentBlockheight) && !shouldThrottle) {
        const balanceUpdate = await refreshBalancesForAddresses({
          addressConfigs: pollEnabledAddresses,
        });
        throttleRequestsLastUpdate = Date.now();

        if (balanceUpdate.updates.length) {
          notifySubscribers('balance', updateSubscriptions, {
            ...balanceUpdate,
            interval: 'poll',
          });
        }

        refreshTransactions(pollEnabledAddresses);
      }
    }, pollingIntervalSeconds * 1000);
  }

  async function createWebsocketConnection(addressesToRegister: AddressConfig[] = []) {
    if (!websocketHandler) {
      websocketHandler = registerWebsocketConnection({
        rpcURL: websocketHandlersByChain.getWebsocketEndpoint(),
        onMessage: onSocketNudge,
        onError: () => {
          onError(
            new WebsocketConnectionError('Error creating websocket connection in history listener'),
          );
        },
      });
    }

    websocketHandler.connectWebsocket();

    const subscribeRequest = await websocketHandlersByChain.getSubscribeForUpdatesRequest(
      addressesToRegister as any,
    );

    websocketHandler.sendMessage(JSON.stringify(subscribeRequest));
  }

  // Used to register new addresses to receive initial and incremental balances/transactions
  async function addAddresses(
    addresses: AddressConfig[],
    config?: {
      lastSyncedBlockheight?: number;
      skipBalanceRefresh?: boolean;
      skipTransactionRefresh?: boolean;
      throttleRequestsMilliseconds?: number;
      throttleRequestsLastUpdate?: number;
    },
    isBalanceFetchingThrottleEnabled?: boolean,
  ) {
    const {
      lastSyncedBlockheight = 0,
      skipBalanceRefresh = false,
      skipTransactionRefresh = false,
      throttleRequestsMilliseconds: delay,
      throttleRequestsLastUpdate: lastUpdated,
    } = config || {};

    syncedBlockheight = lastSyncedBlockheight;
    throttleRequestsMilliseconds = delay;
    throttleRequestsLastUpdate = lastUpdated;

    // Note there are multiple `addresses` that share the same underlying `address`. we have an distinct address object
    // for each network even when the underlying address is the same
    addresses.forEach((address: any) => cache.setCacheItem(initializeCacheItem(address)));

    // Register nudge/SSE enabled addresses
    const nudgeEnabledAddresses = cache.all().filter((address) => address.isNudgeEnabled);
    if (nudgeEnabledAddresses.length) {
      if (nudgeConnection?.close) {
        nudgeConnection.close();
      }

      nudgeConnection = registerNudgeConnection(
        nudgeEnabledAddresses,
        blockchainSymbol,
        onSSENudge,
        undefined,
        onError,
        onNudgeReopen,
      );
    }

    // Register websocket enabled addresses
    const websocketEnabled = cache.all().filter((address) => address.isWebsocketNudgeEnabled);
    if (websocketEnabled.length) {
      createWebsocketConnection(websocketEnabled);
    }

    // Register polling enabled addresses
    const pollEnabled = cache
      .all()
      .filter((address) => !address.isNudgeEnabled && !address.isWebsocketNudgeEnabled);

    if (pollEnabled.length) {
      createPollingInterval(pollEnabled, isBalanceFetchingThrottleEnabled);
    }

    if (!skipBalanceRefresh) {
      const balanceUpdate = await refreshBalancesForAddresses({
        addressConfigs: addresses,
        isBalanceFetchingThrottleEnabled,
      });

      notifySubscribers('balance', updateSubscriptions, {
        ...balanceUpdate,
        interval: 'init',
      });

      balanceUpdate.updates.forEach((update: AddressConfigWithHistory) => {
        cache.setCacheItem(update);
      });
    }

    if (!skipTransactionRefresh) {
      refreshTransactions(addresses);
    }
  }

  function refreshTransactions(addresses?: AddressConfig[]) {
    // Refresh transactions uses a callback because certain chains may emit multiple
    // transactions events when addAddresses is called. It may be worth converting the
    // balance function to behave the same way for consistency.
    const addressesToRefresh = addresses || cache.all();

    refreshTransactionsForChain({
      addressConfigs: addressesToRefresh,
      onRefreshComplete: (updates) => {
        updates.forEach((update) => {
          cache.setCacheItem(update);
        });

        notifySubscribers('transactions', updateSubscriptions, {
          updates,
          interval: 'init',
        });
      },
    });
  }

  async function refreshBalancesForAddresses({
    addressConfigs,
    isRetry = false,
    fullRefresh = false,
    isBalanceFetchingThrottleEnabled,
  }: RefreshParams) {
    const initialAddresses = addressConfigs.map(initializeCacheItem);
    const currentBlockheight = await getBlockheight();
    const shouldRefresh =
      initialAddresses.length !== 0 &&
      (isBlockheightBehind(syncedBlockheight, currentBlockheight) || fullRefresh);

    const shouldThrottle =
      isBalanceFetchingThrottleEnabled &&
      shouldThrottleRequests({
        throttleRequestsMilliseconds,
        throttleRequestsLastUpdate,
      });

    const blockheight = fullRefresh ? 0 : syncedBlockheight;

    let update;
    if (shouldRefresh && !shouldThrottle) {
      update = await refreshBalancesForChain(initialAddresses, blockheight, isRetry);
      throttleRequestsLastUpdate = Date.now();
    } else {
      update = {
        updates: [],
        blockheight: currentBlockheight,
      };
    }

    if (update.blockheight && !isNaN(update.blockheight)) {
      syncedBlockheight = update.blockheight;
    }

    return update;
  }

  /* Performs a hard refresh of all address balances or a subset if a search is provided */
  async function refreshBalances(search: Partial<AddressConfig> = {}) {
    const addresses = cache.all(search);

    const balanceUpdate = await refreshBalancesForAddresses({
      addressConfigs: addresses,
      fullRefresh: true,
    });

    balanceUpdate.updates.forEach((update: AddressConfigWithHistory) => {
      cache.setCacheItem(update);
    });

    notifySubscribers('balance', updateSubscriptions, {
      ...balanceUpdate,
      interval: 'init',
    });
  }

  /* Removes addresses from the history listener */
  function removeAddresses(addresses: any[]) {
    addresses.forEach((address) => {
      cache.deleteCacheItem(initializeCacheItem(address));
    });

    // Close and reopen the nudge connection to ensure the correct addresses are being listened to
    if (nudgeConnection?.close) {
      nudgeConnection.close();
    }

    const nudgeEnabledAddresses = cache.all().filter((address) => address.isNudgeEnabled);

    if (nudgeEnabledAddresses.length) {
      nudgeConnection = registerNudgeConnection(
        nudgeEnabledAddresses,
        blockchainSymbol,
        onSSENudge,
      );
    }

    // Clear and recreate the polling interval to ensure the correct addresses are being polled
    if (pollingInterval) {
      clearInterval(pollingInterval);
    }

    const pollEnabledAddresses = cache.all().filter((address) => !address.isNudgeEnabled);

    if (pollEnabledAddresses.length) {
      createPollingInterval(pollEnabledAddresses, !!throttleRequestsMilliseconds);
    }
  }

  function resetHistoryListener() {
    if (nudgeConnection?.close) {
      nudgeConnection.close();
    }
    updateSubscriptions = [];
    errorSubscriptions = [];
    cache.clear();
    clearInterval(pollingInterval);
  }

  /* Allows a history listener client to manually emit an event to listeners */
  function notifySubscribersOfListener(
    eventType: HistoryEventUpdate['type'],
    balanceUpdate: Omit<HistoryEventUpdate, 'interval' | 'type'>,
  ) {
    notifySubscribers(eventType, updateSubscriptions, {
      ...balanceUpdate,
      interval: 'init',
    });
  }

  function setLastSyncedBlockheight(blockheight = 0) {
    syncedBlockheight = blockheight;
  }

  return {
    addAddresses,
    addEventListener,
    refreshBalances,
    refreshTransactions,
    removeAddresses,
    resetHistoryListener,
    setLastSyncedBlockheight,
    notifySubscribers: notifySubscribersOfListener,
  };
}

function isBlockheightBehind(lastSyncedBlockheight: number, currentBlockheight: number) {
  const blockheightsValid = !isNaN(lastSyncedBlockheight) && !isNaN(currentBlockheight);
  const blockheightNotSupported =
    currentBlockheight === 0 || lastSyncedBlockheight === 0 || !blockheightsValid;
  const blockheightIsBehind = blockheightsValid && lastSyncedBlockheight < currentBlockheight;
  return blockheightNotSupported || blockheightIsBehind;
}

/**
 * Checks if address history requests should be throttled based on
 * throttle configuration.
 *
 * @param throttleRequestsMilliseconds - The number of seconds to throttle requests.
 * @param throttleRequestsLastUpdate - The timestamp when last successful request was made.
 *
 * @returns A boolean indicating if requests should be throttled.
 */
export function shouldThrottleRequests({
  throttleRequestsMilliseconds,
  throttleRequestsLastUpdate,
}: ThrottleRequestParams): boolean {
  if (!throttleRequestsMilliseconds || !throttleRequestsLastUpdate) {
    return false;
  }
  const nextAllowedRequestTime = throttleRequestsLastUpdate + throttleRequestsMilliseconds;
  const now = Date.now();
  return now < nextAllowedRequestTime;
}

function notifySubscribers(
  eventType: HistoryEventUpdate['type'],
  events: HistoryEventSubscription<HistoryEventUpdate>[],
  historyEventUpdate: Omit<HistoryEventUpdate, 'type'>,
) {
  const { updates } = historyEventUpdate;
  events
    .filter(({ type }) => type === eventType)
    .forEach(({ cb, context }) => {
      if (!Object.keys(context).length) {
        cb({ type: eventType, ...historyEventUpdate });
        return;
      }

      const matchedUpdates = updates.filter((update) => {
        const matches = isMatch(update, context);
        return matches;
      });

      if (matchedUpdates.length) {
        cb({
          type: eventType,
          ...historyEventUpdate,
          updates: matchedUpdates,
        });
      }
    });
}

import { type CoinbaseWalletSDK, type ProviderInterface } from '@coinbase/wallet-sdk';
// eslint-disable-next-line no-restricted-imports
import { CoinbaseWalletProvider } from '@coinbase/wallet-sdk/dist/CoinbaseWalletProvider';
// eslint-disable-next-line no-restricted-imports
import { getFavicon } from '@coinbase/wallet-sdk/dist/core/type/util';
import { type Connector, ChainNotConfiguredError, createConnector } from '@wagmi/core';
import type { Evaluate, Mutable, Omit } from '@wagmi/core/internal';
import {
  type ProviderRpcError,
  Address,
  getAddress,
  numberToHex,
  SwitchChainError,
  UserRejectedRequestError,
} from 'viem';

import { CoinbaseSmartWalletProvider } from '../providers/CoinbaseSmartWalletProvider';

export type CoinbaseWalletParameters = Evaluate<
  Mutable<
    Omit<
      ConstructorParameters<typeof CoinbaseWalletSDK>[0],
      'appChainIds' // set via wagmi config
    > & {
      /** Preference for the type of wallet to display. */
      preference?: 'all' | 'smartWalletOnly' | 'eoaOnly' | undefined;
      keysUrl?: string;
    }
  >
>;

type CreateCoinbaseWalletConnectorParameters = {
  type: string;
  id: string;
  provider?: typeof CoinbaseWalletProvider | typeof CoinbaseSmartWalletProvider;
};

/**
 * This function is a factory function that returns the coinbaseWallet connectorFn from wagmi.
 * The difference between this function and the function createCoinbaseWalletConnectorFn returns and wagmi's is
 * that this function supports passing in a custom provider. This is because it enables concurrent coinbase wallet
 * usage within pano.
 * @param connectorParameters
 * @returns
 */

export function createCoinbaseWalletConnectorFn(
  connectorParameters: CreateCoinbaseWalletConnectorParameters,
) {
  const { type, id } = connectorParameters;
  const WalletProvider = connectorParameters.provider || CoinbaseWalletProvider;
  coinbaseWallet.type = type;
  function coinbaseWallet(parameters: CoinbaseWalletParameters) {
    type Provider = ProviderInterface & {
      // for backwards compatibility
      close?: () => void;
    };

    let walletProvider: Provider;
    let accountsChanged: Connector['onAccountsChanged'] | undefined;
    let chainChanged: Connector['onChainChanged'] | undefined;
    let disconnect: Connector['onDisconnect'] | undefined;

    return createConnector<Provider>((config) => ({
      id,
      name: 'Coinbase Wallet',
      supportsSimulation: true,
      type,
      async connect(options = {}) {
        const { chainId } = options;
        // @ts-expect-error extending wagmi connector to take connection params
        const scwOnboardMode = options?.scwOnboardMode;
        try {
          const provider = await this.getProvider();
          const params = scwOnboardMode ? [{ scwOnboardMode }] : undefined;
          const addresses: string[] = await provider.request({
            method: 'eth_requestAccounts',
            params,
          });

          // Address validation will occur downstream in the connector
          // because the address may contain the Smart Wallet user ID
          // which needs to be apart of the response.
          // workspaces/apps/dapp/src/connection/ethereum.ts
          const accounts = addresses.map((x) => x as Address);

          if (!accountsChanged) {
            accountsChanged = this.onAccountsChanged.bind(this);
            provider.on('accountsChanged', accountsChanged);
          }
          if (!chainChanged) {
            chainChanged = this.onChainChanged.bind(this);
            provider.on('chainChanged', chainChanged);
          }
          if (!disconnect) {
            disconnect = this.onDisconnect.bind(this);
            provider.on('disconnect', disconnect);
          }

          // Switch to chain if provided
          let currentChainId = await this.getChainId();
          if (chainId && currentChainId !== chainId) {
            const chain = await this.switchChain!({ chainId }).catch((error) => {
              if (error.code === UserRejectedRequestError.code) throw error;
              return { id: currentChainId };
            });
            currentChainId = chain?.id ?? currentChainId;
          }

          return { accounts, chainId: currentChainId };
        } catch (error) {
          if (
            /(user closed modal|accounts received is empty|user denied account)/i.test(
              (error as Error).message,
            )
          )
            throw new UserRejectedRequestError(error as Error);
          throw error;
        }
      },
      async disconnect() {
        const provider = await this.getProvider();

        if (accountsChanged) {
          provider.removeListener('accountsChanged', accountsChanged);
          accountsChanged = undefined;
        }
        if (chainChanged) {
          provider.removeListener('chainChanged', chainChanged);
          chainChanged = undefined;
        }
        if (disconnect) {
          provider.removeListener('disconnect', disconnect);
          disconnect = undefined;
        }

        provider.disconnect();
        provider.close?.();
      },
      async getAccounts() {
        const provider = await this.getProvider();
        return (
          await provider.request<string[]>({
            method: 'eth_accounts',
          })
        ).map((x) => {
          // The address may contain the Smart Wallet user ID which should be
          // removed before returning the accounts
          return getAddress(x.split(':')[0]);
        });
      },
      async getChainId() {
        const provider = await this.getProvider();
        const chainId = await provider.request<number>({
          method: 'eth_chainId',
        });
        return Number(chainId);
      },
      async getProvider() {
        if (!walletProvider) {
          walletProvider = new WalletProvider({
            preference: {
              keysUrl: parameters.keysUrl,
              options: parameters.preference ?? 'all',
            },
            metadata: {
              appChainIds: config.chains.map((x) => x.id),
              appLogoUrl: getFavicon(),
              appName: 'Coinbase Wallet',
            },
          });
        }

        return walletProvider;
      },
      async isAuthorized() {
        try {
          const accounts = await this.getAccounts();
          return !!accounts.length;
        } catch {
          return false;
        }
      },
      async switchChain({ addEthereumChainParameter, chainId }) {
        const chain = config.chains.find((chain_) => chain_.id === chainId);
        if (!chain) throw new SwitchChainError(new ChainNotConfiguredError());

        const provider = await this.getProvider();

        try {
          await provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: numberToHex(chain.id) }],
          });
          return chain;
        } catch (error) {
          // Indicates chain is not added to provider
          if ((error as ProviderRpcError).code === 4902) {
            try {
              let blockExplorerUrls: string[] | undefined;
              if (addEthereumChainParameter?.blockExplorerUrls)
                blockExplorerUrls = addEthereumChainParameter.blockExplorerUrls;
              else
                blockExplorerUrls = chain.blockExplorers?.default.url
                  ? [chain.blockExplorers?.default.url]
                  : [];

              let rpcUrls: readonly string[];
              if (addEthereumChainParameter?.rpcUrls?.length)
                rpcUrls = addEthereumChainParameter.rpcUrls;
              else rpcUrls = [chain.rpcUrls.default?.http[0] ?? ''];

              const addEthereumChain = {
                blockExplorerUrls,
                chainId: numberToHex(chainId),
                chainName: addEthereumChainParameter?.chainName ?? chain.name,
                iconUrls: addEthereumChainParameter?.iconUrls,
                nativeCurrency: addEthereumChainParameter?.nativeCurrency ?? chain.nativeCurrency,
                rpcUrls,
              };

              await provider.request({
                method: 'wallet_addEthereumChain',
                params: [addEthereumChain],
              });

              return chain;
            } catch (userRejectedError) {
              throw new UserRejectedRequestError(userRejectedError as Error);
            }
          }

          throw new SwitchChainError(error as Error);
        }
      },
      onAccountsChanged(accounts) {
        if (accounts.length === 0) {
          this.onDisconnect();
        } else {
          config.emitter.emit('change', {
            // The address may contain the Smart Wallet user ID which should be
            // removed before emitting the event
            accounts: accounts.map((x) => getAddress(x.split(':')[0])),
          });
        }
      },
      onChainChanged(chain) {
        const chainId = Number(chain);
        config.emitter.emit('change', { chainId });
      },
      async onDisconnect(_error) {
        config.emitter.emit('disconnect');

        const provider = await this.getProvider();
        if (accountsChanged) {
          provider.removeListener('accountsChanged', accountsChanged);
          accountsChanged = undefined;
        }
        if (chainChanged) {
          provider.removeListener('chainChanged', chainChanged);
          chainChanged = undefined;
        }
        if (disconnect) {
          provider.removeListener('disconnect', disconnect);
          disconnect = undefined;
        }
      },
    }));
  }
  return coinbaseWallet;
}

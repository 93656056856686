import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Mutex } from 'async-mutex';
import { useWithObservability } from 'cb-wallet-analytics/utils/useWithObservability';
import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { deleteDatabase, openDatabase } from 'cb-wallet-data/persistence/Database';
import { allowDatabaseAccess, preventDatabaseAccess } from 'cb-wallet-data/persistence/state';
import { bustQueryCache } from 'cb-wallet-data/QueryProvider';
import { updateSoftDeleteAccountStatus } from 'cb-wallet-data/stores/Accounts/database';
import { useHydrateAccounts } from 'cb-wallet-data/stores/Accounts/hooks/useHydrateAccounts';
import { useHydrateAssociatedAccountRecords } from 'cb-wallet-data/stores/Accounts/hooks/useHydrateAssociatedAccountRecords';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { clearCBStoreFromLocalStorage } from 'cb-wallet-data/stores/Accounts/utils/clearCBStoreFromLocalStorage';
import { useClearAuthTokensForDappProviderAccount } from 'cb-wallet-data/stores/Authentication/hooks/useClearAuthTokensForDappProviderAccount';

type DeleteDappProviderAccountParams = {
  // This is only used to revoke access tokens and clear local storage for the accounts
  accountIds: Account['id'][];
};

/**
 * A mutex that is shared with the dapp import code to ensure that we aren't writing to the database at the same time
 * that we're deleting from the database.
 *
 * This is to avoid leaving the database in an unexpected state.
 */
export const deleteDappProviderAccountMutex = new Mutex();

export function useDeleteDappProviderAccount() {
  const hydrateAssociatedAccountRecords = useHydrateAssociatedAccountRecords();
  const hydrateAccounts = useHydrateAccounts();
  const clearAuthTokensForDappProviderAccount = useClearAuthTokensForDappProviderAccount();
  const queryClient = useQueryClient();

  const withObservability = useWithObservability({
    stepWorkflow: 'useDeleteAccount',
  });

  return useCallback(
    async ({ accountIds }: DeleteDappProviderAccountParams) => {
      try {
        await deleteDappProviderAccountMutex.acquire();

        await withObservability('updateSoftDeleteAccountStatus', async () => {
          return Promise.all(
            accountIds.map(async (accountId) => updateSoftDeleteAccountStatus({ accountId })),
          );
        });

        // Make it so any ongoing reads/writes from the db succeed with a noop
        preventDatabaseAccess();
        await withObservability('deleteDatabase', deleteDatabase);

        // reopen the DB to trigger table creation
        await withObservability('openDatabase', openDatabase);
        allowDatabaseAccess();

        queryClient.clear();
        bustQueryCache();

        await withObservability('hydrateAssociatedAccountRecords', async () =>
          hydrateAssociatedAccountRecords(accountIds),
        );

        // deleting account metadata from localStorage
        await withObservability('clearCBStoreFromLocalStorage', clearCBStoreFromLocalStorage);

        // hydrating the recoil state for deleted account record from db
        await withObservability('hydrateAccounts', async () => hydrateAccounts());

        // clear auth tokens if the account is the current user's primary account
        await withObservability('clearAuthTokensForDappProviderAccount', async () =>
          Promise.all(
            accountIds.map(async (accountId) =>
              clearAuthTokensForDappProviderAccount({ accountId }),
            ),
          ),
        );

        // This method is not really deprecated, you can see in later version of async-mutex the author
        // has removed the @deprecated warning
        deleteDappProviderAccountMutex.release();
      } catch (error) {
        // This method is not really deprecated, you can see in later version of async-mutex the author
        // has removed the @deprecated warning
        deleteDappProviderAccountMutex.release();
        cbReportError({
          error: coerceError(error, ''),
          context: 'dapp-provider-account-deletion',
          severity: 'error',
          isHandled: false,
        });

        throw error;
      }
    },
    [
      clearAuthTokensForDappProviderAccount,
      hydrateAccounts,
      hydrateAssociatedAccountRecords,
      withObservability,
      queryClient,
    ],
  );
}

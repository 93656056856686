/* eslint-disable no-process-env */

if (!process.env.CB_WALLET_API_URL) {
  throw new Error('Missing ENV var: CB_WALLET_API_URL');
}

if (!process.env.SOLANA_RPC_URL) {
  throw new Error('Missing ENV var: SOLANA_RPC_URL');
}

if (!process.env.CB_API_URL) {
  throw new Error('Missing ENV var: CB_API_URL');
}

export const CB_WALLET_API_URL: string = process.env.CB_WALLET_API_URL;
export const CB_WALLET_API_DEV_URL = 'https://wallet-api-dev.cbhq.net';

export const CB_WALLET_CHAIN_PROXY_URL =
  process.env.CB_WALLET_CHAIN_PROXY_URL ?? 'https://chain-proxy.wallet.coinbase.com';
export const SOLANA_RPC_URL: string = process.env.SOLANA_RPC_URL;
export const CB_API_URL: string = process.env.CB_API_URL;

export const ONRAMP_API_URL: string =
  process.env.ONRAMP_API_URL ?? 'https://onramp.wallet.coinbase.com';
export const ONRAMP_API_DEV_URL = 'https://onramp-api-dev.cbhq.net';

export const OFFRAMP_PROD_API = 'https://offramp.wallet.coinbase.com';
export const OFFRAMP_DEV_API = 'https://offramp-wallet-dev.cbhq.net';

export const isE2eTest = process.env.TARGET_ENV?.includes('e2e') ?? false;
export const isNonExtensionUiTest = isE2eTest && !process.env.TARGET_ENV?.includes('extension');

// https://wallet-blockchain-dev.cbhq.net/
// https://blockchain.wallet.coinbase.com
export const walletBlockchainUrl = isE2eTest
  ? `${CB_WALLET_API_URL}/mock-blockchain`
  : process.env.CB_WALLET_BLOCKCHAIN_URL ?? 'https://blockchain.wallet.coinbase.com';

export const horizonUrl = isNonExtensionUiTest
  ? `${CB_WALLET_API_URL}/horizon-stellar`
  : 'https://horizon.stellar.org';

const NODE_ENV = process.env.NODE_ENV;
export const IS_PRODUCTION = NODE_ENV === 'production';

export const CBPAY_ID = process.env.CBPAY_ID;

export const WALLET_BRANCH_KEY = process.env.WALLET_BRANCH_KEY ?? '';

export const THIRDWEB_CLIENT_ID =
  process.env.THIRDWEB_CLIENT_ID ?? 'b891d14d436694bb9a7feeba91730b95';

export const GOOGLE_GEOLOCATION_API_KEY = process.env.GOOGLE_GEOLOCATION_API_KEY ?? '';

// TODO Not great to have this here, but we first need to figure out websocket proxying for third-party-proxy
// In the meantime, this will be ok, it's a separate api key that we can worst-case revoke.
// Ticket to track: https://jira.coinbase-corp.com/browse/WALL-36946
const HELIUS_WS_API_KEY = 'eedae435-e8af-4109-b35e-3d57c39b1e15';
export const HELIUS_WEBSOCKET_URL = `wss://atlas-mainnet.helius-rpc.com?api-key=${HELIUS_WS_API_KEY}`;

// NOTE: On iOS we can only test & develop with the production (publicly available) relying party
export const PROD_PASSKEY_RP_ID = 'keys.coinbase.com';
export const RELEASE_STAGE = process.env.RELEASE_STAGE;
export const PASSKEY_TIMEOUT = 180000;

import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { Address } from 'cb-wallet-data/stores/Addresses/models/Address';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';

type GetPrimaryAddressForIndexParams = {
  addresses: Address[];
  blockchain: Blockchain;
  currencyCode: CurrencyCode;
  network: Network;
  walletIndex: bigint;
  accountId: string;
};

// Accepts an array of addresses and will find the primary address for
// the provided index
export function getPrimaryAddressForIndex({
  addresses,
  blockchain,
  currencyCode,
  network,
  walletIndex,
  accountId,
}: GetPrimaryAddressForIndexParams): Address | undefined {
  return addresses.find((address) => {
    return (
      address.currencyCode.rawValue === currencyCode.rawValue &&
      address.blockchain.rawValue === blockchain.rawValue &&
      address.network.rawValue === network.rawValue &&
      address.index === walletIndex &&
      address.accountId === accountId
    );
  });
}

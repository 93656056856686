import { useOnMount } from 'cb-wallet-data/hooks/useOnMount';

export const COOKIE_HAS_L1_WARNING_SEEN = 'hasL1WarningSeen';

const COOKIE_EXPIRY = Date.now() + 365 * 24 * 60 * 60 * 1000;

export function useSetL1WarningCookie() {
  useOnMount(() => {
    document.cookie = `${COOKIE_HAS_L1_WARNING_SEEN}=true; expires=${new Date(
      COOKIE_EXPIRY,
    )}; path=/;`;
  });
}

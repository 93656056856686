export enum ProcessWalletConnectionResponseErrorType {
  ADDRESS_ALREADY_IMPORTED = 'address-already-imported',
  UNKNOWN_ERROR = 'unknown-error',
}

export class ProcessWalletConnectionResponseError extends Error {
  constructor(readonly name: ProcessWalletConnectionResponseErrorType, readonly message: string) {
    super(message);
  }
}

import { useCallback } from 'react';
import { useOverridableKillSwitch as useKillSwitch } from 'cb-wallet-data/hooks/KillSwitches/useOverridableKillSwitch';

import { allEthNetworkChainIds } from './utils/allEthNetworksChainIds';
import { getKillSwitchByPlatform } from './utils/getKillSwitchByPlatform';

export type EthNetworkKillSwitch =
  | 'kill_send'
  | 'kill_dapp_tx'
  | 'kill_txhistory_service'
  | 'kill_chain'
  | 'kill_txhistory_service_v3';

/**
 * @param killSwitchName - This argument should be structured like `kill_send`
 *                         and the actual kill switch name would be like `kill_send_network_polygon_extension`
 * @returns a function that can be called with a network's `chainId` (BN) argument which returns true or false.
 *
 * @note If you need to kill all eth networks, use `useKillSwitchByBlockchain` and enable the `kill_send_blockchain_eth_extension` for example.
 */
export function useKillSwitchByEthNetwork(killSwitchName: EthNetworkKillSwitch) {
  const isETHNetworkKillSwitched = useKillSwitch(
    getKillSwitchByPlatform(`${killSwitchName}_network_eth`),
  );
  const isETCNetworkKillSwitched = useKillSwitch(
    getKillSwitchByPlatform(`${killSwitchName}_network_etc`),
  );
  const isBSCNetworkKillSwitched = useKillSwitch(
    getKillSwitchByPlatform(`${killSwitchName}_network_bsc`),
  );
  const isFTMNetworkKillSwitched = useKillSwitch(
    getKillSwitchByPlatform(`${killSwitchName}_network_ftm`),
  );
  const isGnosisNetworkKillSwitched = useKillSwitch(
    getKillSwitchByPlatform(`${killSwitchName}_network_gnosis`),
  );
  const isPolygonNetworkKillSwitched = useKillSwitch(
    getKillSwitchByPlatform(`${killSwitchName}_network_polygon`),
  );
  const isOptimismNetworkKillSwitched = useKillSwitch(
    getKillSwitchByPlatform(`${killSwitchName}_network_optimism`),
  );
  const isArbitrumNetworkKillSwitched = useKillSwitch(
    getKillSwitchByPlatform(`${killSwitchName}_network_arbitrum`),
  );
  const isAvalancheCNetworkKillSwitched = useKillSwitch(
    getKillSwitchByPlatform(`${killSwitchName}_network_avalanche_c`),
  );
  const isBaseNetworkKillSwitched = useKillSwitch(
    getKillSwitchByPlatform(`${killSwitchName}_network_base_v2`),
  );
  const isZetachainNetworkKillSwitched = useKillSwitch(
    getKillSwitchByPlatform(`${killSwitchName}_network_zetachain`),
  );
  const isZoraNetworkKillSwitched = useKillSwitch(
    getKillSwitchByPlatform(`${killSwitchName}_network_zora`),
  );
  // Testnets
  const isSepoliaBaseNetworkKillSwitched = useKillSwitch(
    getKillSwitchByPlatform(`${killSwitchName}_network_base_sepolia`),
  );
  const isHoleskyNetworkKillSwitched = useKillSwitch(
    getKillSwitchByPlatform(`${killSwitchName}_network_holesky`),
  );
  const isSepoliaOptimismNetworkKillSwitched = useKillSwitch(
    getKillSwitchByPlatform(`${killSwitchName}_network_optimism_sepolia`),
  );
  const isSepoliaNetworkKillSwitched = useKillSwitch(
    getKillSwitchByPlatform(`${killSwitchName}_network_sepolia`),
  );
  const isBnbTestnetNetworkKillSwitched = useKillSwitch(
    getKillSwitchByPlatform(`${killSwitchName}_network_bnb_testnet`),
  );
  const isAvaxFujiNetworkKillSwitched = useKillSwitch(
    getKillSwitchByPlatform(`${killSwitchName}_network_avax_fuji`),
  );
  const isFtmTestnetNetworkKillSwitched = useKillSwitch(
    getKillSwitchByPlatform(`${killSwitchName}_network_ftm_testnet`),
  );
  const isSepoliaArbitrumNetworkKillSwitched = useKillSwitch(
    getKillSwitchByPlatform(`${killSwitchName}_network_arbitrum_sepolia`),
  );

  return useCallback(
    function isKilled(chainId?: bigint): boolean {
      switch (Number(chainId)) {
        case allEthNetworkChainIds.ETHEREUM:
          return isETHNetworkKillSwitched;
        case 61: // gonna remove it as soon as we have a final decision about deprecated chains kill switches
          return isETCNetworkKillSwitched;
        case allEthNetworkChainIds.BINANCE_SMART_CHAIN:
          return isBSCNetworkKillSwitched;
        case allEthNetworkChainIds.FANTOM:
          return isFTMNetworkKillSwitched;
        case allEthNetworkChainIds.GNOSIS:
          return isGnosisNetworkKillSwitched;
        case allEthNetworkChainIds.POLYGON:
          return isPolygonNetworkKillSwitched;
        case allEthNetworkChainIds.OPTIMISM:
          return isOptimismNetworkKillSwitched;
        case allEthNetworkChainIds.ARBITRUM:
          return isArbitrumNetworkKillSwitched;
        case allEthNetworkChainIds.AVALANCHE_C_CHAIN:
          return isAvalancheCNetworkKillSwitched;
        case allEthNetworkChainIds.BASE:
          return isBaseNetworkKillSwitched;
        case allEthNetworkChainIds.ZETACHAIN:
          return isZetachainNetworkKillSwitched;
        case allEthNetworkChainIds.ZORA:
          return isZoraNetworkKillSwitched;
        // Testnets
        case allEthNetworkChainIds.BASE_SEPOLIA:
          return isSepoliaBaseNetworkKillSwitched;
        case allEthNetworkChainIds.HOLESKY:
          return isHoleskyNetworkKillSwitched;
        case allEthNetworkChainIds.OP_SEPOLIA:
          return isSepoliaOptimismNetworkKillSwitched;
        case allEthNetworkChainIds.SEPOLIA:
          return isSepoliaNetworkKillSwitched;
        case allEthNetworkChainIds.BNB_TESTNET:
          return isBnbTestnetNetworkKillSwitched;
        case allEthNetworkChainIds.AVAX_FUJI:
          return isAvaxFujiNetworkKillSwitched;
        case allEthNetworkChainIds.FTM_TESTNET:
          return isFtmTestnetNetworkKillSwitched;
        case allEthNetworkChainIds.ARBITRUM_SEPOLIA:
          return isSepoliaArbitrumNetworkKillSwitched;
        default:
          return false;
      }
    },
    [
      isETHNetworkKillSwitched,
      isETCNetworkKillSwitched,
      isBSCNetworkKillSwitched,
      isFTMNetworkKillSwitched,
      isGnosisNetworkKillSwitched,
      isPolygonNetworkKillSwitched,
      isOptimismNetworkKillSwitched,
      isArbitrumNetworkKillSwitched,
      isAvalancheCNetworkKillSwitched,
      isBaseNetworkKillSwitched,
      isZetachainNetworkKillSwitched,
      isZoraNetworkKillSwitched,
      isSepoliaBaseNetworkKillSwitched,
      isHoleskyNetworkKillSwitched,
      isSepoliaOptimismNetworkKillSwitched,
      isSepoliaNetworkKillSwitched,
      isBnbTestnetNetworkKillSwitched,
      isAvaxFujiNetworkKillSwitched,
      isFtmTestnetNetworkKillSwitched,
      isSepoliaArbitrumNetworkKillSwitched,
    ],
  );
}

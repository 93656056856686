/* eslint-disable no-restricted-imports */
import { useCallback, useEffect, useRef } from 'react';
import {
  ActionType,
  AnalyticsEventImportance,
  ComponentType,
  createEventConfig as ccaCreateEventConfig,
  EventDataDefault,
  logEvent as ccaLogEvent,
  LogMetric,
  logMetric as ccaLogMetric,
  MetricType,
  useEventLogger as useCCAEventLogger,
  useLogEventOnMount as useCCALogEventOnMount,
  ValidEventData,
} from '@cbhq/client-analytics';

import { SDKRequestMetadata } from '../scw';

import type { EventProperties, MaybeAccountTypeEventPropertyValue } from './types/eventProperties';
import { WalletMetricTags } from './types/metricTags';

type AccountMetadataField =
  | 'activeAccountIndex'
  | 'activeAccountType'
  | 'activeAccount'
  | 'activeWalletGroupIndex'
  | 'walletGroupsLength';

// LoggableEventData are fields that individual events can pass. What is omitted
// are metadata fields that should exist on all events
export type LoggableEventData = Omit<
  AnalyticsEventProperties<ValidEventData>,
  AccountMetadataField
>;

// LoggableDataEventData is used for data events which do not require action or componentType
type LoggableDataEventData = Omit<LoggableEventData, 'action' | 'componentType'>;

export type LogEventCustomParams = Omit<
  LoggableEventData,
  'action' | 'componentType' | 'loggingId'
>;

export type LogEventDataLayerParams = LoggableDataEventData & {
  action?: ActionType;
  componentType?: ComponentType;
};

export type LogEventOnMountParams = AnalyticsEventProperties<{
  componentType: ComponentType;
  loggingId?: string;
}>;

export type EventLoggerParams = LoggableEventData;

// CreateEventConfigEventData is used for createEventConfig events which do not require loggingId, action or componentType
export type CreateEventConfigEventData = Omit<
  LoggableEventData,
  'loggingId' | 'action' | 'componentType'
>;

export type AnalyticsEventProperties<T> = T & {
  [key in keyof EventProperties]: EventProperties[key];
};

export type AnalyticsLogFunction = (customProperties?: LogEventCustomParams) => void;

type AccountMetadata = {
  accountsLength?: number;
  activeAccountIndex?: number;
  activeAccountType?: MaybeAccountTypeEventPropertyValue;
  activeWalletGroupIndex?: number;
  userFacingAccountsLength?: number;
  walletGroupsLength?: number;
};

type DappMetadata = {
  accountsLength?: number;
  dappProviderUserId?: string;
};

// Used to emit an event upstream during development
// eslint-disable-next-line @cbhq/func-declaration-in-module
let emitEventUpstream: (message: string) => void = () => {};

// Used to emit a breadcrumb upstream
// eslint-disable-next-line @cbhq/func-declaration-in-module
let emitBreadcrumbUpstream: (breadcrumb: string) => void = () => {};

// Used to emit a metric upstream during development
// eslint-disable-next-line @cbhq/func-declaration-in-module
let emitMetricUpstream: (metric: LogMetric) => void = () => {};

/**
 * accountMetadata
 *
 * @see useSetAmplitudeMetadataForActiveAccount
 */
let accountMetadata: AccountMetadata = {};

let currentAppMode = 'superapp';

let sdkRequestMetadata: SDKRequestMetadata = {};

let dappMetadata: DappMetadata = {};

/**
 * appendGlobalProperties
 *
 * NOTE accountMetadata may override previous event data fields
 * if they share the same field name. However, the value should
 * be the same. If not, it should defer to accountMetadata and
 * the other field should probably be renamed.
 *
 * @param eventData
 * @returns eventData with the account metadata fields
 */
function appendGlobalEventProperties<T>(eventData: T): T & AccountMetadata {
  return {
    ...eventData,
    ...accountMetadata,
    ...sdkRequestMetadata,
    ...dappMetadata,
    currentAppMode,
  };
}

/**
 * setAccountMetadata
 *
 * Setter for accountMetadata
 *
 * @param metadata
 */
export function setAccountMetadata(metadata: Required<AccountMetadata>) {
  // This should be overwritten every time in order to ensure that all
  // metadata fields is up to date.
  accountMetadata = {
    ...metadata,
  };
}

export function setSDKRequestMetadata(metadata: SDKRequestMetadata) {
  sdkRequestMetadata = {
    ...metadata,
  };
}

export function setDappMetadata(metadata: Required<DappMetadata>) {
  dappMetadata = {
    ...metadata,
  };
}

export function setCurrentAppModePropertyForLogger(newValue: string) {
  currentAppMode = newValue;
}

// NOTE Only used for unit tests as there should not be a scenario where
// account metadata needs to be reset. There was consideration to have
// default values for account metadata, but held off in order to capture
// when the metadata values would be initially set by the application.
export function resetAccountMetadata() {
  accountMetadata = {};
}

export function resetSDKRequestMetadata() {
  sdkRequestMetadata = {};
}

export function setEmitEventUpstreamFn(emitEventUpstreamFn: (message: string) => void) {
  emitEventUpstream = emitEventUpstreamFn;
}

export function setBreadcrumbEmitter(emitBreadcrumbFn: (breadcrumb: string) => void) {
  emitBreadcrumbUpstream = emitBreadcrumbFn;
}

export function setEmitMetricUpstreamFn(emitMetricUpstreamFn: (metric: LogMetric) => void) {
  emitMetricUpstream = emitMetricUpstreamFn;
}

export function logEvent(
  eventName: string,
  eventData: LoggableEventData,
  eventImportance?: AnalyticsEventImportance,
  isNoisy = false, // way to mark diagnostic events that we don't want to show up in debug toasts
) {
  emitToast(eventName, eventData, isNoisy);
  emitBreadcrumbUpstream(eventName);

  const finalEventProperties = appendGlobalEventProperties<LoggableEventData>(eventData);
  return ccaLogEvent(eventName, finalEventProperties, eventImportance);
}

export function useLogEventOnMount(
  eventName: string,
  properties: LogEventOnMountParams,
  isNoisy = false,
) {
  useEffect(() => {
    emitToast(eventName, properties, isNoisy);
    // eslint-disable-next-line wallet/exhaustive-deps
  }, []);

  const propertiesWithAccountMetadata =
    appendGlobalEventProperties<LogEventOnMountParams>(properties);
  return useCCALogEventOnMount(eventName, propertiesWithAccountMetadata);
}

export function useEventLogger(
  eventName: string,
  properties: EventLoggerParams,
  eventImportance?: AnalyticsEventImportance | undefined,
  isNoisy = false,
): AnalyticsLogFunction {
  const propertiesRef = useRef(properties);
  useEffect(
    function setNewPropertyRef() {
      propertiesRef.current = properties;
    },
    [properties],
  );

  const propertiesWithAccountMetadata = appendGlobalEventProperties<EventLoggerParams>(properties);
  const logEventCallback = useCCAEventLogger(
    eventName,
    propertiesWithAccountMetadata,
    eventImportance,
  );
  return useCallback(
    (customProperties?: EventDataDefault | undefined) => {
      emitToast(eventName, propertiesRef.current, isNoisy);
      logEventCallback(customProperties);
    },
    [eventName, isNoisy, logEventCallback],
  );
}

/**
 * For data layer events we do not require an action or componentType since we are
 * usually not inside a component, but we can pass one in optionally
 */
export function logDataEvent(
  eventType: string,
  eventProperties: LogEventDataLayerParams,
  eventImportance?: AnalyticsEventImportance | undefined,
) {
  const { action, componentType } = eventProperties;
  const eventPropertiesWithAccountMetadata =
    appendGlobalEventProperties<LogEventDataLayerParams>(eventProperties);

  return ccaLogEvent(
    eventType,
    {
      ...eventPropertiesWithAccountMetadata,
      isDiagnostic: true,
      // TODO: Once the analytics team adds a better componentType for these events
      // we can update, but defaulting to this for now so our events show up in datadog

      // NOTE: We need at least one of `action` or `componentType` to be any value OTHER than "unknown" or else
      // it will NOT get forwarded to DataDog.
      // See CCA notebook: https://app.datadoghq.com/notebook/1571323/analytics-service-tag-cleanup-11-16-21
      // See original PR: https://github.cbhq.net/wallet/wallet-mobile/pull/8188
      action: action ?? ActionType.measurement,
      componentType: componentType ?? ComponentType.unknown,
    },
    eventImportance,
  );
}

/**
 *
 * Wrapper to enforce that we:
 * - use only allowed tags when logging metrics
 * - emit metrics upstream during development
 */
export function logMetric(metricData: {
  metricName: string;
  metricType: MetricType;
  value: number;
  tags?: WalletMetricTags;
}) {
  emitMetricUpstream(metricData);
  return ccaLogMetric(metricData);
}

export function createEventConfig(
  componentName: string,
  actions: ActionType[],
  data?: CreateEventConfigEventData,
) {
  const dataWithAccountMetadata = data
    ? appendGlobalEventProperties<CreateEventConfigEventData>(data)
    : undefined;
  return ccaCreateEventConfig(componentName, actions, dataWithAccountMetadata);
}

function emitToast(
  eventName: string,
  properties: EventLoggerParams | LogEventOnMountParams,
  isNoisy: boolean,
) {
  if (!isNoisy) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { loggingId, ...filteredData } = properties;
    const message = `${eventName}\n\nproperties=${JSON.stringify(filteredData, null, '\t')}`;
    emitEventUpstream(message);
  }
}

/**
 * You should use this function instead of `Array.includes` when you are casting
 * an array to a readonly array with the `as const` type assertion.
 *
 * @param collection - a readonly array with the `as const` type assertion
 * @param el - an item to check for inclusion in the collection
 * @returns a boolean indicating whether the item is included in the collection
 */
export function includes<T extends U, U>(collection: readonly T[], item: U): item is T {
  return collection.includes(item as T);
}

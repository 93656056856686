import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useGetAccountById } from 'cb-wallet-data/stores/Accounts/hooks/useGetAccountById';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { useClearAuthTokensAndUnsetUser } from 'cb-wallet-data/stores/Authentication/hooks/useClearAuthTokensAndUnsetUser';
import {
  ENDPOINT as PROFILE_ADDRESS_ENDPOINT,
  useInvalidateProfileByAddressQuery,
} from 'cb-wallet-data/stores/DecentralizedID/hooks/useProfileByAddress';
import {
  fetchUserImperatively,
  USER_PROFILE_QUERY_KEY,
} from 'cb-wallet-data/stores/User/hooks/useUser';
import { revokeAccessToken } from 'cb-wallet-http/Authentication/tokens/refreshAccessToken';
import { StoreKeys_userId } from 'cb-wallet-http/User/state';
import { Store } from 'cb-wallet-store/Store';
import toLower from 'lodash/toLower';

import { useCanAuthenticateWithAPI } from '../tokens/useCanAuthenticateWithAPI';

type ClearAuthTokensForAccountParams = {
  accountId: Account['id'];
};

/**
 * useClearAuthTokensForDappProviderAccount
 *
 * Hook used for cleaning up user authentication tokens when a dapp provider account is deleted.
 *
 * This hook does the following:
 * 1. Checks if the user is authenticated (returns early if not)
 * 2. Gets the account and user in question (returns early if not found)
 * 3 Validates that the accounts primary address matches the authenticated users primary address (this might need updates)
 * 4. Revokes the access token
 * 5. Clears authentication tokens and Unset the userId in the observability client
 * 6. Removes the userProfile query from the queryClient
 * 7. Invalidates the profileByAddress query
 * 8. Removes the profileByAddress query from the queryClient
 * 9. Removes the userId from local storage
 */
export function useClearAuthTokensForDappProviderAccount() {
  const queryClient = useQueryClient();
  const getAccountById = useGetAccountById();
  const clearAuthTokensAndUnsetUser = useClearAuthTokensAndUnsetUser();
  const canAuthenticateWithAPI = useCanAuthenticateWithAPI();
  const invalidateProfileByAddressQuery = useInvalidateProfileByAddressQuery();

  return useCallback(
    async function clearAuthTokensForDappProviderAccount({
      accountId,
    }: ClearAuthTokensForAccountParams) {
      // If the user is not authenticated we don't have anything to do
      if (!canAuthenticateWithAPI) {
        return;
      }

      const account = getAccountById(accountId);
      const user = await fetchUserImperatively();
      if (!account || !user) {
        return;
      }

      // Validate the account's primary address matches the authenticated user's primary address
      const shouldRemoveAccessToken =
        toLower(account.primaryAddress) === toLower(user?.primaryAddress);

      if (shouldRemoveAccessToken) {
        await revokeAccessToken();

        // Clear auth tokens and unset the userId in the observability client
        clearAuthTokensAndUnsetUser();

        // Remove the userProfile query from the queryClient
        queryClient.removeQueries([USER_PROFILE_QUERY_KEY]);

        // Invalidate the profileByAddress query
        invalidateProfileByAddressQuery(account.primaryAddress);

        // Remove the profileByAddress query from the queryClient
        queryClient.removeQueries([PROFILE_ADDRESS_ENDPOINT, account.primaryAddress]);

        // Remove the userId from local storage
        Store.set(StoreKeys_userId, null);
      }
    },
    [
      canAuthenticateWithAPI,
      clearAuthTokensAndUnsetUser,
      getAccountById,
      invalidateProfileByAddressQuery,
      queryClient,
    ],
  );
}

import { walletConnect } from '@wagmi/connectors';

import { WALLET_CONNECT_PROJECT_ID } from ':dapp/config/env';
import { makeEthereumNetwork } from ':dapp/connection/networks';
import { WalletProviderInfo } from ':dapp/connection/types';
import { WalletConnectorManager } from ':dapp/connection/WalletConnectorManager';

import { setupConnector } from '../wagmi';

const evmConnector = setupConnector(
  walletConnect({
    projectId: WALLET_CONNECT_PROJECT_ID,
    isNewChainsStale: false,
    qrModalOptions: {
      themeVariables: {
        '--wcm-z-index': '9999',
      },
    },
  }),
);
const networks = [makeEthereumNetwork(evmConnector)];

/**
 * Never update these ids. They are used to identify the wallet provider in the
 * database and filter out duplicate connectors from EIP6963. Removing or changing
 * will break panorama for existing users.
 */
export const WALLET_CONNECT_ID = 'walletconnect';

export const WALLETCONNECT: WalletProviderInfo = {
  id: WALLET_CONNECT_ID,
  connectorManager: new WalletConnectorManager(WALLET_CONNECT_ID, networks),
  name: 'WalletConnect',
  image: 'https://go.wallet.coinbase.com/static/wallets/walletconnect.svg',
  enabled: true,
  networks,
};

import { useCallback, useMemo, useState } from 'react';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { useHasAccountsAndWalletGroups } from 'cb-wallet-data/hooks/initialization/useHasAccountsAndWalletGroups';
import { useRouter } from 'next/router';
import { navigationBarMinHeight } from '@cbhq/cds-common/tokens/navigation';
import { zIndex } from '@cbhq/cds-common/tokens/zIndex';
import { IconButton, NavigationIconButton } from '@cbhq/cds-web/buttons';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextTitle3 } from '@cbhq/cds-web/typography';

import { CreateButton } from ':dapp/components/CreateButton/CreateButton';
import { GlobalActionButtonsMobile } from ':dapp/components/Navbar/GlobalActionButtonsMobile';
import { useIsCoinbaseDappBrowser } from ':dapp/hooks/useIsCoinbaseDappBrowser';
import { useSearchContext } from ':dapp/providers/SearchProvider';
import { RoutesEnum, SettingRoutesEnum } from ':dapp/utils/RoutesEnum';

import { Logo } from '../AppLogo/Logo';
import { ConnectButton } from '../ConnectButton/ConnectButton';
import { ConnectedWalletsMenu } from '../ConnectedWalletsMenu/ConnectedWalletsMenu';
import { SearchButton } from '../Search/SearchButton';

import { GlobalActionsDrawer } from './GlobalActionsDrawer';

// TODO: back button should not render on /ocs/shop but with this
// logic, it will
const BACK_BUTTON_PAGES = [
  RoutesEnum.OCS_CHECKOUT,
  RoutesEnum.OCS_ORDER_HISTORY,
  RoutesEnum.OCS_SHOP,
  RoutesEnum.OCS_PRODUCTS,
  RoutesEnum.OCS_SWEEPSTAKES,
  RoutesEnum.OCS_FAQ,
  RoutesEnum.OCS_TOS,
];

const NAMED_PAGES = [
  {
    route: RoutesEnum.OCS_ORDER_HISTORY,
    name: 'Order history',
  },
  {
    route: RoutesEnum.OCS_PRODUCTS,
    name: 'All merch',
  },
];

export function Navbar() {
  const router = useRouter();
  const { isSearching, toggleSearchOn, searchPositionRef } = useSearchContext();
  const hasAccountsAndWalletGroups = useHasAccountsAndWalletGroups();
  const isGlobalSearchEnabled = useIsFeatureEnabled('global_search_web');
  const isCoinbaseDappBrowser = useIsCoinbaseDappBrowser();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { isPhone, isTablet } = useBreakpoints();

  const toggleDrawer = useCallback(() => {
    setIsDrawerOpen(!isDrawerOpen);
  }, [isDrawerOpen]);

  const walletConnectionNav = useMemo(() => {
    if (hasAccountsAndWalletGroups) {
      return <ConnectedWalletsMenu />;
    }
    return (
      <HStack gap={1}>
        {!isCoinbaseDappBrowser && <CreateButton isPhone={isPhone} />}
        <ConnectButton buttonType="gradient" type="connect" />
      </HStack>
    );
  }, [isCoinbaseDappBrowser, hasAccountsAndWalletGroups, isPhone]);

  const backButtonPressed = useCallback(() => {
    router.back();
  }, [router]);

  const handleSettingsIconPressed = useCallback(() => {
    if (isPhone || isTablet) {
      router.push(SettingRoutesEnum.SETTINGS);
    } else {
      router.push(SettingRoutesEnum.MANAGE_WALLETS);
    }
  }, [isPhone, isTablet, router]);

  let shouldRenderBackButton = false;
  for (const page of BACK_BUTTON_PAGES) {
    const matchesSelectionCriterion = !!router.pathname.startsWith(page);
    if (matchesSelectionCriterion) {
      shouldRenderBackButton = true;
      break;
    }
  }

  let shouldRenderTitle = false;
  let title = '';
  for (const page of NAMED_PAGES) {
    const matchesSelectionCriterion = !!router.pathname.startsWith(page.route);
    if (matchesSelectionCriterion) {
      shouldRenderTitle = true;
      title = page.name;
      break;
    }
  }

  return (
    <VStack
      as="nav"
      position="sticky"
      top={0}
      left={0}
      right={0}
      background
      borderedBottom
      height={navigationBarMinHeight}
      gap={2}
      spacing={2}
      zIndex={zIndex.navigation}
      width="100%"
    >
      <HStack
        height={navigationBarMinHeight}
        alignItems="center"
        justifyContent="space-between"
        gap={2}
      >
        {isCoinbaseDappBrowser ? (
          <>
            <IconButton
              name="hamburger"
              accessibilityLabel="Sidenav menu items"
              variant="secondary"
              transparent
              testID="side-navbar-hamburger"
              onPress={toggleDrawer}
            />
            <GlobalActionsDrawer isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
          </>
        ) : null}
        {(isPhone || !hasAccountsAndWalletGroups) && <Logo />}
        {shouldRenderBackButton && (
          <IconButton name="backArrow" onClick={backButtonPressed} testID="navbar-back-arrow" />
        )}
        {shouldRenderTitle ? <TextTitle3 as="p">{title}</TextTitle3> : null}
        <Box alignItems="center" justifyContent="flex-end" flexGrow={1}>
          {isGlobalSearchEnabled && !isPhone ? (
            <SearchButton
              data-testid="navbar-search-button"
              isSearching={isSearching}
              toggleSearchOn={toggleSearchOn}
              searchPositionRef={searchPositionRef}
            />
          ) : null}
        </Box>
        <HStack gap={1}>
          {!isCoinbaseDappBrowser && isPhone ? <GlobalActionButtonsMobile /> : null}
          {isGlobalSearchEnabled && isPhone ? (
            <SearchButton
              data-testid="navbar-search-button"
              isSearching={isSearching}
              toggleSearchOn={toggleSearchOn}
              searchPositionRef={searchPositionRef}
            />
          ) : null}
          <HStack gap={1}>
            {walletConnectionNav}
            {hasAccountsAndWalletGroups && (
              <Box>
                <NavigationIconButton
                  testID="settings-button"
                  name="settings"
                  onPress={handleSettingsIconPressed}
                />
              </Box>
            )}
          </HStack>
        </HStack>
      </HStack>
    </VStack>
  );
}

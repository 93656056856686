import { useCachePortfolioWallets } from './useCachePortfolioWallets';
import { useCacheWalletsByWalletGroup } from './useCacheWalletsByWalletGroup';

/**
 * Cache and debounce expensive wallet computations so we don't do extra work when
 * balances update. See the "Performance" section of the data layer README for details
 */
export function useCacheWalletAtoms(walletGroupIds: (string | undefined)[]) {
  useCacheWalletsByWalletGroup();
  useCachePortfolioWallets(walletGroupIds);
}

import { useEffect, useMemo, useState } from 'react';

type DefaultDimensions = { width?: number; height?: number };

export function getDimensions(defaultDimensions?: DefaultDimensions) {
  if (typeof window === 'undefined') {
    return {
      width: defaultDimensions?.width || 0,
      height: defaultDimensions?.height || 0,
    };
  }
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

export function useViewportDimensions(defaultDimensions?: DefaultDimensions) {
  const initialState = useMemo(() => getDimensions(defaultDimensions), [defaultDimensions]);

  const [dimensions, setDimensions] = useState(initialState);

  useEffect(function addScreenResizeListener() {
    const listener = () => setDimensions(getDimensions());
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, []);

  return dimensions;
}

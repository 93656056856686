export class EthereumError extends Error {
  private constructor(readonly name: string, readonly message: string) {
    super(message);
  }

  /**
   * Unable to find gas price. CipherCore wasn't able to determine to fetch gas price
   */
  static unableToFindGasPrice = new EthereumError('UnableToFindGasPrice', '');

  /**
   * The gas estimation failed.
   */
  static unableToEstimateGas = new EthereumError('UnableToEstimateGas', '');

  /**
   * Thrown when unable to fetch balance for generating an unsigned tx
   */
  static unableToCalculateBalance = new EthereumError('UnableToCalculateBalance', '');

  /**
   * Thrown when unable to find wallet used for signature
   */
  static unableToFindWallet = new EthereumError('UnableToFindWallet', '');

  /**
   * Thrown when unable to find the signed ethereum transaction in the database
   */
  static unableToFindSignedTx = new EthereumError('UnableToFindSignedTx', '');

  /**
   * Thrown when trying to perform ERC20 actions on a non-ERC20 wallet or address
   */
  static invalidERC20 = new EthereumError('InvalidERC20', '');

  /**
   * The dapp provided an incorrect nonce
   */
  static incorrectNonceProvided = new EthereumError(
    'IncorrectNonceProvided',
    'Unable to determine nonce or incorrect nonce provided',
  );

  /**
   * This means we sent an unsigned transaction to CipherCore
   */
  static transactionSignatureMissing = new EthereumError(
    'TransactionSignatureMissing',
    'Tx signature missing',
  );

  /**
   * Thrown when unable to get ERC20 token details
   */
  static unableToGetERC20Details = new EthereumError('UnableToGetERC20Details', '');

  /**
   * Thrown when unable to cast [Configuration] to an [EthereumBasedConfiguration]
   */
  static invalidConfiguration = new EthereumError('InvalidConfiguration', '');

  /**
   * Thrown when accessing estimatedFee property on EthereumUnsigned1559Tx
   */
  static unsupportedAccessToEstimatedFeeOn1559Tx = new EthereumError(
    'UnsupportedAccessToEstimatedFee',
    '1559 tx estimated fee should be represented as a range',
  );

  /**
   * Thrown when accessing a 1559 property on a legacy transaction
   */
  static unsupported1559Property = new EthereumError(
    'UnsupportedAccessToEstimatedFee',
    'This property does not exist on legacy transactions',
  );

  static unsupportedUserOpProperty = new EthereumError(
    'UnsupportedUserOpProperty',
    'This property does not exist on a ethereum user operation',
  );

  /**
   * Thrown if trying to sign a tx with metamask and user cancelled
   */
  static userRejectedWalletLinkRequest = new EthereumError(
    'UserRejectedWalletLinkRequest',
    'User rejected walletlink request',
  );

  static nonceTooLowError = new EthereumError('nonceTooLow', 'Original transaction successful');
}

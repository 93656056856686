import { ONRAMP_API_URL } from 'cb-wallet-env/env';
import { SharedFetchOptions } from 'cb-wallet-http/fetchJSON';

export const DEFAULT_ONRAMP_HOST = ONRAMP_API_URL;
export const DEFAULT_ONRAMP_HOST_DEV = 'https://onramp-api-dev.cbhq.net';

export const onrampServiceConfig: Pick<SharedFetchOptions, 'service' | 'apiVersion'> = {
  service: 'payment-providers',
  apiVersion: 1,
} as const;

export const onrampServiceAuthedConfig: Pick<SharedFetchOptions, 'service' | 'apiVersion'> = {
  service: 'payment-providers-authed',
  apiVersion: 1,
} as const;

import { EthereumBlockchain } from 'cb-wallet-data/chains/AccountBased/Ethereum/config';
import { ETHEREUM_PREFIX } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { SolanaBlockchain } from 'cb-wallet-data/chains/AccountBased/Solana/config';
import { SOLANA_PREFIX } from 'cb-wallet-data/chains/AccountBased/Solana/utils/chain';
import { SerializedSwapAsset } from 'cb-wallet-data/hooks/Swap/serializeSwapAsset';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { setAuthTokensImperative } from 'cb-wallet-data/stores/Authentication/tokens/state';
import {
  chainComponentsFromNetworkRawValue,
  Network,
} from 'cb-wallet-data/stores/Networks/models/Network';
import { TxFlowOrType, TxOrUserOp } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import { TxHistoryVersion } from 'cb-wallet-data/stores/Transactions/TxHistoryVersion';
import { walletBlockchainUrl } from 'cb-wallet-env/env';
import { getJSON, postJSON } from 'cb-wallet-http/fetchJSON';
import { V1GetHistoryForAddressRequest } from '@cbhq/instant-api-hooks-wallet-tx-history/types';

import { Account } from '../Accounts/models/Account';
import { EVMGaslessSwapTrade } from '../Swap/models/EVMGaslessSwapTrade';

import { getTxHistoryParser } from './interfaces/TxHistoryParser';
import { GaslessTxState } from './models/GaslessTxState';
import { SponsoredTxState } from './models/SponsoredTxState';

export const GET_ALL_TXS_KEY = 'txHistory/getForAddress';
const GASLESS_SWAP_KEY = 'gasless/swap';
const GASLESS_AGGREGATOR_ID = 'zeroex';

type currencyDTO = {
  address: string;
  name: string;
  symbol: string;
  imageURL?: string;
  price?: number;
  decimal: number;
  type: string;
  spam_score?: number;
  tokenId?: string;
};

export type TransferDTO = {
  from: {
    address: string;
    domain?: string;
  };
  to: {
    address: string;
    domain?: string;
  };
  exchange: {
    amount: string;
    currency: currencyDTO;
  };
};

export type TransactionDTO = {
  user: {
    address: string;
  };
  hash: string;
  transfers: TransferDTO[];
  timestamp: number;
  types: TxFlowOrType[];
  network: number;
  gasfee: {
    amount: string;
    currency: currencyDTO;
  };
  nonce: number;
  status: string;
};

// internal txhistory service
export type GetAllTxsError = {
  code: string;
  message: string;
  description: string;
};

// internal txhistory service
export type GetAllTxsResult = {
  paginationToken: string;
  spam_score_thresholds?: SpamScoreThresholds;
  transactions: TransactionDTO[] | null;
};

export type GetUTXOWalletTxsInputResponse = {
  value: string;
  address: string;
  index: number;
};

export type GetUTXOWalletTxsOutputResponse = GetUTXOWalletTxsInputResponse & {
  hash: string;
  script: string;
  script_type: string;
};

export type SpamScoreThresholds = {
  global_spam: number;
  whitelist: number;
  likely_spam: number;
  likely_not_spam: number;
};

type UTXOWalletTxsNoChangeResponse = {
  synced_height: number;
  no_change: true;
};

type UTXOWalletTxsSyncResponse = {
  paging_token?: string;
  no_change: boolean;
  synced_height: number;
  transactions: GetUTXOWalletTxsResponse[];
};

type UTXOWalletTxsResponse = UTXOWalletTxsNoChangeResponse | UTXOWalletTxsSyncResponse;

export type GetUTXOWalletTxsResponse = {
  block: string;
  confirmations: number;
  height: number;
  index: number;
  hash: string;
  time: number;
  inputs: GetUTXOWalletTxsInputResponse[];
  outputs: GetUTXOWalletTxsOutputResponse[];
};

type GetUTXOWalletTxsParams = {
  currencyCode: CurrencyCode;
  addresses: string[];
  isTestnet: boolean;
  pagingToken: string | undefined;
  perPage: bigint;
  storedBlockheight?: number;
};

export type RelayTxPayload = {
  fromAddress: string;
  /** this is how we let our relayer know which address should be the recipient of the erc20 transfer */
  toAddress: string;
  nonce: string;
  value: string;
  validAfter: number;
  validBefore: number;
  chainId: number;
  erc20ContractAddress: string;
  v: number;
  r: string;
  s: string;
};

type SerializedSwapQuote = {
  priceImpact: string;
  chainId: number;
  highPriceImpact: boolean | undefined;
  fromAsset: SerializedSwapAsset;
  toAsset: SerializedSwapAsset;
  aggregatorID: string;
};

export type SignedGaslessTrade = Omit<
  EVMGaslessSwapTrade,
  'highPriceImpact' | 'fee' | 'fees' | 'swapHexEncodedTxData' | 'tx' | 'quote' | 'chainId'
> & {
  quote: SerializedSwapQuote;
  chainId: number;
};

export type GaslessSwapTxPayload = {
  signedTrade: SignedGaslessTrade;
  source: 'mobile' | 'extension';
};

export type PayoutServiceStatusCheckResponse = {
  /** TransactionId on the TxHistoryV2 table (sqlite/indexeddb) */
  ClientDbTransactionId: string;
  /** UUID from the PayoutService */
  PayoutServiceTokenId: string;
  /** State of the transaction */
  State: SponsoredTxState;
  /** Transaction hash. For some reason has a different name on payload from Payout */
  TransactionId: string;
  /** Block height when the transaction was mined/submitted */
  BlockHeight: bigint;
  /** Current block height of the chain according to Payout indexer */
  CurrentChainHeight: bigint;
};

type GaslessSwapRelayedTransaction = {
  hash: string;
  timestamp: number;
};

export type GaslessSwapStatusCheckResponse = {
  /** TransactionId on the TxHistoryV2 table (sqlite/indexeddb) */
  ClientDbTransactionId: string;
  /** Status of gasless swap */
  status: GaslessTxState;
  /** Type of transaction (should always be metatransaction) */
  type: string;
  /** Transactions of relayed transaction */
  transactions: GaslessSwapRelayedTransaction[];
  /** Trade hash from zeroex service */
  tradeHash: string;
};

function getBlockchainFromChainPrefix(prefix?: string) {
  if (prefix === SOLANA_PREFIX) {
    return SolanaBlockchain;
  }
  if (prefix === ETHEREUM_PREFIX) {
    return EthereumBlockchain;
  }
}

/**
 * Get all transactions for EVM or Solana, including base asset, ERC20s, NFTs, etc.
 *
 * @param options.address The address to fetch transactions for
 * @param options.network Supported EVM network or Solana network
 * @param options.accountId Account ID of currently active wallet group
 * @param options.walletIndex Wallet walletIndex of currently active wallet group
 * @param options.paginationToken
 *
 * @return A [Promise] wrapping a [List] of [Transaction] objects
 */
export async function getAllTxs({
  address,
  network,
  accountId,
  walletIndex,
  paginationToken,
  apiVersion,
}: {
  address: string;
  network: Network;
  accountId: Account['id'];
  walletIndex: bigint;
  paginationToken?: string;
  apiVersion: TxHistoryVersion;
}): Promise<{ nextPaginationToken: string; transactions: TxOrUserOp[] }> {
  const { chainPrefix } = chainComponentsFromNetworkRawValue(network.rawValue);
  const blockchain = getBlockchainFromChainPrefix(chainPrefix);
  const wacNetworkId = network.asChain()?.wacNetworkId;

  if (!wacNetworkId || !blockchain || !apiVersion) {
    throw Error('No wacNetworkId or blockchain or apiVersion found.');
  }

  const txHistoryParser = getTxHistoryParser(apiVersion);

  const params = {
    network: wacNetworkId,
    address,
    paginationToken,
  } as V1GetHistoryForAddressRequest;

  const { parsedTxns, nextPaginationToken } = await txHistoryParser.getParsedTxHistory(params, {
    accountId,
    walletIndex,
    network,
    blockchain,
  });

  return {
    nextPaginationToken,
    transactions: parsedTxns,
  };
}

/**
 * Get UTXO Wallet transactions
 *
 * @param currencyCode The currency code of the chain to fetch transactions for
 * @param addresses The addresses to fetch transactions for
 * @param isTestnet If true, fetch testnet transactions
 * @param pagingToken The paging token
 * @param perPage The amount of transactions to fetch per page
 *
 * @return A Promise wrapping an object a transactions array GetUTXOWalletTxsResponse[] and the paging token
 */
export async function getUTXOWalletTxs({
  currencyCode,
  addresses,
  isTestnet,
  pagingToken,
  perPage,
  storedBlockheight = 0,
}: GetUTXOWalletTxsParams): Promise<{
  transactions: GetUTXOWalletTxsResponse[];
  nextPagingToken?: string;
  serverSyncedBlockheight: number;
}> {
  const params = {
    addresses,
    testnet: isTestnet,
    // eslint-disable-next-line camelcase
    paging_token: pagingToken,
    // eslint-disable-next-line camelcase
    page_size: Number(perPage),
    // eslint-disable-next-line camelcase
    synced_height: storedBlockheight,
  };

  const { body: response } = await postJSON<UTXOWalletTxsResponse>(
    `${walletBlockchainUrl}/${currencyCode.rawValue.toLowerCase()}/v2/getTransactions`,
    params,
    { isThirdParty: true, returnHeaders: true },
  );

  const serverSyncedBlockheight = response.synced_height;

  if (response.no_change) {
    return { transactions: [], serverSyncedBlockheight };
  }

  const nextPagingToken = response.paging_token;

  return { transactions: response.transactions, nextPagingToken, serverSyncedBlockheight };
}

/**
 * Submit an ETH transaction to the backend relayer
 * @param relayTx EIP-712 signed relay transaction
 * @returns Token for payout service. Can be used to check status of transaction
 */
export async function submitTxForSponsoredRelay(relayTx: RelayTxPayload) {
  const { result } = await postJSON<{ result: { Token: string } }>(
    'sponsoredSend/createSponsoredSend',
    relayTx,
    {
      authenticated: true,
      returnHeaders: false,
      setAuthTokens: setAuthTokensImperative,
    },
  );

  return result;
}

/**
 * Gets status of sponsored EIP-712 relay transaction
 * @param txRequest Token returned by payout service when the transaction was originally relayed
 * @returns Current tx status
 */
export async function getSponsoredTxStatus(
  txId: string,
  token: string,
): Promise<PayoutServiceStatusCheckResponse> {
  const { result } = await getJSON<{ result: PayoutServiceStatusCheckResponse }>(
    'sponsoredSend/getTransactionStatus',
    { token },
  );

  return {
    ...result,
    ClientDbTransactionId: txId,
    PayoutServiceTokenId: token,
  };
}

/**
 * Submit an ETH transaction to the backend relayer
 * @param gaslessSwapTx EIP-712 signed relay transaction
 * @returns tradeHash for 0x relay service. Can be used to check status of transaction
 */
export async function submitTxForGaslessSwapRelay(gaslessSwapTx: GaslessSwapTxPayload) {
  const { result } = await postJSON<{ result: { tradeHash: string } }>(
    `${GASLESS_SWAP_KEY}/submit`,
    gaslessSwapTx,
    {
      authenticated: true,
      returnHeaders: false,
      setAuthTokens: setAuthTokensImperative,
      apiVersion: 1,
    },
  );
  return { Token: result.tradeHash };
}

/**
 * Gets status of gasless swap EIP-712 relay transaction
 * @param txId client db transaction id
 * @param chainId chain id of the transaction
 * @param tradeHash trade hash returned by 0x relay service when the transaction was originally relayed
 * @returns Current tx status
 */
export async function getGaslessSwapTxStatus(txId: string, chainId: string, tradeHash: string) {
  const { result } = await getJSON<{ result: GaslessSwapStatusCheckResponse }>(
    `${GASLESS_SWAP_KEY}/status`,
    { chainId, tradeHash, aggregatorId: GASLESS_AGGREGATOR_ID },
    {
      authenticated: true,
      setAuthTokens: setAuthTokensImperative,
      apiVersion: 1,
    },
  );

  return {
    ...result,
    ClientDbTransactionId: txId,
    tradeHash,
  };
}

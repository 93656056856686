import { IntlProvider, ReactIntlErrorCode } from 'react-intl';
import { logI18NMetric, logNonProductionMissingTranslation } from 'cb-wallet-analytics/i18n';
import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';

export type IntlErrorHandler = NonNullable<React.ComponentProps<typeof IntlProvider>['onError']>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function onIntlProviderError(err: any, isProduction = false) {
  // Avoid logging for frequent expected errors
  if (err && err.code === ReactIntlErrorCode.MISSING_TRANSLATION) {
    // New strings added in the app would require up-to 4 days for the translations to be available and they would be missing in the interim.
    // We don't want to log these errors while QA is testing the new features to prevent mis-reporting.
    if (isProduction) {
      return logI18NMetric(err);
    }

    // log missing translations in non-production environments for debugging
    return logNonProductionMissingTranslation(err);
  }

  const e = coerceError(err, 'onIntlProviderError');

  cbReportError({ error: e, context: 'translations', severity: 'error', isHandled: false });
}

import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { generateWalletAssetGroupId } from 'cb-wallet-data/stores/Wallets/state';

import { generateDrawerContentParams } from ':dapp/components/GlobalDrawer/utils/generateDrawerContentParams';
import { AppRoutesEnum, RoutesEnum } from ':dapp/utils/RoutesEnum';

export const PENDING_TX_HASH = '0x0000000000000000000000000000000000000000000000000000000000000000';

type AppRouteParams = {
  id?: string;
  address?: string;
  txHash?: string;
  isPreview?: boolean;
  query?: Record<string, string>;
  assetBlockchain?: Blockchain;
  assetCurrencyCode?: CurrencyCode;
  assetNetwork?: Network;
  assetName?: string;
  assetContractAddress?: string;
  assetUUID?: string;
  contractAddress?: string;
  fromAddress?: string;
  chainId?: string;
};

function getCryptoPagePath(params?: AppRouteParams) {
  if (!params?.id) {
    return RoutesEnum.ASSETS;
  }
  // Detail page
  const { id, query } = params;
  const searchParams = new URLSearchParams(query).toString();
  const qs = searchParams ? `?${searchParams}` : '';
  return `${RoutesEnum.ASSETS_CRYPTO}/${encodeURIComponent(id)}${qs}`;
}

function getTestnetsPagePath() {
  // Testnets don't have a detail page
  return RoutesEnum.ASSETS_TESTNETS;
}

function getDefiPagePath(params?: AppRouteParams) {
  if (!params?.id) {
    return RoutesEnum.ASSETS_DEFI;
  }
  // Detail page
  const { id, query } = params;
  const searchParams = new URLSearchParams(query).toString();
  const qs = searchParams ? `?${searchParams}` : '';
  return `${RoutesEnum.ASSETS_CRYPTO}/${encodeURIComponent(id)}${qs}`;
}

function getNftPagePath(params?: AppRouteParams) {
  if (!params || !(params?.address && params?.id && params?.query?.chainId)) {
    return RoutesEnum.ASSETS_NFT;
  }
  const { address, id, query } = params;
  // Detail page
  const searchParams = new URLSearchParams({
    chainId: query.chainId,
  });
  const qs = searchParams.toString();
  return `${RoutesEnum.ASSETS_NFT}/${encodeURIComponent(address)}/${encodeURIComponent(id)}?${qs}`;
}

// https://example.com/assets/nft/0x185169058e83cc5ae9fc1bc758eea7098a2557cf/0?chainId=8453

function getNFTCollectionViewPath(params?: AppRouteParams) {
  if (!params) {
    // Default: List page
    return RoutesEnum.ASSETS_NFT;
  }

  const { contractAddress, isPreview, query } = params;
  if (contractAddress && query?.chainId) {
    // Detail page
    return `${RoutesEnum.ASSETS_NFT_COLLECTION}/${encodeURIComponent(contractAddress)}${
      // Preview page
      isPreview ? '/preview' : ''
    }?chainId=${encodeURIComponent(query.chainId)}`;
  }

  return RoutesEnum.ASSETS_NFT;
}
function getNFTCollectionOpenMintsViewPath(params?: AppRouteParams) {
  if (!params) {
    // Default: List page
    return RoutesEnum.ASSETS_NFT;
  }

  const { contractAddress, query } = params;

  if (contractAddress && query?.chainId) {
    // Detail page
    return `${RoutesEnum.ASSETS_NFT_COLLECTION}/${encodeURIComponent(
      contractAddress,
    )}/open-mints?chainId=${encodeURIComponent(query.chainId)}`;
  }

  return RoutesEnum.ASSETS_NFT;
}

function makeSearchParams({ id, fromAddress, chainId }: AppRouteParams) {
  const searchParams = new URLSearchParams();
  if (id) {
    searchParams.set('id', id);
  }
  if (fromAddress && chainId) {
    searchParams.set('fromAddress', fromAddress);
    searchParams.set('chainId', chainId);
  }
  return searchParams;
}

function getTxPagePath(params?: AppRouteParams) {
  if (!params) {
    // Default: List page
    return RoutesEnum.ASSETS_TRANSACTIONS;
  }
  const { txHash, isPreview, id, chainId, fromAddress } = params;
  const searchParams = makeSearchParams({
    id,
    fromAddress,
    chainId,
  });
  // Preview panel
  if (isPreview) {
    const previewParams = generateDrawerContentParams({
      ...Object.fromEntries(searchParams),
      txHash: txHash ?? PENDING_TX_HASH,
    });
    return `${RoutesEnum.ASSETS_TRANSACTIONS}${previewParams}`;
  }
  // Detail page
  const paramsPath = searchParams.toString() ? `?${searchParams.toString()}` : '';
  if (typeof txHash !== 'string' && id) {
    return `${RoutesEnum.ASSETS_TRANSACTIONS}/${PENDING_TX_HASH}${paramsPath}`;
  }
  if (typeof id !== 'string' && txHash) {
    return `${RoutesEnum.ASSETS_TRANSACTIONS}/${encodeURIComponent(txHash)}`;
  }
  if (txHash && id) {
    return `${RoutesEnum.ASSETS_TRANSACTIONS}/${encodeURIComponent(txHash)}${paramsPath}`;
  }

  // Fallback
  return RoutesEnum.ASSETS_TRANSACTIONS;
}

function getAssetDetailPagePath(params?: AppRouteParams) {
  if (!params) {
    // Default: Assets page
    return RoutesEnum.ASSETS;
  }

  const {
    assetBlockchain,
    assetCurrencyCode,
    assetName,
    assetUUID,
    assetContractAddress,
    assetNetwork,
  } = params;

  if (!assetBlockchain || !assetCurrencyCode || !assetNetwork) {
    return RoutesEnum.ASSETS;
  }

  const assetID = generateWalletAssetGroupId({
    blockchain: assetBlockchain,
    currencyCode: assetCurrencyCode,
    network: assetNetwork,
    contractAddress: assetContractAddress,
  });

  return `${RoutesEnum.ASSETS_CRYPTO}/${encodeURIComponent(
    assetID,
  )}?assetUUID=${assetUUID}&assetName=${assetName}`;
}

/**
 * getDynamicPagePath used to generate dynamic paths for assets pages:
 * - Crypto
 * - NFT
 * - Transactions
 *
 * @param page Pass in the page path to get the dynamic path, defaults to the list page passed page path
 * @param params to generate dynamic paths
 * @returns url string
 */
export function getDynamicPagePath(page: AppRoutesEnum, params?: AppRouteParams) {
  switch (page) {
    case RoutesEnum.ASSETS_CRYPTO:
      return getCryptoPagePath(params);
    case RoutesEnum.ASSETS_TESTNETS:
      return getTestnetsPagePath();
    case RoutesEnum.ASSETS_DEFI:
      return getDefiPagePath(params);
    case RoutesEnum.ASSETS_NFT:
      return getNftPagePath(params);
    case RoutesEnum.ASSETS_NFT_COLLECTION:
      return getNFTCollectionViewPath(params);
    case RoutesEnum.ASSETS_NFT_COLLECTION_OPEN_MINTS:
      return getNFTCollectionOpenMintsViewPath(params);
    case RoutesEnum.ASSETS_TRANSACTIONS:
      return getTxPagePath(params);
    case RoutesEnum.ASSETS:
      return getAssetDetailPagePath(params);
    default:
      return page;
  }
}

import { useMemo } from 'react';
import { useGetAccountById } from 'cb-wallet-data/stores/Accounts/hooks/useGetAccountById';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { useWalletGroups } from 'cb-wallet-data/stores/WalletGroups/hooks/useWalletGroups';
import { useWalletsByWalletGroup } from 'cb-wallet-data/stores/Wallets/hooks/useWalletsByWalletGroup';
import { fiatBalanceByWalletGroupAtom } from 'cb-wallet-data/stores/Wallets/state';
import Decimal from 'decimal.js';
import clone from 'lodash/clone';
import { useRecoilValue } from 'recoil';

// primaryAddressForDapp gets the primary address from the first wallet in the wallet group
// if there are no wallets in the wallet group, it uses the account's primary address.
// This is to handle UTXOs, where the account's primary address was not the same as the wallet's primary address
export type AccountWithBalance = Account & {
  balance: Decimal;
  primaryAddressForDapp: string;
};

// TODO: move useAccountsWithBalances to a recoil selector
// https://jira.coinbase-corp.com/browse/WALL-29890

/**
 * Returns an array of accounts with their balances sorted based on the balance
 */
export function useAccountsWithBalances() {
  const walletGroups = useWalletGroups({ visible: true });
  const walletsByWalletGroup = useWalletsByWalletGroup();
  const getAccountById = useGetAccountById();
  const fiatBalancesByWalletGroup = useRecoilValue(fiatBalanceByWalletGroupAtom);

  return useMemo(() => {
    return walletGroups
      .reduce(function reduceWalletGroupAccount(acc: AccountWithBalance[], walletGroup) {
        const account = getAccountById(walletGroup.accountId);
        if (!account) return acc;
        const wallets = walletsByWalletGroup[walletGroup.id];
        const balance = fiatBalancesByWalletGroup[walletGroup.id];
        const accountWithBalance = clone(account) as AccountWithBalance;
        accountWithBalance.balance = balance ?? new Decimal(0);
        accountWithBalance.primaryAddressForDapp =
          wallets && wallets.length > 0 ? wallets[0].primaryAddress : account.primaryAddress;
        acc.push(accountWithBalance);
        return acc;
      }, [])
      .sort(function sortByBalance(a, b) {
        return b.balance.toNumber() - a.balance.toNumber();
      });
  }, [fiatBalancesByWalletGroup, getAccountById, walletGroups, walletsByWalletGroup]);
}

import { tryGetRepository } from 'cb-wallet-data/persistence/Database';
import { CombinedThemeColorPreference } from 'cb-wallet-data/stores/ThemeColors/themeColorConfigs';

import { WalletGroup, WalletGroupDMO } from './models/WalletGroup';

export function repository() {
  return tryGetRepository<WalletGroupDMO>('wallet_group');
}

export async function getWalletGroups(): Promise<WalletGroup[]> {
  const rows = await repository().find();
  return rows.map(WalletGroup.fromDMO);
}

export async function saveWalletGroups(walletGroups: WalletGroup[]): Promise<void> {
  await Promise.allSettled(
    repository().batchUpsert(
      walletGroups.map((wg) => wg.asDMO),
      ['id'],
    ),
  );
}

export async function saveWalletGroup(walletGroup: WalletGroup): Promise<void> {
  await repository().upsert(walletGroup.asDMO, ['id']);
}

export async function updateWalletGroupNickname(
  walletGroupId: string,
  updatedNickname: string,
): Promise<void> {
  await repository().update({ id: walletGroupId }, { nickname: updatedNickname });
}

export async function setIsHiddenForWalletGroup(id: string, isHidden: boolean): Promise<void> {
  await repository().update({ id }, { isHidden });
}

export async function setWalletGroupThemeColor(
  id: string,
  themeColor: CombinedThemeColorPreference,
): Promise<void> {
  await repository().update({ id }, { themeColor });
}

export async function setWalletGroupAvatar(id: string, avatarIndex: number): Promise<void> {
  await repository().update({ id }, { avatarIndex });
}

export async function getWalletGroupsForAccount(accountId: string): Promise<WalletGroup[]> {
  const rows = await repository().find({
    where: {
      accountId,
    },
  });

  return rows.map(WalletGroup.fromDMO);
}

/**
 * Delete walletGroups in bulk
 *
 * Takes walletGroup ids as an input parameter and deletes them from the database.
 * repository method is only invoked if the walletGroupIds array is not empty.
 * @param isSoftDelete - whether to only soft delete the walletGroups
 */
export async function deleteWalletGroups(ids: WalletGroup['id'][]): Promise<void> {
  if (ids.length) {
    await repository().delete(ids);
  }
}

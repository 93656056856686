import { postJSON } from 'cb-wallet-http/fetchJSON';

import { AccessTokenResult } from './types/AccessTokenResult';

type AccessTokenResponse = {
  result: AccessTokenResult;
};

/**
 * Refresh the user's access token
 *
 * @param Parameter refreshToken: The user's refresh token
 *
 * @return A Promise resolving with an AccessTokenResult.
 */
export async function refreshAccessToken(refreshToken: string): Promise<AccessTokenResult> {
  const { result } = await postJSON<AccessTokenResponse>('refreshAccessToken', {
    refreshToken,
  });
  return result;
}

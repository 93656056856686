import { useCallback } from 'react';
import { generateUserCollectibleSettingId } from 'cb-wallet-data/stores/AssetManagement/hooks/useGenerateUserCollectibleSettingId';
import { useUserCollectibleSettings } from 'cb-wallet-data/stores/AssetManagement/hooks/useUserCollectibleSettings';
import { AssetManagementStatus as status } from 'cb-wallet-data/stores/AssetManagement/models/AssetManagementStatus';

type Props = {
  contractAddress: string;
  tokenId: string;
  chainId: bigint;
  userAddress?: string;
  spam?: boolean;
};

export function useIsCollectibleVisible() {
  const userCollectibleSettings = useUserCollectibleSettings();

  return useCallback(
    function isCollectibleVisible({
      contractAddress,
      tokenId,
      chainId,
      userAddress,
      spam = false,
    }: Props) {
      const collectibleId = generateUserCollectibleSettingId({
        contractAddress,
        tokenId,
        ownerAddress: userAddress || '',
        chainId,
      });

      const collectibleSetting = userCollectibleSettings[collectibleId];

      const isVisibleSetting = [
        status.userVisible,
        status.userSpamVisible,
        status.whitelist,
        undefined,
      ].includes(collectibleSetting?.status);

      // Collectible is visible if it's status is userSpamVisible OR visbile but not a spam token.
      return (
        (collectibleSetting?.status === status.userSpamVisible && spam) ||
        (isVisibleSetting && !spam)
      );
    },
    [userCollectibleSettings],
  );
}

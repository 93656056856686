import { hashString } from 'cb-wallet-data/utils/hashString';

import { Account } from '../models/Account';

/**
 * Obscures the account ID by replacing the contained address
 * with a hashed version.
 *
 * Useful for logging the account ID to analytics without exposing
 * any PII.
 */
export async function obscureAccountId(accountId: Account['id']) {
  const parts = accountId.split('/');

  // Safeguard to ensure if any sensitive data is passed here accidentally other
  // than account ID, we do not return it back.
  if (parts.length !== 3) {
    return '';
  }

  const ethAddress = parts[2];

  parts[2] = await hashString(ethAddress);

  return parts.join('/');
}

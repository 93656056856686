import { Connection } from '@solana/web3.js';
import { solanaUrls } from 'cb-wallet-data/chains/AccountBased/Solana/urls';
import { SignAndSubmitSolanaTransactionWithWeb3 } from 'cb-wallet-data/ServiceLocator/signingAndDerivation/types/SignAndSubmitSolanaTransaction';
import { DEFAULT_COMMITMENT_LEVEL } from 'wallet-engine-signing/history';

import { WalletProviderNetwork } from ':dapp/connection/types';
import { isSolanaConnector } from ':dapp/connection/utils/isSolanaConnector';

import { SigningMethodError, SigningMethodErrorType } from '../SigningMethodError';
import { getConnectorManagerForAccountId } from '../utils/getConnectorManagerForAccountId';
import { validateWalletProvidersConnected } from '../utils/validateWalletProvidersConnected';

type SignAndSubmitSolanaTransactionParams = Parameters<SignAndSubmitSolanaTransactionWithWeb3>[0];

export async function signAndSubmitSolanaTransaction({
  accountId,
  solanaTx,
  skipSubmit,
}: SignAndSubmitSolanaTransactionParams): ReturnType<SignAndSubmitSolanaTransactionWithWeb3> {
  if (skipSubmit) {
    throw new SigningMethodError(
      SigningMethodErrorType.Generic,
      'skipSubmit is not supported for web3 signing',
    );
  }

  const walletConnector = getConnectorManagerForAccountId(accountId);
  const provider = await walletConnector.getProvider(WalletProviderNetwork.Solana);

  if (!isSolanaConnector(provider)) {
    throw new SigningMethodError(
      SigningMethodErrorType.InvalidConnector,
      'connector is not a solana connector',
    );
  }

  // validate the provider is connected
  await validateWalletProvidersConnected(walletConnector, WalletProviderNetwork.Solana);

  const connection = new Connection(solanaUrls.SOLANA_MAINNET.rpcUrl);
  const signature = await provider.sendTransaction(solanaTx, connection, {
    preflightCommitment: DEFAULT_COMMITMENT_LEVEL,
  });

  return {
    // The web3 interface doesn't return the signed tx data for resubmit.
    // We return an empty buffer here to indicate that the signature data is not available.
    data: Buffer.from(new Uint8Array(0)),
    hash: signature,
  };
}

import EventSource, { EventSourceOptions } from 'react-native-sse';
import { CB_WALLET_API_URL } from 'cb-wallet-env/env';
// TODO pass access token from datalayer into a single `createHistoryListeners`;
import { getAccessTokenFromStorage } from 'cb-wallet-http/Authentication/tokens/state';
import ReconnectingEventSource from 'reconnecting-eventsource';

import { BaseAddressConfig, SupportedChains } from './types';

export type NudgeBaseEvent = {
  blockchain: string;
  chainId: number;
  address: string;
};

export type NudgeEventInit = NudgeBaseEvent & {
  source: 'INIT';
};

export type NudgeEventOnChain = NudgeBaseEvent & {
  source: 'ON_CHAIN';
  txHash: string;
  retryAttempt: number;
  providerName?: string;
  providerNetworkID?: string;
};

export type NudgeEvent<T extends NudgeBaseEvent> = MessageEvent<T>;

export const NUDGE_RETRY_DELAY = 6000;
export const FINAL_NUDGE_RETRY_DELAY = 30000;

export function registerNudgeConnection<T extends BaseAddressConfig>(
  addressConfigs: T[],
  blockchainSymbol: SupportedChains,
  onNudge: (ev: NudgeEvent<NudgeEventInit | NudgeEventOnChain>) => void,
  onNudgeOpen?: (ev: Event) => void,
  onNudgeError?: (err: Error) => void,
  onNudgeReopen?: () => void,
) {
  const addresses = addressConfigs.map(({ address }) => address);
  const uniqueAddresses = Array.from(new Set(addresses));
  let closedWithError = false;

  const eventSource = new ReconnectingEventSource(
    `${CB_WALLET_API_URL}/stream/v2/balanceNotifications?addresses=${uniqueAddresses.join(
      ',',
    )}&blockchain=${blockchainSymbol}`,
    {
      // eslint-disable-next-line
      max_retry_time: 60000,
      eventSourceClass: class extends EventSource {
        constructor(url: URL | string, options?: EventSourceOptions) {
          super(url, {
            ...options,
            headers: {
              Authorization: `Bearer ${getAccessTokenFromStorage()}`,
              ...options?.headers,
            },
          });
        }
      },
    },
  );

  if (onNudgeOpen) {
    eventSource.addEventListener('open', onNudgeOpen);
    if (closedWithError) {
      closedWithError = false;
      onNudgeReopen?.();
    }
  }

  if (onNudgeError) {
    eventSource.addEventListener('error', () => {
      closedWithError = true;
      // EventSource doesn't throw an error, just fires a generic event ¯\_(ツ)_/¯
      onNudgeError(new Error('Failed to create nudge connection'));
    });
  }

  // TODO in phase 2 we should stop doing naive retries and try to introspect
  // the transaction and detect if the expected change is available on each
  // attempt
  eventSource.addEventListener('nudge', (ev: MessageEvent<string>) => {
    const data: NudgeEventInit | NudgeEventOnChain = JSON.parse(ev.data);
    if (data.source === 'ON_CHAIN') {
      onNudge({
        ...ev,
        data,
        retryAttempt: 0,
      } as NudgeEvent<NudgeEventOnChain>);

      setTimeout(() => {
        onNudge({
          ...ev,
          data,
          retryAttempt: 1,
        } as NudgeEvent<NudgeEventOnChain>);
      }, NUDGE_RETRY_DELAY);

      setTimeout(() => {
        onNudge({
          ...ev,
          data,
          retryAttempt: 2,
        } as NudgeEvent<NudgeEventOnChain>);
      }, FINAL_NUDGE_RETRY_DELAY);
    }
  });

  return eventSource;
}

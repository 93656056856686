import { logEvent } from 'cb-wallet-analytics/utils/log';
import { ActionType, ComponentType } from '@cbhq/client-analytics';

export function logDappClickedFromGlobalSearch(dappName: string) {
  logEvent('global_search.dapp.clicked', {
    action: ActionType.click,
    componentType: ComponentType.card,
    dappName,
  });
}

export function logDappPageViewed(dappName: string) {
  logEvent('dapp_page.viewed', {
    action: ActionType.render,
    componentType: ComponentType.page,
    dappName,
  });
}

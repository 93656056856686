/**
 * The Service Locator
 *
 * A service locator is a design pattern that allows you to decouple the implementation of a service from its use.
 * It is a central registry that stores references to objects that are required by your application.
 *
 * In the case of Wallet, we have a number of services that are required by our applications: RN, Extension, etc.
 * Some of these services require different implementations to be used in the context of the application.
 *
 * For instance, `signAndSubmitTransaction` is used in the extension and in the mobile app. However, the implementation
 * of `signAndSubmitTransaction` is different in the mobile app than it is in the extension. The mobile app has a
 * different method to retrieve the mnemonic than the extension, so our implementation of `signAndSubmitTransaction`
 * must be different to account for this.
 *
 * In the datalayer, we create a ServiceLocator for `signAndSubmitTransaction`. During app initialization in RN and Extension,
 * we register this implementation with the `signAndSubmitTransaction` ServiceLocator. This allows us to use the same
 * `signAndSubmitTransaction` function in the datalayer, but have different implementations depending on the application.
 */
export class ServiceLocator<T extends Record<keyof T, T[keyof T]>> {
  private library: T = {} as T;

  /**
   * @throws Unimplemented Error
   * @param key - the name of the function which is not implemented
   */
  private unimplemented(key: string): Error {
    return new Error(`No implementation supplied for ${key}.`);
  }

  /**
   * @param implementationLibrary - an object containing the implementations of the functions
   */
  public setAll(implementationLibrary: T): void {
    if (Object.keys(this.library).length > 0) {
      throw new Error('ServiceLocator already has implementations set.');
    }
    this.library = implementationLibrary;
  }

  /**
   * @note - If called before datalayer initialization in any app (ext, rn, etc.),
   * this will throw an error.
   * @param key - the name of the function to implement
   * @returns the implementation of the function
   * @throws Unimplemented Error if the implementation has not been supplied
   */
  public get<Tkey extends keyof T>(key: Tkey) {
    if (!this.library[key]) {
      throw this.unimplemented(key as string);
    }
    return this.library[key];
  }

  /**
   * @note - If called before datalayer initialization in any app (ext, rn, etc.),
   * this will throw an error.
   * @returns the object containing the implementations of the functions
   * @throws Unimplemented Error if this is called before any implementations are set
   */
  public getAll(): T {
    if (Object.keys(this.library).length === 0) {
      throw this.unimplemented('this ServiceLocator');
    }
    return this.library;
  }

  /**
   * Used only for testing. Clears library implementation so that a test can call setAll again.
   */
  public clearAllForTesting() {
    this.library = {} as T;
  }

  /**
   * Used only for testing. Sets a partial implementation of the library.
   */
  public setPartialForTesting(implementationLibrary: Partial<T>) {
    this.library = implementationLibrary as T;
  }
}

// eslint-disable-next-line no-restricted-imports
import { ProviderType } from '@coinbase/wallet-sdk/dist/core/type';
import { ETHEREUM_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { SOLANA_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Solana/constants';
import { blockchainConfigurations } from 'cb-wallet-data/chains/blockchains';
import { DataMigration } from 'cb-wallet-data/DataMigrationRunner/types';
import { getAccounts } from 'cb-wallet-data/stores/Accounts/database';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { getAddressForIndex } from 'cb-wallet-data/stores/Addresses/database';
import { WalletConfiguration } from 'cb-wallet-data/stores/Wallets/models/WalletConfiguration';
import { EVM_PROVIDER_ID } from 'cb-wallet-data/Web3/evm/constants';
import { SOLANA_PROVIDER_ID } from 'cb-wallet-data/Web3/solana/constants';
import { getPlatformName } from 'cb-wallet-metadata/metadata';
import { PlatformName } from '@cbhq/client-analytics';

// Top solana dapps with connections > 5
const SOLANA_DAPP_HOSTS = [
  'jup.ag',
  'mechafightclub.com',
  'solend.fi',
  'naming.bonfida.org',
  'play.staratlas.com',
  'raydium.io',
  'solanart.io',
];
const CONNECTED_DAPPS_KEY = 'connectedDapps';

type Dapp = {
  dappName: string;
  dappLogoURL: string;
  dappLogoURLAlt: string;
  dappURL: string;
  connected: boolean;
  overriddenChainId?: string;
  connectedAddress?: string;
  providerType?: ProviderType;
};

// Modified: added accountId in Address getters, modified the script to provide accountId
// Current assumption: accounts has only active account in it, if it doesn't have exact one, don't run this script
const migrateDappConnections: DataMigration = {
  // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
  // eslint-disable-next-line wallet/no-anonymous-params
  up: async () => {
    if (getPlatformName() !== PlatformName.extension) return;
    const accounts = await getAccounts();
    if (accounts.length !== 1) {
      return;
    }
    const activeAccount = accounts[0];
    const ethAddress = await getFirstAddress(
      blockchainConfigurations[ETHEREUM_SYMBOL],
      activeAccount.id,
    );
    const solAddress = await getFirstAddress(
      blockchainConfigurations[SOLANA_SYMBOL],
      activeAccount.id,
    );

    if (!(ethAddress && solAddress)) return;

    const dapps = await getDapps();

    const updatedDapps: Dapp[] = dapps.map(
      // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
      // eslint-disable-next-line wallet/no-anonymous-params
      (dapp: Dapp) => {
        const solana = isSolanaDapp(dapp.dappURL);
        return {
          ...dapp,
          connectedAddress: solana ? solAddress : ethAddress,
          providerType: (solana ? SOLANA_PROVIDER_ID : EVM_PROVIDER_ID) as ProviderType,
        };
      },
    );

    await setDapps(updatedDapps);
  },
};

export async function getFirstAddress(config: WalletConfiguration, accountId: Account['id']) {
  const address = await getAddressForIndex({
    blockchain: config.blockchain,
    currencyCode: config.currencyCode,
    network: config.networkSetting.defaultMainnet.network,
    addressType: config.defaultReceiveType,
    isChangeAddress: false,
    index: 0n,
    accountId,
  });
  return address?.address;
}

function getHostName(dappUrl: string) {
  try {
    const hostname = new URL(dappUrl).hostname.toLowerCase();
    return hostname;
  } catch {
    return undefined;
  }
}

function isSolanaDapp(dappURL: string) {
  const hostname = getHostName(dappURL);
  if (!hostname) return false;
  return SOLANA_DAPP_HOSTS.includes(hostname);
}

async function getDapps() {
  return new Promise<Dapp[]>((resolve, reject) => {
    chrome.storage.local.get([CONNECTED_DAPPS_KEY], (items) => {
      if (items[CONNECTED_DAPPS_KEY]) {
        try {
          const parsedDapps: Dapp[] = JSON.parse(items[CONNECTED_DAPPS_KEY]);
          resolve(parsedDapps);
        } catch {
          reject();
        }
      } else {
        reject();
      }
    });
  });
}

async function setDapps(dapps: Dapp[]) {
  return new Promise<void>((resolve) => {
    chrome.storage.local.set({ [CONNECTED_DAPPS_KEY]: JSON.stringify(dapps) }, () => resolve());
  });
}

export default migrateDappConnections;

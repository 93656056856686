import { ActionType, ComponentType } from '@cbhq/client-analytics';

import { logEvent } from '../utils/log';

export function logDatadogSyntheticsPageViewed() {
  logEvent('dapp_datadog_synthetics_page_viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

import {
  triggerDeleteFullDeletedAccountEnd,
  triggerDeleteFullDeletedAccountStart,
  triggerDeleteSoftDeletedAccountEnd,
  triggerDeleteSoftDeletedAccountStart,
} from 'cb-wallet-analytics/accounts';
import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { deleteDatabase, openDatabase } from 'cb-wallet-data/persistence/Database';
import { deleteAccounts, getAccounts } from 'cb-wallet-data/stores/Accounts/database';
import { clearCBStoreFromLocalStorage } from 'cb-wallet-data/stores/Accounts/utils/clearCBStoreFromLocalStorage';
import { clearLocalStorageForAccount } from 'cb-wallet-data/stores/Accounts/utils/clearLocalStorageForAccount';
import { deleteAssociatedAccountDbRecords } from 'cb-wallet-data/stores/Accounts/utils/deleteAssociatedAccountDbRecords';

/**
 *
 * This function is used to delete any accounts, addresses, wallets, and transactions that are still persisted that
 * are associated with accounts that have been soft deleted. This is necessary because the user may have begun
 * the deletion process for an account but not completed it, and we need to ensure that all data is removed from the
 * device to prevent any potential data hydration issues or security risks. This function is called when the app is started.
 *
 * Note: this method will simply drop the database and clear all cb store records if all accounts have been soft deleted.
 */
export async function checkForSoftDeletedAccountsAndDeleteAssociatedDbRecords() {
  const allAccounts = await getAccounts({ includeDeleted: true });
  const softDeletedAccountIds = allAccounts
    .filter((account) => account.isDeleted)
    .map((account) => account.id);
  if (softDeletedAccountIds.length === 0) {
    return;
  }

  const isDeleteAll = allAccounts.length === softDeletedAccountIds.length;

  try {
    if (isDeleteAll) {
      triggerDeleteFullDeletedAccountStart({ num: softDeletedAccountIds.length });

      await deleteDatabase();
      await openDatabase();
      clearCBStoreFromLocalStorage();
      triggerDeleteFullDeletedAccountEnd({ num: softDeletedAccountIds.length });
    } else {
      triggerDeleteSoftDeletedAccountStart({ num: softDeletedAccountIds.length });
      await Promise.all([
        deleteAssociatedAccountDbRecords(softDeletedAccountIds, true),
        ...softDeletedAccountIds.map((accountId) => clearLocalStorageForAccount(accountId)),
      ]);

      await deleteAccounts(softDeletedAccountIds);
      triggerDeleteSoftDeletedAccountEnd({ num: softDeletedAccountIds.length });
    }
  } catch (error: ErrorOrAny) {
    cbReportError({
      error: coerceError(error, ''),
      context: 'dapp-provider-account-deletion',
      severity: 'error',
      isHandled: true,
    });
  }
}

// We are just importing a type here so it is fine!
// eslint-disable-next-line no-restricted-imports
import type { ec as ECType } from 'elliptic';

let _secp256k1: ECType;

/**
 * EC is a massive import so we make sure to async import it to avoid long
 * script parsing time.
 */
export async function getSecp256k1(): Promise<ECType> {
  const { ec: EC } = await import('elliptic');
  if (!_secp256k1) {
    _secp256k1 = new EC('secp256k1');
  }
  return _secp256k1;
}

import { ETHEREUM_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { resubmitPendingTransactions as resubmitPendingTransactionsEthereum } from 'cb-wallet-data/chains/AccountBased/Ethereum/Transactions/resubmitPendingTransactions';
import { resubmitPendingTransactions as resubmitPendingTransactionsSolana } from 'cb-wallet-data/chains/AccountBased/Solana/Transactions/resubmitPendingTransactions';
import { PossibleAccountBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { SignedTx } from 'cb-wallet-data/stores/Transactions/interfaces/SignedTx';

import { SOLANA_SYMBOL } from '../Solana/constants';

export type ResubmitPendingTransactionsArgs = {
  network: Network;
  syncUpdateToTxHistoryTable: (transaction: SignedTx) => void;
};

export type ResubmitPendingTransactionsReturn = Promise<void>;

type ResubmitPendingTransactions = (
  args: ResubmitPendingTransactionsArgs,
) => ResubmitPendingTransactionsReturn;

export const resubmitPendingTransactionsByBlockchain: Record<
  PossibleAccountBlockchainSymbol,
  ResubmitPendingTransactions
> = {
  [ETHEREUM_SYMBOL]: resubmitPendingTransactionsEthereum,
  [SOLANA_SYMBOL]: resubmitPendingTransactionsSolana,
};

export async function resubmitPendingTransactions({
  blockchainSymbol,
  network,
  syncUpdateToTxHistoryTable,
}: ResubmitPendingTransactionsArgs & {
  blockchainSymbol: PossibleAccountBlockchainSymbol;
}): ResubmitPendingTransactionsReturn {
  const resubmitFn = resubmitPendingTransactionsByBlockchain[blockchainSymbol];
  return resubmitFn({
    network,
    syncUpdateToTxHistoryTable,
  });
}

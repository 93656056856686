import { PossibleUTXOBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
import { StoreKeys_throttleRequestLastUpdated } from 'cb-wallet-data/stores/Wallets/storeKeys';
import { Store } from 'cb-wallet-store/Store';

type StoredLastUpdated = Record<string, number>;

function getStoredLastUpdatedKey(
  blockchainSymbol: PossibleUTXOBlockchainSymbol,
  accountId: string,
) {
  const walletIndex = 0;
  return `${accountId}-${walletIndex}-${blockchainSymbol}`;
}

export function getThrottleRequestLastUpdated(
  blockchainSymbol: PossibleUTXOBlockchainSymbol,
  accountId: string,
): number | undefined {
  const lastUpdated = Store.get<StoredLastUpdated>(StoreKeys_throttleRequestLastUpdated);
  if (!lastUpdated) {
    return undefined;
  }
  const lastUpdatedKey = getStoredLastUpdatedKey(blockchainSymbol, accountId);
  return lastUpdated[lastUpdatedKey] ?? undefined;
}

export function storeThrottleRequestLastUpdated(
  blockchainSymbol: PossibleUTXOBlockchainSymbol,
  throttleRequestLastUpdated: number,
  accountId: string,
) {
  const lastUpdated = Store.get<StoredLastUpdated>(StoreKeys_throttleRequestLastUpdated) || {};
  const lastUpdatedKey = getStoredLastUpdatedKey(blockchainSymbol, accountId);
  Store.set(StoreKeys_throttleRequestLastUpdated, {
    ...lastUpdated,
    [lastUpdatedKey]: throttleRequestLastUpdated,
  });
}

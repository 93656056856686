import { getSigningOrDerivationMethod } from 'cb-wallet-data/ServiceLocator/signingAndDerivation/utils/getSigningOrDerivationMethod';
import { Address } from 'wallet-engine-signing/signing/ethereum/Address';

export function isValidAddress(address: string): boolean {
  return isValidEthereumAddress(address);
}

export async function ethereumAddressFromPublicKey(publicKey: Buffer): Promise<string> {
  const ethAddressFromPublicKey = getSigningOrDerivationMethod(
    'deriveEthereumAddressFromPublicKey',
  );
  return ethAddressFromPublicKey(publicKey);
}

export function checksumEthereumAddress(address: string): string {
  return Address.checksumAddress(address);
}

export function isValidEthereumAddress(address: string): boolean {
  if (!address.match(/^0[xX]/)) {
    return false;
  }
  return Address.isValid(address);
}

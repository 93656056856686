import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { extensionClientKillSwitchConfig } from 'cb-wallet-data/killswitches/extensionClientKillSwitchConfig';
import { rnClientKillSwitchConfig } from 'cb-wallet-data/killswitches/rnClientKillSwitchConfig';
import { webClientKillSwitchConfig } from 'cb-wallet-data/killswitches/webClientKillSwitchConfig';
import { logMetric, MetricType, PlatformName } from '@cbhq/client-analytics';
import {
  ClientKillSwitchConfig,
  initClientKillSwitch,
  ScopeParamsType,
} from '@cbhq/client-kill-switch';

import { APP_VERSION, CB_API_URL } from ':dapp/config/env';

// Kill switches can only be accessed from the below urls
export const KILL_SWITCH_SERVICE_URL = `${CB_API_URL}/v3/coinbase.killswitch.KillSwitchService/KillSwitches`;

// Error handlers below for various errors that can occur when kill switches are used.
const killSwitchContext = 'kill-switches';

// Error logged if there is no default context provided.
function contextErrorHandler() {
  cbReportError({
    error: new Error('ClientKillSwitch: context not provided'),
    context: killSwitchContext,
    severity: 'error',
    isHandled: false,
  });
}

// Error logged if there is an error fetching most recent kill switches from config service
export function fetchErrorHandler(e: Error) {
  cbReportError({
    error: e,
    context: killSwitchContext,
    severity: 'error',
    metadata: { MetaData: e.message || 'fetchError' },
    isHandled: false,
  });
}

// Error logged if there is error using the `useKillSwitch` hook
function hookErrorHandler() {
  cbReportError({
    error: new Error('ClientKillSwitch: useHook error'),
    context: killSwitchContext,
    severity: 'error',
    isHandled: false,
  });
}

// Log Error if there is problem when using the client kill switch Provider
function logger() {
  logMetric({
    metricName: 'client_kill_switch.listen_latest',
    metricType: MetricType.count,
    value: 1,
  });
}

export const KILLSWITCHES_SCOPE = 'wallet';

export const KILLSWITCHES_CONFIG: ScopeParamsType = {
  serviceUrl: KILL_SWITCH_SERVICE_URL,
  disabled: false,
  // https://github.com/vercel/next.js/issues/5354#issuecomment-650170660
  platform: typeof window === 'undefined' ? PlatformName.server : PlatformName.web,
  scope: KILLSWITCHES_SCOPE,
  version: APP_VERSION || '',
  contextErrorHandler,
  fetchErrorHandler,
  hookErrorHandler,
  logger: { logMetric: logger, platform: 'dapp', enableLogging: false },
};

/**
 * The reason we need to import both in the init/killswitches files is that the datalayer has code that checks both
 * rn and extension kill switches (probably legacy code before the getKillSwitchForPlatform hook was introduced).
 */
export const allKillSwitches: ClientKillSwitchConfig = {
  ...webClientKillSwitchConfig,
  ...extensionClientKillSwitchConfig,
  ...rnClientKillSwitchConfig,
};

initClientKillSwitch(allKillSwitches, KILLSWITCHES_CONFIG);

import { useEffect, useRef } from 'react';

/**
 * Stores the value of any variable from the previous render
 *
 * @param value Any value you wish to track across renders of a React component
 * @returns The value that variable held in the prior render cycle
 */
export function usePreviousValue<T>(value: T): T | undefined {
  const ref = useRef<T>();

  useEffect(function setRef() {
    ref.current = value;
  });

  return ref.current;
}

import { useCallback } from 'react';
import { cbReportError as reportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { useGetAccountById } from 'cb-wallet-data/stores/Accounts/hooks/useGetAccountById';
import { createWalletClient, custom, EIP1193Provider, isAddress } from 'viem';
import { walletActionsEip5792 } from 'viem/experimental';

import { WalletProviderNetwork } from ':dapp/connection/types';
import { SMART_WALLET_PROVIDER_IDS } from ':dapp/connection/wallet-providers/constants';
import { getConnectorManagerForAccountId } from ':dapp/datalayer/web3/utils/getConnectorManagerForAccountId';
import { getEthereumAddressFromAccountId } from ':dapp/datalayer/web3/utils/getEthereumAddressFromAccountId';
import { validateKnownEthereumAddress } from ':dapp/datalayer/web3/utils/validateKnownEthereumAddress';

export type SupportedType = { supported: boolean };

export type WalletCapabilities = Record<
  number,
  {
    // Used to check whether the wallet is an scw or not
    atomicBatch?: SupportedType;
    // Whether the scw accepts a paymaster service parameter (currently unused by the app)
    paymasterService?: SupportedType;
    // Whether the scw supports magic spend (using cb funds as part of the transaction)
    auxiliaryFunds?: SupportedType;
  }
>;

/**
 * @param accountId
 * @returns
 */
export function useGetCapabilitiesForAccountId() {
  const getCapabilitiesForAccountIdInner = useGetCapabilitiesForAccountIdInner();

  return useCallback(
    async function getCapabilitiesForAccountId(accountId: string) {
      try {
        return await getCapabilitiesForAccountIdInner(accountId);
      } catch (error: ErrorOrAny) {
        reportError({
          error: coerceError(error, 'Got error getting capabilities for account'),
          context: 'dapp_error',
          isHandled: true,
          severity: 'error',
        });
        return {};
      }
    },
    [getCapabilitiesForAccountIdInner],
  );
}

export function useGetCapabilitiesForAccountIdInner() {
  const getAccountById = useGetAccountById();

  return useCallback(
    async function getCapabilitiesForAccountIdInner(accountId: string) {
      const account = getAccountById(accountId);

      /**
       * @note this check was added to reduce errors caused by trying to get capabilities for non smart wallet providers
       * - this list will need to be updated when we add support for other smart wallet providers
       * - this could also be driven of of config service, or we could try to find another way to deduce if a provider is a smart wallet provider
       */
      if (!SMART_WALLET_PROVIDER_IDS.includes(account?.provider ?? '')) {
        return {};
      }

      const address = getEthereumAddressFromAccountId(accountId);
      const connectorManager = getConnectorManagerForAccountId(accountId);
      const provider = (await connectorManager.getProvider(
        WalletProviderNetwork.Ethereum,
      )) as EIP1193Provider;

      const walletClient = createWalletClient({
        account: address,
        transport: custom(provider),
      }).extend(walletActionsEip5792());

      // validate the provider is connected to the correct address
      await validateKnownEthereumAddress(provider, address);

      if (!isAddress(address)) {
        return {};
      }

      // Make sure we catch if this throws
      return walletClient.getCapabilities();
    },
    [getAccountById],
  );
}

import { parse, stringify } from 'cb-wallet-store/utils/serialization';
/**
 * This is not a great way to serialize/deserialize.
 * Currently doing Buffer > Uint8Array > String on the DB, when we could just to Buffer.toString() instead
 *
 * Consider this temporary code while we figure out best way to define DMOs for the React Native side
 */
export const uint8ArrayTransformer = {
  from: function uint8ArrayTransformerFrom(val: string) {
    const array = val ? val.split(',').map(Number) : [];
    return Buffer.from(new Uint8Array(array));
  },
  to: (val: Uint8Array) => val?.toString(),
};

export const stringArrayTransformer = {
  from: (val: string) => (val ? parse(val) : []),
  to: (val: unknown) => stringify(val),
};

import { HELIUS_WEBSOCKET_URL } from 'cb-wallet-env/env';
import { postJSON } from 'cb-wallet-http/fetchJSON';
import { v4 as uuidv4 } from 'uuid';
import { WebsocketMessageParseError } from 'wallet-engine-signing/history/errors';
import {
  DEFAULT_COMMITMENT_LEVEL,
  getSplsByAddress,
} from 'wallet-engine-signing/history/Solana/RPC';
import {
  SolanaAddressConfig,
  SolanaAddressHistory,
  SPLOwnership,
} from 'wallet-engine-signing/history/Solana/types';

import { DAS } from './heliusDASTypes';

const commonParams = {
  id: 1,
  jsonrpc: '2.0',
};

export type HeliusDASTokenInfo = {
  symbol: string;
  balance: number;
  supply: bigint;
  decimals: number;
  token_program: string;
  associated_token_address: string;
  price_info: {
    price_per_token: number;
    total_price: number;
    currency: string;
  };
};

export type HeliusDASNativeBalance = {
  lamports: bigint;
  price_per_sol: number;
  total_price: number;
};

export type HeliusDASGetAssetResponse = DAS.GetAssetResponse & {
  token_info: HeliusDASTokenInfo;
};

export type HeliusDASGetAssetResponseList = {
  grand_total?: number;
  total: number;
  limit: number;
  page: number;
  items: HeliusDASGetAssetResponse[];
};

export type GetAssetsByOwnerResponse = HeliusDASGetAssetResponseList & {
  nativeBalance: HeliusDASNativeBalance;
};

export async function heliusDASgetAssetsByOwner(
  address: string,
  rpcUrl: string,
  options: {
    showNativeBalance?: boolean;
    showFungible?: boolean;
    showZeroBalance?: boolean;
  },
): Promise<GetAssetsByOwnerResponse> {
  const parameters = {
    method: 'getAssetsByOwner',
    params: {
      ownerAddress: address,
      page: 1,
      limit: 1000,
      displayOptions: {
        showNativeBalance: options.showNativeBalance,
        showFungible: options.showFungible,
        showZeroBalance: options.showZeroBalance,
      },
    },
    ...commonParams,
  };

  const response = await postJSON<{ result: GetAssetsByOwnerResponse }>(rpcUrl, parameters, {
    isThirdParty: true,
  });

  return response.result;
}

// Converts to current way of representing SPL token balances so that we don't have
// to update type definitions everywhere.
export function toSPLOwnership(item: HeliusDASGetAssetResponse): SPLOwnership {
  const tokenInfo = item.token_info;
  const tokenUIAmount = tokenInfo.balance / 10 ** tokenInfo.decimals;
  return {
    pubkey: tokenInfo.associated_token_address,
    account: {
      data: {
        parsed: {
          info: {
            isNative: true,
            mint: item.id,
            owner: item.ownership.owner,
            state: 'initialized',
            tokenAmount: {
              amount: tokenInfo.balance.toString(),
              decimals: tokenInfo.decimals,
              uiAmount: tokenUIAmount,
              uiAmountString: tokenUIAmount.toString(),
            },
          },
        },
      },
    },
  };
}

export function solanaWebsocketHandler() {
  // Filter the websocket enabled address configs to only those included
  // in the socket message
  // TODO: eventually we should use the socket payload to update balances so we can avoid
  // a redundant request to the Helius DAS API.
  // Ticket to track: https://jira.coinbase-corp.com/browse/WALL-36952
  function getAddressesToRefresh(
    event: MessageEvent<any>,
    addresses: SolanaAddressHistory[],
  ): SolanaAddressHistory[] {
    try {
      const parsedSocketMessage = JSON.parse(event.data);

      if (
        parsedSocketMessage.method === 'transactionNotification' ||
        parsedSocketMessage.method === 'accountNotification'
      ) {
        const {
          params: {
            result: {
              transaction: {
                transaction: { accountKeys },
              },
            },
          },
        } = parsedSocketMessage;

        const accountKeysSet = new Set(accountKeys.map(({ pubkey }: { pubkey: string }) => pubkey));
        return addresses.filter(({ address, splBalances }) => {
          if (accountKeysSet.has(address)) {
            return true;
          }

          if (splBalances?.length) {
            return splBalances.some(({ pubkey }) => accountKeysSet.has(pubkey));
          }

          return false;
        });
      }

      return [];
    } catch (e: Error | unknown) {
      throw new WebsocketMessageParseError(
        `Error parsing websocket message: ${(e as Error)?.message}`,
      );
    }
  }

  function getTxHashFromSocketMessage(event: MessageEvent<any>) {
    try {
      const parsedSocketMessage = JSON.parse(event.data);
      if (parsedSocketMessage.method === 'transactionNotification') {
        return parsedSocketMessage.params.result.signature;
      }
    } catch (e) {
      throw new WebsocketMessageParseError(
        `Error parsing websocket message: ${(e as Error)?.message}`,
      );
    }
  }

  // Returns a websocket subscription request, after first querying for all SPL token accounts for the given addresses
  // as each separate SPL token account needs to be subscribed to.
  async function getSubscribeForUpdatesRequest(addresses: SolanaAddressConfig[]) {
    const allSPLPubkeys = (
      await Promise.all(
        addresses.map(async ({ address, rpcUrl }) => {
          return getSplsByAddress(address, rpcUrl);
        }),
      )
    ).flat();

    const allAccountsToSubscribe = addresses.map(({ address }) => address).concat(allSPLPubkeys);
    const commitment = DEFAULT_COMMITMENT_LEVEL;

    return {
      jsonrpc: '2.0',
      id: uuidv4(),
      method: 'transactionSubscribe',
      params: [
        {
          accountInclude: allAccountsToSubscribe,
        },
        {
          encoding: 'jsonParsed', // base58, base64, base65+zstd, jsonParsed
          commitment, // defaults to finalized if unset
          transactionDetails: 'accounts',
          showRewards: true,
          failed: false,
          vote: false,
          maxSupportedTransactionVersion: 0,
        },
      ],
    };
  }

  function getWebsocketEndpoint() {
    return HELIUS_WEBSOCKET_URL;
  }

  return {
    getAddressesToRefresh,
    getWebsocketEndpoint,
    getSubscribeForUpdatesRequest,
    getTxHashFromSocketMessage,
  };
}

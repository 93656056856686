import { useCallback, useMemo } from 'react';
import { logSidebarNavigationClick } from 'cb-wallet-analytics/navigate';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { SidebarItem } from '@cbhq/cds-web/navigation/SidebarItem';
import { SidebarItemsType } from './hooks/useSideNavbarConfig';
const link = "lhlihkp";
export function SideNavbarItem({
  title,
  route,
  base,
  icon,
  onPress
}: SidebarItemsType) {
  const router = useRouter();

  // TODO: Handle cases with query params and dynamic segments
  const pathname = router.pathname;
  const active = useMemo(() => {
    // allow /summer to be active when on /ocs
    // To be removed when OCS is over
    if (pathname === '/summer') {
      return route === '/ocs';
    }
    if (route === '/') {
      return pathname === route;
    }
    if (base) {
      const paths = pathname.split('/').filter(Boolean);
      return base.includes(paths[0]);
    }
    return pathname.startsWith(route);
  }, [base, pathname, route]);
  const handleOnPress = useCallback(() => {
    onPress?.();
    logSidebarNavigationClick(route);
  }, [route, onPress]);
  return <Link href={route} className={link} onClick={handleOnPress}>
      <SidebarItem testID={`sidebar-item--${title}`} key={`sidebar-item--${title}`} active={active} tooltipContent={title} icon={icon} title={title} />
    </Link>;
}

require("./SideNavbarItem.linaria.module.css!=!../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./SideNavbarItem.tsx");
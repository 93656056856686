import { useMemo } from 'react';
import {
  Connector as EthereumConnector,
  getConnectors,
  GetConnectorsReturnType,
} from '@wagmi/core';
import { isFeatureEnabledStore } from 'cb-wallet-data/FeatureManager/classes/IsFeatureEnabledStore';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { getConfig } from 'cb-wallet-data/scw/libs/wagmi/config';

import { makeEthereumNetwork } from '../networks';
import { WalletProviderInfo } from '../types';
import { WALLET_PROVIDERS } from '../wallet-providers';
import { COINBASE_WALLET_ID } from '../wallet-providers/coinbase-wallet';
import { PHANTOM_ID } from '../wallet-providers/phantom';
import { WalletConnectorManager } from '../WalletConnectorManager';

import {
  getEnabledWalletProviders,
  useGetEnabledWalletProviders,
} from './useGetEnabledWalletProviders';
import { useInjectedConnectors } from './useInjectedConnectors';

function makeWalletProviderInfo(connector: EthereumConnector): WalletProviderInfo {
  const networks = [makeEthereumNetwork(connector)];
  return {
    id: connector.id,
    name: connector.name,
    image: connector.icon ?? '', // todo: add fallback image (eth logo?)
    enabled: true,
    networks,
    connectorManager: new WalletConnectorManager(connector.id, networks),
  };
}

function getMipdWalletProviders(connectors: GetConnectorsReturnType) {
  return connectors
    .filter((connector) => ![COINBASE_WALLET_ID, PHANTOM_ID].includes(connector.id))
    .map(makeWalletProviderInfo);
}

function getWalletProviders(
  walletProviders: WalletProviderInfo[],
  connectors: GetConnectorsReturnType,
) {
  const mipdWalletProviders = getMipdWalletProviders(connectors);
  return [...walletProviders, ...mipdWalletProviders];
}

export function useWalletProviders() {
  const getEnabledProviders = useGetEnabledWalletProviders();
  const isMipdEnabled = useIsFeatureEnabled('wallet_provider_mipd');
  const injectedConnectors = useInjectedConnectors();

  const enabledWalletProviders = WALLET_PROVIDERS.filter((provider) =>
    getEnabledProviders(provider.id),
  );
  if (isMipdEnabled) {
    return getWalletProviders(enabledWalletProviders, injectedConnectors);
  }
  return enabledWalletProviders;
}

export function useWalletProvidersMap() {
  const walletProviders = useWalletProviders();
  return useMemo(
    () =>
      walletProviders.reduce((acc, walletProvider) => {
        acc[walletProvider.id] = walletProvider;
        return acc;
      }, {} as Record<string, WalletProviderInfo>),
    [walletProviders],
  );
}

export function getWalletProvidersConnectionManagerMap() {
  const enabledWalletProviders = WALLET_PROVIDERS.filter((provider) =>
    getEnabledWalletProviders(provider.id),
  );
  const connectors = getConnectors(getConfig());
  if (isFeatureEnabledStore.getIsFeatureEnabled('wallet_provider_mipd')) {
    return getWalletProviders(enabledWalletProviders, connectors).reduce((acc, walletProvider) => {
      acc[walletProvider.id] = walletProvider.connectorManager;
      return acc;
    }, {} as Record<string, WalletConnectorManager>);
  }

  return enabledWalletProviders.reduce((acc, walletProvider) => {
    acc[walletProvider.id] = walletProvider.connectorManager;
    return acc;
  }, {} as Record<string, WalletConnectorManager>);
}

import { useCallback, useEffect, useRef } from 'react';
import { logDuplicateAssetRemoved } from 'cb-wallet-analytics/assets';
import { allDeprecatedBlockchains } from 'cb-wallet-data/chains/blockchains';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { deleteWallet, deleteWallets } from '../database';
import { Wallet } from '../models/Wallet';
import { walletsAtom, WalletsMap } from '../state';

class WalletsTarget extends EventTarget {
  setWallets(wallets: Wallet[]) {
    this.dispatchEvent(
      new CustomEvent<{ wallets: Wallet[] }>('setWallets', { detail: { wallets } }),
    );
  }
}

const walletsTarget = new WalletsTarget();

export const setWallets = walletsTarget.setWallets.bind(walletsTarget);

/**
 * Sets up an event listener for a custom event that
 * saves wallets to recoil state when setWallets is called
 *
 * THIS SHOULD ONLY BE CALLED ONCE WHEN THE APP INITS
 */
export function useSetWallets(): void {
  // This atom should not be used outside of this file
  const setWalletsRecoilState = useSetRecoilState(walletsAtom);

  /**
   * Maintains a boxed reference to the current state of the internal wallets, which
   * can be compared against when setWallets event is triggered.
   *
   * This outer object reference can safely be passed as a dependency to the
   * useSetWalletsEffect, without causing the effect to run based on the internal
   * wallets state being updated.
   */
  const prevWalletsRecoilState = useRef(useRecoilValue(walletsAtom));

  const deleteWalletState = useDeleteWallet();

  /**
   * Binds the event listener for setWallets. Effect runs once on first render.
   */
  useEffect(
    function useSetWalletsEffect() {
      const setWalletsListener = ((event: CustomEvent<{ wallets: Wallet[] }>) => {
        const prevWallets = prevWalletsRecoilState.current;
        const nextWallets = event.detail.wallets.reduce<WalletsMap>(
          // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
          // eslint-disable-next-line wallet/no-anonymous-params
          (acc, wallet) => {
            if (!allDeprecatedBlockchains.includes(wallet.blockchain.rawValue)) {
              acc[wallet.id] = wallet;
            }
            return acc;
          },
          {},
        );

        const { shouldUpdate } = shouldUpdateWallets({
          prevWallets,
          nextWallets,
        });

        // After next and previous wallets have been combined, we only set the recoil
        // state if any of the wallets changed (performance enhancement).
        if (shouldUpdate) {
          setWalletsRecoilState(function setWalletState(previousWallets: WalletsMap) {
            const wallets = getWalletsAndDeletesDuplicates(previousWallets, nextWallets);
            prevWalletsRecoilState.current = wallets;
            return wallets;
          });
        }
      }) as EventListener;

      walletsTarget.addEventListener('setWallets', setWalletsListener);

      return function cleanupListener() {
        walletsTarget.removeEventListener('setWallets', setWalletsListener);
      };
    },
    [setWalletsRecoilState, prevWalletsRecoilState, deleteWalletState],
  );
}

/**
 * utility method to abstract the logic of combining previous and next wallets
 * and deleting duplicate wallets from previous wallets if there exist any
 */
export function getWalletsAndDeletesDuplicates(
  previousWallets: WalletsMap,
  nextWallets: WalletsMap,
): WalletsMap {
  const { nextWalletState, dedupedWalletIds } = determineNextWalletsStateAndDuplicateWalletIds(
    previousWallets,
    nextWallets,
  );

  // remove duplicate wallets from previous wallets if there exist
  // delete from database for any duplicate previous wallets
  // In the event if the app crashes just before duplicate wallets are deleted
  // the app when it reloads will again go through the same process to dedupe
  // duplicate wallets since its extracting the previousWallets from cache
  if (dedupedWalletIds.length > 0) {
    deleteDuplicateWallets(dedupedWalletIds);
  }

  return nextWalletState;
}

/**
 * determineNextWalletsState
 *
 * It takes previousWallets, and nextWallets as input parameter
 *
 * It creates new wallet keys for previous and next wallets
 * by excluding currencyCode - this is done to dedupe previous wallets
 * explained below.
 *
 * It also stores the newly created key in a Set.
 * We iterate over this Set and determine which wallet to select
 *
 * If the nextWallet has the key in the Set, we select the next wallet
 * otherwise we default to the previous wallet.
 *
 * While creating nextWalletsKey, we also check if any duplicate wallets
 * are required to be deleted from previous Wallets.
 *
 * This is done by checking if the newly created id (by excluding the currencyCode)
 * in nextWallet also exist in previous wallet.
 * If it does exist, then we add it to the array of wallet ids to be deleted.
 *
 * Finally, we delete the duplicate wallets from the database.
 * This op is done synchronously (w/o awaiting) since the response has no implications
 * on final response.
 */
export function determineNextWalletsStateAndDuplicateWalletIds(
  previousWallets: WalletsMap,
  nextWallets: WalletsMap,
): {
  nextWalletState: WalletsMap;
  dedupedWalletIds: string[];
} {
  // extracting wallets
  const previousWalletsArray = Object.values(previousWallets);
  const nextWalletsArray = Object.values(nextWallets);

  const allWalletKeys = new Set<string>();
  const walletIdsToBeDeleted = new Set<string>();

  const prevWalletsByWalletKey = previousWalletsArray.reduce<Record<string, Wallet[]>>(
    function generatePrevWalletKey(acc, prevWallet) {
      // Creates a list of wallets that have the same contract address and chain. This is used to
      // determine if there is a duplicate wallet with a different wallet id that needs to be deleted.
      // This is needed because the currencyCode for a wallet can change, while still representing the same
      // underlying asset. Deduplicating by contract address and chain ensures that only one wallet
      // exists for a given asset.
      const prevWalletKey = generateWalletKey(prevWallet);

      if (acc[prevWalletKey]) {
        acc[prevWalletKey].push(prevWallet);
      } else {
        allWalletKeys.add(prevWalletKey);
        acc[prevWalletKey] = [prevWallet];
      }

      return acc;
    },
    {},
  );

  const nextWalletsByWalletKey = nextWalletsArray.reduce<Record<string, Wallet[]>>(
    function generateNextWalletKey(acc, nextWallet) {
      const nextWalletKey = generateWalletKey(nextWallet);

      /**
       * we store the response in an array to maintain consistency over
       * previous wallet keys.
       * If there are any duplicate keys in nextWallet, we override it
       * with the most currency Wallet.
       * this maintain consistency with the previous version.
       */
      acc[nextWalletKey] = [nextWallet];
      allWalletKeys.add(nextWalletKey);

      if (prevWalletsByWalletKey[nextWalletKey]) {
        const prevWallets = prevWalletsByWalletKey[nextWalletKey];
        /**
         * Possible scenarios for reading duplicate wallets
         * NOTE: only two scenarios are possible as nextWallets
         * will already override any duplicate next Wallets
         * Next Wallet | Previous Wallet
         *     One     |      Many
         *     One     |      One
         *
         * If a relation exist of One <-> Many b/w next and previous wallets
         * respectively then we always allow only the single next wallet
         * and delete all previous wallets.
         *
         * We also ensure that no ids get added in the array if they already exist
         */
        prevWallets.forEach((prevWallet) => {
          // if a wallet doesn't have a contract address then its a native asset
          // its not possible to have a duplicate native asset since they are
          // hardcoded. We avoid checks for such wallets (this is in parity w/
          // previous version)
          if (
            prevWallet.contractAddress &&
            nextWallet.contractAddress &&
            prevWallet.currencyCode.rawValue !== nextWallet.currencyCode.rawValue
          ) {
            walletIdsToBeDeleted.add(prevWallet.id);
          }
        });
      }

      return acc;
    },
    {},
  );

  const nextWalletState = [...allWalletKeys].reduce<WalletsMap>(function generateNextWalletState(
    acc,
    walletKey,
  ) {
    const walletsToUse = nextWalletsByWalletKey[walletKey] ?? prevWalletsByWalletKey[walletKey];

    walletsToUse.forEach((wallet: Wallet) => {
      acc[wallet.id] = wallet;
    });

    return acc;
  },
  {});

  const dedupedWalletIds = [...walletIdsToBeDeleted];

  return { nextWalletState, dedupedWalletIds };
}

/**
 * Combines the previous wallets state with a next wallets state.
 *
 * Helps with unnecessary rendering by:
 * - Ensuring unchanged wallet objects maintain reference equality.
 * - Providing a flag to indicate whether recoil state should be updated.
 */
export function shouldUpdateWallets({
  prevWallets,
  nextWallets,
}: {
  prevWallets: WalletsMap;
  nextWallets: WalletsMap;
}): {
  /** Whether an update to wallets state is needed. True if any wallets changed. */
  shouldUpdate: boolean;
} {
  // If this remains false after iterating through and creating the wallets
  // state, there's no need to update recoil state and trigger unnecessary rendering.
  let didWalletsChange = false;

  Object.values(nextWallets).reduce(
    // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
    // eslint-disable-next-line wallet/no-anonymous-params
    (acc, wallet) => {
      const prevWallet = acc[wallet.id];
      const isNewWalletOrDidChange = !prevWallet || !Wallet.isEqual(wallet, prevWallet);

      if (isNewWalletOrDidChange) {
        didWalletsChange = true;
      }

      return acc;
    },
    { ...prevWallets },
  );

  return {
    shouldUpdate: didWalletsChange,
  };
}

/**
 * Provides a function to delete a single wallet.
 *
 * Wallet will be deleted both from the client database and recoil state.
 */
export function useDeleteWallet() {
  // This atom should not be used outside of this file
  const setWalletsRecoilState = useSetRecoilState(walletsAtom);

  return useCallback(
    async function deleteWalletCallback(walletToDelete: Wallet) {
      await deleteWallet(walletToDelete);

      setWalletsRecoilState(function deleteWalletFromState(prevWallets) {
        const nextWallets = { ...prevWallets };
        delete nextWallets[walletToDelete.id];
        return nextWallets;
      });
    },
    [setWalletsRecoilState],
  );
}

/**
 * Provides a function to delete multiple wallets.
 *
 * Wallets will be deleted both from the client database and recoil state.
 */
export function useDeleteWallets() {
  // This atom should not be used outside of this file
  const setWalletsRecoilState = useSetRecoilState(walletsAtom);

  return useCallback(
    async function deleteWalletsCallback(walletIdsToDelete: string[]) {
      await deleteWallets(walletIdsToDelete);

      setWalletsRecoilState(function deleteWalletsFromState(prevWallets) {
        const nextWallets = { ...prevWallets };
        walletIdsToDelete.forEach((id) => {
          delete nextWallets[id];
        });
        return nextWallets;
      });
    },
    [setWalletsRecoilState],
  );
}

/**
 * util to generate a unique key for each wallet
 * a new wallet key is generated excluding the currencyCode
 * accountId, selectedIndex, blockchain, network and contractAddress
 * are part of a walletId
 */
export function generateWalletKey(wallet: Wallet): string {
  return [
    wallet.accountId,
    wallet.selectedIndex,
    wallet.blockchain.rawValue,
    wallet.network.rawValue,
    wallet.contractAddress,
  ].join('/');
}

/**
 * utility function to combine previous and next wallets and to
 * remove duplicate wallets from previous wallets if there exist
 * delete from database for any duplicate previous wallets
 */
export function deleteDuplicateWallets(walletIdsToBeDeleted: string[]): void {
  deleteWallets(walletIdsToBeDeleted);
  logDuplicateAssetRemoved(walletIdsToBeDeleted.length);
}

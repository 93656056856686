import { useEffect } from 'react';
import {
  triggerMissingDefaultAccountDetected,
  triggerMissingDefaultWalletGroupDetected,
} from 'cb-wallet-analytics/accounts';
import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { useWalletGroups } from 'cb-wallet-data/stores/WalletGroups/hooks/useWalletGroups';

import { useAccounts } from './useAccounts';

/**
 * This will detect if a user has a missing default Multi-Account entities (e.g. account, wallet group, etc.) and log
 * any events/Bugsnag errors if needed.
 *
 * We have our `migrateForMultiAccount` DataMigrationRunner successfully released so any new or existing users should
 * have their default account and wallet group automatically created during their onboarding so these events/errors
 * should not be emitted unless the user is in an unexpected state.
 *
 * **NOTE: This hook should only be called once we know the user has logged in and the `DataMigrationRunner` has completed
 * to prevent emitting duplicate events/Bugsnag errors!**
 *
 * @see {@link DataMigrationRunner/migrations/migrateForMultiAccount}
 */
export function useDetectMissingMultiAccountEntities(): null {
  const hasAccount = Boolean(useAccounts().length);
  const hasWalletGroup = Boolean(useWalletGroups().length);
  const isMissingAccountOrWalletGroup = !hasWalletGroup || !hasAccount;

  useEffect(
    function detectAnyMissingDefaultAccountsOrWalletGroups() {
      if (!isMissingAccountOrWalletGroup) {
        return;
      }

      if (!hasAccount) {
        triggerMissingDefaultAccountDetected();
      }

      if (!hasWalletGroup) {
        triggerMissingDefaultWalletGroupDetected();
      }

      cbReportError({
        error: new Error('User has no account and/or wallet group'),
        context: 'multi-account',
        isHandled: false,
        severity: 'error',
      });
    },
    [hasAccount, hasWalletGroup, isMissingAccountOrWalletGroup],
  );

  return null;
}

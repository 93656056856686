import Decimal from 'decimal.js';
import { atom, selector } from 'recoil';

import { ExchangeRate } from './types';

export const exchangeRatesAtom = atom({
  key: 'exchangeRates',
  default: [] as ExchangeRate[],
});

export const didLoadCachedExchangeRatesAtom = atom({
  key: 'didLoadCachedExchangeRates',
  default: false,
});

export function getExchangeRateKey({
  chainId,
  networkId,
  contractAddress,
  currencyCode,
}: Omit<ExchangeRate, 'rate'>) {
  const networkIdentifier = networkId || chainId?.toString();
  // If we have networkIdentifier and contractAddress we omit the currencyCode, since
  // it can sometimes be unreliable (e.g. PAX and USDP)
  const code = networkIdentifier && contractAddress ? 'x' : currencyCode.code;
  return `${networkIdentifier || 'x'}-${contractAddress || 'x'}-${code}`;
}

export function generateExchangeRateMap(exchangeRates: ExchangeRate[]) {
  const exchangeRateMap: Record<string, Decimal> = {};
  exchangeRates.forEach((exchangeRate) => {
    exchangeRateMap[getExchangeRateKey(exchangeRate)] = exchangeRate.rate;
  });
  return exchangeRateMap;
}

/**
 * Map for efficiently accessing an exchange rate
 */
export const exchangeRatesMapSelector = selector<Record<string, Decimal>>({
  key: 'exchangeRatesMapSelector',
  get: function getExchangeRatesMap({ get }) {
    const exchangeRates = get(exchangeRatesAtom);
    return generateExchangeRateMap(exchangeRates);
  },
  // eslint-disable-next-line camelcase
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
});

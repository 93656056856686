/**
 * A blockchain-based transaction
 *
 * @property id UUID generated on the client at the time that a Transaction object is created
 * @property createdAt The date the transaction was created
 * @property confirmedAt The date the transaction was confirmed
 * @property blockchain For evm chains, this is always set to 'ETH'. For non-evm chains, this means the blockchain the transaction is on.
 * @property currencyCode The currency code of the token sent in the transaction
 * @property feeCurrencyCode The currency code the transaction fee was paid in
 * @property toAddress If the transaction object has been populated from a server response, then this field is the recipient address of a transfer. If the transaction object has been created on the client, then this field is the contract address with which the transaction is interacting
 * @property toDomain The recipient domain of the transaction
 * @property fromAddress The sender address of the transaction
 * @property fromDomain The sender domain of the transaction
 * @property amount The amount of the transaction
 * @property fee The fee of the transaction
 * @property metadata Metadata for the transaction
 * @property state The state of the transaction
 * @property network The network state
 * @property accountId accountId indicating the account to which this history item has metadata specifically for
 * @property walletIndex number indicating the index of a wallet within a WalletGroup to which this history item has metadata specifically for
 * @property txOrUserOpHash For the case where the history item HAS a userOpHash, the txOrUserOpHash must be the userOpHash. For the case where the history DOES NOT HAVE a userOpHash, the txOrUserOpHash must be the txHash.
 * @property txHash The transaction hash
 * @property isSent True if the user sent the transaction. False if the user received the transaction.
 * @property contractAddress The contract address of the token in the transaction. This will not necessarily give you the contract address of the dex, relayer, or other contract that is top-level called by the transaction
 * @property tokenName The name of the token in the transaction
 * @property tokenDecimal The decimal of the token in the transaction
 * @property walletId ID of associated wallet, used for transactions filtering, based on the active wallet group. Reminder
 * that walletId is a string in the format `blockchain/currencyCode/network/contractAddress/selectedIndex/accountId`
 * @property nonce The nonce value of the transaction
 * @property pendingEthTxData The data associated with the pending state of the transaction
 * @property type The transaction type, 'RECEIVE' | 'SEND' | 'MINT' | 'APPROVE' | 'SWAP' | 'SPONSORED'
 * @property transfers The internal transfer within the transaction
 * @property primaryAction type of the transaction as classified by HRT
 * @property toAssetHistoricalPrice The price of the 'to' asset on the day of the transaction
 * @property fromAssetHistoricalPrice The price of the 'from' asset on the day of the transaction
 * @property nativeAssetHistoricalPrice The price of the native asset on the day of the transaction
 * @property fromAssetImage Required for swaps, bridges, staking, and NFTs
 * @property toAssetImage Required for swaps, bridges, staking, and NFTs
 * @property fromProfileImage Profile image to show beside the wallet address
 * @property toProfileImage Profile image to show beside the wallet address
 * @property fromAmount The amount being sent, mainly used for swaps
 * @property toAmount The amount being received
 * @property isContractExecution true if the transaction interacted with a smart contract, false otherwise
 * @property toNetwork Used for bridge transactions to represent the network assets are being bridged to
 * @property toCurrencyCode The currency code of the token received in the transaction
 * @property toContractAddress The contract address of the token received in the transaction
 * @property toTokenName The name of the token received in the transaction
 * @property toTokenDecimal The decimal of the token received in the transaction
 * @property coinbaseFeeAmount The amount of the coinbase fee
 * @property coinbaseFeeDecimal The decimal of the coinbase fee asset
 * @property coinbaseFeeAssetAddress The contract address of the coinbase fee asset
 * @property coinbaseFeeCurrencyCode The currency code of the coinbase fee asset
 * @property coinbaseFeeName The name of the coinbase fee asset
 * @property protocolFeeAmount The amount of the dex fee
 * @property protocolFeeDecimal The decimal of the dex fee token
 * @property protocolFeeCurrencyCode The currency code of the dex fee token
 * @property protocolFeeName The name of the dex fee token
 * @property toWalletId ID of the 'from' wallet
 * @property fromTokenId ID of the 'from' token
 * @property toTokenId ID of the 'to' token
 * @property source The API source of the transaction
 */
import { TxOrUserOpMetadataKey_txSource } from 'cb-wallet-data/chains/AccountBased/Ethereum/config';
import { isUTXOBasedBlockchain } from 'cb-wallet-data/chains/blockchains';
import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { Persistable } from 'cb-wallet-data/persistence/Database.interface';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { UnsignedTxOrUserOp } from 'cb-wallet-data/stores/Transactions/interfaces/UnsignedTxOrUserOp';
import { TxOrUserOpMetadata } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOpMetadata';
import {
  areAddressesEqual,
  areHistoricalPricesEqual,
  areTransactionTimestampsEqual,
  areTransfersEqual,
  isBasicTxInfoEqual,
  isCoinbaseFeeEqual,
  isFeeEqual,
  isMetadataEqual,
  isNetworkEqual,
  isNonceEqual,
  isPendingTxDataEqual,
  isProtocolFeeEqual,
} from 'cb-wallet-data/stores/Transactions/utils/isEqual';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { stringArrayTransformer, uint8ArrayTransformer } from 'cb-wallet-data/utils/dbTransformers';
import { getTruncatedAddress } from 'cb-wallet-data/utils/getTruncatedAddress';
import { parse, stringify } from 'cb-wallet-store/utils/serialization';
import memoize from 'lodash/memoize';
import { Column, Entity, Index, PrimaryColumn } from '@cbhq/typeorm';

import { getCashoutEntityName } from '../utils/getCashoutEntityName';

import { PendingEthTxState, PendingUTXOTxState } from './PendingEthTxState';
import { Transfer } from './Transfer';
import {
  AssetTypeFungibleTkn,
  AssetTypeNFT,
  TxOrUserOpMetadataKey_assetType,
  TxOrUserOpMetadataKey_cashoutIdem,
  TxOrUserOpMetadataKey_cashoutWithdrawalType,
  TxOrUserOpMetadataKey_consumerTxID,
  TxOrUserOpMetadataKey_gaslessSwapHash,
  TxOrUserOpMetadataKey_hasRecordedConfirmEvent,
  TxOrUserOpMetadataKey_inputCount,
  TxOrUserOpMetadataKey_isCBStakeTxn,
  TxOrUserOpMetadataKey_isGaslessTxn,
  TxOrUserOpMetadataKey_isReceiveTronTx,
  TxOrUserOpMetadataKey_isSponsoredTxn,
  TxOrUserOpMetadataKey_sponsoredUuid,
  TxOrUserOpMetadataKey_toTokenAmount,
  TxOrUserOpMetadataKey_toTokenCurrencyCode,
  TxOrUserOpMetadataKey_toTokenDecimal,
  TxOrUserOpMetadataKey_toTokenImage,
  TxOrUserOpMetadataKey_toTokenName,
  TxOrUserOpMetadataKey_tronDestinationTransactionHash,
  TxOrUserOpMetadataKey_tronFromAddress,
  TxOrUserOpMetadataKey_tronReceiptLink,
  TxOrUserOpMetadataKey_tronTransactionHash,
  TxOrUserOpMetadataKey_tronTxProviderKycLink,
  TxOrUserOpMetadataKey_tronTxStatus,
} from './TxOrUserOpMetadataKey';
import { TxRelayType } from './TxRelayType';
import { TxState } from './TxState';
import type { TxSubmissionType } from './TxSubmissionType';
import { UnsupportedTronTransactionStatus } from './UnsupportedTronTransaction';

/**
 * Memoize this as otherwise it would be called once per transaction each time we
 * refetch transactions from database. Parse is expensive and it would also be called
 * each time a transaction is accessed otherwise.
 *
 * Technically memory-wise it would be better to just use the transfersObj property on
 * Transaction, but this leads to a lot of testing difficulties.
 */
const getTransferFromString = memoize(function getTransferFromString(
  transfersStr: string | undefined,
) {
  return transfersStr ? parse(transfersStr) : undefined;
});

@Entity('tx_or_userop_history')
// We stopped showing a separate history item for the base asset wallet, so no need for currencyCode to be in the uniqueness constraint
// the only need for a field beyond `txOrUserOpHash` in the uniqueness constraint is that metadata may vary for different
// wallets. for example, wallet1 sends to wallet2 will show as a SEND for wallet1 and a receive for wallet2 where both
// wallet1 and wallet2 are wallets controlled by the user as either separated indexed wallets within the same wallet group
// or wallets belonging to two different accounts.
@Index(
  'IDX_txOrUserOpHash_ACCOUNTID_WALLETINDEX_UNIQUE',
  ['txOrUserOpHash', 'accountId', 'walletIndex'],
  {
    unique: true,
  },
)
@Index('IDX_TXUSEROP_BLOCK_CURR_NETWORK_2', ['blockchainStr', 'currencyCodeStr', 'networkStr'])
@Index('IDX_TXUSEROP_CURR_NETWORK_2', ['currencyCodeStr', 'networkStr'])
@Index('IDX_TXUSEROP_TX_HASH_2', ['txHash'])
@Index('IDX_TXUSEROP_TX_HASH_WALLET_2', ['txHash', 'walletId'])
@Index('IDX_TXUSEROP_ITEM_HASH', ['txOrUserOpHash'])
@Index('IDX_TXUSEROP_BLOCK_CURR_NETWORK_ADDR_2', [
  'blockchainStr',
  'currencyCodeStr',
  'networkStr',
  'fromAddress',
])
@Index('IDX_TXUSEROP_WALLETID_ACCOUNTID', ['txOrUserOpHash', 'walletId', 'accountId'])
@Index('IDX_TXUSEROP_CREATED_AT_2', ['createdAt'])
@Index('IDX_TXUSEROP_SOFT_DELETED', ['deleted'])
export class TxOrUserOpDMO {
  @PrimaryColumn()
  id!: string;

  @Column({ type: 'datetime' })
  createdAt!: Date;

  @Column({ type: 'datetime', nullable: true })
  confirmedAt?: Date;

  @Column()
  blockchainStr!: string;

  @Column()
  currencyCodeStr!: string;

  @Column()
  feeCurrencyCodeStr!: string;

  @Column()
  feeCurrencyDecimal!: string;

  @Column({ nullable: true })
  toAddress?: string;

  @Column({ nullable: true })
  toDomain?: string;

  @Column({ nullable: true })
  fromAddress?: string;

  @Column({ nullable: true })
  fromDomain?: string;

  @Column()
  amount!: string;

  @Column({ nullable: true })
  fromAmount?: string;

  @Column({ nullable: true })
  toAmount?: string;

  @Column()
  fee!: string;

  @Column({ type: 'int' })
  state!: TxState;

  @Column({ type: 'varchar', transformer: stringArrayTransformer })
  metadataStrArray!: string[];

  @Column()
  networkStr!: string;

  @Column()
  accountId!: string;

  @Column()
  walletIndex!: string;

  @Column()
  txOrUserOpHash!: string;

  @Column({ nullable: true })
  userOpHash?: string;

  @Column({ nullable: true })
  txHash?: string;

  @Column()
  isSent!: boolean;

  @Column({ nullable: true })
  contractAddress?: string;

  @Column()
  tokenName!: string;

  @Column()
  tokenDecimal!: string;

  @Column({ nullable: true })
  nonce?: string;

  @Column({ nullable: true })
  gasLimit?: string;

  @Column({ nullable: true })
  maxFeePerGas?: string;

  @Column({ nullable: true })
  maxPriorityFeePerGas?: string;

  // overloaded to store feeRate for UTXO transactions
  @Column({ nullable: true })
  baseFeePerGas?: string;

  @Column({ type: 'varchar', nullable: true })
  txSubmissionType?: TxSubmissionType;

  @Column({ nullable: true })
  lowerBoundValue?: string;

  @Column({ type: 'varchar', transformer: uint8ArrayTransformer, nullable: true })
  data?: Buffer;

  @Column({ nullable: true })
  type?: string;

  @Column({ type: 'varchar', nullable: true })
  primaryAction?: TxPrimaryAction;

  @Column({ type: 'varchar', nullable: true })
  transfersStr?: string;

  @Column({ nullable: true })
  toAssetHistoricalPrice?: string;

  @Column({ nullable: true })
  fromAssetHistoricalPrice?: string;

  @Column({ nullable: true })
  nativeAssetHistoricalPrice?: string;

  @Column({ nullable: true })
  fromAssetImage?: string;

  @Column({ nullable: true })
  toAssetImage?: string;

  @Column({ nullable: true })
  fromProfileImage?: string;

  @Column({ nullable: true })
  toProfileImage?: string;

  @Column({ nullable: true })
  isContractExecution?: boolean;

  @Column({ nullable: true })
  toNetwork?: string;

  @Column({ nullable: true })
  toCurrencyCode?: string;

  @Column({ nullable: true })
  toContractAddress?: string;

  @Column({ nullable: true })
  toTokenName?: string;

  @Column({ type: 'varchar', nullable: true })
  source?: TxSource;

  @Column({ nullable: true })
  toTokenDecimal?: string;

  @Column({ nullable: true })
  protocolFeeAmount?: string;

  @Column({ nullable: true })
  coinbaseFeeAmount?: string;

  @Column({ nullable: true })
  coinbaseFeeDecimal?: string;

  @Column({ nullable: true })
  coinbaseFeeAssetAddress?: string;

  @Column({ nullable: true })
  coinbaseFeeCurrencyCode?: string;

  @Column({ nullable: true })
  coinbaseFeeName?: string;

  @Column({ nullable: true })
  protocolFeeDecimal?: string;

  @Column({ nullable: true })
  protocolFeeCurrencyCode?: string;

  @Column({ nullable: true })
  protocolFeeName?: string;

  @Column({ nullable: true })
  fromTokenId?: string;

  @Column({ nullable: true })
  toTokenId?: string;

  @Column({ nullable: true })
  toWalletId?: string;

  @Column()
  walletId!: string;

  @Column({ nullable: true })
  l1GasFee?: string;

  @Column({ nullable: true, type: 'varchar', transformer: stringArrayTransformer })
  utxos?: string[];

  // Once this field is updated to `true`, this transaction will no longer be displayed in any transaction list.
  // We call this a 'soft delete'. We do this to avoid causing an error if the user is on a screen that depends on
  // this transaction object existing at the time the transaction is deleted. A current use-case is when we want to
  // hide our client-side generated transaction for sponsored send once the txhistory service returns us a transaction
  // with a real txHash
  @Column({ default: false })
  deleted!: boolean;

  @Column({ nullable: true })
  isSpam?: boolean;
}

export const PAYMENTS_MODE_TX_TYPES = [
  'RECEIVE',
  'SEND',
  'SPONSORED',
  'GASLESS',
  'CASHOUT',
] as const;

export const TX_FLOW_OR_TYPES = [
  'RECEIVE',
  'SEND',
  'MINT',
  'APPROVE',
  'SWAP',
  'SPONSORED',
  'GASLESS',
  'CASHOUT',
] as const;

export type TxFlowOrType = (typeof TX_FLOW_OR_TYPES)[number];

export type TxPrimaryAction =
  | 'UNKNOWN'
  | 'APPROVE'
  | 'SEND'
  | 'RECEIVE'
  | 'SWAP'
  | 'BRIDGE' // BRIDGE is the result of combining a BRIDGE_IN and BRIDGE_OUT
  | 'BRIDGE_IN'
  | 'BRIDGE_OUT'
  | 'MINT'
  | 'WRAP'
  | 'UNWRAP'
  | 'CONTRACT_EXECUTION'
  | 'STAKE'
  | 'UNSTAKE'
  | 'CASHOUT'
  | 'CLAIM';

// This type represents the API used to fetch the tx history records. This is important since we have multiple
// sources of tx history records and we want to be able to distinguish between them.
export type TxSource = 'ETHERSCAN' | 'V2' | 'V3';

export type TxPendingMetadata = {
  txType: TxPrimaryAction;
  tokenName: string;
  tokenCurrencyCode: CurrencyCode;
  tokenDecimal: bigint;
  tokenImage: string;
  cryptoAmount: bigint;
};

type SharedTxOrUserOpParamsBase = {
  id: string;
  createdAt: Date;
  confirmedAt?: Date;
  blockchain: Blockchain;
  currencyCode: CurrencyCode;
  feeCurrencyCode: CurrencyCode;
  feeCurrencyDecimal: bigint;
  toAddress?: string;
  toDomain?: string;
  fromAddress?: string;
  fromDomain?: string;
  amount: bigint;
  fee: bigint;
  state: TxState;
  metadata: TxOrUserOpMetadata;
  network: Network;
  accountId: string;
  walletIndex: bigint;
  txOrUserOpHash: string;
  isSent: boolean;
  contractAddress?: string;
  tokenName: string;
  tokenDecimal: bigint;
  walletId: Wallet['id'];
  nonce?: bigint;
  pendingEthTxData?: PendingEthTxState;
  pendingUTXOTxData?: PendingUTXOTxState;
  type?: TxFlowOrType;
  deleted?: boolean;
  isSpam?: boolean;
  primaryAction?: TxPrimaryAction;
  toAssetHistoricalPrice?: string;
  fromAssetHistoricalPrice?: string;
  nativeAssetHistoricalPrice?: string;
  fromAssetImage?: string;
  toAssetImage?: string;
  fromProfileImage?: string;
  toProfileImage?: string;
  fromAmount?: string;
  toAmount?: string;
  isContractExecution?: boolean;
  toNetwork?: Network;
  toCurrencyCode?: CurrencyCode;
  toContractAddress?: string;
  toTokenName?: string;
  toTokenDecimal?: bigint;
  coinbaseFeeAmount?: string;
  coinbaseFeeDecimal?: bigint;
  coinbaseFeeAssetAddress?: string;
  coinbaseFeeCurrencyCode?: CurrencyCode;
  coinbaseFeeName?: string;
  protocolFeeAmount?: string;
  protocolFeeDecimal?: bigint;
  protocolFeeCurrencyCode?: CurrencyCode;
  protocolFeeName?: string;
  toWalletId?: Wallet['id'];
  fromTokenId?: string;
  toTokenId?: string;
  source?: TxSource;
};

type WithUserOpHash = SharedTxOrUserOpParamsBase & {
  userOpHash: string;
  txHash?: string;
};

type WithTxHash = SharedTxOrUserOpParamsBase & {
  userOpHash?: string;
  txHash: string;
};

type SharedTxOrUserOpParams = WithUserOpHash | WithTxHash;

type FromDMOTxOrUserOpParams = SharedTxOrUserOpParams & {
  transfersStr?: string;
};

type FromObjectTxOrUserOpParams = SharedTxOrUserOpParams & {
  transfers?: Transfer[];
};

export type TxOrUserOpParams = FromDMOTxOrUserOpParams | FromObjectTxOrUserOpParams;

export class TxOrUserOp implements Persistable<TxOrUserOpDMO> {
  readonly id: string;
  readonly createdAt: Date;
  readonly confirmedAt?: Date;
  readonly blockchain: Blockchain;
  readonly currencyCode: CurrencyCode;
  readonly feeCurrencyCode: CurrencyCode;
  readonly feeCurrencyDecimal: bigint;
  readonly toAddress?: string;
  readonly toDomain?: string;
  readonly fromAddress?: string;
  readonly fromDomain?: string;
  readonly amount: bigint;
  readonly fee: bigint;
  readonly state: TxState;
  readonly metadata: TxOrUserOpMetadata;
  readonly network: Network;
  readonly accountId: string;
  readonly walletIndex: bigint;
  readonly txOrUserOpHash: string;
  readonly userOpHash?: string;
  readonly txHash?: string;
  readonly isSent: boolean;
  readonly contractAddress?: string;
  readonly tokenName: string;
  readonly tokenDecimal: bigint;
  readonly walletId: Wallet['id'];
  readonly nonce?: bigint;
  readonly pendingEthTxData?: PendingEthTxState;
  readonly pendingUTXOTxData?: PendingUTXOTxState;
  readonly type?: TxFlowOrType;
  readonly transfersStr?: string;
  readonly transfersObj?: Transfer[];
  readonly deleted?: boolean;
  readonly isSpam?: boolean;
  readonly primaryAction?: TxPrimaryAction;
  readonly toAssetHistoricalPrice?: string;
  readonly fromAssetHistoricalPrice?: string;
  readonly nativeAssetHistoricalPrice?: string;
  readonly fromAssetImage?: string;
  readonly toAssetImage?: string;
  readonly fromProfileImage?: string;
  readonly toProfileImage?: string;
  readonly fromAmount?: string;
  readonly toAmount?: string;
  readonly isContractExecution?: boolean;
  readonly toNetwork?: Network;
  readonly toCurrencyCode?: CurrencyCode;
  readonly toContractAddress?: string;
  readonly toTokenName?: string;
  readonly toTokenDecimal?: bigint;
  readonly coinbaseFeeAmount?: string;
  readonly coinbaseFeeDecimal?: bigint;
  readonly coinbaseFeeAssetAddress?: string;
  readonly coinbaseFeeCurrencyCode?: CurrencyCode;
  readonly coinbaseFeeName?: string;
  readonly protocolFeeAmount?: string;
  readonly protocolFeeDecimal?: bigint;
  readonly protocolFeeCurrencyCode?: CurrencyCode;
  readonly protocolFeeName?: string;
  readonly toWalletId?: Wallet['id'];
  readonly fromTokenId?: string;
  readonly toTokenId?: string;
  readonly source?: TxSource;

  constructor(params: TxOrUserOpParams) {
    this.id = params.id;
    this.createdAt = params.createdAt;
    this.confirmedAt = params.confirmedAt;
    this.blockchain = params.blockchain;
    this.currencyCode = params.currencyCode;
    this.feeCurrencyCode = params.feeCurrencyCode;
    this.feeCurrencyDecimal = params.feeCurrencyDecimal;
    this.toAddress = params.toAddress;
    this.toDomain = params.toDomain;
    this.fromAddress = params.fromAddress;
    this.fromDomain = params.fromDomain;
    this.amount = params.amount;
    this.fee = params.fee;
    this.state = params.state;
    this.metadata = params.metadata;
    this.network = params.network;
    this.accountId = params.accountId;
    this.walletIndex = BigInt(params.walletIndex);
    // since userOpHash and txHash can both be deterministically created client-side, we can always guarantee a truthy value for txOrUserOpHash
    this.txOrUserOpHash = params.userOpHash ?? params.txHash!;
    this.userOpHash = params.userOpHash;
    this.txHash = params.txHash;
    this.isSent = params.isSent;
    this.contractAddress = params.contractAddress;
    this.tokenName = params.tokenName;
    this.tokenDecimal = params.tokenDecimal;
    this.walletId = params.walletId;
    this.nonce = params.nonce;
    this.pendingEthTxData = params.pendingEthTxData;
    this.pendingUTXOTxData = params.pendingUTXOTxData;
    this.type = params.type;
    this.deleted = params.deleted ?? false;
    this.isSpam = params.isSpam;
    this.primaryAction = params.primaryAction;
    this.toAssetHistoricalPrice = params.toAssetHistoricalPrice;
    this.fromAssetHistoricalPrice = params.fromAssetHistoricalPrice;
    this.nativeAssetHistoricalPrice = params.nativeAssetHistoricalPrice;
    this.fromAssetImage = params.fromAssetImage;
    this.toAssetImage = params.toAssetImage;
    this.fromProfileImage = params.fromProfileImage;
    this.toProfileImage = params.toProfileImage;
    this.fromAmount = params.fromAmount;
    this.toAmount = params.toAmount;
    this.isContractExecution = params.isContractExecution;
    this.toNetwork = params.toNetwork;
    this.toCurrencyCode = params.toCurrencyCode;
    this.toContractAddress = params.toContractAddress;
    this.toTokenName = params.toTokenName;
    this.toTokenDecimal = params.toTokenDecimal;
    this.coinbaseFeeAmount = params.coinbaseFeeAmount;
    this.coinbaseFeeDecimal = params.coinbaseFeeDecimal;
    this.coinbaseFeeAssetAddress = params.coinbaseFeeAssetAddress;
    this.coinbaseFeeCurrencyCode = params.coinbaseFeeCurrencyCode;
    this.coinbaseFeeName = params.coinbaseFeeName;
    this.protocolFeeAmount = params.protocolFeeAmount;
    this.protocolFeeDecimal = params.protocolFeeDecimal;
    this.protocolFeeCurrencyCode = params.protocolFeeCurrencyCode;
    this.protocolFeeName = params.protocolFeeName;
    this.toWalletId = params.toWalletId;
    this.fromTokenId = params.fromTokenId;
    this.toTokenId = params.toTokenId;
    this.source = params.source;

    if ('transfers' in params) {
      this.transfersObj = params.transfers;
    }

    if ('transfersStr' in params) {
      this.transfersStr = params.transfersStr;
    }
  }

  /**
   * If we are constructing a Transaction from database via fromDMO then we
   * defer creating the Transfer objects as this is expensive for large wallets.
   * However if we are constructing a Transaction and already have the transfers
   * object then we can just use it without the transfers string.
   */
  get transfers(): Transfer[] | undefined {
    if (this.transfersObj) {
      return this.transfersObj;
    }

    return getTransferFromString(this.transfersStr);
  }

  static hasSameTxHash(tx1: TxOrUserOp, tx2: TxOrUserOp): boolean {
    return tx1.txHash === tx2.txHash;
  }

  static isEqual(tx1: TxOrUserOp, tx2: TxOrUserOp): boolean {
    const addressesMatch = areAddressesEqual(tx1, tx2);
    const basicTxInfoMatches = isBasicTxInfoEqual(tx1, tx2);
    const coinbaseFeeMatches = isCoinbaseFeeEqual(tx1, tx2);
    const feeMatches = isFeeEqual(tx1, tx2);
    const historicalPricesMatch = areHistoricalPricesEqual(tx1, tx2);
    const metadataMatches = isMetadataEqual(tx1, tx2);
    const networkMatches = isNetworkEqual(tx1, tx2);
    const nonceMatches = isNonceEqual(tx1, tx2);
    const pendingTxDataMatches = isPendingTxDataEqual(tx1, tx2);
    const protocolFeeMatches = isProtocolFeeEqual(tx1, tx2);
    const transactionTimestampsMatch = areTransactionTimestampsEqual(tx1, tx2);
    const transfersMatch = areTransfersEqual(tx1, tx2);

    const transactionsMatch =
      addressesMatch &&
      basicTxInfoMatches &&
      coinbaseFeeMatches &&
      feeMatches &&
      historicalPricesMatch &&
      metadataMatches &&
      networkMatches &&
      nonceMatches &&
      pendingTxDataMatches &&
      protocolFeeMatches &&
      transactionTimestampsMatch &&
      transfersMatch;

    return transactionsMatch;
  }

  static fromDMO(dmo: TxOrUserOpDMO): TxOrUserOp {
    let pendingEthTxState: PendingEthTxState | undefined;
    let pendingUTXOTxState: PendingUTXOTxState | undefined;
    if (
      dmo.gasLimit &&
      dmo.maxFeePerGas &&
      dmo.maxPriorityFeePerGas &&
      dmo.baseFeePerGas &&
      dmo.txSubmissionType &&
      dmo.lowerBoundValue &&
      dmo.data
    ) {
      pendingEthTxState = new PendingEthTxState(
        BigInt(dmo.baseFeePerGas),
        Buffer.from(dmo.data),
        BigInt(dmo.gasLimit),
        BigInt(dmo.maxFeePerGas),
        BigInt(dmo.maxPriorityFeePerGas),
        dmo.txSubmissionType,
        dmo.l1GasFee ? BigInt(dmo.l1GasFee) : undefined,
      );
    } else if (dmo.utxos && dmo.txSubmissionType && dmo.baseFeePerGas) {
      pendingUTXOTxState = new PendingUTXOTxState(
        dmo.txSubmissionType,
        BigInt(dmo.baseFeePerGas),
        dmo?.utxos?.map(function parseUtxo(utxo) {
          const [txHash, index] = utxo.split(':');
          return { hash: txHash, index: BigInt(index) };
        }),
      );
    }

    const blockchain = new Blockchain(dmo.blockchainStr);

    const inferredSource = TxOrUserOp.inferTxSource(
      dmo.type as TxFlowOrType,
      dmo.source,
      dmo.state,
      blockchain,
      dmo.confirmedAt,
    );

    return new TxOrUserOp({
      id: dmo.id,
      createdAt: dmo.createdAt,
      confirmedAt: dmo.confirmedAt,
      blockchain,
      currencyCode: new CurrencyCode(dmo.currencyCodeStr),
      feeCurrencyCode: new CurrencyCode(dmo.feeCurrencyCodeStr),
      feeCurrencyDecimal: BigInt(dmo.feeCurrencyDecimal),
      toAddress: dmo.toAddress,
      toDomain: dmo.toDomain,
      fromAddress: dmo.fromAddress,
      fromDomain: dmo.fromDomain,
      amount: BigInt(dmo.amount),
      fee: BigInt(dmo.fee),
      state: dmo.state,
      metadata: TxOrUserOpMetadata.create(dmo.metadataStrArray),
      network: Network.create(dmo.networkStr)!,
      accountId: dmo.accountId,
      walletIndex: BigInt(dmo.walletIndex),
      txOrUserOpHash: dmo.txOrUserOpHash,
      userOpHash: dmo.userOpHash,
      txHash: dmo.txHash,
      isSent: dmo.isSent,
      contractAddress: dmo.contractAddress,
      tokenName: dmo.tokenName,
      tokenDecimal: BigInt(dmo.tokenDecimal),
      walletId: dmo.walletId,
      nonce: dmo.nonce ? BigInt(dmo.nonce) : undefined,
      pendingEthTxData: pendingEthTxState,
      pendingUTXOTxData: pendingUTXOTxState,
      type: dmo.type ? (dmo.type as TxFlowOrType) : undefined,
      transfersStr: dmo.transfersStr,
      deleted: dmo.deleted,
      isSpam: dmo.isSpam,
      primaryAction: dmo.primaryAction ?? undefined,
      toAssetHistoricalPrice: dmo.toAssetHistoricalPrice,
      fromAssetHistoricalPrice: dmo.fromAssetHistoricalPrice,
      nativeAssetHistoricalPrice: dmo.nativeAssetHistoricalPrice,
      fromAssetImage: dmo.fromAssetImage,
      toAssetImage: dmo.toAssetImage,
      fromAmount: dmo.fromAmount,
      toAmount: dmo.toAmount,
      fromProfileImage: dmo.fromProfileImage,
      toProfileImage: dmo.toProfileImage,
      isContractExecution: dmo.isContractExecution,
      toNetwork: dmo.toNetwork ? Network.create(dmo.toNetwork) : undefined,
      toCurrencyCode: dmo.toCurrencyCode ? new CurrencyCode(dmo.toCurrencyCode) : undefined,
      toContractAddress: dmo.toContractAddress,
      toTokenName: dmo.toTokenName,
      toTokenDecimal: dmo.toTokenDecimal ? BigInt(dmo.toTokenDecimal) : undefined,
      coinbaseFeeAmount: dmo.coinbaseFeeAmount,
      coinbaseFeeDecimal: dmo.coinbaseFeeDecimal ? BigInt(dmo.coinbaseFeeDecimal) : undefined,
      coinbaseFeeAssetAddress: dmo.coinbaseFeeAssetAddress,
      coinbaseFeeCurrencyCode: dmo.coinbaseFeeCurrencyCode
        ? new CurrencyCode(dmo.coinbaseFeeCurrencyCode)
        : undefined,
      coinbaseFeeName: dmo.coinbaseFeeName,
      protocolFeeAmount: dmo.protocolFeeAmount,
      protocolFeeDecimal: dmo.protocolFeeDecimal ? BigInt(dmo.protocolFeeDecimal) : undefined,
      protocolFeeCurrencyCode: dmo.protocolFeeCurrencyCode
        ? new CurrencyCode(dmo.protocolFeeCurrencyCode)
        : undefined,
      protocolFeeName: dmo.protocolFeeName,
      toWalletId: dmo.toWalletId,
      fromTokenId: dmo.fromTokenId,
      toTokenId: dmo.toTokenId,
      source: inferredSource,
    } as TxOrUserOpParams);
  }

  get asDMO(): TxOrUserOpDMO {
    const inferredSource = TxOrUserOp.inferTxSource(
      this.type as TxFlowOrType,
      this.source,
      this.state,
      this.blockchain,
      this.confirmedAt,
    );
    return {
      id: this.id,
      createdAt: this.createdAt,
      confirmedAt: this.confirmedAt,
      blockchainStr: this.blockchain.rawValue,
      currencyCodeStr: this.currencyCode.rawValue,
      feeCurrencyCodeStr: this.feeCurrencyCode.rawValue,
      feeCurrencyDecimal: this.feeCurrencyDecimal.toString(),
      toAddress: this.toAddress,
      toDomain: this.toDomain,
      fromAddress: this.fromAddress,
      fromDomain: this.fromDomain,
      amount: this.amount.toString(),
      fee: this.fee.toString(),
      state: this.state,
      metadataStrArray: this.metadata.rawValue,
      networkStr: this.network.rawValue,
      accountId: this.accountId,
      walletIndex: this.walletIndex.toString(),
      userOpHash: this.userOpHash,
      txOrUserOpHash: this.userOpHash ?? this.txHash!,
      txHash: this.txHash,
      isSent: this.isSent,
      contractAddress: this.contractAddress,
      tokenName: this.tokenName,
      tokenDecimal: this.tokenDecimal.toString(),
      nonce: this.nonce?.toString(),
      gasLimit: this.pendingEthTxData?.gasLimit.toString(),
      maxFeePerGas: this.pendingEthTxData?.maxFeePerGas.toString(),
      maxPriorityFeePerGas: this.pendingEthTxData?.maxPriorityFeePerGas.toString(),
      baseFeePerGas:
        this.pendingEthTxData?.baseFeePerGas.toString() ??
        this.pendingUTXOTxData?.feeRate.toString(),
      txSubmissionType:
        this.pendingEthTxData?.txSubmissionType ?? this.pendingUTXOTxData?.txSubmissionType,
      lowerBoundValue: this.pendingEthTxData?.lowerBoundValue.toString(),
      data: this.pendingEthTxData?.data,
      l1GasFee: this.pendingEthTxData?.l1GasFee?.toString(),
      type: this.type,
      transfersStr: this.transfers ? stringify(this.transfers) : undefined,
      walletId: this.walletId,
      deleted: this.deleted ?? false,
      isSpam: this.isSpam,
      utxos: this.pendingUTXOTxData?.utxos?.map(function stringifyUtxo(utxo) {
        return `${utxo.hash}:${utxo.index}`;
      }),
      primaryAction: this.primaryAction,
      toAssetHistoricalPrice: this.toAssetHistoricalPrice,
      fromAssetHistoricalPrice: this.fromAssetHistoricalPrice,
      nativeAssetHistoricalPrice: this.nativeAssetHistoricalPrice,
      fromAssetImage: this.fromAssetImage,
      toAssetImage: this.toAssetImage,
      fromProfileImage: this.fromProfileImage,
      toProfileImage: this.toProfileImage,
      fromAmount: this.fromAmount,
      toAmount: this.toAmount,
      isContractExecution: this.isContractExecution,
      toNetwork: this.toNetwork?.rawValue,
      toCurrencyCode: this.toCurrencyCode?.rawValue,
      toContractAddress: this.toContractAddress,
      toTokenName: this.toTokenName,
      toTokenDecimal: this.toTokenDecimal?.toString(),
      coinbaseFeeAmount: this.coinbaseFeeAmount,
      coinbaseFeeDecimal: this.coinbaseFeeDecimal?.toString(),
      coinbaseFeeAssetAddress: this.coinbaseFeeAssetAddress,
      coinbaseFeeCurrencyCode: this.coinbaseFeeCurrencyCode?.rawValue,
      coinbaseFeeName: this.coinbaseFeeName,
      protocolFeeAmount: this.protocolFeeAmount,
      protocolFeeDecimal: this.protocolFeeDecimal?.toString(),
      protocolFeeCurrencyCode: this.protocolFeeCurrencyCode?.rawValue,
      protocolFeeName: this.protocolFeeName,
      toWalletId: this.toWalletId,
      fromTokenId: this.fromTokenId,
      toTokenId: this.toTokenId,
      source: this.source ?? inferredSource,
    };
  }

  /**
   * inferTxSource is used to infer the source of a transaction object. This is used to distinguish between
   * the different tx history api sources we have. Transaction records that are already in the database do not
   * have a source value, so we need to infer it based on certain attributes of the transaction object.
   * If a txn is in a pending state then we know it was created by the client and not the server. So we can
   * keep the source as undefined.
   * If a txn is not in a pending state then we know it was created by the server. So we can set the source
   * depending on certain attributes of existing transaction objects.
   * If a txn has a type then we know it was from our V2 api, so we can set the source to V2.
   * If a txn doesn't have a type then we know it was fetched from etherscan, so we can set the source to etherscan.
   * If a txn is a UTXO txn then we label it as v2 since we only have one source of tx history for UTXO txns.
   */
  static inferTxSource(
    type: TxFlowOrType | undefined,
    source: TxSource | undefined,
    txState: TxState,
    blockchain: Blockchain,
    confirmedAt?: Date,
  ): TxSource | undefined {
    if (source) return source;

    const isConfirmedAndNotPending = confirmedAt && txState.valueOf() !== TxState.PENDING.valueOf();

    switch (true) {
      case !isConfirmedAndNotPending:
        return undefined;
      case isUTXOBasedBlockchain(blockchain.rawValue):
        return 'V2';
      case !type:
        return 'ETHERSCAN';
      default:
        return 'V2';
    }
  }

  get sponsoredTxId(): string | undefined {
    if (!this.isSponsored()) return undefined;

    return this.metadata.get(TxOrUserOpMetadataKey_sponsoredUuid) as string;
  }

  get gaslessSwapHash(): string | undefined {
    if (!this.isGaslessSwap()) return undefined;

    return this.metadata.get(TxOrUserOpMetadataKey_gaslessSwapHash) as string;
  }

  /**
   * Renders the appropriate recipient or sender display name based on tx
   *
   * @param truncateIfNeeded: Truncates the recipient address if an address is returned
   * @param replaceAddressWithDomain: Replaces the address with the domain (i.e. vitalik.eth) if available
   *
   * @return The recipient or sender display name presented to the UI
   */
  entityDisplayName(truncateIfNeeded: boolean, replaceAddressWithDomain = true): string {
    if (this.isTronReceiveTx && this.tronFromAddress) {
      return truncateIfNeeded ? getTruncatedAddress(this.tronFromAddress) : this.tronFromAddress;
    }

    if (this.isCashout() && this.metadata.has(TxOrUserOpMetadataKey_cashoutWithdrawalType)) {
      return getCashoutEntityName(this.metadata);
    }
    if (this.isSent) {
      if (replaceAddressWithDomain && this.toDomain) {
        return this.toDomain;
      }
      if (this.toAddress) {
        return truncateIfNeeded ? getTruncatedAddress(this.toAddress) : this.toAddress;
      }
      return '';
    }

    if (this.isConsumerTransfer()) {
      return 'Coinbase.com';
    }

    if (this.fromDomain) {
      return this.fromDomain;
    }

    const fromAddressLocal = this.fromAddress ?? '';

    if (truncateIfNeeded) {
      return getTruncatedAddress(fromAddressLocal);
    }

    const inputCount = this.metadata.get(TxOrUserOpMetadataKey_inputCount);
    const sourceAddress = this.fromAddress;

    if (typeof inputCount === 'number' && inputCount > 1 && sourceAddress) {
      return `${sourceAddress} and ${inputCount - 1}`; // TODO: localization
    }

    return fromAddressLocal;
  }

  /**
   * Indicates that txHistoryService marked this transaction as
   * paid by one of Coinbase's paymaster wallets
   */
  isSponsored(): boolean {
    return (
      this.type === 'SPONSORED' ||
      this.metadata.has(TxOrUserOpMetadataKey_sponsoredUuid) ||
      this.metadata.has(TxOrUserOpMetadataKey_isSponsoredTxn)
    );
  }

  get isTronReceiveTx(): boolean {
    return this.metadata.get(TxOrUserOpMetadataKey_isReceiveTronTx) === true;
  }

  get tronTxStatus(): UnsupportedTronTransactionStatus {
    return this.metadata.get(
      TxOrUserOpMetadataKey_tronTxStatus,
    ) as UnsupportedTronTransactionStatus;
  }

  get tronFromAddress(): string {
    return (this.metadata.get(TxOrUserOpMetadataKey_tronFromAddress) as string) ?? '';
  }

  get tronTxHash(): string {
    return (this.metadata.get(TxOrUserOpMetadataKey_tronTransactionHash) as string) ?? '';
  }

  get tronDestinationTransactionHash(): string {
    return (
      (this.metadata.get(TxOrUserOpMetadataKey_tronDestinationTransactionHash) as string) ?? ''
    );
  }

  /**
   * The link to the Bridge.xyz KYC flow related to the tron transaction
   *
   * Note: We need to decode it because the metadata properties are stored & parsed based on /;
   * with that, we get broken values in case it contains a link that's not encoded
   */
  get tronTxProviderKycLink(): string {
    const kycLink =
      (this.metadata.get(TxOrUserOpMetadataKey_tronTxProviderKycLink) as string) ?? '';

    return decodeURIComponent(kycLink);
  }

  /**
   * The link to the tron transaction receipt
   *
   * Note: We need to decode it because the metadata properties are stored & parsed based on /;
   * with that, we get broken values in case it contains a link that's not encoded
   */
  get tronReceiptLink(): string {
    const receiptLink = (this.metadata.get(TxOrUserOpMetadataKey_tronReceiptLink) as string) ?? '';

    return decodeURIComponent(receiptLink);
  }

  /**
   * the name of the flow responsible for creating the transaction
   */
  get txSource(): string {
    return (this.metadata.get(TxOrUserOpMetadataKey_txSource) as string) ?? '';
  }

  /**
   * Indicates that the transaction was soft deleted.
   * This is done to remove certain ephemeral transactions from the tx history whilst
   * avoiding side effects of completely removing the object from recoil or db state.
   *
   * Mostly used for showing gasless swaps and sponsored sends on the tx history screen,
   * while the relayers do their jobs.
   */
  isSoftDeleted(): boolean {
    return Boolean(this.deleted);
  }

  /**
   * Indicates that a sponsored transaction was successfully relayed:
   * - It was submitted to the blockchain by the Payout service
   * - It has finality, from the point of view of our indexers
   * - It does not mean that the transaction was successful,
   *   only that the relayer has complete its job and the TxHistoryService
   *   takes over from here to sync the correct state.
   */
  isRelayed(): boolean {
    return (this.isSponsored() || this.isGaslessSwap()) && this.isSoftDeleted();
  }

  isConsumerTransfer(): boolean {
    return this.metadata.has(TxOrUserOpMetadataKey_consumerTxID);
  }

  isCashout(): boolean {
    return (
      this.type === 'CASHOUT' ||
      this.metadata.has(TxOrUserOpMetadataKey_cashoutIdem) ||
      this.primaryAction === 'CASHOUT'
    );
  }

  isNFTTransfer(): boolean {
    return this.tokenDecimal === 0n;
  }

  isGaslessSwap(): boolean {
    return (
      this.type === 'GASLESS' ||
      this.metadata.has(TxOrUserOpMetadataKey_gaslessSwapHash) ||
      (this.primaryAction === 'SWAP' && this.metadata.has(TxOrUserOpMetadataKey_isGaslessTxn))
    );
  }

  isCBStakeTX(): boolean {
    return this.metadata.has(TxOrUserOpMetadataKey_isCBStakeTxn);
  }

  isNFTTransaction(): boolean {
    return (
      this.metadata.get(TxOrUserOpMetadataKey_assetType) === AssetTypeNFT &&
      Boolean(this.toTokenId || this.fromTokenId)
    );
  }

  isFungibleTokenTransaction(): boolean {
    return this.metadata.get(TxOrUserOpMetadataKey_assetType) === AssetTypeFungibleTkn;
  }

  getPendingTxMetadata(): TxPendingMetadata {
    const tokenName = this.metadata.get(TxOrUserOpMetadataKey_toTokenName)?.toString() || '';
    const currencyCodeStr = this.metadata
      .get(TxOrUserOpMetadataKey_toTokenCurrencyCode)
      ?.toString();
    const tokenCurrencyCode = new CurrencyCode(currencyCodeStr || this.blockchain.rawValue);
    const tokenDecimal = BigInt(this.metadata.get(TxOrUserOpMetadataKey_toTokenDecimal) || '0');
    const tokenImage = (
      this.metadata.get(TxOrUserOpMetadataKey_toTokenImage)?.toString() || ''
    ).replaceAll('~', `/`);
    const cryptoAmount = BigInt(this.metadata.get(TxOrUserOpMetadataKey_toTokenAmount) || '0');

    let txType: TxPrimaryAction;
    switch (this.metadata.get(TxOrUserOpMetadataKey_txSource)) {
      case 'dex':
        txType = 'SWAP';
        break;
      case 'stake':
        txType = 'STAKE';
        break;
      case 'bridge':
        txType = 'BRIDGE';
        break;
      case 'cashout':
        txType = 'CASHOUT';
        break;
      default:
        txType = 'SEND';
    }

    // If a transaction (excluding tron transactions) doens't have enough metadata to populate the pending
    // transaction details, we mark it as unknown so that it doesn't show up in the pending transactions list.
    // We don't need this check for SEND since the token meta data is used for the toToken metadata only.
    if (!this.isTronReceiveTx && txType !== 'SEND' && (!tokenName || !cryptoAmount)) {
      txType = 'UNKNOWN';
    }

    // Considering that the app doesn't support Tron txs yet, it means that if it is a tron transaction,
    // it's coming from the bridge.xyz integration (USDT → USDC briding) and we can safely assume that it
    // is a receive transaction.
    if (this.isTronReceiveTx) {
      txType = 'RECEIVE';
    }

    return {
      txType,
      tokenName,
      tokenCurrencyCode,
      tokenDecimal,
      tokenImage,
      cryptoAmount,
    };
  }

  /**
   * Create a new pending outgoing transaction instance using SignedTx and UnsignedTx protocols
   *
   * @param unsignedTxOrUserOp Unsigned tx details used to create the transaction
   * @param signedTxHash hash of the signed transaction returned from wallet link
   * @param nonce The transaction nonce
   * @param wallet the crypto wallet representing a currency that the tx was sent in
   * @param txSubmissionType the type of transaction being submitted (i.e. speed-up, cancel, or original)
   *
   * @return New instance of outgoing [Transaction]
   */
  static async createSubmittedTx(
    unsignedTxOrUserOp: UnsignedTxOrUserOp,
    signedTxHash: string,
    nonce: bigint | undefined,
    wallet: Wallet,
    txSubmissionType: TxSubmissionType = 'original',
  ): Promise<TxOrUserOp> {
    const metadataMap = new Map(unsignedTxOrUserOp.metadata);
    metadataMap.set(TxOrUserOpMetadataKey_hasRecordedConfirmEvent, false);

    return unsignedTxOrUserOp.asTransaction(
      signedTxHash,
      nonce,
      wallet,
      metadataMap,
      txSubmissionType,
    );
  }

  /**
   * Create a new pending outgoing relayed transaction.
   * @param unsignedTx Unsigned tx details used to create the transaction
   * @param metadataToken Token returned from the tx relayer (payout service or 0x relayer) for keeping track of the relay
   * @param nonce The transaction nonce
   * @param wallet the crypto wallet representing a currency that the tx was sent in
   * @param txSubmissionType the type of transaction being submitted (i.e. speed-up, cancel, or original)
   * @param context the type of transaction being submitted (i.e. speed-up, cancel, or original)
   *
   * @returns New instance of outgoing relayed [Transaction]
   */
  static async createRelayedTx(
    unsignedTxOrUserOp: UnsignedTxOrUserOp,
    metadataToken: string,
    nonce: bigint | undefined,
    wallet: Wallet,
    txSubmissionType: TxSubmissionType = 'original',
    context = TxRelayType.SEND,
  ): Promise<TxOrUserOp> {
    const metadataMap = new Map(unsignedTxOrUserOp.metadata);
    const metadataKey =
      context === TxRelayType.SEND
        ? TxOrUserOpMetadataKey_sponsoredUuid
        : TxOrUserOpMetadataKey_gaslessSwapHash;
    metadataMap.set(metadataKey, metadataToken);

    // we overload txHash field to also store metadataToken. without doing this - transactions with empty txHash would be
    // rejected on insertion for lack of satisfying uniqueness index constraint. the alternative would be to update the
    // uniquness constraint to reference a new `relayToken` column, but that's a risky change so we overload instead.
    return unsignedTxOrUserOp.asTransaction(
      metadataToken,
      nonce,
      wallet,
      metadataMap,
      txSubmissionType,
      TxRelayType.SEND ? 'SPONSORED' : 'GASLESS',
    );
  }
}

import { TxOrUserOpMetadata } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOpMetadata';
import { buildMetadataForTronTx } from 'cb-wallet-data/stores/Transactions/utils/buildMetadataForTronTx';
import { V1PrimaryAction } from '@cbhq/instant-api-hooks-wallet-tx-history/types';

type Params = {
  primaryAction?: V1PrimaryAction;
  existingMetadata: TxOrUserOpMetadata;
};

/**
 * Function that receives transaction metadata as param and returns it enhanced with
 * some additional Tron Bridge metadata if it's a Bridge.xyz Tron transaction.
 */
export function enhanceWithTronBridgeMetadata({ primaryAction, existingMetadata }: Params) {
  if (primaryAction?.bridgexyzDetails) {
    const { originFromAddress, bridgexyzReceiptLink, originTransactionHash } =
      primaryAction.bridgexyzDetails;

    const tronTxOrUserOpMetadataMap = buildMetadataForTronTx({
      // "success" is not a status returned by Bridge.xyz, but we consider a transaction as "success"
      // when it gets returned by the TxHistory service (i.e. it's confirmed on chain)
      status: 'success',
      originFromAddress,
      originTransactionHash,
      tronReceiptLink: bridgexyzReceiptLink,
    });

    return existingMetadata.mergeMetadata(new TxOrUserOpMetadata(tronTxOrUserOpMetadataMap));
  }

  return existingMetadata;
}

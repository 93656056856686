import Decimal from 'decimal.js';

import { useDefiEarnPortfolioAssets } from './useDefiEarnPortfolioAssets';
import { useEarnPortfolioAssetsAggregateBalance } from './useEarnPortfolioAssetsAggregateBalance';

export type Params = {
  enabled?: boolean;
};

export function useFetchEarnProtocolPositionsAggregateBalance({ enabled }: Params) {
  const { earnPortfolioAssets } = useDefiEarnPortfolioAssets({
    enabled,
  });

  const aggregateBalance = useEarnPortfolioAssetsAggregateBalance({
    portfolioAssets: earnPortfolioAssets,
  });

  if (!enabled) return new Decimal(0);

  return aggregateBalance;
}

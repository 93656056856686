/* eslint-disable @cbhq/react-no-untranslated-string */
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { Button } from 'wallet-cds-web/components/Button';
import { HeroSquare } from '@cbhq/cds-web/illustrations';
import { VStack } from '@cbhq/cds-web/layout';
import { ThemeProvider } from '@cbhq/cds-web/system';
import { TextTitle2 } from '@cbhq/cds-web/typography';

export function RootErrorFallback() {
  const router = useRouter();
  const handleRefresh = useCallback(() => {
    router.reload();
  }, [router]);

  return (
    <ThemeProvider spectrum="dark">
      <VStack height="100vh" justifyContent="center" alignItems="center" gap={3}>
        <HeroSquare name="errorRefresh" />
        <TextTitle2 as="h2" spacingTop={5} spacingBottom={2}>
          Something went wrong
        </TextTitle2>
        <Button onPress={handleRefresh}>Refresh</Button>
      </VStack>
    </ThemeProvider>
  );
}

import { useEffect, useMemo, useRef } from 'react';
import { refreshAllTransactions } from 'cb-wallet-data/AddressHistory/utils/refreshAllTransactions';
import { useRouter } from 'next/router';

import { SKIP_TX_REFRESH_ROUTES } from ':dapp/utils/RoutesEnum';

// For certain promotional pages we don't want to kick off a full tx refresh
export function useSkipTransactionRefresh() {
  const router = useRouter();
  const lastRoute = useRef('');
  const navigationCount = useRef(1);
  const skipTransactionRefresh = useMemo(() => {
    const routeMatch = SKIP_TX_REFRESH_ROUTES.find((route) => router.pathname.includes(route));
    return Boolean(routeMatch);
  }, [router.pathname]);

  useEffect(
    function onRouteChange() {
      const txSkippedForLastRoute = SKIP_TX_REFRESH_ROUTES.find((route) =>
        lastRoute.current.includes(route),
      );

      // If the last route was a skip route and the user has navigated, refresh the transactions
      // but only if the user has navigated once
      if (Boolean(txSkippedForLastRoute) && navigationCount.current <= 2) {
        refreshAllTransactions();
      }

      lastRoute.current = router.pathname;
      navigationCount.current += 1;
    },
    [router.pathname],
  );

  return skipTransactionRefresh;
}

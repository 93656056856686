import { useCallback, useMemo } from 'react';
import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { useRecoilValue } from 'recoil';

import { Account } from '../models/Account';
import { accountsAtom } from '../state';

/**
 * Returns a callback that searches for a single account within the list of accounts in Recoil by a given account ID.
 */
export function useGetAccountById(): (accountId: string) => Account | undefined {
  const accounts = useRecoilValue(accountsAtom);

  return useCallback(
    function getAccountById(accountId) {
      return accounts.find((account) => account.id === accountId);
    },
    [accounts],
  );
}

const NO_ACCOUNT_FOUND = new Error('No Account found for ID in useAccountById');

export function useSafeAccountById(accountId: string): Account | undefined {
  const accounts = useRecoilValue(accountsAtom);

  return useMemo(() => {
    return accounts.find((acct) => acct.id === accountId);
  }, [accounts, accountId]);
}

export function useAccountById(accountId?: string): Account {
  const accounts = useRecoilValue(accountsAtom);

  const account = accounts.find((acct) => acct.id === accountId);

  if (!account) {
    cbReportError({
      context: 'multi-account',
      error: NO_ACCOUNT_FOUND,
      isHandled: false,
      severity: 'error',
    });
    throw NO_ACCOUNT_FOUND;
  }

  return account;
}

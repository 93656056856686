import { useMutation } from '@tanstack/react-query';

import { GAME_ID, GAMIFICATION_URL } from './config';

type Params = {
  walletAddress: string;
  challengeId: string;
};

export type ChallengeCompleteTypeError = { success?: boolean; message?: string };

export function useMutateChallengeComplete(handleError?: (err?: Error) => void) {
  const { mutate, isLoading, error, data } = useMutation({
    mutationFn: async function mutateChallengeComplete({ walletAddress, challengeId }: Params) {
      const response = await fetch(`${GAMIFICATION_URL}/challenges/complete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          gameId: GAME_ID,
          userAddress: walletAddress,
          challengeId,
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to complete challenge');
      }
      return response.json();
    },
    onError: /* istanbul ignore next */ function handleOnError(err?: Error) {
      if (handleError) {
        handleError(err);
      }
    },
  });

  return {
    completeChallenge: mutate,
    isLoading,
    error,
    data,
  };
}

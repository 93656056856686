import { useEffect } from 'react';
import { setDappMetadata } from 'cb-wallet-analytics/utils/log';
import { useAccounts } from 'cb-wallet-data/stores/Accounts/hooks/useAccounts';

import { useGlobalEventMetadataContext } from ':dapp/providers/GlobalEventsProvider';

/**
 * useSetAmplitudeAccountMetadata
 *
 * For Amplitude events, this adds metadata in order to better understand
 *   1. How many accounts
 *   2. The user id selected for the current flow, from account `dappProviderUserId`
 *
 */
export function useSetAmplitudeGlobalEventProperties() {
  const accounts = useAccounts();
  const accountsLength = accounts.length;
  const { dappProviderUserId = 'N/A' } = useGlobalEventMetadataContext();

  useEffect(
    // When the dependencies change, this updates the Amplitude account metadata
    function setAmplitudeMetadataForActiveAccount() {
      setDappMetadata({
        accountsLength,
        dappProviderUserId,
      });
    },
    [accountsLength, dappProviderUserId],
  );
}

import { Transfer } from 'cb-wallet-data/stores/Transactions/models/Transfer';
import {
  TxFlowOrType,
  TxPrimaryAction,
} from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import { TxOrUserOpMetadata } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOpMetadata';
import {
  AssetTypeFungibleTkn,
  AssetTypeNFT,
  TxOrUserOpMetadataKey,
  TxOrUserOpMetadataKey_assetType,
  TxOrUserOpMetadataKey_isCBStakeTxn,
  TxOrUserOpMetadataKey_isGaslessTxn,
  TxOrUserOpMetadataKey_isSponsoredTxn,
  TxOrUserOpMetadataValue,
} from 'cb-wallet-data/stores/Transactions/models/TxOrUserOpMetadataKey';
import { v4 as uuidv4 } from 'uuid';
import {
  Apiv1Label,
  V1AddressMeta,
  V1PrimaryAction,
  V1SpamScoreThresholds,
  V1Transfer,
} from '@cbhq/instant-api-hooks-wallet-tx-history/types';

import { BASE_ASSET_ADDRESS } from '../HRT/constants';

/**
 * buildHRTMetadata constructs a TxOrUserOpMetadata object from the given HRT metadata
 */
export function buildHRTMetadata(
  addressMetadatas: V1AddressMeta[],
  metadata?: Record<string, string>,
): TxOrUserOpMetadata {
  const metadataMap: Map<TxOrUserOpMetadataKey, TxOrUserOpMetadataValue> = new Map();

  // Construct metadata for the type of asset in the transaction (NFT or FUNGIBLE_TKN)
  let containsNFT = false;
  for (const AddressMeta of addressMetadatas) {
    if (AddressMeta?.nftCollection) {
      containsNFT = true;
      break;
    }
  }

  if (containsNFT) {
    metadataMap.set(TxOrUserOpMetadataKey_assetType, AssetTypeNFT);
  } else {
    metadataMap.set(TxOrUserOpMetadataKey_assetType, AssetTypeFungibleTkn);
  }

  if (!metadata) {
    return new TxOrUserOpMetadata(metadataMap);
  }

  // Construct metadata for Gasless and Sponsored transactions
  for (const key of Object.keys(metadata)) {
    switch (key) {
      case 'GASLESS':
        metadataMap.set(TxOrUserOpMetadataKey_isGaslessTxn, metadata[key].toLowerCase() === 'true');
        break;
      case 'SPONSORED':
        metadataMap.set(
          TxOrUserOpMetadataKey_isSponsoredTxn,
          metadata[key].toLowerCase() === 'true',
        );
        break;
      case 'CBSTAKE':
        metadataMap.set(TxOrUserOpMetadataKey_isCBStakeTxn, metadata[key].toLowerCase() === 'true');
        break;
      default:
        break;
    }
  }

  return new TxOrUserOpMetadata(metadataMap);
}

/**
 * apiv1LabelToPrimaryAction converts a raw HRT primary action label to a TxPrimaryAction type used in the database
 */
export function apiv1LabelToPrimaryAction(apiv1Label?: Apiv1Label): TxPrimaryAction {
  if (!apiv1Label) {
    return 'UNKNOWN';
  }

  return apiv1Label.replace('LABEL_', '') as TxPrimaryAction;
}

/**
 * primaryActionToType converts a HRT primary action label (v3) to a legacy transaction type (v2) for backwards compatibility
 */
export function primaryActionToType({
  primaryActionLabel,
  txMetadata,
}: {
  primaryActionLabel?: Apiv1Label;
  txMetadata: TxOrUserOpMetadata;
}): TxFlowOrType {
  if (!primaryActionLabel) {
    return 'SEND';
  }

  if (txMetadata.get(TxOrUserOpMetadataKey_isGaslessTxn) === true) {
    return 'GASLESS';
  }
  if (txMetadata.get(TxOrUserOpMetadataKey_isSponsoredTxn) === true) {
    return 'SPONSORED';
  }

  switch (primaryActionLabel) {
    case Apiv1Label.LABEL_SEND:
      return 'SEND';
    case Apiv1Label.LABEL_RECEIVE:
      return 'RECEIVE';
    case Apiv1Label.LABEL_SWAP:
      return 'SWAP';
    case Apiv1Label.LABEL_BRIDGE_IN:
      return 'RECEIVE';
    case Apiv1Label.LABEL_BRIDGE_OUT:
      return 'SEND';
    case Apiv1Label.LABEL_MINT:
      return 'MINT';
    case Apiv1Label.LABEL_APPROVE:
      return 'APPROVE';
    case Apiv1Label.LABEL_WRAP:
    case Apiv1Label.LABEL_UNWRAP:
      return 'RECEIVE';
    case Apiv1Label.LABEL_CONTRACT_EXECUTION:
    case Apiv1Label.LABEL_UNKNOWN:
      return 'SEND';
    default:
      return 'SEND';
  }
}

/**
 * isSpam returns true if any of the given spamScores exceeds the spam threshold.
 * If any token from a given transaction is a spam token we will mark the entire transaction as spam
 */
export function isSpam(
  spamScoreThresholds?: V1SpamScoreThresholds,
  spamScores?: number[],
): boolean | undefined {
  return spamScores?.some((spamScore) => {
    return (
      spamScore !== undefined &&
      spamScoreThresholds?.likelySpam !== undefined &&
      spamScore >= spamScoreThresholds?.likelySpam
    );
  });
}

/**
 * isSend returns true if the given type involves sending crypto out of the user's wallet
 */
export function isSend(type: Apiv1Label): boolean {
  return (
    type === Apiv1Label.LABEL_SEND ||
    type === Apiv1Label.LABEL_SWAP ||
    type === Apiv1Label.LABEL_BRIDGE_OUT ||
    type === Apiv1Label.LABEL_WRAP ||
    type === Apiv1Label.LABEL_UNWRAP
  );
}

/**
 * hrtTransfersToTransfer converts an array of HRT transfers to an array of Transfer objects
 */
export function hrtTransfersToTransfer(
  hrtTransfers: V1Transfer[],
  addressMetadata: Record<string, V1AddressMeta>,
): Transfer[] {
  return hrtTransfers.map(function parseHRTTransfers(transfer: V1Transfer): Transfer {
    const fromDomain = addressMetadata[transfer.fromAddress || '']?.domain;
    const toDomain = addressMetadata[transfer.toAddress || '']?.domain;
    const assetMetadata = addressMetadata[transfer.assetAddress || ''];

    let tokenType;
    let symbol;
    let name;
    let decimals;
    let tokenId;
    if (assetMetadata?.token || transfer.assetAddress === BASE_ASSET_ADDRESS) {
      tokenType = AssetTypeFungibleTkn;
      symbol = assetMetadata?.token?.symbol;
      name = assetMetadata?.token?.name;
      decimals = assetMetadata?.token?.decimals;
      tokenId = undefined;
    } else if (assetMetadata?.nftCollection) {
      tokenType = AssetTypeNFT;
      symbol = ''; // Transfer has a fallback for NFTs with no name. HRT does not return a symbol for NFTs
      name = assetMetadata?.nftCollection?.collectionName;
      decimals = 0;
      tokenId = transfer?.tokenId;
    } else {
      tokenType = 'UNKNOWN';
    }

    return new Transfer(
      transfer.fromAddress || '',
      fromDomain || '',
      transfer.toAddress || '',
      toDomain || '',
      BigInt(transfer.amount || 0),
      symbol || '',
      transfer.assetAddress || '',
      name || '',
      BigInt(decimals || 0),
      tokenType,
      assetMetadata?.token?.spamScore,
      tokenId,
    );
  });
}

/**
 * findRelevantTransfer returns the transfer that is most relevant to the user for a given transaction
 */
export function findRelevantTransfer(
  type: Apiv1Label,
  transfers: Transfer[],
  userAddress: string,
): Transfer | undefined {
  let transferToReturn: Transfer | undefined;

  for (const transfer of transfers) {
    if (!transferToReturn) {
      transferToReturn = transfer;
    } else {
      switch (type) {
        case Apiv1Label.LABEL_RECEIVE:
        case Apiv1Label.LABEL_MINT:
          // For receive and mint we are choosing the receive tx with the highest amount where the receiver is the user
          transferToReturn =
            transfer.amount > transferToReturn.amount &&
            transfer.toAddress.toLowerCase() === userAddress.toLowerCase()
              ? transfer
              : transferToReturn;
          break;
        default:
          // For all other tx types we are choosing the transfer with the highest amount where the sender is the user
          transferToReturn =
            transfer.amount > transferToReturn.amount &&
            transfer.fromAddress.toLowerCase() === userAddress.toLowerCase()
              ? transfer
              : transferToReturn;
          break;
      }
    }
  }

  return transferToReturn;
}

/**
 * hasMissingDecimals returns true if any of the given tokens have a decimal value of -1.
 * From HRT, this decimal value indicates an unknown decimal value. Without a decimal value we cannot accurately
 * calculate the amount of a transaction to display to the user.
 */
export function hasMissingDecimals(metadatas: V1AddressMeta[]): boolean {
  return metadatas?.some((metadata) => {
    return metadata?.token?.decimals === -1;
  });
}

/**
 * getTransactionId returns the tron transaction hash (originTransactionHash) in case it is
 * a tron transaction (bridge.xyz) and uuidv4() otherwise.
 */
export function getTransactionId(primaryAction: V1PrimaryAction) {
  const { bridgexyzDetails } = primaryAction ?? {};

  return bridgexyzDetails?.originTransactionHash || uuidv4();
}

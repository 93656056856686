import { parse, stringify } from 'cb-wallet-store/utils/serialization';

import {
  DehydratedQuery,
  PersistedClient,
  PersistedClientMetadata,
  PersistorSync,
} from './persistQueryClient';
import { ScopedLocalStorage } from './ScopedLocalStorage';

export const METADATA_KEY = 'rq_metadata';
export const KEY_PREFIX = 'rq_data';

function localStorageGetItemJSON<DataT = unknown>(key: string): DataT | undefined {
  // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
  if (typeof window === 'undefined' || typeof window.localStorage === 'undefined') {
    return undefined;
  }

  const itemString = localStorage.getItem(key);
  if (itemString === null) {
    return undefined;
  }
  return parse(itemString);
}

function localStorageSetItemJSON(key: string, value: Record<string, unknown>): void {
  // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
  if (typeof window === 'undefined' || typeof window.localStorage === 'undefined') {
    return undefined;
  }

  localStorage.setItem(key, stringify(value));
}

/**
 * Persistor using localStorage.
 */
export class LocalStoragePersistor implements PersistorSync {
  _storage = new ScopedLocalStorage(KEY_PREFIX);

  persistQuery(query: DehydratedQuery): void {
    this._storageSetJSON(query.queryHash, query);
  }

  removeQuery(queryHash: string): void {
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    if (typeof window === 'undefined' || typeof window.localStorage === 'undefined') {
      return undefined;
    }

    this._storage.removeItem(queryHash);
  }

  restoreClient(): PersistedClient {
    const queries = this._storage.getAllItems().map((item) => parse<DehydratedQuery>(item));
    const metadata = localStorageGetItemJSON<PersistedClientMetadata>(METADATA_KEY);
    return {
      metadata,
      clientState: {
        queries,
        mutations: [],
      },
    };
  }

  removeClient(): void {
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    if (typeof window === 'undefined' || typeof window.localStorage === 'undefined') {
      return undefined;
    }

    this._storage.clear();
  }

  persistMetadata(metadata: PersistedClientMetadata): void {
    localStorageSetItemJSON(METADATA_KEY, metadata);
  }

  _storageGetJSON<DataT = unknown>(key: string): DataT | undefined {
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    if (typeof window === 'undefined' || typeof window.localStorage === 'undefined') {
      return undefined;
    }

    const itemString = this._storage.getItem(key);
    if (!itemString) {
      return undefined;
    }
    return parse(itemString);
  }

  _storageSetJSON(key: string, value: Record<string, unknown>): void {
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    if (typeof window === 'undefined' || typeof window.localStorage === 'undefined') {
      return undefined;
    }

    this._storage.setItem(key, stringify(value));
  }
}

/**
 * Represents relay tx context types
 */
export enum TxRelayType {
  /**
   * Sponsored sends
   */
  SEND = 'send',

  /**
   * Gasless swaps
   */
  SWAP = 'swap',
}

import bitcoin from 'bitcoinjs-lib';

import { getNetwork } from './getNetwork';
import { UTXOChains } from './indexer';

export function validateAddress(
  blockchainSymbol: UTXOChains,
  address: string,
  testnet: boolean,
): string {
  const network = getNetwork(blockchainSymbol, testnet);
  try {
    bitcoin.address.toOutputScript(address, network);
  } catch {
    throw new Error(`invalid address: ${address}`);
  }
  return address;
}

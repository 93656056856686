import bitcoin from 'bitcoinjs-lib';

import { validateAddress } from '../../blockchains/UTXO/addressValidation';
import { getNetwork } from '../../blockchains/UTXO/getNetwork';
import { isBech32Address } from '../../blockchains/UTXO/isBech32Address';
import {
  BitcoinServiceInterface,
  CoinSelection,
  SignedTransaction,
} from '../generated/BitcoinService.generated';

import { BTCLikeService } from './BTCLikeService';

export class BitcoinService extends BTCLikeService implements BitcoinServiceInterface {
  async signTransactionAsync(
    publicKeysAndPaths: Record<string, { publicKey: Buffer; derivationPath: string }>,
    signMessage: (derivationPath: string, message: Buffer) => Promise<Buffer>,
    coinSelection: CoinSelection,
    testnet: boolean,
  ): Promise<SignedTransaction> {
    const { inputs, outputs } = coinSelection;
    const network = getNetwork(this.blockchainSymbol, testnet);
    const isSegwitMap: Record<string, boolean> = {};
    const txb = new bitcoin.TransactionBuilder(network);

    for (const input of inputs) {
      const { address } = input;
      validateAddress({
        blockchainSymbol: this.blockchainSymbol,
        address,
        testnet,
      });

      const isSegwit = isBech32Address(address, network);
      isSegwitMap[address] = isSegwit;

      // used to allow BIP 125 transaction replacement
      const sequence = 0xfffffffe;

      if (isSegwit) {
        txb.addInput(input.hash, input.index, sequence, input.script);
      } else {
        txb.addInput(input.hash, input.index, sequence);
      }
    }

    for (const output of outputs) {
      const { address } = output;
      validateAddress({
        blockchainSymbol: this.blockchainSymbol,
        address,
        testnet,
      });
      txb.addOutput(address, output.value.toNumber());
    }

    // TODO @spencer convert this is Promise.all or for await of
    const signInputs = async () => {
      // eslint-disable-next-line
      for (let i = 0; i < inputs.length; i++) {
        const input = inputs[i];
        const { publicKey, derivationPath } = publicKeysAndPaths[input.address];

        if (!Buffer.isBuffer(publicKey)) {
          throw new Error(`public key for ${input.address} not found`);
        }

        if (isSegwitMap[input.address]) {
          // eslint-disable-next-line
          await txb.signAsync(
            i,
            publicKey,
            derivationPath,
            signMessage,
            undefined,
            undefined,
            input.value.toNumber(),
          );
        } else {
          // eslint-disable-next-line
          await txb.signAsync(i, publicKey, derivationPath, signMessage);
        }
      }
    };
    await signInputs();

    const tx = txb.build();

    return {
      data: tx.toBuffer(),
      hash: Buffer.from(tx.getHash().reverse()).toString('hex'),
    };
  }

  protected sign(
    privateKeys: Record<string, Buffer>,
    coinSelection: CoinSelection,
    testnet: boolean,
  ): SignedTransaction {
    const { inputs, outputs } = coinSelection;
    const isSegwitMap: Record<string, boolean> = {};
    const network = getNetwork(this.blockchainSymbol, testnet);
    const txb = new bitcoin.TransactionBuilder(network);

    for (const input of inputs) {
      const { address } = input;
      validateAddress({
        blockchainSymbol: this.blockchainSymbol,
        address,
        testnet,
      });

      const isSegwit = isBech32Address(address, network);
      isSegwitMap[address] = isSegwit;

      if (isSegwit) {
        txb.addInput(input.hash, input.index, 4294967293, input.script);
      } else {
        txb.addInput(input.hash, input.index, 4294967293);
      }
    }

    for (const output of outputs) {
      const { address } = output;
      validateAddress({
        blockchainSymbol: this.blockchainSymbol,
        address,
        testnet,
      });
      txb.addOutput(address, output.value.toNumber());
    }

    inputs.forEach((input, i) => {
      const privateKey: Buffer | undefined = privateKeys[input.address];

      if (!Buffer.isBuffer(privateKey)) {
        throw new Error(`private key for ${input.address} not found`);
      }

      const ecPair = bitcoin.ECPair.fromPrivateKey(privateKey, { network });

      if (isSegwitMap[input.address]) {
        txb.sign(i, ecPair, undefined, undefined, input.value.toNumber());
      } else {
        txb.sign(i, ecPair);
      }
    });

    const tx = txb.build();

    return {
      data: tx.toBuffer(),
      hash: Buffer.from(tx.getHash().reverse()).toString('hex'),
    };
  }
}

import { useMemo } from 'react';
import flatten from 'lodash/flatten';
import { useRecoilValue } from 'recoil';

import { visiblePortfolioWalletsByWalletGroupSelector } from '../state';

export function useVisiblePortfolioWallets() {
  const visiblePortfolioWalletsByWalletGroup = useRecoilValue(
    visiblePortfolioWalletsByWalletGroupSelector,
  );

  return useMemo(() => {
    const portfolioWalletArrays = Array.from(visiblePortfolioWalletsByWalletGroup.values());

    // Maintain logic from useBasePortfolioWalletsByWalletGroup which will explicitly
    // return undefined before portfolio wallets state is ready to be derived; this allows
    // components consuming this hook to utilize that logic for loading states.
    if (!portfolioWalletArrays.length) {
      return undefined;
    }

    return flatten(portfolioWalletArrays);
  }, [visiblePortfolioWalletsByWalletGroup]);
}

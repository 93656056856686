import { useMemo } from 'react';
import { ETHEREUM_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { SUPPORTED_BLOCKCHAINS } from 'cb-wallet-data/stores/Buy/cbpay/consts';

import { useQueryCapabilitiesForAccountId } from ':dapp/hooks/Scw/useQueryCapabilitiesForAccountId';

export function useScwWalletSupportedBlockchains(singleAccountId: string | undefined) {
  const accountIdCapabilities = useQueryCapabilitiesForAccountId(singleAccountId);

  const supportedBlockchains = useMemo(() => {
    const chainIds = Object.keys(accountIdCapabilities ?? {});
    return SUPPORTED_BLOCKCHAINS.filter(
      (blockchain) =>
        blockchain?.chainId &&
        chainIds.includes(blockchain?.chainId.toString()) &&
        blockchain.currencyCode !== ETHEREUM_SYMBOL,
    ).map((blockchain) => blockchain.currencyCode);
  }, [accountIdCapabilities]);

  return useMemo(() => {
    return supportedBlockchains;
  }, [supportedBlockchains]);
}

import { useCallback } from 'react';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { TrackedAsset } from 'cb-wallet-data/stores/ExchangeRates/types';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

export const customTrackedExchangeRates = atom<TrackedAsset[]>({
  key: 'customTrackedExchangeRates',
  default: [],
});

/**
 * Manually start monitoring the exchange rate for some crypto asset.
 *
 * If we've never tracked that asset before, it will be fetched immediately.
 *
 * If we've already tracked that asset or already have an exchange rate for it,
 * this function has no effect.
 *
 * @returns A function that can be called to start tracking the exchange rate for a crypto asset.
 */
type AddTrackedExchangeRate = {
  code: CurrencyCode;
  chainId: bigint;
  networkId: string;
  contractAddress?: string;
};
export function useAddTrackedExchangeRate(): ({
  code,
  chainId,
  networkId,
  contractAddress,
}: AddTrackedExchangeRate) => Promise<void> {
  const setTrackedExchangeRate = useSetRecoilState(customTrackedExchangeRates);

  return useCallback(
    async function refresh({ code, chainId, networkId, contractAddress }: AddTrackedExchangeRate) {
      setTrackedExchangeRate(function updateTrackedExchangeRate(old) {
        if (
          !old.some(
            (n) =>
              n.code === code &&
              n.chainId === chainId.toString() &&
              n.networkId === networkId &&
              n.contractAddress === contractAddress,
          )
        ) {
          return [...old, { code, chainId: chainId.toString(), networkId, contractAddress }];
        }
        return old;
      });
    },
    [setTrackedExchangeRate],
  );
}

export function useAddTrackedExchangeRates(): (rates: TrackedAsset[]) => Promise<void> {
  const setTrackedExchangeRate = useSetRecoilState(customTrackedExchangeRates);

  return useCallback(
    async function refresh(rates: TrackedAsset[]) {
      setTrackedExchangeRate([...rates]);
    },
    [setTrackedExchangeRate],
  );
}

export function useCustomTrackedExchangeRates(): TrackedAsset[] {
  return useRecoilValue(customTrackedExchangeRates);
}

import { HTTPError } from './HTTPError';

export async function handleHTTPError(response: Response): Promise<void> {
  let serverError: any; // This is a catch-all for any error that comes from internal and external APIs

  try {
    // Try to parse the response as JSON
    const errorData = await response.json();
    if ('message' in errorData) {
      // Handles retail API errors where the error properties aren't nested under an `error` key
      serverError = { ...errorData, code: 'code' in errorData ? String(errorData.code) : '' };
    } else {
      serverError = errorData.error;
    }
  } catch {
    // This will at least bubble up the status code and the errant url to our error handlers
    // instead of a JSON parsing error
    throw new HTTPError(
      response.status,
      '',
      `Failed to parse network response from ${response.url}. The status code is ${response.status}`,
      undefined,
      undefined,
      response.url,
    );
  }

  throw new HTTPError(
    response.status,
    serverError?.code,
    serverError?.message ?? serverError?.error, // Some third party APIs use `error` instead of `message`
    serverError?.description,
    serverError?.errorDetail ?? serverError?.details,
    response.url,
  );
}

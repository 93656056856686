import { v4 as uuidv4 } from 'uuid';

import { SearchEntity, SearchField } from './types';

export enum SearchPath {
  TypeaheadSearch = 'TypeaheadSearch',
  Search = 'Search',
}

const BASE_URL = 'https://coinbase.com/api';

const END_POINT = 'v3/coinbase.web3_search_service.Web3SearchService';

export function getUrl(path: SearchPath, baseUrl = BASE_URL) {
  return `${baseUrl}/${END_POINT}/${path}`;
}

export function generateRequestId() {
  return uuidv4();
}

export function getOrDefault(arr: SearchField[]) {
  if (arr.length) return arr[0].value;
  return undefined;
}

export function filterOnKey(fields: SearchField[], key: string) {
  return fields.filter((field) => field.key === key);
}

export enum SearchEntityTitle {
  ASSET = 'Coin',
  DAPP = 'App',
  NFT = 'NFT',
  NFT_COLLECTIONS = 'NFT Collection',
  ENS = 'ENS',
  SETTING = 'Setting',
  Profile = 'Profile',
}

export const SearchEntitySubTitles: Partial<Record<SearchEntity, SearchEntityTitle>> = {
  [SearchEntity.SEARCH_ENTITY_ASSET]: SearchEntityTitle.ASSET,
  [SearchEntity.SEARCH_ENTITY_ENS]: SearchEntityTitle.ENS,
  [SearchEntity.SEARCH_ENTITY_NFT_COLLECTIONS]: SearchEntityTitle.NFT_COLLECTIONS,
  [SearchEntity.SEARCH_ENTITY_DAPP]: SearchEntityTitle.DAPP,
};

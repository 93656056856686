import { StorageArea } from 'cb-wallet-store/models/StorageArea';
import { StoreKey } from 'cb-wallet-store/models/StoreKey';
import { parse, stringify } from 'cb-wallet-store/utils/serialization';

export class LocalStorage implements StorageArea {
  private storageScope = 'CBStore.plaintext';

  static mappedPrototypes: Map<string, any> = new Map();

  set<T>(key: StoreKey<T>, value?: T): void {
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    if (typeof window === 'undefined' || typeof window.localStorage === 'undefined') {
      return;
    }

    if (value === undefined || value === null) {
      localStorage.removeItem(this.scopedKey(key.name));
      return;
    }

    const jsonValue = stringify(value);
    localStorage.setItem(this.scopedKey(key.name), jsonValue);
  }

  get<T>(key: StoreKey<T>): T | undefined {
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    if (typeof window === 'undefined' || typeof window.localStorage === 'undefined') {
      return undefined;
    }

    const value = localStorage.getItem(this.scopedKey(key.name));
    if (value === undefined || value === null) return undefined;

    const parsedValue = parse(value);

    const prototype = LocalStorage.mappedPrototypes.get(key.name);
    if (prototype) {
      const parsedValueWithClass = Object.create(prototype);
      Object.assign(parsedValueWithClass, parsedValue);
      return parsedValueWithClass;
    }

    return parsedValue;
  }

  clear(options?: { exceptions?: StoreKey<any>[] }): void {
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    if (typeof window === 'undefined' || typeof window.localStorage === 'undefined') {
      return;
    }

    const prefix = this.scopedKey('');
    const keysToRemove: string[] = [];

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (
        typeof key === 'string' &&
        key.startsWith(prefix) &&
        (!options?.exceptions || this.shouldKeyBeCleared(key, options.exceptions))
      ) {
        keysToRemove.push(key);
      }
    }

    keysToRemove.forEach((key) => localStorage.removeItem(key));
  }

  private scopedKey(key: string): string {
    return `${this.storageScope}:${key}`;
  }

  private shouldKeyBeCleared(key: string, exceptions: StoreKey<any>[]) {
    for (const exception of exceptions) {
      if (this.scopedKey(exception.name) === key) {
        return false;
      }
    }
    return true;
  }
}

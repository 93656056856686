import { useMemo } from 'react';
import { useActiveAccount } from 'cb-wallet-data/stores/Accounts/hooks/useActiveAccount';
import { useFirstCreatedAccount } from 'cb-wallet-data/stores/Accounts/hooks/useFirstCreatedAccount';

import { generateNetworkHasBalanceMapKey } from '../utils/generateNetworkHasBalanceMapKey';
import { getNetworkHasBalanceMapLoadingIndicatorKeys } from '../utils/getNetworkHasBalanceMapLoadingIndicatorKeys';

import { useIsNetworkAllowed } from './useIsNetworkAllowed';

export function useNetworkHasBalanceMapLoadingIndicatorKeys() {
  // Only check balances on first account as the user id is shared across MMA, which only count a user towards MTU
  const firstCreatedAccount = useFirstCreatedAccount();
  const activeAccount = useActiveAccount();
  const account = firstCreatedAccount ?? activeAccount; // fallback to active account if first account id not found
  const isNetworkAllowed = useIsNetworkAllowed();

  const loadingIndicatorKeys = useMemo(() => {
    // we only want to return keys for networks which are allowed
    return getNetworkHasBalanceMapLoadingIndicatorKeys(isNetworkAllowed);
  }, [isNetworkAllowed]);

  // generate account specific loading indicator (networkHasBalanceMap) map
  const accountLoadingIndicatorKeys = useMemo(() => {
    return loadingIndicatorKeys.map((key) =>
      generateNetworkHasBalanceMapKey({
        accountId: account.id,
        networkString: key,
      }),
    );
  }, [account, loadingIndicatorKeys]);

  return { accountLoadingIndicatorKeys, accountId: account.id };
}

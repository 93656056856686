import { ETHEREUM_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { ParseParams } from 'cb-wallet-data/stores/Transactions/methods/parsers/HRT/parseParams';
import {
  apiv1LabelToPrimaryAction,
  buildHRTMetadata,
  primaryActionToType,
} from 'cb-wallet-data/stores/Transactions/methods/parsers/utils/utils';
import { TxOrUserOp, TxOrUserOpParams } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import { TxState } from 'cb-wallet-data/stores/Transactions/models/TxState';
import { unixTimestampToDate } from 'cb-wallet-data/stores/Transactions/utils/unixTimestampToDate';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { v4 as uuidv4 } from 'uuid';
import { V1TransactionStatus } from '@cbhq/instant-api-hooks-wallet-tx-history/types';

export function parseStake({
  primaryAction,
  feeAmount,
  txHash,
  txNonce,
  txTimestamp,
  txStatus,
  userOpHash,
  userAddress,
  blockchain,
  network,
  accountId,
  walletIndex,
  transfers,
}: ParseParams): TxOrUserOp | undefined {
  if (!txHash && !userOpHash) {
    // server is somehow returning a tx or userOp with an identifying hash
    return undefined;
  }

  const txMetadata = buildHRTMetadata([], primaryAction?.metadata);
  const date = unixTimestampToDate(txTimestamp || new Date().getTime().toString());
  const baseAssetCurrencyCode = new CurrencyCode(
    network.asChain()?.baseAssetCurrencyCode || ETHEREUM_SYMBOL,
  );

  const transfer = transfers[0];
  const fromWalletId = Wallet.generateId({
    blockchain,
    currencyCode: baseAssetCurrencyCode,
    network,
    selectedIndex: walletIndex,
    accountId,
  });

  return new TxOrUserOp({
    id: uuidv4(),
    createdAt: date,
    confirmedAt: date,
    blockchain,
    currencyCode: baseAssetCurrencyCode,
    feeCurrencyCode: baseAssetCurrencyCode,
    feeCurrencyDecimal: BigInt(network.asChain()?.baseAssetDecimal ?? 0),
    toAddress: transfer.toAddress,
    fromAddress: userAddress,
    amount: transfer.amount,
    fromAmount: transfer.amount.toString(),
    fee: BigInt(feeAmount || 0),
    state:
      txStatus === V1TransactionStatus.TRANSACTION_STATUS_SUCCESS
        ? TxState.CONFIRMED
        : TxState.FAILED,
    metadata: txMetadata,
    network,
    walletIndex,
    accountId,
    txOrUserOpHash: userOpHash ?? txHash,
    userOpHash,
    txHash,
    tokenName: network.asChain()?.baseAssetDisplayName ?? '',
    tokenDecimal: BigInt(network.asChain()?.baseAssetDecimal ?? 0),
    walletId: fromWalletId,
    nonce: BigInt(txNonce || 0),
    type: primaryActionToType({ primaryActionLabel: primaryAction?.type, txMetadata }),
    transfers,
    isSpam: false,
    primaryAction: apiv1LabelToPrimaryAction(primaryAction?.type),
    isContractExecution: true,
    toNetwork: undefined,
    toCurrencyCode: baseAssetCurrencyCode,
    toWalletId: fromWalletId,
    source: 'V3',
    fromAssetImage: network.asChain()?.baseAssetImageUrl,
    isSent: false,
  } as TxOrUserOpParams);
}

import { getAllERC20s } from '../database';
import { ERC20, getERC20Id } from '../models/ERC20';

// Max time we will use a cached ERC20 object before refetching
const ONE_HOUR = 60 * 60 * 1000;
const ERC20_CACHE_TIME = ONE_HOUR;

/**
 * Fetching ERC20 data is expensive and the data rarely changes. Because
 * of this we keep an in-memory cache of whether or not we have the erc20
 * info in the database and only fetch if we do not have it, or if the
 * data is stale. Ideally this would be done in react so we could just use
 * react-query for this
 */
export class ERC20CacheManager {
  storedERC20s: Record<string, ERC20> = {};
  isInitialized = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hydrationPromise: Promise<any> = Promise.resolve();

  async hydrateFromDatabase() {
    if (!this.isInitialized) {
      this.isInitialized = true;
      this.hydrationPromise = getAllERC20s();
      this.storedERC20s = await this.hydrationPromise;
    } else {
      await this.hydrationPromise;
    }
  }

  setItem(erc20: ERC20) {
    // setItem should do nothing if called before hydrating from db.
    // we read from the db anyways upon init so there is no need to
    // sync in memory beforehand
    if (!this.isInitialized) return;
    this.storedERC20s[erc20.id] = erc20;
  }

  getItem(id: string) {
    const cachedERC20 = this.storedERC20s[id];

    return {
      cachedERC20,
      isStale: !!cachedERC20 && Date.now() - cachedERC20.lastUpdated > ERC20_CACHE_TIME,
    };
  }
}

export const erc20CacheManager = new ERC20CacheManager();

export function getERC20FromCacheIfValid(contractAddress: string, chainId: bigint) {
  const id = getERC20Id(contractAddress, chainId.toString());
  const { cachedERC20, isStale } = erc20CacheManager.getItem(id);

  if (cachedERC20 && !isStale) {
    return cachedERC20;
  }

  return undefined;
}

import { useCallback } from 'react';
import { withTokenRefreshFailureHandling } from 'cb-wallet-data/HTTP/utils/withTokenRefreshFailureHandling';
import { useSetAuthTokens } from 'cb-wallet-data/stores/Authentication/tokens/useSetAuthTokens';
import { FetchOptionsType, postFile, postJSON } from 'cb-wallet-http/fetchJSON';

import { AuthedFetchFn, AuthedFetchParams } from './types';

export type RequestOptions = {
  rawData?: boolean;
  additionalHeaders?: FetchOptionsType['additionalHeaders'];
};

/**
 * Returns a function which performs an authenticated HTTP POST request.
 *
 * @param endpoint
 * @returns Promise of T
 */
export function useAuthedPost<T>(
  endpoint: string,
  type: 'JSON' | 'FILE' = 'JSON',
  forceDevEnvironment = false,
  requestOptions: RequestOptions = { rawData: false },
): AuthedFetchFn<T> {
  const setAuthTokens = useSetAuthTokens();

  return useCallback(
    async function authedPost(params: AuthedFetchParams = {}, options = {}) {
      return withTokenRefreshFailureHandling<T>(
        // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
        // eslint-disable-next-line wallet/no-anonymous-params
        async () => {
          if (type === 'JSON') {
            const response = await postJSON<{ result: T }>(
              endpoint,
              params as Record<string, unknown>,
              {
                authenticated: true,
                returnHeaders: false,
                setAuthTokens,
                forceDevEnvironment,
                ...options,
                additionalHeaders: {
                  ...requestOptions.additionalHeaders,
                  ...options.additionalHeaders,
                },
              },
            );

            if (requestOptions.rawData) {
              return response as unknown as T;
            }

            return response.result;
          }

          const { result } = await postFile<{ result: T }>(endpoint, params as FormData, {
            authenticated: true,
            returnHeaders: false,
            setAuthTokens,
            forceDevEnvironment,
          });

          return result;
        },
      );
    },
    [
      type,
      endpoint,
      setAuthTokens,
      forceDevEnvironment,
      requestOptions.additionalHeaders,
      requestOptions.rawData,
    ],
  );
}

import { useCallback, useMemo } from 'react';
import { logResubmitTransactionFail } from 'cb-wallet-analytics/data/Transactions';
import { resubmitPendingTransactions } from 'cb-wallet-data/chains/AccountBased/operations/resubmitPendingTransactions';
import {
  blockchainConfigurations,
  getCurrentGroupSupportedBlockchainSymbols,
  PossibleAccountBlockchainSymbol,
} from 'cb-wallet-data/chains/blockchains';
import { useIsTestnetsEnabled } from 'cb-wallet-data/hooks/Testnets/useIsTestnetsEnabled';
import { useInterval } from 'cb-wallet-data/hooks/useInterval';
import { SignedTx } from 'cb-wallet-data/stores/Transactions/interfaces/SignedTx';
import {
  DEFAULT_PENDING_TXN_RESUBMIT_DELAY,
  DEFAULT_PENDING_TXN_RESUBMIT_INTERVAL,
  FREQUENT_PENDING_TXN_RESUBMIT_DELAY,
  FREQUENT_PENDING_TXN_RESUBMIT_INTERVAL,
} from 'cb-wallet-data/utils/intervalConstants';

import { useUpdateTransactionState } from './useUpdateTransactionState';

const isResubmissionInProgress: Map<string, boolean> = new Map();

/**
 * Resubmits any pending transactions for a passed in list of supported blockchain symbols' networks in parallel.
 * The updated signed transactions is saved to the DB and then included as an argument to the passed in `onSubmit` callback.
 */
export async function resubmitPendingTransactionsForChains(
  blockchains: PossibleAccountBlockchainSymbol[],
  syncUpdateToTxHistoryTable: (transaction: SignedTx) => void,
  includeTestnets = false,
): Promise<void> {
  // Technically, we could use the array of blockchains as a key in the `isResubmissionInProgress` map, but JS `Map`s use
  // reference equality when comparing object keys so given this is an exported function, we build a simple and deterministic
  // string representation of a list of blockchain symbols to prevent creating near infinite map entries.
  const inProgressKey = blockchains.join('_');

  if (isResubmissionInProgress.get(inProgressKey)) {
    return;
  }

  isResubmissionInProgress.set(inProgressKey, true);

  await Promise.allSettled(
    blockchains
      .map(
        // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
        // eslint-disable-next-line wallet/no-anonymous-params
        (blockchainSymbol) => {
          const blockchainConfig = blockchainConfigurations[blockchainSymbol];
          const networks = includeTestnets
            ? blockchainConfig.networkSetting.allNetworks
            : blockchainConfig.networkSetting.mainnetsAndCustomNetworks;

          return networks.map(async ({ network }) => {
            try {
              await resubmitPendingTransactions({
                blockchainSymbol,
                network,
                syncUpdateToTxHistoryTable,
              });
            } catch (e: ErrorOrAny) {
              logResubmitTransactionFail(network.rawValue);
            }
          });
        },
      )
      .flat(),
  );

  isResubmissionInProgress.set(inProgressKey, false);
}

/**
 * It resubmits any pending transactions for groups of currently supported blockchains at customized intervals.
 * Transactions on any Solana networks are polled every 5 seconds.
 * Transactions on any other blockchains' supported networks are polled every 30 seconds.
 *
 * NOTE: This hook should only be included in one component per application (i.e. extension and RN).
 */
export function useResubmitPendingTransactions(): void {
  const isTestnetsEnabled = useIsTestnetsEnabled();
  const { defaultIntervalChains, frequentIntervalChains } = useMemo(() => {
    const resubmitInternalDefaultChains: PossibleAccountBlockchainSymbol[] = [];
    const resubmitInternalFrequentChains: PossibleAccountBlockchainSymbol[] = [];

    getCurrentGroupSupportedBlockchainSymbols({ blockchainGroup: 'Account' }).forEach(
      (chainSymbol) => {
        switch (chainSymbol) {
          case 'SOL':
            resubmitInternalFrequentChains.push(chainSymbol);

            break;

          default:
            resubmitInternalDefaultChains.push(chainSymbol);

            break;
        }
      },
    );

    return {
      defaultIntervalChains: resubmitInternalDefaultChains,
      frequentIntervalChains: resubmitInternalFrequentChains,
    };
  }, []);

  const updateTransactionState = useUpdateTransactionState();
  const syncUpdateToTxHistoryTable = useCallback(
    function updateTransaction(transaction: SignedTx) {
      updateTransactionState(transaction.txHash, transaction.blockchain, transaction.state);
    },
    [updateTransactionState],
  );

  const resubmitDefaultInterval = useCallback(
    async function resubmitDefaultInterval() {
      return resubmitPendingTransactionsForChains(
        defaultIntervalChains,
        syncUpdateToTxHistoryTable,
        isTestnetsEnabled,
      );
    },
    [defaultIntervalChains, syncUpdateToTxHistoryTable, isTestnetsEnabled],
  );
  const defaultIntervalDelay =
    defaultIntervalChains.length > 0 ? DEFAULT_PENDING_TXN_RESUBMIT_INTERVAL : null;

  useInterval(resubmitDefaultInterval, {
    interval: defaultIntervalDelay,
    initialDelay: DEFAULT_PENDING_TXN_RESUBMIT_DELAY,
  });

  const resubmitFrequentInterval = useCallback(
    async function resubmitFrequentInterval() {
      return resubmitPendingTransactionsForChains(
        frequentIntervalChains,
        syncUpdateToTxHistoryTable,
      );
    },
    [syncUpdateToTxHistoryTable, frequentIntervalChains],
  );
  const frequentIntervalDelay =
    frequentIntervalChains.length > 0 ? FREQUENT_PENDING_TXN_RESUBMIT_INTERVAL : null;

  useInterval(resubmitFrequentInterval, {
    interval: frequentIntervalDelay,
    initialDelay: FREQUENT_PENDING_TXN_RESUBMIT_DELAY,
  });
}

import { GlobalDrawerContent } from './GlobalDrawerContent';
import { GlobalDrawerProvider } from './GlobalDrawerProvider';

type GlobalDrawerProps = {
  children: React.ReactNode;
};

export function GlobalDrawer({ children }: GlobalDrawerProps) {
  return <GlobalDrawerProvider>{children}</GlobalDrawerProvider>;
}

GlobalDrawer.Content = GlobalDrawerContent;

import { defineMessages, useIntl } from 'react-intl';
import { HeroSquare } from '@cbhq/cds-web/illustrations';
import { VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextTitle1 } from '@cbhq/cds-web/typography';

const messages = defineMessages({
  pageTitle: {
    description: 'Under maintenance page title',
    defaultMessage: "We'll be right back",
  },
  pageDescription: {
    description: 'Under maintenance page description',
    defaultMessage:
      "We're currently in maintenance mode. Please wait a few minutes before trying again.",
  },
});

export function UnderMaintenance() {
  const { formatMessage } = useIntl();

  return (
    <VStack height="100vh" justifyContent="center" alignItems="center" gap={3}>
      <HeroSquare name="coinbaseIsDown" />
      <VStack maxWidth="480px" alignItems="center">
        <TextTitle1 as="h2" spacingBottom={2}>
          {formatMessage(messages.pageTitle)}
        </TextTitle1>
        <TextBody as="p" color="foregroundMuted" spacingHorizontal={3} align="center">
          {formatMessage(messages.pageDescription)}
        </TextBody>
      </VStack>
    </VStack>
  );
}

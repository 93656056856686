import { signingAndDerivationMethods } from '../signingAndDerivation';
import { SigningAndDerivationMethods } from '../types/SigningAndDerivationMethods';

/**
 * Provides the signing or derivation function for the given method.
 *
 * Some methods hooked up to the signingAndDerivationMethods service locator are
 * primarily done for performance reasons; these heavy derivation functions can be
 * performed in a webview on a separate thread via CBWalletEngineNative, when on iOS.
 *
 * If any derivation functions in this service locator require the mnemonic/seed
 * they will only be used from locations where the mnemonic/seed is readily available, like
 * during onboarding or MMA import/create flows.
 *
 * NOTE: For similar functions that require the mnemonic or seed outside of onboarding/MMA
 * flows, they will use equivalent functions in signingAndDerivationForAccount service locator,
 * which access the mnemonic only through the native layer.
 */
export function getSigningOrDerivationMethod<TKey extends keyof SigningAndDerivationMethods>(
  method: TKey,
): SigningAndDerivationMethods[TKey] {
  return signingAndDerivationMethods.get(method);
}

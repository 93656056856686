import { ETHEREUM_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { DataMigrationApp, DataMigrationParams } from 'cb-wallet-data/DataMigrationRunner/types';
import { AccountType } from 'cb-wallet-data/stores/Accounts/models/AccountTypes';
import { ThemeColorPreference } from 'cb-wallet-data/stores/ThemeColors/themeColorConfigs';
import { themeColorDefault } from 'cb-wallet-data/stores/User/hooks/useThemeColor';
import { StoreKeys_themeColor } from 'cb-wallet-data/stores/User/state';
import { StoreKeys_activeWalletIndexes } from 'cb-wallet-data/stores/Wallets/state';
import { Store } from 'cb-wallet-store/Store';
import { parse } from 'cb-wallet-store/utils/serialization';

function getActiveEthWalletIndexFromLocalStorage(): bigint | undefined {
  const storedActiveWalletIndexes: string | undefined = Store.get(StoreKeys_activeWalletIndexes);
  return storedActiveWalletIndexes ? parse(storedActiveWalletIndexes)[ETHEREUM_SYMBOL] : undefined;
}

function getThemeColorFromLocalStorage(): ThemeColorPreference | undefined {
  const storedThemeColor = Store.get<ThemeColorPreference>(StoreKeys_themeColor);
  return storedThemeColor;
}

// Takes App-specific data and builds data migration params
export function buildDataMigrationParams(app: DataMigrationApp = {}): DataMigrationParams {
  return {
    accountType: app.accountType ?? AccountType.MNEMONIC,
    activeEthWalletIndex: getActiveEthWalletIndexFromLocalStorage() ?? 0n,
    themeColor: getThemeColorFromLocalStorage() ?? themeColorDefault,
  };
}

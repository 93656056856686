import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { postJSON } from 'cb-wallet-http/fetchJSON';

import { DefiProtocolPortfolioResponse } from '../models/DefiProtocolPortfolio';

const FETCH_QUERY_KEY = 'portfolio/defi/protocol';

export type DefiProtocolPortfolioRequestParams = {
  evmAddresses?: string[];
  enabled?: boolean;
};

export async function fetchDefiProtocolPortfolio({
  evmAddresses,
}: DefiProtocolPortfolioRequestParams): Promise<DefiProtocolPortfolioResponse> {
  try {
    if (!evmAddresses?.length) {
      return {};
    }

    const { result } = await postJSON<{
      result: DefiProtocolPortfolioResponse;
    }>(FETCH_QUERY_KEY, {
      evmWalletAddresses: evmAddresses,
    });
    return result;
  } catch (error) {
    cbReportError({
      context: 'defi-portfolio',
      error: new Error(`Failed to fetch defi protocol portfolio`),
      severity: 'error',
      isHandled: false,
    });
    return Promise.resolve({});
  }
}

export function useFetchDefiProtocolPortfolio({
  evmAddresses,
  enabled = true,
}: DefiProtocolPortfolioRequestParams) {
  const { data, error, isLoading } = useQuery(
    [FETCH_QUERY_KEY, ...(evmAddresses ?? [])],
    async () =>
      fetchDefiProtocolPortfolio({
        evmAddresses,
      }),
    {
      enabled,
      useErrorBoundary: false,
      staleTime: 1000 * 30,
      refetchInterval: 1000 * 60,
      notifyOnChangeProps: ['data'],
    },
  );

  return { response: data, error, isLoading };
}

import { memo, useMemo } from 'react';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { UseNftAvatarMetadataReturn } from 'cb-wallet-data/stores/Collection/hooks/useNftAvatarMetadata';
import { useIsNftPfpEnabled } from 'cb-wallet-data/stores/DecentralizedID/hooks/useIsNftPfpEnabled';
import { isNFTAvatar } from 'cb-wallet-data/stores/DecentralizedID/types';
import { useDefaultAvatarIdxByAddress } from 'cb-wallet-data/stores/WalletGroups/hooks/useDefaultAvatarIdxByAddress';
import { AddressAvatar } from 'wallet-cds-web/components/AddressAvatar/AddressAvatar';
import { CollectibleMedia } from 'wallet-cds-web/components/CollectibleMedia';
import { ErrorBoundary } from 'wallet-cds-web/components/ErrorBoundary/ErrorBoundary';
import { DefaultAvatar } from 'wallet-cds-web/components/ProfileAvatar/DefaultAvatar';
import { NftPfp } from 'wallet-cds-web/components/ProfileAvatar/NftPfp';
import { AvatarSize, PaletteBorder } from '@cbhq/cds-common';
import { useAvatarSize } from '@cbhq/cds-common/media/useAvatarSize';
import { Box } from '@cbhq/cds-web/layout';

type DynamicPfpProps = {
  address?: string;
  testID?: string;
  size?: AvatarSize;
  src?: string;
  alt: string;
  nftAvatarMetadata: UseNftAvatarMetadataReturn;
  borderColor?: PaletteBorder;
  initialAvatarColorIndex?: number;
};

enum TestIDPrefix {
  defaultAvatar = 'default-avatar-',
  defaultWalletGroupAvatar = 'default-wallet-group-avatar-',
  nftAvatar = 'nft-avatar-',
  avatar = 'avatar-',
}

const DynamicPfpContent = memo(function DynamicPfp({
  address,
  testID,
  size,
  src,
  nftAvatarMetadata,
  alt,
  borderColor,
  initialAvatarColorIndex,
}: DynamicPfpProps) {
  const isNftPfpEnabled = useIsNftPfpEnabled();
  const pixelSize = useAvatarSize(size ?? 'l');
  const [width, height] = useMemo(() => [`${pixelSize}px`, `${pixelSize}px`], [pixelSize]);
  const legacyDefaultAvatarIdx = useDefaultAvatarIdxByAddress(address);

  const isNewAvatarsFeatureEnabled = useIsFeatureEnabled('wallet_redesign_new_avatars');

  const isNftDataWithDisabledNftPfp = useMemo(
    () => !isNftPfpEnabled && !!src && isNFTAvatar(src),
    [isNftPfpEnabled, src],
  );

  const shouldDisplayDefaultAvatar = useMemo(
    () =>
      isNftDataWithDisabledNftPfp ||
      (!nftAvatarMetadata && !src) ||
      (isNFTAvatar(src) && !nftAvatarMetadata),
    [isNftDataWithDisabledNftPfp, nftAvatarMetadata, src],
  );

  if (isNftPfpEnabled && nftAvatarMetadata) {
    return (
      <Box testID={`${TestIDPrefix.nftAvatar}${testID}`}>
        <NftPfp
          collectible={nftAvatarMetadata.collectible}
          chainId={nftAvatarMetadata.chainId}
          size={size}
          borderColor={borderColor}
        />
      </Box>
    );
  }

  if (shouldDisplayDefaultAvatar) {
    if (isNewAvatarsFeatureEnabled) {
      return (
        <AddressAvatar
          size={size}
          address={address}
          initialAvatarColorIndex={initialAvatarColorIndex}
        />
      );
    }

    return (
      <Box testID={`${TestIDPrefix.defaultAvatar}${testID}`}>
        <DefaultAvatar
          alt={alt}
          idx={legacyDefaultAvatarIdx || 0}
          size={size}
          borderColor={borderColor}
        />
      </Box>
    );
  }

  return (
    <CollectibleMedia
      testID={`${TestIDPrefix.avatar}${testID}`}
      width={width}
      height={height}
      dangerouslySetRadius={width}
      chainId=""
      imageUrl={src}
      contractAddress={nftAvatarMetadata?.collectible?.contractAddress}
      borderColor={borderColor}
    />
  );
});

export const DynamicPfp = memo(function DynamicPfp(props: DynamicPfpProps) {
  const isNewAvatarsFeatureEnabled = useIsFeatureEnabled('wallet_redesign_new_avatars');

  const fallback = useMemo(() => {
    if (isNewAvatarsFeatureEnabled) {
      return (
        <AddressAvatar
          size={props.size}
          address={props.address}
          initialAvatarColorIndex={props.initialAvatarColorIndex}
        />
      );
    }
    return (
      <DefaultAvatar
        alt={props.alt}
        idx={0}
        size={props.size}
        testID={props.testID}
        borderColor={props.borderColor}
      />
    );
  }, [
    isNewAvatarsFeatureEnabled,
    props.address,
    props.alt,
    props.borderColor,
    props.size,
    props.testID,
    props.initialAvatarColorIndex,
  ]);

  return (
    <ErrorBoundary fallback={fallback}>
      <DynamicPfpContent {...props} />
    </ErrorBoundary>
  );
});

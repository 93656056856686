import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { hash } from 'cb-wallet-data/utils/hash';
import { getJSON } from 'cb-wallet-http/fetchJSON';

export type BasicProfile = {
  address: string;
  name: string;
  avatar: string;
};

type BasicProfiles = Record<string, BasicProfile>;

type BasicPublicProfiles = {
  profiles: BasicProfiles;
};

type BasicPublicProfilesResponse = {
  result: BasicPublicProfiles;
};

const ENDPOINT = 'getBasicPublicProfiles';

export async function fetchBasicPublicProfiles(
  domains?: string[],
  addresses?: string[],
): Promise<BasicPublicProfilesResponse | undefined> {
  if (!domains?.length && !addresses?.length) {
    return undefined;
  }

  if (!!domains && !!domains.length && !addresses?.length) {
    return getJSON(ENDPOINT, { names: domains.join(',') });
  }

  if (!!addresses && !!addresses.length && !domains?.length) {
    return getJSON(ENDPOINT, { addresses: addresses.join(',') });
  }

  if (!!domains && !!addresses && !!domains.length && !!addresses.length) {
    return getJSON(ENDPOINT, { names: domains.join(','), addresses: addresses.join(',') });
  }
}

type UseBasicPublicProfileParams = {
  domains?: string[];
  addresses?: string[];
  enabled?: boolean;
};

export function useBasicPublicProfile({
  domains,
  addresses,
  enabled = true,
}: UseBasicPublicProfileParams): BasicProfiles | undefined {
  const { data: basicPublicProfile } = useQuery(
    [ENDPOINT, hash(domains?.join(',') || ''), hash(addresses?.join(',') || '')],
    async () => fetchBasicPublicProfiles(domains, addresses),
    {
      suspense: false,
      staleTime: 1000 * 60 * 10,
      cacheTime: 1000 * 60 * 10,
      notifyOnChangeProps: ['data'],
      enabled,
    },
  );

  return basicPublicProfile?.result.profiles;
}

import { AllPossibleBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';

import { Wallet } from '../models/Wallet';

export function getAddressesByBlockchainForIds(
  idsStr: string,
  getWalletsById: (id: string) => Map<AllPossibleBlockchainSymbol, Wallet>,
) {
  const ids = idsStr.split(',');
  return ids.reduce(function mapWalletGroupIdToPrimaryReceiveAddressesMap(acc, id) {
    if (!id) {
      return acc;
    }
    const walletsByBlockchain = getWalletsById(id);
    const addressesByBlockchain = new Map();

    for (const [symbol, wallet] of walletsByBlockchain) {
      const address = wallet.addresses.find((a) => a.address === wallet?.primaryAddress);
      addressesByBlockchain.set(symbol, address);
    }

    acc.set(id, addressesByBlockchain);

    // console.log('acc', acc);
    return acc;
  }, new Map());
}

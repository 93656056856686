import {
  logHideCollectibleFailed,
  logHideCollectibleSucceed,
  logHideWalletFailed,
  logHideWalletSucceed,
  logUnhideCollectibleFailed,
  logUnhideCollectibleSucceed,
  logUnhideWalletFailed,
  logUnhideWalletSucceed,
  logUpdateRemoteCollectibleSettingsFailed,
  logUpdateRemoteCollectibleSettingsSucceed,
  logUpdateRemoteWalletSettingsFailed,
  logUpdateRemoteWalletSettingsSucceed,
} from 'cb-wallet-analytics/asset-management/events';
import {
  CollectibleSettingEventProps,
  WalletSettingsEventProps,
} from 'cb-wallet-analytics/utils/types/assetManagement';
import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { atomLocalStorageEffect } from 'cb-wallet-data/utils/atomLocalStorageEffect';
import { getDebouncerInstance } from 'cb-wallet-data/utils/getDebouncerInstance';
import { hasStoredAuthTokens } from 'cb-wallet-data/utils/hasStoredAuthTokens';
import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { Store } from 'cb-wallet-store/Store';
import noop from 'lodash/noop';
import { atom, DefaultValue, selectorFamily } from 'recoil';

import { WalletGroup } from '../WalletGroups/models/WalletGroup';
import { activeWalletGroupIdAtom } from '../WalletGroups/state';

import { AssetManagementStatus } from './models/AssetManagementStatus';
import { CuratedAssetSetting } from './models/CuratedAssetSetting';
import { UserCollectibleSetting } from './models/UserCollectibleSetting';
import { UserWalletSetting } from './models/UserWalletSetting';
import { updateRemoteUserAssetSettings } from './api';
import {
  addUserCollectibleSettings,
  addUserWalletSettings,
  getAllUserCollectibleSettings,
  getAllUserWalletSettings,
} from './database';

type Identifiable = {
  id: string;
};

const userWalletSettingsDebouncer = getDebouncerInstance();
const userCollectibleSettingsDebouncer = getDebouncerInstance();
const userLowBalanceWalletsStatusDebouncer = getDebouncerInstance();

const USER_ASSET_SETTINGS_SYNC_TIMEOUT = 1000 * 60 * 30;

export function getMapFromArrayById<T extends Identifiable>(array: T[]) {
  const result: Record<string, T> = {};

  array.forEach((item) => {
    result[item.id] = item;
  });

  return result;
}

function parseWalletSettingToEventData(setting: UserWalletSetting): WalletSettingsEventProps {
  return {
    currencyCode: setting.currencyCode.rawValue,
    primaryAddress: setting.primaryAddress,
    blockchain: setting.blockchain.rawValue,
    network: setting.network.rawValue,
    chainName: setting.network.asChain()?.baseAssetDisplayName ?? '',
    status: setting.status,
    contractAddress: setting.contractAddress ?? '',
  };
}

function parseCollectibleSettingToEventData(
  setting: UserCollectibleSetting,
): CollectibleSettingEventProps {
  return {
    tokenId: setting.tokenId ?? '',
    primaryAddress: setting.primaryAddress,
    blockchain: setting.blockchain.rawValue,
    network: setting.network.rawValue,
    status: setting.status,
    chainName: setting.network.asChain()?.baseAssetDisplayName ?? '',
    contractAddress: setting.contractAddress ?? '',
  };
}

function updateRemoteSettings(areLowBalanceWalletsHidden: boolean) {
  return () => {
    const primaryAddress = Store.get<string>(StoreKeys_userSettingsPrimaryAddress) ?? '';

    if (primaryAddress && hasStoredAuthTokens()) {
      updateRemoteUserAssetSettings(
        primaryAddress,
        { shouldShowLowBalance: !areLowBalanceWalletsHidden },
        noop,
      ).catch((error) => {
        cbReportError({ error, context: 'assets', severity: 'error', isHandled: false });
      });
    }
  };
}

export const StoreKeys_userSettingsPrimaryAddress = new LocalStorageStoreKey<string>(
  'userSettingsPrimaryAddress',
);

const StoreKeys_hasHiddenAsset = new LocalStorageStoreKey<boolean>('hasHiddenAsset');

export const hasHiddenAssetAtom = atom({
  key: 'hasHiddenAssetStatus',
  default: false,
  effects: [atomLocalStorageEffect(StoreKeys_hasHiddenAsset)],
});

export const StoreKeys_areLowBalanceWalletsHiddenByWalletGroupId = new LocalStorageStoreKey<
  Record<WalletGroup['id'], boolean>
>('areLowBalanceWalletsHiddenByWalletGroupId');

export const areLowBalanceWalletsHiddenByWalletGroupIdAtom = atom({
  key: 'areLowBalanceWalletsHiddenByWalletGroupId',
  default:
    Store.get<Record<WalletGroup['id'], boolean>>(
      StoreKeys_areLowBalanceWalletsHiddenByWalletGroupId,
    ) || ({} as Record<WalletGroup['id'], boolean>),
  effects: [atomLocalStorageEffect(StoreKeys_areLowBalanceWalletsHiddenByWalletGroupId)],
});

export const areLowBalanceWalletsHiddenByWalletGroupIdSelectorFamily = selectorFamily<
  boolean,
  string
>({
  key: 'areLowBalanceWalletsHiddenByWalletGroupIdSelector',
  get:
    (walletGroupId) =>
    ({ get }) => {
      return get(areLowBalanceWalletsHiddenByWalletGroupIdAtom)[walletGroupId];
    },
  set:
    (walletGroupId) =>
    ({ get, set }, newValue) => {
      if (!walletGroupId) return;

      const areLowBalanceWalletsHiddenByWalletGroupId = get(
        areLowBalanceWalletsHiddenByWalletGroupIdAtom,
      );
      if (newValue instanceof DefaultValue) {
        return set(areLowBalanceWalletsHiddenByWalletGroupIdAtom, {
          ...areLowBalanceWalletsHiddenByWalletGroupId,
          [walletGroupId]: false,
        });
      }
      const activeWalletGroupId = get(activeWalletGroupIdAtom);

      // Currently we only allow the user to show/hide low balance wallets for the active wallet group.
      // If that changes, this will need to be updated since updateRemoteSettings
      // only updates remote settings for the active wallet group's primaryAddress
      if (activeWalletGroupId === walletGroupId) {
        userLowBalanceWalletsStatusDebouncer.call(updateRemoteSettings(newValue), 1000);
      }

      set(areLowBalanceWalletsHiddenByWalletGroupIdAtom, {
        ...areLowBalanceWalletsHiddenByWalletGroupId,
        [walletGroupId]: newValue,
      });
    },
});

export const areUserAssetSettingsSyncedAtom = atom({
  key: 'areUserAssetSettingsSynced',
  default: false,
  effects: [
    ({ setSelf, onSet }) => {
      onSet(() => {
        setTimeout(() => setSelf(false), USER_ASSET_SETTINGS_SYNC_TIMEOUT);
      });
    },
  ],
});

export const userWalletSettingsAtom = atom<Record<string, UserWalletSetting>>({
  key: 'userWalletSettings',
  default: {} as Record<string, UserWalletSetting>,
  effects: [
    ({ setSelf, trigger }) => {
      if (trigger === 'get') {
        getAllUserWalletSettings().then((walletSettings) =>
          setSelf(getMapFromArrayById(walletSettings)),
        );
      }
    },
  ],
});

/*
 * This is a buffer that will pool sequencial changes (interval < 1sec) and bulk put them on DB.
 * It prevent us of having throttling on the DB and also prepares data-layer for Token Management Phase 2,
 * where we'll be saving changes on the backend.
 */
export const userWalletSettingsPoolAtom = atom<Record<string, UserWalletSetting>>({
  key: 'userWalletSettingsPool',
  default: {},
  effects: [
    ({ onSet, setSelf }) => {
      function persistSettings(settingsMap: Record<string, UserWalletSetting>) {
        return async () => {
          const userSettings = Object.values(settingsMap);
          const primaryAddress = Store.get<string>(StoreKeys_userSettingsPrimaryAddress) ?? '';

          if (primaryAddress && hasStoredAuthTokens()) {
            // Saving settings on backend
            // TODO Pedro: Find a way to get setAuthTokens outside a hook
            updateRemoteUserAssetSettings(primaryAddress, { wallets: userSettings }, noop)
              .then(() => {
                userSettings.forEach(function logDataForUserSetting(setting) {
                  const userSettingEventData = parseWalletSettingToEventData(setting);
                  logUpdateRemoteWalletSettingsSucceed(userSettingEventData);
                });
              })
              .catch((error) => {
                userSettings.forEach(function logUserSettingFailure(setting) {
                  const userSettingEventData = {
                    ...parseWalletSettingToEventData(setting),
                    error,
                  };
                  logUpdateRemoteWalletSettingsFailed(userSettingEventData);
                  cbReportError({ error, context: 'assets', isHandled: false, severity: 'error' });
                });
              });
          }

          // Saving settings on local DB
          addUserWalletSettings(userSettings)
            .then(function logUserSettingEvents() {
              // We just flush the buffer on succeed cases. This way, if it fails it can still retry on the next one
              setSelf({});

              userSettings.forEach(function logUserSettingSuccesses(setting) {
                const userSettingEventData = parseWalletSettingToEventData(setting);
                if (setting.status === AssetManagementStatus.userHidden) {
                  logHideWalletSucceed(userSettingEventData);
                } else {
                  logUnhideWalletSucceed(userSettingEventData);
                }
              });
            })
            .catch((error) =>
              userSettings.forEach(function logUserSettingFailures(setting) {
                const userSettingEventData = { ...parseWalletSettingToEventData(setting), error };
                if (setting.status === AssetManagementStatus.userHidden) {
                  logHideWalletFailed(userSettingEventData);
                } else {
                  logUnhideWalletFailed(userSettingEventData);
                }
              }),
            );
        };
      }

      onSet((newValue) => {
        userWalletSettingsDebouncer.call(persistSettings(newValue), 1000);
      });
    },
  ],
});

export const userWalletSettingsSelectorFamily = selectorFamily<
  UserWalletSetting | undefined,
  string
>({
  key: 'userWalletSettingsSelectorFamily',
  get:
    (id) =>
    ({ get }) => {
      const userWalletSettings = get(userWalletSettingsAtom);
      return userWalletSettings[id];
    },
  set:
    (id) =>
    ({ set, get }, newValue) => {
      if (!newValue) return;

      const userWalletSettings = get(userWalletSettingsAtom);
      const userWalletSettingsPool = get(userWalletSettingsPoolAtom);

      if (newValue instanceof DefaultValue) {
        set(userWalletSettingsAtom, newValue);
      } else {
        set(userWalletSettingsAtom, { ...userWalletSettings, [id]: newValue });
        set(userWalletSettingsPoolAtom, { ...userWalletSettingsPool, [id]: newValue });
      }
    },
});

export const userCollectibleSettingsAtom = atom({
  key: 'userCollectibleSettings',
  default: {} as Record<string, UserCollectibleSetting>,
  effects: [
    ({ setSelf }) => {
      getAllUserCollectibleSettings().then((collectibleSettings) =>
        setSelf(getMapFromArrayById(collectibleSettings)),
      );
    },
  ],
});

/*
 * This is a buffer that will pool sequencial changes (interval < 1sec) and bulk put them on DB.
 * It prevent us of having throttling on the DB and also prepares data-layer for Token Management Phase 2,
 * where we'll be saving changes on the backend.
 */
export const userCollectibleSettingsPoolAtom = atom<Record<string, UserCollectibleSetting>>({
  key: 'userCollectibleSettingsPool',
  default: {},
  effects: [
    function persistSettingsEffect({ onSet, setSelf }) {
      function persistSettings(settingsMap: Record<string, UserCollectibleSetting>) {
        return async () => {
          const userSettings = Object.values(settingsMap);

          const primaryAddress = Store.get<string>(StoreKeys_userSettingsPrimaryAddress) ?? '';

          if (primaryAddress && hasStoredAuthTokens()) {
            // Saving settings on backend
            // TODO Pedro: Find a way to get setAuthTokens outside a hook
            updateRemoteUserAssetSettings(primaryAddress, { collectibles: userSettings }, noop)
              .then(() => {
                userSettings.forEach(function logRemoteCollectibleSettingSuccess(setting) {
                  const userSettingEventData = parseCollectibleSettingToEventData(setting);
                  logUpdateRemoteCollectibleSettingsSucceed(userSettingEventData);
                });
              })
              .catch((error) => {
                userSettings.forEach(function logRemoteCollectibleSettingFailure(setting) {
                  const userSettingEventData = {
                    ...parseCollectibleSettingToEventData(setting),
                    error,
                  };
                  logUpdateRemoteCollectibleSettingsFailed(userSettingEventData);
                  cbReportError({ error, context: 'assets', severity: 'error', isHandled: false });
                });
              });
          }

          // Saving settings on local DB
          addUserCollectibleSettings(userSettings)
            .then(function logCollectibleSettingSuccesses() {
              // We just flush the buffer on succeed cases. This way, if it fails it can still retry on the next one
              setSelf({});

              userSettings.forEach(function logCollectibleSettingChanges(setting) {
                const userSettingEventData = parseCollectibleSettingToEventData(setting);
                if (setting.status === AssetManagementStatus.userHidden) {
                  logHideCollectibleSucceed(userSettingEventData);
                } else {
                  logUnhideCollectibleSucceed(userSettingEventData);
                }
              });
            })
            .catch((error) =>
              userSettings.forEach(function logCollectibleSettingFailures(setting) {
                const userSettingEventData = {
                  ...parseCollectibleSettingToEventData(setting),
                  error,
                };
                if (setting.status === AssetManagementStatus.userHidden) {
                  logHideCollectibleFailed(userSettingEventData);
                } else {
                  logUnhideCollectibleFailed(userSettingEventData);
                }
              }),
            );
        };
      }

      onSet((newValue) => {
        userCollectibleSettingsDebouncer.call(persistSettings(newValue), 1000);
      });
    },
  ],
});

export const userCollectibleSettingsSelectorFamily = selectorFamily<
  UserCollectibleSetting | undefined,
  string
>({
  key: 'userCollectibleSettingsSelectorFamily',
  get:
    (id) =>
    ({ get }) => {
      const userCollectibleSettings = get(userCollectibleSettingsAtom);
      return userCollectibleSettings[id];
    },
  set:
    (id) =>
    ({ set, get }, newValue) => {
      if (!newValue) return;

      const userCollectibleSettings = get(userCollectibleSettingsAtom);
      const userCollectibleSettingsPool = get(userCollectibleSettingsPoolAtom);

      if (newValue instanceof DefaultValue) {
        set(userCollectibleSettingsAtom, newValue);
      } else {
        set(userCollectibleSettingsAtom, { ...userCollectibleSettings, [id]: newValue });
        set(userCollectibleSettingsPoolAtom, { ...userCollectibleSettingsPool, [id]: newValue });
      }
    },
});

export const curatedAssetsSettingsAtom = atom<Record<string, CuratedAssetSetting>>({
  key: 'curatedAssetSettings',
  default: {} as Record<string, CuratedAssetSetting>,
});

import { useState } from 'react';

import { TimerHandler, UseIntervalOptions } from './types';
import { useIntervalBase } from './useIntervalBase';
import { useOnMount } from './useOnMount';

export function useInterval(
  callback: TimerHandler,
  { interval, initialDelay }: UseIntervalOptions,
): void {
  const [isActive, setIsActive] = useState(initialDelay === undefined || initialDelay <= 0);

  useOnMount(
    // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
    // eslint-disable-next-line wallet/no-anonymous-params
    () => {
      const timeout = setTimeout(() => {
        setIsActive(true);
      }, initialDelay);

      return () => clearTimeout(timeout);
    },
  );

  useIntervalBase(callback, isActive ? interval : null);
}

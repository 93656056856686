import { useCallback, useMemo } from 'react';
import {
  logCollectibleCachedMediaLoadFailureEvent,
  logCollectibleCachedMediaLoadSuccessEvent,
  logCollectibleCachedMediaUnsupportedEvent,
  logCollectibleMediaLoadFailureEvent,
  logCollectibleMediaLoadSuccessEvent,
  logCollectibleMediaMissingEvent,
  logCollectibleMediaUnsupportedEvent,
} from 'cb-wallet-analytics/collectible';

type Props = {
  chainId: string;
  startTime: number;
  mediaType?: string;
  contractAddress?: string;
  hasUnretrievableMetadata?: boolean;
};

function getCommonProps({
  chainId,
  mediaType,
  contractAddress,
  hasUnretrievableMetadata,
}: Omit<Props, 'startTime'>) {
  return {
    chainId,
    mediaType: mediaType || '',
    contractAddress,
    hasUnretrievableMetadata,
  };
}

// Separate since it is called from inside of useMediaInfo
export function triggerCachedMediaUnsupportedAnalytics({
  chainId,
  startTime,
  mediaType,
  contractAddress,
}: Props) {
  const commonProps = getCommonProps({
    chainId,
    mediaType,
    contractAddress,
  });

  logCollectibleCachedMediaUnsupportedEvent({
    ...commonProps,
    elapsedTime: Date.now() - startTime,
  });
}

export function useCollectibleMediaAnalytics({
  chainId,
  startTime,
  mediaType,
  contractAddress,
  hasUnretrievableMetadata = false,
}: Props) {
  const commonProps = getCommonProps({
    chainId,
    mediaType,
    contractAddress,
  });

  const triggerAnalyticsCachedMediaLoadSuccess = useCallback(() => {
    logCollectibleCachedMediaLoadSuccessEvent({
      ...commonProps,
      cacheProvider: 'cloudinary',
      elapsedTime: Date.now() - startTime,
    });
  }, [commonProps, startTime]);
  const triggerAnalyticsMediaLoadSuccess = useCallback(() => {
    logCollectibleMediaLoadSuccessEvent({
      ...commonProps,
      elapsedTime: Date.now() - startTime,
    });
  }, [commonProps, startTime]);
  const triggerAnalyticsCachedMediaLoadFailure = useCallback(() => {
    logCollectibleCachedMediaLoadFailureEvent({
      ...commonProps,
      elapsedTime: Date.now() - startTime,
    });
  }, [commonProps, startTime]);
  const triggerAnalyticsMediaLoadFailure = useCallback(() => {
    logCollectibleMediaLoadFailureEvent({
      ...commonProps,
      elapsedTime: Date.now() - startTime,
    });
  }, [commonProps, startTime]);
  const triggerAnalyticsMediaMissing = useCallback(() => {
    logCollectibleMediaMissingEvent({
      ...commonProps,
      elapsedTime: Date.now() - startTime,
      hasUnretrievableMetadata,
    });
  }, [commonProps, startTime, hasUnretrievableMetadata]);
  const triggerAnalyticsMediaUnsupported = useCallback(() => {
    logCollectibleMediaUnsupportedEvent({
      ...commonProps,
      elapsedTime: Date.now() - startTime,
    });
  }, [commonProps, startTime]);

  return useMemo(
    () => ({
      triggerAnalyticsCachedMediaLoadSuccess,
      triggerAnalyticsMediaLoadSuccess,
      triggerAnalyticsCachedMediaLoadFailure,
      triggerAnalyticsMediaLoadFailure,
      triggerAnalyticsMediaUnsupported,
      triggerAnalyticsMediaMissing,
    }),
    [
      triggerAnalyticsCachedMediaLoadSuccess,
      triggerAnalyticsMediaLoadSuccess,
      triggerAnalyticsCachedMediaLoadFailure,
      triggerAnalyticsMediaLoadFailure,
      triggerAnalyticsMediaUnsupported,
      triggerAnalyticsMediaMissing,
    ],
  );
}

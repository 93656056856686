import {
  SolanaChain,
  SolanaNetworkMap,
} from 'cb-wallet-data/chains/AccountBased/Solana/models/SolanaChain';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';

export const SOLANA_PREFIX = 'SOLANA_CHAIN';
export const SOLANA_NETWORK_ID = 'networks/solana-mainnet';

export function fromNetworkRawValue(rawValue: string): SolanaChain | undefined {
  const chainId = chainIdFromNetworkRawValue(rawValue);

  if (!chainId) {
    return undefined;
  }

  return SolanaNetworkMap.fromChainId(chainId);
}

export function chainIdFromNetworkRawValue(rawValue: string): bigint | undefined {
  const pieces = rawValue.split('/');

  if (pieces.length !== 2) {
    return undefined;
  }

  const prefix = pieces[0];
  const prefixPieces = prefix?.split(':') ?? [];

  if (prefixPieces.length !== 2 || SOLANA_PREFIX !== prefixPieces[0]) {
    return undefined;
  }

  const chainIdStr = prefixPieces[1];

  if (!chainIdStr) {
    return undefined;
  }

  return BigInt(chainIdStr);
}

export function asNetwork(chain: SolanaChain): Network {
  return new Network(`${SOLANA_PREFIX}:${chain.chainId}`, chain.isTestnet);
}

export const SelectedChain = SolanaNetworkMap.whitelisted.SOLANA_MAINNET;

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { appearanceModeAtom } from '../state';

export function useAppearance() {
  const appearance = useRecoilValue(appearanceModeAtom);

  return appearance;
}

export function useSetAppearance() {
  const setAppearance = useSetRecoilState(appearanceModeAtom);

  return setAppearance;
}

import { useMemo } from 'react';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';

import { useHasScwAccounts } from './useHasScwAccounts';

export type ScwFeature = 'buy' | 'send' | 'swap' | 'add_wallet';

/**
 * Checks smart wallet for first account and returns whether or not to kill the following flows:
 * - Buy
 * - Send
 * - Swap
 * - Add wallet
 */
export function useIsFeatureEnabledForScw(feature: ScwFeature) {
  const hasScwAccounts = useHasScwAccounts();
  const buyFlow = useIsFeatureEnabled('scw_display_cbpay_buy_button_navbar');
  const sendFlow = useIsFeatureEnabled('scw_send_blockchain_all');
  const swapFlow = useIsFeatureEnabled('scw_swap');
  const addWalletFlow = useIsFeatureEnabled('scw_add_wallet');

  return useMemo(() => {
    if (!hasScwAccounts) {
      return true;
    }

    switch (feature) {
      case 'buy':
        return buyFlow;
      case 'send':
        return sendFlow;
      case 'swap':
        return swapFlow;
      case 'add_wallet':
        return addWalletFlow;

      default:
        return true;
    }
  }, [addWalletFlow, buyFlow, feature, hasScwAccounts, sendFlow, swapFlow]);
}

import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { Persistable } from 'cb-wallet-data/persistence/Database.interface';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { Column, Entity, Index, PrimaryColumn } from '@cbhq/typeorm';

import { AddressType } from './AddressType';

@Entity('address')
@Index('IDX_ADDRESS_ADDRESS', ['address'])
@Index('IDX_ADDRESS_USEDSTR', ['isUsedStr'])
@Index('IDX_ADDRESS_BLOCKCHAIN', ['blockchainStr'])
@Index('IDX_ADDRESS_BLOCKCHAIN_CURRENCY_NETWORK_ACCOUNTID', [
  'blockchainStr',
  'currencyCodeStr',
  'networkStr',
  'accountId',
])
@Index('IDX_ADDRESS_BLOCKCHAIN_NETWORK_ACCOUNTID', ['blockchainStr', 'networkStr', 'accountId'])
@Index('IDX_ADDRESS_BLOCKCHAIN_CURRENCY_NETWORK', [
  'blockchainStr',
  'currencyCodeStr',
  'networkStr',
])
@Index('IDX_ADDRESS_BLOCKCHAIN_CURRENCY_NETWORK_TYPE', [
  'blockchainStr',
  'currencyCodeStr',
  'networkStr',
  'typeStr',
  'accountId',
])
@Index('IDX_ADDRESS_BLOCKCHAIN_CURRENCY_NETWORK_BALANCE', [
  'blockchainStr',
  'currencyCodeStr',
  'networkStr',
  'balanceStr',
])
@Index('IDX_ADDRESS_BLOCKCHAIN_NETWORK', ['blockchainStr', 'networkStr'])
@Index('IDX_ADDRESS_BLOCKCHAIN_ADDRESS_NETWORK', ['blockchainStr', 'address', 'networkStr'])
@Index('IDX_ADDRESS_BLOCKCHAIN_CURR_ADDRESS_NETWORK_ACCOUNTID', [
  'blockchainStr',
  'currencyCodeStr',
  'address',
  'networkStr',
  'accountId',
])
@Index('IDX_ADDRESS_BLOCKCHAIN_CURR_NETWORK_USED_ACCOUNTID', [
  'blockchainStr',
  'currencyCodeStr',
  'networkStr',
  'isUsedStr',
  'accountId',
])
@Index('IDX_ADDRESS_BLOCKCHAIN_CURR_NETWORK_TYPE_CHANGED_USED_ACCOUNTID', [
  'blockchainStr',
  'currencyCodeStr',
  'networkStr',
  'typeStr',
  'isChangeAddressStr',
  'isUsedStr',
  'accountId',
])
@Index('IDX_ADDRESS_BLOCKCHAIN_CURR_NETWORK_TYPE_CHANGED_INDEX_ACCOUNTID', [
  'blockchainStr',
  'currencyCodeStr',
  'networkStr',
  'typeStr',
  'isChangeAddressStr',
  'indexStr',
  'accountId',
])
export class AddressDMO {
  @PrimaryColumn()
  id!: string;

  @Column()
  indexStr!: string;

  @Column()
  address!: string;

  @Column({ nullable: true })
  balanceStr?: string;

  @Column()
  currencyCodeStr!: string;

  @Column()
  isChangeAddressStr!: string;

  @Column()
  networkStr!: string;

  @Column()
  typeStr!: string;

  @Column()
  derivationPath!: string;

  @Column()
  isUsedStr!: string;

  @Column()
  blockchainStr!: string;

  @Column({ nullable: true })
  contractAddress?: string;

  // TODO: Model property is enforced as string, but leaving this as
  // nullable for now because of some uncertainty around migrations.
  @Column({ nullable: true })
  accountId!: string;

  @Column({ nullable: true, default: false })
  isDeleted!: boolean;
}

/**
 * Represents a wallet address entry
 *
 * @property id Indexed address unique ID
 * @property index Receive address or Change address index
 * @property address Wallet address
 * @property balance Address balance
 * @property currencyCode Wallet currency code
 * @property isChangeAddress Determine whether wallet address is a change address or receive address
 * @property network Blockchain network state
 * @property type Type of indexed address i.e. bitcoin segwit, bitcoin legacy, etc
 * @property derivationPath Derivation Path
 * @property isUsed Determine whether address is used
 * @property blockchain of the address
 * @property contractAddress The smart contract addresses  for non-native currencies i.e. ERC20
 */
export class Address implements Persistable<AddressDMO> {
  /** Address ID. `blockchain/currencyCode/network/type/isChangeAddress/index/accountId` */
  readonly id: string;
  readonly index: bigint;
  readonly address: string;
  readonly balance: bigint | undefined;
  readonly currencyCode: CurrencyCode;
  readonly isChangeAddress: boolean;
  readonly network: Network;
  readonly type: AddressType;
  readonly derivationPath: string;
  readonly isUsed: boolean;
  readonly blockchain: Blockchain;
  readonly contractAddress?: string;
  readonly accountId: string;
  readonly isDeleted?: boolean;

  get asDMO(): AddressDMO {
    return {
      id: this.id,
      indexStr: this.index.toString(),
      address: this.address,
      balanceStr: this.balance?.toString(),
      currencyCodeStr: this.currencyCode.rawValue,
      isChangeAddressStr: this.isChangeAddress.toString(),
      networkStr: this.network.rawValue,
      typeStr: this.type.rawValue,
      derivationPath: this.derivationPath,
      isUsedStr: this.isUsed.toString(),
      blockchainStr: this.blockchain.toString(),
      contractAddress: this.contractAddress?.toString(),
      accountId: this.accountId,
      isDeleted: this.isDeleted ?? false,
    };
  }

  static fromDMO(dmo: AddressDMO): Address {
    return new Address({
      id: dmo.id,
      index: BigInt(dmo.indexStr),
      address: dmo.address,
      balance: dmo.balanceStr ? BigInt(dmo.balanceStr) : undefined,
      currencyCode: new CurrencyCode(dmo.currencyCodeStr),
      isChangeAddress: dmo.isChangeAddressStr === 'true',
      network: Network.create(dmo.networkStr)!,
      type: new AddressType(dmo.typeStr),
      derivationPath: dmo.derivationPath,
      isUsed: dmo.isUsedStr === 'true',
      blockchain: new Blockchain(dmo.blockchainStr),
      contractAddress: dmo.contractAddress ?? undefined,
      accountId: dmo.accountId,
      isDeleted: dmo.isDeleted ?? false,
    });
  }

  constructor({
    id,
    index,
    address,
    balance,
    currencyCode,
    isChangeAddress,
    network,
    type,
    derivationPath,
    isUsed,
    blockchain,
    contractAddress,
    accountId,
    isDeleted = false,
  }: {
    id?: Address['id'];
    index: bigint;
    address: string;
    balance: bigint | undefined;
    currencyCode: CurrencyCode;
    isChangeAddress: boolean;
    network: Network;
    type: AddressType;
    derivationPath: string;
    isUsed: boolean;
    blockchain: Blockchain;
    contractAddress?: string;
    accountId: string;
    isDeleted?: boolean;
  }) {
    this.id =
      id ||
      Address.generateId({
        blockchain,
        currencyCode,
        network,
        type,
        isChangeAddress,
        index,
        accountId,
      });

    this.index = index;
    this.address = address;
    this.balance = balance;
    this.currencyCode = currencyCode;
    this.isChangeAddress = isChangeAddress;
    this.network = network;
    this.type = type;
    this.derivationPath = derivationPath;
    this.isUsed = isUsed;
    this.blockchain = blockchain;
    this.contractAddress = contractAddress;
    this.accountId = accountId;
    this.isDeleted = isDeleted;
  }

  // TODO y tho
  copy(balance: bigint | undefined): Address {
    return new Address({
      id: this.id,
      index: this.index,
      address: this.address,
      balance,
      currencyCode: this.currencyCode,
      isChangeAddress: this.isChangeAddress,
      network: this.network,
      type: this.type,
      derivationPath: this.derivationPath,
      isUsed: this.isUsed,
      blockchain: this.blockchain,
      contractAddress: this.contractAddress,
      accountId: this.accountId,
    });
  }

  static generateId(components: {
    blockchain: Blockchain;
    currencyCode: CurrencyCode;
    network: Network;
    type: AddressType;
    isChangeAddress: boolean;
    index: bigint;
    accountId: Account['id'];
  }): string {
    return [
      components.blockchain.rawValue,
      components.currencyCode.rawValue,
      components.network.rawValue,
      components.type.rawValue,
      `${components.isChangeAddress}`,
      components.index.toString(),
      components.accountId,
    ].join('/');
  }
}

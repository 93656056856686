import { NetworkCustomization } from 'cb-wallet-data/chains/AccountBased/Ethereum/models/CustomNetwork';
import { httpErrors } from 'cb-wallet-http/constants';

export async function checkNetworkIsReachable(network: NetworkCustomization): Promise<boolean> {
  // We don't support websocket connections for rpcUrl yet. And on iOS, fetch won't throw an error
  // when trying to connect to a websocket url. So we need to throw an error here.
  if (network.rpcUrls[0]?.startsWith('wss')) {
    throw new Error('Websocket connections are not supported');
  }

  const result = await fetch(network.rpcUrls[0], {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ jsonrpc: '2.0', method: 'eth_chainId', id: 1 }),
  });

  if (Object.keys(httpErrors).includes(result.status.toString())) {
    throw new Error(`${result.status}: ${httpErrors[result.status]}`);
  }

  return result.ok;
}

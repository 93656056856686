export const LINK_DEFI_GUIDE_URL = 'https://www.coinbase.com/learn/crypto-basics/what-is-defi';

export const LINK_HOW_TO_LOCATE_CDV_PAYMENT_METHOD =
  'https://support.coinbase.com/customer/portal/articles/2739613-how-do-i-locate-my-payment-method-verification-amounts/';

export const SUBDOMAIN_TOS_URL = 'https://www.coinbase.com/legal/subdomain/terms-of-service';

export const SUBDOMAIN_ENS_SUPPORT_URL =
  'https://help.coinbase.com/wallet/managing-account/coinbase-ens-support';

export const LINK_USER_AGREEMENT = 'https://www.coinbase.com/legal/user_agreement';

export const LINK_LEGAL_PRIVACY = 'https://www.coinbase.com/legal/privacy';

export const TERMS_OF_SERVICE_URL = 'https://wallet.coinbase.com/terms-of-service';

export const PRIVACY_POLICY_URL = 'https://wallet.coinbase.com/privacy-policy';

export const DAPP_TERMS_OF_SERVICE_URL = 'https://wallet.coinbase.com/dapp-terms-of-service';

export const DAPP_PRIVACY_POLICY_URL = 'https://wallet.coinbase.com/dapp-privacy-policy';

export const MOBILE_GETTING_STARTED_URL =
  'https://www.coinbase.com/wallet/articles/getting-started-mobile';

export const PANO_URL = 'https://wallet.coinbase.com';
export const WALLET_URL = 'https://www.coinbase.com/wallet';
export const COINBASE_URL = 'https://www.coinbase.com';
export const HELP_CENTER_URL = 'https://help.coinbase.com';
export const STATUS_PAGE_URL = 'https://status.coinbase.com/';

export const COMPLAINT_FORM_URL =
  'https://help.coinbase.com/en/coinbase/other-topics/other/how-to-send-a-complaint';

export const CONTACT_US_EMAIL = 'wallet.support@coinbase.com';
export const CONTACT_US_URL = 'https://help.coinbase.com/en/contact-us';

export const AAA_RULES_URL = 'https://www.adr.org/sites/default/files/Consumer%20Rules.pdf';

export const ARBITRATION_EMAIL = 'arbitration@coinbase.com';

export const LINK_COINBASE_ASSETS = 'https://www.coinbase.com/assets';
export const LINK_COINBASE_DEPOSIT = 'https://www.coinbase.com/deposit';
export const LINK_COINBASE_ADD_PAYMENT_METHOD = 'https://coinbase.com/settings/linked-accounts';
export const LINK_COINBASE_ADD_PAYMENT_METHOD_WITH_ACTION =
  'https://coinbase.com/settings/linked-accounts?action=add-payment-method&operation=cash_out';
export const LINK_COINBASE_REGISTER_AUTHENTICATOR =
  'https://accounts.coinbase.com/security/settings?register-authenticator';

export const ONCHAIN_SUMMER_DAPP_URL = 'https://onchainsummer.xyz';

export const OCS_DAPP_WHITELIST = [
  'https://zora.co',
  'https://opensea.io',
  'https://manifold.xyz',
  'https://magiceden.io',
  'https://onchainsummer.xyz',
];

export const GAS_FEE_LEARN_MORE_LINK =
  'https://help.coinbase.com/coinbase/trading-and-funding/sending-or-receiving-cryptocurrency/assets-on-multiple-networks';

export const USDC_REWARDS_FAQ_LINK =
  'https://help.coinbase.com/coinbase/coinbase-staking/rewards/usd-coin-rewards-faq';

export function linkCoinbaseAccount(accountUUID: string) {
  return `https://www.coinbase.com/accounts/${accountUUID}`;
}

import { EffectCallback, useEffect } from 'react';

/**
 * Emulates componentDidMount via useEffect with an empty dependency array [].
 * Helper hook to execute on mount and exhaustive-deps eslint warnings
 * */
export function useOnMount(effect: EffectCallback) {
  /* eslint-disable-next-line wallet/exhaustive-deps */
  return useEffect(effect, []);
}

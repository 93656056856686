import { useMemo } from 'react';
import { useDelayedTrue } from 'cb-wallet-data/hooks/useDelayedTrue';
import { useRecoilValue } from 'recoil';

import { didLoadCachedExchangeRatesAtom } from '../state';

export const EXCHANGE_RATE_FETCH_DELAY_MS = 5 * 1000;

/**
 * We can fetch exchange rates if 5 seconds has passed OR if we do not have cached exchange rates
 * */
export function useCanFetchExchangeRates(): boolean {
  const isDelayComplete = useDelayedTrue(EXCHANGE_RATE_FETCH_DELAY_MS);
  const didLoadCachedExchangeRates = useRecoilValue(didLoadCachedExchangeRatesAtom);

  return useMemo(
    () => isDelayComplete || !didLoadCachedExchangeRates,
    [isDelayComplete, didLoadCachedExchangeRates],
  );
}

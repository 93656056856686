import { EthereumNetworkMap } from 'cb-wallet-data/chains/AccountBased/Ethereum/EthereumChain';
import { SolanaNetworkMap } from 'cb-wallet-data/chains/AccountBased/Solana/models/SolanaChain';
import { isUTXOBasedBlockchain } from 'cb-wallet-data/chains/blockchains';
import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { Persistable } from 'cb-wallet-data/persistence/Database.interface';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import {
  logPotentialExchangeRateIssueIfNecessary,
  rateFor,
} from 'cb-wallet-data/stores/ExchangeRates/utils';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { bigIntToDecimal } from 'cb-wallet-data/utils/BigInt+Core';
import { parse, stringify } from 'cb-wallet-store/utils/serialization';
import { Decimal } from 'decimal.js';
import isArray from 'lodash/isArray';
import { Column, Entity, Index, PrimaryColumn } from '@cbhq/typeorm';

import { WalletAddress } from './WalletAddress';

@Entity('wallet')
@Index('IDX_WALLET', [
  'blockchainStr',
  'currencyCodeStr',
  'networkStr',
  'contractAddress',
  'selectedIndexStr',
  'accountId',
])
@Index('IDX_WALLET_BLOCKCHAIN', ['blockchainStr'])
@Index('IDX_WALLET_ACCOUNT_ID', ['accountId']) // TODO: Why is this here?
export class WalletDMO {
  @PrimaryColumn()
  id!: string;

  @Column()
  primaryAddress!: string;

  @Column()
  addresses!: string;

  @Column()
  displayName!: string;

  @Column()
  currencyCodeStr!: string;

  @Column({ nullable: true })
  contractAddress?: string;

  @Column({ nullable: true })
  imageURLStr?: string;

  @Column({ nullable: true })
  balanceStr?: string;

  @Column()
  decimalsStr!: string;

  @Column()
  blockchainStr!: string;

  @Column({ nullable: true })
  minimumBalanceStr?: string;

  @Column()
  networkStr!: string;

  @Column({ nullable: true })
  selectedIndexStr?: string;

  // TODO: Model property is enforced as string, but leaving this as
  // nullable for now because of some uncertainty around migrations.
  @Column({ nullable: true })
  accountId!: string;

  @Column({ nullable: true })
  assetUUID?: string;

  @Column({ nullable: true })
  lastBalanceUpdateTxHash?: string;

  @Column({ nullable: true })
  isSpam?: boolean;

  @Column({ nullable: true })
  isWhitelist?: boolean;

  @Column({ nullable: true, default: false })
  isDeleted!: boolean;
}

/**
 * Represents a wallet
 *
 * @property id Unique identifier
 * @property primaryAddress Primary receive address
 * @property addresses List of supported receive addresses
 * @property displayName Wallet UI display name
 * @property currencyCode Currency code
 * @property contractAddress The custom token contract address i.e. usually used in ETH based currencies
 * @property imageURL Currency image URL
 * @property balance Current wallet balance
 * @property decimals Currency atomic unit decimal (maximum on-chain number of decimals for a specific currency)
 * @property blockchain Blockchain
 * @property minimumBalance Optional minimum balance to activate the wallet
 * @property network The network state of the coin
 * @property selectedIndex Current selected wallet index. This will be `null` for wallets that do not support index switching
 * @property accountId The id of the Multi-account that this wallet belongs to
 * @property isSpam informs if the wallet in question is spam or not
 * @property isWhitelist informs if the wallet in question is whitelisted or not
 */
export class Wallet implements Persistable<WalletDMO> {
  /** Wallet ID. `blockchain/currencyCode/network/contractAddress/selectedIndex/accountId` */
  readonly id: string;
  readonly swapId: string;
  readonly primaryAddress: string;
  readonly addresses: WalletAddress[];
  readonly displayName: string;
  readonly currencyCode: CurrencyCode;
  readonly imageURL?: string;
  readonly balance?: bigint;
  readonly decimals: bigint;
  readonly blockchain: Blockchain;
  readonly minimumBalance?: bigint;
  readonly network: Network;
  readonly contractAddress?: string;
  readonly selectedIndex?: bigint;
  readonly accountId: Account['id'];
  readonly assetUUID?: string;
  readonly lastBalanceUpdateTxHash?: string;
  readonly isSpam?: boolean;
  readonly isWhitelist?: boolean;
  readonly isDeleted?: boolean;

  get asDMO(): WalletDMO {
    return {
      id: this.id,
      primaryAddress: this.primaryAddress,
      addresses: stringify(this.addresses.map((addr) => addr.asDMO)),
      displayName: this.displayName,
      currencyCodeStr: this.currencyCode.rawValue,
      imageURLStr: this.imageURL,
      decimalsStr: this.decimals.toString(),
      blockchainStr: this.blockchain.rawValue,
      minimumBalanceStr: this.minimumBalance?.toString(),
      networkStr: this.network.rawValue,
      contractAddress: this.contractAddress,
      selectedIndexStr: this.selectedIndex?.toString(),
      accountId: this.accountId?.toString(),
      assetUUID: this.assetUUID,
      lastBalanceUpdateTxHash: this.lastBalanceUpdateTxHash,
      isSpam: this.isSpam,
      isWhitelist: this.isWhitelist,
      isDeleted: this.isDeleted ?? false,

      // Saving as empty string when undefined is needed for backfilling, to possibly reset the balance
      // of an existing wallet. Undefined fields saved over previously defined ones will not update in db.
      balanceStr: this.balance?.toString() ?? '',
    };
  }

  static fromDMO(dmo: WalletDMO): Wallet {
    // Fixes an error with BN.js serialization where
    // addresses were stored as Object[] instead of stringified addres on the extension.
    // Fixed going forward via prohibiting non-primitive types on DMOs

    let addresses: WalletAddress[];
    if (isArray(dmo.addresses)) {
      try {
        // Attempt to parse the array as-is via the WalletAddress constructor
        addresses = dmo.addresses.map(WalletAddress.fromDMO);
      } catch (err) {
        // If something doesn't work as expected, track the error
        // and return the wallet address array as-is. This is the behavior before the fix was added

        const e = coerceError(err, 'Wallet');
        cbReportError({ context: 'wallets', error: e, severity: 'error', isHandled: false });
        addresses = dmo.addresses;

        // Check if there are instances of the object serialized incorrectly
        // and keep track. This shouldn't happen in production, is just a failsafe
        for (const addr of dmo.addresses as WalletAddress[]) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((addr.index as any) === '[object Object]') {
            const error = new Error('Incorrectly serialized object stored');
            const metadata = {
              address: addr.address,
              index: addr.index?.toString(),
              type: addr.type?.toString(),
            };
            cbReportError({
              context: 'wallets',
              error,
              ...metadata,
              isHandled: false,
              severity: 'error',
            });
            break;
          }
        }
      }
    } else {
      addresses = (parse(dmo.addresses) ?? []).map(WalletAddress.fromDMO);
    }

    return new Wallet({
      id: dmo.id,
      primaryAddress: dmo.primaryAddress,
      addresses,
      displayName: dmo.displayName,
      currencyCode: new CurrencyCode(dmo.currencyCodeStr),
      imageURL: dmo.imageURLStr,
      balance: dmo.balanceStr ? BigInt(dmo.balanceStr) : undefined, // Will be empty string from db if undefined
      decimals: BigInt(dmo.decimalsStr),
      blockchain: new Blockchain(dmo.blockchainStr),
      minimumBalance: dmo.minimumBalanceStr ? BigInt(dmo.minimumBalanceStr) : undefined,
      network: Network.create(dmo.networkStr)!,
      contractAddress: dmo.contractAddress,
      selectedIndex: dmo.selectedIndexStr ? BigInt(dmo.selectedIndexStr) : undefined,
      accountId: dmo.accountId,
      assetUUID: dmo.assetUUID,
      lastBalanceUpdateTxHash: dmo.lastBalanceUpdateTxHash,
      isSpam: dmo.isSpam,
      isWhitelist: dmo.isWhitelist,
      isDeleted: dmo.isDeleted ?? false,
    });
  }

  constructor({
    id,
    primaryAddress,
    addresses,
    displayName,
    currencyCode,
    imageURL,
    balance,
    decimals,
    blockchain,
    minimumBalance,
    network,
    contractAddress,
    selectedIndex,
    accountId,
    assetUUID,
    lastBalanceUpdateTxHash,
    isSpam,
    isWhitelist,
    isDeleted = false,
  }: {
    id?: Wallet['id'];
    primaryAddress: string;
    addresses: WalletAddress[];
    displayName: string;
    currencyCode: CurrencyCode;
    imageURL?: string;
    balance?: bigint;
    decimals: bigint;
    blockchain: Blockchain;
    minimumBalance?: bigint;
    network: Network;
    contractAddress?: string;
    selectedIndex?: bigint;
    accountId: Account['id'];
    assetUUID?: string;
    lastBalanceUpdateTxHash?: string;
    isSpam?: boolean;
    isWhitelist?: boolean;
    isDeleted?: boolean;
  }) {
    this.id =
      id ||
      Wallet.generateId({
        blockchain,
        currencyCode,
        network,
        contractAddress,
        selectedIndex,
        accountId,
      });

    this.primaryAddress = primaryAddress;
    this.addresses = addresses;
    this.displayName = displayName;
    this.currencyCode = currencyCode;
    this.imageURL = imageURL;
    this.balance = balance;
    this.decimals = decimals;
    this.blockchain = blockchain;
    this.minimumBalance = minimumBalance;
    this.network = network;
    this.contractAddress = contractAddress;
    this.selectedIndex = selectedIndex;
    this.accountId = accountId;
    this.assetUUID = assetUUID;
    this.lastBalanceUpdateTxHash = lastBalanceUpdateTxHash;
    // We needed to perform some normalization here to match the pattern returned by the API
    // If "contractAddress" doesn't exists, we should use the string "undefined" instead
    // - API pattern: "ETH/WETH/ETHEREUM_CHAIN:1/false/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"
    this.swapId = `${blockchain.rawValue.toUpperCase()}/${currencyCode.rawValue.toUpperCase()}/${
      network.rawValue
    }/${contractAddress ?? 'undefined'}`;
    this.isSpam = isSpam;
    this.isWhitelist = isWhitelist;
    this.isDeleted = isDeleted;
  }

  canShowFullTxHistory(): boolean {
    if (isUTXOBasedBlockchain(this.blockchain.rawValue)) {
      return true;
    }

    // whitelisted networks should see full tx history
    const chainId = this.network.asChain()?.chainId.toString(10) ?? '';
    return EthereumNetworkMap.isWhitelisted(chainId) || SolanaNetworkMap.isWhitelisted(chainId);
  }

  /**
   * Whether balance has been initially fetched for the wallet.
   *
   * Note: Undefined balances are stored as empty string in db, but coalesced to
   * undefined in Wallet.asDMO when converted to a Wallet record (this allows wallet
   * balances to be reset during backfilling, if needed).
   */
  get isBalanceLoaded(): boolean {
    return this.balance !== undefined;
  }

  get isBaseAsset(): boolean {
    return this.contractAddress === undefined || this.contractAddress === null;
  }

  /** Left here for parity with iOS code, but defined within Ciphercore */
  isLayer2Network(): boolean {
    return !!this.network.asChain()?.isLayer2;
  }

  isSolanaNetwork(): boolean {
    return this.network.asChain()?.chainId === SolanaNetworkMap.whitelisted.SOLANA_MAINNET.chainId;
  }

  isCustomNetwork(): boolean {
    return !!this.network.asChain()?.isCustomNetwork;
  }

  /**
   * fiatBalance
   *
   * Based on the exchangeRatesMap, this method will convert the balance to a
   * fiat value in Decimal format.
   */
  fiatBalance({ exchangeRatesMap = {} }: { exchangeRatesMap: Record<string, Decimal> }): Decimal {
    const exchangeRate =
      rateFor({
        exchangeRatesMap,
        currencyCode: this.currencyCode,
        contractAddress: this.contractAddress,
        network: this.network,
        blockchain: this.blockchain,
      }) ?? new Decimal(0);

    const walletBalance = bigIntToDecimal(this.balance ?? 0n);
    const walletDecimals = bigIntToDecimal(this.decimals);

    const fiatBalance = exchangeRate
      .times(walletBalance)
      .dividedBy(new Decimal(10).pow(walletDecimals));

    logPotentialExchangeRateIssueIfNecessary({
      fiatBalance,
      blockchain: this.blockchain.rawValue,
      currencyCode: this.currencyCode.rawValue,
      contractAddress: this.contractAddress ?? '',
      networkName: this.network.rawValue,
      chainName: this.network.asChain()?.displayName ?? '',
      chainId: this.network.asChain()?.chainId ?? '',
    });

    return fiatBalance;
  }

  /**
   * Checks if two wallets are the same, devoid of reference equality.
   *
   * If two wallets have the same ID, commonly updated Wallet properties
   * are checked against each other.
   *
   * NOTE: Currently used as a performance enhancement when updating walletsAtom
   * and activeWalletsAtom -- If you're not seeing those update from changes
   * to a wallet, a check for that property may need to be added here.
   */
  static isEqual(w1: Wallet, w2: Wallet): boolean {
    if (w1.id !== w2.id) return false;
    if (w1.addresses.length !== w2.addresses.length) return false;
    if (w1.isBalanceLoaded !== w2.isBalanceLoaded) return false;
    if (w1.displayName !== w2.displayName) return false;
    if (w1.decimals !== w2.decimals) return false;
    if (Boolean(w1.isSpam) !== Boolean(w2.isSpam)) return false;
    if (w1.currencyCode.rawValue !== w2.currencyCode.rawValue) return false;

    const w1Balance = w1.balance ?? BigInt(0);
    const w2Balance = w2.balance ?? BigInt(0);
    if (w1Balance !== w2Balance) return false;

    const w1ImageUrlStr = w1.imageURL?.toString();
    const w2ImageUrlStr = w2.imageURL?.toString();
    if (w1ImageUrlStr !== w2ImageUrlStr) return false;

    return true;
  }

  static generateId(components: {
    blockchain: Blockchain;
    currencyCode: CurrencyCode;
    network: Network;
    contractAddress?: string;
    selectedIndex?: bigint;
    accountId: Account['id'];
  }): string {
    return [
      components.blockchain.rawValue,
      encodeURIComponent(components.currencyCode.rawValue), // may contain slashes if coming from fetched tx, sanitized to ensure Wallet.propsFromId can parse it back out
      components.network.rawValue, // includes a slash
      components.contractAddress,
      components.selectedIndex?.toString(),
      components.accountId, // includes 2 slashes
    ].join('/');
  }

  static generateIdFromMaybeEncodedString(maybeEncodedWalletId: string): string | undefined {
    if (maybeEncodedWalletId && Wallet.isValidFormattedId(maybeEncodedWalletId)) {
      // The format may be valid but special characters in the currencyCode
      // need to be re-encoded since certain erc20s contain $, %, @ etc.
      const walletId = Wallet.generateId(Wallet.propsFromId(maybeEncodedWalletId));
      return walletId;
    }

    const decodedWalletId = decodeURIComponent(maybeEncodedWalletId ?? '');

    // need to re-encode the currencyCode since certain erc20s contain $, %, @ etc.
    const walletId = Wallet.generateId(Wallet.propsFromId(decodedWalletId));

    if (Wallet.isValidFormattedId(walletId)) {
      return walletId;
    }

    cbReportError({
      context: 'wallets',
      error: new Error('Failed to generate wallet ID from decoded string'),
      severity: 'error',
      isHandled: false,
    });
  }

  static propsFromId(id: Wallet['id']) {
    const props = id.split('/');

    const blockchainStr = props[0];
    // may contain slashes if originally came from fetched tx
    const currencyCodeStr = decodeURIComponent(props[1]);
    const networkStr = `${props[2]}/${props[3]}`;
    const contractAddress = props[4];
    const selectedIndexStr = props[5];
    const accountId = `${props[6]}/${props[7]}/${props[8]}`;

    return {
      blockchain: new Blockchain(blockchainStr),
      currencyCode: new CurrencyCode(currencyCodeStr),
      network: Network.create(networkStr) as Network,
      contractAddress,
      selectedIndex: selectedIndexStr ? BigInt(selectedIndexStr) : undefined,
      accountId,
    };
  }

  /**
   * Verifies an existing, validly formatted wallet ID, which can be
   * parsed as expected by Wallet.propsFromId.
   *
   * Wallet.id includes 6 data pieces separated by a slash.
   * Inner network str includes 1 slash.
   * Inner Account.id str includes 2 slashes.
   * Wallet.id expected to be split into 9 pieces, verifying exactly 8 slashes.
   *
   * Examples:
   *
   * 6 data pieces: SOL/MSOL/<network>/mSOL123/0/<accountId>
   * network: SOLANA_CHAIN:101/false
   * accountId: mnemonic/ETH/0x456
   * RESULT: "SOL/MSOL/SOLANA_CHAIN:101/false/mSOL123/0/mnemonic/ETH/0x456"
   *
   * 6 data pieces (w/empty contract address): ETH/AVAX/<network>//0/<accountId>
   * network: ETHEREUM_CHAIN:43114/false
   * accountId: mnemonic/ETH/0x456
   * RESULT: "ETH/AVAX/ETHEREUM_CHAIN:43114/false//0/mnemonic/ETH/0x456"
   *
   * @see Wallet.generateID
   */
  static isValidFormattedId(id: Wallet['id'] | undefined) {
    return !!id && id.split('/').length === 9;
  }

  static txScannerId(wallet: Wallet): string {
    return `${wallet.blockchain.rawValue.toLowerCase()}/${
      wallet.network.asChain()?.chainId ?? ''
    }/${String(wallet.network.isTestnet)}`;
  }
}

// Validates whether a numeric string is a positive hex or safe positive integer value
// Used for custom network chain id verification

export function isValidHexOrInteger(value?: string | null): boolean {
  if (!value) {
    return false;
  }

  if (value === '0') {
    return false;
  }

  const sanitizedValue = value.trim().toLocaleLowerCase();
  return (
    // test for a hex, "0x" followed by one or more hexadecimal digits (0-9, a-f, or A-F).
    /^0x[0-9a-f]+$/i.test(sanitizedValue) ||
    // test for a string containing 1 to 15 digits, to avoid unnecessary isSafeInteger checks for 15+ digits
    (/^[0-9]{1,15}$/.test(sanitizedValue) && Number.isSafeInteger(Number(sanitizedValue)))
  );
}

/**
 * Receives a numeric string and returns as positive integer
 *
 * @example
 * '0x1234' => 4660
 * '1234' => 1234
 * '0.0' => throws
 * '-5' => throws
 * 'not a number' => throws
 */
export function asPositiveInteger(value: string): number {
  const sanitizedValue = value.trim().toLocaleLowerCase();

  if (!isValidHexOrInteger(value)) {
    throw Error('invalid numeric value');
  }

  if (sanitizedValue.startsWith('0x')) {
    return parseInt(sanitizedValue, 16);
  }

  return parseInt(sanitizedValue, 10);
}

// Helper function to convert scientific number like 2.5e2 to simplified '250' string
export function scientificToDecimal(num: number): string {
  const nsign = Math.sign(num);
  // remove the sign
  let modifiedNum = Math.abs(num).toString();
  // if the number is in scientific notation remove it
  if (/\d+\.?\d*e[+-]*\d+/i.test(modifiedNum)) {
    const zero = '0';
    const parts = String(num).toLowerCase().split('e'); // split into coeff and exponent
    const e = Number(parts.pop()); // store the exponential part
    let l = Math.abs(e); // get the number of zeros
    const sign = e / l;
    const coeffArray = parts[0].split('.');
    if (sign === -1) {
      l -= coeffArray[0].length;
      if (l < 0) {
        modifiedNum = `${coeffArray[0].slice(0, l)}.${coeffArray[0].slice(l)}${
          coeffArray.length === 2 ? coeffArray[1] : ''
        }`;
      } else {
        modifiedNum = `${zero}.${new Array(l + 1).join(zero)}${coeffArray.join('')}`;
      }
    } else {
      const dec = coeffArray[1];
      if (dec) l -= dec.length;
      if (l < 0) {
        modifiedNum = `${coeffArray[0] + dec.slice(0, l)}.${dec.slice(l)}`;
      } else {
        modifiedNum = coeffArray.join('') + new Array(l + 1).join(zero);
      }
    }
  }

  return nsign < 0 ? `-${modifiedNum.replace('-', '')}` : modifiedNum;
}

export function isScientificNotation(str: string) {
  // Regular expression for scientific notation
  const sciNotationRegex = /^[+-]?\d+(\.\d+)?[eE][+-]?\d+$/;

  return sciNotationRegex.test(str);
}

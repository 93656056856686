import { useCallback } from 'react';
import { useIsExperimentModeEnabled } from 'cb-wallet-data/hooks/ExperimentMode/useIsExperimentModeEnabled';
import { useUserWalletSettings } from 'cb-wallet-data/stores/AssetManagement/hooks/useUserWalletSettings';
import { AssetManagementStatus as status } from 'cb-wallet-data/stores/AssetManagement/models/AssetManagementStatus';
import { UserWalletSetting } from 'cb-wallet-data/stores/AssetManagement/models/UserWalletSetting';
import { areLowBalanceWalletsHiddenByWalletGroupIdAtom } from 'cb-wallet-data/stores/AssetManagement/state';
import { useExchangeRatesMap } from 'cb-wallet-data/stores/ExchangeRates/hooks/useExchangeRates';
import { areSmallBalanceWalletsHiddenAtom } from 'cb-wallet-data/stores/User/state';
import { WalletGroup } from 'cb-wallet-data/stores/WalletGroups/models/WalletGroup';
import Decimal from 'decimal.js';
import { useRecoilValue } from 'recoil';

import { Wallet } from '../models/Wallet';
import { isSpamScoreEnabledAtom } from '../state';
import { checkIfIsLowBalanceWallet } from '../utils/checkIfIsLowBalanceWallet';

type IsVisibleWalletOptions = {
  exchangeRatesMap: Record<string, Decimal>;
  userWalletSettings?: Record<string, UserWalletSetting>;
  isExperimentModeEnabled: boolean;
  areLowBalanceWalletsHidden: boolean;
  areSmallBalanceWalletsHidden?: boolean;
  isSpamScoreEnabled?: boolean;
};

export function isVisibleWallet(
  wallet: Wallet,
  {
    exchangeRatesMap,
    userWalletSettings,
    isExperimentModeEnabled,
    areLowBalanceWalletsHidden,
    areSmallBalanceWalletsHidden = false,
    isSpamScoreEnabled = false,
  }: IsVisibleWalletOptions,
) {
  const userWalletSettingId = UserWalletSetting.generateID(
    wallet.primaryAddress,
    wallet.blockchain,
    wallet.currencyCode,
    wallet.network,
    wallet.contractAddress,
  );

  if (
    (areLowBalanceWalletsHidden || areSmallBalanceWalletsHidden) &&
    checkIfIsLowBalanceWallet(
      wallet,
      exchangeRatesMap,
      isExperimentModeEnabled,
      areSmallBalanceWalletsHidden,
    )
  ) {
    return false;
  }

  if (!userWalletSettings) return true;

  const userWalletSetting = userWalletSettings[userWalletSettingId];

  if (
    isSpamScoreEnabled &&
    ((userWalletSetting?.status === undefined && wallet?.isSpam) ||
      userWalletSetting?.status === status.userHidden)
  ) {
    return false;
  }

  return [status.userVisible, status.userSpamVisible, undefined].includes(
    userWalletSetting?.status,
  );
}

/**
 * Returns a function that determines if a wallet is visible.
 *
 * isVisibleWallet is separated out for use in visiblePortfolioWalletsByWalletGroupSelector.
 * isVisibleWalletCallback is used in prepareWalletsByWalletGroup to construct fiatBalancesByWalletGroup.
 * fiatBalancesByWalletGroup is used to display balances of each wallet group in the WalletSwitcher whether active/inactive.
 */
export function useGetIsVisibleWallet() {
  const exchangeRatesMap = useExchangeRatesMap();
  const isExperimentModeEnabled = useIsExperimentModeEnabled();
  const userWalletSettings = useUserWalletSettings();
  const areSmallBalanceWalletsHidden = useRecoilValue(areSmallBalanceWalletsHiddenAtom);
  const isSpamScoreEnabled = useRecoilValue(isSpamScoreEnabledAtom);
  const areLowBalanceWalletsHiddenForWalletGroup = useRecoilValue(
    areLowBalanceWalletsHiddenByWalletGroupIdAtom,
  );

  return useCallback(
    function isVisibleWalletCallback(wallet: Wallet, walletGroupId: WalletGroup['id']) {
      const areLowBalanceWalletsHiddenForWg =
        areLowBalanceWalletsHiddenForWalletGroup[walletGroupId];

      return isVisibleWallet(wallet, {
        exchangeRatesMap,
        userWalletSettings,
        isExperimentModeEnabled,
        areLowBalanceWalletsHidden: areLowBalanceWalletsHiddenForWg,
        areSmallBalanceWalletsHidden,
        isSpamScoreEnabled,
      });
    },
    [
      areLowBalanceWalletsHiddenForWalletGroup,
      areSmallBalanceWalletsHidden,
      exchangeRatesMap,
      isExperimentModeEnabled,
      isSpamScoreEnabled,
      userWalletSettings,
    ],
  );
}

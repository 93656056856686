import { parseQueryArgs } from '@tanstack/query-core';
import {
  DefinedUseQueryResult,
  QueryFunction,
  QueryFunctionContext,
  QueryKey,
  // eslint-disable-next-line no-restricted-imports
  useQuery as useQueryBase,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { triggerReactQueryUndefinedResponse } from 'cb-wallet-data/hooks/eventing';

export function useQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options:
    | (Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'initialData'> & {
        initialData?: () => undefined;
      })
    | UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
): UseQueryResult<TData, TError>;

export function useQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'initialData'> & {
    initialData: TQueryFnData | (() => TQueryFnData);
  },
): DefinedUseQueryResult<TData, TError>;

export function useQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  options?:
    | (Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'initialData'> & {
        initialData?: () => undefined;
      })
    | Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey'>,
): UseQueryResult<TData, TError>;

export function useQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  options?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'queryKey' | 'initialData'
  > & { initialData: TQueryFnData | (() => TQueryFnData) },
): DefinedUseQueryResult<TData, TError>;

export function useQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?:
    | (Omit<
        UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
        'queryKey' | 'queryFn' | 'initialData'
      > & { initialData?: () => undefined })
    | Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TData, TError>;

export function useQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'queryKey' | 'queryFn' | 'initialData'
  > & { initialData: TQueryFnData | (() => TQueryFnData) },
): DefinedUseQueryResult<TData, TError>;

export function useQuery<
  TQueryFnData,
  TError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  arg1: TQueryKey | UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  arg2?:
    | QueryFunction<TQueryFnData, TQueryKey>
    | UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  arg3?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
): UseQueryResult<TData, TError> {
  const parsedOptions = parseQueryArgs(arg1, arg2, arg3);

  const { queryKey, queryFn, ...options } = parsedOptions;

  const queryFunction = async function baseQueryFn(args: QueryFunctionContext<TQueryKey, unknown>) {
    if (typeof queryFn !== 'function') {
      return null;
    }

    const response = await queryFn(args);

    if (response === undefined) {
      triggerReactQueryUndefinedResponse(JSON.stringify(queryKey));
      return null;
    }

    return response;
  };

  return useQueryBase(
    queryKey as TQueryKey,
    queryFunction as QueryFunction<TQueryFnData, TQueryKey>,
    options,
  );
}

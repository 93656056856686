import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Help',
    description: 'The help setting category',
  },
  description: {
    defaultMessage: 'Help center & support',
    description: 'The help list cell description',
  },
});

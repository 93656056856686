import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { useOnMount } from 'cb-wallet-data/hooks/useOnMount';
import { getAccountByWalletGroupId } from 'cb-wallet-data/stores/WalletGroups/state';
import { useRecoilValue } from 'recoil';

import { Account } from '../models/Account';

const NO_ACCOUNT_FOUND = new Error('No Account found for ID in useAccountByWalletGroupId');

export function useAccountByWalletGroupId(walletGroupId: string | undefined): Account | undefined {
  const account = useRecoilValue(getAccountByWalletGroupId(walletGroupId));

  useOnMount(() => {
    if (!account) {
      cbReportError({
        context: 'multi-account',
        error: NO_ACCOUNT_FOUND,
        isHandled: false,
        severity: 'error',
      });
    }
  });

  return account;
}

import { useCallback } from 'react';

import {
  createWithObservability,
  CreateWithObservabilityParams,
  WithObservabilityFunction,
} from './createWithObservability';

/**
 * Returns a function that wraps a passed-in sync/async function with start and completed/failed eventing sent to Amplitude.
 *
 * This returned function is intended to be used to wrap one of multiple steps in a complex workflow (e.g. creating a new
 * user, signing a user out, etc.) where increased observability is desired to allow us to track a user's progress through
 * said workflow.
 *
 * The Amplitude event names are generic, but consumers can define an overall `stepWorkflow` to group them
 * as well as an individual `stepName`s to identify each step. This can be used to create various segmentation, conversion
 * funnels, or other types of charts in Amplitude.
 *
 * This returned function will also call any optional `onStepStart` and `onStepCompleted`/`onStepFailed` events before
 * and then after the step's function is called, respectively. Each event will contain the `stepWorkflow` and `stepName`
 * parameters passed to this function. The `onStepFailed` event will also contain the error that was thrown by the step's
 * function.
 *
 * **NOTE:** You can use `cb-wallet-analytics/test/buildUseWithObservabilityMocks` to help mock this functionality when
 * writing unit tests.
 *
 * @returns See {@link WithObservabilityFunction}
 */
export function useWithObservability(
  params: CreateWithObservabilityParams,
): WithObservabilityFunction {
  const { stepWorkflow, onStepStart, onStepCompleted, onStepFailed } = params;

  return useCallback(
    async function withObservability(stepName, stepFunction) {
      const innerWithObservability = createWithObservability({
        stepWorkflow,
        onStepStart,
        onStepCompleted,
        onStepFailed,
      });
      return innerWithObservability(stepName, stepFunction);
    },
    [onStepCompleted, onStepFailed, onStepStart, stepWorkflow],
  );
}

import bitcoin from 'bitcoinjs-lib';

import { derivePublicKey } from './derivePublicKey';
import { getNetwork } from './getNetwork';
import { UTXOChains } from './indexer';

type DeriveLegacyAddressFromXpubKeyParams = {
  blockchainSymbol: UTXOChains;
  xpubKey: string;
  index: number;
  change: boolean;
  testnet: boolean;
};

export async function deriveLegacyAddressFromXpubKey({
  blockchainSymbol,
  xpubKey,
  index,
  change,
  testnet,
}: DeriveLegacyAddressFromXpubKeyParams): Promise<string> {
  const network = getNetwork(blockchainSymbol, testnet);
  const publicKey = await derivePublicKey({ xpubKey, index, change });
  const p2pkh = bitcoin.payments.p2pkh({ pubkey: publicKey, network });
  return p2pkh.address;
}

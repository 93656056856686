type HTMLVideoPlayerProps = React.DetailedHTMLProps<
  React.VideoHTMLAttributes<HTMLVideoElement>,
  HTMLVideoElement
>;

type VideoPlayerProps = { preview?: boolean } & Omit<HTMLVideoPlayerProps, 'ref'>;

export function VideoPlayer({ src, preview, ...playerProps }: VideoPlayerProps) {
  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video
      {...playerProps}
      disablePictureInPicture
      disableRemotePlayback
      preload="metadata"
      controlsList="nofullscreen nodownload noremoteplayback noplaybackrate"
    >
      <source src={`${src}#t=0.001${(preview && ',0.002') ?? ''}`} data-testid="video-source" />
    </video>
  );
}

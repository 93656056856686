import { useCallback, useMemo } from 'react';
import { triggerIsRepairDbWithWalletsIfNeededKilled } from 'cb-wallet-analytics/app-load';
import { resetSyncedBlockheights } from 'cb-wallet-data/AddressHistory/utils/blockheightSyncing';
import {
  hasRunTxOrUserOpMigration,
  setHasRunTxOrUserOpMigration,
} from 'cb-wallet-data/hooks/initialization/hasRunTxOrUserOpMigration';
import { openDb } from 'cb-wallet-data/hooks/initialization/openDb';
import { useHydrateDb } from 'cb-wallet-data/hooks/initialization/useHydrateDb';
import { useMigrateDb } from 'cb-wallet-data/hooks/initialization/useMigrateDb';
import { getAccounts } from 'cb-wallet-data/stores/Accounts/database';
import { AccountType } from 'cb-wallet-data/stores/Accounts/models/AccountTypes';
import { DefineIsExpectedToBeSignedInCallback } from 'cb-wallet-data/stores/Accounts/utils/calcIsExpectedToBeSignedIn';
import {
  repairDbWithWalletsIfNeeded,
  status as repairStatus,
} from 'cb-wallet-data/stores/Accounts/utils/repairDbWithWalletsIfNeeded';
import { useIsRepairDbWithWalletsIfNeededKilled } from 'cb-wallet-data/stores/AppLoad/hooks/useIsRepairDbWithWalletsIfNeededKilled';
import { getPlatformName } from 'cb-wallet-metadata/metadata';
import { Store } from 'cb-wallet-store/Store';
import { PlatformName } from '@cbhq/client-analytics';

import { useAppLoadVersion } from './useAppLoadVersion';

type UseInitializeDbProps = {
  defineIsExpectedToBeSignedInCallback?: DefineIsExpectedToBeSignedInCallback;
  deriveAccountType?: () => AccountType;
  overrideRunDataMigration: boolean;
  preMigrationRepairCurrentPlatform?: () => void;
  postMigrationRepairCurrentPlatform?: (accountType: AccountType) => Promise<void>;
  repairDbWithNoWallets?: () => Promise<void>;
};

type InitializeDbParams = {
  defineIsExpectedToBeSignedInCallback?: DefineIsExpectedToBeSignedInCallback;
  hasSecrets?: boolean;
  isRestoringFromDeviceBackup?: boolean;
  isAuthStateAccessible?: boolean;
  obscuredAccountIdsFromSecrets?: string[];
};

/**
 * ONLY INVOKE THIS ONCE IN App.tsx (Extension) / RootStack.tsx (RN)
 *
 * Performs all required steps to initialize DB.
 * It sets DB config and opens the connection
 */
export function useInitializeDb({
  deriveAccountType,
  overrideRunDataMigration,
  preMigrationRepairCurrentPlatform,
  postMigrationRepairCurrentPlatform,
  repairDbWithNoWallets,
}: UseInitializeDbProps) {
  // kill switches
  const isRepairDbWithWalletsIfNeededKilled = useIsRepairDbWithWalletsIfNeededKilled();
  const { version } = useAppLoadVersion();
  const platform = useMemo(() => getPlatformName(), []);
  const hydrateDb = useHydrateDb();
  const { isMigrating, migrateDb } = useMigrateDb();

  const getDerivedAccountType = useCallback(
    async function getDerivedAccountType() {
      const fallbackAccountType =
        platform === PlatformName.extension ? AccountType.WALLET_LINK : AccountType.MNEMONIC;
      const accounts = await getAccounts();

      // deriveAccountType is optional because RN only supported mnemonic account types
      // For extension, deriveAccountType will exist, but we need a sane fallback.
      return accounts[0]?.type ?? deriveAccountType?.() ?? fallbackAccountType;
    },
    [deriveAccountType, platform],
  );

  /**
   * DATABASE SETUP - ONLY RUN ONCE ON APP LOAD
   *
   * See RepairDbWithWalletsIfNeededParams for more details on params passed through.
   *
   * Do NOT change the order of these calls
   *   1. Opens Store
   *   2. Opens DB
   *   3. Runs platform specific repair scripts (pre-migration)
   *   4. Migrates DB
   *   5. Runs platform specific repair scripts (post-migration)
   *   6. Validates DB
   *   7. Hydrates DB
   */
  const initializeDb = useCallback(
    async function initializeDb({
      defineIsExpectedToBeSignedInCallback,
      hasSecrets,
      isRestoringFromDeviceBackup,
      isAuthStateAccessible,
      obscuredAccountIdsFromSecrets,
    }: InitializeDbParams = {}) {
      Store.open();
      await openDb();

      if (preMigrationRepairCurrentPlatform) {
        preMigrationRepairCurrentPlatform();
      }

      const accountType = await getDerivedAccountType();

      await migrateDb({
        overrideRunDataMigration,
        accountType,
      });

      // One time migration required for destructive table migration from tx_history_v2 -> tx_or_userop_history
      // This ensures we fully repopulate UTXO history after this destructive table migration
      if (!hasRunTxOrUserOpMigration()) {
        const accounts = await getAccounts();
        for (const account of accounts) {
          resetSyncedBlockheights(account.id, 'transactions');
        }

        setHasRunTxOrUserOpMigration();
      }

      if (postMigrationRepairCurrentPlatform) {
        await postMigrationRepairCurrentPlatform(accountType);
      }

      // Check state of the db and make any repairs, before hydrating recoil.
      triggerIsRepairDbWithWalletsIfNeededKilled(isRepairDbWithWalletsIfNeededKilled);

      if (!isRepairDbWithWalletsIfNeededKilled) {
        // 1. Repairs db silently to user without any auth triggered. Requires wallets to exist.
        const { status } = await repairDbWithWalletsIfNeeded({
          appLoadVersion: version,
          defineIsExpectedToBeSignedInCallback,
          isAuthStateAccessible,
          obscuredAccountIdsFromSecrets,
          hasSecrets,
          isRestoringFromDeviceBackup,
        });

        // 2. If wallets, accounts and wallets groups didn't exist in above repair attempt and
        // user is signed in, repairs are deferred here; this will trigger auth, and re-create
        // all required data.
        if (status === repairStatus.deferred && repairDbWithNoWallets) {
          // This will never trigger for extension, since repairDbWithNoWallets is only provided
          // for RN from RootStack.
          await repairDbWithNoWallets();
        }
      }

      await hydrateDb();
    },
    [
      preMigrationRepairCurrentPlatform,
      getDerivedAccountType,
      migrateDb,
      overrideRunDataMigration,
      postMigrationRepairCurrentPlatform,
      isRepairDbWithWalletsIfNeededKilled,
      hydrateDb,
      version,
      repairDbWithNoWallets,
    ],
  );

  return useMemo(() => ({ initializeDb, isMigrating }), [initializeDb, isMigrating]);
}

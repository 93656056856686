import { UTXOChains } from './indexer';
import { validateAddress as validateAddressGeneric } from './validateAddress';

type IsAddressValidParams = {
  blockchainSymbol: UTXOChains;
  address: string;
  testnet: boolean;
};

export function isAddressValid({
  blockchainSymbol,
  address,
  testnet,
}: IsAddressValidParams): boolean {
  try {
    validateAddress({
      blockchainSymbol,
      address,
      testnet,
    });
  } catch {
    return false;
  }

  return true;
}

type ValidateAddressParams = {
  blockchainSymbol: UTXOChains;
  address: string;
  testnet: boolean;
};

export function validateAddress({
  blockchainSymbol,
  address,
  testnet,
}: ValidateAddressParams): string {
  switch (blockchainSymbol) {
    case 'BTC':
    case 'LTC':
    case 'DOGE':
      return validateAddressGeneric(blockchainSymbol, address, testnet);
    default:
      const _exhaustiveCheck: never = blockchainSymbol;
      throw new Error(_exhaustiveCheck);
  }
}

import addAccountIdFieldToWalletsAndAddresses from './migrations/addAccountIdFieldToWalletsAndAddresses';
import addAccountIdToWalletsAndAddresses from './migrations/addAccountIdToWalletsAndAddresses';
import initHotfixVersion from './migrations/initHotfixVersion';
import migrateDappConnections from './migrations/migrateDappConnections';
import migrateForMultiAccount from './migrations/migrateForMultiAccount';
import migrateLedgerWalletGroupWalletIndexes from './migrations/migrateLedgerWalletGroupWalletIndexes';
import migrateThemeColor from './migrations/migrateThemeColor';

// Webpack only supports dynamic imports with Babel >7.5
// Metro does not support dynamic imports
// Please keep the key in this format YYYYMMDD_HHMMSS_nameOfMigration.
export const DATA_MIGRATION_FILES = [
  ['20220803_000000_initHotfixVersion', initHotfixVersion],
  ['20220818_000000_migrateForMultiAccount', migrateForMultiAccount],
  ['20220823_000000_migrateDappConnections', migrateDappConnections],
  ['20220823_000001_migrateThemeColor', migrateThemeColor],
  ['20221026_000000_addAccountIdFieldToWalletsAndAddresses', addAccountIdFieldToWalletsAndAddresses], // prettier-ignore
  ['20221026_000001_migrateLedgerWalletGroupWalletIndexes', migrateLedgerWalletGroupWalletIndexes],
  ['20221101_000000_addAccountIdToWalletsAndAddresses', addAccountIdToWalletsAndAddresses],
] as const;

// used for testing
export function getDataMigrationFiles() {
  return DATA_MIGRATION_FILES;
}

import { getJSON } from 'cb-wallet-http/fetchJSON';
import { HTTPError } from 'cb-wallet-http/HTTPError';
import {
  TransactionSyncingError,
  TransactionSyncingRateLimitError,
} from 'wallet-engine-signing/history/errors';

import { EthereumAddressHistory } from './types';

// action=tokentx
export type EtherscanTokenTransactionResponse = {
  from: string;
  to: string;
  gasUsed: string;
  gasPrice: string;
  isError: string;
  hash: string;
  value: string;
  timeStamp: string;
  tokenSymbol: string;
  contractAddress: string;
  tokenName: string;
  tokenDecimal: string;
  nonce: string;
};

// action=txlist
export type EtherscanEthereumTransactionResponse = {
  from: string;
  to: string;
  gasUsed: string;
  gasPrice: string;
  isError: string;
  hash: string;
  value: string;
  timeStamp: string;
  contractAddress: string;
  nonce: string;
};

export type EtherscanResult =
  | EtherscanTokenTransactionResponse
  | EtherscanEthereumTransactionResponse;

enum EtherscanStatusCode {
  Success = '1',
  Error = '0',
}

type MakeEtherscanRequestParams = {
  action: 'tokentx' | 'txlist';
  address: string;
  chainProxyTarget?: string;
  etherscanLikeApiKey?: string;
  etherscanCompatibleTxHistoryApi: string;
  page: bigint;
  perPage: bigint;
};

type EtherscanResponse<T = EtherscanResult> = {
  status: string;
  message: string;
  result: T[];
};

export async function getEtherscanEthereumTransactionsPage({
  addressConfig,
  perPage,
}: {
  addressConfig: EthereumAddressHistory;
  perPage: number;
}): Promise<EtherscanEthereumTransactionResponse[]> {
  try {
    const { status, result } = await makeEtherscanRequest<EtherscanEthereumTransactionResponse>({
      action: 'txlist',
      address: addressConfig.address,
      chainProxyTarget: addressConfig.chainProxyTarget,
      etherscanLikeApiKey: addressConfig.etherscanLikeApiKey,
      etherscanCompatibleTxHistoryApi: addressConfig.etherscanCompatibleTxHistoryApi,
      page: 1n,
      perPage: BigInt(perPage),
    });

    if (
      status === undefined ||
      (status !== EtherscanStatusCode.Success && !Array.isArray(result))
    ) {
      throw new TransactionSyncingRateLimitError();
    }

    return result;
  } catch (err) {
    if (err instanceof TransactionSyncingRateLimitError || (err as HTTPError)?.status === 429) {
      const errMessage = `Error ${
        (err as HTTPError)?.status
      } fetching etherscan Ethereum transactions on
       chain ${addressConfig.chainId}
       for ${addressConfig.address}`;

      throw new TransactionSyncingRateLimitError(errMessage);
    }

    throw new TransactionSyncingError((err as Error).message, 'ETH');
  }
}

export async function getEtherscanTokenTransactionsPage({
  addressConfig,
  perPage,
}: {
  addressConfig: EthereumAddressHistory;
  perPage: number;
}): Promise<EtherscanTokenTransactionResponse[]> {
  try {
    const { status, result } = await makeEtherscanRequest<EtherscanTokenTransactionResponse>({
      action: 'tokentx',
      address: addressConfig.address,
      chainProxyTarget: addressConfig.chainProxyTarget,
      etherscanLikeApiKey: addressConfig.etherscanLikeApiKey,
      etherscanCompatibleTxHistoryApi: addressConfig.etherscanCompatibleTxHistoryApi,
      page: 1n,
      perPage: BigInt(perPage),
    });

    if (status === undefined || (status !== '1' && !Array.isArray(result))) {
      throw new TransactionSyncingRateLimitError();
    }

    return result;
  } catch (err) {
    if (err instanceof TransactionSyncingRateLimitError || (err as HTTPError)?.status === 429) {
      const errMessage = `Error ${
        (err as HTTPError)?.status
      } fetching etherscan erc20 transactions on
       chain ${addressConfig.chainId}
       for ${addressConfig.address}`;

      throw new TransactionSyncingRateLimitError(errMessage);
    }

    throw new TransactionSyncingError((err as Error).message, 'ETH');
  }
}

export async function makeEtherscanRequest<T>({
  action,
  address,
  chainProxyTarget,
  etherscanLikeApiKey,
  etherscanCompatibleTxHistoryApi,
  page,
  perPage,
}: MakeEtherscanRequestParams): Promise<EtherscanResponse<T>> {
  const params = {
    module: 'account',
    action,
    address,
    page: page.toString(),
    offset: perPage.toString(),
    sort: 'desc',
    ...(chainProxyTarget ? { targetName: chainProxyTarget } : {}),
    ...(etherscanLikeApiKey ? { apikey: etherscanLikeApiKey } : {}),
  };

  return getJSON<EtherscanResponse<T>>(`${etherscanCompatibleTxHistoryApi}/api`, params, {
    isThirdParty: true,
  });
}

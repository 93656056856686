// eslint-disable-next-line no-restricted-imports, @cbhq/no-lib-import
import { ScopedLocalStorage as BaseScopedLocalStorage } from '@coinbase/wallet-sdk/dist/lib/ScopedLocalStorage';

export class ScopedLocalStorage extends BaseScopedLocalStorage {
  getAllItems(): string[] {
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    if (typeof window === 'undefined' || typeof window.localStorage === 'undefined') {
      return [];
    }

    const result: string[] = [];
    for (const [key, value] of Object.entries(localStorage)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (key.startsWith((this as any).scope)) {
        result.push(value);
      }
    }
    return result;
  }
}

/* eslint-disable react-perf/jsx-no-new-object-as-prop */
/* eslint-disable react/function-component-definition */
/* eslint-disable import/no-default-export */
import {
  Children,
  createContext,
  Fragment,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import last from 'lodash/last';

import { Transitioner } from '../transitioner';

import { CreateStepperParams, StepContext, StepperType, StepsProps } from './types';

function noop() {}

const defaultContextValues = {
  goToStep: noop,
  goBack: noop,
  setContextHistory: noop,
  history: [],
} as const;

export default function createStepper<T extends string>({
  steps,
  defaultStep,
  noTransitioner,
}: CreateStepperParams<T>): StepperType<T> {
  const context = createContext<StepContext<T>>(defaultContextValues as any);

  const Provider = ({
    children,
    defaultStep: defaultStepOverride,
  }: {
    children: React.ReactNode;
    defaultStep?: CreateStepperParams<T>['defaultStep'];
  }) => {
    const [history, setHistory] = useState<T[]>([defaultStepOverride || defaultStep]);
    const currentStep = useMemo(() => last(history) ?? defaultStep, [history]);

    const goToStep = useCallback((step: T) => {
      setHistory((oldHistory) => [...oldHistory, step]);
    }, []);

    const replaceStep = useCallback((step: T) => {
      setHistory((oldHistory) => {
        return [...oldHistory.slice(0, oldHistory.length - 1), step];
      });
    }, []);

    const goBack = useCallback(() => {
      setHistory((oldHistory) => {
        if (oldHistory.length <= 1) return oldHistory;
        return oldHistory.slice(0, oldHistory.length - 1);
      });
    }, []);

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const setContextHistory = useCallback((history: any) => {
      setHistory(history);
    }, []);

    const previousStep = useMemo(() => {
      return history[history.length - 2];
    }, [history]);

    return (
      <context.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={{
          goToStep,
          replaceStep,
          goBack,
          setContextHistory,
          currentStep,
          previousStep,
          history,
          steps,
          hasPreviousStep: !!previousStep,
        }}
      >
        {children}
      </context.Provider>
    );
  };

  const Steps = ({ children, ...transitionerProps }: StepsProps<T>) => {
    const { currentStep } = useContext(context);

    if (noTransitioner) {
      return Children.map(children, (child) => {
        if (child?.key === currentStep) return child;
        return null;
      }) as any;
    }

    return (
      <Transitioner step={currentStep} {...transitionerProps}>
        {children}
      </Transitioner>
    );
  };

  return {
    context,
    Provider,
    Steps,
    Step: Fragment,
  };
}

import { hashAddressToNumber } from 'cb-wallet-data/stores/Profile/utils/hashAddressToNumber';

export const NUMBER_OF_DEFAULT_AVATARS_LEGACY = 20;
export const NUMBER_OF_DEFAULT_AVATARS = 56;

// Used to determine which of our 20 default avatars to show for a given address

export function getDefaultAvatarIdxByAddress(address: string, isMiamiViceEnable = false) {
  const number = isMiamiViceEnable ? NUMBER_OF_DEFAULT_AVATARS : NUMBER_OF_DEFAULT_AVATARS_LEGACY;
  return hashAddressToNumber(address) % number;
}

// TODO this code was copied over from cipher core
// Also, if you make changes to this code please make sure the equivalent code is updated
// in cipher-core as well.
import {
  EthereumNetworkConfigs,
  StoreKeys_hardhatNetwork,
} from 'cb-wallet-data/chains/AccountBased/Ethereum/config';
import { clearTransactionsForChain } from 'cb-wallet-data/chains/AccountBased/Ethereum/database';
import { EthereumNetworkMap } from 'cb-wallet-data/chains/AccountBased/Ethereum/EthereumChain';
import { fetchGasPrices } from 'cb-wallet-data/chains/AccountBased/Ethereum/Transactions/utils/gas';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { BNToBigint } from 'cb-wallet-data/utils/BigInt+Core';
import { strip0x } from 'cb-wallet-data/utils/String+Core';
import { getJSON } from 'cb-wallet-http/fetchJSON';
import { Store } from 'cb-wallet-store/Store';
import padStart from 'lodash/padStart';
import {
  bigIntFromHex,
  bufferFromHex,
  hexFromBigInt,
  hexFromBuffer,
  hexFromNumber,
  numberFromHex,
} from 'wallet-engine-signing/blockchains/Ethereum/formatNumbers';
import {
  callEthereumJSONRPC,
  getABIStringResult,
  getBNResult,
  getIntegerResult,
  handleErrorResponse,
} from 'wallet-engine-signing/blockchains/Ethereum/RPC';

// Selectors
export enum RPC_SELECTOR {
  balanceOf = '70a08231', // balanceOf(address)
  name = '06fdde03', // name()
  symbol = '95d89b41', // symbol()
  decimals = '313ce567', // decimals()
}

export type EthereumRpcResult = {
  jsonrpc: string;
  id: number;
  // eslint-disable-next-line
  result: any | null;
};

export type EthereumTransactionReceipt = {
  isSuccessful: boolean;
  blockNumber: number;
  blockHash: Buffer;
  contractAddress: string | null;
  gasUsed: bigint;
};

export function addressArgument(address: string): string {
  return padStart(strip0x(address).toLowerCase(), 64, '0');
}

// Address validation imports large math libraries.
async function addressIsValid(address: string): Promise<boolean> {
  const { Address } = await import('wallet-engine-signing/signing/ethereum/Address');
  return Address.isValid(address);
}

export async function getEthereumTransactionReceipt(
  transactionHash: string,
  chainId: number,
  blockHeight: bigint | null = null,
): Promise<EthereumTransactionReceipt | null> {
  const res = await callEthereumJSONRPC(
    'eth_getTransactionReceipt',
    [transactionHash],
    getEthereumRPCURL(chainId),
  );

  const ethResult = res as EthereumRpcResult;
  /**
   * when "eth_getTransactionReceipt" request is unsuccessful, ie tx was not found on the blockchain, the following is
   * returned {"jsonrpc":"2.0","id":1,"result":null}
   */
  if (ethResult.result === null) {
    return null;
  }
  const result = ethResult.result;

  if (
    (typeof result.status !== 'string' && result.status !== null) ||
    typeof result.blockNumber !== 'string' ||
    typeof result.blockHash !== 'string' ||
    typeof result.gasUsed !== 'string' ||
    (typeof result.contractAddress !== 'string' && result.contractAddress !== null)
  ) {
    throw new Error('unexpected response');
  }

  const txBlockNumber = bigIntFromHex(result.blockNumber);
  if (blockHeight !== null && txBlockNumber > blockHeight) {
    return null;
  }

  return {
    isSuccessful: result.status === '0x1' || result.status === null,
    blockNumber: numberFromHex(result.blockNumber),
    blockHash: bufferFromHex(result.blockHash),
    gasUsed: bigIntFromHex(result.gasUsed),
    contractAddress: result.contractAddress || null,
  };
}

export async function getWeiBalance(address: string, chainId: number): Promise<bigint> {
  if (!addressIsValid(address)) {
    throw new Error('address is invalid');
  }

  const res = await callEthereumJSONRPC(
    'eth_getBalance',
    [address.toLowerCase(), 'latest'],
    getEthereumRPCURL(chainId),
  );

  if (res.result && res.result === null) {
    throw new Error('balance unavailable');
  }

  const bnResult = getBNResult(res);
  return BNToBigint(bnResult);
}

export async function getERC20Balance(
  address: string,
  contractAddress: string,
  chainId: number,
): Promise<bigint> {
  if (!addressIsValid(address)) {
    throw new Error('address is invalid');
  }
  if (!addressIsValid(contractAddress)) {
    throw new Error('contract address is invalid');
  }

  const functionSelector = RPC_SELECTOR.balanceOf;
  const functionArgument = addressArgument(address);

  const res = await callEthereumJSONRPC(
    'eth_call',
    [
      {
        to: contractAddress.toLowerCase(),
        // 0x + function_selector (4 bytes) + address (32 bytes, left padded with 0)
        data: `0x${functionSelector}${functionArgument}`,
      },
      'latest',
    ],
    getEthereumRPCURL(chainId),
  );

  handleErrorResponse(res);

  if (!res.result || typeof res.result !== 'string') {
    throw new Error('unexpected response');
  } else if (res.result.length === 0) {
    return 0n;
  } else if (res.result === '0x') {
    throw new Error('balance unavailable');
  }

  const value = strip0x(res.result).slice(0, 64);
  const bigIntValue = bigIntFromHex(value);
  return bigIntValue;
}

export async function estimateEthereumGasUsage(
  fromAddress: string,
  toAddress: string | null,
  weiValue: bigint,
  data: Buffer,
  chainId: number,
): Promise<bigint> {
  if (!addressIsValid(fromAddress)) {
    throw new Error('from address is invalid');
  }
  if (toAddress && !addressIsValid(toAddress)) {
    throw new Error('to address is invalid');
  }

  const call: {
    from: string;
    to?: string;
    value: string;
    data: string;
  } = {
    from: fromAddress.toLowerCase(),
    value: hexFromBigInt(weiValue),
    data: hexFromBuffer(data),
  };

  if (toAddress) {
    call.to = toAddress.toLowerCase();
  }

  const res = await callEthereumJSONRPC('eth_estimateGas', [call], getEthereumRPCURL(chainId));

  const BNResult = getBNResult(res);
  return BNToBigint(BNResult);
}

export async function getEthereumBlockHeight(chainId: number): Promise<bigint> {
  const res = await callEthereumJSONRPC('eth_blockNumber', [], getEthereumRPCURL(chainId));
  const BNResult = getBNResult(res);
  return BNToBigint(BNResult);
}

// from https://github.com/NomicFoundation/hardhat/pull/3382/files
export type HardhatMetadata = {
  clientVersion: string;
  chainId: number;
  instanceId: string;
  latestBlockNumber: number;
  latestBlockHash: string;
  forkedNetwork?: {
    chainId: number;
    forkBlockNumber: number;
    forkBlockHash: string;
  };
};

type HardhatMetadataResponse = {
  id: number;
  jsonrpc: string;
  result: HardhatMetadata;
};
export async function getHardhatMetadata(): Promise<HardhatMetadataResponse> {
  const res = await callEthereumJSONRPC(
    'hardhat_metadata',
    [],
    getEthereumRPCURL(EthereumNetworkMap.whitelisted.LOCALHOST_8545.chainId),
  );
  return res;
}

export async function isHardhatCheck() {
  const localhost8545 = EthereumNetworkMap.whitelisted.LOCALHOST_8545;
  try {
    const { result } = await getHardhatMetadata();

    const storedHardhatMetadata: HardhatMetadata | undefined = Store.get(StoreKeys_hardhatNetwork);

    if (storedHardhatMetadata?.instanceId !== result.instanceId) {
      // clear stored transactions
      clearTransactionsForChain(localhost8545);
    }
    Store.set(StoreKeys_hardhatNetwork, result);
    return getBNResult({ result: hexFromNumber(result.latestBlockNumber) });
  } catch {
    // clear stored transactions
    clearTransactionsForChain(localhost8545);
    Store.set(StoreKeys_hardhatNetwork, undefined);
  }
}

const HARDHAT_BALANCE = 10000000000000000000000n;
export async function getHardhatSetBalance(address: string) {
  const res = await callEthereumJSONRPC(
    'hardhat_setBalance',
    [address, hexFromBigInt(HARDHAT_BALANCE)],
    getEthereumRPCURL(EthereumNetworkMap.whitelisted.LOCALHOST_8545.chainId),
  );
  return res;
}

export async function getConfirmedEthereumTransactionCount(
  address: string,
  network: Network,
  blockHeight: bigint | null = null,
): Promise<bigint> {
  if (address && !addressIsValid(address)) {
    throw new Error('to address is invalid');
  }

  const chainId = network.asChain()!.chainId;

  const height = blockHeight === null ? 'latest' : hexFromBigInt(blockHeight);

  const res = await callEthereumJSONRPC(
    'eth_getTransactionCount',
    [address.toLowerCase(), height],
    getEthereumRPCURL(chainId),
  );
  return BigInt(getIntegerResult(res));
}

export type ERC20Info = {
  address: string;
  name: string;
  symbol: string;
  decimals: number;
  imageUrl?: string;
  assetUUID?: string;
};

export async function getERC20InfoV2(contractAddress: string, chainId: bigint): Promise<ERC20Info> {
  const { result } = await getJSON<{ result: ERC20Info }>('getERC20Info', {
    chainId: chainId.toString(),
    contractAddress,
  });

  return result;
}

export async function getERC20Info(contractAddress: string, chainId: bigint): Promise<ERC20Info> {
  if (!addressIsValid(contractAddress)) {
    throw new Error('contract address is invalid');
  }

  const responses = await Promise.all(
    [RPC_SELECTOR.name, RPC_SELECTOR.symbol, RPC_SELECTOR.decimals].map(async (selector) =>
      callEthereumJSONRPC(
        'eth_call',
        [
          {
            to: contractAddress.toLowerCase(),
            data: `0x${selector}`,
          },
          'latest',
        ],
        getEthereumRPCURL(Number(chainId)),
      ),
    ),
  );

  const name = getABIStringResult(responses[0]);
  const symbol = getABIStringResult(responses[1]);
  const decimals = getBNResult(responses[2]).toNumber();

  if (!name && !symbol && !decimals) {
    throw new Error('erc20 info returned is empty');
  }

  return { address: contractAddress, name, symbol, decimals };
}

export async function getEthereumGasPrices(chainId: bigint): Promise<bigint[]> {
  const network = EthereumNetworkConfigs.get(chainId.toString());
  if (!network) {
    throw new Error(`unknown chain id ${chainId}`);
  }
  const { gasStationUrl } = network;

  if (gasStationUrl) {
    try {
      const gasPrices = await fetchGasPrices(gasStationUrl);
      return gasPrices;
    } catch (_) {
      // fallback to using eth_gasPrice
    }
  }

  const res = await callEthereumJSONRPC('eth_gasPrice', [], getEthereumRPCURL(Number(chainId)));

  return [BigInt(getIntegerResult(res))];
}

export function getEthereumRPCURL(chainId: number): string {
  if (!Number.isInteger(chainId)) {
    throw new Error(`chainId is invalid ${chainId}`);
  }
  const network = EthereumNetworkConfigs.get(chainId.toString());
  if (!network) {
    throw new Error(`unknown chain id ${chainId}`);
  }
  return network.rpcUrl;
}

import BN from 'bn.js';
import { Decimal } from 'decimal.js';
import createKeccakHash from 'keccak';
import padStart from 'lodash/padStart';

import { SanitizedBigDecimal } from 'wallet-engine-signing/util/SanitizedBigDecimal';

const HEXADECIMAL_STRING_REGEX = /^[a-f0-9]*$/;

export const maxUint256 = new BN(2).pow(new BN(256)).sub(new BN(1));

export function arrayFromHexStringResponse(response: unknown): string[] {
  const hex = ensureStringResponse(response);

  return arrayFromHex(hex);
}

export function arrayFromHex(hex: string): string[] {
  return strip0x(ensureHexString(hex)).match(new RegExp('.{1,64}', 'g')) || [];
}

export function bnFromResponse(response: unknown): BN {
  if (!response || typeof response !== 'string') {
    throw new Error('unexpected response');
  } else if (response.length === 0) {
    return new BN(0);
  } else if (response === '0x') {
    throw new Error('balance unavailable');
  }

  const value = strip0x(response).slice(0, 64);
  return bnFromHexStringResponse(value);
}

export function keccak256(data: Buffer | string): Buffer {
  const buf = data instanceof Buffer ? data : Buffer.from(data, 'utf8');
  return createKeccakHash('keccak256').update(buf).digest();
}

export function bnFromHexStringResponse(response: unknown): BN {
  const hex = ensureStringResponse(response);

  return bnFromHex(hex);
}

export function bnFromHex(hex: string): BN {
  if (hex.length === 0) {
    return new BN(0);
  }
  return new BN(ensureHexString(hex), 16);
}

export function numberFromHexStringResponse(response: unknown): number {
  const hex = ensureStringResponse(response);

  return numberFromHex(hex);
}

export function hexStringFromBuffer(buf: Buffer): string {
  return `0x${buf.toString('hex')}`;
}

export function bufferFromHexString(hex: string): Buffer {
  return Buffer.from(strip0x(hex), 'hex');
}

export function numberFromHex(hex: string): number {
  const bn = bnFromHex(hex);
  if (bn.lte(new BN(Number.MAX_SAFE_INTEGER))) {
    return bn.toNumber();
  }
  return Number(bn.toString(10));
}

export function stringFromHexStringResponse(response: unknown): string {
  const hex = ensureStringResponse(response);

  return stringFromHex(hex);
}

export function stringFromHex(hex: string): string {
  const h = strip0x(ensureHexString(hex));
  const len = numberFromHex(h.slice(64, 128));
  if (h.replace(/0/g, '') === '') {
    return '';
  }
  if (h.length <= 64) {
    return bufferFromHex(h).toString('utf8').replace(/\0+$/g, '');
  }
  return bufferFromHex(h.slice(128)).toString('utf8').slice(0, len);
}

export function bufferFromNumber(num: number, blankZero = true): Buffer {
  if (num === 0 && blankZero) {
    return Buffer.alloc(0);
  }
  return bufferFromHex(hexFromNumber(num, false));
}

export function bufferFromBN(bn: BN, blankZero = true): Buffer {
  if (bn.isZero() && blankZero) {
    return Buffer.alloc(0);
  }
  return bn.toArrayLike(Buffer);
}

export function bufferFromHex(hex: string): Buffer {
  if (hex.length === 0) {
    return Buffer.alloc(0);
  }
  return Buffer.from(evenLengthHex(hex, false), 'hex');
}

export function bufferFromHexArray(...args: string[]) {
  return bufferFromHex(args.join(''));
}

export function bigDecimalFromNumber(n: Decimal.Value): SanitizedBigDecimal {
  return SanitizedBigDecimal.create(n);
}

export function bigDecimalFromBN(value: BN): SanitizedBigDecimal {
  return SanitizedBigDecimal.create(value.toString());
}

export function bnFromBigDecimal(value: SanitizedBigDecimal): BN {
  return new BN(value.toFixed(0));
}

export function hexFromNumber(num: number, includePrefix = true): string {
  const hex = bnFromNumber(num).toString(16);
  return includePrefix ? `0x${hex}` : hex;
}

export function hexFromBuffer(buf: Buffer, includePrefix = true): string {
  const hex = buf.toString('hex');
  return includePrefix ? `0x${hex}` : hex;
}

export function hexFromBN(bn: BN, includePrefix = true): string {
  const hex = bn.toString(16);
  return includePrefix ? `0x${hex}` : hex;
}

export function abiParamIndex(hex: string): number {
  const value = bnFromHex(hex);
  return value.div(new BN(32)).toNumber();
}

export function abiAddress(address: string): string {
  return padStart(strip0x(address).toLowerCase(), 64, '0');
}

export function abiUInt256FromBN(value: BN): string {
  return padStart(value.toString(16).toLowerCase(), 64, '0');
}

export function abiUInt256FromNumber(value: number): string {
  return abiUInt256FromBN(new BN(value));
}

export function prepend0x(hex: string): string {
  if (hex.startsWith('0x') || hex.startsWith('0X')) {
    return `0x${hex.slice(2)}`;
  }
  return `0x${hex}`;
}

export function addressFromHexStringResponse(response: unknown): string {
  const hex = ensureStringResponse(response);

  return prepend0x(strip0x(hex).slice(24));
}

export function strip0x(hex: string): string {
  if (hex.startsWith('0x') || hex.startsWith('0X')) {
    return hex.slice(2);
  }
  return hex;
}

export function bnFromNumber(num: number): BN {
  const intNum = Math.floor(num);
  if (intNum <= Number.MAX_SAFE_INTEGER) {
    return new BN(intNum);
  }
  return new BN(intNum.toString(10));
}

// Returns the gas limit with a fingerprinted amount, used to identify Wallet
// transactions for analytics
export function fingerprintGasLimit(n: BN): BN {
  const tenThousand = new BN(10000);
  const relevantDigits = n.mod(tenThousand);
  let difference = new BN(7777).sub(relevantDigits);

  if (difference.isNeg()) {
    difference = difference.add(tenThousand);
  }

  return n.add(difference);
}

function evenLengthHex(hex: string, includePrefix = true): string {
  let h = ensureHexString(hex);
  if (h.length % 2 === 1) {
    h = `0${h}`;
  }
  return includePrefix ? `0x${h}` : h;
}

function ensureHexString(hex: string): string {
  const s = strip0x(hex).toLowerCase();
  if (!s.match(HEXADECIMAL_STRING_REGEX)) {
    throw new Error(`"${hex}" is not a hexadecimal string`);
  }
  return s;
}

function ensureStringResponse(val: unknown): string {
  if (typeof val !== 'string') {
    throw new Error(`Undefined result ${val}`);
  }
  return val;
}

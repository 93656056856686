import { useCallback, useEffect, useState } from 'react';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { useEffectOnUpdate } from 'cb-wallet-data/hooks/useEffectOnUpdate';
import { transactionsPagination } from 'cb-wallet-data/stores/Transactions/database';
import { TxOrUserOp } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import {
  isFetchingTransactionsAtom,
  paginatedTransactionsAtom,
} from 'cb-wallet-data/stores/Transactions/state';
import { WalletGroup } from 'cb-wallet-data/stores/WalletGroups/models/WalletGroup';
import { activeWalletGroupIdAtom } from 'cb-wallet-data/stores/WalletGroups/state';
import { useRecoilState, useRecoilValue } from 'recoil';

export function getUniqueIdentifierForTransaction(tx: TxOrUserOp) {
  return `${tx.txOrUserOpHash}-${tx.accountId}-${tx.walletIndex}`;
}

const fetchedActiveWalletGroupIds = new Set<string>();

const MINIMUM_PAGE_SIZE = 50;
const TRANSACTION_LOADING_TIMEOUT = 5000;

/**
 * usePaginatedTransactions
 *
 * Exposes the paginated transactions recoil atom which powers any component that shows
 * a list of transactions or an individual transaction. Unlike client/server pagination,
 * these transactions are paginated from the client databases where the transactions are
 * cached (IndexedDB, SQLite, etc).
 *
 * Also exposes helper functions for populated and updating the paginated transactions:
 * - ensureMinimumTxsForActiveWalletGroup:
 *   ensure we have enough transactions loaded when the app is first opened.
 * - getRemainingTransactions:
 *   Ignore pagination, just download the rest of the transactions. Used in the tx history filter
 *   since we can't query by fiat value (one of the sorting options).
 * - getNextPage:
 *   Used on scroll to get the next page of transactions
 * - updatePaginatedTransactions:
 *   Used to update the paginated transactions atom with new transactions or transaction updates
 *   that come from tx history service.
 */
export function usePaginatedTransactions() {
  const [hasSetInitialTransactions, setHasSetInitialTransactions] = useState(false);
  const [isFetchingInitialTransactions, setIsFetchingInitialTransactions] = useRecoilState(
    isFetchingTransactionsAtom,
  );
  const isTransactionPaginationEnabled = useIsFeatureEnabled('transactions_pagination');
  const [paginatedTransactions, setPaginatedTransactions] =
    useRecoilState(paginatedTransactionsAtom);
  const activeWalletGroupId = useRecoilValue(activeWalletGroupIdAtom);

  const updatePaginatedTransactions = useCallback(
    async function updatePaginatedTransactionsCb(updatedTransactions: TxOrUserOp[]) {
      if (!updatedTransactions?.length) {
        setHasSetInitialTransactions(true);
        return;
      }

      const existingTransactionsByTxOrUserOpHash = new Map<string, TxOrUserOp>(
        paginatedTransactions.map((tx) => [getUniqueIdentifierForTransaction(tx), tx]),
      );

      const updatedTransactionsByTxOrUserOpHash = new Map<string, TxOrUserOp>(
        updatedTransactions.map((tx) => [getUniqueIdentifierForTransaction(tx), tx]),
      );

      // Inserts or updates transactions in the atom
      setPaginatedTransactions(function createOrUpdatePaginatedTransactions(
        currentPaginatedTransactions: TxOrUserOp[],
      ) {
        const newTransactions = updatedTransactions.filter(
          (tx) => !existingTransactionsByTxOrUserOpHash.has(getUniqueIdentifierForTransaction(tx)),
        );

        const updatedExistingTransactions = currentPaginatedTransactions.map(
          function updateExistingTransactionsAtom(existingTx) {
            const updatedTx = updatedTransactionsByTxOrUserOpHash.get(
              getUniqueIdentifierForTransaction(existingTx),
            );
            return updatedTx ?? existingTx;
          },
        );

        const sortedTransactions = [...newTransactions, ...updatedExistingTransactions].sort(
          function sortByDate(a, b) {
            return b.createdAt.getTime() - a.createdAt.getTime();
          },
        );

        return sortedTransactions;
      });
      setHasSetInitialTransactions(true);
    },
    [setPaginatedTransactions, paginatedTransactions, setHasSetInitialTransactions],
  );

  // If a user has the transaction tab open when they first open the app, we want to
  // show a loading state while we're fetching transactions from the database.
  useEffect(
    function dismissLoadingState() {
      if (hasSetInitialTransactions) {
        setIsFetchingInitialTransactions(false);
      }

      const transactionLoadingTimeout = setTimeout(() => {
        setIsFetchingInitialTransactions(false);
      }, TRANSACTION_LOADING_TIMEOUT);

      return () => {
        clearTimeout(transactionLoadingTimeout);
      };
    },
    [hasSetInitialTransactions, setIsFetchingInitialTransactions],
  );

  const getNextPage = useCallback(async () => {
    const { accountId, walletIndexOrHardwareDerivationPath: walletIndex } = WalletGroup.propsFromId(
      activeWalletGroupId!,
    );

    const nextTransactions: TxOrUserOp[] = await transactionsPagination.getNextPage({
      accountId,
      walletIndex: BigInt(walletIndex),
      offsetKey: activeWalletGroupId!,
      pageSize: MINIMUM_PAGE_SIZE,
      skipPagination: !isTransactionPaginationEnabled,
    });

    updatePaginatedTransactions(nextTransactions);
  }, [updatePaginatedTransactions, activeWalletGroupId, isTransactionPaginationEnabled]);

  const getRemainingTransactions = useCallback(async () => {
    const { accountId, walletIndexOrHardwareDerivationPath: walletIndex } = WalletGroup.propsFromId(
      activeWalletGroupId!,
    );

    const remainingTransactions: TxOrUserOp[] = await transactionsPagination.getRemainingPages({
      accountId,
      walletIndex: BigInt(walletIndex),
      offsetKey: activeWalletGroupId!,
      skipPagination: !isTransactionPaginationEnabled,
    });

    updatePaginatedTransactions(remainingTransactions);
  }, [activeWalletGroupId, updatePaginatedTransactions, isTransactionPaginationEnabled]);

  const ensureMinimumTxsForActiveWalletGroup = useCallback(() => {
    if (!fetchedActiveWalletGroupIds.has(activeWalletGroupId!)) {
      getNextPage();
      fetchedActiveWalletGroupIds.add(activeWalletGroupId!);
    }
  }, [activeWalletGroupId, getNextPage]);

  useEffectOnUpdate(() => {
    ensureMinimumTxsForActiveWalletGroup();
  }, [activeWalletGroupId, ensureMinimumTxsForActiveWalletGroup]);

  return {
    ensureMinimumTxsForActiveWalletGroup,
    getNextPage,
    getRemainingTransactions,
    paginatedTransactions,
    updatePaginatedTransactions,
    isFetchingInitialTransactions,
  };
}

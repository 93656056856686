/* eslint-disable camelcase */
// We disable the camelcase rule to match Consumer and Wallet RN

import { IStepConfig, IThresholdTier } from '@cbhq/client-analytics';

/**
 * We only track steps and not complete journeys in web.
 *
 * In RN, we only track a user journey if all the steps in the journey are completed.
 * In Web, this is a much more difficult thing to acheive because of the varibility of browsers.
 * Things such a opening a new tab, refreshing the page, or using the browsers navigation will create issues in tracking an entire journey.
 *
 * For more info: https://docs.cbhq.net/frontend/performance/web-step-tracking
 *
 * Use markStep(StepMark.RandomStep) or markStepOnce(StepMark.RandomStep) after defining them below.
 * Once the startStep and endStep are completed, the metric is sent to datadog and snowflake.
 */

type IStepsConfigWithTier = IStepConfig<string> & {
  // http://go/service-tiers
  tier?: number;
};

export enum StepMark {
  // Assets
  navigateToCryptoPage = 'navigate_to_crypto_page',
  loadedCryptoPage = 'loaded_crypto_page',

  // Asset detail page
  navigateToAssetDetailPage = 'navigate_to_asset_detail_page',
  loadedAssetDetailPage = 'loaded_asset_detail_page',

  // Transaction
  navigateToTransactionsPage = 'navigate_to_transactions_page',
  loadedTransactionsPage = 'loaded_transactions_page',

  // Transaction detail page
  navigateToTransactionDetailPage = 'navigate_to_transaction_detail_page',
  loadedTransactionDetailPage = 'loaded_transaction_detail_page',

  // Defi
  navigateToDefiPage = 'navigate_to_defi_page',
  loadedDefiPage = 'loaded_defi_page',

  // NFTs
  navigateToNFTPage = 'navigate_to_nft_page',
  loadedNFTPage = 'loaded_nft_page',
}

export const steps: Record<string, IStepsConfigWithTier> = {
  load_crypto_page: {
    threshold: IThresholdTier.moderate,
    marks: [StepMark.navigateToCryptoPage, StepMark.loadedCryptoPage],
  },

  load_transactions_page: {
    threshold: IThresholdTier.moderate,
    marks: [StepMark.navigateToTransactionsPage, StepMark.loadedTransactionsPage],
  },

  load_defi_page: {
    threshold: IThresholdTier.moderate,
    marks: [StepMark.navigateToDefiPage, StepMark.loadedDefiPage],
  },

  load_nft_page: {
    threshold: IThresholdTier.moderate,
    marks: [StepMark.navigateToNFTPage, StepMark.loadedNFTPage],
  },

  load_asset_detail_page: {
    threshold: IThresholdTier.moderate,
    marks: [StepMark.navigateToAssetDetailPage, StepMark.loadedAssetDetailPage],
  },

  load_transaction_detail_page: {
    threshold: IThresholdTier.moderate,
    marks: [StepMark.navigateToTransactionDetailPage, StepMark.loadedTransactionDetailPage],
  },
};

import {
  callEthereumJSONRPC,
  getErrorMessageFromResponseData,
  getStringResult,
} from 'wallet-engine-signing/blockchains/Ethereum/RPC';

import { TPP_MEV_ROUTING_HEADER } from './constants';
import { hexFromBuffer, keccak256 } from './utils';

export async function submitSignedEthereumTransaction(
  signedTransaction: Buffer,
  rpcURL: string,
  isMEVProtectionEnabled = false,
): Promise<Buffer> {
  const headers = isMEVProtectionEnabled ? TPP_MEV_ROUTING_HEADER : {};
  const res = await callEthereumJSONRPC(
    'eth_sendRawTransaction',
    [hexFromBuffer(signedTransaction)],
    rpcURL,
    headers,
  );

  const txHash = keccak256(signedTransaction);
  const errorMessage = getErrorMessageFromResponseData(res);

  if (errorMessage) {
    const errMsg = errorMessage.toLowerCase();

    if (errMsg.includes('known transaction') || errMsg.includes('already imported')) {
      return txHash;
    }

    if (errMsg.includes('nonce too low') || errMsg.includes('nonce is too low')) {
      throw new Error('nonce too low');
    }

    if (errMsg.includes('insufficient funds')) {
      throw new Error('insufficient funds');
    }
  }

  const result = getStringResult(res);
  if (result.match(/^0x0*$/)) {
    throw new Error('transaction could not be submited');
  }

  return txHash;
}

export type ERC20Info = {
  address: string;
  name: string;
  symbol: string;
  decimals: number;
};

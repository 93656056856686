import { refreshAccessToken as refresh } from '../api';
import { AccessTokenResult } from '../types/AccessTokenResult';

import { getRefreshTokenFromStorage } from './state';

let promise: undefined | Promise<AccessTokenResult>;

/**
 * Gets a new access token using the refresh token.
 *
 * @param setAuthTokens A state setter function to be called with the new tokens
 * @returns The new access token
 */
export async function refreshAccessToken(
  setAuthTokens: (tokens: AccessTokenResult) => void,
): Promise<string> {
  const refreshToken = getRefreshTokenFromStorage();
  if (!refreshToken) {
    throw new Error("Attempted to refresh access token without a refresh token. This can't work.");
  }

  let tokens: AccessTokenResult | undefined;

  // If there's already a refresh request in-flight, await the pending promise
  // rather than dispatching another request
  try {
    if (promise) {
      tokens = await promise;
    } else {
      promise = refresh(refreshToken);
      tokens = await promise;
    }
    setAuthTokens(tokens);
    return tokens.token;
  } finally {
    promise = undefined;
  }
}

export const EMPTY_REFRESH_TOKEN_RESULT = {
  token: '',
  expiresIn: Number.MAX_SAFE_INTEGER,
  refreshToken: '',
};

/**
 * Refreshes the tokens and drops the result (to revoke the existing access token)
 * - Waits for any in flight refresh requests to complete
 * - Refreshes the tokens, drops the result
 * - Resolves the promise with an empty result.
 *
 * Only used as part of sign out
 *
 * @param refreshToken the previous refresh token
 */
export async function revokeAccessToken(): Promise<void> {
  const refreshToken = getRefreshTokenFromStorage();
  if (!refreshToken) {
    // If there's no refresh token, there's nothing to revoke
    return;
  }

  // If there's already a refresh request in-flight, await the pending promise
  // rather than dispatching another request
  try {
    if (promise) {
      await promise;
    }

    promise = refresh(refreshToken).then(() => EMPTY_REFRESH_TOKEN_RESULT);
    await promise;
    return;
  } catch {
    // ignore errors
  } finally {
    promise = undefined;
  }
}

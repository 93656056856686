import { ActionType, AnalyticsEventImportance, ComponentType } from '@cbhq/client-analytics';

import { logDataEvent, logEvent, useLogEventOnMount } from '../utils/log';

// RN-only events: please prefix with 'app-load.rn'

type UseTriggerRnGenericAppFallbackViewedProps = { context: string };

/**
 * @deprecated
 * @see GenericAppFallback
 * @param params.context
 */
export function useTriggerRnGenericAppFallbackViewed({
  context,
}: UseTriggerRnGenericAppFallbackViewedProps) {
  useLogEventOnMount(
    'app-load.rn.generic_app_fallback_screen.viewed',
    {
      action: ActionType.view,
      componentType: ComponentType.page,
      loggingId: '2b491222-6057-48d3-affb-5a0a135bc48d',
      data: {
        context,
      },
    },
    true,
  );
}

export function triggerRnGenericAppFallbackViewedLongTime({
  context,
  lengthMs,
}: {
  context: string;
  lengthMs: number;
}) {
  logDataEvent('app-load.rn.generic_app_fallback_screen.viewed.long', {
    loggingId: 'be1b5aec-3640-4deb-a1a7-e213907341d2',
    data: {
      context,
      lengthMs,
    },
  });
}

type UseTriggerRnFullScreenLoaderViewedProps = { testID?: string };

/**
 * @deprecated
 * @see FullScreenLoader
 * @param params.testID
 */
export function useTriggerRnFullScreenLoaderViewed({
  testID,
}: UseTriggerRnFullScreenLoaderViewedProps) {
  useLogEventOnMount(
    'app-load.rn.full_screen_loader.viewed',
    {
      action: ActionType.view,
      componentType: ComponentType.page,
      loggingId: '0d20af42-43c5-4048-80b3-9e7bb84b90d2',
      data: {
        testID,
      },
    },
    true,
  );
}

export function useTriggerRnSignedInNavigatorViewed() {
  useLogEventOnMount(
    'app-load.rn.signed-in-navigator.viewed',
    {
      action: ActionType.view,
      componentType: ComponentType.page,
      loggingId: '3a5ee263-e815-4576-8c13-0b79fa2f1ee4',
    },
    true,
  );
}

export function triggerRnOpenDatabaseAndCheckAuthStart() {
  logEvent(
    'app-load.rn.open_database_and_check_auth.start',
    {
      action: ActionType.unknown,
      componentType: ComponentType.unknown,
      loggingId: '28a0a327-c384-47f3-9468-e68826418e8e',
    },
    AnalyticsEventImportance.high,
    true,
  );
}

export function triggerRnOpenDatabaseAndCheckAuthSuccess() {
  logEvent(
    'app-load.rn.open_database_and_check_auth.success',
    {
      action: ActionType.unknown,
      componentType: ComponentType.unknown,
      loggingId: '4f565b7a-e8e4-46ba-8f8c-7978969ef188',
    },
    AnalyticsEventImportance.high,
    true,
  );
}

export function triggerRnOpenDatabaseAndCheckAuthFailure({
  errorMessage,
}: {
  errorMessage: string;
}) {
  logEvent(
    'app-load.rn.open_database_and_check_auth.failure',
    {
      action: ActionType.unknown,
      componentType: ComponentType.unknown,
      errorMessage,
    },
    AnalyticsEventImportance.high,
    true,
  );
}

export function triggerRnRootStackLoaded() {
  logEvent(
    'app-load.rn.root_stack_loaded',
    {
      action: ActionType.unknown,
      componentType: ComponentType.unknown,
      // 12/7/22 As a bug, this was previously '9fa8060c-41dc-4f74-ad91-9447da103304'
      // which is a dupe of triggerFetchAuthenticationStateRetry
      loggingId: 'ccb5ff2c-b7bf-4c87-9b84-bf4240502484',
    },
    AnalyticsEventImportance.high,
    true,
  );
}

export function triggerFetchAuthenticationStateRetry() {
  logEvent(
    'app-load.rn.fetch_authentication_state.retry',
    {
      action: ActionType.unknown,
      componentType: ComponentType.unknown,
      loggingId: '9fa8060c-41dc-4f74-ad91-9447da103304',
    },
    undefined,
    true,
  );
}

export type AppLoadVersion = 1 | 2;

export function triggerObserveAppLoad({
  isAuthStateAccessible,
  isExpectedToBeSignedIn,
  isRestoringFromDeviceBackup,
  hasSecrets,
  accountsLength,
  hasPrimaryWallet,
  walletGroupsLength,
  walletsLength,
  addressesLength,
  privateKeyAccountsLength,
  ledgerAccountsLength,
  mnemonicAccountsLength,
  walletLinkAccountsLength,
  accountIdsFromSecrets,
  accountIdsFromDB,
  secretsLength,
  version,
}: {
  isAuthStateAccessible?: boolean;
  isExpectedToBeSignedIn: boolean;
  isRestoringFromDeviceBackup: boolean;
  hasSecrets: boolean;
  accountsLength: number;
  hasPrimaryWallet: boolean;
  walletGroupsLength: number;
  walletsLength: number;
  addressesLength: number;
  privateKeyAccountsLength: number;
  ledgerAccountsLength: number;
  mnemonicAccountsLength: number;
  walletLinkAccountsLength: number;
  accountIdsFromSecrets?: string[];
  accountIdsFromDB: string[];
  secretsLength?: number;
  version: AppLoadVersion;
}) {
  logDataEvent(
    'app-load.observe_app_load',
    {
      data: {
        isAuthStateAccessible,
        isExpectedToBeSignedIn,
        isRestoringFromDeviceBackup,
        hasSecrets,
        accountsLength,
        hasPrimaryWallet,
        walletGroupsLength,
        walletsLength,
        addressesLength,
        privateKeyAccountsLength,
        ledgerAccountsLength,
        mnemonicAccountsLength,
        walletLinkAccountsLength,
        accountIdsFromSecrets,
        accountIdsFromDB,
        secretsLength,
        version,
      },
    },
    AnalyticsEventImportance.high,
  );
}

export function useTriggerAddressMismatchErrorViewed() {
  useLogEventOnMount('app-load.rn.address_mismatch_error.viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
    loggingId: '16c8d085-df24-43c5-a784-8c80469133fa',
  });
}

export function useTriggerEscapeHatchChooseWalletRecoveryPhraseViewed() {
  useLogEventOnMount('app-load.rn.escape_hatch_choose_wallet_recovery_phrase.viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
    loggingId: '634ed85c-5647-492c-8f29-566f4b361a56',
  });
}

export function triggerAddressMismatchErrorRecoveryPhraseButtonPressed() {
  logEvent('app-load.rn.address_mismatch_error_recovery_phase_button.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
    loggingId: '0af69126-460f-4f8a-85f6-47184a57d017',
  });
}

export function triggerAddressMismatchErrorSignOutButtonPressed() {
  logEvent('app-load.rn.address_mismatch_error_hatch_sign_out_button.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
    loggingId: '97528cab-c0f0-4735-82d3-8b0e97e33b11',
  });
}

export function useTriggerRecoveryModeViewed() {
  useLogEventOnMount('app-load.rn.recovery_mode.viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
    loggingId: '7dfd5772-4dc9-4923-bfaf-626dac70fd2f',
  });
}

export function triggerRecoveryModeRecoveryPhraseButtonPressed() {
  logEvent('app-load.rn.recovery_mode_recovery_phrase_button.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
    loggingId: '5cedef73-431c-464e-a126-4d2224f2196a',
  });
}

export function triggerRecoveryModeSignOutButtonPressed() {
  logEvent('app-load.rn.recovery_mode_sign_out_button.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
    loggingId: 'c7df6b2f-da74-4d74-b93b-824280d353ef',
  });
}

export function triggerRecoveryModeExitButtonPressed() {
  logEvent('app-load.rn.recovery_mode_exit_button.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
    loggingId: 'b6e0c3c5-1f86-429d-bb50-cea543d554ef ',
  });
}

export function triggerRecoveryModeButtonPressed({
  isRecoveryModeKilled,
}: {
  isRecoveryModeKilled: boolean;
}) {
  logEvent('app-load.rn.recovery_mode_button.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
    loggingId: '81178b91-3b1e-4ae8-91ed-0bfb9063834d',
    isRecoveryModeKilled,
  });
}

export function useDNSBlockedScreenViewed() {
  useLogEventOnMount('app-load.blocked_dns_screen.viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function logDNSBlockedScreenEnableDoHButtonClicked() {
  logEvent('app-load.blocked_dns_screen_enable_doh_button.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function logDNSBlockedScreenDismissButtonClicked() {
  logEvent('app-load.blocked_dns_screen_dismiss_button.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { useGeoAvailability } from 'cb-wallet-data/stores/OFAC/hooks/useIsPermitted';

import { useFetchFiatCode } from '../api';

export const QUERY_KEY = 'get_fiat_code_for_country';

// cache for 24 hours
const FIAT_CODE_CACHE_TIME = 1000 * 60 * 60 * 24;

type UseLocalFiatCodeProps = { countryCodeOverride?: string };

export function useLocalFiatCode({ countryCodeOverride }: UseLocalFiatCodeProps = {}) {
  const geoAvailability = useGeoAvailability();
  const getFiatCode = useFetchFiatCode();

  const { data } = useQuery({
    queryKey: [QUERY_KEY, geoAvailability.country, countryCodeOverride],
    queryFn: async () =>
      getFiatCode({ countryCode: countryCodeOverride || geoAvailability.country }),
    suspense: false,
    staleTime: FIAT_CODE_CACHE_TIME,
    cacheTime: FIAT_CODE_CACHE_TIME,
    notifyOnChangeProps: ['data'],
  });

  return data;
}

/**
 * Serialize a value to a string via `JSON.stringify` that accounts for BigInt values.
 */
export function stringify(value: any): string {
  return JSON.stringify(value, (_key, _value) => {
    if (typeof _value === 'bigint') {
      return `bigint:${_value.toString()}`;
    }

    return _value;
  });
}

/**
 * Parse a string into a value via `JSON.parse` that accounts for BigInt values.
 */
export function parse<R = any>(value: string): R {
  return JSON.parse(value, (_key, _value) => {
    if (typeof _value === 'string' && _value.startsWith('bigint:')) {
      return BigInt(_value.substr('bigint:'.length));
    }
    return _value;
  });
}

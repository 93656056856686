import { useMemo } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { NavigationIcon, NavigationIconName } from '@cbhq/cds-web/icons';

import { AppRoutesEnum } from ':dapp/utils/RoutesEnum';

type BottomNavbarItemProps = {
  route: AppRoutesEnum;
  iconName: NavigationIconName;
  title: string;
  onClick?: () => void;
};

export function BottomNavbarItem({ route, iconName, title, onClick }: BottomNavbarItemProps) {
  const router = useRouter();

  const pathname = router.pathname;

  const active = useMemo(() => {
    return pathname.startsWith(route);
  }, [pathname, route]);

  return (
    <Link href={route} onClick={onClick}>
      <NavigationIcon
        accessibilityLabel={title}
        testID={active ? `bottom-item--${title}-active` : `bottom-item--${title}`}
        name={iconName}
        size="m"
        active={active}
      />
    </Link>
  );
}

import { SOLANA_RPC_URL } from 'cb-wallet-env/env';

import { Interface } from './heliusEnums';
import { heliusDASgetAssetsByOwner, toSPLOwnership } from './heliusRPC';
import { getBalance, getSPLBalances, getSPLMetadata } from './RPC';
import { SolanaAddressConfig, SolanaAddressHistory, SPLBalance, SPLOwnership } from './types';

export function getSolanaCacheId({ address }: SolanaAddressConfig) {
  return address;
}

export function initializeSolanaCacheItem(
  addressConfig: SolanaAddressConfig,
): SolanaAddressHistory {
  return {
    ...addressConfig,
    solanaBalance: BigInt(0),
    splBalances: [],
    errors: [],
    unsyncedTransactions: [],
    addressMetadata: {},
    spamScoreThresholds: {},
  };
}

export async function handleSolanaNudgeBalances(addresses: SolanaAddressHistory[]): Promise<{
  updates: SolanaAddressHistory[];
  blockheight: number;
}> {
  const { updates } = await refreshSolanaBalances(addresses);
  return {
    updates,
    blockheight: 0,
  };
}

export async function refreshSolanaBalances(
  addresses: (SolanaAddressConfig | SolanaAddressHistory)[],
): Promise<{
  updates: SolanaAddressHistory[];
  blockheight: number;
}> {
  const updatedBalances = await Promise.all(
    addresses.map(async (address) => {
      try {
        let solanaBalance: bigint;
        let splOwnerships: SPLOwnership[];
        // This killswitch is here for safety in case we ever stop using Helius as a provider of this API.
        // It is a custom API from Helius, so a fallback to standard Solana APIs is good to have.
        if (address.isDASAPIEnabled) {
          ({ solanaBalance, splOwnerships } = await getSolanaNativeBalanceAndSPLOwnerships(
            address.address,
            SOLANA_RPC_URL,
          ));
        } else {
          [solanaBalance, splOwnerships] = await Promise.all([
            getBalance({ address: address.address, rpcUrl: SOLANA_RPC_URL }),
            getSPLBalances(address.address, SOLANA_RPC_URL),
          ]);
        }

        let splBalances: SPLBalance[] = [];

        if (splOwnerships.length) {
          splBalances = await getSPLMetadata(splOwnerships);
        }

        return {
          ...address,
          errors: [],
          solanaBalance,
          splBalances,
        };
      } catch (err: unknown) {
        return {
          ...address,
          errors: [err as Error],
        };
      }
    }),
  );

  return {
    updates: updatedBalances as SolanaAddressHistory[],
    blockheight: 0,
  };
}

// One API call to Helius api to get both native balance and SPL ownerships
export async function getSolanaNativeBalanceAndSPLOwnerships(
  address: string,
  rpcURL: string,
): Promise<{ solanaBalance: bigint; splOwnerships: SPLOwnership[] }> {
  const assetsByOwner = await heliusDASgetAssetsByOwner(address, rpcURL, {
    showNativeBalance: true,
    showFungible: true,
    showZeroBalance: true,
  });
  const solBalanceLamports = assetsByOwner.nativeBalance.lamports ?? 0;
  const spls: SPLOwnership[] = assetsByOwner.items
    .filter(
      (item) =>
        item.interface === Interface.FUNGIBLE_TOKEN || item.interface === Interface.FUNGIBLE_ASSET,
    )
    .map(toSPLOwnership);
  return {
    solanaBalance: solBalanceLamports,
    splOwnerships: spls,
  };
}

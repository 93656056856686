import { UTXOConfiguration } from 'cb-wallet-data/chains/UTXO/models/UTXOConfiguration';
import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { AddressType } from 'cb-wallet-data/stores/Addresses/models/AddressType';
import { NetworkSetting } from 'cb-wallet-data/stores/Networks/models/NetworkSetting';

export const BITCOIN_SYMBOL = 'BTC';
export const BitcoinSegwitAddressType = new AddressType('BitcoinSegWit');
export const BitcoinBRDSegwitAddressType = new AddressType('BitcoinBRDSegWit');
export const BitcoinLegacyAddressType = new AddressType('BitcoinLegacy');
export const BitcoinBRDLegacyAddressType = new AddressType('BitcoinBRDLegacy');
export const BitcoinBlockchain = new Blockchain(BITCOIN_SYMBOL);
export const BitcoinNetworkSetting = NetworkSetting.forMainnetOnlyBlockchain(BitcoinBlockchain);

// We don't derive testnet addresses for migrated BRD wallets
function isDerivationPathSupported(addressType: AddressType, isTestnet: boolean): boolean {
  switch (addressType.rawValue) {
    case BitcoinBRDLegacyAddressType.rawValue:
    case BitcoinBRDSegwitAddressType.rawValue:
      return !isTestnet;
    default:
      return true;
  }
}

export const BitcoinWalletConfiguration = new UTXOConfiguration({
  supportedAddressTypes: [
    BitcoinSegwitAddressType,
    BitcoinLegacyAddressType,
    BitcoinBRDSegwitAddressType,
    BitcoinBRDLegacyAddressType,
  ],
  blockchain: BitcoinBlockchain,
  currencyName: 'Bitcoin',
  currencyCode: CurrencyCode.BTC,
  decimals: 8n,
  imageURL: 'https://wallet-api-production.s3.amazonaws.com/uploads/tokens/btc_288.png',
  defaultReceiveType: BitcoinSegwitAddressType,
  txResubmitterInterval: 120n,
  maxSubmissionAttempts: 5n,
  qrCodeUriMainnetScheme: 'bitcoin',
  qrCodeUriTestnetScheme: 'bitcoin',
  networkSetting: BitcoinNetworkSetting,
  refreshInterval: 480n,
  isDerivationPathSupported,
});

import { MiamiThemeColorPreference } from 'cb-wallet-data/stores/ThemeColors/themeColorConfigs';
import { objectAtomLocalStorageEffect } from 'cb-wallet-data/utils/objectAtomLocalStorageEffect';
import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { atom, SetterOrUpdater, useRecoilState } from 'recoil';

const walletAvatarsKey = new LocalStorageStoreKey<string>('walletAvatarsKey');

export type AddressAvatar = {
  avatarIndex: number;
  avatarBackgroundColor: MiamiThemeColorPreference;
};

export const walletAvatarsAtom = atom<Record<string, AddressAvatar>>({
  key: 'walletAvatarsKey',
  default: {},
  effects: [objectAtomLocalStorageEffect(walletAvatarsKey)],
});

export function useWalletAvatarsPreference(): [
  Record<string, AddressAvatar>,
  SetterOrUpdater<Record<string, AddressAvatar>>,
] {
  const [avatars, setAvatars] = useRecoilState(walletAvatarsAtom);

  return [avatars, setAvatars];
}

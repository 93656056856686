import bitcoin, { Network } from 'bitcoinjs-lib';

import { UTXOChains } from './indexer';

const LitecoinNetworks: { ltc: Network; ltcTestnet: Network } = {
  ltc: {
    messagePrefix: '\x19Litecoin Signed Message:\n',
    bech32: 'ltc',
    bip32: bitcoin.networks.bitcoin.bip32,
    pubKeyHash: 0x30,
    scriptHash: 0x32,
    wif: 0xb0,
  },
  ltcTestnet: {
    messagePrefix: '\x19Litecoin Signed Message:\n',
    bech32: 'tltc',
    bip32: bitcoin.networks.testnet.bip32,
    pubKeyHash: 0x6f,
    scriptHash: 0x3a,
    wif: 0xef,
  },
};

const DogecoinNetworks: { doge: Network; dogeTestnet: Network } = {
  doge: {
    messagePrefix: '\x19Dogecoin Signed Message:\n',
    bip32: bitcoin.networks.bitcoin.bip32,
    pubKeyHash: 0x1e,
    scriptHash: 0x16,
    wif: 0x9e,
  },
  dogeTestnet: {
    messagePrefix: '\x19Dogecoin Signed Message:\n',
    bip32: bitcoin.networks.testnet.bip32,
    pubKeyHash: 0x71,
    scriptHash: 0xc4,
    wif: 0xf1,
  },
};

export function getNetwork(blockchainSymbol: UTXOChains, testnet: boolean): Network {
  switch (blockchainSymbol) {
    case 'BTC':
      return testnet ? bitcoin.networks.testnet : bitcoin.networks.bitcoin;
    case 'LTC':
      return testnet ? LitecoinNetworks.ltcTestnet : LitecoinNetworks.ltc;
    case 'DOGE':
      return testnet ? DogecoinNetworks.dogeTestnet : DogecoinNetworks.doge;
    default:
      const _exhaustiveCheck: never = blockchainSymbol;
      throw new Error(_exhaustiveCheck);
  }
}

import { ServiceLocator } from '../ServiceLocator';

import {
  SigningAndDerivationMethodsForDappProviderAccount,
  SigningAndDerivationMethodsForMnemonicAccount,
  SigningAndDerivationMethodsForPrivateKeyAccount,
  SigningAndDerivationMethodsForScwAccount,
} from './types/SigningAndDerivationMethodsForAccount';

/**
 * This is a service locator for all of the mnemonic signing functions.
 * It is used to get the correct signing function for a mnemonic account type.
 *
 * @deprecated
 * This is not being deprecated, but we are using this to make it more clear that you should not
 * be using this ServiceLocator directly.
 *
 * This should only be called to `setAll` the service locator implementations.
 * It can also be used to set up mocks in tests.
 *
 * To retrieve and use a method, simply call `getSigningOrDerivationMethodForAccount(accountId, method)`
 *
 * @see {@link getSigningOrDerivationMethodForAccount}
 */
export const signingAndDerivationMethodsForMnemonicAccount =
  new ServiceLocator<SigningAndDerivationMethodsForMnemonicAccount>();

/**
 * This is a service locator for all of the private key signing functions.
 * It is used to get the correct signing function for a private key account type.
 *
 * @deprecated
 * This is not being deprecated, but we are using this to make it more clear that you should not
 * be using this ServiceLocator directly.
 *
 * This should only be called to `setAll` the service locator implementations.
 * It can also be used to set up mocks in tests.
 *
 * To retrieve and use a method, simply call `getSigningOrDerivationMethodForAccount(accountId, method)`
 *
 * @see {@link getSigningOrDerivationMethodForAccount}
 */
export const signingAndDerivationMethodsForPrivateKeyAccount =
  new ServiceLocator<SigningAndDerivationMethodsForPrivateKeyAccount>();

/**
 * This is a service locator for all of the web3 signing functions.
 * It is used to get the correct signing function for a dapp_provider account type.
 *
 * @deprecated
 * This is not being deprecated, but we are using this to make it more clear that you should not
 * be using this ServiceLocator directly.
 *
 * This should only be called to `setAll` the service locator implementations.
 * It can also be used to set up mocks in tests.
 *
 * To retrieve and use a method, simply call `getSigningOrDerivationMethodForAccount(accountId, method)`
 *
 * @see {@link getSigningOrDerivationMethodForAccount}
 */
export const signingAndDerivationMethodsForDappProviderAccount =
  new ServiceLocator<SigningAndDerivationMethodsForDappProviderAccount>();

/**
 * This is a service locator for all of the scw signing functions.
 * It is used to get the correct signing function for a scw account type.
 *
 * @deprecated
 * This is not being deprecated, but we are using this to make it more clear that you should not
 * be using this ServiceLocator directly.
 *
 * This should only be called to `setAll` the service locator implementations.
 * It can also be used to set up mocks in tests.
 *
 * To retrieve and use a method, simply call `getSigningOrDerivationMethodForAccount(accountId, method)`
 *
 * @see {@link getSigningOrDerivationMethodForAccount}
 */
export const signingAndDerivationMethodsForScwAccount =
  new ServiceLocator<SigningAndDerivationMethodsForScwAccount>();

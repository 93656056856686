export function clearCBStoreFromLocalStorage() {
  // Get all local storage keys`
  const keys = Array.from({ length: localStorage.length }, (_, i) => {
    return localStorage.key(i);
  });

  // Clear all keys that contain the account id
  // This includes wallet balances, tx history data, xPub keys, etc
  keys.forEach((key) => {
    if (key?.startsWith('CBStore.')) {
      localStorage.removeItem(key);
    }
  });
}

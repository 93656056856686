import { ActionType, AnalyticsEventImportance, ComponentType } from '@cbhq/client-analytics';

import { logDataEvent, logEvent, useLogEventOnMount } from '../utils/log';

import { AppLoadVersion } from './app-load-rn';

export * from './app-load-rn';

// shared events between Extension and RN

// openDatabase
export function triggerOpenDatabaseStart(dbVersion?: number) {
  logEvent(
    'app-load.open_database.start',
    {
      componentType: ComponentType.unknown,
      action: ActionType.unknown,
      loggingId: '91e012df-4d8b-46e3-b6e2-75df456013be',
      dbVersion: dbVersion ? String(dbVersion) : undefined,
    },
    AnalyticsEventImportance.high,
    true,
  );
}

export function triggerOpenDatabaseSuccess(dbVersion?: number) {
  logEvent(
    'app-load.open_database.success',
    {
      componentType: ComponentType.unknown,
      action: ActionType.unknown,
      loggingId: '4cf64e8b-75f2-4432-ba7e-e0ba6943d0bb',
      dbVersion: dbVersion ? String(dbVersion) : undefined,
    },
    AnalyticsEventImportance.high,
    true,
  );
}

// accounts, wallet groups hydration
export function triggerAccountsWalletGroupsHydrationStart() {
  logEvent(
    'app-load.accounts_wallet_groups_hydration.start',
    {
      componentType: ComponentType.unknown,
      action: ActionType.unknown,
      loggingId: '5706c19e-d24c-4dda-bb97-c4bbc98cf928',
    },
    AnalyticsEventImportance.high,
    true,
  );
}

export function triggerAccountsHydrationResult({ accountsLength }: { accountsLength: number }) {
  logDataEvent(
    'app-load.accounts_hydration.result',
    {
      loggingId: 'b8f82ac8-3d44-4dc2-92d6-e66882246ddb',
      data: {
        accountsLength,
      },
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerWalletGroupsHydrationResult({
  walletGroupsLength,
}: {
  walletGroupsLength: number;
}) {
  logDataEvent(
    'app-load.wallet_groups_hydration.result',
    {
      loggingId: 'b0659aea-8b77-4d83-afdc-f32339267f01',
      data: {
        walletGroupsLength,
      },
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerAccountsWalletGroupsHydrationSuccess() {
  logEvent(
    'app-load.accounts_wallet_groups_hydration.success',
    {
      componentType: ComponentType.unknown,
      action: ActionType.unknown,
      loggingId: '9a171d41-ddf6-4c0e-908e-f0d1e652123f',
    },
    AnalyticsEventImportance.high,
    true,
  );
}

export function triggerAccountsWalletGroupsPresencePostHydration({
  accountsLength,
  walletGroupsLength,
}: {
  accountsLength: number;
  walletGroupsLength: number;
}) {
  logDataEvent(
    'app-load.accounts_wallet_groups_presence_post_hydration',
    {
      loggingId: '9a171d41-ddf6-4c0e-908e-f0d1e652123f',
      data: {
        accountsLength,
        walletGroupsLength,
      },
    },
    AnalyticsEventImportance.high,
  );
}

// kill switches

export function triggerIsRepairDbWithWalletsIfNeededKilled(
  isRepairDbWithWalletsIfNeededKilled: boolean,
) {
  logDataEvent(
    'app-load.is-repair-db-with-wallets-if-needed-killed',
    {
      loggingId: 'ea0e0092-b46a-4648-a1ca-7d312732a3ba',
      data: {
        isRepairDbWithWalletsIfNeededKilled,
      },
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerIsRepairSecretAccountsKilled(isRepairMnemonicAccountsKilled: boolean) {
  logDataEvent(
    'app-load.is-repair-secret-accounts-killed',
    {
      loggingId: 'a998c857-9750-439f-9c22-c540973f4612',
      data: {
        isRepairMnemonicAccountsKilled,
      },
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerIsSecretEscapeHatchEnabled(isSecretEscapeHatchEnabled: boolean) {
  logDataEvent(
    'app-load.is-mnemonic-escape-hatch-enabled',
    {
      loggingId: '42385978-19bc-4e44-93f4-feba03b88073',
      data: {
        isSecretEscapeHatchEnabled,
      },
    },
    AnalyticsEventImportance.high,
  );
}

// repairDbWithWalletsIfNeeded

export function triggerRepairDbWithWalletsIfNeededStart() {
  logDataEvent(
    'app-load.repair-db-with-wallets-if-needed.start',
    {
      loggingId: 'b56d4b68-1b0e-4732-af76-87814be8b2d5',
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerRepairDbWithWalletsRepairStart() {
  logDataEvent(
    'app-load.repair-db-with-wallets-repair.start',
    {
      loggingId: '7ab90551-a9b7-405f-8136-7487a0ce3331',
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerRepairDbWithWalletsRepairResult({
  reason,
  status,
}: {
  reason: string;
  status: 'success' | 'failure';
}) {
  logDataEvent(
    'app-load.repair-db-with-wallets-repair.result',
    {
      loggingId: 'ef04af7b-9bec-4a5c-ae70-5821a844dd23',
      data: {
        reason,
        status,
      },
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerRepairDbWithWalletsIfNeededResult({
  reason,
  status,
}: {
  reason: string;
  status: 'success' | 'failure' | 'deferred';
}) {
  logDataEvent(
    'app-load.repair-db-with-wallets-if-needed.result',
    {
      loggingId: 'b9f2c059-0404-4037-a2ff-8d7c26ea807d',
      data: {
        reason,
        status,
      },
    },
    AnalyticsEventImportance.high,
  );
}

// repair with mnemonics if needed

export function triggerRepairSecretAccountStart(accountType: 'mnemonic' | 'private_key') {
  logDataEvent(
    'app-load.repair-secret-account.start',
    {
      loggingId: 'ad7dc930-0f1d-4927-8482-e6c69053b5b3',
      accountType,
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerRepairSecretAccountSuccess({
  walletsLength,
  accountType,
}: {
  walletsLength: number;
  accountType: 'mnemonic' | 'private_key';
}) {
  logDataEvent(
    'app-load.repair-secret-account.success',
    {
      loggingId: '58bfc6c9-f764-45a9-afb3-e0b7d59d6315',
      data: { walletsLength, accountType },
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerRepairSecretAccountFailure({
  failureReason,
  accountType,
}: {
  failureReason: string;
  accountType?: 'mnemonic' | 'private_key';
}) {
  logDataEvent(
    'app-load.repair-secret-account.failure',
    {
      loggingId: 'ae19a0fc-769d-46af-aacd-d743e2bf0598',
      data: { failureReason, accountType },
    },
    AnalyticsEventImportance.high,
  );
}

type UseTriggerEscapeHatchViewedProps = { context: string };

export function useTriggerEscapeHatchViewed({ context }: UseTriggerEscapeHatchViewedProps) {
  useLogEventOnMount('app-load.mnemonic_escape_hatch.viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
    loggingId: '00bed1a1-73d1-4543-8152-44a245c21990',
    data: { context },
  });
}

export function triggerEscapeHatchRecoveryPhraseButtonPressed() {
  logEvent('app-load.mnemonic_escape_hatch_recovery_phase_button.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
    loggingId: '05f8c8b2-eec5-4858-98fe-bda787fefefa',
  });
}

export function triggerEscapeHatchSignOutButtonPressed() {
  logEvent('app-load.mnemonic_escape_hatch_sign_out_button.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
    loggingId: 'e9a935eb-5506-48dc-996c-e1d24408774f',
  });
}

export function triggerAppLoadSuccess({
  isSignedIn,
  mnemonicMismatchResult,
  requiredWalletBackfill,
  requiredRepairSecretAccounts,
  version,
}: {
  isSignedIn: boolean;
  mnemonicMismatchResult?: 'success' | 'failure' | 'skipped';
  requiredWalletBackfill?: boolean;
  requiredRepairSecretAccounts?: boolean;
  version: AppLoadVersion;
}) {
  logDataEvent(
    'app-load.success',
    {
      data: {
        isSignedIn,
        mnemonicMismatchResult,
        requiredWalletBackfill,
        requiredRepairSecretAccounts,
        version,
      },
    },
    AnalyticsEventImportance.high,
  );
}

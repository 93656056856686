import { useCallback } from 'react';
import {
  triggerBalanceScanForAccountFailed,
  triggerBalanceScanForAccountResult,
  triggerBalanceScanForAccountStart,
} from 'cb-wallet-analytics/accounts';
import { getPrimaryAddressForIndex } from 'cb-wallet-data/AddressHistory/utils/getPrimaryAddressForIndex';
import { EthereumWalletConfiguration as ethConfig } from 'cb-wallet-data/chains/AccountBased/Ethereum/config';
import { ETHEREUM_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { SolanaWalletConfiguration as solConfig } from 'cb-wallet-data/chains/AccountBased/Solana/config';
import { SOLANA_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Solana/constants';
import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { useOverridableKillSwitch } from 'cb-wallet-data/hooks/KillSwitches/useOverridableKillSwitch';
import { getKillSwitchByPlatform } from 'cb-wallet-data/hooks/KillSwitches/utils/getKillSwitchByPlatform';
import { useIsTestnetsEnabled } from 'cb-wallet-data/hooks/Testnets/useIsTestnetsEnabled';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { getAddressesForBlockchain } from 'cb-wallet-data/stores/Addresses/database';
import { createLastSyncedTxHashLookupFn } from 'cb-wallet-data/stores/LastSyncedTxHash/utils';
import { useGetTxHistoryVersion } from 'cb-wallet-data/stores/Transactions/hooks/useGetTxHistoryVersion';
import { useCreateWalletGroups } from 'cb-wallet-data/stores/WalletGroups/hooks/useCreateWalletGroups';
import { Tracer } from 'cb-wallet-data/Tracer/classes/Tracer';
import { EthereumAddressConfig, SolanaAddressConfig } from 'wallet-engine-signing/history';
import { refreshEthereumBalances } from 'wallet-engine-signing/history/Ethereum/history';
import { AddressConfig } from 'wallet-engine-signing/history/historyByChain';
import { refreshSolanaBalances } from 'wallet-engine-signing/history/Solana/balances';

import { ethereumAddressHistoryListener, solanaAddressHistoryListener } from '../listeners';
import { getWalletIndexesToScan, setWalletIndexScanned } from '../utils/balancesScannedByAccount';
import { deriveAccountBasedAddressConfigs } from '../utils/deriveAccountBasedAddressConfigs';

/**
 * Provides a callback, which can be used to invoke account-based chain
 * balance scanning for a given account, to auto-create previous wallet groups.
 *
 * This will check for unscanned indexes of the given account, and fetch
 * balances for them. If results are found, results will be saved and
 * any associated wallet groups created.
 *
 * Only indexes 1-9 are scanned, which will correspond to extra wallets
 * created during import of multi-wallet supported account types.
 */
export function useBalanceScanningForAccount() {
  const createWalletGroups = useCreateWalletGroups();
  const isEthereumNudgeKilled = useOverridableKillSwitch(
    getKillSwitchByPlatform('kill_balance_nudge_blockchain_eth'),
  );
  const isSolanaNudgeKilled = useOverridableKillSwitch(
    getKillSwitchByPlatform('kill_balance_nudge_blockchain_sol'),
  );
  const isSolanaDASAPIKilled = useOverridableKillSwitch(
    getKillSwitchByPlatform('kill_das_api_blockchain_sol'),
  );
  const isWebsocketNudgeEnabled = useIsFeatureEnabled('solana_websocket_nudges');
  const getTxHistoryVersion = useGetTxHistoryVersion();
  const isTestnetsEnabled = useIsTestnetsEnabled();

  return useCallback(
    async function balanceScanAndCreateWalletGroupsForAccount(
      accountId: Account['id'],
      userFacingAccountIndexByCreatedAt: number,
    ) {
      const indexesToScan = getWalletIndexesToScan(accountId);
      const hasAccountBeenBalanceScanned = indexesToScan.length === 0;
      if (hasAccountBeenBalanceScanned) return;

      const tracer = new Tracer('balance_scan_for_account');
      triggerBalanceScanForAccountStart({ userFacingAccountIndexByCreatedAt });

      try {
        // Get all addresses for a chain rather than querying by network for perf reasons
        tracer.startSpan('get_addresses_from_db');
        const [ethAddressesFromDb, solAddressesFromDb] = await Promise.all([
          getAddressesForBlockchain({
            blockchain: ethConfig.blockchain,
            accountId,
          }),
          getAddressesForBlockchain({
            blockchain: solConfig.blockchain,
            accountId,
          }),
        ]);

        const getLastSyncedTxHashByNetwork = await createLastSyncedTxHashLookupFn();

        tracer.endSpan('get_addresses_from_db');

        tracer.startSpan('balance_scan_all_indexes');

        const result = await Promise.all(
          indexesToScan.map(async function balanceScanIndex(walletIndexAsNum) {
            // Wallet group doesn't exist yet, but will be created if history is found on balance fetch.
            const walletGroupProps = {
              accountId,
              walletIndex: BigInt(walletIndexAsNum),
            };

            // TODO: We should be able to stop creating all the wallets for idx 1-9.
            // I've removed dependency for wallets here. As long as we have just derive and save the single
            // SOL + ETH addresses for every index in address table, we can use that instead to balance scan.
            const ethAddressOfIndex = getPrimaryAddressForIndex({
              blockchain: ethConfig.blockchain,
              addresses: ethAddressesFromDb,
              currencyCode: ethConfig.currencyCode,
              network: ethConfig.networkSetting.defaultMainnet.network,
              walletIndex: walletGroupProps.walletIndex,
              accountId,
            });

            const solAddressOfIndex = getPrimaryAddressForIndex({
              blockchain: solConfig.blockchain,
              addresses: solAddressesFromDb,
              currencyCode: solConfig.currencyCode,
              network: solConfig.networkSetting.defaultMainnet.network,
              walletIndex: walletGroupProps.walletIndex,
              accountId,
            });

            let ethAddressConfigs: AddressConfig[] = [];

            if (ethAddressOfIndex?.address) {
              ethAddressConfigs = await deriveAccountBasedAddressConfigs({
                blockchainSymbol: ETHEREUM_SYMBOL,
                walletIndex: BigInt(walletIndexAsNum),
                accountId,
                primaryAddress: ethAddressOfIndex.address,
                isNudgeKilled: isEthereumNudgeKilled,
                isDASAPIKilled: true,
                isWebsocketNudgeKilled: true,
                addressesForBlockchain: ethAddressesFromDb,
                getTxHistoryVersion,
                getLastSyncedTxHashByNetwork,
                networkSelection: isTestnetsEnabled ? 'allNetworks' : 'mainnetsAndCustomNetworks',
              });
            }

            let solAddressConfigs: AddressConfig[] = [];

            if (solAddressOfIndex?.address) {
              solAddressConfigs = await deriveAccountBasedAddressConfigs({
                blockchainSymbol: SOLANA_SYMBOL,
                walletIndex: BigInt(walletIndexAsNum),
                accountId,
                primaryAddress: solAddressOfIndex.address,
                isNudgeKilled: isSolanaNudgeKilled,
                isDASAPIKilled: isSolanaDASAPIKilled,
                isWebsocketNudgeKilled: !isWebsocketNudgeEnabled,
                addressesForBlockchain: solAddressesFromDb,
                getTxHistoryVersion,
                getLastSyncedTxHashByNetwork,
              });
            }

            const [ethResults, solResults] = await Promise.all([
              refreshEthereumBalances(ethAddressConfigs as EthereumAddressConfig[]),
              refreshSolanaBalances(solAddressConfigs as SolanaAddressConfig[]),
            ]);

            const hasEthHistory = ethResults.updates.some(function checkEthHistory(update) {
              const erc20Balances = update.erc20Balances ?? [];
              return Boolean(update.etherBalance || erc20Balances.length);
            });

            const hasSolHistory = solResults.updates.some(function checkSolHistory(update) {
              const splBalances = update.splBalances ?? [];
              return Boolean(update.solanaBalance || splBalances.length);
            });

            const shouldCreateWalletGroup = hasEthHistory || hasSolHistory;

            if (shouldCreateWalletGroup) {
              // Take already fetched balance results and save them to wallets.
              ethereumAddressHistoryListener.notifySubscribers('balance', ethResults);
              solanaAddressHistoryListener.notifySubscribers('balance', solResults);

              // When adding index's addresses to listener, skip refreshing since we
              // already fetched balances, and manually fired init event to save wallets.
              const config = { skipBalanceRefresh: true, skipTransactionRefresh: true };
              ethereumAddressHistoryListener.addAddresses(ethAddressConfigs, config);
              solanaAddressHistoryListener.addAddresses(solAddressConfigs, config);

              // TODO: In future if we remove unnecessary mainnet wallets from import
              // for index 1-9, we'll need to create them here with the wallet group.
              createWalletGroups([walletGroupProps]);
            }

            setWalletIndexScanned(accountId, walletIndexAsNum);

            return shouldCreateWalletGroup ? walletIndexAsNum : false;
          }),
        );

        tracer.endSpan('balance_scan_all_indexes');

        const createdWalletGroupIndexes: number[] = result.filter(
          (index) => Boolean(index), // index will be positive number or false, 0th index isn't supported in balance scanning
        ) as number[];

        triggerBalanceScanForAccountResult({
          userFacingAccountIndexByCreatedAt,
          createdWalletGroupsLength: createdWalletGroupIndexes.length,
          createdWalletGroupIndexes,
        });

        /* eslint-disable camelcase */
        tracer.setMetadata({
          metadata: {
            user_facing_account_index_by_created_at: `${userFacingAccountIndexByCreatedAt}`,
            created_wallet_groups_length: `${createdWalletGroupIndexes.length}`,
            indexes_scanned_length: `${indexesToScan.length}`,
            eth_addresses_from_db_length: `${ethAddressesFromDb.length}`,
            sol_addresses_from_db_length: `${solAddressesFromDb.length}`,
          },
        });
        /* eslint-enable camelcase */
        tracer.done();
      } catch (e) {
        triggerBalanceScanForAccountFailed({ userFacingAccountIndexByCreatedAt });
        const err = coerceError(e, 'useBalanceScanningForAccount');
        cbReportError({
          error: err,
          context: 'accounts-datalayer',
          isHandled: false,
          severity: 'error',
        });
      }
    },
    [
      isEthereumNudgeKilled,
      getTxHistoryVersion,
      isTestnetsEnabled,
      isSolanaNudgeKilled,
      createWalletGroups,
      isSolanaDASAPIKilled,
      isWebsocketNudgeEnabled,
    ],
  );
}

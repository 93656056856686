import {
  DehydratedState,
  hydrate,
  Query,
  QueryClient,
  QueryKey,
  QueryState,
} from '@tanstack/react-query';

import { schedulePersist } from './schedulePersist';

export type DehydratedQuery = {
  queryHash: string;
  queryKey: QueryKey;
  state: QueryState;
};

export type PersistedClientMetadata = {
  timestamp: number;
  buster: string | undefined;
};

export type PersistedClient = {
  metadata: PersistedClientMetadata | undefined;
  clientState: DehydratedState;
};

export type PersistorSync = {
  persistQuery: (query: DehydratedQuery) => void;
  removeQuery: (queryHash: string) => void;
  restoreClient: () => PersistedClient | undefined;
  removeClient: () => void;
  persistMetadata: (metadata: PersistedClientMetadata) => void;
};

export type PersistorAsync = {
  persistQuery: (
    ...args: Parameters<PersistorSync['persistQuery']>
  ) => Promise<ReturnType<PersistorSync['persistQuery']>>;
  removeQuery: (
    ...args: Parameters<PersistorSync['removeQuery']>
  ) => Promise<ReturnType<PersistorSync['removeQuery']>>;
  restoreClient: (
    ...args: Parameters<PersistorSync['restoreClient']>
  ) => Promise<ReturnType<PersistorSync['restoreClient']>>;
  removeClient: (
    ...args: Parameters<PersistorSync['removeClient']>
  ) => Promise<ReturnType<PersistorSync['removeClient']>>;
  persistMetadata: (
    ...args: Parameters<PersistorSync['persistMetadata']>
  ) => Promise<ReturnType<PersistorSync['persistMetadata']>>;
};

export type Persistor = PersistorSync | PersistorAsync;

export type PersistQueryClientOptions = {
  /**
   * The QueryClient to persist.
   */
  queryClient: QueryClient;
  /**
   * The Persistor interface for storing and restoring the cache
   * to / from a persisted location.
   */
  persistor: Persistor;
  /**
   * The max-allowed age of the cache.
   * If a persisted cache is found that is older than this
   * time, it will be discarded.
   */
  maxAge?: number;
  /**
   * A unique string that can be used to forcefully
   * invalidate existing caches if they do  not share the same buster string.
   */
  buster?: string;
  /**
   * Callback executed when an error is thrown during persisting or hydrating.
   */
  onError?: (err: Error) => void;

  /**
   * By default, all queries will be persisted via persistQueryClient
   * Thse following functions allow for more fine-grained control over which queries are persisted.
   *
   * shouldDehydrateQuery: A function that determines whether a query should be persisted
   * from application state into local storage
   *
   * shouldHydrateQuery: A function that determines whether a query should be hydrated
   * from local storage into application state
   */
  shouldDehydrateQuery: (query: Query) => boolean;

  shouldHydrateQuery: (query: DehydratedQuery) => boolean;
};

function dehydrateQuery(query: Query): DehydratedQuery {
  if (typeof query.options.meta?.dehydrateQuery === 'function') {
    return query.options.meta.dehydrateQuery(query);
  }
  return {
    state: query.state,
    queryKey: query.queryKey,
    queryHash: query.queryHash,
  };
}

/**
 * Persists a QueryClient. This is similar to `persistQueryClient-experimental` from `react-query` but
 * queries are persisted individually instead of the whole store, which is a lot more performant.
 *
 * Based on https://github.com/tannerlinsley/react-query/blob/5848fab8a560efcf66ef0062c207c3004bccad83/src/persistQueryClient-experimental/index.ts.
 */
export async function persistQueryClient({
  queryClient,
  persistor,
  maxAge = 1000 * 60 * 60 * 24,
  buster,
  onError,
  shouldDehydrateQuery,
  shouldHydrateQuery,
}: PersistQueryClientOptions) {
  // Restore the cache.
  try {
    const persistedClient = await persistor.restoreClient();

    if (persistedClient !== undefined) {
      if (persistedClient.metadata !== undefined) {
        const expired = Date.now() - persistedClient.metadata.timestamp > maxAge;
        const busted = persistedClient.metadata.buster !== buster;

        const queriesToHydrate = persistedClient.clientState.queries.filter((query) => {
          return shouldHydrateQuery(query);
        });

        const updatedClientState = { ...persistedClient.clientState, queries: queriesToHydrate };
        if (expired || busted) {
          await persistor.removeClient();
        } else {
          hydrate(queryClient, updatedClientState);
        }
      } else {
        await persistor.removeClient();
      }
    }
  } catch (err) {
    onError?.(err as Error);
    try {
      await persistor.removeClient();
    } catch (err2) {
      onError?.(err2 as Error);
    }
  }

  // Persist metadata about the cache.
  try {
    await persistor.persistMetadata({
      buster,
      timestamp: Date.now(),
    });
  } catch (err) {
    onError?.(err as Error);
  }

  // Subscribe to changes in the query cache to trigger persistance.
  queryClient.getQueryCache().subscribe(function triggerLocalStorageQueryPersistence(event) {
    const shouldPersist = shouldDehydrateQuery(event.query);

    if (!shouldPersist) return;
    if (event === undefined) {
      return;
    }

    schedulePersist(async () => {
      try {
        switch (event.type) {
          case 'added':
          case 'updated':
            // Only persist queries that have data
            if (event.query.state.data) {
              await persistor.persistQuery(dehydrateQuery(event.query));
            }
            break;
          case 'removed':
            await persistor.removeQuery(event.query.queryHash);
            break;
          default:
            break;
        }
      } catch (err) {
        onError?.(err as Error);
      }
    });
  });
}

import { useOverridableKillSwitch as useKillSwitch } from 'cb-wallet-data/hooks/KillSwitches/useOverridableKillSwitch';
import { getPlatformName } from 'cb-wallet-metadata/metadata';
import { useRecoilValue } from 'recoil';
import { PlatformName } from '@cbhq/client-analytics';

import { isTestnetsEnabledAtom } from './state';

export function useIsTestnetsEnabled(): boolean {
  const killswitchName =
    getPlatformName() === PlatformName.extension
      ? 'kill_testnet_mode_enabled_extension'
      : 'kill_testnet_mode_enabled_rn';

  const isTestnetsEnabled = useRecoilValue(isTestnetsEnabledAtom);
  const isTestnetModeEnabledKilled = useKillSwitch(killswitchName);

  if (isTestnetsEnabled === undefined) {
    return !isTestnetModeEnabledKilled;
  }
  return isTestnetsEnabled;
}

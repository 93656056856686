import { useCallback } from 'react';
import {
  AllPossibleBlockchainSymbol,
  blockchainConfigurations,
} from 'cb-wallet-data/chains/blockchains';
import { useIsTestnetsEnabled } from 'cb-wallet-data/hooks/Testnets/useIsTestnetsEnabled';

import { Wallet } from '../models/Wallet';

import { useIsNetworkAllowed } from './useIsNetworkAllowed';

/**
 * Provides a function to check if a wallet is valid and usable.
 *
 * For a wallet to be valid, it must:
 * - Have a valid configuration for its blockchain. @see blockchainConfigurations
 * - Not be on a network disabled via feature flags. @see useIsNetworkAllowed
 * - Not be on a testnet network, if testnets aren't enabled. @see useIsTestnetsEnabled
 */
export function useIsValidWallet() {
  const isNetworkAllowed = useIsNetworkAllowed();
  const isTestnetsEnabled = useIsTestnetsEnabled();

  return useCallback(
    function isValidWallet(wallet: Wallet) {
      const { rawValue: blockchainSymbol } = wallet.blockchain;
      const blockchainHasConfig = Boolean(
        blockchainConfigurations[blockchainSymbol as AllPossibleBlockchainSymbol],
      );

      return (
        blockchainHasConfig &&
        isNetworkAllowed(wallet.network) &&
        (!wallet.network.isTestnet || isTestnetsEnabled)
      );
    },
    [isNetworkAllowed, isTestnetsEnabled],
  );
}

import { ContractAddress } from 'cb-wallet-data/chains/AccountBased/Ethereum/models/ContractAddress';
import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { Persistable } from 'cb-wallet-data/persistence/Database.interface';
import { Column, Entity, Index, PrimaryColumn } from '@cbhq/typeorm';

@Entity('swap_asset_stats')
@Index('IDX_SWAP_STATS_ID', ['id'])
@Index('IDX_SWAP_STATS_BLOCKCHAIN_CURRENCY_CHAIN_CONTRACT', [
  'blockchainStr',
  'currencyCodeStr',
  'chainIdStr',
  'contractAddressStr',
])
export class SwapAssetStatsDMO {
  @PrimaryColumn()
  id!: string;

  @Column()
  blockchainStr!: string;

  @Column()
  currencyCodeStr!: string;

  @Column({ nullable: true })
  contractAddressStr?: string;

  @Column()
  chainIdStr!: string;

  @Column()
  selectedCountStr!: string;
}

/**
 * Entity representing the number of times a user has tapped on a swap asset on
 * either the source or target swap asset picker screens
 *
 * @property id Unique identifier
 * @property blockchain The [Blockchain] of the asset
 * @property currencyCode The [CurrencyCode] of the asset
 * @property contractAddress The [ContractAddress] of the asset
 * @property selectedCount The number of times an asset was selected in the swap currency picker
 */
export class SwapAssetStats implements Persistable<SwapAssetStatsDMO> {
  id: string;
  blockchain: Blockchain;
  currencyCode: CurrencyCode;
  contractAddress: ContractAddress | undefined;
  chainId: bigint;
  selectedCount: bigint;

  constructor(
    blockchain: Blockchain,
    chainId: bigint,
    currencyCode: CurrencyCode,
    contractAddress: ContractAddress | undefined,
    selectedCount: bigint,
  ) {
    this.id = SwapAssetStats.generateID(blockchain, chainId, currencyCode, contractAddress);
    this.blockchain = blockchain;
    this.currencyCode = currencyCode;
    this.contractAddress = contractAddress;
    this.selectedCount = selectedCount;
    this.chainId = chainId;
  }

  get asDMO(): SwapAssetStatsDMO {
    return {
      id: this.id,
      blockchainStr: this.blockchain.rawValue,
      currencyCodeStr: this.currencyCode.rawValue,
      contractAddressStr: this.contractAddress?.rawValue ?? undefined,
      chainIdStr: String(this.chainId),
      selectedCountStr: String(this.selectedCount),
    };
  }

  static fromDMO(dmo: SwapAssetStatsDMO): SwapAssetStats {
    return new SwapAssetStats(
      new Blockchain(dmo.blockchainStr),
      BigInt(dmo.chainIdStr),
      new CurrencyCode(dmo.currencyCodeStr),
      dmo.contractAddressStr ? new ContractAddress(dmo.contractAddressStr) : undefined,
      BigInt(dmo.selectedCountStr),
    );
  }

  static generateID(
    blockchain: Blockchain,
    chainId: bigint,
    currencyCode: CurrencyCode,
    contractAddress: ContractAddress | undefined,
  ): string {
    return `${blockchain.rawValue.toUpperCase()}/${currencyCode.rawValue.toUpperCase()}/ETHEREUM_CHAIN:${chainId}/false/${
      contractAddress?.rawValue ?? 'undefined'
    }`;
  }
}

import { postJSON, UTXOChains } from 'wallet-engine-signing/blockchains/UTXO/indexer';

type GetBlockHeightParams = {
  testnet: boolean;
  blockchainSymbol: UTXOChains;
};

export async function getBlockHeight({
  testnet,
  blockchainSymbol,
}: GetBlockHeightParams): Promise<number> {
  const { body: blockHeight } = await postJSON<number>({
    blockchainSymbol,
    endpoint: '/v1/getBlockHeight',
    body: { testnet },
  });

  if (!Number.isInteger(blockHeight)) {
    throw new Error('unexpected response');
  }

  return blockHeight;
}

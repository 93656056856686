import { useCallback } from 'react';
import { panoramaGlobalSearchRecentSearchesAtom } from 'cb-wallet-data/stores/Search/state';
import { RecentSearch, SearchEntity } from 'cb-wallet-data/stores/Search/types';
import { useRecoilState } from 'recoil';

const RECENT_SEARCHES_MAX_SIZE = 20;

export function useRecentSearches() {
  const [recentSearches, setRecentSearches] = useRecoilState(
    panoramaGlobalSearchRecentSearchesAtom,
  );

  const deleteRecentSearchItem = useCallback(
    (searchItem: RecentSearch<SearchEntity>) => {
      setRecentSearches(recentSearches.filter((item) => item.key !== searchItem.key));
    },
    [recentSearches, setRecentSearches],
  );

  const deleteAllRecentSearches = useCallback(() => {
    setRecentSearches([]);
  }, [setRecentSearches]);

  const updateRecentSearches = useCallback(
    function updateRecentSearches(searchItem: RecentSearch<SearchEntity>) {
      // If the item is already in the list, remove it
      const temp = recentSearches.filter((item) => item.key !== searchItem.key);
      // If the list is longer than or equal to max size, remove the last item
      if (temp.length >= RECENT_SEARCHES_MAX_SIZE) {
        temp.pop();
      }
      temp.unshift(searchItem);
      setRecentSearches(temp);
    },
    [recentSearches, setRecentSearches],
  );

  return {
    recentSearches,
    deleteRecentSearchItem,
    deleteAllRecentSearches,
    updateRecentSearches,
  };
}

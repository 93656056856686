import { tryGetRepository } from 'cb-wallet-data/persistence/Database';
import {
  CuratedAssetSetting,
  CuratedAssetSettingDMO,
} from 'cb-wallet-data/stores/AssetManagement/models/CuratedAssetSetting';
import {
  UserCollectibleSetting,
  UserCollectibleSettingDMO,
} from 'cb-wallet-data/stores/AssetManagement/models/UserCollectibleSetting';
import {
  UserWalletSetting,
  UserWalletSettingDMO,
} from 'cb-wallet-data/stores/AssetManagement/models/UserWalletSetting';
import { filterFulfilled } from 'cb-wallet-data/utils/typeormHelpers';

function curatedAssetRepo() {
  return tryGetRepository<CuratedAssetSettingDMO>('curated_asset_setting');
}

function collectibleSettingsRepo() {
  return tryGetRepository<UserCollectibleSettingDMO>('user_collectible_setting');
}

function walletSettingsRepo() {
  return tryGetRepository<UserWalletSettingDMO>('user_wallet_setting');
}

export async function getAllCuratedAssetSettings(): Promise<CuratedAssetSetting[]> {
  const settings = await curatedAssetRepo().find();

  return settings.map(CuratedAssetSetting.fromDMO);
}

export async function getCuratedAssetSettingById(
  id: string,
): Promise<CuratedAssetSetting | undefined> {
  const setting = await curatedAssetRepo().findOne({
    where: { id },
  });

  return setting ? CuratedAssetSetting.fromDMO(setting) : undefined;
}

export async function addCuratedAssetSetting(setting: CuratedAssetSetting): Promise<string> {
  await curatedAssetRepo().upsert(setting.asDMO, ['id']);
  return setting.id;
}

export async function addCuratedAssetSettings(
  settings: CuratedAssetSetting[],
): Promise<string | string[]> {
  const promises = await Promise.allSettled(
    curatedAssetRepo().batchUpsert(
      settings.map((s) => s.asDMO),
      ['id'],
    ),
  );
  return filterFulfilled(promises);
}

export async function clearCuratedAssetSettings(): Promise<void> {
  await curatedAssetRepo().clear();
}

export async function getAllUserCollectibleSettings(): Promise<UserCollectibleSetting[]> {
  const settings = await collectibleSettingsRepo().find();
  return settings.map(UserCollectibleSetting.fromDMO);
}

export async function getUserCollectibleSettingById(
  id: string,
): Promise<UserCollectibleSetting | undefined> {
  const setting = await collectibleSettingsRepo().findOne({
    where: { id },
  });

  return setting ? UserCollectibleSetting.fromDMO(setting) : undefined;
}

export async function addUserCollectibleSetting(setting: UserCollectibleSetting): Promise<string> {
  await collectibleSettingsRepo().upsert(setting.asDMO, ['id']);
  return setting.id;
}

export async function addUserCollectibleSettings(
  settings: UserCollectibleSetting[],
): Promise<string | string[]> {
  const promises = await Promise.allSettled(
    collectibleSettingsRepo().batchUpsert(
      settings.map((s) => s.asDMO),
      ['id'],
    ),
  );
  return filterFulfilled(promises);
}

export async function getAllUserWalletSettings(): Promise<UserWalletSetting[]> {
  const settings = await walletSettingsRepo().find();
  return settings.map(UserWalletSetting.fromDMO);
}

export async function getUserWalletSettingById(id: string): Promise<UserWalletSetting | undefined> {
  const useWalletSetting = await walletSettingsRepo().findOne({ where: { id } });
  return useWalletSetting ? UserWalletSetting.fromDMO(useWalletSetting) : undefined;
}

export async function addUserWalletSetting(setting: UserWalletSetting): Promise<string> {
  await walletSettingsRepo().upsert(setting.asDMO, ['id']);
  return setting.id;
}

export async function addUserWalletSettings(
  settings: UserWalletSetting[],
): Promise<string | string[]> {
  const promises = await Promise.allSettled(
    walletSettingsRepo().batchUpsert(
      settings.map((s) => s.asDMO),
      ['id'],
    ),
  );
  return filterFulfilled(promises);
}

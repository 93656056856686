export enum AssetManagementStatus {
  // these represent statuses caused by user actions
  // userSpam => asset marked as spam by user action
  userSpam = 0,
  // userSpamVisible => spam asset, marked visible by user action
  userSpamVisible = 1,
  // userVisible => general (non-spam) asset, initially marked as hidden by user action and then marked as visible by user action
  userVisible = 2,
  // userHidden => general (non-spam) asset, marked as hidden by user action
  userHidden = 3,
  // spam => asset marked as spam by backend service
  spam = 100,
  // whitelist => asset marked as whitelist by backend service
  whitelist = 101,
}

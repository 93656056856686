import { useMemo } from 'react';
import { SettingCategoryConfig } from 'cb-wallet-data/stores/SettingsRedesign/types';
import { calculateSearchScore } from 'cb-wallet-data/utils/search/calculateSearchScore';
import orderBy from 'lodash/orderBy';

type Props = {
  suggestions: SettingCategoryConfig[];
  query: string;
};

export function useSettingsSearch({ suggestions, query }: Props): SettingCategoryConfig[] {
  return useMemo(() => {
    if (!query) {
      return [];
    }

    const reducedSuggestions = suggestions.reduce<SettingCategoryConfig[]>(
      function filterSuggestionsByQuery(returningSuggestions, suggestion) {
        let title = '';
        let description = '';

        if (typeof suggestion.title === 'string') {
          title = suggestion.title;
        } else if (typeof suggestion.searchableTitle === 'string') {
          title = suggestion.searchableTitle;
        }

        if (typeof suggestion.description === 'string') {
          description = suggestion.description;
        } else if (typeof suggestion.searchableDescription === 'string') {
          description = suggestion.searchableDescription;
        }

        const searchScore = calculateSearchScore(query, title, description);

        if (searchScore.relevance !== null) {
          returningSuggestions.push(suggestion);
        }

        return returningSuggestions;
      },
      [],
    );

    return orderBy(reducedSuggestions, ['relevance', 'coverage'], ['asc', 'desc']);
  }, [query, suggestions]);
}

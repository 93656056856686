import { Suspense } from 'react';
import { Logger, Query, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { logMetric } from 'cb-wallet-analytics/utils/log';
import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { v4 as uuidv4 } from 'uuid';
import { MetricType } from '@cbhq/client-analytics';

import { createPersistor } from './react-query/createPersistor';
import { InstrumentedPersistor } from './react-query/InstrumentedPersistor';
import { persistQueryClient, PersistQueryClientOptions } from './react-query/persistQueryClient';
import { isJestTest } from './utils/debuggingUtils';

export function bustQueryCache(): void {
  // Disabling as optional chaining here breaks the build for apps/dapp
  // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
  if (typeof window === 'undefined' || typeof window.localStorage === 'undefined') {
    return;
  }

  localStorage.setItem('QUERY_CACHE_BUSTER', uuidv4());
}

export function getCacheBuster(): string {
  // Disabling as optional chaining here breaks the build for apps/dapp
  // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
  if (typeof window === 'undefined' || typeof window.localStorage === 'undefined') {
    return '0';
  }

  return localStorage.getItem('QUERY_CACHE_BUSTER') ?? '0';
}

const CACHE_TIME = isJestTest()
  ? Infinity // Setting a cache time other than the default Infinity prevents jest from exiting.
  : 1000 * 60 * 60 * 24 * 7; // 7 days

export function buildQueryClient(disableRetries?: boolean, logger?: Logger): QueryClient {
  return new QueryClient({
    logger,
    defaultOptions: {
      queries: {
        cacheTime: CACHE_TIME,
        ...(disableRetries ? { retry: false } : {}),
      },
      mutations: {},
    },
    queryCache: new QueryCache({
      onError(error: ErrorOrAny, query) {
        logMetric({
          metricName: 'react_query.query_cache.error',
          metricType: MetricType.count,
          value: 1,
        });

        cbReportError({
          error,
          context: 'query_cache_error',
          metadata: { queryKey: query.queryKey.toString() },
          isHandled: false,
          severity: 'error',
        });
      },
    }),
  });
}

export const queryClient = buildQueryClient();

const DEBUG_PERSIST = false;
export const persistor = createPersistor();

export function getDefaultShouldHydrateQueryOptions() {
  /**
   *
   * By default, all queries will be persisted via persistQueryClient
   * Adding a query string to this array will prevent that query from being persisted
   * to the local storage cache. i.e. If you want a query to always fetch from the server.
   * These are the default queries that are excluded from being persisted for any apps that use the
   * QueryProvider from cb-wallet-data.
   */
  const EXCLUDED_CACHE_KEY_STRINGS = new Set(['kill_switches', 'health-check']);

  return (query: Query) => {
    return !query.isStale() && !EXCLUDED_CACHE_KEY_STRINGS.has(query.queryKey[0] as string);
  };
}

export function callDefaultPersistQueryClient(options?: Partial<PersistQueryClientOptions>) {
  persistQueryClient({
    queryClient,
    persistor: DEBUG_PERSIST ? new InstrumentedPersistor(persistor) : persistor,
    maxAge: CACHE_TIME,
    buster: getCacheBuster(),
    shouldHydrateQuery: () => true,
    shouldDehydrateQuery: getDefaultShouldHydrateQueryOptions(),
    onError(error: ErrorOrAny) {
      logMetric({
        metricName: 'react_query.query_persist.error',
        metricType: MetricType.count,
        value: 1,
      });

      cbReportError({
        error,
        context: 'query_persist_error',
        metadata: { persistor: persistor.constructor?.name },
        isHandled: false,
        severity: 'error',
      });
    },
    ...(options ?? {}),
  });
}

type Props = {
  children: React.ReactNode;
};

export function QueryProvider({ children }: Props): JSX.Element {
  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={null}>{children}</Suspense>
    </QueryClientProvider>
  );
}

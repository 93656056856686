import { Icon } from '@cbhq/cds-web/icons';
import { Box, Fallback, VStack } from '@cbhq/cds-web/layout';

import { AssetSelector, Selector } from './AssetSelectorGroup';

const loadingSelectorProps: Selector = {
  title: <Fallback width={100} percentage height={20} flexGrow={1} />,
  subtitle: <Fallback width={100} percentage height={20} flexGrow={1} />,
  detail: (
    <Box justifyContent="flex-end" spacingEnd={3}>
      <Fallback width={50} percentage height={20} />
    </Box>
  ),
  subdetail: (
    <Box justifyContent="flex-end" spacingEnd={3}>
      <Fallback width={50} percentage height={20} />
    </Box>
  ),
  media: <Box height={32} width={32} borderRadius="roundedFull" background="backgroundAlternate" />,
  testID: 'selector-fallback',
};

export function AssetSelectorGroupLoadingFallback() {
  return (
    <VStack justifyContent="flex-end" gap={0.5} testID="asset-selector-group-loading">
      <AssetSelector {...loadingSelectorProps} />
      <Box alignItems="center">
        <Icon
          name="verticalLine"
          size="s"
          spacingStart={1}
          color="foregroundMuted"
          testID="vertical-line"
        />
      </Box>
      <AssetSelector {...loadingSelectorProps} />
    </VStack>
  );
}

import { useCallback } from 'react';
import { triggerAccountsHydrationResult } from 'cb-wallet-analytics/app-load';
import { useSetRecoilState } from 'recoil';

import { getAccounts } from '../database';
import { accountsAtom } from '../state';

/**
 * Hydrates accounts from client database to recoil state.
 *
 * THIS SHOULD ONLY BE CALLED ONCE WHEN THE APP INITS.
 */
export function useHydrateAccounts() {
  const setAccounts = useSetRecoilState(accountsAtom);

  return useCallback(
    async function hydrateAccounts() {
      const accountsFromDb = await getAccounts();
      setAccounts(accountsFromDb);
      triggerAccountsHydrationResult({
        accountsLength: accountsFromDb.length,
      });
      return accountsFromDb.length;
    },
    [setAccounts],
  );
}

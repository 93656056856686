import { useCallback, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { useProcessWalletConnectionResponse } from 'cb-wallet-data/stores/Accounts/hooks/useProcessWalletConnectionResponse';
import { ProcessWalletConnectionResponseError, ProcessWalletConnectionResponseErrorType } from 'cb-wallet-data/stores/Accounts/types';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { Modal, ModalBody } from '@cbhq/cds-web/overlays';
import { useToast } from '@cbhq/cds-web/overlays/useToast';
import { getAvailableNetworks } from ':dapp/connection/hooks/useAvailableNetworks';
import { useConnectWallet } from ':dapp/connection/hooks/useConnectWallet';
import { ConnectWalletOptions, WalletConnectorConnectResponse, WalletProviderInfo, WalletProviderNetworkInfo } from ':dapp/connection/types';
import { COINBASE_WALLET_ID } from ':dapp/connection/wallet-providers/coinbase-wallet';
import { useSkipTransactionRefresh } from ':dapp/hooks/useSkipTransactionRefresh';
import { CoinbaseWalletSelector } from './CoinbaseWalletSelector';
import { ConnectionLoading } from './ConnectionLoading';
import { ConnectionNetworkSelector } from './ConnectionNetworkSelector';
import { ConnectionWalletSelector } from './ConnectionWalletSelector';
type ConnectionModalProps = {
  onClose: () => void;
  onDidClose?: () => void;
  visible?: boolean;
};
const messages = defineMessages({
  existingAccountError: {
    defaultMessage: "You've already imported this account with another wallet.",
    description: 'Error message telling the user they already have an account'
  }
});
const modalClass = "md40n9";
export function ConnectionModal({
  onClose,
  visible
}: ConnectionModalProps) {
  const [selectedWalletProvider, setSelectedWalletProvider] = useState<WalletProviderInfo | null>(null);
  const [connectionResponse, setConnectionResponse] = useState<WalletConnectorConnectResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const {
    isPhone
  } = useBreakpoints();
  const {
    formatMessage
  } = useIntl();
  const connectWallet = useConnectWallet();
  const processWalletConnectionResponse = useProcessWalletConnectionResponse();

  // For certain promotional pages we don't want to kick off a full tx refresh
  const skipTransactionRefresh = useSkipTransactionRefresh();

  // Handle the connection response after the modal closes
  // This is necessary because the modal's exit animation is async and
  // we need to wait for it to finish before processing the response.
  // otherwise the modal does not fully close. This is likely an issue with framer-motion
  const handleDidClose = useCallback(async () => {
    if (connectionResponse?.result) {
      try {
        await processWalletConnectionResponse(connectionResponse.result, skipTransactionRefresh);
      } catch (error) {
        if (error instanceof ProcessWalletConnectionResponseError) {
          if (error.name === ProcessWalletConnectionResponseErrorType.ADDRESS_ALREADY_IMPORTED) {
            setLoading(false);
            toast.show(formatMessage(messages.existingAccountError));
            return;
          }
        }
        cbReportError({
          error: coerceError(error, 'connection modal'),
          context: 'dapp-account-creation',
          severity: 'error',
          isHandled: false
        });
      } finally {
        setConnectionResponse(null);
        setLoading(false);
        onClose();
      }
    }
  }, [connectionResponse, formatMessage, processWalletConnectionResponse, toast, skipTransactionRefresh, onClose]);
  const clearState = useCallback(() => {
    setSelectedWalletProvider(null);
    setLoading(false);
  }, []);
  const handleClose = useCallback(() => {
    onClose();
    clearState();
  }, [clearState, onClose]);
  const handleConnect = useCallback(async (walletProvider: WalletProviderInfo, network: WalletProviderNetworkInfo, options?: ConnectWalletOptions) => {
    setLoading(true);
    const response = await connectWallet({
      walletProvider,
      network,
      options
    });
    setConnectionResponse(response);
    handleClose();
  }, [connectWallet, handleClose]);
  const handleSelect = useCallback(async (walletProvider: WalletProviderInfo, options?: ConnectWalletOptions) => {
    setSelectedWalletProvider(walletProvider);
    try {
      if (walletProvider.id === COINBASE_WALLET_ID) {
        // Return early if the wallet provider is coinbase wallet to show
        // the coinbase wallet selector options
        return;
      }
      const availableNetworks = await getAvailableNetworks(walletProvider);
      if (availableNetworks.length === 1) {
        await handleConnect(walletProvider, availableNetworks[0], options);
      }
    } catch (error) {
      cbReportError({
        error: coerceError(error, 'wallet select error'),
        context: 'wallet-connection-error',
        severity: 'error',
        isHandled: true
      });
    }
  }, [handleConnect]);
  const content = useMemo(() => {
    if (loading && selectedWalletProvider) {
      return <ConnectionLoading onClose={handleClose} walletProvider={selectedWalletProvider} />;
    }
    if (selectedWalletProvider?.id === COINBASE_WALLET_ID) {
      return <CoinbaseWalletSelector onBack={clearState} onClose={handleClose} onConnect={handleConnect} />;
    }
    if (selectedWalletProvider) {
      return <ConnectionNetworkSelector onBack={clearState} onClose={handleClose} onConnect={handleConnect} walletProvider={selectedWalletProvider} />;
    }
    return <ConnectionWalletSelector onClose={handleClose} onSelect={handleSelect} />;
  }, [clearState, handleClose, handleConnect, handleSelect, loading, selectedWalletProvider]);
  const modalProps = useMemo(() => {
    if (loading && !isPhone) {
      return {
        width: 480
      };
    }
    return {};
  }, [loading, isPhone]);
  return <Modal className={modalClass} disablePortal dangerouslyDisableResponsiveness onRequestClose={onClose} onDidClose={handleDidClose} visible={Boolean(visible)} testID="connection-modal" zIndex={5} {...modalProps}>
      <ModalBody>{content}</ModalBody>
    </Modal>;
}

require("./ConnectionModal.linaria.module.css!=!../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./ConnectionModal.tsx");
import { useCallback } from 'react';
import { isFeatureEnabledStore } from 'cb-wallet-data/FeatureManager/classes/IsFeatureEnabledStore';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';

import { COINBASE_SMART_WALLET_ID, COINBASE_WALLET_ID } from '../wallet-providers/coinbase-wallet';
import { PHANTOM_ID } from '../wallet-providers/phantom';
import { WALLET_CONNECT_ID } from '../wallet-providers/walletconnect';

export function useGetEnabledWalletProviders() {
  const isCoinbaseWalletEnabled = useIsFeatureEnabled('wallet_provider_coinbase_wallet');
  const isWalletConnectEnabled = useIsFeatureEnabled('wallet_provider_walletconnect');
  const isPhantomEnabled = useIsFeatureEnabled('wallet_provider_phantom');

  return useCallback(
    (id: string) => {
      switch (id) {
        case COINBASE_WALLET_ID:
        case COINBASE_SMART_WALLET_ID:
          return isCoinbaseWalletEnabled;
        case PHANTOM_ID:
          return isPhantomEnabled;
        case WALLET_CONNECT_ID:
          return isWalletConnectEnabled;
        default:
          return false;
      }
    },
    [isCoinbaseWalletEnabled, isPhantomEnabled, isWalletConnectEnabled],
  );
}

export function getEnabledWalletProviders(id: string) {
  switch (id) {
    case COINBASE_WALLET_ID:
    case COINBASE_SMART_WALLET_ID:
      return isFeatureEnabledStore.getIsFeatureEnabled('wallet_provider_coinbase_wallet');
    case PHANTOM_ID:
      return isFeatureEnabledStore.getIsFeatureEnabled('wallet_provider_phantom');
    case WALLET_CONNECT_ID:
      return isFeatureEnabledStore.getIsFeatureEnabled('wallet_provider_walletconnect');
    default:
      return false;
  }
}

import { useMemo } from 'react';
import { CSSProperties } from '@linaria/core';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { extractColors } from 'extract-colors';

// Get image colors
export async function getGradientColorsFromImage(imgSrc: string) {
  const extractedColors = await extractColors(imgSrc);
  const sortedColors = extractedColors.sort((a, b) => b.area - a.area);

  const gradientColors = (() => {
    if (sortedColors.length >= 2) {
      return { start: sortedColors[0], stop: sortedColors[1] };
    }

    if (sortedColors.length === 1) {
      return { start: sortedColors[0], stop: sortedColors[0] };
    }

    return null;
  })();

  if (!gradientColors) {
    return null;
  }

  const gradientBackground = (() => {
    const start = `rgba(${gradientColors.start.red}, ${gradientColors.start.green}, ${gradientColors.start.blue}, 0.9)`;
    const stop = `rgba(${gradientColors.stop.red}, ${gradientColors.stop.green}, ${gradientColors.stop.blue}, 0.45)`;
    return `linear-gradient(180deg, ${start}, ${stop})`;
  })();

  const style: CSSProperties = {
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    background: gradientBackground,
  };

  return style;
}

export function useGradientFromImage(image: string) {
  /**
   * @todo don't reference the _next directory directly. https://jira.coinbase-corp.com/browse/WALL-39028
   */
  const localImagePath = `/_next/image?url=${encodeURIComponent(image)}&w=750&q=75`;

  const { data, isError, isLoading } = useQuery<CSSProperties | null, Error>(
    ['getGradientFromImage', image],
    async () => getGradientColorsFromImage(localImagePath),
    { enabled: !!image },
  );

  const memoized = useMemo(() => data ?? {}, [data]);

  return { data: memoized, isError, isLoading };
}

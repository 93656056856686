import { useMemo } from 'react'; /* eslint-disable no-param-reassign */
import { defineMessages, useIntl } from 'react-intl';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { useHasAccountsAndWalletGroups } from 'cb-wallet-data/hooks/initialization/useHasAccountsAndWalletGroups';
import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { getNetworkForWacId } from 'cb-wallet-data/stores/Networks/utils/getNetworkForWacId';
import { useGetSearchResults } from 'cb-wallet-data/stores/Search/hooks/useGetSearchResults';
import { AllSearchEntity, SearchEntity } from 'cb-wallet-data/stores/Search/types';
import { SearchPath } from 'cb-wallet-data/stores/Search/util';

import { SearchResultProps } from ':dapp/components/Search/SearchResult';
import { useKeyActionsSearch } from ':dapp/hooks/keyActions/useKeyActionsSearch';
import { useSettingsSearch } from ':dapp/hooks/useSettingsSearch';
import { getDynamicPagePath } from ':dapp/utils/getDynamicPagePath';
import { RoutesEnum } from ':dapp/utils/RoutesEnum';

// eslint-disable-next-line no-process-env
const COINBASE_API_URL = process.env.COINBASE_API_URL;

const messages = defineMessages({
  assets: {
    defaultMessage: 'My assets',
    description: 'Label for my assets section title in search results',
  },
  tokens: {
    defaultMessage: 'Tokens',
    description: 'Label for tokens section title in search results',
  },
  ens: {
    defaultMessage: 'ENS',
    description: 'Label for ENS section title in search results',
  },
  apps: {
    defaultMessage: 'Apps',
    description: 'Label for Apps section title in search results',
  },
  nftCollections: {
    defaultMessage: 'NFT Collections',
    description: 'Label for NFT Collections section title in search results',
  },
  transactions: {
    defaultMessage: 'Transactions',
    description: 'Label for Transactions section title in search results',
  },
  keyActions: {
    defaultMessage: 'Key Actions',
    description: 'Label for Key Actions section title in search results',
  },
  collections: {
    defaultMessage: 'Collections',
    description: 'Label for Collections section title in search results',
  },
  settings: {
    defaultMessage: 'Settings',
    description: 'Label for Settings section title in search results',
  },
});

export function useGlobalSearchResults(query: string, searchFilter: SearchEntity) {
  const { formatMessage } = useIntl();
  const settingSuggestions = useSettingsSearch({ query });
  const keyActionsSuggestions = useKeyActionsSearch({ query });
  const hasUserConnectedWallets = useHasAccountsAndWalletGroups();
  const isGlobalSearchUsingWacEnabled = useIsFeatureEnabled('global_search_using_wac_panorama');
  const isGlobalSearchUsingNLPEnabled = useIsFeatureEnabled('global_search_using_nlp');

  const searchEntities = useMemo(() => {
    if (searchFilter === SearchEntity.SEARCH_ENTITY_UNSPECIFIED) {
      return [
        SearchEntity.SEARCH_ENTITY_ASSET,
        SearchEntity.SEARCH_ENTITY_DAPP,
        SearchEntity.SEARCH_ENTITY_NFT_COLLECTIONS,
      ];
    }
    if (searchFilter === SearchEntity.SEARCH_ENTITY_SETTING) {
      return [];
    }
    return [searchFilter];
  }, [searchFilter]);

  const { entries, isLoading } = useGetSearchResults({
    query,
    searchType: SearchPath.Search,
    entities: searchEntities,
    baseUrlOverride: COINBASE_API_URL,
    pageSize: 20,
    maxResults: 20,
    isEnabled: searchFilter !== SearchEntity.SEARCH_ENTITY_SETTING,
    isWacSearchEnabled: isGlobalSearchUsingWacEnabled,
    isNLPSearchEnabled: isGlobalSearchUsingNLPEnabled,
  });

  const sections = useMemo(() => {
    const wacSearchResultsMap = new Map<string, SearchResultProps<AllSearchEntity>>();

    const tokensMap = new Map<string, SearchResultProps<SearchEntity.SEARCH_ENTITY_ASSET>>();

    const ensMap = new Map<string, SearchResultProps<SearchEntity.SEARCH_ENTITY_ENS>>();

    /**
     * @TODO Add transaction search results
     * @see https://jira.coinbase-corp.com/browse/WALL-27457
     */
    const transactionsMap = new Map<
      string,
      SearchResultProps<SearchEntity.SEARCH_ENTITY_TRANSACTION>
    >();

    const keyActionsMap = new Map<
      string,
      SearchResultProps<SearchEntity.SEARCH_ENTITY_KEY_ACTION>
    >();

    keyActionsSuggestions.forEach((keyAction) => {
      const mapKey = getKeyActionsSearchEntryMapKey({
        title: keyAction.title as string,
      });

      keyActionsMap.set(mapKey, {
        title: keyAction.title as string,
        description: keyAction.description as string,
        key: `${SearchEntity.SEARCH_ENTITY_KEY_ACTION}-${mapKey}`,
        mediaProps: undefined,
        navigationPath: keyAction.navigationPath,
        iconName: keyAction.iconName,
        onPress: keyAction.onPress,
        accessory: keyAction.accessory,
        searchEntity: SearchEntity.SEARCH_ENTITY_KEY_ACTION,
      });
    });

    /**
     * @TODO add search results for collections
     * @see https://jira.coinbase-corp.com/browse/WALL-27459
     */
    const collectionsMap = new Map<
      string,
      SearchResultProps<SearchEntity.SEARCH_ENTITY_NFT_COLLECTIONS>
    >();

    const nftsMap = new Map<
      string,
      SearchResultProps<SearchEntity.SEARCH_ENTITY_NFT_COLLECTIONS>
    >();

    const dappsMap = new Map<string, SearchResultProps<SearchEntity.SEARCH_ENTITY_DAPP>>();

    const settingsMap = new Map<string, SearchResultProps<SearchEntity.SEARCH_ENTITY_SETTING>>();

    settingSuggestions.forEach((setting) => {
      const mapKey = getSettingsSearchEntryMapKey({
        title: setting.title as string,
      });

      settingsMap.set(mapKey, {
        title: setting.title as string,
        description: setting.description as string,
        key: `${SearchEntity.SEARCH_ENTITY_SETTING}-${mapKey}`,
        mediaProps: undefined,
        navigationPath: setting.navigationPath,
        iconName: setting.iconName,
        onPress: setting.onPress,
        accessory: setting.accessory,
        searchEntity: SearchEntity.SEARCH_ENTITY_SETTING,
      });
    });

    entries.forEach(function extractWSSResult(entry) {
      // convert fields to key-value pairs
      const entryField = entry.fields?.reduce(
        function reduceFields(returningField: Record<string, string>, field) {
          returningField[field.key] = field.value;
          return returningField;
        },
        { name: entry.name },
      );

      switch (entry.type) {
        case SearchEntity.SEARCH_ENTITY_ASSET: {
          const mapKey = getAssetsSearchEntryMapKey(entryField);
          const assetBlockchain = new Blockchain(entryField.blockchain ?? '');
          const assetNetwork = Network.fromChainId({
            chainId: BigInt(entryField?.chainId ?? 0),
          });
          const assetCurrencyCode = new CurrencyCode(entryField.code);
          const assetName = entryField.name;
          const assetContractAddress = entryField.contractAddress;
          const assetUUID = entryField.uuid;

          const result = {
            title: entry.name,
            detail: entryField.percentChange24h ?? '',
            description: entryField.code,
            mediaProps: {
              assetCurrencyCode: entryField.code,
              networkBadgeSrc: Network.fromChainId({
                chainId: BigInt(entryField?.chainId ?? 0),
              }).asChain()?.chainImageUrl,
              assetSrc: entryField.imageUrl,
            },
            key: `${SearchEntity.SEARCH_ENTITY_ASSET}-${mapKey}`,
            navigationPath: getDynamicPagePath(RoutesEnum.ASSETS, {
              assetBlockchain,
              assetNetwork,
              assetCurrencyCode,
              assetName,
              assetContractAddress,
              assetUUID,
            }),
            isUnownedToken: true,
            searchEntity: SearchEntity.SEARCH_ENTITY_ASSET,
          };

          tokensMap.set(mapKey, result);
          wacSearchResultsMap.set(mapKey, result);
          break;
        }
        case SearchEntity.SEARCH_ENTITY_ENS: {
          const mapKey = getENSSearchEntryMapKey(entryField);
          const result = {
            title: entry.name,
            mediaProps: {
              address: entryField.Address,
              avatar: entryField.imageUrl,
              alt: entry.name,
            },
            key: `${SearchEntity.SEARCH_ENTITY_ENS}-${mapKey}`,
            searchEntity: SearchEntity.SEARCH_ENTITY_ENS,
          };
          ensMap.set(mapKey, result);
          wacSearchResultsMap.set(mapKey, result);
          break;
        }
        case SearchEntity.SEARCH_ENTITY_DAPP: {
          const mapKey = getDAppSearchEntryMapKey(entryField);
          const result = {
            id: entry.id,
            title: entry.name,
            description: entryField?.tagline,
            mediaProps: {
              src: entryField?.imageUrl,
              alt: entry?.name,
            },
            key: `${SearchEntity.SEARCH_ENTITY_DAPP}-${mapKey}`,
            navigationPath: entry.url,
            searchEntity: SearchEntity.SEARCH_ENTITY_DAPP,
          };
          dappsMap.set(mapKey, result);
          wacSearchResultsMap.set(mapKey, result);
          break;
        }
        case SearchEntity.SEARCH_ENTITY_NFT_COLLECTIONS: {
          const mapKey = getNFTSSearchEntryMapKey(entryField);

          const navigationPath = getDynamicPagePath(RoutesEnum.ASSETS_NFT_COLLECTION, {
            contractAddress: entry.id?.split('/')?.[1] ?? '',
            query: {
              chainId: String(
                getNetworkForWacId(`networks/${entryField?.chainId ?? 0}`)?.asChain()?.chainId || 1,
              ),
            },
          });
          const result = {
            title: entry.name,
            description: entryField?.tokenCount,
            detail: entryField?.floorPrice,
            mediaProps: {
              src: entryField?.imageUrl,
              alt: entry.name,
            },
            key: `${SearchEntity.SEARCH_ENTITY_NFT_COLLECTIONS}-${mapKey}`,
            navigationPath,
            searchEntity: SearchEntity.SEARCH_ENTITY_NFT_COLLECTIONS,
          };
          nftsMap.set(mapKey, result);
          wacSearchResultsMap.set(mapKey, result);
          break;
        }
        default:
          break;
      }
    });

    const settingsResults =
      searchFilter === SearchEntity.SEARCH_ENTITY_UNSPECIFIED
        ? Array.from(settingsMap.values()).slice(0, 3)
        : Array.from(settingsMap.values());

    if (isGlobalSearchUsingWacEnabled) {
      return [
        {
          title: formatMessage(messages.tokens),
          data: [...wacSearchResultsMap.values(), ...settingsResults],
          searchEntity: SearchEntity.SEARCH_ENTITY_UNSPECIFIED,
        },
      ];
    }

    return [
      {
        title: formatMessage(messages.tokens),
        data:
          searchFilter === SearchEntity.SEARCH_ENTITY_UNSPECIFIED
            ? Array.from(tokensMap.values()).slice(0, 3)
            : Array.from(tokensMap.values()).slice(0, 10),
        searchEntity: SearchEntity.SEARCH_ENTITY_ASSET,
      },
      {
        title: formatMessage(messages.nftCollections),
        data:
          searchFilter === SearchEntity.SEARCH_ENTITY_UNSPECIFIED
            ? Array.from(nftsMap.values()).slice(0, 3)
            : Array.from(nftsMap.values()).slice(),
        searchEntity: SearchEntity.SEARCH_ENTITY_NFT_COLLECTIONS,
      },
      {
        title: formatMessage(messages.apps),
        data:
          searchFilter === SearchEntity.SEARCH_ENTITY_UNSPECIFIED
            ? Array.from(dappsMap.values()).slice(0, 3)
            : Array.from(dappsMap.values()).slice(),
        searchEntity: SearchEntity.SEARCH_ENTITY_DAPP,
      },
      {
        title: formatMessage(messages.transactions),
        data: Array.from(transactionsMap.values()),
        searchEntity: SearchEntity.SEARCH_ENTITY_TRANSACTION,
      },
      {
        title: formatMessage(messages.collections),
        data: Array.from(collectionsMap.values()),
        searchEntity: SearchEntity.SEARCH_ENTITY_NFT_COLLECTIONS,
      },
      {
        title: formatMessage(messages.settings),
        data: hasUserConnectedWallets ? settingsResults : [],
        searchEntity: SearchEntity.SEARCH_ENTITY_SETTING,
      },
      {
        title: formatMessage(messages.keyActions),
        data: Array.from(keyActionsMap.values()),
        searchEntity: SearchEntity.SEARCH_ENTITY_KEY_ACTION,
      },
    ];
  }, [
    entries,
    formatMessage,
    hasUserConnectedWallets,
    isGlobalSearchUsingWacEnabled,
    keyActionsSuggestions,
    searchFilter,
    settingSuggestions,
  ]);

  return { sections, isLoading };
}

function getAssetsSearchEntryMapKey(field: Record<string, string>) {
  return `${field.name}-${field.code}-${field.contractAddress}-${field.blockchain}-${field.chainId}`;
}

function getENSSearchEntryMapKey(field: Record<string, string>) {
  return field.name;
}

function getNFTSSearchEntryMapKey(field: Record<string, string>) {
  return field?.name;
}

function getDAppSearchEntryMapKey(field: Record<string, string>) {
  return field?.name;
}

function getSettingsSearchEntryMapKey(field: Record<string, string>) {
  return field.title;
}

function getKeyActionsSearchEntryMapKey(field: Record<string, string>) {
  return field.title;
}

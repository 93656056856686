import { ForwardedRef, forwardRef, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Image from 'next/image';
import { Button } from 'wallet-cds-web/components/Button';
import { IconName } from '@cbhq/cds-common';
import { IconButton } from '@cbhq/cds-web/buttons';
import { ContentCard, ContentCardBody, ContentCardFooter, ContentCardProps } from '@cbhq/cds-web/cards/ContentCard';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { ThemeProvider } from '@cbhq/cds-web/system';
import { TextBody, TextCaption, TextHeadline, TextTitle3 } from '@cbhq/cds-web/typography';
import { useGradientFromImage } from ':dapp/hooks/useGradientFromImage';
import { CardBadge, CardBadgeProps } from './CardBadge';
const messages = defineMessages({
  challengeCompleted: {
    defaultMessage: 'Completed',
    description: 'indicator that the user has already completed this challenge'
  }
});
export const testID = 'ocs-card-with-image';
export const imageClass = "i1fj3dm3";
export const imageRoundClass = "i1wd52nu";
export const compactImageRoundClass = "ci8simh";
export type CardWithImageProps = {
  title?: string;
  label?: string;
  text?: string;
  isLoading?: boolean;
  hasCompleted?: boolean;
  image: {
    src: string;
    width?: number;
    height?: number;
  };
  noBackground?: boolean;
  badge?: CardBadgeProps;
  isGradient?: boolean;
  compact?: boolean;
  flushCompactText?: boolean;
  originBadge?: React.ReactNode;
  customSubtitle?: React.ReactNode;
  action?: {
    text: string;
    icon?: IconName;
    url: string;
  };
  inlinedAction?: boolean;
  priceTag?: string | undefined;
  titleSize?: 'large' | 'xLarge';
  containerProps?: ContentCardProps;
  onClick?: () => void;
  shareClick?: (event: React.MouseEvent) => void;
  useImgTag?: boolean;
};
export const COMPACT_DUMMY_DATA: CardWithImageProps = {
  title: 'Friends with Benefits',
  text: 'In partnership with Cozomo de’ Medici, DeeKay Motion releases his first ever open edition works as the inaugural art mint on BASE.',
  isGradient: false,
  compact: true,
  badge: {
    numCompletions: 0
  },
  image: {
    src: 'https://go.wallet.coinbase.com/static/blue-moon.png'
  },
  action: {
    url: '/',
    text: 'Get notified',
    icon: 'bell'
  }
};
export const CardWithImage = forwardRef(function CardWithImage(props: CardWithImageProps, ref: ForwardedRef<HTMLDivElement>) {
  const {
    label,
    title,
    text,
    image,
    badge,
    isGradient = false,
    action,
    hasCompleted = false,
    compact = false,
    containerProps = {},
    onClick,
    shareClick,
    isLoading = false,
    customSubtitle,
    originBadge,
    noBackground = false,
    flushCompactText = false,
    inlinedAction = false,
    titleSize = 'xxLarge',
    priceTag,
    useImgTag = false
  } = props;
  const {
    formatMessage
  } = useIntl();

  // Set default width/height ratio
  const {
    width = 375,
    height = 325
  } = image;
  const gradientBg = useGradientFromImage(image.src);
  const cardStyle = useMemo(() => {
    if (isGradient) {
      return gradientBg?.data ?? undefined;
    }
    return undefined;
  }, [isGradient, gradientBg.data]);
  const containerClass = useMemo(() => {
    if (compact && !noBackground) {
      return "crx7eg4";
    }
    if (compact && noBackground) {
      return "cuuat2v";
    }
    return "c4zdt2z";
  }, [compact, noBackground]);
  if (isLoading && !title) {
    if (compact) {
      return <CardWithImageCompactLoading />;
    }
    return <CardWithImageLoading />;
  }
  return <ContentCard testID={testID} background={noBackground ? 'background' : 'backgroundAlternate'} borderRadius="roundedXLarge" spacingHorizontal={compact ? 0 : 2} spacingTop={compact ? 0 : 2} spacingBottom={compact ? 0.5 : 0} style={cardStyle} className={containerClass} gap={2} ref={ref} onClick={onClick} {...containerProps}>
      <ContentCardBody body={<VStack spacingHorizontal={compact && !flushCompactText ? 2 : 0}>
            {label ? <ThemeProvider spectrum="dark">
                <TextCaption color="foreground" as="p" spacingBottom={0.5} transform="uppercase">
                  {label}
                </TextCaption>
              </ThemeProvider> : null}
            <HStack width="100%" justifyContent="space-between" alignItems="flex-start">
              <ThemeProvider spectrum="dark">
                {titleSize === 'xLarge' ? <TextTitle3 as="p">{title}</TextTitle3> : <TextHeadline as="span">{title}</TextHeadline>}
              </ThemeProvider>
              {priceTag && <ThemeProvider>
                  <TextBody as="p" color="foregroundMuted" spacingHorizontal={0} noWrap>
                    {priceTag}
                  </TextBody>
                </ThemeProvider>}
            </HStack>

            {text ? <TextBody as="p" spacingTop={0.5} color="foregroundMuted">
                {text}
              </TextBody> : null}
          </VStack>} media={<Box position="relative" spacingBottom={1}>
            {useImgTag ? <img alt={title} src={image.src} className={`${imageClass} ${compact ? compactImageRoundClass : imageRoundClass}`} width={width} height={height} /> : <Image alt={title || ''} src={image.src} className={`${imageClass} ${compact ? compactImageRoundClass : imageRoundClass}`} width={width} height={height} />}
            {/* <img
              alt={title}
              src={image.src}
              className={`${imageClass} ${compact ? compactImageRoundClass : imageRoundClass}`}
              width={width}
              height={height}
             /> */}

            {originBadge ? <div className={"dac8pk1"}>
                {originBadge}
              </div> : null}
            {badge ? <CardBadge {...badge} /> : null}

            {compact || !shareClick ? null : <Box position="absolute" top={12} right={16} zIndex={1}>
                <ThemeProvider scale="small">
                  <IconButton compact name="share" testID="ocs-card-share" onClick={shareClick} />
                </ThemeProvider>
              </Box>}
          </Box>} />
      <div>
        {!inlinedAction && action ? <ContentCardFooter spacingHorizontal={compact ? 2 : 0} display="block">
            <ThemeProvider spectrum="light">
              <Button testID="ocs-card-cta" variant="secondary" block compact endIcon={action.icon ?? undefined} numberOfLines={1}>
                {hasCompleted ? formatMessage(messages.challengeCompleted) : action.text}
              </Button>
            </ThemeProvider>
          </ContentCardFooter> : null}
        {customSubtitle}
      </div>
    </ContentCard>;
});
export function CardWithImageLoading() {
  return <VStack gap={2} background="backgroundAlternate" borderRadius="roundedXLarge" spacing={2} testID="card-with-image-loading">
      <Box width="100%" height={333} background="secondary" borderRadius="roundedXLarge" />
      <VStack gap={0.5}>
        <Box width={72} height={16} background="secondary" />
        <Box width="100%" height={28} background="secondary" />
      </VStack>
      <Box width="100%" height={28} background="secondary" borderRadius="roundedXLarge" />
    </VStack>;
}
export function CardWithImageCompactLoading() {
  const topBorder = "t5bfkid";
  return <VStack width="100%" height={437} background="backgroundAlternate" borderRadius="roundedXLarge" testID="compact-card-with-image-loading">
      <Box width="100%" height={229} background="secondary" overflow="hidden" className={topBorder} />
      <VStack width="100%" justifyContent="space-between" spacing={2} height={208}>
        <VStack gap={0.5}>
          <Box width={200} height={28} background="secondary" />
          <Box width="100%" height={72} background="secondary" />
        </VStack>
        <Box width="100%" height={28} background="secondary" borderRadius="roundedXLarge" />
      </VStack>
    </VStack>;
}

require("./CardWithImage.linaria.module.css!=!../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./CardWithImage.tsx");
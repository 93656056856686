import { useCallback } from 'react';
import {
  triggerCreateWalletGroupsFailed,
  triggerCreateWalletGroupsSuccess,
} from 'cb-wallet-analytics/accounts';
import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { getWalletGroups, saveWalletGroups } from 'cb-wallet-data/stores/WalletGroups/database';
import {
  WalletGroup,
  WalletGroupConstructorOptions,
} from 'cb-wallet-data/stores/WalletGroups/models/WalletGroup';
import { walletGroupsAtom } from 'cb-wallet-data/stores/WalletGroups/state';
import { useSetRecoilState } from 'recoil';

export function useCreateWalletGroups() {
  const setWalletGroups = useSetRecoilState(walletGroupsAtom);

  return useCallback(
    async function createWalletGroups(walletGroups: WalletGroupConstructorOptions[]) {
      try {
        const walletGroupsToCreate: WalletGroup[] = [];
        const newWalletGroupsIndexes: string[] = [];

        walletGroups.forEach(
          // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
          // eslint-disable-next-line wallet/no-anonymous-params
          (group) => {
            walletGroupsToCreate.push(new WalletGroup(group));
            newWalletGroupsIndexes.push(group.walletIndex.toString());
          },
        );

        await saveWalletGroups(walletGroupsToCreate);
        const updatedWalletGroups = await getWalletGroups();

        setWalletGroups(updatedWalletGroups);
        triggerCreateWalletGroupsSuccess({
          count: walletGroupsToCreate.length,
          walletIndexes: newWalletGroupsIndexes,
        });

        return walletGroupsToCreate;
      } catch (e) {
        triggerCreateWalletGroupsFailed();
        const err = coerceError(e, 'useCreateWalletGroups');
        cbReportError({
          error: err,
          context: 'multi-account',
          severity: 'error',
          isHandled: false,
        });

        throw e;
      }
    },
    [setWalletGroups],
  );
}

import { useMemo } from 'react';
import { AssetInfo } from 'cb-wallet-data/stores/DefiPortfolio/models/AssetInfo';
import {
  EarnClaimAsset,
  EarnPortfolioAsset,
} from 'cb-wallet-data/stores/DefiPortfolio/models/EarnPortfolioResponse';
import { memoizeDerivedValue } from 'cb-wallet-data/utils/memoizeDerivedValue';

export type UseFilterEarnPortfolioAssetsProps = {
  portfolioAssets: (EarnPortfolioAsset | EarnClaimAsset)[];
  selectedNetworkFilters?: string[];
  selectedWalletFilters?: string[];
};

export function useFilterEarnPortfolioAssets({
  portfolioAssets,
  selectedNetworkFilters,
  selectedWalletFilters,
}: UseFilterEarnPortfolioAssetsProps) {
  // Filter by network
  const earnPortfolioAssetsFilteredByNetwork = useMemo(() => {
    if (!selectedNetworkFilters || selectedNetworkFilters?.length === 0) {
      return portfolioAssets;
    }

    return portfolioAssets.filter(function filterEarnPortfolioAssets({
      assetInfo,
    }: {
      assetInfo: AssetInfo;
    }) {
      const isOnFilteredNetwork =
        selectedNetworkFilters.includes(assetInfo.asset.network.rawValue) ||
        selectedNetworkFilters.includes(assetInfo.asset.blockchain.rawValue);

      return isOnFilteredNetwork;
    });
  }, [portfolioAssets, selectedNetworkFilters]);

  // If we ever support solana positions we may need to revisit this, not all addressed are case insensitive
  const normalizedWalletFilers = memoizeDerivedValue(selectedWalletFilters ?? [], () =>
    selectedWalletFilters?.map((address) => address.toLowerCase()),
  );

  // Filter by wallet address
  return useMemo(() => {
    if (!selectedWalletFilters || selectedWalletFilters.length === 0) {
      return earnPortfolioAssetsFilteredByNetwork;
    }

    return earnPortfolioAssetsFilteredByNetwork.filter(
      (earnAsset: EarnPortfolioAsset | EarnClaimAsset) => {
        return normalizedWalletFilers?.includes(earnAsset.walletAddress.toLowerCase());
      },
      [selectedNetworkFilters],
    );
  }, [
    earnPortfolioAssetsFilteredByNetwork,
    normalizedWalletFilers,
    selectedNetworkFilters,
    selectedWalletFilters,
  ]);
}

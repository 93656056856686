import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';

import { generateUserWalletSettingId } from '../hooks/useGenerateUserWalletSettingId';
import { CuratedAssetSetting } from '../models/CuratedAssetSetting';
import { UserWalletSetting } from '../models/UserWalletSetting';

import { isAssetStatusChangeRequired } from './isAssetStatusChangeRequired';

export function getWalletsToBeMarkedSpamOrWhitelist(
  wallets: Wallet[],
  curatedAssetsSettingsMap: Record<string, CuratedAssetSetting>,
  userWalletSettingsMap: Record<string, UserWalletSetting>,
): UserWalletSetting[] {
  return wallets.reduce(
    // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
    // eslint-disable-next-line wallet/no-anonymous-params
    (userWalletSettings, wallet) => {
      const curatedAssetSetting =
        curatedAssetsSettingsMap[
          CuratedAssetSetting.generateID(
            wallet.currencyCode,
            wallet.contractAddress,
            wallet.blockchain,
          )
        ];

      if (curatedAssetSetting) {
        const userWalletSetting = userWalletSettingsMap[generateUserWalletSettingId(wallet)];
        if (isAssetStatusChangeRequired(userWalletSetting, curatedAssetSetting)) {
          userWalletSettings.push(
            new UserWalletSetting(
              wallet.blockchain,
              wallet.currencyCode,
              wallet.network,
              curatedAssetSetting.status,
              wallet.contractAddress,
              wallet.primaryAddress,
            ),
          );
        }
      }

      return userWalletSettings;
    },
    [] as UserWalletSetting[],
  );
}

import { useEffect, useState } from 'react';

import { WalletProviderNetwork } from ':dapp/connection/types';
import { COINBASE_SMART_WALLET } from ':dapp/connection/wallet-providers/coinbase-wallet';
import { useScwAccounts } from ':dapp/hooks/Scw/useScwAccounts';

export function useIsSmartWalletConnected() {
  const scwAccounts = useScwAccounts();
  const [scwConnected, setScwConnected] = useState(false);

  useEffect(() => {
    (async () => {
      /**
       * checks if wagmi has a smart wallet connection
       */
      const connected = await COINBASE_SMART_WALLET.connectorManager.isConnected(
        WalletProviderNetwork.Ethereum,
      );
      if (scwAccounts.length > 0 && connected) {
        setScwConnected(true);
      }
    })();
  }, [scwAccounts.length]);

  return scwConnected;
}

import { useCallback, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { useAuthedGet } from 'cb-wallet-data/HTTP/hooks/useAuthedGet';
import { useCanAuthenticateWithAPI } from 'cb-wallet-data/stores/Authentication/tokens/useCanAuthenticateWithAPI';

import type { Profile } from '../types';

export const ENDPOINT = 'getProfileByAddress';

export function useGetProfileByAddress(): (
  address: string | undefined,
) => Promise<Profile | undefined> {
  const getProfileByAddress = useAuthedGet<Profile>(ENDPOINT);
  const canAuthenticateWithAPI = useCanAuthenticateWithAPI();

  return useCallback(
    async function profileByAddress(address: string | undefined) {
      if (!address || !canAuthenticateWithAPI) return undefined;

      try {
        return await getProfileByAddress({ userAddress: address });
      } catch (error) {
        const err = coerceError(error, 'useProfileByAddress');
        const metadata = {
          method: 'useGetProfileByAddress',
          address,
        };
        cbReportError({
          error: err,
          context: 'did_error',
          metadata,
          isHandled: false,
          severity: 'error',
        });
        // eat error and return undefined - we dont want to error if we cant get the profile
        return undefined;
      }
    },
    [canAuthenticateWithAPI, getProfileByAddress],
  );
}

export function useProfileByAddress(address?: string): Profile | undefined {
  const getProfileByAddress = useAuthedGet<Profile>(ENDPOINT);
  const canAuthenticateWithAPI = useCanAuthenticateWithAPI();

  const { data: profile, isInitialLoading } = useQuery(
    [ENDPOINT, address],
    async () => getProfileByAddress({ userAddress: address }),
    {
      enabled: canAuthenticateWithAPI && !!address,
      suspense: false,
      staleTime: 1000 * 30,
      notifyOnChangeProps: ['data'],
    },
  );

  useEffect(
    function reportProfileLoadingError() {
      if (canAuthenticateWithAPI && !isInitialLoading && address && !profile) {
        cbReportError({
          error: new Error(`Unable to find profile for address: ${address}`),
          context: 'user_profiles',
          isHandled: false,
          severity: 'error',
        });
      }
    },
    [address, canAuthenticateWithAPI, isInitialLoading, profile],
  );

  return profile;
}

export function useInvalidateProfileByAddressQuery() {
  const queryClient = useQueryClient();
  return useCallback(
    function invalidateProfileByAddressQuery(address: string) {
      queryClient.invalidateQueries([ENDPOINT, address]);
    },
    [queryClient],
  );
}

import { useMemo } from 'react';

export function getIsCoinbaseDappBrowser() {
  return (
    // eslint was disabled for this line because it preferred optional chaining but
    // these checks are erroring on the server side without checking if window is defined
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    typeof window !== 'undefined' &&
    window?.ethereum?.isCoinbaseWallet &&
    window.ethereum.isCoinbaseBrowser
  );
}

export function useIsCoinbaseDappBrowser() {
  return useMemo(() => getIsCoinbaseDappBrowser(), []);
}

class Debouncer {
  private _id: NodeJS.Timeout | null = null;

  clear() {
    if (this._id) {
      clearTimeout(this._id);
    }
  }

  call(cb: () => void, wait: number) {
    this.clear();
    this._id = global.setTimeout(cb, wait);
  }
}

export function getDebouncerInstance() {
  return new Debouncer();
}

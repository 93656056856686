import { ActionType, ComponentType, logMetric, MetricType } from '@cbhq/client-analytics';

import { logEvent } from '../utils/log';

type BalanceFetchProps = {
  startTime: number;
};

let hasLoggedTotalBalanceDuration = false;

export function logTotalBalanceFetchDuration({ startTime }: BalanceFetchProps) {
  if (!hasLoggedTotalBalanceDuration) {
    logMetric({
      metricName: 'balance.fetch_duration_v3',
      metricType: MetricType.distribution,
      value: performance.now() - startTime,
    });
    hasLoggedTotalBalanceDuration = true;
  }
}

// Duration for the pending total fiat balance animation which includes balance and
// exchange rate updates
export function logTotalBalancePendingDuration({ startTime }: BalanceFetchProps) {
  logMetric({
    metricName: 'balance.pending_duration_v3',
    metricType: MetricType.distribution,
    value: performance.now() - startTime,
  });
}

// Duration for the exchange rate update
export function logExchangeRateFetchDuration({ startTime }: BalanceFetchProps) {
  logMetric({
    metricName: 'exchange_rate.fetch_duration_v3',
    metricType: MetricType.distribution,
    value: performance.now() - startTime,
  });
}

type BalanceFetchByBlockchainProps = {
  startTime: number;
  blockchain: string;
  unlockedAt?: number;
  getAppBackgroundStart: () => number | undefined;
  getAppBackgroundEnd: () => number | undefined;
};

const hasLoggedBalanceFetchDurationByBlockchain: Record<string, boolean> = {
  BTC: false,
  ETH: false,
  SOL: false,
  LTC: false,
  DOGE: false,
};

export function logBalanceFetchDurationByBlockchain({
  startTime,
  blockchain,
  getAppBackgroundStart,
  getAppBackgroundEnd,
}: BalanceFetchByBlockchainProps) {
  // If the app was ever minimized, don't log the balance fetch duration since this can lead to
  // inaccurate metrics
  if (getAppBackgroundStart() !== undefined || getAppBackgroundEnd() !== undefined) {
    return;
  }

  if (!hasLoggedBalanceFetchDurationByBlockchain[blockchain]) {
    logMetric({
      metricName: 'balance.fetch_duration_by_blockchain_v3',
      metricType: MetricType.distribution,
      value: performance.now() - startTime,
      tags: {
        blockchain,
      },
    });
    hasLoggedBalanceFetchDurationByBlockchain[blockchain] = true;
  }
}

type BalanceFetchErrorProps = {
  blockchain: string;
  walletIndex: string;
  error: any;
};

export function logBalanceFetchError({ blockchain, error }: BalanceFetchErrorProps) {
  logMetric({
    metricName: 'balance.errors_by_blockchain',
    metricType: MetricType.count,
    value: 1,
    tags: {
      blockchain,
      error,
    },
  });
}

type NudgeBalanceDurationProps = {
  nudgedAt: number;
  absoluteNudgedAt: number;
  submittedAt?: number;
  startRefreshAt: number;
  nudgeSource: any;
  txSource?: string;
  isDelayedRefresh: boolean;
  unlockedAt?: number;
  network: string;
  getAppBackgroundStart: () => number | undefined;
  getAppBackgroundEnd: () => number | undefined;
};

export function logNudgeBalanceFetchDuration({
  nudgedAt,
  absoluteNudgedAt,
  submittedAt,
  nudgeSource,
  txSource,
  isDelayedRefresh,
  network,
  getAppBackgroundStart,
  getAppBackgroundEnd,
}: NudgeBalanceDurationProps) {
  // If the app was ever minimized, don't log the balance fetch duration since this can lead to
  // inaccurate metrics
  if (getAppBackgroundStart() !== undefined || getAppBackgroundEnd() !== undefined) {
    return;
  }

  const elapsedTime = performance.now() - nudgedAt;

  logMetric({
    metricName: 'nudge.balance_fetch_duration_v3',
    metricType: MetricType.distribution,
    value: elapsedTime,
    tags: {
      networkName: network,
      nudgeSource,
      isDelayedRefresh: String(isDelayedRefresh),
      txSource: txSource ?? 'unknown',
    },
  });

  // Do not log the duration in mempool if the refresh source is a delayed refresh since this will
  // artifically inflate the duration
  if (!submittedAt || isDelayedRefresh || performance.now() - submittedAt < elapsedTime) {
    return;
  }

  // elapsedTimeInMempool = current time - txn submitted time - time balance update duration with debouce - time spent on success screen
  const elapsedTimeInMempool =
    performance.now() - submittedAt - elapsedTime - (nudgedAt - absoluteNudgedAt);

  logMetric({
    metricName: 'transaction.duration_in_mempool_v3',
    metricType: MetricType.distribution,
    value: elapsedTimeInMempool,
    tags: {
      networkName: network,
      txSource: txSource ?? 'unknown',
    },
  });
}

export function logUTXOAdvancedBalanceScanningEnabled() {
  logEvent('balance.utxo_advanced_balance_scanning_enabled.clicked', {
    loggingId: '35b907ed-6cea-49dd-b89f-39b3ce610805',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function logUTXOAddressCreationSuccess(chainName: string) {
  logEvent('assets.utxo_address_creation.success', {
    action: ActionType.process,
    componentType: ComponentType.unknown,
    chainName,
  });
}

export function logUTXOAddressCreationFailure({
  chainName,
  numDerivedAddresses,
  addressTypesWithMissingXpubKeys,
  missingAddressTypes,
}: {
  chainName: string;
  numDerivedAddresses: number;
  addressTypesWithMissingXpubKeys: string[];
  missingAddressTypes: string[];
}) {
  logEvent('assets.utxo_address_creation.failure', {
    action: ActionType.process,
    componentType: ComponentType.unknown,
    chainName,
    numDerivedAddresses,
    addressTypesWithMissingXpubKeys,
    missingAddressTypes,
  });
}

export function logUTXOAddressScanningSuccess(chainName: string) {
  logEvent('assets.utxo_address_scan.success', {
    action: ActionType.process,
    componentType: ComponentType.unknown,
    chainName,
  });
}

export function logUTXOAddressScanningFailure({
  chainName,
  numDerivedAddresses,
  addressTypesWithMissingXpubKeys,
  missingAddressTypes,
}: {
  chainName: string;
  numDerivedAddresses: number;
  addressTypesWithMissingXpubKeys: string[];
  missingAddressTypes: string[];
}) {
  logEvent('assets.utxo_address_scan.failure', {
    action: ActionType.process,
    componentType: ComponentType.unknown,
    chainName,
    numDerivedAddresses,
    addressTypesWithMissingXpubKeys,
    missingAddressTypes,
  });
}

type UTXODroppedAddressesParams = {
  chainName: string;
  failureRate: number;
};

export function logUTXODroppedAddresses({ chainName, failureRate }: UTXODroppedAddressesParams) {
  logEvent('assets.utxo_dropped_addresses', {
    action: ActionType.process,
    componentType: ComponentType.unknown,
    chainName,
    failureRate,
  });
}

export function logUTXOGenericError(chainName: string) {
  logEvent('assets.utxo_generic_error', {
    action: ActionType.process,
    componentType: ComponentType.unknown,
    chainName,
  });
}

export function logCreateUTXOWalletStart(chainName: string) {
  logEvent('accounts.create_utxo_wallet.start', {
    chainName,
    action: ActionType.process,
    componentType: ComponentType.unknown,
  });
}

export function logCreateUTXOWalletComplete({
  chainName,
  results,
  missingAddressTypes,
}: {
  chainName: string;
  results: 'success' | 'failure';
  missingAddressTypes: string[];
}) {
  logEvent('accounts.create_utxo_wallet.result', {
    action: ActionType.process,
    componentType: ComponentType.unknown,
    chainName,
    results,
    missingAddressTypes,
  });
}

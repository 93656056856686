import bs58 from 'bs58';
import {
  SolanaChain,
  SolanaNetworkMap,
} from 'cb-wallet-data/chains/AccountBased/Solana/models/SolanaChain';
import { WalletConfigurationForSolana } from 'cb-wallet-data/chains/AccountBased/Solana/models/SolanaWalletConfiguration';
import { asNetwork } from 'cb-wallet-data/chains/AccountBased/Solana/utils/chain';
import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { AddressType } from 'cb-wallet-data/stores/Addresses/models/AddressType';
import {
  NetworkSetting,
  NetworkSettingItem,
} from 'cb-wallet-data/stores/Networks/models/NetworkSetting';
import {
  TxOrUserOpMetadataKey,
  TxOrUserOpMetadataKind,
} from 'cb-wallet-data/stores/Transactions/models/TxOrUserOpMetadataKey';
import { WalletConfiguration } from 'cb-wallet-data/stores/Wallets/models/WalletConfiguration';
import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';

import {
  BLOCKCHAIN_SOLANA_MAINNET_IMAGE_URL,
  NAME,
  SOLANA_CURRENCY_DECIMAL,
  SOLANA_SYMBOL,
} from './constants';

export const SolanaAddressType = new AddressType(NAME);

export const SolanaBlockchain = new Blockchain(SOLANA_SYMBOL);

export const SolanaNetworkSetting = makeSOLNetworkSetting();

export const SolanaWalletConfiguration: WalletConfigurationForSolana & WalletConfiguration =
  new WalletConfigurationForSolana({
    blockchain: SolanaBlockchain,
    networkSetting: SolanaNetworkSetting,
    refreshInterval: 30n,
    currencyCode: CurrencyCode.SOL,
    decimals: SOLANA_CURRENCY_DECIMAL,
    imageURL: BLOCKCHAIN_SOLANA_MAINNET_IMAGE_URL,
    defaultReceiveType: SolanaAddressType,
    supportsMultiWallet: true,
    isSyncingRequired: false,
  });

/**
 * Returns the derivation path for the solana address at the given index
 *
 * @param index Index of the wallet
 *
 * @return The derivation path
 */
export function solanaAddressDerivationPath(index: bigint): string {
  return `m/44'/501'/${index}'/0'`;
}

/**
 * Encode solana public key to address format
 * @param publicKey
 * @return a string address
 */
export function solAddressFromPublicKey(publicKey: Buffer): string {
  return bs58.encode(publicKey);
}

/**
 * Current block height for the given chainId
 */
export function StoreKeys_solanaBlockHeight(chainId: bigint): LocalStorageStoreKey<bigint> {
  return new LocalStorageStoreKey('solana_block_height', chainId.toString());
}

export function fromSolanaChain(chain: SolanaChain): NetworkSettingItem {
  return new NetworkSettingItem(chain.displayName, asNetwork(chain));
}

function makeSOLNetworkSetting(): NetworkSetting {
  const defaultMainnet = new NetworkSettingItem(
    'Solana',
    asNetwork(SolanaNetworkMap.whitelisted.SOLANA_MAINNET),
  );

  const allValues: SolanaChain[] = [];
  for (const solChain of Object.values(SolanaNetworkMap.whitelisted)) {
    if (!isNaN(solChain.chainId)) {
      allValues.push(SolanaNetworkMap.fromChainId(BigInt(solChain.chainId))!);
    }
  }

  const mainnets: NetworkSettingItem[] = [defaultMainnet];
  const testnets: NetworkSettingItem[] = [];

  allValues
    .filter((chain) => {
      return chain.chainId !== SolanaNetworkMap.whitelisted.SOLANA_MAINNET.chainId;
    })
    .map((chain) => new NetworkSettingItem(chain.displayName, asNetwork(chain)))
    .sort((chain1, chain2) => chain1.name.localeCompare(chain2.name, 'en-US'))
    .forEach(
      // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
      // eslint-disable-next-line wallet/no-anonymous-params
      (chain) => {
        const item = new NetworkSettingItem(chain.name, chain.network);
        if (chain.network.isTestnet) {
          testnets.push(item);
        } else {
          mainnets.push(item);
        }
      },
    );

  return new NetworkSetting(SolanaBlockchain, defaultMainnet, mainnets, testnets);
}

export const TxOrUserOpMetadataKey_solanaSwapStep = new TxOrUserOpMetadataKey(
  'solanaSwapStep',
  TxOrUserOpMetadataKind.string,
);

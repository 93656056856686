import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { useCountryCode } from 'cb-wallet-data/stores/User/hooks/useCountryCode';
import { useUserId } from 'cb-wallet-data/stores/User/hooks/useUserId';
import { locationDebugOverridesAtom } from 'cb-wallet-data/stores/User/state';
import { getJSON } from 'cb-wallet-http/fetchJSON';
import { useRecoilValue } from 'recoil';

export type Response = {
  bypassed: boolean;
  countryWhitelist: string[];
};

// 4 hours cache to avoid unnecessary screen flickering every time
// we open a screen that has a Tron USDT Receive entry point
// These requests will be triggered once per session
const COUNTRY_CODE_CACHE_TIME = 4 * 60 * 60 * 1000;

const GET_TRON_ADDRESS_PERMITTED_GEO_KEY = 'receive/getTronAddressPermittedGeo';

export function useQueryFetchTronAddressPermittedGeo() {
  const userId = useUserId();
  const queryClient = useQueryClient();
  const isEnabled = useIsFeatureEnabled('tron_usdt_receive');

  const queryOptions = useMemo(
    () => ({
      queryFn: async function fetchPermittedGeo() {
        const response = await getJSON<Response>(GET_TRON_ADDRESS_PERMITTED_GEO_KEY, { userId });

        return response;
      },
      queryKey: [GET_TRON_ADDRESS_PERMITTED_GEO_KEY, userId],
    }),
    [userId],
  );

  // Used to prefetch the tron address permitted geo on app foreground.
  // With that, we can avoid needing to fetch it (and cause UI flickering) every time
  // the user opens a screen that contains an entrypoint for this feature
  const prefetchTronAddressPermittedGeo = useCallback(async () => {
    if (!isEnabled) return;

    return queryClient.prefetchQuery(queryOptions);
  }, [isEnabled, queryClient, queryOptions]);

  // Used to fetch the tron address permitted geo on demand
  const fetchTronAddressPermittedGeo = useCallback(async () => {
    return queryClient.fetchQuery(queryOptions);
  }, [queryClient, queryOptions]);

  return {
    fetchTronAddressPermittedGeo,
    prefetchTronAddressPermittedGeo,
  };
}

export function useIsTronUSDTReceiveEnabled() {
  const userId = useUserId();
  const isEnabled = useIsFeatureEnabled('tron_usdt_receive');
  const overrides = useRecoilValue(locationDebugOverridesAtom);

  const countryCode = useCountryCode({
    cacheTime: COUNTRY_CODE_CACHE_TIME,
  });

  const { fetchTronAddressPermittedGeo } = useQueryFetchTronAddressPermittedGeo();

  const { data } = useQuery(
    [GET_TRON_ADDRESS_PERMITTED_GEO_KEY, userId],
    fetchTronAddressPermittedGeo,
    {
      retry: true,
      enabled: isEnabled,
      cacheTime: Infinity,
      staleTime: Infinity,
      notifyOnChangeProps: ['data'],
    },
  );

  return useMemo(() => {
    if (!isEnabled || !countryCode || !data?.countryWhitelist) return false;

    if (overrides.isLocationOverrideEnabled && overrides.countryCode) {
      return data.countryWhitelist.includes(overrides.countryCode);
    }

    return data.bypassed || data.countryWhitelist.includes(countryCode);
  }, [countryCode, data, isEnabled, overrides]);
}

import { BaseChain } from 'cb-wallet-data/chains/AccountBased/shared/BaseChain';
import { NetworkMap } from 'cb-wallet-data/chains/AccountBased/shared/NetworkMap';
import { BLOCKCHAIN_SOLANA_MAINNET_IMAGE_URL } from 'cb-wallet-data/chains/AccountBased/Solana/constants';

import { solanaUrls } from '../urls';

export type SolanaChain = {
  blockchainSymbol: 'SOL';
  isNonEthChain: true;
  isLayer2: false;
} & BaseChain;

const whitelistedNetworks: Record<string, SolanaChain> = {
  SOLANA_MAINNET: {
    blockchainSymbol: 'SOL',
    chainId: 101,
    baseAssetCurrencyCode: 'SOL',
    baseAssetImageUrl: BLOCKCHAIN_SOLANA_MAINNET_IMAGE_URL,
    baseAssetDisplayName: 'Solana',
    baseAssetDecimal: 9,
    displayName: 'Solana',
    isLayer2: false,
    isTestnet: false,
    chainImageUrl: BLOCKCHAIN_SOLANA_MAINNET_IMAGE_URL,
    isCustomNetwork: false,
    isNonEthChain: true,
    isNudgeSupported: false,
    wacNetworkId: 'networks/solana-mainnet',
    ...solanaUrls.SOLANA_MAINNET,
  },
};

export const SolanaNetworkMap = new NetworkMap<SolanaChain>(whitelistedNetworks);

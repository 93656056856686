import { PossibleUTXOBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { AccountType } from 'cb-wallet-data/stores/Accounts/models/AccountTypes';

import { XPubKeyResponse } from '../models/WalletConnectionResponse';

import { deriveDefaultAddressForXPubKey } from './deriveDefaultAddressForXPubKey';

type DeriveDeterministicAccountIdForXPubKeyParams = {
  xpubKey: XPubKeyResponse;
  chain: PossibleUTXOBlockchainSymbol;
};

export async function deriveDeterministicAccountIdForXPubKey({
  xpubKey,
  chain,
}: DeriveDeterministicAccountIdForXPubKeyParams) {
  const address = await deriveDefaultAddressForXPubKey({ xpubKey, chain });
  return Account.generateID(AccountType.DAPP_PROVIDER, chain, address);
}

import { useCallback } from 'react';
import {
  AuthedFetchedOptions,
  AuthedFetchFn,
  AuthedFetchParams,
} from 'cb-wallet-data/HTTP/hooks/types';
import { useAuthedPost } from 'cb-wallet-data/HTTP/hooks/useAuthedPost';

import { useOnrampRequestConfig } from './useOnrampRequestConfig';

export function useOnrampAuthedPost<T>(endpoint: string): AuthedFetchFn<T> {
  const requestConfig = useOnrampRequestConfig();

  const postCall = useAuthedPost<T>(endpoint, 'JSON', false, {
    rawData: true,
  });

  return useCallback(
    async function onrampAuthedPost(
      params?: AuthedFetchParams,
      options?: AuthedFetchedOptions,
    ): Promise<T> {
      return postCall(params, {
        ...requestConfig,
        ...options,
        additionalHeaders: {
          ...requestConfig.additionalHeaders,
          ...options?.additionalHeaders,
        },
      });
    },
    [postCall, requestConfig],
  );
}

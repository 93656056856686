import { logTxRecieved } from 'cb-wallet-analytics/data/Transactions';
import { logFilteredPoisonousTransaction } from 'cb-wallet-analytics/transactions/Transactions';
import { AllPossibleBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import {
  persistTxOrUserOps,
  retrieveTransactionsMatchingHashes,
} from 'cb-wallet-data/stores/Transactions/database';
import { TxOrUserOp } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import { getTruncatedAddress } from 'cb-wallet-data/utils/getTruncatedAddress';

import { TxOrUserOpMetadataKey_toTokenAmount } from '../models/TxOrUserOpMetadataKey';

/**
 * There is certain metadata about a transaction that we only get from our network providers and does not exist at the
 * time we create a transaction on the client and dispatch it to the blockchain.
 *
 * This function takes in a list of transaction objects returned by our aggregate network provider and associated with
 * the user, the function then sorts through which transactions returned from the network already have a corresponding
 * entry in our transactions table (comparison by txhash). If there is an existing transaction row, we want to update
 * that row with the metadata we uniquely get from the network (eg `confirmedAt`) without losing context that
 * our network providers do not have (`id`, `createdAt`, `metadata`)
 * @param fetchedTransactions transfers/transactions that we have just fetched from the backend
 */

export async function deduplicateAndPersist(
  fetchedTransactions: TxOrUserOp[],
  blockchainSymbol: AllPossibleBlockchainSymbol,
  isPoisonousTxFilterEnabled: boolean,
): Promise<TxOrUserOp[]> {
  const databaseTxOrUserOps = await fetchTransactionsFromDbWithMatchingHashes({
    txs: fetchedTransactions,
    blockchainSymbol,
  });

  // Creates a map of transaction arrays where no currency code exists, keyed by hash.
  // In deduplicateAndPersist, this is used to handle overriding txs in client db
  // that previously didn't have a currency code, but now do.
  const existingTxsWithNoCurrencyCodeByHash = databaseTxOrUserOps.reduce(
    function createMissingCurrencyCodeTxMap(
      accumulator: Record<string, TxOrUserOp[]>,
      transaction: TxOrUserOp,
    ) {
      const hash = transaction.txHash;
      if (!hash || transaction.currencyCode.rawValue) return accumulator;

      if (accumulator[hash]) {
        accumulator[hash].push(transaction);
      } else {
        accumulator[hash] = [transaction];
      }

      return accumulator;
    },
    {} as Record<string, TxOrUserOp[]>,
  );

  // Create a map of transaction arrays, keyed by hash + walletId
  const existingTxsByHashAndWalletId = databaseTxOrUserOps.reduce(function createExistingTxsMap(
    accumulator: Record<string, TxOrUserOp[]>,
    transaction: TxOrUserOp,
  ) {
    const hash = transaction.txHash;
    if (!hash) return accumulator;

    const key = `${hash}_${transaction.walletId}`;

    if (accumulator[key]) {
      accumulator[key].push(transaction);
    } else {
      accumulator[key] = [transaction];
    }

    return accumulator;
  },
  {} as Record<string, TxOrUserOp[]>);

  const filteredNetworkTxOrUserOps = isPoisonousTxFilterEnabled
    ? filterPoisonousTransactions(fetchedTransactions)
    : fetchedTransactions;

  const deduplicatedTxOrUserOps = filteredNetworkTxOrUserOps.map(function deduplicate(tx) {
    const txHash = tx.txHash;
    if (!txHash) return tx;

    const existingTxsOfHashAndWalletId = existingTxsByHashAndWalletId[`${txHash}_${tx.walletId}`];
    const existingTxsWithNoCurrencyCodeOfHash = existingTxsWithNoCurrencyCodeByHash[txHash];
    const hasCurrencyCode = Boolean(tx.currencyCode.rawValue);

    // Attempt to find a tx existing in client db that matches this one.
    let existingTxOrUserOpToOverride;

    // We save multiple transfers that happen within a single Eth tx as individual transactions
    // with the same tx hash. We want to store the multiple transfers that happen within an Eth tx
    // as individual Transaction rows so that we can opt to show the user the meaningful (non-zero)
    // transfer within an Eth tx.
    if (existingTxsOfHashAndWalletId) {
      existingTxOrUserOpToOverride = existingTxsOfHashAndWalletId.find((it) =>
        CurrencyCode.isEqual(it.currencyCode, tx.currencyCode),
      );
    }

    // Handles the case where a tx was originally received and saved with no currency code and
    // defaulted to base chain wallet ID -- If a tx w/matching hash was now received with currency
    // code, it may have a different wallet ID, but we still want to override it.
    if (!existingTxOrUserOpToOverride && hasCurrencyCode && existingTxsWithNoCurrencyCodeOfHash) {
      existingTxOrUserOpToOverride = existingTxsWithNoCurrencyCodeOfHash.shift();
    }

    if (!existingTxOrUserOpToOverride) {
      logTxRecieved({
        ...tx,
        amount: tx.amount.toString(),
        fee: tx.fee.toString(),
        blockchain: tx.blockchain.toString(),
        currencyCode: tx.currencyCode.rawValue,
        feeCurrencyCode: tx.feeCurrencyCode.rawValue,
        feeCurrencyDecimal: tx.feeCurrencyDecimal.toString(),
        network: tx.network.rawValue,
        nonce: tx.nonce?.toString(),
        isSponsoredTx: tx.isSponsored(),
        isGaslessSwap: tx.isGaslessSwap(),
        isNFTTransfer: tx.isNFTTransfer(),
        isNFTTransaction: tx.isNFTTransaction(),
        isCashout: tx.isCashout(),
        isConsumerTransfer: tx.isConsumerTransfer(),
        isRelayed: tx.isRelayed(),
        isSpam: tx.isSpam,
      });

      return tx;
    }

    let txOrUserOpDetails = {
      id: existingTxOrUserOpToOverride.id, // will override existing tx in client db w/new data from server
      createdAt: existingTxOrUserOpToOverride.createdAt,
      confirmedAt: tx.confirmedAt ?? existingTxOrUserOpToOverride.confirmedAt,
      blockchain: tx.blockchain,
      currencyCode: tx.currencyCode,
      feeCurrencyCode: tx.feeCurrencyCode,
      feeCurrencyDecimal: tx.feeCurrencyDecimal,
      toAddress: tx.toAddress,
      toDomain: tx.toDomain,
      fromAddress: tx.fromAddress,
      fromDomain: tx.fromDomain,
      amount: tx.amount,
      fee: tx.fee ?? existingTxOrUserOpToOverride.fee,
      state: tx.state,
      metadata: existingTxOrUserOpToOverride.metadata.mergeMetadata(tx.metadata), // don't override existing metadata
      network: tx.network,
      accountId: tx.accountId,
      walletIndex: tx.walletIndex,
      txOrUserOpHash: tx.txOrUserOpHash,
      userOpHash: tx.userOpHash,
      txHash: tx.txHash,
      isSent: tx.isSent,
      contractAddress: tx.contractAddress,
      tokenName: tx.tokenName,
      tokenDecimal: tx.tokenDecimal,
      walletId: tx.walletId,
      nonce: tx.nonce,
      type: tx.type,
      transfers: tx.transfers,
      isSpam: tx.isSpam,
      pendingEthTxData: tx.pendingEthTxData,
      pendingUTXOTxData: tx.pendingUTXOTxData,
      deleted: tx.deleted,
      primaryAction: tx.primaryAction,
      toAssetHistoricalPrice: tx.toAssetHistoricalPrice,
      fromAssetHistoricalPrice: tx.fromAssetHistoricalPrice,
      nativeAssetHistoricalPrice: tx.nativeAssetHistoricalPrice,
      fromAssetImage: tx.fromAssetImage,
      toAssetImage: tx.toAssetImage,
      fromProfileImage: tx.fromProfileImage,
      toProfileImage: tx.toProfileImage,
      fromAmount: tx.fromAmount,
      toAmount: tx.toAmount,
      isContractExecution: tx.isContractExecution,
      toNetwork: tx.toNetwork,
      toCurrencyCode: tx.toCurrencyCode,
      toContractAddress: tx.toContractAddress,
      toTokenName: tx.toTokenName,
      toTokenDecimal: tx.toTokenDecimal,
      coinbaseFeeAmount: tx.coinbaseFeeAmount,
      coinbaseFeeDecimal: tx.coinbaseFeeDecimal,
      coinbaseFeeAssetAddress: tx.coinbaseFeeAssetAddress,
      coinbaseFeeCurrencyCode: tx.coinbaseFeeCurrencyCode,
      coinbaseFeeName: tx.coinbaseFeeName,
      protocolFeeAmount: tx.protocolFeeAmount,
      protocolFeeDecimal: tx.protocolFeeDecimal,
      protocolFeeCurrencyCode: tx.protocolFeeCurrencyCode,
      protocolFeeName: tx.protocolFeeName,
      toWalletId: tx.toWalletId,
      fromTokenId: tx.fromTokenId,
      toTokenId: tx.toTokenId,
      source: TxOrUserOp.inferTxSource(tx.type, tx.source, tx.state, tx.blockchain, tx.confirmedAt),
    };

    // While Persisting we are checking if its a existing transaction for cash out
    // if yes, we would like to use the existing tx type and primary action
    // because currently we are not updating these values in the backend.
    if (existingTxOrUserOpToOverride.isCashout()) {
      const pendingTxMetadata = existingTxOrUserOpToOverride.getPendingTxMetadata();
      const toAmount = existingTxOrUserOpToOverride.metadata
        .get(TxOrUserOpMetadataKey_toTokenAmount)
        ?.toString();

      txOrUserOpDetails = {
        ...txOrUserOpDetails,
        type: existingTxOrUserOpToOverride.type,
        // hardcoding because this value may not exist in the DB
        primaryAction: 'CASHOUT',
        toAmount,
        toTokenName: pendingTxMetadata.tokenName,
        toCurrencyCode: pendingTxMetadata.tokenCurrencyCode,
        toTokenDecimal: pendingTxMetadata.tokenDecimal,
      };
    }

    const updatedTxOrUserOp = new TxOrUserOp(txOrUserOpDetails);

    if (TxOrUserOp.isEqual(existingTxOrUserOpToOverride, updatedTxOrUserOp)) {
      return undefined;
    }

    return updatedTxOrUserOp;
  });

  const filteredDeduplicatedTxs = deduplicatedTxOrUserOps.filter(
    (tx) => tx !== undefined,
  ) as TxOrUserOp[];
  await persistTxOrUserOps(filteredDeduplicatedTxs);

  return filteredDeduplicatedTxs;
}

/**
 * Given the formatted transactions fetched from server, find all the transactions
 * existing in client db with matching hashes.
 */

type FetchTransactionsFromDbWithMatchingHashesParams = {
  txs: TxOrUserOp[];
  blockchainSymbol: AllPossibleBlockchainSymbol;
};

export async function fetchTransactionsFromDbWithMatchingHashes({
  txs,
  blockchainSymbol,
}: FetchTransactionsFromDbWithMatchingHashesParams): Promise<TxOrUserOp[]> {
  const hashes = txs.reduce(function reduce(acc, tx) {
    if (tx.txHash) {
      acc.add(tx.txHash);
    }
    return acc;
  }, new Set<string>());

  const transactions = await retrieveTransactionsMatchingHashes({
    hashes: Array.from(hashes),
    blockchainSymbol,
  });

  return transactions;
}

/**
 * Filters out harmful transactions from an array of transactions.
 * This is to prevent phishing attacks where an attacker creates a transaction
 * with a recipient or sender address that closely resembles a previous legitimate
 * recipient or sender address.
 *
 * The attacks works as follows:
 *
 * 1. The user sends crypto to / (receives crypto from) a legitimate address, e.g., 0xabc...123.
 * 2. The attacker creates a transaction which emits a log, placing a transaction
 *    in the user's history indicating that the user sent/received some crypto to/from a similar,
 *    but different address, say 0xabc...123', where the middle part is different.
 * 3. The user sees the second transaction in their history, copies the address,
 *    and sends funds there, not realizing that it's not the same as the original
 *    address.
 */
export function filterPoisonousTransactions(transactions: TxOrUserOp[]): TxOrUserOp[] {
  const truncatedAddressToFullAddress = new Map();
  const safeTransactions: TxOrUserOp[] = [];

  // We need the transactions array in reverse (oldest to most recent) to keep the first (original) transaction
  for (let i = transactions.length - 1; i >= 0; i--) {
    const transaction = transactions[i];

    const toAddress = transaction.toAddress?.toLowerCase();
    const fromAddress = transaction.fromAddress?.toLowerCase();

    const targetAddress = transaction.isSent ? toAddress : fromAddress;
    const targetTruncatedAddress = targetAddress && getTruncatedAddress(targetAddress);

    if (!targetAddress) {
      safeTransactions.push(transaction);
      continue;
    }

    if (
      targetTruncatedAddress &&
      isUnseenAddress(targetTruncatedAddress, truncatedAddressToFullAddress)
    ) {
      truncatedAddressToFullAddress.set(targetTruncatedAddress, targetAddress);
      safeTransactions.push(transaction);
      continue;
    }

    if (
      targetTruncatedAddress &&
      !isPoisonousAddress(targetTruncatedAddress, targetAddress, truncatedAddressToFullAddress)
    ) {
      safeTransactions.push(transaction);
      continue;
    }

    logFilteredPoisonousTransaction({
      txHash: transaction.txHash,
      network: transaction.network.rawValue,
    });
  }

  // Reverse the array at the end to maintain the original order
  return safeTransactions.reverse();
}

function isUnseenAddress(
  truncatedAddress: string,
  truncatedAddressToFullAddress: Map<string, string>,
): boolean {
  return !truncatedAddressToFullAddress.has(truncatedAddress);
}

function isPoisonousAddress(
  truncatedAddress: string,
  address: string,
  truncatedAddressToFullAddress: Map<string, string>,
): boolean {
  return truncatedAddressToFullAddress.get(truncatedAddress) !== address;
}

import { useCallback, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { useProcessWalletConnectionResponse } from 'cb-wallet-data/stores/Accounts/hooks/useProcessWalletConnectionResponse';
import {
  ProcessWalletConnectionResponseError,
  ProcessWalletConnectionResponseErrorType,
} from 'cb-wallet-data/stores/Accounts/types';
import { useToast } from '@cbhq/cds-web/overlays/useToast';

import { useConnectWallet } from ':dapp/connection/hooks/useConnectWallet';
import { COINBASE_SMART_WALLET } from ':dapp/connection/wallet-providers/coinbase-wallet';

const messages = defineMessages({
  existingAccountError: {
    defaultMessage: "You've already imported this account with another wallet.",
    description: 'Error message telling the user they already have an account',
  },
});

/**
 * Connect to the Coinbase Smart Wallet only
 */
export function useConnectScwCoinbase() {
  const { formatMessage } = useIntl();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const processWalletConnectionResponse = useProcessWalletConnectionResponse();
  const connectWallet = useConnectWallet();

  const handleConnect = useCallback(async () => {
    setLoading(true);
    const response = await connectWallet({
      walletProvider: COINBASE_SMART_WALLET,
      network: COINBASE_SMART_WALLET.networks[0],
    });

    try {
      if (response?.result) {
        await processWalletConnectionResponse(response?.result);
      }
    } catch (err) {
      if (err instanceof ProcessWalletConnectionResponseError) {
        /* istanbul ignore else */
        if (err.name === ProcessWalletConnectionResponseErrorType.ADDRESS_ALREADY_IMPORTED) {
          setLoading(false);
          toast.show(formatMessage(messages.existingAccountError));
          return;
        }
      }
      cbReportError({
        error: coerceError(err, 'connect smart wallet'),
        context: 'dapp-account-creation',
        severity: 'error',
        isHandled: false,
      });
    } finally {
      setLoading(false);
    }
  }, [connectWallet, formatMessage, processWalletConnectionResponse, toast]);

  return useMemo(
    () => ({
      handleConnect,
      loading,
    }),
    [handleConnect, loading],
  );
}

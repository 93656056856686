import { useCallback, useMemo } from 'react';
import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { isSimulatingEmptyWalletAtom } from 'cb-wallet-data/hooks/DebugMenu/state';
import { StoreKeys_collectionsCountCacheState } from 'cb-wallet-data/hooks/useBalanceUpdateEvents/storeKeys';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { useStableQueries } from 'cb-wallet-data/hooks/useStableQueries';
import { SOLANA_CHAIN_ID } from 'cb-wallet-data/stores/Collection/constants';
import { useIsNFTCollectionSortByChainEnabled } from 'cb-wallet-data/stores/Collection/hooks/useIsNFTCollectionSortByChainEnabled';
// eslint-disable-next-line no-restricted-imports
import { useActiveWalletGroupId } from 'cb-wallet-data/stores/WalletGroups/hooks/useActiveWalletGroupId';
import { usePrimaryReceiveAddresses } from 'cb-wallet-data/stores/Wallets/hooks/usePrimaryReceiveAddresses';
import { Store } from 'cb-wallet-store/Store';
import { useRecoilValue } from 'recoil';

import {
  BASE_COLLECTION_CHAIN_ID,
  COLLECTION_VIEW_CHAIN_IDS,
  ETHEREUM_COLLECTION_CHAIN_ID,
  FETCH_QUERY_KEY,
  fetchCollections,
  fetchProfileTokens,
  GNOSIS_COLLECTION_CHAIN_ID,
  OPTIMISM_COLLECTION_CHAIN_ID,
  POLYGON_COLLECTION_CHAIN_ID,
} from '../api';
import { Collection } from '../types';
import {
  getChainSettingsFromBackendBasedChainConfig,
  getChainSettingsFromKillswitchedChainConfig,
} from '../utils';

import { useIsBackendNFTChainConfigEnabled } from './useIsBackendNFTChainConfigEnabled';
import { useIsCollectibleVisible } from './useIsCollectibleVisible';
import {
  useViewNFTChainEnabledConfig,
  ViewNFTChainEnabledConfig,
} from './useViewNFTChainEnabledConfig';

const START_WITH_SPECIAL_CHARACTER = new RegExp(/^[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/);

function startsWithSpecialCharacter(str = '') {
  return START_WITH_SPECIAL_CHARACTER.test(str);
}

function sortCollections(collections: Collection[][], sortByChain: boolean): Collection[] {
  const sort = (array: Collection[]) =>
    array.sort(
      // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
      // eslint-disable-next-line wallet/no-anonymous-params
      (a, b) => {
        const aCollectionNameLower = a?.collectionInfo?.collectionName?.toLowerCase?.() || '';
        const bCollectionNameLower = b?.collectionInfo?.collectionName?.toLowerCase?.() || '';
        // if both collection names are the same, sort by contract address
        if (aCollectionNameLower === bCollectionNameLower) {
          return a.collectionInfo.contractAddress > b.collectionInfo.contractAddress ? 1 : -1;
        }

        // if it starts with special character put it last
        if (
          !startsWithSpecialCharacter(aCollectionNameLower) &&
          startsWithSpecialCharacter(bCollectionNameLower)
        ) {
          return -1;
        }
        if (
          startsWithSpecialCharacter(aCollectionNameLower) &&
          !startsWithSpecialCharacter(bCollectionNameLower)
        ) {
          return 1;
        }

        // items without a collection name should be second to last
        if (aCollectionNameLower && !bCollectionNameLower) {
          return -1;
        }
        if (!aCollectionNameLower && bCollectionNameLower) {
          return 1;
        }

        return aCollectionNameLower > bCollectionNameLower ? 1 : -1;
      },
    );

  if (sortByChain) {
    const sortedCollections = collections.map((chainCollections) => sort(chainCollections));
    const ethCollections = sortedCollections.find((array) => {
      return (
        array.length > 0 &&
        array[0].collectionInfo.chainId === ETHEREUM_COLLECTION_CHAIN_ID.toString()
      );
    });

    const baseCollections = sortedCollections.find((array) => {
      return (
        array.length > 0 && array[0].collectionInfo.chainId === BASE_COLLECTION_CHAIN_ID.toString()
      );
    });

    const optimismCollections = sortedCollections.find((array) => {
      return (
        array.length > 0 &&
        array[0].collectionInfo.chainId === OPTIMISM_COLLECTION_CHAIN_ID.toString()
      );
    });

    const gnosisCollections = sortedCollections.find((array) => {
      return (
        array.length > 0 &&
        array[0].collectionInfo.chainId === GNOSIS_COLLECTION_CHAIN_ID.toString()
      );
    });

    const solanaCollections = sortedCollections.find((array) => {
      return array.length > 0 && array[0].collectionInfo.chainId === SOLANA_CHAIN_ID;
    });

    const polygonCollections = sortedCollections.find((array) => {
      return (
        array.length > 0 &&
        array[0].collectionInfo.chainId === POLYGON_COLLECTION_CHAIN_ID.toString()
      );
    });

    const otherCollections = sortedCollections.filter((array) => {
      return (
        array.length > 0 && !COLLECTION_VIEW_CHAIN_IDS.includes(array[0].collectionInfo.chainId)
      );
    });

    return (ethCollections || []).concat(
      baseCollections || [],
      optimismCollections || [],
      gnosisCollections || [],
      solanaCollections || [],
      polygonCollections || [],
      otherCollections?.flat() || [],
    );
  }
  return sort(collections.flat());
}

export enum CollectionSpamFilter {
  All = 'all', // default behavior to get all collection tokens
  SpamExclude = 'spam_exclude',
  SpamOnly = 'spam_only',
}

export type UseCollectionsProps = {
  chainConfig?: ViewNFTChainEnabledConfig;
  suspense?: boolean;
  spamFilter?: CollectionSpamFilter;
  enabled?: boolean; // whether the query should be executed
};

type UseCollectionsReturnType = {
  collections: Collection[];
  refetch: () => void;
  isQueryLoading?: boolean;
  errors?: Error[];
};

/**
 * Returns a non-paginated list of the current active wallet's NFT collections, hidden or non-hidden.
 *
 * @param chainConfig - optional map to specify which chains to fetch NFTs for, defaults to all currently enabled NFT chains on Wallet
 * @param suspense - defaults to true
 * @param spamFilter - a filter to specify whether to fetch non-spam, spam, or all of the current wallet's NFT collections
 * @param enabled - whether or not to actual execute the fetch for a user's collections
 */
export function useCollections(props?: UseCollectionsProps): UseCollectionsReturnType {
  const defaultViewNFTChainConfig = useViewNFTChainEnabledConfig();

  const {
    chainConfig = defaultViewNFTChainConfig,
    suspense = true,
    spamFilter = CollectionSpamFilter.All,
    enabled = true,
  } = props ?? {};
  const activeWalletGroupId = useActiveWalletGroupId();
  const primaryReceiveAddresses = usePrimaryReceiveAddresses(activeWalletGroupId);
  const primaryReceiveAddress = primaryReceiveAddresses.get('ETH')?.address;
  const solReceiveAdderess = primaryReceiveAddresses.get('SOL')?.address;

  return useCollectionForSOLandETHAddress({
    ethAddress: primaryReceiveAddress,
    solAddress: solReceiveAdderess,
    chainConfig,
    suspense,
    spamFilter,
    enabled,
  });
}

/**
 * Returns a non-paginated list of the current active wallet's non-hidden NFT collections.
 *
 * @param chainConfig - optional map to specify which chains to fetch NFTs for, defaults to all currently enabled NFT chains on Wallet
 * @param suspense
 */
export function useVisibleCollections(props?: UseCollectionsProps): UseCollectionsReturnType {
  const defaultViewNFTChainConfig = useViewNFTChainEnabledConfig();
  const { chainConfig = defaultViewNFTChainConfig, suspense = false } = props ?? {};
  const activeWalletGroupId = useActiveWalletGroupId();
  const primaryReceiveAddress = usePrimaryReceiveAddresses(activeWalletGroupId).get('ETH')?.address;
  return useVisibleCollectionsForAddress({
    address: primaryReceiveAddress,
    chainConfig,
    suspense,
  });
}

type UseCollectionsForAddressProps = {
  address?: string | undefined;
  ethAddress?: string;
  solAddress?: string;
  chainConfig: ViewNFTChainEnabledConfig;
} & UseCollectionsProps;

export function useVisibleCollectionsForAddress({
  address,
  chainConfig,
  suspense = true,
}: UseCollectionsForAddressProps): UseCollectionsReturnType {
  const activeWalletGroupId = useActiveWalletGroupId();
  const solReceiveAdderess = usePrimaryReceiveAddresses(activeWalletGroupId).get('SOL')?.address;
  const {
    collections: userCollections,
    refetch,
    errors,
  } = useCollectionForSOLandETHAddress({
    ethAddress: address,
    solAddress: solReceiveAdderess,
    chainConfig,
    suspense,
  });

  const isVisible = useIsCollectibleVisible();

  const visibleCollections = useMemo(
    function getVisibleCollections() {
      return userCollections.reduce(function filterVisibleCollections(acc, collection) {
        const filteredCollection = {
          ...collection,
          tokens: {
            ...collection.tokens,
            tokenList: collection.tokens.tokenList.filter((token) =>
              isVisible({
                contractAddress: token.contractAddress,
                tokenId: token.tokenId,
                chainId: BigInt(collection.collectionInfo.chainId),
                userAddress: address || '',
                spam: token.spam,
              }),
            ),
          },
        };

        if (filteredCollection.tokens.tokenList?.length) {
          acc.push(filteredCollection);
        }

        return acc;
      }, [] as Collection[]);
    },
    [address, isVisible, userCollections],
  );

  return { collections: visibleCollections, refetch, errors };
}

export function useCollectionForSOLandETHAddress({
  ethAddress,
  solAddress,
  chainConfig,
  suspense = true,
  spamFilter = CollectionSpamFilter.All,
  enabled = true,
}: UseCollectionsForAddressProps): UseCollectionsReturnType {
  const isBackendChainConfigEnabled = useIsBackendNFTChainConfigEnabled();

  const chainIdAddressPairings = useMemo(() => {
    return isBackendChainConfigEnabled
      ? getChainSettingsFromBackendBasedChainConfig({ chainConfig, ethAddress, solAddress })
      : getChainSettingsFromKillswitchedChainConfig({ chainConfig, ethAddress, solAddress });
  }, [isBackendChainConfigEnabled, chainConfig, ethAddress, solAddress]);

  const queryKeys = useMemo(
    () =>
      chainIdAddressPairings.map(({ chainId, address }) => [
        FETCH_QUERY_KEY,
        address,
        chainId,
        spamFilter,
      ]),
    [chainIdAddressPairings, spamFilter],
  );

  return useCollectionForAddress(queryKeys, 'useCollectionForSOLandETHAddress', suspense, enabled);
}

export function useCollectionForETHAddress({
  address: userAddress,
  chainConfig,
  suspense = true,
}: UseCollectionsForAddressProps): UseCollectionsReturnType {
  const isBackendChainConfigEnabled = useIsBackendNFTChainConfigEnabled();
  const chainIdAddressPairings = useMemo(() => {
    return isBackendChainConfigEnabled
      ? getChainSettingsFromBackendBasedChainConfig({ chainConfig, ethAddress: userAddress })
      : getChainSettingsFromKillswitchedChainConfig({ chainConfig, ethAddress: userAddress });
  }, [isBackendChainConfigEnabled, chainConfig, userAddress]);

  const queryKeys = useMemo(
    () =>
      chainIdAddressPairings.map(({ chainId, address }) => [
        FETCH_QUERY_KEY,
        address,
        chainId,
        String(isBackendChainConfigEnabled),
      ]),
    [chainIdAddressPairings, isBackendChainConfigEnabled],
  );

  return useCollectionForAddress(queryKeys, 'useCollectionForETHAddress', suspense);
}

export function useCollectionForAddress(
  queryKeys: (string | undefined)[][],
  method: string,
  suspense = true,
  enabled = true,
) {
  const isSortByChainEnabled = useIsNFTCollectionSortByChainEnabled();
  // Set a local storage state to tell if useBalanceUpdateEvents should cache collections count or run balance update event.
  const startCachingCollectionsCount = useCallback(function startCachingCollectionsCount(
    shouldStart?: boolean,
  ) {
    if (Store.get(StoreKeys_collectionsCountCacheState) !== undefined || !shouldStart) return;

    Store.set(StoreKeys_collectionsCountCacheState, 'start');
  },
  []);

  const queries = useMemo(() => {
    return queryKeys.map(
      // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
      // eslint-disable-next-line wallet/no-anonymous-params
      (queryKey, i) => {
        const [, address, chainId, spamFilter] = queryKey;
        queryKey.push(enabled.toString());
        return {
          queryKey,
          queryFn: async () => fetchCollections(address, chainId, spamFilter),
          enabled: !!address && enabled,
          retry: false,
          suspense,
          useErrorBoundary: false,
          staleTime: 1000 * 30,
          refetchInterval: 1000 * 60 * 2,
          notifyOnChangeProps: ['data'] as 'data'[],
          onSuccess: () => startCachingCollectionsCount(i === queryKeys.length - 1),

          // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
          // eslint-disable-next-line wallet/no-anonymous-params
          onError: (error: ErrorOrAny) => {
            startCachingCollectionsCount(i === queryKeys.length - 1);
            const metadata = {
              method,
              address: address || '',
              chainId: chainId || '',
              spamFilter: spamFilter || '',
            };
            cbReportError({
              error,
              ...metadata,
              context: 'http_error',
              isHandled: false,
              severity: 'error',
            });
          },
        };
      },
    );
  }, [queryKeys, suspense, startCachingCollectionsCount, method, enabled]);

  const results = useStableQueries([...queries]);
  const refetchAll = useCallback(
    function refetchAll() {
      results.forEach(async (result) => result.refetch());
    },
    [results],
  );

  const isQueryLoading = useMemo(
    () => results.reduce((accu, result) => accu || result.isLoading, false),
    [results],
  );

  const collections = useMemo(
    () =>
      results.map(
        // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
        // eslint-disable-next-line wallet/no-anonymous-params
        (result) => {
          const { isSuccess, data } = result;
          return isSuccess ? data : [];
        },
      ),
    [results],
  );

  const isSimulatingEmptyWallet = useRecoilValue(isSimulatingEmptyWalletAtom);

  const sortedCollections = useMemo(() => {
    if (isSimulatingEmptyWallet) return [];

    return sortCollections(collections || [], isSortByChainEnabled);
  }, [collections, isSimulatingEmptyWallet, isSortByChainEnabled]);

  const errors = useMemo(() => {
    return results.filter((result) => !!result.error).map((result) => result.error);
  }, [results]);

  return useMemo(
    () => ({ collections: sortedCollections, refetch: refetchAll, isQueryLoading, errors }),
    [sortedCollections, refetchAll, isQueryLoading, errors],
  );
}

export function useTokensForProfile(userAddress?: string) {
  const metadata = { method: 'useTokensForProfile ' };
  const { data, isLoading } = useQuery(
    ['getUsertokenList', userAddress],
    async () => fetchProfileTokens(userAddress),
    {
      notifyOnChangeProps: ['data'],
      onError: (error: ErrorOrAny) => {
        cbReportError({
          error,
          ...metadata,
          context: 'http_error',
          severity: 'error',
          isHandled: false,
        });
      },
    },
  );

  // @ts-expect-error TS(2339): Property 'tokens' does not exist on type 'never[] ... Remove this comment to see the full error message
  const tokens = data?.tokens;
  return { tokens, isLoading };
}

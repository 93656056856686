import { getJSON, postJSON } from 'cb-wallet-http/fetchJSON';
import { RPC_SELECTORS } from 'wallet-engine-signing/blockchains/Ethereum/constants';
import {
  addressArgument,
  bigIntFromHex,
  strip0x,
} from 'wallet-engine-signing/blockchains/Ethereum/formatNumbers';
import {
  callEthereumJSONRPC,
  getABIStringResult,
  getBNResult,
} from 'wallet-engine-signing/blockchains/Ethereum/RPC';

// Instructs TPP to use the fastest node provider. Only added to
// requests initiated by nudges.
export const TPP_NUDGE_ROUTING_HEADER = {
  'x-tpp-routing-rule': 'urn:routing-rule:function:wallet-balance-refresh',
};

export async function getEtherBalance(
  address: string,
  rpcUrl: string,
  isNudgeRefresh = false,
): Promise<bigint> {
  const headers = isNudgeRefresh ? TPP_NUDGE_ROUTING_HEADER : {};
  const { result } = await callEthereumJSONRPC(
    'eth_getBalance',
    [address.toLowerCase(), 'latest'],
    rpcUrl,
    headers,
  );

  return handleEtherBalanceResponse(result);
}

export function handleEtherBalanceResponse(result: string) {
  if (!result || typeof result !== 'string') {
    throw new Error('unexpected response');
  } else if (result.length === 0 || result === '0x') {
    return 0n;
  }

  return bigIntFromHex(result);
}

export async function getERC20Balance(
  address: string,
  contractAddress: string,
  rpcUrl: string,
): Promise<bigint> {
  const { result } = await callEthereumJSONRPC(
    'eth_call',
    [
      {
        to: contractAddress.toLowerCase(),
        data: `0x${RPC_SELECTORS.balanceOf}${addressArgument(address)}`,
      },
      'latest',
    ],
    rpcUrl,
  );

  if (!result || typeof result !== 'string') {
    throw new Error('unexpected response');
  } else if (result.length === 0 || result === '0x') {
    return BigInt(0);
  }

  return bigIntFromHex(strip0x(result));
}

export async function getERC20Info(contractAddress: string, rpcUrl: string) {
  const responses = await Promise.all(
    [RPC_SELECTORS.name, RPC_SELECTORS.symbol, RPC_SELECTORS.decimals].map(async (selector) =>
      callEthereumJSONRPC(
        'eth_call',
        [
          {
            to: contractAddress.toLowerCase(),
            data: `0x${selector}`,
          },
          'latest',
        ],
        rpcUrl,
      ),
    ),
  );

  const name = getABIStringResult(responses[0]);
  const symbol = getABIStringResult(responses[1]);
  const decimals = getBNResult(responses[2]).toNumber();

  if (!name && !symbol && !decimals) {
    return {
      name: '',
      symbol: '',
      decimals: 0,
      error: 'erc20 info missing',
    };
  }

  return { name, symbol, decimals };
}

export type V2SpamScoreThresholds = {
  global_spam: number;
  whitelist: number;
  likely_spam: number;
  likely_not_spam: number;
};

export type GetERC20Infos = {
  tokens: ERC20TokenMetadataResponse[];
  spamScoreThresholds: V2SpamScoreThresholds;
};

export async function getERC20Infos(
  contractAddresses: string[],
  chainId: bigint,
): Promise<GetERC20Infos> {
  const {
    result: { tokens, spam_score_thresholds: spamScoreThresholds },
  } = await postJSON<{
    result: {
      tokens: ERC20TokenMetadataResponse[];
      spam_score_thresholds: V2SpamScoreThresholds;
    };
  }>('getERC20Infos', {
    chainId: Number(chainId),
    contractAddresses,
  });
  return { tokens, spamScoreThresholds };
}

export type ERC20TokenBalanceResponse = {
  chainId: number;
  contractAddress: string;
  tokenBalance: string;
  errorMessage?: string;
};

export type ERC20TokenMetadataResponse = {
  address?: string;
  name?: string;
  symbol?: string;
  decimals?: number;
  chain_id?: string;
  assetUUID?: string;
  imageUrl?: string;
  spam_score?: number;
};

export type GetERC20Balances = {
  address: string;
  chainId: bigint;
  balances: ERC20TokenBalanceResponse[];
};

export type GetERC20BalancesResult = {
  balances?: GetERC20Balances[];
};

export async function getAllBalancesForChain(
  addresses: string[],
  chainId: string,
): Promise<GetERC20Balances[]> {
  const { result } = await getJSON<{ result: GetERC20BalancesResult }>(
    'getAllBalancesForChain',
    { chainId, includeNativeAsset: 'true', addresses: addresses.join(',') },
    {
      apiVersion: 2,
    },
  );

  return result.balances ? result.balances : [];
}

import { DefiPositionRow, DefiPositionType, LiquidityPoolPosition } from '../models/DefiPosition';

export function isLiquidityPoolPosition(
  position: DefiPositionRow,
): position is LiquidityPoolPosition {
  return (
    position.type === DefiPositionType.LiquidityPool &&
    (position as LiquidityPoolPosition).assetInfos !== undefined
  );
}

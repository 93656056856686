import { CB_WALLET_CHAIN_PROXY_URL } from './env';

export const ethProductionUrls = {
  ETHEREUM_MAINNET: {
    blockExplorerUrl: 'https://etherscan.io',
    rpcUrl: `${CB_WALLET_CHAIN_PROXY_URL}?targetName=ethereum-mainnet`,
    etherscanCompatibleTxHistoryApi: CB_WALLET_CHAIN_PROXY_URL,
  },
  OPTIMISM_MAINNET: {
    blockExplorerUrl: 'https://optimistic.etherscan.io',
    rpcUrl: `${CB_WALLET_CHAIN_PROXY_URL}?targetName=optimism-mainnet`,
    etherscanCompatibleTxHistoryApi: CB_WALLET_CHAIN_PROXY_URL,
  },
  BASE_MAINNET: {
    blockExplorerUrl: 'https://explorer.base.org',
    rpcUrl: `${CB_WALLET_CHAIN_PROXY_URL}?targetName=base`,
    etherscanCompatibleTxHistoryApi: CB_WALLET_CHAIN_PROXY_URL,
  },
  POLYGON_MAINNET: {
    blockExplorerUrl: 'https://polygonscan.com',
    rpcUrl: `${CB_WALLET_CHAIN_PROXY_URL}?targetName=polygon-mainnet`,
    etherscanCompatibleTxHistoryApi: CB_WALLET_CHAIN_PROXY_URL,
  },
  BNB_MAINNET: {
    blockExplorerUrl: 'https://bscscan.com',
    rpcUrl: `${CB_WALLET_CHAIN_PROXY_URL}?targetName=bsc`,
    etherscanCompatibleTxHistoryApi: CB_WALLET_CHAIN_PROXY_URL,
  },
  FANTOM_MAINNET: {
    blockExplorerUrl: 'https://ftmscan.com',
    rpcUrl: `${CB_WALLET_CHAIN_PROXY_URL}?targetName=fantom`,
    etherscanCompatibleTxHistoryApi: CB_WALLET_CHAIN_PROXY_URL,
  },
  ARBITRUM_MAINNET: {
    blockExplorerUrl: 'https://arbiscan.io',
    rpcUrl: `${CB_WALLET_CHAIN_PROXY_URL}?targetName=arbitrum`,
    etherscanCompatibleTxHistoryApi: CB_WALLET_CHAIN_PROXY_URL,
  },
  GNOSIS_MAINNET: {
    blockExplorerUrl: 'https://blockscout.com/poa/xdai',
    rpcUrl: `${CB_WALLET_CHAIN_PROXY_URL}?targetName=xdai`,
    etherscanCompatibleTxHistoryApi: CB_WALLET_CHAIN_PROXY_URL,
  },
  AVALANCHE_MAINNET: {
    blockExplorerUrl: 'https://snowtrace.io',
    rpcUrl: `${CB_WALLET_CHAIN_PROXY_URL}?targetName=avalanche`,
    etherscanCompatibleTxHistoryApi: CB_WALLET_CHAIN_PROXY_URL,
  },
  ZETACHAIN_MAINNET: {
    blockExplorerUrl: 'https://zetachain.blockscout.com',
    rpcUrl: `${CB_WALLET_CHAIN_PROXY_URL}?targetName=zetachain-mainnet`,
    etherscanCompatibleTxHistoryApi: CB_WALLET_CHAIN_PROXY_URL,
  },
  ZORA_MAINNET: {
    blockExplorerUrl: 'https://explorer.zora.energy',
    rpcUrl: `${CB_WALLET_CHAIN_PROXY_URL}?targetName=zora-mainnet`,
    etherscanCompatibleTxHistoryApi: CB_WALLET_CHAIN_PROXY_URL,
  },
  // TESTNETS
  SEPOLIA: {
    blockExplorerUrl: 'https://sepolia.etherscan.io',
    rpcUrl: `${CB_WALLET_CHAIN_PROXY_URL}?targetName=ethereum-sepolia`,
    etherscanCompatibleTxHistoryApi: CB_WALLET_CHAIN_PROXY_URL,
  },
  HOLESKY: {
    blockExplorerUrl: 'https://holesky.etherscan.io',
    rpcUrl: `${CB_WALLET_CHAIN_PROXY_URL}?targetName=ethereum-holesky`,
    etherscanCompatibleTxHistoryApi: CB_WALLET_CHAIN_PROXY_URL,
  },
  OP_SEPOLIA: {
    blockExplorerUrl: 'https://optimism-sepolia.blockscout.com',
    rpcUrl: `${CB_WALLET_CHAIN_PROXY_URL}?targetName=optimism-sepolia`,
    etherscanCompatibleTxHistoryApi: CB_WALLET_CHAIN_PROXY_URL,
  },
  BASE_SEPOLIA: {
    blockExplorerUrl: 'https://sepolia.basescan.org',
    rpcUrl: `${CB_WALLET_CHAIN_PROXY_URL}?targetName=base-sepolia`,
    etherscanCompatibleTxHistoryApi: CB_WALLET_CHAIN_PROXY_URL,
  },
  BNB_TESTNET: {
    blockExplorerUrl: 'https://testnet.bscscan.com',
    rpcUrl: `${CB_WALLET_CHAIN_PROXY_URL}?targetName=bsc-testnet`,
    etherscanCompatibleTxHistoryApi: CB_WALLET_CHAIN_PROXY_URL,
  },
  FANTOM_TESTNET: {
    blockExplorerUrl: 'https://testnet.ftmscan.com',
    rpcUrl: 'https://rpc.ankr.com/fantom_testnet',
    etherscanCompatibleTxHistoryApi: CB_WALLET_CHAIN_PROXY_URL,
  },
  ARBITRUM_SEPOLIA: {
    blockExplorerUrl: 'https://sepolia.arbiscan.io',
    rpcUrl: `${CB_WALLET_CHAIN_PROXY_URL}?targetName=arbitrum-sepolia`,
    etherscanCompatibleTxHistoryApi: CB_WALLET_CHAIN_PROXY_URL,
  },
  AVALANCHE_FUJI: {
    blockExplorerUrl: 'https://testnet.snowtrace.io',
    rpcUrl: `${CB_WALLET_CHAIN_PROXY_URL}?targetName=avalanche-fuji`,
    etherscanCompatibleTxHistoryApi: CB_WALLET_CHAIN_PROXY_URL,
  },
  LOCALHOST_8545: {
    blockExplorerUrl: '',
    rpcUrl: 'http://127.0.0.1:8545',
    etherscanCompatibleTxHistoryApi: '',
  },
};

import { ComponentType } from '@cbhq/client-analytics';

import { logDataEvent } from './log';

type WithObservabilityFactoryEventBaseParams = { stepWorkflow: string; stepName: string };

type WithObservabilityFactoryStartEventParams = WithObservabilityFactoryEventBaseParams;

type WithObservabilityFactoryCompletedEventParams = WithObservabilityFactoryEventBaseParams;

type WithObservabilityFactoryFailureEventParams = WithObservabilityFactoryEventBaseParams & {
  error: ErrorOrAny;
};

type WithObservabilityFactoryStartEvent = (
  params: WithObservabilityFactoryStartEventParams,
) => void;

type WithObservabilityFactoryCompletedEvent = (
  params: WithObservabilityFactoryCompletedEventParams,
) => void;

type WithObservabilityFactoryFailureEvent = (
  params: WithObservabilityFactoryFailureEventParams,
) => void;

export type CreateWithObservabilityParams = {
  stepWorkflow: string;
  onStepStart?: WithObservabilityFactoryStartEvent;
  onStepCompleted?: WithObservabilityFactoryCompletedEvent;
  onStepFailed?: WithObservabilityFactoryFailureEvent;
};

type WithObservabilityStepFunction<R> = (() => Promise<R>) | (() => R);

export type WithObservabilityFunction = <R>(
  stepName: string,
  stepFunction: WithObservabilityStepFunction<R>,
) => Promise<R>;

/**
 * Returns a function that wraps a passed-in sync/async function with start and completed/failed eventing sent to Amplitude.
 *
 * This returned function is intended to be used to wrap one of multiple steps in a complex workflow (e.g. creating a new
 * user, signing a user out, etc.) where increased observability is desired to allow us to track a user's progress through
 * said workflow.
 *
 * The Amplitude event names are generic, but consumers can define an overall `stepWorkflow` to group them
 * as well as an individual `stepName`s to identify each step. This can be used to create various segmentation, conversion
 * funnels, or other types of charts in Amplitude.
 *
 * This returned function will also call any optional `onStepStart` and `onStepCompleted`/`onStepFailed` events before
 * and then after the step's function is called, respectively. Each event will contain the `stepWorkflow` and `stepName`
 * parameters passed to this function. The `onStepFailed` event will also contain the error that was thrown by the step's
 * function.
 *
 * **NOTE:** You can use `cb-wallet-analytics/test/buildUseWithObservabilityMocks` to help mock this functionality when
 * writing unit tests.
 *
 * @returns See {@link WithObservabilityFunction}
 */
export function createWithObservability(
  params: CreateWithObservabilityParams,
): WithObservabilityFunction {
  const { stepWorkflow, onStepStart, onStepCompleted, onStepFailed } = params;

  return async function withObservability(stepName, stepFunction) {
    try {
      logDataEvent('with_observability.step.start', {
        stepWorkflow,
        stepName,
        componentType: ComponentType.unknown,
      });
      onStepStart?.({ stepWorkflow, stepName });

      const stepResult = await stepFunction();

      logDataEvent('with_observability.step.completed', {
        stepWorkflow,
        stepName,
        componentType: ComponentType.unknown,
      });
      onStepCompleted?.({ stepWorkflow, stepName });

      return stepResult;
    } catch (error: ErrorOrAny) {
      // We intentionally do not log any errors here since we cannot know if the error contains any PII data that we
      // need to avoid forwarding to any 3rd party analytics services (i.e. Amplitude).
      logDataEvent('with_observability.step.failed', {
        stepWorkflow,
        stepName,
        componentType: ComponentType.unknown,
      });
      onStepFailed?.({ stepWorkflow, stepName, error });

      throw error;
    }
  };
}

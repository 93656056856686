import { SolanaNetworkSetting } from 'cb-wallet-data/chains/AccountBased/Solana/config';
import { BitcoinNetworkSetting } from 'cb-wallet-data/chains/UTXO/Bitcoin/config';
import { DogecoinNetworkSetting } from 'cb-wallet-data/chains/UTXO/Dogecoin/config';
import { LitecoinNetworkSetting } from 'cb-wallet-data/chains/UTXO/Litecoin/config';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';

import { selectEthereumNetworkFromChainId } from './selectEthereumNetworkFromChainId';

const ethereumNetworkWacIdMap: Record<string, Network | undefined> = {
  'networks/ethereum-mainnet': selectEthereumNetworkFromChainId(1),
  'networks/optimism-mainnet': selectEthereumNetworkFromChainId(10),
  'networks/base-mainnet': selectEthereumNetworkFromChainId(8453),
  'networks/polygon-mainnet': selectEthereumNetworkFromChainId(137),
  'networks/bnb-mainnet': selectEthereumNetworkFromChainId(56),
  'networks/fantom-mainnet': selectEthereumNetworkFromChainId(250),
  'networks/arbitrum-mainnet': selectEthereumNetworkFromChainId(42161),
  'networks/xdai-mainnet': selectEthereumNetworkFromChainId(100),
  'networks/avacchain-mainnet': selectEthereumNetworkFromChainId(43114),
  'networks/zetachain-mainnet': selectEthereumNetworkFromChainId(7000),
  'networks/zora-mainnet': selectEthereumNetworkFromChainId(7777777),
  'networks/ethereum-holesky-testnet': selectEthereumNetworkFromChainId(17000),
  'networks/ethereum-sepolia-testnet': selectEthereumNetworkFromChainId(11155111),
  'networks/optimism-sepolia-testnet': selectEthereumNetworkFromChainId(11155420),
  'networks/base-sepolia-testnet': selectEthereumNetworkFromChainId(84532),
  'networks/bnb-testnet': selectEthereumNetworkFromChainId(97),
  'networks/fantom-testnet': selectEthereumNetworkFromChainId(4002),
  'networks/arbitrum-sepolia-testnet': selectEthereumNetworkFromChainId(421614),
  'networks/avacchain-fuji-testnet': selectEthereumNetworkFromChainId(43113),
};

const bitcoinNetworkWacIdMap: Record<string, Network | undefined> = {
  'networks/bitcoin-mainnet': BitcoinNetworkSetting.mainnets[0].network,
};

const dogecoinNetworkWacIdMap: Record<string, Network | undefined> = {
  'networks/dogecoin-mainnet': DogecoinNetworkSetting.mainnets[0].network,
};

const litecoinNetworkWacIdMap: Record<string, Network | undefined> = {
  'networks/litecoin-mainnet': LitecoinNetworkSetting.mainnets[0].network,
};

const solanaNetworkWacIdMap: Record<string, Network | undefined> = {
  'networks/solana-mainnet': SolanaNetworkSetting.mainnets[0].network,
};

const networkWacIdMap = {
  ...ethereumNetworkWacIdMap,
  ...bitcoinNetworkWacIdMap,
  ...dogecoinNetworkWacIdMap,
  ...litecoinNetworkWacIdMap,
  ...solanaNetworkWacIdMap,
};

export function getNetworkForWacId(wacId: string) {
  return networkWacIdMap[wacId];
}

import { DogecoinAddressType } from 'cb-wallet-data/chains/UTXO/Dogecoin/config';
import { UTXOError } from 'cb-wallet-data/chains/UTXO/exceptions/UTXOError';
import { getSigningOrDerivationMethod } from 'cb-wallet-data/ServiceLocator/signingAndDerivation/utils/getSigningOrDerivationMethod';
import { AddressType } from 'cb-wallet-data/stores/Addresses/models/AddressType';

export async function deriveAddressFromXpubKey(
  xpubKey: string,
  index: bigint,
  addressType: AddressType,
  isChange: boolean,
  isTestnet: boolean,
): Promise<string> {
  switch (addressType.rawValue) {
    case DogecoinAddressType.rawValue:
      return getSigningOrDerivationMethod('deriveLegacyAddressFromXpubKey')({
        blockchainSymbol: 'DOGE',
        xpubKey,
        index: Number(index),
        change: isChange,
        testnet: isTestnet,
      });
    default:
      throw UTXOError.unableToDeriveXpubKey(addressType, isTestnet);
  }
}

import { ThemeColorPreference } from 'cb-wallet-data/stores/ThemeColors/themeColorConfigs';
// eslint-disable-next-line no-restricted-imports
import { useActiveWalletGroupId } from 'cb-wallet-data/stores/WalletGroups/hooks/useActiveWalletGroupId';
import { useWalletGroups } from 'cb-wallet-data/stores/WalletGroups/hooks/useWalletGroups';
import { useWalletGroupToDisplay } from 'cb-wallet-data/stores/WalletGroups/hooks/useWalletGroupToDisplay';

export const themeColorDefault: ThemeColorPreference = 'blue';

export type useThemeColorProps = {
  walletGroupId?: string;
};

/**
 *
 * Returns a theme color for the provided wallet group ID. If no ID is provided,
 * it returns the theme color for the active wallet group.
 * When Multi Account is not enabled, it returns the theme color from local storage
 *
 * @param walletGroupId
 * @returns Theme color preference
 */
export function useThemeColor({ walletGroupId = '' }: useThemeColorProps = {}) {
  const activeWalletGroupId = useActiveWalletGroupId();
  const activeWalletGroup = useWalletGroupToDisplay(activeWalletGroupId);
  const [walletGroupFromId] = useWalletGroups().filter((wg) => wg.id === walletGroupId);

  if (walletGroupFromId) {
    return walletGroupFromId.themeColor ?? themeColorDefault;
  }

  return activeWalletGroup?.themeColor ?? themeColorDefault;
}

import { memo } from 'react';
import { ListCellFallback } from '@cbhq/cds-web/cells';
import { VStack } from '@cbhq/cds-web/layout';

const NO_OF_FALLBACKS = 10;

function getFallbackKey(index: number) {
  return `Fallback-${index}`;
}

export const Loader = memo(function Loader() {
  return (
    <VStack gap={1} height="100%" width="100%" testID="search-result-list-loader">
      {Array.from({ length: NO_OF_FALLBACKS }).map((_, index) => {
        return (
          <ListCellFallback key={getFallbackKey(index)} title description media="avatar" detail />
        );
      })}
    </VStack>
  );
});

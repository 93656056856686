import { isScientificNotation } from 'cb-wallet-data/utils/numeric';

/**
 *
 * Takes a float number representation and excludes all fraction digits after the first non-zero digit.
 *
 * e.g '123.0000123' => '123.00001'
 *
 * WARNING: Do not use it for strings that are not a valid float number representation, like fiat representations,
 * because the decimal separator may not be `.` for certain currencies.
 */
export function abbreviateFiatRawValue(valueStr: string | undefined) {
  if (!valueStr || isNaN(Number(valueStr))) return undefined;

  try {
    const value = isScientificNotation(valueStr) ? Number(valueStr).toFixed(8) : valueStr; // toFixed is used to expand an eventual scientific notation
    const [integer, decimal] = value.split('.') ?? [];

    if (!decimal) return value;

    const decimalFirstNonZero = decimal.split('').findIndex((char) => char !== '0');
    const lastIndex = decimalFirstNonZero === -1 ? decimal.length : decimalFirstNonZero + 1;
    return `${integer}.${decimal?.slice(0, lastIndex)}`;
  } catch {
    return undefined;
  }
}

import { useMemo } from 'react';
import { useSettingsSearch as useSearchFromDataLayer } from 'cb-wallet-data/stores/SettingsRedesign/hooks/useSettingsSearch';
import { KeyActionCategoryConfig } from 'cb-wallet-data/stores/SettingsRedesign/types';

import { useKeyActionsConfigs } from ':dapp/hooks/keyActions/useKeyActionsConfig';

type useKeyActionsSearchProps = {
  query: string;
};

export function useKeyActionsSearch({ query }: useKeyActionsSearchProps) {
  const configs = useKeyActionsConfigs();
  const keyActionsSuggestions = useMemo(() => {
    return configs.reduce((suggestions: KeyActionCategoryConfig[], config) => {
      suggestions.push(config);
      return suggestions;
    }, []);
  }, [configs]);

  return useSearchFromDataLayer({ suggestions: keyActionsSuggestions, query });
}

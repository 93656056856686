import { useState } from 'react';
import { useDebounce } from 'cb-wallet-data/hooks/useDebounce';

const DEBOUNCE_TIME = 300;

export function useDebouncedSearchQuery() {
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, DEBOUNCE_TIME);

  return {
    query,
    setQuery,
    debouncedQuery,
  };
}

import {
  AddressLookupTableAccount,
  ComputeBudgetProgram,
  PublicKey,
  TransactionInstruction,
} from '@solana/web3.js';
import {
  DEFAULT_SOLANA_COMPUTE_UNIT_LIMIT,
  DEFAULT_SOLANA_PRIORITY_FEE,
  SOLANA_CURRENCY_DECIMAL,
} from 'cb-wallet-data/chains/AccountBased/Solana/constants';
import { SolanaNetworkMap } from 'cb-wallet-data/chains/AccountBased/Solana/models/SolanaChain';
import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { fetchDomains } from 'cb-wallet-data/stores/DecentralizedID/hooks/usePublicProfileByAddress';
import { TxOrUserOp } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import { TxOrUserOpMetadata } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOpMetadata';
import { TxState } from 'cb-wallet-data/stores/Transactions/models/TxState';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { SOLANA_RPC_URL } from 'cb-wallet-env/env';
import { getJSON } from 'cb-wallet-http/fetchJSON';
import { v4 as uuidv4 } from 'uuid';
import {
  DEFAULT_COMMITMENT_LEVEL,
  getAccountInfo,
  getAccountInfoBase64Batch,
  GetAccountInfoValueData,
  getSignatureStatuses,
  getSPLInfos,
  GetTransactionResult,
  getTransactionRPC,
  Instruction,
  SolanaCommitmentLevel,
} from 'wallet-engine-signing/history';

import { saveSPLTokens } from './Balance/saveSPLTokens';
import { SPL } from './models/SPL';
import { asNetwork } from './utils/chain';
import { SolanaBlockchain, SolanaWalletConfiguration } from './config';
import { getSPL } from './database';

type PriorityFeeResult = {
  result: {
    computeUnitPrice?: number;
    computeUnitLimit?: number;
  };
};

type PriorityFeeInfo = {
  computeUnitPrice: bigint;
  computeUnitLimit: number;
};

/**
 * Fetch the configured priority fee and compute unit limit from the wallet api or return the default hardcoded value
 *
 * @returns object containing the priority fee in micro lamports and the compute unit limit
 */
export async function fetchPriorityFees(accountAddresses: string[]): Promise<PriorityFeeInfo> {
  try {
    const { result } = await getJSON<PriorityFeeResult>(`estimateSolanaPriorityFee`, {
      accounts: accountAddresses.join(','),
    });

    const computeUnitPrice = result.computeUnitPrice
      ? BigInt(result.computeUnitPrice)
      : DEFAULT_SOLANA_PRIORITY_FEE;
    const computeUnitLimit = result.computeUnitLimit ?? DEFAULT_SOLANA_COMPUTE_UNIT_LIMIT;

    return {
      computeUnitPrice,
      computeUnitLimit,
    };
  } catch (err) {
    return {
      computeUnitPrice: DEFAULT_SOLANA_PRIORITY_FEE,
      computeUnitLimit: DEFAULT_SOLANA_COMPUTE_UNIT_LIMIT,
    };
  }
}

type PriorityFeeInstructions = {
  priorityFeeInstruction: TransactionInstruction;
  computeUnitLimitInstruction: TransactionInstruction;
};

export async function getPriorityFeeInstructions(
  accountAddresses: string[],
): Promise<PriorityFeeInstructions> {
  const { computeUnitPrice, computeUnitLimit } = await fetchPriorityFees(accountAddresses);

  const priorityFeeInstruction = ComputeBudgetProgram.setComputeUnitPrice({
    microLamports: computeUnitPrice,
  });
  const computeUnitLimitInstruction = ComputeBudgetProgram.setComputeUnitLimit({
    units: computeUnitLimit,
  });

  return {
    priorityFeeInstruction,
    computeUnitLimitInstruction,
  };
}

export type SPLToken = {
  address: string;
  errorMessage?: string;
  symbol: string;
  decimals: number;
  name: string;
  imageUrl: string;
  assetUUID?: string;
};

export type GetSPLInfosResponse = {
  tokens: SPLToken[];
  errorMessage?: string;
};

export async function getAddressTableLookupAccounts(
  accountKeys: PublicKey[],
): Promise<AddressLookupTableAccount[]> {
  if (accountKeys.length === 0) return [];

  const addresses = accountKeys.map((accountKey) => accountKey.toBase58());
  const accountInfoResults = await getAccountInfoBase64Batch({
    addresses,
    rpcUrl: SOLANA_RPC_URL,
  });

  if (accountInfoResults.length === 0) {
    return [];
  }

  return accountInfoResults
    .map(function parseAccounts(account, index) {
      if (!account.value) {
        return;
      }

      const data = account.value.data;
      const decodedData = Buffer.from(data[0], 'base64');

      return new AddressLookupTableAccount({
        key: accountKeys[index],
        state: AddressLookupTableAccount.deserialize(decodedData),
      });
    })
    .filter((account): account is AddressLookupTableAccount => !!account);
}

function unixTimestampToDate(timestamp: string): Date {
  return new Date(Number(timestamp) * 1000);
}

const SPL_TRANSFER_INSTRUCTION = 'transferChecked';
const SOL_TRANSFER_INSTRUCTION = 'transfer';
const SPL_PROGRAM = 'spl-token';

export async function formatTransactionFromFetch({
  result,
  signature,
  mainAccountAddress,
  walletIndex,
  accountId,
}: {
  result: GetTransactionResult;
  signature: string;
  mainAccountAddress: string;
  walletIndex: bigint;
  accountId: Account['id'];
}): Promise<TxOrUserOp[] | undefined> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const transferInstruction: any = result.transaction.message.instructions.find(
    (instruction: Instruction) =>
      (instruction.parsed && instruction.parsed?.type === SPL_TRANSFER_INSTRUCTION) ||
      instruction.parsed?.type === SOL_TRANSFER_INSTRUCTION,
  );

  // the transaction is not an SPL or SOL transfer eg. swap, stake, close account, create account ...
  if (!transferInstruction) {
    return formatSwapInstruction({ result, mainAccountAddress, walletIndex, accountId });
  }

  if (
    (transferInstruction.parsed.type === SPL_TRANSFER_INSTRUCTION ||
      transferInstruction.parsed.type === SOL_TRANSFER_INSTRUCTION) &&
    transferInstruction.program === SPL_PROGRAM
  ) {
    let mintAddress = transferInstruction.parsed.info.mint;
    if (!mintAddress) {
      const source = transferInstruction.parsed.info.source;
      const destination = transferInstruction.parsed.info.destination;
      const authorityAddress = transferInstruction.parsed.info.authority;
      const isSent = mainAccountAddress === authorityAddress;
      // get the mint address from source or destination
      const accountInfo = await getAccountInfo({
        address: isSent ? source : destination,
        rpcUrl: SOLANA_RPC_URL,
      });
      mintAddress = (accountInfo?.value?.data as GetAccountInfoValueData)?.parsed?.info?.mint;
      if (!mintAddress) return;
    }
    const splToken = await getSPL(mintAddress, SolanaNetworkMap.whitelisted.SOLANA_MAINNET.chainId);

    if (!splToken) {
      return;
    }

    const splTransaction = await formatSPLTransaction({
      blockTime: result.blockTime,
      fee: BigInt(result.meta.fee),
      signature,
      transferInstruction,
      mainAccountAddress,
      splToken,
      mintAddress,
      walletIndex,
      accountId,
    });
    return splTransaction ? [splTransaction] : undefined;
  }
  if (transferInstruction.parsed.type === SOL_TRANSFER_INSTRUCTION) {
    const solTransaction = await formatSOLTransaction({
      blockTime: result.blockTime,
      fee: BigInt(result.meta.fee),
      signature,
      transferInstruction,
      mainAccountAddress,
      walletIndex,
      accountId,
    });
    return solTransaction ? [solTransaction] : undefined;
  }
}

async function formatSwapInstruction({
  result,
  mainAccountAddress,
  walletIndex,
  accountId,
}: {
  result: GetTransactionResult;
  mainAccountAddress: string;
  walletIndex: bigint;
  accountId: Account['id'];
}): Promise<TxOrUserOp[] | undefined> {
  try {
    const accountKeys = result.transaction.message.accountKeys;
    const innerInstructionsArray = result.meta.innerInstructions;
    if (!innerInstructionsArray) return;
    const innerInstructionsAll = innerInstructionsArray.flatMap((innerInstruction) => {
      return innerInstruction.instructions.flat();
    });

    const postTokenBalances = result.meta.postTokenBalances;
    const signature: string = result.transaction.signatures.find((sig: string) => sig !== '') ?? '';

    const hasDataForFormatting =
      postTokenBalances?.length && innerInstructionsAll && accountKeys && signature;

    if (!hasDataForFormatting) return undefined;

    const swapTransferInstructions = innerInstructionsAll.filter(
      // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
      // eslint-disable-next-line wallet/no-anonymous-params
      (instruction: Instruction) => {
        if (!instruction.parsed) return false;
        const instructionProgram = instruction.program;
        const instructionType = instruction.parsed.type;
        if (instructionProgram === 'spl-token' && instructionType === 'transfer') {
          return true;
        }
        return false;
      },
    );

    // Swaps can have multiple transfers happening in a single transaction,
    // we only need the first and last ones - the instruction withdrawing the original asset and the instruction depositing the swapped asset.
    const mainSwapTxnInstructions = [
      swapTransferInstructions[0],
      swapTransferInstructions[swapTransferInstructions.length - 1],
    ];

    const swapTransactionPromises = mainSwapTxnInstructions.map(
      // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
      // eslint-disable-next-line wallet/no-anonymous-params
      async (instruction) => {
        if (!instruction?.parsed?.info) return;
        const amount = instruction.parsed.info.amount;
        const authority = instruction.parsed.info.authority;
        const source = instruction.parsed.info.source;
        const destination = instruction.parsed.info.destination;

        const indexOfAccountKey: number = accountKeys.findIndex(
          (accountKey: { pubkey: string }) => accountKey.pubkey === destination,
        );

        const index = postTokenBalances.findIndex(
          (pt: { accountIndex: number }) => pt.accountIndex === indexOfAccountKey,
        );

        const postTokenBalance = postTokenBalances[index];
        if (!postTokenBalance) return;
        const mint = postTokenBalance.mint;
        const decimals = postTokenBalance.uiTokenAmount.decimals;

        let splToken = await getSPL(mint, SolanaNetworkMap.whitelisted.SOLANA_MAINNET.chainId);

        if (!splToken) {
          // This is to avoid a case where we do not have WSOL or any other asset info stored in the SPL table locally.
          // TODO this should be returned in the top level async call
          const SPLResult = await Promise.resolve(getSPLInfos([mint]));
          if (!SPLResult.tokens) return;

          await saveSPLTokens({
            splsInfoDTO: SPLResult,
            chainId: SolanaNetworkMap.whitelisted.SOLANA_MAINNET.chainId,
          });
          splToken = await getSPL(mint, SolanaNetworkMap.whitelisted.SOLANA_MAINNET.chainId);
          if (!splToken) return;
        }
        const transferInstruction: SPLTransferCheckedInstruction = {
          parsed: {
            info: {
              authority,
              destination,
              mint,
              source,
              tokenAmount: {
                amount,
                decimals: BigInt(decimals),
              },
            },
          },
        };

        return Promise.resolve(
          formatSPLTransaction({
            blockTime: result.blockTime,
            fee: BigInt(result.meta.fee),
            signature,
            transferInstruction,
            mainAccountAddress,
            splToken,
            mintAddress: mint,
            walletIndex,
            accountId,
          }),
        );
      },
    );

    const swapTransactions = (await Promise.all(swapTransactionPromises)).filter(
      // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
      // eslint-disable-next-line wallet/no-anonymous-params
      (transaction) => {
        if (!transaction) return false;
        return true;
      },
    ) as TxOrUserOp[];

    // there should be exactly two transactions - send the original asset and receive the swapped asset
    return swapTransactions.length === 2 ? swapTransactions : undefined;
  } catch (error) {
    const err = coerceError(error, 'api');
    cbReportError({
      error: err,
      context: 'solana-error',
      severity: 'error',
      isHandled: false,
    });
    return undefined;
  }
}

type SOLTransferInstruction = {
  parsed: {
    info: {
      source: string;
      destination: string;
      lamports: bigint;
    };
    type: string;
  };
};

export async function formatSOLTransaction({
  blockTime,
  fee,
  signature,
  transferInstruction,
  mainAccountAddress,
  walletIndex,
  accountId,
}: {
  blockTime: string;
  fee: bigint;
  signature: string;
  transferInstruction: SOLTransferInstruction;
  mainAccountAddress: string;
  walletIndex: bigint;
  accountId: Account['id'];
}): Promise<TxOrUserOp | undefined> {
  try {
    const date = unixTimestampToDate(blockTime);
    const fees = BigInt(fee);
    const source = transferInstruction.parsed.info.source;
    const destination = transferInstruction.parsed.info.destination;
    const isSent = source === mainAccountAddress;
    const amount = BigInt(transferInstruction.parsed.info.lamports);
    const blockchain = SolanaWalletConfiguration.blockchain;
    const currencyCode = SolanaWalletConfiguration.currencyCode;
    const network = asNetwork(SolanaNetworkMap.whitelisted.SOLANA_MAINNET);
    const walletId = Wallet.generateId({
      blockchain,
      currencyCode,
      network,
      contractAddress: undefined,
      selectedIndex: walletIndex,
      accountId,
    });

    const [destinationDomain, sourceDomain] = await fetchDomains([destination, source]);

    return new TxOrUserOp({
      id: uuidv4(),
      createdAt: date,
      confirmedAt: date,
      blockchain,
      currencyCode,
      feeCurrencyCode: currencyCode,
      feeCurrencyDecimal: SolanaWalletConfiguration.decimals,
      toAddress: destination,
      toDomain: destinationDomain,
      fromAddress: source,
      fromDomain: sourceDomain,
      amount,
      fee: fees,
      state: TxState.CONFIRMED,
      metadata: new TxOrUserOpMetadata(),
      network,
      txOrUserOpHash: signature,
      userOpHash: undefined,
      walletIndex,
      accountId,
      txHash: signature,
      isSent,
      tokenName: SolanaWalletConfiguration.displayName(),
      tokenDecimal: SOLANA_CURRENCY_DECIMAL,
      walletId,
    });
  } catch (error) {
    const err = coerceError(error, 'api');
    cbReportError({
      error: err,
      context: 'solana-error',
      severity: 'error',
      isHandled: false,
    });
    return undefined;
  }
}

type SPLTransferCheckedInstruction = {
  parsed: {
    info: {
      authority: string;
      destination: string;
      mint: string;
      source: string;
      tokenAmount?: {
        amount: string;
        decimals: bigint;
      };
      amount?: string | undefined;
    };
  };
};

async function formatSPLTransaction({
  blockTime,
  fee,
  signature,
  transferInstruction,
  mainAccountAddress,
  splToken,
  mintAddress,
  walletIndex,
  accountId,
}: {
  blockTime: string;
  fee: bigint;
  signature: string;
  transferInstruction: SPLTransferCheckedInstruction;
  mainAccountAddress: string;
  splToken: SPL;
  mintAddress: string;
  walletIndex: bigint;
  accountId: Account['id'];
}): Promise<TxOrUserOp | undefined> {
  try {
    const date = unixTimestampToDate(blockTime);
    const fees = BigInt(fee);
    const source = transferInstruction.parsed.info.source;
    const destination = transferInstruction.parsed.info.destination;
    const amount = transferInstruction.parsed.info.tokenAmount
      ? BigInt(transferInstruction.parsed.info.tokenAmount.amount)
      : BigInt(transferInstruction.parsed.info.amount ?? 0n);
    const amountDecimals = splToken.decimals;
    const authorityAddress = transferInstruction.parsed.info.authority;
    const isSent = mainAccountAddress === authorityAddress;
    const blockchain = SolanaBlockchain;
    const currencyCode = splToken.currencyCode;
    const network = asNetwork(SolanaNetworkMap.whitelisted.SOLANA_MAINNET);
    const walletId = Wallet.generateId({
      blockchain,
      currencyCode,
      network,
      contractAddress: mintAddress,
      selectedIndex: walletIndex,
      accountId,
    });

    const [destinationDomain, sourceDomain] = await fetchDomains([destination, source]);

    return new TxOrUserOp({
      id: uuidv4(),
      createdAt: date,
      confirmedAt: date,
      blockchain,
      currencyCode,
      feeCurrencyCode: SolanaWalletConfiguration.currencyCode,
      feeCurrencyDecimal: SolanaWalletConfiguration.decimals,
      toAddress: destination,
      toDomain: destinationDomain,
      fromAddress: source,
      fromDomain: sourceDomain,
      amount,
      fee: fees,
      state: TxState.CONFIRMED,
      metadata: new TxOrUserOpMetadata(),
      network,
      accountId,
      walletIndex,
      txOrUserOpHash: signature,
      userOpHash: undefined,
      txHash: signature,
      isSent,
      contractAddress: mintAddress,
      tokenName: splToken.name,
      tokenDecimal: amountDecimals,
      walletId,
    });
  } catch (error) {
    const err = coerceError(error, 'api');
    cbReportError({
      error: err,
      context: 'solana-error',
      isHandled: false,
      severity: 'error',
    });
    return undefined;
  }
}

/* Returns a promise of transaction array associated with the signature.
 * The transaction array returned consists of a single send or receive transaction if the signature is of a simple token transfer.
 * It consists of 2 transactions - a send and a receive, if the signature is of a swap transaction.
 */

type GetTransactionParams = {
  signature: string;
  mainAccountAddress: string;
  walletIndex: bigint;
  accountId: Account['id'];
  commitment?: SolanaCommitmentLevel;
};

export async function getTransaction({
  signature,
  mainAccountAddress,
  walletIndex,
  accountId,
  commitment = DEFAULT_COMMITMENT_LEVEL,
}: GetTransactionParams) {
  const result = await getTransactionRPC(signature, commitment, SOLANA_RPC_URL);
  if (!result) {
    return undefined;
  }
  return formatTransactionFromFetch({
    result,
    signature,
    mainAccountAddress,
    walletIndex,
    accountId,
  });
}

export type SolanaTransactionReceipt = {
  isSuccessful: boolean;
};

export async function getTransactionReceipts(
  signatures: string[],
): Promise<(SolanaTransactionReceipt | null)[]> {
  const result = await getSignatureStatuses({ signatures, rpcUrl: SOLANA_RPC_URL });

  if (!result?.length) return [];

  return result.map(function parseTxStatus(txStatus) {
    if (!txStatus) {
      return null;
    }

    return {
      isSuccessful: !txStatus.err,
    };
  });
}

import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { useOnMount } from 'cb-wallet-data/hooks/useOnMount';
import { useProcessWalletConnectionResponse } from 'cb-wallet-data/stores/Accounts/hooks/useProcessWalletConnectionResponse';
import {
  buildWalletConnectionAccountResponse,
  WalletConnectionResponse,
} from 'cb-wallet-data/stores/Accounts/models/WalletConnectionResponse';
import { base } from 'viem/chains';
import { hexFromBigInt } from 'wallet-engine-signing/blockchains/Ethereum/formatNumbers';

import { WalletConnectorConnectResponse } from '../types';
import { COINBASE_WALLET } from '../wallet-providers/coinbase-wallet';

import { getAvailableNetworks } from './useAvailableNetworks';
import { useConnectWallet } from './useConnectWallet';
import { useGetEnabledWalletProviders } from './useGetEnabledWalletProviders';

/**
 * This hook is used to auto connect to Coinbase Wallet when user open the dapp in dapp browser
 */
export function useAutoConnectCoinbaseBrowser() {
  const connectWallet = useConnectWallet();
  const processWalletConnectionResponse = useProcessWalletConnectionResponse();
  const getEnabledWalletProviders = useGetEnabledWalletProviders();

  useOnMount(function handleDappBrowserAutoConnect() {
    // dont autoconnet if wallet provider is killed
    if (!getEnabledWalletProviders(COINBASE_WALLET.id)) {
      return;
    }
    // Only AutoConnect Coinbase Wallet Dapp Browser
    if (!(window?.ethereum?.isCoinbaseWallet && window?.ethereum?.isCoinbaseBrowser)) {
      return;
    }

    const switchChain = async () => {
      try {
        // using window.etherum.request to switch chain.
        // This is safe because we are in coinbase wallet browser
        await window?.ethereum?.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: hexFromBigInt(BigInt(base.id)) }],
        });
      } catch (error) {
        // Do nothing if this fails
      }
    };

    (async function handleConnect() {
      try {
        const availableNetworks = await getAvailableNetworks(COINBASE_WALLET);
        const promises: Promise<WalletConnectorConnectResponse>[] = [];
        availableNetworks.forEach(async (network) => {
          promises.push(
            connectWallet({
              walletProvider: COINBASE_WALLET,
              network,
            }),
          );
        });

        const results = await Promise.all(promises);

        const ethAddresses = results.reduce((acc: Set<string>, { result }) => {
          if (!result?.account?.ETH?.length) return acc;
          result?.account?.ETH.forEach((address) => acc.add(address));
          return acc;
        }, new Set<string>());

        const solAddresses = results.reduce((acc: Set<string>, { result }) => {
          if (!result?.account?.SOL?.length) return acc;
          result?.account?.SOL.forEach((address) => acc.add(address));
          return acc;
        }, new Set<string>());

        const result: WalletConnectionResponse = {
          account: buildWalletConnectionAccountResponse({
            ethAddresses: Array.from(ethAddresses),
            solAddresses: Array.from(solAddresses),
          }),
          provider: COINBASE_WALLET.id,
        };

        await processWalletConnectionResponse(result);
        // Switch chains to Base. Temp workaround so dapp browser is not defaulted to Solana.
        await switchChain();
      } catch (error) {
        cbReportError({
          error: coerceError(error, 'dapp account creation'),
          context: 'dapp-account-creation',
          severity: 'error',
          isHandled: false,
        });
      }
    })();
  });
}

import { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useRecentSearches } from 'cb-wallet-data/stores/Search/hooks/useRecentSearches';
import { RecentSearch, SearchEntity } from 'cb-wallet-data/stores/Search/types';
import { SearchEntityTitle } from 'cb-wallet-data/stores/Search/util';
import { SettingCellAccessoryType } from 'cb-wallet-data/stores/SettingsRedesign/types';
import { convertWeiToEth } from 'cb-wallet-data/utils/CurrencyUtil';
import noop from 'lodash/noop';
import { useRouter } from 'next/router';
import { AssetAvatar } from 'wallet-cds-web/components/AssetAvatar';
import { DAppAvatar } from 'wallet-cds-web/components/DAppAvatar';
import { ProfileAvatar } from 'wallet-cds-web/components/ProfileAvatar';
import { CellAccessoryType } from '@cbhq/cds-common';
import { UiIconName } from '@cbhq/cds-icons';
import { ListCell } from '@cbhq/cds-web/cells';
import { Icon } from '@cbhq/cds-web/icons';

import { PercentChangeText } from ':dapp/components/Search/PercentChangeText';
import { logDappClickedFromGlobalSearch } from ':dapp/pages/apps/[id]/events';
import { useSearchContext } from ':dapp/providers/SearchProvider';
import { RoutesEnum } from ':dapp/utils/RoutesEnum';

const SearchResultMediaMap = {
  [SearchEntity.SEARCH_ENTITY_ASSET]: AssetAvatar,
  [SearchEntity.SEARCH_ENTITY_ENS]: ProfileAvatar,
  [SearchEntity.SEARCH_ENTITY_DAPP]: DAppAvatar,
  [SearchEntity.SEARCH_ENTITY_NFT_COLLECTIONS]: DAppAvatar,
};

type SearchResultMediaType = keyof typeof SearchResultMediaMap;

type SearchResultMediaProps<T extends SearchResultMediaType> = React.ComponentProps<
  (typeof SearchResultMediaMap)[T]
>;

export type SearchResultProps<T> = {
  id?: string;
  title: string;
  description?: string;
  detail?: string;
  mediaProps: T extends SearchResultMediaType ? SearchResultMediaProps<T> : undefined;
  key: string;
  navigationPath?: string;
  iconName?: UiIconName;
  onPress?: (options?: Record<string, never> | undefined) => void;
  accessory?: CellAccessoryType | SettingCellAccessoryType;
  updateRecentSearches?: (searchItem: RecentSearch<SearchEntity>) => void;
  isUnownedToken?: boolean;
  searchEntity: SearchEntity;
};

const AssetsSearchResult = memo(function AssetsSearchResult({
  title,
  description,
  detail,
  mediaProps,
  navigationPath,
  updateRecentSearches,
  isUnownedToken,
}: SearchResultProps<SearchEntity.SEARCH_ENTITY_ASSET>) {
  const MediaComponent = SearchResultMediaMap[SearchEntity.SEARCH_ENTITY_ASSET];
  const router = useRouter();
  const { toggleSearchOff } = useSearchContext();

  const onPress = useCallback(() => {
    const recentSearchItem = {
      key: `${SearchEntity.SEARCH_ENTITY_ASSET}-${title}`,
      assetName: title,
      assetImageSrc: mediaProps.assetSrc,
      searchEntity: SearchEntity.SEARCH_ENTITY_ASSET,
      navigationPath,
    };
    if (navigationPath) {
      updateRecentSearches?.(recentSearchItem);
      router.push(navigationPath);
      toggleSearchOff();
    }
  }, [title, mediaProps.assetSrc, navigationPath, updateRecentSearches, router, toggleSearchOff]);

  const formattedDescription = useMemo(() => {
    return `${SearchEntityTitle.ASSET} • ${description}`;
  }, [description]);

  const formattedDetail = useMemo(() => {
    if (isUnownedToken && detail?.length) {
      return detail !== '0' ? <PercentChangeText percentChange={Number(detail)} /> : null;
    }
    return detail;
  }, [detail, isUnownedToken]);

  return (
    <ListCell
      title={title}
      description={formattedDescription}
      detail={formattedDetail}
      media={<MediaComponent {...mediaProps} />}
      onPress={onPress}
    />
  );
});

const ENSSearchResult = memo(function ENSSearchResult({
  title,
  detail,
  mediaProps,
}: SearchResultProps<SearchEntity.SEARCH_ENTITY_ENS>) {
  const MediaComponent = SearchResultMediaMap[SearchEntity.SEARCH_ENTITY_ENS];

  /**
   * @TODO Maybe customize the onPress callback here
   * @see https://jira.coinbase-corp.com/browse/WALL-27456
   */
  const onPress = noop;

  return (
    <ListCell
      title={title}
      description={SearchEntityTitle.Profile}
      detail={detail}
      media={<MediaComponent {...mediaProps} />}
      onPress={onPress}
    />
  );
});

const NFTCollectionsSearchResult = memo(function NFTCollectionsSearchResult({
  title,
  description,
  detail,
  mediaProps,
  navigationPath,
  onPress,
  updateRecentSearches,
}: SearchResultProps<SearchEntity.SEARCH_ENTITY_NFT_COLLECTIONS>) {
  const { formatNumber } = useIntl();
  const MediaComponent = SearchResultMediaMap[SearchEntity.SEARCH_ENTITY_NFT_COLLECTIONS];
  const { toggleSearchOff } = useSearchContext();
  const router = useRouter();

  const formattedDetail = useMemo(() => {
    const priceInEth = convertWeiToEth(BigInt(detail ?? 0));
    if (Number(priceInEth) < 0.0001) return '<0.0001 ETH';
    return `${priceInEth.slice(0, 6)} ETH`;
  }, [detail]);

  const formattedDescription = useMemo(() => {
    return `${SearchEntityTitle.NFT_COLLECTIONS} • ${formatNumber(Number(description), {
      notation: 'compact',
    })}`;
  }, [description, formatNumber]);

  const handlePress = useCallback(
    function handlePress() {
      onPress?.();

      if (navigationPath) {
        const recentSearchItem = {
          key: `${SearchEntity.SEARCH_ENTITY_NFT_COLLECTIONS}-${title}`,
          assetName: title,
          assetImageSrc: mediaProps.src,
          searchEntity: SearchEntity.SEARCH_ENTITY_NFT_COLLECTIONS,
          navigationPath,
        };
        updateRecentSearches?.(recentSearchItem);
        router.push(navigationPath);
        toggleSearchOff();
      }
    },
    [onPress, navigationPath, title, mediaProps.src, updateRecentSearches, router, toggleSearchOff],
  );

  return (
    <ListCell
      title={title}
      description={formattedDescription}
      detail={formattedDetail}
      media={<MediaComponent {...mediaProps} />}
      onPress={handlePress}
    />
  );
});

const DAppSearchResult = memo(function DAppSearchResult({
  id,
  title,
  description,
  detail,
  mediaProps,
  navigationPath,
  updateRecentSearches,
}: SearchResultProps<SearchEntity.SEARCH_ENTITY_DAPP>) {
  const MediaComponent = SearchResultMediaMap[SearchEntity.SEARCH_ENTITY_DAPP];
  const { toggleSearchOff } = useSearchContext();
  const { push } = useRouter();
  const onPress = useCallback(() => {
    if (navigationPath) {
      const recentSearchItem = {
        id,
        key: `${SearchEntity.SEARCH_ENTITY_DAPP}-${title}`,
        assetName: title,
        assetImageSrc: mediaProps.src,
        searchEntity: SearchEntity.SEARCH_ENTITY_DAPP,
        navigationPath,
      };
      updateRecentSearches?.(recentSearchItem);
      toggleSearchOff();
      push(`${RoutesEnum.DAPPS}/${id}/${title}`);
      logDappClickedFromGlobalSearch(title);
    }
  }, [mediaProps.src, navigationPath, title, id, toggleSearchOff, updateRecentSearches, push]);

  const formattedDescription = useMemo(() => {
    return `${SearchEntityTitle.DAPP} • ${description}`;
  }, [description]);

  return (
    <ListCell
      title={title}
      description={formattedDescription}
      detail={detail}
      media={<MediaComponent {...mediaProps} />}
      onPress={onPress}
    />
  );
});

const SettingsSearchResult = memo(function SettingsSearchResult({
  title,
  description,
  iconName = 'globe',
  accessory,
  onPress,
}: SearchResultProps<SearchEntity.SEARCH_ENTITY_SETTING>) {
  const handlePress = useCallback(() => onPress?.(), [onPress]);

  const detail = useMemo(() => {
    return accessory === 'externalLink' ? (
      <Icon name={accessory} size="m" testID="settings-search-result-accessory" />
    ) : undefined;
  }, [accessory]);

  return (
    <ListCell
      title={title}
      description={description}
      detail={detail}
      detailWidth={25}
      media={<Icon name={iconName} size="m" />}
      onPress={handlePress}
      accessory={accessory as CellAccessoryType}
    />
  );
});

const KeyActionSearchResult = memo(function KeyActionSearchResult({
  title,
  description,
  iconName = 'globe',
  accessory,
  onPress,
}: SearchResultProps<SearchEntity.SEARCH_ENTITY_KEY_ACTION>) {
  const handlePress = useCallback(() => onPress?.(), [onPress]);

  return (
    <ListCell
      title={title}
      description={description}
      media={<Icon name={iconName} size="m" />}
      onPress={handlePress}
      accessory={accessory as CellAccessoryType}
    />
  );
});

const GenericResult = memo(function GenericResult<T extends SearchEntity>({
  title,
  description,
  detail,
}: SearchResultProps<T>) {
  /**
   * @TODO Maybe a generic onPress callback here
   * @see https://jira.coinbase-corp.com/browse/WALL-27456
   */
  const onPress = noop;

  return <ListCell title={title} description={description} detail={detail} onPress={onPress} />;
});

// Introduce this map under the consideration that we may have different logics for different search entities
// For example, they call different hooks to get onPress callback, or title, description, detail may render differently
// Once we implement most of the search entities, we can then decide if we need to remove this map and use the GenericResult component
const SearchResultComponentMap = {
  [SearchEntity.SEARCH_ENTITY_ASSET]: AssetsSearchResult,
  [SearchEntity.SEARCH_ENTITY_ENS]: ENSSearchResult,
  [SearchEntity.SEARCH_ENTITY_UNSPECIFIED]: GenericResult,
  [SearchEntity.SEARCH_ENTITY_DAPP]: DAppSearchResult,
  [SearchEntity.SEARCH_ENTITY_DEFI]: GenericResult,
  [SearchEntity.SEARCH_ENTITY_LEARN_ARTICLES]: GenericResult,
  [SearchEntity.SEARCH_ENTITY_NFT]: GenericResult,
  [SearchEntity.SEARCH_ENTITY_NFT_COLLECTIONS]: NFTCollectionsSearchResult,
  [SearchEntity.SEARCH_ENTITY_WALLET]: GenericResult,
  [SearchEntity.SEARCH_ENTITY_ENS_NAME]: GenericResult,
  [SearchEntity.SEARCH_ENTITY_QUERY]: GenericResult,
  [SearchEntity.SEARCH_ENTITY_TRANSACTION]: GenericResult,
  [SearchEntity.SEARCH_ENTITY_KEY_ACTION]: KeyActionSearchResult,
  [SearchEntity.SEARCH_ENTITY_SETTING]: SettingsSearchResult,
};

export const SearchResult = memo(function SearchResult<T extends SearchEntity>({
  searchEntity,
  ...props
}: SearchResultProps<T> & { searchEntity: T }) {
  const SearchResultComponent = SearchResultComponentMap[searchEntity];
  const { updateRecentSearches } = useRecentSearches();

  if (SearchResultComponent === undefined) return null;

  return (
    <SearchResultComponent
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...(props as any)}
      searchEntity={searchEntity}
      updateRecentSearches={updateRecentSearches}
    />
  );
});

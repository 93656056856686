import { Suspense } from 'react';
import { Provider as JotaiProvider } from 'jotai';
import { RecoilRoot } from 'recoil';

import { QueryProvider } from './QueryProvider';

type ProvidersProps = {
  children: React.ReactNode;
  fallback?: React.ReactNode;
};

export function DataProvider({ children, fallback }: ProvidersProps): JSX.Element {
  return (
    <QueryProvider>
      <RecoilRoot>
        <JotaiProvider>
          <Suspense fallback={fallback ?? null}>{children}</Suspense>
        </JotaiProvider>
      </RecoilRoot>
    </QueryProvider>
  );
}

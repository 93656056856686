import { createNewSpan, logTrace, Span, Trace, updateSpan } from '@cbhq/client-analytics';

import { TraceName, TracerInterface } from '../types';

import { TracerUtils } from './TracerUtils';

/**
 * EnabledTracer
 *
 * Class that supports tracing on the FE. Over time, additional functionality
 * will be supported.
 *
 * - Logs duration for 1 function, with a breakdown for nested steps
 *
 * - Provides a visual breakdown on Datadog
 *
 * - Integrates with client-analytics (https://frontend.cbhq.net/analytics/tracing)
 *
 * - Uses performance.now() to get the current system time
 *
 * Instructions
 * 1. Add new trace name to traceNames for TraceName type.
 * 2. If this is in a React Hook, use useTracer. If it's in a function, use this
 * Tracer class.
 *
 * TODO [Tracing]
 *   - [WALL-27789] Add documentation
 *   - [WALL-27552] Create test mock helpers
 */
export class EnabledTracer implements TracerInterface {
  private spansByName: Record<Span['name'], Span>;
  private trace: Trace;
  private rootSpan: Span;
  private isDone: boolean;

  /**
   * constructor
   *
   * Creates an instance of Tracer
   */
  constructor(traceName: TraceName) {
    this.trace = [];
    this.spansByName = {};
    this.isDone = false;

    // creates root span
    this.rootSpan = this.startRootSpan(traceName);
  }

  /**
   * startRootSpan
   *
   * Creates a new rawSpan if spanName has no conflict
   */
  private startRootSpan(spanName: string): Span {
    return this.startSpanHelper({ spanName, isRootSpan: true });
  }

  /**
   * startSpanHelper
   *
   * Creates a new rawSpan if spanName has no conflict
   *
   * NOTE When the span is created, client-analytics will always convert times to
   * nanoseconds by multiplying by 1_000_000. Be mindful when performing operations,
   * eg: calculating duration, to match up the unit of time.
   */
  private startSpanHelper({
    spanName,
    isRootSpan = false,
  }: {
    spanName: string;
    isRootSpan?: boolean;
  }): Span {
    const traceId = isRootSpan ? undefined : this.rootSpan.trace_id;
    const parentId = isRootSpan ? undefined : this.rootSpan.span_id;

    const span: Span = createNewSpan({
      name: isRootSpan ? spanName : this.rootSpan.name, // for the rootSpan, this is the same as name
      resource: spanName,
      traceId,
      parentId,
      start: Math.round(performance.now()),
      duration: 0, // to be updated later in endSpan
    });

    this.trace.push(span);
    this.spansByName[spanName] = span;

    return span;
  }

  /**
   * endRootSpan
   */
  private endRootSpan() {
    this.endSpan(this.rootSpan.name);
  }

  /**
   * reportError
   */
  private reportError(errorMessage: string) {
    const error = new Error(errorMessage);
    TracerUtils.reportError({
      error,
      metadata: {
        rootSpanName: this.rootSpan.name,
      },
    });
  }

  /**
   * startSpan
   *
   * Creates a new rawSpan if spanName has no conflict
   */
  startSpan(spanName: string): Span | undefined {
    if (this.spansByName[spanName]) {
      // since tracing should not block features and functionality, silently
      // report error
      this.reportError(`Cannot start span due to name conflict: ${spanName}`);
      return;
    }

    return this.startSpanHelper({ spanName });
  }

  /**
   * endSpan
   *
   * Ends a rawSpan if found
   */
  endSpan(spanName: Span['name']): Span | undefined {
    const span = this.spansByName[spanName];

    if (!span) {
      // since tracing should not block features and functionality, silently
      // report error
      this.reportError(`Cannot end span because it's not found: ${spanName}`);
      return;
    }

    return TracerUtils.updateSpanDuration(span);
  }

  /**
   * setMetadata
   *
   * Sets setMetadata for a span. If no spanName is provided, sets Metadata
   * for root span.
   *
   * It will overwrite existing keys and append new key-values, in this fashion:
   * `{ ...existingMeta, ...meta }`.
   *
   * A span should be created through startSpan before setMetadata can be called
   * for it.
   *
   * After a span is created, as long as it is before the trace is logged to the
   * BE through #done, this method can update the metadata for a span. There is
   * no limit to how many times the metadata can be set.
   */
  setMetadata({
    spanName = this.rootSpan.name,
    metadata,
  }: {
    spanName?: Span['name'];
    metadata: Span['meta'];
  }): Span | undefined {
    const span = this.spansByName[spanName];

    if (!span) {
      // since tracing should not block features and functionality, silently
      // report error
      this.reportError(`Cannot set meta for span because it's not found: ${spanName}`);
      return;
    }

    updateSpan(span, { meta: metadata });

    return span;
  }

  /**
   * done
   *
   * Ends raw root span, logs trace
   */
  done(): undefined {
    if (this.isDone) {
      return undefined;
    }

    this.isDone = true;

    this.endRootSpan();
    logTrace(this.trace);

    return undefined;
  }
}

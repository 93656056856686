import { ActionType, ComponentType, logMetric, MetricType } from '@cbhq/client-analytics';

import { logDataEvent, logEvent, useEventLogger, useLogEventOnMount } from '../utils/log';

import { CollectibleOfferDetailProps } from './CollectibleOfferDetail';

export function triggerCollectibleLinkClicked(title: string) {
  logEvent('nft_detail_link_clicked', {
    loggingId: '98ef386e-3145-4131-9429-f68ed4a53cb7',
    action: ActionType.click,
    type: title,
    componentType: ComponentType.button,
  });
}

export function logCollectibleVerifierClicked({
  contractAddress,
  chainId,
  tokenId,
}: CollectionParams) {
  logEvent('nft_detail.verifier_btn.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
    contractAddress,
    chainId,
    tokenId,
  });
}

export function logCollectibleQRCodeClicked({
  contractAddress,
  chainId,
  tokenId,
}: CollectionParams) {
  logEvent('nft_detail.qrcode_btn.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
    contractAddress,
    chainId,
    tokenId,
  });
}

export function useCollectibleDetailVerifyViewed({
  contractAddress,
  chainId,
  tokenId,
  status,
}: CollectionParams) {
  useLogEventOnMount('nft_detail_verify_ticket_viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
    contractAddress,
    chainId,
    tokenId,
    status,
  });
}

export function useCollectibleDetailQRCodeViewed(message: string) {
  useLogEventOnMount('nft_detail_qr_code_viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
    message,
  });
}

export function useCollectibleDetailViewed(title: string) {
  useLogEventOnMount('nft_detail_viewed', {
    loggingId: '62216e73-0c17-4c9a-a903-a000e196fe69',
    action: ActionType.view,
    name: title,
    componentType: ComponentType.page,
  });
}

type UseLogCollectibleDetailViewedProps = {
  title: string;
  entryPoint: string;
};

export function useLogCollectibleDetailViewed({
  title,
  entryPoint,
}: UseLogCollectibleDetailViewedProps) {
  return useEventLogger('nft_detail_viewed', {
    loggingId: '62216e73-0c17-4c9a-a903-a000e196fe69',
    action: ActionType.view,
    name: title,
    componentType: ComponentType.page,
    entryPoint,
  });
}

export function useLogCollectibleDetailPopuptrayViewed() {
  return useEventLogger('nft_detail_popup_tray_viewed', {
    loggingId: 'd7b734f1-706d-48d1-b75f-9949beae1cf0',
    action: ActionType.view,
    componentType: ComponentType.tray,
  });
}

export function useCollectibleDetailBackClicked() {
  return useEventLogger('nft_detail_back_clicked', {
    loggingId: 'beb3c44e-a238-48a9-b24c-e0acde67a632',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function useCollectibleDetailRefreshClicked() {
  return useEventLogger('nft_detail_refresh_clicked', {
    loggingId: 'bce4139c-452c-4bdb-86a3-72821026560a',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function useCollectibleDetailRefreshErrored() {
  return useEventLogger('nft_detail_refresh_error', {
    loggingId: '91704fc7-dd05-48e9-a90b-aa1d72e7c8ff',
    action: ActionType.unknown,
    componentType: ComponentType.unknown,
  });
}

export type CollectibleCacheProvider = 'cloudinary' | 'cloudfront';

type CollectibleMediaLoadProps = {
  chainId: string;
  mediaType: string;
  elapsedTime: number;
  contractAddress?: string;
};

// intentionally limiting the loggable media types
function getMediaTypeEnum(mediaType: string) {
  if (mediaType?.startsWith('image')) {
    return 'image';
  }

  if (mediaType?.startsWith('audio')) {
    return 'audio';
  }

  if (mediaType?.startsWith('video')) {
    return 'video';
  }
  return '';
}

function logCollectibleMediaLoadTime({
  chainId,
  mediaType,
  elapsedTime,
  fromMediaCache,
}: CollectibleMediaLoadProps & { fromMediaCache: boolean }) {
  logMetric({
    metricName: 'nft_media_render_time',
    metricType: MetricType.distribution,
    value: elapsedTime,
    tags: {
      chainId,
      mediaType: getMediaTypeEnum(mediaType).toString(),
      fromMediaCache: String(fromMediaCache),
    },
  });
}

export function logPerceivedCollectibleMediaLoadTime({
  chainId,
  elapsedTime,
}: Pick<CollectibleMediaLoadProps, 'chainId' | 'elapsedTime'>) {
  logMetric({
    metricName: 'wallet.nft_media_perceived_render_time',
    metricType: MetricType.distribution,
    value: elapsedTime,
    tags: {
      chainId,
    },
  });
}

export function logCollectibleCachedMediaLoadSuccessEvent({
  chainId,
  mediaType,
  elapsedTime,
}: CollectibleMediaLoadProps & { cacheProvider: CollectibleCacheProvider }) {
  logMetric({
    metricName: 'nft_cached_media_load_success',
    metricType: MetricType.count,
    value: 1,
    tags: {
      chainId,
      mediaType: getMediaTypeEnum(mediaType).toString(),
    },
  });
  const logLoadTimeProps = {
    chainId,
    mediaType,
    elapsedTime,
    fromMediaCache: true,
  };

  logCollectibleMediaLoadTime(logLoadTimeProps);
}

export function logCollectibleMediaLoadSuccessEvent({
  chainId,
  mediaType,
  elapsedTime,
}: CollectibleMediaLoadProps) {
  logMetric({
    metricName: 'nft_media_load_success',
    metricType: MetricType.count,
    value: 1,
    tags: {
      chainId,
      mediaType: getMediaTypeEnum(mediaType).toString(),
    },
  });
  const logLoadTimeProps = {
    chainId,
    mediaType,
    elapsedTime,
    fromMediaCache: false,
  };
  logCollectibleMediaLoadTime(logLoadTimeProps);
}

export function logCollectibleCachedMediaLoadFailureEvent({
  chainId,
  mediaType,
}: CollectibleMediaLoadProps) {
  logMetric({
    metricName: 'nft_cached_media_load_failure',
    metricType: MetricType.count,
    value: 1,
    tags: {
      chainId,
      mediaType: getMediaTypeEnum(mediaType).toString(),
    },
  });
}

export function logCollectibleMediaLoadFailureEvent({
  chainId,
  mediaType,
  elapsedTime,
  contractAddress,
}: CollectibleMediaLoadProps) {
  const tags = {
    chainId,
    mediaType: getMediaTypeEnum(mediaType).toString(),
  };

  logMetric({
    metricName: 'nft_media_load_failure',
    metricType: MetricType.count,
    value: 1,
    tags,
  });

  // Normally we'd only need to log only the metric, but we do want segmentation
  // by contract address which has very high cardinality. Logging contract address
  // in Amplitude rather than Datadog prevents Datadog costs from ballooning.
  logDataEvent('nft_media_load_failure', {
    loggingId: '7d86b88b-8956-4176-9465-94395963d299',
    action: ActionType.render,
    componentType: ComponentType.card,
    ...tags,
    elapsedTime,
    contractAddress: contractAddress || '',
  });
}

export function logCollectibleCachedMediaUnsupportedEvent({
  chainId,
  mediaType,
  elapsedTime,
}: CollectibleMediaLoadProps) {
  logMetric({
    metricName: 'nft_cached_media_unsupported',
    metricType: MetricType.count,
    value: 1,
    tags: {
      chainId,
      mediaType: getMediaTypeEnum(mediaType).toString(),
      elapsedTime: elapsedTime.toString(),
    },
  });
}

type CollectibleMediaMissingProps = CollectibleMediaLoadProps & {
  hasUnretrievableMetadata?: boolean;
};

export function logCollectibleMediaMissingEvent({
  chainId,
  mediaType,
  elapsedTime,
  contractAddress,
  hasUnretrievableMetadata = false,
}: CollectibleMediaMissingProps) {
  const tags = {
    chainId,
    mediaType: getMediaTypeEnum(mediaType).toString(),
    hasUnretrievableMetadata: String(hasUnretrievableMetadata),
  };
  logMetric({
    metricName: 'nft_media_missing',
    metricType: MetricType.count,
    value: 1,
    tags,
  });

  // Normally we'd only need to log only the metric, but we do want segmentation
  // by contract address which has very high cardinality. Logging contract address
  // in Amplitude rather than Datadog prevents Datadog costs from ballooning.
  return logEvent('nft_media_missing', {
    loggingId: '7748f6bc-cc2c-4ceb-a979-82b053975c47',
    action: ActionType.render,
    componentType: ComponentType.card,
    ...tags,
    elapsedTime,
    contractAddress: contractAddress || '',
    hasUnretrievableMetadata,
  });
}

export function logCollectibleMediaUnsupportedEvent({
  chainId,
  mediaType,
}: CollectibleMediaLoadProps) {
  logMetric({
    metricName: 'nft_media_unsupported',
    metricType: MetricType.count,
    value: 1,
    tags: {
      chainId,
      mediaType: getMediaTypeEnum(mediaType).toString(),
    },
  });
}

export function useCollectibleDetailHistoryTabClicked() {
  return useEventLogger('nft_detail.history_tab.clicked', {
    loggingId: '0cbc19e0-fe0b-4ecf-9084-146f63846bc5',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function useCollectibleDetailHistoryTabViewed() {
  useLogEventOnMount('nft_detail.history_tab.viewed', {
    loggingId: '5bf73b58-fad8-405c-b37d-0de288a1c7a0',
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function useCollectibleDetailHistoryErrorViewed() {
  useLogEventOnMount('nft_detail.history_tab.error.viewed', {
    loggingId: '130dd8e2-ac41-40d0-b269-cb9387ac63a7',
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function useCollectibleDetailAboutTabClicked() {
  return useEventLogger('nft_detail.about_tab.clicked', {
    loggingId: '2719c4b4-0388-4a48-8d59-5142d082dc61',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function useCollectibleDetailAboutTabViewed() {
  useLogEventOnMount('nft_detail.about_tab.viewed', {
    loggingId: '676e6b51-bcd9-4bda-a085-42c45b12084b',
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function useCollectibleDetailOffersTabClicked() {
  return useEventLogger('nft_detail.reservoir_offers_tab.clicked', {
    loggingId: 'a8eba597-9474-4e80-812f-2dbc4b179a24',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function useCollectibleDetailOffersTabViewed() {
  useLogEventOnMount('nft_detail.reservoir_offers_tab.viewed', {
    loggingId: 'f4d25f6a-9967-4d6c-a6e5-b58342b4243e',
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function useCollectibleDetailOffersErrorViewed() {
  useLogEventOnMount('nft_detail.reservoir_offers_tab.error.viewed', {
    loggingId: '510fb2ad-d4e7-41d5-8187-236998202113',
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function useCollectibleDetailReservoirOffersClick() {
  return useEventLogger('nft_detail.reservoir_offer.clicked', {
    loggingId: '857e58f0-c66d-4242-83a6-a66e2ed12d99',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function useCollectibleOfferExpiredViewed({
  entryPoint = '',
  contractAddress = '',
  tokenId = '',
  chainId = '',
  offerPlatform = '',
  offerPaymentTokenSymbol = '',
  offerAmount = '',
  makerAddress = '',
}: CollectibleOfferDetailProps) {
  useLogEventOnMount('nft_offer_detail.expired.viewed', {
    loggingId: 'ac759419-1410-432e-865f-c0b160e0d1db',
    action: ActionType.view,
    componentType: ComponentType.page,
    entryPoint,
    contractAddress,
    tokenId,
    chainId,
    offerPlatform,
    offerPaymentTokenSymbol,
    offerAmount,
    makerAddress,
  });
}

export function useCollectibleDetailOpeninClicked() {
  return useEventLogger('nft_detail.openin.clicked', {
    loggingId: '4d2cb5b8-b572-4e37-bd54-03bf30874509',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function useCollectibleDetailHistoryTabEmptyViewed() {
  useLogEventOnMount('nft_detail.history_tab.empty.viewed', {
    loggingId: 'a946a08a-3054-4998-8d74-4d357e59686f',
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function useCollectibleDetailOffersTabEmptyViewed() {
  useLogEventOnMount('nft_detail.reservoir_offers_tab.empty.viewed', {
    loggingId: 'ddb1df04-49ca-44e5-9c23-a0faf7ef2f61',
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

type CollectionParams = {
  context?: string;
  contractAddress?: string;
  chainId?: string;
  tokenId?: string;
  marketplace?: string;
  offchainOrderId?: string;
  status?: 'Success' | 'Failure';
};

export function useCollectionDetailViewed({ contractAddress, chainId }: CollectionParams) {
  useLogEventOnMount('collection_detail.viewed', {
    loggingId: '166473f3-fbbb-4730-aedc-00bcb36a00ed',
    action: ActionType.view,
    contractAddress,
    chainId,
    componentType: ComponentType.page,
  });
}

export function logCollectionDetailsOpenInClicked({ contractAddress, chainId }: CollectionParams) {
  logEvent('collection_detail.open_in.clicked', {
    loggingId: '1542324b-09ad-43cb-a0bd-d6e3d3c48384',
    action: ActionType.click,
    componentType: ComponentType.button,
    contractAddress,
    chainId,
  });
}

export function logCollectionDetailsCopyAddressClicked({
  contractAddress,
  chainId,
}: CollectionParams) {
  logEvent('collection_detail.copy_address.clicked', {
    loggingId: '68774471-38a2-4620-8276-d01a56bf1bf0',
    action: ActionType.click,
    componentType: ComponentType.button,
    contractAddress,
    chainId,
  });
}

export function useCollectionWalletTokensAllViewed({ contractAddress, chainId }: CollectionParams) {
  useLogEventOnMount('collection_detail.wallet_tokens_all.viewed', {
    loggingId: 'b25de470-9c2a-4e15-9e75-4eebd0d753a4',
    action: ActionType.view,
    contractAddress,
    chainId,
    componentType: ComponentType.page,
  });
}

export function logCollectionDetailsWalletTokensSeeAllClicked({
  contractAddress,
  chainId,
}: CollectionParams) {
  logEvent('collection_detail.wallet_tokens.see_all.clicked', {
    loggingId: '46a589d6-6c9f-40af-96cc-12c136de9f78',
    action: ActionType.click,
    componentType: ComponentType.button,
    contractAddress,
    chainId,
  });
}

export function logCollectionDetailsListingsSeeAllClicked({
  contractAddress,
  chainId,
}: CollectionParams) {
  logEvent('collection_detail.listings.see_all.clicked', {
    loggingId: 'ada0c761-c44d-4295-a925-f8aeff9aecde',
    action: ActionType.click,
    componentType: ComponentType.button,
    contractAddress,
    chainId,
  });
}

export function logCollectionDetailsPreviewSeeAllClicked({
  contractAddress,
  chainId,
}: CollectionParams) {
  logEvent('collection_detail.preview.see_all.clicked', {
    loggingId: '9102dd20-7ce6-40fb-8567-c466f8385b9c',
    action: ActionType.click,
    componentType: ComponentType.button,
    contractAddress,
    chainId,
  });
}

export function logCollectionDetailsCreatorAddressClicked({
  contractAddress,
  chainId,
}: CollectionParams) {
  logEvent('collection_detail.copy_creator_address.clicked', {
    loggingId: '929b25a4-ba22-41c5-9c58-3a8a1b491522',
    action: ActionType.click,
    componentType: ComponentType.button,
    contractAddress,
    chainId,
  });
}

export function logCollectionDetailsCollectibleClicked({
  contractAddress,
  chainId,
  tokenId,
}: CollectionParams) {
  logEvent('collection_detail.collectible.clicked', {
    loggingId: 'e9fef53f-fc6c-4fc7-a780-9101116050fb',
    action: ActionType.click,
    componentType: ComponentType.card,
    contractAddress,
    chainId,
    tokenId,
  });
}

export function logCollectionDetailsListingClicked({
  contractAddress,
  chainId,
  tokenId,
  marketplace,
  offchainOrderId,
}: CollectionParams) {
  logEvent('collection_detail.listing.clicked', {
    loggingId: '9e54ecff-3945-4092-bc92-3464f4ef3b1d',
    action: ActionType.click,
    componentType: ComponentType.card,
    contractAddress,
    chainId,
    tokenId,
    marketplace,
    offchainOrderId,
  });
}

export function logCollectionDetailsOpenInLinkClicked({
  context,
  contractAddress,
  chainId,
}: CollectionParams) {
  logEvent('collection_detail.open_in_link.clicked', {
    loggingId: '47843e6d-873a-4320-869f-e74cc56a167a',
    action: ActionType.click,
    componentType: ComponentType.card,
    contractAddress,
    context,
    chainId,
  });
}

type CollectibleDetailAssetViewedProps = {
  chainId: string;
  mediaType: string;
};

export function logCollectibleDetailAssetViewed({
  chainId,
  mediaType,
}: CollectibleDetailAssetViewedProps) {
  logEvent('nft_detail.asset.viewed', {
    loggingId: '53622e3a-c1a4-4521-b514-f1146a4ed0f8',
    action: ActionType.view,
    componentType: ComponentType.unknown,
    chainId,
    mediaType: getMediaTypeEnum(mediaType),
  });
}

import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  fetchDomainForSolanaAddress,
  isValidAddressFormat as isValidSolanaAddressFormat,
} from 'cb-wallet-data/chains/AccountBased/Solana/utils/utils';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { getJSON } from 'cb-wallet-http/fetchJSON';
import { DomainStorage } from 'cb-wallet-store/storages/DomainStorage';

import type { PublicProfile } from '../types';

const domainStorage = new DomainStorage();
export const ENDPOINT = 'getPublicProfileByAddress';

type Params = {
  address: string;
  cancelQuery?: boolean;
};

type Status = 'error' | 'idle' | 'loading' | 'success';

type PublicProfileWithStatus = {
  publicProfile?: PublicProfile;
  status: Status;
  isFetching: boolean;
};

export async function fetchPublicProfileByAddress(address: string): Promise<PublicProfile> {
  const { result } = await getJSON<{ result: PublicProfile }>(ENDPOINT, { queryAddress: address });
  return result;
}

export async function fetchDomainFromPublicProfile(address: string): Promise<string | undefined> {
  try {
    const profile = await fetchPublicProfileByAddress(address);
    const primaryProfile =
      profile.primaryDomainType === 'eth' ? profile.ensDomainProfile : profile.subdomainProfile;
    return primaryProfile?.name;
  } catch (e) {
    return undefined;
  }
}

export async function fetchDomains(addresses: string[]): Promise<(string | undefined)[]> {
  const domains = await Promise.allSettled(
    addresses.map(async function handleFetchDomains(address) {
      // Disabling domain resolution for Solana addresses temporarily as getProgramAccounts
      // is an expensive call which is currently being throttled/rejected on the backend.
      if (!address || isValidSolanaAddressFormat(address)) return undefined;

      const cachedDomain = domainStorage.get(address);
      if (cachedDomain && !cachedDomain.isExpired) {
        return cachedDomain.domain;
      }

      const fetchDomain = isValidSolanaAddressFormat(address)
        ? fetchDomainForSolanaAddress
        : fetchDomainFromPublicProfile;
      const domain = await fetchDomain(address);

      domainStorage.add(address, domain);

      return domain;
    }),
  );

  return domains.map((a) => (a.status === 'fulfilled' ? a.value : undefined));
}

export function usePublicProfileByAddress({
  address,
  cancelQuery = false,
}: Params): PublicProfileWithStatus {
  const {
    data: publicProfile,
    status,
    isFetching,
  } = useQuery([ENDPOINT, address], async () => fetchPublicProfileByAddress(address), {
    enabled: !cancelQuery,
    suspense: false,
    staleTime: 1000 * 30,
    notifyOnChangeProps: ['data', 'status', 'isFetching'],
  });

  return useMemo(
    () => ({ publicProfile, status, isFetching }),
    [publicProfile, status, isFetching],
  );
}

export function useInvalidatePublicProfileByAddressQuery() {
  const queryClient = useQueryClient();
  return useCallback(
    function invalidatePublicProfileByAddressQueries(address: string) {
      queryClient.invalidateQueries([ENDPOINT, address]);
    },
    [queryClient],
  );
}

import { UTXOConfiguration } from 'cb-wallet-data/chains/UTXO/models/UTXOConfiguration';
import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { AddressType } from 'cb-wallet-data/stores/Addresses/models/AddressType';
import { NetworkSetting } from 'cb-wallet-data/stores/Networks/models/NetworkSetting';

export const DOGECOIN_SYMBOL = 'DOGE';
// Capitalization copied over from native code, probably want to keep it for data interop w/ native
export const DogecoinAddressType = new AddressType('DogeCoin');
export const DogecoinBlockchain = new Blockchain(DOGECOIN_SYMBOL);
export const DogecoinNetworkSetting = NetworkSetting.forMainnetOnlyBlockchain(DogecoinBlockchain);

export const DogecoinWalletConfiguration = new UTXOConfiguration({
  supportedAddressTypes: [DogecoinAddressType],
  blockchain: DogecoinBlockchain,
  currencyName: 'Dogecoin',
  currencyCode: new CurrencyCode('DOGE'),
  decimals: 8n,
  imageURL: 'https://wallet-api-production.s3.amazonaws.com/uploads/tokens/doge_288.png',
  defaultReceiveType: DogecoinAddressType,
  txResubmitterInterval: 30n,
  maxSubmissionAttempts: 5n,
  qrCodeUriMainnetScheme: 'dogecoin',
  qrCodeUriTestnetScheme: 'dogecoin',
  networkSetting: DogecoinNetworkSetting,
  refreshInterval: BigInt(480),
});

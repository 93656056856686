import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { WalletGroup } from 'cb-wallet-data/stores/WalletGroups/models/WalletGroup';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { emptyObject } from 'cb-wallet-data/utils/stableObjects';
import Decimal from 'decimal.js';

import { WalletIdsToWalletGroupIds } from './prepareWalletsByWalletGroup';

export type FiatBalanceByWalletGroup = Record<WalletGroup['id'], Decimal>;

type ComputeFiatBalancesByWalletGroupParams = {
  wallets: Wallet[];
  exchangeRatesMap: Record<string, Decimal>;
  isVisibleWallet: (wallet: Wallet, walletGroupId: WalletGroup['id']) => boolean;
  getAccountById: (accountId: Account['id']) => Account | undefined;
  walletGroups: WalletGroup[];
  walletIdsToWalletGroupIds: WalletIdsToWalletGroupIds;
};

/**
 * Computes the total fiat balance for each wallet group based on the wallets provided.
 *
 * NOTE: This should be moved to a selector
 */
export function computeFiatBalancesByWalletGroup({
  wallets,
  exchangeRatesMap,
  isVisibleWallet,
  getAccountById,
  walletGroups,
  walletIdsToWalletGroupIds,
}: ComputeFiatBalancesByWalletGroupParams): FiatBalanceByWalletGroup {
  // [Perf] If no wallet groups have been hydrated yet, skip iterating over wallets,
  // which would result in no matches.
  if (!walletGroups.length) {
    return emptyObject;
  }

  // Initial state is created by mapping an empty array of wallets to each wallet group,
  // and any wallet group associated with non-existing accounts are left off.
  const initFiatBalancesByWalletGroup = walletGroups.reduce<FiatBalanceByWalletGroup>(
    function reduceInitWalletsByWalletGroup(acc, walletGroup) {
      if (!getAccountById(walletGroup.accountId)) return acc;
      acc[walletGroup.id] = new Decimal(0);
      return acc;
    },
    {},
  );

  const fiatBalancesByWalletGroup = wallets.reduce<FiatBalanceByWalletGroup>(
    function reduceWalletsByWalletGroup(acc, wallet) {
      // If a wallet has been matched to wallet group, that relationship won't change;
      // so we can speed up this overall computation by utilizing any previous match
      // found for the current wallet.
      const walletGroupId = walletIdsToWalletGroupIds[wallet.id];
      if (!walletGroupId) return acc;

      const fiatBalance = acc[walletGroupId] ?? new Decimal(0);

      // In the case where isTestnetsEnabledAtom is true isValidWallet will
      // return true for testnet wallets (so they can be displayed in the Assets list)
      // But testnet balances should not affect wallet group totals because they hold no true value
      if (!wallet.network.isTestnet && isVisibleWallet(wallet, walletGroupId)) {
        acc[walletGroupId] = fiatBalance.plus(wallet.fiatBalance({ exchangeRatesMap }));
      }

      return acc;
    },
    initFiatBalancesByWalletGroup,
  );

  return fiatBalancesByWalletGroup;
}

import { useNftAvatarMetadata } from 'cb-wallet-data/stores/Collection/hooks/useNftAvatarMetadata';
import { DynamicPfp } from 'wallet-cds-web/components/ProfileAvatar/DynamicPfp';
import { AvatarSize, PaletteBorder } from '@cbhq/cds-common';

export const DEFAULT_PROFILE_AVATAR_SIZE = 'l';

type ProfileAvatarProps = {
  alt?: string;
  address?: string;
  testID?: string;
  size?: AvatarSize | 's';
  avatar?: string;
  borderColor?: PaletteBorder;
  initialAvatarColorIndex?: number;
};

export function ProfileAvatar({
  testID,
  address,
  size = DEFAULT_PROFILE_AVATAR_SIZE,
  avatar,
  borderColor,
  initialAvatarColorIndex,
}: ProfileAvatarProps) {
  const nftAvatarMetadata = useNftAvatarMetadata(avatar);

  return (
    <DynamicPfp
      nftAvatarMetadata={nftAvatarMetadata}
      size={size}
      alt={address ?? 'profile-avatar'}
      testID={['profile-avatar', testID].filter(Boolean).join('-')}
      src={avatar}
      address={address}
      borderColor={borderColor}
      initialAvatarColorIndex={initialAvatarColorIndex}
    />
  );
}

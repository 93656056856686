import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { Persistable } from 'cb-wallet-data/persistence/Database.interface';
import { AssetManagementStatus } from 'cb-wallet-data/stores/AssetManagement/models/AssetManagementStatus';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { Column, Entity, Index, PrimaryColumn } from '@cbhq/typeorm';

/**
 * Used to represent manual filters, created by the user. This table stores all
 * filters for Wallets
 */
@Entity('user_collectible_setting')
@Index('IDX_USER_COLLECTIBLE_SETTING', [
  'primaryAddress',
  'contractAddress',
  'blockchainStr',
  'networkStr',
  'tokenId',
])
export class UserCollectibleSettingDMO {
  @PrimaryColumn()
  id!: string;

  @Column()
  blockchainStr!: string;

  @Column({ type: 'int' })
  status!: AssetManagementStatus;

  @Column()
  networkStr!: string;

  @Column()
  contractAddress!: string;

  @Column()
  primaryAddress!: string;

  // Specific item id. Usually, this is just a number indicating
  // the index of an NFT item inside the collection.
  @Column({ nullable: true })
  tokenId?: string;
}

export class UserCollectibleSetting implements Persistable<UserCollectibleSettingDMO> {
  readonly id: string;
  constructor(
    readonly blockchain: Blockchain,
    readonly network: Network,
    readonly status: AssetManagementStatus,
    readonly contractAddress: string,
    readonly primaryAddress: string,
    readonly tokenId: string | undefined,
  ) {
    this.id = UserCollectibleSetting.generateID(
      primaryAddress,
      contractAddress,
      blockchain,
      network,
      tokenId,
    );
  }

  static fromDMO(dmo: UserCollectibleSettingDMO): UserCollectibleSetting {
    return new UserCollectibleSetting(
      new Blockchain(dmo.blockchainStr),
      Network.create(dmo.networkStr)!,
      dmo.status,
      dmo.contractAddress,
      dmo.primaryAddress,
      dmo.tokenId,
    );
  }

  static generateID(
    primaryAddress: string,
    contractAddress: string | undefined,
    blockchain: Blockchain,
    network: Network,
    tokenId: string | undefined,
  ): string {
    return [primaryAddress, contractAddress, blockchain.rawValue, network.rawValue, tokenId].join(
      '/',
    );
  }

  get asDMO(): UserCollectibleSettingDMO {
    return {
      id: this.id,
      blockchainStr: this.blockchain.rawValue,
      networkStr: this.network.rawValue,
      status: this.status,
      contractAddress: this.contractAddress,
      primaryAddress: this.primaryAddress,
      tokenId: this.tokenId,
    };
  }
}

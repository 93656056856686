import { getAllDomains, performReverseLookup } from '@bonfida/spl-name-service';
import { Connection, PublicKey, Transaction, VersionedTransaction } from '@solana/web3.js';

import { solanaUrls } from '../urls';

// Regex from https://docs.solana.com/integrations/exchange#validating-user-supplied-account-addresses-for-withdrawals
export function isValidAddressFormat(address: string): boolean {
  return /^[1-9A-HJ-NP-Za-km-z]{32,44}/.test(address);
}

export function isValidAddress(address: string): boolean {
  try {
    const publicKey = new PublicKey(address);
    return isValidAddressFormat(address) && PublicKey.isOnCurve(publicKey);
  } catch (e) {
    // public key generation will fail if someone pastes, for instance, an ethereum address
    return false;
  }
}

export function decodeLength(bytes: number[]): number {
  let len = 0;
  let size = 0;
  for (;;) {
    const elem = bytes.shift() as number;
    // eslint-disable-next-line no-bitwise
    len |= (elem & 0x7f) << (size * 7);
    size += 1;
    // eslint-disable-next-line no-bitwise
    if ((elem & 0x80) === 0) {
      break;
    }
  }
  return len;
}

export function encodeLength(bytes: number[], len: number): void {
  let remLen = len;
  for (;;) {
    // eslint-disable-next-line no-bitwise
    let elem = remLen & 0x7f;
    // eslint-disable-next-line no-bitwise
    remLen >>= 7;
    if (remLen === 0) {
      bytes.push(elem);
      break;
    } else {
      // eslint-disable-next-line no-bitwise
      elem |= 0x80;
      bytes.push(elem);
    }
  }
}

// Decoder utils
export type InstructionParam = {
  paramKey: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  paramValue: string | number | any;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fromInstructionToParams(params: any): InstructionParam[] {
  const instructionParams: InstructionParam[] = [];
  for (const [key, value] of Object.entries(params)) {
    if (value instanceof PublicKey) {
      instructionParams.push({ paramKey: key, paramValue: value.toString() });
    } else if (isNaN(Number(value))) {
      instructionParams.push({ paramKey: key, paramValue: `${value}` });
    } else {
      instructionParams.push({ paramKey: key, paramValue: Number(value) });
    }
  }
  return instructionParams;
}

export async function fetchDomainForSolanaAddress(
  walletAddress: string,
): Promise<string | undefined> {
  const ownerWallet = new PublicKey(walletAddress);
  const SOLANA_CONNECTION = new Connection(solanaUrls.SOLANA_MAINNET.rpcUrl);
  const allDomainKeys = await getAllDomains(SOLANA_CONNECTION, ownerWallet);
  const allDomainNames = await Promise.all(
    allDomainKeys.map(async (key) => {
      return `${await performReverseLookup(SOLANA_CONNECTION, key)}.sol`;
    }),
  );
  return allDomainNames[0] || undefined;
}

export function isVersionedTransaction(
  transaction: Transaction | VersionedTransaction,
): transaction is VersionedTransaction {
  return 'version' in transaction && transaction.version !== 'legacy';
}

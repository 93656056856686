import { createContext, useCallback, useContext, useMemo, useState } from 'react';

type PageOutageContextValue = {
  gamificationApiIssue: boolean;
  setGamificationApiIssue: () => void;
  exploreApiIssue: boolean;
  setExploreApiIssue: () => void;
};

const INITIAL_STATES: PageOutageContextValue = {
  gamificationApiIssue: false,
  setGamificationApiIssue: () => {},
  exploreApiIssue: false,
  setExploreApiIssue: () => {},
};

const PageOutageContext = createContext(INITIAL_STATES);

type PageOutageProviderValue = { children: React.ReactNode };

/**
 * The purpose of this provider is the manage the state to disable / hide parts of the application in case of API failure / service outage.
 * Currently operating in a "all or nothing" approach where a single API failure of that type triggers the service to be considered to be "out"
 */
export function PageOutageProvider({ children }: PageOutageProviderValue) {
  // Default to true to enable the components to attempt to fetch
  const [gamificationApiIssue, setGamificationApiIssueState] = useState<boolean>(
    INITIAL_STATES.gamificationApiIssue,
  );
  const [exploreApiIssue, setExploreApiIssueState] = useState<boolean>(
    INITIAL_STATES.exploreApiIssue,
  );

  const setGamificationApiIssue = useCallback(() => {
    setGamificationApiIssueState(true);
  }, [setGamificationApiIssueState]);

  const setExploreApiIssue = useCallback(() => {
    setExploreApiIssueState(true);
  }, [setExploreApiIssueState]);

  const value = useMemo(
    () => ({
      gamificationApiIssue,
      setGamificationApiIssue,
      exploreApiIssue,
      setExploreApiIssue,
    }),
    [gamificationApiIssue, setGamificationApiIssue, exploreApiIssue, setExploreApiIssue],
  );
  return <PageOutageContext.Provider value={value}>{children}</PageOutageContext.Provider>;
}

export function usePageOutageContext() {
  return useContext(PageOutageContext);
}

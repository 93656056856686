import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { useExchangeRatesMap } from 'cb-wallet-data/stores/ExchangeRates/hooks/useExchangeRates';
import { rateFor, toDecimal } from 'cb-wallet-data/stores/ExchangeRates/utils';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { Decimal } from 'decimal.js';

export type FiatValueProps = {
  currencyCode: CurrencyCode;
  decimals: bigint;
  value: bigint | Decimal;
  contractAddress?: string | undefined;
  network: Network | undefined;
  blockchain: Blockchain | undefined;
  digitsToFixed?: number | undefined;
  exchangeRatesMap: ReturnType<typeof useExchangeRatesMap>;
  shouldOverrideWithDollar?: boolean;
};

/**
 * ## `fiatValue`
 * Returns the fiat value of a given value of a given currency with
 * elementary formatting depending upon the parameters passed in.
 *
 * @param currencyCode - The currency code of the value to be formatted
 * @param decimals - The number of decimals of the value to be formatted
 * @param value - The value to be formatted
 * @param contractAddress - The contract address of the value to be formatted
 * @param network - The network of the value to be formatted
 * @param blockchain - The blockchain of the value to be formatted
 * @param digitsToFixed - The number of decimal places to show
 * @param exchangeRatesMap - The exchange rates map ie. { 'networks/ethereum-mainnet-x-ETH': 5050 } @see useExchangeRatesMap
 * @returns fiat value of a given currency
 */
export function fiatValue({
  currencyCode,
  decimals,
  value,
  contractAddress,
  network,
  blockchain,
  digitsToFixed,
  exchangeRatesMap,
  shouldOverrideWithDollar,
}: FiatValueProps) {
  const exchangeRate = rateFor({
    exchangeRatesMap,
    currencyCode,
    contractAddress,
    network,
    blockchain,
  });

  if (!exchangeRate) {
    return undefined;
  }

  const decimalValue = toDecimal(value, decimals);
  const fiatAmount = decimalValue.mul(exchangeRate);

  if (digitsToFixed) {
    return fixDigits({
      value: shouldOverrideWithDollar ? decimalValue : fiatAmount,
      digitsToFixed,
    });
  }

  return shouldOverrideWithDollar ? decimalValue : fiatAmount;
}

type FixDigitsProps = {
  value?: Decimal;
  digitsToFixed: number;
};

export function fixDigits({ value, digitsToFixed }: FixDigitsProps) {
  if (!value) return undefined;
  return new Decimal(value.toFixed(digitsToFixed));
}

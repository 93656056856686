import { ETHEREUM_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { SOLANA_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Solana/constants';
import { SolanaNetworkMap } from 'cb-wallet-data/chains/AccountBased/Solana/models/SolanaChain';
import { AllPossibleBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
import { BITCOIN_SYMBOL } from 'cb-wallet-data/chains/UTXO/Bitcoin/config';
import { DOGECOIN_SYMBOL } from 'cb-wallet-data/chains/UTXO/Dogecoin/config';
import { LITECOIN_SYMBOL } from 'cb-wallet-data/chains/UTXO/Litecoin/config';
import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';

import { getAssetChainPrefix } from '../utils/getAssetChainPrefix';

export type OnrampAssetQuote = {
  payoutAmount?: string;
  feeAmount?: string;
  totalAmount?: string;
  exchangeRate?: string;
  quoteId: string;
  providerId: string;
  aggregatorId: string;
  feeBreakdown: {
    networkFee: string;
    transactionFee: string;
  };
  committable: boolean;
  expiringTime: string;
  mfaRequired: boolean;
  slippage?: string;
};

export enum OnrampAssetType {
  UNSPECIFIED = 'ASSET_TYPE_UNSPECIFIED',
  NATIVE = 'ASSET_TYPE_NATIVE',
  TOKEN = 'ASSET_TYPE_TOKEN',
}

export type OnrampAssetResponse = {
  code: string;
  name: string;
  amount?: string;
  imageUrl: string;
  decimals: number;
  chainId: number;
  networkId: string;
  networkLegacyCode: string;
  networkName: string;
  contractAddress: string | null;
  type: OnrampAssetType;
  swappable: boolean; // is the asset swappable
  isMgx?: boolean; // this is not from the API, but used to identify MGX assets
  supportedByPaymentMethod: boolean; // is the asset supported by the current selected payment method
  unverified?: boolean; //   unverified assets from the swap API, mainly used for swap assets
};

export class OnrampAsset {
  type: OnrampAssetType;
  code: string;
  name: string;
  imageUrl: string;
  chainId: number;
  decimals: number;
  networkId: string;
  networkLegacyCode: string;
  networkName: string;
  labels?: string[];
  contractAddress: string | null;
  swappable: boolean;
  isMgx: boolean;
  supportedByPaymentMethod: boolean;
  unverified: boolean;
  blockchain: Blockchain;

  constructor(asset: OnrampAssetResponse) {
    this.type = asset.type;
    this.code = asset.code;
    this.name = asset.name;
    this.imageUrl = asset.imageUrl;
    this.chainId = asset.chainId;
    this.decimals = asset.decimals;
    this.networkId = asset.networkId;
    this.networkLegacyCode = asset.networkLegacyCode;
    this.networkName = asset.networkName;
    this.contractAddress = asset.contractAddress;
    this.swappable = asset.swappable;
    this.isMgx = asset.isMgx ?? false;
    this.supportedByPaymentMethod = asset.supportedByPaymentMethod;
    this.unverified = asset.unverified ?? false;
    this.blockchain = getBlockchain(asset.networkLegacyCode);
  }

  get id(): string {
    return getOnrampAssetId({
      chainId: this.chainId,
      assetCode: this.code,
      // For native tokens, the contract address is empty except for MATIC
      // There is inconsistency in the API response for MATIC from PPA vs swap/assets
      // that MATIC from PPA has contract address while MATIC from swap/assets does not
      // Since MATIC is used with no contract address broadly in the app,
      // we need to force the contract address to be empty for native tokens
      contractAddress: this.type === OnrampAssetType.NATIVE ? '' : this.contractAddress || '',
    });
  }

  get network(): Network | undefined {
    if (!this.chainId) {
      return undefined;
    }

    return Network.fromChainId({
      chainId: BigInt(this.chainId),
      chainPrefix: getAssetChainPrefix(this.chainId),
    });
  }

  get trackingDetails() {
    return {
      cryptoCode: this.code,
      networkId: this.networkId || '',
      chainId: String(this.chainId || ''),
    };
  }

  isLayer2Network(): boolean {
    return !!this.network?.asChain()?.isLayer2;
  }

  isSolanaNetwork(): boolean {
    return this.network?.asChain()?.chainId === SolanaNetworkMap.whitelisted.SOLANA_MAINNET.chainId;
  }
}

export function getOnrampAssetId({
  chainId,
  assetCode,
  contractAddress,
}: {
  chainId: number;
  assetCode: string;
  contractAddress: string;
}) {
  return `${chainId}/${assetCode.toUpperCase()}/${contractAddress}`;
}

export function getBlockchain(networkSymbol: string) {
  let blockchainSymbol: AllPossibleBlockchainSymbol;
  switch (networkSymbol) {
    case BITCOIN_SYMBOL:
    case LITECOIN_SYMBOL:
    case DOGECOIN_SYMBOL:
    case SOLANA_SYMBOL:
      blockchainSymbol = networkSymbol;
      break;
    default:
      blockchainSymbol = ETHEREUM_SYMBOL;
      break;
  }

  return new Blockchain(blockchainSymbol);
}

import { Network } from 'cb-wallet-data/stores/Networks/models/Network';

/**
 * [Network] specific exceptions
 */
export class NetworkError extends Error {
  private constructor(readonly name: string, readonly message: string) {
    super(message);
  }

  /**
   * Thrown when trying to use the wrong network
   */
  static invalidNetwork(network: Network): NetworkError {
    return new NetworkError('InvalidNetwork', `Invalid network state ${network.rawValue}`);
  }
}

import { logDataEvent } from '../utils/log';

type RelayType = 'send' | 'swap';

export function logStatusCheckStarted(count: number, relayType: RelayType) {
  logDataEvent('relay_operation.tx_status_check.started', {
    count,
    relayType,
  });
}

export function logStatusCheckFailed(errorMessage: string, relayType: RelayType) {
  logDataEvent('relay_operation.tx_status_check.failed', {
    errorMessage,
    relayType,
  });
}

export function logStatusCheckCompleted(relayType: RelayType) {
  logDataEvent('relay_operation.tx_status_check.completed', { relayType });
}

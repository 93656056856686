import { SPL, SPLDMO } from 'cb-wallet-data/chains/AccountBased/Solana/models/SPL';
import { tryGetRepository } from 'cb-wallet-data/persistence/Database';
import { TxState } from 'cb-wallet-data/stores/Transactions/models/TxState';

import { SolanaChain } from './models/SolanaChain';
import { SolanaSignedTx, SolanaSignedTxDMO } from './models/SolanaSignedTx';

function splRepo() {
  return tryGetRepository<SPLDMO>('spl');
}
function solanaSignedTxRepo() {
  return tryGetRepository<SolanaSignedTxDMO>('solana_signed_tx');
}

export async function batchSaveSPLs(spls: SPL[]): Promise<void> {
  await Promise.all(
    splRepo().batchUpsert(
      spls.map((it) => it.asDMO),
      ['id'],
    ),
  );
}

export async function getSPL(mintAddress: string, chainId: number): Promise<SPL | undefined> {
  const spl = await splRepo().findOne({
    where: {
      mintAddress,
      chainIdStr: chainId.toString(),
    },
  });

  return spl ? SPL.fromDMO(spl) : undefined;
}

export async function getSPLsForChain(chainId: number): Promise<Record<string, SPL>> {
  const spls = await splRepo().find({
    where: {
      chainIdStr: chainId.toString(),
    },
  });

  const splMap: Record<string, SPL> = {};
  spls.forEach((spl: SPLDMO) => {
    splMap[spl.mintAddress] = SPL.fromDMO(spl);
  });

  return splMap;
}

/**
 *Get list of unmined signed transaction (excluding dropped transactions)
 */
export async function getUnminedSignedTxs(chain: SolanaChain): Promise<SolanaSignedTx[]> {
  const dmoArray = await solanaSignedTxRepo().find({
    where: {
      state: TxState.PENDING.valueOf(),
      chainId: chain.chainId,
    },
  });

  return dmoArray.map(SolanaSignedTx.fromDMO);
}

/**
 * Save or update given solana-based transaction
 */
export async function saveTransaction(tx: SolanaSignedTx): Promise<SolanaSignedTx | undefined> {
  await solanaSignedTxRepo().upsert(tx.asDMO, ['id']);
  return tx;
}

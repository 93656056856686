import { logDataEvent } from '../utils/log';

// logs when the isFeatureEnabledStore is updated
export function logIsFeatureEnabledStoreSet() {
  logDataEvent('fm.is-feature-enabled-store.set', {});
}

// logs when useIsFeatureEnabled is called and the isEnabled value changes
// featureName
export function logIsFeatureEnabledGet({
  featureName,
  isEnabled,
}: {
  featureName: string; // type FeatureName
  isEnabled: boolean;
}) {
  logDataEvent('fm.is-feature-enabled.get', { featureName, isEnabled });
}

export function logKillSwitchHydrated({ isUsingFreshData }: { isUsingFreshData: boolean }) {
  logDataEvent('fm.kill_switches.hydrated', {
    isUsingFreshData,
  });
}

export function logIsEligibleFnFailure({ errorMessage }: { errorMessage: string }) {
  logDataEvent('fm.is_eligible_fn.failure', {
    errorMessage,
  });
}

import { useMemo } from 'react';
import { useAccountTotals } from 'cb-wallet-data/stores/Accounts/hooks/useAccountTotals';
import { AccountType } from 'cb-wallet-data/stores/Accounts/models/AccountTypes';
import { AccountTotals } from 'cb-wallet-data/stores/Accounts/state';

import { FeatureConfig } from '../types';

type CheckIsEnabledForAccountTypesParams = {
  accountTotals?: AccountTotals;
  accountTypes?: readonly AccountType[];
};

function checkIsEnabledForAccountTypes({
  accountTotals,
  accountTypes,
}: CheckIsEnabledForAccountTypesParams): boolean {
  // if it isn't defined, the feature is enabled by default
  if (typeof accountTypes === 'undefined') {
    return true;
  }

  // if it is defined with an empty array
  if (accountTypes.length === 0) {
    return false;
  }

  // else check the intersection of the accountTotals and accountTypes
  return accountTypes.some((accountType) => accountTotals?.[accountType]);
}

/**
 * useIsFeatureEnabledForAccountTypes
 *
 * Handles the logic for FeatureConfig.accountTypes
 * - If it's undefined, the feature is enabled by default
 * - If it's an empty array, the feature is disabled
 * - If it's an array of AccountTypes, the feature is enabled if there is an
 *   intersection between the accountTotals and accountTypes
 */
export function useIsFeatureEnabledForAccountTypes(
  accountTypes: FeatureConfig['accountTypes'],
): boolean {
  const accountTotals = useAccountTotals();
  return useMemo(() => {
    return checkIsEnabledForAccountTypes({ accountTypes, accountTotals });
  }, [accountTypes, accountTotals]);
}

// eslint-disable-next-line no-restricted-imports
import { AddressString, BigIntString, HexString } from '@coinbase/wallet-sdk/dist/core/type';
// eslint-disable-next-line no-restricted-imports
import { CancelablePromise } from '@coinbase/wallet-sdk/dist/relay/RelayAbstract';
// eslint-disable-next-line no-restricted-imports
import { SessionConfig } from '@coinbase/wallet-sdk/dist/relay/walletlink/type/SessionConfig';
// eslint-disable-next-line no-restricted-imports
import { Web3Response } from '@coinbase/wallet-sdk/dist/relay/walletlink/type/Web3Response';
// eslint-disable-next-line no-restricted-imports
import { WalletLinkRelay } from '@coinbase/wallet-sdk/dist/relay/walletlink/WalletLinkRelay';

import { defaultWalletLinkRelay } from '../stores/WalletLink/useWalletLinkRelay';
import { promiseWithTermination } from '../utils/promiseWithTermination';

export type GenericRequestParams = {
  data: {
    chainId: number;
    contractAddress?: string;
    currencyCode: string;
    data: Buffer | HexString | string;
    erc20Value?: BigIntString | null;
    fromAddress: AddressString;
    gasLimit: BigIntString;
    gasPriceInWei?: BigIntString | null;
    id?: number;
    maxFeePerGas?: BigIntString | null;
    maxPriorityFeePerGas?: BigIntString | null;
    nonce?: number | null;
    recipientAddress?: string | null;
    toAddress: string;
    toUsername?: string | null;
    weiValue: BigIntString;
  };
  action: 'cbSignAndSubmit';
};

export type WalletLinkRelayType = {
  walletUsername: string | null;
  sessionConfig: SessionConfig | null;
  addOnSessionConfigChangedCallback: (
    callback: (nextSessionConfig: SessionConfig) => void,
  ) => () => void;
  appVersion: string | null;
  sendCbRequest: (request: {
    method: 'generic';
    params: GenericRequestParams;
  }) => CancelablePromise<Web3Response<'generic'>>;
} & Pick<
  WalletLinkRelay,
  'isLinked' | 'setChainCallback' | 'signEthereumMessage' | 'signAndSubmitEthereumTransaction'
>;

const relayInitializedPromiseWrapper = promiseWithTermination<WalletLinkRelayType>();
export const relayInitializedPromise = relayInitializedPromiseWrapper.promise;
let internalRelay: WalletLinkRelayType | null = null;

export function setRelay(relay: WalletLinkRelayType) {
  finishRelayInitialization(relay);
}

export function finishRelayInitialization(relay: WalletLinkRelayType) {
  if (internalRelay) {
    throw new Error('finishRelayInitialization called more than once');
  }

  internalRelay = relay;
  relayInitializedPromiseWrapper.resolve(relay);
}

export function getWalletLinkRelay(): WalletLinkRelayType {
  if (!internalRelay) {
    return defaultWalletLinkRelay;
  }

  return internalRelay;
}

import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { getAllUserWalletSettings } from '../database';
import { getMapFromArrayById, userWalletSettingsAtom } from '../state';

/**
 * @returns A function that can be invoked in order to load User Wallet Settings from
 * IndexedDB/Sqlite into application state.
 */

export function useHydrateUserWalletSettingsFromDatabase(): () => Promise<void> {
  const setUserWalletSettings = useSetRecoilState(userWalletSettingsAtom);
  return useCallback(
    async function hydrateUserWalletSettingsFromDatabase() {
      const settings = await getAllUserWalletSettings();
      const walletSettingsMap = getMapFromArrayById(settings);
      setUserWalletSettings(walletSettingsMap);
    },
    [setUserWalletSettings],
  );
}

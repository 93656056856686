import { EthereumWalletConfiguration } from 'cb-wallet-data/chains/AccountBased/Ethereum/config';
import { ETHEREUM_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { SolanaWalletConfiguration } from 'cb-wallet-data/chains/AccountBased/Solana/config';
import { SOLANA_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Solana/constants';
import {
  BITCOIN_SYMBOL,
  BitcoinWalletConfiguration,
} from 'cb-wallet-data/chains/UTXO/Bitcoin/config';
import {
  DOGECOIN_SYMBOL,
  DogecoinWalletConfiguration,
} from 'cb-wallet-data/chains/UTXO/Dogecoin/config';
import {
  LITECOIN_SYMBOL,
  LitecoinWalletConfiguration,
} from 'cb-wallet-data/chains/UTXO/Litecoin/config';
import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';

import { WalletConfiguration } from '../stores/Wallets/models/WalletConfiguration';

export const StoreKeys_storedBlockchainDeprecationStatus = new LocalStorageStoreKey<boolean>(
  'storedBlockchainDeprecationStatus',
);

// for now we're keeping this to guarantee we're filtering out deprecated chains from external services (e.g CBPay supported list, Assets search on Explore tab)
// might note be needed, to be removed on the next iteration
export const allDeprecatedBlockchains = ['XRP', 'XLM', 'ETC', 'BCH'];

/**
 * A read-only array of the **possible** account-based blockchain symbols that an app (e.g. Extension, RN, etc.)
 * may support.
 *
 * Use the following public methods to retrieve the **current** list of an app's supported blockchain symbols:
 *
 * @see {@link getCurrentGroupSupportedBlockchainSymbols}
 * @see {@link getAllCurrentSupportedBlockchainSymbols}
 */
export const possibleAccountBlockchainSymbols = [ETHEREUM_SYMBOL, SOLANA_SYMBOL] as const;

/**
 * The **possible** account-based blockchain symbols that an app (e.g. Extension, RN, etc.) may support.
 */
export type PossibleAccountBlockchainSymbol = (typeof possibleAccountBlockchainSymbols)[number];

/**
 * A read-only array of the **possible** UTXO-based blockchain symbols that an app (e.g. Extension, RN, etc.)
 * may support.
 *
 * Use the following public methods to retrieve the **current** list of an app's supported blockchain symbols:
 *
 * @see {@link getCurrentGroupSupportedBlockchainSymbols}
 * @see {@link getAllCurrentSupportedBlockchainSymbols}
 */
export const possibleUTXOBlockchainSymbols = [
  BITCOIN_SYMBOL,
  DOGECOIN_SYMBOL,
  LITECOIN_SYMBOL,
] as const;

/**
 * The **possible** UTXO-based blockchain symbols that an app (e.g. Extension, RN, etc.) may support.
 */
export type PossibleUTXOBlockchainSymbol = (typeof possibleUTXOBlockchainSymbols)[number];

/**
 * A read-only array of all of the **possible** blockchain symbols that an app (e.g. Extension, RN, etc.) may support.
 *
 * Use the following public methods to retrieve the **current** list of an app's supported blockchain symbols:
 *
 * @see {@link getCurrentGroupSupportedBlockchainSymbols}
 * @see {@link getAllCurrentSupportedBlockchainSymbols}
 */
export const allPossibleBlockchainSymbols = [
  ...possibleAccountBlockchainSymbols,
  ...possibleUTXOBlockchainSymbols,
] as const;

/**
 * The **possible** blockchain symbols that an app (e.g. Extension, RN, etc.) may support.
 */
export type AllPossibleBlockchainSymbol = (typeof allPossibleBlockchainSymbols)[number];

/**
 * The read-only mapping of blockchain symbol to wallet configuration.
 */
export const blockchainConfigurations: Readonly<
  Record<AllPossibleBlockchainSymbol, WalletConfiguration>
> = {
  [ETHEREUM_SYMBOL]: EthereumWalletConfiguration,
  [SOLANA_SYMBOL]: SolanaWalletConfiguration,
  [BITCOIN_SYMBOL]: BitcoinWalletConfiguration,
  [DOGECOIN_SYMBOL]: DogecoinWalletConfiguration,
  [LITECOIN_SYMBOL]: LitecoinWalletConfiguration,
};

/**
 * The values used to group any blockchain symbols by the model they follow (e.g. BTC is UTXO-based and ETH is account-based).
 */
export type BlockchainGroup = 'Account' | 'UTXO';

/**
 * A generic that returns the type definition of the **possible** blockchain symbols for a given blockchain group.
 *
 * @see {@link BlockchainGroup}
 */
export type PossibleSupportedBlockchainSymbol<T> = T extends 'Account'
  ? PossibleAccountBlockchainSymbol
  : T extends 'UTXO'
  ? PossibleUTXOBlockchainSymbol
  : never;

/**
 * The shape of how a blockchain symbol is mapped to the boolean indicating if it is **currently** supported or not.
 */
export type AppSupportedBlockchainSymbolMap<T> = Map<T, boolean>;

/**
 * The mapping of blockchain group to blockchain symbols that an app (e.g. Extension, RN, etc.) **currently** supports.
 */
export type AppSupportedBlockchainSymbol<T extends BlockchainGroup> = {
  [K in T]: AppSupportedBlockchainSymbolMap<PossibleSupportedBlockchainSymbol<T>>;
};

/**
 * The **private** mapping of blockchain group (i.e. Account and UTXO) to blockchain symbols that an app
 * (e.g. Extension, RN, etc.) currently supports. We keep this mapping variable private so we can abstract
 * away its structure so if we need to adjust it we can do so with minimal impact.
 *
 * The status of whether an app supports a given blockchain symbol can be get/set via public methods:
 *
 * @see {@link setIsCurrentBlockchainSymbolSupported}
 * @see {@link getCurrentGroupSupportedBlockchainSymbols}
 * @see {@link getAllCurrentSupportedBlockchainSymbols}
 * @see {@link cloneSupportedBlockchainMapping}
 */
const _appSupportedBlockchainSymbol: AppSupportedBlockchainSymbol<BlockchainGroup> = {
  Account: new Map([
    [ETHEREUM_SYMBOL, true],
    [SOLANA_SYMBOL, true],
  ]),
  UTXO: new Map([
    [BITCOIN_SYMBOL, false],
    [DOGECOIN_SYMBOL, false],
    [LITECOIN_SYMBOL, false],
  ]),
};

/**
 * Accepts an array of supported blockchain symbols to enable
 */
export function enableBlockchains(blockchainSymbols?: AllPossibleBlockchainSymbol[]) {
  // Enable all chains if undefined is passed
  const blockchainsToEnable = blockchainSymbols ?? allPossibleBlockchainSymbols;

  blockchainsToEnable.forEach(
    // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
    // eslint-disable-next-line wallet/no-anonymous-params
    (blockchainSymbol: AllPossibleBlockchainSymbol) => {
      const { blockchainGroup } = determineBlockchainInfo(blockchainSymbol);
      setIsCurrentBlockchainSymbolSupported({
        blockchainGroup: blockchainGroup as BlockchainGroup,
        blockchainSymbol,
        isSupported: true,
      });
    },
  );
}

/**
 * Accepts a blockchain symbol, e.g. 'ETH', 'SOL' and return a boolean representing
 * whether that chain is supported on the current client
 */
export function getIsBlockchainSymbolSupported(
  blockchainSymbol: AllPossibleBlockchainSymbol,
): boolean {
  return Boolean(
    _appSupportedBlockchainSymbol.Account.get(blockchainSymbol) ||
      _appSupportedBlockchainSymbol.UTXO.get(blockchainSymbol),
  );
}

/**
 * We disable Solana if we detect the extension is in walletlink mode.
 * We only have a disabling function for Solana as its the only chain we disable at runtime.
 */
export function disableSolana() {
  setIsCurrentBlockchainSymbolSupported({
    blockchainGroup: 'Account',
    blockchainSymbol: 'SOL',
    isSupported: false,
  });
}

/**
 * Returns a copy of the **current** internal mapping of blockchain groups to blockchain symbols and whether they are
 * supported by an app (e.g. Extension, RN, etc.).
 *
 * @see {@link _appSupportedBlockchainSymbol}
 */
export function cloneSupportedBlockchainMapping(): AppSupportedBlockchainSymbol<BlockchainGroup> {
  return {
    Account: new Map(_appSupportedBlockchainSymbol.Account),
    UTXO: new Map(_appSupportedBlockchainSymbol.UTXO),
  };
}

type SetIsCurrentBlockchainSymbolSupportedOptions<T> = {
  blockchainGroup: T;
  blockchainSymbol: PossibleSupportedBlockchainSymbol<T>;
  isSupported: boolean;
};

/**
 * Allows an app (e.g. Extension, RN, etc.) to toggle whether a given blockchain group's blockchain symbol is **currently**
 * supported.
 */
export function setIsCurrentBlockchainSymbolSupported<T extends BlockchainGroup>(
  options: SetIsCurrentBlockchainSymbolSupportedOptions<T>,
): void {
  const { blockchainGroup, blockchainSymbol, isSupported } = options;
  const groupMapping = _appSupportedBlockchainSymbol[blockchainGroup];

  // This `has` check is technically unnecessary since TS will prevent an invalid mapping of chain group + chain symbol
  // to be passed into this function, but we add this check here to protect against consuming modules overriding TS checks.
  if (groupMapping.has(blockchainSymbol)) {
    groupMapping.set(blockchainSymbol, isSupported);
  }
}

type GetCurrentGroupSupportedBlockchainSymbolsOptions<T> = {
  blockchainGroup: T;
};

/**
 * Returns the **current** supported blockchain symbols for a given blockchain group (i.e. Account and UTXO).
 */
export function getCurrentGroupSupportedBlockchainSymbols<T extends BlockchainGroup>(
  options: GetCurrentGroupSupportedBlockchainSymbolsOptions<T>,
): PossibleSupportedBlockchainSymbol<T>[] {
  const { blockchainGroup } = options;

  const supportedChainSymbols: PossibleSupportedBlockchainSymbol<T>[] = [];
  const groupMapping = _appSupportedBlockchainSymbol[blockchainGroup];

  for (const [blockchainSymbol, isSupported] of groupMapping.entries()) {
    if (isSupported) {
      // `Object.entries` doesn't allow defining the key's type def so we cast it to what it's defined as in
      // the `supportedChainSymbols` array value.
      supportedChainSymbols.push(blockchainSymbol as PossibleSupportedBlockchainSymbol<T>);
    }
  }

  return supportedChainSymbols.filter(function filterOutDeprecatedBlockchainsIfNeeded(symbol) {
    return !allDeprecatedBlockchains.includes(symbol);
  });
}

/**
 * Returns the **current** supported blockchain symbols across all blockchain groups (i.e. Account and UTXO).
 */
export function getAllCurrentSupportedBlockchainSymbols(): AllPossibleBlockchainSymbol[] {
  const blockchainSymbols: AllPossibleBlockchainSymbol[] = [];

  Object.keys(_appSupportedBlockchainSymbol).forEach(
    // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
    // eslint-disable-next-line wallet/no-anonymous-params
    (key) => {
      const blockchainGroup = key as BlockchainGroup;
      const groupChainSymbols = getCurrentGroupSupportedBlockchainSymbols({ blockchainGroup });

      blockchainSymbols.push(...groupChainSymbols);
    },
  );

  // Filters out any duplicate chain symbols. Realistically, this should never/cannot happen as a blockchain cannot be
  // both account and UTXO-based at the same time.
  return [...Array.from(new Set(blockchainSymbols))];
}

/**
 * A type guard to determine if a given raw string is **possibly** an account-based blockchain symbol that an app may
 * support via a case sensitive check.
 */
export function isAccountBasedBlockchain(
  rawValue: string,
): rawValue is PossibleSupportedBlockchainSymbol<'Account'> {
  return possibleAccountBlockchainSymbols.includes(
    rawValue as PossibleSupportedBlockchainSymbol<'Account'>,
  );
}

/**
 * A type guard to determine if a given raw string is **possibly** a UTXO-based blockchain symbol that an app may
 * support via a case sensitive check.
 */
export function isUTXOBasedBlockchain(
  rawValue: string,
): rawValue is PossibleSupportedBlockchainSymbol<'UTXO'> {
  return possibleUTXOBlockchainSymbols.includes(
    rawValue as PossibleSupportedBlockchainSymbol<'UTXO'>,
  );
}

/**
 * Whether a blockchain supports multi-wallet functionality. Includes all account-based blockchains.
 *
 * A multi-wallet supported blockchain is compatible with having multiple addresses of the same mnemonic, which are
 * derived at different indexes.
 *
 * Summary:
 * - For all multi-wallet compatible chains only, we create 10 derived addresses during onboarding for each of their networks.
 * - For all multi-wallet compatible chains only, we continue to create additional derived addresses when user creates 11th+ wallet groups.
 * - Currently all account-based blockchains are multi-wallet compatible.
 * - Currently all UTXO blockchains are NOT multi-wallet compatible.
 */
export function isMultiWalletBlockchain(rawValue: AllPossibleBlockchainSymbol) {
  return blockchainConfigurations[rawValue].supportsMultiWallet;
}

type BlockchainInfoBase = {
  blockchainGroup: BlockchainGroup;
  blockchainSymbol: PossibleSupportedBlockchainSymbol<BlockchainGroup>;
};

/**
 * A type guarded account-based blockchain group and blockchain symbol.
 */
export type AccountBlockchainInfo = BlockchainInfoBase & {
  blockchainGroup: 'Account';
  blockchainSymbol: PossibleSupportedBlockchainSymbol<'Account'>;
};

/**
 * A type guarded UTXO-based blockchain group and blockchain symbol.
 */
export type UTXOBlockchainInfo = BlockchainInfoBase & {
  blockchainGroup: 'UTXO';
  blockchainSymbol: PossibleSupportedBlockchainSymbol<'UTXO'>;
};

/**
 * An undefined blockchain group and blockchain symbol.
 */
export type EmptyBlockchainInfo = {
  blockchainGroup: undefined;
  blockchainSymbol: undefined;
};

/**
 * Performs a case sensitive check whether a given raw string is a **possible** blockchain symbol that an
 * app (e.g. Extension, RN, etc.) may support.
 *
 * If so, then we return type safe strings representing the blockchain group and blockchain symbol.
 *
 * If not, then we return an `undefined` blockchain group and blockchain symbol.
 */
export function determineBlockchainInfo(
  rawValue: string,
): AccountBlockchainInfo | UTXOBlockchainInfo | EmptyBlockchainInfo {
  if (isAccountBasedBlockchain(rawValue)) {
    return {
      blockchainGroup: 'Account',
      blockchainSymbol: rawValue,
    };
  }

  if (isUTXOBasedBlockchain(rawValue)) {
    return {
      blockchainGroup: 'UTXO',
      blockchainSymbol: rawValue,
    };
  }

  return {
    blockchainGroup: undefined,
    blockchainSymbol: undefined,
  };
}

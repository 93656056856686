// types for "entities" can be found here
// https://github.cbhq.net/mono/repo/blob/master/consumer/web3-search-service/protos/coinbase/web3_search_service/api.proto
export enum SearchEntity {
  SEARCH_ENTITY_UNSPECIFIED = 'SEARCH_ENTITY_UNSPECIFIED',
  SEARCH_ENTITY_ASSET = 'SEARCH_ENTITY_ASSET',
  SEARCH_ENTITY_DAPP = 'SEARCH_ENTITY_DAPP',
  SEARCH_ENTITY_DEFI = 'SEARCH_ENTITY_DEFI',
  SEARCH_ENTITY_LEARN_ARTICLES = 'SEARCH_ENTITY_LEARN_ARTICLES',
  SEARCH_ENTITY_NFT = 'SEARCH_ENTITY_NFT',
  SEARCH_ENTITY_NFT_COLLECTIONS = 'SEARCH_ENTITY_NFT_COLLECTIONS',
  SEARCH_ENTITY_WALLET = 'SEARCH_ENTITY_WALLET',
  SEARCH_ENTITY_ENS = 'SEARCH_ENTITY_ENS',
  SEARCH_ENTITY_ENS_NAME = 'SEARCH_ENTITY_ENS_NAME',
  // SEARCH_ENTITY_QUERY is used for frontend only, used for saving query terms
  SEARCH_ENTITY_QUERY = 'SEARCH_ENTITY_QUERY',
  // client search entities
  SEARCH_ENTITY_TRANSACTION = 'SEARCH_ENTITY_TRANSACTION',
  SEARCH_ENTITY_KEY_ACTION = 'SEARCH_ENTITY_KEY_ACTION',
  SEARCH_ENTITY_SETTING = 'SEARCH_ENTITY_SETTING',
}

export type SearchEntityType = keyof typeof SearchEntity;

export type SearchField = {
  key: string;
  value: string;
};

export type SearchResponse = {
  type: SearchEntity | GlobalSearchEntity;
  name: string;
  fields: SearchField[];
  id?: string;
  url?: string;
};

export type GetSearchResponse = {
  entries: SearchResponse[];
};

export enum GlobalSearchEntity {
  SEARCH_ENTITY_ASSET = 'SEARCH_ENTITY_ASSET',
  SEARCH_ENTITY_DAPP = 'SEARCH_ENTITY_DAPP',
  SEARCH_ENTITY_NFT = 'SEARCH_ENTITY_NFT',
  SEARCH_ENTITY_NFT_COLLECTIONS = 'SEARCH_ENTITY_NFT_COLLECTIONS',
  SEARCH_ENTITY_ENS = 'SEARCH_ENTITY_ENS',
  // client search entities
  SEARCH_ENTITY_SETTING = 'SEARCH_ENTITY_SETTING',
  // unspecified
  SEARCH_ENTITY_UNSPECIFIED = 'SEARCH_ENTITY_UNSPECIFIED',
}

export type RecentSearch<TEntity extends GlobalSearchEntity | SearchEntity> = {
  id?: string;
  key: string;
  assetName: string;
  assetImageSrc: string | undefined;
  searchEntity: TEntity;
  navigate?: () => void;
  navigationProps?: object;
  navigationPath?: string;
  assetCurrencyCode?: string;
  assetNetworkBadge?: string;
};

export type AllSearchEntity =
  | SearchEntity.SEARCH_ENTITY_DAPP
  | SearchEntity.SEARCH_ENTITY_ASSET
  | SearchEntity.SEARCH_ENTITY_NFT_COLLECTIONS
  | SearchEntity.SEARCH_ENTITY_ENS
  | SearchEntity.SEARCH_ENTITY_SETTING
  | SearchEntity.SEARCH_ENTITY_KEY_ACTION
  | SearchEntity.SEARCH_ENTITY_TRANSACTION
  | SearchEntity.SEARCH_ENTITY_UNSPECIFIED
  | SearchEntity.SEARCH_ENTITY_NFT
  | SearchEntity.SEARCH_ENTITY_DEFI
  | SearchEntity.SEARCH_ENTITY_LEARN_ARTICLES
  | SearchEntity.SEARCH_ENTITY_WALLET
  | SearchEntity.SEARCH_ENTITY_ENS_NAME
  | SearchEntity.SEARCH_ENTITY_QUERY;

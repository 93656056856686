import { ReactIntlErrorCode } from 'react-intl';
import { ActionType, ComponentType, logMetric, MetricType } from '@cbhq/client-analytics';

import { logDataEvent } from '../utils/log';

const reportedKeys: Record<string, boolean> = {};

export function logI18NMetric(err: any) {
  const errorCode = err?.code;
  if (errorCode !== ReactIntlErrorCode.MISSING_TRANSLATION) {
    return;
  }

  const { messageKey, locale } = extractI18nDataFromError(err);

  if (reportedKeys[messageKey]) {
    return;
  }

  if (locale !== 'en') {
    reportedKeys[messageKey] = true;

    logDataEvent('i18n_missing_translation', {
      loggingId: 'cd07c9f6-6507-483f-8c14-0ccaa3f6c58c',
      componentType: ComponentType.text,
      action: ActionType.render,
      language: locale,
      key: messageKey,
    });
  }
}

export function logNonProductionMissingTranslation(err: any) {
  const { messageKey, locale } = extractI18nDataFromError(err);
  logMetric({
    metricName: 'i18n.missing_translation_non_production',
    metricType: MetricType.count,
    value: 1,
    tags: {
      language: locale,
      key: messageKey,
    },
  });
}

function extractI18nDataFromError(err: any) {
  const message = (err?.message as string) ?? '';

  const regexForExtraction = /Missing message: "(?<messageKey>.*)" for locale "(?<locale>.*)"/;

  const extraction = message.match(regexForExtraction);

  return extraction?.groups ?? {};
}

import { PossibleUTXOBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
import { xpubKeyDerivationPathForBlockchain } from 'cb-wallet-data/chains/UTXO/common/config';
import { deriveAddressFromXpubKeyForBlockchain } from 'cb-wallet-data/chains/UTXO/common/deriveAddressFromXpubKey';
import { UTXOError } from 'cb-wallet-data/chains/UTXO/exceptions/UTXOError';
import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { saveAddresses } from 'cb-wallet-data/stores/Addresses/database';
import { Address } from 'cb-wallet-data/stores/Addresses/models/Address';
import { AddressType } from 'cb-wallet-data/stores/Addresses/models/AddressType';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';

export type CreateAddressesParams = {
  rawValue: PossibleUTXOBlockchainSymbol;
  blockchain: Blockchain;
  currencyCode: CurrencyCode;
  network: Network;
  addressType: AddressType;
  isChangeAddress: boolean;
  xpubKey: string;
  addressIndexes: bigint[];
  accountId: Account['id'];
  lastSyncedBlockheight: number;
};

export async function createAddresses({
  rawValue,
  blockchain,
  currencyCode,
  network,
  addressType,
  isChangeAddress,
  addressIndexes,
  xpubKey,
  accountId,
}: CreateAddressesParams): Promise<Address[]> {
  try {
    const deriveAddressFromXpubKey = deriveAddressFromXpubKeyForBlockchain[rawValue];
    const xpubKeyDerivationPath = xpubKeyDerivationPathForBlockchain[rawValue];

    const addresses = await Promise.all(
      addressIndexes.map(async function createUTXOAddress(addressIndex: bigint) {
        const address = await deriveAddressFromXpubKey(
          xpubKey,
          addressIndex,
          addressType,
          isChangeAddress,
          network.isTestnet,
        );

        const prefix = xpubKeyDerivationPath(addressType, network.isTestnet);

        if (!prefix) {
          throw UTXOError.unableToGenerateAddressDerivationPath;
        }

        const derivationPath = `${prefix}/${isChangeAddress ? 1 : 0}/${addressIndex}`;

        return new Address({
          index: addressIndex,
          address,
          balance: 0n,
          currencyCode,
          isChangeAddress,
          network,
          derivationPath,
          isUsed: false,
          blockchain,
          type: addressType,
          accountId,
        });
      }),
    );

    await saveAddresses(addresses);

    return addresses;
  } catch (error: unknown) {
    cbReportError({
      error: new Error(
        `Failed to create ${addressType.rawValue} addresses: ${(error as Error).message}`,
      ),
      context: 'utxo_address_creation',
      severity: 'error',
      isHandled: false,
    });

    throw error;
  }
}

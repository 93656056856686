import keyBy from 'lodash/keyBy';
import {
  BaseAssetError,
  ERC20MissingMetadataError,
  ERC20TokenError,
} from 'wallet-engine-signing/history/errors';
import { isFulfilled } from 'wallet-engine-signing/history/utils';

import {
  ERC20TokenBalanceResponse,
  ERC20TokenMetadataResponse,
  getAllBalancesForChain,
  getERC20Balance,
  getERC20Info,
  getERC20Infos,
  getEtherBalance,
  handleEtherBalanceResponse,
  V2SpamScoreThresholds,
} from './RPC';
import { ERC20Token, EthereumAddressConfig, EthereumAddressHistory } from './types';

const erc20MetadataMap = new Map<string, ERC20TokenMetadataResponse>();
let spamThresholds: V2SpamScoreThresholds;

export async function getBalancesForSupportedNetworks(
  addresses: EthereumAddressConfig[],
): Promise<EthereumAddressHistory[]> {
  const addressesByChainId = addresses.reduce((chainIdMap, address) => {
    const chainIdStr = address.chainId.toString();
    const addressesForChainId = chainIdMap[chainIdStr] || [];
    return {
      ...chainIdMap,
      [chainIdStr]: [...addressesForChainId, address],
    };
  }, {} as Record<string, EthereumAddressConfig[]>);

  const errorsByChainId: Record<string, Error[]> = {};

  const balanceResults = await Promise.allSettled(
    Object.entries(addressesByChainId).map(async ([chainId, addressConfigs]) => {
      // addressConfigs can be multiple addresses but on the same chainId
      const [{ isNudgeEnabled }] = addressConfigs;
      const balances = [];
      const errors = errorsByChainId[chainId] || [];

      if (isNudgeEnabled) {
        try {
          const balancesForNetwork = await getAllBalancesForChain(
            addressConfigs.map((address) => address.address),
            chainId,
          );
          balances.push(...balancesForNetwork);
        } catch (err: unknown) {
          errors.push(new ERC20TokenError((err as Error)?.message));
        }
      }

      errorsByChainId[chainId] = errors;

      return balances.map((balance) => ({
        ...balance,
        chainId: BigInt(chainId),
      }));
    }),
  );

  const successes = balanceResults
    .filter(isFulfilled)
    .map(({ value }) => value)
    .flat(1);

  await populateERC20Metadata(
    successes
      .flatMap((result) => result.balances)
      .filter((balance) => balance.contractAddress !== 'native'),
  );

  const balanceResultsByAddressAndChainIdMap = keyBy(successes, (result) => {
    return `${result.address.toLowerCase()}-${result.chainId.toString()}`;
  });

  const addressesWithBalances = addresses.map((addressConfig) => {
    const errors = [];
    const { address, chainId } = addressConfig;
    const result =
      balanceResultsByAddressAndChainIdMap[`${address.toLowerCase()}-${chainId.toString()}`];

    const erc20Balances = (result?.balances ?? [])
      .filter((balance) => balance.contractAddress !== 'native')
      .map((balance) =>
        formatERC20Metadata({
          ...balance,
          ...erc20MetadataMap.get(`${balance.chainId}-${balance.contractAddress}`),
        }),
      );

    const etherBalanceResult = result?.balances?.find(
      (balance) => balance.contractAddress === 'native',
    );

    let etherBalance = 0n;

    try {
      if (etherBalanceResult) {
        etherBalance = handleEtherBalanceResponse(etherBalanceResult.tokenBalance);
      } else {
        errors.push(new BaseAssetError('Native asset missing from balance response'));
      }
    } catch (e) {
      errors.push(
        new BaseAssetError(`Error fetching native asset balance: ${(e as Error).message}`),
      );
    }

    if (errorsByChainId[chainId.toString()]) {
      errors.push(...errorsByChainId[chainId.toString()]);
    }

    return {
      ...addressConfig,
      etherBalance,
      erc20Balances,
      errors,
      // TODO the three types below are only relevant to transactions. Need to decide
      // if we should split the AddressHistory type to be specific to transactions/balances
      unsyncedTransactions: [],
      spamScoreThresholds: {},
      addressMetadata: {},
    };
  });

  return addressesWithBalances;
}

export async function getBalancesForCustomNetworks(
  addresses: EthereumAddressConfig[],
): Promise<EthereumAddressHistory[]> {
  const addressesWithBalances = await Promise.all(
    addresses.map(async (addressConfig) => {
      const { address, chainId, erc20ContractAddresses = [], rpcUrl } = addressConfig;
      const erc20Balances = await Promise.all(
        erc20ContractAddresses.map(async (contractAddress) => {
          const errors = [];

          let erc20Balance = {
            balance: 0n,
            contractAddress,
            chainId,
            displayName: '',
            decimals: 0n,
            symbol: '',
          };

          try {
            erc20Balance.balance = await getERC20Balance(address, contractAddress, rpcUrl);
          } catch (e) {
            errors.push(new ERC20TokenError(`Error fetch ether balance: ${(e as Error).message}}`));
          }

          try {
            const erc20Info = await getERC20Info(contractAddress, rpcUrl);
            erc20Balance = {
              ...erc20Balance,
              decimals: BigInt(erc20Info.decimals),
              displayName: erc20Info.name,
              symbol: erc20Info.symbol,
            };
          } catch (e) {
            errors.push(
              new ERC20MissingMetadataError(`Missing critical metadata: ${(e as Error).message}`),
            );
          }

          return {
            ...erc20Balance,
            errors,
          };
        }),
      );

      const etherBalance = await getEtherBalance(address, rpcUrl);

      return {
        ...addressConfig,
        etherBalance,
        erc20Balances,
        errors: [],
        // TODO the three types below are only relevant to transactions. Need to decide
        // if we should split the AddressHistory type to be specific to transactions/balances
        unsyncedTransactions: [],
        spamScoreThresholds: {},
        addressMetadata: {},
      };
    }),
  );

  return addressesWithBalances;
}

function formatERC20Metadata(
  erc20: ERC20TokenBalanceResponse & ERC20TokenMetadataResponse,
): ERC20Token {
  const missingMetadata =
    erc20.decimals === undefined ||
    erc20.decimals < 0 ||
    isNaN(erc20.decimals) ||
    !erc20.name ||
    !erc20.symbol;
  const spamScore = erc20.spam_score;
  const isSpamScoreAvailable = spamScore !== undefined && !isNaN(spamScore) && !!spamThresholds;
  const isSpam = isSpamScoreAvailable && spamScore >= spamThresholds?.likely_spam;
  const errors = [];

  if (erc20.errorMessage) {
    errors.push(new ERC20TokenError(erc20.errorMessage));
  }

  if (missingMetadata) {
    errors.push(new ERC20MissingMetadataError('Missing critical metadata'));
  }

  return {
    balance: BigInt(erc20.tokenBalance),
    chainId: BigInt(erc20.chainId),
    contractAddress: erc20.contractAddress,
    decimals: BigInt(erc20.decimals ?? 0),
    displayName: erc20.name || '',
    symbol: erc20.symbol || '',
    assetUUID: erc20.assetUUID,
    imageURL: erc20.imageUrl,
    isSpam,
    errors,
  };
}

async function populateERC20Metadata(erc20Balances: ERC20TokenBalanceResponse[]): Promise<void> {
  const erc20sWithoutMetadata = erc20Balances
    .filter((erc20Balance: ERC20TokenBalanceResponse) => {
      if (!erc20Balance) {
        return false;
      }
      const { chainId, contractAddress } = erc20Balance;
      return !erc20MetadataMap.has(`${chainId}-${contractAddress}`);
    })
    .reduce((addressesByChain, { chainId, contractAddress }: ERC20TokenBalanceResponse) => {
      const contractAddressesByChain = addressesByChain[chainId] || [];
      return {
        ...addressesByChain,
        [chainId]: contractAddressesByChain.concat([contractAddress]),
      };
    }, {} as Record<number, string[]>);

  await Promise.all(
    Object.entries(erc20sWithoutMetadata).map(async ([chainId, contractAddresses]) => {
      const { tokens, spamScoreThresholds } = await getERC20Infos(
        contractAddresses,
        BigInt(chainId),
      );

      spamThresholds = spamScoreThresholds;

      tokens.forEach((token) => {
        erc20MetadataMap.set(`${chainId}-${token.address}`, token);
      });
    }),
  );
}

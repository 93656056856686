import { isFeatureEnabledStore } from 'cb-wallet-data/FeatureManager/classes/IsFeatureEnabledStore';
import { Span } from '@cbhq/client-analytics';

import { TraceName, TracerInterface } from '../types';

import { DisabledTracer } from './DisabledTracer';
import { EnabledTracer } from './EnabledTracer';

/**
 * Tracer
 */
export class Tracer implements TracerInterface {
  #instance: TracerInterface;

  /**
   * constructor
   *
   * Creates an instance of Tracer
   */
  constructor(traceName: TraceName) {
    const isEnabled = isFeatureEnabledStore.getIsFeatureEnabled('fe_tracing');
    this.#instance = isEnabled ? new EnabledTracer(traceName) : new DisabledTracer();
  }

  /**
   * startSpan
   */
  startSpan(spanName: Span['name']): Span | undefined {
    return this.#instance.startSpan(spanName);
  }

  /**
   * endSpan
   */
  endSpan(spanName: Span['name']): Span | undefined {
    return this.#instance.endSpan(spanName);
  }

  /**
   * endSpan
   */
  setMetadata(args: { spanName?: Span['name']; metadata: Span['meta'] }): Span | undefined {
    return this.#instance.setMetadata(args);
  }

  /**
   * done
   */
  done(): undefined {
    return this.#instance.done();
  }
}

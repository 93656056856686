import { secp256k1KeyPair } from 'wallet-engine-signing/cipherCore/cipherCore';

import { Address } from './Address';
import { getSecp256k1 } from './getSecp256k1';
import { MultiKeySigningParams } from './types';
import { bufferFromBN, hexStringFromBuffer, keccak256 } from './utils';

export const ETHEREUM_MESSAGE_PREFIX = Buffer.from('\x19Ethereum Signed Message:\n', 'utf8');

export class Message {
  private _message: Buffer;

  private _prefix: Buffer | null = null;

  constructor(message: Buffer, prefix: Buffer | null = ETHEREUM_MESSAGE_PREFIX) {
    this._message = message;
    this._prefix = prefix;
  }

  public get message() {
    return this._message;
  }

  public get prefixedMessage(): Buffer {
    if (!this._prefix) {
      return this._message;
    }

    return keccak256(
      Buffer.concat([
        this._prefix,
        Buffer.from(String(this._message.length), 'utf8'),
        this._message,
      ]),
    );
  }

  public async sign(privateKey: Buffer): Promise<Buffer> {
    const secp256k1 = await getSecp256k1();
    const sig = secp256k1
      .keyFromPrivate(privateKey)
      .sign(this.prefixedMessage, { canonical: true });

    return Message.buildSignatureBuffer(sig);
  }

  public static buildSignatureBuffer(
    signature: { r: BN; s: BN; recoveryParam: number | null },
    useRecoveryParamVerbatim = false,
  ) {
    const sigBuf = Buffer.alloc(65);
    let offset = 0;

    // copy r
    const r = bufferFromBN(signature.r);
    offset += 32 - r.length;

    offset += r.copy(sigBuf, offset);

    // copy s
    const s = bufferFromBN(signature.s);
    offset += 32 - s.length;

    offset += s.copy(sigBuf, offset);

    // copy v
    if (useRecoveryParamVerbatim) {
      sigBuf.writeUInt8(signature.recoveryParam!, offset);
    } else {
      const recoveryParam: number = signature.recoveryParam ?? 0;
      sigBuf.writeUInt8(recoveryParam + 27, offset);
    }
    return sigBuf;
  }

  public static recoverRSVFromBuffer(signature: Buffer) {
    if (signature.length !== 65) {
      throw new Error('Invalid signature');
    }
    return {
      r: hexStringFromBuffer(signature.slice(0, 32)),
      s: hexStringFromBuffer(signature.slice(32, 64)),
      v: signature[64],
    };
  }

  public async ecRecover(signature: Buffer): Promise<string> {
    if (signature.length !== 65) {
      throw new Error('Invalid signature');
    }
    const secp256k1 = await getSecp256k1();
    const recoveryParam = signature[signature.length - 1] - 27;
    const sig = {
      r: signature.slice(0, 32),
      s: signature.slice(32, 64),
    };
    const point = secp256k1.recoverPubKey(this.prefixedMessage, sig, recoveryParam);
    const pubKey = Buffer.from(point.encode('hex', true), 'hex');
    return Address.from(pubKey).getAddress();
  }
}

export type SignEthereumMessageBaseParams = { message: Buffer; addPrefix?: boolean };
type SignEthereumMessageParams = MultiKeySigningParams<SignEthereumMessageBaseParams>;

export async function signEthereumMessage({
  message,
  derivationPath,
  seed,
  privateKey,
  addPrefix = true,
}: SignEthereumMessageParams): Promise<Buffer> {
  let privateKeyBuffer: Buffer;

  if (!privateKey) {
    const { privateKey: privateKeyFromSeed } = await secp256k1KeyPair(seed, derivationPath);
    privateKeyBuffer = privateKeyFromSeed;
  } else {
    privateKeyBuffer = privateKey;
  }

  const m = addPrefix ? new Message(message) : new Message(message, null);
  return m.sign(privateKeyBuffer);
}

export async function ethereumAddressFromSignedMessage(
  message: Buffer,
  signature: Buffer,
  addPrefix = true,
): Promise<string> {
  const m = addPrefix ? new Message(message) : new Message(message, null);
  return m.ecRecover(signature);
}

import { atomLocalStorageEffect } from 'cb-wallet-data/utils/atomLocalStorageEffect';
import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { atom } from 'recoil';

const StoreKeys_isExperimentModeEnabled = new LocalStorageStoreKey<boolean>(
  'isExperimentModeEnabled',
);

export const isExperimentModeEnabledAtom = atom({
  key: 'isExperimentModeEnabled',
  default: false,
  effects: [atomLocalStorageEffect(StoreKeys_isExperimentModeEnabled)],
});

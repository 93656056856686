import { useState } from 'react';

import { useOnMount } from './useOnMount';

/**
 * Return a boolean representing whether a certain amount of time has
 * passed since the hook was mounted
 */
export function useDelayedTrue(delay: number) {
  const [bool, setBool] = useState(false);

  useOnMount(
    // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
    // eslint-disable-next-line wallet/no-anonymous-params
    () => {
      const timeout = setTimeout(() => {
        setBool(true);
      }, delay);

      return () => clearTimeout(timeout);
    },
  );

  return bool;
}

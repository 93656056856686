import { ActionType, ComponentType } from '@cbhq/client-analytics';

import { useLogEventOnMount } from '../utils/log';

export function useTriggerAppStart() {
  useLogEventOnMount('app_start', {
    action: ActionType.view,
    componentType: ComponentType.page,
    loggingId: '05422143-ed86-4cca-8342-fa56aa36170f',
  });
}

import { defineMessages, useIntl } from 'react-intl';
import { Button } from 'wallet-cds-web/components/Button';

import { useConnectScwCoinbase } from ':dapp/connection/hooks/useConnectScwCoinbase';

type CreateButtonProps = {
  isPhone?: boolean;
};

const messages = defineMessages({
  createCta: {
    defaultMessage: 'Create',
    description: 'Short cta, prepended',
  },
  walletText: {
    defaultMessage: 'a wallet',
    description: 'rest of cta for button',
  },
});

export function CreateButton({ isPhone }: CreateButtonProps) {
  const { loading, handleConnect } = useConnectScwCoinbase();
  const { formatMessage } = useIntl();

  return (
    <Button
      testID="navbar-create-wallet-btn"
      variant="secondary"
      compact
      onClick={handleConnect}
      loading={loading}
    >
      {formatMessage(messages.createCta)}
      {!isPhone ? ` ${formatMessage(messages.walletText)}` : ''}
    </Button>
  );
}

import { PossibleUTXOBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
import { deriveAddressFromXpubKeyForBlockchain } from 'cb-wallet-data/chains/UTXO/common/deriveAddressFromXpubKey';

import { XPubKeyResponse } from '../models/WalletConnectionResponse';

type DeriveDefaultAddressForXPubKeyParams = {
  xpubKey: XPubKeyResponse;
  chain: PossibleUTXOBlockchainSymbol;
};

export async function deriveDefaultAddressForXPubKey({
  xpubKey,
  chain,
}: DeriveDefaultAddressForXPubKeyParams) {
  const deriveAddressFromXpubKey = deriveAddressFromXpubKeyForBlockchain[chain];
  return deriveAddressFromXpubKey(xpubKey.xPubKey, 0n, xpubKey.xPubType, false, false);
}

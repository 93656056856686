import { idbRequest } from './idbRequest';

type IDBConnectParams = {
  /**
   * The name of the IndexedDB database to be created or connected to.
   */
  dbName: string;

  /**
   * The version of the IndexedDB database.
   *
   * If the version is greater than the existing database version, the `onupgradeneeded` event is triggered.
   */
  dbVersion: number;

  /**
   * The name of the object store within the IndexedDB database.
   */
  storeName: string;

  /**
   * Optional params used only if the object store needs to be created via `IDBDatabase.createObjectStore`.
   *
   * Defaults to passing through `undefined`.
   */
  createObjectStoreOptions?: IDBObjectStoreParameters;

  /**
   * Optional callback that returns the object store that is invoked if the DB's store
   * had to be created.
   *
   * This is useful is you need to create indexes on the object store since this function does not
   * make any assumptions beyond creating a store within the DB with the given name.
   */
  onObjectStoreCreated?: (objectStore: IDBObjectStore) => void;
};

/**
 * Creates and returns a connection to the IndexedDB database. If the database and/or its store does not exist, they will be created.
 */
export async function idbConnect(params: IDBConnectParams): Promise<IDBDatabase> {
  const { dbName, dbVersion, storeName, createObjectStoreOptions, onObjectStoreCreated } = params;

  const request = indexedDB.open(dbName, dbVersion);
  request.onupgradeneeded = function idbConnectOnUpgradeNeeded() {
    const { result: dbConnection } = request;

    if (!dbConnection.objectStoreNames.contains(storeName)) {
      const objectStore = dbConnection.createObjectStore(storeName, createObjectStoreOptions);

      onObjectStoreCreated?.(objectStore);
    }
  };

  const db = await idbRequest(request);
  return db;
}

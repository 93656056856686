import { logTxConfirmed, logTxDroppedByRelayer } from 'cb-wallet-analytics/data/Transactions';
import {
  logStatusCheckCompleted,
  logStatusCheckFailed,
  logStatusCheckStarted,
} from 'cb-wallet-analytics/relayed-operations';
import { AllPossibleBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
import { cbReportError } from 'cb-wallet-data/errors/reportError';
import {
  GaslessSwapStatusCheckResponse,
  getGaslessSwapTxStatus,
  getSponsoredTxStatus,
  PayoutServiceStatusCheckResponse,
} from 'cb-wallet-data/stores/Transactions/api';
import {
  persistTxOrUserOp,
  retrievePendingRelayTransactions,
  retrieveTransactionsMatchingHash,
} from 'cb-wallet-data/stores/Transactions/database';
import { GaslessTxState } from 'cb-wallet-data/stores/Transactions/models/GaslessTxState';
import { SponsoredTxState } from 'cb-wallet-data/stores/Transactions/models/SponsoredTxState';
import { TxOrUserOp } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import { TxRelayType } from 'cb-wallet-data/stores/Transactions/models/TxRelayType';
import { TxState } from 'cb-wallet-data/stores/Transactions/models/TxState';

/**
 * processPendingRelayedTransactions
 * 1. fetches `pending` transactions from `tx_history_v2` table where type is `sponsored` or `gasless`. we now have a
 * list of pending relayed operations
 * 2. for each tx object with a `sponsoredTxId` property, check the sponsoredtxstatus endpoint for the status
 * of that pending relay operation. store status check results in `sponsoredStatusChecks`.
 * 3. for each tx object with a `gaslessSwapHash` property, check the gaslessswapstatus endpoint for the status of that
 * pending relay operation. store status check results in `gaslessStatusChecks`.
 * 4. pass sponsored txs and sponsored tx status results to `processPendingRelayedHelper`. this function will handle
 * checking if the txState of each transaction needs to be updated based on status check result
 */

export async function processPendingRelayedTransactions(): Promise<TxOrUserOp[]> {
  const txs = (await retrievePendingRelayTransactions({})) ?? [];
  // We don't expect more than one or two transactions to be available at a time
  // so this won't turn into a performance issue

  const sponsoredStatusChecks = txs
    .filter((tx) => tx.sponsoredTxId)
    .map(async (tx) => getSponsoredTxStatus(tx.id, tx.sponsoredTxId!));
  const gaslessStatusChecks = txs
    .filter((tx) => tx.gaslessSwapHash)
    .map(async (tx) =>
      getGaslessSwapTxStatus(
        tx.id,
        tx.network.asChain()?.chainId?.toString() ?? '1',
        tx.gaslessSwapHash!,
      ),
    );

  const updatedTxs = [];

  if (sponsoredStatusChecks.length) {
    const updatedSponsoredTxs = await processPendingRelayedHelper(
      txs.filter((tx) => tx.isSponsored()),
      sponsoredStatusChecks,
      TxRelayType.SEND,
    );
    updatedTxs.push(...updatedSponsoredTxs);
  }

  if (gaslessStatusChecks.length) {
    const updatedGaslessTxs = await processPendingRelayedHelper(
      txs.filter((tx) => tx.isGaslessSwap()),
      gaslessStatusChecks,
      TxRelayType.SWAP,
    );
    updatedTxs.push(...updatedGaslessTxs);
  }

  return updatedTxs;
}

/**
 * Checks if the txState needs to be updated for each pending transaction passed in
 * @param txs list of pending transactions associated with a relayed operation
 * @param statusChecks list of associated status check promises
 * @param relayType is this a sponsored send or gasless swap
 */
async function processPendingRelayedHelper(
  txs: TxOrUserOp[],
  statusChecks: Promise<PayoutServiceStatusCheckResponse | GaslessSwapStatusCheckResponse>[],
  relayType: TxRelayType,
): Promise<TxOrUserOp[]> {
  const updatedTxs = [];

  if (txs.length) {
    logStatusCheckStarted(txs.length, relayType);
    try {
      const promises = await Promise.allSettled(statusChecks);
      if (relayType === TxRelayType.SEND) {
        const payoutServicePromises =
          promises as PromiseSettledResult<PayoutServiceStatusCheckResponse>[];
        const updatedSponsoredTxs = await processSponsoredStatusPromises(
          txs,
          payoutServicePromises,
        );
        updatedTxs.push(...updatedSponsoredTxs);
      } else if (relayType === TxRelayType.SWAP) {
        const gaslessSwapPromises =
          promises as PromiseSettledResult<GaslessSwapStatusCheckResponse>[];
        const updatedGaslessTxs = await processGaslessStatusPromises(txs, gaslessSwapPromises);
        updatedTxs.push(...updatedGaslessTxs);
      } else {
        throw new Error(
          `Attempted to check status for unsupported relay transaction type ${relayType}`,
        );
      }
    } catch (err) {
      const unexpectedError = new Error(
        `Unexpected error getting status from ${
          relayType === TxRelayType.SEND ? 'payout' : 'swap'
        } service`,
      );

      cbReportError({
        error: unexpectedError,
        context: relayType === TxRelayType.SEND ? 'sponsored_tx' : 'gasless_swap_tx',
        severity: 'error',
        isHandled: false,
        metadata: { error: (err as Error)?.message },
      });

      logStatusCheckFailed(unexpectedError.message, relayType);
    } finally {
      logStatusCheckCompleted(relayType);
    }
  }

  return updatedTxs;
}

/**
 * Loops through all the inputted pending `txs` and associated status check results, and checks to see if each given
 * operation/tx has yet been included in mempool or within a block, and updates txState accordingly.
 *
 * When we create a normal ethereum transaction, we create an entry in both the `tx_history_v2` and
 * `ethereum_signed_tx` tables. `ethereum_signed_tx` table only includes pending transactions and is the source of
 * truth for which transactions we should attempt to resubmit to a blockchain.
 *
 * When we create a transaction row to represent an operation that will be relayed via payout service, we populate the
 * `txHash` column with a token value returned by payout service when we submitted the operation for relaying.
 *
 * When we submit an operation to a relayer, we don't create an entry in the `ethereum_signed_tx` table. The way we
 * record `TX_FAILED` and `TX_DROPPED` depends on comparing what is in `ethereum_signed_tx` vs `tx_history_v2`. Thus
 * we have no means for recording `TX_FAILED` and `TX_DROPPED` events for relayed operations.
 *
 * As soon as a txhash exists for a transaction that will include this relayed operation, we want to update the `txHash`
 * of this transaction to be the real `txHash`. We also want to soft delete the client side created transaction object
 * and favor using the transaction object returned by our txhistory service instead.
 *
 * We soft delete the transaction to avoid the user seeing an error if they are on a transaction history page that is
 * backed by the first client side created `tx_history_v2` row associated with the relayed operation.
 * @param txs list of pending transactions associated with a sponsored relayed operation
 * @param promises list of associated results for status checks on those pending relayed operations
 */
export async function processSponsoredStatusPromises(
  txs: TxOrUserOp[],
  promises: PromiseSettledResult<PayoutServiceStatusCheckResponse>[],
): Promise<TxOrUserOp[]> {
  const updatedTxs = [];

  for (const result of promises) {
    // Error performing status check, report and continue
    if (result.status === 'rejected') {
      const txRejectedError = new Error('Failed to get status from payout service');
      cbReportError({
        error: txRejectedError,
        context: 'sponsored_tx',
        severity: 'error',
        isHandled: false,
        metadata: { reason: result.reason },
      });

      logStatusCheckFailed(txRejectedError.message, TxRelayType.SEND);
      continue;
    }

    if (result.value.State === SponsoredTxState.SAVED.valueOf()) {
      // still processing on payout service
      continue;
    }

    // Error occurred on payout service, we mark as failed on local DB
    if (result.value.State === SponsoredTxState.FAILED.valueOf()) {
      const txToUpdate = txs.find((t) => t.sponsoredTxId === result.value.PayoutServiceTokenId);
      if (txToUpdate) {
        // eslint-disable-next-line no-await-in-loop
        const updatedTx = await updateTxState(txToUpdate, TxState.DROPPED_BY_RELAYER);
        if (updatedTx) updatedTxs.push(updatedTx);
      }

      continue;
    }

    const validSubmissionStates = [
      SponsoredTxState.SUBMITTED.valueOf(), // submitted to the mempool, but not yet mined
      SponsoredTxState.IN_BLOCK.valueOf(), // submitted and mined
    ];

    if (validSubmissionStates.includes(result.value.State)) {
      const txToDelete = txs.find((t) => t.sponsoredTxId === result.value.PayoutServiceTokenId);

      if (txToDelete) {
        // eslint-disable-next-line no-await-in-loop
        const matchingTx = await retrieveTransactionsMatchingHash({
          hash: result.value.TransactionId,
          blockchainSymbol: txToDelete.blockchain.rawValue as AllPossibleBlockchainSymbol,
        });

        if (matchingTx.length) {
          // eslint-disable-next-line no-await-in-loop
          const updatedTx = await updateTxState(txToDelete, TxState.CONFIRMED);
          if (updatedTx) updatedTxs.push(updatedTx);
        } else if (result.value.CurrentChainHeight - result.value.BlockHeight > 10) {
          // If more than 10 blocks have passed since the tx was mined,
          // and it's still not showing up in txHistory, keep track of the anomaly
          logStatusCheckFailed(
            'Sponsored transaction was mined but not found on tx history',
            TxRelayType.SEND,
          );
        }
      }
    }
  }

  return updatedTxs.filter(Boolean);
}

/**
 * Loops through each tx and associated status result, and:
 *
 * 1. checks if we need to update the txstate to `FAILED`
 * 2. checks if we should update the txstate for each tx to `CONFIRMED`. if yes, then we should also soft delete the
 * client side created tx in favor of the tx returned by our txhistory service.
 * @param txs list of pending relayed operations/txs
 * @param promises list of status results associated with the `txs` informing us if the tx is still pending, has been
 * included in mempool, or included in a block.
 */
export async function processGaslessStatusPromises(
  txs: TxOrUserOp[],
  promises: PromiseSettledResult<GaslessSwapStatusCheckResponse>[],
): Promise<TxOrUserOp[]> {
  const updatedTxs = [];

  for (const result of promises) {
    // Error performing status check, report and continue
    if (result.status === 'rejected') {
      const txRejectedError = new Error('Failed to get status from swap service');
      cbReportError({
        error: txRejectedError,
        context: 'gasless_swap_tx',
        severity: 'error',
        isHandled: false,
        metadata: { reason: result.reason },
      });

      // TODO: we should create a bucket error under 'relayed_tx_failed' for analytics
      logStatusCheckFailed(txRejectedError.message, TxRelayType.SWAP);
      continue;
    }

    if (result.value.status === GaslessTxState.SUBMITTED) {
      // still processing on zeroex service
      continue;
    }

    // Error occurred on zeroex service, we mark as failed on local DB
    if (result.value.status === GaslessTxState.FAILED) {
      const txToUpdate = txs.find((t) => t.gaslessSwapHash === result.value.tradeHash);

      if (txToUpdate) {
        // eslint-disable-next-line no-await-in-loop
        const updatedTx = await updateTxState(txToUpdate, TxState.DROPPED_BY_RELAYER);
        if (updatedTx) updatedTxs.push(updatedTx);
      }

      continue;
    }

    const validSubmissionStates = [
      GaslessTxState.SUCCEEDED, // submitted to the mempool, but not yet mined
      GaslessTxState.CONFIRMED, // submitted and mined
    ];

    if (validSubmissionStates.includes(result.value.status)) {
      const txToDelete = txs.find((t) => t.gaslessSwapHash === result.value.tradeHash);

      if (txToDelete) {
        // eslint-disable-next-line no-await-in-loop
        const matchingTx = await retrieveTransactionsMatchingHash({
          hash: result.value.transactions[0]?.hash,
          blockchainSymbol: txToDelete.blockchain.rawValue as AllPossibleBlockchainSymbol,
        });

        // when we call updateTxState to `CONFIRMED`, that function also marks the tx as soft deleted (ie deleted=true)
        if (matchingTx.length) {
          // eslint-disable-next-line no-await-in-loop
          const updatedTx = await updateTxState(txToDelete, TxState.CONFIRMED);
          if (updatedTx) updatedTxs.push(updatedTx);
        }
      }
    }
  }

  return updatedTxs.filter(Boolean);
}

/**
 * If we are updating the transaction to state=CONFIRMED, we also mark the transaction as soft deleted by marking
 * deleted=true so that we only display the transaction returned by txhistoryservice and not the client side created
 * transaction associated with the relayed operation.
 * @param txToUpdate transaction object to be updated
 * @param state the new value of `state` column for transaction row
 */
export async function updateTxState(
  txToUpdate: TxOrUserOp,
  state: TxState,
): Promise<TxOrUserOp | undefined> {
  if (state === TxState.DROPPED_BY_RELAYER) {
    const dmo = txToUpdate.asDMO;
    dmo.state = TxState.DROPPED_BY_RELAYER.valueOf();
    const updatedTx = TxOrUserOp.fromDMO(dmo);
    await persistTxOrUserOp(updatedTx);

    logTxDroppedByRelayer({
      txSource: txToUpdate.txSource,
      txHash: txToUpdate.txHash ?? '',
      blockchain: txToUpdate.blockchain.rawValue,
      chainId: txToUpdate.network.asChain()?.chainId ?? -1,
      chainName: txToUpdate.network.asChain()?.displayName ?? '',
      isSponsoredTx: txToUpdate.isSponsored(),
      isGaslessSwap: txToUpdate.isGaslessSwap(),
    });

    return updatedTx;
  }

  if (state === TxState.CONFIRMED) {
    // As long as txHistory has returned a tx with the same hash,
    // we can (soft) delete the pending tx
    const dmo = txToUpdate.asDMO;
    dmo.deleted = true;
    dmo.state = TxState.CONFIRMED.valueOf();
    const updatedTx = TxOrUserOp.fromDMO(dmo);
    await persistTxOrUserOp(updatedTx);

    logTxConfirmed({
      txSource: txToUpdate.txSource,
      txHash: txToUpdate.txHash ?? '',
      blockchain: txToUpdate.blockchain.rawValue,
      isContractDeploy: false,
      chainId: txToUpdate.network.asChain()?.chainId,
      chainName: txToUpdate.network.asChain()?.displayName,
      isSponsoredTx: txToUpdate.isSponsored(),
      isGaslessSwap: txToUpdate.isGaslessSwap(),
      isFirstPartyRPC: 'false',
    });
    return updatedTx;
  }
}

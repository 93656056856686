import { createContext, useCallback, useContext, useMemo, useState } from 'react';

export type GlobalEventMetadataContextValue = {
  dappProviderUserId?: string;
  setDappProviderUserId: (id?: string) => void;
};

const INITIAL_STATES: GlobalEventMetadataContextValue = {
  dappProviderUserId: undefined,
  setDappProviderUserId: () => {},
};

const GlobalEventMetadataContext = createContext<GlobalEventMetadataContextValue>(INITIAL_STATES);

type GlobalEventMetadataProviderProps = {
  children: React.ReactNode;
};

export function GlobalEventMetadataProvider({ children }: GlobalEventMetadataProviderProps) {
  const [dappProviderUserId, setDappProviderUserId] = useState<string>();

  const setUserId = useCallback((id?: string) => setDappProviderUserId(id || 'N/A'), []);

  const value = useMemo(
    () => ({
      dappProviderUserId,
      setDappProviderUserId: setUserId,
    }),
    [dappProviderUserId, setUserId],
  );

  return (
    <GlobalEventMetadataContext.Provider value={value}>
      {children}
    </GlobalEventMetadataContext.Provider>
  );
}

export function useGlobalEventMetadataContext() {
  return useContext(GlobalEventMetadataContext);
}

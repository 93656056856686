import { AddressType } from 'cb-wallet-data/stores/Addresses/models/AddressType';

export class UTXOError extends Error {
  private constructor(readonly name: string, readonly message: string) {
    super(message);
  }

  /**
   * Thrown when unable to derive UTXO with given addressType/isTestnet
   */
  static unableToDeriveXpubKey = (addressType: AddressType, isTestnet: boolean) =>
    new UTXOError('UnableToDeriveXpubKey', `Unable to derive xpub key ${addressType}/${isTestnet}`);

  /**
   * Thrown when unable to generate UTXO derivation path
   */
  static unableToGenerateAddressDerivationPath = new UTXOError(
    'UnableToGenerateAddressDerivationPath',
    'Unable to generate derivation path',
  );

  /**
   * Fee estimation failed. This may happen if WalletEngine does not give us the expected number of fee rate options.
   */
  static unableToEstimateFee = new UTXOError('UnableToEstimateFee', '');

  /**
   * There is no change address stored
   */
  static changeAddressNotFound = new UTXOError('ChangeAddressNotFound', '');
}

export enum UnsupportedTronTransactionProvider {
  BRIDGE_XYZ = 'bridgexyz',
}

/**
 * The status flow of the transaction is determined by the raw string received from Bridge.xyz's webhook,
 * which is grouped by UI status and then routed from the backend to the frontend via PTS.
 *
 * Status flow for different scenarios:
 *
 * - No KYC Required:
 *   payment_pending -> payment_processed -> success
 *
 * - KYC Required and successful:
 *   kyc_required -> kyc_in_review -> payment_processed -> success
 *
 * - KYC required and failed:
 *   kyc_required -> kyc_in_review -> undeliverable -> wait 0-3 business days -> refunded (not returned anymore by PTS)
 *
 * - KYC never completed:
 *   kyc_required -> wait 30 days -> refunded (not returned anymore by PTS)
 *
 * Note: When a transaction gets returned, it'll be returned to the original tron wallet and it won't be shown
 * anymore on CB Wallet.
 */
export type UnsupportedTronTransactionStatus =
  | 'payment_pending'
  | 'kyc_required'
  | 'kyc_in_review'
  | 'kyc_failed'
  | 'undeliverable'
  | 'payment_processed'
  // "success" is not a status returned by Bridge.xyz, but we consider a transaction as "success"
  // when it gets returned by the TxHistory service (i.e. it's confirmed on chain)
  | 'success'
  // "unknown" is not a status returned by Bridge.xyz, but we consider a transaction as "unknown"
  // when we received a status that is not supported by FE. This may happen if Bridge.xyz/BE introduces
  // a new status that we haven't handled yet.
  | 'unknown';

/**
 * Type returned from PTS used to store information about a Tron transaction that is not supported
 * by the wallet app yet.
 */
export type UnsupportedTronTransaction = {
  providerName: string;
  detailedStatus: UnsupportedTronTransactionStatus;
  updatedAt: string;
  originalAssetNetworkId: string;
  originalAssetContractAddress: string;
  originalSenderAddress: string;
  originalTxnHash: string;
  originalAssetAmount?: string;
  destinationAssetNetworkId: string;
  destinationAssetContractAddress: string;
  destinationReceiverAddress: string;
  destinationTxnHash: string;
  destinationAssetAmount: string;
  providerKycLink: string;
  providerReceiptLink: string;
};

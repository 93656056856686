import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { logSmartWalletSignUp, useViewSmartWalletSignUp } from 'cb-wallet-analytics/wallet-connection';
import { GRADIENT_CLASS } from 'wallet-cds-web/components/GradientButton';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { PressableOpacity } from '@cbhq/cds-web/system';
import { TextBody, TextHeadline } from '@cbhq/cds-web/typography';
import { cx } from '@cbhq/cds-web/utils/linaria';
import { ConnectWalletOptions, WalletProviderInfo } from ':dapp/connection/types';
import { COINBASE_SMART_WALLET } from ':dapp/connection/wallet-providers/coinbase-wallet';
const messages = defineMessages({
  description: {
    defaultMessage: 'The fastest and easiest way to get started',
    description: 'Description of the smart wallet upsell'
  },
  signUp: {
    defaultMessage: 'Sign up',
    description: 'Text telling the user to sign up'
  }
});
const btnClass = "b160ctg3";
type SmartWalletSignupButtonProps = {
  onSelect: (walletProvider: WalletProviderInfo, options?: ConnectWalletOptions) => void;
};
export function SmartWalletSignupButton({
  onSelect
}: SmartWalletSignupButtonProps) {
  const {
    formatMessage
  } = useIntl();
  useViewSmartWalletSignUp();
  const handleSelect = useCallback(() => {
    logSmartWalletSignUp();
    onSelect(COINBASE_SMART_WALLET, {
      scwOnboardMode: 'create'
    });
  }, [onSelect]);
  return <Box spacing={2}>
      <PressableOpacity onPress={handleSelect} className={cx(GRADIENT_CLASS, btnClass)} width="100%" testID="smart-wallet-signup-button">
        <Box flexDirection="column" alignItems="center" justifyContent="center" spacing={3}>
          <HStack gap={1} alignItems="center" justifyContent="center">
            <Icon name="followInactive" size="l" color="foreground" />
            <VStack justifyContent="center" spacingStart={1}>
              <TextHeadline as="h3">{formatMessage(messages.signUp)}</TextHeadline>
              <TextBody as="p" color="foregroundMuted">
                {formatMessage(messages.description)}
              </TextBody>
            </VStack>
          </HStack>
        </Box>
      </PressableOpacity>
    </Box>;
}

require("./SmartWalletSignupButton.linaria.module.css!=!../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./SmartWalletSignupButton.tsx");
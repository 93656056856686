/**
 * Represents a signed transaction. Updates to the SolanaSignedTx table feed into TxHistoryRepository and TxHistoryRepo
 * channels those updates to the Transaction table that is the source of truth for transaction history.
 *
 * SolanaSignedTx is solely for keeping track of pending transactions and resubmissions.
 *
 * @property id Signed transaction ID
 * @property fromAddress From address
 * @property toAddress Recipient solana address
 * @property chainId solana chain Id
 * @property signedTxData Signed transaction data
 * @property txHash Signed transaction hash
 * @property transferValue The solana transfer value
 * @property extrafee The extra fee required to create a new account
 * @property blockchain The blockchain code
 * @property currencyCode The currency code for this tx
 * @property state Determine the transaction state
 * @property notFoundCount Total number of `NotFound` status returned from blockchain
 */ import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { Persistable } from 'cb-wallet-data/persistence/Database.interface';
import { SignedTx } from 'cb-wallet-data/stores/Transactions/interfaces/SignedTx';
import { TxState } from 'cb-wallet-data/stores/Transactions/models/TxState';
import { uint8ArrayTransformer } from 'cb-wallet-data/utils/dbTransformers';
import { Column, Entity, Index, PrimaryColumn } from '@cbhq/typeorm';

@Entity('solana_signed_tx')
@Index('IDX_SOL_STATE_CHAIN', ['state', 'chainId'])
@Index('IDX_SOL_HASH_CHAIN', ['txHash', 'chainId'])
@Index('IDX_SOL_HASH_CHAIN_ADDR', ['txHash', 'chainId', 'fromAddress'])
@Index('IDX_SOL_CHAIN_RECENT_ADDR', ['chainId', 'recentBlockHash', 'fromAddress'])
@Index('IDX_SOL_CHAIN_RECENT_ADDR_CURR_STATE', [
  'chainId',
  'recentBlockHash',
  'fromAddress',
  'currencyCodeStr',
  'state',
])
@Index('IDX_SOL_CHAIN_RECENT_ADDR_TO_STATE', [
  'chainId',
  'recentBlockHash',
  'fromAddress',
  'toAddress',
  'state',
])
export class SolanaSignedTxDMO {
  @PrimaryColumn()
  id!: string;

  @Column()
  fromAddress!: string;

  @Column({ nullable: true })
  toAddress?: string;

  @Column({ type: 'int' })
  chainId!: number;

  @Column({ type: 'varchar', transformer: uint8ArrayTransformer })
  signedTxData!: Uint8Array;

  @Column()
  txHash!: string;

  @Column()
  transferValue!: string;

  @Column()
  blockchainStr!: string;

  @Column()
  currencyCodeStr!: string;

  @Column({ type: 'int' })
  state!: TxState;

  @Column()
  recentBlockHash!: string;

  @Column({ default: 0 })
  lastValidBlockHeight!: number;

  @Column()
  notFoundCount!: string;
}

export class SolanaSignedTx implements SignedTx, Persistable<SolanaSignedTxDMO> {
  // eslint-disable-next-line max-params
  constructor(
    readonly id: string,
    readonly fromAddress: string,
    readonly toAddress: string | undefined,
    readonly chainId: bigint,
    readonly signedTxData: Buffer,
    readonly txHash: string,
    readonly transferValue: bigint,
    readonly blockchain: Blockchain,
    readonly currencyCode: CurrencyCode,
    readonly state: TxState,
    readonly recentBlockHash: string,
    readonly lastValidBlockHeight: number,
    readonly notFoundCount: bigint,
  ) {}

  get asDMO(): SolanaSignedTxDMO {
    return {
      id: this.id,
      fromAddress: this.fromAddress,
      toAddress: this.toAddress,
      chainId: Number(this.chainId),
      signedTxData: new Uint8Array(this.signedTxData),
      txHash: this.txHash,
      transferValue: this.transferValue.toString(),
      blockchainStr: this.blockchain.rawValue,
      currencyCodeStr: this.currencyCode.rawValue,
      state: this.state,
      recentBlockHash: this.recentBlockHash,
      lastValidBlockHeight: this.lastValidBlockHeight,
      notFoundCount: this.notFoundCount.toString(),
    };
  }

  static fromDMO(dmo: SolanaSignedTxDMO): SolanaSignedTx {
    return new SolanaSignedTx(
      dmo.id,
      dmo.fromAddress,
      dmo.toAddress,
      BigInt(dmo.chainId),
      Buffer.from(dmo.signedTxData),
      dmo.txHash,
      BigInt(dmo.transferValue),
      new Blockchain(dmo.blockchainStr),
      new CurrencyCode(dmo.currencyCodeStr),
      dmo.state,
      dmo.recentBlockHash,
      dmo.lastValidBlockHeight,
      BigInt(dmo.notFoundCount),
    );
  }
}

import { getEthereumTransactionReceipt } from 'cb-wallet-data/chains/AccountBased/Ethereum/apis/EthereumRPC';
import { saveTransaction } from 'cb-wallet-data/chains/AccountBased/Ethereum/database';
import { EthereumSignedTx } from 'cb-wallet-data/chains/AccountBased/Ethereum/models/EthereumSignedTx';
import { SignedTx } from 'cb-wallet-data/stores/Transactions/interfaces/SignedTx';
import { TxState } from 'cb-wallet-data/stores/Transactions/models/TxState';

/**
 * Max number of retries to check for a receipt for a given tx before marking the tx as dropped
 */
const MAX_CHECKED_RECIPET_RETRIES = 10n;

/**
 * checks the submitted web3 transactions status and updates the EthereumSignedTx state
 * based on the txs status
 */
export async function checkSubmittedWeb3Transactions(
  txs: EthereumSignedTx[],
  syncUpdateToTxHistoryTable: (transaction: SignedTx) => void,
): Promise<EthereumSignedTx[]> {
  if (txs.length === 0) {
    return Promise.resolve([]);
  }
  const promises = txs.map(async function createUpdateTxPromise(tx: EthereumSignedTx) {
    const receipt = await getEthereumTransactionReceipt(tx.txHash, Number(tx.chainId), null);
    let state = TxState.PENDING;
    let notFoundCount = tx.notFoundCount;
    if (receipt !== null) {
      state = receipt.isSuccessful ? TxState.CONFIRMED : TxState.FAILED;
    } else if (tx.notFoundCount > MAX_CHECKED_RECIPET_RETRIES) {
      state = TxState.DROPPED;
    } else {
      notFoundCount += 1n;
    }

    const updatedTx = new EthereumSignedTx(
      tx.id,
      tx.fromAddress,
      tx.toAddress,
      tx.nonce,
      tx.chainId,
      tx.signedTxData,
      tx.txHash,
      tx.weiValue,
      tx.erc20Value,
      tx.blockchain,
      tx.currencyCode,
      state,
      notFoundCount,
    );
    const savedTx = await saveTransaction(updatedTx);
    syncUpdateToTxHistoryTable(updatedTx);
    return savedTx;
  });

  await Promise.all(promises);
  return txs;
}

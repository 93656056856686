import { ReactNode, useEffect, useMemo, useRef } from 'react';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import {
  CombinedThemeColorPreference,
  darkThemeColorConfigs,
  miamiDarkThemeColorConfigs,
  miamiThemeColorConfigs,
  MiamiThemeColorPreference,
  themeColorConfigs,
  ThemeColorPreference,
} from 'cb-wallet-data/stores/ThemeColors/themeColorConfigs';
import { useAppearance } from 'cb-wallet-data/stores/User/hooks/useAppearance';
import { useThemeColorPreference } from 'cb-wallet-data/stores/User/hooks/useThemeColorPreference';
import { Spectrum } from '@cbhq/cds-common';
import { useDeviceSpectrum } from '@cbhq/cds-web/hooks/useDeviceSpectrum';
import { DevicePreferencesProvider, ThemeProvider } from '@cbhq/cds-web/system';

type WalletThemeProviderProps = {
  children: ReactNode;
  initialThemeColorPreference: ThemeColorPreference;
  initialSpectrumPreference: Spectrum;
};

export function WalletThemeProvider({
  children,
  initialThemeColorPreference,
  initialSpectrumPreference,
}: WalletThemeProviderProps) {
  const deviceSpectrum = useDeviceSpectrum();
  const appearance = useAppearance();
  const firstMountRef = useRef(true);
  const [themeColorPreferenceStoreValue] = useThemeColorPreference();
  const preference: CombinedThemeColorPreference = firstMountRef.current
    ? initialThemeColorPreference
    : themeColorPreferenceStoreValue;

  const spectrum = useMemo(() => {
    if (firstMountRef.current) {
      return initialSpectrumPreference;
    }

    return appearance === 'system' ? deviceSpectrum : appearance;
  }, [appearance, deviceSpectrum, initialSpectrumPreference]);

  const isMiamiViceThemeEnabled = useIsFeatureEnabled('wallet_redesign_miami_vice');

  useEffect(function updateFirstMountRef() {
    if (firstMountRef.current) {
      firstMountRef.current = false;
    }
  }, []);

  const palette = useMemo(() => {
    if (isMiamiViceThemeEnabled) {
      return spectrum === 'light'
        ? miamiThemeColorConfigs[preference as MiamiThemeColorPreference]
        : miamiDarkThemeColorConfigs[preference as MiamiThemeColorPreference];
    }
    return spectrum === 'light'
      ? themeColorConfigs[preference as ThemeColorPreference]
      : darkThemeColorConfigs[preference as ThemeColorPreference];
  }, [isMiamiViceThemeEnabled, spectrum, preference]);

  return (
    <DevicePreferencesProvider spectrum={spectrum}>
      <ThemeProvider spectrum={spectrum} palette={palette}>
        {children}
      </ThemeProvider>
    </DevicePreferencesProvider>
  );
}

import { ActionType, ComponentType } from '@cbhq/client-analytics';

import { logEvent, useLogEventOnMount } from '../utils/log';

export function logDeFiAddCryptoClicked(isMiamiEnabled: boolean) {
  logEvent('defi.add_crypto.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
    isMiamiEnabled,
  });
}

export function logDeFiTokenItemClicked() {
  logEvent('defi.token_item.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function useDeFiNuxViewed(isMiamiEnabled: boolean) {
  useLogEventOnMount('defi_tab_nux_viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
    isMiamiEnabled,
  });
}

export function logDeFiNuxLearnMoreClicked(isMiamiEnabled: boolean) {
  logEvent('defi.nux_learn_more.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
    isMiamiEnabled,
  });
}

export function useProtocolProfileViewed() {
  useLogEventOnMount('protocol_profile_viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function useDeFiTabViewed() {
  useLogEventOnMount('defi_tab_viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function logDefiTabViewed() {
  logEvent('defi_tab_viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function deFiErrorOccurred(error: Error, errorHash: string) {
  logEvent('defi_api_error_occurred', {
    action: ActionType.unknown,
    componentType: ComponentType.unknown,
    errorMessage: error.message,
    errorHash,
  });
}

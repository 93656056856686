import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';

export class FiatCurrency {
  readonly id: string;
  private decimalsStr: string;

  constructor(readonly code: CurrencyCode, readonly name: string, decimals: bigint) {
    this.id = code.rawValue;
    this.decimalsStr = decimals.toString();
  }

  get decimals(): bigint {
    return BigInt(this.decimalsStr);
  }

  toString(): string {
    return JSON.stringify({
      code: this.id,
      name: this.name,
      decimalsStr: this.decimalsStr,
    });
  }

  static USD = new FiatCurrency(CurrencyCode.USD, 'United States Dollar', 2n);
}

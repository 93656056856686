import Decimal from 'decimal.js';

import { useDefiProtocolPositions } from './useDefiProtocolPositions';
import { useProtocolPositionsAggregateBalance } from './useProtocolPositionsAggregateBalance';

type Params = {
  enabled?: boolean;
};

export function useFetchProtocolPositionsAggregateBalance({ enabled }: Params) {
  const { protocolPositions } = useDefiProtocolPositions({
    enabled,
  });

  const protocolPositionsBalance = useProtocolPositionsAggregateBalance({
    positions: protocolPositions,
  });

  if (!enabled) return new Decimal(0);

  return protocolPositionsBalance;
}

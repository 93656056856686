import { cbReportError } from 'cb-wallet-data/errors/reportError';
import {
  deleteAddresses,
  getAddressesForAccount,
  softDeleteAddresses,
} from 'cb-wallet-data/stores/Addresses/database';
import {
  deleteTransactions,
  getTransactionsForAccount,
  softDeleteTransactions,
} from 'cb-wallet-data/stores/Transactions/database';
import {
  deleteWalletGroups,
  getWalletGroupsForAccount,
} from 'cb-wallet-data/stores/WalletGroups/database';
import {
  deleteWallets,
  getWalletsForAccount,
  softDeleteWallets,
} from 'cb-wallet-data/stores/Wallets/database';
import flatten from 'lodash/flatten';

type DeleteAssociatedAccountDbRecordsReturn = {
  walletIdsToBeDeleted: string[];
  transactionIdsToBeDeleted: string[];
  walletGroupIdsToBeDeleted: string[];
  addressIdsToBeDeleted: string[];
};

/**
 * deleteAssociatedAccountDbRecords
 *
 * It accepts an accountId as an input parameter and eliminates associated records for
 * the account including:
 * - wallet
 * - walletGroups
 * - addresses
 * - transactions
 *
 * NOTE: It does not delete the account.
 * NOTE: It does not hydrate Recoil.
 * TODO: Add amplitude events to monitor start, success and failure of associated records
 * */
export async function deleteAssociatedAccountDbRecords(
  accountIds: string[],
  isSoftDelete = false,
): Promise<DeleteAssociatedAccountDbRecordsReturn> {
  try {
    const allWallets = accountIds.map(async (accountId) => getWalletsForAccount(accountId));
    const allWalletGroups = accountIds.map(async (accountId) =>
      getWalletGroupsForAccount(accountId),
    );
    const allAddresses = accountIds.map(async (accountId) => getAddressesForAccount(accountId));
    const allTransactions = accountIds.map(async (accountId) =>
      getTransactionsForAccount(accountId),
    );

    const [
      walletsToBeDeletedMultiDimensional,
      walletGroupsToBeDeletedMultiDimensional,
      addressesToBeDeletedMultiDimensional,
      transactionsToBeDeletedMultiDimensional,
    ] = await Promise.all([
      Promise.all(allWallets),
      Promise.all(allWalletGroups),
      Promise.all(allAddresses),
      Promise.all(allTransactions),
    ]);

    const [
      walletsToBeDeleted,
      walletGroupsToBeDeleted,
      addressesToBeDeleted,
      transactionsToBeDeleted,
    ] = [
      flatten(walletsToBeDeletedMultiDimensional),
      flatten(walletGroupsToBeDeletedMultiDimensional),
      flatten(addressesToBeDeletedMultiDimensional),
      flatten(transactionsToBeDeletedMultiDimensional),
    ];

    const walletIdsToBeDeleted: string[] = walletsToBeDeleted.map((w) => w.id);
    const addressIdsToBeDeleted: string[] = addressesToBeDeleted.map((a) => a.id);
    const transactionIdsToBeDeleted: string[] = transactionsToBeDeleted.map((t) => t.id);
    const walletGroupIdsToBeDeleted: string[] = walletGroupsToBeDeleted.map((w) => w.id);

    const deleteTxsFunc = async () => {
      if (isSoftDelete) {
        await softDeleteTransactions(transactionsToBeDeleted);
      } else {
        await deleteTransactions(transactionIdsToBeDeleted);
      }
    };

    const deleteWalletsFunc = async () => {
      if (isSoftDelete) {
        await softDeleteWallets(walletsToBeDeleted);
      } else {
        await deleteWallets(walletIdsToBeDeleted);
      }
    };

    const deleteAddressesFunc = async () => {
      if (isSoftDelete) {
        await softDeleteAddresses(addressesToBeDeleted);
      } else {
        await deleteAddresses(addressIdsToBeDeleted);
      }
    };
    /**
     * design approach:
     * Happy path: deletion of all records goes through as expected, next step will be hydration
     * Unhappy path: deletion of any record fails, the block of code will immediately throw an error
     * and no further execution [hydration, account deletion] will take place.
     * Since the account has been soft deleted, the user will not be seeing any associated records of that account
     * We'll execute the useDeleteMnemonic hook in the repair script (planned for later) if any of the tasks fail
     * in mnemonic account deletion.
     * We'll know this if the account is still present in the database and is soft deleted.
     */
    const deletionResults = await Promise.allSettled([
      deleteWalletsFunc(),
      deleteAddressesFunc(),
      deleteTxsFunc(),
      deleteWalletGroups(walletGroupIdsToBeDeleted),
    ]);

    const failedDbDeletionReasons: string[] = [];

    deletionResults.forEach((promise) => {
      if (promise.status === 'rejected') {
        failedDbDeletionReasons.push(promise.reason);
      }
    });

    // This block of code is add to ensure we don't delete accounts
    // if any of the associated records aren't deleted.
    // Otherwise, they will be left dangling.
    // Other ops like hydration, auth and local storage
    // will be deleted before this stage
    if (failedDbDeletionReasons.length > 0) {
      throw new Error(
        `Unable to delete records for the following reason(s): ${failedDbDeletionReasons.join(
          ', ',
        )}`,
      );
    }

    return {
      walletIdsToBeDeleted,
      transactionIdsToBeDeleted,
      walletGroupIdsToBeDeleted,
      addressIdsToBeDeleted,
    };
  } catch (err: ErrorOrAny) {
    cbReportError({
      error: err,
      context: 'delete_associated_account_db_records',
      severity: 'error',
      isHandled: false,
    });
    throw err;
  }
}

import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSpectrum } from '@cbhq/cds-common';
import { Tag } from '@cbhq/cds-web/tag/Tag';
import { TextLabel1 } from '@cbhq/cds-web/typography';

export type CardBadgeProps = {
  numCompletions: number;
  launchDate?: string;
};

const messages = defineMessages({
  ocsChallengeCompetions: {
    defaultMessage: '🔥 {numCompletions} PEOPLE COMPLETED',
    description: 'number of people who have completed an onchain summer badge',
  },
  ocsChallengeEarly: {
    defaultMessage: '⏰️ BE EARLY',
    description: 'a badge on a challenge to indicate this is a new challenge',
  },
});

export function CardBadge(props: CardBadgeProps) {
  const spectrum = useSpectrum();
  const { formatMessage } = useIntl();
  const { numCompletions, launchDate } = props;

  const text = useMemo(() => {
    if (launchDate) {
      return launchDate;
    }
    if (numCompletions >= 100) {
      return formatMessage(messages.ocsChallengeCompetions, { numCompletions });
    }
    return formatMessage(messages.ocsChallengeEarly);
  }, [numCompletions, launchDate, formatMessage]);

  const color = useMemo(() => {
    if (launchDate) {
      return '#54DCE7';
    }
    if (numCompletions >= 100) {
      return '#FEE002';
    }
    return '#FF8DCF';
  }, [numCompletions, launchDate]);

  if (launchDate?.includes('Invalid Date')) {
    return null;
  }

  return (
    <Tag
      dangerouslySetBackground={color}
      position="absolute"
      top={16}
      left={16}
      intent="promotional"
      testID="card-badge"
    >
      <TextLabel1
        as="span"
        transform="uppercase"
        mono
        spacingHorizontal={1}
        color={spectrum === 'dark' ? 'primaryForeground' : 'secondaryForeground'}
      >
        {text}
      </TextLabel1>
    </Tag>
  );
}

import { useCallback } from 'react';
import { PossibleUTXOBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';

import { WalletConnectionUTXOResponse } from '../models/WalletConnectionResponse';

import { useCreateAccountAndWalletGroupForXPubKey } from './useCreateAccountAndWalletGroupForXPubKey';

export type ProcessUTXOBasedBlockchainXPubKeysParams = {
  provider: string;
  utxo: WalletConnectionUTXOResponse;
  providerUserId?: string;
};

export function useProcessUTXOBasedBlockchainXPubKeys() {
  const createAccountAndWalletGroupForXPubKey = useCreateAccountAndWalletGroupForXPubKey();

  return useCallback(
    async function processUTXOBasedBlockchainXPubKeys({
      provider,
      utxo,
      providerUserId,
    }: ProcessUTXOBasedBlockchainXPubKeysParams) {
      const accountPromises = [];

      for (const [chain, xpubKeys] of Object.entries(utxo)) {
        const promise = createAccountAndWalletGroupForXPubKey({
          chain: chain as PossibleUTXOBlockchainSymbol,
          xpubKeys,
          provider,
          providerUserId,
        });

        accountPromises.push(promise);
      }

      await Promise.all(accountPromises);
    },
    [createAccountAndWalletGroupForXPubKey],
  );
}

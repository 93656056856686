import { useCallback, useMemo } from 'react';
import { useTokenApprovalsData } from 'cb-wallet-data/stores/TokenApprovals/hooks/useTokenApprovalsData';
import { TxOrUserOp } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import debounceLodash from 'lodash/debounce';

/**
 * Manually refreshes token approvals react query data. Called in useUpdateTransactions
 * when a transaction nudge comes in.
 */
export function useRefreshTokenApprovals() {
  const { refetchData } = useTokenApprovalsData();
  const debouncedRefetchData = useMemo(() => debounceLodash(refetchData, 5000), [refetchData]);

  return useCallback(
    function refreshTokenApprovals(txs: TxOrUserOp[]) {
      const shouldRefresh = txs.some(
        (tx) => tx.isContractExecution && tx.blockchain.rawValue === 'ETH',
      );
      if (shouldRefresh) {
        debouncedRefetchData();
      }
    },
    [debouncedRefetchData],
  );
}

import { useMemo } from 'react';
import { useIsMiamiViceFeatureEnabled } from 'cb-wallet-data/hooks/useIsMiamiViceFeatureEnabled/useIsMiamiViceFeatureEnabled';

import { getDefaultAvatarIdxByAddress } from '../utils/getDefaultAvatarIdxByAddress';

export function useDefaultAvatarIdxByAddress(address?: string) {
  const isMiamiViceAvatarsEnabled = useIsMiamiViceFeatureEnabled('wallet_redesign_avatars');

  return useMemo(
    () => (address ? getDefaultAvatarIdxByAddress(address, isMiamiViceAvatarsEnabled) : 0),
    [address, isMiamiViceAvatarsEnabled],
  );
}

/**
 * Database State
 *
 * These functions set the internal state `_isDatabaseAccessAllowed`.
 *
 * These are usually used during sign out or database deletion operations, where the
 * database should be "locked" until the operation has been completed.
 */

let _isDatabaseAccessAllowed = true;

// Prevents access to database
export function preventDatabaseAccess() {
  _isDatabaseAccessAllowed = false;
}

// Allows access to database
export function allowDatabaseAccess() {
  _isDatabaseAccessAllowed = true;
}

// Returns whether database access is allowed
export function isDatabaseAccessAllowed() {
  return _isDatabaseAccessAllowed;
}

import { serialize } from '@ethersproject/transactions';
import { logConfirmTransactionGasPriceFailure } from 'cb-wallet-analytics/data/Transactions';
import { EthereumChain } from 'cb-wallet-data/chains/AccountBased/Ethereum/EthereumChain';
import { strip0x } from 'cb-wallet-data/utils/String+Core';
import { bigIntFromHex } from 'wallet-engine-signing/blockchains/Ethereum/formatNumbers';
import { callEthereumJSONRPC } from 'wallet-engine-signing/blockchains/Ethereum/RPC';

import { getEthereumRPCURL } from './EthereumRPC';

type L1GasFeeParams = {
  chain: EthereumChain;
  recipientAddress: string;
  nonce: bigint;
  gasPrice: bigint;
  gasLimit: bigint;
  calldata: string;
  type: 1 | 2; // type 1 = legacy, type 2 = 1559
  txSource: string;
};

/**
 * returns L1 fee for OVM transactions in wei
 */
export async function getOvmL1GasFee({
  chain,
  recipientAddress,
  nonce,
  gasPrice,
  gasLimit,
  calldata,
  type,
  txSource,
}: L1GasFeeParams): Promise<bigint | undefined> {
  // non-OVM chains do not have an l1GasFee
  if (!chain.isOvmNetwork) {
    return undefined;
  }

  try {
    // Large library, only import if necessary
    const { getContractFactory, predeploys } = await import('@eth-optimism/contracts');

    const ovmGasPriceOracle = getContractFactory('OVM_GasPriceOracle').attach(
      predeploys.OVM_GasPriceOracle,
    );

    // This aims to be the same data used by the Optimism SDK to estimate the l1GasFee
    // https://github.com/ethereum-optimism/optimism/blob/b2d75bf0c61508333a6e6569d3ac25e7d037c7dc/packages/sdk/src/l2-provider.ts#L97-L105
    const rawTxData = serialize({
      chainId: chain.chainId,
      nonce: Number(nonce),
      gasLimit: `0x${gasLimit.toString(16)}`,
      to: recipientAddress,
      data: calldata,
      type,
      [type === 1 ? 'gasPrice' : 'maxFeePerGas']: `0x${gasPrice.toString(16)}`,
    });

    const getL1FeeCallData = ovmGasPriceOracle.interface.encodeFunctionData('getL1Fee', [
      rawTxData,
    ]);

    const res = await callEthereumJSONRPC(
      'eth_call',
      [
        {
          to: ovmGasPriceOracle.address.toLowerCase(),
          data: getL1FeeCallData,
        },
        'latest',
      ],
      getEthereumRPCURL(chain.chainId),
    );

    if (res?.result === null) {
      throw Error('l1 gas cost contract not deployed yet');
    }

    const hexValue = strip0x(res.result as string).slice(0, 64);
    const finalValue = bigIntFromHex(hexValue);

    // We pad the l1GasFee by 15% to add a cushion during submission which reduces the incidence of "insufficient funds" errors.
    // The l1GasFee is used on the client to calculate the total value of the transaction and check that the user has enough
    // balance to submit. The fee as calculated here is not charged to the user – it is determined on the node:
    // https://github.com/ethereum-optimism/op-geth/blob/8f8af46ec728a79137d449891fc1827e1763cd7e/core/txpool/txpool.go#L709-L716
    const L1_FEE_BUFFER = 1.15;
    const BIGINT_MULTIPLIER = 10 ** 18;
    const multiplier = BigInt(L1_FEE_BUFFER * BIGINT_MULTIPLIER);
    const divisor = BigInt(BIGINT_MULTIPLIER);
    const bufferedValue = (finalValue * multiplier) / divisor;

    return bufferedValue;
  } catch (error: ErrorOrAny) {
    logConfirmTransactionGasPriceFailure({
      blockchain: chain.blockchainSymbol,
      chainName: chain.displayName,
      chainId: chain.chainId.toString(10),
      errorType: 'OptimismL1FeeCalculationFailed',
      errorMessage: error?.message,
      txSource: txSource.toLocaleString(),
    });

    // throw the error so that the tx generation fails
    throw error;
  }
}

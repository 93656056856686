import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { messages } from 'cb-wallet-data/stores/SettingsRedesign/messages/legal';
import {
  SettingCategory,
  SettingCategoryConfig,
} from 'cb-wallet-data/stores/SettingsRedesign/types';
import { DAPP_TERMS_OF_SERVICE_URL } from 'cb-wallet-data/utils/externalLinks';

import { useSearchContext } from ':dapp/providers/SearchProvider';
import { openURLInNewTab } from ':dapp/utils/openURLInNewTab';

import { SETTING_CATEGORY_INNER_SPACING } from '../constants';

export function useTermsOfServiceConfig(): SettingCategoryConfig {
  const { formatMessage } = useIntl();
  const { toggleSearchOff } = useSearchContext();

  const onPress = useCallback(
    function handleTOSPress() {
      openURLInNewTab(DAPP_TERMS_OF_SERVICE_URL);
      toggleSearchOff();
    },
    [toggleSearchOff],
  );

  const categoryConfig = useMemo<SettingCategoryConfig>(() => {
    return {
      title: formatMessage(messages.termsOfService),
      accessory: 'externalLink',
      testID: `${SettingCategory.TOS}-setting`,
      onPress,
      innerSpacing: SETTING_CATEGORY_INNER_SPACING,
    };
  }, [formatMessage, onPress]);

  return categoryConfig;
}

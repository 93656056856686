import { useIsTradeEligible } from 'cb-wallet-data/hooks/Swap/useIsTradeEligible/useIsTradeEligible';
import { useSwapByChainKillSwitch } from 'cb-wallet-data/stores/Swap/api/useSwapByChainKillSwitch';

const SUPPORTED_CHAIN_IDS = [1n, 137n, 10n, 56n, 43114n, 101n];

export function useIsGlobalSwapEligible() {
  const isSwapEligible = useIsTradeEligible();
  const isSwapByChainKilled = useSwapByChainKillSwitch();
  // If all swap is killed return false and hide global swap entry points
  if (!isSwapEligible) return false;

  // If any of the supported chains are not killed return true and show global swap entry points
  return SUPPORTED_CHAIN_IDS.some((chainId) => !isSwapByChainKilled(chainId));
}

import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import {
  CBPayInstanceType,
  generateOnRampURL,
  initOnRamp,
  InitOnRampParams,
} from '@coinbase/cbpay-js';
import { triggerCBPayEventStream } from 'cb-wallet-analytics/buy';
import { useOverridableKillSwitch } from 'cb-wallet-data/hooks/KillSwitches/useOverridableKillSwitch';
import { CBPAY_ID } from 'cb-wallet-env/env';
import noop from 'lodash/noop';

import { useOnMount } from '../useOnMount';

export type DestinationWallets = UseCoinbasePayProps['widgetParameters']['destinationWallets'];

export type CustomInitOnRampWidgetParams = InitOnRampParams['widgetParameters'] & {
  redirectUrl?: string;
};

export type UseCoinbasePayProps = Omit<InitOnRampParams, 'widgetParameters' | 'appId'> & {
  widgetParameters: CustomInitOnRampWidgetParams;
  appId?: string;
  onReady?: () => void;
  enableOnrampSDK?: boolean;
  onEventError?: (error: unknown) => void;
};

const noopCBPayInstance: CBPayInstanceType = { open: noop, destroy: noop };

const allowedEventOrigins = ['https://pay.coinbase.com'];

/*
  This hook initializes the Coinbase Pay SDK and returns a CBPayInstance.
  It uses generateOnRampURL to open the onramp in a new tab by default (enableOnrampSDK is false). 
  generateOnRampURL generates the URL with widgetParameters for the onramp.
  If enableOnrampSDK is true, it initializes the onramp SDK with the widgetParameters and other props.
  The benefit of using the SDK is that it provides various onramp experiences like popup, embedded, and new tab.
  However, there is latency when initializing the SDK. Note: in order to enable popup and embedded experiences,
  the domain must be in the allowlist in Coinbase Pay settings.
*/
export function useCoinbasePay({
  widgetParameters,
  appId = CBPAY_ID ?? 'wallet',
  onEvent,
  onEventError,
  onSuccess,
  onExit,
  onReady,
  closeOnExit,
  closeOnSuccess,
  experienceLoggedOut = 'popup',
  experienceLoggedIn = 'popup',
  embeddedContentStyles,
  enableOnrampSDK,
}: UseCoinbasePayProps): {
  cbPayInstance: RefObject<CBPayInstanceType>;
  isCBPayInstanceReady: boolean;
} {
  const [isReady, setIsReady] = useState(!enableOnrampSDK);
  const cbPayInstance = useRef<CBPayInstanceType>(noopCBPayInstance);
  const isCBPayBuyDefaultExperienceKilled = useOverridableKillSwitch(
    'kill_cbpay_buy_default_experiment',
  );

  const defaultExperience = !isCBPayBuyDefaultExperienceKilled
    ? 'buy'
    : widgetParameters.defaultExperience;

  const handleMessage = useCallback(
    function handleMessage(event: MessageEvent) {
      if (!allowedEventOrigins.includes(event.origin)) return;
      let cbpayEvent;

      try {
        cbpayEvent = JSON.parse(event.data).data;
      } catch (e) {
        onEventError?.(e);
      }

      if (cbpayEvent.eventName && cbpayEvent.eventName === 'success') {
        onSuccess?.();
      }

      if (cbpayEvent.eventName && cbpayEvent.eventName !== 'analytics') {
        triggerCBPayEventStream({ CBPayEventName: cbpayEvent.eventName });
      }

      if (cbpayEvent.eventName && cbpayEvent.eventName === 'analytics') {
        const { event: analyticsEvent } = cbpayEvent;

        triggerCBPayEventStream({
          CBPayEventName: analyticsEvent.name,
          ...analyticsEvent.metadata,
        });
      }
    },
    [onEventError, onSuccess],
  );

  useOnMount(function subscribeToCBPayEvents() {
    // If enableOnrampSDK is true, we don't need to subscribe to the message event because we use initOnRamp instead generateOnRampURL
    if (enableOnrampSDK) return;

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  });

  useEffect(
    function initializeOnramp() {
      if (!enableOnrampSDK) {
        cbPayInstance.current = {
          open: () =>
            window.open(
              generateOnRampURL({ ...widgetParameters, appId, defaultExperience }),
              '_blank',
            ),
          destroy: () => {
            cbPayInstance.current = noopCBPayInstance;
          },
        };

        return onReady?.();
      }

      initOnRamp(
        {
          appId,
          widgetParameters: { ...widgetParameters, defaultExperience },
          closeOnExit,
          closeOnSuccess,
          experienceLoggedOut,
          experienceLoggedIn,
          embeddedContentStyles,
          onEvent: function handleCBPayEvent(event) {
            triggerCBPayEventStream({ CBPayEventName: event.eventName });
            onEvent?.(event);
          },
          onExit,
          onSuccess,
        },
        (_, instance) => {
          if (instance) {
            setIsReady(true);
            cbPayInstance.current = instance;
            onReady?.();
          }
        },
      );

      return () => {
        cbPayInstance.current?.destroy();
      };
    },
    [
      appId,
      closeOnExit,
      closeOnSuccess,
      defaultExperience,
      embeddedContentStyles,
      enableOnrampSDK,
      experienceLoggedIn,
      experienceLoggedOut,
      onEvent,
      onExit,
      onReady,
      onSuccess,
      widgetParameters,
    ],
  );

  return { cbPayInstance, isCBPayInstanceReady: isReady };
}

import bitcoin from 'bitcoinjs-lib';

import { validateAddress } from '../../blockchains/UTXO/addressValidation';
import { getNetwork } from '../../blockchains/UTXO/getNetwork';
import {
  CoinSelection,
  DogecoinServiceInterface,
  SignedTransaction,
} from '../generated/DogecoinService.generated';

import { BTCLikeService } from './BTCLikeService';

export class DogecoinService extends BTCLikeService implements DogecoinServiceInterface {
  public async signTransaction(params: {
    privateKeys: Record<string, Buffer>;
    coinSelection: CoinSelection;
    testnet: boolean;
  }): Promise<SignedTransaction> {
    return this.sign(params.privateKeys, params.coinSelection, params.testnet);
  }

  async signTransactionAsync(
    publicKeysAndPaths: Record<string, { publicKey: Buffer; derivationPath: string }>,
    signMessage: (derivationPath: string, message: Buffer) => Promise<Buffer>,
    coinSelection: CoinSelection,
    testnet: boolean,
  ): Promise<SignedTransaction> {
    const { inputs, outputs } = coinSelection;
    const network = getNetwork(this.blockchainSymbol, testnet);
    const txb = new bitcoin.TransactionBuilder(network);

    txb.setVersion(1); // Dogecoin does not support CSV

    for (const input of inputs) {
      const { address } = input;
      validateAddress({ blockchainSymbol: this.blockchainSymbol, address, testnet });

      txb.addInput(input.hash, input.index);
    }

    for (const output of outputs) {
      const { address } = output;
      validateAddress({ blockchainSymbol: this.blockchainSymbol, address, testnet });
      txb.addOutput(address, output.value.toNumber());
    }

    const signInputs = async () => {
      for (let i = 0; i < inputs.length; i++) {
        const input = inputs[i];
        const { publicKey, derivationPath } = publicKeysAndPaths[input.address];

        if (!Buffer.isBuffer(publicKey)) {
          throw new Error(`public key for ${input.address} not found`);
        }

        await txb.signAsync(i, publicKey, derivationPath, signMessage);
      }
    };
    await signInputs();

    const tx = txb.build();

    return {
      data: tx.toBuffer(),
      hash: Buffer.from(tx.getHash().reverse()).toString('hex'),
    };
  }

  protected sign(
    privateKeys: Record<string, Buffer>,
    coinSelection: CoinSelection,
    testnet: boolean,
  ): SignedTransaction {
    const { inputs, outputs } = coinSelection;
    const network = getNetwork(this.blockchainSymbol, testnet);
    const txb = new bitcoin.TransactionBuilder(network);

    txb.setVersion(1); // Dogecoin does not support CSV

    for (const input of inputs) {
      const { address } = input;
      validateAddress({
        blockchainSymbol: this.blockchainSymbol,
        address,
        testnet,
      });

      txb.addInput(input.hash, input.index);
    }

    for (const output of outputs) {
      const { address } = output;
      validateAddress({
        blockchainSymbol: this.blockchainSymbol,
        address,
        testnet,
      });
      txb.addOutput(address, output.value.toNumber());
    }

    inputs.forEach((input, i) => {
      const privateKey: Buffer | undefined = privateKeys[input.address];

      if (!Buffer.isBuffer(privateKey)) {
        throw new Error(`private key for ${input.address} not found`);
      }

      const ecPair = bitcoin.ECPair.fromPrivateKey(privateKey, { network });
      txb.sign(i, ecPair);
    });

    const tx = txb.build();

    return {
      data: tx.toBuffer(),
      hash: Buffer.from(tx.getHash().reverse()).toString('hex'),
    };
  }
}

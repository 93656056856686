import { QueryMeta } from '@tanstack/react-query';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';

import { ExperimentsResponse, ReleaseEnvironment } from '../types';
import { fetchDeviceExperiments } from '../utils/fetchExperiments';

export const EXPERIMENTS_QUERY_KEY = 'get_experiments';
const DEFAULT_EXPERIMENTS = { groups: [] };

/**
 * useDeviceExperiments - Fetches and caches a logged out user's experiments from the Coinbase API.
 *
 * @returns The ExperimentsResponse object.
 * @param deviceId Unique ID for device via Amplitude's device ID
 * @param releaseEnvironment Current environment, this is used to determine if the current user is an employee.
 * @param version Current app version, used to support version gating on experiments
 */
export function useDeviceExperiments(
  deviceId: string | undefined,
  releaseEnvironment: ReleaseEnvironment,
  version: string,
  meta?: QueryMeta,
) {
  const { data } = useQuery<ExperimentsResponse>(
    [EXPERIMENTS_QUERY_KEY, deviceId],
    async () => fetchDeviceExperiments({ deviceId, releaseEnvironment, version }),
    {
      enabled: !!deviceId, // Do not make the call if the device id is not returned
      suspense: true, // Throw a promise for the first fetch, also throw to ErrorBoundary on fail
      useErrorBoundary: false, // throw to nearest ErrorBoundary on fail
      staleTime: 1000 * 60 * 5, // Refresh the user's experiments when requested again after 5 minute
      cacheTime: 1000 * 60 * 30, // Cache the experiments for 30 min after the user has closed the app
      notifyOnChangeProps: ['data'],
      refetchInterval: 1000 * 60 * 5, // Automatically refetch every 5 minutes
      meta,
    },
  );

  return data! ?? DEFAULT_EXPERIMENTS;
}

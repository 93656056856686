import { useCallback } from 'react';
import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { useHydrateWalletGroups } from 'cb-wallet-data/stores/WalletGroups/hooks/useHydrateWalletGroups';
import { useHydrateWallets } from 'cb-wallet-data/stores/Wallets/hooks/useHydrateWallets';

/**
 * hook to hydrate Recoil state for wallets, walletGroups, transactions
 *
 * utilizes useHydrateWallets and useHydrateWalletGroups hooks
 * These individual hooks make a db query call to get associated records and set the recoil state
 *
 * hydrateWallets is initially set before hydrateWalletGroups because of a race condition
 * which occurs in this file - https://github.cbhq.net/wallet/wallet-mobile/blob/master/workspaces/libs/data/stores/Wallets/utils/prepareWalletsByWalletGroup.ts#L162
 * `walletGroupsOfWalletAccount` would return undefined if wallets get updated first before wallet.
 *
 * this hook DOES NOT: delete any record from database
 * TODO: add analytics part tracked here - https://jira.coinbase-corp.com/browse/WALL-23585
 */
export function useHydrateAssociatedAccountRecords() {
  const hydrateWallets = useHydrateWallets();
  const hydrateWalletGroups = useHydrateWalletGroups();

  return useCallback(
    async function hydrateAssociatedAccountRecords(deletedAccountIds?: string[]) {
      try {
        // hydrate wallets before hydrating wallet groups to avoid race condition
        // TODO: Arnab, JIRA ticket https://jira.coinbase-corp.com/browse/WALL-26160
        // TODO: once the fix is made move hydrateWallets inside Promise and update unit tests accordingly
        await hydrateWallets(deletedAccountIds);

        try {
          // hydrate the associated accountId's wallet groups
          await hydrateWalletGroups(deletedAccountIds);
        } catch (err: ErrorOrAny) {
          throw new Error(`Unable to hydrate records for the following reason: ${err.message}`);
        }
      } catch (err: ErrorOrAny) {
        cbReportError({
          error: err,
          context: 'hydrate_associated_account_db_records',
          severity: 'error',
          isHandled: false,
        });
        throw err;
      }
    },
    [hydrateWalletGroups, hydrateWallets],
  );
}

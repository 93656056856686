import { logHRTMissingTransfers, logHRTTxnMissingDecimals } from 'cb-wallet-analytics/transactions';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { BASE_ASSET_ADDRESS } from 'cb-wallet-data/stores/Transactions/methods/parsers/HRT/constants';
import { parseContractExecution } from 'cb-wallet-data/stores/Transactions/methods/parsers/HRT/parseContractExecution';
import { ParseParams } from 'cb-wallet-data/stores/Transactions/methods/parsers/HRT/parseParams';
import { enhanceWithTronBridgeMetadata } from 'cb-wallet-data/stores/Transactions/methods/parsers/utils/enhanceWithTronBridgeMetadata';
import {
  apiv1LabelToPrimaryAction,
  buildHRTMetadata,
  findRelevantTransfer,
  getTransactionId,
  hasMissingDecimals,
  isSend,
  isSpam,
  primaryActionToType,
} from 'cb-wallet-data/stores/Transactions/methods/parsers/utils/utils';
import { TxOrUserOp, TxOrUserOpParams } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import { TxState } from 'cb-wallet-data/stores/Transactions/models/TxState';
import { unixTimestampToDate } from 'cb-wallet-data/stores/Transactions/utils/unixTimestampToDate';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { Apiv1Label, V1TransactionStatus } from '@cbhq/instant-api-hooks-wallet-tx-history/types';

/**
 * parseSimple is used to parse transaction types from HRT that involve a single important transfer.
 * This includes SEND, RECEIVE, and MINT.
 */
export function parseSimple({
  primaryAction,
  historicalAssetPricesUsd,
  feeAmount,
  txHash,
  txNonce,
  txTimestamp,
  txStatus,
  userOpHash,
  userAddress,
  blockchain,
  network,
  accountId,
  walletIndex,
  spamScoreThresholds,
  addressMetadata,
  transfers,
}: ParseParams): TxOrUserOp | undefined {
  const relevantTransfer = findRelevantTransfer(
    primaryAction?.type || Apiv1Label.LABEL_UNKNOWN,
    transfers,
    userAddress,
  );

  // If there is no transfer in this transaction, it is not possible to accurately populate the
  // transaction model, so we default to a contract execution. Every Send, Receive, or Mint should have at least 1
  // transfer.
  if (!relevantTransfer) {
    logHRTMissingTransfers({
      primaryAction: primaryAction?.type || '',
      blockchain: blockchain.rawValue,
      network: network.rawValue,
    });
    return parseContractExecution({
      primaryAction,
      historicalAssetPricesUsd,
      feeAmount,
      txHash,
      txNonce,
      txTimestamp,
      txStatus,
      userOpHash,
      userAddress,
      blockchain,
      network,
      accountId,
      walletIndex,
      addressMetadata,
      transfers,
    });
  }

  const nativeAssetMetadata = addressMetadata[BASE_ASSET_ADDRESS]?.token;
  const assetMetadata = addressMetadata[relevantTransfer?.contractAddress || ''];
  const fromMetadata = addressMetadata[relevantTransfer?.fromAddress || ''];
  const toMetadata = addressMetadata[relevantTransfer?.toAddress || ''];

  if (
    hasMissingDecimals([
      addressMetadata[BASE_ASSET_ADDRESS],
      assetMetadata,
      fromMetadata,
      toMetadata,
    ])
  ) {
    logHRTTxnMissingDecimals({
      primaryAction: primaryAction?.type || '',
      blockchain: blockchain.rawValue,
      network: network.rawValue,
    });
    return undefined;
  }

  const date = unixTimestampToDate(txTimestamp || new Date().getTime().toString());
  const nonce = BigInt(txNonce || 0);

  const nativeAssetHistoricalPrice = historicalAssetPricesUsd?.[BASE_ASSET_ADDRESS];
  const assetHistoricalPrice = historicalAssetPricesUsd?.[relevantTransfer?.contractAddress || ''];

  const nativeAssetCurrenyCode = new CurrencyCode(
    nativeAssetMetadata?.symbol || blockchain?.rawValue,
  );
  const emptyCurrencyCode = new CurrencyCode('');

  const feeCurrencyDecimal = BigInt(nativeAssetMetadata?.decimals || 0);
  const fee = BigInt(feeAmount || 0);
  const feeCurrencyCode = nativeAssetCurrenyCode;

  const txMetadata = enhanceWithTronBridgeMetadata({
    primaryAction,
    existingMetadata: buildHRTMetadata(
      [assetMetadata, fromMetadata, toMetadata],
      primaryAction?.metadata,
    ),
  });

  let toAssetHistoricalPrice: string | undefined;
  let fromAssetHistoricalPrice: string | undefined;
  let fromAssetImage: string | undefined;
  let toAssetImage: string | undefined;

  switch (primaryAction?.type) {
    case Apiv1Label.LABEL_RECEIVE:
    case Apiv1Label.LABEL_MINT:
      toAssetHistoricalPrice = assetHistoricalPrice;
      if (assetMetadata?.token) {
        // if we are looking at a token, then show the token logo
        toAssetImage = assetMetadata?.token?.logo?.url;
      } else if (relevantTransfer?.tokenId !== undefined && assetMetadata?.nftCollection) {
        // if we are looking at nft collection, find the nftCollection image
        toAssetImage = assetMetadata?.nftCollection?.items?.[relevantTransfer.tokenId]?.imageUrl;
      }
      break;
    default:
      fromAssetHistoricalPrice = assetHistoricalPrice;
      if (assetMetadata?.token) {
        // if we are looking at a token, then show the token logo
        fromAssetImage = assetMetadata?.token?.logo?.url;
      } else if (relevantTransfer?.tokenId !== undefined && assetMetadata?.nftCollection) {
        // if we are looking at nft collection, find the nftCollection image
        fromAssetImage = assetMetadata?.nftCollection?.items?.[relevantTransfer.tokenId]?.imageUrl;
      }
      break;
  }

  const walletId = Wallet.generateId({
    blockchain,
    currencyCode: relevantTransfer?.currencyCode || emptyCurrencyCode,
    network,
    contractAddress:
      relevantTransfer?.contractAddress === BASE_ASSET_ADDRESS
        ? undefined
        : relevantTransfer?.contractAddress,
    selectedIndex: walletIndex,
    accountId,
  });

  const isSent = isSend(primaryAction?.type || Apiv1Label.LABEL_UNKNOWN);

  const tokenId = relevantTransfer?.tokenId;

  return new TxOrUserOp({
    id: getTransactionId(primaryAction!),
    createdAt: date,
    confirmedAt: date,
    blockchain,
    currencyCode: relevantTransfer?.currencyCode || emptyCurrencyCode,
    feeCurrencyCode,
    feeCurrencyDecimal,
    toAddress: relevantTransfer?.toAddress,
    toDomain: relevantTransfer?.toDomain || undefined,
    fromAddress: relevantTransfer?.fromAddress,
    fromDomain: relevantTransfer?.fromDomain || undefined,
    amount: relevantTransfer?.amount || 0n,
    fee,
    state:
      txStatus === V1TransactionStatus.TRANSACTION_STATUS_SUCCESS
        ? TxState.CONFIRMED
        : TxState.FAILED,
    metadata: txMetadata,
    network,
    txOrUserOpHash: userOpHash ?? txHash,
    userOpHash,
    walletIndex,
    accountId,
    txHash,
    isSent,
    contractAddress:
      relevantTransfer?.contractAddress === BASE_ASSET_ADDRESS
        ? undefined
        : relevantTransfer?.contractAddress,
    tokenName: relevantTransfer?.tokenName || '',
    tokenDecimal: relevantTransfer?.decimal || 0n,
    walletId,
    nonce,
    type: primaryActionToType({ primaryActionLabel: primaryAction?.type, txMetadata }),
    transfers,
    isSpam:
      isSpam(spamScoreThresholds, [assetMetadata?.token?.spamScore || 0]) ||
      Boolean(assetMetadata?.nftCollection?.isSpam),
    primaryAction: apiv1LabelToPrimaryAction(primaryAction?.type),
    toAssetHistoricalPrice,
    fromAssetHistoricalPrice,
    nativeAssetHistoricalPrice,
    fromAssetImage,
    toAssetImage,
    fromProfileImage: fromMetadata?.avatarUrl,
    toProfileImage: toMetadata?.avatarUrl,
    fromAmount: undefined,
    toAmount: undefined,
    isContractExecution: relevantTransfer?.contractAddress !== BASE_ASSET_ADDRESS,
    toNetwork: undefined,
    toCurrencyCode: undefined,
    toTokenName: undefined,
    toTokenDecimal: undefined,
    coinbaseFeeAmount: undefined,
    coinbaseFeeDecimal: undefined,
    coinbaseFeeAssetAddress: undefined,
    coinbaseFeeCurrencyCode: undefined,
    coinbaseFeeName: undefined,
    protocolFeeAmount: undefined,
    protocolFeeDecimal: undefined,
    protocolFeeCurrencyCode: undefined,
    protocolFeeName: undefined,
    toWalletId: undefined,
    fromTokenId: isSent ? tokenId : undefined,
    toTokenId: isSent ? undefined : tokenId,
    source: 'V3',
  } as TxOrUserOpParams);
}

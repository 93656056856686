import { useEffect } from 'react';
import { DefiPositionRow } from 'cb-wallet-data/stores/DefiPortfolio/models/DefiPosition';
import { useAddTrackedExchangeRate } from 'cb-wallet-data/stores/ExchangeRates/hooks/useAddTrackedExchangeRate';
import { getNetworkId } from 'cb-wallet-data/stores/Networks/utils/getNetworkId';

import { isLiquidityPoolPosition } from '../utils/isLiquidityPoolPosition';

type UseTrackRatesForProtocolPositionsProps = {
  protocolPositions: DefiPositionRow[];
};

export function useTrackRatesForProtocolPositions({
  protocolPositions,
}: UseTrackRatesForProtocolPositionsProps) {
  const addTrackedRate = useAddTrackedExchangeRate();

  useEffect(() => {
    protocolPositions.forEach((position) => {
      if (isLiquidityPoolPosition(position)) {
        position.assetInfos.forEach(({ asset }) => {
          addTrackedRate({
            code: asset.currencyCode,
            chainId: BigInt(asset.network.asChain()?.chainId ?? 0),
            networkId: getNetworkId({ network: asset.network, blockchain: asset.blockchain }) ?? '',
            contractAddress: asset.address?.rawValue,
          });
        });
      } else {
        addTrackedRate({
          code: position.assetInfo.asset.currencyCode,
          chainId: BigInt(position.assetInfo.asset.network.asChain()?.chainId ?? 0),
          networkId:
            getNetworkId({
              network: position.assetInfo.asset.network,
              blockchain: position.assetInfo.asset.blockchain,
            }) ?? '',
          contractAddress: position.assetInfo.asset.address?.rawValue,
        });
      }
    });
  }, [addTrackedRate, protocolPositions]);
}

import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { messages } from 'cb-wallet-data/stores/SettingsRedesign/messages/display';
import {
  SettingCategory,
  SettingCategoryConfig,
  SubCategoryParam,
} from 'cb-wallet-data/stores/SettingsRedesign/types';
import { generateSubCategoriesForSearch } from 'cb-wallet-data/stores/SettingsRedesign/utils/generateSubCategoriesForSearch';
import { useRouter } from 'next/router';

import { useSearchContext } from ':dapp/providers/SearchProvider';
import { SettingRoutesEnum } from ':dapp/utils/RoutesEnum';

import { SETTING_CATEGORY_INNER_SPACING } from '../constants';

export function useDisplayCategoryConfig(): SettingCategoryConfig {
  const { formatMessage } = useIntl();
  const { toggleSearchOff } = useSearchContext();
  const router = useRouter();

  const onPress = useCallback(() => {
    router.push(SettingRoutesEnum.DISPLAY);
    toggleSearchOff();
  }, [router, toggleSearchOff]);

  const subCategoriesParams = useMemo<SubCategoryParam[]>(() => {
    const params = [
      { title: formatMessage(messages.appearanceSectionTitle), testID: 'appearance-section' },
      { title: formatMessage(messages.darkTitle), testID: 'dark-mode' },
      { title: formatMessage(messages.lightTitle), testID: 'light-mode' },
      { title: formatMessage(messages.systemTitle), testID: 'system-mode' },
      { title: formatMessage(messages.balanceSectionTitle), testID: 'balance-section' },
      {
        title: formatMessage(messages.localCurrencyTitle),
        testID: 'local-currency',
      },
      { title: formatMessage(messages.privacyModeSettingTitle), testID: 'privacy-mode' },
      { title: formatMessage(messages.hideSmallBalancesTitle), testID: 'hide-small-balances' },
    ];

    return params;
  }, [formatMessage]);

  const categoryConfig = useMemo<SettingCategoryConfig>(() => {
    return {
      title: formatMessage(messages.title),
      description: formatMessage(messages.dappDescription),
      media: SettingCategory.DISPLAY,
      accessory: 'arrow',
      testID: `${SettingCategory.DISPLAY}-setting`,
      navigationPath: SettingRoutesEnum.DISPLAY,
      innerSpacing: SETTING_CATEGORY_INNER_SPACING,
      iconName: 'bell',
      onPress,
    };
  }, [formatMessage, onPress]);

  const subCategories = useMemo(
    () => generateSubCategoriesForSearch(categoryConfig, subCategoriesParams),
    [categoryConfig, subCategoriesParams],
  );

  categoryConfig.subCategories = subCategories;

  return categoryConfig;
}

import { OnrampAsset } from 'cb-wallet-data/stores/Buy/models/OnrampAsset';
import orderBy from 'lodash/orderBy';

import { calculateSearchScore } from './calculateSearchScore';

type Props = {
  assets: OnrampAsset[];
  query: string;
};

// Sort based on:
// 1. relevance (earliest occurrence of query in assets by asset currency code)
// 2. coverage (overlap between query and asset name, as a percentage)
export function computeOnrampAssetSearchResults({ assets, query }: Props): OnrampAsset[] {
  if (!query) {
    return Object.values(assets);
  }

  const reducedAssets = Object.values(assets).reduce<OnrampAsset[]>(
    function calculateSearchScoreForAsset(accumulator, currentValue) {
      const searchScore = calculateSearchScore(query, currentValue.code, currentValue.name);

      if (searchScore.relevance !== null) {
        accumulator.push(currentValue);
      }

      return accumulator;
    },
    [],
  );

  return orderBy(reducedAssets, ['relevance', 'coverage'], ['asc', 'desc']);
}

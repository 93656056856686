import { useMemo } from 'react';
import { Button } from 'wallet-cds-web/components/Button';
import { GradientButton } from 'wallet-cds-web/components/GradientButton';
import { IconName } from '@cbhq/cds-common';
import { IconButton } from '@cbhq/cds-web/buttons';

import { useIsFeatureEnabledForScw } from ':dapp/hooks/Scw/useIsFeatureEnabledForScw';

import { useConnectButton } from './useConnectButton';

type ConnectButtonProps = {
  buttonType?: 'default' | 'gradient' | 'icon';
  onOpen?: () => void;
  variant?: 'primary' | 'secondary';
  type?: 'add' | 'connect';
  endIcon?: IconName;
  block?: boolean;
};

export function ConnectButton({
  buttonType = 'default',
  onOpen,
  variant = 'secondary',
  type = 'connect',
  endIcon,
  block = false,
}: ConnectButtonProps) {
  const { buttonText, handleOpenModal } = useConnectButton({ onOpen, type });
  const isSCWEnabled = useIsFeatureEnabledForScw('add_wallet');

  return useMemo(() => {
    if (!isSCWEnabled) return null;

    switch (buttonType) {
      case 'gradient':
        return (
          <GradientButton
            compact
            variant={variant}
            endIcon={endIcon}
            testID={`gradient-${type}-wallet-btn`}
            onPress={handleOpenModal}
          >
            {buttonText}
          </GradientButton>
        );
      case 'icon':
        return (
          <IconButton
            testID={`icon-${type}-wallet-btn`}
            name="add"
            variant={variant}
            transparent
            onPress={handleOpenModal}
          />
        );
      case 'default':
      default: {
        return (
          <Button
            block={block}
            compact
            endIcon={endIcon}
            onPress={handleOpenModal}
            variant={variant}
            testID={`default-${type}-wallet-btn`}
          >
            {buttonText}
          </Button>
        );
      }
    }
  }, [block, buttonText, buttonType, endIcon, handleOpenModal, isSCWEnabled, type, variant]);
}

// eslint-disable-next-line no-restricted-imports
import type { ec as ECType } from 'elliptic';
import createKeccakHash from 'keccak';

import { getSecp256k1 } from './getSecp256k1';
import { strip0x } from './utils';

export async function decompressPublicKey(publicKey: Buffer): Promise<Buffer> {
  const secp256k1 = await getSecp256k1();

  const { length } = publicKey;
  const firstByte = publicKey[0];
  if ((length !== 33 && length !== 65) || firstByte < 2 || firstByte > 4) {
    throw new Error('invalid public key');
  }
  let key: ECType.KeyPair;
  try {
    key = secp256k1.keyFromPublic(publicKey);
  } catch (_err) {
    throw new Error('invalid public key');
  }
  return Buffer.from(key.getPublic().encode('array', false));
}

export class Address {
  private _compressedPublicKey: Buffer;

  private _publicKey: Buffer | undefined;

  private _rawAddress?: Buffer;

  private _address?: string;

  private constructor(publicKey: Buffer) {
    this._compressedPublicKey = publicKey;
  }

  public static from(publicKey: Buffer): Address {
    return new Address(publicKey);
  }

  public static checksumAddress(address: string): string {
    if (!isValidFormat(address)) {
      throw new Error('invalid address');
    }

    const addr = strip0x(address).toLowerCase();
    const hash = createKeccakHash('keccak256').update(addr, 'ascii').digest('hex');
    let newAddr = '0x';

    for (let i = 0; i < addr.length; i++) {
      if (parseInt((hash as unknown as string)[i], 16) >= 8) {
        newAddr += addr[i].toUpperCase();
      } else {
        newAddr += addr[i];
      }
    }

    return newAddr;
  }

  /**
   * returns undefined when address is valid ethereum EOA address. otherwise will return specific Error indicating
   * issue
   * @param address
   * @param skipChecksumValidation whether or not strict checksum validation should be applied for determining the validity
   * of the address
   */
  public static getAddressValidationError(
    address: string,
    skipChecksumValidation: boolean,
  ): Error | ChecksumError | undefined {
    if (!isValidFormat(address)) {
      return Error('Invalid ethereum address');
    }

    if (skipChecksumValidation) {
      return undefined;
    }

    const addr = strip0x(address);
    if (addr.match(/[0-9a-f]{40}/) || addr.match(/[0-9A-F]{40}/)) {
      return undefined;
    }

    let checksumAddress: string;
    try {
      checksumAddress = Address.checksumAddress(addr);
    } catch (_err) {
      return new ChecksumError('Valid ethereum address failed checksum validation');
    }

    if (addr === checksumAddress.slice(2)) {
      return undefined;
    }

    return new ChecksumError('Valid ethereum address failed checksum validation');
  }

  public static isValid(address: string): boolean {
    if (!isValidFormat(address)) {
      return false;
    }

    const addr = strip0x(address);
    if (addr.match(/[0-9a-f]{40}/) || addr.match(/[0-9A-F]{40}/)) {
      return true;
    }

    let checksumAddress: string;
    try {
      checksumAddress = Address.checksumAddress(addr);
    } catch (_err) {
      return false;
    }

    return addr === checksumAddress.slice(2);
  }

  public async getPublicKey(): Promise<Buffer> {
    if (!this._publicKey) {
      const decompressedKey = await decompressPublicKey(this._compressedPublicKey);
      this._publicKey = decompressedKey;
    }
    return this._publicKey;
  }

  public async getRawAddress(): Promise<Buffer> {
    if (!this._rawAddress) {
      const publicKey = await this.getPublicKey();
      this._rawAddress = createKeccakHash('keccak256')
        .update(publicKey.slice(1))
        .digest()
        .slice(-20);
    }
    return this._rawAddress;
  }

  public async getAddress(): Promise<string> {
    if (!this._address) {
      const rawAddress = await this.getRawAddress();
      this._address = Address.checksumAddress(rawAddress.toString('hex'));
    }
    return this._address;
  }
}

function isValidFormat(address: string): boolean {
  return !!strip0x(address).match(/^[0-9a-fA-F]{40}$/);
}

// Export as a function that apps can be registered with data layer service locator
export async function secp256k1AddressFromPublicKey(publicKey: Buffer): Promise<string> {
  return Address.from(publicKey).getAddress();
}

export class ChecksumError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

import { memo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useRecentSearches } from 'cb-wallet-data/stores/Search/hooks/useRecentSearches';
import { SearchEntity } from 'cb-wallet-data/stores/Search/types';
import { IconButton } from '@cbhq/cds-web/buttons';
import { SearchInput } from '@cbhq/cds-web/controls';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { Divider, HStack, VStack } from '@cbhq/cds-web/layout';
import { deviceBreakpoints } from '@cbhq/cds-web/layout/breakpoints';
import { Modal } from '@cbhq/cds-web/overlays';
import { RecentSearches } from ':dapp/components/Search/RecentSearches';
import { useSearchContext } from ':dapp/providers/SearchProvider';
import { messages } from './messages';
import { SearchResultList } from './SearchResultList';
export const modalColorOverride = "m1v9t0jx";
export const SearchMobile = memo(function SearchMobile() {
  const {
    formatMessage
  } = useIntl();
  const {
    isSearching,
    toggleSearchOff,
    query,
    setQuery,
    debouncedQuery
  } = useSearchContext();
  const scrollRef = useRef<HTMLElement>(null);
  const {
    isPhoneLandscape
  } = useBreakpoints();
  const [searchFilter, setSearchFilter] = useState<SearchEntity>(SearchEntity.SEARCH_ENTITY_UNSPECIFIED);
  const {
    recentSearches
  } = useRecentSearches();
  return <Modal visible={isSearching} onRequestClose={toggleSearchOff} testID="mobile-search-modal">
      <HStack spacingVertical={1} spacingHorizontal={2} gap={1} width={isPhoneLandscape ? deviceBreakpoints.phoneLandscape : 'auto'} testID="mobile-search-bar">
        <IconButton name="backArrow" transparent onPress={toggleSearchOff} testID="mobile-search-back-button" />
        <SearchInput testID="mobile-search-input" key="mobile-search-input" compact value={query} width="100%" height={40} autoCapitalize="none" onChangeText={setQuery} placeholder={formatMessage(messages.searchPlaceholderText)} autoFocus hideStartIcon />
      </HStack>
      <Divider />
      {debouncedQuery.length > 2 && <VStack ref={scrollRef} key="mobile-search-results-container" testID="mobile-search-results-container" spacingTop={1} height="100%" overflow="auto">
          <SearchResultList query={debouncedQuery} searchFilter={searchFilter} setSearchFilter={setSearchFilter} />
        </VStack>}
      {!debouncedQuery.length && recentSearches.length > 0 && <RecentSearches />}
    </Modal>;
});

require("./SearchMobile.linaria.module.css!=!../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./SearchMobile.tsx");
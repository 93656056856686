import { useMemo } from 'react';
import {
  DefiPosition,
  DefiPositionRow,
  DefiPositionType,
  LiquidityPoolPosition,
} from 'cb-wallet-data/stores/DefiPortfolio/models/DefiPosition';
import { memoizeDerivedValue } from 'cb-wallet-data/utils/memoizeDerivedValue';

export type UseFilterDefiProtocolPositionsProps = {
  protocolPositions: DefiPositionRow[];
  selectedNetworkFilters?: string[];
  selectedWalletFilters?: string[];
};

export function useFilterDefiProtocolPositions({
  protocolPositions,
  selectedNetworkFilters,
  selectedWalletFilters,
}: UseFilterDefiProtocolPositionsProps) {
  // Filter the positions by network
  const positionsFilteredByNetwork = useMemo(() => {
    if (!selectedNetworkFilters || selectedNetworkFilters?.length === 0) {
      return protocolPositions;
    }

    return protocolPositions.filter(function filterPositionByType(position) {
      const assetInfo =
        position.type === DefiPositionType.LiquidityPool
          ? (position as LiquidityPoolPosition).assetInfos[0]
          : (position as DefiPosition).assetInfo;
      const isOnFilteredNetwork =
        selectedNetworkFilters.includes(assetInfo.asset.network.rawValue) ||
        selectedNetworkFilters.includes(assetInfo.asset.blockchain.rawValue);

      return isOnFilteredNetwork;
    });
  }, [protocolPositions, selectedNetworkFilters]);

  // If we ever support solana positions we may need to revisit this, not all addressed are case insensitive
  const normalizedWalletFilers = memoizeDerivedValue(selectedWalletFilters ?? [], () =>
    selectedWalletFilters?.map((address) => address.toLowerCase()),
  );

  // Filter the positions by wallet address
  return useMemo(() => {
    if (!selectedWalletFilters || selectedWalletFilters.length === 0) {
      return positionsFilteredByNetwork;
    }

    return positionsFilteredByNetwork.filter(
      (position) => {
        return normalizedWalletFilers?.includes(position.walletAddress.toLowerCase());
      },
      [protocolPositions, selectedNetworkFilters],
    );
  }, [
    normalizedWalletFilers,
    positionsFilteredByNetwork,
    protocolPositions,
    selectedNetworkFilters,
    selectedWalletFilters,
  ]);
}

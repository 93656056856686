import { CellProps } from '@cbhq/cds-web/cells/Cell';

export const SETTING_CATEGORY_INNER_SPACING: CellProps['innerSpacing'] = {
  spacingVertical: 2,
  spacingHorizontal: 2,
};

export const NO_DESCRIPTION_SETTING_CATEGORY_INNER_SPACING: CellProps['innerSpacing'] = {
  spacingVertical: 3,
  spacingHorizontal: 3,
};

export const ZERO_OUTER_SPACING: CellProps['outerSpacing'] = {
  spacingVertical: 0,
  spacingHorizontal: 0,
};

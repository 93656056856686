import { AssetManagementStatus } from '../models/AssetManagementStatus';
import { CuratedAssetSetting } from '../models/CuratedAssetSetting';
import { UserCollectibleSetting } from '../models/UserCollectibleSetting';
import { UserWalletSetting } from '../models/UserWalletSetting';

export function isAssetStatusChangeRequired(
  userSetting: UserWalletSetting | UserCollectibleSetting,
  curatedAssetSetting: CuratedAssetSetting,
) {
  const isStatusSame = (status1: AssetManagementStatus, status2: AssetManagementStatus) =>
    status1 === status2;
  const noChangeStatuses = [AssetManagementStatus.userSpam, AssetManagementStatus.userSpamVisible];

  return (
    !userSetting ||
    !(
      isStatusSame(userSetting.status, curatedAssetSetting.status) ||
      noChangeStatuses.includes(userSetting.status)
    ) ||
    (userSetting.status === AssetManagementStatus.spam &&
      curatedAssetSetting.status === AssetManagementStatus.whitelist)
  );
}

import { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import {
  SettingCategory,
  SettingCategoryConfig,
} from 'cb-wallet-data/stores/SettingsRedesign/types';

import { CB_WALLET_SCW_URL } from ':dapp/config/env';
import { SETTING_CATEGORY_INNER_SPACING } from ':dapp/pages/settings/constants';
import { useSearchContext } from ':dapp/providers/SearchProvider';
import { navigateToExternalUrl } from ':dapp/utils/navigateToExternalUrl';

const messages = defineMessages({
  title: {
    defaultMessage: 'Smart Wallet',
    description: 'Title for cell that lets user see smart wallet settings',
  },
  description: {
    defaultMessage: 'Settings for your smart wallet',
    description: 'Description for cell that lets user see smart wallet settings',
  },
});

export function useSmartWalletSettingsConfig(): SettingCategoryConfig {
  const { formatMessage } = useIntl();
  const { toggleSearchOff } = useSearchContext();

  const onPress = useCallback(() => {
    navigateToExternalUrl(`${CB_WALLET_SCW_URL}/settings`);
    toggleSearchOff();
  }, [toggleSearchOff]);

  return useMemo<SettingCategoryConfig>(() => {
    return {
      title: formatMessage(messages.title),
      description: formatMessage(messages.description),
      media: SettingCategory.SMART_WALLET,
      accessory: 'externalLink',
      testID: `${SettingCategory.SMART_WALLET}-setting`,
      innerSpacing: SETTING_CATEGORY_INNER_SPACING,
      onPress,
    };
  }, [formatMessage, onPress]);
}

import {
  WalletConnectionErrorType,
  WalletProviderInfo,
  WalletProviderNetwork,
} from ':dapp/connection/types';

import { SigningMethodError, SigningMethodErrorType } from '../SigningMethodError';

/**
 * This function validates that the wallet provider (i.e. CoinbaseWallet, MetaMask, etc..) is connected for a given
 * network (i.e. Ethereum, Solana, etc..). If the wallet provider for a given network is not connected,
 * this function attempts to establish a new connection between the dapp and wallet provider.
 *
 * This is important because some wallet providers, like WalletConnect may loose
 * their connection w/o the dapp knowing about it.
 *
 * This should be used before any web3 signing method.
 *
 * @param connector {WalletProviderInfo['connector']} The wallet provider connector
 * @param network {WalletProviderNetwork} The network to connect to
 * @throws {SigningMethodError} - SigningMethodErrorType.UserRejectedRequest If the user rejects the connection request
 * @throws {SigningMethodError} - SigningMethodErrorType.Generic If the connection fails for any other reason
 */
export async function validateWalletProvidersConnected(
  connectorManager: WalletProviderInfo['connectorManager'],
  network: WalletProviderNetwork,
) {
  const connected = await connectorManager.isConnected(network);
  if (!connected) {
    try {
      const { error } = await connectorManager.connect(network);
      if (!error) {
        return;
      }
      if (error === WalletConnectionErrorType.USER_REJECTED) {
        throw new SigningMethodError(
          SigningMethodErrorType.UserRejectedRequest,
          'User rejected request',
        );
      }
      throw error;
    } catch (error) {
      let message = 'unknown';
      if (error instanceof Error) {
        message = error.message;
      }
      if (typeof error === 'string') {
        message = error;
      }
      throw new SigningMethodError(SigningMethodErrorType.Generic, message);
    }
  }
}

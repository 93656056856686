import { DataMigration, DataMigrationParams } from 'cb-wallet-data/DataMigrationRunner/types';
import { getAccounts } from 'cb-wallet-data/stores/Accounts/database';
import {
  deleteAddresses,
  getAllAddresses,
  saveAddresses,
} from 'cb-wallet-data/stores/Addresses/database';
import { Address } from 'cb-wallet-data/stores/Addresses/models/Address';
import { deleteWallets, getWallets, saveWallets } from 'cb-wallet-data/stores/Wallets/database';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';

/**
 * Migration script to ensure all wallets and addresses include an
 * accountId field and the accountId in the model ID.
 *
 * With the addition of multi-account features, Wallet and Address
 * models now utilize an accountId, to distinguish these records
 * between multiple accounts.
 *
 * 1. Wallet.accountId + Address.accountId
 *
 * Moving forward, lookups of wallets and addresses require factoring
 * in the accountId; so for any users who have onboarded before
 * multi-account features existed, we must add the accountId field to
 * all of their pre-existing wallets and addresses in client database.
 *
 * 2. Wallet.id + Address.id
 *
 * The format of Wallet and Address IDs has been changed to allow them
 * to exist for multiple accounts, by adding the accountId to the end.
 *
 * Address ID format:
 * Previous: blockchain/currencyCode/network/type/isChangeAddress/index
 * Multi-account: blockchain/currencyCode/network/type/isChangeAddress/index/<accountId: accountType/primaryAddressChain/primaryAddress>
 *
 * Wallet ID format:
 * Previous: blockchain/currencyCode/network/contractAddress/index
 * Multi-account: blockchain/currencyCode/network/contractAddress/index/<accountId: accountType/primaryAddressChain/primaryAddress>
 */
const addAccountIdToWalletsAndAddresses: DataMigration = {
  // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
  // eslint-disable-next-line wallet/no-anonymous-params
  up: async (_params: DataMigrationParams) => {
    // eslint-disable-next-line no-console
    console.log('Migration: addAccountIdToWalletsAndAddresses');

    const accounts = await getAccounts();

    // We can't add accountId to wallets and addresses without an account.
    // If there are no accounts, we're assuming either:
    // 1. User has not onboarded yet.
    // 2. We're in a bad state, as migrateForMultiAccount should have added the first account.
    if (!accounts.length) return;

    // If user has multiple accounts, they would have already been using the app with
    // multi-account features in place, and this migration should have executed already.
    //
    // It's problematic to allow this user to continue because we can't be sure what
    // accountId should be attached to every wallet and address.
    if (accounts.length > 1) {
      throw new Error('user already has multiple accounts');
    }

    const accountId = accounts[0].id; // accounts items should always contain exactly one item
    const [prevWallets, prevAddresses] = await Promise.all([getWallets(), getAllAddresses()]);
    const walletIdsToDelete: Wallet['id'][] = [];
    const addressIdsToDelete: Address['id'][] = [];

    // 1) If any wallet doesn't have correct Wallet.accountId or Wallet.id,
    // set both values, and queue that wallet to be updated in the db.
    // We are not spreading the reduced object here so it is fine
    // eslint-disable-next-line wallet/no-spread-in-reduce
    const nextWallets = prevWallets.reduce<Wallet[]>(function reduceWalletsThatNeedMigration(
      acc,
      prevWallet,
    ) {
      const hasAccountIdField = prevWallet.accountId === accountId;
      const hasMultiAccountFormattedId = prevWallet.id.endsWith(accountId);
      const isValidWalletId = Wallet.isValidFormattedId(prevWallet.id);

      if (!hasAccountIdField || !hasMultiAccountFormattedId || !isValidWalletId) {
        const nextWalletId = Wallet.generateId({
          blockchain: prevWallet.blockchain,
          currencyCode: prevWallet.currencyCode,
          network: prevWallet.network,
          contractAddress: prevWallet.contractAddress,
          selectedIndex: prevWallet.selectedIndex,
          accountId,
        });

        const nextWallet = Wallet.fromDMO({
          ...prevWallet.asDMO,
          id: nextWalletId,
          accountId,
        });

        acc.push(nextWallet);

        const isNewRecord = nextWalletId !== prevWallet.id;
        if (isNewRecord) walletIdsToDelete.push(prevWallet.id);
      }

      return acc;
    },
    []);

    // 2) If any address doesn't have correct Address.accountId or Address.id,
    // set both values, and queue that address to be updated in the db.
    // We are not spreading the reduced object here so it is fine
    // eslint-disable-next-line wallet/no-spread-in-reduce
    const nextAddresses = prevAddresses.reduce<Address[]>(function reduceAddressesThatNeedMigration(
      acc,
      prevAddress,
    ) {
      const hasAccountIdField = prevAddress.accountId === accountId;
      const hasMultiAccountFormattedId = prevAddress.id.endsWith(accountId);

      if (!hasAccountIdField || !hasMultiAccountFormattedId) {
        const nextAddressId = Address.generateId({
          blockchain: prevAddress.blockchain,
          currencyCode: prevAddress.currencyCode,
          network: prevAddress.network,
          type: prevAddress.type,
          isChangeAddress: prevAddress.isChangeAddress,
          index: prevAddress.index,
          accountId,
        });

        const nextAddress = Address.fromDMO({
          ...prevAddress.asDMO,
          id: nextAddressId,
          accountId,
        });

        acc.push(nextAddress);

        const isNewRecord = nextAddressId !== prevAddress.id;
        if (isNewRecord) addressIdsToDelete.push(prevAddress.id);
      }

      return acc;
    },
    []);

    // 3) For any updated wallets or addresses, save them to the db.
    const saveChangesToDbPromises = [];

    if (nextWallets.length > 0) {
      saveChangesToDbPromises.push(saveWallets(nextWallets));
    }

    if (nextAddresses.length > 0) {
      saveChangesToDbPromises.push(saveAddresses(nextAddresses));
    }

    // 4) For any wallets or addresses which are saved as new records
    // from changing the ID, delete the previous records from the db.
    if (walletIdsToDelete.length) {
      saveChangesToDbPromises.push(deleteWallets(walletIdsToDelete));
    }

    if (addressIdsToDelete.length) {
      saveChangesToDbPromises.push(deleteAddresses(addressIdsToDelete));
    }

    await Promise.all(saveChangesToDbPromises);
  },
};

export default addAccountIdToWalletsAndAddresses;

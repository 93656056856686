import { ETHEREUM_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';

/**
 * Finds the first ETH wallet at 0 index, which will have the ETH address
 * we can use to build new account data from.
 *
 * Note: If the user has no accounts, the most likely case is that they
 * will have one ETH wallet at 0 index, because they're coming from a
 * version of the app prior to multi-account functionality.
 */
export function findPrimaryWallet(wallets: Wallet[]): Wallet | undefined {
  return wallets.find(
    (wallet) =>
      wallet.selectedIndex === 0n && wallet.blockchain.rawValue.toUpperCase() === ETHEREUM_SYMBOL,
  );
}

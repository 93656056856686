import { useMemo } from 'react';

import { useQueryCapabilitiesForAccountId } from ':dapp/hooks/Scw/useQueryCapabilitiesForAccountId';

import { WalletCapabilities } from './getCapabilitiesUtils';

/**
 * Returns true if the account associated w/ `accountId` is a smart contract wallet.
 * This indicates that the wallet is an SCW AND that it is actively connected via the provider.
 *
 * @param accountId
 * @param network
 */
export function useIsScwAccountConnected(accountId: string | undefined) {
  const capabilities = useQueryCapabilitiesForAccountId(accountId);

  return useMemo(() => {
    if (!accountId) {
      return false;
    }

    if (!capabilities) {
      return false;
    }

    return isScwAccount(capabilities);
  }, [accountId, capabilities]);
}

export function isScwAccount(capabilities: WalletCapabilities) {
  return capabilities[1]?.atomicBatch?.supported ?? false;
}

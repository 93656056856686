import { memo } from 'react';
import { AvatarShape, AvatarSize, PaletteBorder } from '@cbhq/cds-web';
import { Avatar } from '@cbhq/cds-web/media';

type ProfileAvatarProps = {
  testID?: string;
  shape?: AvatarShape;
  size?: AvatarSize;
  src?: string;
  borderColor?: PaletteBorder;
  alt: string;
};

export const DAppAvatar = memo(function DAppAvatar({
  testID,
  shape = 'square',
  size = 'l',
  src,
  alt,
  borderColor,
}: ProfileAvatarProps) {
  return (
    <Avatar
      borderColor={borderColor}
      testID={testID}
      alt={alt}
      size={size}
      src={src}
      shape={shape}
    />
  );
});

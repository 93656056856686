import { logEthTransactionAcceptedButReverted } from 'cb-wallet-analytics/data/Transactions';
import {
  getEthereumRPCURL,
  getEthereumTransactionReceipt,
} from 'cb-wallet-data/chains/AccountBased/Ethereum/apis/EthereumRPC';
import {
  getUnminedSignedTxs,
  saveTransaction,
} from 'cb-wallet-data/chains/AccountBased/Ethereum/database';
import { EthereumChain } from 'cb-wallet-data/chains/AccountBased/Ethereum/EthereumChain';
import { EthereumSignedTx } from 'cb-wallet-data/chains/AccountBased/Ethereum/models/EthereumSignedTx';
import {
  ResubmitPendingTransactionsArgs,
  ResubmitPendingTransactionsReturn,
} from 'cb-wallet-data/chains/AccountBased/operations/resubmitPendingTransactions';
import { filterPendingTransactions } from 'cb-wallet-data/chains/AccountBased/utils/filterPendingTransactions';
import { NetworkError } from 'cb-wallet-data/stores/Networks/NetworkError';
import { SignedTx } from 'cb-wallet-data/stores/Transactions/interfaces/SignedTx';
import { TxState } from 'cb-wallet-data/stores/Transactions/models/TxState';
import { mapNotNull } from 'cb-wallet-data/utils/Array';
import partition from 'lodash/partition';
import { submitSignedEthereumTransaction } from 'wallet-engine-signing/signing/ethereum/submitSignedTransaction';

import { checkSubmittedWeb3Transactions } from './checkSubmittedWeb3Transactions';

class EthereumTxReceiptsResult {
  constructor(readonly tx: EthereumSignedTx, readonly isSuccessful: boolean) {}
}

/**
 * Max number of received errors from eth node for a given tx before marking the tx as dropped
 */
const maxResubmissionNodeErrors = 5n;

/**
 * Gets called by TxHistoryRepo refreshable to repeatedly resubmit Eth transactions that haven't been included in a
 * confirmed block. Pending eth tx's are stored in the `EthereumSignedTx` table. Since a tx might get dropped from
 * mempool during heavy congestion, we resubmit until:
 *
 * (a) tx is included in a block. tx might still revert/fail even if executed
 * (b) we receive `maxResubmissionNodeErrors` from eth node when attempting to resubmit, indicating there is an issue
 * with the node
 */
export async function resubmitPendingTransactions({
  network,
  syncUpdateToTxHistoryTable,
}: ResubmitPendingTransactionsArgs): ResubmitPendingTransactionsReturn {
  const ethereumChain = network.asChain() as EthereumChain;
  if (!ethereumChain) {
    throw NetworkError.invalidNetwork(network);
  }

  // Get list of unmined signed transactions on all chains. This excludes dropped transaction
  const unminedSignedTxs = await getUnminedSignedTxs(ethereumChain);

  // Filter unmined txs based on whether or not they have signedTxData
  // Web3 transactions do not store the signature, therefore we can't resubmit them
  // Txs status will be verified and the tx state will be updated accordingly
  // for web3 transactions
  const [unminedSignedTxsFiltered, submittedWeb3Txs] = partition(
    unminedSignedTxs,
    (tx) => tx.signedTxData.length !== 0,
  );

  // Check status of all unmined signed transactions
  const transactionsReceipts = await getEthereumTxReceipts(unminedSignedTxsFiltered);

  // Get pending transactions without a receipt
  const pendingTransactions = filterPendingTransactions(transactionsReceipts);

  // Submit pending transactions using CipherCore
  await submitUnconfirmedSignedTxs(pendingTransactions, syncUpdateToTxHistoryTable);

  // Check status of all submitted web3 transactions
  await checkSubmittedWeb3Transactions(submittedWeb3Txs, syncUpdateToTxHistoryTable);

  const ethereumTxReceiptsResults = mapNotNull(transactionsReceipts, ([receipt, tx]) =>
    !receipt ? null : new EthereumTxReceiptsResult(tx, receipt.isSuccessful),
  );

  // If we receive a receipt, that means the transaction has been successfully submitted to ethereum
  // note: this doesn't mean it will succeed. It only means that we don't have to keep resubmitting
  // the pending transaction
  await Promise.all(
    ethereumTxReceiptsResults.map(
      // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
      // eslint-disable-next-line wallet/no-anonymous-params
      async ({ isSuccessful, tx }) => {
        let state: TxState;
        if (isSuccessful) {
          state = TxState.CONFIRMED;
        } else {
          logEthTransactionAcceptedButReverted();
          state = TxState.FAILED;
        }

        const signedTx = new EthereumSignedTx(
          tx.id,
          tx.fromAddress,
          tx.toAddress,
          tx.nonce,
          tx.chainId,
          tx.signedTxData,
          tx.txHash,
          tx.weiValue,
          tx.erc20Value,
          tx.blockchain,
          tx.currencyCode,
          state,
          tx.notFoundCount,
        );

        return saveTransaction(signedTx).then(() => {
          syncUpdateToTxHistoryTable(signedTx);
        });
      },
    ),
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ReceiptTransactionPair = [any | null, EthereumSignedTx];

/**
 * Get ethereum transaction receipts for given transactions
 */
export async function getEthereumTxReceipts(
  txs: EthereumSignedTx[],
): Promise<ReceiptTransactionPair[]> {
  if (!txs.length) {
    return Promise.resolve([]);
  }

  const receiptTransactionPairs: (ReceiptTransactionPair | null)[] =
    await Promise.all<ReceiptTransactionPair | null>(
      txs.map(async (tx: EthereumSignedTx) => {
        try {
          const receipt = await getEthereumTransactionReceipt(tx.txHash, Number(tx.chainId), null);
          return [receipt, tx];
        } catch (error) {
          return [null, tx];
        }
      }),
    );

  return receiptTransactionPairs.filter((pairs): pairs is ReceiptTransactionPair => !!pairs);
}

/**
 * Submit list of unconfirmed signed transactions
 */
export async function submitUnconfirmedSignedTxs(
  txs: EthereumSignedTx[],
  syncUpdateToTxHistoryTable: (transaction: SignedTx) => void,
): Promise<EthereumSignedTx[]> {
  if (txs.length === 0) {
    return Promise.resolve([]);
  }

  await Promise.all(
    txs.map(async (tx: EthereumSignedTx) => {
      try {
        await submitSignedEthereumTransaction(
          tx.signedTxData,
          getEthereumRPCURL(Number(tx.chainId)),
        );
      } catch (err) {
        // If we receive an error when submitting a transaction, then it's possible the node is down and we should
        // continue to attempt resubmission which will happen again in X seconds. We will abort resubmission attempts
        // after `maxResubmissionNodeErrors` are received from eth node
        try {
          const receipt = await getEthereumTransactionReceipt(tx.txHash, Number(tx.chainId), null);

          // eslint-disable-next-line eqeqeq
          if (receipt != null) {
            return;
          }

          let updatedTx: EthereumSignedTx;
          if (tx.notFoundCount > maxResubmissionNodeErrors) {
            updatedTx = new EthereumSignedTx(
              tx.id,
              tx.fromAddress,
              tx.toAddress,
              tx.nonce,
              tx.chainId,
              tx.signedTxData,
              tx.txHash,
              tx.weiValue,
              tx.erc20Value,
              tx.blockchain,
              tx.currencyCode,
              TxState.DROPPED,
              tx.notFoundCount,
            );
          } else {
            updatedTx = new EthereumSignedTx(
              tx.id,
              tx.fromAddress,
              tx.toAddress,
              tx.nonce,
              tx.chainId,
              tx.signedTxData,
              tx.txHash,
              tx.weiValue,
              tx.erc20Value,
              tx.blockchain,
              tx.currencyCode,
              tx.state,
              tx.notFoundCount + 1n,
            );
          }

          return await saveTransaction(updatedTx).then(function updateTxHistoryTable(signedTx) {
            syncUpdateToTxHistoryTable(updatedTx);
            return signedTx;
          });
        } catch (error) {
          return null;
        }
      }
    }),
  );

  return txs;
}

import { logDataEvent } from 'cb-wallet-analytics/utils/log';
import { useOnMount } from 'cb-wallet-data/hooks/useOnMount';
import { Box } from '@cbhq/cds-web/layout';
import { Spinner } from '@cbhq/cds-web/loaders/Spinner';

export function AppFallback() {
  useOnMount(function AppFallbackTest() {
    const timerRefLocal = window.setTimeout(() => {
      logDataEvent('root.fallback_spinner.long_wait', {});
      clearTimeout(timerRefLocal);
    }, 10_000);

    return () => {
      clearTimeout(timerRefLocal);
    };
  });

  return (
    <Box
      testID="app-fallback"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      width="100%"
    >
      <Spinner size={4} color="primary" />
    </Box>
  );
}

import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { useWalletGroupSupportsUTXO } from 'cb-wallet-data/hooks/useWalletGroupSupportsUTXO/useWalletGroupSupportsUTXO';
import { isSCWSupportedChainId } from 'cb-wallet-data/scw/util/supportedNetworks';
import { useAccountByWalletGroupId } from 'cb-wallet-data/stores/Accounts/hooks/useAccountByWalletGroupId';

import { onrampServiceAuthedConfig } from '../config';
import { OnrampNetwork } from '../types/OnrampNetwork';

import { useOnrampAuthedGet } from './useOnrampAuthedGet';
import { useOnrampGeoLocation } from './useOnrampGeoLocation';

const STALE_TIME_IN_MS = 30 * 1000;

export type OnrampNetworksResponse = {
  networks: OnrampNetwork[];
};

type UseOnrampNetworksProps = {
  fiatCode?: string;
  countryCode?: string;
  subdivisionCode?: string;
  enabled?: boolean;
  endpoint?: 'supportedNetworks' | 'mgxNetworks';
  walletGroupId: string | undefined;
  isConnected?: boolean | null;
};

export function useOnrampNetworks({
  fiatCode,
  countryCode,
  subdivisionCode,
  walletGroupId,
  enabled = true,
  endpoint = 'supportedNetworks',
  isConnected,
}: UseOnrampNetworksProps) {
  const getSupportedNetworks = useOnrampAuthedGet<OnrampNetworksResponse>(endpoint);
  const isUTXOSupported = useWalletGroupSupportsUTXO(walletGroupId);
  const account = useAccountByWalletGroupId(walletGroupId);

  const { data, isLoading, isInitialLoading } = useQuery({
    queryKey: getQueryKey({
      endpoint,
      fiatCode,
      countryCode,
      subdivisionCode,
      enabled,
      isConnected,
    }),
    queryFn: async () =>
      getSupportedNetworks({
        countryCode,
        subdivisionCode,
        fiatCode,
      }),
    enabled,
    staleTime: STALE_TIME_IN_MS,
    notifyOnChangeProps: ['data'],
    placeholderData: { networks: [] },
    select: function selectData(response) {
      return selectOnrampNetworksData({ response, enabled, isUTXOSupported, account });
    },
  });

  return useMemo(
    () => ({ data: data ?? [], isLoading, isInitialLoading }),
    [data, isInitialLoading, isLoading],
  );
}

export function useOnrampMgxNetworks(props: UseOnrampNetworksProps) {
  return useOnrampNetworks({
    ...props,
    endpoint: 'mgxNetworks',
  });
}

type Params = {
  fiatCode: string;
  isConnected?: boolean | null;
};

export function useQueryOnrampNetworks({
  walletGroupId,
  endpoint = 'supportedNetworks',
}: UseOnrampNetworksProps) {
  const queryClient = useQueryClient();
  const { countryCode, subdivisionCode } = useOnrampGeoLocation();
  const isUTXOSupported = useWalletGroupSupportsUTXO(walletGroupId);
  const account = useAccountByWalletGroupId(walletGroupId);
  const getSupportedNetworks = useOnrampAuthedGet<OnrampNetworksResponse>(endpoint);

  return useCallback(
    async ({ fiatCode, isConnected }: Params) => {
      // always use the authed config when isConnected is explicitly set to true
      // this could happen when isConnected is determined by async action (function call) instead of sync action (hook)
      const options = isConnected
        ? { ...onrampServiceAuthedConfig, withRetailToken: true }
        : undefined;

      const response = await queryClient.fetchQuery({
        queryKey: getQueryKey({
          endpoint,
          fiatCode,
          countryCode,
          subdivisionCode,
          enabled: true,
          isConnected,
        }),
        queryFn: async function queryOnrampPaymentMethods() {
          return getSupportedNetworks(
            {
              countryCode,
              subdivisionCode,
              fiatCode,
            },
            options,
          );
        },
        staleTime: STALE_TIME_IN_MS,
      });

      return selectOnrampNetworksData({ response, enabled: true, isUTXOSupported, account });
    },
    [
      account,
      countryCode,
      endpoint,
      getSupportedNetworks,
      isUTXOSupported,
      queryClient,
      subdivisionCode,
    ],
  );
}

export function getQueryKey({
  endpoint,
  fiatCode,
  countryCode,
  subdivisionCode,
  enabled,
  isConnected,
}: Pick<
  UseOnrampNetworksProps,
  'endpoint' | 'fiatCode' | 'countryCode' | 'subdivisionCode' | 'enabled' | 'isConnected'
>) {
  return [
    `payment-providers/v1/${endpoint}`,
    countryCode,
    subdivisionCode,
    fiatCode,
    enabled,
    isConnected,
  ];
}

export function selectOnrampNetworksData({
  enabled,
  account,
  response,
  isUTXOSupported,
}: {
  enabled: boolean;
  response: OnrampNetworksResponse | undefined;
  account: ReturnType<typeof useAccountByWalletGroupId>;
  isUTXOSupported: boolean;
}) {
  if (!enabled || !response?.networks) {
    return [];
  }

  return response.networks.filter(
    (network) =>
      (network.chainId !== 0 || isUTXOSupported) &&
      (!account?.isSCWAccount || isSCWSupportedChainId(network.chainId)),
  );
}

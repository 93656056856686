import { defineMessages, useIntl } from 'react-intl';
import noop from 'lodash/noop';
import { AmountInputLoadingFallback } from 'wallet-cds-web/components/AmountInput/AmountInputLoadingFallback';
import { AssetSelectorGroupLoadingFallback } from 'wallet-cds-web/components/AssetSelectorGroup/AssetSelectorGroupLoadingFallback';
import { Button } from 'wallet-cds-web/components/Button';
import { VStack } from '@cbhq/cds-web/layout';

import { SwapSidebarContentWrapper } from '../SwapSidebarContentWrapper';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Swap',
    description: 'Title for trade crypto entry screen',
  },
  preview: {
    defaultMessage: 'Find best price',
    description: 'CTA to navigate to the confirmation screen',
  },
});

export function EntryStepFallback() {
  const { formatMessage } = useIntl();

  return (
    <SwapSidebarContentWrapper>
      <VStack
        testID="entry-step-fallback"
        height={525}
        width="100%"
        gap={2}
        spacing={3}
        justifyContent="space-between"
      >
        <VStack gap={2}>
          <AmountInputLoadingFallback />
          <AssetSelectorGroupLoadingFallback />
        </VStack>
        <Button testID="preview-button" onPress={noop} disabled endIcon="forwardArrow">
          {formatMessage(messages.preview)}
        </Button>
      </VStack>
    </SwapSidebarContentWrapper>
  );
}

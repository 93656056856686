// eslint-disable-next-line no-restricted-imports
import { useOverridableKillSwitchForPlatform } from 'cb-wallet-data/hooks/KillSwitches/useOverridableKillSwitchForPlatform';

/**
 * useIsFeatureEnabledForKillSwitchName
 *
 * When it is a kill_ kill switch, return the inverse of if it is enabled.
 * When it is an enable_ kill switch, return if it is enabled.
 */
export function useIsFeatureEnabledForKillSwitchName(killSwitchName?: string): boolean {
  // the value of the kill/enable switch
  const value: boolean = useOverridableKillSwitchForPlatform(killSwitchName ?? null);

  // if it starts with "enable_", it is an "enable_" kill switch
  const isEnableSwitch: boolean = killSwitchName?.startsWith('enable_') ?? false;

  // if it's an enable switch, return the value, else return the inverse of the value
  //
  // scenario 1: undefined is false, so return true (inverse)
  // scenario 2: enable_export_private_key is true, so return true
  // scenario 3: kill_export_private_key is true, so return false (inverse)
  return isEnableSwitch ? value : !value;
}

import { EthereumAddressType } from 'cb-wallet-data/chains/AccountBased/Ethereum/config';
import { ETHEREUM_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { DataMigration, DataMigrationParams } from 'cb-wallet-data/DataMigrationRunner/types';
import { getAccounts, saveAccount } from 'cb-wallet-data/stores/Accounts/database';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { AccountType } from 'cb-wallet-data/stores/Accounts/models/AccountTypes';
import { findPrimaryWallet } from 'cb-wallet-data/stores/Accounts/utils/findPrimaryWallet';
import { getAddressForIndex } from 'cb-wallet-data/stores/Addresses/database';
import { getWalletGroups, saveWalletGroup } from 'cb-wallet-data/stores/WalletGroups/database';
import { WalletGroup } from 'cb-wallet-data/stores/WalletGroups/models/WalletGroup';
import { getWallets } from 'cb-wallet-data/stores/Wallets/database';

// createAccount and createWalletGroup do not need to update Recoil, because
// hydration runs after data migrations.
async function createAccount(type: AccountType, primaryAddress: string): Promise<Account> {
  const account = new Account({
    type,
    primaryAddressChain: ETHEREUM_SYMBOL,
    primaryAddress,
  });
  await saveAccount(account);
  return account;
}

async function createWalletGroup(
  account: Account,
  walletIndex: bigint,
  hardwareDerivationPath?: string,
): Promise<WalletGroup> {
  const walletGroup = new WalletGroup({
    accountId: account.id,
    walletIndex,
    hardwareDerivationPath,
  });
  await saveWalletGroup(walletGroup);
  return walletGroup;
}

/**
 * NOTE DO NOT RELEASE UNTIL...
 * This must be released only after Multi-Account (MA) is able to create
 * accounts for all types (Mnemonic, Ledger, and WalletLink). Else, users may run
 * this data migration already and then get stuck without an account record.
 *
 * This data migration will check if the user needs to create an account + wallet group(s).
 * as those are mandatory to enabling the app working with multi-account.
 *
 * If needed, it will create the first account and up to 2 wallet groups, the one
 * at index 0 and then one at the currently active index.
 *
 * During scanning or migration, other wallet groups will get created if needed.
 */
const migrateForMultiAccount: DataMigration = {
  // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
  // eslint-disable-next-line wallet/no-anonymous-params
  up: async (params: DataMigrationParams) => {
    // eslint-disable-next-line no-console
    console.log('Migration: migrateLedgerForMultiAccount');

    const { accountType, activeEthWalletIndex } = params;

    /**
     * 1. Determine if there are accounts and wallet groups already. This should
     * be created when the user goes through MA onboarding.
     */
    const accounts = await getAccounts();
    const walletGroups = await getWalletGroups();
    if (accounts.length && walletGroups.length) {
      return;
    }

    /**
     * 2. Determine if there are wallets. If there are no wallets, it can be
     * assumed the user is not signed in or onboarded.
     */
    const wallets = await getWallets();
    if (!wallets.length) {
      return;
    }

    /**
     * NOTE The wallet.selectedIndex corresponds to the primary address used
     * for mnemonic (eth 0th index), Ledger (single address at 0th index), and
     * WalletLink (single address at 0th index). Refer to {@link ImportableAddress}.
     */
    const primaryWallet = findPrimaryWallet(wallets);

    if (!primaryWallet) {
      throw new Error('no Wallet found with selectedIndex of 0');
    }

    const {
      blockchain,
      currencyCode,
      network,
      primaryAddress,
      selectedIndex: walletIndex = 0n,
    } = primaryWallet;

    // Even if there is already an account, create one anyways, as this should
    // be idempotent with the exception of the createdAt field
    const account = await createAccount(accountType, primaryAddress);
    let hardwareDerivationPath;

    /**
     * 3. If the user is logged in with Ledger, find the hardwareDerivationPath.
     *
     * Modified: added accountId in Address getter functions, modified this script for that
     */
    if (account.isLedgerAccount) {
      const address = await getAddressForIndex({
        blockchain,
        currencyCode,
        network,
        addressType: EthereumAddressType,
        isChangeAddress: false,
        index: walletIndex,
        accountId: primaryWallet.accountId,
      });

      if (!address) {
        throw new Error(`no Address found for Wallet id ${primaryWallet.id}`);
      }

      hardwareDerivationPath = address.derivationPath;
    }

    /**
     * 4. Create the first wallet group for all account types.
     */
    await createWalletGroup(account, walletIndex, hardwareDerivationPath);

    /**
     * 5. For mnemonic accounts, which could be index-switchable, also find the
     * active index and create a wallet group there so the user doesn't discover
     * their active wallet group to be missing.
     *
     * Why not leave it for balance scanning to create it? Because it may have a
     * zero balance, which will not result in a wallet group creation.
     */
    if (account.isMnemonicAccount && activeEthWalletIndex !== 0n) {
      await createWalletGroup(account, activeEthWalletIndex);
    }
  },
};

export default migrateForMultiAccount;

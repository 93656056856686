import { useMemo } from 'react';

import { BasicProfile, useBasicPublicProfile } from './useBasicPublicProfiles';

/**
 * Given an array of addresses, get the basic public profiles for them and return them in the same
 * sorting order as the addresses.  For any missing profile, return the address
 */
export function useBasicPublicProfileForAddresses(
  addresses: string[],
): Record<string, BasicProfile> {
  const basicPublicProfilesRecord = useBasicPublicProfile({ addresses });
  return useMemo(
    function mapAddressesToBasicProfileInOrder() {
      return addresses.reduce(function mapAddressesToPublicProfile(acc, address) {
        acc[address] = basicPublicProfilesRecord?.[address] ?? { address, name: '', avatar: '' };
        return acc;
      }, {} as Record<string, BasicProfile>);
    },
    [addresses, basicPublicProfilesRecord],
  );
}

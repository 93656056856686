import { PossibleUTXOBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
import {
  BITCOIN_SYMBOL,
  BitcoinBRDLegacyAddressType,
  BitcoinBRDSegwitAddressType,
} from 'cb-wallet-data/chains/UTXO/Bitcoin/config';
import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { AddressType } from 'cb-wallet-data/stores/Addresses/models/AddressType';
import { isE2eTest } from 'cb-wallet-env/env';
import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { BTCLikeService } from 'wallet-engine-signing/signing/services/BTCLikeService';

import { xpubKeyDerivationPath as xpubKeyDerivationPathBTC } from '../Bitcoin/Wallet/xpubKeyDerivationPath';
import { DOGECOIN_SYMBOL } from '../Dogecoin/config';
import { xpubKeyDerivationPath as xpubKeyDerivationPathDOGE } from '../Dogecoin/Wallet/xpubKeyDerivationPath';
import { LITECOIN_SYMBOL, LitecoinBRDAddressType } from '../Litecoin/config';
import { xpubKeyDerivationPath as xpubKeyDerivationPathLTC } from '../Litecoin/Wallet/xpubKeyDerivationPath';

export async function getServices(): Promise<Record<PossibleUTXOBlockchainSymbol, BTCLikeService>> {
  const { bitcoinService, dogecoinService, litecoinService } = await import(
    'cb-wallet-data/WalletEngine/WalletEngine'
  );

  return {
    [BITCOIN_SYMBOL]: bitcoinService,
    [DOGECOIN_SYMBOL]: dogecoinService,
    [LITECOIN_SYMBOL]: litecoinService,
  };
}

type StoreKeys_xpubKeyProps = {
  blockchain: Blockchain;
  currencyCode: CurrencyCode;
  addressType: AddressType;
  accountId: Account['id'];
  walletIndex: bigint;
  isTestnet: boolean;
};

/**
 * BIP44 extended public key.
 *
 * Note: If you're retrieving an xpub key from local storage, do not use this
 * function to generate the store key; instead use the function getXpubKeyFromLocalStorage.
 */
export function StoreKeys_xpubKey({
  blockchain,
  currencyCode,
  addressType,
  accountId,
  walletIndex, // TODO: Always 0, until UTXO multi-wallet support is added.
  isTestnet,
}: StoreKeys_xpubKeyProps): LocalStorageStoreKey<string> {
  const uuid = [
    blockchain.rawValue,
    currencyCode.rawValue,
    addressType.rawValue,
    accountId,
    walletIndex,
    JSON.stringify(isTestnet),
  ]
    .join(',')
    .toUpperCase();

  // TODO - this should be stored in secure storage on native
  return new LocalStorageStoreKey('BIP44XpubKey', uuid);
}

type StoreKeys_legacyXpubKeyProps = {
  blockchain: Blockchain;
  currencyCode: CurrencyCode;
  addressType: AddressType;
  isTestnet: boolean;
};

/**
 * BIP44 extended public key, legacy format used as fallback and for migrating.
 *
 * @deprecated Use StoreKeys_xpubKey instead.
 */
export function StoreKeys_legacyXpubKey({
  blockchain,
  currencyCode,
  addressType,
  isTestnet,
}: StoreKeys_legacyXpubKeyProps): LocalStorageStoreKey<string> {
  const uuid = [
    blockchain.rawValue,
    currencyCode.rawValue,
    addressType.rawValue,
    JSON.stringify(isTestnet),
  ]
    .join(',')
    .toUpperCase();

  return new LocalStorageStoreKey('BIP44XpubKey', uuid);
}

type AddressDerivationPathForBlockchain = (
  addressType: AddressType,
  testnet: boolean,
) => string | undefined;

export const xpubKeyDerivationPathForBlockchain: Record<
  PossibleUTXOBlockchainSymbol,
  AddressDerivationPathForBlockchain
> = {
  [BITCOIN_SYMBOL]: xpubKeyDerivationPathBTC,
  [DOGECOIN_SYMBOL]: xpubKeyDerivationPathDOGE,
  [LITECOIN_SYMBOL]: xpubKeyDerivationPathLTC,
};

/**
 * MAX_UNUSED: How many unused addresses to scan before deciding that there aren't likely
 * any more used addresses on this HD path. Note that for performance tests we
 * keep this value the same but we decrease for e2e tests to increase speed.
 *
 * MIN_UNUSED: After an initial scan of MAX_UNUSED has been done, we'll do the same scanning
 * process but use MIN_UNUSED if no activity has been detected on an address type, to save
 * requests to our third party indexing service.
 *
 * numUTXOsPerRequest: How many addresses to request from the indexing service at a time.
 */
export const MAX_UNUSED = isE2eTest ? 2 : 20;
export const MIN_UNUSED = 1;

export const numUTXOsPerRequest = 20;

/**
 * UTXO Address types only used by BRD Wallets
 */
export const BRDAddressTypes = [
  BitcoinBRDSegwitAddressType.rawValue,
  BitcoinBRDLegacyAddressType.rawValue,
  LitecoinBRDAddressType.rawValue,
];

import {
  StoreKeys_accessToken,
  StoreKeys_refreshToken,
} from 'cb-wallet-http/Authentication/tokens/state';
import { Store } from 'cb-wallet-store/Store';

export function hasStoredAuthTokens() {
  const authToken = Store.get<string>(StoreKeys_accessToken);
  const refreshToken = Store.get<string>(StoreKeys_refreshToken);

  return !!(authToken && refreshToken);
}

import { useCallback } from 'react';
import { EthereumChain } from 'cb-wallet-data/chains/AccountBased/Ethereum/EthereumChain';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { useKillSwitchByEthNetwork } from 'cb-wallet-data/hooks/KillSwitches/useKillSwitchByEthNetwork';
import { TxHistoryVersion } from 'cb-wallet-data/stores/Transactions/TxHistoryVersion';

/**
 * Returns the active tx history version for the given ETH chain based on the killswitches and experiements
 * that are active. The hook returns a function which accepts a chain, this was done since we need to
 * be able to determine the active tx history version for a chain inside a loop
 */
export function useGetEthTxHistoryVersion() {
  const isTxHistoryServiceKilledForEVMChain = useKillSwitchByEthNetwork('kill_txhistory_service');
  const isTxHistoryServiceV3KilledForEVMChain = useKillSwitchByEthNetwork(
    'kill_txhistory_service_v3',
  );
  const isHRTEnabled = useIsFeatureEnabled('human_readable_transactions');

  return useCallback(
    function getEthTxHistoryVersion(chain?: EthereumChain): {
      isEnabled: boolean;
      apiVersion: TxHistoryVersion | undefined;
    } {
      let isEnabled = false;
      let apiVersion: TxHistoryVersion | undefined;

      if (chain && Boolean(chain.wacNetworkId)) {
        const isTxHistoryV2Enabled = !isTxHistoryServiceKilledForEVMChain(BigInt(chain.chainId));
        const isTxHistoryV3Enabled = !isTxHistoryServiceV3KilledForEVMChain(BigInt(chain.chainId));

        switch (true) {
          case isHRTEnabled && isTxHistoryV3Enabled:
            isEnabled = true;
            apiVersion = 'v3';
            break;
          case isTxHistoryV2Enabled:
            isEnabled = true;
            apiVersion = 'v2';
            break;
          default:
          // Fall through to return default values for isEnabled and apiVersion
        }
      }

      return {
        isEnabled,
        apiVersion,
      };
    },
    [isTxHistoryServiceKilledForEVMChain, isTxHistoryServiceV3KilledForEVMChain, isHRTEnabled],
  );
}

import { logMissingTransactionsForAddressType } from 'cb-wallet-analytics/transactions/Transactions';
import { PossibleUTXOBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
import { Address } from 'cb-wallet-data/stores/Addresses/models/Address';
import { GetUTXOWalletTxsResponse } from 'cb-wallet-data/stores/Transactions/api';

type CheckForMissingTransactionsParams = {
  usedAddresses: Address[];
  transactions: GetUTXOWalletTxsResponse[];
  blockchainSymbol: PossibleUTXOBlockchainSymbol;
};

// Every used address should have at least 1 transaction associated with it. This function
// ensures that the addresses sent to Blockchains Proxy correspond to at least 1 transaction.
export function checkForMissingTransactions({
  usedAddresses,
  transactions,
  blockchainSymbol,
}: CheckForMissingTransactionsParams) {
  const uniqueAddresses = new Map(usedAddresses.map((address) => [address.address, address]));

  const transactionsByAddress = new Set(
    transactions.flatMap(function flattenAddresses(transaction) {
      const { inputs = [], outputs = [] } = transaction;
      const inputAddresses = inputs.map((input) => input.address);
      const outputAddresses = outputs.map((output) => output.address);
      return inputAddresses.concat(outputAddresses);
    }),
  );

  const addressesWithoutTransactions = Array.from(uniqueAddresses, ([, address]) => address).filter(
    (address) => !transactionsByAddress.has(address.address),
  );

  if (addressesWithoutTransactions.length > 0) {
    // error here
    const addressTypesWithoutTransactions = new Set(
      addressesWithoutTransactions.map((address) => address.type),
    );

    Array.from(addressTypesWithoutTransactions).forEach((addressType) => {
      logMissingTransactionsForAddressType({
        blockchain: blockchainSymbol,
        addressType,
      });
    });
  }
}

import { CreateConnectorFn } from '@wagmi/core';
import { getConfig, initConfig } from 'cb-wallet-data/scw/libs/wagmi/config';

initConfig({ multiInjectedProviderDiscovery: true });

/**
 * Use internal wagmi function to setup the connector from the CreateConnectorFn
 */
export function setupConnector(connectorFn: CreateConnectorFn) {
  return getConfig()._internal.connectors.setup(connectorFn);
}

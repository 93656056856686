import camelCase from 'lodash/camelCase';
import isPlain from 'lodash/isPlainObject';
import snakeCase from 'lodash/snakeCase';

function isPlainObject<T>(input: T): boolean {
  return isPlain(input);
}

function deeplyTransformObjectKeys<T>(input: T, transform: (key: string) => string): T {
  if (Array.isArray(input)) {
    return input.map((item) => deeplyTransformObjectKeys(item, transform)) as unknown as T;
  }

  if (isPlainObject(input)) {
    return Object.entries(input as Partial<T>).reduce(
      (output, [key, value]) => ({
        ...output,
        [transform(key)]: deeplyTransformObjectKeys(value, transform),
      }),
      {} as Partial<T>,
    ) as T;
  }

  return input;
}

export function convertKeysToCamelCase<T>(input: T): T {
  return input ? deeplyTransformObjectKeys<T>(input, camelCase) : input;
}

export function convertKeysToSnakeCase<T>(input: T): T {
  return input ? deeplyTransformObjectKeys<T>(input, snakeCase) : input;
}

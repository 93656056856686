import { useMemo } from 'react';
import { HStack } from '@cbhq/cds-web/layout';

import { AppDisplayMode, getAppDisplayMode } from ':dapp/utils/appModeType';

import { useBottomNavbarConfig } from './hooks/useBottomNavbarConfig';
import { BottomNavbarItem } from './BottomNavbarItem';

export const BOTTOM_NAVBAR_HEIGHT = 57;

export function BottomNavbar() {
  const bottomNavbarConfig = useBottomNavbarConfig();
  const isDisplayModeStandalone = useMemo(() => {
    return getAppDisplayMode() === AppDisplayMode.STANDALONE;
  }, []);
  return (
    <HStack
      borderedTop
      bottom={0}
      justifyContent="space-around"
      left={0}
      spacingBottom={isDisplayModeStandalone ? 2 : 0}
      background
      position="fixed"
      spacingVertical={2}
      zIndex={2}
      testID="bottom-navbar"
      width="100%"
      spacingHorizontal={5}
    >
      {bottomNavbarConfig.map((item) => (
        <BottomNavbarItem
          key={item.title}
          route={item.route}
          iconName={item.icon}
          title={item.title}
          onClick={item.triggerAnalyticsEventCallback}
        />
      ))}
    </HStack>
  );
}

import { IconButton } from '@cbhq/cds-web/buttons';
import { Box } from '@cbhq/cds-web/layout';
type SideNavbarToggleProps = {
  isToggled: boolean;
  onToggle: () => void;
};
const sideNavbarToggleStyles = "s7eiop1";
export function SideNavbarToggle({
  isToggled,
  onToggle
}: SideNavbarToggleProps) {
  return <div className={sideNavbarToggleStyles}>
      <Box width="100%" justifyContent={isToggled ? 'flex-start' : 'flex-end'} spacingStart={isToggled ? 0.5 : 0}>
        {isToggled ? <IconButton name="caretRight" onPress={onToggle} variant="foregroundMuted" transparent testID="caret-right-icon" flush="start" /> : <IconButton name="caretLeft" onPress={onToggle} variant="foregroundMuted" transparent testID="caret-left-icon" flush="start" />}
      </Box>
    </div>;
}

require("./SideNavbarToggle.linaria.module.css!=!../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./SideNavbarToggle.tsx");
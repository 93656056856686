import { memo, useEffect, useMemo, useState } from 'react';
import { NUMBER_OF_DEFAULT_AVATARS } from 'cb-wallet-data/stores/WalletGroups/utils/getDefaultAvatarIdxByAddress';
import { AvatarShape, AvatarSize, PaletteBorder } from '@cbhq/cds-web';
import { Avatar } from '@cbhq/cds-web/media';

const ANONYMOUS_PROFILE = Array(NUMBER_OF_DEFAULT_AVATARS)
  .fill(0)
  .map((_, index) => `Frame${index ? `-${index}` : ''}`);

// TODO: Move hooks file to shared location
// Note: Adapted from `workspaces/libs/wallet-cds-extension/hooks/useProfileDefaultAvatar.ts`
// https://jira.coinbase-corp.com/browse/WALL-26669
export function useProfileDefaultAvatar(idx?: number) {
  const [defaultProfileAvatar, setDefaultProfileAvatar] = useState<string | undefined>(undefined);
  const imageName = useMemo(
    () => (typeof idx !== 'undefined' ? `${ANONYMOUS_PROFILE[idx]}` : undefined),
    [idx],
  );

  useEffect(
    function fetchAvatar() {
      let isCancelled = false;
      const fetchImage = async (file: string) => {
        // eslint-disable-next-line import/no-dynamic-require, global-require
        const response = require(`./assets/${file}.svg`);
        if (!isCancelled) {
          setDefaultProfileAvatar(response);
        }
      };

      if (imageName) {
        fetchImage(imageName);
      }

      return () => {
        isCancelled = true;
      };
    },
    [imageName],
  );
  return defaultProfileAvatar;
}

type ProfileAvatarProps = {
  idx?: number;
  testID?: string;
  shape?: AvatarShape;
  size?: AvatarSize;
  src?: string;
  borderColor?: PaletteBorder;
  alt: string;
};

/**
 * ProfileAvatar component to display default avatar or address avatar
 *
 * Built on top of CDS `<Avatar />` component
 *
 * See: https://cds.cbhq.net/components/avatar
 *
 * TODO: Update to use Pfp
 * https://jira.coinbase-corp.com/browse/WALL-26669
 *
 * See: `lib/wallet-cds-extension/components/UserAvatar/ProfileAvatar.tsx`
 *
 */
export const DefaultAvatar = memo(function ProfileAvatar({
  testID,
  shape = 'circle',
  size = 'l',
  src,
  alt,
  idx,
  borderColor,
}: ProfileAvatarProps) {
  const defaultProfileAvatar = useProfileDefaultAvatar(idx);

  return (
    <Avatar
      borderColor={borderColor}
      testID={['profile-avatar', testID].filter(Boolean).join('-')}
      alt={alt}
      size={size === 's' ? undefined : size}
      dangerouslySetSize={size === 's' ? 16 : undefined}
      src={defaultProfileAvatar ?? src}
      shape={shape}
    />
  );
});

import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { getAllCuratedAssetSettings } from '../database';
import { curatedAssetsSettingsAtom, getMapFromArrayById } from '../state';

/**
 * @returns A function that can be invoked in order to load Curated Asset Settings from
 * IndexedDB/Sqlite into application state.
 */

export function useHydrateCuratedAssetSettingsFromDatabase(): () => Promise<void> {
  const setCuratedAssetSettings = useSetRecoilState(curatedAssetsSettingsAtom);
  return useCallback(
    async function hydrateCuratedAssetSettingsFromDatabase() {
      return getAllCuratedAssetSettings().then(function setCuratedSettingsAtom(settings) {
        const curatedAssetSettingsMap = getMapFromArrayById(settings);
        setCuratedAssetSettings(curatedAssetSettingsMap);
      });
    },
    [setCuratedAssetSettings],
  );
}

import {
  ETHEREUM_CHAIN_ID,
  ETHEREUM_SYMBOL,
} from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { queryClient } from 'cb-wallet-data/QueryProvider';
import { getJSON } from 'cb-wallet-http/fetchJSON';

import { useIsDelegatorStakingEnabled } from '../hooks/useIsDelegatorStakingEnabled';

const EARN_KILN_BALANCE_QUERY_KEY = 'earn/ethereum_kiln/balance';

export type Claim = {
  amount: string;
  status: boolean;
};

export type Balance = {
  amount: string;
  claims: Claim[];
};

async function getKilnEarnBalance(userAddress: string) {
  const { result } = await getJSON<{ result: Balance }>(
    EARN_KILN_BALANCE_QUERY_KEY,
    { userAddress },
    {
      apiVersion: 2,
    },
  );
  return result;
}

type Params = {
  enabled?: boolean;
  address: string;
  currencyCode: string;
  chainId: number;
};

export function useKilnEarnBalance({ enabled = true, address, currencyCode, chainId }: Params): {
  enabled?: boolean;
  balance?: Balance;
  isLoading: boolean;
} {
  const isDelegatorStakingEnabled = useIsDelegatorStakingEnabled();

  const { data, isInitialLoading } = useQuery(
    [EARN_KILN_BALANCE_QUERY_KEY, address, currencyCode, chainId],
    async () => getKilnEarnBalance(address),
    {
      staleTime: 60 * 1000 * 2, // 2 minutes query is invalidated on stake / unstake / claim
      enabled:
        enabled &&
        currencyCode === ETHEREUM_SYMBOL &&
        chainId === Number(ETHEREUM_CHAIN_ID) &&
        !!address &&
        isDelegatorStakingEnabled,
    },
  );

  return {
    balance: data,
    isLoading: isInitialLoading,
  };
}

export function resetKilnEarnBalanceQuery({ address, currencyCode, chainId }: Params) {
  if (currencyCode === ETHEREUM_SYMBOL && chainId === Number(ETHEREUM_CHAIN_ID)) {
    queryClient.removeQueries([EARN_KILN_BALANCE_QUERY_KEY, address, currencyCode, chainId]);
  }
}

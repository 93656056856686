import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { Persistable } from 'cb-wallet-data/persistence/Database.interface';
import { AssetManagementStatus } from 'cb-wallet-data/stores/AssetManagement/models/AssetManagementStatus';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { Column, Entity, Index, PrimaryColumn } from '@cbhq/typeorm';

/**
 * Used to represent manual filters, created by the user. This table stores all
 * filters for Wallets
 */
@Entity('user_wallet_setting')
@Index('IDX_PRIMARY_CURRENCY_CONTRACT_BLOCKCHAIN_NETWORK', [
  'primaryAddress',
  'currencyCodeStr',
  'contractAddress',
  'blockchainStr',
  'networkStr',
])
export class UserWalletSettingDMO {
  @PrimaryColumn()
  id!: string;

  @Column()
  blockchainStr!: string;

  @Column()
  currencyCodeStr!: string;

  @Column()
  networkStr!: string;

  @Column({ type: 'int' })
  status!: AssetManagementStatus;

  @Column({ nullable: true })
  contractAddress?: string;

  // ETH address for which this filter is applied. You can have different
  // filters for Wallet1, Wallet2, another set of filters for HW wallet etc.
  @Column()
  primaryAddress!: string;
}

export class UserWalletSetting implements Persistable<UserWalletSettingDMO> {
  readonly id: string;
  constructor(
    readonly blockchain: Blockchain,
    readonly currencyCode: CurrencyCode,
    readonly network: Network,
    readonly status: AssetManagementStatus,
    readonly contractAddress: string | undefined,
    readonly primaryAddress: string,
  ) {
    this.id = UserWalletSetting.generateID(
      primaryAddress,
      blockchain,
      currencyCode,
      network,
      contractAddress,
    );
  }

  static fromDMO(dmo: UserWalletSettingDMO): UserWalletSetting {
    return new UserWalletSetting(
      new Blockchain(dmo.blockchainStr),
      new CurrencyCode(dmo.currencyCodeStr),
      Network.create(dmo.networkStr)!,
      dmo.status,
      dmo.contractAddress,
      dmo.primaryAddress,
    );
  }

  static generateID(
    primaryAddress: string,
    blockchain: Blockchain,
    currencyCode: CurrencyCode,
    network: Network,
    contractAddress: string | undefined,
  ): string {
    return [
      primaryAddress,
      contractAddress,
      blockchain.rawValue,
      network.rawValue,
      currencyCode.rawValue,
    ].join('/');
  }

  get asDMO(): UserWalletSettingDMO {
    return {
      id: this.id,
      blockchainStr: this.blockchain.rawValue,
      currencyCodeStr: this.currencyCode.rawValue,
      networkStr: this.network.rawValue,
      status: this.status,
      contractAddress: this.contractAddress,
      primaryAddress: this.primaryAddress,
    };
  }
}

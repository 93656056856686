import { ActionType, AnalyticsEventImportance, ComponentType } from '@cbhq/client-analytics';

import { logDataEvent, logEvent, useLogEventOnMount } from '../utils/log';
import { AccountTypeEventPropertyValue, EventProperties } from '../utils/types/eventProperties';

type NetworkCurrency = {
  name: string;
  symbol: string;
  decimals: number;
};

export type NetworkCustomization = {
  id: string;
  chainName: string;
  rpcUrls: string[];
  chainId: string;
  nativeCurrency: NetworkCurrency;
  blockExplorerUrls: string[];
  iconUrls: string[];
};

const settingToLogConfig = Object.freeze({
  appearance: { logName: 'appearance', logId: '00dc2a53-8137-4a06-8a33-3f8f2efb1f59' },
  appLockTimer: { logName: 'app_lock_timer', logId: '6f82bee3-124a-4d06-a375-a1e0af72eb74' },
  autoLockTimer: { logName: 'app_lock_timer', logId: '6f82bee3-124a-4d06-a375-a1e0af72eb74' },
  themes: { logName: 'themes', logId: 'c676ca1e-8132-4413-989c-baf5065d6163' },
  changePassword: { logName: 'change_password', logId: '7cd0fc54-efcb-4de6-bfb9-094ae0570b1f' },
  assetManagement: { logName: 'asset_management', logId: 'b59babc6-64d7-42e5-ad20-abd685ecfc35' },
  localCurrency: { logName: 'local_currency', logId: 'b0f87bb9-2f92-43dd-8549-67c5d8c4d6e0' },
  language: { logName: 'language', logId: 'a2d2591b-86fb-4ee3-8003-30bc4bc6d841' },
  connections: { logName: 'connections', logId: '603d73e6-6c83-4837-9ef5-3e274619bc27' },
  walletGroups: { logName: 'wallet_groups', logId: 'd02be462-8bbd-41bd-a89b-1972dbe3313a' },
  networks: { logName: 'networks', logId: '847676b6-493a-4087-8392-237ba8d012ef' },
  showRecoveryPhrase: {
    logName: 'show_recovery_phrase',
    logId: 'f1b428ae-cc0d-4495-8ea0-fd80daa43f84',
  },
  notifications: { logName: 'notifications', logId: '8c4c441b-b438-4493-bc3a-3330efcc711a' },
  connectToCoinbase: {
    logName: 'connect_to_coinbase',
    logId: '3e6a7652-666e-44d3-9a10-8cc899ace675',
  },
  tokenApprovals: { logName: 'token_approvals', logId: 'bc73a437-6bdf-4be5-ac53-0e2591174e47' },
  referralHub: { logName: 'referral_hub', logId: 'd4ef959a-ccee-4384-a90f-417591bf461c' },
  support: { logName: 'support', logId: '087c166e-d6f4-479b-a962-d4d5655fbf43' },
  termsOfService: { logName: 'terms_of_service', logId: '814870d0-cd51-4f52-a3d6-d987e0df8af3' },
  privacyPolicy: { logName: 'privacy_policy', logId: '39b7ace9-c0f2-42b5-8bea-d61130699ab6' },
  hideBalances: { logName: 'hide_balances', logId: 'dda9d3f0-ff9d-4d85-acb1-e2ed22e6622c' },
  publicKeyExport: { logName: 'public_key_export', logId: 'd212cd0f-fd4b-47a6-81b9-ae4780e4f640' },
});

export function useSettingsViewed() {
  return useLogEventOnMount('settings.viewed', {
    loggingId: '1ffe0986-0910-4ea4-9bb8-1324281832d9',
    componentType: ComponentType.page,
  });
}

// Used in dapp
export function logSettingsViewed() {
  return logEvent('settings.viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function logSettingsManageWalletsViewed() {
  return logEvent('settings.manage_wallets.viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function logSettingsDisplayViewed() {
  return logEvent('settings.display.viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export type SettingName = keyof typeof settingToLogConfig;

export function triggerSettingsButtonClick(setting: SettingName, isCBKECountry?: boolean) {
  if (!(setting in settingToLogConfig)) {
    return;
  }
  const { logName, logId } = settingToLogConfig[setting];
  logEvent(`settings.${logName}`, {
    loggingId: logId,
    action: ActionType.click,
    componentType: ComponentType.button,
    ...(isCBKECountry !== undefined ? { isCBKECountry } : undefined),
  });
}

export function useAdvancedSettingsViewed() {
  return useLogEventOnMount('advanced_settings.viewed', {
    loggingId: 'c1d9dfaf-0386-4458-8e9e-8f3221441b8f',
    componentType: ComponentType.page,
  });
}

export function useShowRecoveryPhraseViewed() {
  return useLogEventOnMount('show_recovery_phrase.viewed', {
    loggingId: '5923d4cf-f8e9-41bd-a3ae-e970ca76cad6',
    componentType: ComponentType.page,
  });
}

export function useDeveloperModeModalViewed() {
  return useLogEventOnMount('developer_mode.modal_view', {
    loggingId: 'dc05086c-bbff-11ec-8422-0242ac120002',
    componentType: ComponentType.modal,
  });
}

export function useAddCustomNetworkViewed() {
  return useLogEventOnMount('add_custom_network.viewed', {
    loggingId: 'dc050c04-bbff-11ec-8422-0242ac120002',
    componentType: ComponentType.modal,
  });
}

export function useImportCustomNetworksViewed() {
  return useLogEventOnMount('import_custom_networks.viewed', {
    loggingId: 'c33cb73d-46a9-4b4a-9795-a4672943bb9f',
    componentType: ComponentType.modal,
  });
}

export function triggerExportButtonClicked() {
  return logEvent('export_custom_networks.btn', {
    loggingId: '91c48049-8759-4830-b4cd-a75327e1a6ac',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function triggerAddCustomNetworkViewed() {
  return logEvent('add_custom_network.viewed', {
    loggingId: 'dc050c04-bbff-11ec-8422-0242ac120002',
    componentType: ComponentType.modal,
    action: ActionType.view,
  });
}

export function triggerSettingOptionClick(setting: string) {
  return logEvent('settings.nav_btn', {
    loggingId: 'e7c816c6-fe06-4fb3-a9c8-419651911f25',
    action: ActionType.click,
    navigateTo: setting,
    componentType: ComponentType.button,
  });
}

/* testing tings */
export function triggerSettingLockTimer() {
  logEvent('settings.app_lock_timer', {
    loggingId: 'lockTimerID',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}
/* end of testing tings */

export function triggerCustomNetworkSelected() {
  logEvent('choose_custom_network.network_changed', {
    loggingId: 'dc05213a-bbff-11ec-8422-0242ac120002',
    componentType: ComponentType.table,
    action: ActionType.click,
  });
}

export function triggerNetworkListItemClick({
  chainName,
  chainId,
  networkIsTestnet,
}: {
  chainName?: string;
  chainId?: string;
  networkIsTestnet?: boolean;
}) {
  logEvent('network_picker.network_item.clicked', {
    loggingId: 'e5d4c03f-2c60-4ffd-b949-aac503d29c4b',
    componentType: ComponentType.button,
    action: ActionType.click,
    chainName,
    chainId,
    networkIsTestnet,
  });
}

export function triggerAddCustomNetworkClick(context: 'tabsView-emptyState' | 'tabsView-list') {
  logEvent('network_picker.add_custom_network_btn', {
    loggingId: '7ec6d20e-31e7-42bd-9e4e-b77540767703',
    componentType: ComponentType.button,
    action: ActionType.click,
    context,
  });
}

export function triggerAddCustomNetworkSubmit(metadata: NetworkCustomization) {
  logEvent('add_custom_network.form_submit', {
    loggingId: '89fad40f-6c87-4b03-a793-4ffc3e0e18dc',
    componentType: ComponentType.button,
    action: ActionType.click,
    ...metadata,
  });
}

export function triggerAddCustomNetworkSubmitSuccess(metadata: NetworkCustomization) {
  logDataEvent('add_custom_network.submit_success', {
    loggingId: 'e2c6cdd6-ca05-4ec3-92da-bb36fb4537e8',
    ...metadata,
  });
}

export function triggerAddCustomNetworkSubmitError({
  metadata,
  errorMessage,
}: {
  metadata: NetworkCustomization;
  errorMessage: string;
}) {
  logDataEvent('add_custom_network.submit_fail', {
    loggingId: '8db3e7be-ddc6-4c3b-98d9-329947c3adac',
    ...metadata,
    errorMessage,
  });
}

export function triggerCustomTokenAdded({
  chainName,
  chainId,
  tokenSymbol,
  contractAddress,
  fromWatchAsset,
}: {
  chainName: string;
  chainId: number;
  tokenSymbol: string;
  contractAddress: string;
  fromWatchAsset: boolean;
}) {
  logEvent('settings.custom_token.added', {
    loggingId: '812572b4-158d-41bb-b666-9e9190284a5e',
    action: ActionType.unknown,
    componentType: ComponentType.unknown,
    chainName,
    chainId,
    tokenSymbol,
    contractAddress,
    fromWatchAsset,
  });
}

export function triggerInputErrors(metadata: { error: string; label?: string }) {
  return logEvent(
    'custom_form.errors',
    {
      loggingId: 'ffe0d5b8-bbff-11ec-8422-0242ac120002',
      action: ActionType.click,
      componentType: ComponentType.button,
      ...metadata,
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerTestnetToggle(isTestnetsEnabled: boolean, source = 'toggle') {
  logEvent('settings.testnets.toggle', {
    action: ActionType.click,
    componentType: ComponentType.button,
    isTestnetsEnabled,
    source,
  });
}

export function triggerDeveloperModeSwitchOff() {
  return logEvent(
    'settings.developer_mode.switch_off',
    {
      loggingId: 'b37a27ff-b8d0-4de9-929f-1aa6ade9662b',
      action: ActionType.click,
      componentType: ComponentType.button,
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerDeveloperModeSwitchOn() {
  return logEvent(
    'settings.developer_mode.switch_on',
    {
      loggingId: 'ffe0d9dc-bbff-11ec-8422-0242ac120002',
      action: ActionType.click,
      componentType: ComponentType.button,
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerDeveloperModeConfirm() {
  return logEvent(
    'developer_mode.confirm',
    {
      loggingId: '0f8c037e-bc01-11ec-8422-0242ac120002',
      action: ActionType.click,
      componentType: ComponentType.button,
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerDeveloperModeCancel() {
  return logEvent(
    'developer_mode.cancel',
    {
      loggingId: '0f8c0702-bc01-11ec-8422-0242ac120002',
      action: ActionType.click,
      componentType: ComponentType.button,
    },
    AnalyticsEventImportance.high,
  );
}

// DApp Settings
/** @warning Use 'useDappConnectionViewed' instead */
export function useDeprecatedDAppSettingsViewed() {
  useLogEventOnMount('extension.settings.dapp_settings.viewed', {
    loggingId: 'ddaca4f6-27c8-4b59-85f7-d0fa6e19e498',
    componentType: ComponentType.page,
  });
}

export function triggerDisconnectAllClicked() {
  logEvent('connections.disconnect_all', {
    loggingId: 'a1ba2212-3c18-444f-90ab-af0023a227a0',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

type DisconnectEventProps = {
  dappName?: string;
};

export function triggerDisconnectClicked(props: DisconnectEventProps) {
  logEvent('connections.disconnect', {
    loggingId: '6b24c54c-b3a9-469a-920a-161d3230ead1',
    action: ActionType.click,
    dapp: props.dappName ?? '',
    componentType: ComponentType.button,
  });
}

type UseDappConnectionViewedProps = { dappsListNum: number };

export function useDappConnectionViewed(props: UseDappConnectionViewedProps) {
  return useLogEventOnMount('connections.viewed', {
    loggingId: 'f9a3bb43-58df-4368-b8ee-7b022fc19d2a',
    componentType: ComponentType.page,
    ...props,
  });
}

type ActiveDappNetwork = {
  dappName?: string;
  dappUrl?: string;
};

export function triggerChangeDappNetwork(props: ActiveDappNetwork) {
  return logEvent('connections.change_network', {
    loggingId: 'd4a61a55-c224-49e2-af7b-45ae2cf3d931',
    action: ActionType.click,
    componentType: ComponentType.dropdown,
    ...props,
  });
}

export function triggerDisconnectDappNetwork(props: ActiveDappNetwork) {
  return logEvent('connections.disconnect_dapp', {
    loggingId: '40c0a657-14f8-45e4-88cd-af3a1d371bce',
    action: ActionType.click,
    componentType: ComponentType.dropdown,
    ...props,
  });
}

export function triggerThemeColorChange(
  oldColor: EventProperties['oldColor'],
  newColor: EventProperties['newColor'],
) {
  return logEvent('settings.themes.color_change', {
    loggingId: '40c0a657-14f8-45e4-88cd-af3a1d371bce',
    action: ActionType.click,
    componentType: ComponentType.dropdown,
    oldColor,
    newColor,
  });
}

// Settings redesign events

export type SettingCategoryContext =
  | 'wallets'
  | 'manage_all_wallets'
  | 'networks'
  | 'security'
  | 'display'
  | 'coinbase_connect'
  | 'notifications'
  | 'help'
  | 'legal'
  | 'referral'
  | 'version'
  | 'session'
  | 'tos'
  | 'referralProgram'
  | 'referralProgramInvite'
  | 'privacyPolicy';

type SettingsRedesignSharedEventProperties = {
  context: SettingCategoryContext;
  isBackupRequired?: boolean;
};

export function logSettingCategoryPressed(properties: SettingsRedesignSharedEventProperties) {
  return logEvent('settings.category.pressed', {
    loggingId: '775414b5-d0f1-4c73-a720-12a440360eda',
    action: ActionType.click,
    componentType: ComponentType.button,
    ...properties,
  });
}

export function logSettingBackButtonPressed(properties: SettingsRedesignSharedEventProperties) {
  return logEvent('settings.back_button.pressed', {
    loggingId: 'f6063832-51c0-4428-adaf-1cc62b46fb8a',
    action: ActionType.click,
    componentType: ComponentType.button,
    ...properties,
  });
}

type WalletSubCategory =
  | 'set_up_profile'
  | 'view_profile'
  | 'recovery_phrase'
  | 'connected_dapps'
  | 'export_public_addresses'
  | 'show_private_key'
  | 'hide_assets'
  | 'token_allowances'
  | 'theme_color'
  | 'hide_address_group'
  | 'primary_profile';

type WalletSubCategoryEventProperties = {
  context: WalletSubCategory;
  isBackupRequired?: boolean;
  accountType?: AccountTypeEventPropertyValue;
};

export function logSettingWalletSubCategoryPressed(properties: WalletSubCategoryEventProperties) {
  return logEvent('settings.wallet_sub_category.pressed', {
    loggingId: '775414b5-d0f1-4c73-a720-12a440360eda',
    action: ActionType.click,
    componentType: ComponentType.button,
    ...properties,
  });
}

export function logToggleAppMode(appMode: string) {
  return logEvent('settings.advanced_mode.toggled', {
    action: ActionType.click,
    componentType: ComponentType.button,
    newValue: appMode,
  });
}

import { useCallback } from 'react';
import { PossibleAccountBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';

import { WalletConnectionAccountResponse } from '../models/WalletConnectionResponse';

import { useProcessAccountBasedBlockchainAddress } from './useProcessAccountBasedBlockchainAddress';

export type ProcessAccountBasedBlockchainAddressParams = {
  provider: string;
  account: WalletConnectionAccountResponse;
  skipBalanceRefresh?: boolean;
  skipTransactionRefresh?: boolean;
  providerUserId?: string;
};

export function useProcessAccountBasedBlockchainAddresses() {
  const processAccountBasedAddress = useProcessAccountBasedBlockchainAddress();

  return useCallback(
    async function processAccountBasedBlockchainAddresses({
      provider,
      account,
      skipBalanceRefresh,
      skipTransactionRefresh,
      providerUserId,
    }: ProcessAccountBasedBlockchainAddressParams) {
      const accountPromises = [];

      for (const [chain, addresses] of Object.entries(account)) {
        for (const address of addresses) {
          const promise = processAccountBasedAddress({
            provider,
            chain: chain as PossibleAccountBlockchainSymbol,
            address,
            skipBalanceRefresh,
            skipTransactionRefresh,
            providerUserId,
          });
          accountPromises.push(promise);
        }
      }

      await Promise.all(accountPromises);
    },
    [processAccountBasedAddress],
  );
}

import { useCallback } from 'react';
import { useSetAuthTokens } from 'cb-wallet-data/stores/Authentication/tokens/useSetAuthTokens';

import { getRemoteUserAssetSettings } from '../api';

/**
 * Get user asset settings for both wallets and collectible from API
 * @return A function to fetch wallets and collectible settings from API given an address.
 */
export function useRemoteUserAssetSettings() {
  const setAuthTokens = useSetAuthTokens();

  return useCallback(
    async function getRemoteUserAssetSettingsCallback(address: string) {
      return getRemoteUserAssetSettings(address, setAuthTokens);
    },
    [setAuthTokens],
  );
}

import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import {
  PendingEthTxState,
  PendingUTXOTxState,
} from 'cb-wallet-data/stores/Transactions/models/PendingEthTxState';
import { TxOrUserOp } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import { TxOrUserOpMetadata } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOpMetadata';
import { stringify } from 'cb-wallet-store/utils/serialization';

/**
 * This file contains a series of functions that compare two transactions to determine if they are equal.
 * All of these functions are used in the Transaction.isEqual method to determine if two transactions are equal.
 * Tranaction.isEqual is used to determine if a transaction coming from the server is different than one stored
 * in the local database and needs to be updated.
 */

export function isBasicTxInfoEqual(tx1: TxOrUserOp, tx2: TxOrUserOp): boolean {
  const amountMatches = isPrimitiveEqual(tx1.amount, tx2.amount);
  const currencyCodeMatches = isCurrencyCodeEqual(tx1.currencyCode, tx2.currencyCode);
  const fromAmountMatches = isPrimitiveEqual(tx1.fromAmount, tx2.fromAmount);
  const isContractExecutionMatches = isPrimitiveEqual(
    tx1.isContractExecution,
    tx2.isContractExecution,
  );
  const isSentMatches = isPrimitiveEqual(tx1.isSent, tx2.isSent);
  const primaryActionMatches = isPrimitiveEqual(tx1.primaryAction, tx2.primaryAction);
  const stateMatches = isPrimitiveEqual(tx1.state, tx2.state);
  const toAmountMatches = isPrimitiveEqual(tx1.toAmount, tx2.toAmount);
  const toCurrencyCodeMatches = isCurrencyCodeEqual(tx1.toCurrencyCode, tx2.toCurrencyCode);
  const tokenDecimalMatches = isPrimitiveEqual(tx1.tokenDecimal, tx2.tokenDecimal);
  const tokenNameMatches = isPrimitiveEqual(tx1.tokenName, tx2.tokenName);
  const txHashMatches = isPrimitiveEqual(tx1.txHash, tx2.txHash);
  const typeMatches = isPrimitiveEqual(tx1.type, tx2.type);
  const isDeletedMatches = isPrimitiveEqual(tx1.deleted, tx2.deleted);

  return (
    amountMatches &&
    currencyCodeMatches &&
    fromAmountMatches &&
    isContractExecutionMatches &&
    isDeletedMatches &&
    isSentMatches &&
    primaryActionMatches &&
    stateMatches &&
    toAmountMatches &&
    toCurrencyCodeMatches &&
    tokenDecimalMatches &&
    tokenNameMatches &&
    txHashMatches &&
    typeMatches
  );
}

export function isPendingTxDataEqual(tx1: TxOrUserOp, tx2: TxOrUserOp): boolean {
  const pendingEthTxStateMatches = PendingEthTxState.isEqual(
    tx1.pendingEthTxData,
    tx2.pendingEthTxData,
  );
  const pendingUTXOTxStateMatches = PendingUTXOTxState.isEqual(
    tx1.pendingUTXOTxData,
    tx2.pendingUTXOTxData,
  );
  return pendingEthTxStateMatches && pendingUTXOTxStateMatches;
}

export function areTransfersEqual(tx1: TxOrUserOp, tx2: TxOrUserOp): boolean {
  const transfersMatch = stringify(tx1.transfers) === stringify(tx2.transfers);
  return transfersMatch;
}

export function areHistoricalPricesEqual(tx1: TxOrUserOp, tx2: TxOrUserOp): boolean {
  const toAssetHistoricalPriceMatches = isPrimitiveEqual(
    tx1.toAssetHistoricalPrice,
    tx2.toAssetHistoricalPrice,
  );
  const fromAssetHistoricalPriceMatches = isPrimitiveEqual(
    tx1.fromAssetHistoricalPrice,
    tx2.fromAssetHistoricalPrice,
  );
  const nativeAssetHistoricalPriceMatches = isPrimitiveEqual(
    tx1.nativeAssetHistoricalPrice,
    tx2.nativeAssetHistoricalPrice,
  );
  return (
    toAssetHistoricalPriceMatches &&
    fromAssetHistoricalPriceMatches &&
    nativeAssetHistoricalPriceMatches
  );
}

export function areAddressesEqual(tx1: TxOrUserOp, tx2: TxOrUserOp): boolean {
  const fromAddressMatches = isPrimitiveEqual(tx1.fromAddress, tx2.fromAddress);
  const toAddressMatches = isPrimitiveEqual(tx1.toAddress, tx2.toAddress);
  const toDomainMatches = isPrimitiveEqual(tx1.toDomain, tx2.toDomain);
  const fromDomainMatches = isPrimitiveEqual(tx1.fromDomain, tx2.fromDomain);
  const contractAddressMatches = isPrimitiveEqual(tx1.contractAddress, tx2.contractAddress);
  const toContractAddressMatches = isPrimitiveEqual(tx1.toContractAddress, tx2.toContractAddress);
  return (
    fromAddressMatches &&
    toAddressMatches &&
    toDomainMatches &&
    fromDomainMatches &&
    contractAddressMatches &&
    toContractAddressMatches
  );
}

export function isFeeEqual(tx1: TxOrUserOp, tx2: TxOrUserOp): boolean {
  const feeMatches = isPrimitiveEqual(tx1.fee, tx2.fee);
  const currencyCodeMatches = isCurrencyCodeEqual(tx1.feeCurrencyCode, tx2.feeCurrencyCode);
  const currencyDecimalMatches = isPrimitiveEqual(tx1.feeCurrencyDecimal, tx2.feeCurrencyDecimal);
  return feeMatches && currencyCodeMatches && currencyDecimalMatches;
}

export function isProtocolFeeEqual(tx1: TxOrUserOp, tx2: TxOrUserOp): boolean {
  const feeCurrencyCodeMatches = isCurrencyCodeEqual(
    tx1.protocolFeeCurrencyCode,
    tx2.protocolFeeCurrencyCode,
  );
  const feeAmountMatches = isPrimitiveEqual(tx1.protocolFeeAmount, tx2.protocolFeeAmount);
  const feeDecimalMatches = isPrimitiveEqual(tx1.protocolFeeDecimal, tx2.protocolFeeDecimal);
  const feeNameMatches = isPrimitiveEqual(tx1.protocolFeeName, tx2.protocolFeeName);

  return feeCurrencyCodeMatches && feeAmountMatches && feeDecimalMatches && feeNameMatches;
}

export function isCoinbaseFeeEqual(tx1: TxOrUserOp, tx2: TxOrUserOp): boolean {
  const feeCurrencyCodeMatches = isCurrencyCodeEqual(
    tx1.coinbaseFeeCurrencyCode,
    tx2.coinbaseFeeCurrencyCode,
  );

  const feeAmountMatches = isPrimitiveEqual(tx1.coinbaseFeeAmount, tx2.coinbaseFeeAmount);
  const feeDecimalMatches = isPrimitiveEqual(tx1.coinbaseFeeDecimal, tx2.coinbaseFeeDecimal);
  const feeNameMatches = isPrimitiveEqual(tx1.coinbaseFeeName, tx2.coinbaseFeeName);
  const feeAssetAddressMatches = isPrimitiveEqual(
    tx1.coinbaseFeeAssetAddress,
    tx2.coinbaseFeeAssetAddress,
  );

  return (
    feeCurrencyCodeMatches &&
    feeAmountMatches &&
    feeDecimalMatches &&
    feeNameMatches &&
    feeAssetAddressMatches
  );
}

export function areTransactionTimestampsEqual(tx1: TxOrUserOp, tx2: TxOrUserOp): boolean {
  const createdAtMatches = tx1.createdAt.getTime() === tx2.createdAt.getTime();
  const confirmedAtMatches = tx1.confirmedAt?.getTime() === tx2.confirmedAt?.getTime();
  return createdAtMatches && confirmedAtMatches;
}

export function isNonceEqual(tx1: TxOrUserOp, tx2: TxOrUserOp): boolean {
  const nonce1NullOrUndefined = tx1.nonce === null || tx1.nonce === undefined;
  const nonce2NullOrUndefined = tx2.nonce === null || tx2.nonce === undefined;
  const nonceBothNullOrUndefined = nonce1NullOrUndefined && nonce2NullOrUndefined;
  const nonceEq = !nonceBothNullOrUndefined && tx1.nonce === tx2.nonce;
  return nonceBothNullOrUndefined || nonceEq;
}

export function isNetworkEqual(tx1: TxOrUserOp, tx2: TxOrUserOp): boolean {
  // If both toNetworks are undefined, we consider them equal
  const toNetwork1NullOrUndefined = tx1.toNetwork === null || tx1.toNetwork === undefined;
  const toNetwork2NullOrUndefined = tx2.toNetwork === null || tx2.toNetwork === undefined;
  const areBothToNetworkUndefined = toNetwork1NullOrUndefined && toNetwork2NullOrUndefined;
  // If both toNetworks are defined, we consider them equal if they are equal
  const areBothToNetworkEq =
    !!tx1.toNetwork && !!tx2.toNetwork && Network.isEqual(tx1.toNetwork, tx2.toNetwork);
  const toNetworkMatches = areBothToNetworkUndefined || areBothToNetworkEq;
  const blockchainMatches = Blockchain.isEqual(tx1.blockchain, tx2.blockchain);
  const networkMatches = Network.isEqual(tx1.network, tx2.network);

  return networkMatches && toNetworkMatches && blockchainMatches;
}

export function isMetadataEqual(tx1: TxOrUserOp, tx2: TxOrUserOp): boolean {
  const idMatches = isPrimitiveEqual(tx1.id, tx2.id);
  const toTokenNameMatches = isPrimitiveEqual(tx1.toTokenName, tx2.toTokenName);
  const toTokenDecimalMatches = isPrimitiveEqual(tx1.toTokenDecimal, tx2.toTokenDecimal);
  const fromTokenIdMatches = isPrimitiveEqual(tx1.fromTokenId, tx2.fromTokenId);
  const toTokenIdMatches = isPrimitiveEqual(tx1.toTokenId, tx2.toTokenId);
  const fromAssetImageMatches = isPrimitiveEqual(tx1.fromAssetImage, tx2.fromAssetImage);
  const toAssetImageMatches = isPrimitiveEqual(tx1.toAssetImage, tx2.toAssetImage);
  const fromProfileImageMatches = isPrimitiveEqual(tx1.fromProfileImage, tx2.fromProfileImage);
  const toProfileImageMatches = isPrimitiveEqual(tx1.toProfileImage, tx2.toProfileImage);
  const sourceMatches = isPrimitiveEqual(tx1.source, tx2.source);
  const userOpMetadataMatches = TxOrUserOpMetadata.isEqual(tx1.metadata, tx2.metadata);

  return (
    idMatches &&
    toTokenNameMatches &&
    toTokenDecimalMatches &&
    fromTokenIdMatches &&
    toTokenIdMatches &&
    fromAssetImageMatches &&
    toAssetImageMatches &&
    fromProfileImageMatches &&
    toProfileImageMatches &&
    sourceMatches &&
    userOpMetadataMatches
  );
}

type PossibleCurrencyCode = CurrencyCode | string | undefined;

// CurrencyCodes can enter this function as undefined, a string, or a CurrencyCode. This
// function conforms them to a CurrencyCode then compares them.
export function isCurrencyCodeEqual(
  code1: PossibleCurrencyCode,
  code2: PossibleCurrencyCode,
): boolean {
  const code1ToStringOrCurrencyCode = code1 === undefined ? '' : code1;
  const code2ToStringOrCurrencyCode = code2 === undefined ? '' : code2;
  const currencyCode1 =
    typeof code1ToStringOrCurrencyCode === 'string'
      ? new CurrencyCode(code1ToStringOrCurrencyCode)
      : code1ToStringOrCurrencyCode;
  const currencyCode2 =
    typeof code2ToStringOrCurrencyCode === 'string'
      ? new CurrencyCode(code2ToStringOrCurrencyCode)
      : code2ToStringOrCurrencyCode;

  return CurrencyCode.isEqual(currencyCode1, currencyCode2);
}

type Primitive = string | number | bigint | boolean | undefined | null;

// Certain values come back from the server as undefined but from the database as null. This function
// coerces null and undefined together so they are treated equally.
export function isPrimitiveEqual(value1: Primitive, value2: Primitive): boolean {
  const value1NullOrUndefined = value1 === null || value1 === undefined;
  const value2NullOrUndefined = value2 === null || value2 === undefined;
  return (value1NullOrUndefined && value2NullOrUndefined) || value1 === value2;
}

import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { AccountType } from 'cb-wallet-data/stores/Accounts/models/AccountTypes';

import {
  signingAndDerivationMethodsForDappProviderAccount,
  signingAndDerivationMethodsForMnemonicAccount,
  signingAndDerivationMethodsForPrivateKeyAccount,
  signingAndDerivationMethodsForScwAccount,
} from '../signingAndDerivationForAccount';
import { SigningAndDerivationMethodsForAccount } from '../types/SigningAndDerivationMethodsForAccount';

/**
 * @param accountId
 * @param method
 * @returns appropriate signing method for the account type
 *
 * The accountId is parsed to retrieve the accountType and then the appropriate signing method is returned.
 */
export function getSigningOrDerivationMethodForAccount<
  TKey extends keyof SigningAndDerivationMethodsForAccount,
>(accountId: string, method: TKey): SigningAndDerivationMethodsForAccount[TKey] {
  const accountType = Account.parseAccountTypeFromId(accountId);
  switch (accountType) {
    case AccountType.MNEMONIC:
      return signingAndDerivationMethodsForMnemonicAccount.get(method);
    case AccountType.PRIVATE_KEY:
      return signingAndDerivationMethodsForPrivateKeyAccount.get(method);
    case AccountType.DAPP_PROVIDER:
      return signingAndDerivationMethodsForDappProviderAccount.get(method);
    case AccountType.SCW:
      return signingAndDerivationMethodsForScwAccount.get(method);
    default:
      throw new Error(`Unsupported account type: ${accountType}`) as never;
  }
}

/**
 * Utility functions for querying and storing the last synced tx hash by network. The last synced tx
 * hash is used to pre-empt excessive pagination when syncing transactions from the server. To optimize
 * performance, we now store this value in the last_synced_tx_hash table in the database rather than
 * needing to query the entire transactions table to get these values.
 */

import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { TxOrUserOp } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import keyBy from 'lodash/keyBy';

import { getLastSyncedTxHashes, saveLastSyncedTxHashes } from './database';
import { LastSyncedTxHash } from './LastSyncedTxHash';

export type LastSyncedTxHashByNetworkParams = {
  accountId: string;
  blockchain: Blockchain;
  network: Network;
  walletIndex: bigint;
};

export type GetLastSyncedTxHashByNetwork = (
  params: LastSyncedTxHashByNetworkParams,
) => LastSyncedTxHash | undefined;

// Enshrine a key format for the local cache
function createKey({
  accountId,
  walletIndex,
  blockchain,
  network,
}: LastSyncedTxHashByNetworkParams) {
  return `${accountId}-${walletIndex}-${blockchain.rawValue}-${network.rawValue}`;
}

// Local cache of the last synced tx hashes so we don't requery the database
const lastSyncedTxHashes: Record<string, LastSyncedTxHash> = {};

async function ensureLastSyncedTxHashesLoaded() {
  if (!Object.keys(lastSyncedTxHashes).length) {
    const lastSyncedTxHashDMOs = await getLastSyncedTxHashes();

    lastSyncedTxHashDMOs.forEach(function setLastSyncedHashes(lastSyncedTxHashDMO) {
      const lastSyncedTxHash = LastSyncedTxHash.fromDMO(lastSyncedTxHashDMO);
      const key = createKey(lastSyncedTxHash);
      lastSyncedTxHashes[key] = lastSyncedTxHash;
    });
  }
}

export async function createLastSyncedTxHashLookupFn(): Promise<GetLastSyncedTxHashByNetwork> {
  await ensureLastSyncedTxHashesLoaded();

  return function lookUpLastSyncedTxHash(params: LastSyncedTxHashByNetworkParams) {
    const key = createKey(params);
    return lastSyncedTxHashes[key];
  };
}

export async function saveLastSyncedTxHashOnTxUpdate(updatedTransactions: TxOrUserOp[]) {
  if (!updatedTransactions.length) {
    return;
  }

  const lastSyncedTxHashesFromDb = await getLastSyncedTxHashes();
  const lastSyncedTxHashesById = keyBy(lastSyncedTxHashesFromDb, 'id');

  const latestTransactionsMap: Record<string, TxOrUserOp> = {};
  updatedTransactions.forEach(function getLatestTransaction(tx) {
    const { accountId, selectedIndex } = Wallet.propsFromId(tx.walletId);
    const matchingId = LastSyncedTxHash.createId({
      accountId,
      blockchain: tx.blockchain,
      network: tx.network,
      walletIndex: selectedIndex!,
    });

    const existingTransaction = latestTransactionsMap[matchingId];

    if (!existingTransaction || existingTransaction.createdAt < tx.createdAt) {
      latestTransactionsMap[matchingId] = tx;
    }
  });

  const lastSyncedTxHashesToUpdate = Object.entries(latestTransactionsMap)
    .filter(function getTxHashesToUpdate([id, tx]) {
      const existingLastSyncedTxHash = lastSyncedTxHashesById[id];
      return (
        !existingLastSyncedTxHash ||
        (existingLastSyncedTxHash.createdAt < tx.createdAt &&
          tx.txHash !== existingLastSyncedTxHash.txHash)
      );
    })
    .map(function getUpdatedLastSyncTxHash([, tx]) {
      const { accountId, selectedIndex } = Wallet.propsFromId(tx.walletId);
      return new LastSyncedTxHash({
        accountId,
        blockchain: tx.blockchain,
        network: tx.network,
        txHash: tx.txHash!,
        walletIndex: selectedIndex!,
        createdAt: tx.createdAt,
        updatedAt: new Date(),
      });
    });

  if (lastSyncedTxHashesToUpdate.length) {
    await saveLastSyncedTxHashes(lastSyncedTxHashesToUpdate);
  }
}

import Decimal from 'decimal.js';

const precision = 78;

/**
 * A class that extends the Decimal class from the decimal.js library enabling
 * enough precision to deal with ETH amounts without losing precision.
 *
 * It also enables parsing decimal strings that contain commas or period as group separators.
 */
export class SanitizedBigDecimal extends Decimal {
  private constructor(value: Decimal.Value) {
    super(value);
  }

  public static create(value: Decimal.Value, locale = 'en') {
    let sanitizedValue: Decimal.Value;

    if (typeof value === 'string') {
      // Get the thousand separator based on the current locale.
      const thousandSeparator = Intl.NumberFormat(locale)
        .format(11111)
        .replace(/\p{Number}/gu, '');

      // Get the decimal separator based on the current locale.
      const decimalSeparator = Intl.NumberFormat(locale)
        .format(1.1)
        .replace(/\p{Number}/gu, '');

      // Replace the thousand and decimal separators in the input value
      // with their standard equivalents
      sanitizedValue = value
        .replace(new RegExp(`\\${thousandSeparator}`, 'g'), '')
        .replace(new RegExp(`\\${decimalSeparator}`), '.');
    } else {
      sanitizedValue = value;
    }

    // Call the constructor of the parent Decimal class with the sanitized value.
    return new sanitizedBigDecimalConstructor(sanitizedValue);
  }

  public toString(): string {
    return super
      .toFixed(precision)
      .replace(/0+$/, '')
      .replace(/\.{1}$/, '');
  }
}

const sanitizedBigDecimalConstructor = SanitizedBigDecimal.clone({
  precision: precision,
  toExpPos: precision,
});

import { WalletType } from 'cb-wallet-analytics/utils/types/WalletType';
import { type AllPossibleBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
import { BITCOIN_SYMBOL } from 'cb-wallet-data/chains/UTXO/Bitcoin/config';
import { DOGECOIN_SYMBOL } from 'cb-wallet-data/chains/UTXO/Dogecoin/config';
import { LITECOIN_SYMBOL } from 'cb-wallet-data/chains/UTXO/Litecoin/config';
import { Persistable } from 'cb-wallet-data/persistence/Database.interface';
import { includes } from 'cb-wallet-data/utils/includes';
import { Column, Entity, Index, PrimaryColumn } from '@cbhq/typeorm';

import {
  AccountType,
  HARDWARE_TYPES,
  LOCALLY_STORED_KEY_TYPES,
  MULTI_ACCOUNT_ROLLUP_TYPES,
  SIGNS_VIA_EXTENSION_TYPES,
  SUPPORTS_MULTI_WALLET_TYPES,
  SUPPORTS_UTXO_TYPES,
} from './AccountTypes';

@Entity('account')
@Index('IDX_ACCOUNT', ['type', 'primaryAddress', 'primaryAddressChain'])
@Index('IDX_ACCOUNT_CreatedAt', ['createdAt'])
export class AccountDMO {
  @PrimaryColumn('varchar')
  id!: string;

  @Column('datetime')
  createdAt!: Date;

  @Column('varchar')
  type!: AccountType;

  @Column('varchar', { nullable: true })
  primaryAddressChain!: AllPossibleBlockchainSymbol;

  @Column('varchar', { nullable: true })
  primaryAddress!: string;

  @Column()
  nickname!: string;

  @Column({ nullable: true, default: false })
  isDeleted!: boolean;

  /**
   * This value represents whether an account has been backed up either via cloud or manual backup.
   * All accounts that were onboarded / added before the instant onboarding feature are considered backed up because they back up during the onboarding flow
   * All accounts onboarded via instant onboarding are not backed up until the user backs them up via cloud / manually
   */
  @Column('boolean', { default: true })
  hasCompletedBackupFlow!: boolean;

  @Column('varchar', { nullable: true })
  provider?: string;

  @Column('varchar', { nullable: true })
  dappProviderUserId?: string;
}

export type AccountConstructorOptions = {
  id?: string;
  createdAt?: undefined;
  type: AccountType;
  primaryAddressChain: AllPossibleBlockchainSymbol;
  primaryAddress: string;
  nickname?: string;
  isDeleted?: boolean;
  hasCompletedBackupFlow?: boolean;
  provider?: string;
  dappProviderUserId?: string;
};

export type AccountWithoutInstanceMethods = Omit<Account, 'signingDevice' | 'walletType' | 'asDMO'>;

/**
 * Account
 *
 * NOTE Given that there is only 1 Ledger account displayed, there is a difference
 * between how accounts are stored and how they are displayed in the UI.
 *
 * Accounts relate to the BE User (auth tokens), Wallets/Addresses, and Wallet
 * Groups.
 *
 * What makes different types of accounts unique? primaryAddress
 * Mnemonic type account - primaryAddress is the 0th index ETH address
 * Ledger type account - primaryAddress is the user selected address when onboarding
 *
 * For mnemonic accounts, associated wallet groups are created for each
 * incrementing derivation index.
 *
 */
export class Account implements Persistable<AccountDMO> {
  /** Account ID. `accountType/primaryAddressChain/primaryAddress` */
  readonly id: string;
  readonly createdAt: Date;
  readonly type: AccountType;
  readonly primaryAddressChain: AllPossibleBlockchainSymbol;
  readonly primaryAddress: string;
  readonly isMnemonicAccount: boolean;
  readonly isSCWAccount: boolean;
  readonly isLedgerAccount: boolean;
  readonly isWalletlinkAccount: boolean;
  readonly isDappProviderAccount: boolean;
  readonly isMultiAccountRollup: boolean;
  readonly isHardwareAccount: boolean;
  readonly isPrivateKeyAccount: boolean;
  readonly hasLocallyStoredKey: boolean;
  readonly supportsMultiWallet: boolean;
  readonly supportsUTXO: boolean;
  readonly signsViaExtension: boolean;
  readonly provider?: string;
  readonly dappProviderUserId?: string;

  nickname: string;
  isDeleted: boolean;
  hasCompletedBackupFlow: boolean;
  // TODO readonly userId: string;

  get asDMO(): AccountDMO {
    return {
      id: this.id,
      createdAt: this.createdAt,
      type: this.type,
      primaryAddress: this.primaryAddress,
      primaryAddressChain: this.primaryAddressChain,
      nickname: this.nickname,
      isDeleted: this.isDeleted,
      hasCompletedBackupFlow: this.hasCompletedBackupFlow,
      provider: this.provider,
      dappProviderUserId: this.dappProviderUserId,
    };
  }

  /**
   * Account types that are imported from a hardware wallet device
   */
  private static isHardware(type: AccountType) {
    return includes(HARDWARE_TYPES, type);
  }

  /**
   * Account types that show only 1 user-facing account in the wallet switcher UI
   * even if there are up to 15 raw accounts
   */
  private static isMultiAccountRollup(type: AccountType) {
    return includes(MULTI_ACCOUNT_ROLLUP_TYPES, type);
  }

  /**
   * The keys are stored locally on the wallet app, not a hardware device or walletlink
   *
   * In the case of walletlink, the keys are stored in the RN app, not the extension,
   * and this account type only exists on extension. So it is not considered locally
   * stored.
   *
   * In the case of hardware wallets (ledger, trezor), the keys are stored on the
   * hardware device, not on the extension or RN apps.
   */
  private static hasLocallyStoredKey(type: AccountType) {
    return includes(LOCALLY_STORED_KEY_TYPES, type);
  }

  /**
   * This returns true if the account type supports multi-wallet features
   *  * Multiple different blockchains (UTXO, SOL, and ETH)
   *  * Multiple derived addresses per account as opposed to 1 account per address (eg. Ledger, Private Key)
   */
  private static supportsMultiWallet(accountType: AccountType) {
    return includes(SUPPORTS_MULTI_WALLET_TYPES, accountType);
  }

  /**
   * This returns true if the account type supports utxo wallets
   */
  private static supportsUTXO(
    accountType: AccountType,
    primaryAddressChain: AllPossibleBlockchainSymbol,
  ) {
    if (accountType === AccountType.DAPP_PROVIDER) {
      return includes([BITCOIN_SYMBOL, DOGECOIN_SYMBOL, LITECOIN_SYMBOL], primaryAddressChain);
    }
    return includes(SUPPORTS_UTXO_TYPES, accountType);
  }

  /**
   * This returns true when the account type uses the extension/popup as a means of signing
   * as opposed to using walletlink UI for signing.
   */
  private static signsViaExtension(accountType: AccountType) {
    return includes(SIGNS_VIA_EXTENSION_TYPES, accountType);
  }

  /**
   * This value should ONLY be used for analytics logging purposes.
   *
   * Please use accountType or accountType conditionals instead for feature toggling based on accounts.
   */
  get walletType() {
    if (this.isMnemonicAccount || this.isPrivateKeyAccount || this.isSCWAccount) {
      return WalletType.standalone;
    }
    if (this.isLedgerAccount) {
      return WalletType.hw_wallet;
    }
    if (this.isWalletlinkAccount) {
      return WalletType.walletlink;
    }
    if (this.isDappProviderAccount) {
      return WalletType.dapp_provider;
    }
    throw new Error('No WalletType matches this account type');
  }

  static fromDMO(options: AccountDMO): Account {
    return new Account(options);
  }

  static generateID(
    type: AccountType,
    primaryAddressChain: AllPossibleBlockchainSymbol,
    primaryAddress: string,
  ): string {
    return [type, primaryAddressChain, primaryAddress].join('/');
  }

  /**
   * Checks whether the input is a string and matches the general pattern of an `Account` ID. Specifically, any characters
   * separated by at least two slashes.
   *
   * This method is meant to distinguish `Account` ID strings (e.g. `mnemonic/ETH/0x12345`) from strings without any slashes such as:
   *   - legacy usernames (e.g. `@my_username`)
   *   - CB.IDs (e.g. `my_username.cb.id`)
   */
  static isStringValidAccountId(rawInput?: string): boolean {
    return typeof rawInput === 'string' ? new RegExp('.+/.+/.+', 'g').test(rawInput) : false;
  }

  static parseAccountTypeFromId(id: string): AccountType {
    const [accountType] = id.split('/');
    return accountType as AccountType;
  }

  static isMnemonicAccount(id: string): boolean {
    return Account.parseAccountTypeFromId(id) === AccountType.MNEMONIC;
  }

  static isSCWAccount(id: string): boolean {
    return Account.parseAccountTypeFromId(id) === AccountType.SCW;
  }

  static isLedgerAccount(id: string): boolean {
    return Account.parseAccountTypeFromId(id) === AccountType.LEDGER;
  }

  static isWalletlinkAccount(id: string): boolean {
    return Account.parseAccountTypeFromId(id) === AccountType.WALLET_LINK;
  }

  static isPrivateKeyAccount(id: string): boolean {
    return Account.parseAccountTypeFromId(id) === AccountType.PRIVATE_KEY;
  }

  static isDappProviderAccount(id: string): boolean {
    return Account.parseAccountTypeFromId(id) === AccountType.DAPP_PROVIDER;
  }

  static parseAddressFromId(id: string): string {
    const [, , address] = id.split('/');
    return address;
  }

  static parseMnemonicOrPrivateKeyFromId(
    id: string,
  ): AccountType.MNEMONIC | AccountType.PRIVATE_KEY {
    const type = Account.parseAccountTypeFromId(id);
    if (type !== AccountType.MNEMONIC && type !== AccountType.PRIVATE_KEY) {
      throw new Error(`Invalid account type for show recovery phrase ${type}`);
    }
    return type;
  }

  private static validateDAppProviderAccountConstruction(provider?: string) {
    if (!provider) {
      throw new Error('provider is required when the accountType === DAPP_PROVIDER');
    }
  }

  constructor({
    id,
    createdAt,
    type,
    primaryAddressChain,
    primaryAddress,
    nickname,
    isDeleted,
    hasCompletedBackupFlow,
    provider,
    dappProviderUserId,
  }: AccountConstructorOptions | AccountDMO) {
    if (type === AccountType.DAPP_PROVIDER) {
      Account.validateDAppProviderAccountConstruction(provider);
    }

    this.id = id || Account.generateID(type, primaryAddressChain, primaryAddress);
    this.createdAt = createdAt || new Date();
    this.type = type;
    this.primaryAddressChain = primaryAddressChain;
    this.primaryAddress = primaryAddress;
    this.nickname = nickname || '';
    // Helper variables to compare accountType
    this.isLedgerAccount = this.type === AccountType.LEDGER;
    this.isWalletlinkAccount = this.type === AccountType.WALLET_LINK;
    this.isDappProviderAccount = this.type === AccountType.DAPP_PROVIDER;
    this.isMnemonicAccount = this.type === AccountType.MNEMONIC;
    this.isSCWAccount = this.type === AccountType.SCW;
    this.isPrivateKeyAccount = this.type === AccountType.PRIVATE_KEY;
    this.isMultiAccountRollup = Account.isMultiAccountRollup(this.type);
    this.isHardwareAccount = Account.isHardware(this.type);
    this.hasLocallyStoredKey = Account.hasLocallyStoredKey(this.type);
    this.supportsMultiWallet = Account.supportsMultiWallet(this.type);
    this.supportsUTXO = Account.supportsUTXO(this.type, primaryAddressChain);
    this.signsViaExtension = Account.signsViaExtension(this.type);
    this.provider = provider;
    this.isDeleted = isDeleted ?? false;
    this.hasCompletedBackupFlow = hasCompletedBackupFlow ?? true;
    this.dappProviderUserId = dappProviderUserId;
  }
}

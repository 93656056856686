type TimersMapType = Record<string, number>;

export const timers: TimersMapType = {};

export function newTimer(name: string) {
  timers[name] = performance.now();
}

export function getTimer(name: string): number | undefined {
  return timers[name];
}

export function deleteTimer(name: string) {
  delete timers[name];
}

export function createSubmitTimerNameWithSource(txHash: string, txSource: string): string {
  return `${getSubmittimerNamePrefix(txHash)}.${txSource.toLowerCase()}`;
}

export function getSubmitTimerAndSource(txHash: string): {
  timer: number | undefined;
  source: string | undefined;
} {
  const submitTimerName = Object.keys(timers).find((timerName) =>
    timerName.includes(getSubmittimerNamePrefix(txHash)),
  );

  if (!submitTimerName) {
    return {
      timer: undefined,
      source: undefined,
    };
  }

  return {
    timer: getTimer(submitTimerName),
    source: getSourceFromSubmitTimerName(submitTimerName),
  };
}

function getSourceFromSubmitTimerName(submitTimerName: string): string {
  return submitTimerName.split('.').pop()!;
}

function getSubmittimerNamePrefix(txHash: string) {
  return `transaction.submitted.${txHash.toLowerCase()}`;
}

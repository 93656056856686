import { ETHEREUM_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { blockchainConfigurations } from 'cb-wallet-data/chains/blockchains';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';

/**
 * Chain IDs that support smart contract wallet functionality.
 * Do not use directly. Exported for testing.
 */
export const SCW_SUPPORTED_CHAIN_IDS = [1, 8453, 10, 43114, 42161, 137, 56, 7777777];
export const SCW_SUPPORTED_TESTNET_CHAIN_IDS = [11155111, 84532, 11155420];

/**
 * Get all smart contract wallet supported chain IDs.
 */
export function getSCWSupportedChainIds(): number[] {
  return [...SCW_SUPPORTED_CHAIN_IDS, ...SCW_SUPPORTED_TESTNET_CHAIN_IDS];
}

/**
 * Whether a given chain ID supports smart contract wallet functionality.
 */
export function isSCWSupportedChainId(chainId: number): boolean {
  return getSCWSupportedChainIds().includes(chainId);
}

/**
 * Whether a given network supports smart contract wallet functionality.
 */
export function isSCWSupportedNetwork(network: Network): boolean {
  const chainId = network.asChain()?.chainId;
  return chainId ? isSCWSupportedChainId(chainId) : false;
}

/**
 * From the data layer's Ethereum network configs, get them filtered down to
 * those that support smart wallet functionality.
 */
export function getSCWSupportedNetworks(): Network[] {
  const allNetworks = blockchainConfigurations[ETHEREUM_SYMBOL].networkSetting.allNetworks;

  return allNetworks
    .filter((networkSetting) => isSCWSupportedNetwork(networkSetting.network))
    .map(({ network }) => network);
}

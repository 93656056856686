import { atomLocalStorageEffect } from 'cb-wallet-data/utils/atomLocalStorageEffect';
import {
  getAccessTokenFromStorage,
  getRefreshTokenFromStorage,
  StoreKeys_accessToken,
  StoreKeys_refreshToken,
} from 'cb-wallet-http/Authentication/tokens/state';
import { AccessTokenResult } from 'cb-wallet-http/Authentication/types/AccessTokenResult';
import { Store } from 'cb-wallet-store/Store';
import { atom } from 'recoil';

export const accessTokenAtom = atom({
  key: 'accessToken',
  default: undefined as string | undefined,
  effects: [atomLocalStorageEffect(StoreKeys_accessToken)],
});

export const refreshTokenAtom = atom({
  key: 'refreshToken',
  default: undefined as string | undefined,
  effects: [atomLocalStorageEffect(StoreKeys_refreshToken)],
});

export function canAuthenticateImperative() {
  const accessToken = getAccessTokenFromStorage();
  if (accessToken) {
    return true;
  }

  const refreshToken = getRefreshTokenFromStorage();
  return !!refreshToken;
}

export function setAuthTokensImperative(tokens: AccessTokenResult) {
  Store.set(StoreKeys_accessToken, tokens.token);
  Store.set(StoreKeys_refreshToken, tokens.refreshToken);
}

import { CSSProperties, RefObject, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { durations } from '@cbhq/cds-common/motion/tokens';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { Icon, NavigationIcon } from '@cbhq/cds-web/icons';
import { Box, HStack } from '@cbhq/cds-web/layout';
import { Pressable } from '@cbhq/cds-web/system';
import { BrowserOnly } from '@cbhq/cds-web/system/BrowserOnly';
import { TextBody } from '@cbhq/cds-web/typography';

import { messages } from './messages';

// to match search input style
const searchBoarderStyle: CSSProperties = {
  border: '1px solid transparent',
};

type SearchButtonProps = {
  isSearching: boolean;
  toggleSearchOn: () => void;
  searchPositionRef?: RefObject<HTMLElement>;
};

export function SearchButton({
  isSearching,
  toggleSearchOn,
  searchPositionRef,
}: SearchButtonProps) {
  const { formatMessage } = useIntl();
  const { isPhone: isMobile } = useBreakpoints();

  const handleSearchButtonPress = useCallback(() => {
    toggleSearchOn();
  }, [toggleSearchOn]);

  const style: CSSProperties = useMemo(
    () => ({
      visibility: isSearching ? 'hidden' : 'visible',
      transitionProperty: 'visibility',
      transitionDuration: `${durations.moderate1}ms`,
    }),
    [isSearching],
  );

  const searchIcon = useMemo(
    () =>
      isMobile ? (
        <Box
          width={40}
          height={40}
          alignItems="center"
          justifyContent="center"
          key="magnifyingGlass"
        >
          <NavigationIcon name="magnifyingGlass" size="s" testID="mobile-search-button" />
        </Box>
      ) : (
        <HStack
          ref={searchPositionRef}
          key="desktop-search-bar"
          testID="desktop-search-bar"
          height={40}
          alignContent="center"
          alignItems="center"
          style={searchBoarderStyle}
        >
          <Icon name="search" size="s" color="foreground" spacingHorizontal={2} />
          <TextBody color="foregroundMuted" numberOfLines={1} as="p" spacingStart={0.5}>
            {formatMessage(messages.searchPlaceholderText)}
          </TextBody>
        </HStack>
      ),
    [formatMessage, isMobile, searchPositionRef],
  );

  return (
    <BrowserOnly>
      <Pressable
        as="button"
        background="secondary"
        borderWidth="button"
        borderRadius="roundedFull"
        onPress={handleSearchButtonPress}
        accessibilityLabel={formatMessage(messages.searchPlaceholderText)}
        noScaleOnPress
        style={style}
        width={isMobile ? 'auto' : '550px'}
        testID="global-search-button"
      >
        {searchIcon}
      </Pressable>
    </BrowserOnly>
  );
}

import { useCallback, useEffect, useMemo, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { logEvent } from 'cb-wallet-analytics/utils/log';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { SearchEntity } from 'cb-wallet-data/stores/Search/types';
import { ErrorBoundary } from 'wallet-cds-web/components/ErrorBoundary';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { TextBody } from '@cbhq/cds-web/typography';
import { ActionType, ComponentType } from '@cbhq/client-analytics';

import { Loader } from ':dapp/components/Search/Loader';
import { SearchFilter } from ':dapp/components/Search/SearchFilter';
import { useGlobalSearchResults } from ':dapp/hooks/useGlobalSearchResults';

import { SearchResult } from './SearchResult';

const messages = defineMessages({
  noResultsFound: {
    defaultMessage: 'No results for "{query}"',
    description: 'Helper message for no results found',
  },
  errorFallbackMessage: {
    defaultMessage: 'Something went wrong. Please try again.',
    description: 'Error fallback message',
  },
});

type SearchResultListProps = {
  query: string;
  searchFilter: SearchEntity;
  setSearchFilter: (filter: SearchEntity) => void;
};

export function SearchResultListContent({
  query,
  searchFilter,
  setSearchFilter,
}: SearchResultListProps) {
  const { formatMessage } = useIntl();
  const { sections, isLoading } = useGlobalSearchResults(query, searchFilter);
  const previousSectionsRef = useRef(sections);
  const isGlobalSearchUsingWacEnabled = useIsFeatureEnabled('global_search_using_wac_panorama');

  const isEmptyData = useMemo(
    () => sections.reduce((acc, section) => acc + section.data.length, 0) === 0,
    [sections],
  );

  useEffect(() => {
    logEvent('global_search_web.search_bar.query', {
      action: ActionType.search,
      componentType: ComponentType.search_bar,
      searchQuery: query,
      searchFilter,
    });
  }, [query, searchFilter]);

  const sectionsToRender = useMemo(() => {
    // If we are loading and there is no data from client side filtering,
    // we want to keep the previous sections to avoid flickering
    if (isLoading && isEmptyData) {
      return previousSectionsRef.current;
    }

    previousSectionsRef.current = sections;

    // If we are searching via WAC, we don't need to filter the sections as the results are driven from BE
    if (isGlobalSearchUsingWacEnabled) {
      return sections;
    }

    if (searchFilter !== SearchEntity.SEARCH_ENTITY_UNSPECIFIED) {
      return sections.filter((section) => section.searchEntity === searchFilter);
    }

    return sections;
  }, [isLoading, isEmptyData, sections, isGlobalSearchUsingWacEnabled, searchFilter]);

  const isEmptyStateWithFilters = useMemo(
    () => sectionsToRender.reduce((acc, section) => acc + section.data.length, 0) === 0,
    [sectionsToRender],
  );

  const handleSearchResultClick = useCallback(
    (searchResultName: string, searchResultType: string) => {
      return () =>
        logEvent('global_search_web.search_result.click', {
          action: ActionType.click,
          componentType: ComponentType.button,
          searchResultName,
          searchResultType,
        });
    },
    [],
  );

  const searchResultList = useMemo(() => {
    if (isLoading) {
      return <Loader />;
    }

    return sectionsToRender.map((section, sectionIndex) => (
      <VStack testID="global-search-section-item" key={section.title}>
        {section.data.map((item, itemIndex) => {
          const listItemtTestID = `global-search-result-section-${sectionIndex}-${item.title.toLowerCase()}-${itemIndex}`;
          return (
            <Box
              key={item.key}
              onClick={handleSearchResultClick(item.title, section.searchEntity)}
              testID={listItemtTestID}
            >
              <SearchResult
                {...item}
                searchEntity={
                  section.searchEntity !== SearchEntity.SEARCH_ENTITY_UNSPECIFIED
                    ? section.searchEntity
                    : item.searchEntity
                }
              />
            </Box>
          );
        })}
      </VStack>
    ));
  }, [handleSearchResultClick, isLoading, sectionsToRender]);

  const noResultsFound = useMemo(() => {
    if (isLoading) {
      return <Loader />;
    }

    return (
      <Box justifyContent="center" alignItems="center" spacingTop={2} spacingEnd={4}>
        <TextBody as="p" color="foregroundMuted">
          {formatMessage(messages.noResultsFound, { query })}
        </TextBody>
      </Box>
    );
  }, [formatMessage, isLoading, query]);

  /**
   * @TODO update to use virtualized list
   * @see https://jira.coinbase-corp.com/browse/WALL-27461
   */
  return (
    <>
      <SearchFilter selectedFilter={searchFilter} setSelectedFilter={setSearchFilter} />
      <VStack overflow="auto">
        {!isEmptyStateWithFilters ? searchResultList : noResultsFound}
      </VStack>
    </>
  );
}

export function SearchResultList({ query, searchFilter, setSearchFilter }: SearchResultListProps) {
  return (
    <ErrorBoundary fallback={<ErrorFallback />} context="global_search">
      <SearchResultListContent
        query={query}
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
      />
    </ErrorBoundary>
  );
}

function ErrorFallback() {
  const { formatMessage } = useIntl();
  return (
    <Box height="100%" justifyContent="center" alignItems="center">
      <TextBody as="p" color="foregroundMuted">
        {formatMessage(messages.errorFallbackMessage)}
      </TextBody>
    </Box>
  );
}

import {
  SettingCategory,
  SettingCategoryConfig,
  SubCategoryParam,
} from 'cb-wallet-data/stores/SettingsRedesign/types';

export function generateSubCategoriesForSearch(
  config: SettingCategoryConfig,
  subCategoryParams: SubCategoryParam[],
) {
  return subCategoryParams.map(function generateSubCategoriesFromConfig(subCategoryParam) {
    const onPress =
      subCategoryParam?.onPress ?? (() => config.onPress?.(subCategoryParam?.options));

    const navigationPath = subCategoryParam?.navigationPath ?? config.navigationPath;

    const searchableTitleText =
      typeof subCategoryParam.title === 'string'
        ? subCategoryParam.title
        : subCategoryParam.titleText;

    const subCategoryConfig: SettingCategoryConfig = {
      ...config,
      accessory: 'arrow',
      title: subCategoryParam.title,
      description: `${config.title} → ${searchableTitleText}`,
      onPress,
      media: SettingCategory.SUBCATEGORY,
      testID: config.testID ? `${config.testID}-${subCategoryParam?.testID ?? ''}` : undefined,
      navigationPath,
    };
    return subCategoryConfig;
  });
}

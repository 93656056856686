import { FiatCurrency } from 'cb-wallet-data/stores/ActiveFiatCurrency/models/FiatCurrency';
import { activeFiatCurrencyAtom } from 'cb-wallet-data/stores/ActiveFiatCurrency/state';
import { useRecoilValue, useSetRecoilState } from 'recoil';

export function useActiveFiatCurrency(): FiatCurrency {
  return useRecoilValue(activeFiatCurrencyAtom);
}

export function useSetActiveFiatCurrency() {
  return useSetRecoilState(activeFiatCurrencyAtom);
}

import { idbConnect } from './idbConnect';
import { idbRequest } from './idbRequest';

type IndexedDBKeyValueStoreConstructorParams = Pick<
  Parameters<typeof idbConnect>[0],
  'dbName' | 'dbVersion' | 'storeName'
>;

type KeyValuePair<V> = { id: string; value: V };

/**
 * A key-value store that uses IndexedDB as the underlying storage.
 */
export class IndexedDBKeyValueStore {
  private readonly dbName: string;
  private dbVersion: number;
  private readonly storeName: string;
  private dbConnection: IDBDatabase | undefined;

  /**
   * This constructor takes the name of the database, the version of the database, and the name of the database's store.
   *
   * The `dbVersion` and `dbConnection` are privately mutable to accommodate the scenario where the `versionchange` event
   * is triggered and a DB upgrade is needed. See {@link IndexedDBKeyValueStore.handleVersionChange} for more details.
   */
  constructor({ dbName, dbVersion, storeName }: IndexedDBKeyValueStoreConstructorParams) {
    this.dbName = dbName;
    this.dbVersion = dbVersion;
    this.storeName = storeName;
  }

  /**
   * This will handle the `versionchange` event that is triggered when the database version is changed OR the
   * `IDBDatabase.deleteDatabase`'s method is called.
   *
   * The scenario where the `versionchange` event is triggered is when a user has multiple tabs open of the same origin
   * (e.g. https://coinbase.com) and they reload one of the tabs which pulls in updated client-side code that tries to
   * connect to the database with a different version or attempts to delete it entirely. This will trigger the `versionchange`
   * event on the other tabs.
   *
   * To avoid forcing users to reload all of their other tabs/throw errors on the other tabs, we close the connection
   * then reset the local reference to the connection. This will force the other tabs to create a new connection with the
   * updated version parameter the next time the database is accessed.
   *
   * For database upgrades where the new version is greater than the old version we will update the local reference to the
   * database version so that the next time the database is accessed it will use the updated version number.
   */
  private handleVersionChange(event: IDBVersionChangeEvent): void {
    const { newVersion, oldVersion } = event;
    this.dbConnection?.close();
    this.dbConnection = undefined;

    // We need to check that new version is greater than the old version to avoid throwing an error since
    // IndexedDB will not allow a downgrade to a previous version number.
    if (typeof newVersion === 'number' && newVersion > oldVersion) {
      this.dbVersion = newVersion;
    }
  }

  /**
   * Returns a local reference to the database connection. If the connection does not exist, it will create a new one.
   *
   * When creating a new connection, it will also add an event listener for the `versionchange` event to handle when
   * the database is upgraded or programmatically deleted.
   */
  private async connect(): Promise<IDBDatabase> {
    if (typeof this.dbConnection === 'undefined') {
      const db = await idbConnect({
        dbName: this.dbName,
        dbVersion: this.dbVersion,
        storeName: this.storeName,
        createObjectStoreOptions: { keyPath: 'id', autoIncrement: false },
      });
      db.onversionchange = this.handleVersionChange.bind(this);

      this.dbConnection = db;
    }

    return this.dbConnection;
  }

  /**
   * Upserts (i.e. updates if it exists or inserts if it does not) a key-value pair.
   */
  async upsert(key: string, value: unknown): Promise<void> {
    const db = await this.connect();
    const transaction = db.transaction(this.storeName, 'readwrite');
    const store = transaction.objectStore(this.storeName);
    await idbRequest(store.put({ id: key, value }));
  }

  /**
   * Returns all of the key-value pairs.
   */
  async getAll<V = unknown>(): Promise<KeyValuePair<V>[]> {
    const db = await this.connect();
    const transaction = db.transaction(this.storeName, 'readonly');
    const store = transaction.objectStore(this.storeName);
    const result = await idbRequest<KeyValuePair<V>[]>(store.getAll());
    return result;
  }

  /**
   * Removes a specific key-value pair.
   */
  async remove(key: string): Promise<void> {
    const db = await this.connect();
    const transaction = db.transaction(this.storeName, 'readwrite');
    const store = transaction.objectStore(this.storeName);
    await idbRequest(store.delete(key));
  }

  /**
   * Removes all key-value pairs.
   */
  async clearAll(): Promise<void> {
    const db = await this.connect();
    const transaction = db.transaction(this.storeName, 'readwrite');
    const store = transaction.objectStore(this.storeName);
    await idbRequest(store.clear());
  }
}

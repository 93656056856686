import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { CAIP19Parser } from 'cb-wallet-data/utils/caip19AssetParser';

export const MINT_PAGE_SLUGS = [
  'ocsII',
  'coin-earnings',
  'sunset',
  'sunset1155',
  'test-721-open',
  'buildathon',
  'payment-pioneer',
  'ocscreative',
  'MDP',
  'smartwallet',
  'adidas0',
  'adidas1',
  'adidas2',
  'team-liquid',
  'KIKI-World',
  'i-need-a-dollar',
  'welcomeonchain',
  'CBWonchainsummer2024',
  'meow',
  'mistermiggles',
  'onboardmints',
  'onboardmints1',
  'onboardmints2',
  'onboardmints3',
  'onboardmints4',
  'onboardmints5',
  'onboardmints6',
  'onboardmints7',
  'onboardmints8',
  'onboardmints9',
  'onboardmints10',
  'doodles',
  'coin-earningsq22024',
  'eurc',
  'coffeedays',
  'daylightenergy',
  'swctour2024',
  'fairies',
  'stix',
  'midnightdinerfall',
] as const;

export type MintPageSlug = (typeof MINT_PAGE_SLUGS)[number];

export function isMintPageSlug(key: string): key is MintPageSlug {
  return key in mintPageConfig;
}

type MintPageConfig = Record<MintPageSlug, MintPageConfigType>;

export type MintPageConfigType = {
  slug: string;
  contractAddress: string;
  chainId: string;
  tokenId?: string;
  crossMintConfig?: {
    collectionID: string;
    contractType: 'ThirdWeb1155' | 'Doodles' | 'TokenForge';
  };
  successModal?: {
    title?: string;
    description?: string;
    primarySuccessButton?: {
      text: string;
      url: string;
    };
    secondarySuccessButton?: {
      text: string;
      url: string;
    };
  };
};

export function getMintPageConfigBySlugOrContractAddress(
  slugOrAddress: string,
): MintPageConfigType | undefined {
  const caseInsensitiveSlugOrAddress = slugOrAddress?.toLowerCase();
  let caseInsensitiveParsedAddress = '';
  try {
    const caip19Parsed = CAIP19Parser.TryParse(caseInsensitiveSlugOrAddress);
    caseInsensitiveParsedAddress = caip19Parsed?.assetReference.toLowerCase() ?? '';
  } catch (e) {
    cbReportError({
      error: coerceError(e, 'getMintPageConfigBySlugOrContractAddress'),
      context: 'mint',
      severity: 'warning',
      isHandled: false,
    });
  }

  for (const slug of MINT_PAGE_SLUGS) {
    const override = mintPageConfig[slug];
    const caseInsensitiveMatchingSlug = override?.slug.toLowerCase();
    const caseInsensitiveMatchingAddress = override?.contractAddress.toLowerCase();

    // Match on slug
    const slugMatch = caseInsensitiveSlugOrAddress === caseInsensitiveMatchingSlug;

    // Match on partial CAIP19 address
    const addressMatch = caseInsensitiveParsedAddress === caseInsensitiveMatchingAddress;

    if (slugMatch || addressMatch) {
      return override;
    }
  }
}

export const mintPageConfig: MintPageConfig = {
  ocsII: {
    slug: 'ocsII',
    contractAddress: '0x768E7151500bB5120983d9619374F31DD71D8357',
    chainId: '8453',
    tokenId: undefined,
    successModal: {
      primarySuccessButton: {
        url: '/assets/nft',
        text: 'View my portfolio',
      },
    },
  },
  'coin-earnings': {
    slug: 'coin-earnings',
    contractAddress: '0x1D6b183bD47F914F9f1d3208EDCF8BefD7F84E63',
    chainId: '8453',
    tokenId: '1',
    successModal: {
      primarySuccessButton: {
        url: '/assets/nft',
        text: 'View my portfolio',
      },
    },
  },
  sunset: {
    slug: 'sunset',
    contractAddress: '0x53d19B1334cc21293cbA7029C27118ECe7C1eab0',
    chainId: '8453',
    tokenId: undefined,
    successModal: {
      primarySuccessButton: {
        url: '/assets/nft',
        text: 'View my portfolio',
      },
    },
  },
  sunset1155: {
    slug: 'sunset1155',
    contractAddress: '0x97D8c4577Abab906c70480494F22f6f744Dc2eFd',
    chainId: '8453',
    tokenId: '0',
    successModal: {
      primarySuccessButton: {
        url: '/assets/nft',
        text: 'View my portfolio',
      },
    },
    crossMintConfig: {
      collectionID: 'd57be4af-66b8-42e0-97a3-e8931f415f86',
      contractType: 'ThirdWeb1155',
    },
  },
  'test-721-open': {
    slug: 'test-721-open',
    contractAddress: '0x206687f58837f1Ba873f7fdea12D7DC6228B6D12',
    chainId: '8453',
    tokenId: undefined,
    successModal: {
      primarySuccessButton: {
        url: '/assets/nft',
        text: 'View my portfolio',
      },
    },
  },
  buildathon: {
    slug: 'buildathon',
    contractAddress: '0x0c45CA58cfA181b038E06dd65EAbBD1a68d3CcF3',
    chainId: '8453',
    tokenId: undefined,
    successModal: {
      title: "It's yours",
      primarySuccessButton: {
        url: '/assets/nft',
        text: 'View my portfolio',
      },
    },
  },
  'payment-pioneer': {
    slug: 'payment-pioneer',
    contractAddress: '0x24864a39CA7733F0b35678ED50347ae9c25E8590',
    chainId: '8453',
    tokenId: undefined,
    successModal: {
      title: "It's yours",
      primarySuccessButton: {
        url: '/assets/nft',
        text: 'View my portfolio',
      },
    },
  },
  ocscreative: {
    slug: 'ocscreative',
    contractAddress: '0xe5CE018E2aF6109be9FDA3a7dc36DB3Eb2765f93',
    chainId: '8453',
    tokenId: undefined,
    successModal: {
      title: "It's yours",
      primarySuccessButton: {
        url: 'https://base.org/onchainfont',
        text: 'Download creative files',
      },
    },
  },
  MDP: {
    slug: 'MDP',
    contractAddress: '0xc7DeD9c1BD13A19A877d196Eeea9222Ff6d40736',
    chainId: '8453',
    tokenId: undefined,
    successModal: {
      title: "It's yours",
      primarySuccessButton: {
        url: 'https://warpcast.com/cooprecs/0xc0cca73a',
        text: 'Redeem your free ticket',
      },
    },
  },
  smartwallet: {
    slug: 'smartwallet',
    contractAddress: '0x76c7104567b5D32D4B084C8034724f9103F285be',
    chainId: '8453',
    tokenId: undefined,
    successModal: {
      title: "It's yours",
      primarySuccessButton: {
        url: 'https://wallet.coinbase.com',
        text: 'Explore more',
      },
    },
  },
  adidas0: {
    slug: 'adidas0',
    contractAddress: '0xc6a1f929b7ca5d76e0fa21eb44da1e48765990c5',
    chainId: '8453',
    tokenId: '0',
    crossMintConfig: {
      collectionID: 'ad984f0f-10d6-431e-8513-77955d06d8ab',
      contractType: 'ThirdWeb1155',
    },
  },
  adidas1: {
    slug: 'adidas1',
    contractAddress: '0xc6a1f929b7ca5d76e0fa21eb44da1e48765990c5',
    chainId: '8453',
    tokenId: '1',
    crossMintConfig: {
      collectionID: 'ad984f0f-10d6-431e-8513-77955d06d8ab',
      contractType: 'ThirdWeb1155',
    },
  },
  adidas2: {
    slug: 'adidas2',
    contractAddress: '0xc6a1f929b7ca5d76e0fa21eb44da1e48765990c5',
    chainId: '8453',
    tokenId: '2',
    crossMintConfig: {
      collectionID: 'ad984f0f-10d6-431e-8513-77955d06d8ab',
      contractType: 'ThirdWeb1155',
    },
  },
  'team-liquid': {
    slug: 'team-liquid',
    contractAddress: '0x1b9ac8580d2e81d7322f163362831448e7fcad1b',
    chainId: '8453',
    tokenId: undefined,
    successModal: {
      title: "It's yours",
      primarySuccessButton: {
        url: 'https://www.communitygaming.io/tournament/onchain-summer-tft-1',
        text: 'Explore Tournament',
      },
    },
  },
  'KIKI-World': {
    slug: 'KIKI-World',
    contractAddress: '0x0712645903f99Ca87bFC501345fDf86b2383F2c3',
    chainId: '8453',
    tokenId: '0',
    successModal: {
      title: "It's yours",
      primarySuccessButton: {
        url: 'https://app.deform.cc/form/95f81161-9f00-4a20-99ae-d14793a1756e',
        text: 'Claim Face Patches',
      },
    },
  },
  'i-need-a-dollar': {
    slug: 'i-need-a-dollar',
    contractAddress: '0xB96a54CE28ea8E4463B1c8BBEDDD6792cACeCDE9',
    chainId: '8453',
    successModal: {
      title: "It's yours",
      primarySuccessButton: {
        url: 'https://thespot.wearehume.com/',
        text: 'Listen',
      },
    },
  },
  welcomeonchain: {
    slug: 'welcomeonchain',
    contractAddress: '0x6B033e8199ce2E924813568B716378aA440F4C67',
    chainId: '8453',
    successModal: {
      title: "It's yours",
      primarySuccessButton: {
        url: 'http://wallet.coinbase.com/ocs',
        text: 'Discover now',
      },
    },
  },
  CBWonchainsummer2024: {
    slug: 'CBWonchainsummer2024',
    contractAddress: '0xDb4d4e4f3203F100D72316396C63b60e555368d2',
    chainId: '8453',
  },
  meow: {
    slug: 'meow',
    contractAddress: '0x4CC257C9700DAa76c112e62bF476147F67cf0dEd',
    tokenId: '0',
    chainId: '8453',
    successModal: {
      title: "It's yours",
      primarySuccessButton: {
        url: 'https://wallet.coinbase.com/ocs',
        text: 'Explore Onchain Summer',
      },
    },
  },
  mistermiggles: {
    slug: 'mistermiggles',
    contractAddress: '0xDc03a75F96f38615B3eB55F0F289d36E7A706660',
    tokenId: '0',
    chainId: '8453',
    successModal: {
      title: "It's yours",
      primarySuccessButton: {
        url: 'https://wallet.coinbase.com/nft/gallery/mistermiggles',
        text: 'Remix your own Mr. Miggles',
      },
    },
  },
  onboardmints: {
    slug: 'onboardmints',
    contractAddress: '0x2b88A7cb3294C04E679eAd716be88a44cCa69b76',
    chainId: '8453',
    tokenId: '0',
    successModal: {
      title: "It's yours",
      primarySuccessButton: {
        url: 'https://tally.so/r/w4j8Kb',
        text: 'Claim your perks',
      },
    },
  },
  onboardmints1: {
    slug: 'onboardmints1',
    contractAddress: '0x2b88A7cb3294C04E679eAd716be88a44cCa69b76',
    chainId: '8453',
    tokenId: '1',
    successModal: {
      title: "It's yours",
      primarySuccessButton: {
        url: 'https://tally.so/r/w4j8Kb',
        text: 'Claim your perks',
      },
    },
  },
  onboardmints2: {
    slug: 'onboardmints2',
    contractAddress: '0x2b88A7cb3294C04E679eAd716be88a44cCa69b76',
    chainId: '8453',
    tokenId: '2',
    successModal: {
      title: "It's yours",
      primarySuccessButton: {
        url: 'https://tally.so/r/w4j8Kb',
        text: 'Claim your perks',
      },
    },
  },
  onboardmints3: {
    slug: 'onboardmints3',
    contractAddress: '0x2b88A7cb3294C04E679eAd716be88a44cCa69b76',
    chainId: '8453',
    tokenId: '3',
    successModal: {
      title: "It's yours",
      primarySuccessButton: {
        url: 'https://tally.so/r/w4j8Kb',
        text: 'Claim your perks',
      },
    },
  },
  onboardmints4: {
    slug: 'onboardmints4',
    contractAddress: '0x2b88A7cb3294C04E679eAd716be88a44cCa69b76',
    chainId: '8453',
    tokenId: '4',
    successModal: {
      title: "It's yours",
      primarySuccessButton: {
        url: 'https://tally.so/r/w4j8Kb',
        text: 'Claim your perks',
      },
    },
  },
  onboardmints5: {
    slug: 'onboardmints5',
    contractAddress: '0x2b88A7cb3294C04E679eAd716be88a44cCa69b76',
    chainId: '8453',
    tokenId: '5',
    successModal: {
      title: "It's yours",
      primarySuccessButton: {
        url: 'https://tally.so/r/w4j8Kb',
        text: 'Claim your perks',
      },
    },
  },
  onboardmints6: {
    slug: 'onboardmints6',
    contractAddress: '0x2b88A7cb3294C04E679eAd716be88a44cCa69b76',
    chainId: '8453',
    tokenId: '6',
    successModal: {
      title: "It's yours",
      primarySuccessButton: {
        url: 'https://tally.so/r/w4j8Kb',
        text: 'Claim your perks',
      },
    },
  },
  onboardmints7: {
    slug: 'onboardmints7',
    contractAddress: '0x2b88A7cb3294C04E679eAd716be88a44cCa69b76',
    chainId: '8453',
    tokenId: '7',
    successModal: {
      title: "It's yours",
      primarySuccessButton: {
        url: 'https://tally.so/r/w4j8Kb',
        text: 'Claim your perks',
      },
    },
  },
  onboardmints8: {
    slug: 'onboardmints8',
    contractAddress: '0x2b88A7cb3294C04E679eAd716be88a44cCa69b76',
    chainId: '8453',
    tokenId: '8',
    successModal: {
      title: "It's yours",
      primarySuccessButton: {
        url: 'https://tally.so/r/w4j8Kb',
        text: 'Claim your perks',
      },
    },
  },
  onboardmints9: {
    slug: 'onboardmints9',
    contractAddress: '0x2b88A7cb3294C04E679eAd716be88a44cCa69b76',
    chainId: '8453',
    tokenId: '9',
    successModal: {
      title: "It's yours",
      primarySuccessButton: {
        url: 'https://tally.so/r/w4j8Kb',
        text: 'Claim your perks',
      },
    },
  },
  onboardmints10: {
    slug: 'onboardmints10',
    contractAddress: '0x2b88A7cb3294C04E679eAd716be88a44cCa69b76',
    chainId: '8453',
    tokenId: '10',
    successModal: {
      title: "It's yours",
      primarySuccessButton: {
        url: 'https://tally.so/r/w4j8Kb',
        text: 'Claim your perks',
      },
    },
  },
  doodles: {
    slug: 'doodles',
    contractAddress: '0x76FEa18dcA768c27Afc3a32122c6b808C0aD9b06',
    chainId: '8453',
    tokenId: '0',
    crossMintConfig: {
      collectionID: '97bc2cbf-121c-4a0e-8874-cdb87bedc87e',
      contractType: 'Doodles',
    },
    successModal: {
      title: "It's yours",
      primarySuccessButton: {
        url: 'https://superpass.doodles.app',
        text: 'Learn more',
      },
    },
  },
  'coin-earningsq22024': {
    slug: 'coin-earningsq22024',
    contractAddress: '0x1D6b183bD47F914F9f1d3208EDCF8BefD7F84E63',
    chainId: '8453',
    tokenId: '2',
    successModal: {
      primarySuccessButton: {
        url: 'https://investor.coinbase.com/events-and-presentations/events/event-details/2024/Second-Quarter-2024-Earnings-Call/default.aspx',
        text: 'Learn More',
      },
    },
  },
  eurc: {
    slug: 'eurc',
    contractAddress: '0x615194d9695d0c02Fc30a897F8dA92E17403D61B',
    chainId: '8453',
    successModal: {
      primarySuccessButton: {
        url: 'https://wallet.coinbase.com/assets/crypto/ETH%2FEURC%2FETHEREUM_CHAIN%3A8453%2Ffalse%2F0x60a3e35cc302bfa44cb288bc5a4f316fdb1adb42?assetUUID=674c6343-41a8-4b79-8914-21762877e907&assetName=EURC',
        text: 'Buy EURC',
      },
    },
  },
  coffeedays: {
    slug: 'coffeedays',
    contractAddress: '0xf16755b43eE1a458161f0faE5a9124729f4f6B1B',
    chainId: '8453',
    crossMintConfig: {
      collectionID: '1b9417e1-0c81-4c7e-86a6-860ea636ae4c',
      contractType: 'TokenForge',
    },
    successModal: {
      primarySuccessButton: {
        url: 'https://wallet.coinbase.com/ocs/coffeedays',
        text: 'Learn More',
      },
    },
  },
  daylightenergy: {
    slug: 'daylightenergy',
    contractAddress: '0x31B81650997e26Eb527CA6541B1433d1EF348d93',
    chainId: '8453',
    successModal: {
      primarySuccessButton: {
        url: 'https://apps.apple.com/us/app/daylight-energy/id6450997694',
        text: 'Get the Daylight App',
      },
    },
  },
  swctour2024: {
    slug: 'swctour2024',
    contractAddress: '0x734696e3b6cBB64C8396F61d7Af7908836C83110',
    chainId: '8453',
    successModal: {
      primarySuccessButton: {
        url: 'https://www.standwithcrypto.org/events',
        text: 'RSVP',
      },
    },
  },
  fairies: {
    slug: 'fairies',
    contractAddress: '0x148b883feB1b04e9eda3Bb4417C2386C32f867f3',
    chainId: '8453',
    successModal: {
      primarySuccessButton: {
        url: 'https://hereandnow.events/fairies/',
        text: 'Enter the experience',
      },
    },
  },
  stix: {
    slug: 'stix',
    contractAddress: '0xa7891c87933BB99Db006b60D8Cb7cf68141B492f',
    chainId: '8453',
    successModal: {
      primarySuccessButton: {
        url: 'https://stix.lol/',
        text: 'Stay in the loop',
      },
    },
  },
  midnightdinerfall: {
    slug: 'midnightdinerfall',
    contractAddress: '0x8C5007eba415C0244902Da354E0aFEf7FF829Fbb',
    chainId: '8453',
    successModal: {
      primarySuccessButton: {
        url: 'https://www.cooprecords.xyz/',
        text: 'Meet our artists',
      },
    },
    crossMintConfig: {
      collectionID: '002da3f2-e939-4e8f-8226-bcfab6d3f15e',
      contractType: 'TokenForge',
    },
  },
};

export const OCS_BUTTON_COLOR = '#0052FF';

/**
 * Our indexer strips newlines from the description, so we need to hardcode this description for launch.
 */
export const OCS_DESCRIPTION =
  "Last year, we committed to bringing the world onchain\n\nNow, we're calling on creators and builders everywhere to shape the next era of the internet with us\n\nIt's time for Onchain Summer II";

/* eslint camelcase: 0 */

import { CB_API_URL } from 'cb-wallet-env/env';
import { getJSON } from 'cb-wallet-http/fetchJSON';
import { getClientType } from 'cb-wallet-metadata/metadata';

import {
  DEVICE_SUBJECT_TYPE,
  ExperimentsResponse,
  ReleaseEnvironment,
  WALLET_USER_SUBJECT_TYPE,
} from '../types';

type FetchWalletUserExperimentsArgs = {
  releaseEnvironment: ReleaseEnvironment;
  userId: string | undefined;
  version: string;
  markHasReceivedFirstAuthedExperimentsResult?: () => void;
};

export async function fetchWalletUserExperiments({
  userId,
  releaseEnvironment,
  version,
  markHasReceivedFirstAuthedExperimentsResult,
}: FetchWalletUserExperimentsArgs): Promise<ExperimentsResponse> {
  // The userId can be undefined, instead making the network call which will fail, we'll return an empty
  // ExperimentsResponse.
  if (!userId) {
    return { groups: [] };
  }

  const params = {
    subject_id: userId,
    client: {
      type: getClientType(),
      version,
    },
    subject_type: WALLET_USER_SUBJECT_TYPE,
    is_employee: releaseEnvironment !== 'production',
  };
  const encoded = Buffer.from(JSON.stringify(params), 'utf-8').toString('base64');
  const path = 'coinbase.experiment.ExperimentService/ListSplitTestGroups';

  return getJSON<ExperimentsResponse>(
    `${CB_API_URL}/api/v3/${path}`,
    { q: encoded },
    {
      isThirdParty: true,
      authenticated: false,
    },
  )
    .then(function HandleFetchExperimentResponse(resp: ExperimentsResponse) {
      if (userId) {
        markHasReceivedFirstAuthedExperimentsResult?.();
      }

      return resp;
    })
    .catch(function HandleFetchExperimentError(err: Error) {
      if (userId) {
        markHasReceivedFirstAuthedExperimentsResult?.();
      }

      throw err;
    });
}

type FetchDeviceExperimentsArgs = {
  releaseEnvironment: ReleaseEnvironment;
  deviceId: string | undefined;
  version: string;
};

export async function fetchDeviceExperiments({
  deviceId,
  releaseEnvironment,
  version,
}: FetchDeviceExperimentsArgs): Promise<ExperimentsResponse> {
  // The deviceId can be undefined, instead making the network call which will fail, we'll return an empty
  // ExperimentsResponse.
  if (!deviceId) {
    return { groups: [] };
  }

  const params = {
    subject_id: deviceId,
    client: {
      type: getClientType(),
      version,
    },
    subject_type: DEVICE_SUBJECT_TYPE,
    is_employee: releaseEnvironment !== 'production',
  };
  const encoded = Buffer.from(JSON.stringify(params), 'utf-8').toString('base64');
  const path = 'coinbase.experiment.ExperimentService/ListSplitTestGroups';

  return getJSON<ExperimentsResponse>(
    `${CB_API_URL}/api/v3/${path}`,
    { q: encoded },
    {
      isThirdParty: true,
      authenticated: false,
    },
  );
}

import {
  getHardhatSetBalance,
  getWeiBalance,
} from 'cb-wallet-data/chains/AccountBased/Ethereum/apis/EthereumRPC';
import { StoreKeys_hardhatNetwork } from 'cb-wallet-data/chains/AccountBased/Ethereum/config';
import { EthereumNetworkMap } from 'cb-wallet-data/chains/AccountBased/Ethereum/EthereumChain';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { NetworkError } from 'cb-wallet-data/stores/Networks/NetworkError';
import { Store } from 'cb-wallet-store/Store';
/**
 * Get balance for given address and network
 *
 * @param address Address used to fetch balance
 * @param network Blockchain network state
 *
 * @return Returns balance in atomic unit
 */
export async function getBalance(address: string, network: Network): Promise<bigint | undefined> {
  const ethereumChain = network.asChain();

  if (!ethereumChain) {
    throw NetworkError.invalidNetwork(network);
  }

  // TODO: Subtract pending transaction balances
  const weiBalance = await getWeiBalance(address, ethereumChain.chainId);

  // If user is on hardhat & has empty balance, set balance
  if (
    Store.get(StoreKeys_hardhatNetwork) &&
    ethereumChain.chainId === EthereumNetworkMap.whitelisted.LOCALHOST_8545.chainId &&
    weiBalance < 1n
  ) {
    getHardhatSetBalance(address);
  }
  return weiBalance;
}

import { useMemo } from 'react';
import { useOverridableKillSwitch } from 'cb-wallet-data/hooks/KillSwitches/useOverridableKillSwitch';
import { getKillSwitchByPlatform } from 'cb-wallet-data/hooks/KillSwitches/utils/getKillSwitchByPlatform';
import { useOnMount } from 'cb-wallet-data/hooks/useOnMount';
import { isSpamScoreEnabledAtom } from 'cb-wallet-data/stores/Wallets/state';
import { useSetRecoilState } from 'recoil';

export function useIsSpamScoreEnabled(): boolean {
  const isSpamScoreKilled = useOverridableKillSwitch(getKillSwitchByPlatform('kill_spam_score'));
  const setIsSpamScoreEnabledAtom = useSetRecoilState(isSpamScoreEnabledAtom);

  const isSpamScoreEnabled = useMemo(() => {
    return !isSpamScoreKilled;
  }, [isSpamScoreKilled]);

  useOnMount(() => {
    setIsSpamScoreEnabledAtom(isSpamScoreEnabled);
  });

  return isSpamScoreEnabled;
}

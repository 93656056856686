import { getJSON } from 'cb-wallet-http/fetchJSON';

/**
 * The raw data for 1559 adjustable miner fees, prior to any calculations
 * being called on it (i.e. fiat fee calculated based on a conversion rate)
 * all values are stored in Wei
 *
 * This model is used by the API to communicate with the Repository
 */

export type AdjustableMinerFee1559Result = {
  readonly fastPriorityFee: number;
  readonly normalPriorityFee: number;
  readonly slowPriorityFee: number;
  readonly baseFee: number;
  readonly slowMaxFeePerGas: number;
  readonly normalMaxFeePerGas: number;
  readonly fastMaxFeePerGas: number;
  readonly error: undefined;
};

export type AdjustableMinerFee1559Error = {
  readonly fastPriorityFee: undefined;
  readonly normalPriorityFee: undefined;
  readonly slowPriorityFee: undefined;
  readonly baseFee: undefined;
  readonly slowMaxFeePerGas: undefined;
  readonly normalMaxFeePerGas: undefined;
  readonly fastMaxFeePerGas: undefined;
  readonly error: Error;
};

export type AdjustableMinerFee1559Info = {
  maxFeePerGas: bigint;
  maxPriorityFeePerGas: bigint;
  baseFeePerGas: bigint;
  fiatRangeString: string | undefined;
  cryptoRangeString: string | undefined;
};

export const GAS_PRICES_1559_ESTIMATES_KEY = 'get1559GasPrices';

/**
 * Default fee estimates if the API call to fetch 1559 gas fee estimates fails.
 * Note that transactions should not be used with these values, as they are
 * extremely low and can potentially cause transactions to be stuck.
 */
export const DEFAULT_1559_MINER_FEE_ESTIMATES = {
  baseFee: undefined,
  fastPriorityFee: undefined,
  normalPriorityFee: undefined,
  slowPriorityFee: undefined,
  slowMaxFeePerGas: undefined,
  normalMaxFeePerGas: undefined,
  fastMaxFeePerGas: undefined,
};

type Props = {
  chainId: number;
};

/**
 * HTTP Request for fetching miner fees + associated data relevant for 1559 transactions
 * baseFee is actually baseFeePerGas
 *
 * @param chainId
 * @return A single wrapping 1559 miner fees
 */
export async function get1559MinerFeeEstimates({
  chainId,
}: Props): Promise<AdjustableMinerFee1559Result | AdjustableMinerFee1559Error> {
  const id = chainId.toString();

  try {
    const { result } = await getJSON<{ result: AdjustableMinerFee1559Result }>(
      GAS_PRICES_1559_ESTIMATES_KEY,
      {
        chainId: id,
      },
    );
    return result;
  } catch (e) {
    return Promise.resolve({
      ...DEFAULT_1559_MINER_FEE_ESTIMATES,
      error: e as Error,
    });
  }
}

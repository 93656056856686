// Note: this file is copied from
// https://github.com/wevm/wagmi/blob/main/packages/core/src/actions/connect.ts
// We're overriding the connector.connect function to pass in additional options

import type {
  Config,
  Connector,
  ConnectorAlreadyConnectedErrorType,
  CreateConnectorFn,
} from '@wagmi/core';
import { ConnectorAlreadyConnectedError } from '@wagmi/core';
import type { BaseErrorType, ErrorType } from '@wagmi/core/dist/types/errors/base';
import type { ChainIdParameter } from '@wagmi/core/dist/types/types/properties';
import isEmpty from 'lodash/isEmpty';
import type { Address, ResourceUnavailableRpcErrorType, UserRejectedRequestErrorType } from 'viem';

export type Compute<type> = { [key in keyof type]: type[key] } & unknown;

export type ConnectParameters<config extends Config = Config> = Compute<
  ChainIdParameter<config> & {
    connector: Connector | CreateConnectorFn;
  } & {
    options?: Record<string, unknown>;
  }
>;

export type ConnectReturnType<config extends Config = Config> = {
  accounts: readonly [Address, ...Address[]];
  chainId:
    | config['chains'][number]['id']
    // eslint-disable-next-line @typescript-eslint/ban-types
    | (number extends config['chains'][number]['id'] ? number : number & {});
};

export type ConnectErrorType =
  | ConnectorAlreadyConnectedErrorType
  // connector.connect()
  | UserRejectedRequestErrorType
  | ResourceUnavailableRpcErrorType
  // base
  | BaseErrorType
  | ErrorType;

/** https://wagmi.sh/core/api/actions/connect */
export async function connect<C extends Config>(
  config: C,
  parameters: ConnectParameters<C>,
): Promise<ConnectReturnType<C>> {
  // "Register" connector if not already created
  let connector: Connector;
  if (typeof parameters.connector === 'function') {
    connector = config._internal.connectors.setup(parameters.connector);
  } else {
    connector = parameters.connector;
  }

  // Check if connector is already connected
  if (connector.uid === config.state.current) {
    throw new ConnectorAlreadyConnectedError();
  }

  try {
    config.setState((x) => ({ ...x, status: 'connecting' }));
    connector.emitter.emit('message', { type: 'connecting' });

    const options = !isEmpty(parameters.options) ? parameters.options : {};
    // We're overriding the connect function here to take additional params.
    // Default wagmi connectors will ignore the extra params while CBW Smart Wallet can use
    // them to allow the dapp to pass flags to SCW. Currently, only scwOnboardMode is supported
    // see: workspaces/apps/dapp/src/connection/coinbase-wallet/connectors/createCoinbaseWalletConnectorFn.ts
    const data = await connector.connect({ chainId: parameters.chainId, ...options });
    const accounts = data.accounts as readonly [Address, ...Address[]];

    connector.emitter.off('connect', config._internal.events.connect);
    connector.emitter.on('change', config._internal.events.change);
    connector.emitter.on('disconnect', config._internal.events.disconnect);

    await config.storage?.setItem('recentConnectorId', connector.id);
    config.setState((x) => ({
      ...x,
      connections: new Map(x.connections).set(connector.uid, {
        accounts,
        chainId: data.chainId,
        connector,
      }),
      current: connector.uid,
      status: 'connected',
    }));

    return { accounts, chainId: data.chainId };
  } catch (error) {
    config.setState((x) => ({
      ...x,
      // Keep existing connector connected in case of error
      status: x.current ? 'connected' : 'disconnected',
    }));
    throw error;
  }
}

import { galleryPageConfig, GalleryPageSlug } from './galleryPageConstants';
import { getGalleryBySlug } from './getGalleryBySlug';

export async function getGalleryMetaData(
  _routeName: string,
  _query: NodeJS.Dict<string | string[]>,
) {
  const slug = _query?.galleryId as GalleryPageSlug;

  const galleryBySlugResult = await getGalleryBySlug(slug);

  const metaData = [
    {
      property: 'og:title',
      content: galleryBySlugResult?.gallery?.name ?? '',
    },
    {
      property: 'og:description',
      content: galleryBySlugResult?.gallery?.description ?? '',
    },
    // Twitter
    { property: 'twitter:title', content: galleryBySlugResult?.gallery?.name ?? '' },
    {
      property: 'twitter:description',
      content: galleryBySlugResult?.gallery?.description ?? '',
    },
  ];

  // Prefer we source media + links from our backend, however, we have a static config which we used for launch.  We
  // should maintain backward compatibility until we have the time to update/fix these collections.
  // TODO: WALL-39803
  const media =
    galleryBySlugResult?.gallery?.media?.mediaUri || galleryPageConfig[slug]?.metadata?.imageUrl;
  const mediaType =
    galleryBySlugResult?.gallery?.media?.mediaUriType ||
    galleryPageConfig[slug]?.metadata?.imageType;

  const metadataBySlug = [
    {
      property: 'og:image',
      content: media ?? 'https://go.wallet.coinbase.com/static/pano_og_generic.png',
    },
    {
      property: 'og:image:type',
      content: mediaType ?? 'image/png',
    },
    // Twitter
    {
      property: 'twitter:image',
      content: media ?? 'https://go.wallet.coinbase.com/static/pano_og_generic.png',
    },
  ];
  return [...metaData, ...metadataBySlug];
}

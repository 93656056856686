import { useMutation } from '@tanstack/react-query';

import { OnrampCheckoutSession } from '../models/OnrampCheckoutSession';

import { useOnrampAuthedPost } from './useOnrampAuthedPost';

const QUERY_KEY = 'payment-providers/v1/checkoutSession';

export type Params = {
  assetCode: string;
  networkId: string;
  providerId: string;
  fiatAmount: string;
  aggregatorId: string;
  contractAddress: string;
  paymentMethodId: string;
  paymentMethodUuid: string;
  receiveAddress?: string;
  fiatCode: string;
  countryCode?: string;
  subdivisionCode?: string;
};

export type OnrampCheckoutSessionResponse = {
  reset: () => void;
  error: Error | null;
  getCheckoutSessionAsync: (
    quoteId: string | undefined,
  ) => Promise<OnrampCheckoutSession | undefined>;
  checkoutSession: OnrampCheckoutSession | undefined;
  isLoading: boolean;
};

export function useOnrampCheckoutSession(params: Params): OnrampCheckoutSessionResponse {
  const fetchOnrampCheckoutSession = useOnrampAuthedPost<OnrampCheckoutSession>('checkoutSession');

  const {
    error,
    reset,
    mutateAsync,
    data: checkoutSession,
    isLoading,
  } = useMutation<OnrampCheckoutSession | undefined, Error, string | undefined>(
    [QUERY_KEY, ...Object.values(params)],
    async function getOnrampCheckoutSession(quoteId: string | undefined) {
      return fetchOnrampCheckoutSession({
        quoteId,
        redirectUrl: 'cbwallet://buy',
        ...params,
      });
    },
  );

  return {
    isLoading,
    checkoutSession,
    error,
    reset,
    getCheckoutSessionAsync: mutateAsync,
  };
}

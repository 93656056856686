import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { TxOrUserOp } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import { TxHistoryVersion } from 'cb-wallet-data/stores/Transactions/TxHistoryVersion';
import { postJSON } from 'cb-wallet-http/fetchJSON';
import {
  V1GetHistoryForAddressRequest,
  V1GetHistoryForAddressResult,
} from '@cbhq/instant-api-hooks-wallet-tx-history/types';

import { GET_ALL_TXS_KEY, GetAllTxsError, GetAllTxsResult } from '../api';
import { parseHRTTransaction } from '../methods/parseHRTTransaction';
import { parseTransaction } from '../methods/parseTransaction';

export abstract class TxHistoryParser<ParseMetadataType> {
  abstract getParsedTxHistory(
    params: V1GetHistoryForAddressRequest,
    metadata: ParseMetadataType,
  ): Promise<{ parsedTxns: TxOrUserOp[]; nextPaginationToken: string }>;
}

export type TxHistoryParseMetadata = {
  blockchain: Blockchain;
  network: Network;
  accountId: Account['id'];
  walletIndex: bigint;
};

class TxHistoryV2 extends TxHistoryParser<TxHistoryParseMetadata> {
  async getParsedTxHistory(
    params: V1GetHistoryForAddressRequest,
    { blockchain, network, accountId, walletIndex }: TxHistoryParseMetadata,
  ): Promise<{ parsedTxns: TxOrUserOp[]; nextPaginationToken: string }> {
    const { result: txHistoryResult, error } = await postJSON<{
      result: GetAllTxsResult;
      error: GetAllTxsError;
    }>(GET_ALL_TXS_KEY, params, {
      apiVersion: 2,
    });

    if (error) {
      throw Error(error.message);
    }

    const { spam_score_thresholds: spamScoreThresholds } = txHistoryResult;
    const parsedTxs =
      txHistoryResult.transactions &&
      (
        await Promise.all(
          txHistoryResult.transactions?.map(async (txDTO) =>
            parseTransaction({
              tx: txDTO,
              blockchain,
              network,
              accountId,
              walletIndex,
              spamScoreThresholds,
            }),
          ),
        )
      ).filter((tx): tx is TxOrUserOp => !!tx);

    return {
      parsedTxns: parsedTxs ?? [],
      nextPaginationToken: txHistoryResult.paginationToken,
    };
  }
}

class TxHistoryV3 extends TxHistoryParser<TxHistoryParseMetadata> {
  async getParsedTxHistory(
    params: V1GetHistoryForAddressRequest,
    { blockchain, network, accountId, walletIndex }: TxHistoryParseMetadata,
  ): Promise<{ parsedTxns: TxOrUserOp[]; nextPaginationToken: string }> {
    const { result: txHistoryResult, error } = await postJSON<{
      result: V1GetHistoryForAddressResult;
      error: GetAllTxsError;
    }>(GET_ALL_TXS_KEY, params, {
      apiVersion: 3,
    });

    if (error) {
      throw Error(error.message);
    }

    const spamScoreThresholds = txHistoryResult.spamScoreThresholds;

    const parsedTxs =
      txHistoryResult.transactions &&
      (
        await Promise.all(
          txHistoryResult.transactions?.map(async (txDTO) =>
            parseHRTTransaction({
              tx: txDTO,
              userAddress: params.address,
              blockchain,
              network,
              accountId,
              walletIndex,
              spamScoreThresholds,
              addressMetadata: txHistoryResult.addressMeta || {},
            }),
          ),
        )
      )
        .flat()
        .filter((tx): tx is TxOrUserOp => !!tx);

    return {
      parsedTxns: parsedTxs ?? [],
      nextPaginationToken: txHistoryResult.paginationToken ?? '',
    };
  }
}

type PossibleTxnHistoryParser<V> = V extends 'v2'
  ? TxHistoryV2
  : V extends 'v3'
  ? TxHistoryV3
  : never;

type TxHistoryParserMap = {
  [K in TxHistoryVersion]: PossibleTxnHistoryParser<K>;
};

const txHistoryParsers: TxHistoryParserMap = {
  v2: new TxHistoryV2(),
  v3: new TxHistoryV3(),
};

export function getTxHistoryParser<V extends TxHistoryVersion>(
  apiVersion: V,
): TxHistoryParserMap[V] {
  return txHistoryParsers[apiVersion];
}

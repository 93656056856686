import { ActionType, ComponentType } from '@cbhq/client-analytics';

import { logDataEvent, logEvent, useLogEventOnMount } from '../utils/log';

export function triggerCookiePolicyButtonPressed() {
  logEvent('cookie_policy_button.pressed', {
    componentType: ComponentType.button,
    action: ActionType.click,
  });
}

export function triggerPrivacyPolicyButtonPressed() {
  logEvent('privacy_policy_button.pressed', {
    componentType: ComponentType.button,
    action: ActionType.click,
  });
}

export function useCookieBannerViewed() {
  useLogEventOnMount('cookie_banner.viewed', {
    action: ActionType.view,
    componentType: ComponentType.banner,
  });
}

export function triggerCookieBannerDismissPressed() {
  logEvent('cookie_banner_dismiss.pressed', {
    componentType: ComponentType.button,
    action: ActionType.click,
  });
}

export function triggerCookieManagerLoaded() {
  logDataEvent('cookie_manager_loaded', {});
}

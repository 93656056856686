import { useCallback } from 'react';
import { COINBASE_API_ROOT } from 'cb-wallet-data/HTTP/api';
import { convertKeysToCamelCase } from 'cb-wallet-data/HTTP/utils';
import { getJSON } from 'cb-wallet-http/fetchJSON';

import { useOnrampAuthedGet } from '../Buy/hooks/useOnrampAuthedGet';

const API_PATH = `${COINBASE_API_ROOT}/v2/currencies`;

export type CurrencyResponse = {
  id: string;
  name: string;
  minSize: string;
};

export async function getCurrencies(): Promise<CurrencyResponse[] | undefined> {
  const { data } = await getJSON<{ data: CurrencyResponse[] }>(
    API_PATH,
    {},
    { isThirdParty: true, skipBlockingHeaders: true },
  );
  return convertKeysToCamelCase(data);
}

export type GetFiatCodeParams = {
  countryCode: string;
};

export type FiatCodeResponse = {
  fiatCode: string;
  name: string;
  decimals: number;
};

export function useFetchFiatCode() {
  const fetchFiatCode = useOnrampAuthedGet<FiatCodeResponse>('fiatCode');
  return useCallback(
    async function getFiatCodeFn({ countryCode }: GetFiatCodeParams) {
      return fetchFiatCode({ countryCode, isPopular: 'true' });
    },
    [fetchFiatCode],
  );
}

const CLOUDINARY_HOSTNAME = 'res.cloudinary.com';
const COINBASE_CLOUDINARY_PATHNAME_PREFIX = '/coin-nft/';

export type CloudinaryMediaUrl =
  | `https://res.cloudinary.com${string}`
  | `data:image/png;base64${string}`;

export function isCloudinaryMediaUrl(url: string): url is CloudinaryMediaUrl {
  try {
    if (url.startsWith('data:image/png;base64')) {
      return true;
    }
    const urlObj = new URL(url);
    return (
      urlObj.hostname === CLOUDINARY_HOSTNAME &&
      urlObj.pathname.startsWith(COINBASE_CLOUDINARY_PATHNAME_PREFIX)
    );
  } catch {
    return false;
  }
}

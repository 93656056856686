import { logHRTTxnMissingDecimals } from 'cb-wallet-analytics/transactions';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { BASE_ASSET_ADDRESS } from 'cb-wallet-data/stores/Transactions/methods/parsers/HRT/constants';
import { ParseParams } from 'cb-wallet-data/stores/Transactions/methods/parsers/HRT/parseParams';
import {
  apiv1LabelToPrimaryAction,
  buildHRTMetadata,
  hasMissingDecimals,
  isSend,
  isSpam,
  primaryActionToType,
} from 'cb-wallet-data/stores/Transactions/methods/parsers/utils/utils';
import { TxOrUserOp, TxOrUserOpParams } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import { TxState } from 'cb-wallet-data/stores/Transactions/models/TxState';
import { unixTimestampToDate } from 'cb-wallet-data/stores/Transactions/utils/unixTimestampToDate';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { v4 as uuidv4 } from 'uuid';
import { Apiv1Label, V1TransactionStatus } from '@cbhq/instant-api-hooks-wallet-tx-history/types';

export function parseSwap({
  primaryAction,
  historicalAssetPricesUsd,
  feeAmount,
  txHash,
  txNonce,
  txTimestamp,
  txStatus,
  userOpHash,
  userAddress,
  blockchain,
  network,
  accountId,
  walletIndex,
  spamScoreThresholds,
  addressMetadata,
  transfers,
}: ParseParams): TxOrUserOp | undefined {
  const fromAssetContractAddress = primaryAction?.swapDetails?.fromAssetAddress || '';
  const toAssetContractAddress = primaryAction?.swapDetails?.toAssetAddress || '';
  const dexFeeAssetContractAddress = primaryAction?.swapDetails?.feeAssetAddress || '';
  const coinbaseFeeAssetContractAddress = primaryAction?.swapDetails?.coinbaseFeeAssetAddress || '';

  const nativeAssetMetadata = addressMetadata[BASE_ASSET_ADDRESS]?.token;
  const fromAssetMetadata = addressMetadata[fromAssetContractAddress];
  const toAssetMetadata = addressMetadata[toAssetContractAddress];
  const dexFeeAssetMetadata = addressMetadata[dexFeeAssetContractAddress];
  const coinbaseFeeAssetMetadata = addressMetadata[coinbaseFeeAssetContractAddress];

  if (
    hasMissingDecimals([
      addressMetadata[BASE_ASSET_ADDRESS],
      fromAssetMetadata,
      toAssetMetadata,
      dexFeeAssetMetadata,
      coinbaseFeeAssetMetadata,
    ])
  ) {
    logHRTTxnMissingDecimals({
      primaryAction: primaryAction?.type || '',
      blockchain: blockchain.rawValue,
      network: network.rawValue,
    });
    return undefined;
  }

  const isFromAssetAnNFT = Boolean(fromAssetMetadata?.nftCollection);
  const isToAssetAnNFT = Boolean(toAssetMetadata?.nftCollection);

  const fromAmount = primaryAction?.swapDetails?.fromAmount;
  const toAmount = primaryAction?.swapDetails?.toAmount;
  const dexFeeAmount = primaryAction?.swapDetails?.feeAmount;
  const coinbaseFeeAmount = primaryAction?.swapDetails?.coinbaseFeeAmount;

  const toTokenName = isToAssetAnNFT
    ? toAssetMetadata?.nftCollection?.collectionName || ''
    : toAssetMetadata?.token?.name || '';
  const fromTokenName = isFromAssetAnNFT
    ? fromAssetMetadata?.nftCollection?.collectionName || ''
    : fromAssetMetadata?.token?.name || '';
  const dexFeeName = dexFeeAssetMetadata?.token?.name || '';
  const coinbaseFeeName = coinbaseFeeAssetMetadata?.token?.name || '';

  const toTokenDecimal = isToAssetAnNFT ? BigInt(0) : BigInt(toAssetMetadata?.token?.decimals || 0);
  const fromTokenDecimal = isFromAssetAnNFT
    ? BigInt(0)
    : BigInt(fromAssetMetadata?.token?.decimals || 0);
  const dexFeeDecimal = BigInt(dexFeeAssetMetadata?.token?.decimals || 0);
  const coinbaseFeeDecimal = BigInt(coinbaseFeeAssetMetadata?.token?.decimals || 0);

  const toAssetHistoricalPrice = historicalAssetPricesUsd?.[toAssetContractAddress];
  const fromAssetHistoricalPrice = historicalAssetPricesUsd?.[fromAssetContractAddress];
  const nativeAssetHistoricalPrice = historicalAssetPricesUsd?.[BASE_ASSET_ADDRESS];

  const nativeAssetCurrenyCode = new CurrencyCode(
    nativeAssetMetadata?.symbol || blockchain?.rawValue,
  );
  const fromAssetCurrencyCode = isFromAssetAnNFT
    ? new CurrencyCode('NFT')
    : new CurrencyCode(fromAssetMetadata?.token?.symbol || '');
  const toAssetCurrencyCode = isToAssetAnNFT
    ? new CurrencyCode('NFT')
    : new CurrencyCode(toAssetMetadata?.token?.symbol || '');
  const dexFeeCurrencyCode = new CurrencyCode(dexFeeAssetMetadata?.token?.symbol || '');
  const coinbaseFeeCurrencyCode = new CurrencyCode(coinbaseFeeAssetMetadata?.token?.symbol || '');

  const fromAssetItems = fromAssetMetadata?.nftCollection?.items;
  const [firstFromTokenId, firstFromNFT] = fromAssetItems ? Object.entries(fromAssetItems)[0] : [];
  const toAssetItems = toAssetMetadata?.nftCollection?.items;
  const [firstToTokenId, firstToNFT] = toAssetItems ? Object.entries(toAssetItems)[0] : [];

  const fromAssetImage = isFromAssetAnNFT
    ? firstFromNFT?.imageUrl
    : fromAssetMetadata?.token?.logo?.url;
  const toAssetImage = isToAssetAnNFT ? firstToNFT?.imageUrl : toAssetMetadata?.token?.logo?.url;

  const toProfileImage = addressMetadata[userAddress]?.avatarUrl;
  const fromProfileImage = addressMetadata[userAddress]?.avatarUrl;

  const fromDomain = addressMetadata[userAddress]?.domain;

  const date = unixTimestampToDate(txTimestamp || new Date().getTime().toString());
  const nonce = BigInt(txNonce || 0);

  const feeCurrencyDecimal = BigInt(nativeAssetMetadata?.decimals || 0);
  const fee = BigInt(feeAmount || 0);
  const feeCurrencyCode = nativeAssetCurrenyCode;

  const fromWalletId = Wallet.generateId({
    blockchain,
    currencyCode: fromAssetCurrencyCode,
    network,
    contractAddress:
      fromAssetContractAddress === BASE_ASSET_ADDRESS ? undefined : fromAssetContractAddress,
    selectedIndex: walletIndex,
    accountId,
  });

  const toWalletId = Wallet.generateId({
    blockchain,
    currencyCode: toAssetCurrencyCode,
    network,
    contractAddress:
      toAssetContractAddress === BASE_ASSET_ADDRESS ? undefined : toAssetContractAddress,
    selectedIndex: walletIndex,
    accountId,
  });

  const txMetadata = buildHRTMetadata(
    [fromAssetMetadata, toAssetMetadata],
    primaryAction?.metadata,
  );

  return new TxOrUserOp({
    id: uuidv4(),
    createdAt: date,
    confirmedAt: date,
    blockchain,
    currencyCode: fromAssetCurrencyCode,
    feeCurrencyCode,
    feeCurrencyDecimal,
    toAddress: userAddress,
    toDomain: undefined,
    fromAddress: userAddress,
    fromDomain,
    amount: BigInt(fromAmount || 0),
    fee,
    state:
      txStatus === V1TransactionStatus.TRANSACTION_STATUS_SUCCESS
        ? TxState.CONFIRMED
        : TxState.FAILED,
    metadata: txMetadata,
    network,
    txOrUserOpHash: userOpHash ?? txHash,
    userOpHash,
    accountId,
    walletIndex,
    txHash,
    isSent: isSend(primaryAction?.type || Apiv1Label.LABEL_UNKNOWN),
    contractAddress:
      fromAssetContractAddress === BASE_ASSET_ADDRESS ? undefined : fromAssetContractAddress,
    tokenName: fromTokenName,
    tokenDecimal: fromTokenDecimal,
    walletId: fromWalletId,
    nonce,
    type: primaryActionToType({ primaryActionLabel: primaryAction?.type, txMetadata }),
    transfers,
    isSpam:
      isSpam(spamScoreThresholds, [
        fromAssetMetadata?.token?.spamScore || 0,
        toAssetMetadata?.token?.spamScore || 0,
      ]) ||
      Boolean(fromAssetMetadata?.nftCollection?.isSpam) ||
      Boolean(toAssetMetadata?.nftCollection?.isSpam),
    primaryAction: apiv1LabelToPrimaryAction(primaryAction?.type),
    toAssetHistoricalPrice,
    fromAssetHistoricalPrice,
    nativeAssetHistoricalPrice,
    fromAssetImage,
    toAssetImage,
    fromProfileImage,
    toProfileImage,
    fromAmount,
    toAmount,
    isContractExecution: true,
    toNetwork: undefined,
    toCurrencyCode: toAssetCurrencyCode,
    toContractAddress:
      toAssetContractAddress === BASE_ASSET_ADDRESS ? undefined : toAssetContractAddress,
    toTokenName,
    toTokenDecimal,
    coinbaseFeeAmount,
    coinbaseFeeDecimal,
    coinbaseFeeAssetAddress: coinbaseFeeAssetContractAddress,
    coinbaseFeeCurrencyCode,
    coinbaseFeeName,
    protocolFeeAmount: dexFeeAmount,
    protocolFeeDecimal: dexFeeDecimal,
    protocolFeeCurrencyCode: dexFeeCurrencyCode,
    protocolFeeName: dexFeeName,
    toWalletId,
    source: 'V3',
    fromTokenId: firstFromTokenId,
    toTokenId: firstToTokenId,
  } as TxOrUserOpParams);
}

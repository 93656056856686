import { ETHEREUM_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { SOLANA_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Solana/constants';
import { getConfig } from 'cb-wallet-data/scw/libs/wagmi/config';

import { isSolanaConnector } from './utils/isSolanaConnector';
import * as ethereum from './ethereum';
import * as solana from './solana';
import {
  WalletConnectionErrorType,
  WalletConnectorConnectResponse,
  WalletProviderNetwork,
} from './types';
import { WalletConnectorManager } from './WalletConnectorManager';

export class CoinbaseWalletConnectorManager extends WalletConnectorManager {
  get hasCoinbaseWalletDappProvider() {
    if (typeof window === 'undefined') return false;
    return !!window.coinbaseWallet?.dappProvider;
  }

  connectETHAddresses(addresses: string[]) {
    if (!addresses?.length) {
      return;
    }

    if (!window.coinbaseWalletExtension) {
      return;
    }

    // @ts-expect-error coinbaseWalletExtension not defined on window object
    window.coinbaseWalletExtension._setAddresses(addresses);

    const ethConnector = this.getConnector(WalletProviderNetwork.Ethereum);
    if (ethereum.isValidConnector(ethConnector)) {
      // Setup wagmi state to know that coinbase wallet is connected
      getConfig().setState((x) => ({
        ...x,
        connections: new Map(x.connections).set(ethConnector.uid, {
          accounts: addresses as [`0x${string}`, ...`0x${string}`[]],
          chainId: 1,
          connector: ethConnector,
        }),
        current: ethConnector.uid,
        status: 'connected',
      }));
    }
  }

  async connectSOLAddresses(addresses: string[]) {
    if (!addresses?.length) {
      return;
    }

    // @ts-expect-error coinbaseSolana not defined on window object
    if (!window.coinbaseSolana) {
      return;
    }

    // @ts-expect-error coinbaseSolana not defined on window object
    window.coinbaseSolana._setAddresses(addresses);

    // @ts-expect-error coinbaseSolana not defined on window object
    window.coinbaseSolana.isConnected = true;

    // Hack to ensure Solana is connected - It would be better to handle this
    // from within the dapp provider. Though this is not possible
    // since the dapp provider doesn't have access to the solana adapter
    // which lives with in panorama
    const solConnector = this.getConnector(WalletProviderNetwork.Solana);
    if (isSolanaConnector(solConnector)) {
      await solana.connect(this.id, solConnector);
    }
  }

  async connect(network?: WalletProviderNetwork): Promise<WalletConnectorConnectResponse> {
    if (this.hasCoinbaseWalletDappProvider) {
      try {
        const result = await window.coinbaseWallet.dappProvider.connect();

        this.connectETHAddresses(result?.account?.[ETHEREUM_SYMBOL] ?? []);

        await this.connectSOLAddresses(result?.account?.[SOLANA_SYMBOL] ?? []);

        // Set the response provider to match the connector manager id
        result.provider = this.id;
        return { result };
      } catch (error) {
        if (typeof error === 'object' && error !== null && 'code' in error) {
          if (error.code === 4001) {
            return {
              error: WalletConnectionErrorType.USER_REJECTED,
            };
          }
        }
        return {
          error: WalletConnectionErrorType.UNKNOWN,
        };
      }
    }
    if (!network) {
      throw new Error('network is required to use fallback');
    }
    return super.connect(network);
  }

  async disconnect(network?: WalletProviderNetwork) {
    if (this.hasCoinbaseWalletDappProvider) {
      await window.coinbaseWallet.dappProvider.disconnect();
    }
    if (!network) {
      throw new Error('network is required to use fallback');
    }
    // WalletConnectorManager doesn't support disconnecting from btc, doge, or ltc
    if (
      [
        WalletProviderNetwork.Bitcoin,
        WalletProviderNetwork.Dogecoin,
        WalletProviderNetwork.Litecoin,
      ].includes(network)
    ) {
      return false;
    }
    return super.disconnect(network);
  }

  async isReady(network?: WalletProviderNetwork) {
    if (this.hasCoinbaseWalletDappProvider) {
      return this.hasCoinbaseWalletDappProvider;
    }
    if (!network) {
      throw new Error('network is required to use fallback');
    }
    return super.isReady(network);
  }
}

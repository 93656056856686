import { useMemo } from 'react';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';

import { useIsStakeEligible } from './useIsStakeEligible';

export function useIsDelegatorStakingEnabled() {
  const isStakeEligible = useIsStakeEligible();
  const isDelegatorStakingEnabled = useIsFeatureEnabled('delegator_staking');

  return useMemo(
    () => isDelegatorStakingEnabled && isStakeEligible,
    [isDelegatorStakingEnabled, isStakeEligible],
  );
}

/**
 * Represents sponsored transaction state
 */
export enum GaslessTxState {
  /**
   * In a transaction, mined in a block. Could still be reorged or reverted,
   * so make sure to check the block height of the transaction is sufficiently final
   */
  SUCCEEDED = 'succeeded',

  /** Submitted to the blockchain. Not yet mined */
  SUBMITTED = 'submitted',

  /** Confirmed for multiple blocks */
  CONFIRMED = 'confirmed',

  /** un-retryable failure */
  FAILED = 'failed',
}

import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Connect to Coinbase',
    description: 'The connect to coinbase setting category',
  },
  connectScreenTitle: {
    defaultMessage: 'Buy or transfer crypto',
    description:
      'Title for Onramp CBPay Interstitial Screen where user can connect Coinbase account',
  },
  connectedTitle: {
    defaultMessage: 'Coinbase account',
    description: 'Title for cell that lets user view the connection to their coinbase account',
  },
  connectedScreenTitle: {
    defaultMessage: "You're currently connected",
    description:
      'Title for body of screen where user can see which Coinbase account they have connected',
  },
  description: {
    defaultMessage: 'Buy or transfer from Coinbase',
    description: 'The connect to coinbase list cell description',
  },
  body: {
    defaultMessage: 'You can use your Coinbase account to fund your wallet.',
    description: 'Body on screen where user can connect Coinbase account',
  },
  connectedDescription: {
    defaultMessage: 'Connected',
    description: 'Text that tells user their Coinbase account is connected',
  },
  addCryptoButton: {
    defaultMessage: 'Add crypto with Coinbase Onramp',
    description: 'Text in button that lets users add crypto using Coinbase Onramp',
  },
  signUpButton: {
    defaultMessage: 'Sign up for Coinbase',
    description: 'Button text for signing up for Coinbase',
  },
  completeSignUpButton: {
    defaultMessage: 'Complete sign up for Coinbase',
    description: 'Button text for complete signing up for Coinbase',
  },
  connectButton: {
    defaultMessage: 'Buy or transfer with Coinbase',
    description: 'Button text for connecting Coinbase account',
  },
  disconnectButton: {
    defaultMessage: 'Disconnect',
    description: 'Description for the disconnect button',
  },
  closeButton: {
    defaultMessage: 'Close account',
    description: 'Description for the close account button',
  },
  // Japan specific messages
  connectScreenTitleJapan: {
    defaultMessage: 'Transfer Crypto',
    description: 'Title for screen where Japanese user can connect Coinbase account',
  },
  bodyJapan: {
    defaultMessage:
      'Coinbase Wallet will now access your Coinbase.com account so you can select an asset or transfer.',
    description: 'Body on Coinbase connect account screen only shown to users in Japan',
  },
  connectButtonJapan: {
    defaultMessage: 'Continue',
    description: 'Button to continue to transfer from Coinbase. Only shown to users in Japan.',
  },
  cancelButtonJapan: {
    defaultMessage: 'Cancel',
    description: 'Cancel button for users in Japan',
  },
  // Guest checkout specific messages
  bodyGuestCheckout: {
    defaultMessage:
      "You can use your Coinbase account to fund your wallet. If you don't have a Coinbase account, you can also buy $500 weekly using a debit card.",
    description:
      'Body on screen where user can connect Coinbase account and guest checkout is not available',
  },
  continueAsGuestButton: {
    defaultMessage: 'Continue with debit card',
    description: 'Button text for guest checkout button',
  },
});

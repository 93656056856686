import { useOnMount } from 'cb-wallet-data/hooks/useOnMount';
import { useCanAuthenticateWithAPI } from 'cb-wallet-data/stores/Authentication/tokens/useCanAuthenticateWithAPI';
import { ErrorBoundary } from 'wallet-cds-web/components/ErrorBoundary';
import { GenericErrorPage } from 'wallet-cds-web/components/GenericErrorPage';
import { VStack } from '@cbhq/cds-web/layout';

import { PanoOnrampContextProvider } from ':dapp/providers/PanoOnrampContext';

import { BuyFlowStepper } from '../BuyFlowStepper/BuyFlowStepper';
import { stepper } from '../BuyFlowStepper/flow';
import { BuyFlowStepsEnum } from '../BuyFlowStepper/steps';

type BuyFlowDrawerProps = {
  onClose?: () => void;
};

function BuyFlowDrawerContent({ onClose }: BuyFlowDrawerProps) {
  return (
    <stepper.Provider defaultStep={BuyFlowStepsEnum.ENTRY}>
      <BuyFlowStepper onClose={onClose} />
    </stepper.Provider>
  );
}

export function BuyFlowDrawer({ onClose }: BuyFlowDrawerProps) {
  const hasAccessToken = useCanAuthenticateWithAPI();

  useOnMount(() => {
    if (!hasAccessToken) {
      // TODO: handle deeplinking to buy drawer when not authenticated
      onClose?.();
    }
  });

  if (!hasAccessToken) {
    return null;
  }

  return (
    <ErrorBoundary context="dapp_error" fallback={<GenericErrorPage />}>
      <VStack testID="buy-flow-sidebar" width="100%" height="100%">
        <PanoOnrampContextProvider>
          <BuyFlowDrawerContent onClose={onClose} />
        </PanoOnrampContextProvider>
      </VStack>
    </ErrorBoundary>
  );
}

import { SocialShareType } from ':dapp/pages/nft/mint/[contractAddress]/components/MintShareModal';

export function generateShareUrl(type: SocialShareType, shareUrl: string, text = ''): string {
  switch (type) {
    case 'x':
      return `https://x.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(
        shareUrl,
      )}`;
    // TODO: Add embeds support
    case 'farcaster':
      return `https://warpcast.com/~/compose?text=${encodeURIComponent(shareUrl)}`;
    case 'lens':
      return `https://lenster.xyz/?text=${text}&url=${encodeURIComponent(shareUrl)}`;
    case 'discord':
      return `https://discord.com/share?text=${text}&url=${encodeURIComponent(shareUrl)}`;

    default:
      throw new Error(`Unsupported type: ${type}`);
  }
}

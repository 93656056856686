import { truncateMiddle } from 'cb-wallet-data/utils/String+Core';

/**
 * This is helper function to get truncated address.
 *
 * @param address - e.g.  '0xdF0635793e91D4F8e7426Dbd9Ed08471186F428D'
 * @returns truncated address - e.g. '0xdF06…428D'
 */
export function getTruncatedAddress(address: string) {
  return truncateMiddle(address, 6, 4);
}

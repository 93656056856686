import { useCallback } from 'react';
import { SolanaWalletConfiguration as SolanaConfig } from 'cb-wallet-data/chains/AccountBased/Solana/config';
import { SOLANA_PREFIX } from 'cb-wallet-data/chains/AccountBased/Solana/utils/chain';
import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { SprigEventTrack } from 'cb-wallet-data/hooks/useSyncIsZeroBalanceWallet';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { useFirstCreatedAccount } from 'cb-wallet-data/stores/Accounts/hooks/useFirstCreatedAccount';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { getWalletsOfBlockchainRecord, saveWallets } from 'cb-wallet-data/stores/Wallets/database';
import { useSetLastBalanceUpdateMetadata } from 'cb-wallet-data/stores/Wallets/hooks/useLastBalanceUpdateMetadata';
import {
  setBlockchainHasBalance,
  useSetBlockchainIndexesSynced,
} from 'cb-wallet-data/stores/Wallets/hooks/useZeroBalanceTracking';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { WalletAddress } from 'cb-wallet-data/stores/Wallets/models/WalletAddress';
import { blockchainIsRefreshedMapAtom } from 'cb-wallet-data/stores/Wallets/state';
import { useSetRecoilState } from 'recoil';
import { HistoryEventUpdate, SolanaAddressHistory } from 'wallet-engine-signing/history';

/**
 * Provides a stateful callback function, for listening to all SOL address
 * history updates, including init and poll. Nudges not supported.
 */
export function useUpdateSolAddressHistory(sprigEventTrack?: SprigEventTrack) {
  const setBlockchainIsRefreshedMap = useSetRecoilState(blockchainIsRefreshedMapAtom);
  const firstCreatedAccount = useFirstCreatedAccount();
  const setBlockchainIndexesSynced = useSetBlockchainIndexesSynced();
  const setLastBalanceUpdateMetadata = useSetLastBalanceUpdateMetadata();

  return useCallback(
    async function updateSolAddressHistory(ev: HistoryEventUpdate) {
      const historyUpdates = ev.updates as SolanaAddressHistory[];
      const existingWalletsRecord = await getWalletsOfBlockchainRecord('SOL');
      const syncedIndexes = new Set<bigint>();

      const walletsToSave = await historyUpdates.reduce<Promise<Wallet[]>>(
        async function reduceSolWalletsToSave(accPromise, update) {
          const {
            address,
            chainId,
            context,
            solanaBalance: receivedBalance,
            splBalances,
            errors,
          } = update;

          const acc = await accPromise;
          const walletId = context?.walletId;
          const wallet = existingWalletsRecord[walletId];
          const { accountId, selectedIndex } = Wallet.propsFromId(walletId);
          const isPrimaryAccount = accountId === firstCreatedAccount?.id;

          syncedIndexes.add(selectedIndex!);

          if (errors.length) {
            errors.forEach((error: Error) => {
              cbReportError({
                error,
                metadata: { walletId },
                context: 'address_history_error',
                severity: 'error',
                isHandled: false,
              });
            });
            // If there is an error, don't update the wallet.
            return acc;
          }

          const didBalanceChange = wallet && wallet.balance !== receivedBalance;

          // Update the base asset wallet, if it changed.
          if (didBalanceChange) {
            const updatedWallet = Wallet.fromDMO({
              ...wallet.asDMO,
              balanceStr: String(receivedBalance),
            });

            acc.push(updatedWallet);
          }

          if (isPrimaryAccount && receivedBalance > 0n) {
            setBlockchainHasBalance('SOL', true);
          }

          if (wallet && splBalances.length) {
            const splInfos = splBalances.map((splBalance) => ({
              address: splBalance.address,
              errorMessage: splBalance.errorMessage,
              symbol: splBalance.symbol,
              decimals: splBalance.decimals,
              name: splBalance.name,
              imageUrl: splBalance.imageUrl,
              assetUUID: splBalance.assetUUID,
            }));

            // Wait for SPL tokens to be stored into SPL Table before proceeding
            const splMap: Record<string, SPL> = {};
            splInfos.forEach((splInfo) => {
              splMap[splInfo.address] = {
                name: splInfo.name,
                decimals: BigInt(splInfo.decimals),
                mintAddress: splInfo.address,
                chainId: Number(chainId),
                currencyCode: new CurrencyCode(splInfo.symbol),
                imageURL: splInfo.imageUrl,
              };
            });

            for (const token of splBalances) {
              const mintAddress = token.account.data.parsed.info.mint;
              const mintBalance = token.account.data.parsed.info.tokenAmount.amount;
              const spl = splMap[mintAddress];
              if (!spl) continue;

              const assetUUID = token.assetUUID ?? '';

              if (isPrimaryAccount && mintBalance && BigInt(mintBalance) > 0n) {
                setBlockchainHasBalance('SOL', true);
              }

              const splWallet = createSplWallet({
                address,
                spl,
                balance: BigInt(mintBalance),
                accountId: wallet.accountId,
                walletIndex: wallet.selectedIndex ?? 0n, // Should never be undefined, if from Solana wallet
                assetUUID,
              });

              if (
                !existingWalletsRecord[splWallet.id] ||
                !Wallet.isEqual(splWallet, existingWalletsRecord[splWallet.id])
              ) {
                acc.push(splWallet);
              }
            }
          }

          return acc;
        },
        Promise.resolve([]),
      );

      if (walletsToSave.length) {
        saveWallets(walletsToSave);
      }

      if (ev.interval === 'init') {
        setBlockchainIndexesSynced('SOL', syncedIndexes, sprigEventTrack);
        setBlockchainIsRefreshedMap((curr) => ({
          ...curr,
          SOL: true,
        }));
        setLastBalanceUpdateMetadata({ updatedAt: new Date().getTime(), source: 'init' });
      }
    },
    [
      sprigEventTrack,
      setBlockchainIsRefreshedMap,
      firstCreatedAccount?.id,
      setBlockchainIndexesSynced,
      setLastBalanceUpdateMetadata,
    ],
  );
}

export type SPL = {
  name: string;
  decimals: bigint;
  mintAddress: string;
  chainId: number;
  currencyCode: CurrencyCode;
  imageURL?: string;
};

export function createSplWallet({
  address,
  spl,
  balance,
  accountId,
  walletIndex,
  assetUUID,
}: {
  address: string;
  spl: SPL;
  balance: bigint;
  accountId: Account['id'];
  walletIndex: bigint;
  assetUUID: string;
}) {
  return new Wallet({
    primaryAddress: address,
    addresses: [new WalletAddress(SolanaConfig.defaultReceiveType, address, walletIndex)],
    displayName: spl.name,
    currencyCode: spl.currencyCode,
    imageURL: spl.imageURL?.toString(),
    balance,
    decimals: spl.decimals,
    blockchain: SolanaConfig.blockchain,
    minimumBalance: undefined,
    network: Network.fromChainId({ chainPrefix: SOLANA_PREFIX, chainId: BigInt(spl.chainId) }),
    contractAddress: spl.mintAddress,
    selectedIndex: walletIndex,
    accountId,
    assetUUID,
  });
}

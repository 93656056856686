import {
  KillSwitchByPlatform,
  KillSwitchByPlatformConfigKey,
  killSwitchesByPlatformConfig,
  KillSwitchPlatform,
} from 'cb-wallet-data/killswitches/killSwitchesByPlatformConfig';
import {
  KillSwitchCrossPlatform,
  killSwitchesCrossPlatformConfig,
} from 'cb-wallet-data/killswitches/killSwitchesCrossPlatformConfig';

export type CbKillSwitch = KillSwitchByPlatform | KillSwitchCrossPlatform;
type CbKillSwitchConfig = { [K in CbKillSwitch]?: boolean };

export const allKillSwitchesConfig: CbKillSwitchConfig = {};

(Object.keys(killSwitchesByPlatformConfig) as KillSwitchByPlatformConfigKey[]).forEach(
  function populateAllKillswitches(killswitchName: KillSwitchByPlatformConfigKey) {
    const killswitchValue = killSwitchesByPlatformConfig[killswitchName];

    (Object.entries(killswitchValue) as [KillSwitchPlatform, boolean][]).forEach(
      ([platform, isEnabled]) => {
        allKillSwitchesConfig[`${killswitchName}_${platform}`] = isEnabled;
      },
    );

    for (const [key, value] of Object.entries(killSwitchesCrossPlatformConfig) as [
      KillSwitchCrossPlatform,
      boolean,
    ][]) {
      allKillSwitchesConfig[key] = value;
    }
  },
);

export const killswitchSet = new Set<string>();
Object.keys(allKillSwitchesConfig).forEach((killswitchName: string) => {
  killswitchSet.add(killswitchName);
});

import { TxOrUserOp } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';

type Params = {
  tronTransactions?: TxOrUserOp[];
  txHistoryTransactions?: TxOrUserOp[];
};
/**
 * Currently we have a backend limitation that leads us to a scenario where the wallet
 * already received the USDC asset on Base but PTS is still returning the transaction as pending
 * due to a delay in the Bridge.xyz webhook system.
 *
 * With that, aiming to reduce the amount of time we're showing these duplicated transactions,
 * we're filtering our PTS transactions which contain a "destinationTxnHash" that's already related to
 * a transaction returned by the transaction history service.
 */
export function dedupePendingTronTransactions({
  tronTransactions = [],
  txHistoryTransactions = [],
}: Params): TxOrUserOp[] {
  if (!tronTransactions) return [];

  if (!txHistoryTransactions) return tronTransactions;

  return tronTransactions.filter(function dedupeTronTransactions(tronTx) {
    // If the PTS transaction doesn't have a "destinationTxnHash", we should keep it in the list
    if (!tronTx.tronDestinationTransactionHash) return true;

    const isDuplicated = txHistoryTransactions.find(
      (tx) => tx.txHash?.toLowerCase() === tronTx.tronDestinationTransactionHash.toLowerCase(),
    );

    return !isDuplicated;
  });
}

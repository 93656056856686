import { useMemo } from 'react';
import { useAccounts } from 'cb-wallet-data/stores/Accounts/hooks/useAccounts';
import { useWalletGroups } from 'cb-wallet-data/stores/WalletGroups/hooks/useWalletGroups';

/**
 * This hook ensures that accounts and wallet groups are present since they are required
 * for the app to load into a LoginReady state
 */
export function useHasAccountsAndWalletGroups() {
  const accounts = useAccounts();
  const walletGroups = useWalletGroups();

  return useMemo(
    () => Boolean(accounts.length && walletGroups.length),
    [accounts.length, walletGroups.length],
  );
}

import { ActionType, ComponentType } from '@cbhq/client-analytics';

import { logEvent, LogEventCustomParams, useLogEventOnMount } from '../utils/log';

export function triggerCBPayEventStream(cbPayEventProperties: LogEventCustomParams) {
  logEvent('onboarding.cbpay.stream', {
    action: ActionType.unknown,
    componentType: ComponentType.modal,
    ...cbPayEventProperties,
  });
}

export function logWebViewNavigation(params?: LogEventCustomParams) {
  logEvent('onramp_psps.web_view.navigation', {
    componentType: ComponentType.page,
    action: ActionType.view,
    ...params,
    url: params?.url && removeQueryParams(params.url),
    mainDocumentURL: params?.mainDocumentURL && removeQueryParams(params.mainDocumentURL),
  });
}

export function logPostMessageFromUnauthorizedOrigin(params?: LogEventCustomParams) {
  return logEvent('onramp_psps.web_view.post_message_unauthorized_origin', {
    componentType: ComponentType.page,
    action: ActionType.unknown,
    ...params,
  });
}

export function triggerVerificationModalViewed(isAppToAppAvailable: boolean) {
  logEvent('buy.verification_modal.viewed', {
    action: ActionType.view,
    componentType: ComponentType.modal,
    isAppToAppAvailable,
  });
}

export function trigger3DSFlowRedirected(url: string) {
  logEvent('buy.3ds_challenge_flow.redirected', {
    action: ActionType.process,
    componentType: ComponentType.unknown,
    url,
  });
}

export function trigger3DSFlowReturned(url: string) {
  logEvent('buy.3ds_challenge_flow.returned', {
    action: ActionType.process,
    componentType: ComponentType.unknown,
    url,
  });
}

export function triggerGuestCheckoutPaymentLinkOpen(url: string) {
  logEvent('buy.guest_payment_link.open', {
    action: ActionType.process,
    componentType: ComponentType.unknown,
    url,
  });
}

export function triggerGuestCheckoutPaymentLinkClose() {
  logEvent('buy.guest_payment_link.close', {
    action: ActionType.process,
    componentType: ComponentType.unknown,
  });
}

export function triggerGuestCheckoutPaymentLinkSuccess() {
  logEvent('buy.guest_payment_link.success', {
    action: ActionType.process,
    componentType: ComponentType.unknown,
  });
}

export function useLogBuyEntryViewed() {
  useLogEventOnMount('buy.entry.viewed', {
    componentType: ComponentType.page,
    action: ActionType.render,
  });
}

export function useLogCBPayEntryViewed() {
  useLogEventOnMount('cbpay.entry.viewed', {
    componentType: ComponentType.page,
    action: ActionType.render,
  });
}

type BuyErrorViewedProps = {
  errorTitle?: string;
  errorMessage?: string;
};

export function useLogBuyErrorViewed({ errorTitle, errorMessage }: BuyErrorViewedProps) {
  useLogEventOnMount('buy.error.viewed', {
    componentType: ComponentType.page,
    action: ActionType.render,
    errorTitle,
    errorMessage,
  });
}

function removeQueryParams(url: string): string {
  return url.split('?')[0];
}

type LogLaunchBuyFlowParams = {
  origin?: string;
  isConnected: boolean;
  walletId?: string;
  amount?: number;
  asset?: string;
  network?: string;
  provider?: string;
  market?: string;
};

export function logLaunchBuyFlow(params: LogLaunchBuyFlowParams) {
  logEvent('connect_buy.launch', {
    action: ActionType.render,
    componentType: ComponentType.page,
    ...params,
  });
}

export type BuyFlowVariant = 'CBPay' | 'BuyNonCb';

export function logOpenBuyFlow(
  params: LogLaunchBuyFlowParams & { variation: BuyFlowVariant; isCBKECountry?: boolean },
) {
  logEvent('buy.open', {
    action: ActionType.render,
    componentType: ComponentType.page,
    ...params,
  });
}

import { useIsExperimentInTreatment } from 'cb-wallet-data/stores/Experiments/hooks/useIsExperimentInTreatment';
import { getPlatformName } from 'cb-wallet-metadata/metadata';
import { PlatformName } from '@cbhq/client-analytics';
import { ExperimentType } from '@cbhq/experiments';

import { featureConfigs, FeatureName } from '../featureConfigs';
import { FeatureConfig } from '../types';

type UseIsFeatureEnabledForExperimentsParams = {
  featureName: FeatureName;
  mustSkipTracking: boolean;
  experimentTreatmentGroups: string[];
};

/**
 * maybeGetExperimentForPlatform
 *
 * This function will get the experiment for the current platform. If there is
 * no experiment specified in the FeatureConfig, it returns null.
 */
function maybeGetExperimentForPlatform(featureConfig: FeatureConfig): ExperimentType | null {
  const platformName = getPlatformName();
  const { experiments } = featureConfig;

  switch (platformName) {
    case PlatformName.extension:
      return experiments?.extension ?? null;

    case PlatformName.android:
      return experiments?.android ?? null;

    case PlatformName.ios:
      return experiments?.ios ?? null;

    case PlatformName.web:
      return experiments?.web ?? null;

    default:
      return null;
  }
}

/**
 * useIsFeatureEnabledForExperiments
 *
 * This hooks supports useIsFeatureEnabled by managing the logic for Experiments.
 * Use this hook in conjunction with the feature config.
 *
 * Responsibility
 * The responsibility of this hook is to determine based on the experiments property of
 * a feature config whether or not the feature should be enabled.
 *
 * Exposure Correctness
 * `mustSkipTracking` is a boolean. If it's true, it will force skipTracking to be true.
 * This could be used for scenarios where there are other conditions that are not met
 * for the feature to be enabled. An example is when a feature is killed and
 * should not be tracked as exposed.
 *
 * Checking treatment groups
 * `experimentTreatmentGroups` is an array of treatment groups that is used to check
 *  if the user is in a given treatment group for the experiment. This is used for multi variant
 *  experiments
 *
 * @return
 *
 * NOTE This hook will return true even if skipTracking may be true. It is up to
 * the manager of this hook to determine if the feature should be enabled.
 *
 * NOTE This will return true if there is no experiment configured for the platform.
 */
export function useIsFeatureEnabledForExperiments({
  featureName,
  mustSkipTracking,
  experimentTreatmentGroups,
}: UseIsFeatureEnabledForExperimentsParams): boolean {
  const featureConfig = featureConfigs[featureName];
  const experiment = maybeGetExperimentForPlatform(featureConfig);

  // doesExperimentExist denotes if there is an experiment specified in the featureConfig
  // for the current platform.
  const doesExperimentExist = experiment !== null;

  // If mustSkipTracking is true, skipTracking is set to true so that it prevents
  // exposure tracking. This is done to maintain analytics correctness.
  // Example: if a feature is killed, it should not be tracked as exposed.
  //
  // If experiment is null, this sets skipTracking to true, which should have no
  // effect anyways given the experiment is null.
  const skipTracking = mustSkipTracking || !doesExperimentExist;
  const isExperimentInTreatment = useIsExperimentInTreatment(experiment, {
    skipTracking,
    treatmentGroups: experimentTreatmentGroups,
  });

  // If there isn't a experiment, return true. Otherwise, return the experiment's
  // variant. Even though `mustSkipTracking` may be false, this should
  // still return whether or not the user is in treatment. Logic for whether or
  // not the feature is enabled will be handled by the Feature Manager.
  return doesExperimentExist ? isExperimentInTreatment : true;
}

import { Drawer } from 'wallet-cds-web/components/Drawer';

import { useGlobalDrawerWidth } from './hooks/useGlobalDrawerWidth';
import { useGlobalDrawer } from './GlobalDrawerProvider';

export function GlobalDrawerContent() {
  const { isOpen, content, closeDrawer } = useGlobalDrawer();
  const width = useGlobalDrawerWidth();

  return (
    <Drawer overlay open={isOpen} pin="right" size={width} onClose={closeDrawer}>
      {content}
    </Drawer>
  );
}

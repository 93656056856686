import { useEffect, useRef } from 'react';
import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { useIsSpamScoreEnabled } from 'cb-wallet-data/stores/AssetManagement/hooks/useIsSpamScoreEnabled';

import { clearCuratedAssetSettings } from '../database';

export function useClearCuratedAssetsTable() {
  const isSpamScoreEnabled = useIsSpamScoreEnabled();
  const isCuratedAssetsTableCleared = useRef<boolean>(false);

  useEffect(
    function clearCuratedAssets() {
      const asyncClearCuratedAssets = async () => {
        try {
          if (isSpamScoreEnabled && !isCuratedAssetsTableCleared.current) {
            await clearCuratedAssetSettings();
            isCuratedAssetsTableCleared.current = true;
          }
        } catch (clearError) {
          const err = coerceError(clearError, 'useClearCuratedAssetsTable');
          cbReportError({ error: err, context: 'assets', isHandled: false, severity: 'error' });
          return false;
        }
      };

      asyncClearCuratedAssets();
    },
    [isSpamScoreEnabled],
  );
}

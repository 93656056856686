import { createContext, RefObject, useCallback, useContext, useMemo, useRef } from 'react';
import { useToggler } from '@cbhq/cds-common';

import { useDebouncedSearchQuery } from ':dapp/hooks/useDebouncedSearchQuery';

export type SearchContextValue = {
  isSearching: boolean;
  query: string;
  debouncedQuery: string;
  setQuery: (query: string) => void;
  toggleSearchOn: () => void;
  toggleSearchOff: () => void;
  searchPositionRef?: RefObject<HTMLElement>;
};

const INITIAL_STATES: SearchContextValue = {
  isSearching: false,
  query: '',
  debouncedQuery: '',
  setQuery: () => {},
  toggleSearchOn: () => {},
  toggleSearchOff: () => {},
  searchPositionRef: undefined,
};

const SearchContext = createContext<SearchContextValue>(INITIAL_STATES);

type SearchProviderProps = { children: React.ReactNode };

export function SearchProvider({ children }: SearchProviderProps) {
  const [isSearching, { toggleOn, toggleOff }] = useToggler(false);
  const searchPositionRef = useRef<HTMLElement>(null);
  const { query, setQuery, debouncedQuery } = useDebouncedSearchQuery();

  const toggleSearchOff = useCallback(
    function toggleSearchOff() {
      setQuery('');
      toggleOff();
    },
    [setQuery, toggleOff],
  );

  const value = useMemo(
    () => ({
      isSearching,
      query,
      debouncedQuery,
      searchPositionRef,
      setQuery,
      toggleSearchOn: toggleOn,
      toggleSearchOff,
    }),
    [debouncedQuery, isSearching, query, setQuery, toggleOn, toggleSearchOff],
  );
  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
}

export function useSearchContext() {
  return useContext(SearchContext);
}

/**
 * This code was copied and modified from CDS's BaseButton component.
 * https://github.cbhq.net/frontend/cds/blob/master/packages/web/buttons/Button.tsx
 */
import { forwardRef, useMemo } from 'react';
import { ButtonBaseProps } from '@cbhq/cds-common';
import { useButtonIconSize } from '@cbhq/cds-common/hooks/useButtonIconSize';
import { useButtonSpacing as useSharedButtonSpacing } from '@cbhq/cds-common/hooks/useButtonSpacing';
import { useButtonVariant } from '@cbhq/cds-common/hooks/useButtonVariant';
import { useInteractableHeight } from '@cbhq/cds-common/hooks/useInteractableHeight';
import * as buttonStyles from '@cbhq/cds-web/buttons/buttonStyles';
import { useButtonSpacing } from '@cbhq/cds-web/hooks/useButtonSpacing';
import { useFlushStyles } from '@cbhq/cds-web/hooks/useFlushStyles';
import { Icon } from '@cbhq/cds-web/icons/Icon';
import { MaterialSpinner } from '@cbhq/cds-web/loaders/MaterialSpinner';
import * as foregroundColors from '@cbhq/cds-web/styles/foregroundColor';
import { Pressable, PressableProps } from '@cbhq/cds-web/system/Pressable';
import { TextHeadline } from '@cbhq/cds-web/typography/TextHeadline';
import { cx } from '@cbhq/cds-web/utils/linaria';
export type GradientButtonProps = ButtonBaseProps & PressableProps & {
  type?: 'button' | 'submit';
  gradientBackgroundClass?: string;
};
export const GRADIENT_CLASS = "gfjbsyo";

/**
 * GradientButton
 */
export const GradientButton = forwardRef(function Button({
  accessibilityLabel,
  block,
  children,
  compact,
  disabled,
  endIcon,
  loading,
  onPress,
  startIcon,
  to,
  transparent,
  flush,
  type = 'button',
  variant = 'primary',
  noScaleOnPress,
  numberOfLines,
  gradientBackgroundClass,
  ...props
}: GradientButtonProps, ref: React.Ref<HTMLButtonElement>) {
  const hasIcon = Boolean(startIcon ?? endIcon);
  const iconSize = useButtonIconSize(compact);
  const spacing = useSharedButtonSpacing({
    flush,
    compact,
    startIcon,
    endIcon
  });
  const flushStyles = useFlushStyles({
    flush,
    spacing
  });
  const spacingClass = useButtonSpacing({
    flush,
    compact,
    startIcon,
    endIcon
  });
  const height = useInteractableHeight(compact);
  const {
    color,
    backgroundColor,
    borderColor
  } = useButtonVariant(variant, transparent);
  const style = useMemo(() => ({
    '--interactable-height': `${height}px`,
    ...flushStyles
  }), [height, flushStyles]);
  const className = useMemo(() => cx(foregroundColors[color], buttonStyles.button, numberOfLines && buttonStyles.unsetNoWrap, hasIcon && buttonStyles.buttonWithIcon, compact && buttonStyles.buttonCompact, block && buttonStyles.buttonBlock, spacingClass,
  // We only want to apply Gradient styles if the button is not transparent
  !transparent && (gradientBackgroundClass || GRADIENT_CLASS)), [block, color, compact, gradientBackgroundClass, hasIcon, numberOfLines, spacingClass, transparent]);
  return <Pressable aria-label={accessibilityLabel ?? (loading ? 'Loading' : undefined)} {...props} transparentWhileInactive={transparent} background={backgroundColor} block={block} borderColor={borderColor} borderRadius="roundedFull" borderWidth="button" className={className} loading={loading} disabled={disabled} onPress={onPress} style={style} type={type} ref={ref} to={to} noScaleOnPress={noScaleOnPress}>
      {startIcon && <span className={buttonStyles.startIcon}>
          <Icon name={startIcon} size={iconSize} color={color} />
        </span>}
      <span className={buttonStyles.positionRelative}>
        {loading && <span className={cx(buttonStyles.centerLoader)}>
            <MaterialSpinner size={buttonStyles.LOADERSIZE} color={color} />
          </span>}
        <TextHeadline as="span" color={color} numberOfLines={numberOfLines}>
          <span className={cx(loading && buttonStyles.visibilityHidden)}>{children}</span>
        </TextHeadline>
      </span>
      {endIcon && <span className={buttonStyles.endIcon}>
          <Icon name={endIcon} size={iconSize} color={color} />
        </span>}
    </Pressable>;
});

require("./GradientButton.linaria.module.css!=!../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./GradientButton.tsx");
import { ETHEREUM_PREFIX } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { ERC20, ERC20DMO } from 'cb-wallet-data/chains/AccountBased/Ethereum/models/ERC20';
import {
  EthereumSignedTx,
  EthereumSignedTxDMO,
} from 'cb-wallet-data/chains/AccountBased/Ethereum/models/EthereumSignedTx';
import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { tryGetRepository } from 'cb-wallet-data/persistence/Database';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { TransactionsRepositoryName } from 'cb-wallet-data/stores/Transactions/database';
import { TxOrUserOpDMO } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import { TxState } from 'cb-wallet-data/stores/Transactions/models/TxState';
import { bigIntFromNumber } from 'wallet-engine-signing/blockchains/Ethereum/formatNumbers';

import { erc20CacheManager, getERC20FromCacheIfValid } from './Balance/ERC20CacheManager';
import { EthereumChain } from './EthereumChain';

function ethereumSignedRepo() {
  return tryGetRepository<EthereumSignedTxDMO>('ethereum_signed_tx');
}

function erc20Repo() {
  return tryGetRepository<ERC20DMO>('erc20');
}

function transactionRepo() {
  return tryGetRepository<TxOrUserOpDMO>(TransactionsRepositoryName.v2);
}

export async function saveERC20(erc20: ERC20): Promise<ERC20 | undefined> {
  erc20CacheManager.setItem(erc20);
  await erc20Repo().upsert(erc20.asDMO, ['id']);

  return erc20;
}

export async function getAllERC20s(): Promise<Record<string, ERC20>> {
  const erc20s: ERC20DMO[] = await erc20Repo().find();

  return erc20s.reduce(function reduceErc20sToMap(accumulator, erc20) {
    accumulator[erc20.id] = ERC20.fromDMO(erc20);
    return accumulator;
  }, {} as Record<string, ERC20>);
}

export async function getERC20(
  contractAddress: string,
  chainId: bigint,
): Promise<ERC20 | undefined> {
  const cachedERC20 = getERC20FromCacheIfValid(contractAddress, chainId);
  if (cachedERC20) return cachedERC20;

  const erc20DMO = await erc20Repo().findOne({
    where: {
      contractAddress,
      chainIdStr: chainId.toString(),
    },
  });

  return erc20DMO ? ERC20.fromDMO(erc20DMO) : undefined;
}

/**
 * Save or update given ethereum-based transaction
 */
export async function saveTransaction(tx: EthereumSignedTx): Promise<EthereumSignedTx | undefined> {
  await ethereumSignedRepo().upsert(tx.asDMO, ['id']);
  return tx;
}

/**
 *Get list of unmined signed transaction (excluding dropped transactions)
 */
export async function getUnminedSignedTxs(chain: EthereumChain): Promise<EthereumSignedTx[]> {
  const dmoArray = await ethereumSignedRepo().find({
    where: {
      state: TxState.PENDING.valueOf(),
      chainId: chain.chainId,
    },
  });

  return dmoArray.map(EthereumSignedTx.fromDMO);
}

/**
 * Get list of unmined signed Ether transaction
 */
export async function getUnminedEtherTxs(
  address: string,
  chain: EthereumChain,
): Promise<EthereumSignedTx[]> {
  const dmoArray = await ethereumSignedRepo().find({
    where: {
      state: TxState.PENDING.valueOf(),
      chainId: chain.chainId,
      fromAddress: address,
    },
  });

  return dmoArray.map(EthereumSignedTx.fromDMO);
}

/**
 * Get list of unmined signed ERC20 transactions
 */
export async function getUnminedERC20Txs(
  address: string,
  contractAddress: string,
  chain: EthereumChain,
): Promise<EthereumSignedTx[]> {
  const dmoArray = await ethereumSignedRepo().find({
    where: {
      state: TxState.PENDING.valueOf(),
      chainId: chain.chainId,
      toAddress: contractAddress,
      fromAddress: address,
    },
  });

  return dmoArray.map(EthereumSignedTx.fromDMO);
}

export async function deleteTxByHash(txHash: string) {
  const deleteResult = await ethereumSignedRepo().delete({
    txHash,
  });

  if (deleteResult.affected !== 1) {
    const txNotDeletedError = Error(
      'transaction was not deleted from EthereumSignedTx table -- may lead to permanently pending tx',
    );
    cbReportError({
      error: txNotDeletedError,
      context: 'transactions',
      severity: 'warning',
      isHandled: false,
    });
  }
}

/**
 * Get a list of signed transactions ordered by nonce
 */
export async function getUnminedSignedTxsAfterNonce(
  nonce: bigint,
  chainId: bigint,
  fromAddress: string,
): Promise<EthereumSignedTx[]> {
  const dmoArray = await ethereumSignedRepo().find({
    where: {
      state: TxState.PENDING.valueOf(),
      chainId: Number(chainId),
      fromAddress,
    },
    order: {
      nonce: 'ASC',
    },
  });

  return dmoArray
    .filter((signedTxDMO: EthereumSignedTxDMO) => BigInt(signedTxDMO.nonce) >= nonce)
    .map(EthereumSignedTx.fromDMO);
}

/**
 * Save signed transaction to local store
 */
export async function insertSignedTransaction(
  signedTransaction: EthereumSignedTx,
): Promise<EthereumSignedTx | undefined> {
  await ethereumSignedRepo().insert(signedTransaction.asDMO);

  return signedTransaction;
}

/**
 * Clear transactions from local store for a given chain id
 */
export async function clearTransactionsForChain(chain: EthereumChain) {
  const network = Network.fromChainId({
    chainPrefix: ETHEREUM_PREFIX,
    chainId: bigIntFromNumber(chain.chainId),
  });

  const [ethTxnList, allTxnList] = await Promise.all([
    ethereumSignedRepo().find({
      where: {
        chainId: chain.chainId,
      },
    }),
    transactionRepo().find({
      where: {
        networkStr: network.rawValue,
      },
    }),
  ]);

  if (ethTxnList.length === 0 && allTxnList.length === 0) return;
  const ethTxnIds = ethTxnList.map((txn: EthereumSignedTxDMO) => txn.id);
  const txnIds = allTxnList.map((txn: TxOrUserOpDMO) => txn.id);

  return Promise.all([ethereumSignedRepo().delete(ethTxnIds), transactionRepo().delete(txnIds)]);
}

import { BaseWalletAdapter as SolanaWalletConnector } from '@solana/wallet-adapter-base';

import { PossibleNetworkWalletConnector } from '../types';

export function isSolanaConnector(
  connector: PossibleNetworkWalletConnector | null | unknown,
): connector is SolanaWalletConnector {
  return (
    typeof connector === 'object' &&
    connector !== null &&
    connector &&
    'readyState' in connector &&
    'connect' in connector
  );
}

import { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';

import { getSearchResults, GetSearchResultsParams } from '../api';
import { GetSearchResponse, SearchResponse } from '../types';

type UseGetSearchResultsOptions = Omit<
  UseQueryOptions<GetSearchResponse, unknown, GetSearchResponse, string[]>,
  'queryKey' | 'queryFn'
>;

export function useGetSearchResults(
  {
    query,
    searchType,
    requestId,
    entities,
    pageSize,
    maxResults,
    baseUrlOverride,
    isEnabled = true,
    isWacSearchEnabled = false,
    isNLPSearchEnabled = false,
  }: GetSearchResultsParams,
  options?: UseGetSearchResultsOptions,
) {
  const { data, isLoading, isError, fetchStatus, error } = useQuery(
    [searchType, query, ...(entities ?? [])],
    async () =>
      getSearchResults({
        query,
        searchType,
        requestId,
        entities,
        pageSize,
        maxResults,
        baseUrlOverride,
        isWacSearchEnabled,
        isNLPSearchEnabled,
      }),
    {
      notifyOnChangeProps: ['data'],
      ...options,
      enabled: isEnabled,
    },
  );

  if (entities) {
    return {
      entries:
        data?.entries?.filter((ent) => {
          // needed type inference for includes to infer the type correctly.
          return (entities as SearchResponse['type'][]).includes(ent.type);
        }) || [],
      isLoading: fetchStatus !== 'idle' && isLoading,
      isError,
      error,
    };
  }

  return {
    entries: data?.entries || [],
    isLoading: fetchStatus !== 'idle' && isLoading,
    isError,
    error,
  };
}

import { useMemo } from 'react';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';

import { UserWalletSetting } from '../models/UserWalletSetting';

export function useGetUserWalletSettingId(wallet: Wallet) {
  return useMemo(() => generateUserWalletSettingId(wallet), [wallet]);
}

export function generateUserWalletSettingId(wallet: Wallet) {
  return UserWalletSetting.generateID(
    wallet.primaryAddress,
    wallet.blockchain,
    wallet.currencyCode,
    wallet.network,
    wallet.contractAddress,
  );
}

import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { TxOrUserOp } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import { TxOrUserOpMetadata } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOpMetadata';
import { TxState } from 'cb-wallet-data/stores/Transactions/models/TxState';
import { UnsupportedTronTransaction } from 'cb-wallet-data/stores/Transactions/models/UnsupportedTronTransaction';
import { buildMetadataForTronTx } from 'cb-wallet-data/stores/Transactions/utils/buildMetadataForTronTx';
import Decimal from 'decimal.js';

const customUSDCBlockchain = new Blockchain('ETH');

// We need to override the blockExplorerUrl here because TRON is not currently supported on Wallet.
// Otherwise, it'd use the BASE blockExplorerUrl since we're using it as fallback to represent the TRON txs.
customUSDCBlockchain.blockExplorerUrl = function blockExplorerUrl(txHash: string, _: Network) {
  return `https://tronscan.org/#/transaction/${txHash}`;
};

export const USDT_ON_TRON_PROXY = {
  decimals: 6n,
  blockchain: customUSDCBlockchain,
  currencyCode: new CurrencyCode('USDC'),
  network: new Network('ETHEREUM_CHAIN:8453'),
  contractAddress: '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913',
};

export type TronTxParams = {
  primaryReceiveWalletId: string;
  accountId: string;
  walletIndex: bigint;
};

// This is tron-specific code. PTS is short for Provider Transaction Service
export function getTransactionFromPtsPayload(
  { primaryReceiveWalletId, accountId, walletIndex }: TronTxParams,
  {
    originalAssetAmount = '0',
    originalTxnHash,
    updatedAt,
    originalSenderAddress,
    destinationReceiverAddress,
    detailedStatus,
    providerKycLink,
    providerReceiptLink,
    destinationTxnHash,
  }: UnsupportedTronTransaction,
) {
  return new TxOrUserOp({
    id: originalTxnHash,
    fee: 0n,
    isSent: false,
    /**
     * Considering that we don't support TRON on Wallet yet, every transaction fetched here can only
     * be a "RECEIVE" transaction
     */
    type: 'RECEIVE',
    /**
     * Every transaction fetched from PST will always be considered as "PENDING" and, once it gets fulfilled,
     * PST will stop returning it and it'll be fetched by the regular Transaction History service.
     *
     * Note: The final transaction list that's shown in the UI is a join between the
     * - Transaction History service response (regular transactions supported by Wallet)
     * - PST response (Pending tron usdt/usdc transactions)
     */
    state: TxState.PENDING,
    txOrUserOpHash: originalTxnHash,
    userOpHash: undefined,
    walletIndex,
    accountId,
    txHash: originalTxnHash,
    /**
     * BE doesn't provide us a "createdAt" date, so we're using the "updatedAt" date as a fallback.
     */
    createdAt: new Date(updatedAt),
    fromAddress: originalSenderAddress,
    toAddress: destinationReceiverAddress,
    walletId: primaryReceiveWalletId,
    /**
     * We're using the USDC_ON_BASE as a fallback to represent the TRON txs.
     * We chose USDC on Base because, in the end of the day, the user will receive it in their wallet.
     */
    tokenDecimal: USDT_ON_TRON_PROXY.decimals,
    blockchain: USDT_ON_TRON_PROXY.blockchain,
    currencyCode: USDT_ON_TRON_PROXY.currencyCode,
    feeCurrencyDecimal: USDT_ON_TRON_PROXY.decimals,
    tokenName: USDT_ON_TRON_PROXY.currencyCode.code,
    feeCurrencyCode: USDT_ON_TRON_PROXY.currencyCode,
    contractAddress: USDT_ON_TRON_PROXY.contractAddress,
    /**
     * We consider any TRON transaction as a BASE transaction since we don't have TRON support on Wallet.
     */
    network: new Network('ETHEREUM_CHAIN:8453', false),
    /**
     * "originalAssetAmount" indicate the amount of USDC, so we need to multiply it by
     * 1,000,000 (USDC has 6 decimals) to get the crypto amount.
     */
    amount: BigInt(new Decimal(originalAssetAmount).mul(1000000).toNumber()),
    /**
     * Build the transaction metadata that help us to identify these tron transactions
     */
    metadata: new TxOrUserOpMetadata(
      buildMetadataForTronTx({
        providerKycLink,
        status: detailedStatus,
        tronReceiptLink: providerReceiptLink,
        originTransactionHash: originalTxnHash,
        originFromAddress: originalSenderAddress,
        destinationTxnHash,
      }),
    ),
  });
}

import { UTXOConfiguration } from 'cb-wallet-data/chains/UTXO/models/UTXOConfiguration';
import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { AddressType } from 'cb-wallet-data/stores/Addresses/models/AddressType';
import { NetworkSetting } from 'cb-wallet-data/stores/Networks/models/NetworkSetting';

export const LITECOIN_SYMBOL = 'LTC';
// Capitalization copied over from native code, probably want to keep it for data interop w/ native
export const LitecoinSegwitAddressType = new AddressType('LitecoinSegwit');
export const LitecoinLegacyAddressType = new AddressType('LitecoinLegacy');
export const LitecoinBRDAddressType = new AddressType('LitecoinBRD');
export const LitecoinBlockchain = new Blockchain(LITECOIN_SYMBOL);
export const LitecoinNetworkSetting = NetworkSetting.forMainnetOnlyBlockchain(LitecoinBlockchain);

export function isDerivationPathSupported(addressType: AddressType, isTestnet: boolean): boolean {
  return addressType.rawValue === LitecoinBRDAddressType.rawValue ? !isTestnet : true;
}

export const LitecoinWalletConfiguration = new UTXOConfiguration({
  supportedAddressTypes: [
    LitecoinSegwitAddressType,
    LitecoinLegacyAddressType,
    LitecoinBRDAddressType,
  ],
  blockchain: LitecoinBlockchain,
  currencyName: 'Litecoin',
  currencyCode: new CurrencyCode('LTC'),
  decimals: 8n,
  imageURL: 'https://wallet-api-production.s3.amazonaws.com/uploads/tokens/ltc_288.png',
  defaultReceiveType: LitecoinSegwitAddressType,
  txResubmitterInterval: 30n,
  maxSubmissionAttempts: 5n,
  qrCodeUriMainnetScheme: 'litecoin',
  qrCodeUriTestnetScheme: 'litecoin',
  networkSetting: LitecoinNetworkSetting,
  refreshInterval: BigInt(480),
  isDerivationPathSupported,
});

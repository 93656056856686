import {
  TxOrUserOpMetadataKey,
  TxOrUserOpMetadataKey_isReceiveTronTx,
  TxOrUserOpMetadataKey_tronDestinationTransactionHash,
  TxOrUserOpMetadataKey_tronFromAddress,
  TxOrUserOpMetadataKey_tronReceiptLink,
  TxOrUserOpMetadataKey_tronTransactionHash,
  TxOrUserOpMetadataKey_tronTxProviderKycLink,
  TxOrUserOpMetadataKey_tronTxStatus,
  TxOrUserOpMetadataValue,
} from 'cb-wallet-data/stores/Transactions/models/TxOrUserOpMetadataKey';

import {
  UnsupportedTronTransaction,
  UnsupportedTronTransactionStatus,
} from '../models/UnsupportedTronTransaction';

export type BuildMetadataForTronTxParams = {
  status?: UnsupportedTronTransactionStatus;
  providerKycLink?: UnsupportedTronTransaction['providerKycLink'];
  tronReceiptLink?: UnsupportedTronTransaction['providerReceiptLink'];
  originTransactionHash?: UnsupportedTronTransaction['originalTxnHash'];
  destinationTxnHash?: UnsupportedTronTransaction['destinationTxnHash'];
  originFromAddress?: UnsupportedTronTransaction['originalSenderAddress'];
};

export function buildMetadataForTronTx({
  status,
  providerKycLink,
  tronReceiptLink,
  originFromAddress,
  originTransactionHash,
  destinationTxnHash,
}: BuildMetadataForTronTxParams): Map<TxOrUserOpMetadataKey, TxOrUserOpMetadataValue> {
  const metadata = new Map<TxOrUserOpMetadataKey, TxOrUserOpMetadataValue>();

  metadata.set(TxOrUserOpMetadataKey_isReceiveTronTx, true);

  if (status) {
    metadata.set(TxOrUserOpMetadataKey_tronTxStatus, status);
  }

  if (originFromAddress) {
    metadata.set(TxOrUserOpMetadataKey_tronFromAddress, originFromAddress);
  }

  if (originTransactionHash) {
    metadata.set(TxOrUserOpMetadataKey_tronTransactionHash, originTransactionHash);
  }

  if (destinationTxnHash) {
    metadata.set(TxOrUserOpMetadataKey_tronDestinationTransactionHash, destinationTxnHash);
  }

  /**
   * We need to encode "tronReceiptLink" and "tronTxProviderKycLink" because the
   * metadata properties are stored & parsed based on /; with that, we get broken values
   * in case it contains a link that's not encoded
   */
  if (tronReceiptLink) {
    metadata.set(TxOrUserOpMetadataKey_tronReceiptLink, encodeURIComponent(tronReceiptLink));
  }

  if (providerKycLink) {
    metadata.set(TxOrUserOpMetadataKey_tronTxProviderKycLink, encodeURIComponent(providerKycLink));
  }

  return metadata;
}

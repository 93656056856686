import { ETHEREUM_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { SOLANA_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Solana/constants';
import { BITCOIN_SYMBOL } from 'cb-wallet-data/chains/UTXO/Bitcoin/config';
import { DOGECOIN_SYMBOL } from 'cb-wallet-data/chains/UTXO/Dogecoin/config';
import { LITECOIN_SYMBOL } from 'cb-wallet-data/chains/UTXO/Litecoin/config';

import { COINBASE_SMART_WALLET, COINBASE_WALLET } from './wallet-providers/coinbase-wallet';
import { PHANTOM } from './wallet-providers/phantom';
import { WALLETCONNECT } from './wallet-providers/walletconnect';
import { WalletProviderNetwork } from './types';

export const WALLET_PROVIDERS = [COINBASE_WALLET, COINBASE_SMART_WALLET, WALLETCONNECT, PHANTOM];

export const PRIMARY_CHAIN_WALLET_PROVIDER_NETWORK_MAP = {
  [ETHEREUM_SYMBOL]: WalletProviderNetwork.Ethereum,
  [SOLANA_SYMBOL]: WalletProviderNetwork.Solana,
  [BITCOIN_SYMBOL]: WalletProviderNetwork.Bitcoin,
  [LITECOIN_SYMBOL]: WalletProviderNetwork.Litecoin,
  [DOGECOIN_SYMBOL]: WalletProviderNetwork.Dogecoin,
};

import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { getPlatformName } from 'cb-wallet-metadata/metadata';
import { PlatformName } from '@cbhq/client-analytics';

export type MiamiViceFeatureFlag =
  | 'wallet_redesign_payments_tab'
  | 'wallet_redesign_browser_trending_dapps'
  | 'wallet_redesign_top_level_messaging_tab'
  | 'wallet_redesign_miami_vice_theme_colors'
  | 'wallet_redesign_transactions_tab'
  | 'wallet_view_profile'
  | 'wallet_redesign_fsto'
  | 'wallet_redesign_miami_button'
  | 'wallet_redesign_avatars';

export function useIsMiamiViceFeatureEnabled(feature: MiamiViceFeatureFlag) {
  const platform = getPlatformName();

  const isWalletNewArchEnabled = useIsFeatureEnabled('wallet_app_architecture');

  const isWalletMiamiViceRedesignEnabled = useIsFeatureEnabled('wallet_redesign_miami_vice');
  const isKillswitchEnabled = useIsFeatureEnabled(feature);

  return (
    (platform === PlatformName.web || platform === PlatformName.extension
      ? true
      : isWalletNewArchEnabled) &&
    isWalletMiamiViceRedesignEnabled &&
    isKillswitchEnabled
  );
}

import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { deepEqual } from '@wagmi/core';
import { useRouter } from 'next/router';
import qs from 'qs';

import { useLinkToGlobalDrawer } from './hooks/useLinkToGlobalDrawer';
import { generateDrawerContentParams } from './utils/generateDrawerContentParams';

export type OpenDrawerOptions = {
  contentParams?: Record<string, string | undefined>;
};

type GlobalDrawerContextProps = {
  content: React.ReactNode | null;
  isOpen: boolean;
  openDrawer: (content: React.ReactNode, options?: OpenDrawerOptions) => void;
  closeDrawer: () => void;
};

const GlobalDrawerContext = createContext<GlobalDrawerContextProps>({
  content: null,
  isOpen: false,
  openDrawer: () => {},
  closeDrawer: () => {},
});

type GlobalDrawerProviderProps = {
  children: React.ReactNode;
};

export function useRouteChangeStart(setContent: Dispatch<SetStateAction<ReactNode>>) {
  const router = useRouter();
  return useCallback(
    (url: string) => {
      // We need to remove the locale from the url to compare it with the previous path
      // because the locale is not included in the asPath
      // https://github.com/vercel/next.js/pull/18807/files
      const path = url.split('?')[0];
      const nextPath = path.endsWith(`/${router.locale}`)
        ? path.replace(`/${router.locale}`, '/')
        : path.replace(`/${router.locale}`, '');
      const previousPath = router.asPath.split('?')[0];
      if (nextPath !== previousPath) {
        setContent(null);
      }
    },
    [router.asPath, router.locale, setContent],
  );
}

export function GlobalDrawerProvider({ children }: GlobalDrawerProviderProps) {
  const [content, setContent] = useState<React.ReactNode | null>(null);
  const router = useRouter();
  const previousQueryString = useRef<qs.ParsedQs>({});

  const openDrawer = useCallback(
    (drawerContent: React.ReactNode, options?: OpenDrawerOptions) => {
      const querystring = generateDrawerContentParams(options?.contentParams);
      const parsedCurrentQueryString = qs.parse(querystring, { ignoreQueryPrefix: true });
      if (!deepEqual(previousQueryString.current, parsedCurrentQueryString)) {
        router.replace(`${window.location.pathname}${querystring}`);
      }
      previousQueryString.current = parsedCurrentQueryString;
      setContent(drawerContent);
    },
    [router],
  );

  const closeDrawer = useCallback(async () => {
    const querystring = generateDrawerContentParams();
    const parsedCurrentQueryString = qs.parse(querystring, { ignoreQueryPrefix: true });
    if (!deepEqual(previousQueryString.current, parsedCurrentQueryString)) {
      await router.replace(`${window.location.pathname}${querystring}`);
    }
    previousQueryString.current = {};
    setContent(null);
  }, [router]);

  // Closes the drawer when the route changes
  const handleRouteChangeStart = useRouteChangeStart(setContent);
  useEffect(
    function routeChangeStart() {
      // Close the drawer when the route changes
      router.events.on('routeChangeStart', handleRouteChangeStart);
      return () => {
        router.events.off('routeChangeStart', handleRouteChangeStart);
      };
    },
    [handleRouteChangeStart, router.events],
  );

  // Allows for deep linking to the global drawer
  // Any drawer that should be linked to needs to
  // be defined in this hook
  useLinkToGlobalDrawer({ openDrawer });

  const ctx = useMemo(
    () => ({ isOpen: !!content, openDrawer, closeDrawer, content }),
    [openDrawer, closeDrawer, content],
  );

  return <GlobalDrawerContext.Provider value={ctx}>{children}</GlobalDrawerContext.Provider>;
}

export function useGlobalDrawer() {
  const context = useContext(GlobalDrawerContext);
  /* istanbul ignore next */
  if (!context) {
    /* istanbul ignore next */
    throw new Error('useGlobalDrawer must be used within a GlobalDrawerProvider');
  }
  return context;
}

import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { Store } from 'cb-wallet-store/Store';

export const StoreKeys_userId = new LocalStorageStoreKey<string>('userId');

// Used to set the X-Wallet-User-Id header
export function getUserIdFromStorage(): string | undefined {
  return Store.get<string>(StoreKeys_userId);
}

export function setUserIdInStorage(userId?: string) {
  Store.set(StoreKeys_userId, userId);
}

import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { parseTransfers } from 'cb-wallet-data/stores/Transactions/methods/parseTransfers';
import { TxOrUserOp } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import {
  V1AddressMeta,
  V1SpamScoreThresholds,
  V1Transaction,
  V1UserOperation,
} from '@cbhq/instant-api-hooks-wallet-tx-history/types';

/**
 * HRT = human readable transaction
 * @param tx
 * @param userAddress
 * @param blockchain
 * @param network
 * @param accountId
 * @param walletIndex
 * @param spamScoreThresholds
 * @param addressMetadata
 */
export function parseHRTTransaction({
  tx,
  userAddress,
  blockchain,
  network,
  accountId,
  walletIndex,
  spamScoreThresholds,
  addressMetadata,
}: {
  tx: V1Transaction;
  userAddress: string;
  blockchain: Blockchain;
  network: Network;
  accountId: Account['id'];
  walletIndex: bigint;
  spamScoreThresholds?: V1SpamScoreThresholds;
  addressMetadata: Record<string, V1AddressMeta>;
}): (TxOrUserOp | undefined)[] {
  const chain = network.asChain();

  if (!chain) {
    return [undefined];
  }

  const userOps = tx.userOperations;

  if (userOps && userOps.length > 0) {
    return userOps.map(function mapUserOpToTransfer(
      userOp: V1UserOperation,
    ): TxOrUserOp | undefined {
      return parseTransfers({
        primaryAction: userOp.primaryAction,
        feeAmount: userOp.feeAmount,
        historicalAssetPricesUsd: tx.historicalAssetPricesUsd,
        txNonce: tx.nonce!,
        txStatus: tx.status!,
        txTimestamp: tx.timestamp!,
        txHash: tx.hash!,
        userAddress,
        blockchain,
        network,
        accountId,
        walletIndex,
        spamScoreThresholds,
        addressMetadata,
        userOpHash: userOp.hash!,
        inputTransfers: userOp.transfers,
      });
    });
  }

  const result = parseTransfers({
    primaryAction: tx.primaryAction,
    feeAmount: tx.feeAmount,
    historicalAssetPricesUsd: tx.historicalAssetPricesUsd,
    txNonce: tx.nonce!,
    txStatus: tx.status!,
    txTimestamp: tx.timestamp!,
    txHash: tx.hash!,
    userAddress,
    blockchain,
    network,
    accountId,
    walletIndex,
    spamScoreThresholds,
    addressMetadata,
    userOpHash: undefined,
    inputTransfers: tx.transfers,
  });

  return [result];
}

// TODO we'll be gradually moving some of this over to wallet engine
export const NAME = 'Solana';

export const SOLANA_SYMBOL = 'SOL';

export const BLOCKCHAIN_SOLANA_MAINNET_IMAGE_URL =
  'https://asset-metadata-service-production.s3.amazonaws.com/asset_icons/b658adaf7913c1513c8d120bcb41934a5a4bf09b6adbcb436085e2fbf6eb128c.png';

export const SOLANA_CURRENCY_DECIMAL = 9n;

export const LAMPORT_CURRENCY = 'lamport';

export const SOLANA_SIGNATURE_LENGTH = 64;

export const DEFAULT_SOLANA_PRIORITY_FEE = 100_000n; // denoted in micro lamports

// default compute unit limit of 200,000 is the network default already
export const DEFAULT_SOLANA_COMPUTE_UNIT_LIMIT = 200_000;

// denoted in bytes, this is the combined size of a setComputePrice and a setComputeLimit instruction – this is static and should not changed based on values
export const SOLANA_PRIORITY_INSTRUCTIONS_SIZE = 52;

export const SOLANA_TRANSACTION_MAX_SIZE = 1232; // denoted in bytes

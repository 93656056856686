/* eslint camelcase: 0 */

// Color Logs
export const DEFAULT_COLORS = {
  positive: '#00A45F',
  warning: '#FF9900',
  negative: '#E5212E',
};

export const PERF_MARKS = {
  collections: 'collections',
  collections_v2: 'collections_v2',
  onboarding: 'onboarding',
  createNewMnemonicAccount: 'create_new_mnemonic_account',
  importNewMnemonicAccount: 'import_new_mnemonic_account',
  import_ledger_addresses: 'import_ledger_addresses',
  cloud_backups: 'cloud_backups',
  receive: 'receive',
  send: 'send',
  swap: 'swap',
  subdomain: 'subdomain',
  assets: 'assets',
  wallets: 'wallets',
  browser: 'browser',
  nft_detail: 'nft_detail',
  nft_detail_asset: 'nft_detail_asset',
  nft_detail_history_tab: 'nft_detail.history_tab',
  nft_detail_offers_tab: 'nft_detail.offers_tab',
  nft_detail_reservoir_offers_tab: 'nft_detail.reservoir_offers_tab',
  nft_offer_detail: 'nft_offer_detail',
  nft_offer_detail_reservoir: 'nft_offer_detail_reservoir',
  nft_listing_detail: 'nft_listing_detail',
  nft_all_offers: 'nft_all_offers',
  nft_all_offers_card: 'nft_all_offers_card',
  nft_paginated_collections: 'nft_paginated_collections',
  nft_grid_view: 'nft_grid_view',
  nft_collections_grid_view: 'nft_collections_grid_view',
  bridge: 'bridge',
  collection_details: 'collection_details',
  explore: 'explore',
  profile: 'profile',
  sign_out: 'sign_out',
  watchlist: 'watchlist',
  tx_history: 'tx_history',
  seed_from_recovery_time: 'seed_from_recovery_time',
  stake: 'stake',
  cashout: 'cashout',
};

export const ONBOARDING_MARKS = {
  navigate_to_restore_wallet: 'navigate_to_restore_wallet',
  navigate_to_protect_wallet: 'navigate_to_protect_wallet',
  navigate_to_create_passcode: 'navigate_to_create_passcode',
  navigate_to_pick_username: 'navigate_to_pick_username',
  navigate_to_pick_username_import: 'navigate_to_pick_username_import',
  navigate_to_import_mnemonic: 'navigate_to_import_mnemonic',
  navigate_to_import_guide: 'navigate_to_import_guide',
  navigate_to_verify_mnemonic: 'navigate_to_verify_mnemonic',
  navigate_to_backup_mnemonic: 'navigate_to_backup_mnemonic',
  navigate_to_cloud_backup_password: 'navigate_to_cloud_backup_password',
  navigate_to_cloud_recovery_choose_wallet: 'navigate_to_cloud_recovery_choose_wallet',
  navigate_to_cloud_recovery_password: 'navigate_to_cloud_recovery_password',
  navigate_to_success: 'navigate_to_success',
  set_biometrics: 'set_biometrics',
  complete_import: 'complete_import',
  complete_import_ledger: 'complete_import_ledger',
  complete_import_tti: 'complete_import_tti',
  balances_loaded: 'balances_loaded',
  load_username: 'load_username',
  load_backup_wallet: 'load_backup_wallet',
  load_verify_phrase: 'load_verify_phrase',
  load_password: 'load_password',
  load_import_wallet: 'load_import_wallet',
  load_recovery_phrase: 'load_recovery_phrase',
  load_wallet_link: 'load_wallet_link',
  load_scan_qr: 'load_scan_qr',
};

export const COLLECTION_MARKS = {
  navigate_to_collections: 'navigate_to_collections',
  navigate_to_paginated_collections: 'navigate_to_paginated_collections',
  navigate_to_collection: 'navigate_to_collection',
  navigate_to_collectible_detail: 'navigate_to_collectible_detail',
  load_first_collectible: 'load_first_collectible',
  navigate_to_collection_detail: 'navigate_to_collection_detail',
  load_collectible_detail: 'load',
  load_collectible_detail_history: 'load',
  load_collectible_detail_offers: 'load',
  load_collectible_detail_asset: 'load',
  load_collectible_detail_asset_cloudinary: 'load_cloudinary',
  load_collectible_offer_detail: 'load',
  load_collection_detail: 'load',
  load_collection_all_offers: 'load_collection_all_offers',
  load_collection_all_offers_card: 'load_collection_all_offers_card',
  load_collectible_accept_offer_tray: 'load_collectible_accept_offer_tray',
  load_paginated_collectibles: 'load_paginated_collectibles',
  load_collectible_accept_offer_data: 'load_collectible_accept_offer_data',
};

export const RECEIVE_MARKS = {
  navigate_to_receive_tabs: 'navigate_to_receive_tabs',
  navigate_to_receive_other_assets: 'navigate_to_receive_other_assets',
  open_receive_qr_modal: 'open_receive_qr_modal',
};

export const SEND_MARKS = {
  navigate_to_send_input: 'navigate_to_send_input',
  navigate_to_send_select_asset: 'navigate_to_send_select_asset',
  navigate_to_send_select_recipient: 'navigate_to_send_select_recipient',
  navigate_to_send_confirmation: 'navigate_to_send_confirmation',
  navigate_to_send_success: 'navigate_to_send_success',
  navigate_to_send_authenticator: 'navigate_to_send_authenticator',
};

export const SWAP_MARKS = {
  launch_swap: 'launch_swap',
  load_preview: 'load_preview',
  trigger_swap: 'trigger_swap',
  navigate_to_swap_select_asset: 'navigate_to_swap_select_asset',
  navigate_to_swap_status: 'navigate_to_swap_status',
  load_transactions: 'load_transactions',
  navigate_to_enter_passcode: 'navigate_to_enter_passcode',
};

export const BRIDGE_MARKS = {
  launch_bridge: 'launch_bridge',
  load_preview: 'load_preview',
  trigger_bridge: 'trigger_bridge',
  navigate_to_bridge_select_asset: 'navigate_to_bridge_select_asset',
  navigate_to_bridge_status: 'navigate_to_bridge_status',
  load_transactions: 'load_transactions',
  navigate_to_enter_passcode: 'navigate_to_enter_passcode',
};

export const ASSETS_MARKS = {
  load_assets: 'load_assets',
  load_assets_tti: 'load_assets_tti',
  load_assets_full: 'load_assets_full',
  load_assets_full_tti: 'load_assets_full_tti',
  navigate_to_asset_detail: 'navigate_to_asset_detail',
  navigate_to_asset_tx_details: 'navigate_to_asset_tx_details',
  navigate_to_transaction_detail_from_asset_detail:
    'navigate_to_transaction_detail_from_asset_detail',
  navigate_to_transaction_detail_from_asset_tx_details:
    'navigate_to_transaction_detail_from_asset_tx_details',
};

export const BROWSER_MARKS = {
  load_browser_home: 'load_browser_home',
  load_dapp: 'load_dapp',
};

export const WALLET_MARKS = {
  create_wallets: 'create_wallets',
};

export const CLOUD_BACKUP_MARKS = {
  complete_backup: 'complete_backup',
  complete_restore: 'complete_restore',
};

export const EXPLORE_MARKS = {
  load_nft_collections: 'load_nft_collections',
  load_upcoming_drops: 'load_upcoming_drops',
  load_top_coins: 'load_top_coins',
  load_top_movers: 'load_top_movers',
  load_trending_coins: 'load_trending_coins',
  load_gaming_tokens: 'load_gaming_tokens',
  load_recommended_coins: 'load_recommended_coins',
  load_collectible_listing_detail: 'load_collectible_listing_detail',
  load_collectible_listing_confirmation: 'load_collectible_listing_confirmation',
  load_collectible_listing_status: 'load_collectible_listing_status',
  load_ocs_upcoming_drops: 'load_ocs_upcoming_drops',
  load_ocs_minting: 'load_ocs_minting',
  load_nft_promotion: 'load_nft_promotion',
  load_spotlight: 'load_spotlight',
  load_explore_on_home: 'load_explore_on_home',
};

export const WATCHLIST_MARKS = {
  navigate_to_watchlist: 'navigate_to_watchlist',
  navigate_to_watchlist_discovery: 'navigate_to_watchlist_discovery',
};

export const PROFILE_MARKS = {
  profile_page_load: 'profile_page_load',
  crypto_tab_load: 'crypto_tab_load',
  activity_tab_load: 'activity_tab_load',
  assets_tab_load: 'assets_tab_load',
  profile_details_load: 'profile_details_load',
  profile_header_load: 'profile_header_load',
  manage_profile_load: 'manage_profile_load',
  manage_privacy_load: 'manage_privacy_load',
  claim_subdomain_load: 'claim_subdomain_load',
};

export const SUBDOMAIN_MARKS = {
  load_subdomain_nux_username: 'load_subdomain_nux_username',
  load_subdomain_nux_success: 'load_subdomain_nux_success',
  load_subdomain_existing_user: 'load_subdomain_existing_user',
  load_subdomain_existing_user_success: 'load_subdomain_existing_user_success',
  load_subdomain_existing_user_decline: 'load_subdomain_existing_user_decline',
  load_subdomain_existing_username: 'load_subdomain_existing_username',
  load_subdomain: 'load_subdomain',
  load_subdomain_claim: 'load_subdomain_claim',
  load_subdomain_change: 'load_subdomain_change',
  load_subdomain_invalid: 'load_subdomain_invalid',
  load_subdomain_revoke: 'load_subdomain_revoke',
};

export const SIGN_OUT_MARKS = {
  complete_sign_out: 'complete_sign_out',
};

export const TX_HISTORY_MARKS = {
  full_sync: 'full_sync',
  update_sync: 'update_sync',
};

export const SEED_MARKS = {
  publicKeySolanaExt: 'public_key_solana_ext',
  publicKeySolanaRN: 'public_key_solana_rn',
  partialSignSolanaTransactionExt: 'partial_sign_solana_transaction_ext',
  partialSignSolanaTransactionRN: 'partial_sign_solana_transaction_rn',
  ed25519PublicKeyExt: 'ed25519_public_key_ext',
  ed25519PublicKeyRN: 'ed25519_public_key_rn',
  secp256k1PublicKeyExt: 'secp256k1_public_key_ext',
  secp256k1PublicKeyRN: 'secp256k1_public_key_rn',
  signAndSubmitUTXOTransactionExt: 'sign_and_submit_utxo_transaction_ext',
  signAndSubmitUTXOTransactionRN: 'sign_and_submit_utxo_transaction_rn',
  signAndSubmitXLMTransactionExt: 'sign_and_submit_xlm_transaction_ext',
  signAndSubmitXLMTransactionRN: 'sign_and_submit_xlm_transaction_rn',
  signAndSubmitXRPTransactionExt: 'sign_and_submit_xrp_transaction_ext',
  signAndSubmitXRPTransactionRN: 'sign_and_submit_xrp_transaction_rn',
  signETH1559TransactionExt: 'sign_eth_1559_transaction_ext',
  signETH1559TransactionRN: 'sign_eth_1559_transaction_rn',
  signETH2930TransactionExt: 'sign_eth_2930_transaction_ext',
  signETH2930TransactionRN: 'sign_eth_2930_transaction_rn',
  signEthereumMessageExt: 'sign_ethereum_message_ext',
  signEthereumMessageRN: 'sign_ethereum_message_rn',
  signETHLegacyTransactionExt: 'sign_eth_legacy_transaction_ext',
  signETHLegacyTransactionRN: 'sign_eth_legacy_transaction_rn',
  signSolanaMessageExt: 'sign_solana_message_ext',
  signSolanaMessageRN: 'sign_solana_message_rn',
  signSolanaTransactionExt: 'sign_solana_transaction_ext',
  signSolanaTransactionRN: 'sign_solana_transaction_rn',
  signETH712TransferAuthorizationExt: 'sign_eth_712_transfer_authorization_ext',
  signETH712TransferAuthorizationRN: 'sign_eth_712_transfer_authorization_rn',
  signETH712MessageExt: 'sign_eth_712_message_ext',
  signETH712MessageRN: 'sign_eth_712_message_rn',
  xpubKeyExt: 'xpub_key_ext',
  xpubKeyRN: 'xpub_key_rn',
  onboarding: 'onboarding',
  createNewMnemonicAccount: 'create_new_mnemonic_account',
  importNewMnemonicAccount: 'import_new_mnemonic_account',
  // inconsistent naming for create/import/add should be fixed
  'add-account': 'add-account',
  deriveETHAddress: 'derive_eth_address',
  getMainEthAddressFromMnemonic: 'get_main_eth_address_from_mnemonic',
  getEthereumWalletKeyPair: 'get_ethereum_wallet_key_pair',
  generateMnemonicSignature: 'generate_mnemonic_signature',
} as const;

export const STAKE_MARKS = {
  launch_stake: 'launch_stake',
  load_preview: 'load_preview',
  trigger_stake: 'trigger_stake',
  navigate_to_stake_status: 'navigate_to_stake_status',
  load_transactions: 'load_transactions',
  navigate_to_enter_passcode: 'navigate_to_enter_passcode',
  load_claim_preview: 'load_claim_preview',
  navigate_to_claim_status: 'navigate_to_claim_status',
  trigger_claim: 'trigger_claim',
};

export const CASHOUT_MARKS = {
  navigate_to_cashout_input: 'navigate_to_cashout_input',
};

export function toExtensionName(name: string) {
  return name[0].toUpperCase() + name.slice(1);
}

import { QueryFunction, QueryKey, useQueryClient } from '@tanstack/react-query';

import { EnabledIf } from '../typeUtils';

import { useOnMount } from './useOnMount';

type Params<TQueryFnData, TQueryKey extends QueryKey, TEnabled extends boolean> = {
  /**
   * queryKey: The key used to identify and cache the query. Always try to include all the dependencies of the queryFn in the queryKey.
   */
  queryKey: TQueryKey;
  /**
   * queryFn: An async function that will either resolve to the data or throw an error.
   * This function will trigger a suspense if the data is not available in cache.
   */
  queryFn: QueryFunction<TQueryFnData, TQueryKey>;
  /**
   * enabled: If the query should be enabled or not.
   */
  enabled: TEnabled;
};

/**
 * useSuspendable
 *
 * This hook is a lighter version of useQuery and doesn't have any options like
 * staleTime, cacheTime etc and will always suspend if the data is not available in cache.
 *
 * Use this hook incases where useQuery is adding performance overhead. This is
 * a custom hook which uses useQueryClient and makes use of prefetchQuery and
 * fetchQuery to fetch data and return from cache if available or suspend if not available.
 *
 * @param queryKey - The key used to identify and cache the query.
 * @param queryFn - An async function that will either resolve to the data or throw an error.
 * @param enabled - If the query should be enabled or not.
 */
function useSuspendable<
  TQueryFnData,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  TError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
  TEnabled extends boolean = true,
>({
  queryKey,
  queryFn,
  enabled,
}: Params<TQueryFnData, TQueryKey, TEnabled>): EnabledIf<
  Params<TQueryFnData, TQueryKey, TEnabled>['enabled'],
  TData,
  undefined
>;
function useSuspendable<
  TQueryFnData,
  TError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
  TEnabled extends boolean = true,
>({ queryKey, queryFn, enabled }: Params<TQueryFnData, TQueryKey, TEnabled>): TData | undefined {
  const queryClient = useQueryClient();

  useOnMount(function fetchOnMount() {
    function cancelQueries() {
      queryClient.cancelQueries({ queryKey, exact: true });
    }

    if (enabled) {
      const state = queryClient.getQueryState<TData, TError>(queryKey);
      /**
       * Alert Hacky Code: This condition is a hacky one to prevent background fetch on first request or when data is not in cache.
       * Ideally we can solve this using QueryObserver, but it has performance overhead.
       *
       * What it is doing?
       * if fetch happened just within a second ago, we don't want to trigger a background fetch.
       * this case needs to be handled as we suspend on first request / or when no data in cache
       * and this function won't be called until suspense is resolved.
       */
      if (state?.dataUpdatedAt && Date.now() - state.dataUpdatedAt < 1000) {
        return cancelQueries;
      }

      // this is to fetch in background. prefetchQuery is async and will ensure that duplicate requests for this query are not created.
      queryClient.prefetchQuery<TQueryFnData, TError, TData, TQueryKey>(queryKey, queryFn);

      return cancelQueries;
    }
  });

  // If not enabled we don't want to suspend and return here itself
  if (!enabled) {
    return;
  }

  // If there is cached data we want to return from cache and not suspend
  const cachedData = queryClient.getQueryData<TData>(queryKey);
  if (cachedData !== undefined) {
    return cachedData;
  }

  const queryState = queryClient.getQueryState<TData, TError>(queryKey);
  // If the status is undefined or loading the query either hasn't started fetching or is fetching so we suspend here.
  if (queryState?.status === undefined || queryState?.status === 'loading') {
    // fetchQuery is async and will ensure that duplicate requests for this query are not created.
    throw queryClient.fetchQuery(queryKey, queryFn);
  }

  // This returns resolved data from query state
  if (queryState.status === 'success') {
    return queryState.data;
  }

  if (queryState.status === 'error') {
    throw queryState.error;
  }
}

export { useSuspendable };

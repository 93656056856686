import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { Store } from 'cb-wallet-store/Store';

export function setHasRunTxOrUserOpMigration() {
  Store.set(StoreKeys_hasRunTxOrUserOpMigration, true);
}

export function hasRunTxOrUserOpMigration(): boolean {
  return Store.get<boolean>(StoreKeys_hasRunTxOrUserOpMigration) ?? false;
}

export const StoreKeys_hasRunTxOrUserOpMigration = new LocalStorageStoreKey<string>(
  'hasRunTxOrUserOpMigration2',
);

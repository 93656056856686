import { useRecoilValue } from 'recoil';

import { AccountTotals, accountTotalsSelector } from '../state';

/**
 * useAccountTotals
 *
 * @see useUserFacingAccountTotals for user-facing account totals
 */
export function useAccountTotals(): AccountTotals {
  return useRecoilValue(accountTotalsSelector);
}

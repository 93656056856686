import { getApplicationMetadata } from 'cb-wallet-metadata/metadata';
import {
  createNewSpan,
  getTracingHeadersFromSpan,
  logTrace,
  Span,
  SpanUpdate,
} from '@cbhq/client-analytics';

/**
 * We sample 1% of total requests
 */
export const DEFAULT_SAMPLING_LOGIC = 0.01;

/**
 * Converts nanoseconds to milliseconds and round up to the nearest millisecond
 * @param nanoseconds
 */
function nanosecondsToMilliseconds(nanoseconds: number): number {
  return Math.ceil(nanoseconds / 1e6); // 1 millisecond is equal to 1,000,000 nanoseconds
}

function sanitizeUrlPath(inputUrl: string): string {
  // Use a regular expression to remove the query parameters
  const sanitizedUrl = inputUrl.replace(/\?.*$/, '');

  return sanitizedUrl;
}

/**
 * we want to sample traces directly on the client to avoid over tracing and increase cost
 * @param p probability between 0,1 of sending the trace
 */
export function canSendTrace(p = DEFAULT_SAMPLING_LOGIC): boolean {
  return Math.random() <= p;
}

/**
 * Creates a new network trace span
 * @param {string } resource the resource being requested
 * @returns {Span|null} object
 */
export function startNetworkSpan(resource: string): Span | null {
  if (!canSendTrace()) {
    return null;
  }
  const start = Date.now();
  const { version } = getApplicationMetadata();

  /**
   * return a span object
   */
  return createNewSpan({
    name: 'network_request',
    resource: sanitizeUrlPath(resource),
    start,
    meta: {
      version: version ?? '0.0.0',
    },
  });
}

export function stopNetworkTrace(span: Span | null, spanUpdates: SpanUpdate = {}): void {
  if (!span) {
    return;
  }

  try {
    const stop = Date.now();
    // convert span.start to milliseconds
    logTrace([span], { duration: stop - nanosecondsToMilliseconds(span.start), ...spanUpdates });
  } catch (error) {
    reportError(error);
  }
}

export function getNetworkTracingHeaders(span: Span | null): Record<string, string> {
  if (!span) {
    return {};
  }

  return getTracingHeadersFromSpan(span);
}

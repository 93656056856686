import { Fallback, HStack, VStack } from '@cbhq/cds-web/layout';

import { TEXT_INPUT_HEIGHT } from './AmountInput';

export function AmountInputLoadingFallback() {
  return (
    <HStack gap={1} spacingTop={2} testID="amount-input-loading">
      <VStack gap={1} width="100%">
        <HStack minHeight={TEXT_INPUT_HEIGHT} flexWrap="nowrap" width="100%">
          <Fallback height={56} width={125} />
        </HStack>
        <HStack spacingTop={1} alignItems="center" spacingHorizontal={0}>
          <Fallback width={125} height={20} />
        </HStack>
      </VStack>
    </HStack>
  );
}

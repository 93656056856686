import { ServiceLocator } from '../ServiceLocator';

import { SigningAndDerivationMethods } from './types/SigningAndDerivationMethods';

/**
 * This is a service locator for all of the derivation functions, which we're passing through
 * the native layer to a webview in iOS, for better performance.
 *
 * Any functions hooked up here that require the seed or mnemonic, will only be used when it
 * is readily available like from onboarding or MMA flows; otherwise, an equivalent function from
 * the signing service locator will be used, which will access the mnemonic safely through
 * the native layer when on mobile.
 *
 * @deprecated
 * This is not being deprecated, but we are using this to make it more clear that you should not
 * be using this ServiceLocator directly.
 *
 * This should only be called to `setAll` the service locator implementations.
 * It can also be used to set up mocks in tests.
 *
 * To retrieve and use a method, simply call `getSigningOrDerivationMethod(method)`
 *
 * @see {@link getSigningOrDerivationMethod}
 * */
export const signingAndDerivationMethods = new ServiceLocator<SigningAndDerivationMethods>();

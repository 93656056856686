import keyBy from 'lodash/keyBy';
import {
  DroppedAddressesError,
  InvalidAddressError,
  SyncedBlockheightError,
  UTXOBalanceSyncingError,
} from 'wallet-engine-signing/history/errors';

import { getBalances, GetBalancesResponse } from './getBalances';
import { getBlockHeight } from './getBlockheight';
import { SupportedUTXOChain, UTXOAddressConfig, UTXOAddressHistory } from './types';

export function getUTXOCacheId(addressConfig: UTXOAddressConfig) {
  const { address, testnet } = addressConfig;
  return `${address.toLowerCase()}/${testnet}`;
}

export function initializeUTXOCacheItem(addressConfig: UTXOAddressConfig): UTXOAddressHistory {
  return {
    ...addressConfig,
    balance: BigInt(0),
    isUsed: false,
    unsyncedTransactions: [],
  };
}

export async function getBlockheight(blockchainSymbol: SupportedUTXOChain) {
  return getBlockHeight({
    testnet: false,
    blockchainSymbol,
  });
}

export async function refreshUTXOBalances(
  addresses: UTXOAddressHistory[],
  lastSyncedBlockheight: number,
): Promise<{
  updates: UTXOAddressHistory[];
  blockheight: number;
  errors: (DroppedAddressesError | UTXOBalanceSyncingError)[];
}> {
  const [{ blockchainSymbol, testnet }] = addresses;
  const batchSize = 20;
  const addressBatches = [];
  const errors = [];

  for (let i = 0; i < addresses.length; i += batchSize) {
    addressBatches.push(addresses.slice(i, i + batchSize));
  }

  const addressMap: Record<UTXOAddressHistory['address'], UTXOAddressHistory> = keyBy(
    addresses,
    'address',
  );

  const results = await Promise.allSettled(
    addressBatches.map(async (addressBatch) => {
      const utxos = await getBalances({
        addresses: addressBatch.map(({ address }) => address),
        blockchainSymbol,
        testnet,
        lastSyncedBlockheight,
      });

      return utxos;
    }),
  );

  const isFulfilled = (
    input: PromiseSettledResult<GetBalancesResponse>,
  ): input is PromiseFulfilledResult<GetBalancesResponse> => input.status === 'fulfilled';

  const isRejected = (
    input: PromiseSettledResult<GetBalancesResponse>,
  ): input is PromiseRejectedResult => input.status === 'rejected';

  const successes = results.filter(isFulfilled).map(({ value }) => value);
  const failures = results.filter(isRejected).map(({ reason }) => reason);

  if (failures.length) {
    errors.push(new UTXOBalanceSyncingError(`Failed to fetch UTXO balances: ${failures[0]}}`));
  }

  const serverSyncedBlockheight = Math.min(
    ...successes.map(({ syncedBlockheight }) => syncedBlockheight),
  );

  const updates = successes.flatMap((result) => {
    return Object.values(result.addresses ?? []).map(({ address, balance, isUsed }) => {
      const addressConfig = addressMap[address];
      return {
        ...addressConfig,
        balance,
        isUsed,
      };
    });
  });

  const droppedAddresses = successes.flatMap(({ failedAddresses }) => failedAddresses);
  const invalidAddresses = successes.flatMap(({ invalidAddresses: invalid }) => invalid);

  if (droppedAddresses.length) {
    const droppedAddressesFailureRate = droppedAddresses.length / addresses.length;
    errors.push(
      new DroppedAddressesError(
        `Failed to fetch balances for ${droppedAddresses.length} out of ${addresses.length} addresses`,
        droppedAddressesFailureRate,
      ),
    );
  }

  if (droppedAddresses.length && serverSyncedBlockheight > lastSyncedBlockheight) {
    errors.push(
      new SyncedBlockheightError(
        `Server updated blockheight to ${serverSyncedBlockheight} even though there were errors`,
      ),
    );
  }

  if (invalidAddresses.length) {
    invalidAddresses.forEach((address) => {
      errors.push(new InvalidAddressError(`Client requested invalid address: ${address}`));
    });
  }

  const blockheight = errors.length ? lastSyncedBlockheight : serverSyncedBlockheight;

  return {
    updates,
    blockheight,
    errors,
  };
}

import { useEffect, useMemo, useState } from 'react';

import { WalletProviderInfo, WalletProviderNetworkInfo } from ':dapp/connection/types';

export async function getAvailableNetworks(walletProvider: WalletProviderInfo) {
  const networksAvailable = await Promise.all(
    walletProvider.networks.map(async (network: WalletProviderNetworkInfo) =>
      walletProvider.connectorManager.isReady(network.id),
    ),
  );
  return walletProvider.networks.filter((_, i) => networksAvailable[i]);
}

/**
 * Returns the available networks for a given wallet provider.
 * Use this hook to get the available networks for a given wallet provider.
 */
export function useAvailableNetworks(walletProvider: WalletProviderInfo) {
  const [networks, setNetworks] = useState<WalletProviderNetworkInfo[]>([]);

  useEffect(
    function filterAvailableNetworks() {
      (async () => {
        const availableNetworks = await getAvailableNetworks(walletProvider);
        setNetworks(availableNetworks);
      })();
    },
    [walletProvider],
  );

  return useMemo(() => networks, [networks]);
}

import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { Span, updateSpan } from '@cbhq/client-analytics';
import { MiscMetadata } from '@cbhq/error-vitals-core';

export class TracerUtils {
  public static NS_IN_A_MS = 1_000_000;

  /**
   * getCurrentTimeInNanoseconds
   */
  private static getCurrentTimeInNanoseconds() {
    // performance.now() returns milliseconds elapsed
    // convert it to nanoseconds
    return TracerUtils.getPerformanceNow() * TracerUtils.NS_IN_A_MS;
  }

  /**
   * calculateDuration
   */
  private static calculateDuration(span: Span) {
    return Math.round(
      (TracerUtils.getCurrentTimeInNanoseconds() - span.start) / TracerUtils.NS_IN_A_MS,
    );
  }

  /**
   * getPerformanceNow
   *
   * used only for mocking performance.now during tests
   */
  public static getPerformanceNow() {
    return performance.now();
  }

  /**
   * updateSpanDuration
   */
  public static updateSpanDuration(span: Span): Span {
    const duration = this.calculateDuration(span);
    updateSpan(span, { duration });
    return span;
  }

  /**
   * reportError
   */
  public static reportError({ error, metadata }: { error: Error; metadata: MiscMetadata }) {
    cbReportError({
      error,
      context: 'tracing',
      metadata,
      severity: 'error',
      isHandled: false,
    });
  }
}

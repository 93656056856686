import {
  LitecoinBRDAddressType,
  LitecoinLegacyAddressType,
  LitecoinSegwitAddressType,
} from 'cb-wallet-data/chains/UTXO/Litecoin/config';
import { AddressType } from 'cb-wallet-data/stores/Addresses/models/AddressType';

export function xpubKeyDerivationPath(
  addressType: AddressType,
  testnet: boolean,
): string | undefined {
  switch (addressType.rawValue) {
    case LitecoinSegwitAddressType.rawValue:
      return testnet ? "m/84'/1'/0'" : "m/84'/2'/0'";
    case LitecoinLegacyAddressType.rawValue:
      return testnet ? "m/44'/1'/0'" : "m/44'/2'/0'";
    case LitecoinBRDAddressType.rawValue:
      return testnet ? undefined : "m/44'/2'/0'";
    default:
      return undefined;
  }
}

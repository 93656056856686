import { useMemo } from 'react';
import { useCurrencyFormatter } from 'cb-wallet-data/CurrencyFormatter/hooks/useCurrencyFormatter';
import { useExchangeRatesMap } from 'cb-wallet-data/stores/ExchangeRates/hooks/useExchangeRates';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { Decimal } from 'decimal.js';

import { getTotalBalanceFromWallets } from '../utils/getTotalBalanceFromWallets';

import { DisableDeFiBalancesOptions, useDeFiAggregateBalance } from './useDeFiAggregateBalance';
import { usePortfolioWalletGroupIds } from './usePortfolioWalletGroupIds';
import { usePortfolioWallets } from './usePortfolioWallets';
import { useVisiblePortfolioWallets } from './useVisiblePortfolioWallets';
import { useWalletsForWalletGroupIds } from './useWalletsForWalletGroupIds';

export type Options = {
  showCurrencySymbol?: boolean;
  includeDeFiBalance?: boolean;
} & DisableDeFiBalancesOptions;

function usePortfolioBalanceBase(
  portfolioWallets: Wallet[] | undefined,
  {
    showCurrencySymbol = true,
    includeDeFiBalance = false,
    ...defiDisableOptions
  }: Options = {} as Options,
) {
  const exchangeRatesMap = useExchangeRatesMap();
  const { fiatValueString } = useCurrencyFormatter();

  // Active wallets used to check initial zero balances, agnostic of portfolio wallet filtering.
  const walletGroupIds = usePortfolioWalletGroupIds();
  const activeWallets = useWalletsForWalletGroupIds(walletGroupIds);

  const deFiAggregateBalance = useDeFiAggregateBalance({
    enabled: includeDeFiBalance,
    ...defiDisableOptions,
  });

  return useMemo(() => {
    if (!Object.keys(exchangeRatesMap || {}).length) {
      // If every active wallet balance is 0, we don't need to wait for the
      // exchanges rates map to be fetched.
      const isEveryActiveWalletZeroBalance =
        Boolean(activeWallets.length) && activeWallets.every((w) => w.balance === 0n);

      if (isEveryActiveWalletZeroBalance) {
        return fiatValueString(new Decimal(0), showCurrencySymbol);
      }

      // If wallets have balances to calculate and there's no exchange rates yet,
      // return undefined to trigger loading states.
      return undefined;
    }

    let cumulativeBalance = getTotalBalanceFromWallets(portfolioWallets ?? [], exchangeRatesMap);

    // If there's a positive staked balance, we should add it to "cumulativeBalance"
    if (deFiAggregateBalance.total.gt(0)) {
      cumulativeBalance = cumulativeBalance.plus(deFiAggregateBalance.total);
    }

    return fiatValueString(cumulativeBalance, showCurrencySymbol);
  }, [
    exchangeRatesMap,
    portfolioWallets,
    deFiAggregateBalance,
    fiatValueString,
    showCurrencySymbol,
    activeWallets,
  ]);
}

export function usePortfolioBalance() {
  const portfolioWallets = usePortfolioWallets();
  return usePortfolioBalanceBase(portfolioWallets);
}

export function useVisiblePortfolioBalance(options: Options = {} as Options) {
  const visibleWallets = useVisiblePortfolioWallets();

  return usePortfolioBalanceBase(visibleWallets, options);
}

/* eslint-disable no-process-env */
import { useMemo } from 'react';
import { CbKillSwitch, killswitchSet } from 'cb-wallet-data/killswitches/killswitches';
import { debugMenuKillSwitchOverridesAtom } from 'cb-wallet-data/stores/DebugMenuKillSwitches/state';
import { useAtomValue } from 'jotai';
// eslint-disable-next-line no-restricted-imports
import { useKillSwitch } from '@cbhq/client-kill-switch';

/**
 * Kill switch overrides should only be allowed in non-production environments
 *
 * This is a function that returns this value so that unit tests are able to test
 * for both enabled and disabled kill switch overrides by mocking process.env.
 *
 * NOTE: THIS MUST ALWAYS RETURN THE SAME VALUE ON A DEVICE, OR REACT WILL CRASH
 */
function enableKillSwitchOverrides(): boolean {
  const RELEASE_PRODUCTION = 'production';
  const TEST_ENVIRONMENT = 'test';

  return (
    process.env.RELEASE_ENVIRONMENT !== RELEASE_PRODUCTION &&
    process.env.RELEASE_STAGE !== RELEASE_PRODUCTION &&
    process.env.NODE_ENV !== TEST_ENVIRONMENT
  );
}

// Keep this hook signature the same as useKillSwitch from @cbhq/client-kill-switch
function useDevKillSwitch(killSwitch: CbKillSwitch | null): boolean {
  const serverSideKillSwitchValue = useKillSwitch(killSwitch);
  const debugMenuKillSwitchOverrides = useAtomValue(debugMenuKillSwitchOverridesAtom);

  // If the killSwitch is not null, check if the user has overridden the kill switch
  // locally in the debug menu.
  const isLocallyOverridden = useMemo(() => {
    return killSwitch !== null && debugMenuKillSwitchOverrides.includes(killSwitch);
  }, [killSwitch, debugMenuKillSwitchOverrides]);

  return isLocallyOverridden ? !serverSideKillSwitchValue : serverSideKillSwitchValue;
}

type WalletClientKillSwitchConfig = { [killSwitch in CbKillSwitch]: boolean };

/**
 * Returns whether a user has a kill switch enabled, taking into account local overrides
 *
 * If the killSwitch is null, this hook returns false.
 */
export function useOverridableKillSwitch(killSwitch: CbKillSwitch | null): boolean {
  if (killSwitch !== null && !killswitchSet.has(killSwitch)) {
    throw Error(`killSwitch '${killSwitch}' is not defined in the client-side killSwitches config`);
  }

  // This hook is rendered only in non-production environments because it is called
  // in many places and calls to useRecoilValue are expensive (~1ms per call in performance tests)
  if (enableKillSwitchOverrides()) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useDevKillSwitch(killSwitch);
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const killSwitchValue = useKillSwitch<WalletClientKillSwitchConfig>(killSwitch);

  return killSwitchValue;
}

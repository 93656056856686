import {
  EthereumChain,
  EthereumNetworkMap,
} from 'cb-wallet-data/chains/AccountBased/Ethereum/EthereumChain';
import { BaseChain } from 'cb-wallet-data/chains/AccountBased/shared/BaseChain';

/**
 * CAIP19 Asset Type
 * @ref https://github.com/ChainAgnostic/CAIPs/blob/main/CAIPs/caip-19.md
 */
export type CAIP19Asset = {
  chainId: string; // Namespace+Blockchain ID as per CAIP-2
  assetNamespace: string; // [-a-z0-9]{3,8}
  assetReference: string; // [-.%a-zA-Z0-9]{1,128}
  tokenId: string | undefined;
  chain: BaseChain | undefined;
};
/**
 *  Pattern to properly include all specified characters in each segment.  Also, we have an adjusted
 *  specification which allows for : instead of / so we don't product wacky URIs.. Both of these will be
 *  valid per our specification:
 *  eip155:8453:erc721:0x7b8c5980ce7131d1c394a036dc81ab2e4570e0e9:1
 *  eip155:8453/erc721:0x7b8c5980ce7131d1c394a036dc81ab2e4570e0e9/1
 * - chain_namespace and chain_reference may include a colon and hyphen as seen in common blockchain IDs.
 * - asset_namespace adheres to the 3-8 character rule, inclusive of hyphen.
 * - asset_reference expands to include characters like colon, hyphen, period, and percent.
 * - Optionally matches asset_id with token_id following the same rule set for asset_reference.
 * @private
 */
const CAIP19Pattern =
  /^([a-zA-Z0-9]+:[a-zA-Z0-9-]+)[:/]{1}([a-zA-Z0-9-]{3,8}):([-.%a-zA-Z0-9]{1,128})([:/]{1}[-.%a-zA-Z0-9]{1,78})?$/;

function parseEthererumChain(chainId: string): EthereumChain | undefined {
  const parts = chainId.split(':');
  if (parts.length === 2) {
    const chainNum = BigInt(parts[1]);
    return EthereumNetworkMap.fromChainId(chainNum);
  }
}

export class CAIP19Parser {
  public static TryParse(str: string): CAIP19Asset | undefined {
    // Pattern with capture groups for chain_id, asset_namespace, asset_reference, and an optional token_id

    const match = str.match(CAIP19Pattern);

    if (match) {
      const [, chainId, assetNamespace, assetReference, tokenId] = match;
      const asset: CAIP19Asset = {
        chainId,
        assetNamespace,
        assetReference,
        tokenId: tokenId ? tokenId.slice(1) : undefined, // Remove the leading '/' from the token_id if it exists
        chain: undefined,
      };

      // TODO: Support more chains here as we add support
      if (asset.chainId.startsWith('eip155')) {
        asset.chain = parseEthererumChain(chainId);
      }

      return asset;
    }
  }

  /**
   * Determines if
   * @param str
   * @constructor
   */
  public static IsValid(str: string) {
    return CAIP19Pattern.test(str);
  }
}

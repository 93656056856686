import { IS_DEV, IS_TEST } from ':dapp/config/env';

// The usage of useActiveWalletGroup is problematic and should only be used in the extension and RN repos.
// We've audited the app and added a list of allowed usages below inside of pano.
// These should be removed in the future as well.
const allowed = [
  'useAddBlockchainAddresses', // WALL-34881 Used for calling 'add address' - used in analytics on our backend
  'useSetBlockchainIndexesSynced', // WALL-34881 - need to remove using active wallet group id in syncing code
  'useAccountBasedAddressHistory', // WALL-34881 - need to remove using active wallet group id in syncing code
  'useBalanceUpdateWithCallbacks', // WALL-34881 - need to remove using active wallet group id in syncing code
  'useMarkAssetAsSpamOrWhitelist', // WALL-34881 - need to remove using active wallet group id in syncing code
  'useSyncAssetSettings', // WALL-34881 - need to remove using active wallet group id in syncing code
  'useKilnEarnBalanceTotalAmount', // WALL-34880 it looks like we're under counting people's kiln balance whatever that is
  'PanoOnrampContextProvider', // WALL-34879 should use useAccountsWithBalances to get the highest balance wallet as the default
  'StakeAssetDetailsEntryPoint', // WALL-34878 seems to only use active wallet group id to get a currency code
  'StakeEntryStepContent', // WALL-34878 seems to use active wallet group id to get a default wallet.
  'StakeConfirmationStepContent', // WALL-34878 StakeConfirmationStepContent calls usePopulateStakeConfirmationStep-> useSelectStakeWallet which uses active wallet group id.  This looks problematic as it could fall through to staking on the wrong wallet.
  'OnrampDebug', // Just debug code
  'NFTCollectionDetailView', // https://jira.coinbase-corp.com/browse/WALL-30010
  'useHideUserCollectible', // used to show/hide collectibles
];

// We've added a warning that will prevent additional usage of the useActiveWalletGroupId hook in the dapp.
// Please also try to not add additional usage of useActiveWalletGroupId in the extension and RN repos.
const errorText =
  "Using useActiveWalletGroupId is discouraged, expecially in the app where all wallet groups are 'active' at the same time.  If you're developing a feature that reuses a datalayer hook that uses useActiveWalletGroupId, your feature will not work in the app unless you refactor that hook to pass in the wallet group id that the user is currently using.  Ignoring this warning could lead to users sending from / to the wrong address or wasting gas fees";

export function checkForAllowedUsageOfActiveWalletGroupId(error: Error) {
  if (!IS_DEV && !IS_TEST) {
    // Probably better to not throw in production, but hopefully we never hit this
    return;
  }
  const stackTraceString = JSON.stringify(error.stack);
  if (!allowed.find((str) => stackTraceString.includes(str))) {
    const cloneError = new Error(errorText);
    cloneError.stack = error.stack;
    throw cloneError;
  }
}

// https://github.cbhq.net/infra/config-service/blob/2b99c4f536d0110c3507ba322bca3e95ff19801b/protos/coinbase/config/parameter_types.proto#L267
export const WALLET_USER_SUBJECT_TYPE = 3;
export const DEVICE_SUBJECT_TYPE = 2;

export type ReleaseEnvironment =
  | 'development'
  | 'alpha'
  | 'beta'
  | 'production'
  | 'pre-prod'
  | 'corporate'
  | 'local-prod'
  | 'local'
  | 'test'
  | 'staging'
  | 'perf_test'
  | 'perf_test_delta';

export type ExperimentsResponse = {
  groups: ExperimentGroup[];
};

export type ExperimentGroup = {
  test: string;
  group: string;
  isTracked: boolean;
};

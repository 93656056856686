import {
  BitcoinBRDLegacyAddressType,
  BitcoinBRDSegwitAddressType,
  BitcoinLegacyAddressType,
  BitcoinSegwitAddressType,
} from 'cb-wallet-data/chains/UTXO/Bitcoin/config';
import { AddressType } from 'cb-wallet-data/stores/Addresses/models/AddressType';

export function xpubKeyDerivationPath(
  addressType: AddressType,
  testnet: boolean,
): string | undefined {
  switch (addressType.rawValue) {
    case BitcoinSegwitAddressType.rawValue:
      return testnet ? "m/84'/1'/0'" : "m/84'/0'/0'";
    case BitcoinLegacyAddressType.rawValue:
      return testnet ? "m/44'/1'/0'" : "m/44'/0'/0'";
    case BitcoinBRDSegwitAddressType.rawValue:
    case BitcoinBRDLegacyAddressType.rawValue:
      return testnet ? undefined : "m/0'";
    default:
      return undefined;
  }
}

import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { Store } from 'cb-wallet-store/Store';
import { parse, stringify } from 'cb-wallet-store/utils/serialization';
import { Atom, atom, Getter, PrimitiveAtom, SetStateAction, WritableAtom } from 'jotai';
import { atomWithDefault, RESET } from 'jotai/utils';

export type SetStateActionWithReset<Value> =
  | Value
  | typeof RESET
  | ((prev: Value) => Value | typeof RESET);

function atomWithStorageInner<T>({
  initialValue,
  getStoredValue,
  setStoredValue,
}: {
  initialValue: (get: Getter) => T;
  getStoredValue: () => T | undefined;
  setStoredValue: (value: T) => void;
}): PrimitiveAtom<T> {
  const baseAtom = atomWithDefault(getStoredValue);

  return atom(
    (get) => get(baseAtom) ?? initialValue(get),
    function writeBaseAtom(get, set, update: SetStateAction<T>) {
      const nextValue =
        typeof update === 'function'
          ? (update as (prev: T) => T)(get(baseAtom) ?? initialValue(get))
          : update;
      setStoredValue(nextValue);
      set(baseAtom, nextValue);
    },
  );
}

function atomWithStorageObjectInner<T>({
  key,
  initialValue,
}: {
  key: LocalStorageStoreKey<string>;
  initialValue: (get: Getter) => T;
}) {
  return atomWithStorageInner({
    initialValue,
    getStoredValue: function getStoredValue() {
      const savedValue = Store.get(key) as string;

      if (savedValue) {
        try {
          return parse(savedValue);
        } catch (e) {
          return savedValue as unknown as T;
        }
      }

      return undefined;
    },
    setStoredValue: function setStoredValue(value: T) {
      const serialized = stringify(value);
      Store.set(key, serialized);
    },
  });
}

export function atomWithStorageObjectAndSelector<T>({
  key,
  initialValue,
}: {
  key: LocalStorageStoreKey<string>;
  initialValue: Atom<T>;
}): PrimitiveAtom<T> {
  return atomWithStorageObjectInner({
    key,
    initialValue: (get) => get(initialValue),
  });
}

export function atomWithStorageObject<T>({
  key,
  initialValue,
}: {
  key: LocalStorageStoreKey<string>;
  initialValue: T;
}): PrimitiveAtom<T> {
  return atomWithStorageObjectInner({
    key,
    initialValue: () => initialValue,
  });
}

export function atomWithStorage<T>({
  key,
  initialValue,
}: {
  key: LocalStorageStoreKey<string>;
  initialValue: T;
}): PrimitiveAtom<T> {
  return atomWithStorageInner({
    initialValue: () => initialValue,
    getStoredValue: function getStoredValue() {
      const storeValue = Store.get<T>(key);
      if (typeof storeValue !== 'undefined') {
        return storeValue as unknown as T;
      }
      return undefined;
    },
    setStoredValue: function setStoredValue(value: T) {
      Store.set(key, value);
    },
  });
}

export function resetAtomWithStorage<T>({
  key,
  initialValue,
}: {
  key: LocalStorageStoreKey<string>;
  initialValue: T;
}): WritableAtom<T, [SetStateActionWithReset<T>], void> {
  const baseAtom = atomWithStorage({ key, initialValue });

  return atom(
    (get) => get(baseAtom),
    function writeBaseAtom(get, set, update: SetStateActionWithReset<T>) {
      const nextValue =
        typeof update === 'function' ? (update as (prev: T) => T)(get(baseAtom)) : update;
      if (nextValue === RESET) {
        set(baseAtom, initialValue);
      } else {
        set(baseAtom, nextValue);
      }
    },
  );
}

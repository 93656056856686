/**
 *
 * LastSyncedTxHash is a model that represents the last synced transaction hash by network, accountId and walletID.
 * Its values are used to short-circuit unnecessary pagination during transaction syncing.
 * @property id UUID generated on the client when the record is created. txHash cannot be used as the primary key because it a txHash can be shared across wallet IDs and account IDs (e.g. when a transaction is sent between two wallets/accounts a user owns)
 * @property txHash - The most recent tx hash by Transaction.createdAt for a network
 * @property network - The network the transaction is on
 * @property blockchain - The blockchain the transaction is on
 * @property accountId - The account the transaction is on
 * @property walletId - The wallet the transaction is on
 * @property createdAt - The time the record was created
 * @property updatedAt - The time the record was last updated
 */

import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { Persistable } from 'cb-wallet-data/persistence/Database.interface';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { Column, Entity, Index, PrimaryColumn } from '@cbhq/typeorm';

@Entity('last_synced_tx_hash')
@Index(
  'IDX_BLOCKCHAIN_NETWORK_ACCOUNTID_WALLETINDEX',
  ['blockchainStr', 'networkStr', 'accountId', 'walletIndexStr'],
  { unique: true },
)
@Index('IDX_BLOCKCHAIN_ACCOUNTID_WALLETINDEX', ['blockchainStr', 'accountId', 'walletIndexStr'])
export class LastSyncedTxHashDMO {
  @PrimaryColumn()
  id!: string;

  @Column()
  txHash!: string;

  @Column()
  networkStr!: string;

  @Column()
  blockchainStr!: string;

  @Column()
  accountId!: string;

  @Column()
  walletIndexStr!: string;

  @Column({ type: 'datetime' })
  createdAt!: Date;

  @Column({ type: 'datetime' })
  updatedAt!: Date;
}

type LastSyncedTxHashParams = {
  accountId: string;
  blockchain: Blockchain;
  network: Network;
  txHash: string;
  walletIndex: bigint;
  createdAt: Date;
  updatedAt: Date;
};

export class LastSyncedTxHash implements Persistable<LastSyncedTxHashDMO> {
  readonly id: string;
  readonly txHash: string;
  readonly network: Network;
  readonly blockchain: Blockchain;
  readonly accountId: string;
  readonly walletIndex: bigint;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(params: LastSyncedTxHashParams) {
    this.id = LastSyncedTxHash.createId({
      accountId: params.accountId,
      blockchain: params.blockchain,
      network: params.network,
      walletIndex: params.walletIndex,
    });

    this.txHash = params.txHash;
    this.network = params.network;
    this.blockchain = params.blockchain;
    this.accountId = params.accountId;
    this.walletIndex = params.walletIndex ?? 0n;
    this.createdAt = params.createdAt;
    this.updatedAt = params.updatedAt;
  }

  static createId({
    accountId,
    blockchain,
    network,
    walletIndex,
  }: {
    accountId: string;
    blockchain: Blockchain;
    network: Network;
    walletIndex: bigint;
  }): string {
    return `${accountId}-${blockchain.rawValue}-${network.rawValue}-${walletIndex}`;
  }

  static fromDMO(dmo: LastSyncedTxHashDMO): LastSyncedTxHash {
    return new LastSyncedTxHash({
      accountId: dmo.accountId,
      blockchain: new Blockchain(dmo.blockchainStr),
      network: Network.create(dmo.networkStr)!,
      txHash: dmo.txHash,
      walletIndex: BigInt(dmo.walletIndexStr),
      createdAt: dmo.createdAt,
      updatedAt: dmo.updatedAt,
    });
  }

  get asDMO(): LastSyncedTxHashDMO {
    return {
      id: this.id,
      txHash: this.txHash,
      networkStr: this.network.rawValue,
      blockchainStr: this.blockchain.rawValue,
      accountId: this.accountId,
      walletIndexStr: this.walletIndex.toString(),
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }
}

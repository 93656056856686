export const ETHEREUM_PREFIX = 'ETHEREUM_CHAIN';

export const ETHEREUM_SYMBOL = 'ETH';

export const ETHEREUM_CURRENCY_DECIMAL = 18n;

export const ETHEREUM_QR_CODE_MAINNET_SCHEME = 'ethereum';

export const BURN_ADDRESS = '0x000000000000000000000000000000000000dead';

export const ETHEREUM_CHAIN_ID = 1n;
export const POLYGON_CHAIN_ID = 137n;

export const ETHEREUM_NETWORK_ID = 'networks/ethereum-mainnet';
export const POLYGON_NETWORK_ID = 'networks/polygon-mainnet';
export const AVALANCHE_NETWORK_ID = 'networks/avacchain-mainnet';

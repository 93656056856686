import { BaseChain } from './BaseChain';

export class NetworkMap<T extends BaseChain> {
  private networkMap: Map<string, T>;
  public whitelisted: Record<string, T>;
  public internal: Record<string, T>;

  // Internal sets to increase lookup performance
  private whitelistedChainIdSet: Set<string>;
  private internalChainIdSet: Set<string>;

  constructor(whitelisted: Record<string, T>, internal?: Record<string, T>) {
    this.whitelisted = whitelisted;
    this.internal = internal || {};

    // Construct these internal sets so that we can do fast lookups
    // for whitelisted and internal networks, which do not change after instantiation
    this.whitelistedChainIdSet = new Set(
      Object.values(this.whitelisted).map((n) => n.chainId.toString()),
    );
    this.internalChainIdSet = new Set(
      Object.values(this.internal).map((n) => n.chainId.toString()),
    );

    this.networkMap = new Map(Object.values(whitelisted).map((n) => [n.chainId.toString(), n]));
  }

  public get allNetworks(): Map<string, T> {
    return this.networkMap;
  }

  public addNetwork(chainId: string, network: T): void {
    this.networkMap.set(chainId, network);
  }

  public removeNetwork(chainId: string) {
    if (this.isWhitelisted(chainId)) {
      throw Error('Cannot delete a whitelisted network');
    }
    this.networkMap.delete(chainId);
  }

  public removeCustomizationForWhitelisted(chainId: string) {
    const networkFromWhitelist = Object.values(this.whitelisted).find(
      (n) => n.chainId.toString() === chainId,
    );
    if (networkFromWhitelist) {
      this.addNetwork(chainId, networkFromWhitelist);
    }
  }

  public isWhitelisted(chainId: string): boolean {
    return this.whitelistedChainIdSet.has(chainId);
  }

  public isInternal(chainId: string): boolean {
    return this.internalChainIdSet.has(chainId);
  }

  public isWhiteListedAndCustomized(chainId: string): boolean {
    if (!this.isWhitelisted(chainId)) {
      return false;
    }

    const networkFromSingleton = this.fromChainId(BigInt(chainId));
    const networkFromWhitelist = Object.values(this.whitelisted).find(
      (n) => n.chainId.toString() === chainId,
    );

    return (
      !networkFromSingleton ||
      !networkFromWhitelist ||
      networkFromSingleton.displayName !== networkFromWhitelist.displayName ||
      networkFromSingleton.rpcUrl !== networkFromWhitelist.rpcUrl ||
      networkFromSingleton.baseAssetCurrencyCode !== networkFromWhitelist.baseAssetCurrencyCode ||
      networkFromSingleton.blockExplorerUrl !== networkFromWhitelist.blockExplorerUrl
    );
  }

  /**
   * Resets all networks to the default mappings.
   */
  public resetNetworks(): void {
    this.networkMap = new Map(
      Object.values(this.whitelisted).map((n) => [n.chainId.toString(), n]),
    );
  }

  public fromChainId(chainId: bigint): T | undefined {
    const chainIdAsNum = chainId.toString();
    if (this.networkMap.has(chainIdAsNum)) {
      return this.networkMap.get(chainIdAsNum);
    }

    return undefined;
  }
}

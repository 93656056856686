import { useMemo } from 'react';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';

import { FETCH_ENABLED_COLLECTIBLE_CHAIN_IDS, getSupportedCollectibleChains } from '../api';
import { CollectibleChainSupportedFeaturesConfig } from '../types';

import { useIsBackendNFTChainConfigEnabled } from './useIsBackendNFTChainConfigEnabled';

// Hook that fetches from a backend endpoint which NFT chain IDs are enabled
export function useSupportedCollectibleChains(): CollectibleChainSupportedFeaturesConfig[] {
  const isBackendChainConfigEnabled = useIsBackendNFTChainConfigEnabled();
  const { data } = useQuery(
    [FETCH_ENABLED_COLLECTIBLE_CHAIN_IDS],
    async () => {
      return getSupportedCollectibleChains();
    },
    {
      suspense: false,
      useErrorBoundary: false,
      staleTime: 1000 * 60 * 5, // stale after 5 minutes
      cacheTime: 1000 * 60 * 60 * 24 * 7, // cache the supported chain ids for one week
      notifyOnChangeProps: ['data'],
      enabled: isBackendChainConfigEnabled,
      refetchInterval: 1000 * 60 * 5, // refetch every 5 minutes
    },
  );

  return useMemo(() => {
    if (!data) {
      return [
        {
          id: '8453',
          name: 'Base',
          supportedFeatures: {
            sendNft: true,
          },
          addressType: 'ADDRESS_TYPE_EVM',
          chainImageUrl: 'https://go.wallet.coinbase.com/static/chains/base-logo.png',
        },
        {
          id: '1',
          name: 'Ethereum',
          supportedFeatures: {
            sendNft: true,
          },
          addressType: 'ADDRESS_TYPE_EVM',
          chainImageUrl: 'https://go.wallet.coinbase.com/static/chains/ethereum-logo.png',
        },
        {
          id: '100',
          name: 'Gnosis',
          supportedFeatures: {},
          addressType: 'ADDRESS_TYPE_EVM',
          chainImageUrl: 'https://go.wallet.coinbase.com/static/chains/gnosis-logo.png',
        },
        {
          id: '10',
          name: 'Optimism',
          supportedFeatures: {},
          addressType: 'ADDRESS_TYPE_EVM',
          chainImageUrl: 'https://go.wallet.coinbase.com/static/chains/optimism-logo.png',
        },
        {
          id: '137',
          name: 'Polygon',
          supportedFeatures: {
            sendNft: true,
          },
          addressType: 'ADDRESS_TYPE_EVM',
          chainImageUrl: 'https://go.wallet.coinbase.com/static/chains/polygon-logo.png',
        },
        {
          id: '101',
          name: 'Solana',
          supportedFeatures: {},
          addressType: 'ADDRESS_TYPE_SOL',
          chainImageUrl: 'https://go.wallet.coinbase.com/static/chains/solana-logo.png',
        },
        {
          id: '43114',
          name: 'Avax',
          supportedFeatures: {},
          addressType: 'ADDRESS_TYPE_EVM',
          chainImageUrl: 'https://go.wallet.coinbase.com/static/chains/avalanche-logo.png',
        },
        {
          id: '1380012617',
          name: 'Rari',
          supportedFeatures: {},
          addressType: 'ADDRESS_TYPE_EVM',
          chainImageUrl: 'https://go.wallet.coinbase.com/static/rarible.png',
        },
        {
          id: '7777777',
          name: 'Zora',
          supportedFeatures: {},
          addressType: 'ADDRESS_TYPE_EVM',
          chainImageUrl: 'https://go.wallet.coinbase.com/static/chains/zora-logo.png',
        },
      ];
    }

    return data.chains;
  }, [data]);
}

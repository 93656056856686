import { useMemo } from 'react';
import { useOverridableKillSwitch as useKillSwitch } from 'cb-wallet-data/hooks/KillSwitches/useOverridableKillSwitch';
import { debugMenuExperimentsOverridesAtom } from 'cb-wallet-data/stores/DebugMenuExperiments/state';
import { isProduction } from 'cb-wallet-data/utils/isProduction';
import { useAtomValue } from 'jotai';
/* eslint-disable-next-line no-restricted-imports */
import { ExperimentType, useVariant, UseVariantOptions } from '@cbhq/experiments';

const DEFAULT_TREATMENT_GROUPS = ['treatment'];

/**
 * Returns a boolean based on whether the user is in the treatment group of an experiment.
 * You can optionally pass in a second parameter with the treatmentGroups option in order
 * to specify which experiment groups are considered to be treatment. For example if your
 * experiment's groups are 'control', 'variant1' and 'variant2', you could call the hook
 * with:
 *
 * // Declare this outside of the hook or memoize
 * const hookOptions = { treatmentGroups: ['variant1', 'variant2'] }
 * useIsExperimentInTreatment("my_experiment_name", hookOptions)
 */
export function useIsExperimentInTreatment<
  T extends ExperimentType | null,
  K extends ExperimentType | null,
>(
  experiment: T,
  experimentOptions: UseVariantOptions<K> & { treatmentGroups?: string[] } = {},
): boolean {
  // This hook is rendered only in non-production environments because it is called
  // in many places and calls to useRecoilValue are expensive
  if (!isProduction) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useDevIsExperimentInTreatment(experiment, experimentOptions);
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useProdIsExperimentInTreatment(experiment, experimentOptions);
}

/**
 * We make this a separate hook because currently useRecoilValue is actually quite
 * expensive and accessing this dev overrides atom every time we get an experiment
 * can slow down the app
 */
function useDevIsExperimentInTreatment<
  T extends ExperimentType | null,
  K extends ExperimentType | null,
>(
  experiment: T,
  {
    treatmentGroups = DEFAULT_TREATMENT_GROUPS,
    ...options
  }: UseVariantOptions<K> & { treatmentGroups?: string[] } = {},
): boolean {
  const currentVariant = useVariant(experiment, options);
  const debugMenuExperimentOverrides = useAtomValue(debugMenuExperimentsOverridesAtom);
  const isOverrideDisabled = useKillSwitch('kill_experiments_local_override');

  return useMemo(() => {
    const isEnabled = currentVariant ? treatmentGroups.includes(currentVariant) : false;

    if (isOverrideDisabled) return isEnabled;

    const localOverride = debugMenuExperimentOverrides.find((e) => e.name === experiment?.name);
    if (!localOverride) return isEnabled;

    return treatmentGroups.includes(localOverride?.group) ?? isEnabled;
  }, [
    currentVariant,
    debugMenuExperimentOverrides,
    experiment?.name,
    isOverrideDisabled,
    treatmentGroups,
  ]);
}

function useProdIsExperimentInTreatment<
  T extends ExperimentType | null,
  K extends ExperimentType | null,
>(
  experiment: T,
  {
    treatmentGroups = DEFAULT_TREATMENT_GROUPS,
    ...options
  }: UseVariantOptions<K> & { treatmentGroups?: string[] } = {},
): boolean {
  const currentVariant = useVariant(experiment, options);

  return useMemo(() => {
    const isEnabled = currentVariant ? treatmentGroups.includes(currentVariant) : false;

    return isEnabled;
  }, [currentVariant, treatmentGroups]);
}

import { useMemo } from 'react';
import {
  DefiPositionResponse,
  DefiPositionRow,
  DefiPositionType,
  LiquidityPoolPositionResponse,
} from 'cb-wallet-data/stores/DefiPortfolio/models/DefiPosition';
import { DefiProtocolPortfolioResponse } from 'cb-wallet-data/stores/DefiPortfolio/models/DefiProtocolPortfolio';

import { useParseLiquidityPoolPositionResponse } from './useParseLiquidityPoolPositionResponse';
import { useParseProtocolPositionResponse } from './useParseProtocolPositionResponse';

type Props = {
  response?: DefiProtocolPortfolioResponse;
};

export function useParseProtocolPortfolioResponse({ response }: Props): DefiPositionRow[] {
  const parseLiquidityPoolPositionResponse = useParseLiquidityPoolPositionResponse();
  const parseProtocolPositionResponse = useParseProtocolPositionResponse();

  return useMemo(() => {
    if (!response) {
      return [];
    }

    const result: DefiPositionRow[] = [];

    Object.entries(response).forEach(function parseResponse([key, value]) {
      if (!value) {
        return;
      }

      // If the key is liquidityPool, we need to parse the response differently
      if (key === DefiPositionType.LiquidityPool) {
        value.forEach((item) => {
          result.push(parseLiquidityPoolPositionResponse(item as LiquidityPoolPositionResponse));
        });
        return;
      }
      // All other keys are the regular DefiPosition shape
      value.forEach((item) => {
        result.push(
          parseProtocolPositionResponse(item as DefiPositionResponse, key as DefiPositionType),
        );
      });
    });

    return result;
  }, [parseLiquidityPoolPositionResponse, parseProtocolPositionResponse, response]);
}

import {
  BaseAssetError,
  ERC20MissingMetadataError,
  ERC20TokenError,
} from 'wallet-engine-signing/history/errors';
import type { BaseAddressConfig } from 'wallet-engine-signing/history/types';
import {
  V1AddressMeta,
  V1SpamScoreThresholds,
  V1Transaction,
} from '@cbhq/instant-api-hooks-wallet-tx-history/types';

import type {
  EtherscanEthereumTransactionResponse,
  EtherscanResult,
  EtherscanTokenTransactionResponse,
} from './etherscan';
import { V2SpamScoreThresholds } from './RPC';

export type {
  EtherscanEthereumTransactionResponse,
  EtherscanResult,
  EtherscanTokenTransactionResponse,
  V1AddressMeta,
  V1SpamScoreThresholds,
};

export type EthereumAddressConfig = BaseAddressConfig & {
  blockchainSymbol: 'ETH';
  chainId: bigint;
  rpcUrl: string;
  baseAssetCurrencyCode: string;
  // Certain EVM networks support SSE-powered balance nudges
  isNudgeEnabled: boolean;
  // Always false, solana-only
  isWebsocketNudgeEnabled: boolean;
  // Determines how far back to sync transactions
  lastSyncedTxHash?: string;
  txHistoryVersion: 'v2' | 'v3' | 'etherscan';
  wacNetworkId: string;
  etherscanCompatibleTxHistoryApi: string;
  chainProxyTarget?: string;
  nextPaginationToken?: string;

  // For custom tokens we don't index
  erc20ContractAddresses?: string[];
  etherscanLikeApiKey?: string;
};

type EthereumBalanceUpdate = {
  etherBalance: bigint;
  erc20Balances: ERC20Token[];
  errors: BaseAssetError[];
  unsyncedTransactions: EthereumTransaction[];
  addressMetadata: Record<string, V1AddressMeta>;
  spamScoreThresholds: V1SpamScoreThresholds | V2SpamScoreThresholds;
};

export type EthereumAddressHistory = EthereumAddressConfig & EthereumBalanceUpdate;

export type EthereumTransaction = V1Transaction | V2Transaction | EtherscanResult;

export type ERC20Token = {
  balance: bigint;
  chainId: bigint;
  contractAddress: string;
  decimals: bigint;
  displayName: string;
  symbol: string;
  assetUUID?: string;
  imageURL?: string;
  isSpam?: boolean;
  errorMessage?: string;
  errors: (ERC20TokenError | ERC20MissingMetadataError)[];
};

export type V2Transaction = {
  user: {
    address: string;
  };
  hash: string;
  transfers: TransferDTO[];
  timestamp: number;
  types: TxFlowOrType[];
  network: number;
  gasfee: {
    amount: string;
    currency: currencyDTO;
  };
  nonce: number;
  status: string;
};

type currencyDTO = {
  address: string;
  name: string;
  symbol: string;
  imageURL?: string;
  price?: number;
  decimal: number;
  type: string;
  spam_score?: number;
  tokenId?: string;
};

export type TransferDTO = {
  from: {
    address: string;
    domain?: string;
  };
  to: {
    address: string;
    domain?: string;
  };
  exchange: {
    amount: string;
    currency: currencyDTO;
  };
};

export type TxFlowOrType =
  | 'RECEIVE'
  | 'SEND'
  | 'MINT'
  | 'APPROVE'
  | 'SWAP'
  | 'SPONSORED'
  | 'GASLESS'
  | 'CASHOUT';

export enum UnsupportedTronTransactionNetwork {
  TRON = 'networks/tron-mainnet',
  BASE = 'networks/base-mainnet',
}

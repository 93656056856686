import {
  triggerRepairNetworksFail,
  triggerRepairNetworksStart,
  triggerRepairNetworksSuccess,
} from 'cb-wallet-analytics/dapp';
import { EthereumWalletConfiguration } from 'cb-wallet-data/chains/AccountBased/Ethereum/config';
import { importAddressForNetworks } from 'cb-wallet-data/chains/AccountBased/operations/importAddress';
import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { getAccounts } from 'cb-wallet-data/stores/Accounts/database';
import { deleteAssociatedNetworksDbRecords } from 'cb-wallet-data/stores/Accounts/utils/deleteAssociatedNetworkDbRecords';
import { getAddressesForAccount } from 'cb-wallet-data/stores/Addresses/database';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { ImportableAddress } from 'cb-wallet-data/stores/Wallets/types/ImportableAddress';
import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { Store } from 'cb-wallet-store/Store';

import { sha256 } from ':dapp/utils/sha256';

const StoreKeys_lastNetworkCheckKey = new LocalStorageStoreKey('lastNetworkCheck');

async function computeNetworkCheckKey(): Promise<string> {
  return sha256(
    EthereumWalletConfiguration.networkSetting.allNetworks
      .map((networkSetting) => networkSetting.network.rawValue)
      .join(','),
  );
}
/**
 *
 * This function is used to delete any addresses, wallets, and transactions that have networks that aren't in our list
 * of supported eth networks. The function also imports any addresses, and wallets that are missing from our list of
 * supported eth networks.
 */
export async function checkForNetworkChangesAndUpdateAssociatedTables() {
  // Optimization: If the network check key hasn't changed (i.e. the list of networks hasn't changed),
  // then we don't need to do anything.
  const currentNetworkCheckKey = await computeNetworkCheckKey();
  const lastNetworkCheckKey = Store.get(StoreKeys_lastNetworkCheckKey);
  if (currentNetworkCheckKey === lastNetworkCheckKey) {
    return;
  }
  Store.set(StoreKeys_lastNetworkCheckKey, currentNetworkCheckKey);

  // 1. Get all ethereum accounts
  const allEthereumAccounts = (await getAccounts()).filter(
    (account) => account.primaryAddressChain === 'ETH',
  );

  if (allEthereumAccounts.length === 0) {
    return;
  }

  // 2. Check the addresses of the first account to see if we need to import or delete any networks.
  // We don't need to check all of the ethereum accounts since they all have the same set of supported
  // networks.
  const addresses = await getAddressesForAccount(allEthereumAccounts[0].id);
  const existingNetworkMap = addresses.reduce((acc, address) => {
    acc.set(address.network.rawValue, address.network);
    return acc;
  }, new Map<string, Network>());

  // 3. Get all of the currently supported ethereum networks
  const allEthereumBasedNetworks = EthereumWalletConfiguration.networkSetting.allNetworks.reduce(
    (acc, networkSetting) => {
      acc.set(networkSetting.network.rawValue, networkSetting.network);
      return acc;
    },
    new Map<string, Network>(),
  );

  // 4. Compare the existing networks with the currently supported ethereum networks. For any new networks, prepare to import them
  const networksToImport = Array.from(allEthereumBasedNetworks.values()).reduce((acc, curr) => {
    if (!existingNetworkMap.get(curr.rawValue)) {
      acc.push(curr);
    }
    return acc;
  }, [] as Network[]);

  // 5. Compare the existing networks with the currently supported ethereum networks. For any old networks, prepare to delete them
  const networksToDelete = Array.from(existingNetworkMap.values()).reduce((acc, curr) => {
    if (!allEthereumBasedNetworks.has(curr.rawValue)) {
      acc.push(curr);
    }
    return acc;
  }, [] as Network[]);

  if (networksToImport.length === 0 && networksToDelete.length === 0) {
    return;
  }

  const allDeletePromises = [];
  const allImportPromises = [];
  try {
    if (networksToDelete.length > 0 || networksToImport.length > 0) {
      triggerRepairNetworksStart(networksToDelete.length, networksToImport.length > 0);
    }
    for (const account of allEthereumAccounts) {
      if (networksToDelete.length > 0) {
        const deletePromises = networksToDelete.map(async (network) =>
          deleteAssociatedNetworksDbRecords({ blockchainSymbol: 'ETH', network }),
        );
        allDeletePromises.push(...deletePromises);
      }
      if (networksToImport.length > 0) {
        const importableAddress: ImportableAddress = {
          index: 0n,
          address: account.primaryAddress,
          accountId: account.id,
        };

        allImportPromises.push(
          importAddressForNetworks('ETH', importableAddress, networksToImport),
        );
      }
    }

    await Promise.all([...allImportPromises, ...allDeletePromises]);
    if (networksToDelete.length > 0 || networksToImport.length > 0) {
      triggerRepairNetworksSuccess(networksToDelete.length, networksToImport.length);
    }
  } catch (err: ErrorOrAny) {
    triggerRepairNetworksFail();
    cbReportError({
      error: err,
      context: 'delete_associated_networks_db_records',
      severity: 'error',
      isHandled: false,
    });
  }
}

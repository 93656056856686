import { memo, useMemo } from 'react';
import { type UiIconName } from '@cbhq/cds-icons';
import { Button, IconButton } from '@cbhq/cds-web/buttons';
import { HStack } from '@cbhq/cds-web/layout';
import { TextTitle3 } from '@cbhq/cds-web/typography';

type StepperNavbarProps = {
  onLeftIconPress?: () => void;
  onRightIconPress?: () => void;
  onRightButtonPress?: () => void;
  leftIconDisabled?: boolean;
  leftIcon?: UiIconName;
  rightIcon?: UiIconName;
  title?: string;
  rightButtonText?: string;
  rightComponent?: React.ReactNode;
};

export const StepperNavbar = memo(function StepperNavbar({
  onLeftIconPress,
  onRightIconPress,
  onRightButtonPress,
  leftIconDisabled,
  leftIcon,
  rightIcon,
  rightButtonText,
  rightComponent,
  title,
}: StepperNavbarProps) {
  const rightContent = useMemo(() => {
    if (rightComponent) {
      return rightComponent;
    }
    if (onRightButtonPress && rightButtonText) {
      return (
        <Button
          compact
          transparent
          onPress={onRightButtonPress}
          testID="stepper-navbar-right-button"
        >
          {rightButtonText}
        </Button>
      );
    }
    if (onRightIconPress && rightIcon) {
      return (
        <IconButton
          transparent
          name={rightIcon}
          onPress={onRightIconPress}
          testID="stepper-navbar-right-icon-button"
        />
      );
    }
    return null;
  }, [onRightButtonPress, onRightIconPress, rightButtonText, rightComponent, rightIcon]);

  return (
    <HStack width="100%" alignItems="center" justifyContent="space-between" testID="stepper-navbar">
      <HStack alignItems="center" gap={1}>
        {onLeftIconPress && leftIcon && (
          <IconButton
            disabled={leftIconDisabled}
            name={leftIcon}
            onPress={onLeftIconPress}
            transparent
            testID="stepper-navbar-left-icon"
          />
        )}
        {title && (
          <TextTitle3 testID="stepper-navbar-title" as="h3">
            {title}
          </TextTitle3>
        )}
      </HStack>
      {rightContent}
    </HStack>
  );
});

import { useCallback, useEffect } from 'react';
import {
  triggerUserAssetsSyncFailed,
  triggerUserAssetsSyncSucceed,
} from 'cb-wallet-analytics/asset-management/events';
import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { useIsCrossPlatformHiddenAssetsKilled } from 'cb-wallet-data/stores/AssetManagement/hooks/useIsCrossPlatformHiddenAssetsKilled';
// eslint-disable-next-line no-restricted-imports
import { useActiveWalletGroupId } from 'cb-wallet-data/stores/WalletGroups/hooks/useActiveWalletGroupId';
import { usePrimaryReceiveAddresses } from 'cb-wallet-data/stores/Wallets/hooks/usePrimaryReceiveAddresses';
import { isE2eTest } from 'cb-wallet-env/env';
import { Store } from 'cb-wallet-store/Store';
import { useRecoilState } from 'recoil';

import { addUserCollectibleSettings, addUserWalletSettings } from '../database';
import { areUserAssetSettingsSyncedAtom, StoreKeys_userSettingsPrimaryAddress } from '../state';

import { useUnsafeSetAreLowBalanceWalletsHiddenAtom } from './useAreLowBalanceWalletsHidden';
import { useHydrateUserCollectibleSettingsFromDatabase } from './useHydrateUserCollectibleSettings';
import { useHydrateUserWalletSettingsFromDatabase } from './useHydrateUserWalletSettings';
import { useRemoteUserAssetSettings } from './useRemoteUserAssetSettings';

export async function useSyncAssetSettings(supportsMultiWallet = true) {
  const [areUserAssetSettingsSynced, setUserAssetSettingsSynced] = useRecoilState(
    areUserAssetSettingsSyncedAtom,
  );

  const unsafeSetLowBalanceWalletsHidden = useUnsafeSetAreLowBalanceWalletsHiddenAtom();
  const hydrateUserWalletSettings = useHydrateUserWalletSettingsFromDatabase();
  const hydrateUserCollectibleSettings = useHydrateUserCollectibleSettingsFromDatabase();
  const getRemoteUserAssetSettings = useRemoteUserAssetSettings();
  const activeWalletGroupId = useActiveWalletGroupId();
  const primaryAddress = usePrimaryReceiveAddresses(activeWalletGroupId).get('ETH')?.address;

  const isCrossPlatformHiddenAssetsKilled = useIsCrossPlatformHiddenAssetsKilled();

  const syncUserAssetSettings = useCallback(
    async function syncUserAssetSettings() {
      const shouldSyncUserAssetSettings =
        !isCrossPlatformHiddenAssetsKilled &&
        supportsMultiWallet &&
        !!primaryAddress &&
        !areUserAssetSettingsSynced &&
        !isE2eTest;

      if (!shouldSyncUserAssetSettings) {
        return;
      }

      Store.set(StoreKeys_userSettingsPrimaryAddress, primaryAddress);

      try {
        const {
          wallets: fetchedWalletSettings,
          collectibles: fetchedCollectibleSettings,
          shouldShowLowBalance,
        } = await getRemoteUserAssetSettings(primaryAddress);

        if (typeof shouldShowLowBalance === 'boolean') {
          unsafeSetLowBalanceWalletsHidden(!shouldShowLowBalance);
        }

        if (fetchedWalletSettings.length) {
          addUserWalletSettings(fetchedWalletSettings).then(hydrateUserWalletSettings);
        }

        if (fetchedCollectibleSettings.length) {
          addUserCollectibleSettings(fetchedCollectibleSettings).then(
            hydrateUserCollectibleSettings,
          );
        }

        setUserAssetSettingsSynced(true);
        triggerUserAssetsSyncSucceed();
      } catch (syncError) {
        // Wrapping on a try catch for now to prevent it from hitting the error boundary in case of failure
        const err = coerceError(syncError, 'useSyncUserAssetSettings');
        cbReportError({ error: err, context: 'assets', severity: 'error', isHandled: false });
        triggerUserAssetsSyncFailed();
      }
    },
    [
      isCrossPlatformHiddenAssetsKilled,
      supportsMultiWallet,
      primaryAddress,
      areUserAssetSettingsSynced,
      getRemoteUserAssetSettings,
      setUserAssetSettingsSynced,
      unsafeSetLowBalanceWalletsHidden,
      hydrateUserWalletSettings,
      hydrateUserCollectibleSettings,
    ],
  );

  useEffect(
    function resyncAssetsWhenAddressChange() {
      if (
        !!primaryAddress &&
        primaryAddress !== Store.get<string>(StoreKeys_userSettingsPrimaryAddress) &&
        areUserAssetSettingsSynced
      ) {
        setUserAssetSettingsSynced(false);
      }
    },
    [areUserAssetSettingsSynced, primaryAddress, setUserAssetSettingsSynced],
  );

  useEffect(
    function initSync() {
      syncUserAssetSettings();
    },
    [syncUserAssetSettings],
  );
}

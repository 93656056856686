import { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import {
  AccountWithBalance,
  useAccountsWithBalances,
} from 'cb-wallet-data/stores/Accounts/hooks/useAccountsWithBalances';
import { StepperNavbar } from 'wallet-cds-web/components/StepperNavbar';
import { useStepper } from 'wallet-cds-web/external-libs/stepper';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { PressableOpacity } from '@cbhq/cds-web/system/PressableOpacity';

import { ConnectedWalletsMenuRow } from ':dapp/components/ConnectedWalletsMenu/ConnectedWalletsMenuRow';
import { usePanoOnrampContext } from ':dapp/providers/PanoOnrampContext';

import { stepper } from '../BuyFlowStepper/flow';
import { OnRampDefaultMainnets, onRampDefaultMainnets } from '../NetworkSwitcher/NetworkSwitcher';

const messages = defineMessages({
  selectWallet: {
    defaultMessage: 'Add funds to',
    description: 'Onramp flow label to indicate destination wallet address to add funds',
  },
});

export function WalletSelectorContent() {
  const { goBack } = useStepper(stepper);
  const { formatMessage } = useIntl();
  const { setSelectedWallet, selectedAsset } = usePanoOnrampContext();
  const accountsWithBalance = useAccountsWithBalances();

  const filteredWallets = useMemo(() => {
    const mainnet =
      onRampDefaultMainnets[selectedAsset?.networkId as OnRampDefaultMainnets] ?? 'ETH';
    return accountsWithBalance.filter((account) => account.primaryAddressChain === mainnet);
  }, [accountsWithBalance, selectedAsset]);

  const handleBackPressed = useCallback(() => {
    goBack();
  }, [goBack]);

  const handleWalletChange = useCallback(
    (wallet: AccountWithBalance) => () => {
      setSelectedWallet(wallet);
      goBack();
    },
    [setSelectedWallet, goBack],
  );

  return (
    <VStack width="100%" overflow="hidden">
      <Box spacing={3}>
        <StepperNavbar
          title={formatMessage(messages.selectWallet)}
          onLeftIconPress={handleBackPressed}
          leftIcon="backArrow"
        />
      </Box>
      <VStack minHeight={400} width="100%" overflow="auto" spacingHorizontal={1} borderedBottom>
        {filteredWallets.map((wallet) => {
          if (!wallet.provider) return null;
          return (
            <PressableOpacity
              key={wallet.primaryAddressForDapp}
              as="button"
              onPress={handleWalletChange(wallet)}
              testID={`onramp-entry-wallet-list-${wallet.primaryAddressForDapp}`}
            >
              <ConnectedWalletsMenuRow
                address={wallet.primaryAddressForDapp}
                balance={wallet.balance}
                provider={wallet.provider}
                addressChain={wallet.primaryAddressChain}
                copyEnabled={false}
                hideCopy
              />
            </PressableOpacity>
          );
        })}
      </VStack>
    </VStack>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';

// This mimics componentDidUpdate, taken from retail
export function useEffectOnUpdate(callback: () => void, dependencies: any[]) {
  const lastDependencies = useRef<any[]>(dependencies);

  useEffect(function updateFunction() {
    /*
     * This is required to defend against React re-running the effect
     * while persisting the refs in StrictMode, to effectively
     * add support for a stateful unmount/remount.
     *
     * https://reactjs.org/docs/strict-mode.html#detecting-unexpected-side-effects
     */
    if (!isEqual(dependencies, lastDependencies.current)) {
      lastDependencies.current = dependencies;
      callback();
    }
    // eslint-disable-next-line wallet/exhaustive-deps
  }, dependencies);
}

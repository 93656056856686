import { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { logOnrampAuthCompleted, logOnrampAuthLaunched } from 'cb-wallet-analytics/buy';
import { enableBlockchains } from 'cb-wallet-data/chains/blockchains';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { useCoinbasePay } from 'cb-wallet-data/hooks/Onramp/useCoinbasePay';
import { useAccounts } from 'cb-wallet-data/stores/Accounts/hooks/useAccounts';
import { useCanAuthenticateWithAPI } from 'cb-wallet-data/stores/Authentication/tokens/useCanAuthenticateWithAPI';
import { useModal } from '@cbhq/cds-common/overlays/useModal';
import { SpotRectangle } from '@cbhq/cds-web/illustrations';
import { VStack } from '@cbhq/cds-web/layout';
import { Modal, ModalBody } from '@cbhq/cds-web/overlays';
import { useToast } from '@cbhq/cds-web/overlays/useToast';
import { TextBody, TextTitle2 } from '@cbhq/cds-web/typography';

import { useGlobalDrawer } from ':dapp/components/GlobalDrawer/GlobalDrawerProvider';
import { useGlobalDrawerOrNavigate } from ':dapp/components/GlobalDrawer/hooks/useGlobalDrawerOrNavigate';
import { SprigEventsEnum } from ':dapp/components/Sprig/constants';
import { useLaunchSprig } from ':dapp/components/Sprig/SprigProvider';
import { CB_WALLET_PUBLIC_URL, CBPAY_ID } from ':dapp/config/env';
import { COINBASE_SMART_WALLET_ID } from ':dapp/connection/wallet-providers/coinbase-wallet';
import { useCreateSessionForDapp } from ':dapp/hooks/useCreateSessionForDapp';
import { BuyFlowDrawer } from ':dapp/pages/buy/components/BuyFlowDrawer/BuyFlowDrawer';
import { RoutesEnum } from ':dapp/utils/RoutesEnum';

import { useIsFeatureEnabledForScw } from '../Scw/useIsFeatureEnabledForScw';

import { useScwDestinationWallets } from './useScwDestinationWallets';

const messages = defineMessages({
  signInWithWallet: {
    defaultMessage: 'Verify your wallet',
    description: 'Onramp Sign in with your wallet',
  },
  verifyAddress: {
    defaultMessage: 'For your security, please confirm the signature request.',
    description: 'Onramp verify that this is your address',
  },
});

export function useLaunchBuyFlow() {
  enableBlockchains();

  const toast = useToast();
  const { formatMessage } = useIntl();
  const { openModal, closeModal } = useModal();
  const hasAccessToken = useCanAuthenticateWithAPI();
  const createSessionForDapp = useCreateSessionForDapp();
  const { openDrawerOrNavigate } = useGlobalDrawerOrNavigate();
  const { closeDrawer } = useGlobalDrawer();

  const isCBPayBuyButtonNavbarEnabled = useIsFeatureEnabled('display_cbpay_buy_button_navbar');
  const isSCWEnabled = useIsFeatureEnabledForScw('buy');
  const isSCWAuthEnabled = useIsFeatureEnabled('scw_sign_in_with_ethereum_auth');

  const accounts = useAccounts();
  const launchSprig = useLaunchSprig();
  const accountToAuthenticateWith = useMemo(() => {
    const accountOptions = accounts.filter(function filterAccountsToSignInWith(accountItem) {
      if (accountItem.primaryAddressChain !== 'ETH') {
        return false;
      }

      if (accountItem?.provider !== COINBASE_SMART_WALLET_ID) {
        // Non scw wallets can always auth
        return true;
      }

      // If it's an scw, return whether the backend supports sign in with ethereum (siwe) auth for scw's
      return isSCWAuthEnabled;
    });

    return accountOptions[0];
  }, [accounts, isSCWAuthEnabled]);

  const { scwDestinationWallets } = useScwDestinationWallets();

  const { cbPayInstance } = useCoinbasePay({
    appId: CBPAY_ID,
    widgetParameters: {
      destinationWallets: scwDestinationWallets ?? [],
      redirectUrl: CB_WALLET_PUBLIC_URL,
    },
    onSuccess: () => launchSprig(SprigEventsEnum.BUY_SUCCESS),
  });

  const handleAuthenticate = useCallback(async () => {
    return createSessionForDapp(
      accountToAuthenticateWith.id,
      accountToAuthenticateWith.primaryAddress,
    );
  }, [accountToAuthenticateWith, createSessionForDapp]);

  const handleOpenWarningModal = useCallback(
    () =>
      openModal(
        <Modal width={420} visible onRequestClose={closeModal}>
          <ModalBody>
            <VStack alignItems="center" width="100%" spacing={2} gap={2}>
              <SpotRectangle name="walletReconnectSuccess" />
              <TextTitle2 as="p">{formatMessage(messages.signInWithWallet)}</TextTitle2>
              <TextBody align="center" color="foregroundMuted" as="p">
                {formatMessage(messages.verifyAddress)}
              </TextBody>
            </VStack>
          </ModalBody>
        </Modal>,
      ),
    [openModal, closeModal, formatMessage],
  );

  const handleOpenDrawer = useCallback(() => {
    openDrawerOrNavigate(<BuyFlowDrawer onClose={closeDrawer} />, RoutesEnum.BUY);
  }, [closeDrawer, openDrawerOrNavigate]);

  const launchBuyFlow = useCallback(async () => {
    try {
      if (scwDestinationWallets) {
        cbPayInstance.current?.open();
        return;
      }
      if (!hasAccessToken) {
        handleOpenWarningModal();
        logOnrampAuthLaunched();
        await handleAuthenticate();
        handleOpenDrawer();
      } else {
        handleOpenDrawer();
      }
    } catch (error) {
      if (error instanceof Error) {
        toast.show(error.message);
      }
    } finally {
      logOnrampAuthCompleted();
      closeModal();
    }
  }, [
    closeModal,
    handleAuthenticate,
    handleOpenDrawer,
    handleOpenWarningModal,
    hasAccessToken,
    toast,
    scwDestinationWallets,
    cbPayInstance,
  ]);

  const isBuyFlowEnabled = useMemo(() => {
    return Boolean(
      (accountToAuthenticateWith || scwDestinationWallets) &&
        isCBPayBuyButtonNavbarEnabled &&
        isSCWEnabled,
    );
  }, [
    accountToAuthenticateWith,
    isCBPayBuyButtonNavbarEnabled,
    isSCWEnabled,
    scwDestinationWallets,
  ]);

  return useMemo(() => {
    return {
      launchBuyFlow,
      isBuyFlowEnabled,
    };
  }, [isBuyFlowEnabled, launchBuyFlow]);
}

import { importAddress as accountBasedImportAddress } from 'cb-wallet-data/chains/AccountBased/operations/importAddress';
import {
  getCurrentGroupSupportedBlockchainSymbols,
  PossibleAccountBlockchainSymbol,
} from 'cb-wallet-data/chains/blockchains';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { ImportableAddress } from 'cb-wallet-data/stores/Wallets/types/ImportableAddress';

import { WalletError } from '../WalletError';

export type ImportAddress = (addressToImport: ImportableAddress) => Promise<Wallet[]>;

export type ImportableAddressesByBlockchain = {
  [K in PossibleAccountBlockchainSymbol]?: ImportableAddress[];
};

function partiallyApplyAccountBasedBlockchain(chain: PossibleAccountBlockchainSymbol) {
  return async (addressToImport: ImportableAddress) =>
    accountBasedImportAddress(chain, addressToImport);
}

type ImportAddressByBlockchain = { [K in PossibleAccountBlockchainSymbol]?: ImportAddress };

// TODO: Once we add UTXO-based Blockchains, we need to add them here.
export const importAddressByBlockchain: ImportAddressByBlockchain =
  getCurrentGroupSupportedBlockchainSymbols({ blockchainGroup: 'Account' }).reduce(
    function reduceToImportAddressByBlockchain(accumulator: ImportAddressByBlockchain, chain) {
      accumulator[chain] = partiallyApplyAccountBasedBlockchain(chain);

      return accumulator;
    },
    {},
  );

/**
 * importAddressesByBlockchain takes in ImportableAddress[] for account-based
 * blockchains, handling the downstream work:
 * - creating addresses for supported + custom networks, and for each one
 *  - creating + saving Address
 *  - creating + saving Wallet
 *  - TODO create + saving WalletGroup
 *
 * NOTE When a custom network that is added, it will also generate wallets for all
 *  those networks.
 * NOTE Only call importAddressesByBlockchain after the user has been created on
 * the backend.
 *
 * @return Promise<Wallet[]>
 */
export async function importAddressesByBlockchain(
  importableAddressesByBlockchain: ImportableAddressesByBlockchain,
): Promise<Wallet[]> {
  const blockchains = Object.keys(
    importableAddressesByBlockchain,
  ) as PossibleAccountBlockchainSymbol[];

  const wallets = await Promise.all(
    blockchains.flatMap(
      // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
      // eslint-disable-next-line wallet/no-anonymous-params
      (blockchain) => {
        const importableAddresses = importableAddressesByBlockchain[blockchain] ?? [];
        const importAddress = importAddressByBlockchain[blockchain];
        if (typeof importAddress === 'undefined') {
          throw WalletError.missingConfig(blockchain);
        }
        return importableAddresses.map(async (address) => {
          return importAddress(address);
        });
      },
    ),
  );

  return wallets.flat();
}

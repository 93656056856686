import { useMemo } from 'react';
import {
  CurrencyFormatter,
  useCurrencyFormatter,
} from 'cb-wallet-data/CurrencyFormatter/hooks/useCurrencyFormatter';
// eslint-disable-next-line no-restricted-imports
import { useActiveWalletGroupId } from 'cb-wallet-data/stores/WalletGroups/hooks/useActiveWalletGroupId';
import { useRecoilValue } from 'recoil';

import { Wallet } from '../models/Wallet';
import { portfolioWalletsByWalletGroupAtom } from '../state';
import { WalletsByWalletGroup } from '../utils/prepareWalletsByWalletGroup';

export function sortWalletsByFiatValue(
  wallets: Wallet[],
  convertToFiatValue: CurrencyFormatter['fiatValue'],
): Wallet[] {
  // Map unique id by calculated fiat balance
  const walletIdFiatMap = new Map(
    wallets.map(
      // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
      // eslint-disable-next-line wallet/no-anonymous-params
      (wallet) => {
        const fiatNumberValue = convertToFiatValue({
          currencyCode: wallet.currencyCode,
          decimals: wallet.decimals,
          value: wallet.balance ?? BigInt(-1),
          contractAddress: wallet.contractAddress,
          network: wallet.network,
          blockchain: wallet.blockchain,
          digitsToFixed: 2,
        });
        return [wallet.id, fiatNumberValue?.toNumber()];
      },
    ),
  );

  return [...wallets].sort(
    // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
    // eslint-disable-next-line wallet/no-anonymous-params
    (a, b) => {
      // Sort by fiat balance
      const aFiatValue = walletIdFiatMap.get(a.id) ?? -1;
      const bFiatValue = walletIdFiatMap.get(b.id) ?? -1;

      // Sort wallets alphabetically if they have the same balance
      if (aFiatValue === bFiatValue) {
        if (a.displayName.toUpperCase() > b.displayName.toUpperCase()) {
          return 1;
        }
        return -1;
      }

      return bFiatValue - aFiatValue;
    },
  );
}

export type BasePortfolioWalletOptions = {
  wallets: Wallet[] | undefined;
  includeCustomNetworks?: boolean;
};

export type BasePortfolioWalletByWalletGroupOptions = {
  walletsByWalletGroup: WalletsByWalletGroup | undefined;
  includeCustomNetworks?: boolean;
};

export function useBasePortfolioWallets({
  wallets,
  includeCustomNetworks,
}: BasePortfolioWalletOptions): Wallet[] | undefined {
  const { fiatValue } = useCurrencyFormatter();

  return useMemo(() => {
    // Wait for at least one active wallet with a defined balance:
    // - If no wallets have a defined balance return undefined to indicate balances are still being loaded.
    // - And if wallet state is just empty, we also want to return undefined.
    if (!wallets || !wallets.some((wallet) => wallet.isBalanceLoaded)) {
      // Indicate that we are still loading balances or active wallets hasn't been hydrated yet
      return undefined;
    }

    const filteredWallets = wallets.filter(
      (wallet) => wallet.balance || (includeCustomNetworks && wallet.isCustomNetwork()),
    );

    return sortWalletsByFiatValue(filteredWallets, fiatValue);
  }, [fiatValue, includeCustomNetworks, wallets]);
}

export function useBasePortfolioWalletsByWalletGroup({
  walletsByWalletGroup,
  includeCustomNetworks,
}: BasePortfolioWalletByWalletGroupOptions): Map<string, Wallet[]> | undefined {
  const { fiatValue } = useCurrencyFormatter();

  return useMemo(() => {
    if (!walletsByWalletGroup) {
      return undefined;
    }

    const walletGroupIds = Object.keys(walletsByWalletGroup);

    const anyWalletHasDefinedBalance = walletGroupIds.some((walletGroupId) =>
      walletsByWalletGroup[walletGroupId]?.some((wallet) => wallet.isBalanceLoaded),
    );

    // Wait for at least one active wallet with a defined balance:
    // - If no wallets have a defined balance return undefined to indicate balances are still being loaded.
    // - And if wallet state is just empty, we also want to return undefined.
    if (!anyWalletHasDefinedBalance) {
      // Indicate that we are still loading balances or active wallets hasn't been hydrated yet
      return undefined;
    }

    return walletGroupIds.reduce<Map<string, Wallet[]>>(function sortedWalletsByWalletGroups(
      acc,
      walletGroupId,
    ) {
      const wallets = walletsByWalletGroup[walletGroupId] ?? [];
      const filteredWallets = wallets.filter(
        (wallet) => wallet.balance || (includeCustomNetworks && wallet.isCustomNetwork()),
      );

      acc.set(walletGroupId, sortWalletsByFiatValue(filteredWallets, fiatValue));
      return acc;
    },
    new Map<string, Wallet[]>());
  }, [fiatValue, includeCustomNetworks, walletsByWalletGroup]);
}

/**
 * Returns Wallets having a positive fiat balance, or which are custom-added tokens, sorted by fiat balance descending.
 */
export function usePortfolioWallets() {
  const activeWalletGroupId = useActiveWalletGroupId();
  const portfolioWalletsByWalletGroup = useRecoilValue(portfolioWalletsByWalletGroupAtom);

  return useMemo(() => {
    if (!activeWalletGroupId) {
      return undefined;
    }

    return portfolioWalletsByWalletGroup?.get(activeWalletGroupId) ?? undefined;
  }, [activeWalletGroupId, portfolioWalletsByWalletGroup]);
}

import {
  CombinedThemeColorPreference,
  miamiThemeColorConfigs,
  themeColorConfigs,
} from 'cb-wallet-data/stores/ThemeColors/themeColorConfigs';

export function assertThemeColor(
  value: unknown,
): asserts value is CombinedThemeColorPreference | undefined {
  if (value === null || value === undefined) {
    return;
  }

  if (
    typeof value === 'string' &&
    (value in themeColorConfigs || value in miamiThemeColorConfigs)
  ) {
    return;
  }

  // HACK typecasting as any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  throw new Error(`${(value as any).toString()} is not a themeColor`);
}

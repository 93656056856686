import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { messages } from 'cb-wallet-data/stores/SettingsRedesign/messages/help';
import {
  SettingCategory,
  SettingCategoryConfig,
} from 'cb-wallet-data/stores/SettingsRedesign/types';

import { useSearchContext } from ':dapp/providers/SearchProvider';
import { getLinkHelp } from ':dapp/utils/externalLinks';
import { openURLInNewTab } from ':dapp/utils/openURLInNewTab';

import { SETTING_CATEGORY_INNER_SPACING } from '../constants';

export function useHelpCategoryConfig(): SettingCategoryConfig {
  const { formatMessage, locale } = useIntl();
  const { toggleSearchOff } = useSearchContext();

  const handlePress = useCallback(
    function handleHelpSettingPress() {
      openURLInNewTab(getLinkHelp(locale));
      toggleSearchOff();
    },
    [locale, toggleSearchOff],
  );

  const categoryConfig = useMemo<SettingCategoryConfig>(() => {
    return {
      title: formatMessage(messages.title),
      description: formatMessage(messages.description),
      media: SettingCategory.HELP,
      accessory: 'externalLink',
      testID: `${SettingCategory.HELP}-setting`,
      onPress: handlePress,
      innerSpacing: SETTING_CATEGORY_INNER_SPACING,
    };
  }, [formatMessage, handlePress]);

  return categoryConfig;
}

import {
  blockchainConfigurations,
  PossibleUTXOBlockchainSymbol,
} from 'cb-wallet-data/chains/blockchains';
import { BRDAddressTypes } from 'cb-wallet-data/chains/UTXO/common/config';
import { UTXOConfiguration } from 'cb-wallet-data/chains/UTXO/models/UTXOConfiguration';
import { AddressType } from 'cb-wallet-data/stores/Addresses/models/AddressType';
import { StoreKeys_isBRDScanningEnabled } from 'cb-wallet-data/stores/Wallets/storeKeys';
import { Store } from 'cb-wallet-store/Store';

export function getAddressesTypesToRefresh(blockchainSymbol: PossibleUTXOBlockchainSymbol) {
  const configuration = blockchainConfigurations[blockchainSymbol] as UTXOConfiguration;
  const addressTypesToRefresh = configuration.supportedAddressTypes.filter(
    function filterAddressTypes(addressType: AddressType) {
      const isBRDScanningEnabled = Store.get(StoreKeys_isBRDScanningEnabled);
      const isBRDAddress = BRDAddressTypes.includes(addressType.rawValue);
      return !isBRDAddress || isBRDScanningEnabled;
    },
  );
  return addressTypesToRefresh;
}

import { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { logOnrampLaunched } from 'cb-wallet-analytics/buy';
import { enableBlockchains } from 'cb-wallet-data/chains/blockchains';
import { DestinationWallets, useCoinbasePay } from 'cb-wallet-data/hooks/Onramp/useCoinbasePay';
import { UTXO_EXPERIMENT_ENABLED_CHAINS } from 'cb-wallet-data/hooks/useHasUTXOExtensionExperiment/useHasUTXOExtensionExperiment';
import { useOnMount } from 'cb-wallet-data/hooks/useOnMount';
import { useAccountsWithBalances } from 'cb-wallet-data/stores/Accounts/hooks/useAccountsWithBalances';
import { useActiveFiatCurrency } from 'cb-wallet-data/stores/ActiveFiatCurrency/hooks/useActiveFiatCurrency';
import {
  LegacyDestinationWallets,
  LegacySupportedBlockchain,
  parseDestinationWallets,
} from 'cb-wallet-data/utils/parseDestinationWallets';
import { Button } from 'wallet-cds-web/components/Button';

import { SprigEventsEnum } from ':dapp/components/Sprig/constants';
import { useLaunchSprig } from ':dapp/components/Sprig/SprigProvider';
import { CB_WALLET_PUBLIC_URL, CBPAY_ID } from ':dapp/config/env';
import { usePanoOnrampContext } from ':dapp/providers/PanoOnrampContext';

const messages = defineMessages({
  previewBuy: {
    defaultMessage: 'Continue to Coinbase',
    description: 'Onramp flow label to open CBPay URL',
  },
});

type BuyButtonProps = {
  ctaText?: string;
};

export function BuyButton({ ctaText }: BuyButtonProps) {
  useOnMount(() => {
    enableBlockchains();
  });
  const { sanitizedAmount, selectedAsset, sourceWallet } = usePanoOnrampContext();
  const accountsWithBalance = useAccountsWithBalances();
  const activeFiatCurrency = useActiveFiatCurrency();
  const launchSprig = useLaunchSprig();
  const selectedWallet =
    sourceWallet ||
    accountsWithBalance.filter((account) => account.primaryAddressChain === 'ETH')[0];

  const { formatMessage } = useIntl();

  const destinationWallets: LegacyDestinationWallets[] = useMemo(() => {
    return [
      {
        address: selectedWallet?.primaryAddressForDapp ? selectedWallet?.primaryAddressForDapp : '',
        blockchains: [
          selectedAsset?.networkLegacyCode
            ? (selectedAsset?.networkLegacyCode as LegacySupportedBlockchain)
            : selectedWallet?.primaryAddressChain,
        ],
      },
    ];
  }, [
    selectedAsset?.networkLegacyCode,
    selectedWallet?.primaryAddressForDapp,
    selectedWallet?.primaryAddressChain,
  ]);

  const parsedDestinationWallets = parseDestinationWallets({
    destinationWallets,
    tokenId: 'ALL_TOKENS', // you can provide an additional token id if you have one selected already
    additionalSupportedBlockchainIds: UTXO_EXPERIMENT_ENABLED_CHAINS,
  });

  const { cbPayInstance } = useCoinbasePay({
    appId: CBPAY_ID,
    widgetParameters: {
      destinationWallets: parsedDestinationWallets as DestinationWallets,
      presetFiatAmount: Number(sanitizedAmount),
      fiatCurrency: activeFiatCurrency.code.code,
      defaultAsset: selectedAsset?.code,
      redirectUrl: CB_WALLET_PUBLIC_URL,
    },
    onSuccess: () => launchSprig(SprigEventsEnum.BUY_SUCCESS),
  });

  const handleConnectCoinbaseClick = useCallback(() => {
    logOnrampLaunched({
      amount: sanitizedAmount,
      chainId: selectedAsset?.chainId,
      currencyCode: activeFiatCurrency.code.code,
    });
    cbPayInstance.current?.open();
  }, [cbPayInstance, activeFiatCurrency.code.code, sanitizedAmount, selectedAsset?.chainId]);

  return (
    <Button
      testID="buy-flow-button"
      onPress={handleConnectCoinbaseClick}
      block
      endIcon="forwardArrow"
    >
      {ctaText || formatMessage(messages.previewBuy)}
    </Button>
  );
}

import { useMemo } from 'react';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { useRecoilValue } from 'recoil';

import { walletsArraySelector, walletsByWalletGroupAtom } from '../state';

/**
 * Provides all of the usable wallets, given the wallet group id.
 *
 * @param walletGroupId wallet group id (essentially the ethereum address) to select wallets for
 * @param network Filters to matching network
 * @returns Wallets matching the params.
 */
export function useWalletsForWalletGroupId(
  walletGroupId: string | undefined,
  network?: Network,
): Wallet[] {
  const allWallet = useRecoilValue(walletsArraySelector);
  const walletsByWalletGroup = useRecoilValue(walletsByWalletGroupAtom);

  return useMemo(() => {
    if (!walletGroupId) {
      return allWallet ?? [];
    }

    const activeWallets = walletsByWalletGroup[walletGroupId] ?? [];
    // Filter by network
    if (network) {
      return activeWallets.filter((wallet) => {
        return Network.isEqual(network, wallet.network);
      });
    }

    return activeWallets;
  }, [walletGroupId, walletsByWalletGroup, network, allWallet]);
}

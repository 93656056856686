import { useCallback, useEffect } from 'react';
import { useNetworkHasBalanceMapLoadingIndicatorKeys } from 'cb-wallet-data/stores/Wallets/hooks/useNetworkHasBalanceMapLoadingIndicatorKeys';
import { useSetAndTriggerZeroBalanceEvent } from 'cb-wallet-data/stores/Wallets/hooks/useSetAndTriggerZeroBalanceEvent';
import {
  isNewlyCreatedWalletAtom,
  networkHasBalanceMapAtom,
} from 'cb-wallet-data/stores/Wallets/state';
import {
  getIsZeroBalanceWallet,
  getLastZeroBalanceFireTimeStamp,
} from 'cb-wallet-data/stores/Wallets/utils/isZeroBalanceWallet';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useDebouncedCallback } from './useDebouncedCallback';

export const DEBOUNCE_TIME = 300;

export type SprigEventTrack = (
  eventName: string,
  newAttribute?: { name: string; value: string } | undefined,
) => void;

type Params = {
  sprigEventTrack?: SprigEventTrack;
  includeLegacyBalanceCheck?: boolean;
};

/** Check network has balance map and update isZeroBalanceWallet local storage flag and trigger zero_balance event
 *
 * if some chains has balance, isZeroBalanceWallet = false
 * else if all chains has no balance, isZeroBalanceWallet = true
 */
export function useSyncIsZeroBalanceWallet() {
  const networkHasBalanceMap = useRecoilValue(networkHasBalanceMapAtom);
  const setAndTriggerZeroBalanceEvent = useSetAndTriggerZeroBalanceEvent();
  const setIsNewlyCreatedWallet = useSetRecoilState(isNewlyCreatedWalletAtom);

  // generate account specific loading indicator (networkHasBalanceMap) map
  const { accountLoadingIndicatorKeys, accountId } = useNetworkHasBalanceMapLoadingIndicatorKeys();

  const syncIsZeroBalanceWallet = useCallback(
    function syncIsZeroBalanceWallet({
      sprigEventTrack,
      // The zero balance override will ignore the legacy method of determining zero balance and use
      // the one that relies on useAddressHistory and useZeroBalanceTracking. Once useAddressHistory is
      // no longer behind a killswitch we can remove the legacy zero balance architecture.
      isZeroBalanceOverride,
    }: {
      isZeroBalanceOverride?: boolean;
      sprigEventTrack?: SprigEventTrack;
    }) {
      // Get the initialNetworkHasBalanceMap with keys and values
      const accountLoadingIndicatorMap = accountLoadingIndicatorKeys.reduce(
        function reduceAccountLoadingMap(accumulator: Record<string, boolean | undefined>, key) {
          accumulator[key] = networkHasBalanceMap[key];

          return accumulator;
        },
        {},
      );

      const networkHasBalanceMapByAccount = getNetworkHasBalanceMapByAccount({
        accountId,
        networkHasBalanceMap,
      });

      const networkHasBalanceValues = Object.values(networkHasBalanceMapByAccount);

      const loadingIndicatorValues = Object.values(accountLoadingIndicatorMap);

      if (
        (!networkHasBalanceValues.length || !loadingIndicatorValues.length) &&
        isZeroBalanceOverride === undefined
      )
        return;

      // used to short circuit to false as soon as at least one blockchain balance is present
      const doSomeChainsHaveBalance = networkHasBalanceValues.some((hasBalance) => hasBalance);

      // used to wait until all balances have loaded
      const doAllChainsHaveFlagSet = networkHasBalanceValues.every(
        (hasBalance) => hasBalance !== undefined,
      );

      // used to wait until all loading indicators have values
      const doAllLoadingIndicatorsHaveFlagSet = loadingIndicatorValues.every(
        (hasBalance) => hasBalance !== undefined,
      );

      // Exit if short circuit is not applicable and not all balances have loaded
      if (
        isZeroBalanceOverride === undefined &&
        !doSomeChainsHaveBalance &&
        !(doAllChainsHaveFlagSet && doAllLoadingIndicatorsHaveFlagSet)
      )
        return;

      const doAllChainsHaveZeroBalance =
        isZeroBalanceOverride !== undefined
          ? isZeroBalanceOverride
          : networkHasBalanceValues.every((hasBalance) => hasBalance === false);

      // get the value for local storage inside the function to avoid renders
      // even though isZeroBalanceWallet is not undefined
      // and call setAndTriggerZeroBalanceEvent to send analytic event
      const isZeroBalanceWallet = getIsZeroBalanceWallet(accountId);

      const currentTimeStamp = Date.now();

      const lastZeroBalanceFireTimeStamp = getLastZeroBalanceFireTimeStamp(accountId);

      // isZeroBalanceWallet is not set on import
      if (isZeroBalanceWallet === undefined) {
        if (doAllChainsHaveZeroBalance) {
          sprigEventTrack?.('zeroBalanceWalletImported');
        }

        return setAndTriggerZeroBalanceEvent({
          isZeroBalanceWallet: doAllChainsHaveZeroBalance,
          zeroBalanceEventContext: 'initial_balance_load',
          accountId,
        });
      }

      // balance update from $0
      if (isZeroBalanceWallet && !doAllChainsHaveZeroBalance) {
        setIsNewlyCreatedWallet(false);
        sprigEventTrack?.('zeroBalanceWalletFunded');

        return setAndTriggerZeroBalanceEvent({
          isZeroBalanceWallet: false,
          zeroBalanceEventContext: 'balance_update',
          accountId,
        });
      }

      // fire the event daily
      if (
        lastZeroBalanceFireTimeStamp &&
        currentTimeStamp > lastZeroBalanceFireTimeStamp + 1000 * 60 * 60 * 24
      ) {
        return setAndTriggerZeroBalanceEvent({
          isZeroBalanceWallet,
          zeroBalanceEventContext: 'daily',
          accountId,
        });
      }
    },
    [
      accountId,
      accountLoadingIndicatorKeys,
      networkHasBalanceMap,
      setAndTriggerZeroBalanceEvent,
      setIsNewlyCreatedWallet,
    ],
  );

  return syncIsZeroBalanceWallet;
}

// TODO (darion) remove once useAddressHistory is no longer behind a killswitch
export function useCheckForZeroBalanceWallet({
  sprigEventTrack,
  includeLegacyBalanceCheck,
}: Params = {}) {
  const syncIsZeroBalanceWallet = useSyncIsZeroBalanceWallet();

  const debouncedSyncIsZeroBalanceWallet = useDebouncedCallback(
    syncIsZeroBalanceWallet,
    DEBOUNCE_TIME,
  );

  useEffect(
    function legacyZeroBalanceCheck() {
      if (includeLegacyBalanceCheck) {
        debouncedSyncIsZeroBalanceWallet({ sprigEventTrack });
      }
    },
    [debouncedSyncIsZeroBalanceWallet, includeLegacyBalanceCheck, sprigEventTrack],
  );
}

function getNetworkHasBalanceMapByAccount({
  accountId,
  networkHasBalanceMap,
}: {
  accountId: string;
  networkHasBalanceMap: Record<string, boolean | undefined>;
}) {
  return Object.keys(networkHasBalanceMap).reduce(function getHasBalanceDataByAccount(
    accumulator: Record<string, boolean | undefined>,
    mapKey,
  ) {
    if (mapKey.toLowerCase().includes(accountId.toLowerCase())) {
      accumulator[mapKey] = networkHasBalanceMap[mapKey];
    }
    return accumulator;
  },
  {});
}

import {
  StoreKeys_lastSyncedBlockheightBalances,
  StoreKeys_lastSyncedBlockheightTransactions,
} from 'cb-wallet-data/stores/Wallets/storeKeys';
import { Store } from 'cb-wallet-store/Store';

type StoredBlockheights = Record<string, number>;

// Clears all local storage data for the given account
// This is used during the account deletion process
export function clearLocalStorageForAccount(accountId: string) {
  // Get all local storage keys
  const keys = Array.from({ length: localStorage.length }, (_, i) => localStorage.key(i));

  // Clear all keys that contain the account id
  // This includes wallet balances, tx history data, xPub keys, etc
  keys.forEach((key) => {
    if (key?.toLowerCase().includes(accountId.toLowerCase())) {
      localStorage.removeItem(key);
    }
  });

  // Clear lastSyncedBlockheight values for the account
  [StoreKeys_lastSyncedBlockheightBalances, StoreKeys_lastSyncedBlockheightTransactions].forEach(
    function clearSyncedBlockheightsForAccount(storeKey) {
      const blockheights = Store.get<StoredBlockheights>(storeKey) || {};
      const updatedBlockheights: StoredBlockheights = {};

      Object.keys(blockheights).forEach((key) => {
        if (!key.toLowerCase().includes(accountId.toLowerCase())) {
          updatedBlockheights[key] = blockheights[key];
        }
      });

      Store.set(storeKey, updatedBlockheights);
    },
  );
}

import {
  endPerfMark as endPerfMarkDefault,
  startPerfMark as startPerfMarkDefault,
} from '@cbhq/client-analytics';

import { PerfMarkFunction } from './perfMarkType';

/**
 * The extension and rn app share different implementations of perf marking. In order
 * to mark items in the data layer properly we wrap the core-vitals implementation
 * in the perfMark interface
 */
export const startPerfMark: PerfMarkFunction = startPerfMarkDefault;
export const endPerfMark: PerfMarkFunction = endPerfMarkDefault;

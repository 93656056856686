import { ETHEREUM_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { SOLANA_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Solana/constants';
import {
  PossibleAccountBlockchainSymbol,
  PossibleUTXOBlockchainSymbol,
} from 'cb-wallet-data/chains/blockchains';
import { AddressType } from 'cb-wallet-data/stores/Addresses/models/AddressType';

export type XPubKeyResponse = {
  xPubType: AddressType;
  xPubKey: string;
};

export type WalletConnectionAccountResponse = Record<PossibleAccountBlockchainSymbol, string[]>;

export type WalletConnectionUTXOResponse = Record<PossibleUTXOBlockchainSymbol, XPubKeyResponse[]>;

export type WalletConnectionResponse = {
  provider: string;
  account?: WalletConnectionAccountResponse;
  utxo?: WalletConnectionUTXOResponse;
  // The provider user ID corresponds to a Coinbase SCW or EOA user.
  // This is used for analytics purposes and non Coinbase users will not have this field.
  providerUserId?: string;
};

export function buildWalletConnectionAccountResponse({
  ethAddresses,
  solAddresses,
}: {
  ethAddresses?: string[];
  solAddresses?: string[];
}): WalletConnectionAccountResponse {
  return {
    [ETHEREUM_SYMBOL]: Array.isArray(ethAddresses) ? ethAddresses : [],
    [SOLANA_SYMBOL]: Array.isArray(solAddresses) ? solAddresses : [],
  };
}

export function buildWalletConnectionUTXOResponse({
  btcXPubKeyResponses,
  dogeXPubKeyResponses,
  ltcXPubKeyResponses,
}: {
  btcXPubKeyResponses?: XPubKeyResponse[];
  dogeXPubKeyResponses?: XPubKeyResponse[];
  ltcXPubKeyResponses?: XPubKeyResponse[];
}): WalletConnectionUTXOResponse {
  return {
    BTC: Array.isArray(btcXPubKeyResponses) ? btcXPubKeyResponses : [],
    DOGE: Array.isArray(dogeXPubKeyResponses) ? dogeXPubKeyResponses : [],
    LTC: Array.isArray(ltcXPubKeyResponses) ? ltcXPubKeyResponses : [],
  };
}

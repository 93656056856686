import { useEffect, useState } from 'react';

export function useDebounce<T>(value: T, delay: number | null, onDebounceComplete = () => {}): T {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    function updateDebouncedValue() {
      if (delay === null) {
        setDebouncedValue(value);
        onDebounceComplete();
        return;
      }

      // Update debounced value after delay
      const handler = setTimeout(function runAfterDebounce() {
        setDebouncedValue(value);
        onDebounceComplete();
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay, onDebounceComplete], // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { AddressType } from 'cb-wallet-data/stores/Addresses/models/AddressType';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { NetworkSetting } from 'cb-wallet-data/stores/Networks/models/NetworkSetting';
import { WalletConfiguration } from 'cb-wallet-data/stores/Wallets/models/WalletConfiguration';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
interface IWalletConfigurationForEthereum {
  readonly isERC20Supported: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/consistent-type-definitions
export interface WalletConfigurationForEthereum extends IWalletConfigurationForEthereum {}

export class WalletConfigurationForEthereum extends WalletConfiguration {
  constructor({
    blockchain,
    networkSetting,
    refreshInterval,
    currencyCode,
    decimals,
    imageURL,
    defaultReceiveType,
    supportsMultiWallet,
    isSyncingRequired,
    isERC20Supported,
  }: IWalletConfigurationForEthereum & {
    blockchain: Blockchain;
    networkSetting: NetworkSetting;
    refreshInterval: bigint;
    currencyCode: CurrencyCode;
    decimals: bigint;
    imageURL: string | undefined;
    defaultReceiveType: AddressType;
    supportsMultiWallet: boolean;
    isSyncingRequired: boolean;
  }) {
    super({
      blockchain,
      networkSetting,
      refreshInterval,
      currencyCode,
      decimals,
      imageURL,
      defaultReceiveType,
      supportsMultiWallet,
      isSyncingRequired,
    });

    Object.assign(this, { isERC20Supported });
  }

  displayName(network: Network): string {
    const ethereumNetwork = network.asChain();
    if (!ethereumNetwork) {
      return 'Unknown';
    }

    return ethereumNetwork.baseAssetDisplayName;
  }

  imageURLForNetwork(network: Network): string | undefined {
    const ethereumNetwork = network.asChain();

    return ethereumNetwork?.baseAssetImageUrl;
  }

  currencyCodeForNetwork(network: Network): CurrencyCode {
    const ethereumNetwork = network.asChain();
    if (!ethereumNetwork) {
      return this.currencyCode;
    }

    return new CurrencyCode(ethereumNetwork.baseAssetCurrencyCode ?? this.currencyCode.code);
  }

  feeDecimalForNetwork(network: Network): bigint {
    const ethereumNetwork = network.asChain();
    if (!ethereumNetwork) {
      return this.decimals;
    }

    return BigInt(ethereumNetwork.baseAssetDecimal);
  }
}

import {
  AppLoadVersion,
  triggerObserveAppLoad,
  triggerRepairDbWithWalletsIfNeededResult,
  triggerRepairDbWithWalletsIfNeededStart,
  triggerRepairDbWithWalletsRepairResult,
  triggerRepairDbWithWalletsRepairStart,
} from 'cb-wallet-analytics/app-load';
import { ETHEREUM_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { getAccounts, saveAccount } from 'cb-wallet-data/stores/Accounts/database';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { AccountType } from 'cb-wallet-data/stores/Accounts/models/AccountTypes';
import { findPrimaryWallet } from 'cb-wallet-data/stores/Accounts/utils/findPrimaryWallet';
import {
  deleteAddresses,
  getAllAddresses,
  saveAddresses,
} from 'cb-wallet-data/stores/Addresses/database';
import { Address } from 'cb-wallet-data/stores/Addresses/models/Address';
import { getWalletGroups, saveWalletGroup } from 'cb-wallet-data/stores/WalletGroups/database';
import { WalletGroup } from 'cb-wallet-data/stores/WalletGroups/models/WalletGroup';
import { deleteWallets, getWallets, saveWallets } from 'cb-wallet-data/stores/Wallets/database';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { getPlatformName } from 'cb-wallet-metadata/metadata';

import {
  calcIsExpectedToBeSignedIn,
  DefineIsExpectedToBeSignedInCallback,
} from './calcIsExpectedToBeSignedIn';
import { obscureAccountId } from './obscureAccountId';

export const status = {
  failure: 'failure',
  success: 'success',
  deferred: 'deferred', // requires signed-in state to be known
} as const;

const result = {
  noWallets: {
    reason: 'User has no wallets',
    status: status.success,
  },
  signedInWithEmptyDb: {
    reason: 'User is signed in, but requires full account repair',
    status: status.deferred, // status "deferred" will trigger repairDbWithNoWallets->repairSecretAccounts flow in RN
  },
  hasAccountAndWalletGroup: {
    reason: 'User already has at least 1 account and wallet group',
    status: status.success,
  },
  missingPrimaryWallet: {
    reason: 'User does not have a primary wallet to build from',
    status: status.failure,
  },
  repairSuccess: {
    reason: 'User has successfully created account and wallet group',
    status: status.success,
  },
  repairFailure: {
    reason: 'User has unsuccessfully created account and wallet group',
    status: status.failure,
  },
} as const;

type RepairDbWithWalletsIfNeededParams = {
  /** Version of app load - refer to useAppLoadVersion */
  appLoadVersion: AppLoadVersion;

  /** Callback function which reconciles the database accounts information with
   * platform-specific data to determine if the user is expected to be signed in */
  defineIsExpectedToBeSignedInCallback?: DefineIsExpectedToBeSignedInCallback;

  /** Whether secure enclave was accessible during app load and returned a result (RN only). */
  isAuthStateAccessible?: boolean;

  /** Whether user has stored mnemonic. */
  hasSecrets?: boolean;

  /** Whether restoring from iOS backup, where salt exists from previously storing secrets on another device, but secrets are missing (RN only). */
  isRestoringFromDeviceBackup?: boolean;

  /** List of obscured secret IDs stored in local storage (Ext only). */
  obscuredAccountIdsFromSecrets?: string[];
};

/**
 * Repairs the DB with wallets if possible by creating any missing or
 * incorrectly formatted data. This includes accounts, wallet groups,
 * Wallet/Address account ID, and Ledger wallet groups
 *
 * It also adds observability for app load in RootStack/App as a result of the
 * infinite loading spinner incident. @see WALL-14574
 *
 * NOTE Make sure to unmock this manually if needed during tests.
 *   @see apps/rn/test/setup.ts
 *
 * NOTE Must run after database is open + before recoil state hydration
 *
 * @param {RepairDbWithWalletsIfNeededParams} params
 */
export async function repairDbWithWalletsIfNeeded({
  appLoadVersion,
  defineIsExpectedToBeSignedInCallback,
  isAuthStateAccessible,
  hasSecrets = false,
  isRestoringFromDeviceBackup = false,
  obscuredAccountIdsFromSecrets,
}: RepairDbWithWalletsIfNeededParams) {
  triggerRepairDbWithWalletsIfNeededStart();
  // Verifies entities in the database and if there is a primary wallet that
  // can be used to create the first account if needed in migrateForMultiAccount
  const [wallets, addresses, accounts, walletGroups] = await Promise.all([
    getWallets(),
    getAllAddresses(),
    getAccounts(),
    getWalletGroups(),
  ]);

  // Filter on accounts read from the DB
  const privateKeyAccounts = accounts.filter((account) => account.type === AccountType.PRIVATE_KEY);
  const ledgerAccounts = accounts.filter((account) => account.type === AccountType.LEDGER);
  const mnemonicAccounts = accounts.filter((account) => account.type === AccountType.MNEMONIC);
  const walletLinkAccounts = accounts.filter((account) => account.type === AccountType.WALLET_LINK);

  const primaryWallet = findPrimaryWallet(wallets);

  const hasAccounts = Boolean(accounts.length);
  const isExpectedToBeSignedIn = calcIsExpectedToBeSignedIn({
    defineIsExpectedToBeSignedInCallback,
    hasSecrets,
    hasAccounts,
  });

  triggerObserveAppLoad({
    isAuthStateAccessible,
    isExpectedToBeSignedIn,
    isRestoringFromDeviceBackup,
    hasSecrets,
    secretsLength: obscuredAccountIdsFromSecrets?.length,
    accountIdsFromSecrets: obscuredAccountIdsFromSecrets,
    accountsLength: accounts.length,
    accountIdsFromDB: await Promise.all(accounts.map(async ({ id }) => obscureAccountId(id))),
    hasPrimaryWallet: Boolean(primaryWallet),
    walletGroupsLength: walletGroups.length,
    walletsLength: wallets.length,
    addressesLength: addresses.length,
    privateKeyAccountsLength: privateKeyAccounts.length,
    ledgerAccountsLength: ledgerAccounts.length,
    mnemonicAccountsLength: mnemonicAccounts.length,
    walletLinkAccountsLength: walletLinkAccounts.length,
    version: appLoadVersion,
  });

  // Assumption: if there are no wallets, the user:
  // - has not onboarded yet
  // - Or backgrounded the app in the middle of onboarding
  // - Or is coming from legacy app
  //
  // Note: Without wallets, this function is not able to repair the DB because there
  // is no primary wallet.
  if (!wallets.length) {
    // If has secrets stored, but is missing wallets + account data, will return "deferred" status,
    // which will trigger repair secret account flow, to rebuild all data.
    // Note: This is likely a legacy mobile user. Only triggered currently in RN.
    if (hasSecrets && (!accounts.length || !walletGroups.length)) {
      triggerRepairDbWithWalletsIfNeededResult(result.signedInWithEmptyDb);
      return result.signedInWithEmptyDb;
    }

    // If signed-in state is unknown, we can not make an assumption of how to handle
    // there not being wallets present.
    // - If not signed in, user will remain on signed-out splash screen.
    // - If signed in, missing wallets will get filled by wallet backfilling.
    triggerRepairDbWithWalletsIfNeededResult(result.noWallets);
    return result.noWallets;
  }

  // Assumptions:
  // 1. There's no need to create an account or wallet group.
  // 2. It does not verify that the account, wallet group, and primaryWallet
  // work with each other.
  if (accounts.length && walletGroups.length) {
    // Verify that all wallets and addresses contain the account ID field,
    // and ID is formatted correctly.
    await repairAddAccountIdToWalletsAndAddresses({ accounts, wallets, addresses });

    // Extension only: Repair Ledger and private key wallet group by ensuring it has a zero wallet index
    const platformName = getPlatformName();
    if (platformName === 'web') {
      await repairMultiAccountRollupWalletGroupWalletIndexes({
        accountType: AccountType.LEDGER,
        accounts,
        walletGroups,
      });
      await repairMultiAccountRollupWalletGroupWalletIndexes({
        accountType: AccountType.PRIVATE_KEY,
        accounts,
        walletGroups,
      });
    }

    triggerRepairDbWithWalletsIfNeededResult(result.hasAccountAndWalletGroup);
    return result.hasAccountAndWalletGroup;
  }

  // Assumption: There's a need to create an account or wallet group, but there
  // is no primary wallet so it's not possible.
  if (!primaryWallet) {
    triggerRepairDbWithWalletsIfNeededResult(result.missingPrimaryWallet);
    return result.missingPrimaryWallet;
  }

  // Else rebuild idempotently an account and first wallet group
  // Assumptions:
  // 1. Account should be mnemonic
  // 2. WalletGroup.walletIndex is 0 (first wallet group at index 0)
  try {
    triggerRepairDbWithWalletsRepairStart();

    const account = new Account({
      type: AccountType.MNEMONIC,
      primaryAddressChain: ETHEREUM_SYMBOL,
      primaryAddress: primaryWallet.primaryAddress,
    });
    await saveAccount(account);

    const walletGroup = new WalletGroup({
      accountId: account.id,
      walletIndex: 0n,
    });
    await saveWalletGroup(walletGroup);

    triggerRepairDbWithWalletsRepairResult(result.repairSuccess);
    triggerRepairDbWithWalletsIfNeededResult(result.repairSuccess);

    return result.repairSuccess;
  } catch (error) {
    const e = error as Error;
    const failureResult = {
      reason: e.message,
      status: status.failure,
    };

    cbReportError({ error: e, context: 'app-load', isHandled: false, severity: 'error' });
    triggerRepairDbWithWalletsRepairResult(failureResult);
    // should come after triggerRepairDbWithWalletsRepairResult
    triggerRepairDbWithWalletsIfNeededResult(result.repairFailure);

    return failureResult;
  }
}

type RepairAddAccountIdToWalletsAndAddressesOptions = {
  accounts: Account[];
  wallets?: Wallet[];
  addresses?: Address[];
};

// Migration Script: DataMigrationRunner/migrations/addAccountIdToWalletsAndAddresses.ts
async function repairAddAccountIdToWalletsAndAddresses({
  accounts,
  wallets,
  addresses,
}: RepairAddAccountIdToWalletsAndAddressesOptions) {
  // Assumption: User does not require db repair given they already have multiple
  // accounts. The db cannot contain multiple accounts if there are missing fields
  // or formatting errors
  if (accounts.length > 1) return;

  const prevWallets = wallets ?? (await getWallets());
  const prevAddresses = addresses ?? (await getAllAddresses());

  // Verify that the account's wallets and addresses has an account ID field,
  // and the ID is formatted correctly
  const accountId = accounts[0].id; // accounts items should always contain exactly one item
  const walletIdsToDelete: Wallet['id'][] = [];
  const addressIdsToDelete: Address['id'][] = [];

  // If any wallet doesn't have correct Wallet.accountId or Wallet.id,
  // set both values, and queue that wallet to be updated in the db.
  // We are not spreading the reduced object here so it is fine
  // eslint-disable-next-line wallet/no-spread-in-reduce
  const nextWallets = prevWallets.reduce<Wallet[]>(function reduceWalletsThatNeedMigration(
    acc,
    prevWallet,
  ) {
    const hasAccountIdField = prevWallet.accountId === accountId;
    const hasMultiAccountFormattedId = prevWallet.id.endsWith(accountId);
    const isValidWalletId = Wallet.isValidFormattedId(prevWallet.id);

    if (!hasAccountIdField || !hasMultiAccountFormattedId || !isValidWalletId) {
      const nextWalletId = Wallet.generateId({
        blockchain: prevWallet.blockchain,
        currencyCode: prevWallet.currencyCode,
        network: prevWallet.network,
        contractAddress: prevWallet.contractAddress,
        selectedIndex: prevWallet.selectedIndex,
        accountId,
      });

      const nextWallet = Wallet.fromDMO({
        ...prevWallet.asDMO,
        id: nextWalletId,
        accountId,
      });

      acc.push(nextWallet);

      const isNewRecord = nextWalletId !== prevWallet.id;
      if (isNewRecord) walletIdsToDelete.push(prevWallet.id);
    }

    return acc;
  },
  []);

  // If any address doesn't have correct Address.accountId or Address.id,
  // set both values, and queue that address to be updated in the db.
  // We are not spreading the reduced object here so it is fine
  // eslint-disable-next-line wallet/no-spread-in-reduce
  const nextAddresses = prevAddresses.reduce<Address[]>(function reduceAddressesThatNeedMigration(
    acc,
    prevAddress,
  ) {
    const hasAccountIdField = prevAddress.accountId === accountId;
    const hasMultiAccountFormattedId = prevAddress.id.endsWith(accountId);

    if (!hasAccountIdField || !hasMultiAccountFormattedId) {
      const nextAddressId = Address.generateId({
        blockchain: prevAddress.blockchain,
        currencyCode: prevAddress.currencyCode,
        network: prevAddress.network,
        type: prevAddress.type,
        isChangeAddress: prevAddress.isChangeAddress,
        index: prevAddress.index,
        accountId,
      });

      const nextAddress = Address.fromDMO({
        ...prevAddress.asDMO,
        id: nextAddressId,
        accountId,
      });

      acc.push(nextAddress);

      const isNewRecord = nextAddressId !== prevAddress.id;
      if (isNewRecord) addressIdsToDelete.push(prevAddress.id);
    }

    return acc;
  },
  []);

  // For any updated wallets or addresses, save them to the db.
  const saveChangesToDbPromises = [];

  if (nextWallets.length > 0) {
    saveChangesToDbPromises.push(saveWallets(nextWallets));
  }

  if (nextAddresses.length > 0) {
    saveChangesToDbPromises.push(saveAddresses(nextAddresses));
  }

  // For any wallets or addresses which are saved as new records
  // from changing the ID, delete the previous records from the db.
  if (walletIdsToDelete.length) {
    saveChangesToDbPromises.push(deleteWallets(walletIdsToDelete));
  }

  if (addressIdsToDelete.length) {
    saveChangesToDbPromises.push(deleteAddresses(addressIdsToDelete));
  }

  await Promise.all(saveChangesToDbPromises);
}

// Migration Script: DataMigrationRunner/migrations/migrateLedgerWalletGroupIndexes.ts
async function repairMultiAccountRollupWalletGroupWalletIndexes({
  accountType,
  accounts,
  walletGroups,
}: {
  accountType: AccountType.LEDGER | AccountType.PRIVATE_KEY;
  accounts: Account[];
  walletGroups: WalletGroup[];
}) {
  // Get all multi account rollup type accounts (LEDGER || PRIVATE_KEY)
  const rollupAccounts = accounts.filter(({ type }) => type === accountType);

  // There should only be one account to repair per rollup
  if (rollupAccounts.length > 1 || rollupAccounts.length === 0) return;
  const [rollupAccount] = rollupAccounts;

  // Get wallet group for this account
  const rollupWalletGroup = walletGroups.find(
    (walletGroup) => walletGroup.accountId === rollupAccount.id,
  );

  // Wallet group associated with ledger does not exist - nothing to repair
  if (!rollupWalletGroup) return;

  // If wallet group already has an index of 0, no need to repair, return.
  const isMultiAccountRollupWalletGroupIndexZero = rollupWalletGroup.walletIndex === 0n;

  if (isMultiAccountRollupWalletGroupIndexZero) return;

  const newWalletGroup = new WalletGroup({
    ...rollupWalletGroup,
    walletIndex: 0n,
  });

  //  Save wallet group with wallet index of 0
  await saveWalletGroup(newWalletGroup);
}

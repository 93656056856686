import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { WalletConfiguration } from 'cb-wallet-data/stores/Wallets/models/WalletConfiguration';

import { BLOCKCHAIN_SOLANA_MAINNET_IMAGE_URL, NAME } from '../constants';

export class WalletConfigurationForSolana extends WalletConfiguration {
  displayName(): string {
    return NAME;
  }

  imageURLForNetwork(): string {
    return BLOCKCHAIN_SOLANA_MAINNET_IMAGE_URL;
  }

  currencyCodeForNetwork(): CurrencyCode {
    return this.currencyCode;
  }

  feeDecimalForNetwork(): bigint {
    return this.decimals;
  }
}

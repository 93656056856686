import { useMemo } from 'react';
import { EthereumNetworkMap } from 'cb-wallet-data/chains/AccountBased/Ethereum/EthereumChain';
import {
  CHAIN_ID_TO_BLOCKCHAIN_MAP,
  ETHEREUM_NETWORK_CODES,
  NETWORK_CODE_TO_BLOCKCHAIN_MAP,
  SUPPORTED_BLOCKCHAINS,
  UTXO_NETWORK_CODES,
} from 'cb-wallet-data/stores/Buy/cbpay/consts';
import { usePrimaryReceiveAddresses } from 'cb-wallet-data/stores/Wallets/hooks/usePrimaryReceiveAddresses';

const allEthereumMainNets = Object.values(EthereumNetworkMap.whitelisted).filter(
  (network) => !network.isTestnet && !!CHAIN_ID_TO_BLOCKCHAIN_MAP[network.chainId],
);

// All supported blockchain names by both cbpay and cbwallet
export type SupportedBlockchain = (typeof SUPPORTED_BLOCKCHAINS)[number]['name'];

// All supported network codes by both cbpay and cbwallet
export type SupportedNetwork = (typeof SUPPORTED_BLOCKCHAINS)[number]['currencyCode'];

export type DestinationWallet = {
  address: string;
  /** List of networks enabled for the associated address. All assets available per network are displayed to the user. */
  blockchains?: SupportedBlockchain[];
  /** List of assets enabled for the associated address. They are appended to the available list of assets. */
  assets?: string[];
  /** Restrict the networks available for the associated assets. */
  supportedNetworks?: SupportedBlockchain[];
};

type AssetData = {
  asset?: string;
  chainId?: number;
};

export type UseDestinationWalletsProps = AssetData & {
  walletGroupId: string | undefined;

  // specify the networks to filter the destination wallets
  // "ETH" | "AVAX" | "MATIC" | "OP" | "ARB" | "BASE" | "SOL" | "BTC" | "DOGE" | "LTC"
  networks?: SupportedNetwork[];
};

/** Returns destination wallets for the given asset and chainId.
 *
 * If asset, chainId and networks are not provided, it returns all destination wallets for active wallet group.
  [
      {
        address: '0x123',
        supportedNetworks: [
          'ethereum',
          'optimism',
          'base',
          'polygon',
          'arbitrum',
          'avalanche-c-chain',
        ],
      },
      { address: 'sol123', supportedNetworks: ['solana'] },
      { address: 'btc123', supportedNetworks: ['bitcoin'] },
      { address: 'doge123', supportedNetworks: ['dogecoin'] },
      { address: 'ltc123', supportedNetworks: ['litecoin'] },
    ]
 * If only networks are provided, it returns destination wallets for the given networks.
    Example: networks: ['MATIC', 'ETH', 'LTC']
    
    Output: 
    [ 
      { address: '0x123', supportedNetworks: ['polygon', 'ethereum'] },
      { address: 'ltc123', supportedNetworks: ['litecoin'] },
    ]


 * If only asset is provided, it returns destination wallets with all blockchains for the given asset.
    Example1: asset: 'BTC'
    Output1:
    [
     { address: 'btc123', supportedNetworks: ['bitcoin'] },
    ]

    Example2: asset: 'USDC'
    Output2:
    [
      {
        address: '0x123',
        assets: ['USDC'],
        supportedNetworks: [
          'ethereum',
          'optimism',
          'base',
          'polygon',
          'arbitrum',
          'avalanche-c-chain',
        ],
      },
      { address: 'sol123', assets: ['USDC'], supportedNetworks: ['solana'] },
    ]
 *
 * If only chainId are provided, it returns destination wallets for the given blockchain.
    Example1: chainId: 1
    Output2: [{ address: '0x123', supportedNetworks: ['ethereum'] }]

    Example1: chainId: 8453
    Output2: [{ address: '0x123', supportedNetworks: ['base'] }]
 *
 * If asset is provided and chainId is not provided,
 *  - For account based assets, it returns destination wallets with all blockchains for the given asset.
 *  - For UTXO assets, it returns destination wallets with the given asset.
 *
 * Others cases include:
 * - assets and networks are provided but chainId is not provided
 * - chainId and networks are provided but asset is not provided
 * - asset, chainId and networks are provided
 * checkout the test cases for more examples.
 *
 * Note: Make sure to asset and chainId combination is valid,
 *       otherwise cbpay will not be able to find the asset.
 */
export function useDestinationWallets({
  asset = undefined,
  chainId = undefined,
  networks = undefined,
  walletGroupId,
}: UseDestinationWalletsProps): DestinationWallet[] {
  const primaryReceiveAddresses = usePrimaryReceiveAddresses(walletGroupId);

  const networkCode = useMemo(() => {
    return getNetworkCode({ asset, chainId });
  }, [asset, chainId]);

  const destinationWallets = useMemo(() => {
    return Array.from(primaryReceiveAddresses).reduce<DestinationWallet[]>(
      function convertToDestinationWallets(returningDesWallets, [blockchainSymbol, { address }]) {
        const destinationWallet: DestinationWallet = { address };

        if (UTXO_NETWORK_CODES.includes(blockchainSymbol)) {
          destinationWallet.supportedNetworks = [
            NETWORK_CODE_TO_BLOCKCHAIN_MAP[blockchainSymbol].name,
          ];

          if (
            asset === blockchainSymbol ||
            (!asset && !chainId && (!networks || networks.includes(blockchainSymbol)))
          ) {
            destinationWallet.assets = asset ? [asset] : undefined;
            returningDesWallets.push(destinationWallet);
          }
        }

        if (blockchainSymbol === 'SOL') {
          const solCondition = !asset && (!networkCode || networkCode === blockchainSymbol);

          if (
            ((asset && (!networkCode || networkCode === 'SOL')) || solCondition) &&
            (!networks || networks.includes(blockchainSymbol))
          ) {
            destinationWallet.assets = asset ? [asset] : undefined;
            destinationWallet.supportedNetworks = [NETWORK_CODE_TO_BLOCKCHAIN_MAP.SOL.name];
            returningDesWallets.push(destinationWallet);
          }
        }

        if (blockchainSymbol === 'ETH') {
          const filteredSupportedNetworks = networks
            ?.filter((network) => ETHEREUM_NETWORK_CODES.includes(network))
            .map((code) => NETWORK_CODE_TO_BLOCKCHAIN_MAP[code].name);
          if (asset) {
            if (!networkCode && !networks) {
              destinationWallet.assets = [asset];
              destinationWallet.supportedNetworks = allEthereumMainNets.map(
                (mainnet) => CHAIN_ID_TO_BLOCKCHAIN_MAP[mainnet.chainId].name,
              );
              returningDesWallets.push(destinationWallet);
            } else if (networkCode && ETHEREUM_NETWORK_CODES.includes(networkCode)) {
              destinationWallet.assets = [asset];
              destinationWallet.supportedNetworks = [
                NETWORK_CODE_TO_BLOCKCHAIN_MAP[networkCode].name,
              ];
              returningDesWallets.push(destinationWallet);
            } else if (
              !networkCode &&
              filteredSupportedNetworks &&
              filteredSupportedNetworks.length > 0 &&
              !UTXO_NETWORK_CODES.includes(asset) &&
              asset !== 'SOL'
            ) {
              destinationWallet.assets = [asset];

              destinationWallet.supportedNetworks = filteredSupportedNetworks;
              returningDesWallets.push(destinationWallet);
            }
          } else if (!asset) {
            if (networkCode && ETHEREUM_NETWORK_CODES.includes(networkCode)) {
              if (networks) {
                destinationWallet.supportedNetworks = [
                  NETWORK_CODE_TO_BLOCKCHAIN_MAP[networkCode].name,
                ];
                returningDesWallets.push(destinationWallet);
              } else {
                destinationWallet.supportedNetworks = [
                  NETWORK_CODE_TO_BLOCKCHAIN_MAP[networkCode].name,
                ];
                returningDesWallets.push(destinationWallet);
              }
            } else if (
              !networkCode &&
              filteredSupportedNetworks &&
              filteredSupportedNetworks.length > 0
            ) {
              destinationWallet.supportedNetworks = filteredSupportedNetworks;
              returningDesWallets.push(destinationWallet);
            } else if (!networkCode && !networks) {
              destinationWallet.supportedNetworks = allEthereumMainNets.map(
                (mainnet) => CHAIN_ID_TO_BLOCKCHAIN_MAP[mainnet.chainId].name,
              );
              returningDesWallets.push(destinationWallet);
            }
          }
        }

        return returningDesWallets;
      },
      [],
    );
  }, [asset, chainId, networkCode, networks, primaryReceiveAddresses]);

  return destinationWallets;
}

/** Network code is used to determine the blockchain
 * 1. Use default network if provided
 * 2. Use chainId to determine network if provided
 * 3. If asset is defined and it is an UTXO asset, use the asset code as the network
 * 4. If asset is defined and it is not an UTXO asset, use the asset to determine network
 */
export function getNetworkCode({ asset, chainId }: AssetData): SupportedNetwork | undefined {
  // Use chainId to determine network
  if (chainId && CHAIN_ID_TO_BLOCKCHAIN_MAP[chainId]) {
    return CHAIN_ID_TO_BLOCKCHAIN_MAP[chainId].currencyCode;
  }

  // If asset is defined, use the asset to determine network
  if (asset && UTXO_NETWORK_CODES.includes(asset)) {
    return asset as SupportedNetwork;
  }
}

/**
 * Returns the default network for the given asset and chainId
 */
export function getDefaultNetwork({ asset, chainId }: AssetData) {
  const networkCode = getNetworkCode({ asset, chainId });

  if (networkCode) {
    return NETWORK_CODE_TO_BLOCKCHAIN_MAP[networkCode].name;
  }
}

import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { CHECK_GEO_QUERY_KEY } from 'cb-wallet-data/stores/OFAC/hooks/useIsPermitted';
import { getJSON } from 'cb-wallet-http/fetchJSON';

export type FetchCountryCodeResponse = {
  result: {
    country: string;
    permitted: boolean;
  };
};

async function fetchCountryCode(): Promise<string> {
  const { result } = await getJSON<FetchCountryCodeResponse>(CHECK_GEO_QUERY_KEY, {});

  return result.country;
}

export type UseCountryCodeParams = {
  cacheTime?: number;
};

export function useCountryCode({ cacheTime = 0 }: UseCountryCodeParams = {}): string | undefined {
  const { data: countryCode } = useQuery(
    [`useCountryCode/${CHECK_GEO_QUERY_KEY}`],
    fetchCountryCode,
    {
      cacheTime,
      suspense: false,
      notifyOnChangeProps: ['data'],
    },
  );

  return countryCode;
}

import { bigIntToDecimal } from 'cb-wallet-data/utils/BigInt+Core';
import { Decimal } from 'decimal.js';

Decimal.set({ precision: 100 });

export const BLACK_HOLE_ADDRESS = '0x0000000000000000000000000000000000000000';

export function getScaledDecimal(value: string | number, decimals: bigint) {
  const decimalValue = new Decimal(value);
  const power = new Decimal(10).pow(Number(decimals));
  return decimalValue.mul(power);
}

export function getDownScaledDecimal(value: string | number, decimals: bigint) {
  const decimalValue = new Decimal(value || 0);
  const power = new Decimal(10).pow(Number(decimals));
  return decimalValue.div(power);
}

export function checkInsufficientFunds(amount: bigint, maxAmount: bigint) {
  const amountInDecimal = bigIntToDecimal(amount);
  const maxAmountInDecimal = bigIntToDecimal(maxAmount);
  return amountInDecimal.greaterThan(maxAmountInDecimal);
}

export function FormatCurrencySymbol(currency: string) {
  // black hole address is ETH
  if (currency === BLACK_HOLE_ADDRESS) {
    return 'ETH';
  }

  // for V1 only ETH mainnet is supported
  return 'ETH';
}

/**
 * For values > 10: The result is rounded to the nearest whole number.
 * For values between 1 and 5: Up to 1 decimal place is shown
 * For values < 1: All decimal places are shown until a significant digit is found.
 *      will show 2 significant digits if they are adjacent to each other
 */
export function convertWeiToGwei(value: bigint): string {
  const scaledValue = getScaledDecimal(value.toString(), BigInt(-9));
  if (scaledValue.greaterThan(10)) {
    return scaledValue.toFixed(0, Decimal.ROUND_HALF_UP);
  }
  if (scaledValue.greaterThan(1)) {
    return scaledValue.toDecimalPlaces(1, Decimal.ROUND_HALF_UP).toString();
  }
  return scaledValue
    .toSignificantDigits(2, Decimal.ROUND_HALF_UP)
    .toFixed(9, Decimal.ROUND_HALF_UP)
    .toString()
    .replace(/0+$/, '')
    .replace(/\.$/, '');
}

export function convertGweiToWei(value: string): bigint {
  if (value === '') return 0n;
  const fixedValue = Number(value).toFixed(9);
  return BigInt(getScaledDecimal(fixedValue, 9n).toFixed(0));
}

export function convertEthtoWei(value: string): bigint {
  if (value === '') return 0n;
  const fixedValue = Number(value).toFixed(18);
  return BigInt(getScaledDecimal(fixedValue, 18n).toFixed(0));
}

export function convertWeiToEth(value: bigint): string {
  return getDownScaledDecimal(value.toString(), 18n).toString();
}

export function convertWeiToEthDecimal(value: bigint): string {
  return getDownScaledDecimal(value.toString(), 18n).toFixed(18);
}

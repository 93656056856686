import { Span } from '@cbhq/client-analytics';

import { TracerInterface } from '../types';

/**
 * DisabledTracer
 */
export class DisabledTracer implements TracerInterface {
  /**
   * startSpan
   */
  startSpan(_spanName: Span['name']): undefined {
    return undefined;
  }

  /**
   * endSpan
   */
  endSpan(_spanName: Span['name']): undefined {
    return undefined;
  }

  /**
   * setMetadata
   */
  setMetadata(_: { spanName?: Span['name']; metadata: Span['meta'] }): undefined {
    return undefined;
  }

  /**
   * done
   */
  done(): undefined {
    return undefined;
  }
}

import BN from 'bn.js';

export const ethereumChains = {
  mainnet: 1,
};

export const currencyNames = {
  eth: 'Ethereum',
};

export const currencyCodes = {
  eth: 'ETH',
};

export const minimumGasLimit = new BN(21000);

export const MEV_ROUTING_RULE = 'urn:routing-rule:function:wallet-mev-dex';
export const TPP_MEV_ROUTING_HEADER = {
  'x-tpp-routing-rule': MEV_ROUTING_RULE,
};

import { useCallback } from 'react';
import { logNudgeBalanceFetchDuration } from 'cb-wallet-analytics/balances/Balances';
import {
  EthereumAddressType,
  EthereumBlockchain,
} from 'cb-wallet-data/chains/AccountBased/Ethereum/config';
import { errorIgnoredChainIds } from 'cb-wallet-data/chains/AccountBased/Ethereum/EthereumChain';
import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { SprigEventTrack } from 'cb-wallet-data/hooks/useSyncIsZeroBalanceWallet';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { useFirstCreatedAccount } from 'cb-wallet-data/stores/Accounts/hooks/useFirstCreatedAccount';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { getWalletsOfBlockchainRecord, saveWallets } from 'cb-wallet-data/stores/Wallets/database';
import { useSetLastBalanceUpdateMetadata } from 'cb-wallet-data/stores/Wallets/hooks/useLastBalanceUpdateMetadata';
import {
  setBlockchainHasBalance,
  useSetBlockchainIndexesSynced,
} from 'cb-wallet-data/stores/Wallets/hooks/useZeroBalanceTracking';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { WalletAddress } from 'cb-wallet-data/stores/Wallets/models/WalletAddress';
import { blockchainIsRefreshedMapAtom } from 'cb-wallet-data/stores/Wallets/state';
import { getSubmitTimerAndSource, getTimer } from 'cb-wallet-data/utils/globalTimer';
import { useSetRecoilState } from 'recoil';
import {
  ERC20Token,
  EthereumAddressHistory,
  HistoryEventUpdate,
} from 'wallet-engine-signing/history';
import { BaseAssetError, ERC20MissingMetadataError } from 'wallet-engine-signing/history/errors';

type UseUpdateEthAddressHistoryParams = {
  sprigEventTrack?: SprigEventTrack;
};

/**
 * Provides a stateful callback function, for listening to all ETH address
 * history updates, including init, poll, and nudge type events.
 */
export function useUpdateEthAddressHistory({ sprigEventTrack }: UseUpdateEthAddressHistoryParams) {
  const setBlockchainIsRefreshedMap = useSetRecoilState(blockchainIsRefreshedMapAtom);
  const firstCreatedAccount = useFirstCreatedAccount();
  const setBlockchainIndexesSynced = useSetBlockchainIndexesSynced();
  const setLastBalanceUpdateMetadata = useSetLastBalanceUpdateMetadata();

  return useCallback(
    async function updateEthAddressHistory(ev: HistoryEventUpdate) {
      const historyUpdates = ev.updates as EthereumAddressHistory[];
      const existingWalletsRecord = await getWalletsOfBlockchainRecord('ETH');
      const syncedIndexes = new Set<bigint>();
      const refreshUpdateStartPerfMark = performance.now();

      // We are not spreading the reduced object here so it is fine
      // eslint-disable-next-line wallet/no-spread-in-reduce
      const walletsToSave = historyUpdates.reduce<Wallet[]>(function reduceEthWalletsToSave(
        acc,
        update,
      ) {
        const { context, etherBalance: receivedBalance, erc20Balances, errors } = update;
        const walletId = context?.walletId;
        const wallet = existingWalletsRecord[walletId];
        if (!wallet) {
          return acc;
        }

        const { accountId, selectedIndex } = Wallet.propsFromId(walletId);
        const isPrimaryAccount = accountId === firstCreatedAccount?.id;

        syncedIndexes.add(selectedIndex!);

        const ignoreErrors = errorIgnoredChainIds.includes(Number(update.chainId));

        if (errors.length && !ignoreErrors) {
          errors.forEach(function reportError(error: Error) {
            cbReportError({
              error,
              metadata: { walletId },
              context: 'address_history_error',
              isHandled: false,
              severity: 'error',
            });
          });
        }

        const updatedWallet = Wallet.fromDMO({
          ...wallet.asDMO,
          balanceStr: String(receivedBalance),
          lastBalanceUpdateTxHash: ev.txHash,
        });

        // Update the base asset wallet if it changed.
        if ((wallet && !Wallet.isEqual(wallet, updatedWallet)) || ev.interval === 'nudge') {
          // If there was an error fetching the native asset balance, don't update the wallet
          const hasNativeAssetError = errors.find((e) => e instanceof BaseAssetError) !== undefined;
          if (!hasNativeAssetError) {
            acc.push(updatedWallet);
          }
        }

        if (isPrimaryAccount && receivedBalance > 0n) {
          setBlockchainHasBalance('ETH', true);
        }

        // Create or update erc20 wallets
        if (erc20Balances?.length) {
          for (const erc20Balance of erc20Balances) {
            const erc20Wallet = createErc20Wallet({
              address: wallet.primaryAddress,
              erc20Token: erc20Balance,
              accountId: wallet.accountId,
              walletIndex: wallet.selectedIndex!,
              txHashFromNudge: ev.txHash,
            });

            if (!erc20Wallet && !ignoreErrors && erc20Balance.errors.length) {
              erc20Balance.errors.forEach((error) => {
                // There are lots of spam tokens that are missing metadata necessary to display
                // the token correctly, usually decimals, so we don't report these errors
                // to reduce noise
                if (!(error instanceof ERC20MissingMetadataError)) {
                  cbReportError({
                    error,
                    metadata: { assetUUID: erc20Balance.assetUUID ?? '' },
                    context: 'address_history_error',
                    isHandled: false,
                    severity: 'error',
                  });
                }
              });

              continue;
            }

            if (isPrimaryAccount && erc20Balance.balance > 0n) {
              setBlockchainHasBalance('ETH', true);
            }

            if (erc20Wallet) {
              const existingWallet = existingWalletsRecord[erc20Wallet.id];
              if (
                !existingWallet ||
                (existingWallet && !Wallet.isEqual(existingWallet, erc20Wallet))
              ) {
                acc.push(erc20Wallet);
              }
            }
          }
        }

        return acc;
      },
      []);

      if (walletsToSave.length) {
        saveWallets(walletsToSave);
      }

      // Track the wallet indexes that have been synced so zero balance tracking
      // can fire once they're all synced
      setBlockchainIndexesSynced('ETH', syncedIndexes, sprigEventTrack);

      if (ev.interval === 'init') {
        setBlockchainIsRefreshedMap((curr) => ({
          ...curr,
          ETH: true,
        }));
        setLastBalanceUpdateMetadata({ updatedAt: new Date().getTime(), source: 'init' });
      }

      if (ev.interval === 'nudge') {
        const [update] = ev.updates as EthereumAddressHistory[];
        const { timer: txSubmittedTimer, source: txSource } = getSubmitTimerAndSource(ev.txHash!);
        const submittedAt = txSubmittedTimer ?? undefined;

        logNudgeBalanceFetchDuration({
          network: Network.fromChainId({ chainPrefix: 'ETH', chainId: update.chainId })?.rawValue,
          nudgedAt: refreshUpdateStartPerfMark,
          absoluteNudgedAt: refreshUpdateStartPerfMark,
          submittedAt,
          startRefreshAt: refreshUpdateStartPerfMark,
          nudgeSource: 'ON_CHAIN',
          txSource,
          isDelayedRefresh: !!ev.didRetry,
          unlockedAt: getTimer('balance.AppStart'),
          getAppBackgroundStart: () => getTimer('timer.AppBackgroundStart'),
          getAppBackgroundEnd: () => getTimer('timer.AppBackgroundEnd'),
        });
      }
    },
    [
      setBlockchainIndexesSynced,
      sprigEventTrack,
      firstCreatedAccount?.id,
      setBlockchainIsRefreshedMap,
      setLastBalanceUpdateMetadata,
    ],
  );
}

export function createErc20Wallet({
  address,
  erc20Token,
  accountId,
  walletIndex,
  txHashFromNudge,
}: {
  address: string;
  erc20Token: ERC20Token;
  accountId: string;
  walletIndex: bigint;
  txHashFromNudge?: string;
}) {
  if (erc20Token.errors.length) {
    return undefined;
  }

  return new Wallet({
    accountId,
    primaryAddress: address,
    addresses: [new WalletAddress(EthereumAddressType, address, walletIndex)],
    displayName: erc20Token.displayName,
    currencyCode: new CurrencyCode(erc20Token.symbol),
    imageURL: erc20Token.imageURL?.toString(),
    balance: erc20Token.balance,
    decimals: erc20Token.decimals,
    blockchain: EthereumBlockchain,
    minimumBalance: undefined,
    network: Network.fromChainId({
      chainId: erc20Token.chainId,
    }),
    contractAddress: erc20Token.contractAddress,
    selectedIndex: walletIndex,
    assetUUID: erc20Token.assetUUID,
    lastBalanceUpdateTxHash: txHashFromNudge,
    isSpam: erc20Token.isSpam,
  });
}

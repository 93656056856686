import { ethereumAddressDerivationPath } from 'cb-wallet-data/chains/AccountBased/Ethereum/config';
import {
  blockchainConfigurations,
  PossibleAccountBlockchainSymbol,
} from 'cb-wallet-data/chains/blockchains';
import { saveAddress } from 'cb-wallet-data/stores/Addresses/database';
import { Address } from 'cb-wallet-data/stores/Addresses/models/Address';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { saveWallets } from 'cb-wallet-data/stores/Wallets/database';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { WalletAddress } from 'cb-wallet-data/stores/Wallets/models/WalletAddress';
import { ImportableAddress } from 'cb-wallet-data/stores/Wallets/types/ImportableAddress';

import { solanaAddressDerivationPath } from '../Solana/config';

export const derivationPathByAccountBasedBlockchain: Readonly<
  Record<PossibleAccountBlockchainSymbol, (index: bigint) => string>
> = {
  ETH: ethereumAddressDerivationPath,
  SOL: solanaAddressDerivationPath,
};

/**
 * Creates wallet + address records for an account-based blockchain,
 * given a pre-derived address.
 *
 * Provided blockchain + account are used to determine which networks
 * are used.
 *
 * Examples of current uses:
 * - ledger onboarding: Uses all supported ETH networks.
 * - wallet link onboarding: Uses all supported ETH networks.
 * - smart contract wallet onboarding: Uses a subset of our supported ETH networks.
 */
export async function importAddress(
  rawValue: PossibleAccountBlockchainSymbol,
  addressToImport: ImportableAddress,
): Promise<Wallet[]> {
  const networkSettingItems = blockchainConfigurations[rawValue].networkSetting.allNetworks;

  return importAddressForNetworks(
    rawValue,
    addressToImport,
    addressToImport.networks ?? networkSettingItems.map(({ network }) => network),
  );
}

export async function importAddressForNetworks(
  rawValue: PossibleAccountBlockchainSymbol,
  addressToImport: ImportableAddress,
  networks: Network[],
) {
  const configuration = blockchainConfigurations[rawValue];

  const addressPromises = networks.map(async function createAndSaveAddressForNetwork(network) {
    // For Ledger, the derivation paths are provided.
    // For mnemonic + WalletLink, the derivation paths are automatically derived.
    const derivationPath =
      addressToImport.derivationPath ||
      derivationPathByAccountBasedBlockchain[rawValue](addressToImport.index);

    const address = new Address({
      index: addressToImport.index,
      address: addressToImport.address,
      balance: undefined,
      currencyCode: configuration.currencyCodeForNetwork(network),
      isChangeAddress: false,
      network,
      type: configuration.defaultReceiveType,
      derivationPath,
      isUsed: true,
      blockchain: configuration.blockchain,
      contractAddress: undefined,
      accountId: addressToImport.accountId,
    });

    return saveAddress(address).then(function createAndSaveWallet() {
      const walletAddress = new WalletAddress(
        configuration.defaultReceiveType,
        address.address,
        address.index,
      );

      return new Wallet({
        primaryAddress: address.address,
        addresses: [walletAddress],
        displayName: configuration.displayName(network),
        currencyCode: configuration.currencyCodeForNetwork(network),
        imageURL: configuration.imageURLForNetwork(network),
        balance: address.balance,
        decimals: configuration.decimals,
        blockchain: configuration.blockchain,
        minimumBalance: undefined,
        network,
        contractAddress: undefined,
        selectedIndex: address.index,
        accountId: addressToImport.accountId,
      });
    });
  });

  const wallets = await Promise.all(addressPromises);
  saveWallets(wallets);
  return wallets;
}

import { CoinbaseWalletAdapter } from '@solana/wallet-adapter-coinbase';
import { coinbaseWallet } from '@wagmi/connectors';
import CbWalletLogo from 'wallet-cds-web/assets/cb-wallet-logo-round.svg';

import { CB_WALLET_SCW_URL } from ':dapp/config/env';
import { CoinbaseWalletConnectorManager } from ':dapp/connection/CoinbaseWalletConnectorManager';
import { makeEthereumNetwork, makeSolanaNetwork } from ':dapp/connection/networks';
import { WalletProviderInfo } from ':dapp/connection/types';
import { setupConnector } from ':dapp/connection/wagmi';
import { WalletConnectorManager } from ':dapp/connection/WalletConnectorManager';

import { coinbaseSmartWallet } from '../coinbase-wallet/connectors/coinbaseSmartWallet';

const appName = 'Coinbase Wallet';

const solanaAdapter = new CoinbaseWalletAdapter();

/**
 * Never update this id. It is used to identify the wallet provider in the
 * database and filter out duplicate connectors from EIP6963. Removing or changing
 * without running a migration will break signing for existing panarama users.
 */
export const COINBASE_WALLET_ID = 'com.coinbase.wallet';

const eoaConnector = setupConnector(
  coinbaseWallet({
    appName,
    preference: 'eoaOnly',
  }),
);

const eoaNnetworks = [makeEthereumNetwork(eoaConnector), makeSolanaNetwork(solanaAdapter)];

export const COINBASE_WALLET: WalletProviderInfo = {
  id: COINBASE_WALLET_ID,
  name: appName,
  image: CbWalletLogo,
  enabled: true,
  networks: eoaNnetworks,
  connectorManager: new CoinbaseWalletConnectorManager(COINBASE_WALLET_ID, eoaNnetworks),
};

/**
 * Never update this id. It is used to identify the wallet provider in the
 * database and filter out duplicate connectors from EIP6963. Removing or changing
 * without running a migration will break signing for existing panarama users.
 */
export const COINBASE_SMART_WALLET_ID = 'com.coinbase.keys';

const smartWalletConnector = setupConnector(
  coinbaseSmartWallet({
    appName,
    preference: 'smartWalletOnly',
    keysUrl: `${CB_WALLET_SCW_URL}/connect`,
  }),
);

const smartWalletNetworks = [makeEthereumNetwork(smartWalletConnector)];

export const COINBASE_SMART_WALLET: WalletProviderInfo = {
  id: COINBASE_SMART_WALLET_ID,
  name: 'Smart wallet',
  image: CbWalletLogo,
  enabled: true,
  networks: smartWalletNetworks,
  connectorManager: new WalletConnectorManager(COINBASE_SMART_WALLET_ID, smartWalletNetworks),
};

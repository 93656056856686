/**
 * If you call JSON.stringify() on a Map, it will return an empty object.
 * This function converts a Map to a JSON string so we can use it in the cache keys
 */
export function stringifyMap(map: Map<string, unknown>): string {
  const obj: Record<string, unknown> = {};

  for (const [key, value] of map.entries()) {
    obj[key] = value;
  }

  return JSON.stringify(obj);
}

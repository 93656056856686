import { logConfirmTransactionGasPriceFailure } from 'cb-wallet-analytics/data/Transactions';
import {
  estimateEthereumGasUsage,
  getEthereumGasPrices,
} from 'cb-wallet-data/chains/AccountBased/Ethereum/apis/EthereumRPC';
import {
  AdjustableMinerFee1559Error,
  AdjustableMinerFee1559Result,
  get1559MinerFeeEstimates,
} from 'cb-wallet-data/chains/AccountBased/Ethereum/apis/MinerFeeAPI';
import { getOvmL1GasFee } from 'cb-wallet-data/chains/AccountBased/Ethereum/apis/Optimism';
import { getBalance } from 'cb-wallet-data/chains/AccountBased/Ethereum/Balance/getBalance';
import { getERC20Balance } from 'cb-wallet-data/chains/AccountBased/Ethereum/Balance/getERC20Balance';
import {
  EthereumBlockchain,
  EthereumWalletConfiguration,
} from 'cb-wallet-data/chains/AccountBased/Ethereum/config';
import { EthereumChain } from 'cb-wallet-data/chains/AccountBased/Ethereum/EthereumChain';
import { EthereumError } from 'cb-wallet-data/chains/AccountBased/Ethereum/exceptions/EthereumExceptions';
import { EthereumGas } from 'cb-wallet-data/chains/AccountBased/Ethereum/models/EthereumGas';
import { GasEstimationStatus } from 'cb-wallet-data/chains/AccountBased/Ethereum/models/GasEstimationStatus';
import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { TransferValue } from 'cb-wallet-data/models/TransferValue';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { getJSON } from 'cb-wallet-http/fetchJSON';
import {
  bigIntFromNumber,
  hexFromBuffer,
} from 'wallet-engine-signing/blockchains/Ethereum/formatNumbers';

type LegacyGasAndTxValueArguments = {
  transactionAmount: TransferValue;
  wallet: Wallet;
  recipientAddress: string;
  contractAddress?: string;
  selectedGasPrice?: bigint;
  selectedGasLimit?: bigint;
  data?: Buffer;
  txSource: string;
  nonce?: bigint;
};

type LegacyGasAndTxValueReturn = {
  gasLimit: bigint;
  gasPrice: bigint;
  weiValue: bigint;
  gasEstimationStatus: 'Success' | 'Failure';
  l1GasFee?: bigint;
};

export async function getLegacyGasAndTxValue({
  transactionAmount,
  wallet,
  data,
  recipientAddress,
  contractAddress,
  selectedGasPrice,
  selectedGasLimit,
  txSource,
  nonce,
}: LegacyGasAndTxValueArguments): Promise<LegacyGasAndTxValueReturn> {
  const ethereumChain = wallet.network.asChain() as EthereumChain;
  const isBaseAssetTransaction = CurrencyCode.isEqual(
    wallet.currencyCode,
    EthereumWalletConfiguration.currencyCodeForNetwork(wallet.network),
  );
  const hexData = data?.length ? hexFromBuffer(data) : '0x00';

  if (transactionAmount.kind === 'Amount') {
    const gasLimit = await getGasLimit(
      wallet.primaryAddress,
      recipientAddress,
      transactionAmount.value,
      data,
      ethereumChain,
      txSource,
      selectedGasLimit,
    );

    const gasPrice = selectedGasPrice ?? (await getGasPrice(ethereumChain, txSource));

    const l1GasFee = await getOvmL1GasFee({
      chain: ethereumChain,
      recipientAddress,
      nonce: nonce ?? 1n,
      gasPrice,
      gasLimit: gasLimit.gas.value,
      calldata: hexData,
      type: 1,
      txSource,
    });

    return {
      weiValue: transactionAmount.value,
      gasPrice,
      gasLimit: gasLimit.gas.value,
      gasEstimationStatus: gasLimit.kind,
      l1GasFee,
    };
  }

  if (transactionAmount.kind === 'EntireBalance') {
    const balance = isBaseAssetTransaction
      ? await getBalance(wallet.primaryAddress, wallet.network)
      : await getERC20Balance(wallet.primaryAddress, contractAddress!, wallet.network);

    if (balance === undefined) {
      throw EthereumError.unableToCalculateBalance;
    }

    const gasPrice = selectedGasPrice ?? (await getGasPrice(ethereumChain, txSource));

    const gasLimit = await getGasLimit(
      wallet.primaryAddress,
      recipientAddress,
      balance,
      data,
      ethereumChain,
      txSource,
      selectedGasLimit,
    );

    const l1GasFee = await getOvmL1GasFee({
      chain: ethereumChain,
      recipientAddress,
      nonce: nonce ?? 1n,
      gasPrice,
      gasLimit: gasLimit.gas.value,
      calldata: hexData,
      type: 1,
      txSource,
    });

    const gasCost = gasPrice * gasLimit.gas.value + (l1GasFee ?? 0n);

    const finalWeiValue = getEntireBalanceSendValue({
      gasCost,
      isBaseAssetTransaction,
      walletBalance: balance,
    });

    return {
      weiValue: BigInt(finalWeiValue),
      gasPrice,
      gasLimit: gasLimit.gas.value,
      gasEstimationStatus: gasLimit.kind,
      l1GasFee,
    };
  }

  const _exhaustive: never = transactionAmount;
  return _exhaustive;
}

type GasAndTxValueArguments = {
  transactionAmount: TransferValue;
  wallet: Wallet;
  recipientAddress: string;
  contractAddress?: string;
  selectedBaseFeePerGas?: bigint;
  selectedMaxFeePerGas?: bigint;
  selectedMaxPriorityFeePerGas?: bigint;
  selectedGasLimit?: bigint;
  data?: Buffer;
  txSource: string;
  nonce?: bigint;
  isSponsoredTx?: boolean;
};

type GasAndTxValueReturn = {
  weiValue: bigint;
  baseFeePerGas: bigint;
  maxFeePerGas: bigint;
  maxPriorityFeePerGas: bigint;
  gasLimit: bigint;
  gasEstimationStatus: 'Failure' | 'Success';
  l1GasFee?: bigint;
};

export async function get1559GasAndTxValue({
  transactionAmount,
  wallet,
  data,
  recipientAddress,
  selectedBaseFeePerGas,
  selectedMaxFeePerGas,
  selectedMaxPriorityFeePerGas,
  selectedGasLimit,
  txSource,
  nonce,
  isSponsoredTx,
}: GasAndTxValueArguments): Promise<GasAndTxValueReturn> {
  const ethereumChain = wallet.network.asChain() as EthereumChain;
  const isBaseAssetTransaction = CurrencyCode.isEqual(
    wallet.currencyCode,
    EthereumWalletConfiguration.currencyCodeForNetwork(wallet.network),
  );

  // numeric values need to be converted to BigInt
  const minerFeeEstimateResult = await get1559MinerFeeEstimates({
    chainId: ethereumChain.chainId,
  });

  if (minerFeeEstimateResult.error && !isSponsoredTx) {
    throw minerFeeEstimateResult.error;
  }

  const baseFee = minerFeeEstimateResult.baseFee || 1;
  const normalMaxFeePerGas = minerFeeEstimateResult.normalMaxFeePerGas || 1;
  const normalPriorityFee = minerFeeEstimateResult.normalPriorityFee || 1;

  const baseFeePerGas = selectedBaseFeePerGas ?? BigInt(baseFee);
  const maxFeePerGas = selectedMaxFeePerGas ?? BigInt(normalMaxFeePerGas);
  const maxPriorityFeePerGas = selectedMaxPriorityFeePerGas ?? BigInt(normalPriorityFee);

  const hexData = data?.length ? hexFromBuffer(data) : '0x00';

  if (transactionAmount.kind === 'Amount') {
    const gasLimit = await getGasLimit(
      wallet.primaryAddress,
      recipientAddress,
      transactionAmount.value,
      data,
      ethereumChain,
      txSource,
      selectedGasLimit,
    );

    // fetch OVM network L1 fee if applicable
    const l1GasFee = await getOvmL1GasFee({
      chain: ethereumChain,
      recipientAddress,
      nonce: nonce ?? 1n,
      gasPrice: maxFeePerGas,
      gasLimit: gasLimit.gas.value,
      calldata: hexData,
      type: 2,
      txSource,
    });

    return {
      weiValue: transactionAmount.value,
      baseFeePerGas,
      maxFeePerGas,
      maxPriorityFeePerGas,
      gasLimit: gasLimit.gas.value,
      gasEstimationStatus: gasLimit.kind,
      l1GasFee,
    };
  }

  if (transactionAmount.kind === 'EntireBalance') {
    try {
      const balance = isBaseAssetTransaction
        ? await getBalance(wallet.primaryAddress, wallet.network)
        : await getERC20Balance(wallet.primaryAddress, wallet.contractAddress!, wallet.network);

      if (balance === undefined) {
        throw EthereumError.unableToCalculateBalance;
      }

      const gasLimit = await getGasLimit(
        wallet.primaryAddress,
        recipientAddress,
        balance,
        data,
        ethereumChain,
        txSource,
        selectedGasLimit,
      );

      // fetch OVM network L1 fee if applicable
      const l1GasFee = await getOvmL1GasFee({
        chain: ethereumChain,
        recipientAddress,
        nonce: nonce ?? 1n,
        gasPrice: maxFeePerGas,
        gasLimit: gasLimit.gas.value,
        calldata: hexData,
        type: 2,
        txSource,
      });

      const gasCost = maxFeePerGas * gasLimit.gas.value + (l1GasFee ?? 0n);

      const finalWeiValue = getEntireBalanceSendValue({
        gasCost,
        isBaseAssetTransaction,
        walletBalance: balance,
      });

      return {
        weiValue: BigInt(finalWeiValue),
        baseFeePerGas,
        maxFeePerGas,
        maxPriorityFeePerGas,
        gasLimit: gasLimit.gas.value,
        gasEstimationStatus: gasLimit.kind,
        l1GasFee,
      };
    } catch (error: ErrorOrAny) {
      cbReportError({ error, context: 'ethereum', severity: 'error', isHandled: false });
      throw EthereumError.unableToEstimateGas;
    }
  }

  const _exhaustive: never = transactionAmount;
  return _exhaustive;
}

export async function getGasPrice(ethereumChain: EthereumChain, txSource: string): Promise<bigint> {
  const gasPrices = await getEthereumGasPrices(BigInt(ethereumChain.chainId));
  if (!gasPrices.length) {
    logConfirmTransactionGasPriceFailure({
      blockchain: EthereumBlockchain.rawValue,
      chainName: ethereumChain.displayName,
      chainId: ethereumChain.chainId.toString(10),
      errorType: 'UnableToFindGasPrice',
      errorMessage: 'Unable to find gas price',
      txSource,
    });

    throw EthereumError.unableToFindGasPrice;
  }

  const middleIndex = Math.floor((gasPrices.length - 1) / 2);
  return gasPrices[middleIndex];
}

export async function getGasLimit(
  fromAddress: string,
  toAddress: string | undefined,
  value: bigint,
  data: Buffer | undefined,
  ethereumChain: EthereumChain,
  txSource: string,
  selectedGasLimit?: bigint,
): Promise<GasEstimationStatus> {
  try {
    if (selectedGasLimit) {
      return { kind: 'Success', gas: new EthereumGas(selectedGasLimit) };
    }

    const valueEstimate = await estimateEthereumGasUsage(
      fromAddress,
      toAddress ?? null,
      value,
      data ?? Buffer.from(new Uint8Array(0)),
      ethereumChain.chainId,
    );

    const gasLimit = new EthereumGas(valueEstimate);

    const overEstimate = gasLimit.overEstimatedReduced;

    let result: EthereumGas;
    if (!ethereumChain.isOvmNetwork) {
      result = overEstimate;
    } else if (overEstimate.value < EthereumGas.maximumOptimismTxGasLimit.value) {
      result = overEstimate;
    } else {
      result = gasLimit;
    }

    return { kind: 'Success', gas: new EthereumGas(result.value) };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any | Error) {
    logConfirmTransactionGasPriceFailure({
      blockchain: EthereumBlockchain.rawValue,
      chainName: ethereumChain.displayName,
      chainId: ethereumChain.chainId.toString(10),
      errorType: 'UnableToGetGasLimit',
      errorMessage: e?.message,
      txSource,
    });

    return Promise.resolve({
      kind: 'Failure',
      gas: EthereumGas.defaultGasLimit,
    });
  }
}

export async function get1559GasPrice(
  chainId: number,
): Promise<AdjustableMinerFee1559Result | AdjustableMinerFee1559Error> {
  return get1559MinerFeeEstimates({ chainId });
}

type GasStationResponseData = {
  safeLow: number; // safelow
  average: number; // standard
  fast: number; // fast
  fastest: number; // fastest
};

type WrappedGasStationResponseData = {
  result: GasStationResponseData;
};

export async function fetchGasPrices(
  url = 'https://api.wallet.coinbase.com/rpc/v2/getMainnetGasPrices',
): Promise<bigint[]> {
  const { data } = await getJSON<{
    data: { result: GasStationResponseData | WrappedGasStationResponseData };
  }>(
    url,
    {},
    {
      isThirdParty: true,
    },
  );

  if (!data) {
    throw new Error('unexpected gas station response');
  }

  const gasPrices =
    isValidWrappedGasStationResponseData(data) || isValidGasStationResponseData(data) ? data : null;

  const { safeLow, average, fast, fastest } = gasPrices as GasStationResponseData;
  return [safeLow, average, fast, fastest].map((val) => bigIntFromNumber(val * 1e8));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isValidWrappedGasStationResponseData(data: any): data is WrappedGasStationResponseData {
  if (!data || typeof data !== 'object') {
    return false;
  }

  return isValidGasStationResponseData(data.result);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isValidGasStationResponseData(data: any): data is GasStationResponseData {
  if (!data || typeof data !== 'object') {
    return false;
  }

  return (
    typeof data.safeLow === 'number' &&
    typeof data.average === 'number' &&
    typeof data.fast === 'number' &&
    typeof data.fastest === 'number'
  );
}

// utility function to determing the transaction value for an entire balance send
function getEntireBalanceSendValue({
  gasCost,
  isBaseAssetTransaction,
  walletBalance,
}: {
  gasCost: bigint;
  walletBalance: bigint;
  isBaseAssetTransaction: boolean;
}) {
  const weiValue = isBaseAssetTransaction ? walletBalance - gasCost : walletBalance;

  // we do not want to return a negative weiValue. If the balance minus fees is negative we
  // return the balance which will allow us to show an insufficient funds error
  if (weiValue < 0n) {
    return walletBalance;
  }

  return weiValue;
}

import cryptoMiamiVice from 'wallet-cds-web/assets/crypto.svg';

import { D_240_X_120 as dimension } from './consts/dimensions';
import { MiamiViceIllustrationProps } from './types/MiamiViceIllustrationProps';

export function Crypto({ alt, scaleMultiplier = 1 }: MiamiViceIllustrationProps) {
  const width = scaleMultiplier * dimension.width;
  const height = scaleMultiplier * dimension.height;

  return (
    <img
      src={cryptoMiamiVice}
      alt={alt}
      data-testid="crypto-miami-vice"
      width={width}
      height={height}
    />
  );
}

// useGetTxHistoryVersion handled the responsibility of chain
import { useCallback } from 'react';
import { EthereumChain } from 'cb-wallet-data/chains/AccountBased/Ethereum/EthereumChain';
import { SolanaChain } from 'cb-wallet-data/chains/AccountBased/Solana/models/SolanaChain';
import { TxHistoryVersion } from 'cb-wallet-data/stores/Transactions/TxHistoryVersion';

import { useGetEthTxHistoryVersion } from './useGetEthTxHistoryVersion';
import { useGetSolTxHistoryVersion } from './useGetSolTxHistoryVersion';

export type GetTxHistoryVersion = (chain?: EthereumChain | SolanaChain) => {
  apiVersion: TxHistoryVersion | undefined;
  isEnabled: boolean;
};

/**
 * Returns the active tx history version for the given chain based on the killswitches and experiements
 * that are active. The hook returns a function which accepts a chain, this was done since we need to
 * be able to determine the active tx history version for a chain inside a loop
 */
export function useGetTxHistoryVersion(): GetTxHistoryVersion {
  const getEthTxHistoryVersion = useGetEthTxHistoryVersion();
  const getSolTxHistoryVersion = useGetSolTxHistoryVersion();

  return useCallback(
    (chain?: EthereumChain | SolanaChain) => {
      return chain?.blockchainSymbol === 'ETH'
        ? getEthTxHistoryVersion(chain)
        : getSolTxHistoryVersion(chain);
    },
    [getEthTxHistoryVersion, getSolTxHistoryVersion],
  );
}

import { useCalculateAggregateBalanceFromAssetInfoList } from 'cb-wallet-data/stores/DefiPortfolio/hooks/useCalculateAggregateBalanceFromAssetInfoList';
import {
  DefiPosition,
  DefiPositionRow,
  DefiPositionType,
  LiquidityPoolPosition,
} from 'cb-wallet-data/stores/DefiPortfolio/models/DefiPosition';
import Decimal from 'decimal.js';

type Props = {
  positions: DefiPositionRow[];
};

export function useProtocolPositionsAggregateBalance({ positions }: Props) {
  const calculateAggregateBalanceFromAssetInfoList =
    useCalculateAggregateBalanceFromAssetInfoList();

  return positions.reduce(function getAggregateBalance(acc, position) {
    if (position.type === DefiPositionType.LiquidityPool) {
      const liquidityTotal = calculateAggregateBalanceFromAssetInfoList(
        (position as LiquidityPoolPosition).assetInfos,
      );

      return acc.add(liquidityTotal ?? new Decimal(0));
    }

    const defiPositionTotal = calculateAggregateBalanceFromAssetInfoList([
      (position as DefiPosition).assetInfo,
    ]);

    return acc.add(defiPositionTotal ?? new Decimal(0));
  }, new Decimal(0));
}

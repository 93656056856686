import { usePreviousValue } from './usePreviousValue';

/**
 * Returns whether the value has changed since the previous render,
 * using a shallow-equals check
 */
export function useHasChanged(value: unknown): boolean {
  const previous = usePreviousValue(value);

  return previous !== value;
}

/* Type compliance is not expected for this file */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DeepPartial,
  InsertResult,
  ObjectID,
  ObjectLiteral,
  QueryRunner,
  SelectQueryBuilder,
} from '@cbhq/typeorm';
import { QueryDeepPartialEntity } from '@cbhq/typeorm/query-builder/QueryPartialEntity';
import { UpsertOptions } from '@cbhq/typeorm/repository/UpsertOptions';

/**
 * Repository-like class. Loosely-based on typeorm Repository<Entity>
 *
 * Used to provide noop methods when database tables are being re-created/migrated
 * and async operations that require a repository object cannot be stopped.
 * */
export class NoopRepository<Entity extends ObjectLiteral> {
  createQueryBuilder(
    alias?: string | undefined,
    queryRunner?: QueryRunner | undefined,
  ): SelectQueryBuilder<Entity> {
    const queryBuilder = {} as any as SelectQueryBuilder<Entity>;

    queryBuilder.where = (predicate: string, opts?: Record<string, any>) => {
      return queryBuilder;
    };
    queryBuilder.andWhere = queryBuilder.where;
    queryBuilder.getMany = async () => Promise.resolve([]);
    queryBuilder.getOne = async () => Promise.resolve(null);

    return queryBuilder;
  }

  get target(): string {
    return '';
  }

  hasId(entity: Entity): boolean {
    return false;
  }

  getId(entity: Entity) {
    return null;
  }

  create(): Entity {
    return undefined as any;
  }

  merge(mergeIntoEntity: Entity, ...entityLikes: DeepPartial<Entity>[]): Entity {
    return undefined as any;
  }

  async preload(entityLike: DeepPartial<Entity>): Promise<Entity | undefined> {
    return Promise.resolve(undefined);
  }

  async save(entity: unknown, options?: unknown): Promise<[]> {
    return Promise.resolve([]);
  }

  async remove(entity: unknown, options?: unknown) {
    return Promise.resolve(undefined);
  }

  async softRemove(entity: unknown, options?: unknown) {
    return Promise.resolve([]);
  }

  async recover(entity: unknown, options?: unknown) {
    return Promise.resolve([]);
  }

  async insert(entity: QueryDeepPartialEntity<Entity> | QueryDeepPartialEntity<Entity>[]) {
    return Promise.resolve(undefined);
  }

  async update(
    criteria: string | number | string[] | number[] | Date | Date[] | ObjectID | ObjectID[],
    partialEntity: QueryDeepPartialEntity<Entity>,
  ) {
    return Promise.resolve(undefined);
  }

  async upsert(
    entityOrEntities: QueryDeepPartialEntity<Entity> | QueryDeepPartialEntity<Entity>[],
    conflictPathsOrOptions: string[] | UpsertOptions<Entity>,
  ): Promise<InsertResult> {
    throw new Error('Method not implemented.');
  }

  async delete(_: any) {
    throw new Error('Method not implemented.');
  }

  async softDelete(
    criteria: string | number | string[] | number[] | Date | Date[] | ObjectID | ObjectID[],
  ) {
    return Promise.resolve(undefined);
  }

  async restore(
    criteria: string | number | string[] | number[] | Date | Date[] | ObjectID | ObjectID[],
  ) {
    return Promise.resolve(undefined);
  }

  async count(conditions?: unknown): Promise<number> {
    return Promise.resolve(0);
  }

  async find(conditions?: unknown): Promise<Entity[]> {
    return Promise.resolve([]);
  }

  async findAndCount(conditions?: unknown): Promise<[Entity[], number]> {
    return Promise.resolve([[], 0]);
  }

  async findByIds(ids: unknown, conditions?: unknown): Promise<Entity[]> {
    return Promise.resolve([]);
  }

  async findOne(conditions?: unknown, options?: unknown): Promise<Entity | undefined> {
    return Promise.resolve(undefined);
  }

  async findOneOrFail(conditions?: unknown, options?: unknown): Promise<Entity> {
    throw new Error('Method not implemented.');
  }

  async query(query: string, parameters?: any[] | undefined): Promise<any> {
    return Promise.resolve(undefined);
  }

  async clear(): Promise<void> {
    return Promise.resolve();
  }

  async increment(propertyPath: string, value: string | number) {
    return Promise.resolve(undefined);
  }

  async decrement(propertyPath: string, value: string | number) {
    return Promise.resolve(undefined);
  }

  batchUpsert(
    entities: QueryDeepPartialEntity<Entity>[],
    conflictPaths: string[],
    batchSize?: number | undefined,
  ): Promise<any>[] {
    return entities.map(async () => Promise.resolve(undefined));
  }

  isNull() {
    return true;
  }
}

import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { walletGroupsAtom } from '../state';

type UseWalletGroupsProps =
  | {
      visible: true;
      hidden?: false;
    }
  | {
      visible?: false;
      hidden: true;
    }
  | undefined;

export function useWalletGroups(options?: UseWalletGroupsProps) {
  const walletGroups = useRecoilValue(walletGroupsAtom);

  return useMemo(() => {
    if (options?.hidden) {
      return walletGroups.filter((walletGroup) => walletGroup.isHidden);
    }
    if (options?.visible) {
      return walletGroups.filter((walletGroup) => !walletGroup.isHidden);
    }
    return walletGroups;
  }, [options?.hidden, options?.visible, walletGroups]);
}

export function useWalletGroupIds() {
  const walletGroups = useWalletGroups();
  return useMemo(() => {
    return walletGroups.map((walletGroup) => walletGroup.id);
  }, [walletGroups]);
}

import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { Store } from 'cb-wallet-store/Store';

import { AccessTokenResult } from '../types/AccessTokenResult';

export const StoreKeys_accessToken = new LocalStorageStoreKey<string>('accessToken');

export const StoreKeys_refreshToken = new LocalStorageStoreKey<string>('refreshToken');

export function getAccessTokenFromStorage(): string | undefined {
  return Store.get<string>(StoreKeys_accessToken);
}

export function getRefreshTokenFromStorage(): string | undefined {
  return Store.get<string>(StoreKeys_refreshToken);
}

export function setAuthTokensImperative(tokens: AccessTokenResult) {
  Store.set(StoreKeys_accessToken, tokens.token);
  Store.set(StoreKeys_refreshToken, tokens.refreshToken);
}

import { ETHEREUM_PREFIX } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import {
  EthereumChain,
  EthereumNetworkMap,
} from 'cb-wallet-data/chains/AccountBased/Ethereum/EthereumChain';
import {
  SolanaChain,
  SolanaNetworkMap,
} from 'cb-wallet-data/chains/AccountBased/Solana/models/SolanaChain';
import { SOLANA_PREFIX } from 'cb-wallet-data/chains/AccountBased/Solana/utils/chain';
import { cbReportError } from 'cb-wallet-data/errors/reportError';
import memoize from 'lodash/memoize';

import { SupportedChain } from './SupportedChain';

export const TESTNET_PREFIX = 'TESTNET';

/**
 * We memoize this as it is called quite often for wallets with lots of transactions,
 * but often with only a small number of rawValues.
 */
const createNetworkMemoized = memoize(function createNetwork(rawValue: string) {
  const pieces = rawValue.split('/');

  if (pieces.length !== 2) {
    return undefined;
  }

  const prefix = pieces[0];
  const testnet = JSON.parse(pieces[1]);

  if (typeof testnet === 'boolean') {
    return new Network(prefix, testnet);
  }

  return undefined;
});

export class Network {
  readonly rawValue: string;

  constructor(uuid: string, readonly isTestnet = false) {
    this.rawValue = [uuid, String(isTestnet)].join('/');
  }

  static createTestnetNetwork(isTestnet: boolean): Network {
    return new Network(TESTNET_PREFIX, isTestnet);
  }

  static create(rawValue: string): Network | undefined {
    return createNetworkMemoized(rawValue);
  }

  static isEqual(n1: Network, n2: Network): boolean {
    return n1.rawValue === n2.rawValue;
  }

  static fromChainId({
    chainPrefix = ETHEREUM_PREFIX,
    chainId,
  }: {
    chainPrefix?: string;
    chainId: bigint;
  }): Network {
    let prefix = chainPrefix;
    let chain: EthereumChain | SolanaChain | undefined = EthereumNetworkMap.fromChainId(chainId);
    if (chainId === BigInt(101) && !chain) {
      prefix = SOLANA_PREFIX;
    }
    if (prefix === SOLANA_PREFIX) chain = SolanaNetworkMap.fromChainId(chainId);
    return new Network(`${prefix}:${chainId}`, chain?.isTestnet ?? false);
  }

  static fromSupportedChain(supportedChain: SupportedChain): Network {
    return new Network(`${supportedChain.prefix}:${supportedChain.chainId}`, false);
  }

  asChain(): EthereumChain | SolanaChain | undefined {
    const { chainPrefix, chainId } = chainComponentsFromNetworkRawValue(this.rawValue);

    if (!chainPrefix || !chainId) {
      return undefined;
    }

    switch (chainPrefix) {
      case ETHEREUM_PREFIX:
        return EthereumNetworkMap.fromChainId(chainId);
      case SOLANA_PREFIX:
        return SolanaNetworkMap.fromChainId(chainId);
      default:
        return undefined;
    }
  }
}

type ChainComponentsType = {
  chainId: bigint | undefined;
  chainPrefix: string | undefined;
};

const undefinedChainComponents = {
  chainId: undefined,
  chainPrefix: undefined,
};

/**
 * We memoize this to make asChain() and other network related functions faster
 * since it is a simple computation from the rawValue
 */
export const chainComponentsFromNetworkRawValue = memoize(
  function chainComponentsFromNetworkRawValue(rawValue: string): ChainComponentsType {
    if (!rawValue) return undefinedChainComponents;

    const pieces = rawValue.split('/');

    if (pieces.length !== 2) {
      return undefinedChainComponents;
    }

    const prefix = pieces[0];
    const prefixPieces = prefix?.split(':') ?? [];

    if (prefixPieces.length !== 2) {
      return undefinedChainComponents;
    }

    const chainPrefixStr = prefixPieces[0];
    const chainIdStr = prefixPieces[1];
    let chainId;

    try {
      chainId = chainIdStr === 'undefined' ? undefined : BigInt(chainIdStr);
    } catch (err) {
      cbReportError({
        error: err as Error,
        context: 'networks',
        metadata: {
          networkRawValue: rawValue,
        },
        isHandled: true,
        severity: 'error',
      });
      throw err;
    }

    return { chainPrefix: chainPrefixStr, chainId };
  },
);

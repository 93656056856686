/**
 * Represents sponsored transaction state
 */
export enum SponsoredTxState {
  /** Submitted to the payout service. Not yet submitted to the blockchain */
  SAVED = 0,

  /** Submitted to the blockchain. Not yet mined */
  SUBMITTED = 1,

  /**
   * In a transaction, mined in a block. Could still be reorged or reverted,
   * so make sure to check the block height of the transaction is sufficiently final
   */
  IN_BLOCK = 2,

  /** un-retryable failure */
  FAILED = 3,
}

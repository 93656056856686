import { defineMessages, useIntl } from 'react-intl';
import { useLogOnrampEntryViewed } from 'cb-wallet-analytics/buy';
import { AssetSelectorGroup } from 'wallet-cds-web/components/AssetSelectorGroup';
import { StepperNavbar } from 'wallet-cds-web/components/StepperNavbar';
import { Box, Divider, VStack } from '@cbhq/cds-web/layout';

import { useBuyAssetSelectorGroupValues } from ':dapp/hooks/Buy/useBuyAssetSelectorGroupValues';

import { BuyAmountInput } from '../BuyAmountInput/BuyAmountInput';
import { BuyButton } from '../BuyButton/BuyButton';

const messages = defineMessages({
  arrowDownAccessibilityLabel: {
    defaultMessage: 'To',
    description: 'Onramp flow arrow down accessibility label',
  },
  title: {
    description: 'Buy drawer title',
    defaultMessage: 'Buy',
  },
});

type BuyEntryProps = {
  onClose?: () => void;
};

export function BuyEntry({ onClose }: BuyEntryProps) {
  useLogOnrampEntryViewed({});
  const { formatMessage } = useIntl();

  const assetSelectorGroupProps = useBuyAssetSelectorGroupValues();

  return (
    <VStack width="100%" justifyContent="flex-start">
      <VStack height="100%" width="100%" gap={2} spacing={3}>
        <StepperNavbar
          title={formatMessage(messages.title)}
          onRightIconPress={onClose}
          rightIcon="close"
        />
        <BuyAmountInput />
        <VStack alignContent="center" justifyContent="center">
          <AssetSelectorGroup {...assetSelectorGroupProps} />
        </VStack>
        <Box spacingBottom={1}>
          <BuyButton />
        </Box>
      </VStack>
      <Divider width="100%" />
    </VStack>
  );
}

import { getSigningOrDerivationMethodForAccount } from 'cb-wallet-data/ServiceLocator/signingAndDerivation/utils/getSigningOrDerivationMethodForAccount';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { hexFromBuffer } from 'wallet-engine-signing/signing/ethereum/utils';

import { SIGNATURE_HOST } from '../constants';
import { Signature } from '../types/Signature';

type GenerateWeb3SignatureParamsWithAccountId = {
  header: string;
  nonce: string;
  accountId: Account['id'];
  address: string;
};

/**
 * Generates a web3 signature object, for the purpose of
 * posting as JSON.
 */
export async function generateWeb3Signature({
  accountId,
  address,
  header,
  nonce,
}: GenerateWeb3SignatureParamsWithAccountId): Promise<Signature> {
  const message = Buffer.from(
    `${header}\n\nAddress: ${address}\nNonce: ${nonce}\nHost: ${SIGNATURE_HOST}`,
  );
  const signEthereumMessage = getSigningOrDerivationMethodForAccount(
    accountId,
    'signEthereumMessage',
  );
  const signature = await signEthereumMessage({
    message,
    accountId,
  });

  return {
    address,
    signature: hexFromBuffer(signature),
  };
}

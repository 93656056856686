import { SignoutMetadata } from 'cb-wallet-analytics/sign-out';
import { atomLocalStorageEffect } from 'cb-wallet-data/utils/atomLocalStorageEffect';
import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { Store } from 'cb-wallet-store/Store';
import { atom } from 'recoil';

import { assertSignoutMetadata } from './utils/assertSignoutMetadata';

// These global getters and setters allow no-react-lifecycle functions
// to tap into the auth state of whether the application is signing out.
//
// These must be set together.
// - setIsSigningOut (Recoil)
// - setGlobalSignoutMetadata (global)

// isGlobalSigningOut -- this is set via the Recoil atom effect
export const StoreKeys_isSigningOut = new LocalStorageStoreKey<boolean>('signout_isSigningOut');
export function isGlobalSigningOut() {
  const isSigningOut = Store.get<boolean>(StoreKeys_isSigningOut);
  return isSigningOut;
}

export const isSigningOutAtom = atom<boolean>({
  key: 'authStateIsSigningOut',
  default: false,
  effects: [atomLocalStorageEffect(StoreKeys_isSigningOut)],
});

// isGlobalSignoutProcessing -- does not need to be persisted to localstorage
// as the app will continue signing out when it opens based on the
// `StoreKeys_isSigningOut` value
let _isGlobalSignoutProcessing = false;
export function isGlobalSignoutProcessing() {
  return _isGlobalSignoutProcessing;
}
export function setGlobalSignoutProcessing(newValue: boolean) {
  _isGlobalSignoutProcessing = newValue;
}

// globalSignoutMetadata
export const StoreKeys_signoutMetadata = new LocalStorageStoreKey<string>(
  'signout_signoutMetadata',
);
export function globalSignoutMetadata(): SignoutMetadata {
  const maybeSignoutMetadata = Store.get(StoreKeys_signoutMetadata);
  try {
    assertSignoutMetadata(maybeSignoutMetadata);
    return maybeSignoutMetadata;
  } catch (e) {
    // swallow error since it is most likely not set
    const defaultMetadata: SignoutMetadata = { context: 'unknown', cid: '' };
    return defaultMetadata;
  }
}
export function setGlobalSignoutMetadata(signoutMetadata: SignoutMetadata) {
  Store.set(StoreKeys_signoutMetadata, signoutMetadata);
}

export const oauthAccessTokenStateAtom = atom<{ accessToken: string | null; isLoading: boolean }>({
  key: 'oauthAccessTokenStateAtom',
  default: { accessToken: null, isLoading: true },
});

import { LocalStoragePersistor } from './LocalStoragePersistor';
import { getOverriddenPersistor } from './overridePersistor';
import { Persistor } from './persistQueryClient';

/**
 * This will check for an overridden persistor and return it if it exists, otherwise it will return a new instance
 * of `LocalStoragePersistor`.
 */
export function createPersistor(): Persistor {
  const overriddenPersistor = getOverriddenPersistor();

  return overriddenPersistor ?? new LocalStoragePersistor();
}

import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';

export function StoreKeys_WalletBalance(walletId: string): LocalStorageStoreKey<bigint> {
  return new LocalStorageStoreKey<bigint>(walletId);
}

export const StoreKeys_ZeroNFTsEventLogged = new LocalStorageStoreKey<boolean>(
  'zeroNFTsEventLogged',
);

export function StoreKeys_cryptosLoadingState(walletGroupId = '') {
  return new LocalStorageStoreKey<'loaded' | 'cached'>(`cryptosLoadingState/${walletGroupId}`);
}

export const StoreKeys_haveBalancesLoadedState = new LocalStorageStoreKey<'loaded' | 'cached'>(
  'haveBalancesLoadedState',
);

export const StoreKeys_collectionsCountCacheState = new LocalStorageStoreKey<'start' | 'cached'>(
  'collectionsCountCacheState',
);

export function StoreKeys_CollectionTokenCount(
  collectionId: string,
): LocalStorageStoreKey<boolean> {
  return new LocalStorageStoreKey<boolean>(collectionId);
}

import { defineMessages, useIntl } from 'react-intl';
import { Button } from '@cbhq/cds-web/buttons';
import { HeroSquare } from '@cbhq/cds-web/illustrations';
import { VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextTitle2 } from '@cbhq/cds-web/typography';

const messages = defineMessages({
  somethingWentWrong: {
    description: 'Error page copy',
    defaultMessage: 'Something went wrong',
  },
  errorBody: {
    description: 'Error page body',
    defaultMessage: 'Oops, we encountered a problem. Please try again later.',
  },
  tryAgain: {
    description: 'Try again page copy',
    defaultMessage: 'Try again',
  },
});

type GenericErrorPageProps = {
  onReset?: () => void;
  errorInfo?: any;
};

// Errors for smaller components (Tabs, sidebars, etc)
export function GenericErrorPage({ onReset, errorInfo }: GenericErrorPageProps) {
  const { formatMessage } = useIntl();

  return (
    <VStack testID="genericErrorPage" height="100%" justifyContent="center" alignItems="center">
      <HeroSquare name="bigWarning" />
      <TextTitle2 as="h3">{formatMessage(messages.somethingWentWrong)}</TextTitle2>
      <TextBody
        as="p"
        color="foregroundMuted"
        spacingVertical={1}
        spacingHorizontal={3}
        align="center"
      >
        {formatMessage(messages.errorBody)}
      </TextBody>
      {onReset && (
        <Button compact onPress={onReset} testID="reset-button">
          {formatMessage(messages.tryAgain)}
        </Button>
      )}
      {errorInfo}
    </VStack>
  );
}

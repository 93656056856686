import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { observabilityClient } from 'cb-wallet-data/stores/Observability/classes/ObservabilityClient';
import { getUserIdFromStorage, setUserIdInStorage } from 'cb-wallet-http/User/state';
import nextJsRouter from 'next/router';
import {
  AnalyticsSetIdentity,
  identify as ccaIdentify,
  init,
  initNextJsTrackPageview,
  PlatformName,
  UserTypeWallet,
} from '@cbhq/client-analytics';

import {
  AMPLITUDE_KEY,
  APP_BUILD_ID,
  IS_PROD,
  RELEASE_ENVIRONMENT,
  ReleaseEnvironment,
} from ':dapp/config/env';
import { steps } from ':dapp/utils/analytics/userJourneySteps';
import { getDeviceId } from ':dapp/utils/deviceId';

import { pageKeyRegex } from './page-key';

/**
 * Gets the browser locale, defaulting to the specified default
 * if the browser locale could not be determined.
 *
 * @param defaultLocale The default locale to use.
 */
function getNavigatorLocale(defaultLocale = 'en'): string {
  if (navigator.languages && navigator.languages.length > 0) {
    return navigator.languages[0] || defaultLocale;
  }
  // @ts-expect-error userLanguage is for IE
  return navigator.language || navigator.userLanguage || defaultLocale;
}

/**
 * identify
 *
 * This function initializes client analytics by setting
 * the device id, user id and page tracking.
 */
export function identify() {
  const userId = getUserIdFromStorage();

  ccaIdentify({
    locale: getNavigatorLocale(),
    deviceId: getDeviceId(),
    userTypeEnum: UserTypeWallet,
    userId,
  });
}

type IdentifyUserParams = {
  userId?: AnalyticsSetIdentity['userId'];
};

/**
 * setAnalyticsUserId
 *
 * This function initializes client analytics by setting
 * the device id, user id and page tracking.
 * @param userId The user id to use.
 */
export function setAnalyticsUserId({ userId }: IdentifyUserParams) {
  if (userId) {
    setUserIdInStorage(userId);
  }
  ccaIdentify({
    locale: getNavigatorLocale(),
    userTypeEnum: UserTypeWallet,
    userId,
  });
}
/**
 * initObservabilityClient
 *
 * This function initializes the observability client by setting
 * the setAnalyticsUserId function. It ensures that these functions are set during
 * app initialization, and not at the time of the first error or analytics event.
 */
function initObservabilityClient() {
  observabilityClient.setAnalyticsUserIdFn = setAnalyticsUserId;
}

/**
 * initializeAnalytics
 *
 * This function initializes client analytics by setting
 * the device id, user id and page tracking.
 */
function initializeAnalytics() {
  init({
    amplitudeApiKey: AMPLITUDE_KEY,
    isProd: IS_PROD,
    platform: typeof window === 'undefined' ? PlatformName.server : PlatformName.web,
    // Setting any header on dev causes the client analytics library to use xhr instead of sendBeacon
    // This is desireable since sendBeacon fails on cross-origin requests on web when run in a browser that has uBlock extension running
    // or on Brave.  This isn't needed on prod since the origin will be *.coinbase.com.
    // So basically this header is here as a hack - it is stripped from the request prior to sending to amplitude.
    headers:
      RELEASE_ENVIRONMENT !== ReleaseEnvironment.production &&
      RELEASE_ENVIRONMENT !== ReleaseEnvironment.preprod
        ? {
            Origin: '',
          }
        : {},
    projectName: 'wallet_dapp',
    showDebugLogging: false,
    version: IS_PROD ? APP_BUILD_ID : 'local',
    steps,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: Error, metadata: Record<string, any> | undefined) => {
      if (!IS_PROD) return;
      cbReportError({
        error,
        metadata,
        context: 'analytics',
        severity: 'error',
        isHandled: false,
      });
    },
  });

  if (typeof window === 'undefined') return;

  // init identity
  identify();

  // init page view tracking
  initNextJsTrackPageview({
    pageKeyRegex,
    nextJsRouter,
  });

  // init the observability client
  initObservabilityClient();
}

initializeAnalytics();

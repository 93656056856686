import {
  blockchainConfigurations,
  PossibleUTXOBlockchainSymbol,
} from 'cb-wallet-data/chains/blockchains';
import {
  StoreKeys_xpubKey,
  xpubKeyDerivationPathForBlockchain,
} from 'cb-wallet-data/chains/UTXO/common/config';
import { createAddresses } from 'cb-wallet-data/chains/UTXO/common/createAddresses';
import { UTXOConfiguration } from 'cb-wallet-data/chains/UTXO/models/UTXOConfiguration';
import { getHDKey } from 'cb-wallet-data/cipherCore';
import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { getSigningOrDerivationMethodForAccount } from 'cb-wallet-data/ServiceLocator/signingAndDerivation/utils/getSigningOrDerivationMethodForAccount';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { XPubKeyResponse } from 'cb-wallet-data/stores/Accounts/models/WalletConnectionResponse';
import { AddressType } from 'cb-wallet-data/stores/Addresses/models/AddressType';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { Store } from 'cb-wallet-store/Store';

type CreateWalletsOptions = {
  rawValue: PossibleUTXOBlockchainSymbol;
  createAtIndexes?: bigint[];
  accountId: Account['id'];
  xpubKeys?: XPubKeyResponse[];
};

export async function createWallets({
  rawValue,
  accountId,
  xpubKeys,
}: CreateWalletsOptions): Promise<void> {
  const xpubKeyDerivationPath = xpubKeyDerivationPathForBlockchain[rawValue];

  const createUTXOAddresses = async (
    addressType: AddressType,
    network: Network,
    xpubKey?: string,
  ): Promise<void> => {
    const derivationPath = xpubKeyDerivationPath(addressType, network.isTestnet);
    if (!derivationPath) {
      // We don't generate testnet wallets for BRD wallets, so just return
      return;
    }

    // since we already have the xpubkey from the provider we can skip deriving it from the accountId
    if (xpubKey) {
      return _createWallets(rawValue, xpubKey, accountId, addressType, network);
    }

    let xpubKeyResult;

    try {
      const getXpubKey = getSigningOrDerivationMethodForAccount(accountId, 'xpubKey');
      xpubKeyResult = await getXpubKey({ accountId, derivationPath });
    } catch (err: unknown) {
      cbReportError({
        error: new Error(
          `Failed to create ${addressType.rawValue} xpubkey: ${(err as Error).message}`,
        ),
        context: 'utxo_balance_scanning',
        severity: 'error',
        isHandled: true,
      });

      throw err;
    }
    return _createWallets(rawValue, xpubKeyResult, accountId, addressType, network);
  };

  const configuration = blockchainConfigurations[rawValue] as UTXOConfiguration;

  const createWalletPromises = configuration.networkSetting.allNetworks.map(
    async function createWalletsCb(networkSetting) {
      const network = networkSetting.network;

      if (xpubKeys) {
        return Promise.all(
          xpubKeys.map(async (xpubKey) =>
            createUTXOAddresses(xpubKey.xPubType, network, xpubKey.xPubKey),
          ),
        );
      }

      return Promise.all(
        configuration.supportedAddressTypes.map(async (addressType) =>
          createUTXOAddresses(addressType, network),
        ),
      );
    },
  );

  await Promise.all(createWalletPromises);
}

export type XpubKeyData = {
  chainSymbol: PossibleUTXOBlockchainSymbol;
  publicKey: Buffer;
  accountId: Account['id'];
  addressType: AddressType;
  network: Network;
};

export async function createWalletsFromXpubKeys(xpubKeyData: XpubKeyData[]) {
  const HDKey = await getHDKey();

  return Promise.all(
    xpubKeyData.map(async ({ chainSymbol, publicKey, accountId, addressType, network }) => {
      return _createWallets(
        chainSymbol,
        HDKey.parseExtendedKey(publicKey.toString('ascii')).extendedPublicKey,
        accountId,
        addressType,
        network,
      );
    }),
  );
}

async function _createWallets(
  rawValue: PossibleUTXOBlockchainSymbol,
  xpubKey: string,
  accountId: Account['id'],
  addressType: AddressType,
  network: Network,
): Promise<void> {
  const configuration = blockchainConfigurations[rawValue] as UTXOConfiguration;

  const xpubKeyStoreKey = StoreKeys_xpubKey({
    blockchain: configuration.blockchain,
    currencyCode: configuration.currencyCode,
    addressType,
    accountId,
    walletIndex: 0n,
    isTestnet: network.isTestnet,
  });

  Store.set(xpubKeyStoreKey, xpubKey);

  // Create one change and one receive address at index 0 per address type
  await Promise.all([
    createAddresses({
      rawValue,
      blockchain: configuration.blockchain,
      currencyCode: configuration.currencyCode,
      network,
      addressType,
      isChangeAddress: true,
      addressIndexes: [0n],
      xpubKey,
      accountId,
      lastSyncedBlockheight: 0,
    }),
    createAddresses({
      rawValue,
      blockchain: configuration.blockchain,
      currencyCode: configuration.currencyCode,
      network,
      addressType,
      isChangeAddress: false,
      addressIndexes: [0n],
      xpubKey,
      accountId,
      lastSyncedBlockheight: 0,
    }),
  ]);
}

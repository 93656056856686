import { useMemo } from 'react';
import { ETHEREUM_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { usePrimaryReceiveWallets } from 'cb-wallet-data/hooks/Receive/usePrimaryReceiveWallets';
import { useExchangeRatesMap } from 'cb-wallet-data/stores/ExchangeRates/hooks/useExchangeRates';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import Decimal from 'decimal.js';

import { useIsOverallEarnBalanceEnabled } from '../hooks/useIsOverallEarnBalanceEnabled';

import { useKilnEarnBalance } from './useKilnEarnBalance';

export type Params = {
  enabled?: boolean;
};

export function useKilnEarnBalanceTotalAmount({ enabled = false }: Params = {}): Decimal {
  const exchangeRatesMap = useExchangeRatesMap();
  const primaryReceiveWallets = usePrimaryReceiveWallets();

  const wallet = useMemo(() => {
    return primaryReceiveWallets.get(ETHEREUM_SYMBOL);
  }, [primaryReceiveWallets]);

  const isOverallEarnBalanceEnabled = useIsOverallEarnBalanceEnabled();
  const { balance: kilnEarnBalance } = useKilnEarnBalance({
    enabled,
    address: wallet?.primaryAddress ?? '',
    currencyCode: wallet?.currencyCode.code ?? '',
    chainId: wallet?.network.asChain()?.chainId ?? 1,
  });

  return useMemo(() => {
    if (!isOverallEarnBalanceEnabled || !wallet || !enabled) return new Decimal(0);
    const kilnAmountBalance = BigInt(kilnEarnBalance?.amount ?? '0');
    const claimableBalance =
      kilnEarnBalance?.claims.reduce((acc, claim) => {
        return acc + BigInt(claim.amount);
      }, 0n) ?? 0n;

    const earnBalanceTotalAmount = kilnAmountBalance + claimableBalance;

    const tmpWalletWithStakedBalance = Wallet.fromDMO({
      ...wallet.asDMO,
      balanceStr: earnBalanceTotalAmount.toString(),
    });

    return tmpWalletWithStakedBalance.fiatBalance({ exchangeRatesMap });
  }, [enabled, exchangeRatesMap, isOverallEarnBalanceEnabled, kilnEarnBalance, wallet]);
}

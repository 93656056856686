import { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { triggerResetRequired } from 'cb-wallet-analytics/wallet-connection';
import { useAccounts } from 'cb-wallet-data/stores/Accounts/hooks/useAccounts';
import Image from 'next/image';
import { IconButton } from '@cbhq/cds-web/buttons/IconButton';
import { ListCell } from '@cbhq/cds-web/cells/ListCell';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextHeadline } from '@cbhq/cds-web/typography';

import {
  WalletProviderInfo,
  WalletProviderNetwork,
  WalletProviderNetworkInfo,
} from ':dapp/connection/types';
import {
  COINBASE_SMART_WALLET,
  COINBASE_WALLET,
  COINBASE_WALLET_ID,
} from ':dapp/connection/wallet-providers/coinbase-wallet';
import { useIsCoinbaseDappBrowser } from ':dapp/hooks/useIsCoinbaseDappBrowser';

import { useIsSmartWalletConnected } from './useIsSmartWalletConnected';

type CoinbaseWalletOptionsProps = {
  onBack: () => void;
  onClose: () => void;
  onConnect: (
    walletProvider: WalletProviderInfo,
    network: WalletProviderNetworkInfo,
  ) => Promise<void>;
};

const messages = defineMessages({
  title: {
    defaultMessage: 'Select your wallet type',
    description: 'Text telling the user to select their connection method',
  },
  backLabel: {
    defaultMessage: 'Back',
    description: 'Text telling the user to select their connection method',
  },
  mobileTitle: {
    defaultMessage: 'Wallet (mobile app)',
    description: 'Text telling the user to select their connection method',
  },
  mobileDescription: {
    defaultMessage: 'Sign in with Coinbase Wallet app',
    description: 'Text telling the user to select their connection method',
  },
  extensionTitle: {
    defaultMessage: 'Wallet (extension)',
    description: 'Text telling the user to select their connection method',
  },
  extensionDescription: {
    defaultMessage: 'Sign in your browser extension',
    description: 'Text telling the user to select their connection method',
  },
  smartWallet: {
    defaultMessage: 'Smart Wallet (passkey)',
    description: 'Text telling the user to select their connection method',
  },
  smartWalletDescription: {
    defaultMessage: 'Biometrics, Yubikey, and more',
    description: 'Text telling the user to select their connection method',
  },
  connected: {
    defaultMessage: 'Connected',
    description: 'Text telling the user they are connected',
  },
});

export function CoinbaseWalletSelector({ onBack, onClose, onConnect }: CoinbaseWalletOptionsProps) {
  const { formatMessage } = useIntl();
  const accounts = useAccounts();

  const smartWalletConnected = useIsSmartWalletConnected();

  const coinbaseWalletsAccounts = useMemo(
    () => accounts.filter((account) => account.provider === COINBASE_WALLET_ID),
    [accounts],
  );

  const hasExtension = useMemo(() => {
    if ('coinbaseWalletExtension' in window) {
      return true;
    }
    return false;
  }, []);

  const handleConnect = useCallback(
    (walletProvider: WalletProviderInfo) => async () => {
      // Reset the wallet connection if it's already connected in the provider but
      // no accounts exist in the db
      if (
        (await walletProvider.connectorManager.isConnected(WalletProviderNetwork.Ethereum)) &&
        coinbaseWalletsAccounts.length === 0
      ) {
        triggerResetRequired();
        await walletProvider.connectorManager.disconnect(WalletProviderNetwork.Ethereum);
      }

      const network = walletProvider.connectorManager.networks.find(
        (network_) => network_.id === WalletProviderNetwork.Ethereum,
      );
      if (network) {
        await onConnect(walletProvider, network);
      }
    },
    [coinbaseWalletsAccounts.length, onConnect],
  );

  const eoaDescription = useMemo(() => {
    if (coinbaseWalletsAccounts.length > 0) {
      return formatMessage(messages.connected);
    }
    if (hasExtension) {
      return formatMessage(messages.extensionDescription);
    }
    return formatMessage(messages.mobileDescription);
  }, [coinbaseWalletsAccounts.length, formatMessage, hasExtension]);

  const isCoinbaseDappBrowser = useIsCoinbaseDappBrowser();

  return (
    <VStack gap={1} testID="coinbase-wallet-selector" spacingBottom={2}>
      <Box alignItems="center" justifyContent="space-between" spacing={2}>
        <IconButton
          name="backArrow"
          onClick={onBack}
          testID="coinbase-wallet-selector-back-arrow-icon"
          transparent
        />
        <TextHeadline as="h2">{formatMessage(messages.title)}</TextHeadline>
        <IconButton
          transparent
          name="close"
          onClick={onClose}
          testID="coinbase-wallet-selector-close-icon"
        />
      </Box>
      <VStack gap={1}>
        {!isCoinbaseDappBrowser && (
          <ListCell
            priority="start"
            disabled={smartWalletConnected}
            testID="coinbase-wallet-selector-smart-wallet"
            title={<TextBody as="span">{formatMessage(messages.smartWallet)}</TextBody>}
            onPress={handleConnect(COINBASE_SMART_WALLET)}
            media={
              <Box
                alignItems="center"
                background="backgroundOverlay"
                borderRadius="roundedFull"
                spacing={1}
              >
                <Image alt="Passkey Icon" src="/icon-passkey.svg" height={16} width={16} />
              </Box>
            }
            action={
              <Box alignItems="center">
                <Icon name="caretRight" size="s" color="foregroundMuted" />
              </Box>
            }
            description={
              <TextBody as="span" color={smartWalletConnected ? 'positive' : 'foregroundMuted'}>
                {formatMessage(
                  smartWalletConnected ? messages.connected : messages.smartWalletDescription,
                )}
              </TextBody>
            }
          />
        )}
        <ListCell
          priority="start"
          testID="coinbase-wallet-selector-wallet"
          title={
            <TextBody spacingEnd={1} as="span">
              {formatMessage(hasExtension ? messages.extensionTitle : messages.mobileTitle)}
            </TextBody>
          }
          onPress={handleConnect(COINBASE_WALLET)}
          media={
            <Box
              alignItems="center"
              background="backgroundOverlay"
              borderRadius="roundedFull"
              spacing={1}
            >
              <Image
                alt={hasExtension ? 'Extension Icon' : 'Mobile Icon'}
                src={hasExtension ? '/icon-extension.svg' : '/icon-mobile.svg'}
                height={16}
                width={16}
              />
            </Box>
          }
          action={
            <Box alignItems="center">
              <Icon name="caretRight" size="s" color="foregroundMuted" />
            </Box>
          }
          description={
            <TextBody
              as="span"
              color={coinbaseWalletsAccounts.length > 0 ? 'positive' : 'foregroundMuted'}
            >
              {eoaDescription}
            </TextBody>
          }
        />
      </VStack>
    </VStack>
  );
}

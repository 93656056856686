import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { Persistable } from 'cb-wallet-data/persistence/Database.interface';
import { stringToURL } from 'cb-wallet-data/utils/String+Core';
import { Column, Entity, Index, PrimaryColumn } from '@cbhq/typeorm';

@Entity('spl')
@Index('IDX_SPL_CHAIN', ['chainIdStr'])
@Index('IDX_SPL_ADDR_CHAIN', ['mintAddress', 'chainIdStr'])
export class SPLDMO {
  @PrimaryColumn()
  id!: string;

  @Column()
  name!: string;

  @Column()
  decimalsStr!: string;

  @Column()
  mintAddress!: string;

  @Column()
  chainIdStr!: string;

  @Column()
  currencyCodeStr!: string;

  @Column()
  blockchainStr!: string;

  @Column({ nullable: true })
  imageURLStr?: string;
}

export function getSPLId(mintAddress: string, chainId: number) {
  return [mintAddress, chainId.toString()].join('/');
}

export class SPL implements Persistable<SPLDMO> {
  readonly id: string;

  constructor(
    readonly name: string,
    readonly decimals: bigint,
    readonly mintAddress: string,
    readonly chainId: number,
    readonly currencyCode: CurrencyCode,
    readonly blockchain: Blockchain,
    readonly imageURL?: URL,
  ) {
    this.id = getSPLId(mintAddress, chainId);
  }

  get asDMO(): SPLDMO {
    return {
      id: this.id,
      name: this.name,
      decimalsStr: this.decimals.toString(),
      mintAddress: this.mintAddress,
      chainIdStr: this.chainId.toString(),
      currencyCodeStr: this.currencyCode.rawValue,
      blockchainStr: this.blockchain.rawValue,
      imageURLStr: this.imageURL?.toString(),
    };
  }

  static fromDMO(dmo: SPLDMO): SPL {
    return new SPL(
      dmo.name,
      BigInt(dmo.decimalsStr),
      dmo.mintAddress,
      Number(dmo.chainIdStr),
      new CurrencyCode(dmo.currencyCodeStr),
      new Blockchain(dmo.blockchainStr),
      stringToURL(dmo.imageURLStr),
    );
  }
}

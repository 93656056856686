import { ActionType, ComponentType } from '@cbhq/client-analytics';

import { logDataEvent, logEvent, LogEventDataLayerParams } from '../utils/log';

export function fn() {}

export type TOKEN_TYPE = 'NFT_ITEM' | 'NFT_COLLECTION' | 'TOKEN';

function logTokenApprovalAnalytics(
  eventName: string,
  eventId: string,
  eventProperties: Omit<LogEventDataLayerParams, 'loggingId'> = {},
) {
  return logDataEvent(eventName, {
    loggingId: eventId,
    ...eventProperties,
  });
}

export function threeSecondThresholdTriggered() {
  logTokenApprovalAnalytics(
    'token_approvals.three_second_timeout_triggered',
    '3dcc8951-5649-44bc-b17d-281f36fc2a83',
  );
}

export function refetchCounterTriggered() {
  logTokenApprovalAnalytics(
    'token_approvals.refetch_triggered',
    '9150de57-72d0-406a-aac1-66c6f8d484d7',
  );
}

export function nftApprovalsAPICallTriggered() {
  logTokenApprovalAnalytics(
    'token_approvals.nft_approvals_triggered',
    'd19c738a-cb36-4f32-86c1-7b65236d44c2',
  );
}

export function tokenApprovalsAPICallTriggered() {
  logTokenApprovalAnalytics(
    'token_approvals.token_approvals_triggered',
    'e3a72b71-64d9-4499-8379-a383f9506f21',
  );
}

export function nftApprovalsAPICallSuccessEvent(successfullyReturnedItems: number) {
  logTokenApprovalAnalytics(
    'token_approvals.nft_approvals_successful',
    '7d2ef216-114a-444a-a790-1a2d1d2c658f',
    { successfullyReturnedItems },
  );
}

export function tokenApprovalsAPICallSuccessEvent(successfullyReturnedItems: number) {
  logTokenApprovalAnalytics(
    'token_approvals.token_approvals_successful',
    '13406db8-7292-4a1a-96ce-b1512dbaa6db',
    { successfullyReturnedItems },
  );
}

export function nftApprovalsAPICallFailureEvent() {
  logTokenApprovalAnalytics(
    'token_approvals.nft_approvals_failure',
    'e7d178a2-b6fb-4df7-99b3-50db0f1f3911',
  );
}

export function tokenApprovalsAPICallFailureEvent() {
  logTokenApprovalAnalytics(
    'token_approvals.token_approvals_failure',
    'ac28512c-a0c3-4f1c-a395-24620fd00e44',
  );
}

export function revokeApprovalButtonClicked(approval: any) {
  logTokenApprovalAnalytics(
    'token_approvals.revoke_approval_clicked',
    '68008184-7270-42ab-a43d-1bc0a6f63374',
    { data: approval },
  );
}

export function revokeApprovalFailureOnClick(error: any) {
  logTokenApprovalAnalytics(
    'token_approvals.revoke_approval_failure_on_click',
    '292b0006-1bb7-4449-9f72-3b48688832fa',
    { error },
  );
}

export function logViewTokenAllowanceItemComponentError(error: any) {
  logEvent('token_approvals.view_token_allowance_item_component_error', {
    loggingId: 'ba29815a-7021-485f-91ea-0863bdda6450',
    action: ActionType.view,
    componentType: ComponentType.unknown,
    errorMessage: error,
  });
}

export function revokeApprovalSuccessfullyTriggeredSignTransaction(
  timeToGenerateSignTxnData: number | undefined,
) {
  logTokenApprovalAnalytics(
    'token_approvals.successfully_triggered_sign_transaction',
    'baec2f9a-bd8f-4592-8909-aba99b997d24',
    { timeToGenerateSignTxnData },
  );
}

export function successfullyRevokedTxn(revokeType: TOKEN_TYPE) {
  logTokenApprovalAnalytics(
    'token_approvals.successfully_revoked_transaction',
    '29ba2c0a-71f3-47e6-846b-b220aafa9b9f',
    { revokeType },
  );
}

export function revokeTxnFailed(revokeType: TOKEN_TYPE) {
  logTokenApprovalAnalytics(
    'token_approvals.revoked_transaction_failed',
    'b8d12467-8da1-47fc-8634-514cd1016128',
    { revokeType },
  );
}

export function revokeApprovalNuxViewed() {
  logTokenApprovalAnalytics(
    'token_approvals.revoke_approval_failure_on_click',
    'f76be385-2048-48c7-8f13-c8fab0e69d5a',
  );
}

export function revokeApprovalConfirmationScreenViewed() {
  logTokenApprovalAnalytics(
    'token_approvals.revoke_approval_confirmation_screen_viewed',
    'a863543f-12db-4c0e-b55b-282b6a322ffd',
  );
}

export function revokeApprovalCancelOnConfirmation() {
  logTokenApprovalAnalytics(
    'token_approvals.revoke_approval_cancel_on_confirmation',
    'c9e434d3-1281-4ff7-95ee-82e1345864c9',
  );
}

export function revokeApprovalSubmitOnConfirmation() {
  logTokenApprovalAnalytics(
    'token_approvals.revoke_approval_submit_on_confirmation',
    'dda945fd-0e08-4b74-bfeb-400cd40fe164',
  );
}

export function revokeApprovalSuccessfulSubmitOnConfirmation() {
  logTokenApprovalAnalytics(
    'token_approvals.revoke_approval_successful_submit_on_confirmation',
    '939681b0-6d8b-480d-9714-f521046b71ab',
  );
}

export function revokeApprovalSignErrorOnConfirmation() {
  logTokenApprovalAnalytics(
    'token_approvals.revoke_approval_sign_erro_on_confirmation',
    '5415f7fd-db5a-469b-9c78-ebf6784cc3b1',
  );
}

export function revokeApprovalFailureAtSettingsPage(error: string) {
  logTokenApprovalAnalytics(
    'token_approvals.failure_at_settings_page',
    '5415f7fd-db5a-469b-9c78-ebf6784cc3b1',
    { error },
  );
}

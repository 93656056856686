import { EthereumBlockchain } from 'cb-wallet-data/chains/AccountBased/Ethereum/config';
import { AllPossibleBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { tryGetRepository } from 'cb-wallet-data/persistence/Database';
import { Address, AddressDMO } from 'cb-wallet-data/stores/Addresses/models/Address';
import { AddressType } from 'cb-wallet-data/stores/Addresses/models/AddressType';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { IsNull, Not } from '@cbhq/typeorm';

import { sortAddressDMOs } from './helpers';

export type GetErc20AddressesForNetworkAndIndexParams = {
  network: Network;
  index: bigint;
  accountId: string;
};

export type GetAddressForIndexParams = {
  blockchain: Blockchain;
  currencyCode: CurrencyCode;
  network: Network;
  addressType: AddressType;
  isChangeAddress: boolean;
  index: bigint;
  accountId: string;
};

export type GetFirstUnusedAddressParams = {
  blockchain: Blockchain;
  currencyCode: CurrencyCode;
  network: Network;
  addressType: AddressType;
  isChangeAddress: boolean;
  accountId: string;
};

export type GetLatestAddressParams = {
  blockchain: Blockchain;
  currencyCode: CurrencyCode;
  network: Network;
  addressType: AddressType;
  isChangeAddress: boolean;
  accountId: string;
};

export type GetAddressesParams = {
  blockchain: Blockchain;
  currencyCode: CurrencyCode;
  addresses: string[];
  network: Network;
  accountId: string;
};

export type GetAddressParams = {
  blockchain: Blockchain;
  currencyCode: CurrencyCode;
  network: Network;
  address: string;
  accountId: string;
};

export type GetUsedAddressesParams = {
  blockchain: Blockchain;
  currencyCode: CurrencyCode;
  network: Network;
  accountId: string;
};

export type GetAddressesByAddressTypeParams = {
  blockchain: Blockchain;
  currencyCode: CurrencyCode;
  network: Network;
  addressType: AddressType;
  accountId: string;
};

export type GetAddressesForBlockchainOfAccountParams = {
  blockchain: Blockchain;
  currencyCode: CurrencyCode;
  network: Network;
  accountId: string;
};

export type GetAddressesForBlockchain = {
  blockchain: Blockchain;
  network?: Network;
  accountId?: string;
};

export function repository() {
  return tryGetRepository<AddressDMO>('address');
}

export async function getErc20AddressesForNetworkAndIndex({
  network,
  index,
  accountId,
}: GetErc20AddressesForNetworkAndIndexParams): Promise<Address[]> {
  const addresses = await repository().find({
    where: {
      networkStr: network.rawValue,
      blockchainStr: EthereumBlockchain.rawValue,
      contractAddress: Not(IsNull()),
      indexStr: index.toString(),
      accountId,
    },
  });

  return sortAddressDMOs(addresses)
    .map(Address.fromDMO)
    .filter((a) => !a.isDeleted);
}

export async function getAddressesByAddressType({
  blockchain,
  currencyCode,
  network,
  addressType,
  accountId,
}: GetAddressesByAddressTypeParams): Promise<Address[]> {
  const addresses = await repository().find({
    where: {
      blockchainStr: blockchain.rawValue,
      currencyCodeStr: currencyCode.rawValue,
      networkStr: network.rawValue,
      typeStr: addressType.rawValue,
      accountId,
    },
  });

  return addresses.map(Address.fromDMO).filter((a) => !a.isDeleted);
}

export async function getAddressForIndex({
  blockchain,
  currencyCode,
  network,
  addressType,
  isChangeAddress,
  index,
  accountId,
}: GetAddressForIndexParams): Promise<Address | undefined> {
  const address = await repository().findOne({
    where: {
      blockchainStr: blockchain.rawValue,
      currencyCodeStr: currencyCode.rawValue,
      networkStr: network.rawValue,
      typeStr: addressType.rawValue,
      isChangeAddressStr: `${isChangeAddress}`,
      indexStr: index.toString(),
      accountId,
    },
  });

  if (address?.isDeleted) {
    return undefined;
  }

  return address ? Address.fromDMO(address) : undefined;
}

/**
 * Get first unused address for given address type
 */
export async function getFirstUnusedAddress({
  blockchain,
  currencyCode,
  network,
  addressType,
  isChangeAddress,
  accountId,
}: GetFirstUnusedAddressParams): Promise<Address | undefined> {
  const addresses = await repository().find({
    where: {
      blockchainStr: blockchain.rawValue,
      currencyCodeStr: currencyCode.rawValue,
      networkStr: network.rawValue,
      typeStr: addressType.rawValue,
      isChangeAddressStr: isChangeAddress.toString(),
      isUsedStr: 'false',
      accountId,
    },
  });

  const sortedAddresses = sortAddressDMOs(addresses).filter((a) => !a.isDeleted);

  return sortedAddresses[0] ? Address.fromDMO(sortedAddresses[0]) : undefined;
}

/**
 * Get latest address for given wallet address type and currency code
 */
export async function getLatestAddress({
  blockchain,
  currencyCode,
  network,
  addressType,
  isChangeAddress,
  accountId,
}: GetLatestAddressParams): Promise<Address | undefined> {
  const addresses = await repository().find({
    where: {
      blockchainStr: blockchain.rawValue,
      currencyCodeStr: currencyCode.rawValue,
      networkStr: network.rawValue,
      typeStr: addressType.rawValue,
      isChangeAddressStr: `${isChangeAddress}`,
      accountId,
    },
  });

  if (addresses.length === 0) return undefined;

  const sortedAddresses = sortAddressDMOs(addresses).filter((a) => !a.isDeleted);

  return Address.fromDMO(addresses[sortedAddresses.length - 1]);
}

/**
 * Get list of addresses with a balance greater than zero for given currency code
 */
export async function getAddressesWithBalance({
  blockchain,
  currencyCode,
  network,
  accountId,
}: GetAddressesForBlockchainOfAccountParams): Promise<Address[]> {
  const addresses = await repository().find({
    where: {
      blockchainStr: blockchain.rawValue,
      currencyCodeStr: currencyCode.rawValue,
      networkStr: network.rawValue,
      accountId,
    },
  });

  return addresses
    .filter((a) => !a.isDeleted)
    .filter((address: AddressDMO) => !!address.balanceStr && address.balanceStr !== '0')
    .map(Address.fromDMO);
}

/**
 * Get an ordered list of HD addresses for given currency code
 */
export async function getOrderedAddresses({
  blockchain,
  currencyCode,
  network,
  accountId,
}: GetAddressesForBlockchainOfAccountParams): Promise<Address[]> {
  const addresses = await repository().find({
    where: {
      blockchainStr: blockchain.rawValue,
      currencyCodeStr: currencyCode.rawValue,
      networkStr: network.rawValue,
      accountId,
    },
  });

  return sortAddressDMOs(addresses)
    .map(Address.fromDMO)
    .filter((a) => !a.isDeleted);
}

/**
 * Get all addresses for a certain blockchain of an account, with no sorting required.
 */
export async function getAddressesForBlockchainOfAccount({
  blockchain,
  network,
  currencyCode,
  accountId,
}: GetAddressesForBlockchainOfAccountParams): Promise<Address[]> {
  const addresses = await repository().find({
    where: {
      blockchainStr: blockchain.rawValue,
      currencyCodeStr: currencyCode.rawValue,
      networkStr: network.rawValue,
      accountId,
    },
  });

  return addresses.map(Address.fromDMO).filter((a) => !a.isDeleted);
}

/**
 * Get all addresses for a certain blockchain
 * optionally filtered by network and accountId
 */
export async function getAddressesForBlockchain({
  blockchain,
  network,
  accountId,
}: GetAddressesForBlockchain): Promise<Address[]> {
  const addresses = await repository().find({
    where: {
      blockchainStr: blockchain.rawValue,
      ...(network && { networkStr: network.rawValue }),
      ...(accountId && { accountId }),
    },
  });

  return addresses.map(Address.fromDMO).filter((a) => !a.isDeleted);
}

/**
 * Save or update given address
 */
export async function saveAddress(address: Address): Promise<Address> {
  await repository().upsert(address.asDMO, ['id']);
  return address;
}

/**
 * Save or update given [addressList]
 */
export async function saveAddresses(addresses: Address[]): Promise<void> {
  await Promise.all(
    repository().batchUpsert(
      addresses.map((a) => a.asDMO),
      ['id'],
    ),
  );
}

/**
 * Get addresses for given list of address strings
 */
export async function getAddresses({
  blockchain,
  currencyCode,
  addresses,
  network,
  accountId,
}: GetAddressesParams): Promise<Address[]> {
  const queriedAddresses: AddressDMO[] = await repository().find({
    where: {
      blockchainStr: blockchain.rawValue,
      currencyCodeStr: currencyCode.rawValue,
      networkStr: network.rawValue,
      accountId,
    },
  });

  // Since we can't use a query builder we don't have a great way
  // of quering for 'LOWER(address) IN (:...addresses)'
  // so we narrow resultset for blockchain + currencyCode + network
  // and then filter addresses in memory instead
  const addressesToTest = addresses.map((a) => a.toLowerCase());
  return queriedAddresses
    .filter((a) => !a.isDeleted)
    .filter((entry) => addressesToTest.includes(entry.address.toLowerCase()))
    .map(Address.fromDMO);
}

/**
 * Get address for given address string
 */
export async function getAddress({
  blockchain,
  currencyCode,
  network,
  address,
  accountId,
}: GetAddressParams): Promise<Address | undefined> {
  const queriedAddresses: AddressDMO[] = await repository().find({
    where: {
      blockchainStr: blockchain.rawValue,
      currencyCodeStr: currencyCode.rawValue,
      networkStr: network.rawValue,
      accountId,
    },
  });

  const result = queriedAddresses
    .filter((a) => !a.isDeleted)
    .find((entry) => address.toLowerCase() === entry.address.toLowerCase());

  return result ? Address.fromDMO(result) : undefined;
}

/**
 * Get an ordered list of "used" HD addresses for given currency code
 */
export async function getUsedAddresses({
  blockchain,
  currencyCode,
  network,
  accountId,
}: GetUsedAddressesParams): Promise<Address[]> {
  const addresses = await repository().find({
    where: {
      blockchainStr: blockchain.rawValue,
      currencyCodeStr: currencyCode.rawValue,
      networkStr: network.rawValue,
      isUsedStr: 'true',
      accountId,
    },
  });

  return sortAddressDMOs(addresses)
    .map(Address.fromDMO)
    .filter((a) => !a.isDeleted);
}

/**
 * Get all addresses.
 */
export async function getAllAddresses(): Promise<Address[]> {
  const addresses = await repository().find();
  return addresses.map(Address.fromDMO).filter((a) => !a.isDeleted);
}

/**
 * Delete addresses in bulk
 *
 * Takes address ids as an input parameter and deletes them from the database.
 * repository method is only invoked if the addressIds array is not empty.
 *
 * * @param isSoftDelete - whether to only soft delete the addresses
 */
export async function deleteAddresses(addressIds: string[]): Promise<void> {
  if (addressIds.length) {
    await repository().delete(addressIds);
  }
}

export async function softDeleteAddresses(addresses: Address[]): Promise<void> {
  await Promise.all(
    repository().batchUpsert(
      addresses.map(function markAddressAsSoftDeleted(a) {
        const dmo = a.asDMO;
        dmo.isDeleted = true;
        return dmo;
      }),
      ['id'],
    ),
  );
}

export async function getAddressesForAccount(accountId: string): Promise<Address[]> {
  const addresses = await repository().find({
    where: {
      accountId,
    },
  });

  return addresses.map(Address.fromDMO).filter((a) => !a.isDeleted);
}

export async function getAddressIdsForBlockchainAndNetwork(
  symbol: AllPossibleBlockchainSymbol,
  network: Network,
): Promise<string[]> {
  const rows = await repository().find({
    where: {
      blockchainStr: symbol,
      networkStr: network.rawValue,
    },
  });

  return rows.map((row) => row.id);
}

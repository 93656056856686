import noop from 'lodash/noop';

import { SprigEventTrack, useCheckForZeroBalanceWallet } from '../useSyncIsZeroBalanceWallet';

import { useCollectionsUpdate } from './useCollectionsUpdate';
import { CryptoBalanceUpdateOptions, useCryptosUpdate } from './useCryptosUpdate';

export type BalanceUpdateEventOptions = {
  includeLegacyBalanceCheck?: boolean;
  includeTestnet?: boolean;
  sprigEventTrack?: SprigEventTrack;
  onCryptosBalanceDidUpdate?: CryptoBalanceUpdateOptions['onBalanceDidUpdate'];
  onCryptosBalanceNoUpdate?: CryptoBalanceUpdateOptions['onBalanceNoUpdate'];
  collectionsUpdateCallback?: () => void;
};

export function useBalanceUpdateEvents({
  sprigEventTrack,
  includeLegacyBalanceCheck = false,
  includeTestnet = false,
  onCryptosBalanceDidUpdate = noop,
  onCryptosBalanceNoUpdate = noop,
  collectionsUpdateCallback = noop,
}: BalanceUpdateEventOptions) {
  useCheckForZeroBalanceWallet({ sprigEventTrack, includeLegacyBalanceCheck });

  useCryptosUpdate({
    includeTestnet,
    onBalanceDidUpdate: onCryptosBalanceDidUpdate,
    onBalanceNoUpdate: onCryptosBalanceNoUpdate,
  });

  useCollectionsUpdate({
    balanceUpdateCallback: collectionsUpdateCallback,
  });
}

import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { postJSON } from 'cb-wallet-http/fetchJSON';

import { EarnPortfolioResponse } from '../models/EarnPortfolioResponse';

const FETCH_QUERY_KEY = 'portfolio/defi/earn';

export type EarnPortfolioRequestParams = {
  evmAddresses?: string[];
  enabled?: boolean;
};

export async function fetchDefiEarnPortfolio({
  evmAddresses,
}: EarnPortfolioRequestParams): Promise<EarnPortfolioResponse> {
  try {
    if (!evmAddresses?.length) {
      return { earnPortfolioAssets: [] };
    }

    const { result } = await postJSON<{
      result: EarnPortfolioResponse;
    }>(FETCH_QUERY_KEY, {
      evmWalletAddresses: evmAddresses,
    });
    return result;
  } catch (error) {
    cbReportError({
      context: 'defi-portfolio',
      error: new Error(`Failed to fetch defi earn portfolio`),
      severity: 'error',
      isHandled: false,
    });
    return Promise.resolve({ earnPortfolioAssets: [] });
  }
}

export function useFetchDefiEarnPortfolio({
  evmAddresses,
  enabled = true,
}: EarnPortfolioRequestParams) {
  const { data, error, isLoading } = useQuery(
    [FETCH_QUERY_KEY, ...(evmAddresses ?? [])],
    async () =>
      fetchDefiEarnPortfolio({
        evmAddresses,
      }),
    {
      enabled,
      useErrorBoundary: false,
      staleTime: 1000 * 30,
      refetchInterval: 1000 * 60,
      notifyOnChangeProps: ['data'],
    },
  );

  return { response: data, error, isLoading };
}

import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { FiatCurrency } from 'cb-wallet-data/stores/ActiveFiatCurrency/models/FiatCurrency';
import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { Store } from 'cb-wallet-store/Store';
import { atom, AtomEffect } from 'recoil';

const STATE_KEY = 'activeFiatCurrency';

export const StoreKeys_activeFiatCurrency = new LocalStorageStoreKey<string>(STATE_KEY);

// Persists and hydrates instances of FiatCurrency to and from string localStorage values
function fiatCurrencyLocalStorageEffect(
  key: LocalStorageStoreKey<string>,
): AtomEffect<FiatCurrency> {
  return (({ setSelf, onSet }) => {
    const savedValue = Store.get(key);

    if (savedValue !== undefined) {
      const { code, name, decimalsStr } = JSON.parse(savedValue as string);
      const currencyCode = new CurrencyCode(code);
      setSelf(new FiatCurrency(currencyCode, name, BigInt(decimalsStr)));
    }

    onSet((newValue: FiatCurrency) => {
      Store.set(key, newValue.toString());
    });
  }) as AtomEffect<FiatCurrency>;
}

export const activeFiatCurrencyAtom = atom<FiatCurrency>({
  key: STATE_KEY,
  default: FiatCurrency.USD,
  effects: [fiatCurrencyLocalStorageEffect(StoreKeys_activeFiatCurrency)],
});

import { useCallback } from 'react';
import { L1EthWarningScreen } from 'wallet-cds-web/components/L1EthWarningScreen';
import { useClipboard } from 'wallet-cds-web/hooks/useClipboard';
import { Modal } from '@cbhq/cds-web/overlays';

type L1WarningModalProps = {
  onClose: () => void;
  onDidClose?: () => void;
  visible: boolean;
  address: string;
};

export function L1WarningModal({ address, onClose, visible }: L1WarningModalProps) {
  const copy = useClipboard();

  const handleAccept = useCallback(() => {
    copy(address);
    onClose();
  }, [address, copy, onClose]);

  return (
    <Modal visible={visible} onRequestClose={onClose}>
      <L1EthWarningScreen onSubmit={handleAccept} alignItems="flex-start" spacing={5} />
    </Modal>
  );
}

import { useCallback } from 'react';
import { useAuthedGet } from 'cb-wallet-data/HTTP/hooks/useAuthedGet';
import { useAuthedPost } from 'cb-wallet-data/HTTP/hooks/useAuthedPost';
import { WalletAddress } from 'cb-wallet-data/stores/Wallets/models/WalletAddress';
import { getJSON } from 'cb-wallet-http/fetchJSON';

type CheckUsernameAvailabilityResponse = {
  result: {
    available: boolean;
  };
};

/**
 * Check if the given username is available.
 *
 * @param username The username to check
 *
 * @return A Promise resolving with a boolean indicating if the username is available.
 */
export async function checkUsernameAvailability(
  username: string,
  isSubdomain = false,
): Promise<boolean> {
  const { result } = await getJSON<CheckUsernameAvailabilityResponse>('checkUsernameAvailability', {
    username: username.toLowerCase(),
    isSubdomain: isSubdomain ? 'true' : '',
  });
  return result.available;
}

type AddAddressResponse = {
  added: boolean;
};

type AddAddress = (blockchain: string, addresses: WalletAddress[]) => Promise<boolean>;

export function useAddAddresses(): AddAddress {
  const post = useAuthedPost<AddAddressResponse>('addAddress');

  /**
   * Add blockchain addresses
   *
   * @param blockchain The `Blockchain` for the addresses
   * @param addresses A list of `WalletAddress` addresses to add
   */
  const addAddress = async (blockchain: string, addresses: WalletAddress[]) => {
    const { added } = await post({
      chain: blockchain,
      addresses: addresses.map(({ index, address }) => ({
        address,
        index: Number(index.toString()),
      })),
    });

    return added;
  };

  return useCallback(addAddress, [post]);
}

export function useCheckUserAddressMatches(): (address: WalletAddress) => Promise<boolean> {
  const get = useAuthedGet<{ matches: boolean }>('checkUserAddressMatches');

  /**
   * Call api to check if the given address matches one of the current user's registered addresses
   * @param address an address to check
   */
  const checkUserAddressMatches = async (address: WalletAddress) => {
    const { matches } = await get({
      address: address.address,
    });

    return matches;
  };

  return useCallback(checkUserAddressMatches, [get]);
}

import { useSyncExternalStore } from 'react';
import { getConnectors, watchConnectors } from '@wagmi/core';
import { getConfig } from 'cb-wallet-data/scw/libs/wagmi/config';

export function useInjectedConnectors() {
  return useSyncExternalStore(
    (onChange) => watchConnectors(getConfig(), { onChange }),
    () => getConnectors(getConfig()),
    () => getConnectors(getConfig()),
  );
}

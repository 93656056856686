/**
 * This file is not covered by unit tests because it is copied/pasted from @coinbase/wallet-sdk.
 * The only change in this file is the import paths, lint, and changing the storage key, which
 * enables walletlink and SCW to work concurrently in pano. In the future if other changes are
 * made, pano specific testing should be added.
 */
/* istanbul ignore file */
/* eslint-disable no-restricted-imports */
import { Communicator } from '@coinbase/wallet-sdk/dist/core/communicator/Communicator';
import { standardErrorCodes, standardErrors } from '@coinbase/wallet-sdk/dist/core/error';
import { serializeError } from '@coinbase/wallet-sdk/dist/core/error/serialize';
import { SignerType } from '@coinbase/wallet-sdk/dist/core/message';
import {
  AppMetadata,
  ConstructorOptions,
  Preference,
  ProviderInterface,
  RequestArguments,
  Signer,
} from '@coinbase/wallet-sdk/dist/core/provider/interface';
import { determineMethodCategory } from '@coinbase/wallet-sdk/dist/core/provider/method';
import { AddressString, Chain, IntNumber } from '@coinbase/wallet-sdk/dist/core/type';
import {
  areAddressArraysEqual,
  hexStringFromIntNumber,
} from '@coinbase/wallet-sdk/dist/core/type/util';
import { AccountsUpdate, ChainUpdate } from '@coinbase/wallet-sdk/dist/sign/interface';
import { createSigner } from '@coinbase/wallet-sdk/dist/sign/util';
import {
  checkErrorForInvalidRequestArgs,
  fetchRPCRequest,
} from '@coinbase/wallet-sdk/dist/util/provider';
import { ScopedLocalStorage } from '@coinbase/wallet-sdk/dist/util/ScopedLocalStorage';
import EventEmitter from 'eventemitter3';
import get from 'lodash/get';

import { fetchSignerType } from './sign/utils';

const SIGNER_TYPE_KEY = 'SignerType';
const storage = new ScopedLocalStorage('CBWSDK', 'SCWSignerConfigurator');
function loadSignerType() {
  return storage.getItem(SIGNER_TYPE_KEY) as SignerType | null;
}

function storeSignerType(signerType: string) {
  storage.setItem(SIGNER_TYPE_KEY, signerType);
}

export class CoinbaseSmartWalletProvider extends EventEmitter implements ProviderInterface {
  private readonly metadata: AppMetadata;
  private readonly preference: Preference;
  private readonly communicator: Communicator;

  private signer: Signer | null;
  protected accounts: AddressString[] = [];
  protected chain: Chain;

  constructor({ metadata, preference: { keysUrl, ...preference } }: Readonly<ConstructorOptions>) {
    super();
    this.metadata = metadata;
    this.preference = preference;
    this.communicator = new Communicator(keysUrl);
    this.chain = {
      id: metadata.appChainIds?.[0] ?? 1,
    };
    // Load states from storage
    const signerType = loadSignerType();
    this.signer = signerType ? this.initSigner(signerType) : null;
  }

  public get connected() {
    return this.accounts.length > 0;
  }

  public async request<T>(args: RequestArguments): Promise<T> {
    try {
      const invalidArgsError = checkErrorForInvalidRequestArgs(args);
      if (invalidArgsError) throw invalidArgsError;
      // unrecognized methods are treated as fetch requests
      const category = determineMethodCategory(args.method) ?? 'fetch';
      return this.handlers[category](args) as T;
    } catch (error) {
      return Promise.reject(serializeError(error, args.method));
    }
  }

  protected readonly handlers = {
    // eth_requestAccounts
    handshake: async (request: RequestArguments): Promise<AddressString[]> => {
      try {
        if (this.connected) {
          this.emit('connect', { chainId: hexStringFromIntNumber(IntNumber(this.chain.id)) });
          return this.accounts;
        }

        // --- START NOTE
        // this deviates from the SDK's implementation.
        // It's a workaround to pass additional options to SCW when connecting
        const scwOnboardMode = get(request, 'params[0].scwOnboardMode');
        const options = scwOnboardMode ? { scwOnboardMode } : {};
        const signerType = await this.requestSignerSelection(options);
        // --- END

        const signer = this.initSigner(signerType);
        const accounts = await signer.handshake();

        this.signer = signer;
        storeSignerType(signerType);

        this.emit('connect', { chainId: hexStringFromIntNumber(IntNumber(this.chain.id)) });
        return accounts;
      } catch (error) {
        this.handleUnauthorizedError(error);
        throw error;
      }
    },

    sign: async (request: RequestArguments) => {
      if (!this.connected || !this.signer) {
        throw standardErrors.provider.unauthorized(
          "Must call 'eth_requestAccounts' before other methods",
        );
      }
      try {
        return await this.signer.request(request);
      } catch (error) {
        this.handleUnauthorizedError(error);
        throw error;
      }
    },

    fetch: async (request: RequestArguments) => fetchRPCRequest(request, this.chain),

    state: (request: RequestArguments) => {
      const getConnectedAccounts = (): AddressString[] => {
        if (this.connected) return this.accounts;
        throw standardErrors.provider.unauthorized(
          "Must call 'eth_requestAccounts' before other methods",
        );
      };
      switch (request.method) {
        case 'eth_chainId':
          return hexStringFromIntNumber(IntNumber(this.chain.id));
        case 'net_version':
          return this.chain.id;
        case 'eth_accounts':
          return getConnectedAccounts();
        case 'eth_coinbase':
          return getConnectedAccounts()[0];
        default:
          return this.handlers.unsupported(request);
      }
    },

    deprecated: ({ method }: RequestArguments) => {
      throw standardErrors.rpc.methodNotSupported(`Method ${method} is deprecated.`);
    },

    unsupported: ({ method }: RequestArguments) => {
      throw standardErrors.rpc.methodNotSupported(`Method ${method} is not supported.`);
    },
  };

  private handleUnauthorizedError(error: unknown) {
    const e = error as { code?: number };
    if (e.code === standardErrorCodes.provider.unauthorized) this.disconnect();
  }

  /** @deprecated Use `.request({ method: 'eth_requestAccounts' })` instead. */
  public async enable(): Promise<unknown> {
    // eslint-disable-next-line no-console
    console.warn(
      `.enable() has been deprecated. Please use .request({ method: "eth_requestAccounts" }) instead.`,
    );
    return this.request({
      method: 'eth_requestAccounts',
    });
  }

  async disconnect(): Promise<void> {
    this.accounts = [];
    this.chain = { id: 1 };
    ScopedLocalStorage.clearAll();
    this.emit('disconnect', standardErrors.provider.disconnected('User initiated disconnection'));
  }

  readonly isCoinbaseWallet = true;

  protected readonly updateListener = {
    onAccountsUpdate: ({ accounts, source }: AccountsUpdate) => {
      if (areAddressArraysEqual(this.accounts, accounts)) return;
      this.accounts = accounts;
      if (source === 'storage') return;
      this.emit('accountsChanged', this.accounts);
    },
    onChainUpdate: ({ chain, source }: ChainUpdate) => {
      if (chain.id === this.chain.id && chain.rpcUrl === this.chain.rpcUrl) return;
      this.chain = chain;
      if (source === 'storage') return;
      this.emit('chainChanged', hexStringFromIntNumber(IntNumber(chain.id)));
    },
  };

  private async requestSignerSelection(options: Record<string, unknown>): Promise<SignerType> {
    return fetchSignerType({
      communicator: this.communicator,
      preference: this.preference,
      metadata: this.metadata,
      options,
    });
  }

  private initSigner(signerType: SignerType): Signer {
    return createSigner({
      signerType,
      metadata: this.metadata,
      communicator: this.communicator,
      updateListener: this.updateListener,
    });
  }
}

import { useRecoilValue } from 'recoil';

import { firstCreatedAccountSelector } from '../state';

/**
 * @returns The first created account (not only mnemonic accounts), or undefined if no accounts exist.
 *
 * RN - Since we only support mnemonic accounts as of Feb 2023, this is the same as `useFirstCreatedMnemonicAccount`.
 *
 * EXT - This will retrieve the first created account, not necessarily the first created mnemonic account.
 * If you need the first created mnemonic account, use `useFirstCreatedMnemonicAccount`.
 */
export function useFirstCreatedAccount() {
  return useRecoilValue(firstCreatedAccountSelector);
}

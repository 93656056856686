import { StepperStep } from 'wallet-cds-web/external-libs/stepper';

import { AssetSelectorContent } from '../AssetSelector/AssetSelectorContent';
import { BuyEntry } from '../BuyEntry/BuyEntry';
import { WalletSelectorContent } from '../WalletSelector/WalletSelectorContent';

import { stepper } from './flow';
import { BuyFlowStepsEnum } from './steps';

type BuyFlowStepperProps = {
  onClose?: () => void;
};

export function BuyFlowStepper({ onClose }: BuyFlowStepperProps) {
  return (
    <stepper.Steps>
      <StepperStep key={BuyFlowStepsEnum.ENTRY} component={<BuyEntry onClose={onClose} />} />
      <StepperStep key={BuyFlowStepsEnum.ASSET_SELECTOR} component={<AssetSelectorContent />} />
      <StepperStep key={BuyFlowStepsEnum.WALLET_SELECTOR} component={<WalletSelectorContent />} />
    </stepper.Steps>
  );
}

import { AllPossibleBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
import { BITCOIN_SYMBOL } from 'cb-wallet-data/chains/UTXO/Bitcoin/config';
import { DOGECOIN_SYMBOL } from 'cb-wallet-data/chains/UTXO/Dogecoin/config';
import { LITECOIN_SYMBOL } from 'cb-wallet-data/chains/UTXO/Litecoin/config';
import { experiments } from 'cb-wallet-data/experiments';
import { useIsExperimentInTreatment } from 'cb-wallet-data/stores/Experiments/hooks/useIsExperimentInTreatment';
import { getPlatformName } from 'cb-wallet-metadata/metadata';
import { PlatformName } from '@cbhq/client-analytics';

export function useHasUTXOExtensionExperiment() {
  const platformName = getPlatformName();
  const hasExperiment = useIsExperimentInTreatment(experiments.mar_2023_utxo_extension);

  /**
   * For RN, we want this hook to return true so that
   * the data layering filtering hooks will not filter out UTXO wallets / transactions.
   * see @useGetUTXOExperimentGatedWallets
   * see @useGetUTXOExperimentGatedTransactions
   */
  if (platformName !== PlatformName.extension) {
    return true;
  }

  return hasExperiment;
}

export const UTXO_EXPERIMENT_ENABLED_CHAINS: AllPossibleBlockchainSymbol[] = [
  BITCOIN_SYMBOL,
  LITECOIN_SYMBOL,
  DOGECOIN_SYMBOL,
];

import { AccountType } from 'cb-wallet-data/stores/Accounts/models/AccountTypes';

import { SigningAndDerivationMethodsForAccount } from '../types/SigningAndDerivationMethodsForAccount';

/**
 * This function is used to throw an error when a method is not implemented for a given account type.
 *
 * This is meant to be used as an implementation for an unsupported method for a given account type.
 */
export function throwUnimplementedErrorWithAccount(
  accountType: AccountType,
  methodName: keyof SigningAndDerivationMethodsForAccount,
): (...args: any[]) => Promise<never> {
  return async function unimplementedServiceLocatorImplementation(_params) {
    throw new Error(`Unimplemented method ${methodName} for account type ${accountType}`);
  };
}

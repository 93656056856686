import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Legal',
    description: 'The legal setting category title',
  },
  privacyPolicy: {
    defaultMessage: 'Privacy policy',
    description: 'Title for cell that lets user read privacy policy',
  },
  termsOfService: {
    defaultMessage: 'Terms of service',
    description: 'Title for cell that lets user read terms of service',
  },
});

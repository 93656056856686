import { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useActiveWalletGroupId } from 'cb-wallet-data/stores/WalletGroups/hooks/useActiveWalletGroupId';
import { usePrimaryReceiveAddresses } from 'cb-wallet-data/stores/Wallets/hooks/usePrimaryReceiveAddresses';
import { WalletAddress } from 'cb-wallet-data/stores/Wallets/models/WalletAddress';

import { useAddAddresses } from '../api';

import { useUser } from './useUser';

export function useAddBlockchainAddresses(): void {
  const user = useUser();
  const addAddresses = useAddAddresses();
  const activeWalletGroupId = useActiveWalletGroupId();
  const primaryReceiveAddresses = usePrimaryReceiveAddresses(activeWalletGroupId);

  useEffect(
    function addBlockchainAddresses() {
      if (user?.isPublic) {
        primaryReceiveAddresses.forEach((walletAddress: WalletAddress, blockchain: string) => {
          addAddresses(blockchain, [walletAddress]);
        });
      }
    },
    [addAddresses, primaryReceiveAddresses, user],
  );
}

import { ReactNode } from 'react';
import { Divider, VStack } from '@cbhq/cds-web/layout';

type Props = {
  children: ReactNode;
};

export function SwapSidebarContentWrapper({ children }: Props) {
  return (
    <VStack width="100%" justifyContent="flex-start">
      {children}
      <Divider width="100%" />
    </VStack>
  );
}

import { AllPossibleBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';

type GenerateNetworkHasBalanceMapKeyOptions = {
  index?: bigint;
  network?: Network;
  accountId?: string;
  networkString?: string;
  blockchainSymbol?: AllPossibleBlockchainSymbol;
};

export const NO_NETWORK_RAW_VALUE = 'NO_NETWORK_RAW_VALUE';

export function generateNetworkHasBalanceMapKey({
  index,
  network,
  accountId,
  networkString,
  blockchainSymbol,
}: GenerateNetworkHasBalanceMapKeyOptions) {
  let networkRawValue = NO_NETWORK_RAW_VALUE;

  if (networkString) {
    networkRawValue = networkString;
  } else if (network) {
    if (network.asChain()) {
      networkRawValue = network.rawValue;
    } else {
      networkRawValue = `${blockchainSymbol}/${network?.rawValue}`;
    }
  }

  if (!index && !accountId) return networkRawValue;

  return [accountId, index?.toString(), networkRawValue].filter((str) => !!str).join('/');
}

/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { AddressType } from 'cb-wallet-data/stores/Addresses/models/AddressType';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { NetworkSetting } from 'cb-wallet-data/stores/Networks/models/NetworkSetting';

interface IWalletConfiguration {
  readonly blockchain: Blockchain;
  readonly networkSetting: NetworkSetting;
  readonly refreshInterval: bigint;
  readonly currencyCode: CurrencyCode;
  readonly decimals: bigint;
  readonly imageURL: string | undefined;
  readonly defaultReceiveType: AddressType;
  readonly supportsMultiWallet: boolean;
  readonly isSyncingRequired: boolean;
}

export interface WalletConfiguration extends IWalletConfiguration {}
/**
 * Configuration for wallet repositories
 *
 * @property blockchain Current blockchain
 * @property networkSetting Supported network setting
 * @property refreshInterval Balance refresh interval in seconds
 * @property currencyCode HDWallet currency code
 * @property decimals HDWallet decimals
 * @property imageURL HDWallet image url
 * @property defaultReceiveType Default supported address format
 * @property supportsMultiWallet Determine whether user can switch between different wallet indices
 * @property isSyncingRequired if true, a blockchain is marked `synched` once a full refresh completes. Otherwise, it will be marked as `synced` as soon as the wallet is created
 */
export abstract class WalletConfiguration {
  constructor(props: IWalletConfiguration) {
    Object.assign(this, props);
  }

  /**
   * Get the wallet name based on given network
   *
   * @param network Wallet network
   *
   * @return Wallet name
   */
  abstract displayName(network: Network): string;

  imageURLForNetwork(_network: Network): string | undefined {
    return this.imageURL;
  }

  currencyCodeForNetwork(_network: Network): CurrencyCode {
    return this.currencyCode;
  }

  get minimumBalance(): bigint | undefined {
    return undefined;
  }
}

import { StoreKeys_appearance } from 'cb-wallet-data/stores/User/state';
import { Store } from 'cb-wallet-store/Store';
import { paletteAliasToRgbaString } from '@cbhq/cds-common/palette/paletteAliasToRgbaString';
import { Spectrum } from '@cbhq/cds-web';
import { useDeviceSpectrum } from '@cbhq/cds-web/hooks/useDeviceSpectrum';

export function useGetAppearanceBackground(): [Spectrum | undefined, string] {
  const deviceSpectrum = useDeviceSpectrum();

  const storedAppearance = Store.get(StoreKeys_appearance) ?? 'dark';

  const appearance = (
    storedAppearance === 'system' ? deviceSpectrum : storedAppearance
  ) as Spectrum;
  const background = paletteAliasToRgbaString('background', appearance);

  return [appearance, background];
}

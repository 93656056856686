import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { IconButton } from '@cbhq/cds-web/buttons';
import { TextInput } from '@cbhq/cds-web/controls';
import { HStack, Spacer, VStack } from '@cbhq/cds-web/layout';
import { useToast } from '@cbhq/cds-web/overlays/useToast';
import { Pressable } from '@cbhq/cds-web/system';
import { useCopyToClipboard } from ':dapp/hooks/useCopyToClipboard';
import { useIsMobile } from ':dapp/hooks/useIsMobile';
import { SocialShareType } from ':dapp/pages/nft/mint/[contractAddress]/components/MintShareModal';
import { generateShareUrl } from ':dapp/utils/generateShareUrl';
const messages = defineMessages({
  copied: {
    defaultMessage: 'Share link copied',
    description: 'Success message on copy click'
  },
  openedLink: {
    defaultMessage: 'Opened {item} in a new tab',
    description: 'Opened item in a new tab message'
  }
});
const overridePressableClass = "o1og6h3p";
type OCSShareProps = {
  shareUrl: string;
  copyUrl?: string;
  showX?: boolean;
  showWarpcast?: boolean;
  showLens?: boolean;
};
export function OCSShareBar({
  shareUrl,
  copyUrl,
  showLens = true,
  showX = true,
  showWarpcast = true
}: OCSShareProps) {
  const {
    formatMessage
  } = useIntl();
  const toast = useToast();
  const isMobile = useIsMobile();
  const {
    handleCopy
  } = useCopyToClipboard({
    successMessage: formatMessage(messages.copied),
    hideCloseButton: true
  });
  const shareHandler = useCallback((type: SocialShareType) => () => {
    const url = generateShareUrl(type, shareUrl);
    toast.show(formatMessage(messages.openedLink, {
      item: `${type.charAt(0).toUpperCase()}${type.slice(1)}`
    }), {
      hideCloseButton: true
    });
    window.open(url, 'blank');
  }, [formatMessage, shareUrl, toast]);
  const copyHandler = useCallback(() => {
    /* istanbul ignore next */
    if (!copyUrl) return;
    handleCopy(copyUrl);
  }, [handleCopy, copyUrl]);
  return <VStack width="100%" testID="ocs-share-bar">
      <HStack gap={3} alignSelf="flex-start" spacingVertical={1}>
        {showWarpcast && <Pressable onPress={shareHandler('farcaster')} background="transparent" transparentWhileInactive testID="warpcast" className={overridePressableClass}>
            <img src="/ocs/warpcast.svg" alt="warpcast share button" />
          </Pressable>}
        {showX && <Pressable onPress={shareHandler('x')} background="transparent" transparentWhileInactive testID="xshare" className={overridePressableClass}>
            <img src="/ocs/xshare.svg" alt="x share button" />
          </Pressable>}
        {showLens && <Pressable onPress={shareHandler('lens')} background="transparent" transparentWhileInactive testID="lens" className={overridePressableClass}>
            <img src="/ocs/lensShare.svg" alt="lens share button" />
          </Pressable>}
      </HStack>
      {copyUrl && <>
          {!isMobile && <Spacer vertical={1} />}
          <TextInput label="" variant="foregroundMuted" placeholder="" value={copyUrl} testID="share-url-input" readOnly end={<IconButton name="copy" accessibilityLabel="Copy URL" color="foreground" transparent variant="foregroundMuted" onClick={copyHandler} testID="copyBtn" />} />
        </>}
    </VStack>;
}

require("./OCSShareBar.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./OCSShareBar.tsx");
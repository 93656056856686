import { useMemo } from 'react';

import { CollectibleChainEnabledSetting } from '../types';
import { EnabledSettingForNFTChain } from '../utils';

import { useSupportedCollectibleChains } from './useSupportedCollectibleChains';

export type ViewNFTChainEnabledConfig = Map<string, CollectibleChainEnabledSetting>;

type ChainConfigMapData = [string, CollectibleChainEnabledSetting];
const ethConfigData: ChainConfigMapData = ['Ethereum', EnabledSettingForNFTChain.ETHEREUM];

export function getChainConfigMap(configData?: CollectibleChainEnabledSetting[]) {
  const configMapData = configData
    ? [ethConfigData, ...configData.map((data) => [data.networkName, data] as ChainConfigMapData)]
    : [ethConfigData];
  return new Map<string, CollectibleChainEnabledSetting>(configMapData);
}

export function useViewNFTChainEnabledConfig(): ViewNFTChainEnabledConfig {
  const enabledChains = useSupportedCollectibleChains();

  return useMemo(() => {
    const baseChainConfig = getChainConfigMap(
      enabledChains.map(({ name, addressType, id: chainId, chainImageUrl }) => ({
        networkName: name,
        chainId,
        addressType,
        enabled: true,
        chainImageUrl,
      })),
    );

    return baseChainConfig;
  }, [enabledChains]);
}

import { memo, useMemo } from 'react';
import { Collectible } from 'cb-wallet-data/stores/Collection/types';
import { CollectibleMedia } from 'wallet-cds-web/components/CollectibleMedia';
import { AvatarSize, PaletteBorder } from '@cbhq/cds-common';
import { useAvatarSize } from '@cbhq/cds-common/media/useAvatarSize';
import { Box } from '@cbhq/cds-web/layout';

type ProfileNftPfpProps = {
  collectible: Collectible | undefined;
  chainId: string | undefined;
  size?: AvatarSize;
  borderColor?: PaletteBorder;
};

export const NftPfp = memo(function ProfileNftPfpContent({
  collectible,
  chainId,
  size = 'l',
  borderColor,
}: ProfileNftPfpProps) {
  const pixelSize = useAvatarSize(size);
  const [width, height] = useMemo(() => [`${pixelSize}px`, `${pixelSize}px`], [pixelSize]);
  return (
    <Box testID="nft-pfp">
      <CollectibleMedia
        height={height}
        width={width}
        dangerouslySetRadius={width}
        contractAddress={collectible?.contractAddress}
        animationUrl={collectible?.animationUrl}
        imageUrl={collectible?.imageUrl}
        cachedAnimationUrl={collectible?.cachedAnimationUrl}
        cachedImageUrl={collectible?.cachedImageUrl}
        chainId={chainId || '1'}
        preview
        borderColor={borderColor}
      />
    </Box>
  );
});

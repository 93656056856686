import { useMemo } from 'react';
import { cbReportError as reportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { useProvidersReady } from 'cb-wallet-data/ProviderLocator/hooks/useSyncProviderLocator';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';

import { useGetCapabilitiesForAccountIdInner, WalletCapabilities } from './getCapabilitiesUtils';

/**
 * Hook for getting capabilities for many account ids. This only caches in memory for 30 seconds.
 *
 * The result will get stale after 30 seconds.
 * On error, react query will retry 3 times with a delay / backoff, and then again 30 seconds later.
 *
 * @param accountId
 * @returns
 */
export function useQueryCapabilitiesForAccountIds(accountIds: Account['id'][]) {
  const queryKey = useMemo(() => ['getCapabilities', accountIds], [accountIds]);
  const providersReady = useProvidersReady();
  const getCapabilitiesForAccountIdInner = useGetCapabilitiesForAccountIdInner();

  const { data, isError } = useQuery({
    queryKey,
    queryFn: async () => {
      try {
        return await Promise.all(
          accountIds.map(async (id) => ({
            id,
            capabilities: await getCapabilitiesForAccountIdInner(id),
          })),
        );
      } catch (error: ErrorOrAny) {
        reportError({
          error: coerceError(error, 'Got error getting capabilities for account'),
          context: 'dapp_error',
          isHandled: true,
          severity: 'error',
        });
        // Make sure don't cache the result
        throw error;
      }
    },
    staleTime: 1000 * 30, // 30 seconds
    suspense: false,
    placeholderData: [],
    enabled: accountIds.length > 0 && providersReady,
    notifyOnChangeProps: ['data'],
    select: (resp) => {
      const accountToCapabilitiesMap: AccountToCapabilitiesMap = {};
      resp.forEach(({ id, capabilities }) => {
        accountToCapabilitiesMap[id] = capabilities;
      });
      return accountToCapabilitiesMap;
    },
  });

  return useMemo(() => ({ data: data ?? {}, isError }), [data, isError]);
}

type AccountToCapabilitiesMap = Record<string, WalletCapabilities>;

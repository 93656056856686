import { ReactNode, useCallback, useEffect } from 'react';
import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { createBrowserHistory } from 'history';

import { IS_PROD } from ':dapp/config/env';
import { getDeviceId } from ':dapp/utils/deviceId';

export const SPRIG_ENVIRONMENT_ID = IS_PROD ? 'SaAnIkWLlWzc' : 'ndrc-IIckiOD';

type SprigProviderProps = {
  children: ReactNode;
};

export function SprigProvider({ children }: SprigProviderProps) {
  const deviceId = getDeviceId();
  useEffect(() => {
    import('@cbhq/user-research-web').then((module) => {
      module.init({
        killSwitchConfig: {},
        platform: 'web',
        environmentId: SPRIG_ENVIRONMENT_ID,
        onError: (err, metadata) => {
          cbReportError({
            error: err,
            context: 'analytics',
            metadata: metadata as Record<string, string>,
            severity: 'error',
            isHandled: false,
          });
        },
        navigationEvents: {},
      });
      module.enableResearchEventsOnNavigationChange(createBrowserHistory());
      module.setUserIdentifier(deviceId);
    });
  }, [deviceId]);

  return <>{children}</>;
}

export function useLaunchSprig() {
  const isSprigEnabled = useIsFeatureEnabled('sprig_web');

  return useCallback(
    (eventName: string) => {
      if (isSprigEnabled) {
        import('@cbhq/user-research-web').then((module) => {
          module.triggerResearchEventOnUserAction(eventName);
        });
      }
    },
    [isSprigEnabled],
  );
}

export function useLogoutSprig() {
  return useCallback(() => {
    import('@cbhq/user-research-web').then((module) => {
      module.logoutUser();
    });
  }, []);
}

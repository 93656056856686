import { getPrimaryAddressForIndex } from 'cb-wallet-data/AddressHistory/utils/getPrimaryAddressForIndex';
import { EthereumWalletConfiguration as ethConfig } from 'cb-wallet-data/chains/AccountBased/Ethereum/config';
import { ETHEREUM_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { SolanaWalletConfiguration as solConfig } from 'cb-wallet-data/chains/AccountBased/Solana/config';
import { SOLANA_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Solana/constants';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { getAddressesForBlockchain } from 'cb-wallet-data/stores/Addresses/database';
import type { GetLastSyncedTxHashByNetwork } from 'cb-wallet-data/stores/LastSyncedTxHash/utils';
import { GetTxHistoryVersion } from 'cb-wallet-data/stores/Transactions/hooks/useGetTxHistoryVersion';
import { AddressConfig } from 'wallet-engine-signing/history/historyByChain';

import { ethereumAddressHistoryListener, solanaAddressHistoryListener } from '../listeners';

import { deriveAccountBasedAddressConfigs } from './deriveAccountBasedAddressConfigs';

async function getAddressesForAccountBasedHistoryListeners({
  accountId,
  walletIndex,
  isEthereumNudgeKilled,
  isSolanaNudgeKilled,
  isSolanaDASAPIKilled,
  isWebsocketNudgeKilled,
  getTxHistoryVersion,
  getLastSyncedTxHashByNetwork,
}: {
  accountId: Account['id'];
  walletIndex: bigint;
  isEthereumNudgeKilled: boolean;
  isSolanaNudgeKilled: boolean;
  isSolanaDASAPIKilled: boolean;
  isWebsocketNudgeKilled: boolean;
  getTxHistoryVersion: GetTxHistoryVersion;
  getLastSyncedTxHashByNetwork: GetLastSyncedTxHashByNetwork;
}) {
  // Get all addresses for a chain rather than querying by network in
  // deriveAccountBasedAddressConfigs for perf reasons
  const [ethAddressesFromDb, solAddressesFromDb] = await Promise.all([
    getAddressesForBlockchain({
      blockchain: ethConfig.blockchain,
    }),
    getAddressesForBlockchain({
      blockchain: solConfig.blockchain,
    }),
  ]);

  const primaryEthAddress = getPrimaryAddressForIndex({
    blockchain: ethConfig.blockchain,
    addresses: ethAddressesFromDb,
    currencyCode: ethConfig.currencyCode,
    network: ethConfig.networkSetting.defaultMainnet.network,
    walletIndex,
    accountId,
  });

  const primarySolAddress = getPrimaryAddressForIndex({
    blockchain: solConfig.blockchain,
    addresses: solAddressesFromDb,
    currencyCode: solConfig.currencyCode,
    network: solConfig.networkSetting.defaultMainnet.network,
    walletIndex,
    accountId,
  });

  let ethAddressConfigs: AddressConfig[] | undefined;
  if (primaryEthAddress?.address) {
    ethAddressConfigs = await deriveAccountBasedAddressConfigs({
      blockchainSymbol: ETHEREUM_SYMBOL,
      walletIndex,
      accountId,
      primaryAddress: primaryEthAddress.address,
      isNudgeKilled: isEthereumNudgeKilled,
      isDASAPIKilled: true,
      isWebsocketNudgeKilled: true,
      addressesForBlockchain: ethAddressesFromDb,
      getTxHistoryVersion,
      getLastSyncedTxHashByNetwork,
    });
  }

  let solAddressConfigs: AddressConfig[] | undefined;
  if (primarySolAddress?.address) {
    solAddressConfigs = await deriveAccountBasedAddressConfigs({
      blockchainSymbol: SOLANA_SYMBOL,
      walletIndex,
      accountId,
      primaryAddress: primarySolAddress.address,
      isNudgeKilled: isSolanaNudgeKilled,
      isDASAPIKilled: isSolanaDASAPIKilled,
      isWebsocketNudgeKilled,
      addressesForBlockchain: solAddressesFromDb,
      getTxHistoryVersion,
      getLastSyncedTxHashByNetwork,
    });
  }

  return { ethAddressConfigs, solAddressConfigs };
}

/**
 * Used to remove addresses from account-based
 * history listeners while the app is running.
 *
 * This function is utilized when user deletes accounts.
 *
 */
export async function removeAddressesFromAccountBasedHistoryListeners(params: {
  accountId: Account['id'];
  walletIndex: bigint;
  isEthereumNudgeKilled: boolean;
  isSolanaNudgeKilled: boolean;
  isSolanaDASAPIKilled: boolean;
  isWebsocketNudgeKilled: boolean;
  getTxHistoryVersion: GetTxHistoryVersion;
  getLastSyncedTxHashByNetwork: GetLastSyncedTxHashByNetwork;
}) {
  const { ethAddressConfigs, solAddressConfigs } =
    await getAddressesForAccountBasedHistoryListeners(params);
  if (ethAddressConfigs) {
    ethereumAddressHistoryListener.removeAddresses(ethAddressConfigs);
  }

  if (solAddressConfigs) {
    solanaAddressHistoryListener.removeAddresses(solAddressConfigs);
  }
}

/**
 * Used to add newly created addresses while app is running, to account-based
 * history listeners.
 *
 * This function is utilized when user creates additional accounts or creates
 * additional wallet groups.
 *
 * Note: There are some cases where app is forced to reload and adding new
 * addresses dynamically isn't needed, like when importing ledger addresses,
 * which is done in isolated tab.
 */
export async function addAddressesToAccountBasedHistoryListeners(params: {
  accountId: Account['id'];
  walletIndex: bigint;
  isEthereumNudgeKilled: boolean;
  isSolanaNudgeKilled: boolean;
  isSolanaDASAPIKilled: boolean;
  isWebsocketNudgeKilled: boolean;
  getTxHistoryVersion: GetTxHistoryVersion;
  getLastSyncedTxHashByNetwork: GetLastSyncedTxHashByNetwork;
  skipBalanceRefresh?: boolean;
  skipTransactionRefresh?: boolean;
}) {
  const { ethAddressConfigs, solAddressConfigs } =
    await getAddressesForAccountBasedHistoryListeners(params);

  if (ethAddressConfigs) {
    ethereumAddressHistoryListener.addAddresses(ethAddressConfigs, {
      skipBalanceRefresh: params.skipBalanceRefresh,
      skipTransactionRefresh: params.skipTransactionRefresh,
    });
  }

  if (solAddressConfigs) {
    solanaAddressHistoryListener.addAddresses(solAddressConfigs, {
      skipBalanceRefresh: params.skipBalanceRefresh,
      skipTransactionRefresh: params.skipTransactionRefresh,
    });
  }
}

import {
  logGetXpubKeyResult,
  logGetXpubKeyStart,
  logXpubKeyMissing,
  logXpubStoreKeyMigrated,
} from 'cb-wallet-analytics/accounts';
import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { AddressType } from 'cb-wallet-data/stores/Addresses/models/AddressType';
import { Store } from 'cb-wallet-store/Store';

import { StoreKeys_legacyXpubKey, StoreKeys_xpubKey } from './config';

/**
 * Gets the xpub key from local storage, factoring in possible migration
 * from xpub key stored in legacy format before multi-account.
 */
export function getXpubKeyFromLocalStorage({
  blockchain,
  currencyCode,
  addressType,
  accountId,
  walletIndex,
  isTestnet,
}: {
  blockchain: Blockchain;
  currencyCode: CurrencyCode;
  addressType: AddressType;
  accountId: Account['id'];
  walletIndex: bigint;
  isTestnet: boolean;
}) {
  const accountType = Account.parseAccountTypeFromId(accountId);

  logGetXpubKeyStart({
    blockchain: blockchain.rawValue,
    addressType: addressType.rawValue,
    walletIndex: walletIndex.toString(),
    accountType,
  });

  const xpubKeyStoreKey = StoreKeys_xpubKey({
    blockchain,
    currencyCode,
    addressType,
    accountId,
    walletIndex,
    isTestnet,
  });

  let xpubKey = Store.get<string>(xpubKeyStoreKey);

  if (!xpubKey) {
    logXpubKeyMissing({
      blockchain: blockchain.rawValue,
      addressType: addressType.rawValue,
      walletIndex: walletIndex.toString(),
    });

    // If xpub key is currently stored under the legacy format (before multi-account),
    // find it and re-save it correctly to the given account ID.
    //
    // Assumption: If the xpub key doesn't exist in the new format and it does exist in
    // legacy format, the current account ID must correspond to an original account
    // created before multi-account functionality. Any mnemonics imported after
    // multi-account would have xpub keys saved in new format upon wallet creation.
    const legacyXpubKeyStoreKey = StoreKeys_legacyXpubKey({
      blockchain,
      currencyCode,
      addressType,
      isTestnet,
    });

    xpubKey = Store.get<string>(legacyXpubKeyStoreKey);

    const wasStoredInLegacyFormat = Boolean(xpubKey);

    if (wasStoredInLegacyFormat) {
      Store.set(xpubKeyStoreKey, xpubKey);
      Store.set(legacyXpubKeyStoreKey, undefined);

      logXpubStoreKeyMigrated({
        blockchain: blockchain.rawValue,
        addressType: addressType.rawValue,
        walletIndex: 0n.toString(),
      });
    }
  }

  logGetXpubKeyResult({
    blockchain: blockchain.rawValue,
    addressType: addressType.rawValue,
    walletIndex: walletIndex.toString(),
    accountType,
    result: xpubKey ? 'success' : 'missing',
  });

  return xpubKey;
}

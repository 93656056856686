export type PaginationOptions = {
  offsetKey: string;
  pageSize?: number;
};

type CreatePaginationFunctionParams<Model, Options extends PaginationOptions> = {
  query: PaginationQuery<Model, Options>;
};

export type PaginationQueryParams<Options extends PaginationOptions> = {
  offset: number;
  options: Options;
  pageSize?: number;
};

type PaginationQuery<T, Options extends PaginationOptions> = (
  params: PaginationQueryParams<Options>,
) => Promise<T[]>;

// A simple function that accepts a database query and maintains an internal offset/cursor
// so that we can paginate from our client databases
export function createPaginationFunction<Model, Options extends PaginationOptions>({
  query,
}: CreatePaginationFunctionParams<Model, Options>) {
  // The offset is stored as a Map since we can keep multiple cursors based on things
  // like selected account/walletIndex
  const offsetMap = new Map<string, number>();

  async function getNextPage(options: Options): Promise<Model[]> {
    const offset = offsetMap.get(options.offsetKey) ?? 0;
    const pageSize = options.pageSize ?? 50;
    const data = await query({ pageSize, offset, options });
    offsetMap.set(options.offsetKey, offset + data.length);
    return data;
  }

  async function getRemainingPages(options: Omit<Options, 'pageSize'>): Promise<Model[]> {
    const offset = offsetMap.get(options.offsetKey) ?? 0;
    const data = await query({ offset, options: options as Options });
    offsetMap.set(options.offsetKey, offset + data.length);
    return data;
  }

  return {
    getNextPage,
    getRemainingPages,
  };
}

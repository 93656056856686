// eslint-disable-next-line no-restricted-imports
import { SessionConfig } from '@coinbase/wallet-sdk/dist/relay/walletlink/type/SessionConfig';

/**
 * Reads initial session config from localStorage.
 *
 * Write half is here:
 * https://github.cbhq.net/wallet/wallet-mobile/blob/f42500d4d7508cd244853a2c7a2b00cfbf18b7ef/workspaces/apps/extension/src/serviceworker/ExtensionWalletLinkRelay.ts#L75
 *
 * The config is fetched from the network at runtime. We'd like for it to be
 * available before everything else in extension runs. Today, it isn't known
 * whether a user is a wallet link user or not at app startup, without first
 * initializing the wallet link relay.
 *
 * See https://github.cbhq.net/wallet/wallet-mobile/blob/8045ec09a2e08dc8b3db4dcec7577ce4598e3f73/workspaces/apps/extension/src/screens/Send/steps/SignOnPhoneStep/SignOnPhoneStep.tsx#L91
 *
 * To solve this we grab state from storage. We avoid the abstractions
 * (ScopedLocalStorage and ExtensionWalletLinkRelay) because loading them
 * triggers many import time side effects, causing the extension to slow to a
 * crawl. Avoiding the side effects while using the abstraction is quite
 * complex.
 *
 * The long term solution here is to only load wallet link for wallet link
 * users and block the app while fetching session config through a cache on
 * initial load only for those users.
 */
export function fetchInitialSessionConfig(): SessionConfig | null {
  const fromStorage = localStorage.getItem(
    '-walletlink:https://www.walletlink.org:SessionConfigKey',
  );
  if (!fromStorage) {
    return null;
  }

  return JSON.parse(fromStorage);
}

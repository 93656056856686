import { objectAtomLocalStorageEffect } from 'cb-wallet-data/utils/objectAtomLocalStorageEffect';
import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { atom } from 'recoil';

import { GlobalSearchEntity, RecentSearch, SearchEntity } from './types';

const globalSearchRecentSearches = new LocalStorageStoreKey<string>(
  'globalSearchRecentSearchesKey',
);

export const globalSearchRecentSearchesAtom = atom<RecentSearch<GlobalSearchEntity>[]>({
  key: 'globalSearchRecentSearchesKey',
  default: [],
  effects: [objectAtomLocalStorageEffect(globalSearchRecentSearches)],
});

const panoramaGlobalSearchRecentSearches = new LocalStorageStoreKey<string>(
  'panoramaGlobalSearchRecentSearchesKey',
);

export const panoramaGlobalSearchRecentSearchesAtom = atom<RecentSearch<SearchEntity>[]>({
  key: 'panoramaGlobalSearchRecentSearchesKey',
  default: [],
  effects: [objectAtomLocalStorageEffect(panoramaGlobalSearchRecentSearches)],
});

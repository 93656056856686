import { useCallback } from 'react';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import {
  CombinedThemeColorPreference,
  miamiThemeColorConfigs,
  themeColorConfigs,
} from 'cb-wallet-data/stores/ThemeColors/themeColorConfigs';
import { useThemeColorPreference } from 'cb-wallet-data/stores/User/hooks/useThemeColorPreference';
import { setCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { WalletLogo } from 'wallet-cds-web/icons/WalletLogo';
import { HStack } from '@cbhq/cds-web/layout';
import { Pressable } from '@cbhq/cds-web/system';

import { RouterPathname } from ':dapp/utils/RoutesEnum';

export function Logo() {
  const router = useRouter();
  const isMiamiViceThemeEnabled = useIsFeatureEnabled('wallet_redesign_miami_vice');
  const themeColorConfigKeys = Object.keys(
    isMiamiViceThemeEnabled ? miamiThemeColorConfigs : themeColorConfigs,
  );

  const [themeColorPreference, setThemeColorPreference] = useThemeColorPreference();

  const handlePress = useCallback(() => {
    const isOnAssetsOrHomepage =
      router.asPath.startsWith(RouterPathname.ASSETS) || router.asPath === RouterPathname.HOME;
    if (isOnAssetsOrHomepage) {
      const index = themeColorConfigKeys.indexOf(themeColorPreference);
      const nextIndex = (index + 1) % themeColorConfigKeys.length;
      const name = themeColorConfigKeys[nextIndex] as CombinedThemeColorPreference;
      setCookie('themeColor', name);
      setThemeColorPreference(name);
    } else {
      router.push(RouterPathname.HOME);
    }
  }, [themeColorConfigKeys, themeColorPreference, setThemeColorPreference, router]);

  return (
    <Pressable onPress={handlePress} background="transparent" noScaleOnPress>
      <HStack testID="app-logo">
        <WalletLogo size={32} useThemeColor />
      </HStack>
    </Pressable>
  );
}

import bitcoin, { Network } from 'bitcoinjs-lib';

export function isBech32Address(address: string, network: Network): boolean {
  let decoded: { prefix: string; version: number };
  try {
    decoded = bitcoin.address.fromBech32(address);
  } catch {
    return false;
  }

  return decoded.prefix === network.bech32 && decoded.version === 0;
}

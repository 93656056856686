// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components';
import cssValue from 'wallet-cds-web/util/cssValue';

function align(p: any) {
  if (p.align === 'center') {
    return 'center';
  }

  if (p.align === 'top') {
    return 'flex-start';
  }

  return 'flex-end';
}

const Frame = styled.div<any>`
  position: relative;
  display: flex;
  flex: 0 0 100%;
  width: 100%;
  justify-content: ${align};
  align-items: ${align};
  transition: transform ${(p) => cssValue(p.transitionTime)}ms;
  ${(p) =>
    p.active &&
    `
    transform: translateX(0);
  `}
  ${(p) =>
    !p.active &&
    `
    pointer-events: none;
    position: absolute;
  `}
  ${(p) =>
    p.transitioning &&
    !p.active &&
    `
    transform: translateX(${p.direction === 'right' ? '-100%' : '100%'});
  `}
  ${(p) =>
    !p.transitioning &&
    !p.active &&
    `
    transform: translateX(${p.direction === 'right' ? '100%' : '-100%'});
    transition: none;
  `}
`;

const Container = styled.div<any>`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: ${align};
  align-items: ${align};
`;

// eslint-disable-next-line import/no-default-export
export default {
  Container,
  Frame,
};

import {
  getLastSyncedBlockheight,
  storeLastSyncedBlockheight,
} from 'cb-wallet-data/AddressHistory/utils/blockheightSyncing';
import { checkForMissingTransactions } from 'cb-wallet-data/AddressHistory/utils/UTXOTransactionAnalytics';
import { PossibleUTXOBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { getAddressesForBlockchainOfAccount } from 'cb-wallet-data/stores/Addresses/database';
import {
  getUTXOWalletTxs,
  GetUTXOWalletTxsInputResponse,
  GetUTXOWalletTxsOutputResponse,
  GetUTXOWalletTxsResponse,
} from 'cb-wallet-data/stores/Transactions/api';
import { TxHistorySyncing } from 'cb-wallet-data/stores/Transactions/interfaces/TxHistorySyncing';
import { TxOrUserOp } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import { TxOrUserOpMetadata } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOpMetadata';
import {
  TxOrUserOpMetadataKey_inputCount,
  TxOrUserOpMetadataKey_outputCount,
} from 'cb-wallet-data/stores/Transactions/models/TxOrUserOpMetadataKey';
import { TxState } from 'cb-wallet-data/stores/Transactions/models/TxState';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { MemoryStorageKey } from 'cb-wallet-store/models/MemoryStorageStoreKey';
import { StoreKey } from 'cb-wallet-store/models/StoreKey';
import { v4 } from 'uuid';

export function StoreKeys_hasSyncedUTXOTxHistory({
  currencyCode,
  network,
  selectedIndex: walletIndex = 0n,
  accountId,
}: Wallet): LocalStorageStoreKey<boolean> {
  return new LocalStorageStoreKey(
    'hasSyncedUTXOTxHistory3',
    `${currencyCode.rawValue}_${network.rawValue}_${walletIndex}_${accountId}`,
  );
}

export function StoreKeys_isSyncingUTXOTxHistory({
  currencyCode,
  network,
  selectedIndex: walletIndex = 0n,
  accountId,
}: Wallet): MemoryStorageKey<boolean> {
  return new MemoryStorageKey(
    'isSyncingUTXOTxHistory',
    `${currencyCode.rawValue}_${network.rawValue}_${walletIndex}_${accountId}`,
  );
}

type TransactionInputOutput = GetUTXOWalletTxsInputResponse | GetUTXOWalletTxsOutputResponse;

/*
 * @deprecated
 * This operation is being deprecated in favor of the address history listener
 */
export class SyncUTXOTxHistoryOperation extends TxHistorySyncing {
  readonly isSyncingTxsKey: StoreKey<boolean>;
  readonly hasSyncedTxsKey: StoreKey<boolean>;

  constructor(
    protected readonly wallet: Wallet,
    private readonly isUTXOTransactionsBlockheightSyncingEnabled: boolean,
  ) {
    super(wallet.currencyCode, wallet.network);
    this.isSyncingTxsKey = StoreKeys_isSyncingUTXOTxHistory(wallet);
    this.hasSyncedTxsKey = StoreKeys_hasSyncedUTXOTxHistory(wallet);
    this.isUTXOTransactionsBlockheightSyncingEnabled = isUTXOTransactionsBlockheightSyncingEnabled;
  }

  // TODO add unit tests to this function. Perhaps split it out into a separate function
  private parseUTXOWalletTxResponse(
    responses: GetUTXOWalletTxsResponse[],
    addressSet: Set<string>,
  ): TxOrUserOp[] {
    return responses.map(
      // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
      // eslint-disable-next-line wallet/no-anonymous-params
      (txResponse) => {
        const date = new Date(txResponse.time * 1000);
        let totalInput = 0n;
        let totalUserInput = 0n;

        txResponse.inputs.forEach(function sumTransactionInputs(input) {
          const inputValue = BigInt(input.value);
          totalInput += inputValue;

          if (addressSet.has(input.address)) {
            totalUserInput += inputValue;
          }
        });

        let totalOutput = 0n;
        let totalUserOutput = 0n;

        txResponse.outputs.forEach(function sumTransctionOutputs(output) {
          const outputValue = BigInt(output.value);
          totalOutput += outputValue;

          if (addressSet.has(output.address)) {
            totalUserOutput += outputValue;
          }
        });

        // The user sent the transaction if the user's input is greater than the user's output
        const isSent = totalUserInput > totalUserOutput;

        // The fee is the difference between all of the inputs and all of the outputs
        const totalFee = totalInput - totalOutput;

        let fee: bigint;
        if (txResponse.outputs.length > 2) {
          // If there are more than 2 outputs, that means this was a batched send
          // from an exchange and the user's share of the fee is totalFee / num outputs
          fee = totalFee / BigInt(txResponse.outputs.length);
        } else {
          fee = totalFee;
        }

        // - If the user sent the transaction the amount of the transaction is the total input value minus the
        //   total value of the user's outputs minus the fee
        // - If the user received the transaction the amount of the transaction is the total value of the
        //   user's outputs
        let amount: bigint;
        if (isSent) {
          amount = totalInput - totalUserOutput - fee;
        } else {
          amount = totalUserOutput;
        }

        const sort = (o1: TransactionInputOutput, o2: TransactionInputOutput): number => {
          if (BigInt(o2.value) < BigInt(o1.value)) {
            return -1;
          }
          if (BigInt(o2.value) > BigInt(o1.value)) {
            return 1;
          }
          return 0;
        };

        // - If the user sent the transaction, the to address is the address of the largest output that does
        //   not belong to the user
        // - If the user received the transaction, the to address is the address of the largest output that does
        //   belong to the user
        const sortedOutputs = txResponse.outputs.sort(sort);
        // If there is no address matching the filtered criteria the transaction was internal to the wallet, so
        // choose the highest output as the destination
        const toAddress = (
          sortedOutputs.find(
            // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
            // eslint-disable-next-line wallet/no-anonymous-params
            (output) => {
              if (isSent) {
                return !addressSet.has(output.address);
              }
              return addressSet.has(output.address);
            },
          ) ?? sortedOutputs[0]
        )?.address;

        // - If the user sent the transaction, the from address is the address of the largest input that
        //   belongs to the user
        // - If the user received the transaction, the from address is the address of the largest input that
        //   does not belong to the user
        const sortedInputs = txResponse.inputs.sort(sort);

        // If there is no address matching the filtered criteria the transaction was internal to the wallet, so
        // choose the highest output as the destination
        const fromAddress = (
          sortedInputs.find(
            // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
            // eslint-disable-next-line wallet/no-anonymous-params
            (input) => {
              if (isSent) {
                return addressSet.has(input.address);
              }
              return !addressSet.has(input.address);
            },
          ) ?? sortedInputs[0]
        )?.address;

        const metadata = new TxOrUserOpMetadata(
          new Map([
            [TxOrUserOpMetadataKey_inputCount, BigInt(txResponse.inputs.length)],
            [TxOrUserOpMetadataKey_outputCount, BigInt(txResponse.outputs.length)],
          ]),
        );

        return new TxOrUserOp({
          id: v4(),
          createdAt: date,
          confirmedAt: date,
          blockchain: this.wallet.blockchain,
          currencyCode: this.wallet.currencyCode,
          feeCurrencyCode: this.wallet.currencyCode,
          feeCurrencyDecimal: this.wallet.decimals,
          toAddress,
          fromAddress,
          amount,
          fee,
          state: TxState.CONFIRMED,
          metadata,
          network: this.wallet.network,
          accountId: this.wallet.accountId,
          walletIndex: this.wallet.selectedIndex ?? 0n,
          txOrUserOpHash: txResponse.hash,
          userOpHash: undefined,
          txHash: txResponse.hash,
          isSent,
          tokenName: this.wallet.displayName,
          tokenDecimal: this.wallet.decimals,
          walletId: this.wallet.id,
        });
      },
    );
  }

  async getUTXOWalletTxs({
    addresses,
    ownedAddresses,
    after = [],
    page = 0n,
    pagingToken,
    perPage = this.perPage,
    rawTransactionsRecursive = [],
    serverSyncedBlockheightRecursive = 0,
    storedBlockheight = 0,
  }: {
    addresses: string[];
    ownedAddresses: Set<string>;
    after?: TxOrUserOp[];
    page?: bigint;
    pagingToken?: string;
    perPage?: bigint;
    // The blockheight from localstorage that was last synced for this wallet
    storedBlockheight?: number;
    rawTransactionsRecursive?: GetUTXOWalletTxsResponse[];
    // The blockheight that comes back from the server in getUTXOWalletTxs
    // passed here because this function is recursive.
    serverSyncedBlockheightRecursive?: number;
  }): Promise<{
    serverSyncedBlockheight: number;
    formattedTransactions: TxOrUserOp[];
    rawTransactions: GetUTXOWalletTxsResponse[];
  }> {
    if (page > this.maximumPages) {
      return {
        serverSyncedBlockheight: serverSyncedBlockheightRecursive,
        formattedTransactions: after,
        rawTransactions: rawTransactionsRecursive,
      };
    }

    try {
      const { transactions, nextPagingToken, serverSyncedBlockheight } = await getUTXOWalletTxs({
        currencyCode: this.wallet.currencyCode,
        addresses,
        pagingToken,
        perPage,
        isTestnet: this.wallet.network.isTestnet,
        storedBlockheight,
      });

      const txs = this.parseUTXOWalletTxResponse(transactions, ownedAddresses);

      if (!nextPagingToken) {
        return {
          serverSyncedBlockheight,
          formattedTransactions: [...after, ...txs],
          rawTransactions: [...rawTransactionsRecursive, ...transactions],
        };
      }

      // we want to remove addresses from request payload if the paging token has marked the address as done
      const parsedPagingToken = JSON.parse(nextPagingToken);
      const remainingAddresses: string[] = [];

      for (const utxoAddress of addresses) {
        // if the address is not in the paging token OR
        // if the address is in the paging token but it is not marked "done"
        // => we should keep passing the address in the subsequent requests
        if (!parsedPagingToken[utxoAddress] || parsedPagingToken[utxoAddress] !== 'done') {
          remainingAddresses.push(utxoAddress);
        }
      }

      return await this.getUTXOWalletTxs({
        addresses: remainingAddresses,
        after: [...after, ...txs],
        pagingToken: nextPagingToken,
        page: page + 1n,
        perPage,
        ownedAddresses,
        storedBlockheight,
        serverSyncedBlockheightRecursive: serverSyncedBlockheight,
        rawTransactionsRecursive: [...rawTransactionsRecursive, ...transactions],
      });
    } catch (error: ErrorOrAny) {
      cbReportError({
        error,
        metadata: {
          error: (error as Error)?.message,
          blockchainSymbol: this.wallet.blockchain.rawValue,
        },
        context: 'utxo_transaction_history',
        severity: 'error',
        isHandled: false,
      });

      return {
        // If there's an error don't update the blockheight
        serverSyncedBlockheight: storedBlockheight,
        formattedTransactions: after,
        rawTransactions: rawTransactionsRecursive,
      };
    }
  }

  async getTransactions() {
    try {
      const ownedAddresses = await getAddressesForBlockchainOfAccount({
        blockchain: this.wallet.blockchain,
        accountId: this.wallet.accountId,
        network: this.wallet.network,
        currencyCode: this.wallet.currencyCode,
      });
      const ownedAddressesSet = new Set(ownedAddresses.map((address) => address.address));

      const usedAddresses = ownedAddresses.filter((address) => address.isUsed);
      const usedAddressesStr = usedAddresses.map((address) => address.address);

      const blockheight = getLastSyncedBlockheight({
        blockchainSymbol: this.wallet.blockchain.rawValue as PossibleUTXOBlockchainSymbol,
        accountId: this.wallet.accountId,
        getTransactionsBlockheight: true,
      });

      const storedBlockheight = this.isUTXOTransactionsBlockheightSyncingEnabled ? blockheight : 0;

      const { formattedTransactions, rawTransactions, serverSyncedBlockheight } =
        await this.getUTXOWalletTxs({
          addresses: usedAddressesStr,
          after: [],
          pagingToken: undefined,
          page: 0n,
          perPage: this.perPage,
          ownedAddresses: ownedAddressesSet,
          storedBlockheight,
        });

      // We can only check for missing transactions when the blockheight is 0. Thats the only time
      // the backend will return all transactions. If the blockheight is synced then it may only
      // return transactions for addresses that have updated in the blockheight caching range.
      if (storedBlockheight === 0) {
        checkForMissingTransactions({
          usedAddresses,
          transactions: rawTransactions,
          blockchainSymbol: this.wallet.blockchain.rawValue as PossibleUTXOBlockchainSymbol,
        });
      }

      storeLastSyncedBlockheight({
        blockchainSymbol: this.wallet.blockchain.rawValue as PossibleUTXOBlockchainSymbol,
        accountId: this.wallet.accountId,
        lastSyncedBlockheight: serverSyncedBlockheight,
        storeTransactionsBlockheight: true,
      });

      return formattedTransactions;
    } catch (error: ErrorOrAny) {
      cbReportError({
        error,
        metadata: {
          error: (error as Error)?.message,
          blockchainSymbol: this.wallet.blockchain.rawValue,
        },
        context: 'utxo_transaction_history',
        severity: 'error',
        isHandled: false,
      });

      return [];
    }
  }
}

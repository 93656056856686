export class EthereumGas {
  constructor(readonly value: bigint) {}

  /**
   * Bumps the estimated gas up by 1.5 times if the estimated amount is not the minimum amount.
   */
  get overEstimated(): EthereumGas {
    if (this.value > EthereumGas.minimumGasLimit.value) {
      return new EthereumGas((this.value * 15n) / 10n);
    }
    return this;
  }

  /**
   * Bumps the estimated gas up by 1.25 times if the estimated amount is not the minimum amount.
   */
  get overEstimatedReduced(): EthereumGas {
    if (this.value > EthereumGas.minimumGasLimit.value) {
      return new EthereumGas((this.value * 25n) / 20n);
    }
    return this;
  }

  /**
   * The default gas limit if gas estimation failed. This amount is dangerously high and may lead to loss of funds
   */
  static defaultGasLimit = new EthereumGas(5000000n);

  /**
   * Maximum amount of gas per transaction for Optimism chain, which is effectively the maximum gas per block since there is one tx per L2 block
   */
  static maximumOptimismTxGasLimit = new EthereumGas(15000000n);

  /**
   * The minimum gas limit.
   */
  static minimumGasLimit = new EthereumGas(21000n);
}

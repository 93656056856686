import { SignAndSubmitETHLegacyTransactionWithWeb3 } from 'cb-wallet-data/ServiceLocator/signingAndDerivation/types/SignAndSubmitETHLegacyTransaction';

import { baseSignAndSubmitETHTransaction } from '../utils/baseSignAndSubmitETHTransaction';

/**
 * Signs a legacy eth transaction using the web3 providers signTransaction method
 * signETHLegacyTransaction emits SigningMethodErrors errors, which should be handled by the caller
 *
 * see baseSignAndSubmitETHTransaction (:dapp/datalayer/web3/utils/baseSignAndSubmitETHTransaction) for more details
 */
export async function signAndSubmitETHLegacyTransaction(
  params: Parameters<SignAndSubmitETHLegacyTransactionWithWeb3>[0],
): ReturnType<SignAndSubmitETHLegacyTransactionWithWeb3> {
  return baseSignAndSubmitETHTransaction(params);
}

/**
 * Represent an UTXO signed transaction
 *
 * @property id HD Wallet unique identifier
 * @property signedTxData Transaction signed data. This is the raw transaction submitted to the blockchain
 * @property txHash Transaction hash. This is the unique identifier of this transaction on the blockchain
 * @property state Determine the transaction state
 * @property isTestnet Determine whether the transaction is on Testnet or Mainnet
 * @property currencyCode HdWallet currency code.
 * @property state Determine the transaction state using enum
 * @property notFoundCount Total number of `NotFound` status returned from blockchain
 */ import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { Persistable } from 'cb-wallet-data/persistence/Database.interface';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { SignedTx } from 'cb-wallet-data/stores/Transactions/interfaces/SignedTx';
import { TxState } from 'cb-wallet-data/stores/Transactions/models/TxState';
import { uint8ArrayTransformer } from 'cb-wallet-data/utils/dbTransformers';
import { Column, Entity, Index, PrimaryColumn } from '@cbhq/typeorm';

@Entity('utxo_signed_tx')
@Index('IDX_UTXO_BLOCKCHAIN_CURRENCY_NETWORK_STATE', [
  'blockchainStr',
  'currencyCodeStr',
  'networkStr',
  'state',
])
export class UTXOSignedTxDMO {
  @PrimaryColumn()
  id!: string;

  @Column({ type: 'varchar', transformer: uint8ArrayTransformer })
  signedTransactionData!: Uint8Array;

  @Column()
  txHash!: string;

  @Column()
  networkStr!: string;

  @Column()
  currencyCodeStr!: string;

  @Column()
  blockchainStr!: string;

  @Column({ type: 'int' })
  state!: TxState;

  @Column()
  notFoundCount!: string;
}

export class UTXOSignedTx implements SignedTx, Persistable<UTXOSignedTxDMO> {
  readonly id: string;
  readonly signedTransactionData: Buffer;
  readonly txHash: string;
  readonly network: Network;
  readonly currencyCode: CurrencyCode;
  readonly blockchain: Blockchain;
  readonly state: TxState;
  readonly notFoundCount: bigint;
  constructor({
    id,
    signedTransactionData,
    txHash,
    network,
    currencyCode,
    blockchain,
    state,
    notFoundCount,
  }: {
    id: string;
    signedTransactionData: Buffer;
    network: Network;
    notFoundCount: bigint;
  } & SignedTx) {
    this.id = id;
    this.signedTransactionData = signedTransactionData;
    this.txHash = txHash;
    this.network = network;
    this.currencyCode = currencyCode;
    this.blockchain = blockchain;
    this.state = state;
    this.notFoundCount = notFoundCount;
  }

  get asDMO(): UTXOSignedTxDMO {
    return {
      id: this.id,
      signedTransactionData: new Uint8Array(this.signedTransactionData),
      txHash: this.txHash,
      networkStr: this.network.rawValue,
      currencyCodeStr: this.currencyCode.rawValue,
      blockchainStr: this.blockchain.rawValue,
      state: this.state,
      notFoundCount: this.notFoundCount.toString(),
    };
  }

  static fromDMO(dmo: UTXOSignedTxDMO): UTXOSignedTx {
    return new UTXOSignedTx({
      id: dmo.id,
      signedTransactionData: Buffer.from(dmo.signedTransactionData),
      txHash: dmo.txHash,
      network: Network.create(dmo.networkStr)!,
      currencyCode: new CurrencyCode(dmo.currencyCodeStr),
      blockchain: new Blockchain(dmo.blockchainStr),
      state: dmo.state,
      notFoundCount: BigInt(dmo.notFoundCount),
    });
  }
}

import { useRef } from 'react';
import { useOnMount } from 'cb-wallet-data/hooks/useOnMount';

// eslint-disable-next-line @cbhq/func-declaration-in-module
let checkForAllowedUsage = (_error: Error) => {};

export function setCheckForAllowedUsageOfActiveWalletGroupId(
  callbackFunction: (error: Error) => void,
) {
  checkForAllowedUsage = callbackFunction;
}

export function useWarnDevOfActiveWalletGroupIdUsage() {
  // Hold on to where we are in the stack
  const errorRef = useRef(new Error());
  useOnMount(() => {
    checkForAllowedUsage(errorRef.current);
  });
}

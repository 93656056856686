import { validateAddress } from 'wallet-engine-signing/blockchains/UTXO/addressValidation';
import { postJSON, UTXOChains } from 'wallet-engine-signing/blockchains/UTXO/indexer';

export type AddressServerResponseOk = {
  balance: number;
  is_used: boolean;
};

export type AddressServerResponseError = {
  error: string;
};

export type AddressServerResponse = AddressServerResponseOk | AddressServerResponseError;

type GetBalancesParams = {
  addresses: string[];
  blockchainSymbol: UTXOChains;
  testnet: boolean;
  lastSyncedBlockheight?: number;
};

export type AddressResponse = {
  address: string;
  balance: bigint;
  isUsed: boolean;
};

type AddressesResponse = Record<string, AddressResponse>;

export type GetBalancesResponse = {
  addresses: AddressesResponse;
  syncedBlockheight: number;
  failedAddresses: string[];
  invalidAddresses: string[];
};

export async function getBalances({
  blockchainSymbol,
  addresses,
  testnet,
  lastSyncedBlockheight = 0,
}: GetBalancesParams): Promise<GetBalancesResponse> {
  const failedAddresses: string[] = [];
  const invalidAddresses: string[] = [];

  // validate all addresses before fetching UTXOs
  for (const address of addresses) {
    try {
      validateAddress({
        blockchainSymbol,
        address,
        testnet,
      });
    } catch (e) {
      invalidAddresses.push(address);
    }
  }

  const response = await makeGetBalancesRequest({
    blockchainSymbol,
    addresses,
    testnet,
    lastSyncedBlockheight,
  });

  const { synced_height: syncedBlockheight, result: addressBalances } =
    response as GetBalanceFullSyncResponse;

  const { no_change: noChange } = response as GetBalancesNoChangeResponse;

  if (noChange) {
    return {
      addresses: {},
      syncedBlockheight,
      failedAddresses: [],
      invalidAddresses: [],
    };
  }

  const requestAddressesSet = new Set(addresses);
  const addressesResponse: AddressesResponse = {};

  Object.entries(addressBalances || {}).forEach(([address, history]) => {
    // Don't update the stored address if there is an error
    // and reset the synced blockheight to 0 so it will retry at
    // the next interval
    if (
      !history ||
      (history as AddressServerResponseError).error ||
      (history as AddressServerResponseOk).balance === undefined
    ) {
      failedAddresses.push(address);
      return;
    }

    if (requestAddressesSet.has(address)) {
      requestAddressesSet.delete(address);
    }

    const { balance, is_used: isUsed } = history as AddressServerResponseOk;

    addressesResponse[address] = {
      address,
      isUsed,
      balance: BigInt(balance),
    };
  });

  // We only report failed addresses when the last synced blockheight is 0 because this
  // is the only time the server will return all of the requested addresses. Otherwise the
  // server will only return addresses that changed in blocks since lastSyncedBlockheight.
  if (addressBalances && requestAddressesSet.size && lastSyncedBlockheight === 0) {
    failedAddresses.push(...requestAddressesSet);
  }

  return {
    addresses: addressesResponse,
    syncedBlockheight: failedAddresses.length ? lastSyncedBlockheight : syncedBlockheight,
    failedAddresses,
    invalidAddresses,
  };
}

type GetBalancesRequestParams = {
  blockchainSymbol: UTXOChains;
  addresses: string[];
  testnet: boolean;
  lastSyncedBlockheight: number;
};

type GetBalanceFullSyncResponse = {
  result: Record<string, AddressServerResponse>;
  synced_height: number;
};

type GetBalancesNoChangeResponse = {
  no_change: boolean;
  synced_height: number;
};

async function makeGetBalancesRequest({
  blockchainSymbol,
  addresses,
  testnet,
  lastSyncedBlockheight,
}: GetBalancesRequestParams) {
  const res = await postJSON<GetBalanceFullSyncResponse | GetBalancesNoChangeResponse>({
    blockchainSymbol,
    endpoint: '/v2/getBalances',
    // eslint-disable-next-line camelcase
    body: { addresses, testnet, synced_height: lastSyncedBlockheight },
  });

  if (!res.body) {
    throw new Error(`Missing response body from ${blockchainSymbol} getBalances request`);
  }

  return res.body;
}

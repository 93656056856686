import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';
import { injected } from '@wagmi/core';

import { makeEthereumNetwork, makeSolanaNetwork } from ':dapp/connection/networks';
import { WalletProviderInfo } from ':dapp/connection/types';
import { WalletConnectorManager } from ':dapp/connection/WalletConnectorManager';

import { setupConnector } from '../wagmi';

const solanaAdapter = new PhantomWalletAdapter();
const evmConnector = setupConnector(injected({ target: 'phantom' }));

const networks = [makeEthereumNetwork(evmConnector), makeSolanaNetwork(solanaAdapter)];

/**
 * Never update these ids. They are used to identify the wallet provider in the
 * database and filter out duplicate connectors from EIP6963. Removing or changing
 * will break panorama for existing users.
 */
export const PHANTOM_ID = 'app.phantom';

export const PHANTOM: WalletProviderInfo = {
  id: PHANTOM_ID,
  connectorManager: new WalletConnectorManager(PHANTOM_ID, networks),
  name: 'Phantom',
  image: 'https://go.wallet.coinbase.com/static/wallets/phantom.svg',
  enabled: true,
  networks,
};

import {
  PossibleUTXOBlockchainSymbol,
  possibleUTXOBlockchainSymbols,
} from 'cb-wallet-data/chains/blockchains';
import {
  StoreKeys_lastSyncedBlockheightBalances,
  StoreKeys_lastSyncedBlockheightTransactions,
} from 'cb-wallet-data/stores/Wallets/storeKeys';
import { Store } from 'cb-wallet-store/Store';

type StoredBlockheights = Record<string, number>;

function getStoredBlockheightKey(
  blockchainSymbol: PossibleUTXOBlockchainSymbol,
  accountId: string,
) {
  const walletIndex = 0;
  return `${accountId}-${walletIndex}-${blockchainSymbol}`;
}

type GetLastSyncedBlockheightParams = {
  blockchainSymbol: PossibleUTXOBlockchainSymbol;
  accountId: string;
  getTransactionsBlockheight: boolean;
};

export function getLastSyncedBlockheight({
  blockchainSymbol,
  accountId,
  getTransactionsBlockheight,
}: GetLastSyncedBlockheightParams): number {
  const storeKey = getTransactionsBlockheight
    ? StoreKeys_lastSyncedBlockheightTransactions
    : StoreKeys_lastSyncedBlockheightBalances;

  const blockheights = Store.get<StoredBlockheights>(storeKey) || ({} as StoredBlockheights);
  const blockheightKey = getStoredBlockheightKey(blockchainSymbol, accountId);
  return blockheights[blockheightKey] ?? 0;
}

type StoreLastSyncedBlockheightParams = {
  blockchainSymbol: PossibleUTXOBlockchainSymbol;
  accountId: string;
  lastSyncedBlockheight: number;
  storeTransactionsBlockheight: boolean;
};

export function storeLastSyncedBlockheight({
  blockchainSymbol,
  accountId,
  lastSyncedBlockheight,
  storeTransactionsBlockheight,
}: StoreLastSyncedBlockheightParams) {
  const storeKey = storeTransactionsBlockheight
    ? StoreKeys_lastSyncedBlockheightTransactions
    : StoreKeys_lastSyncedBlockheightBalances;

  const blockheights = Store.get<StoredBlockheights>(storeKey) || {};
  const blockheightKey = getStoredBlockheightKey(blockchainSymbol, accountId);

  Store.set(storeKey, {
    ...blockheights,
    [blockheightKey]: lastSyncedBlockheight,
  });
}

export function resetSyncedBlockheights(
  accountId: string,
  blockheightToReset: 'balances' | 'transactions',
) {
  possibleUTXOBlockchainSymbols.forEach(function resetBlockheights(blockchainSymbol) {
    storeLastSyncedBlockheight({
      blockchainSymbol,
      accountId,
      lastSyncedBlockheight: 0,
      storeTransactionsBlockheight: blockheightToReset === 'transactions',
    });
  });
}

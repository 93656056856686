import { isAddress } from 'viem';

import { SigningMethodError, SigningMethodErrorType } from '../SigningMethodError';

export function getEthereumAddressFromAccountId(accountId: string) {
  const parts = accountId.split('/');
  if (parts[2]) {
    const address = parts[2];
    if (isAddress(address)) {
      return address;
    }
    throw new SigningMethodError(SigningMethodErrorType.Generic, `invalid address: ${address}`);
  }
  throw new SigningMethodError(SigningMethodErrorType.Generic, `invalid account id: ${accountId}`);
}

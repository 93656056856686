import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import {
  ChallengeCompleteTypeError,
  useMutateChallengeComplete,
} from 'cb-wallet-data/hooks/Gamification/useMutateChallengeComplete';
import { useDebouncedCallback } from 'cb-wallet-data/hooks/useDebouncedCallback';
import { usePageOutageContext } from 'cb-wallet-data/stores/PageOutage/PageOutageProvider';
import Image from 'next/image';
import { Button } from 'wallet-cds-web/components/Button';
import { useSpectrum, useToggler } from '@cbhq/cds-common';
import { Banner } from '@cbhq/cds-web/banner/Banner';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { ThemeProvider } from '@cbhq/cds-web/system';
import { Tag } from '@cbhq/cds-web/tag/Tag';
import { TextBody } from '@cbhq/cds-web/typography';

import { CardWithImageProps, imageClass } from ':dapp/components/Card/CardWithImage';
import { HomebaseModal } from ':dapp/components/HomebaseModal/HomebaseModal';
import { setChallengeToClaim } from ':dapp/hooks/useAutoClaimChallenges';
import { usePreferredAccount, WalletPreferenceFeature } from ':dapp/hooks/usePreferredAccount';
import { OCSChallengeCard } from ':dapp/pages/ocs/types/ocsResponseTypes';

export type ModalContentType = CardWithImageProps;

const messages = defineMessages({
  tagLabel: {
    defaultMessage: '{emoji} Earns {challengePoints} pts',
    description: 'Label for tag content for each challenge',
  },
  successMessage: {
    defaultMessage: 'Congrats you got {points} points for this challenge!',
    description: 'Claimed message for challenge completion',
  },
  claimedMessage: {
    defaultMessage: 'You already earned points for this challenge',
    description: 'Success message for challenge completion',
  },
});

export type ExperienceModalContextValue = {
  isExperienceModalOpen: boolean;
  openExperienceModal: (newContent: OCSChallengeCard) => void;
  closeExperienceModal: () => void;
  activeContent?: OCSChallengeCard;
  setContent?: (content: OCSChallengeCard) => void;
};

const INITIAL_STATES: ExperienceModalContextValue = {
  isExperienceModalOpen: false,
  openExperienceModal: () => {},
  closeExperienceModal: () => {},
  activeContent: undefined,
  setContent: () => {},
};

export const ExperienceModalContext = createContext<ExperienceModalContextValue>(INITIAL_STATES);

type ExperienceModalProviderProps = { children: React.ReactNode };

const bannerStyle = { borderLeft: '3px solid #578BFA' };

export function ExperienceModalProvider({ children }: ExperienceModalProviderProps) {
  const [isExperienceModalOpen, { toggleOn, toggleOff }] = useToggler(false);
  const [activeContent, setActiveContent] = useState<OCSChallengeCard | undefined>(undefined);
  const [bannerMessage, setBannerMessage] = useState<string | null>(null);
  const { setGamificationApiIssue } = usePageOutageContext();
  const { completeChallenge, isLoading } = useMutateChallengeComplete(setGamificationApiIssue);
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();
  const spectrum = useSpectrum();

  useEffect(function resetBannerMessage() {
    return () => {
      setBannerMessage(null);
    };
  }, []);

  const { account } = usePreferredAccount({
    blockchainSymbol: 'ETH',
    feature: WalletPreferenceFeature.OCS,
  });

  const handleChallemgeComplete = useCallback(
    (content: OCSChallengeCard) => {
      if (!account || !account.primaryAddressForDapp) {
        throw new Error('No account found for OCS');
      }

      // 2 is actually marked as completed.
      if (content.hasCompleted === 2 || content.points === 0) {
        return;
      }

      completeChallenge(
        {
          challengeId: content.id,
          walletAddress: account.primaryAddressForDapp,
        },
        {
          onSuccess: (response: unknown) => {
            queryClient.invalidateQueries({
              queryKey: ['getContentByTrendingUnauth'],
            });
            queryClient.invalidateQueries({
              queryKey: ['game_userProfileState'],
            });

            if (
              (response as ChallengeCompleteTypeError)?.success === true &&
              (response as ChallengeCompleteTypeError)?.message === 'challenge-completed' &&
              content.points > 0
            ) {
              setBannerMessage(formatMessage(messages.successMessage, { points: content.points }));
            } else {
              setChallengeToClaim(content.id, account.primaryAddressForDapp);
            }
          },
        },
      );
    },
    [account, completeChallenge, formatMessage, queryClient],
  );

  const debouncedHandleChallengeComplete = useDebouncedCallback(handleChallemgeComplete, 500);

  const dismissModal = useCallback(() => {
    toggleOff();

    setActiveContent(undefined);
    setBannerMessage(null);
  }, [toggleOff]);

  const handleCTAClick = useCallback(() => {
    if (activeContent?.targetUrl) {
      const url = new URL(activeContent.targetUrl);
      if (activeContent?.targetUrl.includes('wallet.coinbase.com/nft/mint')) {
        url.searchParams.append('challengeId', activeContent?.id);
      }
      window.open(url.toString(), '_blank');
    }
    dismissModal();
  }, [activeContent?.targetUrl, dismissModal, activeContent?.id]);

  const openModal = useCallback(
    (newContent: OCSChallengeCard) => {
      setActiveContent(newContent);

      if (newContent.hasCompleted === 2 && newContent.points !== 0) {
        setBannerMessage(formatMessage(messages.claimedMessage, { points: newContent.points }));
      }

      debouncedHandleChallengeComplete(newContent);
      toggleOn();
    },
    [debouncedHandleChallengeComplete, formatMessage, toggleOn],
  );

  const value = useMemo(
    () => ({
      isExperienceModalOpen,
      openExperienceModal: openModal,
      closeExperienceModal: dismissModal,
      activeContent,
      setActiveContent,
    }),
    [isExperienceModalOpen, openModal, dismissModal, activeContent],
  );

  const banner = useMemo(() => {
    if (!bannerMessage) return null;

    return (
      <Box>
        <Banner
          title={bannerMessage}
          variant="promotional"
          startIcon="trophy"
          bordered={false}
          borderRadius="roundedNone"
          style={bannerStyle}
          testID="experience-banner"
        >
          {' '}
        </Banner>
      </Box>
    );
  }, [bannerMessage]);

  const modal = useMemo(() => {
    if (!activeContent) return null;

    return (
      <HomebaseModal
        title={activeContent.title}
        onClose={dismissModal}
        visible={isExperienceModalOpen}
        testID="experience-modal"
        contentSpacingBottom={2}
        contentOverflow="auto"
        footer={
          <VStack width="100%" spacingTop={2} spacingHorizontal={3}>
            <ThemeProvider spectrum="light">
              <Button
                loading={isLoading}
                onPress={handleCTAClick}
                variant={spectrum === 'dark' ? 'secondary' : 'primary'}
                block
                testID="experience-cta-button"
              >
                {activeContent.ctaText}
              </Button>
            </ThemeProvider>
          </VStack>
        }
      >
        <VStack gap={2}>
          <Box>
            <Image
              src={activeContent.imageUrl}
              alt={activeContent.title}
              width={410}
              height={200}
              className={imageClass}
            />
          </Box>
          <ThemeProvider spectrum="light">
            <Tag colorScheme="yellow" intent="promotional" maxWidth="150px">
              {formatMessage(messages.tagLabel, {
                challengePoints: activeContent.points,
                emoji: '💰️',
              })}
            </Tag>
          </ThemeProvider>
          <TextBody as="span" color="foregroundMuted">
            {activeContent.fullDescription}
          </TextBody>
          {banner}
        </VStack>
      </HomebaseModal>
    );
  }, [
    activeContent,
    banner,
    dismissModal,
    formatMessage,
    handleCTAClick,
    isExperienceModalOpen,
    isLoading,
    spectrum,
  ]);

  return (
    <ExperienceModalContext.Provider value={value}>
      {children}
      <VStack overflow="auto">{modal}</VStack>
    </ExperienceModalContext.Provider>
  );
}

export function useExperienceModalContext() {
  return useContext(ExperienceModalContext);
}

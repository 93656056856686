import { useMemo } from 'react';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { miamiDarkThemeColorConfigs, MiamiThemeColorPreference } from 'cb-wallet-data/stores/ThemeColors/themeColorConfigs';
import { useAppearance } from 'cb-wallet-data/stores/User/hooks/useAppearance';
import { useThemeColorPreference } from 'cb-wallet-data/stores/User/hooks/useThemeColorPreference';
import { GradientButton, GradientButtonProps } from 'wallet-cds-web/components/GradientButton';
import { Button as CDSButton, ButtonProps as CDSButtonProps } from '@cbhq/cds-web/buttons';
import { ThemeProvider } from '@cbhq/cds-web/system';
type GradientStop = {
  start: string;
  end: string;
};
const miamiThemeColorConfigs = {
  blue: {
    primary: 'blue60',
    primaryWash: 'blue0',
    primaryForeground: 'gray100'
  },
  pink: {
    primary: 'pink60',
    primaryWash: 'pink0',
    primaryForeground: 'gray100'
  },
  purple: {
    primary: 'purple70',
    primaryWash: 'purple0',
    primaryForeground: 'gray100'
  },
  green: {
    primary: 'green70',
    primaryWash: 'green0',
    primaryForeground: 'gray100'
  },
  orange: {
    primary: 'orange70',
    primaryWash: 'orange0',
    primaryForeground: 'gray100'
  },
  yellow: {
    primary: 'yellow70',
    primaryWash: 'yellow0',
    primaryForeground: 'gray100'
  }
} as const;

/**
 * An extension of the existing GradientButton component that allows rendering button with Gradient background.
 * */

export function Button({
  children,
  ...rest
}: CDSButtonProps) {
  const [colorPreference] = useThemeColorPreference();
  const isMiamiViceThemeEnabled = useIsFeatureEnabled('wallet_redesign_miami_vice');
  const appearance = useAppearance();
  const {
    variant
  } = rest;
  const gradientClass = useMemo(() => {
    switch (colorPreference) {
      case 'blue':
        return "gsgwok8";
      case 'orange':
        return "gdw795c";
      case 'yellow':
        return "g91yxta";
      case 'purple':
        return "ga1ktnd";
      case 'pink':
        return "g1aq19qc";
      case 'green':
        return "g13sni7q";
      default:
        return "g1ldz4fp";
    }
  }, [colorPreference]);
  const palette = useMemo(() => {
    return appearance === 'light' ? miamiThemeColorConfigs[colorPreference as MiamiThemeColorPreference] : miamiDarkThemeColorConfigs[colorPreference as MiamiThemeColorPreference];
  }, [appearance, colorPreference]);
  if (isMiamiViceThemeEnabled && (!variant || variant === 'primary')) {
    return <ThemeProvider palette={palette}>
        <GradientButton gradientBackgroundClass={gradientClass} {...rest as GradientButtonProps}>
          {children}
        </GradientButton>
      </ThemeProvider>;
  }
  return <CDSButton {...rest}>{children}</CDSButton>;
}

require("./Button.linaria.module.css!=!../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Button.tsx");
import { ethers } from 'ethers';

const { keccak256, toUtf8Bytes } = ethers.utils;

export const transferWithAuthorizationTypeHash = keccak256(
  toUtf8Bytes(
    'TransferWithAuthorization(address from,address to,uint256 value,uint256 validAfter,uint256 validBefore,bytes32 nonce)',
  ),
);

export const receiveWithAuthorizationTypeHash = keccak256(
  toUtf8Bytes(
    'ReceiveWithAuthorization(address from,address to,uint256 value,uint256 validAfter,uint256 validBefore,bytes32 nonce)',
  ),
);

export const cancelAuthorizationTypeHash = keccak256(
  toUtf8Bytes('CancelAuthorization(address authorizer,bytes32 nonce)'),
);

export const permitTypeHash = keccak256(
  toUtf8Bytes('Permit(address owner,address spender,uint256 value,uint256 nonce,uint256 deadline)'),
);

export function getTypeHash(functionDefinition: string) {
  return keccak256(toUtf8Bytes(functionDefinition));
}

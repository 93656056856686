import { useMemo } from 'react';

import { useQueryCapabilitiesForAccountIds } from './useQueryCapabilitiesForAccountIds';

/**
 * Hook for getting capabilities for account id.
 *
 * The result will get stale after 30 seconds.
 * On error, react query will retry 3 times with a delay / backoff, and then again 30 seconds later.
 * @param accountId
 * @returns
 */
export function useQueryCapabilitiesForAccountId(accountId: string | undefined) {
  const { data: capabilitiesByAccount } = useQueryCapabilitiesForAccountIds(
    accountId ? [accountId] : [],
  );

  return useMemo(
    () => (accountId ? capabilitiesByAccount?.[accountId] : undefined),
    [accountId, capabilitiesByAccount],
  );
}

export const LOW_BALANCE_THRESHOLD = 0.012;

// small balance: user setting to hide balances below $1.00
export const SMALL_BALANCE_THRESHOLD = 1;

/**
 * The legacy mobile apps create 10 ETH addresses at indexes 0-9. To continue to support
 * these addresses we create and store them. The balance refreshing logic below will refresh
 * balances for these addresses and determine which can be hidden/shown in the multi-wallet interface.
 */
export const LAST_LEGACY_INDEX = 9n;

/**
 * Server endpoint used to create additional ETH wallets beyond the initial amount created
 * during onboarding.
 */
export const CREATE_WALLETS_ENDPOINT = 'createWallets';

/**
 * Header message use with each address signature sent to CREATE_WALLETS_ENDPOINT.
 */
export const CREATE_WALLETS_HEADER = 'Coinbase Wallet Address Registration';

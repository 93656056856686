import { useCallback } from 'react';
import debounce from 'lodash/debounce';

/**
 * Note: This was borrowed from coinbase-www
 *
 * This hook debounces a callback's execution, can be used
 * as an alternative to useDebounce and removes references to useRef
 * which has shaky compatibility with React Concurrent Mode.
 *
 * NOTE: The callback that's passed in MUST be memoized with useCallback
 * in order to ensure that the callback is never outdated.
 *
 * How to use:
 * const someAction = useCallback((...args) => {
 *  fetch(...)
 * }, [deps])
 *
 * const debouncedAction = useDebouncedCallback(someAction)
 *
 * ...
 *
 * debouncedAction(...args)
 */

export function useDebouncedCallback<P extends unknown[], R>(
  callback: (...params: P) => R,
  delay = 400,
) {
  // eslint-disable-next-line wallet/exhaustive-deps
  const runDebounce = useCallback(
    debounce((handler: () => R) => handler(), delay),
    [],
  );

  return useCallback(
    function debouncedCallback(...params: P): R | undefined {
      return runDebounce(() => callback(...params));
    },
    [runDebounce, callback],
  );
}

import { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { SettingCategoryConfig } from 'cb-wallet-data/stores/SettingsRedesign/types';
import { useRouter } from 'next/router';

import { SETTING_CATEGORY_INNER_SPACING } from ':dapp/pages/settings/constants';
import { useSearchContext } from ':dapp/providers/SearchProvider';
import { SettingRoutesEnum } from ':dapp/utils/RoutesEnum';

const messages = defineMessages({
  title: {
    defaultMessage: 'Settings',
    description: 'The Setting category title',
  },
});

export function useSettingsPageConfig(): SettingCategoryConfig {
  const { formatMessage } = useIntl();
  const { toggleSearchOff } = useSearchContext();
  const router = useRouter();

  const onPress = useCallback(() => {
    router.push(SettingRoutesEnum.SETTINGS);
    toggleSearchOff();
  }, [router, toggleSearchOff]);

  const settingConfig = useMemo<SettingCategoryConfig>(() => {
    return {
      title: formatMessage(messages.title),
      media: undefined,
      accessory: 'arrow',
      testID: 'settings',
      navigationPath: SettingRoutesEnum.SETTINGS,
      innerSpacing: SETTING_CATEGORY_INNER_SPACING,
      iconName: 'gear',
      onPress,
    };
  }, [formatMessage, onPress]);

  return settingConfig;
}

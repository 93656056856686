import { ETHEREUM_PREFIX } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { SOLANA_PREFIX } from 'cb-wallet-data/chains/AccountBased/Solana/utils/chain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { SOLANA_CHAIN_ID } from 'cb-wallet-data/stores/Collection/constants';
import {
  Collectible,
  CollectibleChainEnabledSetting,
  CollectibleInfo,
  CollectibleLink,
  CollectibleTransactionCombined,
  CollectionsSortMethodType,
  CollectionsSortOrderType,
  PortfolioCollectible,
} from 'cb-wallet-data/stores/Collection/types';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { stringifyMap } from 'cb-wallet-data/utils/mapUtils';
import memoize from 'lodash/memoize';

import { ViewNFTChainEnabledConfig } from './hooks/useViewNFTChainEnabledConfig';
import {
  BASE_COLLECTION_CHAIN_ID,
  ETHEREUM_COLLECTION_CHAIN_ID,
  GNOSIS_COLLECTION_CHAIN_ID,
  OPTIMISM_COLLECTION_CHAIN_ID,
  POLYGON_COLLECTION_CHAIN_ID,
} from './api';

const MINT_ADDRESS = '0x0000000000000000000000000000000000000000';
export type LINK_TYPE =
  | 'Opensea'
  | 'Rarible'
  | 'Etherscan'
  | 'Coinbase NFT'
  | 'Polygonscan'
  | 'Solscan'
  | 'MagicEden'
  | 'Gnosisscan'
  | 'Optimistic Etherscan'
  | 'Basescan';

export function isSendTransaction(transaction: CollectibleTransactionCombined) {
  return transaction.fromAddress && transaction.fromAddress !== MINT_ADDRESS;
}

export function getPlatformName({
  platform,
  variant = 'full',
}: {
  platform: string;
  variant?: 'short' | 'full';
}): string {
  switch (platform.toUpperCase()) {
    case 'OPENSEA':
      return 'OpenSea';
    case 'RARIBLE':
      return 'Rarible';
    case 'COINBASE':
    case 'CBNFT':
      if (variant === 'full') {
        return 'Coinbase NFT';
      }
      return 'Coinbase';
    case 'LOOKSRARE':
      return 'LooksRare';
    case 'X2Y2':
      return 'X2Y2';
    default:
      return 'Reservoir';
  }
}

export function getCollectibleTitle(collectibleInfo: CollectibleInfo) {
  if (collectibleInfo.tokenName?.length > 0) {
    return collectibleInfo.tokenName;
  }

  if (collectibleInfo.collectionName?.length > 0) {
    return `${collectibleInfo.collectionName} #${collectibleInfo.tokenId}`;
  }

  return collectibleInfo.tokenId;
}

type Props = {
  collectible: Collectible;
  chainId: string;
  includeMarketplaceLinks: boolean;
};

export function getCollectibleLinks({ collectible, includeMarketplaceLinks }: Props) {
  const marketplaceUrls = collectible.isPending
    ? collectible.pendingTokenDetails?.marketplaceUrls
    : collectible.marketplaceUrls;

  const blockchainExplorerUrl = collectible.isPending
    ? collectible.pendingTokenDetails?.blockchainExplorerUrl
    : collectible.blockchainExplorerUrl;

  if (!includeMarketplaceLinks) {
    return blockchainExplorerUrl ? [blockchainExplorerUrl] : [];
  }

  const array: CollectibleLink[] = [];

  const filteredMarketplaceUrls =
    marketplaceUrls?.filter((marketplaceUrl) => {
      return !!marketplaceUrl;
    }) || [];

  array.push(...filteredMarketplaceUrls);

  if (blockchainExplorerUrl) {
    array.push(blockchainExplorerUrl);
  }

  return array;
}

// Filter wallets by blockchain, network and currency according to its chain ID
export function getNftWalletByChainId(wallets: Wallet[] | undefined, chainId: string) {
  const chainIDBigInt = BigInt(chainId);

  const prefix = chainId === SOLANA_CHAIN_ID ? SOLANA_PREFIX : ETHEREUM_PREFIX;

  const network = Network.fromChainId({ chainPrefix: prefix, chainId: chainIDBigInt });
  const networkCurrencyCode = network.asChain()?.baseAssetCurrencyCode;

  const currencyCode = networkCurrencyCode
    ? new CurrencyCode(networkCurrencyCode)
    : CurrencyCode.ETH;

  return wallets?.find(
    (w) =>
      Network.isEqual(network, w.network) && CurrencyCode.isEqual(w.currencyCode, currencyCode),
  );
}

export type ChainIdFromConfigProps = {
  chainConfig: ViewNFTChainEnabledConfig;
  ethAddress?: string;
  solAddress?: string;
};

function killSwitchCacheKeyResolver({
  chainConfig,
  ethAddress,
  solAddress,
}: ChainIdFromConfigProps) {
  const chainConfigStringified = stringifyMap(chainConfig);
  return JSON.stringify({ chainConfigStringified, ethAddress, solAddress });
}

export const getChainSettingsFromKillswitchedChainConfig = memoize(
  function getChainSettingsFromKillswitchedChainConfig({
    chainConfig,
    ethAddress,
    solAddress,
  }: ChainIdFromConfigProps) {
    const chainIdList = [];

    if (chainConfig.get('Ethereum')?.enabled && !!ethAddress)
      chainIdList.push({ chainId: ETHEREUM_COLLECTION_CHAIN_ID.toString(), address: ethAddress });
    if (chainConfig.get('Base')?.enabled && !!ethAddress)
      chainIdList.push({ chainId: BASE_COLLECTION_CHAIN_ID.toString(), address: ethAddress });
    if (chainConfig.get('Optimism')?.enabled && !!ethAddress)
      chainIdList.push({ chainId: OPTIMISM_COLLECTION_CHAIN_ID.toString(), address: ethAddress });
    if (chainConfig.get('Gnosis')?.enabled && !!ethAddress)
      chainIdList.push({ chainId: GNOSIS_COLLECTION_CHAIN_ID.toString(), address: ethAddress });
    if (chainConfig.get('Polygon')?.enabled && !!ethAddress)
      chainIdList.push({ chainId: POLYGON_COLLECTION_CHAIN_ID.toString(), address: ethAddress });
    if (chainConfig.get('Solana')?.enabled && !!solAddress)
      chainIdList.push({ chainId: SOLANA_CHAIN_ID.toString(), address: solAddress });

    return chainIdList;
  },
  killSwitchCacheKeyResolver,
);

function backendCacheKeyResolver({ chainConfig, ethAddress, solAddress }: ChainIdFromConfigProps) {
  const chainConfigStringified = stringifyMap(chainConfig);
  return JSON.stringify({ chainConfigStringified, ethAddress, solAddress });
}

export const getChainSettingsFromBackendBasedChainConfig = memoize(
  function getChainSettingsFromBackendBasedChainConfig({
    chainConfig,
    ethAddress,
    solAddress,
  }: ChainIdFromConfigProps) {
    return Array.from(chainConfig.keys())
      .filter(function filterEnabledChains(key: string) {
        const chainEnabledSetting = chainConfig.get(key);
        if (!chainEnabledSetting) {
          return false;
        }

        const { enabled, addressType } = chainEnabledSetting;
        return enabled && (addressType === 'ADDRESS_TYPE_EVM' ? !!ethAddress : !!solAddress);
      })
      .map(function mapToAddressChainIdPairing(key: string) {
        const chainEnabledSetting = chainConfig.get(key)!;
        return {
          address: chainEnabledSetting.addressType === 'ADDRESS_TYPE_EVM' ? ethAddress : solAddress,
          chainId: chainEnabledSetting.chainId,
        };
      });
  },
  backendCacheKeyResolver,
);

export const EnabledSettingForNFTChain = {
  ETHEREUM: {
    networkName: 'Ethereum',
    chainId: ETHEREUM_COLLECTION_CHAIN_ID.toString(),
    addressType: 'ADDRESS_TYPE_EVM',
    enabled: true,
    chainImageUrl: 'https://go.wallet.coinbase.com/static/chains/ethereum-logo.png',
  } as CollectibleChainEnabledSetting,
  BASE: {
    networkName: 'Base',
    chainId: BASE_COLLECTION_CHAIN_ID.toString(),
    addressType: 'ADDRESS_TYPE_EVM',
    enabled: true,
    chainImageUrl: 'https://go.wallet.coinbase.com/static/chains/base-logo.png',
  } as CollectibleChainEnabledSetting,
  OPTIMISM: {
    networkName: 'Optimism',
    chainId: OPTIMISM_COLLECTION_CHAIN_ID.toString(),
    addressType: 'ADDRESS_TYPE_EVM',
    enabled: true,
    chainImageUrl: 'https://go.wallet.coinbase.com/static/chains/optimism-logo.png',
  } as CollectibleChainEnabledSetting,
  GNOSIS: {
    networkName: 'Gnosis',
    chainId: GNOSIS_COLLECTION_CHAIN_ID.toString(),
    addressType: 'ADDRESS_TYPE_EVM',
    enabled: true,
    chainImageUrl: 'https://go.wallet.coinbase.com/static/chains/gnosis-logo.png',
  } as CollectibleChainEnabledSetting,
  SOLANA: {
    networkName: 'Solana',
    chainId: SOLANA_CHAIN_ID.toString(),
    addressType: 'ADDRESS_TYPE_SOL',
    enabled: true,
    chainImageUrl: 'https://go.wallet.coinbase.com/static/chains/solana-logo.png',
  } as CollectibleChainEnabledSetting,
  POLYGON: {
    networkName: 'Polygon',
    chainId: POLYGON_COLLECTION_CHAIN_ID.toString(),
    addressType: 'ADDRESS_TYPE_EVM',
    enabled: true,
    chainImageUrl: 'https://go.wallet.coinbase.com/static/chains/polygon-logo.png',
  } as CollectibleChainEnabledSetting,
};

type GetIconNameForCollectionsSortProps = {
  sortMethod: CollectionsSortMethodType;
  sortOrder: CollectionsSortOrderType;
};

export function getIconNameForCollectionsSort({
  sortMethod,
  sortOrder,
}: GetIconNameForCollectionsSortProps) {
  if (sortMethod === 'recently_added') {
    return sortOrder === 'asc' ? 'arrowUp' : 'arrowDown';
  }

  return sortOrder === 'asc' ? 'arrowDown' : 'arrowUp';
}

export function isAggregatedOE721Collectible({
  oe721Count,
}: Pick<PortfolioCollectible, 'oe721Count'>) {
  const oe721CountNumber = oe721Count === undefined ? 0 : Number(oe721Count);

  // the NFT is an aggregation of OE721s if the count is > 1 or is defined and NaN
  // NaN represents that we know there are multiple OE721s but we don't know the exact count
  return oe721CountNumber > 1 || isNaN(oe721CountNumber);
}

import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';

import { cbReportError } from '../errors/reportError';

/**
 * ProviderLocator creates a map for account ids to wallet connection providers
 * This is used to get the correct provider for an account when signing
 * messages/transactions
 */
export class ProviderLocator {
  public _providers = new Map<Account['id'], string>();

  /**
   * registers a provider for an account
   */
  register(accountId: Account['id'], provider: string) {
    if (!this._providers.has(accountId)) {
      this._providers.set(accountId, provider);
    } else if (this._providers.get(accountId) !== provider) {
      cbReportError({
        error: new Error(
          `tried to add different provider for account, existing ${this._providers.get(
            accountId,
          )} new ${provider}`,
        ),
        context: 'web3-provider-connection',
        severity: 'error',
        isHandled: true,
      });
    }
    // This shouldn't happen, the processWalletConnectionResponse call should
    // throw a ProcessWalletConnectionResponseError with an ADDRESS_ALREADY_IMPORTED
    // error. We use a sync approach to register providers so a noop here is the best approach
  }

  /**
   * unregisters a provider for an account, used during disconnect
   */
  unregister(accountId: Account['id']) {
    if (this._providers.has(accountId)) {
      this._providers.delete(accountId);
    }
  }

  get(accountId: Account['id']) {
    if (!this._providers.has(accountId)) {
      throw new Error(`No provider registered for account ${accountId}`);
    }
    return this._providers.get(accountId);
  }

  ids() {
    return Array.from(this._providers.keys());
  }

  providers() {
    return Array.from(this._providers.values());
  }
}

/**
 * We use a singleton to register providers based on their account id
 */
export const providerLocator = new ProviderLocator();

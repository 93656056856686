import { useCallback } from 'react';
import { getWallets } from 'cb-wallet-data/stores/Wallets/database';
import { walletsAtom, WalletsMap } from 'cb-wallet-data/stores/Wallets/state';
import { useSetRecoilState } from 'recoil';

export function useHydrateWallets() {
  const setWalletsRecoilState = useSetRecoilState(walletsAtom);

  return useCallback(
    async function hydrateWallets(deletedAccountIds?: string[]) {
      const allWallets = await getWallets();

      const nextWallets = allWallets.reduce<WalletsMap>(function reduceWallet(acc, wallet) {
        if (deletedAccountIds && deletedAccountIds.includes(wallet.accountId)) {
          return acc;
        }
        acc[wallet.id] = wallet;
        return acc;
      }, {});

      setWalletsRecoilState(nextWallets);
    },
    [setWalletsRecoilState],
  );
}

import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useGetUserState } from 'cb-wallet-data/hooks/Gamification/useUserOCSProfile';
import { useToggler } from '@cbhq/cds-common';
import { Spacer, VStack } from '@cbhq/cds-web/layout';

import { CardWithImage } from ':dapp/components/Card/CardWithImage';
import { HomebaseModal } from ':dapp/components/HomebaseModal/HomebaseModal';
import { usePreferredAccount, WalletPreferenceFeature } from ':dapp/hooks/usePreferredAccount';
import { OCSShareBar } from ':dapp/pages/ocs/components/OCSShareBar';
import { OCSChallengeCard } from ':dapp/pages/ocs/types/ocsResponseTypes';

export const testID = 'shareExperienceModal';

type ShareModalProviderProps = { children: React.ReactNode };

type getReferralUrlProps = {
  id: string;
  baseUrl: string;
  referralCode?: string;
};

export type ShareModalContextValue = {
  isShareModalOpen: boolean;
  openShareModal: (newContent: OCSChallengeCard) => void;
  closeShareModal: () => void;
  activeContent?: OCSChallengeCard;
  setContent?: (content: OCSChallengeCard) => void;
};

const INITIAL_STATES: ShareModalContextValue = {
  isShareModalOpen: false,
  openShareModal: () => {},
  closeShareModal: () => {},
  activeContent: undefined,
  setContent: () => {},
};

export const ShareModalProviderContext = createContext<ShareModalContextValue>(INITIAL_STATES);

function getReferralUrl({ baseUrl, id, referralCode }: getReferralUrlProps) {
  return `${baseUrl}?referral_id=${referralCode || ''}&challenge_id=${id || ''}`;
}

export function ShareModalProvider({ children }: ShareModalProviderProps) {
  const [isShareModalOpen, { toggleOn, toggleOff }] = useToggler(false);
  const [activeContent, setActiveContent] = useState<OCSChallengeCard | undefined>(undefined);

  const { account } = usePreferredAccount({
    blockchainSymbol: 'ETH',
    feature: WalletPreferenceFeature.OCS,
  });
  const { userState } = useGetUserState(account?.primaryAddress);

  const referralUrl = useMemo(() => {
    if (typeof window === 'undefined') return '';
    const { protocol, host } = window.location;
    const ocsUrl = `${protocol}//${host}/summer/play`;

    return getReferralUrl({
      baseUrl: ocsUrl,
      id: activeContent?.id ?? '',
      referralCode: userState?.referralData.referralCode,
    });
  }, [activeContent?.id, userState?.referralData.referralCode]);

  const dismissModal = useCallback(() => {
    toggleOff();

    setActiveContent(undefined);
  }, [toggleOff]);

  const openModal = useCallback(
    (newContent: OCSChallengeCard) => {
      setActiveContent(newContent);
      toggleOn();
    },
    [toggleOn],
  );

  const value = useMemo(
    () => ({
      isShareModalOpen,
      openShareModal: openModal,
      closeShareModal: dismissModal,
      activeContent,
      setContent: setActiveContent,
    }),
    [isShareModalOpen, openModal, dismissModal, activeContent],
  );

  const image = useMemo(() => ({ src: activeContent?.imageUrl ?? '' }), [activeContent]);

  const modal = useMemo(() => {
    if (!isShareModalOpen || !activeContent) return null;

    return (
      <HomebaseModal
        title="Share this experience"
        visible={isShareModalOpen}
        onClose={toggleOff}
        testID="share-card-modal"
      >
        <VStack alignItems="center" testID={testID} width="100%" overflow="scroll">
          <CardWithImage title={activeContent.title} image={image} isGradient />
          <Spacer vertical={2} />
          <OCSShareBar data-testid="share-bar" shareUrl={referralUrl} copyUrl={referralUrl} />
          <Spacer vertical={2} />
        </VStack>
      </HomebaseModal>
    );
  }, [activeContent, image, isShareModalOpen, referralUrl, toggleOff]);

  return (
    <ShareModalProviderContext.Provider value={value}>
      {children}
      <VStack overflow="auto">{modal}</VStack>
    </ShareModalProviderContext.Provider>
  );
}

export function useShareModalContext() {
  return useContext(ShareModalProviderContext);
}

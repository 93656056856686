import { useCallback, useMemo } from 'react';
import { QueryMeta } from '@tanstack/react-query';
import { triggerExposureTracked } from 'cb-wallet-analytics/experiment';
import { useDeviceExperiments } from 'cb-wallet-data/stores/Experiments/hooks/useDeviceExperiments';
import { useWalletUserExperiments } from 'cb-wallet-data/stores/Experiments/hooks/useWalletUserExperiments';
import { ReleaseEnvironment } from 'cb-wallet-data/stores/Experiments/types';
import { useUser } from 'cb-wallet-data/stores/User/hooks/useUser';
import noop from 'lodash/noop';
import { exposeExperiment, SubjectType } from '@cbhq/client-analytics';
import { ArrayFromExperiments, ExperimentProvider, Experiments } from '@cbhq/experiments';

/**
 * @param shouldSuspend if true, should activate global suspense spinner while fetching experiments. if false, should
 * not activate global suspense spinner while fetching experiments
 * @param onReceiveFirstAuthedExperimentResult callback which is called the first time the application receives
 * experiment response from hitting api for authed experiments
 */
type ExperimentationProviderProps = {
  children: React.ReactNode;
  releaseEnvironment: ReleaseEnvironment;
  appVersion: string;
  shouldSuspend: boolean;
  onReceiveFirstAuthedExperimentResult?: () => void;
  userId?: number;
  deviceId?: string;
  meta?: QueryMeta;
};

/**
 * ExperimentationProvider that is shared by both pano and scw codebases. And can be reused by other CBW web apps.
 *
 * This component fetches `device` experiments, which are experiments that do not require a userId.
 *
 * This component also fetches `authed` experiments, which are experiments that require a userId to determine a user's
 * cohort.
 *
 * You can deterministically use any 'authed' @cbhq/experiments hooks and know that 'authed' experiments are ready for
 * use as soon as `OnboardingContext#isAuthedExperimentDataAvailable` is true.
 */
export function ExperimentationProvider(props: ExperimentationProviderProps) {
  const user = useUser({ enabled: !props.userId });
  const userId = props.userId?.toString() ?? user?.id?.toString();
  const deviceId = props.deviceId;

  const experiments = useWalletUserExperiments({
    releaseEnvironment: props.releaseEnvironment,
    version: props.appVersion,
    userId,
    shouldSuspend: props.shouldSuspend,
    onReceiveFirstAuthedExperimentResult: props.onReceiveFirstAuthedExperimentResult ?? noop,
    meta: props.meta,
  });

  const deviceExperiments = useDeviceExperiments(
    deviceId,
    props.releaseEnvironment,
    props.appVersion,
    props.meta,
  );

  const combinedExperiments: ArrayFromExperiments<Experiments> = useMemo(() => {
    const experimentGroups = experiments?.groups ?? [];
    const deviceExperimentGroups = deviceExperiments?.groups ?? [];
    const combined = [...experimentGroups, ...deviceExperimentGroups];

    return combined.map((group: { test: string; group: string; isTracked: boolean }) => ({
      name: group.test,
      group: group.group,
      skipTracking: !group.isTracked,
    }));
  }, [deviceExperiments.groups, experiments.groups]);

  const trackExposure = useCallback(
    function trackExposure(testName: string, group: string) {
      if (!userId && !deviceId) {
        return;
      }
      const deviceExperimentGroups = deviceExperiments?.groups ?? [];

      const isDeviceExperiment = deviceExperimentGroups.some(
        (experiment) => experiment.test === testName,
      );
      const subjectId = isDeviceExperiment ? deviceId : userId;
      const subjectType = isDeviceExperiment ? SubjectType.device : SubjectType.wallet_user;

      if (!subjectId) {
        return;
      }

      exposeExperiment({
        subjectId,
        subjectType,
        group,
        testName,
      });

      triggerExposureTracked({
        experimentGroup: group,
        experimentName: testName,
        experimentSubjectId: subjectId,
        experimentSubjectType: subjectType,
      });
    },
    [deviceExperiments.groups, deviceId, userId],
  );

  return (
    <ExperimentProvider experiments={combinedExperiments} trackExposure={trackExposure} {...props}>
      {props.children}
    </ExperimentProvider>
  );
}

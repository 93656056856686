import { ActionType, ComponentType } from '@cbhq/client-analytics';

import { logEvent } from '../utils/log';

export type AutoConnectSource = 'extension' | 'scw';
/**
 * The method used to send the auto-connect request
 */
type RequestMethod = 'cross-domain-messaging' | 'redirect-with-query-params';
/**
 * The action that the dapp will take after auto-connecting
 */
export type RequestAction = 'assets' | 'scw_marketing' | 'transaction_details';

export function logDappAutoConnectStart(
  source: AutoConnectSource,
  requestId: string,
  requestMethod?: RequestMethod,
  requestAction?: RequestAction,
) {
  logEvent('dapp.auto_connect.start', {
    data: {
      source,
      requestId,
    },
    request: requestId,
    requestMethod,
    requestAction,
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function logDappAutoConnectSuccess(
  source: AutoConnectSource,
  requestId: string,
  requestMethod?: RequestMethod,
  requestAction?: RequestAction,
) {
  logEvent('dapp.auto_connect.success', {
    data: {
      source,
      requestId,
    },
    request: requestId,
    requestMethod,
    requestAction,
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function logDappAutoConnectFail(
  source: AutoConnectSource,
  requestId: string,
  errorMessage: string,
  requestMethod?: RequestMethod,
  requestAction?: RequestAction,
) {
  logEvent('dapp.auto_connect.fail', {
    data: {
      source,
      requestId,
      errorMessage,
    },
    request: requestId,
    requestMethod,
    requestAction,
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function logDappAutoConnectAlreadyConnected(
  source: AutoConnectSource,
  requestId: string,
  requestMethod?: RequestMethod,
  requestAction?: RequestAction,
) {
  logEvent('dapp.auto_connect.already_connected', {
    data: {
      source,
      requestId,
    },
    request: requestId,
    requestMethod,
    requestAction,
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

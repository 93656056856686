import { tryGetRepository } from 'cb-wallet-data/persistence/Database';

import { LastSyncedTxHash, LastSyncedTxHashDMO } from './LastSyncedTxHash';

export function repository() {
  return tryGetRepository<LastSyncedTxHashDMO>('last_synced_tx_hash');
}

export async function saveLastSyncedTxHashes(
  lastSyncedTxHashes: LastSyncedTxHash[],
): Promise<void> {
  await Promise.all(
    repository().batchUpsert(
      lastSyncedTxHashes.map((lastSyncedTxHash) => lastSyncedTxHash.asDMO),
      ['id'],
    ),
  );
}

export async function getLastSyncedTxHashes() {
  return repository().find();
}

async function fetchMediaContentType(url: string) {
  if (!url) {
    return '';
  }

  try {
    let res = await fetch(encodeURI(url), { method: 'HEAD' });
    if (res.ok) {
      return res.headers.get('content-type');
    }

    if (res.status === 405) {
      res = await fetch(encodeURI(url), { method: 'GET' });
      return res.headers.get('content-type');
    }

    return '';
  } catch (e) {
    return '';
  }
}

const MEMO: Record<string, string> = {};
export async function getMediaContentType(url: string) {
  if (!(url in MEMO)) {
    MEMO[url] = (await fetchMediaContentType(url)) || '';
  }

  return MEMO[url];
}

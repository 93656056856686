import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { useHasAccountsAndWalletGroups } from 'cb-wallet-data/hooks/initialization/useHasAccountsAndWalletGroups';
import { useSpectrum } from '@cbhq/cds-common';
import { paletteAliasToRgbaString } from '@cbhq/cds-common/palette/paletteAliasToRgbaString';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { Tooltip } from '@cbhq/cds-web/overlays';
import { Pressable } from '@cbhq/cds-web/system';
import { TextHeadline } from '@cbhq/cds-web/typography';
import { DrawerName } from ':dapp/components/GlobalDrawer/constants';
import { useLaunchBuyFlow } from ':dapp/hooks/Buy/useLaunchBuyFlow';
import { useIsFeatureEnabledForScw } from ':dapp/hooks/Scw/useIsFeatureEnabledForScw';
import { useIsGlobalSwapEnabled } from ':dapp/hooks/Swap/useIsGlobalSwapEnabled';
import { useHandleGabItemPress } from ':dapp/hooks/useHandleGabItemPress';
const messages = defineMessages({
  buyLabel: {
    defaultMessage: 'Buy',
    description: 'Global buy entry point button label'
  },
  buyDesciption: {
    defaultMessage: 'Buy more crypto',
    description: 'Global buy entry point button description'
  },
  swapLabel: {
    defaultMessage: 'Swap',
    description: 'Global swap entry point button label'
  },
  swapDescription: {
    defaultMessage: 'Swap one coin for another',
    description: 'Global swap entry point button description'
  },
  sendLabel: {
    defaultMessage: 'Send',
    description: 'Global send entry point button label'
  },
  sendDescription: {
    defaultMessage: 'Send crypto to another wallet',
    description: 'Global send entry point button description'
  }
});
type GlobalActionButtonsProps = {
  isSideNavbarCollapsed: boolean;
};
const actionButtonLabelContainerStyles = "a1lhs0p0";
export function GlobalActionButtons({
  isSideNavbarCollapsed
}: GlobalActionButtonsProps) {
  const {
    formatMessage
  } = useIntl();
  const hasAccountsAndWalletGroups = useHasAccountsAndWalletGroups();
  const isGlobalSwapEnabled = useIsGlobalSwapEnabled();
  const isSCWSendEnabled = useIsFeatureEnabledForScw('send');
  const isGlobalSendEnabled = useIsFeatureEnabled('send_blockchain_all');
  const {
    launchBuyFlow,
    isBuyFlowEnabled
  } = useLaunchBuyFlow();
  const handleOpenDrawer = useHandleGabItemPress({
    closeModal: () => {}
  });
  const isSendFlowEnabled = isGlobalSendEnabled && isSCWSendEnabled;
  const handleBuyFlow = useCallback(async () => {
    await launchBuyFlow();
  }, [launchBuyFlow]);
  if (!hasAccountsAndWalletGroups) {
    return null;
  }
  return <VStack>
      <VStack width="100%" spacingBottom={1}>
        <VStack gap={0.5} width="100%" testID="nav-gab" accessibilityLabel="Group">
          {isBuyFlowEnabled ? <Tooltip visible={isSideNavbarCollapsed} content={formatMessage(messages.buyLabel)} placement="right" testID="buy-tooltip">
              <Pressable background={isSideNavbarCollapsed ? 'transparent' : 'background'} width="100%" testID="global-buy-button" onPress={handleBuyFlow} borderRadius="rounded">
                <HStack alignItems="center" gap={2} spacing={1}>
                  <Box width={32} height={32} justifyContent="center" alignItems="center" background="secondary" borderRadius="roundedFull">
                    <Icon name="add" accessibilityLabel={formatMessage(messages.buyDesciption)} size="s" color="foreground" />
                  </Box>
                  {!isSideNavbarCollapsed ? <div className={actionButtonLabelContainerStyles}>
                      <TextHeadline as="span">{formatMessage(messages.buyLabel)}</TextHeadline>
                    </div> : null}
                </HStack>
              </Pressable>
            </Tooltip> : null}

          {isGlobalSwapEnabled ? <Tooltip visible={isSideNavbarCollapsed} content={formatMessage(messages.swapLabel)} placement="right" testID="swap-tooltip">
              <Pressable background={isSideNavbarCollapsed ? 'transparent' : 'background'} width="100%" testID="global-swap-button" onPress={handleOpenDrawer(DrawerName.Swap)} borderRadius="rounded">
                <HStack alignItems="center" gap={2} spacing={1}>
                  <Box width={32} height={32} justifyContent="center" alignItems="center" background="secondary" borderRadius="roundedFull">
                    <SwapIcon />
                  </Box>
                  {!isSideNavbarCollapsed ? <div className={actionButtonLabelContainerStyles}>
                      <TextHeadline as="span">{formatMessage(messages.swapLabel)}</TextHeadline>
                    </div> : null}
                </HStack>
              </Pressable>
            </Tooltip> : null}

          {isSendFlowEnabled ? <Tooltip visible={isSideNavbarCollapsed} content={formatMessage(messages.sendLabel)} placement="right" testID="send-tooltip">
              <Pressable background={isSideNavbarCollapsed ? 'transparent' : 'background'} width="100%" testID="global-send-button" onPress={handleOpenDrawer(DrawerName.Send)} borderRadius="rounded">
                <HStack alignItems="center" gap={2} spacing={1}>
                  <Box width={32} height={32} justifyContent="center" alignItems="center" background="secondary" borderRadius="roundedFull">
                    <Icon name="arrowUp" accessibilityLabel={formatMessage(messages.sendDescription)} size="s" color="foreground" />
                  </Box>
                  {!isSideNavbarCollapsed ? <div className={actionButtonLabelContainerStyles}>
                      <TextHeadline as="span">{formatMessage(messages.sendLabel)}</TextHeadline>
                    </div> : null}
                </HStack>
              </Pressable>
            </Tooltip> : null}
        </VStack>
      </VStack>
    </VStack>;
}
function SwapIcon() {
  const spectrum = useSpectrum();
  const {
    formatMessage
  } = useIntl();
  const fill = paletteAliasToRgbaString('foreground', spectrum);
  return <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" aria-label={formatMessage(messages.swapDescription)}>
      <path d="M8 .75a7.25 7.25 0 0 1 6.4 3.85L16 3v5h-5l1.49-1.49C11.86 4.62 10.1 3.25 8 3.25c-1.98 0-3.67 1.21-4.39 2.94L1.3 5.23C2.4 2.6 4.98.75 8 .75ZM3.51 9.49c.63 1.89 2.39 3.26 4.49 3.26 1.98 0 3.67-1.21 4.39-2.94l2.31.96c-1.1 2.63-3.68 4.48-6.7 4.48a7.25 7.25 0 0 1-6.4-3.85L0 13V8h5L3.51 9.49Z" fill={fill} />
    </svg>;
}

require("./GlobalActionButtons.linaria.module.css!=!../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./GlobalActionButtons.tsx");
import { ActionType, ComponentType, logMetric, MetricType } from '@cbhq/client-analytics';

import { logEvent, useLogEventOnMount } from '../utils/log';

type CollectionProps = {
  network: string;
  sortMethod: string;
  sortOrder: string;
};

type CollectibleClickProps = {
  contractAddress: string;
  tokenId: string;
  chainId: string;
};

type FilterProps = {
  type: 'viewSetting' | 'network' | 'sort';
  currentView: string;
};

type FilterOptionProps = FilterProps & {
  oldValue: string;
  newValue: string;
};

export function logCollectibleGridViewed({ network, sortMethod, sortOrder }: CollectionProps) {
  return logEvent('assets.nft_grid.viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
    network,
    sortBy: `${sortMethod},${sortOrder}`,
  });
}

export function logCollectionsGridViewed({ network, sortMethod, sortOrder }: CollectionProps) {
  return logEvent('assets.collections_grid.viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
    network,
    sortBy: `${sortMethod},${sortOrder}`,
  });
}

export function logGetPaginatedCollectionsQueryError() {
  logMetric({
    metricName: 'paginated_collections.query.error',
    metricType: MetricType.count,
    value: 1,
  });
}

export function logPaginatedCollectionsSeeAllClicked() {
  logEvent('paginated_collections.see_all_button.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function useLogPaginatedCollectionTokensViewed() {
  useLogEventOnMount('paginated_collection_tokens.viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function logGetPaginatedCollectionTokensQueryError(errorMessage: string) {
  logMetric({
    metricName: 'paginated_collection_tokens.query.error',
    metricType: MetricType.count,
    value: 1,
    tags: {
      errorMessage,
    },
  });
}

export function logPaginatedCollectionTokenClicked() {
  logEvent('paginated_collection_token.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function logCollectibleGridItemClicked({
  contractAddress,
  tokenId,
  chainId,
}: CollectibleClickProps) {
  logEvent('assets.nft_grid.collectible.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
    contractAddress,
    tokenId,
    chainId,
  });
}

export function logCollectionsGridItemClicked({
  contractAddress,
  chainId,
}: Omit<CollectibleClickProps, 'tokenId'>) {
  logEvent('assets.collections_grid.collection.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
    contractAddress,
    chainId,
  });
}

export function useLogHiddenCollectiblesButtonViewed() {
  useLogEventOnMount('paginated_collections.hidden_collectibles_button.viewed', {
    action: ActionType.view,
    componentType: ComponentType.button,
  });
}

export function logHiddenCollectiblesButtonClicked() {
  logEvent('paginated_collections.hidden_collectibles_button.clicked', {
    action: ActionType.view,
    componentType: ComponentType.button,
  });
}

export function logNftFilterPressed({ type, currentView }: FilterProps) {
  logEvent('assets.collections.filter.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
    type,
    currentView,
  });
}

export function logNftFilterViewed({ type, currentView }: FilterProps) {
  logEvent('assets.collections.filter_tray.viewed', {
    action: ActionType.view,
    componentType: ComponentType.tray,
    type,
    currentView,
  });
}

export function logNftFilterOptionSelected({
  type,
  oldValue,
  newValue,
  currentView,
}: FilterOptionProps) {
  logEvent('assets.collections.filter_tray.option.selected', {
    action: ActionType.click,
    componentType: ComponentType.button,
    type,
    currentView,
    oldValue,
    newValue,
  });
}

export function logCollectibleClickedV2({
  contractAddress,
  tokenId,
  chainId,
}: CollectibleClickProps) {
  logEvent('nft_detail_clicked_v2', {
    action: ActionType.click,
    componentType: ComponentType.button,
    contractAddress,
    tokenId,
    chainId,
  });
}

export function logPendingCollectibleClicked({
  contractAddress,
  tokenId,
  chainId,
}: CollectibleClickProps) {
  logEvent('assets.nft_grid.pending_collectible.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
    contractAddress,
    tokenId,
    chainId,
  });
}

export function logPendingCollectibleViewed({ network, sortMethod, sortOrder }: CollectionProps) {
  logEvent('assets.nft_grid.pending_collectible.viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
    network,
    sortBy: `${sortMethod},${sortOrder}`,
  });
}

export function logPendingCollectionClicked({
  contractAddress,
  chainId,
}: Omit<CollectibleClickProps, 'tokenId'>) {
  logEvent('assets.collections_grid.pending_collection.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
    contractAddress,
    chainId,
  });
}

export function logPendingCollectionsViewed({ network, sortMethod, sortOrder }: CollectionProps) {
  logEvent('assets.collections_grid.pending_collections.viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
    network,
    sortBy: `${sortMethod},${sortOrder}`,
  });
}

export function logNftViewed() {
  logEvent('nft.viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}
export function logNftPreviewViewed() {
  logEvent('nft_preview.viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function logNftDetailViewed() {
  logEvent('nft_detail.viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function logNftCollectionViewed() {
  logEvent('nft_collection.viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

import { useCallback, useMemo, useState } from 'react';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';

export function useIsSideNavbarCollapsed() {
  const [isCollapsed, setIsCollapsed] = useState(true); // being `false` causes the screen to jitter when it is re-rendered
  const { isTablet, isPhoneLandscape } = useBreakpoints();

  const toggleCollapsed = useCallback(() => {
    setIsCollapsed((prev) => !prev);
  }, []);

  const isSideNavbarCollapsed = useMemo(() => {
    return isCollapsed || isPhoneLandscape || isTablet;
  }, [isCollapsed, isPhoneLandscape, isTablet]);

  return { isCollapsed: isSideNavbarCollapsed, toggleCollapsed };
}

import { useEffect, useState } from 'react';
import { useWalletLinkRelay } from 'cb-wallet-data/stores/WalletLink/useWalletLinkRelay';

import { fetchInitialSessionConfig } from './fetchInitialSessionConfig';

export function useWalletLinkSessionConfig() {
  const relay = useWalletLinkRelay();

  const [sessionConfig, setSessionConfig] = useState(
    // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
    // eslint-disable-next-line wallet/no-anonymous-params
    () => {
      if (!relay) {
        return fetchInitialSessionConfig();
      }

      return relay.sessionConfig;
    },
  );

  useEffect(
    function sessionConfigChangedEffect() {
      if (!relay) {
        return;
      }

      if (relay.sessionConfig) {
        setSessionConfig(relay.sessionConfig);
      }

      return relay.addOnSessionConfigChangedCallback((nextSessionConfig) =>
        setSessionConfig(nextSessionConfig),
      );
    },
    [relay],
  );

  return sessionConfig;
}

import {
  CB_WALLET_API_DEV_URL,
  CB_WALLET_API_URL,
  OFFRAMP_DEV_API,
  OFFRAMP_PROD_API,
  ONRAMP_API_DEV_URL,
  ONRAMP_API_URL,
} from 'cb-wallet-env/env';

export const httpErrors: Record<number, string> = {
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Payload Too Large',
  416: 'Request Range Not Satisfiable',
  429: 'Too Many Requests',
  499: 'Client Closed Request',
  500: 'Internal Server Error',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
};

// This is a map of the wallet service URLs for different environments
// that is used to determine the base URL when making requests to the wallet services
export const WALLET_API_URL_MAP = Object.freeze({
  CB_WALLET_API: {
    production: CB_WALLET_API_URL,
    development: CB_WALLET_API_DEV_URL,
  },
  ONRAMP_API: {
    production: ONRAMP_API_URL,
    development: ONRAMP_API_DEV_URL,
  },
  OFFRAMP_API: {
    production: OFFRAMP_PROD_API,
    development: OFFRAMP_DEV_API,
  },
});

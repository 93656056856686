import { TransactionMaxRetriesError } from 'wallet-engine-signing/history/errors';
import { getTxHistoryTransactions } from 'wallet-engine-signing/history/Ethereum/transactions';
import { getTxHistoryV3TransactionForTxHash } from 'wallet-engine-signing/history/Ethereum/txhistory';
import { NudgeDetails } from 'wallet-engine-signing/history/types';
import { isFulfilled, retryWithBackoff } from 'wallet-engine-signing/history/utils';
import { V1Transaction } from '@cbhq/instant-api-hooks-wallet-tx-history/types';

import { SolanaAddressHistory } from './types';

export type RefreshSolanaTransactions = {
  addressConfigs: SolanaAddressHistory[];
  onRefreshComplete: (updates: SolanaAddressHistory[]) => void;
};

export async function refreshSolanaTransactions({
  addressConfigs,
  onRefreshComplete,
}: RefreshSolanaTransactions) {
  const txHistoryRequests = await Promise.allSettled(
    addressConfigs.map(async (addressConfig) => {
      return refreshSolanaTransactionsForAddress(addressConfig);
    }),
  );

  const txHistoryUpdates = txHistoryRequests.filter(isFulfilled).map((result) => result.value);

  onRefreshComplete(txHistoryUpdates);
}

export async function refreshSolanaTransactionsForAddress(addressConfig: SolanaAddressHistory) {
  const txUpdate = await getTxHistoryTransactions({ addressConfig });
  return {
    ...addressConfig,
    ...txUpdate,
  };
}

export async function handleSolanaNudgeTransactions(
  addressConfigs: SolanaAddressHistory[],
  nudgeDetails: NudgeDetails,
) {
  const txHistoryUpdates = await Promise.allSettled(
    addressConfigs.map(async (addressConfig) => {
      const result = await retryWithBackoff(
        async () =>
          getTxHistoryV3TransactionForTxHash({
            address: addressConfig.address,
            network: 'networks/solana-mainnet',
            hash: nudgeDetails.txHash,
          }),
        { maxRetries: 10, backOffIntervalMS: 1000 },
      );
      return {
        ...addressConfig,
        unsyncedTransactions: [result.transaction as V1Transaction],
        spamScoreThresholds: result.spamScoreThresholds!,
        addressMetadata: result.addressMeta!,
      };
    }),
  );

  const updates = txHistoryUpdates.filter(isFulfilled).map((result) => result.value);
  const errors = txHistoryUpdates
    .filter((result) => result.status === 'rejected')
    .map(() => [new TransactionMaxRetriesError('Max retries exceeded', 'SOL')]);

  return {
    updates,
    blockheight: 0,
    errors,
  };
}

import { useMemo } from 'react';
import { useAccounts } from 'cb-wallet-data/stores/Accounts/hooks/useAccounts';

import { COINBASE_SMART_WALLET_ID } from ':dapp/connection/wallet-providers/coinbase-wallet';

/**
 * Returns all accounts that are a Coinbase smart contract wallet.
 *
 * Important distinction between this hook and `useHasScwConnected` is that
 * this hook returns scw accounts even if the provider is not actively connected.
 */
export function useScwAccounts() {
  const accounts = useAccounts();

  return useMemo(() => {
    return accounts.filter((account) => account.provider === COINBASE_SMART_WALLET_ID);
  }, [accounts]);
}

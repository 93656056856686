import { DogecoinAddressType } from 'cb-wallet-data/chains/UTXO/Dogecoin/config';
import { AddressType } from 'cb-wallet-data/stores/Addresses/models/AddressType';

export function xpubKeyDerivationPath(
  addressType: AddressType,
  testnet: boolean,
): string | undefined {
  switch (addressType.rawValue) {
    case DogecoinAddressType.rawValue:
      return testnet ? "m/44'/1'/0'" : "m/44'/3'/0'";
    default:
      return undefined;
  }
}

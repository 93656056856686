import { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import {
  triggerAddAdditionalWalletClick,
  triggerWalletConnectionConnectClick,
} from 'cb-wallet-analytics/wallet-connection';
import { useModal } from '@cbhq/cds-common/overlays/useModal';

import { ConnectionModal } from '../ConnectionModal/ConnectionModal';

export type UseConnectButtonProps = {
  onOpen?: () => void;
  type?: 'add' | 'connect';
};

const messages = defineMessages({
  addWallet: {
    defaultMessage: 'Add wallet',
    description: 'Text telling the user to add another wallet',
  },
  connectWallet: {
    defaultMessage: 'Connect',
    description: 'Text telling the user to connect a wallet',
  },
});

export function useConnectButton({ onOpen, type = 'connect' }: UseConnectButtonProps) {
  const { formatMessage } = useIntl();
  const { openModal, closeModal } = useModal();

  const handleOpenModal = useCallback(() => {
    if (type === 'add') {
      triggerAddAdditionalWalletClick();
    } else {
      triggerWalletConnectionConnectClick();
    }
    onOpen?.();
    openModal(<ConnectionModal onClose={closeModal} />);
  }, [closeModal, onOpen, openModal, type]);

  const buttonText = useMemo(() => {
    switch (type) {
      case 'add': {
        return formatMessage(messages.addWallet);
      }
      case 'connect':
      default: {
        return formatMessage(messages.connectWallet);
      }
    }
  }, [formatMessage, type]);

  return useMemo(() => {
    return {
      handleOpenModal,
      buttonText,
    };
  }, [buttonText, handleOpenModal]);
}

import { cbReportError } from 'cb-wallet-data/errors/reportError';
import type { AnalyticsSetIdentity } from '@cbhq/client-analytics/src/types';

/**
 * Function that identifies the user for analytics purposes.
 */
export type SetAnalyticsUserId = ({ userId }: { userId?: AnalyticsSetIdentity['userId'] }) => void;

type ObservabilityClientI = {
  setAnalyticsUserIdFn: SetAnalyticsUserId;
  unsetUserId: () => void;
  setUserId: ({ userId }: { userId?: string | undefined }) => void;
};

/**
 * ObservabilityClient
 *
 * NOTE This is only exported for typing and unit tests.
 *
 * This is used as a singleton which has private methods being set in a hook. While
 * this pattern is not common, it was chosen over a Provider pattern because:
 * - Easy Access: there is no need to pipe it through React UI components to datalayer
 *   hooks
 * - Memory: there is no need to store the userId in React state
 * - Performance: there is no need to re-render components if the setter functions change
 * - Testing: there is no need to mock the Provider
 * - Type Safety: there is no need to use generics to type the Provider
 *
 * While it may not be a common pattern, the number of developers working in this
 * area should be minimal as setting userId for observability is a rare occurrence.
 */
export class ObservabilityClient implements ObservabilityClientI {
  private _setAnalyticsUserId?: SetAnalyticsUserId;

  /* *
   * Sets the setAnalyticsUserId function. While both RN/Ext platforms use the
   * same analytics #identify, there is platform-specific logic surrounding it,
   * such as where to store the userId and get the deviceId. Note the suffix -Fn
   * is to prevent collision of duplicate methods names.
   *
   * @param setAnalyticsUserId - The function to identify the user for analytics purposes.
   */
  public set setAnalyticsUserIdFn(setAnalyticsUserId: SetAnalyticsUserId) {
    this._setAnalyticsUserId = setAnalyticsUserId;
  }

  /* *
   * Sets the Amplitude userId and calls the set `_setAnalyticsUserId` function
   *
   * @param args.userId - Analytics userId.
   */
  private setAnalyticsUserId({ userId }: { userId?: AnalyticsSetIdentity['userId'] }): void {
    if (!this._setAnalyticsUserId) {
      cbReportError({
        error: new Error('analyticsIdentify function is not set'),
        context: 'accounts-datalayer',
        isHandled: false,
        severity: 'error',
      });
      return;
    }
    this._setAnalyticsUserId({ userId });
  }

  /* *
   * Sets the Amplitude userId to undefined
   */
  public unsetUserId(): void {
    this.setAnalyticsUserId({ userId: undefined });
  }

  /* *
   * Sets the Amplitude userId to userId
   */
  public setUserId({ userId }: { userId?: string | undefined }): void {
    this.setAnalyticsUserId({ userId });
  }
}

// Export a singleton instance
export const observabilityClient = new ObservabilityClient();

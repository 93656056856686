const MAX_RETRY_DELAY = 10000;

export function getRetryDelay(attempts: number) {
  if (attempts === 0) return 0;
  return Math.min(2 ** attempts * 1000, MAX_RETRY_DELAY);
}

export async function sleep(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export const errorTypes = {
  noChangeDetected: 'no-change-detected',
} as const;

export function formatError(type: (typeof errorTypes)[keyof typeof errorTypes], message: string) {
  return {
    type,
    message,
  };
}

export async function promiseTimeout<T = any>(promise: Promise<T>, ms = 0): Promise<T> {
  const timeout = new Promise<never>((resolve, reject) => {
    const id = setTimeout(() => {
      clearTimeout(id);
      reject(new Error('Request timed out'));
    }, ms);
  });

  return Promise.race([promise, timeout]);
}

export function isFulfilled<T>(input: PromiseSettledResult<T>): input is PromiseFulfilledResult<T> {
  return input.status === 'fulfilled';
}

export async function waitFor(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

type RetryWithBackoffOptions = {
  maxRetries?: number;
  backOffIntervalMS?: number;
  onRetry?: (attempt: number) => void;
};

export async function retryWithBackoff<T>(
  promise: () => Promise<T>,
  retryOptions?: RetryWithBackoffOptions,
) {
  const { maxRetries = 6, backOffIntervalMS = 500, onRetry } = retryOptions ?? {};
  async function retry(retries: number): Promise<T> {
    try {
      if (retries > 0) {
        const timeToWait = 2 ** retries * backOffIntervalMS;
        await waitFor(timeToWait);
      }
      return await promise();
    } catch (e) {
      if (retries < maxRetries) {
        onRetry?.(retries + 1);
        return retry(retries + 1);
      }
      throw e;
    }
  }
  return retry(0);
}

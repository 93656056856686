// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components';
import cssValue from 'wallet-cds-web/util/cssValue';

type Props = {
  children: any;
};

const Frame = styled.div<any>`
  flex: 1;
  display: flex;
  flex-direction: column;
  ${(p) =>
    p.active &&
    `
    position: relative;
    opacity: 1;
  `}
  ${(p) =>
    !p.active &&
    `
    opacity: 0;
    pointer-events: none;
  `}
  ${(p) =>
    p.transitioning &&
    `
    transition: opacity ${cssValue(p.transitionTime)}ms;
  `}
  ${(p) =>
    (!p.active || p.transitioning) &&
    `
    position: absolute;
    top: 0;
    left: 0;
  `}
  ${(p) =>
    p.fill &&
    (!p.active || p.transitioning) &&
    `
    right: 0;
  `}
  ${(p) =>
    p.transitioning &&
    `
    bottom: 0;
    right: 0;
  `}
`;

function Container({ children }: Props) {
  return children;
}

// eslint-disable-next-line import/no-default-export
export default {
  Container,
  Frame,
};

import { DataMigration } from 'cb-wallet-data/DataMigrationRunner/types';
import { getAccounts } from 'cb-wallet-data/stores/Accounts/database';
import { getWalletGroups, saveWalletGroup } from 'cb-wallet-data/stores/WalletGroups/database';
import { WalletGroup } from 'cb-wallet-data/stores/WalletGroups/models/WalletGroup';

/**
 * This migration updates all ledger wallet groups for users who onboarded with a single account
 * to have a walletIndex of 0. In order to correctly find wallets and addresses for ledger wallet groups, they need
 * to have the same index as wallets / addresses which for ledger is zero.
 */

const migrateLedgerWalletGroupWalletIndexes: DataMigration = {
  // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
  // eslint-disable-next-line wallet/no-anonymous-params
  up: async () => {
    // eslint-disable-next-line no-console
    console.log('Migration: migrateLedgerWalletGroupWalletIndexes');

    // 1. Get all ledger accounts (account.type === LEDGER)
    //    Only run this migration for legacy (non LMA) ledger users who have a single account
    const accounts = await getAccounts();
    const ledgerAccounts = accounts.filter(({ isLedgerAccount }) => isLedgerAccount);

    if (ledgerAccounts.length > 1 || ledgerAccounts.length === 0) return;
    const [ledgerAccount] = ledgerAccounts;

    // 2. Get wallet group for this account
    const walletGroups = await getWalletGroups();
    const ledgerWalletGroup = walletGroups.find(
      (walletGroup) => walletGroup.accountId === ledgerAccount.id,
    );

    if (!ledgerWalletGroup) {
      throw new Error(
        `Unable to find wallet group associated with ledger account id: ${ledgerAccount.id}.`,
      );
    }

    // If wallet group already has an index of 0, no need to update, return.
    const isLedgerWalletGroupIndexZero = ledgerWalletGroup.walletIndex === 0n;

    if (isLedgerWalletGroupIndexZero) {
      return;
    }

    const newWalletGroup = new WalletGroup({
      ...ledgerWalletGroup,
      walletIndex: 0n,
    });

    // 3. Save wallet group with wallet index of 0
    await saveWalletGroup(newWalletGroup);
  },
};

export default migrateLedgerWalletGroupWalletIndexes;

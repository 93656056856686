import { useMemo } from 'react';
import { useTokenDetails } from 'cb-wallet-data/stores/Collection/hooks/useTokenDetails';
import { useIsNftPfpEnabled } from 'cb-wallet-data/stores/DecentralizedID/hooks/useIsNftPfpEnabled';
import { isNFTAvatar } from 'cb-wallet-data/stores/DecentralizedID/types';

import { Collectible } from '../types';

export type AvatarNFT = {
  collectible: Collectible;
  chainId: string;
};

export type UseNftAvatarMetadataReturn = AvatarNFT | undefined;

export function useNftAvatarMetadata(avatar: string | undefined): UseNftAvatarMetadataReturn {
  const isNftPfpEnabled = useIsNftPfpEnabled();
  const isNftPfp = isNFTAvatar(avatar);
  const nftAvatarInfo = useMemo(() => {
    if (!avatar || !isNftPfp) return undefined;
    const nftInfo = avatar.split(':');
    return {
      contractAddress: nftInfo[2].split('/')[0],
      tokenId: nftInfo[2].split('/')[1],
      chainId: nftInfo[1].split('/')[0],
    };
  }, [avatar, isNftPfp]);

  const { data: tokenDetails } = useTokenDetails({
    contractAddress: nftAvatarInfo?.contractAddress,
    tokenId: nftAvatarInfo?.tokenId,
    chainId: nftAvatarInfo?.chainId,
  });

  const isNFT = useMemo(() => isNftPfpEnabled && !!tokenDetails, [isNftPfpEnabled, tokenDetails]);
  const nftMetadata = useMemo(
    () => ({
      collectible: tokenDetails,
      chainId: nftAvatarInfo?.chainId || '1',
    }),
    [nftAvatarInfo?.chainId, tokenDetails],
  );

  if (!avatar) return undefined;

  return isNFT ? (nftMetadata as AvatarNFT) : undefined;
}

import { useCallback } from 'react';
import { useCurrencyFormatter } from 'cb-wallet-data/CurrencyFormatter/hooks/useCurrencyFormatter';
import { AssetInfo } from 'cb-wallet-data/stores/DefiPortfolio/models/AssetInfo';
import { logPotentialExchangeRateIssueIfNecessary } from 'cb-wallet-data/stores/ExchangeRates/utils';
import Decimal from 'decimal.js';

export function useCalculateAggregateBalanceFromAssetInfoList() {
  const { fiatValue } = useCurrencyFormatter();

  return useCallback(
    function calculateAggregateBalanceFromAssetInfoList(assetInfos: AssetInfo[]) {
      try {
        return assetInfos.reduce(function calculateAggregateBalance(acc, assetInfo) {
          const valueInFiat = fiatValue({
            currencyCode: assetInfo.asset.currencyCode,
            decimals: assetInfo.asset.decimals,
            value: assetInfo.balance,
            contractAddress: assetInfo.asset.address?.rawValue,
            network: assetInfo.asset.network,
            blockchain: assetInfo.asset.blockchain,
          });
          logPotentialExchangeRateIssueIfNecessary({
            fiatBalance: valueInFiat ?? new Decimal(0),
            blockchain: assetInfo.asset.blockchain.rawValue,
            currencyCode: assetInfo.asset.currencyCode.rawValue,
            contractAddress: assetInfo.asset.address?.rawValue ?? '',
            networkName: assetInfo.asset.network.rawValue,
            chainName: assetInfo.asset.network.asChain()?.displayName ?? '',
            chainId: assetInfo.asset.network.asChain()?.chainId ?? '',
          });

          if (!valueInFiat) {
            throw Error('Could not calculate value in fiat');
          }
          return acc.add(valueInFiat);
        }, new Decimal(0));
      } catch (error) {
        // If we cannot calculate the aggregate balance, return undefined
        return undefined;
      }
    },
    [fiatValue],
  );
}

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { privacyModeAtom } from '../state';

export function usePrivacyMode() {
  const privacyMode = useRecoilValue(privacyModeAtom);

  return privacyMode;
}

export function useSetPrivacyMode() {
  const setPrivacyMode = useSetRecoilState(privacyModeAtom);

  return setPrivacyMode;
}

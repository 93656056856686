import { logDataEvent } from '../utils/log';

// Log when a migration is about to run
export function triggerMigrationRun({ filename }: { filename: string }) {
  return logDataEvent('migrations.migration.run', {
    loggingId: '73e835a7-583e-4bdc-a646-28c334b6be6f',
    data: {
      filename,
    },
  });
}

// Log the result of a single migration
export function triggerMigrationResult({
  filename,
  isSuccess,
}: {
  filename: string;
  isSuccess: boolean;
}) {
  return logDataEvent('migrations.migration.result', {
    loggingId: 'a42c2a1a-1481-4fb3-87c0-d4b224d8939c',
    data: {
      filename,
      isSuccess,
    },
  });
}

// Log at the start of #migrate when migrations have not run yet
export function triggerMigrateRun() {
  return logDataEvent('migrations.migrate.run', {
    loggingId: '36235d49-7c69-4616-8c76-68e459a26173',
  });
}

// Log result the end of #migrate after migrations have run
// param: {@link DataMigrationRunnerMigrateResult}
export function triggerMigrateResult({
  isUpToDate,
  error,
  dataMigrationVersion,
  dataMigrationVersionUpdatedAt,
}:
  | {
      isUpToDate: true;
      error: undefined;
      dataMigrationVersion?: string;
      dataMigrationVersionUpdatedAt?: string;
    }
  | {
      isUpToDate: false;
      error?: Error;
      dataMigrationVersion?: string;
      dataMigrationVersionUpdatedAt?: string;
    }) {
  return logDataEvent('migrations.migrate.result', {
    loggingId: 'ef19e4d7-4f6a-45f6-a421-63282969cb2a',
    data: {
      isUpToDate,
      error,
      dataMigrationVersion,
      dataMigrationVersionUpdatedAt,
    },
  });
}

import { useMemo } from 'react';
import { EthereumBlockchain } from 'cb-wallet-data/chains/AccountBased/Ethereum/config';
import { Collectible } from 'cb-wallet-data/stores/Collection/types';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
// eslint-disable-next-line no-restricted-imports
import { useActiveWalletGroupId } from 'cb-wallet-data/stores/WalletGroups/hooks/useActiveWalletGroupId';
import { usePrimaryReceiveAddresses } from 'cb-wallet-data/stores/Wallets/hooks/usePrimaryReceiveAddresses';

import { UserCollectibleSetting } from '../models/UserCollectibleSetting';

export function useGenerateUserCollectibleSettingId(collectible: Collectible, chainId: bigint) {
  const activeWalletGroupId = useActiveWalletGroupId();
  const primaryAddress = usePrimaryReceiveAddresses(activeWalletGroupId).get('ETH')?.address;

  return useMemo(
    () =>
      generateUserCollectibleSettingId({
        contractAddress: collectible.contractAddress,
        tokenId: collectible.tokenId,
        ownerAddress: primaryAddress ?? '',
        chainId,
      }),
    [chainId, collectible, primaryAddress],
  );
}

type Props = {
  contractAddress: string;
  tokenId: string;
  ownerAddress: string;
  chainId: bigint;
};

export function generateUserCollectibleSettingId({
  contractAddress,
  tokenId,
  ownerAddress,
  chainId,
}: Props) {
  const network = Network.fromChainId({ chainId });

  return UserCollectibleSetting.generateID(
    ownerAddress,
    contractAddress,
    EthereumBlockchain,
    network,
    tokenId,
  );
}

import { useMemo, useRef } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { dequal } from 'dequal';

import { CryptoExchangeRate, FiatExchangeRate } from '../api';

export type CryptoAssetQuotesQuery = {
  suspense: boolean;
  useErrorBoundary: boolean;
  staleTime: number;
  notifyOnChangeProps: ('data' | 'isFetching' | 'isInitialLoading')[];
  keepPreviousData: boolean;
  queryKey: (string | number)[];
  queryFn: () => Promise<CryptoExchangeRate[]>;
};

export type FiatExchangeRateQuery = Omit<CryptoAssetQuotesQuery, 'queryFn'> & {
  queryFn: () => Promise<FiatExchangeRate[]>;
};

/**
 * Checks if the keys of queries that we pass to useQueries have changed.
 * We use deep equal in a similar way as react-query
 * */
export function useHasQueriesChanged(
  cryptoAssetQuotesQueries: CryptoAssetQuotesQuery[] | FiatExchangeRateQuery[],
) {
  const prevKeys = useRef(cryptoAssetQuotesQueries.map((it) => it.queryKey));

  const [queriesHasChanged, currentKeys] = useMemo(() => {
    const currentKeysInternal = cryptoAssetQuotesQueries.map((it) => it.queryKey);
    if (!dequal(currentKeysInternal, prevKeys.current)) {
      return [true, currentKeysInternal];
    }
    return [false, currentKeysInternal];
  }, [cryptoAssetQuotesQueries, prevKeys]);
  prevKeys.current = currentKeys;
  return queriesHasChanged;
}

type UseMemoizedExchangeRateDataParams = {
  cryptoAssetQuotesQueries: CryptoAssetQuotesQuery[];
  fiatRateQuery: FiatExchangeRateQuery;
  isFetching: boolean;
  fiatRateData: FiatExchangeRate[] | undefined;
  cryptoQuotesQueryResults: UseQueryResult<CryptoExchangeRate[], unknown>[];
  canFetchExchangeRates: boolean;
};

/**
 * Makes sure that we only update results from useQuery/useQueries when we stopped fetching
 * or took new data from cache
 * */
export function useMemoizedExchangeRateData({
  cryptoAssetQuotesQueries,
  fiatRateQuery,
  isFetching,
  fiatRateData,
  cryptoQuotesQueryResults,
  canFetchExchangeRates,
}: UseMemoizedExchangeRateDataParams) {
  const cryptoQueriesHasChanged = useHasQueriesChanged(cryptoAssetQuotesQueries);
  const fiatQueryHasChanged = useHasQueriesChanged([fiatRateQuery]);
  const queriesHasChanged = cryptoQueriesHasChanged || fiatQueryHasChanged;

  const prevMemoizedResults = useRef({
    cryptoQuotesQueryResults,
    fiatRateData,
  });

  const notifiedAboutTheLastUpdate = useRef(true);

  // 1) We just changed canFetchExchangeRates and are about to fetch let's wait for now
  const previousCanFetchExchangeRates = useRef(true);
  if (!previousCanFetchExchangeRates.current && canFetchExchangeRates) {
    previousCanFetchExchangeRates.current = canFetchExchangeRates;
    return prevMemoizedResults.current;
  }
  previousCanFetchExchangeRates.current = canFetchExchangeRates;

  // 2) We changed queries but we are not fetching. That means we got the results from cache.
  // Let's update exchange rates.
  if (queriesHasChanged && !isFetching) {
    notifiedAboutTheLastUpdate.current = true;
    prevMemoizedResults.current = {
      cryptoQuotesQueryResults,
      fiatRateData,
    };
  }

  // 3) We just finished fetching let's update exchange rates
  if (!isFetching && !notifiedAboutTheLastUpdate.current) {
    notifiedAboutTheLastUpdate.current = true;
    prevMemoizedResults.current = {
      cryptoQuotesQueryResults,
      fiatRateData,
    };
  }

  if (isFetching) {
    notifiedAboutTheLastUpdate.current = false;
  }
  return prevMemoizedResults.current;
}

import { black, blue, white } from '@cbhq/cds-common/hooks/useLogo';
import { useSpectrumConditional } from '@cbhq/cds-common/hooks/useSpectrumConditional';
import { foreground } from '@cbhq/cds-web/styles/foregroundColor';
export const iconStyles = "i1e8zo78";
const walletBrandLogoMarkData = {
  viewBox: '0 0 137 32',
  logoPath: 'M0 16C0 24.8356 7.16444 32 16 32C24.8356 32 32 24.8356 32 16C32 7.16444 24.8356 0 16 0C7.16444 0 0 7.16444 0 16ZM11.9111 10.8444C11.32 10.8444 10.8444 11.32 10.8444 11.9111V20.0889C10.8444 20.68 11.32 21.1556 11.9111 21.1556H20.0889C20.68 21.1556 21.1556 20.68 21.1556 20.0889V11.9111C21.1556 11.32 20.68 10.8444 20.0889 10.8444H11.9111Z'
};

/**
 * WalletBrandLogoMark
 *
 * CDS component: `SubBrandLogoMark` with Wallet logo
 */
export function WalletBrandLogoMark() {
  const logoColor = useSpectrumConditional({
    light: foreground ? black : blue,
    dark: white
  });
  const typeColor = useSpectrumConditional({
    light: black,
    dark: white
  });
  const {
    viewBox,
    logoPath
  } = walletBrandLogoMarkData;
  const title = `Coinbase Wallet logo`;
  return <svg aria-labelledby={title} className={iconStyles} role="img" viewBox={viewBox} width="100%" xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <g>
        <path d={logoPath} fill={logoColor} clipRule="evenodd" fillRule="evenodd" />
        <path d="M43 7.52408H45.6728L49.3329 20.6714H49.381L53.017 7.5H55.5453L59.2535 20.7195H59.3017L62.8895 7.52408H65.3697L60.602 24.5H58.0255L54.1969 11.1841H54.1487L50.4164 24.5H47.9363L43 7.52408Z" fill={typeColor} />
        <path d="M77.2609 24.4759L75.7439 20.4306H68.1589L66.666 24.4759H64.0173L70.5187 7.52408H73.4323L80.006 24.4759H77.2609ZM71.8912 10.245L68.9536 18.2394H74.9252L71.9394 10.245H71.8912Z" fill={typeColor} />
        <path d="M82.3129 7.52408H84.8653V22.2606H93.3894V24.4759H82.3129V7.52408Z" fill={typeColor} />
        <path d="M95.9515 7.52408H98.504V22.2606H107.028V24.4759H95.9515V7.52408Z" fill={typeColor} />
        <path d="M109.59 7.52408H121.221V9.73938H112.143V14.6516H120.498V16.8187H112.143V22.2606H121.221V24.4759H109.59V7.52408Z" fill={typeColor} />
        <path d="M128.42 9.73938H122.809V7.52408H136.583V9.73938H130.972V24.4759H128.42V9.73938Z" fill={typeColor} />
      </g>
    </svg>;
}

require("./WalletBrandLogoMark.linaria.module.css!=!../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./WalletBrandLogoMark.tsx");
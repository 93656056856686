import { ActionType, ComponentType } from '@cbhq/client-analytics';

import { logEvent } from '../utils/log';

export function logSidebarNavigationClick(navigateTo: string) {
  logEvent('sidebar_navigation.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
    navigateTo,
  });
}

import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { useAuthedGet } from 'cb-wallet-data/HTTP/hooks/useAuthedGet';
import { queryClient } from 'cb-wallet-data/QueryProvider';
import {
  canAuthenticateImperative,
  setAuthTokensImperative,
} from 'cb-wallet-data/stores/Authentication/tokens/state';
import { useCanAuthenticateWithAPI } from 'cb-wallet-data/stores/Authentication/tokens/useCanAuthenticateWithAPI';
import { getJSON } from 'cb-wallet-http/fetchJSON';

import { User } from '../types/User';

export const USER_PROFILE_QUERY_KEY = 'getUserProfile';

type Props = {
  suspense?: boolean;
  shouldThrowToErrorBoundary?: boolean;
  enabled?: boolean;
};

export function handleError({
  err,
  shouldThrowToErrorBoundary,
  suspense,
}: {
  err: ErrorOrAny;
  shouldThrowToErrorBoundary: boolean;
  suspense: boolean;
}) {
  /**
   * If the refreshToken is not valid, we don't want to throw to an error boundary,
   * the app will prompt the user to input their pin / biometrics to request a new
   * token
   */
  if (err?.message === 'refreshToken is invalid') {
    return false;
  }

  if (shouldThrowToErrorBoundary && suspense) {
    return true;
  }

  return false;
}

/**
 *
 * @deprecated Endpoint 'getUserProfile' is deprecated
 * @param suspense - If true, the network call will suspend
 * @param shouldThrowToErrorBoundary - If true and suspense is true, the error will be thrown to the nearest error boundary
 * @returns
 */
export function useUser({
  suspense = false,
  shouldThrowToErrorBoundary = true,
  enabled = true,
}: Props = {}): User | undefined {
  // When connected to the mobile app via WalletLink, we can't issue any
  // authenticated requests because we don't have an access token -- UNLESS
  // they go through some flow which makes them sign in. At which point we DO
  // have an access token and want to fetch the User.
  const canAuthenticateWithAPI = useCanAuthenticateWithAPI();
  const fetchUser = useAuthedGet<User>(USER_PROFILE_QUERY_KEY);

  const { data } = useQuery<User>([USER_PROFILE_QUERY_KEY], async () => fetchUser(), {
    enabled: canAuthenticateWithAPI && enabled,
    // Suspend
    suspense,
    // but return the error as state
    useErrorBoundary: (err) => handleError({ err, shouldThrowToErrorBoundary, suspense }),
    staleTime: 60 * 1000,
    notifyOnChangeProps: ['data'],
  });

  return data;
}

export async function fetchUserImperatively(): Promise<User | undefined> {
  if (!canAuthenticateImperative()) {
    return;
  }

  return queryClient.fetchQuery(
    [USER_PROFILE_QUERY_KEY],
    // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles

    async () => {
      try {
        const { result } = await getJSON<{ result: User }>(
          USER_PROFILE_QUERY_KEY,
          {},
          {
            authenticated: true,
            setAuthTokens: setAuthTokensImperative,
          },
        );

        return result;
      } catch (error: ErrorOrAny) {
        const err = coerceError(error, 'useUser');
        cbReportError({
          error: err,
          context: 'http_error',
          severity: 'error',
          isHandled: false,
        });

        const cachedData = queryClient.getQueryData<User | undefined>([USER_PROFILE_QUERY_KEY]);
        if (cachedData) {
          return cachedData;
        }

        throw error;
      }
    },
  );
}

/**
 * Represents transaction state
 */
export enum TxState {
  /**
   * Transaction pending
   */
  PENDING = 1,

  /**
   * Transaction failed and was mined
   */
  FAILED = 2,

  /**
   * Transaction was confirmed
   */
  CONFIRMED = 3,

  /**
   * Transactions ends up in this state after receiving X errors from eth node resubmission attempts.
   * cmd+f for `DROPPED` in resubmitPendingTransactions.ts to see precise logic
   */
  DROPPED = 4,

  /**
   * Relayer returned response indicating unretryable failure. Operation will not be included in a transaction
   * on the blockchain.
   */
  DROPPED_BY_RELAYER = 5,

  /**
   * Transaction was replaced by a tx with higher gas price via speedup or cancel actions
   */
  REPLACED = 6,
}

/**
 * Converts an IPFS URL to a standardized HTTPS URL using a public IPFS gateway.
 *
 * @param url The original URL
 * @returns https:// URL for the media
 * @link https://docs.ipfs.tech/how-to/address-ipfs-on-web/ - for more details.
 */
export function convertIpfsToHttps(url: string) {
  const IPFS_GATEWAY_URL = 'https://b891d14d436694bb9a7feeba91730b95.ipfscdn.io/ipfs/';
  if (url.startsWith('ipfs://')) {
    return url.replace('ipfs://', IPFS_GATEWAY_URL);
  }

  return url;
}

let abortController = new AbortController();

export function abortAllHTTPRequests(): void {
  abortController.abort();

  // AbortController is single-use. After abort() has been called, we have to make a new one.
  abortController = new AbortController();
}

export function getAbortSignal(): AbortSignal {
  return abortController.signal;
}

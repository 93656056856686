import { useCallback } from 'react';
import { triggerWalletGroupsHydrationResult } from 'cb-wallet-analytics/app-load';
import { useSetRecoilState } from 'recoil';

import { getWalletGroups } from '../database';
import { walletGroupsAtom } from '../state';

/**
 * Hydrates wallet groups from client database to recoil state.
 *
 * THIS SHOULD ONLY BE CALLED ONCE WHEN THE APP INITS.
 */
export function useHydrateWalletGroups() {
  const setWalletGroups = useSetRecoilState(walletGroupsAtom);

  return useCallback(
    async function hydrateWalletGroups(deletedAccountIds?: string[]) {
      const walletGroupsFromDb = await getWalletGroups();
      if (deletedAccountIds) {
        const nextWalletGroups = walletGroupsFromDb.filter(
          (walletGroup) => !deletedAccountIds.includes(walletGroup.accountId),
        );
        setWalletGroups(nextWalletGroups);
        triggerWalletGroupsHydrationResult({
          walletGroupsLength: nextWalletGroups.length,
        });
        return;
      }
      setWalletGroups(walletGroupsFromDb);
      triggerWalletGroupsHydrationResult({
        walletGroupsLength: walletGroupsFromDb.length,
      });
    },
    [setWalletGroups],
  );
}

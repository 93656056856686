import { refreshAccessToken } from 'cb-wallet-http/Authentication/tokens/refreshAccessToken';
import { getAccessTokenFromStorage } from 'cb-wallet-http/Authentication/tokens/state';

type GetAuthedHeadersOptions = {
  withRetailToken?: boolean;
  withXCbwAuthorizationHeader?: boolean;
};

type RefreshAccessTokensOptions = {
  onRefreshWalletAccessToken: Parameters<typeof refreshAccessToken>[0];
  shouldRefreshWalletAccessToken?: boolean;
};

/**
 * Manages the access tokens for the wallet and retail access tokens
 * in the headers for the requests
 */
class AuthTokenManager {
  getRetailAccessToken: (() => Promise<string | null>) | undefined = undefined;
  refreshRetailAccessToken: (() => Promise<void>) | undefined = undefined;

  public getWalletAccessToken() {
    return getAccessTokenFromStorage();
  }

  public async refreshWalletAccessToken(setAuthTokens: Parameters<typeof refreshAccessToken>[0]) {
    return refreshAccessToken(setAuthTokens);
  }

  /**
   * @param withRetailToken - whether to include the retail access token in the headers
   * @param withXCbwAuthorizationHeader - whether to include the wallet access token in the X-Cbw-Authorization header
   */
  public async getAuthedHeaders({
    withRetailToken = false,
    withXCbwAuthorizationHeader = false,
  }: GetAuthedHeadersOptions = {}) {
    const walletAccessToken = this.getWalletAccessToken();

    // initialize the headers with the wallet access token
    // on both the Authorization and X-Cbw-Authorization headers
    const authedHeader: Record<string, string> = {
      Authorization: `Bearer ${walletAccessToken}`,
    };

    if (withXCbwAuthorizationHeader) {
      authedHeader['X-Cbw-Authorization'] = `Bearer ${walletAccessToken}`;
    }

    if (withRetailToken) {
      // if error occurs, it will be caught by the caller
      const retailAccessToken = await this.getRetailAccessToken?.();

      // override the Authorization header with the retail access token
      // if it exists
      if (retailAccessToken) {
        authedHeader.Authorization = `Bearer ${retailAccessToken}`;
      } else {
        throw new Error('Failed to get retail access token.');
      }
    }

    return authedHeader;
  }

  /**
   * @param shouldRefreshWalletAccessToken - whether to explicitly refresh the wallet access token, if false, only refresh the retail access token
   */
  public async refreshAccessTokens({
    onRefreshWalletAccessToken,
    shouldRefreshWalletAccessToken = true, // default to true
  }: RefreshAccessTokensOptions) {
    if (shouldRefreshWalletAccessToken) {
      await this.refreshWalletAccessToken(onRefreshWalletAccessToken);
    } else {
      await this.refreshRetailAccessToken?.();
    }
  }
}

export const authTokenManager = new AuthTokenManager();

import { useMemo } from 'react';
import { AllPossibleBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
import { useRecoilValue } from 'recoil';

import { WalletAddress } from '../models/WalletAddress';
import {
  activePrimaryReceiveAddressByBlockchainAndWalletGroupSelector,
  activePrimaryReceiveAddressesByBlockchainAndWalletGroupSelector,
} from '../state';

/**
 * Get the primary receive address for each blockchain
 *
 * @param walletGroupId the wallet group id to get the primary receive addresses for
 *
 * @return a map of current blockchain's default mainnet to a list of addresses
 */
export function usePrimaryReceiveAddresses(
  walletGroupId: string | undefined,
): Map<AllPossibleBlockchainSymbol, WalletAddress> {
  return useRecoilValue(
    activePrimaryReceiveAddressByBlockchainAndWalletGroupSelector(walletGroupId),
  );
}

/**
 * Get the primary receive address for each blockchain
 *
 * @param walletGroupIds the wallet group ids to get the primary receive addresses for
 *
 * @return a map of wallet group id to current blockchain's default mainnet to a list of addresses
 */
export function usePrimaryReceiveAddressesForWalletGroupIds(
  walletGroupIds: string[],
): Map<string, Map<AllPossibleBlockchainSymbol, WalletAddress>> {
  const walletGroupIdsSelectorKey = useMemo(() => walletGroupIds.join(','), [walletGroupIds]);
  return useRecoilValue(
    activePrimaryReceiveAddressesByBlockchainAndWalletGroupSelector(walletGroupIdsSelectorKey),
  );
}

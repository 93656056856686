import omit from 'lodash/omit';
import qs from 'qs';

export function generateDrawerContentParams(contentParams?: Record<string, string | undefined>) {
  const parsedParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  // if we have new params, update the drawer query params otherwise remove them
  const params = omit(parsedParams, 'contentParams');
  if (contentParams) {
    params.contentParams = contentParams;
  }
  const querystring = qs.stringify(params);
  return querystring.length ? `?${querystring}` : '';
}

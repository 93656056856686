import BN from 'bn.js';
import padEnd from 'lodash/padEnd';
import padStart from 'lodash/padStart';

const HEXADECIMAL_STRING_REGEX = /^[a-f0-9]*$/;
const NUMERIC_WITH_SIGN_REGEX = /^-?[0-9]*$/;
const NUMERIC_REGEX = /^[0-9]*$/;

export function isHexString(hex: unknown): hex is string {
  if (typeof hex !== 'string') {
    return false;
  }
  return HEXADECIMAL_STRING_REGEX.test(hex.toLowerCase());
}

export function bnFromDecimalString(numberString: string, decimalPlaces: number): BN | null {
  const [integer, fractional] = numberString.split('.');
  if (
    (integer === '' && fractional === undefined) ||
    !NUMERIC_WITH_SIGN_REGEX.test(integer || '0') ||
    !NUMERIC_REGEX.test(fractional || '0')
  ) {
    return null;
  }

  const rhs = padEnd(fractional || '', decimalPlaces, '0').slice(0, decimalPlaces);
  return new BN(`${integer ? integer : ''}${rhs}`, 10);
}

export function decimalStringFromBN(bn: BN, decimalPlaces: number): string {
  let s = bn.abs().toString(10);
  if (decimalPlaces === 0) {
    return s;
  }
  s = padStart(s, decimalPlaces, '0');
  s = s.slice(0, -decimalPlaces) + '.' + s.slice(-decimalPlaces);
  s = s.replace(/0+$/, '');
  if (s === '.') {
    s = '0';
  } else if (s.startsWith('.')) {
    s = '0' + s;
  } else if (s.endsWith('.')) {
    s = s.slice(0, -1);
  }
  if (bn.ltn(0)) {
    s = '-' + s;
  }
  return s;
}

export function bnDivCeil(a: BN, b: BN): BN {
  const remainder = a.mod(b);
  const quotient = a.div(b);
  if (remainder.isZero()) {
    return quotient;
  }
  return quotient.addn(1);
}

export function isString(val: unknown): boolean {
  return typeof val === 'string';
}

export function isEmpty(val: unknown): boolean {
  return val === undefined || val === null;
}

export function bnFromStringOrNumber(value: unknown, decimals = 0): BN | null {
  if (typeof value === 'number') {
    if (decimals === 0) {
      return Number.isInteger(value) ? new BN(value) : null;
    }
    return new BN(Math.floor(value * 10 ** decimals));
  } else if (typeof value === 'string') {
    return bnFromDecimalString(value, decimals);
  }
  return null;
}

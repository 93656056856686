import { postJSON } from 'cb-wallet-http/fetchJSON';
import { TransactionSyncingError } from 'wallet-engine-signing/history/errors';
import {
  V1GetHistoryForAddressRequest,
  V1GetHistoryForAddressResult,
  V1GetTransactionDetailsRequest,
  V1GetTransactionDetailsResult,
} from '@cbhq/instant-api-hooks-wallet-tx-history/types';

import { V2SpamScoreThresholds } from './RPC';
import { V2Transaction } from './types';

export async function getTxHistoryV3TransactionsPage(params: V1GetHistoryForAddressRequest) {
  try {
    const { result } = await postJSON<{ result: V1GetHistoryForAddressResult }>(
      'txHistory/getForAddress',
      params,
      {
        apiVersion: 3,
      },
    );

    return {
      addressMetadata: result.addressMeta,
      nextPaginationToken: result.paginationToken,
      transactions: result.transactions ?? [],
      spamScoreThresholds: result.spamScoreThresholds,
    };
  } catch (err) {
    return {
      addressMetadata: {},
      nextPaginationToken: '',
      transactions: [],
      spamScoreThresholds: {},
      errors: [new TransactionSyncingError((err as Error).message, 'ETH')],
    };
  }
}

export async function getTxHistoryV3TransactionForTxHash(params: V1GetTransactionDetailsRequest) {
  const { result } = await postJSON<{ result: V1GetTransactionDetailsResult }>(
    'txHistory/getTransactionDetails',
    params,
    {
      apiVersion: 3,
    },
  );

  if (!result?.transaction) {
    throw new Error(`Transaction not found for hash ${params.hash}`);
  }

  return result;
}

// TxHistoryV2 Types
export type V2GetTransactionsRequest = {
  address: string;
  network: string;
  paginationToken?: string;
};

export type V2GetTransactionsResult = {
  paginationToken: string;
  spam_score_thresholds?: V2SpamScoreThresholds;
  transactions: V2Transaction[] | null;
};

export async function getTxHistoryV2TransactionsPage(params: V2GetTransactionsRequest) {
  try {
    const { result } = await postJSON<{ result: V2GetTransactionsResult }>(
      'txHistory/getForAddress',
      params,
      {
        apiVersion: 2,
      },
    );

    return {
      addressMetadata: {},
      nextPaginationToken: result.paginationToken,
      transactions: result.transactions ?? [],
      spamScoreThresholds: result.spam_score_thresholds,
    };
  } catch (err) {
    return {
      addressMetadata: {},
      nextPaginationToken: '',
      transactions: [],
      spamScoreThresholds: undefined,
      errors: [new TransactionSyncingError((err as Error).message, 'ETH')],
    };
  }
}

export async function getTxHistoryV2TransactionForTxHash(
  params: V2GetTransactionsRequest & {
    hash: string;
  },
) {
  const { transactions } = await getTxHistoryV2TransactionsPage(params);
  const txMatchingHash = (transactions ?? []).find((tx) => tx.hash === params.hash);

  if (!txMatchingHash) {
    throw new Error(`Transaction not found for hash ${params.hash}`);
  }

  return txMatchingHash;
}

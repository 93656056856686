import { AvatarBaseProps, PaletteBorder } from '@cbhq/cds-common';
import { DotSymbol, DotSymbolProps } from '@cbhq/cds-web/dots/DotSymbol';
import { Avatar } from '@cbhq/cds-web/media/Avatar';

type AssetNetworkProps = {
  /** url string to network logo */
  networkBadgeSrc?: string;
  /**
   *
   * if `assetSrc` is not provided, `assetCurrencyCode` will be used as fallback
   * image
   */
  assetSrc?: string;
  /**
   * used as alt text and fallback for missing image
   */
  assetCurrencyCode?: string;
  assetSize?: AvatarBaseProps['size'] | 's';
  borderColor?: PaletteBorder;
};

export type AssetAvatarProps = AssetNetworkProps &
  Omit<DotSymbolProps, 'iconName' | 'circular' | 'overlap' | 'source'>;

/**
 *
 * `AssetAvatar` displays the asset icon and its network badge
 *
 *  _Built on CDS DotSymbol component_: https://cds.cbhq.net/components/dot-symbol/
 *
 * `assetSrc` prop is used to display asset token icon
 *
 * `networkBadgeSrc` prop is used to display network icon
 */
export function AssetAvatar({
  assetSrc,
  assetCurrencyCode,
  networkBadgeSrc,
  pin = 'bottom-end',
  size = 's',
  assetSize,
  children,
  borderColor,
  ...dotSymbolProps
}: AssetAvatarProps) {
  return (
    <DotSymbol
      {...dotSymbolProps}
      pin={pin}
      overlap="circular"
      size={size}
      source={networkBadgeSrc}
    >
      {assetCurrencyCode ? (
        <Avatar
          size={assetSize === 's' ? undefined : assetSize}
          dangerouslySetSize={assetSize === 's' ? 16 : undefined}
          alt={assetCurrencyCode}
          name={assetCurrencyCode}
          src={assetSrc}
          borderColor={borderColor}
          shape="circle"
        />
      ) : (
        children
      )}
    </DotSymbol>
  );
}

import { useMemo } from 'react';
import Head from 'next/head';

import { APP_NAME } from ':dapp/config/env';
import { Metadata } from ':dapp/utils/getRouteMetadata';

export type AppHeadProps = {
  routeMetadata: Metadata[];
};
export function AppHead({ routeMetadata = [] }: AppHeadProps) {
  const metadata = useMemo(
    () =>
      routeMetadata.map(({ property, content }) => (
        <meta key={property} property={property} content={content} />
      )),
    [routeMetadata],
  );

  const pageTitle = useMemo(() => {
    const titleMeta = routeMetadata.find((meta) => meta.property === 'title');

    if (!titleMeta?.content) return APP_NAME;

    return `${titleMeta.content} | ${APP_NAME}`;
  }, [routeMetadata]);

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta key="og:site_name" property="og:site_name" content={APP_NAME} />
      <meta
        key="viewport-meta"
        name="viewport"
        content="width=device-width, initial-scale=1.0, viewport-fit=cover"
      />
      <link rel="icon" href="/favicon.ico" />
      <link rel="apple-touch-icon" href="/favicon.ico" />
      <link rel="manifest" href="/manifest.json" />
      {metadata}
    </Head>
  );
}

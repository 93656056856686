import { useCallback } from 'react';
import { useClearAuthTokens } from 'cb-wallet-data/stores/Authentication/tokens/useClearAuthTokens';
import { observabilityClient } from 'cb-wallet-data/stores/Observability/classes/ObservabilityClient';

/**
 * useClearAuthTokensAndUnsetUser
 *
 * Hook that:
 * 1. Clears authentication tokens and
 * 2. Unset the userId in the observability client
 *
 * @returns {Function} clearAuthTokensAndUnsetUser
 */
export function useClearAuthTokensAndUnsetUser() {
  const clearAuthTokens = useClearAuthTokens();

  const clearAuthTokensAndUnsetUser = useCallback(() => {
    clearAuthTokens();
    observabilityClient.unsetUserId();
  }, [clearAuthTokens]);

  return clearAuthTokensAndUnsetUser;
}

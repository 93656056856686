import { useMemo, useState } from 'react';
import { useOnMount } from 'cb-wallet-data/hooks/useOnMount';

import { WalletProviderInfo, WalletProviderNetworkInfo } from ':dapp/connection/types';

async function getAvailableNetworks(walletProvider: WalletProviderInfo) {
  const results = await Promise.all(
    walletProvider.networks.map(async (network: WalletProviderNetworkInfo) =>
      walletProvider.connectorManager.isReady(network.id),
    ),
  );
  return results.some((result) => result);
}

const CONNECTION_TIMEOUT = 1000; // 1 second;

async function getAvailableWalletProviders(walletProviders: WalletProviderInfo[]) {
  const ready = await Promise.allSettled(
    walletProviders.map(getAvailableNetworks).map(async (promise) =>
      Promise.race([
        promise,
        new Promise((_, reject) => {
          setTimeout(reject, CONNECTION_TIMEOUT, new Error('timeout'));
        }),
      ]),
    ),
  );
  return walletProviders.filter((p, i) => {
    const result = ready[i];
    if (result.status === 'fulfilled') {
      return result.value;
    }
    return false;
  });
}

type UseAvailableWalletProvidersProps = {
  walletProviders: WalletProviderInfo[];
};

/**
 * This hook filters the available wallet providers based on the networks they support.
 */
export function useAvailableWalletProviders({ walletProviders }: UseAvailableWalletProvidersProps) {
  const [availableWalletProviders, setAvailableProviders] = useState<WalletProviderInfo[]>([]);

  useOnMount(function filterAvailableWalletProviders() {
    (async () => {
      const available = await getAvailableWalletProviders(walletProviders);
      setAvailableProviders(available);
    })();
  });

  return useMemo(() => availableWalletProviders, [availableWalletProviders]);
}

import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { HTTPError } from 'cb-wallet-http/HTTPError';

import { onrampServiceAuthedConfig } from '../config';
import { OnrampAsset } from '../models/OnrampAsset';
import { OnrampPaymentMethod, OnrampPaymentOption } from '../types/OnrampPaymentMethod';
import { OnrampProvider } from '../types/OnrampProvider';

import { useOnrampAuthedGet } from './useOnrampAuthedGet';
import { useOnrampGeoLocation } from './useOnrampGeoLocation';

const STALE_TIME_IN_MS = 30 * 1000;

export const ONRAMP_PAYMENT_METHODS_QUERY_KEY = 'payment-providers/v1/paymentOptions';

export type GetOnrampPaymentMethodsResponse = {
  options: OnrampPaymentOption[];
};

export type OnrampPaymentMethods = Record<
  string,
  {
    providerInfo: OnrampProvider;
    paymentMethods: OnrampPaymentMethod[];
  }
>;

export type UseOnrampPaymentMethodsParams = {
  assetCode?: string | null;
  networkId?: string | null;
  contractAddress?: string | null;
};

type UseOnrampPaymentMethodsProps = {
  asset: OnrampAsset | undefined;
  fiatCode: string;
  countryCode?: string;
  subdivisionCode?: string;
  enabled?: boolean;
  isConnected?: boolean | null;
  includeCoinbaseMgx: boolean;
  includeCoinbaseNative: boolean;
  onError?: (error: Error) => void;
};

export function useOnrampPaymentMethods({
  asset,
  fiatCode,
  countryCode,
  subdivisionCode,
  enabled = true,
  isConnected,
  includeCoinbaseMgx,
  includeCoinbaseNative,
  onError,
}: UseOnrampPaymentMethodsProps): {
  paymentMethods: OnrampPaymentMethods;
  isLoading: boolean;
} {
  const isOnrampStripeEnabled = useIsFeatureEnabled('onramp_stripe_integration');

  const getOnrampPaymentMethods =
    useOnrampAuthedGet<GetOnrampPaymentMethodsResponse>('paymentOptions');

  const shouldEnableQuery = useMemo(
    () => Boolean(enabled && asset?.networkId && asset.code),
    [asset?.code, asset?.networkId, enabled],
  );

  const { data, isInitialLoading } = useQuery({
    queryKey: getQueryKey({ asset, fiatCode, countryCode, subdivisionCode, enabled, isConnected }),
    queryFn: async () =>
      getOnrampPaymentMethods({
        countryCode,
        subdivisionCode,
        fiatCode,
        assetCode: asset?.code,
        networkId: asset?.networkId,
        contractAddress: asset?.contractAddress,
      }),
    enabled: shouldEnableQuery,
    staleTime: STALE_TIME_IN_MS,
    notifyOnChangeProps: ['data'],
    keepPreviousData: true,
    onError: function onQueryError(error: Error) {
      cbReportError({
        error,
        context: 'onramp_payment_methods',
        isHandled: false,
        severity: 'error',
      });
      onError?.(error);
    },
    select: function selectData(response) {
      return selectQueryResponse({
        response,
        includeCoinbaseMgx,
        includeCoinbaseNative,
        includeStripe: isOnrampStripeEnabled,
      });
    },
    retry: function retry(failureCount, error) {
      // don't retry on error code 13 (no aggregator supports)
      if (error instanceof HTTPError && Number(error.code) === 13) {
        return false;
      }

      return failureCount < 3;
    },
  });

  return { paymentMethods: data ?? {}, isLoading: isInitialLoading };
}

export function useQueryOnrampPaymentMethods() {
  const { countryCode, subdivisionCode } = useOnrampGeoLocation();
  const isOnrampStripeEnabled = useIsFeatureEnabled('onramp_stripe_integration');

  const getOnrampPaymentMethods =
    useOnrampAuthedGet<GetOnrampPaymentMethodsResponse>('paymentOptions');

  const queryClient = useQueryClient();

  return useCallback(
    async ({
      asset,
      fiatCode,
      isConnected,
      includeCoinbaseMgx,
      includeCoinbaseNative,
    }: UseOnrampPaymentMethodsProps) => {
      // always use the authed config when isConnected is explicitly set to true
      // this could happen when isConnected is determined by async action (oAuthSDK.getToken) instead of sync action (useIsConnectToCoinbase)
      const options = isConnected
        ? { ...onrampServiceAuthedConfig, withRetailToken: true }
        : undefined;

      const response = await queryClient.fetchQuery({
        queryKey: getQueryKey({
          asset,
          fiatCode,
          countryCode,
          subdivisionCode,
          enabled: true,
          isConnected,
        }),
        queryFn: async function queryOnrampPaymentMethods() {
          return getOnrampPaymentMethods(
            {
              countryCode,
              subdivisionCode,
              fiatCode,
              assetCode: asset?.code,
              networkId: asset?.networkId,
              contractAddress: asset?.contractAddress,
            },
            options,
          );
        },
        staleTime: STALE_TIME_IN_MS,
      });

      return selectQueryResponse({
        response,
        includeCoinbaseMgx,
        includeCoinbaseNative,
        includeStripe: isOnrampStripeEnabled,
      });
    },
    [countryCode, getOnrampPaymentMethods, isOnrampStripeEnabled, queryClient, subdivisionCode],
  );
}

function getQueryKey({
  asset,
  fiatCode,
  countryCode,
  subdivisionCode,
  enabled,
  isConnected,
}: Pick<
  UseOnrampPaymentMethodsProps,
  'asset' | 'countryCode' | 'subdivisionCode' | 'enabled' | 'fiatCode' | 'isConnected'
>) {
  const shouldEnableQuery = Boolean(enabled && asset?.networkId && asset.code);

  return [
    ONRAMP_PAYMENT_METHODS_QUERY_KEY,
    countryCode,
    subdivisionCode,
    fiatCode,
    asset?.code,
    asset?.networkId,
    asset?.contractAddress,
    shouldEnableQuery,
    isConnected,
  ];
}

type SelectQueryResponseParams = {
  response: GetOnrampPaymentMethodsResponse;
  includeStripe: boolean;
  includeCoinbaseMgx: boolean;
  includeCoinbaseNative: boolean;
};

function selectQueryResponse({
  response,
  includeStripe,
  includeCoinbaseMgx,
  includeCoinbaseNative,
}: SelectQueryResponseParams) {
  const sortedOptions = response.options.sort((a, b) => b.priority - a.priority);

  // eslint-disable-next-line wallet/no-spread-in-reduce
  return sortedOptions.reduce<OnrampPaymentMethods>(function reducePaymentMethodsResponse(
    result,
    { provider, paymentMethod, checkoutConfig, availability, labels, priority, lastUsed },
  ) {
    const { id: providerId } = provider;

    // ideally this filtering should be done in the BE
    // but it's not straightforward to do it there
    // and it requires substantial efforts in the BE
    // so we're doing it here for now
    // until BE fully supports all the assets
    if (
      (providerId === 'stripe' && !includeStripe) ||
      (providerId === 'coinbase-mgx' && !includeCoinbaseMgx) ||
      (providerId === 'coinbase-native' && !includeCoinbaseNative)
    ) {
      return result;
    }

    if (!result[providerId]) {
      // eslint-disable-next-line no-param-reassign
      result[providerId] = {
        providerInfo: provider,
        paymentMethods: [],
      };
    }

    const enhancedProviderInfo = {
      ...provider,
      ...checkoutConfig,
    };

    // eslint-disable-next-line no-param-reassign
    result[providerId].providerInfo = enhancedProviderInfo;

    result[providerId].paymentMethods.push({
      ...paymentMethod,
      labels,
      lastUsed,
      disabled: availability.disabled,
      priority,
      provider: enhancedProviderInfo,
      providerPaymentMethodId: checkoutConfig.providerPaymentMethodId,
    });

    return result;
  },
  {});
}

import {
  getEthereumCacheId,
  handleEthereumNudgeBalances,
  initializeEthereumCacheItem,
  refreshEthereumBalances,
} from './Ethereum/history';
import {
  handleEthereumNudgeTransactions,
  refreshEthereumTransactions,
} from './Ethereum/transactions';
import { EthereumAddressConfig, EthereumAddressHistory } from './Ethereum/types';
import {
  getSolanaCacheId,
  handleSolanaNudgeBalances,
  initializeSolanaCacheItem,
  refreshSolanaBalances,
} from './Solana/balances';
import { solanaWebsocketHandler } from './Solana/heliusRPC';
import { handleSolanaNudgeTransactions, refreshSolanaTransactions } from './Solana/transactions';
import { SolanaAddressConfig, SolanaAddressHistory } from './Solana/types';
import {
  getBlockheight,
  getUTXOCacheId,
  initializeUTXOCacheItem,
  refreshUTXOBalances,
} from './UTXO/balances';
import { UTXOAddressConfig, UTXOAddressHistory } from './UTXO/types';
import { NudgeDetails, SupportedChains } from './types';

// AddressConfig is config passed in from the data layer
export type AddressConfig = EthereumAddressConfig | SolanaAddressConfig | UTXOAddressConfig;
// AddressConfigWithHistory is the same AddressConfig object, with the history for that address -- additional
// transactions and balances data -- populated
export type AddressConfigWithHistory =
  | EthereumAddressHistory
  | SolanaAddressHistory
  | UTXOAddressHistory;

/* *
 * GetCacheId
 * Defines a unique cache id based on the address config
 * */

type GetCacheId<C extends AddressConfig> = (addressConfig: C) => string;

type GetCacheIdMap = {
  [C in AddressConfig as C['blockchainSymbol']]: GetCacheId<C>;
};

const getCacheIdMap: GetCacheIdMap = {
  ETH: getEthereumCacheId,
  SOL: getSolanaCacheId,
  BTC: getUTXOCacheId,
  LTC: getUTXOCacheId,
  DOGE: getUTXOCacheId,
};

/* *
 * InitializeCacheItem
 * Creates an empty cache item with default balance values
 * */

type InitializeCacheItem<C extends AddressConfig, H extends AddressConfigWithHistory> = (
  addressConfig: C,
) => H;

type InitializeCacheItemMap = {
  [C in AddressConfig as C['blockchainSymbol']]: InitializeCacheItem<C, any>;
};

const initializeCacheItemMap: InitializeCacheItemMap = {
  ETH: initializeEthereumCacheItem,
  SOL: initializeSolanaCacheItem,
  BTC: initializeUTXOCacheItem,
  LTC: initializeUTXOCacheItem,
  DOGE: initializeUTXOCacheItem,
};

/* *
 * HandleNudge
 * Takes an array of addresses and nudge details and updates balances or transactions
 * for the addresses
 * */

type HandleNudge<H extends AddressConfigWithHistory> = (
  addresses: H[],
  nudgeDetails: NudgeDetails,
) => Promise<{
  updates: H[];
  txHash?: string;
  chainId?: bigint;
  blockheight: number;
  nudgeProvider?: string;
  nudgeProviderNetworkID?: string;
}>;

type HandleNudgeMap = {
  [C in AddressConfigWithHistory as C['blockchainSymbol']]: HandleNudge<C>;
};

async function handleNudgeNoop<H extends AddressConfigWithHistory>(
  _addresses: H[],
  _nudgeDetails: NudgeDetails,
) {
  return Promise.resolve({} as never);
}

const handleNudgeBalancesMap: HandleNudgeMap = {
  ETH: handleEthereumNudgeBalances,
  SOL: handleSolanaNudgeBalances,
  // No nudges for UTXOs :/
  BTC: handleNudgeNoop,
  LTC: handleNudgeNoop,
  DOGE: handleNudgeNoop,
};

const handleNudgeTransactionsMap: HandleNudgeMap = {
  ETH: handleEthereumNudgeTransactions,
  // No nudges for UTXOs and Solana not yet implemented
  SOL: handleSolanaNudgeTransactions,
  BTC: handleNudgeNoop,
  LTC: handleNudgeNoop,
  DOGE: handleNudgeNoop,
};

/* *
 * RefreshBalances
 * Takes an address history item and updates its balances and blockheight
 * */

type RefreshBalances<H extends AddressConfigWithHistory> = (
  addresses: H[],
  lastSyncedBlockheight: number,
  isRetry?: boolean,
) => Promise<{
  updates: H[];
  retries?: H[];
  blockheight: number;
}>;

type RefreshBalancesMap = {
  [H in AddressConfigWithHistory as H['blockchainSymbol']]: RefreshBalances<H>;
};

const refreshBalancesMap: RefreshBalancesMap = {
  ETH: refreshEthereumBalances,
  SOL: refreshSolanaBalances,
  BTC: refreshUTXOBalances,
  LTC: refreshUTXOBalances,
  DOGE: refreshUTXOBalances,
};

type RegisterWebsocketHandlers<H extends AddressConfigWithHistory> = {
  getSubscribeForUpdatesRequest: (addresses: H[]) => Promise<any>;
  getAddressesToRefresh: (ev: MessageEvent<any>, addressConfigs: H[]) => H[];
  getWebsocketEndpoint: () => string;
  getTxHashFromSocketMessage: (ev: MessageEvent) => string;
};

type RegisterWebsocketHandlersMap = {
  [H in AddressConfigWithHistory as H['blockchainSymbol']]: RegisterWebsocketHandlers<H>;
};

const noopWebsocketHandler = {
  getSubscribeForUpdatesRequest: async () => Promise.resolve(),
  getAddressesToRefresh: (_: MessageEvent, __: any) => [],
  getWebsocketEndpoint: () => '',
  getTxHashFromSocketMessage: (_: MessageEvent) => '',
};

const registerWebsocketHandlersMap: RegisterWebsocketHandlersMap = {
  ETH: noopWebsocketHandler,
  SOL: solanaWebsocketHandler(),
  BTC: noopWebsocketHandler,
  LTC: noopWebsocketHandler,
  DOGE: noopWebsocketHandler,
};

/* *
 * RefreshTransactions
 * Takes an address history item and updates its transactions until it reaches
 * the last synced transaction hash
 * */

type RefreshTransactionsType<H> = {
  addressConfigs: H[];
  onRefreshComplete: (updates: H[]) => void;
};

type RefreshTransactions<H extends AddressConfigWithHistory> = (
  params: RefreshTransactionsType<H>,
) => Promise<void>;

type RefreshTransactionsMap = {
  [H in AddressConfigWithHistory as H['blockchainSymbol']]: RefreshTransactions<H>;
};

const refreshTransactionsMap: RefreshTransactionsMap = {
  ETH: refreshEthereumTransactions,
  SOL: refreshSolanaTransactions,
  BTC: async () => ({ updates: [], blockheight: 0 } as never),
  LTC: async () => ({ updates: [], blockheight: 0 } as never),
  DOGE: async () => ({ updates: [], blockheight: 0 } as never),
};

/* *
 * GetBlockheight
 * Called on an interval by polling-enabled chain to determine when
 * to refresh addresses
 * */

type GetBlockheight = () => Promise<number>;

type GetBlockheightMap = {
  [K in SupportedChains]: GetBlockheight;
};

async function getBlockheightNoop() {
  return Promise.resolve(0);
}

const getBlockheightMap: GetBlockheightMap = {
  ETH: getBlockheightNoop,
  SOL: getBlockheightNoop,
  BTC: async () => getBlockheight('BTC'),
  LTC: async () => getBlockheight('LTC'),
  DOGE: async () => getBlockheight('DOGE'),
};

export function getHistoryFunctionsByChain(blockchainSymbol: SupportedChains) {
  return {
    getCacheId: getCacheIdMap[blockchainSymbol],
    getBlockheight: getBlockheightMap[blockchainSymbol],
    initializeCacheItem: initializeCacheItemMap[blockchainSymbol],
    handleNudgeBalances: handleNudgeBalancesMap[blockchainSymbol],
    handleNudgeTransactions: handleNudgeTransactionsMap[blockchainSymbol],
    refreshBalances: refreshBalancesMap[blockchainSymbol],
    refreshTransactions: refreshTransactionsMap[blockchainSymbol],
    registerWebsocketHandlers: registerWebsocketHandlersMap[blockchainSymbol],
  };
}

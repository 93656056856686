import { useMemo } from 'react';
import { useCalculateAggregateBalanceFromAssetInfoList } from 'cb-wallet-data/stores/DefiPortfolio/hooks/useCalculateAggregateBalanceFromAssetInfoList';
import {
  EarnClaimAsset,
  EarnPortfolioAsset,
} from 'cb-wallet-data/stores/DefiPortfolio/models/EarnPortfolioResponse';
import Decimal from 'decimal.js';

type UseEarnPortfolioAssetsAggregateBalanceProps = {
  portfolioAssets: (EarnPortfolioAsset | EarnClaimAsset)[];
};

export function useEarnPortfolioAssetsAggregateBalance({
  portfolioAssets,
}: UseEarnPortfolioAssetsAggregateBalanceProps) {
  const calculateAggregateBalanceFromAssetInfoList =
    useCalculateAggregateBalanceFromAssetInfoList();

  return useMemo(() => {
    return portfolioAssets.reduce(function reduceEarnPortfolioAssets(acc, portfolioAsset) {
      const earnPortfolioAssetTotal = calculateAggregateBalanceFromAssetInfoList([
        portfolioAsset.assetInfo,
      ]);

      return acc.add(earnPortfolioAssetTotal ?? new Decimal(0));
    }, new Decimal(0));
  }, [calculateAggregateBalanceFromAssetInfoList, portfolioAssets]);
}

import { useEffect, useRef } from 'react';
import { UseQueryResult } from '@tanstack/react-query';

import { usePreviousValue } from './usePreviousValue';

/**
 * It's a replacement for useQueries refetchInterval as useQueries creates a
 * separate setInterval for each query what gets out of sync pretty quickly.
 * As a result it generates multiple re-renders instead of just one
 * this hook creates only one setInterval that calls all fetch functions.
 * */
export function useRefetcher(
  refetchFunctions: ((options: {
    throwOnError?: boolean;
    cancelRefetch?: boolean;
  }) => Promise<UseQueryResult>)[],
  active: boolean,
  refetchInterval: number,
) {
  const intervalRef = useRef<null | ReturnType<typeof setInterval>>(null);
  const lastRefetchFunctions = useRef(refetchFunctions);
  lastRefetchFunctions.current = refetchFunctions;

  const previousActive = usePreviousValue(active);

  useEffect(
    function startRefetchInterval() {
      // We just activated fetching let's force fetch
      if (active && previousActive === false) {
        lastRefetchFunctions.current.forEach(async (refetch) =>
          refetch({ throwOnError: false, cancelRefetch: true }),
        );
      }

      if (active) {
        intervalRef.current = setInterval(() => {
          lastRefetchFunctions.current.forEach(async (refetch) =>
            refetch({ throwOnError: false, cancelRefetch: true }),
          );
        }, refetchInterval);
      }

      return () => {
        if (intervalRef.current !== null) {
          clearInterval(intervalRef.current);
        }
      };
    },
    [active, intervalRef, refetchInterval, lastRefetchFunctions, previousActive],
  );
}

import { useCallback, useState } from 'react';
import { useInitializeDb } from 'cb-wallet-data/hooks/initialization/useInitializeDb';
import { useOnMount } from 'cb-wallet-data/hooks/useOnMount';
import { AccountType } from 'cb-wallet-data/stores/Accounts/models/AccountTypes';
import { checkForSoftDeletedAccountsAndDeleteAssociatedDbRecords } from 'cb-wallet-data/stores/Accounts/repair/checkForSoftDeletedAccountsAndDeleteAssociatedDbRecords';
import noop from 'lodash/noop';

import { checkForNetworkChangesAndUpdateAssociatedTables } from ':dapp/datalayer/repair/checkForNetworkChangesAndUpdateAssociatedTables';

export function useInitializeDatabase() {
  const [databaseReady, setDatabaseReady] = useState(false);
  const deriveAccountType = useCallback(() => {
    return AccountType.DAPP_PROVIDER;
  }, []);

  const { initializeDb, isMigrating } = useInitializeDb({
    deriveAccountType,
    overrideRunDataMigration: false,
    preMigrationRepairCurrentPlatform: noop,
    postMigrationRepairCurrentPlatform: async function postMigrationRepair() {
      await checkForSoftDeletedAccountsAndDeleteAssociatedDbRecords();
      await checkForNetworkChangesAndUpdateAssociatedTables();
    },
  });

  useOnMount(() => {
    (async function initDb() {
      await initializeDb();
      setDatabaseReady(true);
    })();
  });

  return { databaseReady, isMigrating };
}

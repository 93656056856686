import { ETHEREUM_PREFIX } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { SolanaNetworkMap } from 'cb-wallet-data/chains/AccountBased/Solana/models/SolanaChain';
import { SOLANA_PREFIX } from 'cb-wallet-data/chains/AccountBased/Solana/utils/chain';

export function getAssetChainPrefix(chainId?: number | null) {
  if (!chainId) return undefined;

  return chainId === SolanaNetworkMap.whitelisted.SOLANA_MAINNET.chainId
    ? SOLANA_PREFIX
    : ETHEREUM_PREFIX;
}

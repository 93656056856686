import { useMemo } from 'react';
import { usePrimaryReceiveWallets } from 'cb-wallet-data/hooks/Receive/usePrimaryReceiveWallets';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { useAuthedGet } from 'cb-wallet-data/HTTP/hooks/useAuthedGet';
import { TxOrUserOp } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import { useIsTronUSDTReceiveEnabled } from 'cb-wallet-data/stores/TronUSDTReceive/hooks/useIsTronUSDTReceiveEnabled';

import {
  UnsupportedTronTransaction,
  UnsupportedTronTransactionProvider,
} from '../models/UnsupportedTronTransaction';

import { dedupePendingTronTransactions } from './dedupePendingTronTransactions';
import { getTransactionFromPtsPayload } from './getTransactionFromPtsPayload';

const QUERY_KEY = 'bridges/pending';

type Response = { metadataList: UnsupportedTronTransaction[] };

export function useFetchPendingTronTransactions(txHistoryTransactions?: TxOrUserOp[]) {
  const isTronUSDTReceiveEnabled = useIsTronUSDTReceiveEnabled();
  const getPTSPendingTransactions = useAuthedGet<Response>(QUERY_KEY, { rawData: true });
  const primaryReceiveWallet = usePrimaryReceiveWallets().get('ETH');

  const primaryAddress = primaryReceiveWallet?.primaryAddress?.toLowerCase();

  const { data, refetch } = useQuery(
    [QUERY_KEY, primaryAddress],
    async function fetchPTSPendingTransactions() {
      if (!primaryAddress) {
        return {
          metadataList: [],
        };
      }

      const response = await getPTSPendingTransactions(
        {
          userWalletAddress: primaryAddress,
          providerName: UnsupportedTronTransactionProvider.BRIDGE_XYZ,
        },
        {
          apiVersion: 1,
          service: 'provider-transaction-service/transactions',
        },
      );

      return response;
    },
    {
      retry: true,
      cacheTime: 1000 * 30,
      notifyOnChangeProps: ['data'],
      enabled: isTronUSDTReceiveEnabled,
    },
  );

  const pendingTronTransactions = useMemo(() => {
    const tronTransactions = data?.metadataList.map((ptsTransaction) =>
      getTransactionFromPtsPayload(
        {
          primaryReceiveWalletId: primaryReceiveWallet?.id ?? '',
          accountId: primaryReceiveWallet?.accountId ?? '',
          walletIndex: primaryReceiveWallet?.selectedIndex ?? 0n,
        },
        ptsTransaction,
      ),
    );

    return dedupePendingTronTransactions({
      tronTransactions,
      txHistoryTransactions,
    });
  }, [
    data?.metadataList,
    primaryReceiveWallet?.accountId,
    primaryReceiveWallet?.id,
    primaryReceiveWallet?.selectedIndex,
    txHistoryTransactions,
  ]);

  return useMemo(() => {
    if (!isTronUSDTReceiveEnabled || !data?.metadataList) {
      return {
        pendingTronTransactions: [],
        refetchPendingTronTransactions: refetch,
      };
    }

    return {
      refetchPendingTronTransactions: refetch,
      pendingTronTransactions,
    };
  }, [data?.metadataList, isTronUSDTReceiveEnabled, pendingTronTransactions, refetch]);
}

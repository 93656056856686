import { AllPossibleBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
import {
  deleteAddresses,
  getAddressIdsForBlockchainAndNetwork,
} from 'cb-wallet-data/stores/Addresses/database';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import {
  deleteTransactions,
  getTransactionIdsForBlockchainAndNetwork,
} from 'cb-wallet-data/stores/Transactions/database';
import {
  deleteWallets,
  getWalletIdsForBlockchainAndNetwork,
} from 'cb-wallet-data/stores/Wallets/database';

type DeleteAssociatedNetworksDbRecordsReturn = {
  walletIdsToBeDeleted: string[];
  transactionIdsToBeDeleted: string[];
  addressIdsToBeDeleted: string[];
};

/**
 * deleteAssociatedNetworksDbRecords
 *
 * It accepts a list of networks as an input parameter and eliminates associated records for
 * the networks including:
 * - wallet
 * - addresses
 * - transactions
 *
 * NOTE: It does not delete the account.
 * NOTE: It does not delete any wallet groups
 * NOTE: It does not hydrate Recoil.
 * */
export async function deleteAssociatedNetworksDbRecords({
  blockchainSymbol,
  network,
}: {
  blockchainSymbol: AllPossibleBlockchainSymbol;
  network: Network;
}): Promise<DeleteAssociatedNetworksDbRecordsReturn> {
  const [walletIdsToBeDeleted, addressIdsToBeDeleted, transactionIdsToBeDeleted] =
    await Promise.all([
      getWalletIdsForBlockchainAndNetwork(blockchainSymbol, network),
      getAddressIdsForBlockchainAndNetwork(blockchainSymbol, network),
      getTransactionIdsForBlockchainAndNetwork(blockchainSymbol, network),
    ]);

  /**
   * design approach:
   * Happy path: deletion of all records goes through as expected, next step will be hydration
   * Unhappy path: deletion of any record fails, the block of code will immediately throw an error
   * log it, but continue.
   * On a subsequent launch, if there are still undesirable networks present, this code will run again.
   */
  const deletionResults = await Promise.allSettled([
    deleteWallets(walletIdsToBeDeleted),
    deleteAddresses(addressIdsToBeDeleted),
    deleteTransactions(transactionIdsToBeDeleted),
  ]);

  const failedDbDeletionReasons: string[] = [];

  deletionResults.forEach((promise) => {
    if (promise.status === 'rejected') {
      failedDbDeletionReasons.push(promise.reason);
    }
  });

  // Throw if any deletions failed.
  if (failedDbDeletionReasons.length > 0) {
    throw new Error(
      `Unable to delete records for network ${
        network.rawValue
      } and blockchain ${blockchainSymbol} for the following reason(s): ${failedDbDeletionReasons.join(
        ', ',
      )}`,
    );
  }

  return {
    walletIdsToBeDeleted,
    transactionIdsToBeDeleted,
    addressIdsToBeDeleted,
  };
}

import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { useOnrampNetworks } from 'cb-wallet-data/stores/Buy/hooks/useOnrampNetworks';
import { memoizeDerivedValue } from 'cb-wallet-data/utils/memoizeDerivedValue';

import { onrampServiceAuthedConfig } from '../config';
import { OnrampAsset, OnrampAssetResponse } from '../models/OnrampAsset';
import { OnrampNetwork } from '../types/OnrampNetwork';

import { useOnrampAuthedGet } from './useOnrampAuthedGet';
import { useOnrampGeoLocation } from './useOnrampGeoLocation';

const STALE_TIME_IN_MS = 60 * 1000;

export type GetOnrampAssetsResponse = {
  assets: OnrampAssetResponse[];
};

type UseOnrampAssetsProps = {
  fiatCode: string;
  countryCode?: string;
  subdivisionCode?: string;
  enabled?: boolean;
  walletGroupId: string | undefined;
  suspense?: boolean;
  isConnected?: boolean | null;
};

export function useOnrampAssets({
  fiatCode,
  countryCode,
  subdivisionCode,
  walletGroupId,
  isConnected,
  enabled = true,
  suspense = false,
}: UseOnrampAssetsProps): {
  data: OnrampAsset[];
  isLoading: boolean;
} {
  const getAssetList = useOnrampAuthedGet<GetOnrampAssetsResponse>('assetList');
  const networks = useOnrampNetworks({
    fiatCode,
    countryCode,
    subdivisionCode,
    enabled,
    walletGroupId,
  });

  const { data, isInitialLoading } = useQuery({
    queryKey: getQueryKey({ fiatCode, countryCode, subdivisionCode, enabled, isConnected }),
    queryFn: async () => getAssetList({ countryCode, subdivisionCode, fiatCode }),
    staleTime: STALE_TIME_IN_MS,
    notifyOnChangeProps: ['data'],
    enabled,
    suspense,
  });

  const assets = selectOnrampAssetData({ assets: data?.assets, networks: networks.data });

  return {
    data: assets,
    isLoading: isInitialLoading,
  };
}

type Params = {
  fiatCode: string;
  networks: OnrampNetwork[];
  isConnected?: boolean | null;
};

export function useQueryOnrampAssets() {
  const queryClient = useQueryClient();
  const { countryCode, subdivisionCode } = useOnrampGeoLocation();
  const getAssetList = useOnrampAuthedGet<GetOnrampAssetsResponse>('assetList');

  return useCallback(
    async ({ fiatCode, networks, isConnected }: Params) => {
      // always use the authed config when isConnected is explicitly set to true
      // this could happen when isConnected is determined by async action (function call) instead of sync action (hook)
      const options = isConnected
        ? { ...onrampServiceAuthedConfig, withRetailToken: true }
        : undefined;

      const response = await queryClient.fetchQuery({
        queryKey: getQueryKey({
          fiatCode,
          countryCode,
          subdivisionCode,
          enabled: true,
          isConnected,
        }),
        queryFn: async function queryOnrampAssets() {
          return getAssetList(
            {
              countryCode,
              subdivisionCode,
              fiatCode,
            },
            options,
          );
        },
        staleTime: STALE_TIME_IN_MS,
      });

      return selectOnrampAssetData({ assets: response.assets, networks });
    },
    [countryCode, getAssetList, queryClient, subdivisionCode],
  );
}

export function getQueryKey({
  fiatCode,
  countryCode,
  subdivisionCode,
  enabled,
  isConnected,
}: Pick<
  UseOnrampAssetsProps,
  'fiatCode' | 'countryCode' | 'subdivisionCode' | 'enabled' | 'isConnected'
>) {
  return [
    'payment-providers/v1/assetList',
    countryCode,
    subdivisionCode,
    fiatCode,
    enabled,
    isConnected,
  ];
}

export function selectOnrampAssetData({
  assets,
  networks,
}: {
  assets: OnrampAssetResponse[] | undefined;
  networks: OnrampNetwork[];
}) {
  if (!assets) {
    return [];
  }
  const supportedChainIdsSet = new Set(networks.map((network) => network.chainId));

  // Filter out assets whose network is not supported
  const filteredAssets = assets.filter((asset) => supportedChainIdsSet.has(asset.chainId));

  return memoizeDerivedValue(filteredAssets ?? [], () => {
    return filteredAssets.map((asset) => new OnrampAsset(asset)) ?? [];
  });
}

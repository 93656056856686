import { useCallback, useMemo } from 'react';
import { triggerCopyAddressClick } from 'cb-wallet-analytics/wallet-connection';
import { useFetchDomain } from 'cb-wallet-data/stores/DecentralizedID/hooks/useFetchDomains';
import { getTruncatedAddress } from 'cb-wallet-data/utils/getTruncatedAddress';
import { useClipboard } from 'wallet-cds-web/hooks/useClipboard';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, HStack } from '@cbhq/cds-web/layout';
import { useToast } from '@cbhq/cds-web/overlays/useToast';
import { PressableOpacity } from '@cbhq/cds-web/system';
import { TextBody, TextProps } from '@cbhq/cds-web/typography';
import { TextHeadline } from '@cbhq/cds-web/typography/TextHeadline';
import { TextLabel2 } from '@cbhq/cds-web/typography/TextLabel2';

export function formatDomainName(input: string) {
  const pattern = /\.(?=[a-z]+)/;
  const parts = input.split(pattern);
  if (parts.length > 1) {
    return [parts[0], parts.slice(1).join('.')];
  }
  return [input];
}

export type FormattedAddressWithDomainProps = {
  address: string;
  compact?: boolean;
  variant?: 'foreground' | 'foregroundMuted';
  showCopyButton?: boolean;
  textComponentName?: 'TextBody';
  domainColor?: TextProps['color'];
};

type Domain = {
  domain?: string;
};

export type FormattedAddressProps = FormattedAddressWithDomainProps & Domain;

const textComponent = {
  TextBody,
};

function FormattedAddress({
  domain,
  address,
  compact = false,
  variant = 'foreground',
  showCopyButton = false,
  textComponentName,
  domainColor,
}: FormattedAddressProps) {
  const copy = useClipboard();
  const toast = useToast();
  const formattedDomain = formatDomainName(domain ?? '');

  const Text = useMemo(() => {
    if (textComponentName === 'TextBody') return textComponent[textComponentName];

    if (compact) return TextLabel2;

    return TextHeadline;
  }, [textComponentName, compact]);

  const testID = compact ? 'formatted-address-compact' : 'formatted-address';

  const handleCopyPress = useCallback(async () => {
    toast.clearQueue();
    triggerCopyAddressClick();
    copy(address);
  }, [address, copy, toast]);

  if (domain && formattedDomain.length > 1) {
    return (
      <Box>
        <Text as="span" color={domainColor}>
          {formattedDomain[0]}
        </Text>
        <Text as="span" color={domainColor || 'foregroundMuted'}>
          <span>.</span>
          {formattedDomain[1]}
        </Text>
        {showCopyButton && (
          <PressableOpacity onPress={handleCopyPress}>
            <Icon
              testID="copy-address-button"
              spacingStart={1}
              size="s"
              name="copy"
              color="foreground"
            />
          </PressableOpacity>
        )}
      </Box>
    );
  }

  return (
    <HStack gap={1} alignItems="center">
      <Text as="span" color={variant} testID={testID}>
        {getTruncatedAddress(address)}
      </Text>
      {showCopyButton && (
        <PressableOpacity onPress={handleCopyPress}>
          <Icon testID="copy-address-button" size="s" name="copy" color="foreground" />
        </PressableOpacity>
      )}
    </HStack>
  );
}

export function FormattedAddressWithDomain({
  address,
  compact = false,
  variant = 'foreground',
  showCopyButton = false,
  textComponentName,
  domainColor,
}: FormattedAddressWithDomainProps) {
  const domain = useFetchDomain(address);
  return (
    <FormattedAddress
      address={address}
      compact={compact}
      variant={variant}
      showCopyButton={showCopyButton}
      textComponentName={textComponentName}
      domainColor={domainColor}
      domain={domain}
    />
  );
}

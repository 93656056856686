import keyBy from 'lodash/keyBy';
import { NudgeDetails } from 'wallet-engine-signing/history/types';

import { getBalancesForCustomNetworks, getBalancesForSupportedNetworks } from './balances';
import { EthereumAddressConfig, EthereumAddressHistory } from './types';

export function getEthereumCacheId(addressConfig: EthereumAddressConfig) {
  const { address, chainId } = addressConfig;
  return `${address.toLowerCase()}/${chainId}`;
}

export function initializeEthereumCacheItem(
  addressConfig: EthereumAddressConfig,
): EthereumAddressHistory {
  return {
    ...addressConfig,
    etherBalance: BigInt(0),
    erc20Balances: [],
    errors: [],
    unsyncedTransactions: [],
    spamScoreThresholds: {},
    addressMetadata: {},
  };
}

export async function handleEthereumNudgeBalances(
  addresses: EthereumAddressHistory[],
  nudgeDetails: NudgeDetails,
) {
  const { chainId, txHash } = nudgeDetails;

  const { updates } = await refreshEthereumBalances(addresses, undefined);

  return {
    updates,
    blockheight: 0,
    txHash,
    chainId,
  };
}

export async function refreshEthereumBalances(
  addresses: EthereumAddressHistory[] | EthereumAddressConfig[],
  _lastSyncedBlockheight?: number,
): Promise<{
  updates: EthereumAddressHistory[];
  blockheight: number;
}> {
  const addressesOnSupportedNetworks = addresses.filter((address) => address.isNudgeEnabled);
  const addressesOnCustomNetworks = addresses.filter((address) => !address.isNudgeEnabled);

  const refreshedAddressesResults = await Promise.allSettled([
    getBalancesForSupportedNetworks(addressesOnSupportedNetworks),
    getBalancesForCustomNetworks(addressesOnCustomNetworks),
  ]);

  const refreshedAddresses = refreshedAddressesResults
    .filter(({ status }) => status === 'fulfilled')
    .map(({ value }: any) => value as EthereumAddressHistory[])
    .flat(1);

  return {
    updates: refreshedAddresses,
    blockheight: 0,
  };
}

export function didHistoryItemChange(
  historyItem: EthereumAddressHistory,
  updatedHistoryItem: EthereumAddressHistory,
): boolean {
  const balanceChanged = historyItem.etherBalance !== updatedHistoryItem.etherBalance;

  const newErc20Balance =
    historyItem.erc20Balances.length !== updatedHistoryItem.erc20Balances.length;

  const existingERC20sByContractAddress = keyBy(historyItem.erc20Balances, 'contractAddress');
  const erc20Changes = updatedHistoryItem.erc20Balances.find((updatedErc20Token) => {
    const existingErc20Token = existingERC20sByContractAddress[updatedErc20Token.contractAddress];
    const tokenBalanceChanged = existingErc20Token?.balance !== updatedErc20Token.balance;
    return tokenBalanceChanged;
  });

  return balanceChanged || newErc20Balance || !!erc20Changes;
}

import { walletBlockchainUrl } from 'cb-wallet-env/env';
import { postJSON as _postJSON } from 'cb-wallet-http/fetchJSON';

// TODO @darion once we move chain enablement to wallet engine we can use the chain
// enablement definitions/types instead of this
export type UTXOChains = 'BTC' | 'DOGE' | 'LTC';

const BlockchainIndexerURLs = {
  BTC: `${walletBlockchainUrl}/btc`,
  DOGE: `${walletBlockchainUrl}/doge`,
  LTC: `${walletBlockchainUrl}/ltc`,
};

type PostJSONParams = {
  blockchainSymbol: UTXOChains;
  endpoint: string;
  body?: Record<string, any>;
  options?: Record<string, any>;
};

export async function postJSON<T>({
  blockchainSymbol,
  endpoint,
  body = {},
  options = {},
}: PostJSONParams) {
  const url = `${BlockchainIndexerURLs[blockchainSymbol]}${endpoint}`;
  return _postJSON<T>(url, body, {
    isThirdParty: true,
    returnHeaders: true,
    ...options,
  });
}

import { postJSON } from 'cb-wallet-http/fetchJSON';

import {
  GetEditApprovalDataObj,
  GetEditApprovalDataResult,
  GetNFTApprovalsResult,
  GetTokenApprovalsResult,
} from '../types';

const GET_TOKEN_APPROVALS = 'getTokenApprovals';
const GET_NFT_APPROVALS = 'getNFTApprovals';
const GET_EDIT_APPROVAL_DATA = 'getEditApprovalTxData';

export async function getTokenApprovals(addresses: string[]) {
  const { result } = await postJSON<GetTokenApprovalsResult>(GET_TOKEN_APPROVALS, {
    addresses,
  });

  return result;
}

export async function getNFTApprovals(addresses: string[]) {
  const { result } = await postJSON<GetNFTApprovalsResult>(GET_NFT_APPROVALS, {
    addresses,
  });

  return result;
}

export async function getEditTokenApprovalData(data: GetEditApprovalDataObj) {
  const {
    result: {
      txData: { data: result },
    },
  } = await postJSON<GetEditApprovalDataResult>(GET_EDIT_APPROVAL_DATA, data);

  return result;
}

import { EthereumNetworkSetting } from 'cb-wallet-data/chains/AccountBased/Ethereum/config';
import { NetworkSetting } from 'cb-wallet-data/stores/Networks/models/NetworkSetting';

export const ETH_CHAIN_ALIASES = {
  1: 'ETHEREUM',
  56: 'BINANCE_SMART_CHAIN',
  250: 'FANTOM',
  100: 'GNOSIS',
  137: 'POLYGON',
  10: 'OPTIMISM',
  42161: 'ARBITRUM',
  43114: 'AVALANCHE_C_CHAIN',
  8453: 'BASE',
  7000: 'ZETACHAIN',
  7777777: 'ZORA',
  // Testnets
  11155111: 'SEPOLIA',
  17000: 'HOLESKY',
  97: 'BNB_TESTNET',
  11155420: 'OP_SEPOLIA',
  4002: 'FTM_TESTNET',
  43113: 'AVAX_FUJI',
  84532: 'BASE_SEPOLIA',
  421614: 'ARBITRUM_SEPOLIA',
} as const;

function getEthNetworkChainIdsFromNetworkSetting(networkSetting: NetworkSetting) {
  const networksForBlockchain = networkSetting.allNetworks.reduce(function getNetworksForBlockchain(
    networks,
    network,
  ) {
    const { network: networkSettingItem } = network;
    const chain = networkSettingItem.asChain();

    if (!chain) return networks;

    const { chainId } = chain;
    const name = ETH_CHAIN_ALIASES[chainId as keyof typeof ETH_CHAIN_ALIASES];

    if (!name) return networks;

    return { ...networks, [name]: chainId };
  },
  {} as Record<(typeof ETH_CHAIN_ALIASES)[keyof typeof ETH_CHAIN_ALIASES], number>);

  return networksForBlockchain;
}

export const allEthNetworkChainIds =
  getEthNetworkChainIdsFromNetworkSetting(EthereumNetworkSetting);

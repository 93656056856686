import dynamic from 'next/dynamic';

import { useGlobalDrawer } from ':dapp/components/GlobalDrawer/GlobalDrawerProvider';

import { EntryStepFallback } from './EntryStepFallback';

const SwapSidebar = dynamic(async () => import('./SwapSidebar').then((mon) => mon.SwapSidebar), {
  loading: EntryStepFallback,
  ssr: false,
});

export function SwapSidebarDrawer() {
  const { closeDrawer } = useGlobalDrawer();
  return <SwapSidebar onClose={closeDrawer} />;
}

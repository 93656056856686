export enum AppDisplayMode {
  FULLSCREEN = 'fullscreen',
  STANDALONE = 'standalone',
  MINIMAL_UI = 'minimal-ui',
  BROWSER = 'browser',
}

export function getAppDisplayMode() {
  // when app runs in its own window, with minimal browser UI(like a PWA or native app)
  if (window.matchMedia('(display-mode: standalone)').matches) {
    return AppDisplayMode.STANDALONE;
  }
  // when app takes up the entire screen, without any browser UI.
  if (window.matchMedia('(display-mode: fullscreen)').matches) {
    return AppDisplayMode.FULLSCREEN;
  }
  // when app runs in a window with minimal browser UI(more than standalone but less than browser)
  if (window.matchMedia('(display-mode: minimal-ui)').matches) {
    return AppDisplayMode.MINIMAL_UI;
  }
  // when app runs in a regular browser tab, with full browser UI
  return AppDisplayMode.BROWSER;
}

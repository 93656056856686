import { useCallback, useMemo, useState } from 'react';
import { dataMigrationRunner } from 'cb-wallet-data/DataMigrationRunner/DataMigrationRunner';
import { AccountType } from 'cb-wallet-data/stores/Accounts/models/AccountTypes';
import { useIsDataMigrationRunnerKilled } from 'cb-wallet-data/stores/AppLoad/hooks/useIsDataMigrationRunnerKilled';

type InitializeDbOptions = {
  overrideRunDataMigration?: boolean;
  accountType?: AccountType;
};
/**
 * Migrates users from single account to multi-account
 */
export function useMigrateDb() {
  const isDataMigrationRunnerKilled = useIsDataMigrationRunnerKilled();
  const [isMigrating, setIsMigrating] = useState(false);
  // This is needed to migrate legacy users to multi-account.
  // Before multi-account, signing device was used as a way to identify the user's single account type.

  const migrateDb = useCallback(
    async function migrateDb({ overrideRunDataMigration, accountType }: InitializeDbOptions) {
      // NOTE Database is opened
      // NOTE Do not hydrate yet
      if (!isDataMigrationRunnerKilled || overrideRunDataMigration) {
        // data migrations should run right after the database is opened
        setIsMigrating(true);
        // NOTE dataMigrationRunner.migrate has logs internally
        await dataMigrationRunner.migrate({
          accountType,
        });
        // TODO WALL-14368 [DMR] [RN] Block App until Migrations run successfully
        setIsMigrating(false);
      }
    },
    [isDataMigrationRunnerKilled],
  );

  return useMemo(
    () => ({
      migrateDb,
      isMigrating,
    }),
    [migrateDb, isMigrating],
  );
}

import { useCallback } from 'react';
import { useInterval } from 'cb-wallet-data/hooks/useInterval';
import { useOnMount } from 'cb-wallet-data/hooks/useOnMount';
import { StoreKeys_eTagCuratedAssets } from 'cb-wallet-data/stores/AssetManagement/api';
import { useIsCrossPlatformHiddenAssetsKilled } from 'cb-wallet-data/stores/AssetManagement/hooks/useIsCrossPlatformHiddenAssetsKilled';
import { useIsSpamScoreEnabled } from 'cb-wallet-data/stores/AssetManagement/hooks/useIsSpamScoreEnabled';
import { CURATED_ASSET_SYNCING_INTERVAL } from 'cb-wallet-data/utils/intervalConstants';
import { Store } from 'cb-wallet-store/Store';

import { syncCuratedAssetSettings } from '../utils/syncCuratedAssetSettings';

import { useHydrateCuratedAssetSettingsFromDatabase } from './useHydrateCuratedAssetSettings';
import { useMarkAssetAsSpamOrWhitelist } from './useMarkAssetAsSpamOrWhitelist';

/**
 * This hook should only be included in only one component.
 * It syncs curated assests once when called and then every 30 mins.
 * It will start marking wallets and collectibles as spam or whitelist when the sync is completed
 */
export function useSyncCuratedAssetSettings(): void {
  const isCrossPlatformHiddenAssetsKilled = useIsCrossPlatformHiddenAssetsKilled();
  const hydrateCuratedAssetSettings = useHydrateCuratedAssetSettingsFromDatabase();
  const markAssetAsSpamOrWhitelist = useMarkAssetAsSpamOrWhitelist();
  const isSpamTokenEnabled = useIsSpamScoreEnabled();

  const checkFeatureFlagAndSync = useCallback(
    async function checkFeatureFlagAndSync() {
      if (isSpamTokenEnabled) {
        Store.set(StoreKeys_eTagCuratedAssets, undefined);
        return;
      }

      if (!isCrossPlatformHiddenAssetsKilled) {
        const hasSynced = await syncCuratedAssetSettings();
        if (hasSynced) {
          await hydrateCuratedAssetSettings();
          await markAssetAsSpamOrWhitelist();
        }
      }
    },
    [
      hydrateCuratedAssetSettings,
      isCrossPlatformHiddenAssetsKilled,
      isSpamTokenEnabled,
      markAssetAsSpamOrWhitelist,
    ],
  );

  useOnMount(() => {
    checkFeatureFlagAndSync();
  });

  useInterval(checkFeatureFlagAndSync, {
    interval: !isCrossPlatformHiddenAssetsKilled ? CURATED_ASSET_SYNCING_INTERVAL : null,
  });
}

import { useMemo } from 'react';
import { useSettingsSearch as useSettingsSearchFromDataLayer } from 'cb-wallet-data/stores/SettingsRedesign/hooks/useSettingsSearch';
import { SettingCategoryConfig } from 'cb-wallet-data/stores/SettingsRedesign/types';

import { useSettingConfigs } from ':dapp/pages/settings/hooks/useSettingConfigs';

type useSettingsSearchProps = {
  query: string;
};

export function useSettingsSearch({ query }: useSettingsSearchProps) {
  const configs = useSettingConfigs();
  const settingsSuggestions = useMemo(() => {
    return configs.reduce((suggestions: SettingCategoryConfig[], config) => {
      suggestions.push(config);
      for (const subCategory of config.subCategories ?? []) {
        suggestions.push(subCategory);
      }
      return suggestions;
    }, []);
  }, [configs]);

  return useSettingsSearchFromDataLayer({ suggestions: settingsSuggestions, query });
}

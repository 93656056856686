import { ActionType, ComponentType } from '@cbhq/client-analytics';

import { logDataEvent, logEvent } from '../utils/log';
import {
  CollectibleSettingEventProps,
  WalletSettingsEventProps,
} from '../utils/types/assetManagement';

export const EVENTS = {
  addTokenButtonClicked: 'token_management.add_token_button.clicked',
  reportHiddenAssetsStatus: 'token_management.hidden_assets_status',
  unhideLowBalanceCrypto: 'token_management.unhide_low_balance_crypto',
  hideLowBalanceCrypto: 'token_management.hide_low_balance_crypto',
  hideCryptoSucceed: 'token_management.hide_crypto.succeed',
  hideCryptoFailed: 'token_manamgent.hide_crypto.failed',
  hideNFTSucceed: 'token_management.hide_nft.succeed',
  hideNFTFailed: 'token_management.hide_nft.failed',
  unhideCryptoSucceed: 'token_management.unhide_crypto.succeed',
  unhideCryptoFailed: 'token_manamgent.unhide_crypto.failed',
  unhideNFTSucceed: 'token_management.unhide_nft.succeed',
  unhideNFTFailed: 'token_management.unhide_nft.failed',
  updateRemoteWalletSettingsSucceed: 'token_management.update_remote_wallet_settings.succeed',
  updateRemoteWalletSettingsFailed: 'token_management.update_remote_wallet_settings.failed',
  updateRemoteCollectibleSettingsSucceed:
    'token_management.update_remote_collectible_settings.succeed',
  updateRemoteCollectibleSettingsFailed:
    'token_management.update_remote_collectible_settings.failed',
  syncUserAssetSettingsSucceed: 'token_management.sync_user_asset_settings.succeed',
  syncUserAssetSettingsFailed: 'token_management.sync_user_asset_settings.failed',
  assetManagementScreenViewed: 'token_management.asset_management_screen.viewed',
  changeTabButtonClicked: 'token_management.asset_management_screen.change_tab_button.clicked',
  searchBarType: 'token_management.asset_management_screen.search_bar.type',
  hideAssetSucceedScreenViewed: 'token_management.hide_asset_succeed_screen.viewed',
  hideAssetSucceedScreenBackButtonClicked:
    'token_management.hide_asset_succeed_screen.back_button.clicked',
  hideOptionsViewed: 'token_management.hide_options.viewed',
  hideOptionsHideButtonClicked: 'token_management.hide_options.hide_button.clicked',
  hideOptionsUndoButtonClicked: 'token_management.hide_options.undo_button.clicked',
  countHiddenCrypto: 'token_management.hidden_crypto_count',
  countHiddenNFTs: 'token_management.hidden_nft_count',
  countCrypto: 'token_management.crypto_count',
  countNFT: 'token_management.nft_count',
};

function logSettingsChange<T>(eventName: string, eventId: string, eventProperties: T) {
  return logDataEvent(eventName, {
    loggingId: eventId,
    ...eventProperties,
  });
}

export function logHideWalletSucceed(props: WalletSettingsEventProps) {
  logSettingsChange(EVENTS.hideCryptoSucceed, '6182044b-f0e9-4fd8-876a-a92fd32690d0', props);
}

export function logUnhideWalletSucceed(props: WalletSettingsEventProps) {
  logSettingsChange(EVENTS.unhideCryptoSucceed, 'bb7f1726-1152-486b-9cda-0f81c69f213c', props);
}

export function logHideWalletFailed(props: WalletSettingsEventProps) {
  logSettingsChange(EVENTS.hideCryptoFailed, '9a16a372-5f4b-440c-ad11-0566cc11845c', props);
}

export function logUpdateRemoteWalletSettingsFailed(props: WalletSettingsEventProps) {
  logSettingsChange(
    EVENTS.updateRemoteWalletSettingsFailed,
    '63c6ec54-080a-449b-84ce-f2d3d358bc7a',
    props,
  );
}

export function logUpdateRemoteWalletSettingsSucceed(props: WalletSettingsEventProps) {
  logSettingsChange(
    EVENTS.updateRemoteWalletSettingsSucceed,
    '3b003053-16e2-49bf-be8f-11d96dfc1790',
    props,
  );
}

export function logUpdateRemoteCollectibleSettingsSucceed(props: CollectibleSettingEventProps) {
  logSettingsChange(
    EVENTS.updateRemoteCollectibleSettingsSucceed,
    'b331fc01-6c5c-405f-9717-714eff4d6b9f',
    props,
  );
}

export function logUpdateRemoteCollectibleSettingsFailed(props: CollectibleSettingEventProps) {
  logSettingsChange(
    EVENTS.updateRemoteCollectibleSettingsFailed,
    'c2f3fd13-e561-4666-b9da-93ed8ef13bbc',
    props,
  );
}

export function logUnhideWalletFailed(props: WalletSettingsEventProps) {
  logSettingsChange(EVENTS.unhideCryptoFailed, '09f3e342-2db9-43e1-b2ef-48c2f07271b3', props);
}

export function logHideCollectibleSucceed(props: CollectibleSettingEventProps) {
  logSettingsChange(EVENTS.hideNFTSucceed, '0f59cf16-1af2-4e6c-b637-a0186cc46f29', props);
}

export function logUnhideCollectibleSucceed(props: CollectibleSettingEventProps) {
  logSettingsChange(EVENTS.unhideNFTSucceed, 'db2a79be-6e8d-47a1-b5f2-1ac9730518a3', props);
}

export function logHideCollectibleFailed(props: CollectibleSettingEventProps) {
  logSettingsChange(EVENTS.hideNFTFailed, '9432c7f9-ec4c-4312-9b03-d9698a4ba059', props);
}

export function logUnhideCollectibleFailed(props: CollectibleSettingEventProps) {
  logSettingsChange(EVENTS.unhideNFTFailed, 'a92f8cb8-7381-4f3d-9c5f-0d5dd3894372', props);
}

export function triggerUserAssetsSyncSucceed() {
  logEvent(
    EVENTS.syncUserAssetSettingsSucceed,
    {
      loggingId: 'a380f351-616d-4e44-a7fa-6ed931247e9e',
      action: ActionType.view,
      componentType: ComponentType.unknown,
    },
    undefined,
    true,
  );
}

export function triggerUserAssetsSyncFailed() {
  logEvent(EVENTS.syncUserAssetSettingsFailed, {
    loggingId: 'e52b336f-eff8-461a-8cd8-7dee4de2cc51',
    action: ActionType.view,
    componentType: ComponentType.unknown,
  });
}

export function triggerHideAssetOptionsViewed() {
  logEvent(EVENTS.hideOptionsViewed, {
    loggingId: 'b4b82d37-14ac-4a05-ad8c-bbc12b8d973c',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

type HideButtonVariant = 'HIDE' | 'HIDE_AND_REPORT';

export function triggerHideOptionClicked(option: HideButtonVariant) {
  logEvent(EVENTS.hideOptionsHideButtonClicked, {
    loggingId: '5e25f2a7-2a75-4791-b5c0-6d7068acc14a',
    action: ActionType.click,
    componentType: ComponentType.button,
    option,
  });
}

export function triggerUndoOptionClicked() {
  logEvent(EVENTS.hideOptionsUndoButtonClicked, {
    loggingId: 'b09db2b2-99a7-4d1e-9f82-4d15830c49fe',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function triggerTabChange(props: { nextStatus: 'crypto' | 'nft' }) {
  logEvent(EVENTS.changeTabButtonClicked, {
    action: ActionType.click,
    componentType: ComponentType.button,
    loggingId: '0f09b95e-61f2-4001-9869-411c12a818a6',
    ...props,
  });
}

export function triggerSearchAsset(props: { tab: 'crypto' | 'nft'; results: string }) {
  logEvent(EVENTS.searchBarType, {
    action: ActionType.change,
    componentType: ComponentType.search_bar,
    loggingId: 'db1b67ee-8b7c-47b9-b969-1f21bcd203d1',
    ...props,
  });
}

export function triggerLowBalanceWalletsHidden() {
  logEvent(EVENTS.hideLowBalanceCrypto, {
    action: ActionType.change,
    componentType: ComponentType.button,
    loggingId: 'ad4843cc-1616-403f-822d-14c93dd45120',
  });
}

export function triggerLowBalanceWalletsUnhidden() {
  logEvent(EVENTS.unhideLowBalanceCrypto, {
    action: ActionType.change,
    componentType: ComponentType.button,
    loggingId: 'c883e328-dd40-4e62-9bd3-ff41190bc267',
  });
}

export type HiddenAssetsReportProperties = {
  hiddenCollectiblesCount: number;
  hiddenAssetsCount: number;
  spamHiddenCollectiblesCount: number;
  spamHiddenAssetsCount: number;
  hasAlreadyHiddenAsset: boolean;
  hasHiddenLowBalanceWallets: boolean;
  isSpamScoreEnabled: boolean;
};

export function triggerReportHiddenAssets(properties: HiddenAssetsReportProperties) {
  logDataEvent(EVENTS.reportHiddenAssetsStatus, {
    loggingId: 'e502bc64-6e94-4218-8640-f73e6d363a6e',
    action: ActionType.view,
    componentType: ComponentType.page,
    ...properties,
  });
}

export function triggerAssetManagementScreenViewed() {
  logEvent(EVENTS.assetManagementScreenViewed, {
    loggingId: '51eeca12-6ed2-41ec-bdb9-2fe6583403d4',
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export type ReportTokenProperties = { count: number };

export function triggerReportCrypto({ count }: ReportTokenProperties) {
  logEvent(EVENTS.countCrypto, {
    loggingId: '4f94fcea-0270-4de0-8bb1-e85f45973ca8',
    action: ActionType.view,
    componentType: ComponentType.page,
    count,
  });
}

export function triggerReportNFTs({ count }: ReportTokenProperties) {
  logEvent(EVENTS.countNFT, {
    loggingId: 'b05ccae4-b08b-43ec-8e57-a7ae16b12dc0',
    action: ActionType.view,
    componentType: ComponentType.page,
    count,
  });
}

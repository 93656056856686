import { useCallback } from 'react';
import { TextBody } from '@cbhq/cds-web/typography';

export function getLinkHelp(locale = 'en') {
  return `https://help.coinbase.com/${locale}/wallet`;
}

const URL_REGEX =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
const ALPHABETIC_REGEX = /[\W_]+/g;

export function sanitizeTextWithLinks(text: string) {
  return text
    .replaceAll('\n', ' \n') // this prevents an issue w a word followed by new line being included in the link
    .split(' ')
    .map((word, index, array) => {
      const key = word.replace(ALPHABETIC_REGEX, '');
      if (URL_REGEX.test(word)) {
        return <LinkWithSpace url={word.trim()} key={`TextIncludeLinks-${key}`} />;
      }
      return index !== array.length - 1 ? `${word} ` : word;
    });
}

type LinkWithSpaceProps = { url: string };

function LinkWithSpace({ url }: LinkWithSpaceProps) {
  const openLink = useCallback(() => {
    // TODO: before going live, add a check/warning for possibly unsafe url before sending the user to it
    window.open(url, '_blank');
  }, [url]);

  return (
    <TextBody as="p" onClick={openLink} color="primary" overflow="break">
      {`${url} `}
    </TextBody>
  );
}

import { useMemo } from 'react';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { useRecoilValue } from 'recoil';

import { walletsByWalletGroupAtom } from '../state';

/**
 * Provides all list of wallets for the given wallet group ids.
 *
 * @returns Wallets matching the params.
 */
export function useWalletsForWalletGroupIds(walletGroupIds: (string | undefined)[]): Wallet[] {
  const walletsByWalletGroup = useRecoilValue(walletsByWalletGroupAtom);

  return useMemo(
    function filterWalletsByWalletGroups() {
      if (!walletGroupIds) {
        return [];
      }

      // We are not spreading the reduced object here so it is fine
      // eslint-disable-next-line wallet/no-spread-in-reduce
      const activeWallets = walletGroupIds.reduce<Wallet[]>(function getWalletsForWalletGroupIds(
        acc,
        walletGroupId,
      ) {
        if (walletGroupId) {
          acc.push(...(walletsByWalletGroup[walletGroupId] ?? []));
        }
        return acc;
      },
      []);

      return activeWallets;
    },
    [walletsByWalletGroup, walletGroupIds],
  );
}

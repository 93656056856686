import { validateAddress } from 'wallet-engine-signing/blockchains/UTXO/addressValidation';
import { postJSON, UTXOChains } from 'wallet-engine-signing/blockchains/UTXO/indexer';
import { bnFromStringOrNumber, isHexString } from 'wallet-engine-signing/utils';

export type UTXO = {
  address: string;
  hash: string;
  index: number;
  value: BN;
  script: Buffer;
};

export type ServerUTXO = {
  value: number | string;
  script: string;
  address: string;
  hash: string;
  index: number;
};

export type AddressServerResponseOk = {
  address: string;
  is_used: boolean;
  utxos: ServerUTXO[];
};

export type AddressServerResponseError = {
  address: string;
  error: string;
};

export type AddressServerResponse = AddressServerResponseOk | AddressServerResponseError;

export type GetUTXOsResponse = {
  utxos: Record<string, AddressServerResponse>;
  synced_height: number;
  no_change: boolean;
};

export type GetUTXOsParams = {
  addresses: string[];
  blockchainSymbol: UTXOChains;
  testnet: boolean;
};

export async function getUTXOs({
  addresses,
  blockchainSymbol,
  testnet,
}: GetUTXOsParams): Promise<UTXO[]> {
  const result: UTXO[] = [];

  // validate all addresses before fetching UTXOs
  addresses.forEach((address) => {
    validateAddress({
      blockchainSymbol,
      address,
      testnet,
    });
  });

  const { body: res } = await postJSON<GetUTXOsResponse>({
    blockchainSymbol,
    endpoint: '/v2/getUTXOs',
    // explicit synced_height: 0 - makes it clear we are not syncing locally
    // eslint-disable-next-line camelcase
    body: { addresses, testnet, synced_height: 0 },
    options: {
      params: { extendedUTXO: 'true' },
    },
  });

  addresses.forEach((address) => {
    if (
      res.synced_height === 0 ||
      (res.utxos[address] as AddressServerResponseError).error ||
      !Object.keys(res.utxos).length ||
      !res.utxos[address] ||
      !(res.utxos[address] as AddressServerResponseOk).utxos
    ) {
      throw new Error('Network error, try again in a couple minutes');
    }

    const utxos = (res.utxos[address] as AddressServerResponseOk).utxos || [];

    utxos.forEach((coin) => {
      if (
        !isHexString(coin.hash) ||
        coin.hash.length === 0 ||
        !Number.isInteger(coin.index) ||
        !isHexString(coin.script) ||
        coin.script.length === 0
      ) {
        throw new Error('Network error, try again in a couple minutes');
      }

      const value = coin && bnFromStringOrNumber(coin.value, 0);
      if (!value) {
        throw new Error('Network error, try again in a couple minutes');
      }

      const utxo: UTXO = {
        address,
        hash: coin.hash,
        index: coin.index,
        value,
        script: Buffer.from(coin.script, 'hex'),
      };

      result.push(utxo);
    });
  });
  return result;
}

import { UserWalletSetting } from 'cb-wallet-data/stores/AssetManagement/models/UserWalletSetting';
import { userWalletSettingsAtom } from 'cb-wallet-data/stores/AssetManagement/state';
import { TxOrUserOp } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { portfolioWalletGroupIdsAtom, walletsAtom } from 'cb-wallet-data/stores/Wallets/state';
import { atom, selector } from 'recoil';

import { AssetManagementStatus } from '../AssetManagement/models/AssetManagementStatus';
import { walletGroupsAtom } from '../WalletGroups/state';

export const paginatedTransactionsAtom = atom<TxOrUserOp[]>({
  key: 'paginated_transactions',
  default: [],
});

export const isFetchingTransactionsAtom = atom<boolean>({
  key: 'is_fetching_transactions',
  default: true,
});

function checkShouldShowTxBasedOnSpamSetting({
  wallet,
  userWalletSettings,
  isSpamTransaction,
}: {
  wallet: Wallet | undefined;
  userWalletSettings: Record<string, UserWalletSetting> | undefined;
  isSpamTransaction?: boolean;
}): boolean {
  // if wallet info is missing and transaction is spam, the txn shouldn't be shown
  if (!wallet && isSpamTransaction) return false;

  if (!wallet) {
    return true;
  }

  const userWalletSettingId = UserWalletSetting.generateID(
    wallet.primaryAddress,
    wallet.blockchain,
    wallet.currencyCode,
    wallet.network,
    wallet.contractAddress,
  );
  const userWalletSetting = userWalletSettings?.[userWalletSettingId];
  const isSpam = Boolean(wallet?.isSpam);

  switch (true) {
    // If token is spam, by default, and userWalletSetting is not defined do not show it
    // If token is either spam or not spam, but the user has set the token as spam do not show it
    // If token user hidden do not show it
    case isSpam && userWalletSetting === undefined:
    case userWalletSetting?.status === AssetManagementStatus.userSpam:
    case userWalletSetting?.status === AssetManagementStatus.userHidden:
      return false;
    // else show it
    default:
      return true;
  }
}

/**
 * Filters transactions to those that correspond to the portfolio wallet groups.
 */
export const portfolioTransactionsSelector = selector({
  key: 'transactions/activeAddress',
  get: async function getActiveAddressTransactions({ get }): Promise<TxOrUserOp[]> {
    const transactions = get(paginatedTransactionsAtom);
    const userWalletSettings = get(userWalletSettingsAtom);
    const walletsMap = get(walletsAtom);

    const portfolioWalletGroupIds = get(portfolioWalletGroupIdsAtom);
    if (portfolioWalletGroupIds.length === 0) {
      return [];
    }

    const walletGroups = get(walletGroupsAtom);
    const portfolioWalletPropsToWalletGroupIdMap = walletGroups.reduce(
      function getWalletPropToWalletGroupMap(acc, walletGroup) {
        if (portfolioWalletGroupIds.includes(walletGroup.id)) {
          acc.set(`${walletGroup.accountId}/${walletGroup.walletIndex}`, walletGroup.id);
        }
        return acc;
      },
      new Map<string, string>(),
    );

    if (!portfolioWalletPropsToWalletGroupIdMap.size) return [];

    const filteredTxs = (transactions ?? []).filter(function filterActiveAddressTransactions(
      tx: TxOrUserOp,
    ) {
      const {
        walletIndex: walletIndexOfTx = 0n, // Undefined for UTXO, default to 0n to match against wallet group
        accountId: accountIdOfTx,
      } = tx;

      const wallet = walletsMap[tx.walletId];
      const isSpamTransaction = tx.isSpam;

      const walletProps = `${accountIdOfTx}/${walletIndexOfTx}`;
      const passesSpamFilter = checkShouldShowTxBasedOnSpamSetting({
        wallet,
        userWalletSettings,
        isSpamTransaction,
      });
      const walletMatchesWalletGroup = portfolioWalletPropsToWalletGroupIdMap.has(walletProps);
      return passesSpamFilter && walletMatchesWalletGroup;
    });

    return filteredTxs;
  },
});

/**
 * Filter that is used to filter transactions, or other lists by network. For utxo-based chains,
 * the blockchain code is used as the network value
 */
export const networkFilterAtom = atom<Set<string>>({
  key: 'transactions/filter',
  default: new Set(),
});

export const walletFilterAtom = atom<Set<string>>({
  key: 'wallets/filter',
  default: new Set(),
});

/**
 * Transactions are sorted by date descending when loaded in by the data layer.
 * orderAtom uses a boolean to flip between ascending and descending.
 */
export const sortAtom = atom({
  key: 'transactions/sort',
  default: 'date',
});

export const orderDescendingAtom = atom<boolean>({
  key: 'transactions/order',
  default: true,
});

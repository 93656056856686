import { defineMessages, useIntl } from 'react-intl';
import { Crypto } from 'wallet-cds-web/components/MiamiIllustrationWrapper/MiamiViceIllustrations/Crypto';
import { SpacingScale } from '@cbhq/cds-common';
import { Button } from '@cbhq/cds-web/buttons';
import { VStack } from '@cbhq/cds-web/layout';
import { Link, TextBody, TextTitle2 } from '@cbhq/cds-web/typography';

import { useSetL1WarningCookie } from './hooks/useSetL1WarningCookie';

const messages = defineMessages({
  title: {
    defaultMessage: 'Ethereum network fees are  high with a smart wallet',
    description: 'modal title',
  },
  description: {
    defaultMessage: 'To avoid paying high fees, use a {link} like Base.',
    description: 'modal description',
  },
  descriptionLink: {
    defaultMessage: 'low-cost network',
    description: 'modal description link',
  },
  confirm: {
    defaultMessage: 'I understand',
    description: 'confirm button',
  },
});

type L1EthWarningScreenProps = {
  /** callback for user clicking on 'I understand' */
  onSubmit: () => void;
  /** content alignment */
  alignItems?: 'center' | 'flex-start';
  /** spacing */
  spacing?: SpacingScale;
};

/**
 * L1EthWarningScreen
 *
 * Warn users that using the ETH mainnet is expensive and we recommend using Base
 */
export function L1EthWarningScreen({
  onSubmit,
  spacing = 4,
  alignItems = 'center',
}: L1EthWarningScreenProps) {
  const { formatMessage } = useIntl();
  const textAlignment = alignItems === 'center' ? 'center' : 'start';

  useSetL1WarningCookie();

  return (
    <VStack
      spacing={spacing}
      justifyContent="center"
      alignItems="center"
      testID="l1-eth-warning-screen"
    >
      <VStack gap={4} maxWidth={375} alignItems={alignItems}>
        <Crypto />
        <TextTitle2 as="h2" align={textAlignment}>
          {formatMessage(messages.title)}
        </TextTitle2>
        <TextBody color="foregroundMuted" as="p" align={textAlignment}>
          {formatMessage(messages.description, {
            link: (
              <Link
                to="https://help.coinbase.com/en/wallet/layer2-networks/layer2-networks-and-sidechains"
                testID="l2-external-link"
                openInNewWindow
              >
                {formatMessage(messages.descriptionLink)}
              </Link>
            ),
          })}
        </TextBody>
        <VStack gap={1} width="100%">
          <Button key="confirm" onClick={onSubmit}>
            {formatMessage(messages.confirm)}
          </Button>
        </VStack>
      </VStack>
    </VStack>
  );
}

import { useCallback, useMemo } from 'react';
import { logIsEligibleFnFailure } from 'cb-wallet-analytics/feature-management';
import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { useSuspendable } from 'cb-wallet-data/hooks/useSuspendable';

import { IsEligibleSuspenseQuery } from '../types';

export type UseIsFeatureEnabledForSuspenseQueryParams = {
  /**
   * featureName
   *
   * Represents the name of the feature.
   */
  featureName: string;
  /**
   * isEligibleSuspenseQuery
   *
   * An async function which returns boolean determining if the feature should be enabled
   *
   * When it is undefined, isEnabled will be set to true by default. Note this is
   * different from providing a isEligibleSuspenseQuery which always returns true. In the
   * case where there is a isEligibleSuspenseQuery provided, the initial isEnabled will be
   * false until the call succeeds.
   */
  isEligibleSuspenseQuery?: IsEligibleSuspenseQuery;

  /**
   * skipIsEligibleSuspenseQuery
   *
   * When this is true, it will skip making the isEligibleFn call and always
   * set isEnabled to true.
   */
  skipIsEligibleSuspenseQuery?: boolean;
};

/**
 * useIsFeatureEnabledForIsEligibleFn
 *
 * This is in prototype mode.
 *
 * Returns true if the feature is enabled for the user based on an eligibility function.
 * If no eligibility function is provided, the feature is considered enabled by default.
 */
export function useIsFeatureEnabledForSuspenseQuery({
  isEligibleSuspenseQuery,
  skipIsEligibleSuspenseQuery,
  featureName,
}: UseIsFeatureEnabledForSuspenseQueryParams): boolean {
  const defaultIsEnabled = skipIsEligibleSuspenseQuery ? true : !isEligibleSuspenseQuery;
  const { queryKey, queryFn } = isEligibleSuspenseQuery ?? {
    queryFn: undefined,
    queryKey: [],
  };
  const isQueryEnabled = skipIsEligibleSuspenseQuery ? false : Boolean(queryFn);

  const checkEligibility = useCallback(async () => {
    try {
      // if queryFn is provided, use it. Otherwise, default to true for the next set of checks
      const isEligible = await (queryFn ? queryFn() : Promise.resolve(true));
      return isEligible;
    } catch (e) {
      // queryFn should catch errors and when a uncaught error is encountered we assume the feature to be disabled.
      const error = coerceError(e, 'feature management error');
      logIsEligibleFnFailure({ errorMessage: error.message });
      cbReportError({
        context: 'feature-management',
        error,
        severity: 'error',
        isHandled: false,
      });

      return false;
    }
  }, [queryFn]);

  const isEnabled = useSuspendable({
    queryKey: [...queryKey, featureName, 'feature-management-query-key'],
    queryFn: checkEligibility,
    enabled: isQueryEnabled,
  });

  return useMemo(() => {
    if (!isQueryEnabled) {
      return defaultIsEnabled;
    }

    return Boolean(isEnabled);
  }, [defaultIsEnabled, isEnabled, isQueryEnabled]);
}

import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Provider, Region, useCookie } from '@coinbase/cookie-manager';
import { SetCookieFunction } from '@coinbase/cookie-manager/dist/types';
import { triggerCookieManagerLoaded } from 'cb-wallet-analytics/cookies';
import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { isInEU } from '@cbhq/intl';

import { cookieManagerConfig } from ':dapp/utils/cookieManagerConfig';

export type CookieContextType = {
  setTrackingPreference: () => void;
  hasCookieBannerRendered: boolean;
  handleHasBannerRendered: () => void;
  handleSetDismissCookie: SetCookieFunction;
  isCookieBannerDismissed: boolean;
};
const CookieContext = createContext<CookieContextType>({
  setTrackingPreference: () => {},
  handleHasBannerRendered: () => {},
  hasCookieBannerRendered: false,
  handleSetDismissCookie: () => {},
  isCookieBannerDismissed: false,
});

type CookieManagerProviderProps = { children: React.ReactNode };

export function CookieContextProvider({ children }: CookieManagerProviderProps) {
  const [hasCookieBannerRendered, setHasCookieBannerRendered] = useState<boolean>(false);
  const [dismissedCookie, setDismissCookie] = useCookie('cookies-banner') as [
    string,
    SetCookieFunction,
  ];

  const setTrackingPreference = useCallback(() => {
    /**
     * Placeholder for preference handler can utilized for optional cookies in the future
     *  */
  }, []);
  const handleHasBannerRendered = useCallback(() => {
    setHasCookieBannerRendered(true);
  }, []);

  const handleSetDismissCookie: SetCookieFunction = useCallback(
    (value) => {
      setDismissCookie(value);
    },
    [setDismissCookie],
  );
  const value = useMemo(() => {
    return {
      handleHasBannerRendered,
      hasCookieBannerRendered,
      setTrackingPreference,
      handleSetDismissCookie,
      isCookieBannerDismissed: dismissedCookie === 'dismissed',
    };
  }, [
    handleHasBannerRendered,
    hasCookieBannerRendered,
    setTrackingPreference,
    handleSetDismissCookie,
    dismissedCookie,
  ]);

  return <CookieContext.Provider value={value}>{children}</CookieContext.Provider>;
}

export function useCookieContext() {
  return useContext(CookieContext);
}

export function CookieManagerProvider({ children }: CookieManagerProviderProps) {
  const { locale } = useIntl();
  const coerceCookieManagerError = useCallback(function coerceCookieManagerError(
    error: ErrorOrAny,
  ) {
    cbReportError({
      error: coerceError(error, 'Got error in cookie manager'),
      context: 'dapp_error',
      isHandled: false,
      severity: 'error',
    });
  },
  []);

  const customLog = useCallback(() => {
    triggerCookieManagerLoaded();
  }, []);

  const region = useMemo(() => {
    return isInEU(locale) ? Region.EU : Region.DEFAULT;
  }, [locale]);

  const setTrackingPreference = useCallback(() => {
    /**
     * Empty function to satisfy Cookie Managager Provider props from package
     * We can utilize this if we have optional cookies in the future
     *  */
  }, []);

  return (
    <Provider
      log={customLog}
      region={region}
      locale={locale}
      projectName="wallet_dapp"
      onError={coerceCookieManagerError}
      config={cookieManagerConfig}
      onPreferenceChange={setTrackingPreference}
    >
      <CookieContextProvider>{children}</CookieContextProvider>
    </Provider>
  );
}

import * as BufferLayout from '@solana/buffer-layout';
import { Commitment, LAMPORTS_PER_SOL, SendOptions } from '@solana/web3.js';
import { postJSON } from 'cb-wallet-http/fetchJSON';
import keyBy from 'lodash/keyBy';
import { SolanaError } from 'wallet-engine-signing/blockchains/Solana/errors';

import { SPLBalance, SPLOwnership, SPLToken } from './types';

const commonParams = {
  id: 1,
  jsonrpc: '2.0',
};

export const ACCOUNT_LAYOUT = BufferLayout.struct([
  // @ts-expect-error TS(2322): Type 'Blob' is not assignable to type 'Layout<neve... Remove this comment to see the full error message
  BufferLayout.blob(32, 'mint'),
  // @ts-expect-error TS(2322): Type 'Blob' is not assignable to type 'Layout<neve... Remove this comment to see the full error message
  BufferLayout.blob(32, 'owner'),
  // @ts-expect-error TS(2322): Type 'NearUInt64' is not assignable to type 'Layou... Remove this comment to see the full error message
  BufferLayout.nu64('amount'),
  // @ts-expect-error TS(2322): Type 'Blob' is not assignable to type 'Layout<neve... Remove this comment to see the full error message
  BufferLayout.blob(93),
]);

type Signature = {
  signature: string;
  blockTime: number | null;
};

export type GetSignaturesForAddress = {
  result: Signature[];
};

export type GetTransactionResult = {
  blockTime: string;
  meta: {
    fee: number;
    innerInstructions?: InnerInstruction[];
    postTokenBalances?: PostTokenBalance[];
  };
  transaction: SOLTxnResponse;
};

type SOLTxnResponse = {
  message: {
    accountKeys: [];
    instructions: [];
  };
  signatures: [];
};

type GetTransaction = {
  result: GetTransactionResult;
};

type PostTokenBalance = {
  mint: string;
  accountIndex: number;
  uiTokenAmount: {
    decimals: number;
  };
};

export type Instruction = {
  parsed?: {
    type?: string;
    info?: {
      amount: string;
      authority: string;
      source: string;
      destination: string;
    };
  };
  program?: string;
};

type InnerInstruction = {
  instructions: Instruction[];
};

export enum SolanaCommitmentLevel {
  CONFIRMED = 'confirmed',
  FINALIZED = 'finalized',
}

export const DEFAULT_COMMITMENT_LEVEL = SolanaCommitmentLevel.CONFIRMED;

type GetTransactionBatchedResult = GetTransactionResult[];

export async function getSignaturesForAddress({
  mainAccountAddress,
  rpcUrl,
  limit,
  before,
  ataAddress,
}: {
  mainAccountAddress: string;
  rpcUrl: string;
  limit: number;
  before: string | undefined;
  ataAddress?: string;
}) {
  const address = ataAddress ?? mainAccountAddress;
  const paramsArray = before ? [address, { limit, before }] : [address, { limit }];

  const parameters = {
    method: 'getSignaturesForAddress',
    params: paramsArray,
    ...commonParams,
  };

  const { result } = await postJSON<GetSignaturesForAddress>(rpcUrl, parameters, {
    isThirdParty: true,
  });

  const signatures = result.map((txnSignature: Signature) => txnSignature.signature);
  if (!signatures.length) return { txns: [], originalLength: 0 };
  const transactions = await getTransactionBatchedRpc({ signatures, rpcUrl });

  return {
    txns: transactions.filter((txResult) => !!txResult),
    originalLength: transactions.length,
  };
}

export async function getSplsByAddress(address: string, rpcUrl: string): Promise<string[]> {
  const balances = await getTokenAccountsByOwner({ address, rpcUrl });
  return balances.map((balance) => balance.pubkey);
}

export async function getTransactionBatchedRpc({
  rpcUrl,
  signatures,
}: {
  signatures: string[];
  rpcUrl: string;
}): Promise<GetTransactionBatchedResult> {
  const parameters = signatures.map((signature) => {
    return {
      method: 'getTransaction',
      params: [signature, { encoding: 'jsonParsed' }],
      ...commonParams,
    };
  });

  const response = await postJSON<GetTransaction[]>(rpcUrl, parameters, {
    isThirdParty: true,
  });

  if (!response) return [];

  return response.map((tx) => {
    return tx.result;
  });
}

export async function getTransactionRPC(
  signature: string,
  commitment: SolanaCommitmentLevel,
  rpcUrl: string,
  performRetry = true,
): Promise<GetTransactionResult | undefined> {
  const parameters = {
    method: 'getTransaction',
    params: [signature, { encoding: 'jsonParsed', commitment }],
    ...commonParams,
  };

  const { result } = await postJSON<GetTransaction>(rpcUrl, parameters, {
    isThirdParty: true,
  });

  if (result) return result;
  if (!performRetry) return undefined;

  return retryGetTransaction(parameters, rpcUrl);
}

const maxRetries = 15;

// TODO replace with retry util in history module
async function retryGetTransaction(parameters: Record<string, any>, rpcUrl: string) {
  for (let i = 0; i < maxRetries; i++) {
    // eslint-disable-next-line no-await-in-loop
    const retryResult: GetTransactionResult | undefined = await new Promise((resolve) => {
      setTimeout(async function retry() {
        const response = await postJSON<GetTransaction>(rpcUrl, parameters, {
          isThirdParty: true,
        });
        resolve(response.result);
      }, 3000);
    });

    if (retryResult) return retryResult;
  }

  throw new Error('unable to get tx confirmation');
}

type GetBalanceResponse = {
  result: {
    value: number;
  };
};

export async function getBalance({
  address,
  rpcUrl,
}: {
  address: string;
  rpcUrl: string;
}): Promise<bigint> {
  const parameters = {
    method: 'getBalance',
    params: [`${address}`],
    ...commonParams,
  };

  const { result } = await postJSON<GetBalanceResponse>(rpcUrl, parameters, {
    isThirdParty: true,
  });

  return BigInt(result.value);
}

export async function getSPLBalances(address: string, rpcUrl: string): Promise<any[]> {
  const tokensByOwner = await getTokenAccountsByOwner({ address, rpcUrl });
  return tokensByOwner;
}

type GetSPLInfosResponse = {
  tokens: SPLToken[];
  errorMessage?: string;
};

export async function getTokenAccountsByOwner({
  address,
  rpcUrl,
}: {
  address: string;
  rpcUrl: string;
}): Promise<SPLOwnership[]> {
  const parameters = {
    method: 'getTokenAccountsByOwner',
    params: [
      `${address}`,
      { programId: 'TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA' },
      { encoding: 'jsonParsed', commitment: DEFAULT_COMMITMENT_LEVEL },
    ],
    ...commonParams,
  };

  const { result } = await postJSON<{ result: { value: SPLOwnership[] } }>(rpcUrl, parameters, {
    isThirdParty: true,
  });

  return result.value;
}

export async function getSPLMetadata(splOwnerships: SPLOwnership[]): Promise<SPLBalance[]> {
  const ownershipByMintAddress = keyBy(
    splOwnerships,
    (ownership) => ownership.account.data.parsed.info.mint,
  );
  const params = { contractAddresses: Object.keys(ownershipByMintAddress) };
  const {
    result: { tokens },
  } = await postJSON<{ result: GetSPLInfosResponse }>('getSPLInfos', params);

  return tokens.map((token: SPLToken) => {
    const ownership = ownershipByMintAddress[token.address] || {};
    return {
      ...token,
      ...ownership,
    };
  });
}

/**
 * Call wallet/api endpoint to get SPL infos of contractAddresses
 * TODO: this should be deduped with ^
 * @param contractAddresses
 */
export async function getSPLInfos(contractAddresses: string[]): Promise<GetSPLInfosResponse> {
  const params = { contractAddresses };
  const { result } = await postJSON<{ result: GetSPLInfosResponse }>('getSPLInfos', params);
  return result;
}

export async function getSolanaBlockheight(rpcUrl: string): Promise<number> {
  const params = {
    ...commonParams,
    method: 'getBlockHeight',
    params: [{ commitment: DEFAULT_COMMITMENT_LEVEL }],
  };

  const { result } = await postJSON<{ result: number }>(rpcUrl, params, {
    isThirdParty: true,
  });

  return result;
}

/**
 * getRecentBlockHash
 */

export const SOLANA_BLOCKHASH_RETRY_COUNT = 3;

type GetRecentBlockhashResponse = {
  result: {
    value: {
      blockhash: string;
      feeCalculator: { lamportsPerSignature: number };
    };
  };
};

/**
 * @deprecated Please use getLatestBlockhash to fetch the blockhash and lastValidBlockHeight – this will be removed soon.
 * @param param0
 * @returns
 */
export async function getRecentBlockHash({
  retryCount = 0,
  commitment = DEFAULT_COMMITMENT_LEVEL,
  rpcUrl,
}: {
  rpcUrl: string;
  retryCount?: number;
  commitment?: Commitment;
}): Promise<string> {
  const parameters = {
    method: 'getRecentBlockhash',
    params: [{ commitment }],
    ...commonParams,
  };

  const { result } = await postJSON<GetRecentBlockhashResponse>(rpcUrl, parameters, {
    isThirdParty: true,
  });

  const isValid = await isBlockHashValid({
    blockhash: result.value.blockhash,
    rpcUrl,
  });

  if (!isValid) {
    if (retryCount >= SOLANA_BLOCKHASH_RETRY_COUNT) {
      throw SolanaError.unableToGetRecentBlockHash;
    } else {
      return getRecentBlockHash({
        retryCount: retryCount + 1,
        rpcUrl,
        commitment,
      });
    }
  }

  return result.value.blockhash;
}

type GetLatestBlockhashResponse = {
  result: {
    context: {
      slot: number;
    };
    value: {
      blockhash: string;
      lastValidBlockHeight: number;
    };
  };
};

type GetLatestBlockhash = {
  blockhash: string;
  lastValidBlockHeight: number;
};

export async function getLatestBlockhash({
  commitment = DEFAULT_COMMITMENT_LEVEL,
  rpcUrl,
}: {
  commitment?: Commitment;
  rpcUrl: string;
}): Promise<GetLatestBlockhash> {
  const parameters = {
    method: 'getLatestBlockhash',
    params: [{ commitment }],
    ...commonParams,
  };

  const { result } = await postJSON<GetLatestBlockhashResponse>(rpcUrl, parameters, {
    isThirdParty: true,
  });

  return result.value;
}

/**
 * isBlockHashValid
 */

type IsBlockHashValidResponse = {
  result: {
    context: {
      slot: number;
    };
    value: boolean;
  };
};

export async function isBlockHashValid({
  blockhash,
  rpcUrl,
}: {
  blockhash: string;
  rpcUrl: string;
}): Promise<boolean> {
  const params = {
    method: 'isBlockhashValid',
    params: [blockhash, { commitment: DEFAULT_COMMITMENT_LEVEL }],
    ...commonParams,
  };

  const { result } = await postJSON<IsBlockHashValidResponse>(rpcUrl, params, {
    isThirdParty: true,
  });

  return result.value;
}

export async function getSolRequiredForRentExempt(rpcUrl: string): Promise<bigint> {
  const params = {
    method: 'getMinimumBalanceForRentExemption',
    params: [ACCOUNT_LAYOUT.span, { commitment: DEFAULT_COMMITMENT_LEVEL }],
    ...commonParams,
  };
  const { result } = await postJSON<{ result: number }>(rpcUrl, params, {
    isThirdParty: true,
  });

  return BigInt(result);
}

/**
 * getAccountInfoBase64Batch
 */

type GetAccountInfoResultBase64 = {
  result: GetAccountInfoValueBase64;
};

type GetAccountInfoValueBase64 = {
  value: {
    data: [string, 'base64'];
    executable: boolean;
    lamports: number;
    onwer: string;
    rentEpoch: number;
  } | null;
};

type GetAccountInfoBase64BatchedResult = GetAccountInfoValueBase64[];

export async function getAccountInfoBase64Batch({
  rpcUrl,
  addresses,
}: {
  rpcUrl: string;
  addresses: string[];
}): Promise<GetAccountInfoBase64BatchedResult> {
  const params = addresses.map((address) => {
    return {
      method: 'getAccountInfo',
      params: [`${address}`, { commitment: DEFAULT_COMMITMENT_LEVEL, encoding: 'base64' }],
      ...commonParams,
    };
  });

  const response = await postJSON<GetAccountInfoResultBase64[]>(rpcUrl, params, {
    isThirdParty: true,
  });

  return response
    .filter((accountResponse) => !!accountResponse.result)
    .map((accountResponse) => {
      return accountResponse.result;
    });
}

/**
 * getNetworkFee
 */

type Success = {
  kind: 'Success';
  networkFee: bigint;
};

type Failure = {
  kind: 'Failure';
  networkFee: undefined;
};

type NetworkFeeStatus = Success | Failure;

export async function getNetworkFee({ rpcUrl }: { rpcUrl: string }): Promise<NetworkFeeStatus> {
  const params = {
    method: 'getRecentBlockhash',
    params: [{ commitment: DEFAULT_COMMITMENT_LEVEL }],
    ...commonParams,
  };

  try {
    const { result } = await postJSON<GetRecentBlockhashResponse>(rpcUrl, params, {
      isThirdParty: true,
    });

    return {
      kind: 'Success',
      networkFee: BigInt(result.value.feeCalculator.lamportsPerSignature),
    };
  } catch (err) {
    return { kind: 'Failure', networkFee: undefined };
  }
}

/**
 * getFeeForMessage
 */

type GetFeeForMessageResponse = {
  result: {
    context: {
      slot: number;
    };
    value: number;
  };
};

export async function getFeeForMessage({
  transactionMessage,
  rpcUrl,
}: {
  transactionMessage: string;
  rpcUrl: string;
}): Promise<NetworkFeeStatus> {
  const params = {
    method: 'getFeeForMessage',
    params: [transactionMessage, { commitment: DEFAULT_COMMITMENT_LEVEL }],
    ...commonParams,
  };

  try {
    const { result } = await postJSON<GetFeeForMessageResponse>(rpcUrl, params, {
      isThirdParty: true,
    });

    return {
      kind: 'Success',
      networkFee: BigInt(result.value),
    };
  } catch (err) {
    return { kind: 'Failure', networkFee: undefined };
  }
}

/**
 * requestAirdrop
 */

// TODO fix any
export async function requestAirDrop(address: string, amount: bigint, url: string): Promise<any> {
  const lamports = BigInt(LAMPORTS_PER_SOL) * amount;

  const parameters = {
    method: 'requestAirdrop',
    params: [`${address}`, Number(lamports)],
    ...commonParams,
  };

  return postJSON<unknown>(url, parameters, { isThirdParty: true });
}

/**
 * submitSignedTransaction
 */

type SubmitSignedTransactionResponse = {
  result?: string;
  error?: {
    code: number;
    message: string;
  };
};

export async function submitSignedTransaction({
  serializedTx,
  additionalParams = {
    preflightCommitment: DEFAULT_COMMITMENT_LEVEL,
  },
  rpcUrl,
}: {
  rpcUrl: string;
  serializedTx: Buffer;
  additionalParams?: SendOptions;
}): Promise<string | undefined> {
  const params = {
    method: 'sendTransaction',
    params: [
      `${serializedTx.toString('base64')}`,
      {
        encoding: 'base64',
        ...additionalParams,
      },
    ],
    ...commonParams,
  };

  const response = await postJSON<SubmitSignedTransactionResponse>(rpcUrl, params, {
    isThirdParty: true,
  });

  if (response.error) {
    throw new SolanaError(
      SolanaError.transactionSimulationFailed.name,
      SolanaError.transactionSimulationFailed.message,
      response.error.message,
    );
  }

  return response.result;
}

/**
 * getAccountInfo
 */

type GetAccountInfoResult = {
  result: GetAccountInfoValue;
};

type GetAccountInfoValue = {
  value: {
    data: string | GetAccountInfoValueData | string[];
    executable: boolean;
    lamports: number;
    onwer: string;
    rentEpoch: number;
  } | null;
};

export type GetAccountInfoValueData = {
  parsed: {
    info: {
      mint: string;
      owner: string;
    };
  };
};

export async function getAccountInfo({
  address,
  rpcUrl,
}: {
  address: string;
  rpcUrl: string;
}): Promise<GetAccountInfoValue> {
  const params = {
    method: 'getAccountInfo',
    params: [`${address}`, { commitment: DEFAULT_COMMITMENT_LEVEL, encoding: 'jsonParsed' }],
    ...commonParams,
  };

  const { result } = await postJSON<GetAccountInfoResult>(rpcUrl, params, {
    isThirdParty: true,
  });

  return result;
}

/**
 * getSPLBalanceOfATA
 */

type SPLTokenAmount = {
  amount: string;
  decimals: number;
  uiAmount: number;
  uiAmountString: string;
};

type GetSPLBalanceOfAtaValue = {
  result?: {
    value: SPLTokenAmount;
  };
  error?: {
    code: number;
    message: string;
  };
};

export async function getSPLBalanceOfATA({
  ataAddress,
  rpcUrl,
}: {
  ataAddress: string;
  rpcUrl: string;
}): Promise<bigint> {
  const params = {
    method: 'getTokenAccountBalance',
    params: [`${ataAddress}`],
    ...commonParams,
  };

  const response = await postJSON<GetSPLBalanceOfAtaValue>(rpcUrl, params, {
    isThirdParty: true,
  });

  if (response.error) {
    throw new Error(response.error.message ?? '');
  } else if (!response.result?.value) {
    throw new Error('Unexpected response');
  }

  return BigInt(response.result.value.amount);
}

type GetSignatureStatuses = {
  result: {
    value: GetSignatureStatusesResult[];
  };
};

type GetSignatureStatusesResult = {
  slot: number;
  confirmations: number;
  err: Record<string, any>;
  confirmationStatus: Commitment;
} | null;

export async function getSignatureStatuses({
  signatures,
  rpcUrl,
}: {
  signatures: string[];
  rpcUrl: string;
}): Promise<GetSignatureStatusesResult[] | undefined> {
  const parameters = {
    method: 'getSignatureStatuses',
    params: [signatures, { searchTransactionHistory: true }],
    ...commonParams,
  };

  const { result } = await postJSON<GetSignatureStatuses>(rpcUrl, parameters, {
    isThirdParty: true,
  });

  if (!result) return undefined;

  return result.value;
}

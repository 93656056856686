import { Formatters } from 'react-intl';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { formatDecimal } from 'cb-wallet-data/stores/ExchangeRates/utils';
import Decimal from 'decimal.js';

export function formatDecimalToCrypto({
  formatters,
  includeCode,
  currencyCode,
  decimalValue,
  minDisplayDecimals,
  maxDisplayDecimals,
  shouldDisplayDustAmount,
}: {
  formatters: Formatters;
  includeCode?: boolean;
  currencyCode: CurrencyCode;
  decimalValue: Decimal;
  minDisplayDecimals: bigint;
  maxDisplayDecimals: bigint;
  shouldDisplayDustAmount?: boolean;
}) {
  const isDustAmount = decimalValue.lessThan(new Decimal(10).pow(Number(maxDisplayDecimals) * -1));
  const numOfDecimalsToDisplay = maxDisplayDecimals;
  const formatter = formatters.getNumberFormat(undefined, {
    maximumFractionDigits: Number(numOfDecimalsToDisplay),
    minimumFractionDigits: Number(minDisplayDecimals),
    minimumIntegerDigits: 1,
  });
  let formattedValue;
  if (isDustAmount && shouldDisplayDustAmount) {
    formattedValue = '0.00000';
  } else {
    const roundedValue = decimalValue.toDecimalPlaces(
      Number(numOfDecimalsToDisplay),
      Decimal.ROUND_HALF_UP,
    );

    const floating = roundedValue.modulo(1);
    const wholeNumber = roundedValue.minus(floating);
    const adjustedValue = wholeNumber.add(new Decimal(floating.toPrecision(3)));

    formattedValue = formatDecimal({
      integerFormatter: formatter,
      decimalFormatter: formatter,
      value: adjustedValue,
    });
  }
  return `${formattedValue} ${includeCode ? currencyCode.rawValue : ''}`.trim();
}

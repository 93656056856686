export const zIndex = {
  hidden: -1,
  /* Hidden behind everything else */
  normal: 0,
  /* Default for all elements */
  elevated: 1,
  /* Above normal elements */
  high: 2,
  /* Above advanced trading chart elements */
  extraHigh: 25,
  /* Above elevated elements (exceptional high-value cases, e.g. tooltips) */
  backdrop: 999,
  /* Above all elements */
  overlay: 1000,
  /* Above backdrop (e.g. backdrop content, modal) */
  top: 1001,
  /* Above overlay (e.g. toasts) */
};

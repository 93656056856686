import { logHRTTxnMissingDecimals } from 'cb-wallet-analytics/transactions';
import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { getNetworkForWacId } from 'cb-wallet-data/stores/Networks/utils/getNetworkForWacId';
import { BASE_ASSET_ADDRESS } from 'cb-wallet-data/stores/Transactions/methods/parsers/HRT/constants';
import {
  apiv1LabelToPrimaryAction,
  buildHRTMetadata,
  hasMissingDecimals,
  isSend,
  isSpam,
  primaryActionToType,
} from 'cb-wallet-data/stores/Transactions/methods/parsers/utils/utils';
import { Transfer } from 'cb-wallet-data/stores/Transactions/models/Transfer';
import { TxOrUserOp, TxOrUserOpParams } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import { TxState } from 'cb-wallet-data/stores/Transactions/models/TxState';
import { unixTimestampToDate } from 'cb-wallet-data/stores/Transactions/utils/unixTimestampToDate';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { v4 as uuidv4 } from 'uuid';
import {
  Apiv1Label,
  V1AddressMeta,
  V1PrimaryAction,
  V1SpamScoreThresholds,
  V1TransactionStatus,
} from '@cbhq/instant-api-hooks-wallet-tx-history/types';

export function parseBridge({
  primaryAction,
  historicalAssetPricesUsd,
  feeAmount,
  txHash,
  txNonce,
  txTimestamp,
  txStatus,
  userOpHash,
  userAddress,
  blockchain,
  network,
  accountId,
  walletIndex,
  spamScoreThresholds,
  addressMetadata,
  transfers,
}: {
  primaryAction: V1PrimaryAction | undefined;
  historicalAssetPricesUsd?: Record<string, string>;
  feeAmount?: string;
  txHash?: string;
  txNonce: number;
  txTimestamp?: string;
  txStatus?: V1TransactionStatus;
  userOpHash: string | undefined;
  userAddress: string;
  blockchain: Blockchain;
  network: Network;
  accountId: Account['id'];
  walletIndex: bigint;
  spamScoreThresholds?: V1SpamScoreThresholds;
  addressMetadata: Record<string, V1AddressMeta>;
  transfers: Transfer[];
}): TxOrUserOp | undefined {
  const bridgeAssetContractAddress = primaryAction?.bridgeDetails?.assetAddress || '';
  const bridgeFeeAssetContractAddress = primaryAction?.bridgeDetails?.feeAssetAddress || '';

  const nativeAssetMetadata = addressMetadata[BASE_ASSET_ADDRESS]?.token;
  const bridgeAssetMetadata = addressMetadata[bridgeAssetContractAddress];
  const bridgeFeeAssetMetadata = addressMetadata[bridgeFeeAssetContractAddress];

  if (
    hasMissingDecimals([
      addressMetadata[BASE_ASSET_ADDRESS],
      bridgeAssetMetadata,
      bridgeFeeAssetMetadata,
    ])
  ) {
    logHRTTxnMissingDecimals({
      primaryAction: primaryAction?.type || '',
      blockchain: blockchain.rawValue,
      network: network.rawValue,
    });
    return undefined;
  }

  const bridgeAmount = primaryAction?.bridgeDetails?.amount;
  const bridgeFeeAmount = primaryAction?.bridgeDetails?.feeAmount;

  const bridgeTokenName = bridgeAssetMetadata?.token?.name || '';
  const bridgeFeeTokenName = bridgeFeeAssetMetadata?.token?.name || '';

  const bridgeTokenDecimal = BigInt(bridgeAssetMetadata?.token?.decimals || 0);
  const bridgeFeeTokenDecimal = BigInt(bridgeFeeAssetMetadata?.token?.decimals || 0);

  const profileImage = addressMetadata[userAddress]?.avatarUrl;

  const fromDomain = addressMetadata[userAddress]?.domain;

  const bridgeAssetImage = bridgeAssetMetadata?.token?.logo?.url;

  const toNetwork = primaryAction?.bridgeDetails?.network;

  const bridgeAssetHistoricalPrice = historicalAssetPricesUsd?.[bridgeAssetContractAddress];
  const nativeAssetHistoricalPrice = historicalAssetPricesUsd?.[BASE_ASSET_ADDRESS];

  const nativeAssetCurrenyCode = new CurrencyCode(
    nativeAssetMetadata?.symbol || blockchain?.rawValue,
  );
  const bridgeAssetCurrencyCode = new CurrencyCode(bridgeAssetMetadata?.token?.symbol || '');
  const bridgeFeeAssetCurrencyCode = new CurrencyCode(bridgeFeeAssetMetadata?.token?.symbol || '');

  const date = unixTimestampToDate(txTimestamp || new Date().getTime().toString());
  const nonce = BigInt(txNonce || 0);

  const feeCurrencyDecimal = BigInt(nativeAssetMetadata?.decimals || 0);
  const fee = BigInt(feeAmount || 0);
  const feeCurrencyCode = nativeAssetCurrenyCode;

  const walletId = Wallet.generateId({
    blockchain,
    currencyCode: bridgeAssetCurrencyCode,
    network,
    contractAddress:
      bridgeAssetContractAddress === BASE_ASSET_ADDRESS ? undefined : bridgeAssetContractAddress,
    selectedIndex: walletIndex,
    accountId,
  });

  const txMetadata = buildHRTMetadata([bridgeAssetMetadata], primaryAction?.metadata);

  return new TxOrUserOp({
    id: uuidv4(),
    createdAt: date,
    confirmedAt: date,
    blockchain,
    currencyCode: bridgeAssetCurrencyCode,
    feeCurrencyCode,
    feeCurrencyDecimal,
    toAddress: userAddress,
    toDomain: undefined,
    fromAddress: userAddress,
    fromDomain,
    amount: BigInt(bridgeAmount || 0),
    fee,
    state:
      txStatus === V1TransactionStatus.TRANSACTION_STATUS_SUCCESS
        ? TxState.CONFIRMED
        : TxState.FAILED,
    metadata: txMetadata,
    network,
    txOrUserOpHash: userOpHash ?? txHash!,
    userOpHash,
    walletIndex,
    accountId,
    txHash,
    isSent: isSend(primaryAction?.type || Apiv1Label.LABEL_UNKNOWN),
    contractAddress:
      bridgeAssetContractAddress === BASE_ASSET_ADDRESS ? undefined : bridgeAssetContractAddress,
    tokenName: bridgeTokenName,
    tokenDecimal: bridgeTokenDecimal,
    walletId,
    nonce,
    type: primaryActionToType({ primaryActionLabel: primaryAction?.type, txMetadata }),
    transfers,
    isSpam:
      isSpam(spamScoreThresholds, [bridgeAssetMetadata?.token?.spamScore || 0]) ||
      Boolean(bridgeAssetMetadata?.nftCollection?.isSpam),
    primaryAction: apiv1LabelToPrimaryAction(primaryAction?.type),
    toAssetHistoricalPrice:
      primaryAction?.type === Apiv1Label.LABEL_BRIDGE_IN ? bridgeAssetHistoricalPrice : undefined,
    fromAssetHistoricalPrice:
      primaryAction?.type === Apiv1Label.LABEL_BRIDGE_OUT ? bridgeAssetHistoricalPrice : undefined,
    nativeAssetHistoricalPrice,
    fromAssetImage:
      primaryAction?.type === Apiv1Label.LABEL_BRIDGE_OUT ? bridgeAssetImage : undefined,
    toAssetImage: primaryAction?.type === Apiv1Label.LABEL_BRIDGE_IN ? bridgeAssetImage : undefined,
    fromProfileImage:
      primaryAction?.type === Apiv1Label.LABEL_BRIDGE_OUT ? profileImage : undefined,
    toProfileImage: primaryAction?.type === Apiv1Label.LABEL_BRIDGE_IN ? profileImage : undefined,
    fromAmount: primaryAction?.type === Apiv1Label.LABEL_BRIDGE_OUT ? bridgeAmount : undefined,
    toAmount: primaryAction?.type === Apiv1Label.LABEL_BRIDGE_IN ? bridgeAmount : undefined,
    isContractExecution: true,
    toNetwork: getNetworkForWacId(toNetwork || ''), // TODO: expand this function to ethereum testnets
    toCurrencyCode:
      primaryAction?.type === Apiv1Label.LABEL_BRIDGE_IN ? bridgeAssetCurrencyCode : undefined,
    toTokenName: primaryAction?.type === Apiv1Label.LABEL_BRIDGE_IN ? bridgeTokenName : undefined,
    toTokenDecimal:
      primaryAction?.type === Apiv1Label.LABEL_BRIDGE_IN ? bridgeTokenDecimal : undefined,
    protocolFeeAmount: bridgeFeeAmount,
    protocolFeeDecimal: bridgeFeeTokenDecimal,
    protocolFeeCurrencyCode: bridgeFeeAssetCurrencyCode,
    protocolFeeName: bridgeFeeTokenName,
    toWalletId: undefined,
    source: 'V3',
  } as TxOrUserOpParams);
}

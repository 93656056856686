import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { KeyActionCategoryConfig } from 'cb-wallet-data/stores/SettingsRedesign/types';
import noop from 'lodash/noop';

import { SETTING_CATEGORY_INNER_SPACING as INNER_SPACING } from ':dapp/pages/settings/constants';

const messages = defineMessages({
  title: {
    defaultMessage: 'Swap',
    description: 'Swap category title',
  },
});

export function useSwapPageConfig(): KeyActionCategoryConfig {
  const { formatMessage } = useIntl();

  /*
   * TODO: Add logic to navigate to the swap page
   * JIRA: https://jira.coinbase-corp.com/browse/WALL-28831
   */
  const onPress = noop;

  const swapConfig = useMemo<KeyActionCategoryConfig>(() => {
    return {
      title: formatMessage(messages.title),
      media: undefined,
      accessory: 'arrow',
      testID: 'key-actions-swap',
      innerSpacing: INNER_SPACING,
      iconName: 'arrowsHorizontal',
      onPress,
    };
  }, [formatMessage, onPress]);

  return swapConfig;
}

import { logSolanaTxAcceptedButReverted } from 'cb-wallet-analytics/data/Transactions';
import {
  ResubmitPendingTransactionsArgs,
  ResubmitPendingTransactionsReturn,
} from 'cb-wallet-data/chains/AccountBased/operations/resubmitPendingTransactions';
import { filterPendingTransactions } from 'cb-wallet-data/chains/AccountBased/utils/filterPendingTransactions';
import { NetworkError } from 'cb-wallet-data/stores/Networks/NetworkError';
import { TxState } from 'cb-wallet-data/stores/Transactions/models/TxState';
import { mapNotNull } from 'cb-wallet-data/utils/Array';
import { SOLANA_RPC_URL } from 'cb-wallet-env/env';
import partition from 'lodash/partition';
import {
  getSolanaBlockheight,
  submitSignedTransaction,
} from 'wallet-engine-signing/history/Solana/RPC';

import { getTransactionReceipts, SolanaTransactionReceipt } from '../api';
import { getUnminedSignedTxs, saveTransaction } from '../database';
import { SolanaChain } from '../models/SolanaChain';
import { SolanaSignedTx } from '../models/SolanaSignedTx';

class SolanaTxReceiptsResult {
  constructor(readonly tx: SolanaSignedTx, readonly isSuccessful: boolean) {}
}

/**
 * Gets called by TxHistoryRepo refreshable to repeatedly resubmit Sol transactions that haven't been included in a
 * confirmed block, and change the status of the pending txn once it is confirmed. Pending sol tx's are stored in the `SolanaSignedTx` table.
 * We mark a tx as dropped if the blockhash is no longer valid and we have not received a receipt from the network for the tx.
 *
 * Note: if/when resubmit is implemented, web3 transactions (i.e. for panorama) will need to be filtered.
 * see ethereum's implementation as an example: workspaces/libs/data/chains/AccountBased/Ethereum/Transactions/resubmitPendingTransactions.ts
 */
export async function resubmitPendingTransactions({
  network,
  syncUpdateToTxHistoryTable,
}: ResubmitPendingTransactionsArgs): ResubmitPendingTransactionsReturn {
  const solanaChain = network.asChain() as SolanaChain;
  if (!solanaChain) {
    throw NetworkError.invalidNetwork(network);
  }

  // Get list of unmined signed transactions on Solana chain. This excludes dropped transaction
  const unminedSignedTxs = await getUnminedSignedTxs(solanaChain);

  const [unminedSignedTxsFiltered, submittedTxs] = partition(
    unminedSignedTxs,
    (tx) => tx.signedTxData.length !== 0,
  );

  // Get list of transactions receipts
  const unminedTransactionsReceipts = await getSolanaTxReceipts(unminedSignedTxsFiltered);
  const submittedTransactionsReceipts = await getSolanaTxReceipts(submittedTxs);

  // Get pending transactions without a receipt
  const pendingTransactions = filterPendingTransactions(unminedTransactionsReceipts);

  // Submit pending transactions
  await submitUnconfirmedSignedTxs(pendingTransactions, syncUpdateToTxHistoryTable);

  const solanaTxReceiptsResults = mapNotNull(
    [...unminedTransactionsReceipts, ...submittedTransactionsReceipts],
    ([receipt, tx]) => (!receipt ? null : new SolanaTxReceiptsResult(tx, receipt.isSuccessful)),
  );

  // Update the state of transactions for which we received receipts. If we receive a receipt, that means the
  // transaction was succesfully submitted to the network. This does not mean that the transaction will succeeed.
  // It only means that we do not need to keep resubmitting the pending transaction.
  await Promise.all(
    solanaTxReceiptsResults.map(async function updateTransactions({ isSuccessful, tx }) {
      let state: TxState;
      if (isSuccessful) {
        state = TxState.CONFIRMED;
      } else {
        logSolanaTxAcceptedButReverted();
        state = TxState.FAILED;
      }

      const signedTx = new SolanaSignedTx(
        tx.id,
        tx.fromAddress,
        tx.toAddress,
        tx.chainId,
        tx.signedTxData,
        tx.txHash,
        tx.transferValue,
        tx.blockchain,
        tx.currencyCode,
        state,
        tx.recentBlockHash,
        tx.lastValidBlockHeight,
        tx.notFoundCount,
      );

      return saveTransaction(signedTx).then(() => {
        syncUpdateToTxHistoryTable(signedTx);
      });
    }),
  );
}

type ReceiptTransactionPair = [SolanaTransactionReceipt | null, SolanaSignedTx];

/**
 * Get Solana transaction receipts for given transactions
 */
export async function getSolanaTxReceipts(
  txs: SolanaSignedTx[],
): Promise<ReceiptTransactionPair[]> {
  if (!txs.length) {
    return Promise.resolve([]);
  }

  const txHashes = txs.map((tx) => tx.txHash);

  const transactionReceipts = await getTransactionReceipts(txHashes);

  return txs.map(function mapReceiptPairs(tx, index) {
    const receipt = transactionReceipts[index];
    return [receipt, tx] as ReceiptTransactionPair;
  });
}

/**
 * Submit list of unconfirmed signed transactions
 */
export async function submitUnconfirmedSignedTxs(
  txs: SolanaSignedTx[],
  syncUpdateToTxHistoryTable: (transaction: SolanaSignedTx) => void,
): Promise<SolanaSignedTx[]> {
  if (txs.length === 0) {
    return Promise.resolve([]);
  }

  await Promise.all(
    txs.map(async (tx: SolanaSignedTx) => {
      try {
        const currentBlockheight = await getSolanaBlockheight(SOLANA_RPC_URL);

        const canResubmit =
          !!tx.lastValidBlockHeight && currentBlockheight < tx.lastValidBlockHeight;

        if (canResubmit) {
          await submitSignedTransaction({ serializedTx: tx.signedTxData, rpcUrl: SOLANA_RPC_URL });
        } else {
          // the blockhash is no longer valid so we mark the transaction as dropped
          const updatedTx = new SolanaSignedTx(
            tx.id,
            tx.fromAddress,
            tx.toAddress,
            tx.chainId,
            tx.signedTxData,
            tx.txHash,
            tx.transferValue,
            tx.blockchain,
            tx.currencyCode,
            TxState.DROPPED,
            tx.recentBlockHash,
            tx.lastValidBlockHeight,
            tx.notFoundCount,
          );

          return await saveTransaction(updatedTx).then(() => {
            syncUpdateToTxHistoryTable(updatedTx);
          });
        }
      } catch (err) {
        return null;
      }
    }),
  );

  return txs;
}
